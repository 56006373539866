import { useMemo } from 'react';
import { useQueryParam } from './browser';

const EMPTY_OBJECT = {};

// Overlays are managed the same way as modals (see modals.ts)
export function useRouteOverlay(routeKey: string, searchParams = EMPTY_OBJECT) {
  const [overlays, setOverlays] = useQueryParam('overlay', {
    isArray: true,
    searchParams
  });

  return useMemo(() => {
    const overlayList = Array.isArray(overlays) ? overlays : [overlays];
    const isOpen = overlayList.includes(routeKey);
    return {
      isOpen,
      open: () => !isOpen && setOverlays([...overlayList, routeKey]),
      close: () =>
        isOpen && setOverlays(overlayList.filter(id => id !== routeKey))
    };
  }, [overlays, setOverlays, routeKey]);
}
