import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon } from '@grain/grain-ui';
import { Button, Thumbnail, theme, Skeleton } from '@grain/grain-ui/v4';
import { Duration } from 'components/atoms/Duration/Duration';
import { format } from 'date-fns';
import { AdHocRecordingFragment } from '~/modules/contentFilter/contentfilter.generated';
import { ParticipantsMenu } from '~/modules/meetings/ParticipantsMenu';
import { CompanyMetaItem } from '~/modules/meetings/CompanyMetaItem';
import { MetadataContainer } from '~/modules/meetings/ButtonMetadata';
import { useWorkspace } from '@grain/api/auth';
import { OwnerMetaItem } from '~/modules/meetings/OwnerMetaItem';

const Container = styled.article`
  ${theme.utils.py('14px')}
  ${theme.utils.px('2xl')}
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto auto;
  flex-shrink: 0;
  gap: ${theme.tokens.spacing.md};

  &:hover {
    background-color: ${theme.tokens.color.surfaceSecondary};
  }
`;

const Title = styled.h3`
  ${theme.tokens.typography.b3[500]};
  color: ${theme.tokens.color.textPrimary};
  padding: 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  margin: 0 0 ${theme.tokens.spacing['2xs']} 0;
`;

const Meta = styled.div`
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textTertiary};
`;

type MeetingListItemProps = {
  recording: AdHocRecordingFragment;
  onClickRestore: () => void;
  onClickDelete: () => void;
};

export const DeletedListItem = ({
  recording,
  onClickDelete,
  onClickRestore
}: MeetingListItemProps) => {
  const { workspace } = useWorkspace();

  return (
    <Container>
      <Thumbnail
        imgSrc={
          recording.fullWebpThumbnailUrl ||
          recording.fullJpegThumbnailUrl ||
          undefined
        }
        css={['height: 36px;']}
      />

      <div
        css={css`
          overflow: hidden;
          white-space: nowrap;
        `}
      >
        <Title title={recording.title}>
          {recording.title}
          {recording.workspaceShared && (
            <Icon.Briefcase
              css={css`
                ${theme.utils.size('16px')};
                margin-left: ${theme.tokens.spacing.xs};
                color: ${theme.tokens.color.textDisabled};
              `}
            />
          )}
        </Title>
        <Meta>
          {format(new Date(recording.startDatetime), 'MMM, do h:mm a')} ·{' '}
          <Duration
            durationMs={
              new Date(recording.endDatetime ?? '').getTime() -
              new Date(recording.startDatetime).getTime()
            }
          />
        </Meta>
      </div>
      <div
        css={css`
          display: none;
          align-items: center;
          gap: ${theme.tokens.spacing.sm};

          @media (${theme.tokens.breakpoints.lgMin}) {
            display: flex;
          }
        `}
      >
        <OwnerMetaItem owner={recording.owner} />
        <CompanyMetaItem
          participantScope={recording.participantScope}
          externalGroupsData={recording.externalGroupsData}
          workspaceName={workspace?.name || null}
          workspaceLogoUrl={workspace?.logoUrl || null}
        />

        <MetadataContainer width='sm'>
          <ParticipantsMenu
            externalGroupsData={recording.externalGroupsData}
            participants={recording.participants}
            owner={recording.owner}
            recordingId={recording.id}
            tippyProps={{
              placement: 'bottom-end'
            }}
          />
        </MetadataContainer>
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: ${theme.tokens.spacing.sm};
        `}
      >
        <Button
          variant='neutral'
          size='md'
          icon={Icon.Refresh}
          onClick={onClickRestore}
        >
          Restore
        </Button>
        <Button
          variant='neutral'
          size='md'
          icon={Icon.Delete}
          onClick={onClickDelete}
        >
          Delete
        </Button>
      </div>
    </Container>
  );
};

DeletedListItem.Skeleton = () => (
  <Container>
    <Skeleton
      css={css`
        width: 64px;
        height: 36px;
      `}
    />
    <Skeleton
      css={css`
        width: 200px;
        height: 16px;
      `}
    />

    <div
      css={css`
        display: flex;
        align-items: center;
        gap: ${theme.tokens.spacing.sm};
      `}
    >
      <Skeleton
        css={css`
          width: 140px;
          height: 16px;
        `}
      />
      <Skeleton
        css={css`
          width: 140px;
          height: 16px;
        `}
      />
      <Skeleton
        css={css`
          width: 140px;
          height: 16px;
        `}
      />
      <Skeleton
        css={css`
          width: 20px;
          height: 16px;
        `}
      />
    </div>
  </Container>
);
