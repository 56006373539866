import React from 'react';
import isToday from 'date-fns/isToday';
import parse from 'date-fns/parse';
import { displayCents } from '~/support/price';
import {
  getBillingDisplayDate,
  displayPeriodAdjective,
  displayPeriodNoun
} from '~/modules/subscriptions/helpers';
import { getRecordingAllocationMessage } from '@grain/components/Subscriptions/helpers';

const changeConfirmProps = {
  confirmButtonType: 'primary',
  confirmContent: 'Continue',
  width: 418,
  showCancel: false
};

export const getFromToTemplate = (fromPlan, toPlan) => {
  const template = getTemplate(fromPlan, toPlan);

  if (fromPlan && fromPlan && !template) {
    if (process.env.NODE_ENV === 'production') {
      throw new Error(`No template exists for [${fromPlan.sku}_${toPlan.sku}]`);
    } else {
      // eslint-disable-next-line no-console
      console.error(`
        No template exists for [${fromPlan.sku}_${toPlan.sku}]
      `);
    }
  }

  return template;
};

// For downgrading from a paid plan to another paid plan.
function downgradePaidToPaidConfirm({
  toPlan,
  workspaceSubscription,
  currentPlan
}) {
  return {
    ...changeConfirmProps,
    title: <>Your workspace will be downgraded to {toPlan?.name}</>,
    description: (
      <>
        At the end of the current billing period,{' '}
        {getBillingDisplayDate(workspaceSubscription?.renewalDate)}, your
        workspace will be downgraded to the {toPlan?.shortName} plan and members
        will lose access to {currentPlan.shortName}
        {currentPlan?.isLegacy && ' (Legacy)'} features.
      </>
    ),
    confirmContent: 'Got it'
  };
}

// Use the same next message for upgrading and downgrading to and from paid plans
function downgradeOrUpgradePaidToPaidNext({
  subscriptionPreview,
  toPlan,
  workspaceSubscription,
  currentPlan
}) {
  // if the plan change occurs immediately, then it is an upgrade
  const isUpgrade = isToday(
    parse(subscriptionPreview?.billingDate, 'yyyy-MM-dd', new Date())
  );

  return (
    <>
      {isUpgrade ? (
        <>Your workspace will switch from </>
      ) : (
        <>
          On {getBillingDisplayDate(workspaceSubscription?.renewalDate)}, your
          workspace will switch from{' '}
        </>
      )}
      <b>
        {currentPlan?.name}
        {currentPlan?.isLegacy && ' (Legacy)'}{' '}
        {displayPeriodAdjective[workspaceSubscription?.billingPeriod]}
      </b>{' '}
      to{' '}
      <b>
        {toPlan?.name}{' '}
        {displayPeriodAdjective[subscriptionPreview?.billingPeriod]}
        {isUpgrade && ' today'}
      </b>
      .
      <br />
      {isUpgrade &&
        (subscriptionPreview?.netPrice > 0 ? (
          <>
            The prorated charge for this upgrade will be{' '}
            <b>${displayCents(subscriptionPreview?.netPrice || 0) || ''}</b>.
            <br />
          </>
        ) : (
          <>
            This account will be credited{' '}
            <b>
              $
              {displayCents(Math.abs(subscriptionPreview?.netPrice || 0)) || ''}
            </b>{' '}
            towards future payments.
            <br />
          </>
        ))}
      Upon renewal, your credit card will be charged a total of{' '}
      <b>
        ${displayCents(subscriptionPreview?.renewalPrice || 0) || ''} per{' '}
        {displayPeriodNoun[subscriptionPreview?.billingPeriod]}
      </b>{' '}
      for{' '}
      <b>
        {subscriptionPreview?.renewalCount ?? '..'} {toPlan.shortName} seat(s){' '}
      </b>
      on your renewal date.
      <br />
    </>
  );
}

// For downgrading to a free plan
function downgradeToFreeConfirm({
  toPlan,
  currentPlan,
  workspaceSubscription
}) {
  return {
    ...changeConfirmProps,
    title: <>Your workspace is now on {toPlan?.name}</>,
    description: (
      <>
        Your workspace will be downgraded at the end of the current billing
        period, {getBillingDisplayDate(workspaceSubscription.renewalDate)}.
        Members will lose access to {currentPlan?.shortName}
        {currentPlan?.isLegacy && ' (Legacy)'} plan features.
      </>
    ),
    confirmContent: 'Got it'
  };
}

function downgradeToFreeNext({ workspaceSubscription, currentPlan, toPlan }) {
  return (
    <>
      At the end of the current billing period,{' '}
      {getBillingDisplayDate(workspaceSubscription?.renewalDate)}, your
      workspace will be downgraded to {toPlan.shortName} plan and will have
      limited recordings and lose access to {currentPlan?.shortName}
      {currentPlan?.isLegacy && ' (Legacy)'} features.
    </>
  );
}

// For upgrading from a paid plan to another paid plan
function upgradePaidToPaidConfirm({
  toPlan,
  subscriptionPreview,
  workspaceSubscription
}) {
  return {
    ...changeConfirmProps,
    title: <>Your workspace is now on {toPlan?.name}</>,
    description: (
      <>
        All members with a paid seat now have{' '}
        {getRecordingAllocationMessage(toPlan)}.<br></br>
        Your next {
          displayPeriodAdjective[subscriptionPreview?.billingPeriod]
        }{' '}
        bill will automatically renew on{' '}
        {getBillingDisplayDate(subscriptionPreview.renewalDate)}.
      </>
    )
  };
}

// NOTE(ajoslin): There are no templates for "free" to "anything",
// because that triggers the upgrade "wizard" modal, not this screen.
/* eslint-disable react/prop-types */
export const getTemplate = (fromPlan, toPlan) => {
  // Both are paid plans
  if (!fromPlan?.isFree && !toPlan?.isFree) {
    // Upgrade
    if (fromPlan?.monthlyPrice < toPlan?.monthlyPrice) {
      return {
        confirm: upgradePaidToPaidConfirm,
        next: downgradeOrUpgradePaidToPaidNext
      };
    } else {
      // Downgrade
      return {
        confirm: downgradePaidToPaidConfirm,
        next: downgradeOrUpgradePaidToPaidNext
      };
    }
  }
  if (!fromPlan?.isFree && toPlan?.isFree) {
    return {
      confirm: downgradeToFreeConfirm,
      next: downgradeToFreeNext
    };
  }
  return undefined;
};

/* eslint-enable react/prop-types */
