import React from 'react';

import { useCalendly } from './hooks';
import { StyledFullCalendlyBlock } from './styles';

export const OnboardingRoleKey = 'onboarding-role';

const CalendlyPage = () => {
  const { setupCalendly } = useCalendly({ isFullFrame: true });

  React.useEffect(() => {
    setupCalendly();
  }, [setupCalendly]);

  return <StyledFullCalendlyBlock id='calendly-block' />;
};

export default CalendlyPage;
