import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button } from '@grain/grain-ui';

import {
  ModalLayout,
  ModalLogo,
  ModalInfo
} from '~/modals/LogoInfoInteractionModals';

import { CrmMeetingThumbnail } from '~/components/CrmMeetingThumbnail/CrmMeetingThumbnail';
import {
  type CrmProvider,
  crmProviderForRecording
} from '~/modules/freemium/crmProviderForRecording';
import {
  useFreemiumTrialOnboardingRecordingQuery,
  useFreemiumTrialOnboardingRecordingGenerateMutation
} from '~/modules/freemium/freemium.generated';

import type { StepComponentProps } from '../types';

// We want this button to look like the button from the prior step, which was a
// GDL3 button, because that step was copied from onboarding, which uses GDL3.
// (That said, the padding of the button deviated from the padding defined in
// our component.)  Also, there are 2 variations on this screen.
export const StyledNarrowButton = styled(Button)`
  height: auto;
  padding: 14px 30px;
`;
export const StyledWideButton = styled(Button)`
  height: auto;
  padding: 14px 88px;
`;

const CRM_DISPLAY_NAMES: Record<CrmProvider, string> = {
  hubspot: 'HubSpot',
  salesforce: 'Salesforce'
};

export function CRMTour({ handleDone }: StepComponentProps) {
  const navigate = useNavigate();
  const { data, loading } = useFreemiumTrialOnboardingRecordingQuery();
  const [recordingGenerating, setRecordingGenerating] = useState(false);
  const crmRecording = data?.freemiumTrialOnboardingRecording;
  const crmProvider = crmRecording
    ? crmProviderForRecording(crmRecording)
    : undefined;
  const crmName = crmProvider && CRM_DISPLAY_NAMES[crmProvider];

  const [freemiumTrialOnboardingRecordingGenerate] =
    useFreemiumTrialOnboardingRecordingGenerateMutation({
      onError() {
        // Swallow all errors since they are not actionable.
        return true;
      }
    });

  const handleStartTour = useCallback(() => {
    if (crmRecording?.recordingPath) {
      setRecordingGenerating(true);
      // call recording generate again to ensure the follow up email is generated
      freemiumTrialOnboardingRecordingGenerate({
        onCompleted() {
          navigate(crmRecording.recordingPath, {
            state: {
              showFreemiumTrialTour: true
            }
          });
        },
        // REMOVE THIS ONCE BE IS IN
        onError() {
          // Swallow all errors since they are not actionable.
          navigate(crmRecording.recordingPath, {
            state: {
              showFreemiumTrialTour: true
            }
          });
          return true;
        }
      });
    }
  }, [crmRecording, navigate, freemiumTrialOnboardingRecordingGenerate]);

  if (loading) return;

  if (!crmRecording) {
    return (
      <ModalLayout>
        <ModalLogo />
        <ModalInfo
          title='Enjoy your 14-day business trial'
          body='AI notes will now sync to CRM contacts if they are participants of your meetings. Also, you can now access Trackers, Coaching, Deals and more.'
        />
        <div css={['margin-top: 48px;']}>
          <StyledWideButton onClick={handleDone}>Check it out</StyledWideButton>
        </div>
      </ModalLayout>
    );
  }

  return (
    <ModalLayout>
      <ModalLogo />
      <ModalInfo
        title='Take a tour of Grain Business'
        body={`We’ll look at a recent meeting ${crmName ? `with ${crmName} Contacts` : ''} to highlight the benefits.`}
      />
      <div css={['margin-top: 32px;']}>
        <CrmMeetingThumbnail
          loading={loading}
          maxWidth={364}
          title={crmRecording.title}
          date={new Date(crmRecording.startDatetime)}
          duration={crmRecording.duration || 0}
          thumbnailUrl={crmRecording.fullJpegThumbnailUrl || ''}
          crmProvider={crmProvider}
        />
      </div>
      <div css={['margin-top: 32px;']}>
        <StyledNarrowButton
          onClick={handleStartTour}
          spinning={recordingGenerating}
        >
          Start Tour
        </StyledNarrowButton>
      </div>
    </ModalLayout>
  );
}
