import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Button from '@grain/components/Button';
import { displayCents } from '~/support/price';
import { useWorkspaceWithMembers } from '@grain/components/Workspace/hooks';
import { color, font, spacing } from '@grain/styles/constants';
import { useMyself } from '@grain/api/auth';
import { pxToRem, Icon, getTrackEventFunction } from '@grain/grain-ui';

import { StyledCompleteContainer, StyledConfirmationHeader } from './styles';
import { useSubscribeInfo } from '../context';
import api from '@grain/components/support/api';

Confirmation.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  currentSubscription: PropTypes.object.isRequired
};

export default function Confirmation({ onSuccess, currentSubscription }) {
  const { plan, membersSet, subscriptionPreviewCurrent, promoCode, isYearly } =
    useSubscribeInfo();
  const { myself } = useMyself();
  const eventsTrackedRef = React.useRef(false);

  const { workspace } = useWorkspaceWithMembers();

  const trackEvent = useMemo(() => getTrackEventFunction('', api), []);

  const { workspaceSubscription } = workspace || {};

  React.useEffect(() => {
    if (!myself?.user || eventsTrackedRef.current) return;

    eventsTrackedRef.current = true;

    trackEvent('Subscription Start Confirmation', {
      category: 'Billing'
    });

    trackEvent(
      'Upgrade Users Modal Submitted',
      {
        user_count_upgraded: membersSet.size,
        billing_cycle: isYearly ? 'Annual' : 'Monthly',
        workspace_plan_sku: plan.sku,
        workspace_renewal_price: workspaceSubscription?.renewalPrice,
        workspace_user_count: workspace?.users?.length
      },
      ['user', 'workspace']
    );
  }, [
    myself?.user,
    trackEvent,
    isYearly,
    membersSet,
    plan,
    workspace,
    workspaceSubscription
  ]);

  const isDeferredBilling = !subscriptionPreviewCurrent?.immediate;

  return (
    <div
      css={[
        'display: flex; flex-direction: column; align-items: center;',
        'text-align: center;',
        'padding: 32px 32px 0 32px;',
        font.bodyL,
        'line-height: 1.5rem;'
      ]}
    >
      <StyledConfirmationHeader>
        Success{' '}
        <span onClick={onSuccess}>
          <Icon.Close />
        </span>
      </StyledConfirmationHeader>
      <StyledCompleteContainer>
        <Icon.LoveStruckEye />
      </StyledCompleteContainer>
      {!isDeferredBilling && (
        <div css={[spacing.mt4]}>
          {membersSet.size}{' '}
          {membersSet.size > 1 ? 'members have' : 'member has'} been upgraded to{' '}
          {plan.name}.
        </div>
      )}
      <div
        css={[
          'margin-top: 12px;',
          `font-size: ${pxToRem(16)};`,
          `line-height: ${pxToRem(20)};`,
          'font-weight: 400;',
          color.crow
        ]}
      >
        {promoCode ? (
          <>
            A receipt was sent to{' '}
            {workspace?.workspaceSubscription?.billingEmail}. Check it to
            confirm your promotion was applied correctly. You will be billed at
            our normal price when your promotion ends.
          </>
        ) : isDeferredBilling ? (
          <>
            Your {currentSubscription?.trial ? '' : 'next'} payment of{' '}
            <b>${displayCents(workspaceSubscription?.renewalPrice)}</b> will be
            billed on{' '}
            <b>
              {new Date(workspaceSubscription?.renewalDate).toLocaleDateString({
                dateStyle: 'long'
              })}
              .
            </b>
          </>
        ) : (
          <>
            A receipt for $
            <b>{displayCents(subscriptionPreviewCurrent?.netPrice) || ''}</b>{' '}
            was sent to <b>{workspace?.workspaceSubscription?.billingEmail}</b>.
          </>
        )}
      </div>
      <Button
        css={['width: 113px;', 'height: 48px;', spacing.mt6]}
        type='primary'
        onClick={onSuccess}
      >
        Continue
      </Button>
    </div>
  );
}
