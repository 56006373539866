import * as Recoil from 'recoil';
import PropTypes from 'prop-types';
import React from 'react';
import { billingPreviewIsYearlyState } from '~/modules/subscriptions/state';
import { subscriptionPreviewQuery } from '~/modules/subscriptions/graphql';
import {
  usePlan,
  useWorkspacePlan
} from '@grain/components/Subscriptions/hooks';
import { useQuery } from '@grain/api/graphql';
import { useQueryParam } from '@grain/grain-ui';
import { useWorkspaceWithMembers } from '@grain/components/Workspace/hooks';
import { useLocation } from 'react-router-dom';

const SubscribeInfoContext = React.createContext();

export const useSubscribeInfo = () => React.useContext(SubscribeInfoContext);

SubscribeInfoProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export function SubscribeInfoProvider({ children }) {
  const location = useLocation();
  const [queryPlanSku] = useQueryParam('sku');
  const [planSku, setPlanSku] = React.useState(queryPlanSku);
  const [omitPlanMembers] = useQueryParam('omitPlanMembers');
  const [source] = useQueryParam('source');
  const [membersSet, setMembersSet] = React.useState(new Set());
  const [isYearly, setIsYearly] = Recoil.useRecoilState(
    billingPreviewIsYearlyState
  );
  const onlyShowFreeMembers = omitPlanMembers === 'true';
  const [promoCode, setPromoCode] = React.useState(null);
  const [monthlyPricePerUserOnAnnual, setMonthlyPricePerUserOnAnnual] =
    React.useState(0);
  const [monthlyPricePerUserOnMonthly, setMonthlyPricePerUserOnMonthly] =
    React.useState(0);

  const { subscriptionPlan: workspacePlan, loading: workspacePlanLoading } =
    useWorkspacePlan();
  const { subscriptionPlan: plan } = usePlan(planSku);

  // Start fetching before workspace members screen shows up so it's ready instantly.
  const workspaceRes = useWorkspaceWithMembers();

  const onloadSubscriptionRef = React.useRef();
  if (
    !onloadSubscriptionRef.current &&
    workspaceRes.workspace?.workspaceSubscription
  ) {
    onloadSubscriptionRef.current =
      workspaceRes.workspace?.workspaceSubscription;
  }

  const subscriptionArgs = React.useMemo(
    () => ({
      promoCode,
      planSku: plan?.sku,
      userIds: [...membersSet].map(user => user.id),
      billingPeriod: isYearly ? 'YEARLY' : 'MONTHLY'
    }),
    [plan, membersSet, isYearly, promoCode]
  );

  // Fetch one yearly res and one monthly res every time variables change,
  // so we can show the price difference.
  const subscriptionPreviewYearlyRes = useQuery(subscriptionPreviewQuery, {
    skip: Boolean(membersSet.size === 0 || !plan),
    fetchPolicy: 'cache-and-network',
    variables: {
      subscriptionArgs: {
        ...subscriptionArgs,
        billingPeriod: 'YEARLY'
      }
    },
    onCompleted: data => {
      const netPrice = data?.subscriptionPreview?.netPrice;
      setMonthlyPricePerUserOnAnnual(netPrice / membersSet.size / 12);
    }
  });
  const subscriptionPreviewMonthlyRes = useQuery(subscriptionPreviewQuery, {
    skip: Boolean(membersSet.size === 0 || !plan),
    fetchPolicy: 'cache-and-network',
    variables: {
      subscriptionArgs: {
        ...subscriptionArgs,
        billingPeriod: 'MONTHLY'
      }
    },
    onCompleted: data => {
      const netPrice = data?.subscriptionPreview?.netPrice;
      setMonthlyPricePerUserOnMonthly(netPrice / membersSet.size);
    }
  });

  const currentMonthlyPricePerUser = React.useMemo(() => {
    return isYearly
      ? monthlyPricePerUserOnAnnual
      : monthlyPricePerUserOnMonthly;
  }, [isYearly, monthlyPricePerUserOnAnnual, monthlyPricePerUserOnMonthly]);

  const subscriptionPreviewLoading =
    subscriptionPreviewMonthlyRes.loading ||
    subscriptionPreviewYearlyRes.loading;

  const [yearlyPriceDiff, setYearlyPriceDiff] = React.useState(0);
  React.useEffect(() => {
    if (subscriptionPreviewLoading) return setYearlyPriceDiff(0);

    const subscriptionPreviewMonthly =
      subscriptionPreviewMonthlyRes?.data?.subscriptionPreview;
    const subscriptionPreviewYearly =
      subscriptionPreviewYearlyRes?.data?.subscriptionPreview;

    const diff =
      subscriptionPreviewMonthly?.netPrice * 12 -
      subscriptionPreviewYearly?.netPrice;
    setYearlyPriceDiff(diff);
  }, [
    subscriptionPreviewLoading,
    subscriptionPreviewYearlyRes,
    subscriptionPreviewMonthlyRes
  ]);

  React.useEffect(() => {
    if (workspaceRes.workspace?.users.length > 0 && !membersSet.size) {
      const allUsers = workspaceRes.workspace.users;
      const usersToSet = allUsers.filter(user => {
        if (location.state?.usersToSelect) {
          return location.state.usersToSelect.includes(user.id);
        }
        if (onlyShowFreeMembers) {
          return !user.onPlan;
        }
        return true;
      });

      setMembersSet(new Set(usersToSet));
    }
  }, [
    workspaceRes.workspace,
    setMembersSet,
    membersSet,
    onlyShowFreeMembers,
    location
  ]);

  const value = React.useMemo(() => {
    const subscriptionPreviewMonthly =
      subscriptionPreviewMonthlyRes?.data?.subscriptionPreview;
    const subscriptionPreviewYearly =
      subscriptionPreviewYearlyRes?.data?.subscriptionPreview;
    const subscriptionPreviewCurrent = isYearly
      ? subscriptionPreviewYearly
      : subscriptionPreviewMonthly;

    return {
      plan,
      planSku,
      setPlanSku,
      isYearly,
      setIsYearly,
      promoCode,
      setPromoCode,
      membersSet,
      setMembersSet,
      workspaceRes,
      subscriptionArgs,
      yearlyPriceDiff,
      subscriptionPreviewMonthly,
      subscriptionPreviewYearly,
      subscriptionPreviewCurrent,
      currentMonthlyPricePerUser,
      subscriptionPreviewLoading,
      subscriptionPreviewError:
        subscriptionPreviewMonthlyRes.error ||
        subscriptionPreviewYearlyRes.error,
      omitPlanMembers: omitPlanMembers === 'true',
      workspacePlan,
      workspacePlanLoading,
      source
    };
  }, [
    plan,
    planSku,
    setPlanSku,
    isYearly,
    setIsYearly,
    promoCode,
    setPromoCode,
    membersSet,
    setMembersSet,
    workspaceRes,
    subscriptionArgs,
    yearlyPriceDiff,
    currentMonthlyPricePerUser,
    subscriptionPreviewLoading,
    subscriptionPreviewMonthlyRes,
    subscriptionPreviewYearlyRes,
    omitPlanMembers,
    workspacePlan,
    workspacePlanLoading,
    source
  ]);

  return (
    <SubscribeInfoContext.Provider value={value}>
      {children}
    </SubscribeInfoContext.Provider>
  );
}
