import { css } from '@emotion/react';
import { Icon, getBasePublicPath, StepsIndicator } from '@grain/grain-ui';
import {
  LeftSide,
  RightSide,
  Title,
  Subtitle,
  Description,
  Buttons,
  SlideButton,
  StepComponentProps,
  StyledBullet,
  BulletDescription,
  StyledOverlay
} from '../../../modals/LegacyOnboarding';

export const bulletIconStyles = css`
  min-height: 20px;
  min-width: 20px;
`;

const bullets = [
  {
    icon: <Icon.VideoRecorder css={[bulletIconStyles]} />,
    description: (
      <>
        <span>Unlimited meetings</span> with AI notes for your team
      </>
    )
  },
  {
    icon: <Icon.Star06 css={[bulletIconStyles]} />,
    description: (
      <>
        Coach like a champ with AI feedback, insights, and{' '}
        <span>coaching alerts</span>
      </>
    )
  },
  {
    icon: <Icon.BellFilled css={[bulletIconStyles]} />,
    description: (
      <>
        Save deals with trackers, keyword alerts, and Grain's{' '}
        <span>deal board</span>
      </>
    )
  },
  {
    icon: <Icon.DataFlow css={[bulletIconStyles]} />,
    description: (
      <>
        Automate meeting ops with <span>CRM integrations</span> and AI-powered
        follow-up emails
      </>
    )
  }
];

const BusinessTrial = ({
  totalSteps,
  step,
  showSetupComplete,
  handleNext,
  handleDone,
  onlyTrial
}: StepComponentProps) => (
  <>
    <LeftSide width={378}>
      <div>
        {showSetupComplete && <Subtitle>Setup complete</Subtitle>}
        <Title>
          Welcome to <br />
          your free 14-day Grain Business trial
        </Title>
        <Description>
          Record all your team's meetings to coach your reps and close more
          deals.{' '}
          <a
            href='https://grain.com/blog/support-sales-team'
            target='_blank'
            rel='noreferrer'
          >
            <span css={['text-decoration: underline;']}>Learn more.</span>
          </a>
        </Description>
      </div>
      <Buttons>
        <SlideButton
          variant='primary'
          size='large'
          onClick={step === totalSteps - 1 ? handleDone : handleNext}
        >
          {step === totalSteps - 1 ? 'Done' : 'Continue'}
        </SlideButton>
      </Buttons>
    </LeftSide>
    <RightSide
      background={`${getBasePublicPath()}/images/noise-and-texture.png`}
      width={542}
    >
      <StyledOverlay>
        {bullets.map(({ icon, description }, index) => (
          <StyledBullet key={index}>
            {icon}
            <BulletDescription>{description}</BulletDescription>
          </StyledBullet>
        ))}
      </StyledOverlay>
      {!onlyTrial && (
        <div className='steps-indicator'>
          <StepsIndicator steps={totalSteps} step={step} />
        </div>
      )}
    </RightSide>
  </>
);

export default BusinessTrial;
