import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import type { OnboardingSurveyAnswers } from '@grain/api/schema.extra';
import { useExperiment, useMyself, useWorkspace } from '@grain/api/auth';
import { FTUX_SLIDESHOW_ROUTE_ID } from '@grain/components/overlays/constants';
import { getBasePublicPath, media, useRouteOverlay } from '@grain/grain-ui';
import { Button, Icon16, theme } from '@grain/grain-ui/v4';

import { type SampleQuery } from '~/modules/contentFilter/contentfilter.generated';
import { getIsOnPaidPlan } from '~/support/getIsOnPaidPlan';
import { ImageEmptyView } from '~/components/ImageEmptyView';
import { inMediumToLargeSizedCompany } from '~/pages/Onboarding/ChooseRole';
import { getCalendlyUrl } from '~/pages/Onboarding/Calendly/hooks';
import { MeetingTabId } from '../types';

type EmptyViewProps = {
  section: MeetingTabId;
};

const title = 'No meetings here, yet';

const descriptions: Record<MeetingTabId, string> = {
  'all-meetings': 'All meetings you have access to will show up here.',
  'my-meetings': 'All the meetings you were part of will show up here.',
  shared: 'All the meetings shared with you will show up here.'
};

const Content = ({ section }: EmptyViewProps) => {
  const description = descriptions[section];

  const { myself } = useMyself();
  const { workspace } = useWorkspace();

  const isOnPaidPlan = getIsOnPaidPlan(workspace);
  const calendlyUrl = myself
    ? getCalendlyUrl(isOnPaidPlan, myself.user.email)
    : '';

  const hasWorkEmailDomains = workspace?.hasWorkEmailDomains ?? false;
  const onboardingSurveyAnswers = myself
    ? (myself.onboardingSurveyAnswers as OnboardingSurveyAnswers)
    : undefined;
  const canBookOnboarding = onboardingSurveyAnswers
    ? inMediumToLargeSizedCompany(onboardingSurveyAnswers)
    : false;

  const showScheduleOnboardingCall = hasWorkEmailDomains && canBookOnboarding;
  const showAddGrainToLiveMeeting = myself?.user.onPlan ?? false;

  return (
    <ImageEmptyView.Wrapper css={['max-width: 514px;']}>
      <ImageEmptyView.Image
        css={['max-width: 482px;']}
        src={`${getBasePublicPath()}/images/zero-states/meetings.png`}
      />
      <ImageEmptyView.InnerWrapper>
        <ImageEmptyView.Title>{title}</ImageEmptyView.Title>
        <ImageEmptyView.Description>{description}</ImageEmptyView.Description>
        {(showScheduleOnboardingCall || showAddGrainToLiveMeeting) && (
          <ImageEmptyView.Actions>
            {showScheduleOnboardingCall && (
              <Button
                as='a'
                href={calendlyUrl}
                target='_blank'
                rel='noopener noreferrer'
                size='xl'
                variant='stroked'
                textLabelProps={{ startIcon: Icon16.ExternalLink }}
              >
                Schedule onboarding call
              </Button>
            )}
            {showAddGrainToLiveMeeting && (
              <Button
                as={Link}
                to='/app/calendar'
                size='xl'
                variant='stroked'
                textLabelProps={{ startIcon: Icon16.Plus }}
              >
                Add Grain to live meeting
              </Button>
            )}
          </ImageEmptyView.Actions>
        )}
      </ImageEmptyView.InnerWrapper>
    </ImageEmptyView.Wrapper>
  );
};

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - ${2 * parseFloat(theme.tokens.spacing['3xl'])}px);
  margin-right: ${theme.tokens.spacing['3xl']};
  margin-bottom: 52px;
  margin-left: ${theme.tokens.spacing['3xl']};
  border-top: 1px solid ${theme.tokens.color.borderTertiary};
  padding-top: ${theme.tokens.spacing['3xl']};

  ${media.small} {
    width: calc(100% - ${2 * parseFloat(theme.tokens.spacing.lg)}px);
    margin-right: ${theme.tokens.spacing.lg};
    margin-bottom: ${theme.tokens.spacing.lg};
    margin-left: ${theme.tokens.spacing.lg};
    padding-top: ${theme.tokens.spacing.lg};
  }
`;

const BannerTitle = styled.div`
  margin-bottom: ${theme.tokens.spacing.md};
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textSecondary};
`;

const BannerCardRow = styled.div`
  display: flex;

  @media (min-width: 1120px) {
    flex-direction: row;
    gap: ${theme.tokens.spacing['3xl']};

    > * {
      width: calc(50% - ${parseFloat(theme.tokens.spacing['3xl']) / 2}px);
    }
  }

  @media (max-width: 1119px) {
    flex-direction: column;
    gap: ${theme.tokens.spacing.lg};

    > * {
      width: 100%;
    }
  }
`;

const Card = styled.div`
  display: inline-flex;
  gap: 10px;
  padding: 14px ${theme.tokens.spacing['2xl']} 14px ${theme.tokens.spacing.lg};
  border-radius: ${theme.tokens.radii.xl};
  border: 1px solid ${theme.tokens.color.borderTertiary};
  background: ${theme.tokens.color.surfaceSecondary};
  cursor: pointer;

  &:focus-visible,
  &:hover {
    border-color: ${theme.tokens.color.borderSecondary};
    background: ${theme.tokens.color.surfacePrimary};
  }
`;

const CardThumbnailAndText = styled.div`
  flex: 1;
  display: flex;
  gap: ${theme.tokens.spacing.lg};
  align-items: center;
`;

const CardThumbnail = styled.img`
  border-radius: ${theme.tokens.radii.sm};
  width: 110px;
  height: 58px;
  object-fit: cover;
  background: ${theme.tokens.color.buttonNeutralDefault};
  color: transparent; // Hide alt text if the image doesn't load
`;

const CardText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing['2xs']};
`;

const CardTitle = styled.div`
  ${theme.tokens.typography.b2[500]};
  color: ${theme.tokens.color.textPrimary};
`;

const CardBody = styled.div`
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textTertiary};
`;

const CardButtonColumn = styled.div`
  display: flex;
  align-items: center;
`;

type BannerCardProps = {
  thumbnailAlt: string;
  thumbnailUrl: string;
  title: string;
  body: string;
  onClick: () => void;
};

const BannerCard = ({
  thumbnailAlt,
  thumbnailUrl,
  title,
  body,
  onClick
}: BannerCardProps) => {
  return (
    <Card tabIndex={0} onClick={onClick}>
      <CardThumbnailAndText>
        <CardThumbnail alt={thumbnailAlt} src={thumbnailUrl} />
        <CardText>
          <CardTitle>{title}</CardTitle>
          <CardBody>{body}</CardBody>
        </CardText>
      </CardThumbnailAndText>
      <CardButtonColumn>
        <Button
          tabIndex={-1}
          size='xl'
          variant='neutral'
          textLabelProps={{ startIcon: <Icon16.PlayHollow /> }}
        />
      </CardButtonColumn>
    </Card>
  );
};

type BannerProps = {
  sampleRecording?: SampleQuery['recordingSample'];
};

const Banner = ({ sampleRecording }: BannerProps) => {
  const navigate = useNavigate();
  const { open: openFtuxSlideshow } = useRouteOverlay(FTUX_SLIDESHOW_ROUTE_ID);
  const { enabled: ftuxSlideshowEnabled } = useExperiment('ftux_slideshow');
  if (!sampleRecording && !ftuxSlideshowEnabled) return;
  return (
    <BannerContainer>
      <BannerTitle>Getting started</BannerTitle>
      <BannerCardRow>
        {ftuxSlideshowEnabled && (
          <BannerCard
            thumbnailAlt='Thumbnail of a web-based meeting taking place with a Grain notification appearing on the user’s desktop'
            thumbnailUrl={`${getBasePublicPath()}/images/ftux-slideshow-thumbnail.png`}
            title='Check out how a meeting looks like with Grain'
            body='Know what to expect before, during, and after meetings.'
            onClick={openFtuxSlideshow}
          />
        )}
        {sampleRecording && (
          <BannerCard
            thumbnailAlt='Thumbnail of Grain’s “Getting Started” video, featuring a member of Grain’s staff showing you how to use the product'
            thumbnailUrl={sampleRecording.fullJpegThumbnailUrl || ''}
            title='Explore a meeting recorded with Grain'
            body='Learn how to get the most out of your meetings.'
            onClick={() => navigate(sampleRecording.recordingPath)}
          />
        )}
      </BannerCardRow>
    </BannerContainer>
  );
};

export const EmptyMeetingsState = {
  Content,
  Banner
};
