import React from 'react';
import { useRecoilState } from 'recoil';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import copy from 'clipboard-copy';
import { useApolloClient } from '@apollo/client';
import { color, spacing } from '@grain/styles/constants';
import { useMutation } from '@grain/api/graphql';
import {
  storyUpdateMutation,
  storyDeleteMutation
} from '~/modules/stories/graphql';
import { cacheDeleteStory } from '@grain/api/graphql/cache-helpers';
import { Tooltip } from '@grain/grain-ui/v4';
import {
  Icon,
  useConfirm,
  useAnalytics,
  Dropdown,
  MenuItem
} from '@grain/grain-ui';
import IconButton from '~/components/IconButton';
import { useStoryToast } from '~/pages/StoryPage/hooks';
import { storyState } from '../../state';
import CoverPicker from './CoverPicker';
import {
  TITLE_CHAR_LIMIT,
  DESCRIPTION_CHAR_LIMIT
} from '~/pages/StoryPage/constants';
import StoryTagSelector from '../../StoryTagSelector';
import {
  downloadFromUrl,
  scrollIntoViewIfNeeded
} from '@grain/components/support/browser';
import useMeasure from 'react-use/lib/useMeasure';
import {
  STICKY_HEIGHT,
  StyledSticky,
  StyledButtonsContainer,
  StyledTitleWrapper,
  StyledTitleContent,
  StyledTitleInlineInput,
  StyledCharCounter,
  StyledDescriptionTextarea,
  StyledDescriptionContainer,
  StyledPillContainer,
  StyledCodeIcon
} from './styles';

export const getStoryTitleEl = () => document.getElementById('story-title');

export default function StoryTitle() {
  const location = useLocation();
  const navigate = useNavigate();
  const showToast = useStoryToast();
  const showConfirm = useConfirm();
  const client = useApolloClient();
  const [searchParams] = useSearchParams();
  const [story, setStory] = useRecoilState(storyState);
  const [title, setTitle] = React.useState(story.title);
  const [isTitleFocused, setTitleFocused] = React.useState(false);
  const [isDescriptionFocused, setDescriptionFocused] = React.useState(false);
  const [showCoverPicker, setShowCoverPicker] = React.useState(false);
  const [description, setDescription] = React.useState(story.description ?? '');
  const [titleMeasureRef, titleRect] = useMeasure();
  const { trackEvent } = useAnalytics();

  const titleMeasureHeight = titleRect.height;
  const titleHeight = React.useMemo(() => {
    const titleEl = document.querySelector('#story-title');
    if (!titleEl) return;
    const paddingTop = window
      .getComputedStyle(titleEl)
      .getPropertyValue('padding-top');
    const paddingBottom = window
      .getComputedStyle(titleEl)
      .getPropertyValue('padding-bottom');
    return titleMeasureHeight + parseInt(paddingTop) + parseInt(paddingBottom);
  }, [titleMeasureHeight]);

  const prevStoryRef = React.useRef();
  const [deleteStory] = useMutation(storyDeleteMutation, {
    variables: { storyId: story.id },
    update() {
      cacheDeleteStory(client, { storyId: story.id });
    }
  });

  // Select title input on story create
  const titleInputContainerRef = React.useRef();
  React.useEffect(() => {
    if (!titleInputContainerRef?.current) return;
    const inputEl = titleInputContainerRef.current.querySelector('input');
    if (!inputEl) return;

    const isInit = searchParams.has('init');
    if (isInit) {
      searchParams.delete('init');
      navigate(location.pathname, { replace: true });
      inputEl.focus();
      inputEl.select();
    }
  }, [searchParams, location, navigate]);

  const [updateStory] = useMutation(storyUpdateMutation, {
    onError() {
      if (prevStoryRef.current) {
        setStory(prevStoryRef.current);
        prevStoryRef.current = null;
      }
    }
  });

  const handleSaveTitle = title => {
    if (title?.length > TITLE_CHAR_LIMIT || title === story.title) {
      return;
    }

    updateStory({
      variables: {
        storyId: story.id,
        title
      }
    });
    prevStoryRef.current = story;
    setStory(story => ({
      ...story,
      title
    }));

    showToast({
      content: 'Story title saved'
    });
  };

  const handleSaveDescription = description => {
    // Don't save unnecessarily
    if (
      description?.length > DESCRIPTION_CHAR_LIMIT ||
      description === story.description ||
      (description === '' && story.description === null)
    ) {
      return;
    }
    updateStory({
      variables: {
        storyId: story.id,
        description
      }
    });
    prevStoryRef.current = story;
    setStory(story => ({
      ...story,
      description
    }));
    showToast({
      content: 'Story description saved'
    });
  };

  const handleCopyIframe = React.useCallback(
    event => {
      if (!story.iframeHtml) return;

      copy(story.iframeHtml)
        .then(() => {
          showToast({
            content: 'Story iframe copied to clipboard',
            type: 'success',
            uniqueId: 'copied_story_iframe'
          });
        })
        .catch(() => {
          showToast({
            content: 'Oops, try again.',
            type: 'failure'
          });
        });
    },
    [story.iframeHtml, showToast]
  );

  async function handleDeleteStory() {
    const result = await showConfirm({
      width: 400,
      confirmContent: 'Delete',
      description: 'This will permanently delete the Story.',
      title: `Are you sure you want to delete ${title}?`
    });
    if (result.isConfirm) {
      showToast({
        content: 'Story has been deleted',
        type: 'success'
      });
      deleteStory();
      navigate('/app/stories', { replace: true });
    }
  }

  const handleDownloadClipsInStory = React.useCallback(() => {
    if (!story.downloadUrl) return;

    downloadFromUrl(story.downloadUrl);

    trackEvent(
      'Media Downloaded',
      {
        trigger: 'story_page'
      },
      ['user', 'workspace', `story:${story.id}`]
    );
  }, [story.downloadUrl, story.id, trackEvent]);

  return (
    <>
      {showCoverPicker && (
        <CoverPicker onClose={() => setShowCoverPicker(false)} />
      )}
      <div
        className='title-buffer'
        css={['flex-shrink: 0']}
        style={{ height: titleHeight - STICKY_HEIGHT }}
      />
      <StyledSticky
        titleHeight={titleHeight}
        bannerImageUrl={story.bannerImageUrl}
        onClick={() => {
          scrollIntoViewIfNeeded(getStoryTitleEl());
        }}
      >
        <div className='image' />
        <div className='mask' />
        <div className='title fs-block'>{story.title}</div>
      </StyledSticky>
      <StyledTitleWrapper
        ref={titleMeasureRef}
        id='story-title'
        bannerImageUrl={story.bannerImageUrl}
      >
        <div className='image' />
        <div className='mask' />
        <div className='fs-block' css={['display: flex; align-items: center;']}>
          <StyledTitleContent>
            <div ref={titleInputContainerRef} className='title-wrapper'>
              <StyledTitleInlineInput
                className='fs-block'
                key={story.title}
                maxLength={TITLE_CHAR_LIMIT}
                defaultValue={story.title}
                onChange={() => handleSaveTitle(title)}
                inputProps={{
                  onChange: ev => setTitle(ev.target.value),
                  onFocus: () => setTitleFocused(true),
                  onBlur: () => setTitleFocused(false),
                  'data-cy': 'story-title'
                }}
              />
              {(isTitleFocused || title?.length > TITLE_CHAR_LIMIT) && (
                <StyledCharCounter
                  css={['flex-shrink: 0;']}
                  isMax={title?.length > TITLE_CHAR_LIMIT}
                >
                  {title?.length}/{TITLE_CHAR_LIMIT}
                </StyledCharCounter>
              )}
            </div>
            {/* The mix of padding and margin is to make room for the scroll arrows inside. */}
            <StyledPillContainer>
              <StoryTagSelector className='fs-block' story={story} />
            </StyledPillContainer>
          </StyledTitleContent>
          <StyledButtonsContainer>
            <Tooltip
              tippyProps={{ trigger: 'mouseenter' }}
              content='Choose cover background'
            >
              <div>
                <IconButton onClick={() => setShowCoverPicker(true)}>
                  <Icon.Camera css={['width: 20px; height: 20px;']} />
                </IconButton>
              </div>
            </Tooltip>
            <div css={[spacing.mt2]} />
            <Dropdown
              appendTo={document.body}
              tippyProps={{ placement: 'bottom' }}
              targetElement={
                <div>
                  <IconButton>
                    <Icon.MenuMore css={['width: 20px; height: 20px;']} />
                  </IconButton>
                </div>
              }
            >
              {story.downloadUrl && (
                <MenuItem
                  label='Download all clips in story'
                  icon={Icon.Download}
                  onClick={() => handleDownloadClipsInStory()}
                />
              )}
              {story.iframeHtml && (
                <MenuItem
                  label='Copy embed iframe'
                  icon={StyledCodeIcon}
                  onClick={handleCopyIframe}
                />
              )}
              <MenuItem
                label='Delete story'
                icon={Icon.Delete}
                onClick={handleDeleteStory}
                css={[color.errorbird]}
              />
            </Dropdown>
          </StyledButtonsContainer>
        </div>
        <StyledDescriptionContainer>
          <StyledDescriptionTextarea
            data-cy='story-description'
            value={description}
            onChange={e => setDescription(e.target.value)}
            placeholder='Type a description for your story...'
            onKeyDown={e => {
              if (e.key === 'Enter') e.target.blur();
            }}
            onBlur={e => {
              handleSaveDescription(e.target.value);
              setDescriptionFocused(false);
            }}
            onFocus={() => setDescriptionFocused(true)}
          />
          <StyledCharCounter
            isMax={description?.length > DESCRIPTION_CHAR_LIMIT}
            css={[
              `visibility: ${
                isDescriptionFocused ||
                description?.length > DESCRIPTION_CHAR_LIMIT
                  ? 'visible'
                  : 'hidden'
              };`
            ]}
          >
            {description?.length}/{DESCRIPTION_CHAR_LIMIT}
          </StyledCharCounter>
        </StyledDescriptionContainer>
      </StyledTitleWrapper>
    </>
  );
}
