import {
  TokenizedInput,
  TokenizedInputProps,
  useClickedOutside
} from '@grain/grain-ui';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { FilterSuggestions } from './FilterSuggestions';
import styled from '@emotion/styled';
import {
  Token,
  TokenizedInputRef
} from 'components/molecules/TokenizedInput/TokenizedInput';
import {
  NormalizedActiveFilter,
  useSearchSuggestions
} from '~/pages/Search/state';

const FILTER_SUGGESTIONS_LAYER_INDEX = 3;
const TOKENIZED_INPUT_LAYER_INDEX = FILTER_SUGGESTIONS_LAYER_INDEX + 1;

const Container = styled.div`
  position: relative;
  max-width: 100%;
  width: 800px;
`;

const StyledTokenizedInput = styled(TokenizedInput)`
  position: relative;
  z-index: ${TOKENIZED_INPUT_LAYER_INDEX};
`;

const StyledFilterSuggestions = styled(FilterSuggestions)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-height: 400px;
  height: 0;
  opacity: 0;
  overflow: auto;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
  z-index: ${FILTER_SUGGESTIONS_LAYER_INDEX};

  &.open {
    height: auto;
    opacity: 1;
  }
`;

type SearchWithFilterSuggestionsProps = TokenizedInputProps & {
  normalizedActiveFilters: NormalizedActiveFilter[];
  filters: string;
  onAddSuggestion: (key: string, label: string, value: string) => void;
};

const filterToToken = (filter: NormalizedActiveFilter): Token => {
  return {
    name: filter.filterLabel,
    key: filter.filterKey,
    values: filter.values.map(value => {
      return {
        value: value.value,
        text: value.label
      };
    })
  };
};

export const SearchWithFilterSuggestions = forwardRef<
  TokenizedInputRef,
  SearchWithFilterSuggestionsProps
>(
  (
    {
      normalizedActiveFilters,
      filters,
      onAddSuggestion,
      ...tokenizedInputProps
    },
    tokenizedInputRef
  ) => {
    const [comboboxOpen, setComboboxOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [search, setSearch] = useState('');
    const { suggestions, hasUsedModifiers } = useSearchSuggestions(
      search,
      filters,
      normalizedActiveFilters
    );

    const showSearchString = !hasUsedModifiers || suggestions.length === 0;

    const containerRef = useClickedOutside<HTMLDivElement>(() =>
      setComboboxOpen(false)
    );

    const tokens: Token[] = useMemo(
      () => normalizedActiveFilters.map(filterToToken).filter(Boolean),
      [normalizedActiveFilters]
    );

    useEffect(() => {
      setSelectedIndex(0);
      setComboboxOpen(search.length > 0);
    }, [search]);

    useEffect(() => {
      setSelectedIndex(0);
    }, [suggestions]);

    const handleAddSuggestion = useCallback(
      (index: number) => {
        const selectedSuggestion =
          suggestions[index - (showSearchString ? 1 : 0)];
        if (selectedSuggestion) {
          onAddSuggestion(
            selectedSuggestion.filterKey,
            selectedSuggestion.valueLabel,
            selectedSuggestion.valueId
          );

          if (tokenizedInputRef && 'current' in tokenizedInputRef) {
            tokenizedInputRef.current?.onSuggestionAdded(
              selectedSuggestion.valueLabel,
              selectedSuggestion.filterRegex
            );
          }
        }
      },
      [onAddSuggestion, suggestions, tokenizedInputRef, showSearchString]
    );

    useEffect(() => {
      const handleKeyDown = (e: KeyboardEvent) => {
        const firstSuggestionIndex = showSearchString ? 1 : 0;
        if (comboboxOpen) {
          switch (e.key) {
            case 'ArrowDown':
              e.preventDefault();
              setSelectedIndex(prevIndex =>
                Math.min(prevIndex + 1, suggestions.length)
              );
              break;
            case 'ArrowUp':
              e.preventDefault();
              setSelectedIndex(prevIndex => Math.max(0, prevIndex - 1));
              break;
            case 'Home':
              e.preventDefault();
              setSelectedIndex(0);
              break;
            case 'End':
              e.preventDefault();
              setSelectedIndex(suggestions.length);
              break;
            case 'Escape':
              e.preventDefault();
              setComboboxOpen(false);
              break;
            case 'Enter':
              setComboboxOpen(false);
              if (selectedIndex >= firstSuggestionIndex) {
                e.preventDefault();
                e.stopPropagation();
                handleAddSuggestion(selectedIndex);
              }
              break;
            case 'Tab':
              e.preventDefault();
              e.stopPropagation();
              setSelectedIndex(prevIndex =>
                prevIndex < suggestions.length
                  ? Math.min(prevIndex + 1, suggestions.length)
                  : 0
              );
              break;
          }
        }
      };

      const input =
        tokenizedInputRef && 'current' in tokenizedInputRef
          ? tokenizedInputRef?.current?.getInputRef()
          : null;

      if (input) {
        input.addEventListener('keydown', handleKeyDown, { capture: true });
      }

      return () => {
        if (input) {
          input.removeEventListener('keydown', handleKeyDown, {
            capture: true
          });
        }
      };
    }, [
      tokenizedInputRef,
      selectedIndex,
      handleAddSuggestion,
      suggestions,
      comboboxOpen,
      showSearchString
    ]);

    return (
      <Container ref={containerRef}>
        <StyledTokenizedInput
          {...tokenizedInputProps}
          tokens={tokens}
          onSearch={setSearch}
          ref={tokenizedInputRef}
        />
        <StyledFilterSuggestions
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          className={comboboxOpen ? 'open' : ''}
          search={search}
          normalizedActiveFilters={normalizedActiveFilters}
          newFilters={suggestions}
          onSelect={(selectedIndex, searchString) => {
            setComboboxOpen(false);
            const firstSuggestionIndex = showSearchString ? 1 : 0;
            if (selectedIndex >= firstSuggestionIndex) {
              handleAddSuggestion(selectedIndex);
            } else if (searchString) {
              tokenizedInputProps.onEnter(searchString);
            }
          }}
          showSearchString={showSearchString}
        />
      </Container>
    );
  }
);
