import { useWorkspace } from '@grain/api/auth';
import { useMutation } from '@grain/api/graphql';
import { subscriptionCancellationDiscountApply } from '@grain/api/graphql/mutations';
import { CancelStepsProps } from './CancelTypes';
import {
  StyledContent,
  ProgressBarContainer,
  StyledProgressBar,
  StyledTitleContainer,
  InfoBoxWrapper,
  StyledButtons,
  InfoTextWrapper,
  OfferWrapper,
  PageWrapper
} from './styles';
import { Button, Icon, useAnalytics } from '@grain/grain-ui';

import React, { useEffect } from 'react';
import { displayCents } from '../../support/price';
import {
  workspaceMembersQuery,
  subscriptionPlansQuery
} from '@grain/api/graphql/queries';
import { PlanFeaturesDocument } from '@grain/api/graphql/queries/planFeatures.generated';

export const OfferPage = ({
  step = 2,
  totalSteps = 3,
  nextStep,
  netPrice,
  renewalPrice,
  prevStep,
  setHasRenewed,
  closeModal
}: CancelStepsProps) => {
  const [subscriptionCancellationDiscount, { loading }] = useMutation(
    subscriptionCancellationDiscountApply,
    {
      onCompleted: () => {
        setHasRenewed(true);
      }
    }
  );
  const { trackEvent } = useAnalytics();
  useEffect(() => {
    trackEvent('Save Offer Viewed', {});
  }, [trackEvent]);
  const { workspace } = useWorkspace();
  const isYearly = workspace?.workspaceSubscription?.billingPeriod === 'YEARLY';
  return (
    <PageWrapper>
      <StyledContent>
        <ProgressBarContainer>
          <StyledProgressBar
            type='progress'
            progressTheme='highlightGreenDark'
            size='medium'
            percent={(step / totalSteps) * 100}
          />
          <span>
            {' '}
            {step} of {totalSteps}{' '}
          </span>
          <span onClick={closeModal}>
            {' '}
            <Icon.Close />{' '}
          </span>
        </ProgressBarContainer>
        <StyledTitleContainer>
          <h1>Let’s find a better solution.</h1>
          <p>
            Keep access to your meetings and all the benefits of using Grain at
            a lower cost.
          </p>
        </StyledTitleContainer>
        <OfferWrapper>
          {!isYearly ? (
            <Icon.FortyPercentOffer />
          ) : (
            <Icon.TwentyFivePercentOffer />
          )}
          <InfoBoxWrapper>
            <InfoTextWrapper>
              <h6>
                {!isYearly
                  ? `Save $${displayCents(
                      (renewalPrice ?? 0) - (netPrice ?? 0)
                    )}/mo for 6 months to stay with Grain`
                  : `Save $${displayCents(
                      (renewalPrice ?? 0) - (netPrice ?? 0)
                    )} off your next annual plan to stay with Grain`}{' '}
              </h6>
              <p>
                {isYearly ? (
                  <>
                    Your annual cost: ${displayCents(renewalPrice)}
                    /year <span>-{'>'} </span> ${displayCents(netPrice)}/year
                  </>
                ) : (
                  <>
                    Your monthly cost: ${displayCents(renewalPrice)}/month{' '}
                    <span>-{'>'}</span> ${displayCents(netPrice)}/month
                  </>
                )}
              </p>
            </InfoTextWrapper>
          </InfoBoxWrapper>
        </OfferWrapper>
      </StyledContent>
      <div style={{ display: 'flex', width: '100%', marginTop: 'auto' }}>
        <StyledButtons>
          <Button variant='stealth' onClick={nextStep}>
            No thanks
          </Button>
        </StyledButtons>
        <StyledButtons style={{ marginLeft: 'auto' }}>
          <Button variant='secondary' onClick={prevStep}>
            Back
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              subscriptionCancellationDiscount({
                refetchQueries: [
                  {
                    query: workspaceMembersQuery,
                    fetchPolicy: 'network-only'
                  },
                  {
                    query: PlanFeaturesDocument,
                    fetchPolicy: 'network-only'
                  },
                  { query: subscriptionPlansQuery, fetchPolicy: 'network-only' }
                ]
              });
            }}
            spinning={loading}
          >
            Accept offer
          </Button>
        </StyledButtons>
      </div>
    </PageWrapper>
  );
};
