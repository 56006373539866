import { Helmet } from 'react-helmet';

import * as Layout from '~/components/Layout';
import { SearchInputContainer } from './components/SearchInputContainer';
import {
  searchScreenState,
  useSearchResultsWithFilters,
  useSearchString,
  useSearchTranscriptsFlag
} from './state';
import { Divider, Switch, color, media, spacing, Icon } from '@grain/grain-ui';
import { useEffect, useState } from 'react';
import { RecentSearchItems } from './components/RecentSearchItems';
import { Results } from './components/Results';
import { HubspotModal } from '../Settings/Integrations/providers/Hubspot';
import { useRecoilState } from 'recoil';
import styled from '@emotion/styled';
import SkeletonRecordingSearchItem from '~/components/RecordingSearchItem/SkeletonRecordingSearchingItem';
import { css } from '@emotion/react';
import { TextRow } from './components/TextRow';
import { RecordingBulkActions } from '~/components/RecordingBulkActions/RecordingBulkActions';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@grain/components/support/browser';
import {
  Button,
  Icon16,
  Menu,
  MenuButton,
  MenuHeader,
  PageHeader,
  theme
} from '@grain/grain-ui/v4';
import { SearchSortBy } from '@grain/api/schema.generated';
import { FilterBar } from '~/modules/filtersV2/FilterBar/FilterBar';
import { isDesktopApp } from '@grain/desktop-lib';

export const StyledContentFilterContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  ${theme.utils.px('lg')}
  ${spacing.py3}

  ${media.small} {
    flex-direction: column;
    gap: 16px;
  }

  @media (${theme.tokens.breakpoints.lgMin}) {
    ${spacing.px7}
  }
`;

const StyledFilterBar = styled(FilterBar)`
  flex: 1;
  padding: 0;
  min-height: 0;
`;

const StyledMenuButton = styled(MenuButton)<{ isSelected: boolean }>`
  ${props => !props.isSelected && 'padding-left: 30px'};
  ${props =>
    props.isSelected && `color: ${theme.tokens.color.textBrand} !important`};
  ${props => props.disabled && 'cursor: default !important'};
`;

const MultipleSearchSkeletons = Array.from({ length: 5 }, (_, index) => (
  <SkeletonRecordingSearchItem key={index} />
));

const EMPTY_ARRAY: string[] = [];

export default function Search() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    results,
    totalCount,
    fetchMore,
    searching,
    filters,
    normalizedActiveFilters,
    removeFilterByName,
    resetFilters,
    filterJsonAsString,
    sortBy,
    setSortBy
  } = useSearchResultsWithFilters();
  const { toggleCollapsed } = Layout.useSidebar();
  const [referrer, setReferrer] = useState<string | null>(null);
  const [screen, setScreen] = useRecoilState(searchScreenState);
  const [searchString] = useSearchString();
  const [showHubspotModal, setShowHubspotModal] = useState(false);
  const [searchTranscriptsFlag, setSearchTranscriptsFlag] =
    useSearchTranscriptsFlag();
  const isSmallScreen = useMediaQuery(media.small);

  const [selectedRecordingIds, setSelectedRecordingIds] =
    useState<string[]>(EMPTY_ARRAY);

  useEffect(() => {
    if (location.state?.referrer) {
      setReferrer(location.state.referrer);
    }
  }, [location.state]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (document.activeElement?.tagName === 'INPUT') {
          const inputElement = document.activeElement as HTMLInputElement;
          inputElement.blur();
          return;
        }
        if (referrer) {
          navigate(referrer);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate, location.state, referrer]);

  useEffect(() => {
    setSelectedRecordingIds([]);
  }, [searchString]);

  useEffect(() => {
    if (searching) {
      setScreen('searching');
    } else if (
      (searchString || normalizedActiveFilters.length > 0) &&
      results &&
      results.length > 0
    ) {
      setScreen('results');
    } else if (
      (searchString || normalizedActiveFilters.length > 0) &&
      !results?.length
    ) {
      setScreen('no_results');
    } else if (!searchString && normalizedActiveFilters.length === 0) {
      setScreen('recent_items');
    }
  }, [searching, results, setScreen, searchString, normalizedActiveFilters]);

  return (
    <>
      <Helmet title='Search' />
      <Layout.Wrapper withSidebar>
        <Layout.Content
          css={css`
            overflow: hidden;
          `}
        >
          {isSmallScreen && (
            <PageHeader
              icon={Icon16.Search}
              title='Search'
              onMenuClick={toggleCollapsed}
              isDesktopApp={isDesktopApp}
            />
          )}
          <SearchInputContainer
            normalizedActiveFilters={normalizedActiveFilters}
            filtersAsString={filterJsonAsString}
            filters={filters}
            removeFilterByName={
              removeFilterByName as (filterName: string) => void
            }
          />
          <Divider />
          <StyledContentFilterContainer>
            <StyledFilterBar filters={filters} resetAllFilters={resetFilters} />
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 24
              }}
            >
              <Switch
                switchSize='small'
                checked={searchTranscriptsFlag}
                onClick={() => setSearchTranscriptsFlag(!searchTranscriptsFlag)}
              >
                <span css={{ fontSize: 12, fontWeight: 500 }}>
                  Include transcripts
                </span>
              </Switch>
              <Menu
                width='225px'
                hideOnClickInside
                content={
                  <>
                    <MenuHeader variant='subheader'>Sort by</MenuHeader>
                    <StyledMenuButton
                      key='relevance'
                      label='Relevance'
                      disabled={sortBy === SearchSortBy.Relevancy}
                      isSelected={sortBy === SearchSortBy.Relevancy}
                      textLabelProps={{
                        startIcon:
                          sortBy === SearchSortBy.Relevancy
                            ? Icon.DeprecatedCheck
                            : undefined
                      }}
                      onClick={() => setSortBy(SearchSortBy.Relevancy)}
                    />
                    <StyledMenuButton
                      key='most-recent'
                      label='Most recent'
                      disabled={sortBy === SearchSortBy.StartedAt}
                      isSelected={sortBy === SearchSortBy.StartedAt}
                      textLabelProps={{
                        startIcon:
                          sortBy === SearchSortBy.StartedAt
                            ? Icon.DeprecatedCheck
                            : undefined
                      }}
                      onClick={() => setSortBy(SearchSortBy.StartedAt)}
                    />
                  </>
                }
              >
                <Button variant='ghost' size='lg' icon={Icon.Toggles} />
              </Menu>
            </div>
          </StyledContentFilterContainer>
          <Divider />
          <div id='infinite-scroll-container' css={{ overflow: 'auto' }}>
            {screen === 'recent_items' && <RecentSearchItems />}
            {screen === 'searching' && MultipleSearchSkeletons}
            {screen === 'results' && (
              <Results
                searchResults={results ?? []}
                totalCount={totalCount}
                hasMore={true}
                onLoadMore={fetchMore}
                onConnectToHubspot={() => setShowHubspotModal(true)}
                setSelectedRecordingIds={setSelectedRecordingIds}
                selectedRecordingIds={selectedRecordingIds}
                smartTagView={Boolean(filters.smart_tags?.active)}
              />
            )}
            {screen === 'no_results' && (
              <>
                <Divider />
                <TextRow css={[color.fg.pigeon]}>No search results</TextRow>
              </>
            )}
          </div>
        </Layout.Content>
      </Layout.Wrapper>
      {showHubspotModal && (
        <HubspotModal onClose={() => setShowHubspotModal(false)} />
      )}
      <RecordingBulkActions
        recordings={(results ?? []).map(result => ({
          id: result.recordingId,
          canDelete: true
        }))}
        checkedItemIds={selectedRecordingIds}
        cancelSelect={() => setSelectedRecordingIds([])}
      />
    </>
  );
}
