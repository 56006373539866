import React from 'react';
import { Switch, useAnalytics } from '@grain/grain-ui';
import { useDesktop, getLaunchSettings } from '@grain/desktop-lib';

import {
  StyledSwitchSection,
  StyledSeparator,
  StyledSwitchSectionContainer
} from './styles';
import { useExtra } from '@grain/components/support/extra';

const userExtraSettings = ['preferOpenInDesktopApp'] as const;

const settingItems = [
  'launchAtStartup',
  'autoOpenRecording',
  'hideFromScreenCapture',
  'autoOpenNotepad',
  'sendUsageData',
  'showStartNotification'
] as const;

const allSettings = [...settingItems, ...userExtraSettings] as const;

type Settings = {
  [key in (typeof allSettings)[number]]: boolean;
};

const isUserExtraSetting = (
  setting: keyof Settings
): setting is (typeof userExtraSettings)[number] => {
  return userExtraSettings.includes(
    setting as (typeof userExtraSettings)[number]
  );
};

const getOpenAtStartupSetting = async (): Promise<boolean> => {
  const launchSettings = await getLaunchSettings();
  return launchSettings.willLaunchAtStartup;
};

export default function DesktopOnlySettings() {
  const { trackEvent } = useAnalytics();
  const { extra, saveExtra, loading: extraLoading } = useExtra();
  const [settings, setSettings] = React.useState<Partial<Settings>>({});
  const { store } = useDesktop();

  const changeSettings = (name: keyof Settings, value: boolean) => {
    if (isUserExtraSetting(name)) {
      saveExtra({ ...extra, [name]: value });
      trackEvent('Settings Updated', {
        setting_name: `user_extra_${name}`,
        setting_new_value: value,
        setting_old_value: settings[name]
      });
    } else {
      store?.set(name, value);
    }
    setSettings({ ...settings, [name]: value });
  };

  React.useEffect(() => {
    if (!store || extraLoading) return;

    // Update launchAtStartup setting when changed
    const updateLaunchAtStartupSetting = (key: string, value: boolean) => {
      if (key === 'launchAtStartup') {
        setSettings({ ...settings, launchAtStartup: value });
      }
    };
    store.on('change', updateLaunchAtStartupSetting);

    const keys = Object.keys(settings);
    if (keys.length) {
      return;
    }

    const settingsData = {} as Settings;
    allSettings.forEach(item => {
      if (isUserExtraSetting(item)) {
        settingsData[item] = extra?.[item] ?? false;
      } else {
        const value: boolean = store?.get(item) ?? false;
        settingsData[item] = value;
      }
    });
    setSettings(settingsData);

    const syncLaunchSettings = async () => {
      const actualLaunchSetting = await getOpenAtStartupSetting();
      if (settings.launchAtStartup !== actualLaunchSetting) {
        setSettings({ ...settingsData, launchAtStartup: actualLaunchSetting });
      }
    };

    syncLaunchSettings();

    return () => {
      store.off('change', updateLaunchAtStartupSetting);
    };
  }, [store, settings, extraLoading, extra]);

  return (
    <StyledSwitchSectionContainer>
      <StyledSwitchSection>
        <div className='title'>
          Open links in desktop app
          <div className='sub-title'>
            Always open Grain links in the desktop app instead of your browser.
          </div>
        </div>

        <div className='switch'>
          <Switch
            checked={settings.preferOpenInDesktopApp}
            onChange={ev =>
              changeSettings('preferOpenInDesktopApp', ev.target.checked)
            }
          />
        </div>
      </StyledSwitchSection>

      <StyledSwitchSection>
        <div className='title'>
          Open Grain app on system startup
          <div className='sub-title'>
            When you log in to your computer, Grain will automatically open.
          </div>
        </div>

        <div className='switch'>
          <Switch
            checked={settings.launchAtStartup}
            onChange={ev =>
              changeSettings('launchAtStartup', ev.target.checked)
            }
          />
        </div>
      </StyledSwitchSection>

      <StyledSwitchSection>
        <div className='title'>
          Open to meeting after recording ends
          <div className='sub-title'>
            Open the Grain app after your meeting ends to easily review and
            share notes.
          </div>
        </div>

        <div className='switch'>
          <Switch
            checked={settings.autoOpenRecording}
            onChange={ev =>
              changeSettings('autoOpenRecording', ev.target.checked)
            }
          />
        </div>
      </StyledSwitchSection>

      <StyledSwitchSection>
        <div className='title'>
          Open notepad when Grain starts recording
          <div className='sub-title'>
            Automatically open the notepad when Grain starts recording meetings.
          </div>
        </div>

        <div className='switch'>
          <Switch
            checked={settings.autoOpenNotepad}
            onChange={ev =>
              changeSettings('autoOpenNotepad', ev.target.checked)
            }
          />
        </div>
      </StyledSwitchSection>

      <StyledSwitchSection>
        <div className='title'>
          Hide Grain from screen capture
          <div className='sub-title'>
            The Grain application will not appear in screenshots or recordings.
          </div>
        </div>

        <div className='switch'>
          <Switch
            checked={settings.hideFromScreenCapture}
            onChange={ev =>
              changeSettings('hideFromScreenCapture', ev.target.checked)
            }
          />
        </div>
      </StyledSwitchSection>

      <StyledSeparator />

      <StyledSwitchSection>
        <div className='title'>
          Notify me when meetings start
          <div className='sub-title'>
            Receive a notification when your meetings are about to start with
            quick access to record and join.
          </div>
        </div>

        <div className='switch'>
          <Switch
            checked={settings.showStartNotification}
            onChange={ev =>
              changeSettings('showStartNotification', ev.target.checked)
            }
          />
        </div>
      </StyledSwitchSection>
    </StyledSwitchSectionContainer>
  );
}
