// @ts-strict-ignore
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import fetch from 'unfetch';

import { getBasePublicPath } from '@grain/components/support/environment';
import * as Layout from '~/components/Layout';

import {
  StyledContainer,
  StyledTitle,
  StyledPackageList,
  StyledPackage
} from './styles';

type License = {
  repository: string;
};

const Acknowledgements = () => {
  const [licenses, setLicenses] = useState<[string, License][]>(null);

  useEffect(() => {
    Promise.all([
      fetch(`${getBasePublicPath()}/generated/licenses.desktop.json`)
        .then(response => response.json())
        .catch(() => {}),
      fetch(`${getBasePublicPath()}/generated/licenses.web.json`)
        .then(response => response.json())
        .catch(() => {})
    ]).then(([desktop, web]) => {
      const all: { [key: string]: License } = { ...desktop, ...web };
      setLicenses(Object.entries(all).sort((a, b) => a[0].localeCompare(b[0])));
    });
  }, []);

  return (
    <Layout.Wrapper>
      <Helmet title='Acknowledgements' />
      <Layout.Header withLogo centered />
      <Layout.Content>
        <StyledContainer>
          <StyledTitle>Open-source libraries we use</StyledTitle>
          {licenses && (
            <StyledPackageList>
              {licenses.map(
                ([name, info]) =>
                  name !== '' && (
                    <StyledPackage href={info.repository}>{name}</StyledPackage>
                  )
              )}
            </StyledPackageList>
          )}
        </StyledContainer>
      </Layout.Content>
    </Layout.Wrapper>
  );
};

export default Acknowledgements;
