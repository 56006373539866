import {
  IntelligenceTemplate,
  IntelligenceTemplateTypedSection
} from '@grain/api/schema.generated';
import {
  StyleTemplateInfo,
  StyledTemplateCardWrapper,
  StyledTemplateOtherInfo,
  StyledTemplateSectionInfo
} from './styles';
import { Icon, Divider } from '@grain/grain-ui';
import { useNotesTemplateEdit } from './hooks';
import {
  Button,
  Icon16,
  Menu,
  MenuButton,
  TextLabel,
  theme,
  Tooltip
} from '@grain/grain-ui/v4';
import { css } from '@emotion/react';

type NoteTemplateCardProps = {
  template: IntelligenceTemplate;
  onEdit: (id: string) => void;
  isGateActive?: boolean;
};

export const NoteTemplateCard = ({
  template,
  onEdit,
  isGateActive
}: NoteTemplateCardProps) => {
  const { handleTemplateDelete } = useNotesTemplateEdit(template.id);

  return (
    <StyledTemplateCardWrapper>
      <StyleTemplateInfo>
        <span>{template.title}</span>
        {template.description && <p>{template.description}</p>}
      </StyleTemplateInfo>
      <StyledTemplateOtherInfo>
        <Menu
          unmountOnClose
          hideOnClickInside
          stopClickPropagation
          tippyProps={{
            placement: 'bottom-end',
            trigger: 'mouseenter',
            disabled: template.sections.length === 0
          }}
          width='232px'
          content={template.sections.map(
            (section: IntelligenceTemplateTypedSection) => (
              <TextLabel
                key={section.id}
                css={css`
                  padding: ${theme.tokens.spacing.sm};
                `}
              >
                {section.title.length > 40
                  ? `${section.title.slice(0, 40)}...`
                  : section.title}
              </TextLabel>
            )
          )}
        >
          <StyledTemplateSectionInfo>
            <span>{template.sections.length} sections</span>
            <Icon.ChevronDown />
          </StyledTemplateSectionInfo>
        </Menu>
        {template?.canDelete ? (
          <Menu
            unmountOnClose
            hideOnClickInside
            stopClickPropagation
            tippyProps={{ placement: 'bottom-end' }}
            width='232px'
            content={
              <>
                <MenuButton
                  textLabelProps={{ startIcon: Icon16.Edit }}
                  label='Edit template'
                  onClick={() => onEdit(template.id)}
                />
                <Divider />
                <MenuButton
                  textLabelProps={{ startIcon: Icon16.Trash }}
                  label='Delete template'
                  onClick={() => handleTemplateDelete()}
                />
              </>
            }
          >
            <Button variant='ghost' icon={Icon16.Overflow} />
          </Menu>
        ) : (
          <Tooltip content='Upgrade your plan to enable this'>
            <Button
              variant='neutral'
              size='sm'
              disabled={isGateActive}
              onClick={() => onEdit(template.id)}
            >
              Edit
            </Button>
          </Tooltip>
        )}
      </StyledTemplateOtherInfo>
    </StyledTemplateCardWrapper>
  );
};
