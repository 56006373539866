import { ChipLabel, theme } from 'ui/v4/index';
import { DealDiagnosisCompletenessIcon } from './DealDiagnosisCompletenessIcon';
import { CompletenessStatus } from '@grain/api/schema.generated';

type DealDiagnosisCompletenessPillProps = {
  status?: CompletenessStatus;
  disabled?: boolean;
};

export const DealDiagnosisCompletenessPill = ({
  status = CompletenessStatus.None,
  disabled = false
}: DealDiagnosisCompletenessPillProps) => {
  const infoByStatus = {
    [CompletenessStatus.Complete]: {
      label: 'Complete',
      color: theme.tokens.color.iconBrand
    },
    [CompletenessStatus.Partial]: {
      label: 'Partial',
      color: theme.tokens.color.iconWarning
    },
    [CompletenessStatus.None]: {
      label: 'No Data',
      color: theme.tokens.color.iconDisabled
    }
  };

  return (
    <ChipLabel
      size='sm'
      css={{
        backgroundColor: disabled
          ? theme.tokens.color.surfaceSecondary
          : infoByStatus[status].color,
        color: disabled
          ? theme.tokens.color.textSecondary
          : theme.tokens.color.iconInvert
      }}
      startIcon={() => (
        <DealDiagnosisCompletenessIcon
          status={status}
          invertColors={!disabled}
          disabled={disabled}
        />
      )}
    >
      {infoByStatus[status].label}
    </ChipLabel>
  );
};
