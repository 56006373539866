import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { textAreaStyles, labelStyles } from './styles';

const TextArea = React.forwardRef(({ label, error, ...rest }, ref) => {
  return (
    <div css={['display: flex; flex-direction: column;']}>
      {label && <div css={labelStyles}>{label}</div>}
      <textarea
        type='text'
        css={textAreaStyles}
        ref={ref}
        {...{
          autoComplete: 'off',
          autoCorrect: 'off',
          autoCapitalize: 'off',
          spellCheck: 'false',
          ...rest
        }}
        className={cx(rest.className, error ? 'error' : '')}
      />
    </div>
  );
});

TextArea.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool
};

export default TextArea;
