import styled from '@emotion/styled';
import { Chip, Icon, color, font, spacing } from '@grain/grain-ui';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Suggestion } from '~/pages/Search/state';

const EMPTY_ARRAY: never[] = [];

type SuggestionRowProps = {
  text?: string;
  filters?: Suggestion[];
  isSelected?: boolean;
  onMouseMove: () => void;
  onSelect: () => void;
};

const IconWrapper = styled.div<{ isOverflowing: boolean; isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: ${({ isOverflowing }) => (isOverflowing ? 24 : 8)}px;
  margin-right: 0;
  position: sticky;
  left: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: -12px;
  margin-bottom: -12px;
  background: linear-gradient(
    to right,
    ${({ isSelected }) => (isSelected ? color.goose : color.swan)},
    ${({ isSelected }) => (isSelected ? color.goose : color.swan)} 70%,
    transparent
  );
`;

const Container = styled.div<{ isSelected: boolean; hasFilters: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  ${spacing.px4}
  ${({ hasFilters }) => (hasFilters ? spacing.py3 : spacing.py4)}
  padding-left: 0;
  border-bottom: 1px solid ${color.gull};
  ${font.v4.b3[400]}
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  background-color: ${({ isSelected }) =>
    isSelected ? color.goose : color.swan};

  &:last-child {
    border-bottom: none;
  }

  & > *:not(${IconWrapper}) {
    flex-shrink: 0;
    ${spacing.mr2}
  }
`;

export const SuggestionRow = ({
  text,
  filters = EMPTY_ARRAY,
  isSelected = false,
  onMouseMove,
  onSelect
}: SuggestionRowProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useLayoutEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth;
      setIsOverflowing(
        containerRef.current.scrollWidth > containerRef.current.clientWidth
      );
    }
  }, [filters, text]);

  // if isSelected scroll row into view
  useEffect(() => {
    if (isSelected) {
      containerRef.current?.scrollIntoView({
        behavior: 'instant',
        block: 'nearest'
      });
    }
  }, [isSelected]);

  const mergedFilters = useMemo(() => {
    const result: Suggestion[] = [];
    for (const filter of filters) {
      const existingFilter = result.find(f => f.filterKey === filter.filterKey);
      if (existingFilter) {
        existingFilter.valueLabel += `, ${filter.valueLabel}`;
      } else {
        result.push({ ...filter });
      }
    }
    return result;
  }, [filters]);

  return (
    <Container
      ref={containerRef}
      isSelected={isSelected}
      onMouseMove={onMouseMove}
      onClick={onSelect}
      hasFilters={mergedFilters.length > 0}
    >
      <IconWrapper isOverflowing={isOverflowing} isSelected={isSelected}>
        <Icon.Search color={color.pigeon} width={16} height={16} />
      </IconWrapper>
      {mergedFilters.map(filter => (
        <Chip
          key={filter.filterKey}
          title={`${filter.filterLabel}:`}
          variant='stroked'
          state={filter.valueLabel}
          size='extrasmall'
        />
      ))}
      {text}
    </Container>
  );
};
