import styled from '@emotion/styled';
import { Link, useParams } from 'react-router-dom';
import pluralize from 'pluralize';
import {
  Recording,
  RecordingsParticipantScope,
  SalesScoreCard
} from '@grain/api/schema.generated';
import {
  Avatar,
  color,
  Icon,
  spacing,
  TagPill,
  font,
  Label
} from '@grain/grain-ui';
import { Tooltip } from '@grain/grain-ui/v4';
import { OPPORTUNITY_LABELS, scoreToLabelVariant } from '~/support/scorecard';
import { metricsMap, outOfRangeMetricsKeyMap } from '~/support/speakerMetrics';

function getCompanyName(recording: Recording) {
  if (recording?.participantScope === RecordingsParticipantScope.Internal) {
    return 'Team';
  }
  if (!recording.externalGroupsData?.length) return null;
  const groups = recording?.externalGroupsData;
  if (groups.length > 1) {
    return `${groups?.length} ${pluralize('Company', groups?.length)}`;
  } else if (groups.length === 1) {
    return groups[0].group.name;
  }
  return null;
}

function getDealStage(recording: Recording) {
  const groups = recording?.externalGroupsData;
  if (
    recording?.participantScope === RecordingsParticipantScope.Internal ||
    groups.length !== 1 ||
    !groups[0]?.deal?.stage
  ) {
    return null;
  }
  return groups[0].deal.stage;
}

const Container = styled.div`
  display: flex;
  border-bottom: ${color.gull} 1px solid;
  ${spacing.pl7};
  padding-right: 39px;
`;

const MetadataColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 0; // Fix ellipsis
  padding: 18px 32px 18px 0;
  font-size: 12px;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  height: 20px; // Have a consistent height with below rows
`;

const MetadataTitle = styled.div<{ viewed: boolean }>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  color: ${({ viewed }) => (viewed ? color.pigeon : color.blackbird)};
`;

const MetadataRow = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  color: ${color.crow};
`;

const MetadataRowIcon = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

const MetadataRowText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const MetadataRowLabels = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;

  & > * {
    width: 0;
    flex: 1 1 auto;
    min-width: 35px;
    max-width: fit-content;
  }
`;

const CustomTagPill = styled(TagPill)`
  margin: 0 !important;
  transition: flex 0.2s ease-in-out;

  span {
    ${color.fg.blackbird};
    ${font.v4.b4[500]};
  }

  &:hover {
    flex: 10 0 auto;
  }
`;

const LabelOverflowWrapper = styled.span`
  --icon-plus-counter-width: 65px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

type RecordingListItemProps = {
  active: boolean;
  recording: Recording;
  viewed: boolean;
};

export function RecordingListItem({
  active,
  recording,
  viewed
}: RecordingListItemProps) {
  const companyName = getCompanyName(recording);
  const dealStage = getDealStage(recording);
  const { tabId } = useParams();

  const overallScore = recording?.salesCoaching?.scoring?.scoreCard?.overall;
  const { __typename, ...scoreCard } =
    recording?.salesCoaching?.scoring?.scoreCard ?? {};

  const opportunities = Object.entries(scoreCard)
    .filter(([key, value]) => (value === 1 || value === 2) && key !== 'overall') // Can't do value < 3 because 0 is an invalid case
    .sort(([, a], [, b = 0]) => (a || 0) - (b || 0))
    .map(
      ([key]) =>
        OPPORTUNITY_LABELS[key as keyof Omit<SalesScoreCard, '__typename'>]
    );

  const outOfRangeMetrics = Object.entries(
    recording?.salesCoaching?.outOfRangeSpeakerStats ?? {}
  )
    .filter(([_key, value]) => value === true)
    .map(([key]) => {
      const metricKey =
        outOfRangeMetricsKeyMap[key as keyof typeof outOfRangeMetricsKeyMap];
      const metric = metricsMap[metricKey];
      return metric.title;
    });

  const flaggedLabels = [...opportunities, ...outOfRangeMetrics];

  const remainingFlaggedLabels = flaggedLabels.splice(2);

  return (
    <Link
      to={`/app/coaching/${recording.id}${tabId ? `/${tabId}` : ''}`}
      css={[
        `
        display: block;
        background: ${active && color.washbird};
        color: inherit;
        &:hover { text-decoration: none; }
        &:hover, &:focus { background: ${
          active ? color.washbird : color.goose
        }; }
      `
      ]}
    >
      <Container>
        <MetadataColumn css={['padding-right: 0;']}>
          <TitleRow css={['justify-content: space-between;']}>
            <MetadataTitle viewed={viewed}>{recording.title}</MetadataTitle>
            {overallScore && (
              <Label variant={scoreToLabelVariant(overallScore)} square>
                {overallScore}
              </Label>
            )}
          </TitleRow>
          <MetadataRow>
            <MetadataRowIcon>
              <Avatar
                avatarSize='extrasmall'
                name={recording.owner.name}
                avatarUrl={recording.owner.avatarUrl ?? false}
                defaultAvatarColor={color.gull}
                textColor={color.blackbird}
              />
            </MetadataRowIcon>{' '}
            <MetadataRowText>{recording.owner.name}</MetadataRowText>
          </MetadataRow>
          {(companyName || dealStage) && (
            <MetadataRow>
              <MetadataRowIcon>
                {companyName ? (
                  <Icon.Building css={{ color: color.pigeon }} />
                ) : (
                  <Icon.Heartbeat css={{ color: color.pigeon }} />
                )}
              </MetadataRowIcon>{' '}
              <MetadataRowText>
                {companyName && dealStage
                  ? `${companyName} · ${dealStage}`
                  : companyName || dealStage}
              </MetadataRowText>
            </MetadataRow>
          )}
          <MetadataRow>
            <MetadataRowIcon css={['max-width: 16px']}>
              <Icon.MegaphoneOutline css={{ color: color.pigeon }} />
            </MetadataRowIcon>{' '}
            <MetadataRowLabels>
              {flaggedLabels.length > 0 ? (
                <>
                  {flaggedLabels.map(flaggedLabel => (
                    <CustomTagPill key={flaggedLabel}>
                      <LabelOverflowWrapper>
                        {flaggedLabel}
                      </LabelOverflowWrapper>
                    </CustomTagPill>
                  ))}
                  {remainingFlaggedLabels.length > 0 && (
                    <Tooltip content={remainingFlaggedLabels.join(', ')}>
                      <CustomTagPill css={['margin-right: 0 !important']}>
                        <span>+{remainingFlaggedLabels.length}</span>
                      </CustomTagPill>
                    </Tooltip>
                  )}
                </>
              ) : (
                <span>None</span>
              )}
            </MetadataRowLabels>
          </MetadataRow>
        </MetadataColumn>
      </Container>
    </Link>
  );
}
