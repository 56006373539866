import React, { useMemo } from 'react';
import {
  PageHeader,
  PageTitle,
  type Option as LegacyOption,
  useShowToast
} from '@grain/grain-ui';
import { IntelligenceTemplate, UserRole } from '@grain/api/schema.generated';
import { StyledPageHeader } from '../../SettingsWrapper';
import { useExperiment, useFeature, useMyself } from '~/support/auth';
import {
  StyledContainer,
  StyledSectionLabel,
  StyledSeparator,
  Description,
  StyledContentWrapper,
  StyledSection,
  StyledSectionMainDescription,
  StyledSectionSubHeader,
  StyledTemplateCardWrapper
} from './styles';
import { NoteTemplateCard } from './NoteTemplateCard';
import { TemplateDefaultScope, templateSettingsOptions } from './hooks';
import { useWorkspaceInfoQuery } from '@grain/api/graphql/queries/workspace.generated';
import { useWorkspaceEditMutation } from '@grain/api/graphql/mutations/workspace.generated';
import { Select, Option, Button, Icon16, Tooltip } from '@grain/grain-ui/v4';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { GatedSwitch } from '~/modules/gates/GatedSwitch';

export type ViewTopicsProps = {
  templates: IntelligenceTemplate[];
  onEdit: (id: string) => void;
  onAdd: () => void;
};

const CreateTemplateButton = ({
  onAdd,
  isGateActive
}: {
  onAdd: () => void;
  isGateActive: boolean;
}) => {
  return (
    <Tooltip
      content='Upgrade your plan to enable this'
      disabled={!isGateActive}
    >
      <Button
        variant='neutral'
        disabled={isGateActive}
        icon={Icon16.Plus}
        onClick={onAdd}
      >
        Create template
      </Button>
    </Tooltip>
  );
};

export const NoteTemplatesList = ({
  onEdit,
  templates,
  onAdd
}: ViewTopicsProps) => {
  const { enabled: isCustomAiNotesEnabled } = useFeature('custom_ai_notes');
  const { enabled: adminManagementEnabled } = useFeature('admin_paid_features');
  const { enabled: isFreemiumEnabled } = useExperiment('freemium');
  const showToast = useShowToast();
  const [templateRequireAdmin, setTemplateRequireAdmin] = React.useState(true);
  const { data } = useWorkspaceInfoQuery({
    onCompleted: ({ workspace }) => {
      setTemplateRequireAdmin(workspace.templateEditingRequireAdmin);
    }
  });
  const workspace = data?.workspace;
  const { myself } = useMyself();

  const templateOptions = useMemo(() => {
    const options = [
      {
        title: 'None',
        value: ''
      },
      ...templates?.map(template => ({
        title: template.title,
        value: template.id
      }))
    ];

    return options;
  }, [templates]);

  const internalTemplate = useMemo(
    () => workspace?.internalRecordingIntelligenceTemplateV2,
    [workspace]
  );
  const externalTemplate = useMemo(
    () => workspace?.externalRecordingIntelligenceTemplateV2,
    [workspace]
  );
  const isAdmin = myself?.user?.role === UserRole.Admin;

  const [editWorkspace] = useWorkspaceEditMutation({
    onCompleted: () => {
      showToast({
        content: 'Default template updated.',
        type: 'success',
        uniqueId: 'workspace-update'
      });
    }
  });

  const handleSetDefault = (id: string, scope: TemplateDefaultScope) => {
    const internalId = scope === 'internal' ? id || null : internalTemplate?.id;
    const externalId = scope === 'external' ? id || null : externalTemplate?.id;

    editWorkspace({
      variables: {
        internalRecordingIntelligenceTemplateId: internalId,
        externalRecordingIntelligenceTemplateId: externalId
      }
    });
  };

  const defaultTemplates = templates?.filter(template => !template.canDelete);
  const customTemplates = templates?.filter(template => template.canDelete);

  const getSelectedTemplate = React.useCallback(
    (type: TemplateDefaultScope): LegacyOption[] => {
      const templateSelection =
        type === 'internal' ? internalTemplate : externalTemplate;
      const template = templates?.find(
        template => template.id === templateSelection?.id
      );

      const templateOptions = template
        ? [template]
        : [
            {
              title: 'Select Template',
              id: ''
            }
          ];

      return templateOptions.map(template => ({
        title: template.title,
        value: template.id
      }));
    },
    [internalTemplate, externalTemplate, templates]
  );

  const handleTemplateRequireAdminChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTemplateRequireAdmin(e.target.checked);
    editWorkspace({
      variables: {
        templateEditingRequireAdmin: e.target.checked
      }
    });
  };

  return (
    <>
      <StyledPageHeader isTopic sticky>
        <PageHeader.Left>
          <PageTitle hasSubTitle>
            <div className='page-name'>Workspace</div>
            <div>Templates</div>
          </PageTitle>
        </PageHeader.Left>
        <PageHeader.Right>
          <CreateTemplateButton
            onAdd={onAdd}
            isGateActive={!isCustomAiNotesEnabled}
          />
        </PageHeader.Right>
      </StyledPageHeader>
      <StyledContainer>
        <StyledContentWrapper>
          <StyledSection>
            <Description css={['width: 840px;']}>
              {!isCustomAiNotesEnabled ? (
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 8px;
                  `}
                >
                  <Button
                    size='sm'
                    variant='plan'
                    as={Link}
                    to='/app/settings/workspace?tab=plans'
                  >
                    Upgrade
                  </Button>
                  to customize AI notes with templates. Get the key information
                  in the format you want on every call.
                </div>
              ) : (
                <>
                  Customize your AI notes with templates. Get the key
                  information in the format you want on every call.{' '}
                  <a
                    href='https://support.grain.com/en/articles/8229813-how-to-create-custom-note-templates'
                    css={css`
                      text-decoration: underline;
                    `}
                  >
                    Read more
                  </a>
                </>
              )}
            </Description>
          </StyledSection>
          <StyledSeparator />
          <StyledSection>
            <StyledSectionMainDescription>
              <span>Defaults</span>
              <Description>
                Select the default templates to automatically use for your
                workspace’s meetings.
              </Description>
            </StyledSectionMainDescription>
            {templateSettingsOptions.map(option => (
              <div key={option.value}>
                <StyledSectionLabel>{option.title}</StyledSectionLabel>
                <StyledContainer>
                  <div css={['width: 450px']}>
                    <Tooltip
                      content='Contact your admin to change Workspace template defaults'
                      disabled={isAdmin}
                    >
                      <Select
                        fullWidth
                        matchWidth
                        value={
                          getSelectedTemplate(option.value)[0]?.value ?? ''
                        }
                        placeholder='Select Template'
                        disabled={!isAdmin}
                        onChange={value => {
                          handleSetDefault(value, option.value);
                        }}
                      >
                        {templateOptions.map(template => (
                          <Option key={template.value} value={template.value}>
                            {template.title}
                          </Option>
                        ))}
                      </Select>
                    </Tooltip>
                  </div>
                </StyledContainer>
              </div>
            ))}
          </StyledSection>
          {(isFreemiumEnabled ||
            (!isFreemiumEnabled && isAdmin && adminManagementEnabled)) && (
            <>
              <StyledSeparator />
              <StyledSection
                css={css`
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                `}
              >
                <StyledSectionMainDescription>
                  <StyledSectionSubHeader>
                    Admin-only template editing
                  </StyledSectionSubHeader>
                  <Description>
                    Only allow admins to access and edit workspace template
                    settings.
                  </Description>
                </StyledSectionMainDescription>

                <div className='switch'>
                  <GatedSwitch
                    gate='paid_settings'
                    checked={templateRequireAdmin}
                    onChange={handleTemplateRequireAdminChange}
                  />
                </div>
              </StyledSection>
            </>
          )}
          <StyledSeparator />
          <StyledSection>
            <StyledSectionSubHeader>Grain templates</StyledSectionSubHeader>
            {defaultTemplates?.map(template => (
              <NoteTemplateCard
                key={template.id}
                template={template}
                onEdit={onEdit}
                isGateActive={!isCustomAiNotesEnabled}
              />
            ))}
            {isCustomAiNotesEnabled && (
              <>
                <StyledSectionSubHeader css={['margin-top: 16px;']}>
                  Custom templates
                </StyledSectionSubHeader>
                {customTemplates?.length === 0 && (
                  <StyledTemplateCardWrapper>
                    <span css={['font-size: 14px; font-weight: 400']}>
                      You don’t have any custom templates.
                    </span>
                    <CreateTemplateButton
                      onAdd={onAdd}
                      isGateActive={!isCustomAiNotesEnabled}
                    />
                  </StyledTemplateCardWrapper>
                )}
                {customTemplates?.map(template => (
                  <NoteTemplateCard
                    key={template.id}
                    template={template}
                    onEdit={onEdit}
                    isGateActive={!isCustomAiNotesEnabled}
                  />
                ))}
              </>
            )}
          </StyledSection>
        </StyledContentWrapper>
      </StyledContainer>
    </>
  );
};
