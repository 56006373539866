import { CompletenessStatus } from '@grain/api/schema.generated';
import { theme } from '@grain/grain-ui/v4';

const { iconBrand, iconInvert, iconWarning, iconDisabled, surfaceTertiary } =
  theme.tokens.color;

type Status = CompletenessStatus | 'PROCESSING';

type DealDiagnosisCompletenessIconProps = {
  status: Status;
  invertColors?: boolean;
  disabled?: boolean;
} & React.SVGProps<SVGSVGElement>;

export const DealDiagnosisCompletenessIcon = ({
  status,
  invertColors,
  disabled,
  ...svgProps
}: DealDiagnosisCompletenessIconProps) => {
  const completeColor = disabled ? iconDisabled : iconBrand;
  const partialColor = disabled ? iconDisabled : iconWarning;

  const circlePropsByStatus: Record<Status, { stroke: string; fill?: string }> =
    {
      COMPLETE: {
        stroke: invertColors ? iconInvert : completeColor,
        fill: invertColors ? iconInvert : completeColor
      },
      PARTIAL: {
        stroke: invertColors ? iconInvert : partialColor,
        fill: invertColors ? undefined : iconInvert
      },
      NONE: {
        stroke: invertColors ? iconInvert : iconDisabled
      },
      PROCESSING: {
        stroke: surfaceTertiary,
        fill: surfaceTertiary
      }
    };

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      aria-label='Deal Diagnosis Completeness Icon'
      {...svgProps}
    >
      <circle
        cx='7'
        cy='7'
        r='6.25'
        strokeWidth='1.5'
        {...circlePropsByStatus[status]}
      />
      {status === CompletenessStatus.Complete && (
        <path
          d='M9.5625 5.23828L6.03906 8.76172L4.4375 7.16016'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
          stroke={invertColors ? completeColor : iconInvert}
        />
      )}

      {status === CompletenessStatus.Partial && (
        <path
          d='M7.19997 10.9951C9.31621 10.8909 11 9.14208 11 7C11 4.85792 9.31621 3.1091 7.19997 3.00491C7.08965 2.99948 7 3.08954 7 3.2V10.8C7 10.9105 7.08965 11.0005 7.19997 10.9951Z'
          fill={invertColors ? iconInvert : partialColor}
        />
      )}
    </svg>
  );
};
