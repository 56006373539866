import React, { ComponentProps } from 'react';
import { CitationButton } from './CitationButton';
import { ExtraProps } from 'react-markdown';
import { AugmentedCitation } from '../types';

export const formatTime = (ms: number): string => {
  const totalSeconds = Math.floor(ms / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

const citationRegex = /(\[\d+\])/g;

export const processCitationsInChildren = (
  children: React.ReactNode,
  citations: AugmentedCitation[] | null
): React.ReactNode => {
  return React.Children.map(children, child => {
    if (typeof child !== 'string') {
      return child;
    }

    if (!child.includes('[') || !child.includes(']')) {
      return child;
    }

    const parts = child.split(citationRegex);
    return parts.map(part => {
      const match = part.match(/\[(\d+)\]/);
      if (match) {
        const citationId = match[1];
        return (
          <CitationButton
            key={citationId}
            citationId={citationId}
            citations={citations}
          />
        );
      }
      return part;
    });
  });
};

type WithCitationsProps<K extends keyof JSX.IntrinsicElements> = {
  Component: K;
  props: ComponentProps<K> & ExtraProps;
  citations: AugmentedCitation[] | null;
};

export const withCitations = <K extends keyof JSX.IntrinsicElements>({
  Component,
  props,
  citations
}: WithCitationsProps<K>): React.ReactElement => {
  const { children, ...rest } = props;
  const processedChildren = processCitationsInChildren(children, citations);
  return React.createElement(Component, rest, processedChildren);
};
