import styled from '@emotion/styled';

import { theme } from '@grain/grain-ui/v4';

type BarMap = {
  [key: string]: { incoming: number; outgoing: number } | null;
};

const BAR_MAP: BarMap = {
  0: { incoming: 2, outgoing: 2 },
  1: null,
  2: null,
  3: { incoming: 2, outgoing: 2 },
  4: { incoming: 5, outgoing: 5 },
  5: { incoming: 2, outgoing: 2 },
  6: null,
  7: { incoming: 2, outgoing: 2 },
  8: null
};

const TOTAL_DATES = Object.keys(BAR_MAP).length;

const MAX_ACTIVITY_UNITS = 5;
const ONE_UNIT_OF_ACTIVITY_HEIGHT = 2.7;
const MIN_ACTIVITY_BAR_HEIGHT = 1.5;

const activityToHeight = (activity: number) => {
  return (
    Math.min(activity, MAX_ACTIVITY_UNITS) * ONE_UNIT_OF_ACTIVITY_HEIGHT +
    MIN_ACTIVITY_BAR_HEIGHT
  );
};

const StyledMomentumTimeline = styled.div`
  display: flex;
  gap: 2px;
`;

const StyledMomentumBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledMomentumBarItem = styled.div`
  height: 50%;
  display: flex;
`;

type BarProps = { height: number; hasOpacity?: boolean };

const StyledMomentumBarHalf = styled.div<BarProps>`
  background: ${theme.tokens.color.surfaceInvert};
  height: ${({ height }) => height}px;
  ${({ hasOpacity }) => hasOpacity && 'opacity: 0.4;'}
  width: 3px;
`;

const StyledMomentumBarT = styled(StyledMomentumBarHalf)`
  background-color: ${theme.tokens.color.textPrimary};
  border-radius: 2px 2px 0 0;
`;

const StyledMomentumBarB = styled(StyledMomentumBarHalf)`
  background-color: ${theme.tokens.color.textSecondary};

  border-radius: 0 0 2px 2px;
`;

export function ExampleTimeline() {
  return (
    <StyledMomentumTimeline aria-label='Momentum timeline showing incoming and outgoing activity over time (example, no real data)'>
      {Array(TOTAL_DATES)
        .fill(0)
        .map((_, i) => {
          const info = BAR_MAP[i];

          return (
            <StyledMomentumBar key={i}>
              <StyledMomentumBarItem css={['align-items: flex-end']}>
                <StyledMomentumBarT
                  height={activityToHeight(info?.outgoing ?? 0)}
                  hasOpacity={Boolean(info && !info.outgoing)}
                />
              </StyledMomentumBarItem>
              <StyledMomentumBarItem css={['align-items: flex-start']}>
                <StyledMomentumBarB
                  height={activityToHeight(info?.incoming ?? 0)}
                  hasOpacity={!!info?.incoming}
                />
              </StyledMomentumBarItem>
            </StyledMomentumBar>
          );
        })}
    </StyledMomentumTimeline>
  );
}
