import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { bool, node } from 'prop-types';
import {
  Modal,
  ModalTitle,
  flex,
  UpgradeBadge,
  Switch,
  useShowToast,
  pxToRem,
  color,
  SpeakerOverlaySelector
} from '@grain/grain-ui';
import { colors, spacing } from '@grain/styles/constants';
import TextArea from '@grain/components/TextAreaField';
import Input, { useTrimmedField as useField } from '@grain/components/Input';
import usePreviousDistinct from 'react-use/lib/usePreviousDistinct';
import { useFeature, useMyself, useWorkspace } from '@grain/api/auth';
import { StyledRecorderSection, StyledSubSectionLabel } from '../styles';
import { RecorderModeSelector } from './RecorderModeSelector/RecorderModeSelector';
import { Tooltip, Button, theme } from '@grain/grain-ui/v4';
import { TippyProps } from '@tippyjs/react';
import { RecorderModeType, SpeakerVideoWhenScreenshare } from '@g/schema';
import { useSetSettingsMutation } from '@grain/api/graphql/mutations/user.generated';

const StyledZoomLink = styled.a`
  cursor: default;
  color: ${colors.zoomLinkBlue};
  pointer-events: none;
`;

const StyledPreviewLink = styled.a`
  cursor: pointer;
  font-size: ${pxToRem(14)};
`;

const StyledTextareaContainer = styled.div`
  padding-top: 0;
`;

const StyledContainer = styled.div`
  ${flex.direction.row};
  ${flex.justifyContent.spaceBetween};
  ${flex.alignItems.center};
`;

const StyledContainerLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(16)};
  font-feature-settings:
    'case' on,
    'cpsp' on;
  color: ${colors.crow};
  margin-top: 2px;
  margin-bottom: 8px;
`;

const StyledZoomPreviewBubble = styled.div`
  font-weight: 500;
  letter-spacing: -0.01em;
  font-size: ${pxToRem(12)};
  color: ${colors.blackbird};
  border-radius: ${pxToRem(16)};
  background: ${colors.zoomGray};
  padding: ${pxToRem(12)} ${pxToRem(16)};
  margin: ${pxToRem(24)} ${pxToRem(48)} ${pxToRem(32)};

  p {
    margin-top: 0;

    &:not(:first-child) {
      margin: 0;
    }
  }
`;

const StyledInputSection = styled(StyledRecorderSection)`
  width: 100%;

  > div {
    width: 420px;
    > div {
      width: 100%;
    }
  }

  ${flex.direction.row};
  ${flex.alignItems.center};
  ${flex.justifyContent.spaceBetween};
  margin-bottom: 16px;

  > div {
    ${flex.direction.row};
    ${flex.alignItems.center};

    span {
      padding-left: ${pxToRem(8)};
    }
  }
`;

export const StyledSwitchSection = styled.div`
  ${flex.direction.row};
  ${flex.alignItems.center};
  ${flex.justifyContent.spaceBetween};
  .title {
    ${color.fg.blackbird};
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
    font-feature-settings:
      'case' on,
      'cpsp' on;

    .sub-title {
      ${color.fg.crow};
      ${spacing.mt2};
      font-style: normal;
      font-weight: 400;
      font-size: ${pxToRem(14)};
      line-height: ${pxToRem(18)};
      font-feature-settings:
        'case' on,
        'cpsp' on;
    }
  }
`;

const OptionContainer = styled.div`
  width: 420px;

  .option-select-button {
    background-color: ${colors.gull} !important;
    border: none !important;
  }
`;

const StyledSeparator = styled.div`
  height: 1px;
  background: ${colors.gull};
  margin: 32px -32px;
`;

type PreviewProps = {
  removed?: boolean;
  title: string;
  onCancel: () => void;
};
function Preview({ removed, title, onCancel }: PreviewProps) {
  const { workspace } = useWorkspace();
  const { myself } = useMyself();

  if (!workspace) {
    return null;
  }

  return (
    <Modal closable width={516} onCancel={onCancel}>
      <ModalTitle title={title} centered />
      <StyledZoomPreviewBubble>
        <p>{myself?.settings?.recorderChatMessage}</p>
        {!removed && (
          <p>
            Learn more:{' '}
            <StyledZoomLink>https://www.grain.com/example-link</StyledZoomLink>
          </p>
        )}
      </StyledZoomPreviewBubble>
    </Modal>
  );
}

type FreemiumGateTooltipProps = {
  children: TippyProps['children'];
  disabled: boolean;
};
const FreemiumGateTooltip = ({
  children,
  disabled
}: FreemiumGateTooltipProps) => {
  return (
    <Tooltip disabled={disabled} content='Upgrade your plan to change this'>
      {children}
    </Tooltip>
  );
};

FreemiumGateTooltip.propTypes = {
  children: node.isRequired,
  disabled: bool.isRequired
};

export default function CustomizeGrainRecorder() {
  const showToast = useShowToast();
  const { myself } = useMyself();
  const { workspace } = useWorkspace();
  const [changeSettings, { loading }] = useSetSettingsMutation();
  const [preview, setPreview] = useState(false);
  const { enabled: botConfigEnabled } = useFeature('custom_bot_config');
  const storedSettings = usePreviousDistinct(myself?.settings);
  const { enabled: isPaidSettingsEnabled } = useFeature('paid_settings');

  const isRecorderCustomizationsDisabled =
    !isPaidSettingsEnabled || !botConfigEnabled;

  const [
    recorderName,
    {
      setValue: setRecorderName,
      dirty: recorderNameDirty,
      setDirty: setRecorderNameDirty
    }
  ] = useField();
  const [
    recorderMessage,
    {
      setValue: setRecorderMessage,
      dirty: recorderMessageDirty,
      setDirty: setRecorderMessageDirty
    }
  ] = useField<HTMLTextAreaElement>({
    initialValue: myself?.settings?.recorderDisplayMessage
  });

  const [
    recorderChatMessage,
    {
      setValue: setRecorderChatMessage,
      dirty: recorderChatMessageDirty,
      setDirty: setRecorderChatMessageDirty
    }
  ] = useField<HTMLTextAreaElement>({
    initialValue: myself?.settings?.recorderChatMessage || undefined
  });

  const saveRecorderName = React.useCallback(() => {
    let recorder = recorderName.value;
    if (recorder.trim() === '') {
      const defaultName = `${
        myself?.user?.name.split(' ')[0]
      }'s Grain Notetaker`;
      recorder = defaultName;
    }
    changeSettings({
      variables: { settings: { recorderName: recorder } }
    }).then(() => {
      setRecorderName(recorder);
      setRecorderNameDirty(false);
      showToast &&
        showToast({
          type: 'success',
          uniqueId: 'grain-recorder-rename',
          content: `Grain Notetaker renamed to ${recorder}.`
        });
    });
  }, [
    recorderName.value,
    changeSettings,
    myself?.user?.name,
    setRecorderName,
    setRecorderNameDirty,
    showToast
  ]);

  const enableRecorderMessage = React.useCallback(() => {
    let message = recorderMessage.value;
    if (message.trim() === '') {
      const defaultmessage = 'Learn more at Grain.com.';
      message = defaultmessage;
    }
    changeSettings({
      variables: {
        settings: {
          recorderDisplayMessage: message
        }
      }
    }).then(() => {
      setRecorderMessage(message);
      setRecorderMessageDirty(false);
      showToast &&
        showToast({
          type: 'success',
          uniqueId: 'grain-message-set',
          content: 'Grain Notetaker message set.'
        });
    });
  }, [
    recorderMessage.value,
    changeSettings,
    setRecorderMessage,
    setRecorderMessageDirty,
    showToast
  ]);

  const enableRecorderChatMessage = React.useCallback(() => {
    let message = recorderChatMessage.value;
    if (message.trim() === '') {
      const defaultmessage = 'This call is being recorded with Grain.';
      message = defaultmessage;
    }
    changeSettings({
      variables: {
        settings: {
          recorderChatMessage: message
        }
      }
    }).then(() => {
      setRecorderChatMessage(message);
      setRecorderChatMessageDirty(false);
      showToast &&
        showToast({
          type: 'success',
          uniqueId: 'grain-message-set',
          content: 'Grain chat message set.'
        });
    });
  }, [
    recorderChatMessage.value,
    changeSettings,
    setRecorderChatMessage,
    setRecorderChatMessageDirty,
    showToast
  ]);

  const onRecorderModeChange = React.useCallback(
    (setting: RecorderModeType) =>
      changeSettings({ variables: { settings: { recorderMode: setting } } }),
    [changeSettings]
  );

  const onSpeakerOverlayChange = React.useCallback(
    (setting: SpeakerVideoWhenScreenshare) =>
      changeSettings({
        variables: { settings: { speakerVideoWhenScreenshare: setting } }
      }),
    [changeSettings]
  );

  useEffect(() => {
    if (myself?.settings && !storedSettings) {
      if (!recorderName.value) {
        setRecorderName(myself.settings.recorderName);
      }
    }
  }, [
    myself,
    recorderName,
    storedSettings,
    setRecorderName,
    setRecorderMessage
  ]);

  return (
    <>
      {preview && (
        <Preview
          title='Zoom Message Preview'
          onCancel={() => setPreview(false)}
        />
      )}
      {!isPaidSettingsEnabled ? (
        <>
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 8px;
              ${theme.tokens.typography.b2[400]}
            `}
          >
            <Button
              size='sm'
              variant='plan'
              as={Link}
              to='/app/settings/workspace?tab=plans'
            >
              Upgrade
            </Button>
            to customize the look and behavior of the notetaker in your
            meetings.
          </div>
          <StyledSeparator />
        </>
      ) : (
        <StyledSubSectionLabel css={[spacing.mt0, spacing.mb7]}>
          Customize how the Grain notetaker appears during your meetings.
          {!botConfigEnabled && (
            <Link
              to='/app/settings/workspace?tab=plans'
              style={{ textDecoration: 'none' }}
            >
              <UpgradeBadge theme='light' css={['cursor: pointer;']} />
            </Link>
          )}
        </StyledSubSectionLabel>
      )}

      <StyledSwitchSection css={[spacing.mb4]}>
        <div className='title'>
          <div>Notetaker name</div>
          <div className='sub-title'>
            Choose a custom name for your notetaker in Zoom, Microsoft Teams,
            and Webex.
          </div>
        </div>
      </StyledSwitchSection>
      <StyledInputSection>
        <FreemiumGateTooltip disabled={!isRecorderCustomizationsDisabled}>
          <Input
            css={['width: 420px']}
            {...recorderName}
            disabled={isRecorderCustomizationsDisabled || !workspace || !myself}
            placeholder={`${workspace?.name || 'Workspace'} Call`}
            onKeyDown={ev => {
              ev.stopPropagation();
              if (ev.key === 'Enter') {
                ev.preventDefault();
                ev.currentTarget.blur();
              }
            }}
            onBlur={() => {
              if (!recorderNameDirty) {
                return;
              }
              saveRecorderName();
            }}
          />
        </FreemiumGateTooltip>
      </StyledInputSection>
      <StyledSeparator />
      <StyledSwitchSection css={[spacing.mb6]}>
        <div className='title'>
          <div>Meeting chat message</div>
          <div className='sub-title'>
            When your notetaker joins the meeting, it will send a custom message
            to the chat.
          </div>
        </div>
        <FreemiumGateTooltip disabled={!isRecorderCustomizationsDisabled}>
          <Switch
            disabled={isRecorderCustomizationsDisabled}
            checked={myself?.settings?.sendChatMessage}
            onChange={e =>
              changeSettings({
                variables: {
                  settings: {
                    sendChatMessage: e.target.checked
                  }
                }
              })
            }
          />
        </FreemiumGateTooltip>
      </StyledSwitchSection>
      {myself?.settings?.sendChatMessage && (
        <div>
          <StyledContainer>
            <StyledTextareaContainer css={['width: 100%;']}>
              <TextArea
                {...recorderChatMessage}
                disabled={isRecorderCustomizationsDisabled}
                placeholder='Type a message'
                css={[`resize: none; height: ${pxToRem(72)};`]}
                onKeyDown={ev => {
                  ev.stopPropagation();
                  if (ev.key === 'Enter' && !ev.shiftKey) {
                    if (loading) return;
                    ev.preventDefault();
                    ev.currentTarget.blur();
                  }
                }}
                onBlur={() => {
                  if (recorderChatMessageDirty) {
                    if (loading) return;
                    enableRecorderChatMessage();
                  }
                }}
              />
            </StyledTextareaContainer>
          </StyledContainer>

          <StyledContainer css={['justify-content: flex-start; gap: 8px;']}>
            <StyledPreviewLink
              css={[spacing.mt6, spacing.mb0]}
              onClick={() => setPreview(true)}
            >
              Preview
            </StyledPreviewLink>
          </StyledContainer>
        </div>
      )}
      <StyledSeparator />
      <StyledSwitchSection css={[spacing.mb6]}>
        <div className='title'>
          <div>Join meeting with video display</div>
          <div className='sub-title'>
            In meetings the notetaker will display it’s recording, your logo and
            a short message.
          </div>
        </div>
        <FreemiumGateTooltip disabled={!isRecorderCustomizationsDisabled}>
          <Switch
            disabled={isRecorderCustomizationsDisabled}
            checked={myself?.settings?.showRecorderVideoDisplay}
            onChange={e =>
              changeSettings({
                variables: {
                  settings: {
                    showRecorderVideoDisplay: e.target.checked
                  }
                }
              })
            }
          />
        </FreemiumGateTooltip>
      </StyledSwitchSection>
      {myself?.settings?.showRecorderVideoDisplay && (
        <StyledContainer>
          <div css={['width: 100%']}>
            <StyledContainerLabel>Display message</StyledContainerLabel>
            <StyledTextareaContainer css={['width: 100%;']}>
              <FreemiumGateTooltip disabled={!isRecorderCustomizationsDisabled}>
                <TextArea
                  {...recorderMessage}
                  disabled={isRecorderCustomizationsDisabled}
                  placeholder='Type a message'
                  css={[`resize: none; height: ${pxToRem(72)};`]}
                  onKeyDown={(ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
                    ev.stopPropagation();
                    if (ev.key === 'Enter' && !ev.shiftKey) {
                      if (loading) {
                        return;
                      }
                      ev.preventDefault();
                      ev.currentTarget.blur();
                    }
                  }}
                  onBlur={() => {
                    if (recorderMessageDirty) {
                      if (loading) {
                        return;
                      }
                      enableRecorderMessage();
                    }
                  }}
                />
              </FreemiumGateTooltip>
            </StyledTextareaContainer>
          </div>
        </StyledContainer>
      )}
      <>
        <StyledSeparator />
        <StyledSwitchSection css={[spacing.mb4]}>
          <div className='title'>
            <div>Recording mode</div>
            <div className='sub-title'>
              Choose how Grain captures video during your meetings.
            </div>
          </div>
        </StyledSwitchSection>
        <OptionContainer>
          <FreemiumGateTooltip disabled={!isRecorderCustomizationsDisabled}>
            <RecorderModeSelector
              selectedSetting={myself?.settings?.recorderMode}
              onChangeSetting={onRecorderModeChange}
              width='410px'
              disabled={isRecorderCustomizationsDisabled}
            />
          </FreemiumGateTooltip>
        </OptionContainer>
      </>
      <StyledSeparator />
      <StyledSwitchSection css={[spacing.mb4]}>
        <div className='title'>
          <div>Screen share recording</div>
          <div className='sub-title'>
            Choose how Grain captures active speaker video when a user is
            screen-sharing.
          </div>
        </div>
      </StyledSwitchSection>
      <OptionContainer>
        <FreemiumGateTooltip disabled={!isRecorderCustomizationsDisabled}>
          <SpeakerOverlaySelector
            selectedSetting={myself?.settings?.speakerVideoWhenScreenshare}
            onChangeSetting={onSpeakerOverlayChange}
            width='410px'
            disabled={
              !myself?.settings?.speakerVideoWhenScreenshare ||
              isRecorderCustomizationsDisabled
            }
          />
        </FreemiumGateTooltip>
      </OptionContainer>
    </>
  );
}
