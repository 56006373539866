import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Button,
  color,
  Modal,
  spacing,
  useAnalytics,
  useRouteModal
} from '@grain/grain-ui';
import {
  ONBOARDING_ROUTE_ID,
  CALENDLY_ROUTE_ID,
  TRIAL_STARTED_ROUTE_ID
} from '@grain/components/modals/constants';
import { useWorkspace } from '@grain/api/auth';

import {
  canBookOnboarding,
  OnboardingRoleKey
} from '../../pages/Onboarding/ChooseRole';
import BusinessTrial from '../../pages/Onboarding/SlideshowModals/BusinessTrial';
import AutoRecording from '../../pages/Onboarding/SlideshowModals/AutoRecording';
import ScheduleOnboarding from '../../pages/Onboarding/SlideshowModals/ScheduleOnboarding';
import CalendarPerms from '../../pages/Onboarding/SlideshowModals/CalendarPerms';
import { media } from '@grain/styles/constants';
import { getIsOnPaidPlan } from '~/support/getIsOnPaidPlan';
import { theme } from '@grain/grain-ui/v4';

const Content = styled.div`
  display: flex;
  height: 496px;

  ${media.small} {
    flex-direction: column-reverse;
    height: auto;
  }
`;

export const LeftSide = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.small} {
    width: 100%;
  }
`;

export const RightSide = styled.div<{ width: number; background: string }>`
  position: relative;
  ${color.bg.goose};
  width: ${({ width }) => width}px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ background }) => background && `background-image: url(${background});`}
  background-size: cover;
  background-position: center;

  ${media.small} {
    width: 100%;
  }

  img {
    max-width: 100%;
  }

  .steps-indicator {
    position: absolute;
    left: 50%;
    bottom: 24px;
    transform: translateX(-50%);

    .indicator-circle {
      &.inactive {
        ${color.bg.swan};
        border-color: ${color.pigeon};
      }
      &.active {
        ${({ background }) => background && `border-color: ${color.swan};`}
      }
    }
  }
`;

export const Subtitle = styled.div`
  ${spacing.mb4};
  ${theme.tokens.typography.b4[600]};
  text-transform: uppercase;
`;

export const Title = styled.div`
  ${spacing.mb3};
  ${theme.tokens.typography.h1};
`;

export const Description = styled.div`
  ${color.fg.crow};
  ${theme.tokens.typography.b1[400]};

  a {
    color: ${color.fg.graieen};
    text-decoration: underline !important;
  }
`;

export const Buttons = styled.div`
  display: flex;

  ${media.small} {
    ${spacing.mt4};
  }

  button:first-of-type {
    ${spacing.mr2};
  }
`;

export const SlideButton = styled(Button)`
  width: 113px;
  font-style: normal;
  ${theme.tokens.typography.button};
  height: 44px;
  padding: 14px 30px;
`;

export const StyledBullet = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${theme.tokens.typography.b2[400]};
  gap: 16px;

  span {
    font-weight: 700;
  }

  div {
    display: inline-block;
  }
`;

export const bulletIconStyles = css`
  min-height: 20px;
  min-width: 20px;
`;

export const BulletDescription = styled.div`
  margin-right: 50px;
`;

export const StyledOverlay = styled.div`
  display: flex;
  width: 469px;
  flex-direction: column;
  padding: 24px;
  border-radius: 12px;
  ${color.bg.swan};
  box-shadow: 0px 5px 32px 0px rgba(0, 185, 108, 0.3);
  gap: 16px;
`;

export type StepComponentProps = {
  totalSteps: number;
  step: number;
  showSetupComplete: boolean;
  handleBack: () => void;
  handleNext: () => void;
  handleDone: () => void;
  showStepsIndicator: boolean;
  onlyTrial: boolean;
  onlyCalendarPerms: boolean;
  isOnPaidPlan: boolean;
};

type LegacyOnboardingModalProps = {
  onlyBookingScreen?: boolean;
  onlyTrial?: boolean;
  close?: null | (() => void);
  onlyCalendarPerms?: boolean;
};

export default function LegacyOnboardingModal({
  onlyBookingScreen = false,
  onlyTrial = false,
  close = null,
  onlyCalendarPerms = false
}: LegacyOnboardingModalProps = {}) {
  const existingRole = window.localStorage.getItem(OnboardingRoleKey);
  const role = existingRole ? JSON.parse(existingRole) : null;
  const { workspace, loading } = useWorkspace();
  const { close: closeOnboardingModal } = useRouteModal(ONBOARDING_ROUTE_ID);
  const { close: closeBusinessTrialModal } = useRouteModal(
    TRIAL_STARTED_ROUTE_ID
  );
  const { open: openCalendlyModal, close: closeCalendlyModal } =
    useRouteModal(CALENDLY_ROUTE_ID);
  const { trackEvent } = useAnalytics();

  const trialStarted = workspace?.workspaceSubscription.trial;

  const TOTAL_STEPS_WITH_BOOKING = trialStarted ? 3 : 2;
  const BOOKING_STEP = TOTAL_STEPS_WITH_BOOKING - 1;

  const canScheduleOnboarding = React.useMemo(
    () => canBookOnboarding(role, workspace),
    [role, workspace]
  );

  const isOnPaidPlan = getIsOnPaidPlan(workspace);
  const showSetupComplete = !onlyBookingScreen;
  const showStepsIndicator = !onlyBookingScreen && !onlyTrial;

  const [step, setStep] = useState(0);
  const steps = React.useMemo(() => {
    if (onlyTrial) return [BusinessTrial];
    if (onlyBookingScreen) return [ScheduleOnboarding];
    if (onlyCalendarPerms) return [CalendarPerms];
    return [
      ...(trialStarted ? [BusinessTrial] : []),
      AutoRecording,
      ...(canScheduleOnboarding ? [ScheduleOnboarding] : [])
    ];
  }, [
    onlyTrial,
    onlyBookingScreen,
    trialStarted,
    canScheduleOnboarding,
    onlyCalendarPerms
  ]);
  const StepComponent = steps[step];
  const totalSteps = steps.length;

  useEffect(() => {
    if (onlyBookingScreen) return;
    trackEvent('Onboarding Slideshow Viewed', {}, ['user', 'workspace']);
  }, [onlyBookingScreen, trackEvent]);

  const trackCompleted = () => {
    if (onlyBookingScreen || step < totalSteps - 1) return;
    trackEvent('Onboarding Slideshow Completed', {}, ['user', 'workspace']);
  };

  const handleCancel = () => {
    if (close) {
      close();
      trackCompleted();
    } else if (step < BOOKING_STEP && totalSteps === TOTAL_STEPS_WITH_BOOKING) {
      openCalendlyModal();
    } else {
      closeOnboardingModal();
      trackCompleted();
    }
  };

  const handleDone = () => {
    closeOnboardingModal();
    closeCalendlyModal();
    closeBusinessTrialModal();
    trackCompleted();
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  return (loading || trialStarted === undefined) &&
    !onlyCalendarPerms ? null : (
    <Modal
      onCancel={handleCancel}
      width={920}
      roundness='lg'
      centered
      closable={!onlyCalendarPerms || (step < BOOKING_STEP && totalSteps !== 1)}
    >
      <Content>
        <StepComponent
          totalSteps={totalSteps}
          step={step}
          showSetupComplete={showSetupComplete}
          handleBack={handleBack}
          handleNext={handleNext}
          handleDone={handleDone}
          showStepsIndicator={showStepsIndicator}
          onlyTrial={onlyTrial}
          onlyCalendarPerms={onlyCalendarPerms}
          isOnPaidPlan={isOnPaidPlan}
        />
      </Content>
    </Modal>
  );
}
