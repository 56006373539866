import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { ANNOUNCEMENT_ROUTE_ID } from '@grain/components/modals/constants';
import {
  Modal,
  StepsIndicator,
  useAnalytics,
  useRouteModal
} from '@grain/grain-ui';
import { Button, Icon16, theme } from '@grain/grain-ui/v4';

import {
  ModalInfo,
  ModalGraphic,
  ModalComponent
} from '~/modals/InfoGraphicActionModals';

export const ModalActions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  border-top: 1px solid ${theme.tokens.color.borderSecondary};
  padding: ${theme.tokens.spacing['2xl']};
`;

type ImageAnnouncement = {
  imageSrc: string;
  imageAlt: string;
  component?: never;
};

type ComponentAnnouncement = {
  component: React.ReactNode;
  imageAlt?: never;
  imageSrc?: never;
};

export type Announcement = (ImageAnnouncement | ComponentAnnouncement) & {
  prelude: string;
  title: string;
  body?: React.ReactNode;
  primaryButtonText?: string;
  primaryButtonAction?: () => void;
  secondaryButtonText?: string;
  secondaryButtonLink?: string;
  secondaryButtonAction?: () => void;
  showBackButton?: boolean;
  isHidden?: boolean;
  onShow?: () => void;
};

type AnnouncementProps = {
  showAnnouncementModal: boolean;
  setAnnouncementClosed: () => void;
  setAnnouncementShown: () => void;
  announcements: Announcement[];
};

export default function Announcement({
  showAnnouncementModal,
  setAnnouncementClosed,
  setAnnouncementShown,
  announcements
}: AnnouncementProps) {
  const { trackEvent } = useAnalytics();
  const { close: closeModal } = useRouteModal(ANNOUNCEMENT_ROUTE_ID);

  const [step, setStep] = useState(0);
  const totalSteps = announcements.length;
  const isLastStep = step === announcements.length - 1;

  useEffect(() => {
    // Reset the step when the announcements change.
    setStep(0);
  }, [announcements]);

  const {
    imageAlt,
    imageSrc,
    prelude,
    title,
    body,
    component,
    showBackButton,
    primaryButtonText,
    primaryButtonAction,
    secondaryButtonText,
    secondaryButtonLink,
    onShow
  } = announcements[step] ?? ({} as Announcement);

  useEffect(() => {
    if (!showAnnouncementModal) closeModal();
  }, [closeModal, showAnnouncementModal]);

  useEffect(() => {
    if (showAnnouncementModal && onShow) {
      onShow();
    }
  }, [showAnnouncementModal, onShow]);

  const handleComplete = useCallback(() => {
    primaryButtonAction?.();
    setAnnouncementShown();
    setAnnouncementClosed();
    trackEvent(
      'New Feature Announcement Completion',
      {
        announcements: announcements.map(({ title }) => title)
      },
      ['user', 'workspace']
    );
  }, [
    announcements,
    primaryButtonAction,
    setAnnouncementClosed,
    trackEvent,
    setAnnouncementShown
  ]);

  const handleCancel = useCallback(() => {
    if (isLastStep) return handleComplete();
    primaryButtonAction?.();
    setAnnouncementClosed();
    setAnnouncementShown();
  }, [
    isLastStep,
    handleComplete,
    primaryButtonAction,
    setAnnouncementClosed,
    setAnnouncementShown
  ]);

  const handleBack = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const handleNext = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  if (!showAnnouncementModal) return;

  return (
    <Modal onCancel={handleCancel} width={700} roundness='md' centered closable>
      <ModalInfo prelude={prelude} title={title} body={body} />
      {imageSrc && (
        <ModalGraphic>
          <img alt={imageAlt} src={imageSrc} />
        </ModalGraphic>
      )}
      {!imageSrc && <ModalComponent>{component}</ModalComponent>}
      <ModalActions>
        <div css={{ justifySelf: 'flex-start', gridColumn: '1 / 2' }}>
          {showBackButton && (
            <Button
              variant='ghost'
              size='lg'
              onClick={handleBack}
              textLabelProps={{ startIcon: Icon16.ArrowLeft }}
            >
              Back
            </Button>
          )}

          {secondaryButtonLink && (
            <Button
              variant='ghost'
              size='lg'
              textLabelProps={{ endIcon: Icon16.ExternalLink }}
              as={Link}
              to={secondaryButtonLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              {secondaryButtonText || 'Learn more'}
            </Button>
          )}
        </div>
        {totalSteps > 1 && (
          <div css={{ justifySelf: 'center', gridColumn: '2 / 3' }}>
            <StepsIndicator steps={totalSteps} step={step} />
          </div>
        )}
        <div css={{ justifySelf: 'flex-end', gridColumn: '3 / 4' }}>
          {!isLastStep ? (
            <Button css={['min-width: 96px;']} onClick={handleNext}>
              Next
            </Button>
          ) : (
            <Button css={['min-width: 96px;']} onClick={handleComplete}>
              {primaryButtonText || (totalSteps > 1 ? 'Done' : 'Got it')}
            </Button>
          )}
        </div>
      </ModalActions>
    </Modal>
  );
}
