import { ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PageHeader, PageTitle, LinkButton, Icon } from '@grain/grain-ui';
import { KnockFeedProvider } from '~/components/KnockFeedProvider/KnockFeedProvider';
import { getUTMParams } from '~/support/campaigns';
import { useAuth } from '~/support/auth';
import * as Layout from '~/components/Layout';
import { Helmet } from 'react-helmet';

import {
  StyledHeaderBar,
  StyledHeaderLogo,
  StyledHeaderSignup,
  flexContainer
} from './styles';

type WrapperProps = {
  children?: ReactNode;
};

export const Wrapper = ({ children }: WrapperProps) => {
  const { isAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();
  const workspaceId = searchParams.get('sso_workspace_id') || false;
  const action = searchParams.get('action') || 'web_login';
  const state = searchParams.get('state');

  return isAuthenticated ? (
    <KnockFeedProvider>
      <Layout.Wrapper withSidebar>
        <Helmet title='Download Grain' />
        <Layout.Content centered css={['height: 100%;']}>
          <PageHeader sticky css={flexContainer}>
            <PageTitle>Download</PageTitle>
          </PageHeader>
          {children}
        </Layout.Content>
      </Layout.Wrapper>
    </KnockFeedProvider>
  ) : (
    <>
      <StyledHeaderBar>
        <StyledHeaderLogo>
          <a
            href={`/?${getUTMParams({
              source: 'app',
              medium: 'site_link',
              content: 'product',
              campaign: 'signin_logo'
            })}`}
          >
            <Icon.GrainLogoAndWord />
          </a>
        </StyledHeaderLogo>
        {!workspaceId && (
          <StyledHeaderSignup>
            <LinkButton
              css={['width: 108px;']}
              size='large'
              variant='secondary'
              to={`/app/signup?${
                !action ? '' : `action=${action.split('_')[0]}_register`
              }${!state ? '' : `&state=${state}`}`}
            >
              Sign up
            </LinkButton>
          </StyledHeaderSignup>
        )}
      </StyledHeaderBar>
      {children}
    </>
  );
};
