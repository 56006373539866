import cx from 'classnames';
import styled from '@emotion/styled';
import { useEffect, useMemo, useState } from 'react';
import { OauthProvider } from '@grain/api/schema.generated';
import { useMyself } from '@grain/api/auth';
import { getBasePublicPath } from '@grain/grain-ui';

import type { Slide } from '../types';
import { NOOP_EXIT_FUNCTION } from '../constants';

const EMAIL_SLIDE_WIDTH = 1140;
const EMAIL_SLIDE_HEIGHT = 628;

const BaseEmailSlideGraphicContainer = styled.div`
  display: flex; // Fix height
  position: relative;
  overflow: hidden;
  width: 100%;

  .background-image {
    width: 100%;
    height: auto;
    aspect-ratio: ${EMAIL_SLIDE_WIDTH} / ${EMAIL_SLIDE_HEIGHT};
  }

  .email-subject {
    transition:
      opacity linear 0.5s,
      transform linear 0.5s;
    opacity: 1;
    transform: translateY(0);
    &.hidden {
      opacity: 0;
      transform: translateY(-100%);
    }
  }

  .email-recipient-and-timestamp {
    transition:
      opacity linear 0.5s,
      transform linear 0.5s;
    opacity: 1;
    transform: translateY(0);
    &.hidden {
      opacity: 0;
      transform: translateY(-100%);
    }
  }

  .email-content {
    transition:
      opacity linear 0.5s,
      transform linear 0.5s;
    opacity: 1;
    transform: translateY(0);
    &.hidden {
      opacity: 0;
      transform: translateY(10%);
    }
  }
`;

const GoogleEmailSlideGraphicContainer = styled(BaseEmailSlideGraphicContainer)`
  .email-subject {
    position: absolute;
    top: ${(62 / EMAIL_SLIDE_HEIGHT) * 100}%;
    left: ${(249 / EMAIL_SLIDE_WIDTH) * 100}%;
    width: ${(401 / EMAIL_SLIDE_WIDTH) * 100}%;
  }

  .email-recipient-and-timestamp {
    position: absolute;
    top: ${(101 / EMAIL_SLIDE_HEIGHT) * 100}%;
    left: ${(196 / EMAIL_SLIDE_WIDTH) * 100}%;
    width: ${(887 / EMAIL_SLIDE_WIDTH) * 100}%;
  }

  .email-content {
    position: absolute;
    bottom: 0;
    left: ${(346 / EMAIL_SLIDE_WIDTH) * 100}%;
    width: ${(587 / EMAIL_SLIDE_WIDTH) * 100}%;
  }
`;

const MicrosoftEmailSlideGraphicContainer = styled(
  BaseEmailSlideGraphicContainer
)`
  .email-subject {
    position: absolute;
    top: ${(48 / EMAIL_SLIDE_HEIGHT) * 100}%;
    left: ${(208 / EMAIL_SLIDE_WIDTH) * 100}%;
    width: ${(331 / EMAIL_SLIDE_WIDTH) * 100}%;
  }

  .email-recipient-and-timestamp {
    position: absolute;
    top: ${(106 / EMAIL_SLIDE_HEIGHT) * 100}%;
    left: ${(218 / EMAIL_SLIDE_WIDTH) * 100}%;
    width: ${(209 / EMAIL_SLIDE_WIDTH) * 100}%;
  }

  .email-content {
    position: absolute;
    bottom: 0;
    left: ${(377 / EMAIL_SLIDE_WIDTH) * 100}%;
    width: ${(587 / EMAIL_SLIDE_WIDTH) * 100}%;
  }
`;

export const EmailSlide: Slide = {
  question: 'Who automatically receives email summaries?',
  shortQuestion: 'Who receives summaries?',
  answer:
    'After the meeting, email summaries are sent to participants based on your settings.',
  Graphic({ transitionDelay = 0, exitFunctionRef }) {
    const [initialState, setInitialState] = useState(true);

    useEffect(() => {
      setTimeout(() => setInitialState(false), transitionDelay + 500);
      // This effect should only run once, on the first render.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    exitFunctionRef.current = NOOP_EXIT_FUNCTION;

    const { myself } = useMyself();
    const providers = useMemo(() => {
      if (!myself) return [];
      return myself?.oauthAccounts?.map(account => account.provider);
    }, [myself]);

    const provider = (() => {
      if (providers.includes(OauthProvider.Google)) {
        return OauthProvider.Google;
      } else if (providers.includes(OauthProvider.Microsoft)) {
        return OauthProvider.Microsoft;
      }
      return OauthProvider.Google; // default
    })();

    const EmailSlideGraphicContainer = (() => {
      if (provider === OauthProvider.Google) {
        return GoogleEmailSlideGraphicContainer;
      }
      if (provider === OauthProvider.Microsoft) {
        return MicrosoftEmailSlideGraphicContainer;
      }
    })()!;

    return (
      <EmailSlideGraphicContainer>
        <img
          className='background-image'
          alt='A web-based email client’s user interface, used as the background of this slide'
          src={`${getBasePublicPath()}/images/ftux-slideshow/email-client-${provider.toLowerCase()}-background.jpg`}
        />
        <img
          className={cx('email-subject', { hidden: initialState })}
          alt='The subject of the email, which is “Meeting Summary: <Meeting Name>”'
          src={`${getBasePublicPath()}/images/ftux-slideshow/email-client-${provider.toLowerCase()}-subject.png`}
        />
        <img
          className={cx('email-recipient-and-timestamp', {
            hidden: initialState
          })}
          alt='The email sender, recipient, and timestamp'
          src={`${getBasePublicPath()}/images/ftux-slideshow/email-client-${provider.toLowerCase()}-recipient-and-timestamp.png`}
        />
        <img
          className={cx('email-content', { hidden: initialState })}
          alt='The content of an email, entitled “AI meeting summary.” The meeting’s title, date, and time are displayed. The names of the meeting’s participants are listed. There are sections of the meeting summary entitled “Purpose” and “Key Takeaways,” with paragraphs of AI-generated information listed in each section.'
          src={`${getBasePublicPath()}/images/ftux-slideshow/email-content.jpg`}
        />
      </EmailSlideGraphicContainer>
    );
  }
};
