import styled from '@emotion/styled';
import { flex, TextArea, ProgressBar, Tooltip } from '@grain/grain-ui';
import { theme } from '@grain/grain-ui/v4';
import { spacing, font, color, colors } from '@grain/styles/constants';

export const PageWrapper = styled.div`
  display: inline-flex;
  ${spacing.p7};
  flex-direction: column;
  align-items: flex-end;
  min-height: 577px;
  gap: 67px;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 24px;
  ${font.bodyM};
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  span {
    ${color.crow}
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  span:last-of-type {
    margin-left: auto;
    cursor: pointer;
  }
  width: 100%;
`;
export const StyledProgressBar = styled(ProgressBar)`
  height: 8px !important;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  h1 {
    ${color.blackbird}
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    padding: 0;
    margin: 0;
  }
  p {
    ${color.crow}
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 0;
    margin: 0;
  }
`;

export const StyledOption = styled.label`
  align-items: center;
  display: flex;
  gap: 8px;

  input {
    &:disabled ~ .box {
      background-color: ${colors.gull} !important;
      border-color: ${colors.gull} !important;
    }
  }
  span {
    ${color.blackbird}
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
`;

export const OptionsSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  span {
    ${color.crow}
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }
`;

export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  span {
    ${color.crow}
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }
`;
export const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  height: 86px;
`;
export const StyledTextArea = styled(TextArea)`
  font-family: Inter;
  font-size: 12px !important;
  color: ${colors.blackbird} !important;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 8px 16px;
  flex: 1;
  border-radius: 8px;
  border: 1px solid ${colors.pigeon} !important;
  background: ${colors.swan};
`;

export const StyledButtons = styled.div`
  ${flex.direction.row};
  margin-top: auto;
  gap: 8px;
  align-self: flex-end;
  button {
    display: flex;
    padding: 14px 30px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    height: auto;
    width: auto;
  }
`;

export const OfferWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`;

export const InfoBoxWrapper = styled.div`
  display: flex;
  width: 340px;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 16px;
  border-radius: 0px 12px 12px 0px;
  border: 1px solid ${colors.gull};
  background: ${colors.goose};
`;
export const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  h6,
  p,
  span {
    color: ${colors.blackbird};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    padding: 0;
    margin: 0;
  }
  h6 {
    font-weight: 600;
  }
  p,
  span {
    font-weight: 400;
  }
  span {
    ${color.hawk};
  }
`;

export const InfoBoxConfirmCancellationWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid ${colors.gull};
  background: ${colors.goose};
  h6,
  p,
  span {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    line-height: 18px;
    margin: 0;
    padding: 0;
  }
  h6 {
    font-weight: 600;
  }
  p,
  span {
    font-weight: 400;
  }
  .marked-red {
    ${color.errorbird}
  }
  .strike-through {
    text-decoration: line-through;
  }
  .tippy-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ConfirmCancellationList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  padding-left: 22px;

  li > span {
    display: inline-flex;
    align-items: center;
    gap: ${theme.tokens.spacing.xs};
  }

  .tippy-wrapper {
    display: flex; // Fix height for vertical alignment purposes
  }

  svg {
    color: ${theme.tokens.color.iconTertiary};
  }
`;

export const StyledInfoBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    ${color.pigeon}
  }
`;

export const StyledTooltip = styled(Tooltip)`
  .tippy-content {
    padding: 2px 10px !important;
  }
  .tippy-content,
  &.tippy-box {
    border-radius: 100px !important;
  }
`;
export const StyledTooltipText = styled.div`
  display: flex;
  padding: 6px;
  align-items: center;
  span {
    color: ${colors.swan};
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;

export const StyledConfirmationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  span {
    color: ${colors.blackbird};
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-left: auto;
  }
  span:last-of-type {
    color: ${colors.pigeon};
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const StyledConfirmationContent = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 32px;
  justify-content: center;
  h5,
  p {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  h5 {
    color: ${colors.blackbird};
    font-weight: 600;
  }
  p {
    color: ${colors.crow};
    font-weight: 400;
  }
`;
