import { Link, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Icon, Skeleton } from '@grain/grain-ui';
import { theme } from '@grain/grain-ui/v4';

import type { DealDetailsFragment } from '../../deal.generated';
import type { RecordingWithFeedback } from '../../types';
import { formatDate, getMeetingPerformanceBasedOnScore } from '../../utils';

export const MeetingWithScoreEmptyState = styled.div`
  padding: ${theme.tokens.spacing.lg} ${theme.tokens.spacing['2xl']};
  ${theme.tokens.typography.b3[500]};
  color: ${theme.tokens.color.textTertiary};
`;

const StyledMeetingWithScore = styled(Link)`
  // Override link styles:
  color: inherit;
  &:hover {
    text-decoration: none;
  }

  display: flex;
  justify-content: space-between;
  padding: ${theme.tokens.spacing.lg} ${theme.tokens.spacing['2xl']};
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.tokens.color.borderTertiary};
  }

  &:hover,
  &:focus-visible {
    background-color: ${theme.tokens.color.surfaceSecondary};
  }
`;

const StyledMeetingDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  ${theme.tokens.typography.b3[500]};

  svg {
    width: 16px;
    height: 16px;
  }
`;

const StyledMeetingDate = styled.span`
  color: ${theme.tokens.color.textTertiary};
`;

type MeetingWithScoreProps = {
  deal: DealDetailsFragment;
  recording: RecordingWithFeedback;
};

export function MeetingWithScore({ deal, recording }: MeetingWithScoreProps) {
  const overallScore = recording.salesCoaching?.scoring?.scoreCard?.overall;
  const location = useLocation();
  return (
    <StyledMeetingWithScore
      to={recording.recordingPath}
      state={{
        breadcrumbs: [
          { title: 'Deals', path: '/app/deals' },
          { title: deal.name, path: location.pathname }
        ]
      }}
    >
      <StyledMeetingDetails>
        <Icon.Record16x16 />
        <span>{recording.title}</span>
        <StyledMeetingDate>
          {formatDate(new Date(recording.startDatetime))}
        </StyledMeetingDate>
      </StyledMeetingDetails>
      {typeof overallScore === 'number' && (
        <Score deal={deal} value={overallScore} />
      )}
    </StyledMeetingWithScore>
  );
}

const StyledScore = styled.div`
  display: flex;
  gap: ${theme.tokens.spacing.sm};
`;

function getScoreColor(isOpen: boolean, value: number) {
  if (!isOpen) return theme.primitives.color.grey500;
  // We only ever pass 1, 2, 3, 4, or 5 here:
  return getMeetingPerformanceBasedOnScore(value)!.color;
}

const StyledSingleScore = styled.div<{
  active: boolean;
  isOpen: boolean;
  value: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: ${theme.tokens.radii.sm};

  ${theme.tokens.typography.b4[500]};

  ${({ active, isOpen, value }) =>
    active
      ? css`
          color: ${theme.tokens.color.textInvert};
          background-color: ${getScoreColor(isOpen, value)};
        `
      : css`
          color: ${theme.tokens.color.textSecondary};
          background-color: ${theme.tokens.color.surfaceTertiary};
        `}
`;

type ScoreProps = {
  deal: DealDetailsFragment;
  value: number;
};

function Score({ value, deal }: ScoreProps) {
  return (
    <StyledScore>
      {Array(5)
        .fill(0)
        .map((_, index) => (
          <StyledSingleScore
            key={index}
            isOpen={deal.isOpen}
            value={index + 1}
            active={index + 1 === value}
          >
            {index + 1}
          </StyledSingleScore>
        ))}
    </StyledScore>
  );
}

const StyledSkeletonMeetingWithScore = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${theme.tokens.spacing.lg} ${theme.tokens.spacing['2xl']};
  border-bottom: 1px solid ${theme.tokens.color.borderTertiary};
`;

export function SkeletonMeetingWithScore() {
  return (
    <StyledSkeletonMeetingWithScore>
      <Skeleton
        width={200}
        height={16}
        css={[`border-radius: ${theme.tokens.radii.md};`]}
      />
      <Skeleton
        width={140}
        height={16}
        css={[`border-radius: ${theme.tokens.radii.md};`]}
      />
    </StyledSkeletonMeetingWithScore>
  );
}
