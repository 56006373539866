import {
  AccordionItem,
  AccordionItemProps,
  Skeleton,
  TextLabel,
  theme
} from '@grain/grain-ui/v4';
import { DealDiagnosisCompletenessIcon } from './DealDiagnosisCompletenessIcon';
import styled from '@emotion/styled';
import { CompletenessStatus } from '@grain/api/schema.generated';
import Markdown from 'react-markdown';
import { ReactNode } from 'react';

type DealDiagnosisDimensionProps = {
  label: ReactNode;
  status: CompletenessStatus;
  summary: string;
  processing: boolean;
} & Omit<AccordionItemProps, 'summary'>;

const SummaryContainer = styled.div`
  display: flex;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.tokens.spacing.xs};
  flex: 0 1 180px;
`;

const SummaryText = styled(Markdown)<{ expanded?: boolean }>`
  flex: 1;
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${props => (props.expanded ? 'unset' : 2)};
  overflow: hidden;
  ${theme.tokens.typography.b3[400]}
`;

const Container = styled.div`
  container-type: inline-size;
`;

const Content = styled.div`
  @container (min-width: 700px) {
    margin-left: 228px; // aligns with the summary text
  }
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing.lg};
`;

const AccordionSummary = ({
  label,
  status,
  summary,
  expanded,
  processing,
  disabled
}: Pick<
  DealDiagnosisDimensionProps,
  'label' | 'status' | 'summary' | 'expanded' | 'processing' | 'disabled'
>) => {
  return (
    <SummaryContainer>
      <LabelContainer>
        <DealDiagnosisCompletenessIcon
          status={processing ? 'PROCESSING' : status}
          disabled={disabled}
        />
        <TextLabel>{label}</TextLabel>
      </LabelContainer>
      {processing ? (
        <Skeleton width='100%' height={16} />
      ) : (
        <SummaryText expanded={expanded}>{summary}</SummaryText>
      )}
    </SummaryContainer>
  );
};

export const DealDiagnosisDimension = ({
  label,
  status,
  summary,
  children,
  expanded,
  processing,
  disabled,
  ...accordionItemProps
}: DealDiagnosisDimensionProps) => {
  return (
    <AccordionItem
      disabled={processing}
      summary={isExpanded => (
        <AccordionSummary
          label={label}
          status={status}
          summary={summary}
          expanded={isExpanded}
          processing={processing}
          disabled={disabled}
        />
      )}
      expanded={expanded}
      {...accordionItemProps}
    >
      <Container>
        <Content>{children}</Content>
      </Container>
    </AccordionItem>
  );
};
