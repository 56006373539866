export type PlanFeature = {
  name: string;
  description?: string;
};

export type PublicPlan = {
  sku: string;
  monthlyPrice: number;
  yearlyPrice: number;
  description: string;
  features: PlanFeature[];
  name: string;
  isFree: boolean;
  isStarter: boolean;
  selfServe: boolean;
  planNote: string;
};

export enum PlanAction {
  CANCEL_PLAN = 'CANCEL_PLAN',
  CHANGE_PLAN = 'CHANGE_PLAN',
  CURRENT_PLAN = 'CURRENT_PLAN',
  FALLBACK_AFTER_TRIAL = 'FALLBACK_AFTER_TRIAL',
  INVITE_TEAM = 'INVITE_TEAM',
  REQUEST_TRIAL = 'REQUEST_TRIAL',
  REQUEST_UPGRADE = 'REQUEST_UPGRADE',
  START_TRIAL = 'START_TRIAL',
  UPCOMING_PLAN = 'UPCOMING_PLAN',
  UPGRADE_PLAN = 'UPGRADE_PLAN',
  VIEW_MEMBERS = 'VIEW_MEMBERS'
}
