import styled from '@emotion/styled';
import { Button, ButtonProps, color } from '@grain/grain-ui';
import { forwardRef, useState, type JSX } from 'react';
import { GatedUpgradeModal } from './GatedUpgradeModal';
import { useFeature, type GatedFeatureName } from '@grain/api/auth';
const UpgradeContent = styled.div`
  gap: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;

  span {
    width: 100px;
  }
`;

const Badge = styled.div`
  border: 1px solid ${color.dipper};
  border-radius: 20px;
  padding: 1px 5px;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
`;

type GatedMenuItemProps = ButtonProps & {
  gate: GatedFeatureName;
  children: string | JSX.Element;
};

export const GatedButton = forwardRef<HTMLButtonElement, GatedMenuItemProps>(
  ({ gate, children, ...rest }, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { enabled } = useFeature(gate);

    return (
      <>
        <GatedUpgradeModal
          gate={gate}
          visible={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
        />
        <Button
          css={[!enabled && { paddingRight: '14px' }]}
          {...rest}
          onClick={!enabled ? () => setIsModalOpen(true) : rest.onClick}
          ref={ref}
        >
          {children}
          <UpgradeContent>{!enabled && <Badge>Upgrade</Badge>}</UpgradeContent>
        </Button>
      </>
    );
  }
);
