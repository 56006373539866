import React from 'react';
import PropTypes from 'prop-types';
import { BANNER_IMAGE_URLS } from '~/pages/StoryPage/constants';

import { spacing } from '@grain/styles/constants';

const IMAGES_PER_ROW = 3;
const ROW_COUNT = Math.ceil(BANNER_IMAGE_URLS.length / IMAGES_PER_ROW);

// NOTE(ajoslin): split banner images into rows
// This could be done with flex-wrap, but I wanted to try this...
const BANNER_IMAGE_URL_ROWS = [];
for (var i = 0; i < ROW_COUNT; i++) {
  const row = [];
  BANNER_IMAGE_URL_ROWS.push(row);

  for (var j = 0; j < IMAGES_PER_ROW; j++) {
    const index = i * IMAGES_PER_ROW + j;
    if (index < BANNER_IMAGE_URLS.length) {
      row.push(BANNER_IMAGE_URLS[index]);
    }
  }
}

Gallery.propTypes = {
  onSelect: PropTypes.func.isRequired
};

export default function Gallery({ onSelect }) {
  return (
    <>
      {BANNER_IMAGE_URL_ROWS.map((row, index) => (
        <div key={index} css={['display: flex;', index > 0 && spacing.mt4]}>
          {row.map((bannerImageUrl, index) => (
            <div
              key={index}
              css={[
                index > 0 && spacing.ml4,
                'cursor: pointer;',
                'width: 151px; height: 85px;',
                `background-image: url(${bannerImageUrl});`,
                'background-position: center center; background-size: cover;',
                'border-radius: 4px;'
              ]}
              onClick={() => onSelect(bannerImageUrl)}
            />
          ))}
        </div>
      ))}
    </>
  );
}
