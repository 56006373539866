import styled from '@emotion/styled';
import { theme } from '@grain/grain-ui/v4';
import { flex, pxToRem, color } from '@grain/grain-ui';
import { spacing } from '@grain/styles/constants';

export const StyledModalTitle = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${theme.tokens.spacing.sm};
  ${theme.tokens.typography.b2[600]};

  svg {
    color: ${theme.tokens.color.iconPlan};
  }
`;

export const StyledSubtitle = styled.div`
  ${spacing.pb4};
  ${flex.justifyContent.center};
  color: ${color.crow};
  font-size: ${pxToRem(16)};
  font-weight: 400;
`;

export const StyledPlanList = styled.div`
  display: flex;
  ${flex.justifyContent.center};
  flex-direction: row;
  overflow: auto;
  margin-top: ${theme.tokens.spacing['3xl']};
`;

export const StyledButtonWrapper = styled.div`
  ${flex.justifyContent.center};
  padding-top: ${theme.tokens.spacing['2xs']};
  padding-bottom: ${theme.tokens.spacing.lg};
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textTertiary};

  a {
    color: ${theme.tokens.color.textPrimary};
    cursor: pointer;
  }

  > div {
    display: flex;
    gap: ${theme.tokens.spacing.xs};
  }
`;
