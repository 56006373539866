import { Link } from 'react-router-dom';
import { getBasePublicPath } from '@grain/grain-ui';
import { Button, Icon16 } from '@grain/grain-ui/v4';

import {
  ModalInfo,
  ModalGraphic,
  ModalActions
} from '~/modals/InfoGraphicActionModals';

import { type StepComponentProps } from '../';
import { useOnboardingNextMeetingInformation } from '~/modules/freemium/useOnboardingNextMeetingInformation';

// NOTE: adapted from pages/Onboarding/SlideshowModals/AutoRecording, with
// modifications made to fulfill new design requirements.
export function AutoRecording({
  totalSteps,
  step,
  handleNext,
  handleDone
}: StepComponentProps) {
  const { autoRecordTitle, autoRecordDescription, loading } =
    useOnboardingNextMeetingInformation();

  if (loading) {
    return null;
  }

  return (
    <>
      <ModalInfo
        prelude='Setup complete!'
        title={autoRecordTitle}
        body={autoRecordDescription}
      />
      <ModalGraphic>
        <img
          alt='Illustration of an AI-generated meeting summary next to a recording. The meeting summary includes "Chapters," "Outcome," and "Action Items" sections. The illustration also includes a depiction of the settings option which controls whether meetings are automatically recorded, which is a toggle labeled "Auto-record customer meetings."'
          src={`${getBasePublicPath()}/images/onboarding/auto-recording.svg`}
        />
      </ModalGraphic>
      <ModalActions>
        <Button
          variant='ghost'
          size='lg'
          textLabelProps={{ endIcon: Icon16.ExternalLink }}
          as={Link}
          to={'/app/settings/workspace?tab=meetings'}
        >
          Change setting
        </Button>
        <Button
          size='lg'
          onClick={step === totalSteps - 1 ? handleDone : handleNext}
        >
          {step === totalSteps - 1 ? 'Get started' : 'Continue'}
        </Button>
      </ModalActions>
    </>
  );
}
