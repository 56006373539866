import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useMyself } from '@grain/api/auth';
import type { OnboardingSurveyAnswers } from '@grain/api/schema.extra';
import { Button, Icon16, theme } from '@grain/grain-ui/v4';

import {
  ModalLayout,
  ModalLogo,
  ModalInfo
} from '~/modals/LogoInfoInteractionModals';
import {
  getConnectHubspotHref,
  getConnectSalesforceHref
} from '~/modules/integrations/getConnectHrefs';

import type { StepComponentProps } from '../types';

const StyledButtonContainer = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing['2xs']};
`;

export const SUPPORTED_CRMS = ['HubSpot', 'Salesforce'];

export function ConnectCRM({ getNextHref, handleNext }: StepComponentProps) {
  const { myself } = useMyself();
  const onboardingSurveyAnswers =
    myself && (myself.onboardingSurveyAnswers as OnboardingSurveyAnswers);

  // TODO: determine selected CRM at the workspace level rather than the user
  // level.  Trial eligibility happens at the workspace level, so the user
  // starting the trial was not necessarily the user who indicated that a
  // supported CRM was being used.
  //
  // Relatedly, we need to handle the case where none of the supported CRMs were
  // selected by this user.  In that case, we don't know which single CRM to
  // show, so show all of them.
  //
  const surveyedCRM = onboardingSurveyAnswers?.ob_survey_selected_crm_type;
  const connectableCRM =
    surveyedCRM && SUPPORTED_CRMS.includes(surveyedCRM)
      ? surveyedCRM
      : undefined;

  const hubspotHref = getConnectHubspotHref(getNextHref);
  const salesforceHref = getConnectSalesforceHref(getNextHref);

  return (
    <ModalLayout>
      <ModalLogo />
      <ModalInfo
        title={
          connectableCRM
            ? `Automate your ${connectableCRM} workflow`
            : 'Automate your workflow'
        }
        body={`Connect ${connectableCRM || 'your CRM'} to auto-detect customer meetings and sync AI notes to contacts.`}
      />
      <StyledButtonContainer>
        {(!connectableCRM || connectableCRM === 'HubSpot') && (
          <Button
            as='a'
            href={hubspotHref}
            variant='stroked'
            size='xl'
            textLabelProps={{ startIcon: Icon16.Hubspot }}
            css={css`
              width: 290px;

              .button-text-label svg {
                color: #fc5c39;
              }
            `}
          >
            Connect HubSpot
          </Button>
        )}
        {(!connectableCRM || connectableCRM === 'Salesforce') && (
          <Button
            as='a'
            href={salesforceHref}
            variant='stroked'
            size='xl'
            textLabelProps={{ startIcon: Icon16.Salesforce }}
            css={css`
              width: 290px;

              .button-text-label svg {
                color: #00a1e0;
                fill: rgba(0, 161, 224, 0.2);
              }
            `}
          >
            Connect Salesforce
          </Button>
        )}
        <Button
          variant='ghost'
          size='xl'
          css={['width: 290px;']}
          onClick={() => handleNext(2)}
        >
          Skip for now
        </Button>
      </StyledButtonContainer>
    </ModalLayout>
  );
}
