import { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Icon16, theme } from '@grain/grain-ui/v4';

import {
  ModalLayout,
  ModalLogo,
  ModalInfo
} from '~/modals/LogoInfoInteractionModals';
import {
  useGetHubspotIntegration,
  useGetSalesforceIntegration
} from '~/pages/RecordingPage/hooks';
import { useFreemiumTrialOnboardingRecordingGenerateMutation } from '~/modules/freemium/freemium.generated';

import type { StepComponentProps } from '../types';

export function ConnectedCRM({ handleNext }: StepComponentProps) {
  const hubspotIntegration = useGetHubspotIntegration();
  const salesforceIntegration = useGetSalesforceIntegration();
  const hasHubspot = !!hubspotIntegration;
  const hasSalesforce = !!salesforceIntegration;

  const selectedCRM = (() => {
    if (hasHubspot) return 'HubSpot';
    if (hasSalesforce) return 'Salesforce';
  })();

  const [minimumWaitTimePromise] = useState(
    new Promise(resolve => setTimeout(resolve, 5000))
  );

  // Even though the recording might be generated quickly, try to ensure the
  // user has a moment to read the message before advancing to the next state.
  const proceedToNextStepAfterMinimumWait = useCallback(async () => {
    await minimumWaitTimePromise;
    handleNext(1);
  }, [handleNext, minimumWaitTimePromise]);

  const [freemiumTrialOnboardingRecordingGenerate] =
    useFreemiumTrialOnboardingRecordingGenerateMutation({
      onError() {
        // Swallow all errors since they are not actionable.
        proceedToNextStepAfterMinimumWait();
        return true;
      }
    });

  useEffect(() => {
    if (!selectedCRM) return; // Wait for one to be identified.
    freemiumTrialOnboardingRecordingGenerate({
      onCompleted() {
        proceedToNextStepAfterMinimumWait();
      }
    });
  }, [
    freemiumTrialOnboardingRecordingGenerate,
    proceedToNextStepAfterMinimumWait,
    selectedCRM
  ]);

  // Confirm that the integration exists before rendering anything.
  if (!selectedCRM) return <ModalLayout />;

  return (
    <ModalLayout>
      <ModalLogo />
      <ModalInfo
        title={`${selectedCRM} connected successfully!`}
        body='Finalizing setup. This should only take a few moments.'
      />
      <Icon16.AnimatedSpinner
        css={css`
          width: 96px;
          height: 96px;
          margin-top: 76px;
          color: ${theme.primitives.color.brand500};
        `}
      />
    </ModalLayout>
  );
}
