import styled from '@emotion/styled';
import { pxToRem, flex } from '@grain/grain-ui';
import { color, media, spacing, colors } from '@grain/styles/constants';

export const StyledNameContainer = styled.div`
  ${spacing.mb7};
  align-items: center;
`;

export const StyledLogo = styled.div`
  ${spacing.mt7};
  ${spacing.mb7};
`;

export const StyledSubText = styled.span`
  ${spacing.mt2};
  ${color.crow};
  font-weight: 400;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(20)};
`;

export const StyledLogoContainer = styled.div`
  margin-right: 10px;
  border-radius: 10px;
  height: 60px;
  width: 60px;
`;

export const StyledCustomizeVocabularyContainer = styled.div`
  max-width: 500px;
`;

export const StyledEditLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledEditLogoColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 7px;
`;

export const StyledEditWorkspace = styled.a`
  cursor: pointer;
  font-size: ${pxToRem(14)};
  text-decoration: underline;
  font-weight: 400;
  line-height: ${pxToRem(20)};
`;

export const StyledWorkspaceAccessContainer = styled.div`
  ${spacing.mt6};
  display: flex;
  justify-content: space-between;

  & > div {
    width: 50%;
  }

  ${media.smallOrMedium} {
    flex-direction: column;

    & > div:not(div:first-of-type) {
      ${spacing.mt6};
    }
    & > div {
      width: 100%;
    }
  }
`;

export const StyledRecordingAccessContainer = styled.div`
  ${spacing.mt6};
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .sub-heading {
    ${color.blackbird};
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
    font-feature-settings:
      'case' on,
      'cpsp' on;
    display: flex;
    justify-content: center;
    flex-direction: column;

    span {
      ${color.crow};
      font-size: ${pxToRem(14)};
      line-height: ${pxToRem(18)};
      font-style: normal;
      font-weight: 400;
      ${spacing.mt2};
    }
  }

  .group-heading {
    ${color.blackbird};
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(18)};
    line-height: ${pxToRem(22)};
    display: flex;
    justify-content: center;
    flex-direction: column;

    span {
      font-size: ${pxToRem(14)};
      line-height: ${pxToRem(18)};
      font-style: normal;
      font-weight: 400;
      ${spacing.mt2};
    }
  }
  .option-select-button {
    background-color: ${colors.gull} !important;
    border: none !important;
  }

  .option-select-arrow {
    margin-right: 16px !important;
  }
`;

export const StyledOptionWrapper = styled.div`
  font-size: calc(14rem / 16);

  .option-label {
    ${spacing.ml2};
    cursor: default;
  }

  .switch-and-option {
    align-items: center;
    display: flex;
    max-width: 500px;
  }

  .switch-margin {
    ${spacing.mt4};
  }
`;

export const StyledSectionDescription = styled.div`
  ${spacing.mt1};
  font-style: normal;
  font-weight: 400;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(16)};
  font-feature-settings:
    'case' on,
    'cpsp' on;
  ${color.crow};
`;

export const StyledDeleteWorkspace = styled.div`
  .delete-link {
    ${color.crow};
    cursor: pointer;
    font-size: ${pxToRem(14)};
    font-weight: 400;
    line-height: ${pxToRem(20)};
    text-decoration: underline;
  }

  .delete-description {
    ${color.crow};
    ${spacing.mt2};
    font-size: ${pxToRem(12)};
    font-weight: 400;
  }
`;

export const StyledContainer = styled.div`
  ${spacing.mt6};
  ${flex.direction.row};
  ${flex.justifyContent.spaceBetween};
  ${flex.alignItems.center};
`;

export const StyledDivider = styled.div`
  border-bottom: 1px solid ${colors.gull};
  margin: 32px -32px;
`;

export const StyledOption = styled.label`
  align-items: center;
  display: flex;
  ${spacing.mb4};
  ${spacing.mt0};

  input {
    &:disabled ~ .box {
      background-color: ${colors.gull} !important;
      border-color: ${colors.gull} !important;
    }
  }

  .option-label {
    ${spacing.ml2};
    ${flex.direction.column};
    font-size: ${pxToRem(14)};
    font-weight: 400;
    line-height: ${pxToRem(18)};
  }
`;

export const StyledOptionTitle = styled.div`
  ${color.blackbird};
  ${spacing.ml2};
  ${flex.direction.column};
  font-size: ${pxToRem(14)};

  span {
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(16)};
    ${color.crow};
    .option-reset {
      cursor: pointer;
      ${color.graieen};
    }
  }

  .option-subtitle {
    ${color.graieen};
    font-size: calc(12rem / 16);
  }

  .option-label {
    align-items: center;
    display: flex;
    svg {
      ${spacing.ml1};
    }
  }
`;

export const StyledLockAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
