import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Button,
  Checkbox,
  Menu,
  Thumbnail,
  theme,
  Skeleton,
  Icon16,
  TextLabel,
  type BreadcrumbLocationState
} from '@grain/grain-ui/v4';
import { Duration } from 'components/atoms/Duration/Duration';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { AdHocRecordingFragment } from '~/modules/contentFilter/contentfilter.generated';
import {
  ButtonMetadata,
  MetadataContainer
} from '~/modules/meetings/ButtonMetadata';
import { CompanyMetaItem } from '~/modules/meetings/CompanyMetaItem';
import { ParticipantsMenu } from '~/modules/meetings/ParticipantsMenu';
import { MeetingOverflowMenu } from './MeetingOverflowMenu';
import { useMedia } from 'react-use';
import { useWorkspace } from '@grain/api/auth';
import { OwnerMetaItem } from '~/modules/meetings/OwnerMetaItem';
import { LabelWithValue } from '~/modules/filtersV2/types';
import { useMeetingMetadataItems } from '~/modules/meetings/useMeetingMetadataItems';
import { TrackerMetaItem } from '~/modules/meetings/TrackerMetaItem';
import { TagMetaItem } from '~/modules/meetings/TagMetaItem';
import { RecordingsParticipantScope } from '@grain/api/schema.generated';
import { MetadataItemId, MetadataMap } from '~/modules/meetings/MetadataItems';

const StyledCheckbox = styled(Checkbox)<{ forceShow?: boolean }>`
  visibility: hidden;

  ${({ forceShow }) =>
    forceShow &&
    css`
      visibility: visible;
    `}
`;

const generateGridTemplateColumns = (
  enabledMetadataItems: MetadataItemId[]
) => {
  const columns = enabledMetadataItems.map(
    item => MetadataMap.get(item)?.gridColumnWidth
  );
  return columns.join(' ');
};

type ContainerProps = {
  disableCheckbox?: boolean;
  enabledMetadataItems: MetadataItemId[];
};

const Container = styled(Link)<ContainerProps>`
  ${theme.utils.py('14px')}
  padding-right: ${theme.tokens.spacing['3xl']};
  display: grid;
  align-items: center;
  grid-template-columns: auto minmax(110px, 1fr) ${({ enabledMetadataItems }) =>
      generateGridTemplateColumns(enabledMetadataItems)} auto;
  flex-shrink: 0;
  gap: ${theme.tokens.spacing.md};
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: ${theme.tokens.color.surfaceSecondary};
    text-decoration: none;

    & ${StyledCheckbox} {
      visibility: visible;
    }
  }

  @media (${theme.tokens.breakpoints.mdMax}) {
    ${theme.utils.px('lg')};
  }

  @media (${theme.tokens.breakpoints.lgMin}) {
    grid-template-columns: auto minmax(220px, 1fr) ${({
        enabledMetadataItems
      }) => generateGridTemplateColumns(enabledMetadataItems)} auto;
  }

  ${({ disableCheckbox }) =>
    disableCheckbox &&
    css`
      &:hover {
        & ${StyledCheckbox} {
          visibility: hidden;
        }
      }
    `}
`;

const Title = styled.h3`
  ${theme.tokens.typography.b3[500]};
  color: ${theme.tokens.color.textPrimary};
  padding: 0;
  margin: 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Meta = styled.div`
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textTertiary};
`;

type MeetingOverflowMenuProps = React.ComponentProps<
  typeof MeetingOverflowMenu
>;

type MeetingListItemProps = {
  recording: AdHocRecordingFragment;
  selected: boolean;
  toggleSelected: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;
  forceShowCheckbox: boolean;
  onClickShare: () => void;
  overflowOptions: MeetingOverflowMenuProps['overflowOptions'];
  disableCheckbox?: boolean;
  setOwnerFilter?: (owner: LabelWithValue) => void;
  setCompanyFilter?: (company: LabelWithValue) => void;
  setPersonFilter?: (person: LabelWithValue) => void;
  canConfigureMetadataItems?: boolean;
  breadcrumbs?: BreadcrumbLocationState[];
};

const getRecordingThumbnailProps = (recording: AdHocRecordingFragment) => {
  const imgSrc =
    recording.fullWebpThumbnailUrl || recording.fullJpegThumbnailUrl;

  if (recording.locked) {
    return { isLocked: true };
  }

  if (imgSrc) {
    return {
      imgSrc
    };
  }

  return { isLoading: true };
};

const BasicMetadata = styled(TextLabel)`
  color: ${theme.tokens.color.textSecondary};
`;

type MetadataItemProps = {
  icon: (typeof Icon16)[keyof typeof Icon16];
  value: number | string;
  width?: 'sm' | 'md' | 'lg';
};

const MetadataItem = ({
  icon: Icon,
  value,
  width = 'sm'
}: MetadataItemProps) => (
  <ButtonMetadata width={width} disableInteractive>
    <BasicMetadata size='sm' startIcon={Icon} metadata={`${value ?? '0'}`} />
  </ButtonMetadata>
);

const EMPTY_ARRAY: never[] = [];

export const MeetingListItem = ({
  recording,
  selected,
  toggleSelected,
  forceShowCheckbox,
  onClickShare,
  overflowOptions,
  disableCheckbox = false,
  setOwnerFilter,
  setCompanyFilter,
  setPersonFilter,
  canConfigureMetadataItems = false,
  breadcrumbs
}: MeetingListItemProps) => {
  const isSmall = useMedia(theme.tokens.breakpoints.mdMax);
  const { workspace } = useWorkspace();
  const { selectedMetadataItems, defaultMetadataItems } =
    useMeetingMetadataItems();

  let metadataItems = canConfigureMetadataItems
    ? selectedMetadataItems
    : defaultMetadataItems;

  if (isSmall) {
    metadataItems = EMPTY_ARRAY;
  }

  return (
    <Container
      to={recording.recordingPath}
      state={{
        breadcrumbs: breadcrumbs
      }}
      role='article'
      data-cy='meeting-list-item'
      disableCheckbox={disableCheckbox || isSmall}
      enabledMetadataItems={metadataItems}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: ${theme.tokens.spacing.sm};

          @media (${theme.tokens.breakpoints.lgMin}) {
            padding-left: ${theme.tokens.spacing.sm};
          }
        `}
      >
        {!isSmall && (
          <StyledCheckbox
            size='sm'
            onClick={e => e.stopPropagation()}
            onChange={event => toggleSelected(event, recording.id)}
            checked={selected}
            forceShow={forceShowCheckbox}
          />
        )}
        <Thumbnail
          {...getRecordingThumbnailProps(recording)}
          css={['height: 36px;']}
        />
      </div>

      <div
        css={css`
          overflow: hidden;
          white-space: nowrap;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            margin: 0 0 ${theme.tokens.spacing['2xs']} 0;
          `}
        >
          <Title title={recording.title}>{recording.title}</Title>
          {recording.workspaceShared && (
            <Icon16.BuildingSm
              css={css`
                flex-shrink: 0;
                margin-left: ${theme.tokens.spacing.xs};
                color: ${theme.tokens.color.iconDisabled};
              `}
            />
          )}
        </div>
        <Meta>
          {format(new Date(recording.startDatetime), 'MMM, do h:mm a')} ·{' '}
          <Duration
            durationMs={
              new Date(recording.endDatetime ?? '').getTime() -
              new Date(recording.startDatetime).getTime()
            }
          />
        </Meta>
      </div>

      {metadataItems.includes('owner') && (
        <OwnerMetaItem
          owner={recording.owner}
          setOwnerFilter={setOwnerFilter}
          stopClickPropagation
        />
      )}
      {metadataItems.includes('company') && (
        <CompanyMetaItem
          participantScope={recording.participantScope}
          externalGroupsData={recording.externalGroupsData}
          workspaceName={workspace?.name || null}
          workspaceLogoUrl={workspace?.logoUrl || null}
          setCompanyFilter={setCompanyFilter}
          stopClickPropagation
        />
      )}
      {metadataItems.includes('participants') && (
        <MetadataContainer width='sm'>
          <ParticipantsMenu
            externalGroupsData={recording.externalGroupsData}
            participants={recording.participants}
            owner={recording.owner}
            recordingId={recording.id}
            tippyProps={{
              placement: 'bottom-end'
            }}
            setPersonFilter={setPersonFilter}
            stopClickPropagation
          />
        </MetadataContainer>
      )}
      {metadataItems.includes('meetingTags') && (
        <TagMetaItem tags={recording.tags.map(tag => tag.text)} />
      )}
      {metadataItems.includes('trackers') && (
        <TrackerMetaItem
          trackers={recording.smartTagCounts.map(tag => ({
            tracker: tag.tag.text,
            count: tag.count
          }))}
          viewInstancesUrl={`${recording.recordingPath}?tab=trackers`}
        />
      )}
      {metadataItems.includes('comments') && (
        <MetadataItem
          icon={Icon16.Comment}
          value={recording.comments.list.length}
        />
      )}
      {metadataItems.includes('clips') && (
        <MetadataItem icon={Icon16.Clip} value={recording.clipCount} />
      )}
      {metadataItems.includes('views') && (
        <MetadataItem icon={Icon16.Eye} value={recording.views} />
      )}
      {metadataItems.includes('meetingType') && (
        <MetadataItem
          width='lg'
          icon={Icon16.Record}
          value={
            recording.participantScope === RecordingsParticipantScope.External
              ? 'Customer Meeting'
              : 'Team Meeting'
          }
        />
      )}
      <Menu
        unmountOnClose
        hideOnClickInside
        stopClickPropagation
        tippyProps={{ placement: 'bottom-end' }}
        width='232px'
        content={
          <MeetingOverflowMenu
            recording={recording}
            onClickShare={onClickShare}
            overflowOptions={overflowOptions}
          />
        }
      >
        <Button
          data-cy='meeting-overflow'
          variant='ghost'
          size='md'
          icon={Icon16.Overflow}
        />
      </Menu>
    </Container>
  );
};

MeetingListItem.Skeleton = () => (
  <Container
    to=''
    css={css`
      padding-left: ${theme.tokens.spacing['3xl']};
    `}
    enabledMetadataItems={[]}
  >
    <Skeleton height={36} width={64} />
    <Skeleton height={16} width={200} />
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: ${theme.tokens.spacing.sm};
      `}
    >
      <Skeleton height={16} width={140} />
      <Skeleton height={16} width={140} />
      <Skeleton height={16} width={140} />
      <Skeleton height={16} width={20} />
    </div>
  </Container>
);
