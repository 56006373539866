import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  DealMomentumState,
  type Group,
  type Workspace
} from '@grain/api/schema.generated';
import { capitalize, Icon, resetButtonStyles } from '@grain/grain-ui';
import {
  Divider,
  Menu,
  MenuHeader,
  TextLabel,
  theme
} from '@grain/grain-ui/v4';

import type {
  DealDetailsFragment,
  DealDetailsRecordingFragment,
  DealEmailFragment
} from '../../deal.generated';
import { formatDate } from '../../utils';

import { DealEmailModal } from '../DealEmailModal';

export type ActivitySectionName = 'team' | 'customer' | 'meetings';

// It's somewhat-redundant to have this type in addition to the 2 more-specific
// types below, however I'm exporting this type because it seems to be much
// simpler to dynamically construct ActivitySections in MomentumTimeline when
// the type is specified like this.
export type ActivitySection = {
  name: ActivitySectionName;
  activity: (DealEmailFragment | DealDetailsRecordingFragment)[];
};

type ActivityEmailsSection = {
  name: 'team' | 'customer';
  activity: DealEmailFragment[];
};
type ActivityMeetingsSection = {
  name: 'meetings';
  activity: DealDetailsRecordingFragment[];
};

function formatTime(timestamp: string) {
  return format(new Date(timestamp), 'h:mm aaa');
}

const momentumToColor: { [K in DealMomentumState]: string } = {
  PROGRESSING: theme.tokens.color.iconBrand,
  SLOWING: theme.tokens.color.iconWarning,
  STALLED: theme.tokens.color.iconDanger
};

function getIconColor(isOpen: boolean, momentum?: DealMomentumState) {
  if (!isOpen || !momentum) return theme.tokens.color.iconDisabled;
  return momentumToColor[momentum];
}

const StyledEmptyState = styled.div`
  padding: ${theme.tokens.spacing.sm};
  ${theme.tokens.typography.b3[500]};
  color: ${theme.tokens.color.textTertiary};
`;

const StyledListItem = styled(TextLabel)`
  // Override button styles (when <StyledListItem as='button' ... />):
  ${resetButtonStyles}
  text-align: start;

  // Override link styles (when <StyledListItem as={Link} ... />):
  &:hover {
    text-decoration: none;
  }

  padding: ${theme.tokens.spacing.sm};
  border-radius: ${theme.tokens.radii.md};
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background-color: ${theme.tokens.color.buttonGhostHover};
  }

  &:active {
    background-color: ${theme.tokens.color.buttonGhostClick};
  }

  & ${TextLabel.Text} {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & ${TextLabel.Metadata} {
    white-space: nowrap;
  }
`;

type StyledListTitleProps = {
  isOpen: boolean;
  momentum?: DealMomentumState;
  isCustomer?: boolean;
};

const StyledListTitle = styled(TextLabel)<StyledListTitleProps>`
  padding: ${theme.tokens.spacing.sm};
  color: ${theme.tokens.color.textSecondary};

  & ${TextLabel.Text} {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${({ momentum, isCustomer, isOpen }) => css`
    & ${TextLabel.LabelIcon} {
      color: ${getIconColor(isOpen, momentum)};

      ${isCustomer && 'opacity: 0.4;'}

      .last {
        opacity: 0.4;
      }
    }
  `}
`;

const EMPTY_ARRAY: never[] = [];

type ActivityForDateMenuProps = {
  workspace: Pick<Workspace, 'name' | 'logoUrl'>;
  deal: DealDetailsFragment;
  group: Pick<Group, 'name'>;
  date: Date;
  isOpenDate?: boolean;
  stageUpdate?: string | null;
  momentum?: DealMomentumState;
  sections?: ActivitySection[];
} & Omit<React.ComponentProps<typeof Menu>, 'content'>;

export function ActivityForDateMenu({
  workspace,
  deal,
  group,
  date,
  isOpenDate,
  stageUpdate,
  momentum,
  sections = EMPTY_ARRAY,
  ...restMenuProps
}: ActivityForDateMenuProps) {
  const metadata = (() => {
    if (isOpenDate) return 'Deal Opened';
    if (stageUpdate) return `Updated to ${stageUpdate}`;
    if (momentum) return capitalize(momentum.toLowerCase());
  })();

  const teamSection = sections.find(
    (section): section is ActivityEmailsSection => section.name === 'team'
  );
  const customerSection = sections.find(
    (section): section is ActivityEmailsSection => section.name === 'customer'
  );
  const meetingsSection = sections.find(
    (section): section is ActivityMeetingsSection => section.name === 'meetings'
  );

  const [email, setEmail] = useState<DealEmailFragment | null>();
  const openEmailModal = useCallback((email: DealEmailFragment) => {
    setEmail(email);
  }, []);
  const closeEmailModal = useCallback(() => {
    setEmail(null);
  }, []);

  return (
    <>
      <Menu
        width='272px'
        focusFirstElement={false}
        tippyProps={{
          trigger: 'mouseenter focus',
          hideOnClick: false
        }}
        content={
          <>
            <MenuHeader metadata={metadata} variant='subheader'>
              {formatDate(date)}
            </MenuHeader>
            <Divider />
            {!(teamSection || customerSection || meetingsSection) && (
              <StyledEmptyState>No Activity</StyledEmptyState>
            )}
            {meetingsSection && (
              <>
                <StyledListTitle
                  size='sm'
                  endIcon={Icon.Dots}
                  isOpen={deal.isOpen}
                  momentum={momentum}
                >
                  Meetings
                </StyledListTitle>
                {meetingsSection.activity.map(recording => (
                  <StyledListItem
                    key={recording.id}
                    as={Link}
                    to={recording.recordingPath}
                    startIcon={Icon.Record16x16}
                    metadata={formatTime(recording.startDatetime)}
                  >
                    {recording.title}
                  </StyledListItem>
                ))}
              </>
            )}
            {meetingsSection && teamSection && <Divider />}
            {teamSection && (
              <>
                <StyledListTitle
                  size='sm'
                  avatarProps={{
                    name: workspace.name,
                    url: workspace.logoUrl ?? undefined,
                    variant: 'square'
                  }}
                  endIcon={Icon.Dot}
                  isOpen={deal.isOpen}
                  momentum={momentum}
                >
                  {workspace.name}
                </StyledListTitle>
                {teamSection.activity.map(email => (
                  <StyledListItem
                    key={email.id}
                    as='button'
                    startIcon={Icon.Mail16x16}
                    metadata={formatTime(email.sentAt)}
                    onClick={() => openEmailModal(email)}
                  >
                    Email from {email.from.name}
                  </StyledListItem>
                ))}
              </>
            )}
            {(meetingsSection || teamSection) && customerSection && <Divider />}
            {customerSection && (
              <>
                <StyledListTitle
                  size='sm'
                  avatarProps={{
                    name: group.name,
                    variant: 'square'
                  }}
                  endIcon={Icon.Dot}
                  isOpen={deal.isOpen}
                  momentum={momentum}
                  isCustomer
                >
                  {group.name}
                </StyledListTitle>
                {customerSection.activity.map(email => (
                  <StyledListItem
                    key={email.id}
                    as='button'
                    startIcon={Icon.Mail16x16}
                    metadata={formatTime(email.sentAt)}
                    onClick={() => openEmailModal(email)}
                  >
                    Email from {email.from.name}
                  </StyledListItem>
                ))}
              </>
            )}
          </>
        }
        {...restMenuProps}
      />
      {email && (
        <DealEmailModal deal={deal} email={email} onCancel={closeEmailModal} />
      )}
    </>
  );
}
