import pluralize from 'pluralize';
import { format, formatISO } from 'date-fns';

import { DealActivityFragment } from './deal.generated';
import { DealMomentumState } from '@grain/api/schema.generated';
import { theme } from '@grain/grain-ui/v4';

function countWeekdays(startDate: Date, endDate: Date): number {
  if (startDate > endDate) {
    [startDate, endDate] = [endDate, startDate];
  }

  const msPerDay = 24 * 60 * 60 * 1000;
  const totalDays = Math.floor(
    (endDate.getTime() - startDate.getTime()) / msPerDay
  );

  if (totalDays < 0) {
    return 0;
  }

  const fullWeeks = Math.floor(totalDays / 7);
  let weekdays = fullWeeks * 5;
  const remainingDays = totalDays % 7;

  // Determine the day of the week of the start date (0 = Sunday, 6 = Saturday)
  const startDay = startDate.getDay();

  // Add the remaining weekdays
  for (let i = 0; i <= remainingDays; i++) {
    const currentDay = (startDay + i) % 7;
    // Count the day if it's a weekday (not Sunday (0) or Saturday (6))
    if (currentDay > 0 && currentDay < 6) {
      weekdays++;
    }
  }

  return weekdays;
}

const ONE_MINUTE = 60 * 1000;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;
const ONE_WEEK = 7 * ONE_DAY;
const ONE_MONTH = 30 * ONE_DAY;
const ONE_YEAR = 365 * ONE_DAY;

export function formatTimeAgoWithWeekdays(
  startDate: Date,
  endDate: Date = new Date()
) {
  const difference = Number(endDate) - Number(startDate);
  if (difference < ONE_MINUTE) {
    return 'Just now';
  } else if (difference < ONE_HOUR) {
    const minutes = Math.floor(difference / ONE_MINUTE);
    return `${minutes} ${pluralize('min', minutes)} ago`;
  } else if (difference < ONE_DAY) {
    const hours = Math.floor(difference / ONE_HOUR);
    return `${hours} ${pluralize('hr', hours)} ago`;
  }
  const weekdays = countWeekdays(startDate, endDate);
  if (weekdays < 7) {
    return `${weekdays} ${pluralize('weekday', weekdays)} ago`;
  } else if (difference < ONE_MONTH) {
    const weeks = Math.floor(difference / ONE_WEEK);
    return `${weeks} ${pluralize('week', weeks)} ago`;
  } else if (difference < ONE_YEAR) {
    // Since months are estimated at 30 days, by 360 days we would report
    // "12 months ago," but we should never report more than "11 months" before
    // reporting "over a year ago."  (It's an estimate anyway.)
    const months = Math.min(11, Math.floor(difference / ONE_MONTH));
    return `${months} ${pluralize('month', months)} ago`;
  }
  return 'Over a year ago';
}

export function formatDuration(startDate: Date, endDate: Date) {
  const difference = Number(endDate) - Number(startDate);
  if (difference < ONE_MINUTE) {
    return 'Less than 1 min';
  } else if (difference < ONE_HOUR) {
    const minutes = Math.floor(difference / ONE_MINUTE);
    return `${minutes}m`;
  } else if (difference < ONE_DAY) {
    const hours = Math.floor(difference / ONE_HOUR);
    return `${hours}${pluralize('hr', hours)}`;
  } else if (difference < ONE_WEEK) {
    const days = Math.floor(difference / ONE_DAY);
    return `${days} ${pluralize('day', days)}`;
  } else if (difference < ONE_MONTH) {
    const weeks = Math.floor(difference / ONE_WEEK);
    return `${weeks} ${pluralize('week', weeks)}`;
  } else if (difference < ONE_YEAR) {
    // Since months are estimated at 30 days, by 360 days we would report
    // "12 months ago," but we should never report more than "11 months" before
    // reporting "over a year ago."  (It's an estimate anyway.)
    const months = Math.min(11, Math.floor(difference / ONE_MONTH));
    return `${months} ${pluralize('month', months)}`;
  }
  return 'Over a year';
}

export function countHours(startDate: Date, endDate: Date = new Date()) {
  const difference = Number(endDate) - Number(startDate);
  return Math.floor(difference / ONE_HOUR);
}

export function formatWaitingTime(startDate: Date, endDate: Date = new Date()) {
  const difference = Number(endDate) - Number(startDate);
  if (difference < ONE_DAY * 2) {
    const hours = Math.floor(difference / ONE_HOUR);
    return `${hours} ${pluralize('hr', hours)}`;
  }
  const weekdays = countWeekdays(startDate, endDate);
  if (weekdays < 7) {
    return `${weekdays} ${pluralize('weekday', weekdays)}`;
  } else if (difference < ONE_MONTH) {
    const weeks = Math.floor(difference / ONE_WEEK);
    return `${weeks} ${pluralize('week', weeks)}`;
  } else if (difference < ONE_YEAR) {
    // Since months are estimated at 30 days, by 360 days we would report
    // "12 months ago," but we should never report more than "11 months" before
    // reporting "over a year ago."  (It's an estimate anyway.)
    const months = Math.min(11, Math.floor(difference / ONE_MONTH));
    return `${months} ${pluralize('month', months)}`;
  }
  return 'Over a year';
}

export function formatDate(date: Date) {
  return format(date, 'MMM d');
}

export function getLastDates(days = 30, startDate = new Date()) {
  return Array.from({ length: days }, (_, i) => {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() - (days - 1) + i);
    return date;
  });
}

export function formatDateString(date: Date) {
  return formatISO(date, { representation: 'date' });
}

export function getActivityTimestamp(item: DealActivityFragment) {
  switch (item.__typename) {
    case 'DealEmail':
      return item.sentAt;
    case 'DealRecordingView':
      return item.viewedAt;
    case 'DealStageChange':
      return item.changedAt;
    case 'Recording':
      return item.startDatetime;
    default:
      return '';
  }
}

const EMPTY_ARRAY: never[] = [];

export function getAtRiskReasons(
  atRisk: boolean,
  momentum: DealMomentumState,
  overallScore: number | null
): string[] {
  if (!atRisk || !overallScore) return EMPTY_ARRAY;

  const reasons: string[] = [];
  const isStalled = momentum === DealMomentumState.Stalled;
  const isSlowing = momentum === DealMomentumState.Slowing;
  const isPoor = overallScore === 1 || overallScore === 2;

  if (isStalled || isPoor) {
    if (isStalled) reasons.push('Stalled Momentum');
    if (isPoor) reasons.push('Poor Scorecard Average');
  } else if (isSlowing && overallScore >= 1 && overallScore <= 3) {
    reasons.push('Slowing Momentum');
    if (overallScore === 3) reasons.push('Fair Scorecard Average');
    else reasons.push('Poor Scorecard Average');
  }

  return reasons;
}

type Performance = 'Strong' | 'Fair' | 'Poor';
type PerformanceResult = {
  text: Performance;
  color: string;
} | null;

const PERFORMANCE_THRESHOLDS = {
  STRONG: 4,
  FAIR: 3
} as const;

/**
 * Get the meeting performance text based on the score.
 * @param score - The score to get the performance for.
 * @returns The performance based on the score.
 */
export function getMeetingPerformanceBasedOnScore(
  score: number | null
): PerformanceResult {
  if (score === null || score < 1 || score > 5) {
    return null;
  }

  if (score >= PERFORMANCE_THRESHOLDS.STRONG) {
    return { text: 'Strong', color: theme.tokens.color.iconBrand };
  }
  if (score >= PERFORMANCE_THRESHOLDS.FAIR) {
    return { text: 'Fair', color: theme.tokens.color.iconWarning };
  }
  return { text: 'Poor', color: theme.tokens.color.iconDanger };
}
