import { Icon } from '@grain/grain-ui';
import { Button } from '@grain/grain-ui/v4';

type LoginType = 'Google' | 'Microsoft' | 'Zoom' | 'SSO';

type LoginButtonProps = React.ComponentProps<typeof Button> & {
  loginType?: LoginType;
};

const LoginIconMap: Record<LoginType, React.ComponentType> = {
  Google: () => <Icon.Google />,
  Microsoft: () => <Icon.Microsoft />,
  Zoom: () => <Icon.Zoom css={['width: 16px; height: 16px;']} />,
  SSO: () => <Icon.KeyholeCircle />
};

export function LoginButton({
  as = 'a',
  loginType = 'Google',
  size = 'xl',
  variant = 'stroked',
  ...rest
}: LoginButtonProps) {
  const loginIcon = LoginIconMap[loginType];
  return (
    <Button
      as={as}
      size={size}
      variant={variant}
      textLabelProps={{ startIcon: loginIcon }}
      {...rest}
    />
  );
}
