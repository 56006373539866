import { css } from '@emotion/react';
import { HotspotTooltip } from '@grain/grain-ui';
import { theme } from '@grain/grain-ui/v4';

type Props = Omit<
  React.ComponentProps<typeof HotspotTooltip>,
  'title' | 'description'
>;

export const AskAI = (props: Props) => {
  return (
    <HotspotTooltip
      showStepNumber={false}
      title='Ask AI about your meetings'
      actionText='Done'
      description={
        <>
          <div
            css={css`
              margin-bottom: ${theme.tokens.spacing.md};
            `}
          >
            Get instant answers about discussion topics, decisions, timelines,
            and more.
          </div>
          <span
            css={css`
              color: ${theme.tokens.color.textTertiary};
            `}
          >
            AI can make mistakes, check important info.
          </span>
        </>
      }
      {...props}
    />
  );
};
