import { Helmet } from 'react-helmet';
import { Button, Divider, Icon20, PageHeader, theme } from '@grain/grain-ui/v4';
import * as Layout from '~/components/Layout';
import { css } from '@emotion/react';
import { Icon, UPLOADS_ACCEPT, useAnalytics } from '@grain/grain-ui';
import { StyledDashedBox } from './styles';
import { useMemo, useRef, useState } from 'react';
import { useFileUploadSetup } from './useFileUploadSetup';
import { MeetingDateHeader } from '~/modules/meetings/MeetingDateHeader';
import { UploadedMeetingItem } from './components/UploadedMeetingItem';
import { RecordingFileUpload } from 'lib/upload/RecordingFileUploadContext';
import { GatedContentWrapper } from '~/modules/gates';
import { useExperiment } from '@grain/api/auth';
import { isDesktopApp } from '@grain/desktop-lib';

const UploadRecordingPage = () => {
  const { toggleCollapsed } = Layout.useSidebar();
  const [showHighlight, setShowHighlight] = useState(false);
  const fileInputField = useRef<HTMLInputElement | null>(null);
  const { trackEvent } = useAnalytics();
  const { uploadedRecordings, handleNewFileUpload, removeUpload } =
    useFileUploadSetup();
  const { enabled: isFreemiumEnabled } = useExperiment('freemium');

  // Under Freemium, access to the Upload Meetings page is restricted to all
  // users on Free workspaces; checking "upload_recording" achieves that.
  //
  // Before Freemium, all users on Free workspaces would be able to access the
  // page.  Only Free Viewers on Paid plans would be restricted; checking
  // "create_recording" achieves that.
  const applicableGate = isFreemiumEnabled
    ? 'upload_recording'
    : 'create_recording';

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    trackEvent(
      'File Upload Enqueued',
      {
        trigger: 'file_upload',
        num_files_enqueued: e?.target?.files?.length,
        upload_queue_length: Object.values(uploadedRecordings)?.filter(
          upload => !upload.complete && !upload.error
        ).length
      },
      ['user', 'workspace']
    );

    const fileList = Array.from(e.target.files || []);

    for (const file of fileList) {
      await handleNewFileUpload(file);
    }
  };

  const fileUploads = useMemo(() => {
    return Object.entries(uploadedRecordings) as [
      string,
      RecordingFileUpload
    ][];
  }, [uploadedRecordings]);

  const dragEvent = (val: boolean) => (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setShowHighlight(val);
  };

  const handleOnDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    setShowHighlight(false);
    e.preventDefault();
    e.stopPropagation();
    trackEvent(
      'File Upload Enqueued',
      {
        trigger: 'drag_and_drop',
        num_files_enqueued: e?.dataTransfer?.files?.length,
        upload_queue_length: Object.values(uploadedRecordings)?.filter(
          upload => !upload.complete && !upload.error
        ).length
      },
      ['user', 'workspace']
    );

    const fileList = Array.from(e.dataTransfer.files || []);

    for (const file of fileList) {
      await handleNewFileUpload(file);
    }
  };

  return (
    <>
      <Helmet title='Upload a Meeting' />
      <Layout.Wrapper withSidebar>
        <PageHeader
          title='Upload Meetings'
          icon={Icon20.UploadCloud}
          onMenuClick={toggleCollapsed}
          isDesktopApp={isDesktopApp}
        />
        <Divider />
        <GatedContentWrapper
          gate={applicableGate}
          displayFeature='upload meetings'
        >
          <StyledDashedBox
            highlighted={showHighlight}
            onDragEnter={dragEvent(true)}
            onDragOver={dragEvent(true)}
            onDragLeave={dragEvent(false)}
            onDrop={handleOnDrop}
          >
            <input
              id='recording-meeting-file'
              type='file'
              value=''
              accept={UPLOADS_ACCEPT.join(',')}
              onChange={handleFileChange}
              ref={fileInputField}
              multiple
              hidden
            />
            <Icon.AddFile />
            <div css={[theme.tokens.typography.c1[500]]}>
              Drag & drop or use the file picker to upload Meetings
            </div>
            <div
              css={[
                theme.tokens.typography.c1[500],
                `color: ${theme.tokens.color.textSecondary}`
              ]}
            >
              Files supported: mov, .mp4, .mp3, .m4a
            </div>
            <Button
              onClick={() => fileInputField.current?.click()}
              css={css`
                margin-top: ${theme.tokens.spacing.sm};
              `}
            >
              Select files
            </Button>
          </StyledDashedBox>
          {fileUploads.length > 0 && (
            <>
              <MeetingDateHeader>Today</MeetingDateHeader>
              {fileUploads.map(([uploadKey, upload]) => (
                <UploadedMeetingItem
                  key={uploadKey}
                  upload={upload}
                  onCancel={() => removeUpload(uploadKey)}
                />
              ))}
            </>
          )}
        </GatedContentWrapper>
      </Layout.Wrapper>
    </>
  );
};

export default UploadRecordingPage;
