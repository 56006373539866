import styled from '@emotion/styled';
import { ContentTile } from 'components/atoms/ContentTile/ContentTile';
import {
  ImportRecordingTileThumbnail,
  ImportRecordingTileThumbnailProps
} from './ImportRecordingTileThumbnail';
import {
  ImportRecordingTileMeta,
  ImportRecordingTileMetaProps
} from './ImportRecordingTileMeta';

export type ImportRecordingTileProps = ImportRecordingTileMetaProps &
  ImportRecordingTileThumbnailProps & {
    staticThumbnailUrl: string;
  };

const ImportRecordingContentTile = styled(ContentTile)`
  cursor: auto;
`;

export const ImportRecordingTile = ({
  staticThumbnailUrl,
  title,
  durationMs,
  when,
  onImport,
  onPreview,
  state
}: ImportRecordingTileProps) => {
  return (
    <ImportRecordingContentTile>
      <ImportRecordingTileThumbnail
        {...{
          staticThumbnailUrl,
          onImport,
          onPreview,
          durationMs,
          state
        }}
      />
      <ImportRecordingTileMeta title={title} when={when} />
    </ImportRecordingContentTile>
  );
};
