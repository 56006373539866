import React, { useEffect, useMemo } from 'react';
import { ErrorBox, useShowToast } from '@grain/grain-ui';
import {
  Button,
  FormGroup,
  FormLabel,
  Icon16,
  Option,
  Select,
  Tooltip
} from '@grain/grain-ui/v4';
import { workspaceMembersQuery } from '@grain/api/graphql/queries';
import { useWorkspaceMembersInviteMutation } from '@grain/api/graphql/mutations/workspace.generated';
import copy from 'clipboard-copy';
import { UserRole } from '@grain/api/schema.generated';

import { EmailInput } from '~/components/MultiInput';
import { useMyself } from '~/support/auth';
import { useWorkspacePlan } from '@grain/components/Subscriptions/hooks';
import { useWorkspaceWithMembers } from '@grain/components/Workspace/hooks';
import { pluralize } from '~/support/language';
import { GraphQLError } from 'graphql';
import { ApolloError } from '@apollo/client';
import { getSeatBillingInfoText } from '~/modules/subscriptions/seats';

import { theme } from 'ui/v4/theme';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const StyledEmailInviteBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${theme.tokens.spacing['3xl']};
  ${theme.utils.px(theme.tokens.spacing['3xl'])};
  ${theme.utils.py(theme.tokens.spacing['3xl'])};
`;

const StyledFormSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${theme.tokens.spacing.lg};
`;

const StyledFormGroup = styled(FormGroup)`
  width: 100%;
`;

const StyledPlanInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.tokens.spacing.sm};
  width: 100%;
  ${theme.utils.px(theme.tokens.spacing.md)};
  ${theme.utils.py(theme.tokens.spacing.sm)};
  border: 1px solid ${theme.tokens.color.surfaceTertiary};
  border-radius: ${theme.primitives.spacing[5]};
  background: ${theme.tokens.color.surfaceSecondary};
  ${theme.tokens.typography.b3['400']};
  color: ${theme.tokens.color.textSecondary};

  svg {
    color: ${theme.tokens.color.textTertiary};
  }
`;

type GraphQLEmailError = GraphQLError & {
  email?: string;
};

type EmailError = ApolloError & {
  graphQLErrors: GraphQLEmailError[];
};

type SendInviteProps = {
  onNext: () => void;
};

export default function SendInvite({ onNext }: SendInviteProps) {
  const { error: workspaceError, workspace } = useWorkspaceWithMembers({
    fetchPolicy: 'cache-and-network'
  });
  const { myself, error: myselfError } = useMyself();

  const { subscriptionPlan: workspacePlan } = useWorkspacePlan();

  const showToast = useShowToast();
  const [emailsToSend, setEmailsToSend] = React.useState<string[]>([]);
  const seatsRequested = emailsToSend.length;
  const [addToPlan, setAddToPlan] = React.useState(false);

  const defaultText =
    'Grain enhances meetings by automatically recording, transcribing, and summarizing them using AI.';

  const emailsToIgnore = workspace
    ? [
        ...workspace?.pendingInvitees?.map(invite => invite.email),
        ...workspace?.users?.map(user => user.email)
      ]
    : [];

  const [invite] = useWorkspaceMembersInviteMutation({
    refetchQueries: [
      {
        query: workspaceMembersQuery,
        fetchPolicy: 'network-only'
      }
    ],
    onError: (error: ApolloError) => {
      if ((error as EmailError).graphQLErrors) {
        const emailErrorArray = (error as EmailError).graphQLErrors;
        // A user already exists in a workspace
        for (let i = 0; i < (emailErrorArray || []).length; i++) {
          const emailError = emailErrorArray[i];
          if (emailError.message === 'users_exist_in_workspace') {
            showToast({
              content: `${emailError.email} is already a member of your workspace.`,
              type: 'failure'
            });
          } else if (emailError.message === 'users_exist_in_other_workspaces') {
            showToast({
              content: `${emailError.email} is already a member of another workspace.`,
              type: 'failure'
            });
          }
        }
      }
      onNext();
      return true;
    },
    onCompleted() {
      const pluralizedMessage = pluralize(
        'Invite',
        'Invites',
        emailsToSend.length
      );
      showToast({ content: `${pluralizedMessage} sent`, type: 'success' });
      onNext();
      setEmailsToSend([]);
    }
  });

  const handleInviteUsers = async () => {
    if (!emailsToSend.length) {
      return showToast({
        content: 'Please enter one or more emails to invite.',
        type: 'failure'
      });
    }
    const duplicatedEmails = emailsToIgnore.filter(emailToIgnore =>
      emailsToSend.includes(emailToIgnore)
    );

    if (duplicatedEmails.length === 1) {
      showToast({
        content: `${duplicatedEmails[0]} has already been invited`,
        type: 'failure'
      });
    } else if (duplicatedEmails.length > 1) {
      showToast({
        content: `${duplicatedEmails.length} email addresses have already been invited`,
        type: 'failure'
      });
    }

    invite({
      variables: {
        emails: emailsToSend,
        note: defaultText,
        addToPlan
      }
    });
  };

  const onChangeEmails = React.useCallback((values: string[]) => {
    setEmailsToSend(values);
  }, []);

  const handleCopyInviteLink = () => {
    if (addToPlan && workspace?.planInviteLink) {
      copy(workspace?.planInviteLink);
      showToast({ content: 'Copied to clipboard.', type: 'success' });
    }
    if (!addToPlan && workspace?.inviteLink) {
      copy(workspace?.inviteLink);
      showToast({ content: 'Copied to clipboard.', type: 'success' });
    }
  };

  const workspaceSubscription = workspace?.workspaceSubscription;
  const isTrial = workspaceSubscription?.trial;
  const canInviteOnPlan = !workspacePlan?.isFree;

  const isAdmin = myself?.user?.role === UserRole.Admin;
  const canInviteAsMember = isAdmin;

  const canInvite = canInviteOnPlan && canInviteAsMember;

  const paidSeatName = !isTrial ? 'Paid' : 'Free notetaker';

  const cannotInviteMessage = (() => {
    if (!canInviteOnPlan) {
      return `Upgrade your workspace to invite team members to a ${paidSeatName} seat.`;
    }
    if (!canInviteAsMember) {
      return `Only admins can invite to a ${paidSeatName} seat`;
    }
  })();

  useEffect(() => {
    if (canInvite) setAddToPlan(true);
  }, [canInvite]);

  const seatBillingInfoText = useMemo(
    () =>
      getSeatBillingInfoText({
        seatsRequested,
        workspacePlan,
        workspaceSubscription
      }),
    [seatsRequested, workspacePlan, workspaceSubscription]
  );

  if (workspaceError || myselfError) {
    return <ErrorBox error={workspaceError || myselfError} />;
  }

  if (!workspace || !myself) return null;

  return (
    <>
      <StyledEmailInviteBox>
        <StyledFormSection>
          <StyledFormGroup>
            <FormLabel htmlFor='invite-email'>Email</FormLabel>
            <EmailInput
              id='invite-email'
              placeholder='Enter email address to invite'
              onChangeEmails={onChangeEmails}
              defaultValue={emailsToSend}
              allowDebounce={true}
              css={['width: 100%;']}
            />
          </StyledFormGroup>

          <StyledFormGroup>
            <FormLabel htmlFor='invite-plan'>Seat type</FormLabel>
            <Tooltip content={cannotInviteMessage}>
              <Select
                id='invite-plan'
                fullWidth
                matchWidth
                value={addToPlan ? 'paid' : 'free'}
                onChange={value => setAddToPlan(value !== 'free')}
                entryComponent={props => <Select.Input {...props} size='lg' />}
                disabled={!canInvite}
              >
                <Option
                  value='paid'
                  label={`${paidSeatName} seat`}
                  caption='Can record meetings and access all features on your plan'
                >
                  {paidSeatName} seat{' '}
                  <span
                    css={[
                      canInvite && `color: ${theme.tokens.color.textSecondary};`
                    ]}
                  >
                    – Can record meetings and access all features
                  </span>
                </Option>
                <Option
                  value='free'
                  label='Free viewer seat'
                  caption='Can’t record meetings but can access all other features on your plan'
                >
                  Free viewer seat{' '}
                  <span
                    css={[
                      canInvite && `color: ${theme.tokens.color.textSecondary};`
                    ]}
                  >
                    – Can’t record meetings but can access all other features
                  </span>
                </Option>
              </Select>
            </Tooltip>
          </StyledFormGroup>
          {!isTrial &&
            canInvite &&
            addToPlan &&
            isAdmin &&
            emailsToSend.length > 0 && (
              <StyledPlanInfo>
                <div>
                  <Icon16.AlertCircle />
                </div>
                <div>{seatBillingInfoText}</div>
              </StyledPlanInfo>
            )}
        </StyledFormSection>
        <StyledFormSection>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            `}
          >
            <Tooltip
              content={
                addToPlan
                  ? `Anyone with a company email who joins using this link will be assigned a ${paidSeatName} seat${!isTrial ? ', and your workspace will be billed' : ''}.`
                  : 'Anyone with an allowed domain can join into a free viewer seat with the link.'
              }
            >
              <Button
                icon={Icon16.Link}
                size='sm'
                variant='ghost'
                data-track='Workspace User Invited'
                data-track-args={JSON.stringify({ invite_trigger: 'link' })}
                onClick={handleCopyInviteLink}
              >
                {addToPlan
                  ? `${paidSeatName} seat invite link`
                  : 'Free viewer invite link'}
              </Button>
            </Tooltip>
            <Button
              size='lg'
              onClick={handleInviteUsers}
              disabled={emailsToSend.length === 0}
            >
              Send invites
            </Button>
          </div>
        </StyledFormSection>
      </StyledEmailInviteBox>
    </>
  );
}
