// @ts-strict-ignore
import { useCallback, useRef } from 'react';
import styled from '@emotion/styled';
import { Recording, RecordingComments } from '@grain/api/schema.generated';
import { cacheUpdateRecordingComments } from '@grain/api/graphql/cache-helpers';
import {
  color,
  CommentThread,
  CommentThreadInitializer,
  Transcript,
  toTimestamp,
  spacing
} from '@grain/grain-ui';
import {
  useWorkspaceWithMembers,
  useMemberSuggestionsForComments
} from '@grain/components/Workspace/hooks';
import { useMyself, useFeature } from '~/support/auth';
import {
  useRecordingCommentAddOnTimestampMutation,
  useRecordingCommentsChangedSubscription
} from '~/modules/comments/comments.generated';
import { useCommentThreadCallbacks } from '~/modules/comments/hooks';

const StyledEmptyState = styled.div`
  color: ${color.pigeon};
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
`;

const StyledComments = styled.div`
  ${spacing.pb3}
  .empty-state {
    margin-top: 56px;
  }

  .comment-thread {
    margin-top: 24px;
  }
`;

type CommentsProps = {
  recording: Recording;
  transcript: Transcript[];
  currentVideoTime?: number;
  handleVideoPause: () => void;
};

export function Comments({
  recording,
  transcript,
  currentVideoTime,
  handleVideoPause
}: CommentsProps) {
  const { enabled } = useFeature('sales_coaching');
  const comments = recording?.comments?.list ?? [];
  const { myself } = useMyself();
  const { workspace } = useWorkspaceWithMembers();
  const isCommentDirtyRef = useRef(false);
  const memberSuggestions = useMemberSuggestionsForComments(recording);
  const [recordingCommentAddOnTimestamp] =
    useRecordingCommentAddOnTimestampMutation();

  useRecordingCommentsChangedSubscription({
    variables: { recordingId: recording?.id },
    skip: Boolean(!enabled || !recording?.id),
    onData: ({ client, data: { data } }) => {
      cacheUpdateRecordingComments(client, {
        recordingId: recording?.id,
        comments: data.recordingCommentsChanged as unknown as RecordingComments,
        broadcast: true
      });
    }
  });

  const timestamp =
    typeof currentVideoTime === 'number'
      ? toTimestamp(currentVideoTime * 1000, true)
      : '0:00';
  const placeholder = `Leave a comment @ ${timestamp}`;
  const submitText = `Comment @ ${timestamp}`;

  const handleThreadInitializerSubmit = useCallback(
    (text: string) =>
      recordingCommentAddOnTimestamp({
        variables: {
          recordingId: recording.id,
          text,
          timestamp: Math.floor(currentVideoTime * 1000)
        }
      }),
    [recordingCommentAddOnTimestamp, recording?.id, currentVideoTime]
  );

  const commentThreadCallbacks = useCommentThreadCallbacks();

  const handleCommentUpdate = useCallback(
    (text: string) => {
      if (text.trim().length === 0) {
        isCommentDirtyRef.current = false;
        return;
      }
      if (isCommentDirtyRef.current) return;
      handleVideoPause();
      isCommentDirtyRef.current = true;
    },
    [handleVideoPause]
  );

  return (
    <StyledComments>
      <CommentThreadInitializer
        workspaceMembers={workspace?.users}
        recording={recording}
        user={myself?.user}
        memberSuggestions={memberSuggestions}
        placeholder={placeholder}
        submitText={submitText}
        onUpdate={handleCommentUpdate}
        onSubmit={handleThreadInitializerSubmit}
      />
      {comments.length === 0 && (
        <StyledEmptyState className='empty-state'>No Comments</StyledEmptyState>
      )}
      {comments.map(comment => (
        <CommentThread
          key={comment.id}
          workspaceMembers={workspace?.users}
          recording={recording}
          transcript={transcript}
          comment={comment}
          user={myself.user}
          memberSuggestions={memberSuggestions}
          {...commentThreadCallbacks}
          onClick={null}
        />
      ))}
    </StyledComments>
  );
}
