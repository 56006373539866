import { Link } from 'react-router-dom';
import { useWorkspace } from '@grain/api/auth';
import { getBasePublicPath } from '@grain/grain-ui';
import { Button, Icon16 } from '@grain/grain-ui/v4';

import {
  ModalInfo,
  ModalGraphic,
  ModalActions
} from '~/modals/InfoGraphicActionModals';

import { type StepComponentProps } from '../';

export function FreeViewerWelcome({
  totalSteps,
  step,
  handleNext,
  handleDone
}: StepComponentProps) {
  const { workspace, loading } = useWorkspace();

  if (loading || !workspace) return null;

  return (
    <>
      <ModalInfo
        prelude={`Welcome to ${workspace.name}’s Workspace!`}
        title='Know what happened in meetings, even without being there.'
      />
      <ModalGraphic>
        <img
          alt='Illustration of an AI-generated meeting summary next to a recording. The meeting summary includes "Chapters," "Outcome," and "Action Items" sections. The recording in the illustration features a transcript with highlighted content as well as video playback of a meeting, with a timeline of when different speakers spoke in the meeting.'
          src={`${getBasePublicPath()}/images/onboarding/free-viewer-welcome.svg`}
        />
      </ModalGraphic>
      <ModalActions>
        <Button
          variant='ghost'
          size='lg'
          textLabelProps={{ endIcon: Icon16.ExternalLink }}
          as={Link}
          to='https://support.grain.com/en/articles/9232436-search-your-meeting-recording-library'
          target='_blank'
          rel='noopener noreferrer'
        >
          Learn more
        </Button>
        <Button
          size='lg'
          onClick={step === totalSteps - 1 ? handleDone : handleNext}
        >
          {step === totalSteps - 1 ? 'Got it' : 'Continue'}
        </Button>
      </ModalActions>
    </>
  );
}
