import type { SubscriptionPlan } from '@grain/api/schema.generated';
import { PlanAction } from '~/modules/subscriptions/PlanCard';

type GetPlanActionArgs = {
  plan: Pick<SubscriptionPlan, 'isFree' | 'isPopular'>;
  isCurrentPlan: boolean;
  isUpcomingPlan: boolean;
  isFreemiumEnabled: boolean;
  isAdmin: boolean;
  isTrial: boolean;
  isPostTrial: boolean;
  canTrial: boolean;
};

export function getPlanAction({
  plan,
  isCurrentPlan,
  isUpcomingPlan,
  isFreemiumEnabled,
  isAdmin,
  isTrial,
  isPostTrial,
  canTrial
}: GetPlanActionArgs): PlanAction {
  // There should be no action for the current plan; this modal is concerned
  // with upgrading only.  The exception is that during the trial, the current
  // plan is "upgradable" (you are on it for the duration of the trial, but we
  // want the user to be able to "upgrade" [i.e. convert] while on the trial).
  if (isCurrentPlan && !isTrial) {
    return PlanAction.CURRENT_PLAN;
  }

  // As part of the Freemium changes, we want to guide users into the trial.
  // Naturally, we only do that pre-trial.
  if (isFreemiumEnabled && plan.isPopular && !isPostTrial && !isTrial) {
    // We want to allow admins on qualified workspaces to self-initiate trials.
    if (isAdmin && canTrial) {
      return PlanAction.START_TRIAL;
    }

    // Admins on non-qualified workspaces can still request a trial, in which
    // case they go through Sales, which can determine whether the trial will
    // suit their needs.
    if (isAdmin && !canTrial) {
      return PlanAction.REQUEST_TRIAL;
    }

    // Everyone else (all members) need to request their admin manage this.
    return PlanAction.REQUEST_UPGRADE;
  }

  // While on trial, we want to communicate to users that they will go back to
  // the upcoming plan if they don't end up upgrading by the end of the trial.
  // There is no action here, it's just for text.  Again, this modal is only
  // concerned with upgrading, nothing else.
  if (isUpcomingPlan && isTrial) {
    return PlanAction.FALLBACK_AFTER_TRIAL;
  }

  // I'm not sure if this is ever a possible scenario for this modal, but just
  // in case, cover it.
  if (isUpcomingPlan && !isTrial) {
    return PlanAction.UPCOMING_PLAN;
  }

  // With all the special cases covered, in all other cases the options we're
  // presenting in this modal should only be upgradable options.  So the only
  // remaining option is to show the option to upgrade.
  return PlanAction.UPGRADE_PLAN;
}
