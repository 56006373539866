import * as Layout from '~/components/Layout';
import { useOpenSettings } from '~/modules/settings';
import { media } from '@grain/grain-ui';
import {
  Button,
  Divider,
  PageHeader,
  Icon16,
  Icon20
} from '@grain/grain-ui/v4';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import ImportZoomGrid from './ImportZoomGrid';
import { useMediaQuery } from '@grain/components/support/browser';
import { GatedContentWrapper } from '~/modules/gates';
import { useExperiment, useFeature } from '@grain/api/auth';
import { isDesktopApp } from '@grain/desktop-lib';

const Content = styled.div`
  padding: 24px 50px;
  overflow: auto;
`;

export const StyledHeaderHelper = styled.div`
  display: flex;
`;

export default function ImportZoomRecordings() {
  const openZoomIntegration = useOpenSettings('integrations', 'zoom');
  const isSmallScreen = useMediaQuery(media.small);
  const { toggleCollapsed } = Layout.useSidebar();
  const { enabled: isFreemiumEnabled } = useExperiment('freemium');

  // Under Freemium, access to the Import Zoom Meetings page is restricted to
  // all users on Free workspaces; checking "zoom_import" achieves that.
  //
  // Before Freemium, all users on Free workspaces would be able to access the
  // page.  Only Free Viewers on Paid plans would be restricted; checking
  // "create_recording" achieves that.
  const applicableGate = isFreemiumEnabled ? 'zoom_import' : 'create_recording';
  const { enabled } = useFeature(applicableGate);

  return (
    <Layout.Wrapper withSidebar>
      <Helmet title='Import' />
      <PageHeader
        title='Import Zoom Meetings'
        icon={Icon20.UploadCloud}
        onMenuClick={toggleCollapsed}
        isDesktopApp={isDesktopApp}
        rightSection={
          enabled &&
          !isSmallScreen && (
            <Button
              variant='neutral'
              icon={Icon16.Settings01}
              onClick={openZoomIntegration}
            >
              Integration settings
            </Button>
          )
        }
      />
      <Divider />
      <GatedContentWrapper
        gate={applicableGate}
        displayFeature='import meetings'
      >
        <Content id='infinite-scroll-container'>
          <ImportZoomGrid />
        </Content>
      </GatedContentWrapper>
    </Layout.Wrapper>
  );
}
