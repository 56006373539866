import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { JsonFilterValue } from '../filtersV2/types';

export const useRestoreFilter = () => {
  const { state } = useLocation();
  return state?.filters ?? {};
};

type useStoreFilterArgs = {
  viewAdHocFiltersJSON: JsonFilterValue[];
  skip?: boolean;
};

export const useStoreFilter = ({
  viewAdHocFiltersJSON,
  skip
}: useStoreFilterArgs) => {
  const location = useLocation();
  const navigate = useNavigate();

  // NOTE: Since location updates itself, adding it to the dependencies array
  // will cause an infinite loop.
  // Not having all the dependencies is wanted behaviour in this specific case.
  React.useEffect(() => {
    if (skip || !viewAdHocFiltersJSON?.length) {
      return;
    }

    navigate(location, {
      replace: true,
      state: {
        ...location.state,
        filters: viewAdHocFiltersJSON
      }
    });
  }, [viewAdHocFiltersJSON, skip]); // eslint-disable-line react-hooks/exhaustive-deps
};
