import React from 'react';
import { PreviewTopicMatchesModal } from '@grain/grain-ui';
import { SmartTopicMatchPreview } from '@grain/api/schema.generated';
import { isDesktopApp } from '@grain/desktop-lib';

type PreviewMatchesModalProps = {
  isOpen: boolean;
  currentSmartTopicMatchPreview: SmartTopicMatchPreview;
  onClose: () => void;
};

export const PreviewMatchesModal = ({
  isOpen,
  currentSmartTopicMatchPreview,
  onClose
}: PreviewMatchesModalProps) => {
  const naviagateToRecordingPage = (recordingId: string) => {
    isDesktopApp
      ? (window.location.href = `/share/recording/${recordingId}`)
      : window.open(`/share/recording/${recordingId}/`);
  };
  if (!isOpen) return null;
  return (
    <PreviewTopicMatchesModal
      topicMatches={currentSmartTopicMatchPreview.recordingMatches.filter(
        recordingMatches => recordingMatches.recording
      )}
      navigateToRecording={naviagateToRecordingPage}
      onClose={onClose}
    />
  );
};
