import { useMutation } from '@grain/api/graphql';
import {
  BillingPeriod,
  SubscriptionPlan,
  User,
  WorkspaceSubscription
} from '@grain/api/schema.generated';
import {
  addWorkspaceUsersToSubscriptionMutation,
  createSubscriptionMutation,
  setSubscriptionAutoRenewMutation
} from '@grain/components/Subscriptions/graphql';
import { useConfirm, useShowToast } from '@grain/grain-ui';
import { format } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import UpdatePaymentModal from '~/modules/subscriptions/BillingStatus/UpdatePaymentModal';
import { displayCents } from '~/support/price';
import ChangeEmailModal from './modals/ChangeEmailModal';
import { useSubscriptionBillingEmailUpdateMutation } from './mutations.generated';
import ChangeBillingCycleModal from './modals/ChangeBillingCycle';

export const useBillingActions = ({
  wsUsers,
  workspacePlan,
  workspaceSubscription
}: {
  workspacePlan: SubscriptionPlan;
  wsUsers: User[];
  workspaceSubscription: WorkspaceSubscription;
}) => {
  const showConfirm = useConfirm();
  const showToast = useShowToast();
  const [isUpdateModalShown, setShowUpdateModal] = useState(false);
  const [isUpdateBillingEmailModalShown, setShowUpdateBillingEmailModal] =
    useState(false);
  const [isUpdateBillingCycleModalShown, setShowUpdateBillingCycleModal] =
    useState(false);
  const [updateBillingEmail] = useSubscriptionBillingEmailUpdateMutation();

  const [createSubscription, { loading: intervalChangeLoading }] = useMutation(
    createSubscriptionMutation
  );

  const [mutateAutoRenew, mutateAutoRenewRes] = useMutation(
    setSubscriptionAutoRenewMutation,
    {
      onCompleted(res) {
        const workspace = res.subscriptionAutoRenewSet;
        const autoRenew = workspace.workspaceSubscription.autoRenew;
        if (autoRenew) {
          showToast({
            type: 'success',
            content: 'Subscription reactivated.'
          });
        }
      }
    }
  );

  const hasNoActivatedUsers = useMemo(() => {
    return wsUsers.filter((user: User) => user.staysOnPlan).length === 0;
  }, [wsUsers]);

  const paidUsers = useMemo(() => {
    return wsUsers.filter((user: User) => user.onPlan);
  }, [wsUsers]);

  const [addUsersToSubscription] = useMutation(
    addWorkspaceUsersToSubscriptionMutation,
    {}
  );

  const handleReactivate = async (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault(); // don't toggle its internal value

    await showConfirm({
      width: 420,
      cancelContent: 'Go Back',
      confirmContent: 'Continue',
      confirmButtonType: 'primary',
      confirmButtonProps: {
        css: ['width: 100px;']
      },
      onConfirm: async () => {
        if (hasNoActivatedUsers) {
          const userIds = paidUsers.map(({ id }: { id: string }) => id);
          await addUsersToSubscription({
            variables: { userIds }
          });
        }
        await mutateAutoRenew({
          variables: { shouldAutoRenew: true }
        });
      },
      title: 'Confirm billing change',
      description: (
        <>
          You are about to <b>reactivate</b> your <b>{workspacePlan?.name}</b>{' '}
          plan
        </>
      )
    });
  };

  const openBillingModal = () => setShowUpdateModal(true);

  const paymentFormProps = useMemo(
    () => ({
      buttonText: 'Update',
      defaultBillingEmail: workspaceSubscription?.billingEmail,
      updateBillingEmail: !workspaceSubscription?.billingEmail,
      summaryContent: (
        <>
          This will be used for your next bill of{' '}
          <b>${displayCents(workspaceSubscription?.renewalPrice)}</b> on{' '}
          <b>
            {workspaceSubscription?.renewalDate
              ? format(
                  new Date(workspaceSubscription?.renewalDate),
                  'MMMM d, yyyy'
                )
              : 'N/A'}
          </b>
        </>
      ),
      onSuccess: () => {
        showToast({
          content: 'Payment information updated successfully.',
          type: 'success'
        });
        setShowUpdateModal(false);
      }
    }),
    [workspaceSubscription, showToast]
  );

  const PaymentModal = useMemo(() => {
    if (!isUpdateModalShown) {
      return null;
    }

    return (
      <UpdatePaymentModal
        onClose={() => setShowUpdateModal(false)}
        {...paymentFormProps}
      />
    );
  }, [isUpdateModalShown, paymentFormProps]);

  const openBillingEmailModal = () => setShowUpdateBillingEmailModal(true);

  const handleUpdateBillingEmail = useCallback(
    async (billingEmail: string) => {
      try {
        await updateBillingEmail({
          variables: { billingEmail }
        });
      } catch (error) {
        showToast({
          content: 'An error occurred while updating billing email.',
          type: 'failure'
        });
        return;
      }

      setShowUpdateBillingEmailModal(false);
      showToast({
        content: 'Billing email updated successfully.',
        type: 'success'
      });
    },
    [updateBillingEmail, showToast]
  );

  const handleUpdateBillingInterval = useCallback(
    async (billingInterval: BillingPeriod) => {
      try {
        await createSubscription({
          variables: {
            subscriptionArgs: {
              plan_sku: workspacePlan.sku,
              billingPeriod: billingInterval,
              userIds: paidUsers.map(({ id }) => id)
            }
          }
        });
      } catch (error) {
        showToast({
          content: 'An error occurred while updating billing interval.',
          type: 'failure'
        });
        return;
      }

      setShowUpdateBillingCycleModal(false);
      showToast({
        content: 'Billing interval updated successfully.',
        type: 'success'
      });
    },
    [createSubscription, showToast, workspacePlan, paidUsers]
  );

  const openBillingCycleModal = () => setShowUpdateBillingCycleModal(true);

  const BillingChangeEmailModal = useMemo(() => {
    if (!isUpdateBillingEmailModalShown) {
      return null;
    }

    return (
      <ChangeEmailModal
        email={workspaceSubscription?.billingEmail ?? ''}
        onUpdate={(billingEmail: string) => {
          handleUpdateBillingEmail(billingEmail);
        }}
        onClose={() => setShowUpdateBillingEmailModal(false)}
      />
    );
  }, [
    isUpdateBillingEmailModalShown,
    workspaceSubscription,
    handleUpdateBillingEmail
  ]);

  const BillingChangeIntervalModal = useMemo(() => {
    if (!isUpdateBillingCycleModalShown) {
      return null;
    }

    return (
      <ChangeBillingCycleModal
        currentSubscription={workspaceSubscription}
        onUpdate={(interval: BillingPeriod) => {
          handleUpdateBillingInterval(interval);
        }}
        onClose={() => setShowUpdateBillingCycleModal(false)}
        loading={intervalChangeLoading}
      />
    );
  }, [
    workspaceSubscription,
    isUpdateBillingCycleModalShown,
    handleUpdateBillingInterval,
    intervalChangeLoading
  ]);

  return {
    handleReactivate,
    error: mutateAutoRenewRes.error,
    openBillingModal,
    PaymentModal,
    openBillingEmailModal,
    BillingChangeEmailModal,
    BillingChangeIntervalModal,
    openBillingCycleModal
  };
};
