import { useEffect, useState } from 'react';
import {
  SmartTopic,
  TrackerStatistics,
  TrackersInsights,
  InsightQueries
} from '@grain/api/schema.generated';
import { SmartTagsDetail } from './SmartTagsDetail';
import { EmptyState } from '../EmptyState';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import {
  Icon,
  InputText,
  Spinner,
  color,
  flex,
  font,
  spacing
} from '@grain/grain-ui';
import {
  SmartTagMatchResults,
  useSmartTagFiltersState
} from '~/modals/SmartTagMatchResults';
import { CreateSmartTagPopup } from './CreateSmartTagPopup';
import { useHotspotByKey } from '~/components/Hotspot/context';
import { useLayoutBanner } from '~/components/LayoutBanners';
import { useFiltersManager } from '~/modules/filtersV2/useFiltersManager';

const Wrapper = styled.div`
  ${spacing.pb6}
  width: 100%;
  position: relative;
  display: inline-block;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: calc(100vh - 374px);
  ${spacing.g2};
  ${color.fg.graieen};
`;

const StyledInput = styled(InputText)`
  width: 100%;
  height: 32px;
  ${spacing.pr7};
`;

const BodyContainer = styled.div`
  ${spacing.pl8}
  border-top: 1px solid ${color.gull};
  display: flex;
`;

const SearchBoxPanel = styled.div`
  width: 262px;
  border-right: 1px solid ${color.gull};
  height: 100%;
  ${spacing.pr7}
  flex-shrink: 0;

  .tracker-row {
    display: flex;
    cursor: pointer;
    ${spacing.py2}
    ${spacing.px3}
    font-size: 12px;
    font-weight: 500;
    ${flex.alignItems.center}
    ${flex.justifyContent.spaceBetween}
    border-radius: 8px;
    text-transform: capitalize;
    &.active {
      color: ${color.highlightGreenDark};
      background: ${color.goose};
    }
    :hover {
      border-radius: 8px;
      background: ${color.goose};
    }
  }
`;

const GridTitleRowContainer = styled.div`
  ${spacing.pl6}
`;

const GridTitleRow = styled.div`
  ${spacing.pt6}
  ${spacing.pb1}
  ${flex.alignItems.center}
  gap: 8px;
`;

const GridTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
`;

const GridSubtitle = styled.div`
  font-size: 10px;
  font-weight: 500;
  color: ${color.pigeon};
`;

const MatchCount = styled.button`
  all: unset;
  cursor: pointer;
  ${font.v4.c2[500]}
  ${color.fg.highlightGreenDark}
`;

const GridDescription = styled.div`
  font-size: 12px;
  font-weight: 500;
  ${spacing.pb6}
  color: ${color.crow};
`;

const GridContainer = styled.div`
  height: 100%;
  width: 100%;

  .grid-row {
    display: grid;
    align-items: center;
    grid-template-columns: 25px 125px 120px auto 50px;
    grid-gap: 8px;
    font-size: 12px;
    font-weight: 500;
    ${spacing.pt3};
    ${spacing.pb3};
    ${spacing.pl6};
    text-overflow: ellipsis;
    color: ${color.crow};
    border-bottom: 1px solid ${color.gull};
    .bar {
      background-color: ${color.highlightGreenDark};
      border-radius: 8px;
      height: 6px;
    }
    .drill-down {
      ${spacing.pr6}
      display: flex;
      ${flex.justifyContent.flexEnd}
    }
    :not(.header, .na-user):hover {
      cursor: pointer;
      background-color: ${color.goose};
    }
    &.header {
      color: ${color.crow};
      .bar-scale {
        padding-right: 56px;
        ${flex.justifyContent.spaceBetween}
      }
    }
  }
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 8px;
  top: 6px;
  cursor: pointer;
  ${color.crow};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export type SmartTagsProps = {
  filter: string;
  initialFilters: ReturnType<typeof useFiltersManager>['initialFilters'];
  onFiltersClear: () => void;
  trackersInsightData: InsightQueries;
  trackersLoading: boolean;
  onSetActiveSmartTag: (tag: SmartTopic) => void;
  activeSmartTag?: SmartTopic;
  trackerInsightDetail: InsightQueries;
  trackerDetailLoading: boolean;
};

export type TagName = 'tag' | 'owner';

export type FilterNavigation = {
  [key: string]: TagDetails;
};

export type TagDetails = {
  name?: string;
  id?: string;
};

export const SmartTags = ({
  filter,
  initialFilters,
  onFiltersClear,
  trackersInsightData,
  trackersLoading,
  activeSmartTag,
  onSetActiveSmartTag,
  trackerInsightDetail,
  trackerDetailLoading
}: SmartTagsProps) => {
  const [smartTagFilters, setSmartTagFilters] = useSmartTagFiltersState();
  const layoutBanner = useLayoutBanner();
  const isTopBannerOpen = Boolean(layoutBanner);

  const { setStepViewed, hasSeenHotspot } = useHotspotByKey('smart-tags-ftux');
  const [trackersInsights, setTrackersInsights] = useState<TrackersInsights>();
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();

  const filterSmartTags = (value: string) => {
    setIsFiltering(value.length > 0);
    setInputValue(value);
    const filteredSmartTags =
      trackersInsightData?.trackersInsights?.trackers.filter(
        a =>
          a.smartTopic.tag.text.toLowerCase().indexOf(value.toLowerCase()) > -1
      ) as TrackerStatistics[];
    const filteredInsights = { ...trackersInsights } as TrackersInsights;
    filteredInsights.trackers = [...filteredSmartTags];
    setTrackersInsights(filteredInsights);
  };

  const handleNavigate = (nav: FilterNavigation) => {
    setSmartTagFilters({
      ...initialFilters,
      smart_tags: [{ value: nav.tag.id!, label: nav.tag.name! }],
      owner: { value: nav.owner.id!, label: nav.owner.name! }
    });
  };

  const handleOpenMatchCount = () => {
    if (!activeSmartTag) {
      return;
    }
    setSmartTagFilters({
      ...initialFilters,
      smart_tags: [
        { value: activeSmartTag?.tag.id, label: activeSmartTag?.tag.text }
      ]
    });
  };

  useEffect(() => {
    if (!trackersInsightData?.trackersInsights) return;

    const insights: TrackersInsights = trackersInsightData.trackersInsights;
    setTrackersInsights(insights);
    onSetActiveSmartTag(insights?.trackers[0]?.smartTopic);
  }, [trackersInsightData, onSetActiveSmartTag]);

  if (trackersLoading) {
    return (
      <LoadingContainer>
        <Spinner size={20} />
      </LoadingContainer>
    );
  }

  return trackersInsights?.trackers.length || isFiltering ? (
    <>
      <BodyContainer>
        <SearchBoxPanel>
          <div>
            <GridTitleRow css={{ justifyContent: 'space-between' }}>
              <GridTitle>Trackers</GridTitle>
              <GridSubtitle>
                Based on {trackersInsights?.recordingCount} meetings
              </GridSubtitle>
            </GridTitleRow>
            <GridDescription>
              % of meetings with tracker detection.
            </GridDescription>
            <Wrapper>
              <StyledInput
                value={inputValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  filterSmartTags(event.target.value)
                }
                placeholder='Search by name'
              />
              {inputValue && (
                <IconWrapper onClick={() => filterSmartTags('')}>
                  <Icon.Close />
                </IconWrapper>
              )}
            </Wrapper>
          </div>
          <div
            style={{
              maxHeight: isTopBannerOpen
                ? 'calc(100vh - 389px)'
                : 'calc(100vh - 335px)',
              overflow: 'auto'
            }}
          >
            {trackersInsights?.trackers?.map(tracker => (
              <div
                key={tracker.smartTopic.tag.id}
                onClick={() => {
                  onSetActiveSmartTag(tracker?.smartTopic);
                }}
                className={`tracker-row ${
                  tracker.smartTopic.tag.id === activeSmartTag?.tag.id
                    ? 'active'
                    : ''
                } `}
              >
                <div>{tracker.smartTopic?.tag?.text}</div>
                <div>
                  {Math.round(
                    (tracker.hitCount / trackersInsights.recordingCount) * 100
                  )}
                  %
                </div>
              </div>
            ))}
            {!hasSeenHotspot('insights-create') && (
              <CreateSmartTagPopup
                onClick={() => {
                  setStepViewed('insights-create');
                  navigate('/app/settings/workspace?tab=topics&tour=true');
                }}
                onDismiss={() => setStepViewed('insights-create')}
              />
            )}
          </div>
        </SearchBoxPanel>
        <GridContainer>
          <GridTitleRowContainer>
            <GridTitleRow>
              <GridTitle>{activeSmartTag?.tag.text}</GridTitle>
              <MatchCount onClick={handleOpenMatchCount}>
                {activeSmartTag?.matchCount}{' '}
                {activeSmartTag?.matchCount === 1 ? 'match' : 'matches'}
              </MatchCount>
            </GridTitleRow>
            <GridDescription>
              % of meetings where{' '}
              <strong css={['text-decoration: capitalize']}>
                {activeSmartTag?.tag.text}
              </strong>{' '}
              was detected for each team member.
            </GridDescription>
          </GridTitleRowContainer>
          <div className='grid-row header'>
            <div>Member</div>
            <div style={{ gridColumn: 3 }}># of meetings</div>
            <div className='bar-scale'>
              <div>0%</div>
              <div>100%</div>
            </div>
          </div>
          {activeSmartTag && (
            <SmartTagsDetail
              activeSmartTag={activeSmartTag}
              onNavigate={handleNavigate}
              trackerInsightDetail={trackerInsightDetail}
              trackerDetailLoading={trackerDetailLoading}
            />
          )}
        </GridContainer>
      </BodyContainer>
      {smartTagFilters && (
        <SmartTagMatchResults
          filters={smartTagFilters}
          onClose={() => setSmartTagFilters(null)}
          eventSource='insights_page'
        />
      )}
    </>
  ) : (
    <EmptyState filter={filter} onFiltersClear={onFiltersClear} />
  );
};
