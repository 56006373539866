import styled from '@emotion/styled';
import { getBasePublicPath, Icon } from '@grain/grain-ui';
import { LoginButton } from '~/components/LoginButton';
import {
  LeftSide,
  RightSide,
  Title,
  Description,
  Buttons
} from '../../../modals/LegacyOnboarding';
import { useSearchParams } from 'react-router-dom';

export const HelpContainer = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  color: #545454;
  margin-top: 26px;
`;

const CalendarPerms = () => {
  const [searchParams] = useSearchParams();
  const newUrlParams = new URLSearchParams();
  const addUrlParamIfExists = (key: string) => {
    const val = searchParams.get(key);
    val && newUrlParams.set(key, val);
  };

  newUrlParams.set('action', searchParams.get('action') || 'web_register');
  addUrlParamIfExists('redirect_to');
  addUrlParamIfExists('state');
  addUrlParamIfExists('post_registration');
  const redirect = (provider: string) =>
    `/app/_/redirect/${provider}?${newUrlParams.toString()}`;

  return (
    <>
      <LeftSide width={378}>
        <div>
          <Title>
            Let's try that again, Grain needs calendar access to work
          </Title>
          <Description>
            Grain syncs with your calendar to record your customer meetings.
            <br />
            <br />
            Please click "Select all" when connecting your calendar.
          </Description>
        </div>
        <div css={['display: flex; flex-direction: column;']}>
          <Buttons>
            <LoginButton
              as='a'
              loginType='Google'
              css={['width: 100%;']}
              href={redirect('google')}
            >
              Connect Google Calendar
            </LoginButton>
          </Buttons>
          <HelpContainer>
            <Icon.QuestionMark
              css={[
                'width: 20px; height: 20px; color: #545454; margin-right: 8px;'
              ]}
            />
            Need help?&nbsp;
            <a
              href='mailto:help@grain.com'
              target='_blank'
              rel='noreferrer'
              css={['text-decoration: underline; font-size: 12px;']}
            >
              Contact support
            </a>
          </HelpContainer>
        </div>
      </LeftSide>
      <RightSide
        background={`${getBasePublicPath()}/images/noise-and-texture.png`}
        width={542}
      >
        <img
          src={`${getBasePublicPath()}/images/onboarding/calendar-perms.png`}
          alt='Calendar permissions example with arrow'
          css={[
            'width: 420px; height: 290px; margin-top: 104px; margin-bottom: 104px; margin-right: 40px;'
          ]}
        />
      </RightSide>
    </>
  );
};

export default CalendarPerms;
