import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Button,
  ButtonIcon,
  Icon,
  color,
  flex,
  font,
  spaces,
  spacing
} from '@grain/grain-ui';

const Header = styled.h6([
  font.v4.h6,
  spacing.my0,
  css`
    display: inline-flex;
    align-items: center;
    gap: 8px;
  `
]);

type CreateSmartTagPopupProps = {
  onClick: () => void;
  onDismiss: () => void;
};
export const CreateSmartTagPopup = ({
  onClick,
  onDismiss
}: CreateSmartTagPopupProps) => {
  return (
    <div
      css={css`
        border: 1px solid ${color.gull};
        padding: ${spaces[4]};
        margin-top: 16px;
      `}
    >
      <div
        css={[
          flex.alignItems.center,
          flex.justifyContent.spaceBetween,
          spacing.mb2
        ]}
      >
        <Header>
          <Icon.Star04 width={16} height={16} /> Create Trackers
        </Header>
        <ButtonIcon
          variant='stealth'
          onClick={onDismiss}
          css={css`
            margin-top: -6px;
            margin-right: -6px;
            svg {
              height: 12px;
              width: 12px;
            }
          `}
        >
          <Icon.Close css={['width: 12px; height: 12px;']} />
        </ButtonIcon>
      </div>
      <p css={[font.v4.b4[400], spacing.mt0, spacing.mb4]}>
        Understand how important topics are covered in your meetings.
      </p>
      <Button onClick={onClick} css={['width: 100%;']} variant='secondary'>
        Setup trackers
      </Button>
    </div>
  );
};
