import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { POST_SLIDESHOW_ROUTE_ID } from '@grain/components/overlays/constants';
import { useRouteOverlay } from '@grain/grain-ui';
import { Button, Icon16 } from '@grain/grain-ui/v4';
import { useSampleQuery } from '~/modules/contentFilter/contentfilter.generated';
import { DarkOverlay } from '~/components/DarkOverlay';
import { MeetingCard } from './MeetingCard';

export default function PostSlideshowOverlay() {
  const navigate = useNavigate();
  const { close } = useRouteOverlay(POST_SLIDESHOW_ROUTE_ID);
  const [exiting, setExiting] = useState(false);

  const { data: sampleRecordingData } = useSampleQuery();
  const sampleRecording = sampleRecordingData?.recordingSample;

  const fadeOutAndOpenSample = useCallback(() => {
    if (!sampleRecording) return; // Should never happen
    setExiting(true);
    // Navigate without removing the overlay from the URL yet, so it has time to
    // fade out before the component is unmounted.
    navigate(
      `${sampleRecording.recordingPath}?overlay=${POST_SLIDESHOW_ROUTE_ID}`
    );
    setTimeout(() => close(), 1000); // Clean up URL
  }, [close, navigate, sampleRecording]);

  const overlayStyles = css`
    opacity: 1;
    transition: opacity linear 1s;
    ${exiting && 'opacity: 0;'}
  `;

  if (!sampleRecording) return;

  return (
    <DarkOverlay
      onClose={close}
      title='End of slideshow'
      body='Now, watch a short video to learn how to get the most out of your recordings in the Grain app.'
      overlayStyles={overlayStyles}
    >
      <MeetingCard
        title='Explore a meeting recorded with Grain'
        body='Learn how to get the most out of your meetings.'
        recording={sampleRecording}
        onClick={fadeOutAndOpenSample}
      />
      <Button
        size='xl'
        textLabelProps={{ startIcon: Icon16.Play }}
        onClick={fadeOutAndOpenSample}
      >
        Start tutorial
      </Button>
    </DarkOverlay>
  );
}
