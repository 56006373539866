import Button from '@grain/components/Button';
import { spacing } from '@grain/styles/constants';
import { Icon } from '@grain/grain-ui';

import {
  StyledHeaderBar,
  StyledWrapper,
  StyledDescription
} from '../Login/styles';

import {
  StyledTitle,
  StyledButtonContainer,
  loginConfirmButtonStyles,
  StyledSection,
  StyledSectionLabel,
  StyledRow,
  StyledRowLabel,
  StyledRowText,
  StyledConnectionVertical,
  StyledConnectionHorizontal,
  StyledConnectionSection,
  StyledGrainLogo,
  StyledSSO
} from './styles';

export default function LoginConfirm() {
  const urlParams = new URLSearchParams(window.location.search);
  const grainUserName = urlParams.get('grain_user_name') || false;
  const grainUserEmail = urlParams.get('grain_user_email') || false;
  const ssoUserName = urlParams.get('sso_user_name') || false;
  const ssoUserEmail = urlParams.get('sso_user_email') || false;

  const validUserData =
    grainUserEmail && grainUserName && ssoUserName && ssoUserEmail;

  if (!validUserData) {
    window.location = '/login?error=auth_failure';
    return null;
  }

  return (
    <>
      <StyledHeaderBar>
        <div css={[spacing.ml5, spacing.my1]}>
          <a href='/'>
            <Icon.GrainLogoAndWord />
          </a>
        </div>
      </StyledHeaderBar>
      <StyledWrapper css={[spacing.mb2]}>
        <StyledConnectionSection>
          <StyledGrainLogo />
          <StyledConnectionHorizontal />
          <StyledSSO />
        </StyledConnectionSection>
        <StyledTitle>Confirm account connection</StyledTitle>
        <StyledDescription css={[spacing.mb8]}>
          Make sure you are connecting the correct SSO account to your Grain
          account.
        </StyledDescription>
        <StyledSection>
          <StyledSectionLabel>Grain</StyledSectionLabel>
          <StyledRow>
            <StyledRowLabel>Name</StyledRowLabel>
            <StyledRowText>{grainUserName}</StyledRowText>
          </StyledRow>
          <StyledRow>
            <StyledRowLabel>Email</StyledRowLabel>
            <StyledRowText>{grainUserEmail}</StyledRowText>
          </StyledRow>
        </StyledSection>
        <StyledConnectionVertical />
        <StyledSection>
          <StyledSectionLabel>SSO</StyledSectionLabel>
          <StyledRow>
            <StyledRowLabel>Name</StyledRowLabel>
            <StyledRowText>{ssoUserName}</StyledRowText>
          </StyledRow>
          <StyledRow>
            <StyledRowLabel>Email</StyledRowLabel>
            <StyledRowText>{ssoUserEmail}</StyledRowText>
          </StyledRow>
        </StyledSection>
        <StyledButtonContainer>
          <Button
            css={loginConfirmButtonStyles}
            onClick={() => {
              window.location.href = '/login';
            }}
          >
            Cancel
          </Button>
          <Button
            type='primary'
            css={loginConfirmButtonStyles}
            onClick={() => {
              window.location.href = '/app/_/confirm_auth_connection';
            }}
          >
            Confirm
          </Button>
        </StyledButtonContainer>
      </StyledWrapper>
    </>
  );
}
