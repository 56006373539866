import { stringToInteger } from '@grain/components/support/utils';
import { TAG_REGEX } from '@grain/components/Highlight';

export const MATCH_TEXT_TYPES = {
  QUOTE: 'QUOTE',
  TITLE: 'TITLE',
  NOTE: 'NOTE',
  HIGHLIGHT: 'HIGHLIGHT'
} as const;
type MatchTextTypesValues =
  (typeof MATCH_TEXT_TYPES)[keyof typeof MATCH_TEXT_TYPES];

export function formatMatchText(text: string, type: MatchTextTypesValues) {
  switch (type) {
    case MATCH_TEXT_TYPES.QUOTE:
      return parseMatchHtmlToJsx(setEllipses(trimMatchPosition(text)));
    case MATCH_TEXT_TYPES.NOTE:
    case MATCH_TEXT_TYPES.HIGHLIGHT:
      return parseMatchHtmlToJsx(trimMatchPosition(text));
    default:
    case MATCH_TEXT_TYPES.TITLE:
      return parseMatchHtmlToJsx(text);
  }
}

const regexMatchRange = /(<>[^<>]*<\/>)/;
const regexMatchDelimiter = /<>|<\/>/g;
// @param {string} text - api response of `matchText`, which wraps substrings with <> and </>
// @returns {jsx} - wraps <> and </> styling
export function parseMatchHtmlToJsx(text: string) {
  return text.split(regexMatchRange).map(substring => {
    if (regexMatchRange.test(substring)) {
      const matchText = substring.replace(regexMatchDelimiter, '');
      const isTag = TAG_REGEX.test(matchText);

      return (
        <span className={isTag ? 'search-tag' : 'search-match'}>
          {matchText}
        </span>
      );
    }

    return substring;
  });
}

const AVERAGE_CHAR_WIDTH = 5.85;
const TARGET_ROW_PERCENTAGE = 0.3; // match words are roughly 30% of the screen width

function getTargetIndex() {
  const screenWidth = window.innerWidth;
  const charsPerLine = Math.floor(screenWidth / AVERAGE_CHAR_WIDTH);
  return Math.floor(charsPerLine * TARGET_ROW_PERCENTAGE);
}

// Shorten text so the index of the first '<>' is less than 24
function trimMatchPosition(text = '') {
  const targetIndex = getTargetIndex();
  const matchIndex = text.indexOf('<>');
  if (matchIndex < targetIndex) return text;

  // Find new cutoff to shift match closer to start of string
  const spaceIndex = text.indexOf(' ', matchIndex - targetIndex);
  return text.slice(spaceIndex + 1);
}

// @examples:
// - '... And therefore' -> 'And therefore'
// - '... and therefore' -> (no change)
// - 'and therefore' -> '... and therefore'
// - 'And therefore' -> (no change)
function setEllipses(text: string) {
  const hasLeadingEllipsis = text.charAt(0) === '…';
  if (hasLeadingEllipsis) {
    // If letter after ellipsis is uppercase, remove ellipsis
    if (text.charAt(2).toUpperCase() === text.charAt(2)) {
      return text.slice(2);
    } else {
      return text;
    }
  }
  // If lowercase, add leading ellipsis
  if (text.charAt(0).toLowerCase() === text.charAt(0)) {
    return '… ' + text;
  }
  return text;
}

// @param {obj} props - api response for a SearchHit
// TODO: Update this parseSearchItem with a proper type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseSearchItem(props: any) {
  const { isProcessed, mark, type, clip, timestamp, matchText } = props;
  let item;
  let label;
  let startTimeMs;
  let endTimeMs;

  if (type === 'NOTE') {
    item = mark;
    label = isProcessed ? 'Play note' : 'Note';
    startTimeMs = Math.max(timestamp - 10 * 1000, 0);
    endTimeMs = timestamp + 10 * 1000;
  } else if (type === 'HIGHLIGHT') {
    item = clip;
    label = isProcessed ? 'Play clip' : 'Clip';
    startTimeMs = item.timestamp ?? item.data?.timestamp;
    endTimeMs = item.outTimestamp ?? item.data?.outTimestamp;
  } else {
    item = props;
    label = isProcessed ? 'Play quote' : 'Quote';
    startTimeMs = Math.max(timestamp - 10 * 1000, 0);
    endTimeMs = timestamp + 10 * 1000;
  }

  // Quotes do not have ids
  const id = item?.id ?? stringToInteger(matchText);

  return {
    id,
    label,
    startTimeMs,
    endTimeMs
  };
}

// @returns {string} search path with passed query using existing search parameters
export function getSearchPath(queryString: string) {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('q', queryString);
  return `/app/search?${searchParams.toString()}`;
}
