// @ts-strict-ignore
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { IntelligenceTemplate } from '@grain/api/schema.generated';
import { RecordingWithScheduledTimes } from '@grain/api/schema.extra';
import { useIntelligenceTemplatesQuery } from '@grain/api/graphql/modules/templates.generated';
import { useRecordingIntelligenceTemplateSetMutation } from '@grain/api/graphql/queries/notesTemplate.generated';
import {
  ButtonIcon,
  ControlGroup,
  Icon,
  media,
  spaces,
  spacing,
  useConfirm
} from '@grain/grain-ui';
import { isDesktopApp } from '@grain/desktop-lib';
import { useActiveRecordings } from '@grain/components/ActiveRecordingsContext';
import { useMediaQuery } from '@grain/components/support/browser';
import { useBot } from '~/components/StartBot';
import { LiveRecordingHeaderMetadata } from '~/components/HeaderMetadata';

import { useRecordingPage } from '../context';
import {
  StyledHeader,
  StyledContainer,
  StyledLeft,
  StyledRight
} from '../RecordingHeader/styles';
import { LiveRecordingDropdownMenu } from './LiveRecordingDropdownMenu';
import { Option, Select } from '@grain/grain-ui/v4';

type LiveRecordingHeaderProps = Omit<
  ComponentProps<typeof StyledHeader>,
  'isExpanded'
>;

export function LiveRecordingHeader(props: LiveRecordingHeaderProps) {
  const showConfirm = useConfirm();
  const isSmallScreen = useMediaQuery(media.small);

  const { recordingRes } = useRecordingPage();
  const recording = recordingRes.data?.recording;

  const { getRecordingScheduledTimes } = useActiveRecordings();
  const recordingWithScheduledTimes: RecordingWithScheduledTimes = useMemo(
    () => ({ ...recording, ...getRecordingScheduledTimes(recording.id) }),
    [recording, getRecordingScheduledTimes]
  );

  const { data: intelligenceTemplatesData } = useIntelligenceTemplatesQuery();
  const meetingTemplateList = useMemo(
    () =>
      (intelligenceTemplatesData?.intelligenceTemplates ??
        []) as IntelligenceTemplate[],
    [intelligenceTemplatesData]
  );
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    ''
  );

  useEffect(() => {
    if (recording.intelligenceTemplate?.template?.id) {
      setSelectedTemplateId(recording.intelligenceTemplate?.template?.id);
    }
  }, [recording.intelligenceTemplate?.template?.id]);

  const [setRecordingTemplate] = useRecordingIntelligenceTemplateSetMutation();
  const handleChangeTemplate = useCallback(
    (templateId: string) => {
      setRecordingTemplate({
        variables: {
          recordingId: recording.id,
          intelligenceTemplateId: templateId,
          eventProperties: JSON.stringify({
            trigger: 'live_meeting_page'
          })
        }
      });
      setSelectedTemplateId(templateId);
    },
    [recording, setRecordingTemplate]
  );

  // Sharing disabled until we provide support for it for non-members.
  // const { isOpen: shareRecordingIsOpen, open: openShare } =
  //   useRouteModal(SHARE_RECORDING_ID);

  const { stopRecording } = useBot();
  const handleStopRecording = useCallback(() => {
    showConfirm({
      confirmButtonType: 'danger',
      confirmContent: 'Stop',
      title: `Are you sure you want to stop recording: ${recording.title}`,
      cancelContent: 'Cancel',
      width: 418,
      closable: false,
      onConfirm: () =>
        stopRecording({
          recordingId: recording.id
        })
    });
  }, [recording, showConfirm, stopRecording]);

  return (
    <StyledHeader isExpanded={false} {...props}>
      <StyledContainer>
        <StyledLeft css={[isDesktopApp && isSmallScreen && spacing.ml9]}>
          <div css={['display: flex; align-items: center; width: 100%;']}>
            <div className='undraggable-children' css={['min-width: 0;']}>
              <LiveRecordingHeaderMetadata
                recording={recordingWithScheduledTimes}
              />
            </div>
          </div>
        </StyledLeft>
        {isSmallScreen ? (
          <ControlGroup gap={spaces[4]}>
            <LiveRecordingDropdownMenu stopRecording={handleStopRecording}>
              <ButtonIcon
                className='undraggable-children'
                variant='stealth'
                data-cy='menu-more-button'
              >
                <Icon.MenuMore />
              </ButtonIcon>
            </LiveRecordingDropdownMenu>
          </ControlGroup>
        ) : (
          <StyledRight>
            <ControlGroup className='undraggable-children' gap={spaces[4]}>
              <Select
                label='Template'
                value={selectedTemplateId || ''}
                placeholder='Select Template'
                onChange={handleChangeTemplate}
              >
                <Option
                  value={null}
                  // We display an Option with `None` as the label, but we want the select label to be "Select Template"
                  // So we set `selected` to true if `selectedTemplateId` is null.
                  // If selected is false, it will indent the option. So we explicitly set it to undefined.
                  selected={!selectedTemplateId ? true : undefined}
                >
                  None
                </Option>
                {(meetingTemplateList || []).map(template => (
                  <Option key={template.id} value={template.id}>
                    {template.title}
                  </Option>
                ))}
              </Select>
              {/* <ButtonIcon
                    data-cy='recording-share'
                    variant='stealth'
                    onClick={openShare}
                    aria-haspopup='true'
                    title='Share recording'
                    aria-label='Share recording'
                  >
                    <Icon.Share />
                  </ButtonIcon> */}
            </ControlGroup>
          </StyledRight>
        )}
      </StyledContainer>
      {/* {shareRecordingIsOpen && (
            <ShareRecordingPermissions recording={recording} />
          )} */}
    </StyledHeader>
  );
}
