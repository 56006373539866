import styled from '@emotion/styled';
import { Button, theme } from '@grain/grain-ui/v4';
import StepOne from './step-one.jpg';
import StepTwo from './step-two.jpg';

type MacDownloadProps = {
  onDownloadAgain: () => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.tokens.spacing.xl};
  padding: ${theme.tokens.spacing['3xl']};
`;

const StepsContainer = styled.div`
  display: flex;
  gap: 40px;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.tokens.spacing.lg};
  text-align: center;
  max-width: 414px;
  flex: 1 0 50%;
`;

const StepNumber = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${theme.tokens.color.surfaceTertiary};
  display: flex;
  align-items: center;
  justify-content: center;
  ${theme.tokens.typography.h3};
  color: ${theme.tokens.color.textInactive};
`;

const StepText = styled.div`
  ${theme.tokens.typography.b3[400]};
  color: ${theme.tokens.color.textSecondary};
  max-width: 280px;
`;

const Image = styled.img`
  width: 100%;
  max-width: 414px;
`;

const DownloadAgainContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.tokens.spacing['2xs']};
  margin-top: 100px;
`;

const Text = styled.div`
  ${theme.tokens.typography.b4[400]};
  color: ${theme.tokens.color.textSecondary};
`;

export const MacDownload = ({ onDownloadAgain }: MacDownloadProps) => {
  return (
    <Container>
      <StepsContainer>
        <Step>
          <StepNumber>1</StepNumber>
          <StepText>
            Once downloaded, open the .zip file by selecting it from your
            downloads folder.
          </StepText>
          <Image src={StepOne} alt='Download step 1' />
        </Step>
        <Step>
          <StepNumber>2</StepNumber>
          <StepText>
            Once installed, open the Grain app
            <br />
            <span> and sign-in to your account.</span>
          </StepText>
          <Image src={StepTwo} alt='Download step 2' />
        </Step>
      </StepsContainer>
      <DownloadAgainContainer>
        <Text>Problems?</Text>
        <Button variant='ghost' onClick={onDownloadAgain}>
          Download again
        </Button>
      </DownloadAgainContainer>
    </Container>
  );
};
