import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Button,
  Icon16,
  Menu,
  Skeleton,
  TextLabel,
  theme,
  Thumbnail
} from '@grain/grain-ui/v4';
import { Duration } from 'components/atoms/Duration/Duration';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { AdHocClipV2Fragment } from '~/modules/contentFilter/contentfilter.generated';
import { ButtonMetadata } from '~/modules/meetings/ButtonMetadata';
import { ClipOverflowMenuContent } from './ClipOverflowMenuContent';

type ClipListItemProps = {
  clip: AdHocClipV2Fragment;
};

const Container = styled.article`
  ${theme.utils.py('14px')}
  ${theme.utils.px('3xl')}
  cursor: pointer;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto auto;
  flex-shrink: 0;
  gap: ${theme.tokens.spacing.md};

  &:hover {
    background-color: ${theme.tokens.color.surfaceSecondary};
  }
`;

const Title = styled.h3`
  display: inline-flex;
  align-items: center;
  ${theme.tokens.typography.b3[500]};
  color: ${theme.tokens.color.textPrimary};
  padding: 0;
  margin: 0 0 ${theme.tokens.spacing['2xs']} 0;
`;

const Meta = styled.div`
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textTertiary};
`;

const getDuration = ({ data }: AdHocClipV2Fragment) => {
  if ('inTimestamp' in data && 'outTimestamp' in data) {
    return data.outTimestamp - data.inTimestamp;
  }

  return null;
};

export const ClipListItem = ({ clip }: ClipListItemProps) => {
  const duration = getDuration(clip);

  const thumbnail = useMemo(() => {
    if ('thumbnailJpegUrl' in clip.data && clip.data.thumbnailJpegUrl) {
      return clip.data.thumbnailJpegUrl;
    }
    return '';
  }, [clip]);

  const getRecordingClipUrl = () => {
    const url = new URL(clip.data.recording?.recordingUrl || '');
    url.searchParams.set('t', clip.data.timestamp.toString());
    url.searchParams.set('mediaTab', 'clips');
    url.searchParams.set('clipId', clip.id);

    return url.toString();
  };

  return (
    <Container>
      <Link
        to={getRecordingClipUrl()}
        css={css`
          display: contents;
        `}
      >
        <Thumbnail imgSrc={thumbnail} css={['height: 36px;']} />

        <div>
          <Title title={clip.data.text}>{clip.data.text}</Title>
          <Meta>
            {format(new Date(clip.data.createdAt), 'MMM, do h:mm a')} ·{' '}
            {duration && <Duration durationMs={duration || 0} />}
          </Meta>
        </div>
      </Link>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: ${theme.tokens.spacing.sm};
        `}
      >
        {clip.data.user && (
          <ButtonMetadata width='lg' disableInteractive>
            <TextLabel
              avatarProps={{
                name: clip.data.user.name,
                url: clip.data.user.avatarUrl || '',
                color: clip.data.user.hexColor
              }}
            >
              {clip.data.user.name}
            </TextLabel>
          </ButtonMetadata>
        )}

        {clip.data.recording ? (
          <Link to={getRecordingClipUrl()}>
            <ButtonMetadata width='lg'>
              <TextLabel size='sm' startIcon={Icon16.Record}>
                {clip.data.recording.title}
              </TextLabel>
            </ButtonMetadata>
          </Link>
        ) : (
          <ButtonMetadata width='lg'>
            <TextLabel size='sm' startIcon={Icon16.Record}>
              Unavailable
            </TextLabel>
          </ButtonMetadata>
        )}
      </div>
      <Menu
        unmountOnClose
        hideOnClickInside
        tippyProps={{ placement: 'bottom-end' }}
        content={<ClipOverflowMenuContent clip={clip} />}
      >
        <Button variant='ghost' size='md' icon={Icon16.Overflow} />
      </Menu>
    </Container>
  );
};

ClipListItem.Skeleton = () => (
  <Container>
    <Skeleton height={36} width={64} />
    <Skeleton height={16} width={200} />
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 24px;
      `}
    >
      <Skeleton height={16} width={140} />
      <Skeleton height={16} width={140} />
    </div>
    <Skeleton height={16} width={28} />
  </Container>
);
