import { Icon, useShowToast } from '@grain/grain-ui';
import {
  BaseMenuButton,
  Divider,
  Input,
  MenuButton,
  TextLabel,
  theme,
  Thumbnail
} from '@grain/grain-ui/v4';
import { AdHocClipV2Fragment } from '~/modules/contentFilter/contentfilter.generated';
import copy from 'clipboard-copy';
import { useHighlightsDelete } from '~/pages/Library/hooks';
import { useMemo, useState } from 'react';
import {
  StoriesQuerySimpleListV2Query,
  useStoriesQuerySimpleListV2Query,
  useStoryClipAddMutation
} from '~/modules/stories/graphql.generated';
import { css } from '@emotion/react';

type ClipOverflowMenuContent = {
  clip: AdHocClipV2Fragment;
};

type Mode = 'menu' | 'collections';

type Story = Extract<
  StoriesQuerySimpleListV2Query['viewAdHoc']['list'][number],
  { __typename?: 'Story' }
>;

export const ClipOverflowMenuContent = ({ clip }: ClipOverflowMenuContent) => {
  const [search, setSearch] = useState('');
  const [mode, setMode] = useState<Mode>('menu');
  const showToast = useShowToast();
  const deleteClip = useHighlightsDelete();

  const [addClipToStory] = useStoryClipAddMutation();

  const { data } = useStoriesQuerySimpleListV2Query({
    variables: {
      filter: JSON.stringify({ types: ['stories'], filters: [] })
    },
    fetchPolicy: 'cache-and-network',
    skip: Boolean(mode !== 'collections')
  });

  const stories = useMemo(() => {
    const cleanSearch = search.trim().toLowerCase();
    const list = (data?.viewAdHoc?.list || []) as Story[];
    return list.filter(story =>
      story.title.toLowerCase().includes(cleanSearch)
    );
  }, [data, search]);

  return mode === 'menu' ? (
    <>
      <MenuButton
        label='Copy link'
        textLabelProps={{ startIcon: Icon.CopyLink }}
        onClick={() => {
          if ('url' in clip.data && clip.data.url) {
            copy(clip.data.url);
            showToast({
              content: 'Clip copied to clipboard',
              type: 'success'
            });
          }
        }}
      />
      <MenuButton
        label='Add to Story'
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          setMode('collections');
        }}
        textLabelProps={{ startIcon: Icon.AddToStory }}
      />
      <Divider />
      <MenuButton
        label='Delete'
        textLabelProps={{ startIcon: Icon.Delete }}
        onClick={() => deleteClip([clip.id])}
      />
    </>
  ) : (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      `}
    >
      <Input
        autoFocus
        value={search}
        onClick={e => e.stopPropagation()}
        onChange={e => setSearch(e.target.value)}
        css={css`
          margin-bottom: 4px;
        `}
      />
      {stories.map(story => (
        <BaseMenuButton
          key={story.id}
          onClick={() =>
            addClipToStory({
              variables: {
                clipId: clip.id,
                storyId: story.id
              },
              onCompleted: () => {
                showToast({
                  type: 'success',
                  content: `Clip added to ${story.title}`
                });
              }
            })
          }
          css={css`
            display: flex;
            flex-direction: row;
            gap: ${theme.tokens.spacing.sm};
          `}
        >
          <Thumbnail
            imgSrc={story.bannerImageUrl || ''}
            css={css`
              height: 20px;
            `}
          />
          <TextLabel>{story.title}</TextLabel>
        </BaseMenuButton>
      ))}
    </div>
  );
};
