import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { StyledContainer, StyledHandle } from './styles';
import { Icon } from '@grain/grain-ui';

export default React.forwardRef(StoryItemWrapper);

StoryItemWrapper.propTypes = {
  item: PropTypes.object.isRequired,
  handleProps: PropTypes.object.isRequired,
  highlighted: PropTypes.bool.isRequired,
  disableHover: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired
};

function StoryItemWrapper(
  { item, handleProps, highlighted, disableHover, children, ...rest },
  ref
) {
  const isClip = item.__typename === 'StoryClipV2';

  return (
    <StyledContainer
      highlighted={highlighted}
      isClip={isClip}
      {...rest}
      className={cx(
        rest.className,
        disableHover && 'disable-hover',
        isClip ? 'clip' : 'section'
      )}
      ref={ref}
    >
      <StyledHandle {...handleProps}>
        <Icon.DragHandle />
      </StyledHandle>
      {children}
    </StyledContainer>
  );
}
