import { PersonContactType } from '@g/schema';
import { type FreemiumTrialOnboardingRecordingFragmentFragment as Recording } from './freemium.generated';

export type CrmProvider = 'hubspot' | 'salesforce';

export function crmProviderForRecording(
  recording: Recording
): CrmProvider | undefined {
  for (const participant of recording.participants || []) {
    for (const contact of participant.person?.contacts || []) {
      if (
        [
          PersonContactType.HubspotContactId,
          PersonContactType.HubspotOwnerId,
          PersonContactType.SalesforceLeadId,
          PersonContactType.SalesforceContactId
        ].includes(contact.contactType)
      ) {
        return [
          PersonContactType.HubspotContactId,
          PersonContactType.HubspotOwnerId
        ].includes(contact.contactType)
          ? 'hubspot'
          : 'salesforce';
      }
    }
  }
}
