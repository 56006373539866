import { Modal, ModalTitle, useRouteModal } from '@grain/grain-ui';
import { TextLabel } from '@grain/grain-ui/v4';
import { useWorkspaceWithMembers } from '@grain/components/Workspace/hooks';
import { INVITE_ROUTE_ID } from '@grain/components/modals/constants';
import SendInvite from './SendInvite';
import { StyledContentContainer } from './styles';

export default function InviteModal() {
  const { close } = useRouteModal(INVITE_ROUTE_ID);
  const { workspace } = useWorkspaceWithMembers();

  if (!workspace) return null;

  const title = `Invite to ${workspace.name} workspace`;

  return (
    <Modal closable width={600} onCancel={close}>
      <ModalTitle
        title={
          <TextLabel
            css={['display: inline-flex;']}
            size='lg'
            avatarProps={{
              url: workspace.logoUrl ?? undefined,
              name: workspace.name,
              variant: 'square'
            }}
          >
            {title}
          </TextLabel>
        }
        centered
      />
      <StyledContentContainer>
        <SendInvite
          onNext={() => {
            close();
          }}
        />
      </StyledContentContainer>
    </Modal>
  );
}
