import React from 'react';
import styled from '@emotion/styled';
import { Skeleton, spacing } from '@grain/grain-ui';

import { useDealPipelinesQuery } from './deal.generated';
import { Option, Select, PageHeader, Icon20 } from '@grain/grain-ui/v4';
import { css } from '@emotion/react';
import { useSidebar } from '~/components/Layout';
import { isDesktopApp } from '@grain/desktop-lib';
const PipelinePicker = styled.div`
  padding: 6px 12px;
  display: flex;
  ${spacing.g4}
`;

type Props = {
  pipelineResults?: ReturnType<typeof useDealPipelinesQuery>;
  currentPipelineId?: string;
  setCurrentPipelineId?: (pipelineId: string) => void;
  hideMenu?: boolean;
};

export const DealPageHeader = ({
  pipelineResults,
  currentPipelineId,
  setCurrentPipelineId,
  hideMenu = false
}: Props) => {
  const { toggleCollapsed } = useSidebar();

  const { data: pipelinesData, loading: pipelinesLoading } =
    pipelineResults ?? {};

  const pipelines = React.useMemo(
    () => pipelinesData?.dealPipelines ?? [],
    [pipelinesData]
  );

  const selectedPipeline = React.useMemo(() => {
    if (!currentPipelineId) {
      return;
    }

    return pipelines.find(({ pipelineId }) => pipelineId === currentPipelineId);
  }, [currentPipelineId, pipelines]);

  const rightSection = (
    <>
      {!hideMenu &&
        (pipelinesLoading ? (
          <PipelinePicker
            css={css`
              padding: 0;
            `}
          >
            <Skeleton width='150px' height='20px' />
          </PipelinePicker>
        ) : (
          <PipelinePicker
            css={css`
              padding: 0;
            `}
          >
            <Select
              matchWidth
              onChange={pipelineId => {
                setCurrentPipelineId?.(pipelineId);
              }}
              value={selectedPipeline?.pipelineId ?? ''}
              label='Pipeline'
            >
              {pipelines.map(({ label, pipelineId }) => (
                <Option key={pipelineId} value={pipelineId}>
                  {label}
                </Option>
              ))}
            </Select>
          </PipelinePicker>
        ))}
    </>
  );

  return (
    <PageHeader
      onMenuClick={toggleCollapsed}
      title='Deals'
      icon={Icon20.BankNote}
      rightSection={rightSection}
      isDesktopApp={isDesktopApp}
    />
  );
};
