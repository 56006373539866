import {
  DealMomentumState,
  type DealMomentum
} from '@grain/api/schema.generated';
import { capitalize, Icon, type IconType } from '@grain/grain-ui';
import { theme } from '@grain/grain-ui/v4';

import { StatePill } from './StatePill';

const momentumStateToIcon: { [key in DealMomentumState]: IconType } = {
  PROGRESSING: Icon.ArrowNarrowUpRight,
  SLOWING: Icon.ArrowNarrowRight,
  STALLED: Icon.ArrowNarrowDownRight
};

const momentumStateToColor: { [key in DealMomentumState]: string } = {
  PROGRESSING: theme.tokens.color.iconBrand,
  SLOWING: theme.tokens.color.iconWarning,
  STALLED: theme.tokens.color.iconDanger
};

type MomentumStateProps = {
  isOpen: boolean;
  state: DealMomentum['state'];
};

export function MomentumState({ isOpen, state }: MomentumStateProps) {
  const icon = momentumStateToIcon[state];
  const color = momentumStateToColor[state];
  const displayState = capitalize(state.toLowerCase());
  return (
    <StatePill isOpen={isOpen} icon={icon} title={displayState} color={color} />
  );
}
