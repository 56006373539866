import { useMemo, useEffect, useCallback } from 'react';
import {
  cacheDeleteItem,
  useRemoveFromAdHocCache,
  useUpdateAdHocCache
} from './cacheHelpers';
import { useAnalytics } from '@grain/grain-ui';
import {
  useViewAdHocQuery,
  useViewAdHocChangedSubscription
} from './contentfilter.generated';
import { ViewChangedType } from '@grain/api/schema.generated';
import { useLocation } from 'react-router-dom';
import { usePrevLocationContext } from '../../routers/usePrevLocation';
import { WatchQueryFetchPolicy } from '@apollo/client';
import { useFilters, UseFiltersParams } from './useFilters';

export type UseAdHocFiltersParams = UseFiltersParams & {
  fetchPolicy?: WatchQueryFetchPolicy;
  skip?: boolean;
  includeCoaching?: boolean;
  includeScoring?: boolean;
  includeIntelligence?: boolean;
  viewAdHocOptions?: Parameters<typeof useViewAdHocQuery>[0];
  page?: string;
};

export const useAdHocFilters = ({
  filterType,
  filters: filtersList,
  viewerRole,
  workspaceShared,
  explicitShared,
  locked,
  storagePrefix = 'library',
  storageType,
  collectionId,
  participantScope,
  fetchPolicy = 'cache-and-network',
  skip,
  hideFilter = false,
  hasSmartTagsFilter = false,
  onlyCoachingOpportunity = false,
  includeCoaching = false,
  includeScoring = false,
  includeIntelligence = false,
  initialSortBy,
  hasFeedbackAssessments = false,
  includeDeleted = false,
  onlyDeleted = false,
  viewAdHocOptions = {},
  initialFilters = {},
  page
}: UseAdHocFiltersParams) => {
  const {
    activeFilters,
    filtersMap,
    isFilterActive,
    filters,
    filter,
    filterAsJson,
    onFilterChange,
    onSearch,
    resetAllFilters,
    setSortByFiltered,
    setSortDirectionFiltered,
    sortBy,
    sortDirection,
    setTagFilterWithName,
    setFilterById
  } = useFilters({
    filterType,
    filters: filtersList,
    viewerRole,
    workspaceShared,
    explicitShared,
    locked,
    storagePrefix,
    storageType,
    collectionId,
    participantScope,
    filterPeople: true,
    onlyCoachingOpportunity,
    hideFilter,
    hasSmartTagsFilter,
    initialSortBy,
    hasFeedbackAssessments,
    includeDeleted,
    onlyDeleted,
    initialFilters
  });

  const location = useLocation();
  const prevLocation = usePrevLocationContext();

  const { trackEvent } = useAnalytics();
  const updateAdHocCache = useUpdateAdHocCache();
  const removeFromAdHocCache = useRemoveFromAdHocCache();

  const {
    data: viewData,
    fetchMore: fetchMoreFiltered,
    loading,
    error,
    refetch
  } = useViewAdHocQuery({
    fetchPolicy,
    skip,
    variables: {
      sortDirection,
      sortBy,
      filter,
      includeCoaching,
      includeScoring,
      includeIntelligence
    },
    onCompleted: (...data) => {
      const activeFiltersKeys = activeFilters.filter(filter => filter?.name);

      if (viewAdHocOptions?.onCompleted) {
        viewAdHocOptions.onCompleted(...data);
      }

      activeFilters.length &&
        trackEvent(
          'Filter Used',
          {
            content_type: page ?? filterType,
            filter_by: activeFiltersKeys.map(filter => filter?.name)
          },
          ['user', 'workspace']
        );
    }
  });

  useEffect(() => {
    const prevPath = prevLocation?.pathname;
    // Refetch if views change
    if (
      ['/app/workspace', '/app'].includes(prevPath) &&
      location?.pathname !== prevPath
    ) {
      refetch();
    }
  }, [location, prevLocation, refetch]);

  useViewAdHocChangedSubscription({
    variables: {
      filter
    },
    onData: ({ client, data }) => {
      const { viewAdHocChanged } = data.data!;
      if (viewAdHocChanged.type === ViewChangedType.Added) {
        updateAdHocCache(viewAdHocChanged.data);
      }

      if (viewAdHocChanged.type === ViewChangedType.Removed) {
        let typename = viewAdHocChanged.data.__typename!;
        if (typename === 'RemovedMediaClip') typename = 'ClipV2';
        if (typename === 'RemovedMediaRecording') typename = 'Recording';
        if (typename === 'RemovedMediaStory') typename = 'Story';

        cacheDeleteItem(client, {
          typename,
          id: viewAdHocChanged.data.id
        });
        removeFromAdHocCache(viewAdHocChanged.data.id);
      }
    }
  });

  const cursor = viewData?.viewAdHoc?.cursor;
  const fetchMoreWithCursor = useCallback(() => {
    !!cursor && fetchMoreFiltered({ variables: { cursor } });
  }, [cursor, fetchMoreFiltered]);

  const value = useMemo(
    () => ({
      activeFilters,
      filtersMap,
      isFilterActive,
      error,
      fetchMoreFiltered,
      hasMore: !!cursor,
      fetchMoreWithCursor,
      filters,
      filter,
      filterAsJson,
      loading,
      onFilterChange,
      onSearch,
      resetAllFilters,
      setSortByFiltered,
      setSortDirectionFiltered,
      sortBy,
      viewData,
      setTagFilterWithName,
      setFilterById,
      refetch
    }),
    [
      activeFilters,
      filtersMap,
      cursor,
      isFilterActive,
      error,
      fetchMoreFiltered,
      fetchMoreWithCursor,
      filters,
      filter,
      filterAsJson,
      loading,
      onFilterChange,
      onSearch,
      resetAllFilters,
      setSortByFiltered,
      setSortDirectionFiltered,
      sortBy,
      viewData,
      setTagFilterWithName,
      setFilterById,
      refetch
    ]
  );

  return value;
};
