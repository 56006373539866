import styled from '@emotion/styled';

import { theme } from '@grain/grain-ui/v4';

import { DealStageChangeFragment } from '../../deal.generated';
import { getActivityTimestamp } from '../../utils';

import { formatTime } from './Timeline';

const StyledContainer = styled.div`
  padding: ${theme.tokens.spacing.lg};
  display: flex;
  gap: ${theme.tokens.spacing.sm};
  ${theme.tokens.typography.c1[500]};
`;

const StyledTitle = styled.div`
  color: ${theme.tokens.color.textPrimary};
`;

const StyledTime = styled.div`
  color: ${theme.tokens.color.textSecondary};
`;

type StageChangeProps = { stage: DealStageChangeFragment };
export const StageChange = ({ stage }: StageChangeProps) => {
  return (
    <StyledContainer>
      <StyledTitle>Stage changed to {stage.stage}</StyledTitle>
      <StyledTime>{formatTime(getActivityTimestamp(stage))}</StyledTime>
    </StyledContainer>
  );
};
