import { useCallback, useEffect, useMemo } from 'react';
import Helmet from 'react-helmet';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import isThisYear from 'date-fns/isThisYear';

import {
  Divider as LegacyDivider,
  Icon,
  useAnalytics,
  media
} from '@grain/grain-ui';
import LegacyButton from '@grain/components/Button';
import { useActiveRecordings } from '@grain/components/ActiveRecordingsContext';
import { colors, spacing } from '@grain/styles/constants';
import { useMyself, useFeature, useWorkspace } from '@grain/api/auth';
import { useWorkspaceRecordingTags } from '@grain/components/Tags/hooks';
import { WorkspaceTagsChangedDocument } from '@grain/components/Tags/tags.generated';
import { useMediaQuery } from '@grain/components/support/browser';
import { useCalendarQuery } from '@grain/api/graphql/modules/calendar.generated';
import { useMutation } from '@grain/api/graphql';
import { CalendarUpdatedDocument } from '@grain/api/graphql/subscriptions/subscriptions.generated';
import { calendarEventWorkspaceSharedOverrideSetMutation } from '@grain/api/graphql/mutations';
import {
  Calendar,
  CalendarEvent,
  CalendarMeetingProvider,
  IntelligenceTemplate,
  WorkspaceTag
} from '@grain/api/schema.generated';

import { useOpenSettings } from '~/modules/settings';
import * as Layout from '~/components/Layout';
import { useBot } from '~/components/StartBot';
import { GatedContentWrapper } from '~/modules/gates';
import { getIsOnPaidPlan } from '~/support/getIsOnPaidPlan';

import MeetingCard from './MeetingCard';
import { StyledCard } from './MeetingCard/styles';
import { EmptyCalendarState } from './EmptyCalendarState';

import {
  StyledLayoutContent,
  StyledPageWrapper,
  StyledInnerPageWrapper,
  StyledMeetingControl,
  StyledMeetingInput,
  StyledLeftColumn,
  StyledColumnsContainer,
  compactStyles
} from './styles';
import {
  Button,
  Divider,
  Icon16,
  Icon20,
  PageHeader
} from '@grain/grain-ui/v4';
import { css } from '@emotion/react';
import { isDesktopApp } from '@grain/desktop-lib';

// For debugging in dev/staging:
declare global {
  interface Window {
    resetWorkspaceSharedOverrides: () => void;
  }
}

function getDateLabel(day: string) {
  return isToday(new Date(day))
    ? 'Today'
    : isTomorrow(new Date(day))
      ? 'Tomorrow'
      : isThisYear(new Date(day))
        ? format(new Date(day), 'eeee, MMM d')
        : format(new Date(day), 'eeee, MMM d, y');
}

type DateDividerProps = { label: string };

function DateDivider({ label }: DateDividerProps) {
  return <LegacyDivider label={label} spacing={24} icon={Icon.Calendar} />;
}

export default function RecordCall() {
  const { toggleCollapsed } = Layout.useSidebar();
  const openRecordingSettings = useOpenSettings('account', 'recordings');
  const { enabled } = useFeature('create_recording');

  const openCall = useCallback((url: string) => {
    window.open(url, '_blank');
  }, []);

  const isSmallScreen = useMediaQuery(media.small);
  const { linkValue, setLinkValue, startRecording, linkIsValid } = useBot();
  const { meetings } = useActiveRecordings();
  const { myself } = useMyself();
  const { trackEvent } = useAnalytics();

  const {
    subscribeToMore: workspaceTagsSubscribeToMore,
    workspaceRecordingTags
  } = useWorkspaceRecordingTags();
  useEffect(() => {
    const cancel = workspaceTagsSubscribeToMore({
      document: WorkspaceTagsChangedDocument,
      updateQuery: (
        prev,
        {
          subscriptionData
        }: {
          subscriptionData: { data: { workspaceTagsChanged: WorkspaceTag[] } };
        }
      ) => {
        if (!subscriptionData.data) return prev;
        return {
          ...prev,
          workspaceTags: subscriptionData.data.workspaceTagsChanged
        };
      }
    });

    return () => cancel();
  }, [workspaceTagsSubscribeToMore]);

  const {
    data: calendarData,
    subscribeToMore: calendarDataSubscribeToMore,
    refetch: refetchCalendarData
  } = useCalendarQuery();

  useEffect(() => {
    const unsubscribe = calendarDataSubscribeToMore({
      document: CalendarUpdatedDocument,
      updateQuery: (
        prev,
        {
          subscriptionData
        }: { subscriptionData: { data: { calendarUpdated: Calendar } } }
      ) => {
        if (!subscriptionData.data) return prev;
        return { ...prev, calendar: subscriptionData.data.calendarUpdated };
      }
    });

    // @edwardbaeg - There exists a race condition where a newly connected
    // calendar account is still syncing when the initial query completes
    // but finishes before the client subscribes. This fixes that.
    refetchCalendarData();

    return unsubscribe;
  }, [calendarDataSubscribeToMore, refetchCalendarData]);

  // DEBUGGING IN DEV/STAGING:

  const [calendarEventWorkspaceSharedOverrideSet] = useMutation(
    calendarEventWorkspaceSharedOverrideSetMutation
  );

  useEffect(() => {
    if (process.env.ENVIRONMENT !== 'production') {
      window.resetWorkspaceSharedOverrides = () => {
        if (!calendarData) throw Error('Calendar data not loaded yet');
        const meetingsWithOverride = meetings.filter(
          meeting => typeof meeting.workspace_shared_override === 'boolean'
        );
        const eventsWithOverride = calendarData.calendar.events.filter(
          event => typeof event.workspaceSharedOverride === 'boolean'
        );
        const eventIdsWithOverride = [
          ...meetingsWithOverride.map(
            ({ calendar_event_id }) => calendar_event_id
          ),
          ...eventsWithOverride.map(({ id }) => id)
        ];
        eventIdsWithOverride.forEach(eventId => {
          calendarEventWorkspaceSharedOverrideSet({
            variables: {
              eventId,
              workspaceSharedOverride: null
            }
          });
        });
      };
    }
  }, [meetings, calendarData, calendarEventWorkspaceSharedOverrideSet]);

  // END DEBUGGING IN DEV/STAGING

  const { workspace } = useWorkspace();
  const isOnPaidPlan = getIsOnPaidPlan(workspace);

  const providers = useMemo(() => {
    if (!myself) return [];
    return myself?.oauthAccounts?.map(account => account.provider);
  }, [myself]);

  const hasCalendarConnected = useMemo(() => {
    if (!myself) return null;
    return myself?.oauthAccounts?.some(account => account.calendarConnected);
  }, [myself]);

  const calendarSyncing = useMemo(() => {
    if (!calendarData) return true;
    return calendarData?.calendar?.accounts?.some(account =>
      ['NOT_SYNCED', 'SYNCING'].includes(account.status)
    );
  }, [calendarData]);

  const days = useMemo(() => {
    if (!calendarData?.calendar) return {};

    return calendarData.calendar.events.reduce(
      (acc, event) => {
        // Omit calendar events that are currently happening
        if (meetings.find(meeting => meeting.calendar_event_id === event.id)) {
          return acc;
        }

        const day = format(new Date(event.start), 'MM/dd/yyyy');

        if (acc[day]) {
          acc[day].push(event as CalendarEvent);
        } else {
          acc[day] = [event as CalendarEvent];
        }
        return acc;
      },
      {} as Record<string, CalendarEvent[]>
    );
  }, [calendarData, meetings]);

  const handleStartRecordingByLink = () => {
    if (!linkValue) return;
    startRecording({ link: linkValue }).then(() => {
      trackEvent(
        'Link Recording Started',
        {
          button_name: 'link_recording_started',
          category: 'Scheduling',
          location: 'calendar_page'
        },
        ['user', 'workspace']
      );
    });
  };

  const handleStartRecordingByMeetingId = useCallback(
    (meetingId: string) => {
      if (!meetingId) return;
      startRecording({ meetingId });
    },
    [startRecording]
  );

  const isEmpty = calendarData?.calendar?.events?.length === 0;

  const activeMeetingsToRender = useMemo(() => {
    return meetings.map(meeting => {
      const meetingProvider: CalendarMeetingProvider =
        (meeting.provider?.toUpperCase() as CalendarMeetingProvider) ||
        CalendarMeetingProvider.Zoom;
      const intelligenceTemplate = meeting.intelligence_template_id
        ? ({
            id: meeting.intelligence_template_id
          } as IntelligenceTemplate)
        : undefined;
      return (
        <MeetingCard
          event={{
            meetingProvider,
            intelligenceTemplate,
            intelligenceTemplateV2: intelligenceTemplate || null,
            title: meeting.title,
            start: meeting.scheduled_start_datetime ?? undefined,
            end: meeting.scheduled_end_datetime ?? undefined,
            internal: meeting.internal_event,
            internalOrganiser: meeting['is_internal_organiser?'],
            meetingUrl: meeting.url ?? undefined,
            id: meeting.calendar_event_id,
            workspaceSharedOverride: meeting.workspace_shared_override
          }}
          key={meeting.id}
          meeting={meeting}
          onRecordingStart={() => handleStartRecordingByMeetingId(meeting?.id)}
          onRecurringEventTemplateChange={refetchCalendarData}
          openCall={openCall}
        />
      );
    });
  }, [
    handleStartRecordingByMeetingId,
    meetings,
    openCall,
    refetchCalendarData
  ]);

  return (
    <Layout.Wrapper withSidebar>
      <Helmet title='Calendar' />
      {!enabled && (
        <>
          <PageHeader
            title='Calendar'
            icon={Icon20.Calendar}
            onMenuClick={toggleCollapsed}
            isDesktopApp={isDesktopApp}
          />
          <Divider />
        </>
      )}
      <GatedContentWrapper gate='create_recording'>
        <StyledLayoutContent>
          <StyledPageWrapper
            css={[
              hasCalendarConnected && 'height: 100%;',
              isSmallScreen && compactStyles
            ]}
          >
            <StyledInnerPageWrapper>
              <div
                css={css`
                  position: sticky;
                  top: 0;
                  z-index: 1;
                `}
              >
                <PageHeader
                  title='Calendar'
                  icon={Icon20.Calendar}
                  onMenuClick={toggleCollapsed}
                  rightSection={
                    !isSmallScreen && (
                      <Button
                        variant='neutral'
                        icon={Icon16.Settings01}
                        onClick={openRecordingSettings}
                      >
                        Recording settings
                      </Button>
                    )
                  }
                  isDesktopApp={isDesktopApp}
                />
                <Divider />
              </div>
              <StyledColumnsContainer>
                <StyledLeftColumn>
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      handleStartRecordingByLink();
                    }}
                  >
                    <StyledMeetingControl>
                      <StyledMeetingInput
                        value={linkValue}
                        onChange={e => setLinkValue(e.currentTarget.value)}
                        placeholder='Enter meeting link or ID'
                      />
                      <LegacyButton
                        type='primary'
                        htmlType='submit'
                        disabled={!linkIsValid || linkValue === ''}
                      >
                        Record
                      </LegacyButton>
                    </StyledMeetingControl>
                  </form>
                  {activeMeetingsToRender.length > 0 && (
                    <div>
                      <DateDivider label='Now' />
                      {activeMeetingsToRender}
                    </div>
                  )}
                  {Object.keys(days).map(day => (
                    <div key={day}>
                      <DateDivider label={getDateLabel(day)} />

                      {days[day].map(event => (
                        <MeetingCard
                          accessibleTags={workspaceRecordingTags}
                          event={event}
                          key={event.id}
                          openCall={openCall}
                          onRecurringEventTemplateChange={refetchCalendarData}
                        />
                      ))}
                    </div>
                  ))}
                </StyledLeftColumn>
              </StyledColumnsContainer>
            </StyledInnerPageWrapper>
            {hasCalendarConnected &&
              isEmpty &&
              (calendarSyncing ? (
                <div>
                  <DateDivider label='Today' />
                  <StyledCard
                    css={[`background: ${colors.swan};`, spacing.mb6]}
                  >
                    We're importing your calendar events. This may take a few
                    seconds.
                  </StyledCard>
                </div>
              ) : (
                <EmptyCalendarState
                  view='no-events'
                  isOnPaidPlan={isOnPaidPlan}
                />
              ))}
            {!hasCalendarConnected && !calendarSyncing && (
              <EmptyCalendarState
                view='not-connected'
                email={myself?.user?.email}
                providers={providers}
              />
            )}
          </StyledPageWrapper>
        </StyledLayoutContent>
      </GatedContentWrapper>
    </Layout.Wrapper>
  );
}
