import * as Types from '@grain/api/schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@grain/api/graphql';
const defaultOptions = {} as const;
export type StoryBannerUploadInfoQueryVariables = Types.Exact<{
  storyId: Types.Scalars['ID']['input'];
}>;


export type StoryBannerUploadInfoQuery = { __typename?: 'RootQueryType', storyBannerUploadInfo: { __typename?: 'ImageUploadInfo', maxUploadBytes: number, url: { __typename?: 'UploadUrl', uuid: string, url: string } | null } };


export const StoryBannerUploadInfoDocument = gql`
    query storyBannerUploadInfo($storyId: ID!) {
  storyBannerUploadInfo(storyId: $storyId) {
    url {
      uuid
      url
    }
    maxUploadBytes
  }
}
    `;

/**
 * __useStoryBannerUploadInfoQuery__
 *
 * To run a query within a React component, call `useStoryBannerUploadInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryBannerUploadInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryBannerUploadInfoQuery({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useStoryBannerUploadInfoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<StoryBannerUploadInfoQuery, StoryBannerUploadInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<StoryBannerUploadInfoQuery, StoryBannerUploadInfoQueryVariables>(StoryBannerUploadInfoDocument, options);
      }
export function useStoryBannerUploadInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StoryBannerUploadInfoQuery, StoryBannerUploadInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<StoryBannerUploadInfoQuery, StoryBannerUploadInfoQueryVariables>(StoryBannerUploadInfoDocument, options);
        }
export function useStoryBannerUploadInfoSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<StoryBannerUploadInfoQuery, StoryBannerUploadInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<StoryBannerUploadInfoQuery, StoryBannerUploadInfoQueryVariables>(StoryBannerUploadInfoDocument, options);
        }
export type StoryBannerUploadInfoQueryHookResult = ReturnType<typeof useStoryBannerUploadInfoQuery>;
export type StoryBannerUploadInfoLazyQueryHookResult = ReturnType<typeof useStoryBannerUploadInfoLazyQuery>;
export type StoryBannerUploadInfoSuspenseQueryHookResult = ReturnType<typeof useStoryBannerUploadInfoSuspenseQuery>;
export type StoryBannerUploadInfoQueryResult = Apollo.QueryResult<StoryBannerUploadInfoQuery, StoryBannerUploadInfoQueryVariables>;