import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/*
 * Use this hook to handle global keyboard shortcuts.
 */
export const useGlobalShortcuts = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const keyboardListener = (e: KeyboardEvent) => {
      const target = e.target as HTMLElement;

      // Ignore input and input-like fields
      if (
        ['INPUT', 'TEXTAREA'].includes(target?.tagName) ||
        target?.contentEditable === 'true'
      ) {
        return;
      }

      // Search shortcut
      if (e.key === '/') {
        e.preventDefault();
        navigate('/app/search', {
          state: {
            referrer: location.pathname
          }
        });
      }
    };

    window.addEventListener('keydown', keyboardListener);

    return () => window.removeEventListener('keydown', keyboardListener);
  }, [navigate]);
};
