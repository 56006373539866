import { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { format } from 'date-fns';

import { Icon, useAnalytics } from '@grain/grain-ui';
import {
  theme,
  Divider,
  PageHeader,
  Tab,
  Tabs,
  Breadcrumb,
  BreadcrumbLink,
  TextLabel
} from '@grain/grain-ui/v4';
import * as Layout from '~/components/Layout';
import { useUrlErrorBanner } from '~/modules/errors/hooks';
import { GatedContentWrapper } from '~/modules/gates';

import { useDealDetailsQuery, useDealActivityQuery } from '../deal.generated';
import { useCRMIntegrations } from '../hooks';

import { HeaderActions } from './HeaderActions';
import { Overview, SkeletonOverview } from './Overview';
import { Activity } from './Activity';
import { Contacts } from './Contacts';
import { DealProperties, SkeletonProperties } from './DealProperties';

const StyledClosedPill = styled.div`
  display: flex;
  gap: ${theme.tokens.spacing.xs};
  ${theme.utils.px('sm')};
  ${theme.utils.py('2xs')};
  ${theme.tokens.typography.b4[500]};
  border-radius: ${theme.tokens.radii.full};
  background-color: ${theme.tokens.color.surfaceTertiary};
`;

const StyledPanes = styled.div`
  flex: 1;
  min-height: 0; // Ensure fills remaining screen height
  display: flex;
`;

const StyledTabsPane = styled.div<{ shrink: boolean; minWidth: number }>`
  flex-grow: 1; // Expand when wide, but...
  ${({ shrink }) =>
    // ...do not get crushed when narrow, either
    !shrink && 'flex-shrink: 0;'}

  ${({ minWidth }) => `min-width: ${minWidth}px;`}

  display: flex;
  flex-direction: column;
  border-right: 1px solid ${theme.tokens.color.borderTertiary};

  > div[role='tablist'] {
    flex-shrink: 0;
  }
`;

const StyledPropertiesPane = styled.div`
  width: 380px;
  flex-shrink: 0;
`;

const StyledTabsContent = styled.div`
  min-height: 0; // Ensure fills remaining screen height
`;

const StyledOverviewLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing['2xl']};
  padding: ${theme.tokens.spacing['2xl']} ${theme.tokens.spacing['3xl']};
  height: 100%;
  overflow: auto;
`;

const StyledActivityLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .timeline {
    flex: 1;
  }
`;

const TABS = ['overview', 'activity', 'contacts'];

type DealDetailsProps = {
  contentType: 'overview' | 'activity' | 'contacts';
};

export default function DealDetails({
  contentType = 'overview'
}: DealDetailsProps) {
  const { toggleCollapsed } = Layout.useSidebar();
  const ErrorBanner = useUrlErrorBanner();
  const navigate = useNavigate();
  const { id: dealIdParam } = useParams();
  const selectedDealId = dealIdParam!;
  const { hubspotIntegration } = useCRMIntegrations();
  const { trackEvent } = useAnalytics();

  const { data: detailsData } = useDealDetailsQuery({
    skip: Boolean(!selectedDealId),
    variables: { id: selectedDealId, includeDealV4: true },
    onError: _e => {
      navigate('/app/deals');
      return false;
    }
  });

  const deal = detailsData?.deal;
  const pageTitle = deal?.name || 'Deal Details';

  const { data: activityData } = useDealActivityQuery({
    skip: Boolean(!deal?.id),
    variables: { id: deal?.id as string },
    onError: () => {
      navigate('/app/deals');
      return false;
    }
  });

  const currentTabIndex = contentType ? TABS.indexOf(contentType) : -1;
  const setCurrentTabIndex = useCallback(
    (nextIndex: number) => {
      const nextContentType = TABS[nextIndex];
      navigate(`/app/deals/${selectedDealId}/${nextContentType}`);
    },
    [navigate, selectedDealId]
  );

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (!contentType) return;

    trackEvent('Deal Tabs Changed', { tab: contentType }, [
      'user',
      'workspace'
    ]);
  }, [contentType, trackEvent]);

  return (
    <>
      <Helmet title={pageTitle} />
      <Layout.Wrapper withSidebar>
        <>
          <ErrorBanner />
          <GatedContentWrapper gate='deal_board'>
            <PageHeader
              onMenuClick={toggleCollapsed}
              breadcrumbs={
                <Breadcrumb>
                  <BreadcrumbLink
                    textLabelProps={{ startIcon: Icon.DealsOutline }}
                    to='/app/deals'
                  >
                    Deals
                  </BreadcrumbLink>
                  <TextLabel size='lg' css={theme.utils.px('sm')}>
                    <span>{pageTitle}</span>
                    {deal && !deal.isOpen && (
                      <StyledClosedPill>
                        <Icon.Check />
                        <span>
                          Deal Closed - Last updated{' '}
                          {format(new Date(deal.updatedAt), 'MMM d, yyyy')}
                        </span>
                      </StyledClosedPill>
                    )}
                  </TextLabel>
                </Breadcrumb>
              }
              rightSection={
                <HeaderActions
                  dealId={selectedDealId}
                  providerKey={deal?.providerKey}
                />
              }
            />
            <Divider />
            {currentTabIndex > -1 && (
              <StyledPanes>
                <StyledTabsPane
                  shrink={['activity', 'overview'].includes(contentType)}
                  minWidth={contentType === 'activity' ? 600 : 0}
                >
                  <Tabs
                    currentValue={currentTabIndex}
                    onChange={setCurrentTabIndex}
                    css={css`
                      display: flex;
                      align-items: center;
                      padding-left: 24px;
                      padding-right: 24px;
                      height: 56px;
                    `}
                  >
                    <Tab aria-controls='overview' value={0}>
                      Overview
                    </Tab>
                    <Tab aria-controls='activity' value={1}>
                      Activity
                    </Tab>
                    <Tab aria-controls='contacts' value={2}>
                      Contacts
                    </Tab>
                  </Tabs>
                  <Divider />
                  <StyledTabsContent>
                    {currentTabIndex === 0 && (
                      <StyledOverviewLayout id='overview'>
                        {deal && activityData ? (
                          <Overview deal={deal} activityData={activityData} />
                        ) : (
                          <SkeletonOverview />
                        )}
                      </StyledOverviewLayout>
                    )}
                    {currentTabIndex === 1 && (
                      <StyledActivityLayout id='activity'>
                        {deal && activityData && (
                          <Activity deal={deal} activityData={activityData} />
                        )}
                      </StyledActivityLayout>
                    )}
                    {currentTabIndex === 2 && deal && (
                      <div id='contacts'>
                        <Contacts
                          deal={deal}
                          hubspotIntegration={hubspotIntegration}
                        />
                      </div>
                    )}
                  </StyledTabsContent>
                </StyledTabsPane>
                <StyledPropertiesPane>
                  {deal ? (
                    <DealProperties deal={deal} />
                  ) : (
                    <SkeletonProperties />
                  )}
                </StyledPropertiesPane>
              </StyledPanes>
            )}
          </GatedContentWrapper>
        </>
      </Layout.Wrapper>
    </>
  );
}
