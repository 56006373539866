import * as Layout from '~/components/Layout';
import {
  Breadcrumb,
  BreadcrumbLink,
  Button,
  ButtonRow,
  Divider,
  EmptyState,
  Icon16,
  Icon20,
  Input,
  Menu,
  MenuButton,
  PageHeader,
  theme
} from '@grain/grain-ui/v4';
import {
  COLLECTIONS_DEFAULT_TITLE,
  useConfirm,
  useRouteModal,
  useShowToast
} from '@grain/grain-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { useFiltersManager } from '~/modules/filtersV2/useFiltersManager';
import { FilterBar } from '~/modules/filtersV2/FilterBar/FilterBar';
import {
  AdHocRecordingFragment,
  useViewAdHocQuery
} from '~/modules/contentFilter/contentfilter.generated';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MeetingListItem } from '../Meetings/components/MeetingListItem';
import { useAdHocSubscription } from '~/modules/filters/adhoc';
import {
  useCollectionQuery,
  useCollectionUpdateMutation
} from '~/modules/collections/collections.generated';
import copy from 'clipboard-copy';
import { SHARE_COLLECTION_ID } from '@grain/components/modals/constants';
import ShareCollectionPermissions from '~/modals/SharePermissions/ShareCollection';
import {
  useCollectionRemoveRecordings,
  useDeleteCollection
} from '~/modules/collections/hooks';
import { EmptyCollectionState } from './components/EmptyCollectionState';
import {
  MeetingActionBar,
  useActionBar
} from '~/modules/meetings/MeetingActionBar';
import pluralize from 'pluralize';
import { usePlaylistsSubscription } from '../Playlists/hooks/usePlaylistsSubscription';
import { LabelWithValue } from '~/modules/filtersV2/types';
import { isDesktopApp } from '@grain/desktop-lib';

const StyledInput = styled(Input)`
  border-color: transparent;
  min-width: 175px;
  display: flex;
  ${theme.tokens.typography.b2[500]}

  & svg {
    height: 20px;
    width: 20px;
    color: ${theme.tokens.color.iconPrimary};
  }
  & input {
    flex-grow: 1;
  }
`;

const CollectionPage = () => {
  const { toggleCollapsed } = Layout.useSidebar();
  const { isOpen: shareCollectionIsOpen, open: openCollectionShare } =
    useRouteModal(SHARE_COLLECTION_ID);
  const titleInputRef = useRef<HTMLInputElement | null>(null);
  const { id: collectionId } = useParams();
  const showToast = useShowToast();
  const showConfirm = useConfirm();
  const [title, setTitle] = useState('');
  const deleteCollection = useDeleteCollection();
  const [mutateCollection] = useCollectionUpdateMutation();
  const handleRemoveRecordings = useCollectionRemoveRecordings();
  const { data: collectionData } = useCollectionQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      collectionId: collectionId!
    },
    skip: Boolean(!collectionId),
    onCompleted: data => {
      if (data.collection.title !== COLLECTIONS_DEFAULT_TITLE) {
        setTitle(data.collection.title);
      }

      if (
        data.collection.title === '' ||
        data.collection.title === COLLECTIONS_DEFAULT_TITLE
      ) {
        titleInputRef.current?.focus();
      }
    }
  });
  const collection = collectionData?.collection;

  const { filters, resetFilters, viewAdHocFilterString, activeFilters } =
    useFiltersManager({
      filterEntityType: 'recordings',
      availableFilters: [
        'title',
        'date',
        'owners',
        'tags',
        'smart_tags',
        'persons',
        'groups',
        'collections'
      ],
      initialValues: {
        collections: { value: collectionId || '', label: '' }
      },
      hiddenFilters: ['collections']
    });

  usePlaylistsSubscription({
    filter: JSON.stringify({
      types: ['collections'],
      filters: []
    })
  });

  const { data, loading, fetchMore } = useViewAdHocQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      includeCoaching: true,
      filter: viewAdHocFilterString
    }
  });

  useAdHocSubscription(viewAdHocFilterString);

  const cursor = data?.viewAdHoc?.cursor;

  const recordings = useMemo(() => {
    return (data?.viewAdHoc?.list || []).filter(
      (item): item is AdHocRecordingFragment => item.__typename === 'Recording'
    );
  }, [data]);

  const {
    selectedMeetingIds,
    resetSelected,
    toggleSelectedMeetingId,
    showActionBar,
    canDelete
  } = useActionBar(recordings);

  const hasFilters = Object.keys(activeFilters).length >= 1;
  const showEmptyState = !loading && !hasFilters && recordings.length === 0;
  const showNoResults = !loading && hasFilters && recordings.length === 0;

  const fetchMoreWithCursor = useCallback(() => {
    fetchMore({
      variables: {
        cursor
      }
    });
  }, [fetchMore, cursor]);

  const setOwnerFilter = (owner: LabelWithValue) => {
    filters.owner.setValue(owner);
  };

  return (
    <>
      <Layout.Wrapper withSidebar>
        <PageHeader
          isDesktopApp={isDesktopApp}
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbLink icon={Icon20.Playlists} to='/app/playlists'>
                Playlists
              </BreadcrumbLink>
              <StyledInput
                placeholder={COLLECTIONS_DEFAULT_TITLE}
                ref={titleInputRef}
                value={title}
                css={css`
                  width: ${(title || COLLECTIONS_DEFAULT_TITLE).length}ch;
                `}
                onChange={e => setTitle(e.target.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.currentTarget.blur();
                  }
                }}
                onBlur={() => {
                  if (collection?.title !== title && title) {
                    mutateCollection({
                      variables: { title, collectionId: collectionId! }
                    });
                    showToast({
                      type: 'success',
                      content: 'Playlist name saved.'
                    });
                  }
                }}
              />
            </Breadcrumb>
          }
          rightSection={
            <ButtonRow>
              <Button
                variant='ghost'
                icon={Icon16.Link}
                onClick={() => {
                  copy(collection?.collectionUrl || '');
                  showToast({
                    type: 'success',
                    content: 'Playlist link copied.'
                  });
                }}
              />
              <Button
                variant='ghost'
                icon={Icon16.Share}
                data-track='Button Clicked'
                data-track-args={JSON.stringify({
                  button_name: 'collection_page_share_collection',
                  category: 'collections',
                  button_text: 'Share'
                })}
                onClick={openCollectionShare}
              />

              <Menu
                content={
                  <>
                    <MenuButton
                      textLabelProps={{ startIcon: Icon20.Delete }}
                      label='Delete Playlist'
                      onClick={() =>
                        deleteCollection(collection, '/app/playlists')
                      }
                    />
                  </>
                }
              >
                <Button variant='ghost' icon={Icon16.Overflow} />
              </Menu>
            </ButtonRow>
          }
          onMenuClick={toggleCollapsed}
        />

        <Divider />
        <FilterBar filters={filters} resetAllFilters={resetFilters} />
        <Divider />
        <div
          id='infinite-scrollable-div'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <InfiniteScroll
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
            dataLength={recordings.length}
            hasMore={Boolean(data && cursor)}
            next={fetchMoreWithCursor}
            loader={<MeetingListItem.Skeleton />}
            scrollableTarget='infinite-scrollable-div'
          >
            {loading && recordings.length === 0 && (
              <>
                <MeetingListItem.Skeleton />
                <Divider />
                <MeetingListItem.Skeleton />
                <Divider />
                <MeetingListItem.Skeleton />
              </>
            )}
            {showNoResults && (
              <EmptyState.NoResults
                title='No content matches your filters'
                onClearFilters={resetFilters}
              />
            )}
            {showEmptyState && <EmptyCollectionState />}
            {recordings.map((recording, index) => (
              <Fragment key={recording.id}>
                {index > 0 && <Divider />}
                <MeetingListItem
                  breadcrumbs={[
                    { title: 'Playlists', path: '/app/playlists' },
                    {
                      title: collection?.title || '',
                      path: collection?.collectionPath || ''
                    }
                  ]}
                  recording={recording}
                  selected={selectedMeetingIds.includes(recording.id)}
                  toggleSelected={toggleSelectedMeetingId}
                  overflowOptions={[
                    'add_to_playlist',
                    'copy',
                    'delete',
                    'download',
                    'remove_from_playlist'
                  ]}
                  forceShowCheckbox={showActionBar}
                  onClickShare={() => {}}
                  setOwnerFilter={setOwnerFilter}
                />
              </Fragment>
            ))}
          </InfiniteScroll>
        </div>

        {showActionBar && (
          <MeetingActionBar
            onDelete={() => {
              showConfirm({
                width: 400,
                confirmContent: 'Delete',
                description:
                  'This will remove the selected meetings from the playlist.',
                title: `Remove ${selectedMeetingIds.length} ${pluralize(
                  'meetings',
                  selectedMeetingIds.length
                )}?`,
                onConfirm: () => {
                  handleRemoveRecordings(collectionId, selectedMeetingIds);
                  resetSelected();
                }
              });
            }}
            reset={resetSelected}
            selectedMeetingIds={selectedMeetingIds}
            canDelete={canDelete}
          />
        )}

        {collection && shareCollectionIsOpen && (
          <ShareCollectionPermissions collection={collection} />
        )}
      </Layout.Wrapper>
    </>
  );
};

export default CollectionPage;
