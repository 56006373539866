import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Switch, SwitchProps } from '@grain/grain-ui';
import { Button, Tooltip } from '@grain/grain-ui/v4';
import { useFeature, type GatedFeatureName } from '@grain/api/auth';

type GatedMenuItemProps = SwitchProps & {
  gatedTooltipText?: string;
  gate: GatedFeatureName;
};

const GatedSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const GatedSwitch = ({
  gate,
  gatedTooltipText = 'Upgrade your plan to enable this',
  ...switchProps
}: GatedMenuItemProps) => {
  const { enabled } = useFeature(gate);

  return (
    <GatedSwitchWrapper>
      {!enabled && (
        <Button
          variant='plan'
          size='sm'
          as={Link}
          to='/app/settings/workspace?tab=plans'
        >
          Upgrade
        </Button>
      )}
      <Tooltip content={gatedTooltipText} disabled={enabled}>
        <Switch {...switchProps} disabled={switchProps.disabled || !enabled} />
      </Tooltip>
    </GatedSwitchWrapper>
  );
};
