import { useEffect, useRef } from 'react';

import { RecordingMedia } from '~/components/RecordingMedia';

import { useRecordingPage } from './context';
import { useTranscriptState } from './transcriptContext';
import { useTimeStamp } from '~/modules/recording/hooks';
import { useHotspots } from '~/components/Hotspot';

type RecordingMediaPaneProps = {
  forceAudioPlayer?: boolean;
};

export function RecordingMediaPane({
  forceAudioPlayer
}: RecordingMediaPaneProps) {
  const { recordingRes, videoContextRef, filteredSpeaker } = useRecordingPage();
  const { data, loading, refetch } = recordingRes;
  const recording = data?.recording;
  const wrapperRef = useRef(null);

  const { transcript, transcriptDataLoaded } = useTranscriptState();
  const { initialTime, settingInitialTime } = useTimeStamp({
    transcript,
    transcriptDataLoaded,
    recordingId: recording?.id
  });

  const [playButtonRef, { completeStep: completePlayButtonStep }] = useHotspots(
    'recording-sample',
    'play-button'
  );

  const videoApi = videoContextRef.current?.api;
  useEffect(() => {
    if (!videoApi) return;
    const handlePlay = () => completePlayButtonStep();
    videoApi.addEventListener('play', handlePlay);
    return () => videoApi.removeEventListener('play', handlePlay);
  }, [completePlayButtonStep, videoApi]);

  return (
    <RecordingMedia
      initialTime={initialTime}
      settingInitialTime={settingInitialTime}
      videoContextRef={videoContextRef}
      playButtonRef={playButtonRef}
      filteredSpeaker={filteredSpeaker}
      wrapperRef={wrapperRef}
      recording={recording}
      refetchRecording={refetch}
      isLoading={loading}
      forceAudioPlayer={forceAudioPlayer}
    />
  );
}
