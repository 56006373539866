import {
  SmartTopicMatchScope,
  SmartTopicSpeakerMatchScope
} from '@grain/api/schema.generated';
import {
  Button,
  Modal,
  flex,
  font,
  spacing,
  useShowToast,
  color
} from '@grain/grain-ui';
import { useCallback, useState } from 'react';
import { Step2 } from './Step2';
import { Steps, Topic } from './types';
import { Step1 } from './Step1';
import { Step3 } from './Step3';
import { useSmartTopicConfigureV2Mutation } from '@grain/api/graphql/queries/smartTopic.generated';
import { useHotspotByKey } from '~/components/Hotspot/context';
import { validateTopicScope } from '../utils';

export const MAX_KEYWORDS_AND_PHRASES = 20;

const schemaValidate = (step: Steps, topic: Topic) => {
  if (step === Steps.ONE) {
    return [topic.name.length > 0];
  }
  if (step === Steps.TWO) {
    return [
      (topic.phrases.length > 0 || topic.keywords.length > 0) &&
        topic.keywords.length + topic.phrases.length <= MAX_KEYWORDS_AND_PHRASES
    ];
  }
  if (step === Steps.THREE) {
    const error = validateTopicScope(topic);
    return [!error, error];
  }
  return [true];
};

type TopicWizardProps = {
  onClose: () => void;
};
export const TopicWizard = ({ onClose }: TopicWizardProps) => {
  const { hasSeenHotspot, setStepViewed } = useHotspotByKey('smart-tags-ftux');
  const showToast = useShowToast();
  const [topic, setTopic] = useState<Topic>({
    name: '',
    phrases: [],
    keywords: [],
    matchScope: SmartTopicMatchScope.All,
    speakerScope: SmartTopicSpeakerMatchScope.All
  });
  const setTopicValue = useCallback(
    <K extends keyof Topic>(key: K, value: Topic[K]) => {
      const newValue =
        key === 'name' ? (value as string).replace(/\s/g, '-') : value;

      setTopic(prev => ({ ...prev, [key]: newValue }));
    },
    [setTopic]
  );
  const [smartTopicConfigureMutationV2] = useSmartTopicConfigureV2Mutation({
    refetchQueries: ['workspaceSmartTopics', 'workspaceTags']
  });

  // Change back to one before merge
  const [step, setStep] = useState(Steps.ONE);
  const [isValidSchema, error] = schemaValidate(step, topic);

  const prevStepClick = async () => {
    if (step === Steps.THREE) setStep(Steps.TWO);
    if (step === Steps.TWO) setStep(Steps.ONE);
    if (step === Steps.ONE) onClose();
  };

  const nextStepClick = async () => {
    if (step === Steps.ONE) setStep(Steps.TWO);
    if (step === Steps.TWO) setStep(Steps.THREE);
    if (step === Steps.THREE) {
      smartTopicConfigureMutationV2({
        variables: {
          text: topic.name,
          description: '',
          keywords: Array.from(new Set(topic.keywords)), // Purge duplicates
          matchScope: topic.matchScope,
          phrases: Array.from(new Set(topic.phrases)), // Purge duplicates
          speakerScope: topic.speakerScope
        },
        onCompleted: ({ smartTopicConfigureV2: data }) => {
          showToast({
            content: `${data.tag.text} Tracker created`,
            type: 'success',
            uniqueId: 'smart_topic_created_updated'
          });
          if (!hasSeenHotspot('create-or-edit')) {
            setStepViewed('create-or-edit');
          }
          onClose();
        }
      });
    }
  };

  return (
    <Modal closable onCancel={onClose} css={['overflow: unset;']}>
      <div css={[spacing.my4, font.v4.h6, 'text-align: center;']}>
        Create Tracker
      </div>
      {step === Steps.ONE && (
        <Step1 topic={topic} setTopicValue={setTopicValue} />
      )}
      {step === Steps.TWO && (
        <Step2 topic={topic} setTopicValue={setTopicValue} />
      )}
      {step === Steps.THREE && (
        <Step3 topic={topic} setTopicValue={setTopicValue} />
      )}
      {error && (
        <div css={[spacing.px7, font.v4.b2[400], color.fg.errorbird]}>
          {error}
        </div>
      )}
      <section css={[flex.justifyContent.spaceBetween, spacing.p6]}>
        <Button type='button' onClick={prevStepClick} variant='secondary'>
          {step === Steps.ONE ? 'Cancel' : 'Previous'}
        </Button>

        <Button
          type='button'
          onClick={nextStepClick}
          variant={isValidSchema ? 'primary' : 'secondary'}
          disabled={!isValidSchema}
        >
          {step === Steps.THREE ? 'Done' : 'Continue'}
        </Button>
      </section>
    </Modal>
  );
};
