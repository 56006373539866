import { Icon16, TextLabel, theme, Button } from '@grain/grain-ui/v4';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useExperiment } from '@grain/api/auth';
import { useWorkspacePlan } from '@grain/components/Subscriptions/hooks';
import { useRouteModal } from 'lib/modals';
import { UPGRADE_PLAN_ROUTE_ID } from '@grain/components/modals/constants';

type FreemiumCutoffBannerProps = {
  cutoffApplied: number | null;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${theme.utils.gap('sm')}
  border-radius: ${theme.tokens.radii.lg};
  border: 1px solid ${theme.tokens.color.borderSecondary};
  background: ${theme.tokens.color.surfacePrimary};

  /* Diffused Shadow */
  height: 48px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
  ${theme.utils.margin('2xl', '2xl', 'none', '2xl')}
  ${theme.utils.padding('10px', 'lg')}
`;

export const FreemiumCutoffBanner = ({
  cutoffApplied
}: FreemiumCutoffBannerProps) => {
  const { open: openUpgradePlanModal } = useRouteModal(UPGRADE_PLAN_ROUTE_ID);
  const workspacePlan = useWorkspacePlan();
  const { enabled } = useExperiment('freemium');
  const isFreePlan = workspacePlan?.subscriptionPlan?.isFree;

  if (cutoffApplied === null || !enabled) {
    return null;
  }

  return (
    <Container>
      <TextLabel
        startIcon={
          <Icon16.AlertCircle
            css={css`
              color: ${theme.tokens.color.iconTertiary};
            `}
          />
        }
      >
        {isFreePlan
          ? `Recording stopped at the ${cutoffApplied}-minute limit`
          : `Recording and notes stop at ${cutoffApplied}-minutes, due to being on the Free plan previously`}
      </TextLabel>
      {isFreePlan && (
        <Button onClick={openUpgradePlanModal} variant='plan'>
          Upgrade
        </Button>
      )}
    </Container>
  );
};
