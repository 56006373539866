import {
  Input,
  Select,
  type SelectProps,
  Option,
  theme
} from '@grain/grain-ui/v4';
import { Option as OptionType } from '@grain/grain-ui';
import { useMemo, useState } from 'react';
import { css } from '@emotion/react';

type DealStageCellProps<T> = Omit<SelectProps<T>, 'children'> & {
  stageOptions: OptionType[];
};

export const DealStageCell = <T,>({
  stageOptions,
  ...rest
}: DealStageCellProps<T>) => {
  const [dealFilter, setDealFilter] = useState('');

  const filteredStageOptions = useMemo(() => {
    return stageOptions.filter(stage =>
      stage.title.toLowerCase().includes(dealFilter.toLowerCase())
    );
  }, [dealFilter, stageOptions]);

  return (
    <Select
      fullWidth
      {...rest}
      tippyProps={{
        onHide: () => setDealFilter('')
      }}
    >
      <Input
        placeholder='Filter...'
        onClick={e => e.stopPropagation()}
        value={dealFilter}
        onChange={e => setDealFilter(e.target.value)}
        css={css`
          flex-shrink: 0;
          margin-bottom: ${theme.tokens.spacing.sm};
        `}
      />
      {filteredStageOptions.map(stage => (
        <Option key={stage.value} value={stage.value}>
          {stage.titleNode || stage.title}
        </Option>
      ))}
      {filteredStageOptions.length === 0 && (
        <Option value={''}>No results found</Option>
      )}
    </Select>
  );
};
