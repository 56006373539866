import { spacing, pxToRem, color } from 'base/css';
import styled from '@emotion/styled';
import { Icon } from '../Icon/Icon';
import { ElementType } from 'react';

export const StyledDownloadButton = styled.button<{ inactive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: ${props => (props.inactive ? 'default' : 'pointer')};
  background-color: ${props =>
    props.inactive ? color.gull : color.highlightGreenDark};
  color: ${props => (props.inactive ? color.pigeon : color.swan)};
  width: 278px;
  height: 60px;
  border-radius: 12px;

  font-size: ${pxToRem(16)};
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  &:hover {
    background-color: ${props => (props.inactive ? color.gull : '#33c789')};
    text-decoration: none;
  }
`;

type DownloadButtonProps = {
  downloadType?: 'Mac' | 'Windows';
  children?: React.ReactNode;
  href: string;
  as?: ElementType;
  onClick?: () => void;
  inactive?: boolean;
};

export function DownloadButton({
  children = null,
  downloadType = 'Mac',
  inactive = false,
  as = 'a',
  ...rest
}: DownloadButtonProps) {
  const downloadIconMap = {
    Mac: <Icon.Mac />,
    Windows: <Icon.Windows />
  };

  return (
    <StyledDownloadButton inactive={inactive} as={as} {...rest}>
      <div className='icon' css={[spacing.mr3]}>
        {downloadIconMap[downloadType]}
      </div>
      {children}
    </StyledDownloadButton>
  );
}
