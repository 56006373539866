import * as Layout from '~/components/Layout';
import { Helmet } from 'react-helmet';
import {
  Breadcrumb,
  BreadcrumbLink,
  Button,
  Divider,
  EmptyState,
  Icon20,
  PageHeader
} from '@grain/grain-ui/v4';
import { COLLECTIONS_DEFAULT_TITLE, Icon, useAnalytics } from '@grain/grain-ui';
import { FilterBar } from '~/modules/filtersV2/FilterBar/FilterBar';
import { useFiltersManager } from '~/modules/filtersV2/useFiltersManager';
import { CollectionFragmentFragment as PlaylistFragment } from '~/modules/contentFilter/contentfilter.generated';
import { Fragment, useCallback, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PlaylistListItem } from './components/PlaylistListItem';
import { useCreateCollection } from '~/modules/collections/hooks';
import ShareCollectionPermissions from '~/modals/SharePermissions/ShareCollection';
import { PlaylistEmptyState } from './components/PlaylistEmptyState';
import { useFetchPlaylists } from './hooks/useFetchPlaylists';
import { isDesktopApp } from '@grain/desktop-lib';

const PlaylistsPage = () => {
  const { toggleCollapsed } = Layout.useSidebar();
  const [currentPlaylist, setCurrentPlaylist] =
    useState<null | PlaylistFragment>(null);
  const { trackEvent } = useAnalytics();
  const createCollection = useCreateCollection();
  const { filters, resetFilters, viewAdHocFilterString, activeFilters } =
    useFiltersManager({
      filterEntityType: 'collections',
      availableFilters: ['title', 'date']
    });

  const { data, playlists, fetchMore, error, loading, cursor } =
    useFetchPlaylists({
      filter: viewAdHocFilterString
    });

  const createNewPlaylist = useCallback(() => {
    trackEvent(
      'New Collection Clicked',
      {
        button_name: 'new_collection',
        trigger: 'new_collection_clicked'
      },
      ['user', 'workspace']
    );
    createCollection(COLLECTIONS_DEFAULT_TITLE);
  }, [trackEvent, createCollection]);

  const [isResettingTitleFilter, setIsResettingTitleFilter] = useState(false);

  const resetFiltersWithLoading = () => {
    setIsResettingTitleFilter(true);
    resetFilters();
    filters.title.setValue('');
    setTimeout(() => setIsResettingTitleFilter(false), 100);
  };

  const hasTitleFilter = filters.title.value !== '';
  const hasFilters = Object.keys(activeFilters).length >= 1 || hasTitleFilter;
  const isLoading = loading || isResettingTitleFilter;
  const showEmptyState =
    !error && playlists.length === 0 && !isLoading && !hasFilters;
  const showNoResults =
    !error && playlists.length === 0 && !isLoading && hasFilters;
  const showFullPageError = error && !isLoading && playlists.length === 0;

  return (
    <>
      <Helmet title='Playlists' />
      <Layout.Wrapper withSidebar>
        <PageHeader
          isDesktopApp={isDesktopApp}
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbLink
                textLabelProps={{ startIcon: Icon20.Playlists }}
                to='/app/playlists'
              >
                Playlists
              </BreadcrumbLink>
            </Breadcrumb>
          }
          onMenuClick={toggleCollapsed}
          rightSection={
            <Button
              variant='neutral'
              icon={Icon.Plus}
              onClick={createNewPlaylist}
            >
              New playlist
            </Button>
          }
        />

        <Divider />
        <FilterBar filters={filters} resetAllFilters={resetFilters} />
        <Divider />
        <div
          id='infinite-scrollable-div'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <InfiniteScroll
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
            dataLength={playlists.length}
            hasMore={Boolean(data && cursor)}
            next={fetchMore}
            loader={<PlaylistListItem.Skeleton />}
            scrollableTarget='infinite-scrollable-div'
          >
            {showFullPageError && (
              <EmptyState.Error
                title='Error loading Playlists'
                onReload={fetchMore}
              />
            )}
            {!data &&
              loading &&
              Array.from({ length: 20 }, (_, i) => (
                <Fragment key={i}>
                  {i > 0 && <Divider />}
                  <PlaylistListItem.Skeleton />
                </Fragment>
              ))}
            {showNoResults && (
              <EmptyState.NoResults
                title='No content matches your filters'
                onClearFilters={resetFiltersWithLoading}
              />
            )}
            {showEmptyState && (
              <PlaylistEmptyState onNewPlaylist={createNewPlaylist} />
            )}
            {playlists.map((playlist, index) => (
              <Fragment key={playlist.id}>
                {index > 0 && <Divider />}
                <PlaylistListItem
                  playlist={playlist}
                  onClickShare={() => setCurrentPlaylist(playlist)}
                />
              </Fragment>
            ))}
          </InfiniteScroll>
        </div>
        {currentPlaylist && (
          <ShareCollectionPermissions
            collection={currentPlaylist}
            onCancel={() => setCurrentPlaylist(null)}
          />
        )}
      </Layout.Wrapper>
    </>
  );
};

export default PlaylistsPage;
