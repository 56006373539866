import React from 'react';

import { useMutation } from '@grain/api/graphql';
import { userTermsOfServicePrivacyPolicyAcceptMutation } from '@grain/api/graphql/mutations';
import Button from '@grain/components/Button';

import { logout } from '~/support/auth';
import { Modal, ModalTitle, useShowToast } from '@grain/grain-ui';
import { StyledButtons, StyledContent, buttonStyles } from './styles';

export default function AcceptTerms() {
  const [userTermsOfServicePrivacyPolicyAccept] = useMutation(
    userTermsOfServicePrivacyPolicyAcceptMutation
  );
  const doAcceptTerms = () => {
    userTermsOfServicePrivacyPolicyAccept();
  };
  const showToast = useShowToast();
  const signOut = React.useCallback(() => {
    logout().catch(() => {
      showToast({
        content: 'Failed to logout. Please try again.',
        type: 'failure'
      });
    });
  }, [showToast]);
  return (
    <Modal width={440}>
      <ModalTitle
        title='We are updating our Terms of Service and Privacy Policy'
        centered={false}
      />
      <StyledContent>
        Our{' '}
        <a
          href='https://grain.com/terms'
          target='_blank'
          rel='noopener noreferrer'
        >
          Terms of Service
        </a>{' '}
        and{' '}
        <a
          href='https://grain.com/privacy-policy'
          target='_blank'
          rel='noopener noreferrer'
        >
          Privacy Policy
        </a>{' '}
        have changed. By clicking accept, you agree that you have read and agree
        to be bound by these new terms.
      </StyledContent>

      <StyledButtons>
        <Button css={buttonStyles} onClick={signOut}>
          Reject
        </Button>
        <Button type='primary' css={buttonStyles} onClick={doAcceptTerms}>
          Accept
        </Button>
      </StyledButtons>
    </Modal>
  );
}
