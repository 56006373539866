import styled from '@emotion/styled';
import { Skeleton } from '@grain/grain-ui';
import { Avatar, theme } from '@grain/grain-ui/v4';

const StyledDealOwner = styled.div`
  padding: 14px ${theme.tokens.spacing['2xl']};
  border-bottom: 1px solid ${theme.tokens.color.borderTertiary};
`;

const StyledDealOwnerColumns = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.tokens.spacing.md};
`;

const StyledDealOwnerDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing['2xs']};
`;

const StyledDealOwnerName = styled.div`
  ${theme.tokens.typography.b3[500]};
`;

const StyledDealOwnerTitle = styled.div`
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textTertiary};
`;

type DealOwnerProps = {
  owner: {
    avatarUrl?: string;
    name: string;
  };
};

export function DealOwner({ owner }: DealOwnerProps) {
  const { avatarUrl, name } = owner;
  return (
    <StyledDealOwner>
      <StyledDealOwnerColumns>
        <Avatar size='xl' name={name} url={avatarUrl} />
        <StyledDealOwnerDescription>
          <StyledDealOwnerName>{name}</StyledDealOwnerName>
          <StyledDealOwnerTitle>Deal Owner</StyledDealOwnerTitle>
        </StyledDealOwnerDescription>
      </StyledDealOwnerColumns>
    </StyledDealOwner>
  );
}

export function SkeletonDealOwner() {
  return (
    <StyledDealOwner>
      <StyledDealOwnerColumns>
        <Skeleton
          width={64}
          height={36}
          css={[`border-radius: ${theme.tokens.radii.md};`]}
        />
        <Skeleton
          width={200}
          height={16}
          css={[`border-radius: ${theme.tokens.radii.md};`]}
        />
      </StyledDealOwnerColumns>
    </StyledDealOwner>
  );
}
