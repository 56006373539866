import { StyledBullet, StyledPlanItemContainer } from './styles';
import { Icon, color } from '@grain/grain-ui';
import { Tooltip } from '@grain/grain-ui/v4';
import { PlanFeature } from './types';

type FeatureListProps = {
  features: PlanFeature[];
  previousPlanName?: string;
};

export const FeatureList = ({
  features,
  previousPlanName
}: FeatureListProps) => {
  return (
    <div>
      <div className='bullet-header'>
        {previousPlanName && <span>Everything in {previousPlanName} +</span>}
      </div>
      <div>
        {features?.map(({ name, description }, idx) => (
          <StyledBullet key={idx}>
            <Icon.CheckBullet
              css={['min-height: 16px; min-width: 16px;']}
              fill={color.goose}
            />
            <StyledPlanItemContainer>
              {name}{' '}
              {description && (
                <span>
                  {' '}
                  <Tooltip content={description} maxWidth={350}>
                    <Icon.Help />
                  </Tooltip>
                </span>
              )}
            </StyledPlanItemContainer>
          </StyledBullet>
        ))}
      </div>
    </div>
  );
};
