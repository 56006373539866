import styled from '@emotion/styled';
import { theme } from '@grain/grain-ui/v4';
import Markdown, { Options as MarkdownProps, ExtraProps } from 'react-markdown';
import { ComponentProps, memo } from 'react';
import { withCitations } from './utils';
import { AugmentedCitation } from '../types';

const EMPTY_ARRAY: never[] = [];

/**
 * Preprocesses markdown text to convert single line breaks to hard line breaks
 * by adding two spaces before each newline character
 */
const processLineBreaks = (markdownText: string): string => {
  try {
    // This regex matches single newlines that aren't already preceded by two spaces
    // (?<! ) is a negative lookbehind that ensures the newline isn't already preceded by two spaces
    return markdownText.replace(/(?<! {2})\n/g, '  \n');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error processing line breaks:', error);
    return markdownText;
  }
};

const StyledMarkdown = styled(Markdown)`
  padding-top: 6px;
  margin-bottom: ${theme.tokens.spacing.sm};

  & > *:first-child {
    margin-top: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    padding: 0;
    margin: 0;
  }

  h1 {
    ${theme.tokens.typography.h4}
    margin-bottom: ${theme.tokens.spacing.lg};
  }

  h2 {
    ${theme.tokens.typography.b1[600]}
    margin-bottom: ${theme.tokens.spacing.md};
  }

  h3 {
    ${theme.tokens.typography.b2[600]}
    margin-bottom: ${theme.tokens.spacing.sm};
  }

  h4,
  h5,
  h6 {
    ${theme.tokens.typography.b3[600]}
    margin-bottom: ${theme.tokens.spacing.sm};
  }

  p,
  li {
    ${theme.tokens.typography.b2['400p']}
    margin-bottom: ${theme.tokens.spacing.sm};
  }

  ul,
  ol {
    padding-left: ${theme.tokens.spacing.xl};
    margin-bottom: ${theme.tokens.spacing.md};
  }
`;

type LimitedMarkdownProps = Omit<
  MarkdownProps,
  'allowedElements' | 'unwrapDisallowed'
> & {
  citations: AugmentedCitation[];
};

const ALLOWED_HTML_ELEMENTS_FOR_MARKDOWN = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'li',
  'ol',
  'ul',
  'blockquote',
  'code',
  'br',
  'hr',
  'strong',
  'em',
  'code'
];

// Elements that need citation processing
const CITATION_ELEMENTS = [
  'li',
  'p',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'blockquote'
] as const;

type CitationElement = (typeof CITATION_ELEMENTS)[number];

// Wrapped in a memo to prevent unnecessary re-renders (markdown parsing is expensive)
export const AssistantChatMessageMarkdown = memo(
  function AssistantChatMessageMarkdown({
    citations = EMPTY_ARRAY,
    children,
    ...props
  }: LimitedMarkdownProps) {
    const createCitationComponent = (Component: CitationElement) => {
      return (props: ComponentProps<CitationElement> & ExtraProps) =>
        withCitations({
          Component,
          props,
          citations
        });
    };

    const citationComponents = CITATION_ELEMENTS.reduce<
      Record<CitationElement, ReturnType<typeof createCitationComponent>>
    >(
      (acc, Component) => {
        acc[Component] = createCitationComponent(Component);
        return acc;
      },
      {} as Record<CitationElement, ReturnType<typeof createCitationComponent>>
    );

    const processedChildren =
      typeof children === 'string' ? processLineBreaks(children) : children;

    return (
      <StyledMarkdown
        allowedElements={ALLOWED_HTML_ELEMENTS_FOR_MARKDOWN}
        unwrapDisallowed
        components={{
          code: ({ node, ...props }) => {
            // Check if this is a markdown code block
            if (props.className?.includes('language-markdown')) {
              const nestedContent =
                typeof props.children === 'string'
                  ? processLineBreaks(props.children)
                  : props.children;

              return (
                <AssistantChatMessageMarkdown citations={citations}>
                  {nestedContent as string}
                </AssistantChatMessageMarkdown>
              );
            }

            // disallow other code blocks
            return null;
          },
          ...citationComponents
        }}
        {...props}
      >
        {processedChildren}
      </StyledMarkdown>
    );
  }
);
