import React from 'react';
import { Container, Title, Actions, WindowsControls } from './styles';
import { IS_WINDOWS } from 'desktop/lib/constants';
import NotepadWindow from 'desktop/lib/window/Notepad';
import { useDesktop } from 'providers/DesktopProvider';
import { css } from '@emotion/react';
import { Tooltip } from '@grain/grain-ui';

interface MenuBarProps {
  compact?: boolean;
  dark?: boolean;
  title?: string;
  actions?: React.ReactNode;
  hasErrorText?: boolean;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  onClick?: () => void;
}

const MenuBar = ({
  compact = false,
  dark = false,
  title = '',
  actions = null,
  hasErrorText = false,
  leftContent = null,
  rightContent = null,
  onClick,
  ...rest
}: MenuBarProps) => {
  const { desktopWindow, recordingContext } = useDesktop();

  const handleMinimize = () => {
    NotepadWindow.minimize(desktopWindow);
  };

  const handleHide = () => {
    NotepadWindow.hide(desktopWindow);
  };
  const activeRecording = recordingContext?.getActiveRecording({
    meetingId: recordingContext?.meetings[0]?.id
  });
  return (
    <Container
      dark={dark}
      variant={IS_WINDOWS ? 'windows' : 'mac'}
      onClick={onClick}
      {...rest}
    >
      <Actions>{actions}</Actions>
      {!!title && (
        <Title hasErrorText={hasErrorText} dark={dark}>
          {leftContent}
          <Tooltip content='Open live transcript'>
            <span
              className='undraggable-children'
              css={css`
                cursor: pointer;
                pointer-events: auto;
                -webkit-app-region: no-drag;

                &:hover {
                  text-decoration: underline;
                }
              `}
              onClick={() => {
                if (!activeRecording?.recording_path) return;
                desktopWindow.sendEvent({
                  type: 'open-url',
                  window: 'main',
                  url: activeRecording.recording_path
                });
              }}
            >
              {title}
            </span>
          </Tooltip>
          {rightContent}
        </Title>
      )}
      {!IS_WINDOWS ? (
        <span />
      ) : (
        <WindowsControls dark={dark}>
          <div className='button minimize' onClick={handleMinimize}>
            &#xE921;
          </div>
          {!compact && <div className='button maximize'>&#xE922;</div>}
          <div className='button close' onClick={handleHide}>
            &#xE8BB;
          </div>
        </WindowsControls>
      )}
    </Container>
  );
};

export default MenuBar;
