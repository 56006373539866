import Markdown from 'react-markdown';

import {
  Button,
  Icon12,
  Icon16,
  Menu,
  MenuButton,
  TextLabel,
  theme,
  Tooltip
} from '@grain/grain-ui/v4';
import { DealDiagnosisCompletenessIcon } from './DealDiagnosisCompletenessIcon';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { copyElementAsHtml, useAnalytics, useShowToast } from '@grain/grain-ui';
import {
  CompletenessStatus,
  UserFeedback,
  UserFeedbackType
} from '@grain/api/schema.generated';
import { useCallback, useMemo, useState } from 'react';
import { formatDuration } from '~/pages/Deals/utils';
import { SourceFragment } from '~/pages/Deals/deal.generated';
import { DealDiagnosisSourceMenuContent } from './DealDiagnosisSourceMenuContent';
import pluralize from 'pluralize';
import { Feedback, FeedbackData, FeedbackType } from '~/components/Feedback';

type DealDiagnosisSubDimensionProps = {
  status: CompletenessStatus;
  label: string;
  content: string;
  userFeedback: Pick<UserFeedback, 'type'> | null;
  lastSync: string;
  sources: SourceFragment[];
  disabled: boolean;
  onFeedbackSubmit?: (data: FeedbackData) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing.sm};
  padding: ${theme.tokens.spacing.lg};
  border: 1px solid ${theme.tokens.color.borderTertiary};
  border-radius: ${theme.tokens.radii.xl};
  background-color: ${theme.tokens.color.surfacePrimary};
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.tokens.spacing.xs};
`;

const StyledMarkdown = styled(Markdown)`
  ${theme.tokens.typography.b3[400]}
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const actionButtonStyle = css`
  color: ${theme.tokens.color.iconDisabled};
  &:hover {
    color: ${theme.tokens.color.iconPrimary};
  }
`;

const ActionGroup = styled.div<{ show: boolean }>`
  display: flex;
  align-items: center;
  gap: ${theme.tokens.spacing.sm};
  color: ${theme.tokens.color.textTertiary};
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out;
`;

const SourcesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.tokens.spacing.sm};
`;

export const DealDiagnosisSubDimension = ({
  status,
  label,
  content,
  userFeedback,
  lastSync,
  sources,
  disabled,
  onFeedbackSubmit
}: DealDiagnosisSubDimensionProps) => {
  const { trackEvent } = useAnalytics();
  const showToast = useShowToast();
  const [showActions, setShowActions] = useState(false);

  const sourcesGroupedByContainingObject = useMemo(() => {
    return sources.reduce(
      (acc, source) => {
        acc[source.containingObject?.id ?? ''] = [
          ...(acc[source.containingObject?.id ?? ''] || []),
          source
        ];
        return acc;
      },
      {} as Record<string, SourceFragment[]>
    );
  }, [sources]);

  const sourcesCount = Object.keys(sourcesGroupedByContainingObject).length;

  const onCopySubdimension = useCallback(() => {
    copyElementAsHtml(
      <>
        <h3>{label}</h3>
        <p>{content}</p>
      </>
    );
    trackEvent('Deal Diagnosis Copied', {
      type: 'sub_dimension'
    });
    showToast({
      content: 'Sub dimension copied',
      type: 'success'
    });
  }, [content, label, showToast, trackEvent]);

  const getFeedbackType = (): FeedbackType => {
    return userFeedback?.type === UserFeedbackType.ThumbsUp
      ? 'positive'
      : 'negative';
  };

  const feedbackData: FeedbackData | undefined = userFeedback
    ? {
        type: getFeedbackType()
      }
    : undefined;

  const handleFeedbackSubmit = (data: FeedbackData) => {
    if (onFeedbackSubmit) {
      onFeedbackSubmit(data);
    }
  };

  const isContentAvailable =
    content?.trim() !== '' && content !== '<not available>';

  return (
    <Container
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
      onFocus={() => setShowActions(true)}
      onBlur={() => setShowActions(false)}
      tabIndex={0}
    >
      <Label>
        <DealDiagnosisCompletenessIcon status={status} disabled={disabled} />
        <TextLabel>{label}</TextLabel>
      </Label>
      {isContentAvailable ? (
        <StyledMarkdown>{content}</StyledMarkdown>
      ) : (
        <TextLabel css={{ color: theme.tokens.color.textTertiary }}>
          No {label} identified
        </TextLabel>
      )}

      <Actions>
        <SourcesContainer>
          {sourcesCount > 0 && (
            <Menu
              width='300px'
              content={
                <DealDiagnosisSourceMenuContent
                  sourcesGroupedByContainingObject={
                    sourcesGroupedByContainingObject
                  }
                />
              }
            >
              <MenuButton
                textLabelProps={{
                  endIcon: () => (
                    <div css={{ display: 'flex', alignItems: 'center' }}>
                      <Icon12.ChevronDown />
                    </div>
                  )
                }}
                label={`${sourcesCount} ${pluralize('Source', sourcesCount)}`}
              />
            </Menu>
          )}
          {lastSync && (
            <TextLabel
              size='sm'
              css={css`
                color: ${theme.tokens.color.textTertiary};
              `}
            >
              Updated{' '}
              {formatDuration(new Date(lastSync), new Date()).toLowerCase()} ago
            </TextLabel>
          )}
        </SourcesContainer>
        <ActionGroup show={showActions}>
          <Tooltip content='Copy'>
            <Button
              onlyIcon
              icon={Icon16.Copy}
              variant='ghost'
              css={actionButtonStyle}
              onClick={onCopySubdimension}
            />
          </Tooltip>
          {!disabled && (
            <Feedback
              feedbackData={feedbackData}
              onFeedbackSubmit={handleFeedbackSubmit}
              analyticsTrigger='deal_diagnosis'
              buttonStyle={actionButtonStyle}
            />
          )}
        </ActionGroup>
      </Actions>
    </Container>
  );
};
