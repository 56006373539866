import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { color, colors, spacing } from '@grain/styles/constants';
import { pxToRem } from '@grain/grain-ui';

export const StyledContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledSourceContainer = styled.div`
  ${spacing.mt6};
  align-items: center;
  background: ${colors.gull};
  border-radius: 6px;
  display: flex;
  padding: 2px;
  width: 240px;
`;

export const StyledSource = styled.div`
  ${props => props.disabled && 'pointer-events: none;'};

  ${spacing.py1};
  user-select: none;
  border-radius: 6px;
  cursor: pointer;
  flex: 1;
  font-size: ${pxToRem(12)};
  font-weight: 500;
  text-align: center;

  :not(:first-of-type) {
    ${spacing.ml1};
  }

  :hover {
    ${props => !props.active && `background: ${colors.goose};`}
  }

  // Do not rearrange
  ${props =>
    props.active && `color: ${colors.blackbird}; background: ${colors.swan};`}
  ${color.crow};
`;

export const StyledImageContainer = styled.div`
  ${spacing.mb8};
  ${spacing.mt6};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
`;

export const StyledAttribution = styled.div`
  ${spacing.mt2};
  align-items: center;
  display: flex;
  font-size: ${pxToRem(14)};
  justify-content: center;

  a {
    display: flex;
  }
`;

export const StyledImageWrapper = styled.div`
  ${spacing.pt4};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const inputStyles = css`
  height: 32px;
  width: 453px;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledThumbnail = styled.div`
  ${spacing.m1};
  background-image: url(${props => props.thumbUrl});
  background-position: center center;
  background-size: cover;
  border-radius: 4px;
  cursor: pointer;
  height: 85px;
  width: 151px;
`;
