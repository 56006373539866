import { DealDiagnosticMethodology } from '@grain/api/schema.generated';
import { useAnalytics } from '@grain/grain-ui';
import { useReducer, useCallback } from 'react';
import { useHubspotAutomationEditMutation } from '~/modules/integrations/hubspot/hubspot.generated';
import { useHubspotIntegration } from '~/modules/integrations/hubspot/useHubspotIntegration';

type DealDiagnosisState = {
  setup: boolean;
  miniSetup: boolean;
  methodologySelector: boolean;
  processing: boolean;
  ready: boolean;
};

type DealDiagnosisAction =
  | { type: 'CLOSE_SETUP' }
  | { type: 'SELECT_METHODOLOGY' }
  | { type: 'CLOSE_METHODOLOGY' }
  | { type: 'COMPLETE_SETUP' }
  | { type: 'SET_READY' };

const INITIAL_DIAGNOSIS_STATE: DealDiagnosisState = {
  setup: true,
  miniSetup: false,
  methodologySelector: false,
  processing: false,
  ready: false
};

const READY_STATE = {
  ...INITIAL_DIAGNOSIS_STATE,
  setup: false,
  ready: true
};

function dealDiagnosisReducer(
  state: DealDiagnosisState,
  action: DealDiagnosisAction
): DealDiagnosisState {
  switch (action.type) {
    case 'CLOSE_SETUP':
      return { ...state, setup: false, miniSetup: true };
    case 'SELECT_METHODOLOGY':
      return { ...state, methodologySelector: true };
    case 'CLOSE_METHODOLOGY':
      return { ...state, methodologySelector: false };
    case 'COMPLETE_SETUP':
      return {
        ...state,
        methodologySelector: false,
        processing: true,
        miniSetup: false,
        setup: false
      };
    case 'SET_READY':
      return {
        ...state,
        setup: false,
        miniSetup: false,
        processing: false,
        ready: true
      };
    default:
      return state;
  }
}

export const useDealDiagnosis = () => {
  const { trackEvent } = useAnalytics();
  const [updateAutomation] = useHubspotAutomationEditMutation();
  const hubspotIntegration = useHubspotIntegration();
  const methodology = hubspotIntegration?.dealDiagnosticMethodology ?? null;
  const hasMethodologySelected = Boolean(methodology);

  const [state, dispatch] = useReducer(
    dealDiagnosisReducer,
    hasMethodologySelected ? READY_STATE : INITIAL_DIAGNOSIS_STATE
  );

  const updateMethodologyMutation = useCallback(
    (value: DealDiagnosticMethodology, onCompleted: () => void) => {
      updateAutomation({
        variables: { settings: { dealDiagnosticMethodology: value } },
        onCompleted
      });
    },
    [updateAutomation]
  );
  const handleCloseSetup = useCallback(() => {
    trackEvent('Deal Diagnosis Setup Collapsed');
    dispatch({ type: 'CLOSE_SETUP' });
  }, [trackEvent]);

  const handleSelectMethodology = useCallback(() => {
    trackEvent('Deal Diagnosis Setup Clicked');
    dispatch({ type: 'SELECT_METHODOLOGY' });
  }, [trackEvent]);

  const handleCloseMethodology = useCallback(() => {
    trackEvent('Deal Diagnosis Setup Dismissed');
    dispatch({ type: 'CLOSE_METHODOLOGY' });
  }, [trackEvent]);

  const handleSetReady = useCallback(() => {
    dispatch({ type: 'SET_READY' });
  }, []);

  const handleCompleteSetup = useCallback(
    (methodology: DealDiagnosticMethodology) => {
      trackEvent('Deal Diagnosis Methodology Selected');
      updateMethodologyMutation(methodology, handleSetReady);
    },
    [trackEvent, updateMethodologyMutation, handleSetReady]
  );

  return {
    state,
    handleCloseSetup,
    handleSelectMethodology,
    handleCloseMethodology,
    handleSetReady,
    handleCompleteSetup,
    methodology
  };
};
