import styled from '@emotion/styled';
import { Skeleton } from 'components/atoms/Skeleton/Skeleton';

const Wrapper = styled.div`
  aspect-ratio: 16 / 9;
  border-radius: 6px;
  overflow: hidden;
  background: #000000;
`;

export function SkeletonVideo() {
  return (
    <Wrapper>
      <Skeleton
        baseColor='transparent'
        shimmerColor='#232323'
        width='100%'
        height='100%'
      />
    </Wrapper>
  );
}
