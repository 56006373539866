import React from 'react';
import { useMyself } from '@grain/api/auth';
import { getBasePublicPath } from '@grain/components/support/environment';
import { useExtra } from '@grain/components/support/extra';
import {
  StyledModalCard,
  StyledCardHeaderText,
  StyledModalCardSubHeaderText,
  StyledCardWrapper,
  StyledNextButton
} from '../styles';
import { StyledClose, StyledConnect } from './styles';
import {
  useRouteModal,
  getOauthRedirectUrl,
  Icon,
  color
} from '@grain/grain-ui';
import { ZOOM_CONNECT_ROUTE_ID } from '@grain/components/modals/constants';
import { useGetZoomIntegration } from '~/pages/Settings/Integrations/hook';

const ConnectZoom = () => {
  const { close } = useRouteModal(ZOOM_CONNECT_ROUTE_ID);
  const { extra, saveExtra } = useExtra();
  const { myself } = useMyself();
  const zoomIntegration = useGetZoomIntegration();

  const [zoomConnected, hasZoomAccount, zoomReauthRequired] =
    React.useMemo(() => {
      if (!zoomIntegration) return [];
      const connected = !!zoomIntegration && !zoomIntegration?.reauthRequired;
      return [connected, zoomIntegration, zoomIntegration?.reauthRequired];
    }, [zoomIntegration]);

  const next = window.location.pathname + window.location.search;

  return (
    <StyledCardWrapper>
      <StyledModalCard>
        <div className='left'>
          {!zoomConnected ? (
            <>
              {' '}
              <StyledCardHeaderText>
                Please{' '}
                {hasZoomAccount && zoomReauthRequired ? 'reconnect' : 'connect'}{' '}
                Zoom
              </StyledCardHeaderText>
              <StyledModalCardSubHeaderText>
                Grain needs new permissions to automatically join and record
                your Zoom meetings.
              </StyledModalCardSubHeaderText>
              <div className='content-wrapper'>
                <StyledConnect
                  as='a'
                  href={getOauthRedirectUrl(
                    'zoom',
                    true,
                    'connect_account',
                    {},
                    next
                  )}
                  css={['background-color: #0E71EB', color.fg.swan]}
                  data-track='Button Clicked'
                  data-track-args={JSON.stringify({
                    button_name: 'connect_zoom',
                    category: 'integrations',
                    trigger: 'connect_zoom_existing',
                    button_text: 'Connect Zoom Account'
                  })}
                >
                  <span className='icon' css={['width: 20px;']}>
                    <Icon.ZoomLogo />
                  </span>{' '}
                  {hasZoomAccount && zoomReauthRequired
                    ? 'Reconnect'
                    : 'Connect'}{' '}
                  Zoom account
                </StyledConnect>
              </div>
            </>
          ) : (
            <>
              <StyledCardHeaderText>You're all set</StyledCardHeaderText>
              <StyledModalCardSubHeaderText>
                Your Zoom account has been connected.
              </StyledModalCardSubHeaderText>
            </>
          )}
          {!zoomConnected ? (
            <StyledClose
              onClick={async () => {
                await saveExtra({
                  ...extra,
                  connectZoomModalDismissedAdditional: true,
                  connectZoomModalShown: true,
                  connectZoomModalReminder: new Date().toString()
                });
                close();
              }}
            >
              {myself?.extra?.connectZoomModalShown
                ? 'Not now'
                : 'Remind me later'}
            </StyledClose>
          ) : (
            <StyledNextButton
              variant='primary'
              css={['margin-right: auto; margin-top: auto;']}
              onClick={() => close()}
            >
              Done
            </StyledNextButton>
          )}
        </div>
        <div className='right'>
          <img
            src={`${`${getBasePublicPath()}/images/onboarding/card-zoom.svg`}`}
          />
        </div>
      </StyledModalCard>
    </StyledCardWrapper>
  );
};

export default ConnectZoom;
