import styled from '@emotion/styled';
import { color, pxToRem } from '@grain/grain-ui';

export const StyledConnect = styled.button<{ href?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: none;
  outline: none;
  ${color.bg.gull};
  ${color.fg.blackbird};
  width: 469px;
  height: 48px;
  border-radius: 12px;
  cursor: pointer;

  font-size: ${pxToRem(14)};
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  gap: 12px;

  &:hover {
    text-decoration: none;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledClose = styled.a`
  ${color.fg.crow};
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(20)};
  font-style: normal;
  font-weight: 500;
  text-decoration-line: underline;
  margin-top: auto;
  cursor: pointer;
`;

export const StyledActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;
