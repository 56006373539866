/**
 * NOTE:
 * The app is served on the route domain grain.com and shares url space with the
 * backend app and the marketing website.
 *
 * Marketing has decreed that they control the url space so by default every url
 * is routed to their website unless specifically allowed. It's worth checking
 * <insert terraform file url here> and `server/lib/grain_web/router.ex` to see if
 * a new url path will collide.
 *
 * If unsure and it's a one off page, prefix the url with `/app/` and it'll work
 * out of the box.
 */

import * as Intercom from '~/support/intercom';

import {
  FTUX_SLIDESHOW_ROUTE_ID,
  POST_ONBOARDING_ROUTE_ID,
  POST_SLIDESHOW_ROUTE_ID
} from '@grain/components/overlays/constants';
import {
  ANNOUNCEMENT_ROUTE_ID,
  CALENDLY_ROUTE_ID,
  CANCEL_PLANS_ROUTE_ID,
  HUBSPOT_CONNECT_ROUTE_ID,
  IMPORT_ZOOM_ROUTE_ID,
  INTEGRATIONS_ROUTE_ID,
  INVITE_ROUTE_ID,
  ONBOARDING_ROUTE_ID,
  START_TRIAL_ROUTE_ID,
  SUBSCRIBE_ROUTE_ID,
  TRIAL_ENDED_ROUTE_ID,
  TRIAL_STARTED_ROUTE_ID,
  UPGRADE_PLAN_ROUTE_ID,
  ZOOM_CONNECT_ROUTE_ID,
  REQUEST_UPGRADE_ADMIN_ROUTE_ID,
  RECORD_MEETINGS_GATE_ROUTE_ID
} from '@grain/components/modals/constants';
import { DesktopLayout, desktopRoutes, useDesktop } from '@grain/desktop-lib';
import { LastLocation, usePrevLocation } from './usePrevLocation';
import {
  matchPath,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useRoutes,
  useSearchParams
} from 'react-router-dom';
import React, { useRef, useCallback } from 'react';
import {
  useAnalytics,
  useOpenModal,
  useRouteModal,
  useRouteOverlay,
  useGlobalShortcuts
} from '@grain/grain-ui';
import { useMyself } from '@grain/api/auth';
import { useExtra } from '@grain/components/support/extra';
import { KnockFeedProvider } from '~/components/KnockFeedProvider';

import AccountSettings from '~/pages/Settings/Account';
import AskPage from '~/pages/AskPage';
import CalendlyPage from '~/pages/Onboarding/Calendly';
import ImportZoom from '~/pages/ImportZoom';
import IntegrationSettings from '~/pages/Settings/Integrations';
import CalendarPage from '~/pages/Calendar';
import RecordingPage from '~/pages/RecordingPage';
import SearchPage from '~/pages/Search';
import StoryPage from '~/pages/StoryPage';
import SubscriptionPlanPage from '~/pages/ChangePlan';
import Upgrade from '~/pages/Upgrade';
import WorkspaceSettings from '~/pages/Settings/Workspace';
import Onboarding from '~/pages/Onboarding';
import { useActiveRecordings } from '@grain/components/ActiveRecordingsContext';
import { useCheckAnnouncements } from '~/modals/Announcement/hooks';
import { useGlobalEffects } from '@grain/components/support/globalEffects';
import { useHasAcceptTerms } from '~/modals/AcceptTerms/hooks';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import { useOnboardingToast } from './useOnboardingToaster';
import { useWebSocket } from '@grain/components/WebSocketContext';
import PostOnboardingOverlay from '~/overlays/PostOnboarding';
import FtuxSlideshowOverlay from '~/overlays/FtuxSlideshow';
import PostSlideshowOverlay from '~/overlays/PostSlideshow';
import SubscribeModal from '~/modals/Subscribe';
import InviteModal from '~/modals/Invite';
import CancelPlansModal from '~/modals/CancelPlans';
import PostLoginPage from '~/pages/PostLogin';
import OnboardingModal from '~/modals/Onboarding';
import IntegrationsModal from '~/modals/Integrations';
import AcceptTermsModal from '~/modals/AcceptTerms';
import StartTrialModal from '~/modals/StartTrial';
import TrialEndedModal from '~/modals/TrialEnded';
import TrialStartedModal from '~/modals/TrialStarted';
import UploadMeetingFilePage from '~/pages/UploadMeetingFilePage';
import DeletedPage from '~/pages/Deleted';
import CalendlyModal from '~/modals/Calendly';
import ZoomConnectModal from '~/modals/ZoomConnect';
import HubspotReconnectModal from '~/modals/HubspotReconnect';
import ImportZoomConfirmModal from '~/modals/ImportZoomConfirm';
import AnnouncementModal from '~/modals/Announcement';
import UpgradePlanModal from '~/modals/UpgradePlan';
import RequestAdminUpgradeModal from '~/modals/RequestAdminUpgrade';
import RecordMeetingsGateModal from '~/modals/RecordMeetingsGate';
import Playlists from '~/pages/Playlists';
import Insights from '~/pages/Insights';
import Deals from '~/pages/Deals';
import DealDetails from '~/pages/Deals/DealDetails';
import CoachingPage from '~/pages/CoachingPage';
import CollectionPage from '~/pages/CollectionPage';
import MeetingsPage from '~/pages/Meetings';
import StoriesPage from '~/pages/Stories';
import { useCacheRefresh } from './useCacheRefresh';
import Downloading from '~/pages/Onboarding/DownloadDesktop/Downloading';
import { useUnassignedSeatsToast } from '../hooks/useUnassignedSeatsToast';
import { useMyselfQuery } from '@grain/api/graphql/queries/user.generated';
import DesktopRedirectPage from '~/pages/DesktopRedirect';
import { useDesktopAppRedirect } from '~/support/useDesktopAppRedirect';
import { useDesktopAppToaster } from '~/support/useDesktopAppToaster/useDesktopAppToaster';
import { useSilentMode } from '@grain/grain-ui/v4';

// Dev Only Pages
import Playground from '~/pages/Playground';
const GrainDevTools = React.lazy(
  () => import('~/modules/devTools/GrainDevTools')
);

// Routes that should be in silent mode by default
const SILENT_MODE_ROUTES = ['/app/onboarding', '/downloading'];

// Params, when present in the URL, that should trigger silent mode
const SILENT_MODE_PARAMS = ['overlay'];

// This layout sets up state common to all logged-in routes.
//
// The desktop notification/notepad windows both need active recording data, so
// they use this route.  But this route also excludes modals, etc, which should
// never appear in those windows.
function LoggedInLayout() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [, setUserId] = useLocalStorage('user_id', null);
  const { userChannel } = useWebSocket();
  const { myself } = useMyself();
  const { restoreData } = useActiveRecordings();
  useCacheRefresh();

  const isSilentModeEnabled =
    SILENT_MODE_ROUTES.some(route => matchPath(route, location.pathname)) ||
    SILENT_MODE_PARAMS.some(param => Boolean(searchParams.get(param)));
  useSilentMode('silenced-page', isSilentModeEnabled);

  const myselfId = myself?.id;
  React.useEffect(() => {
    if (!myselfId) return;

    userChannel.connect(`user:${myselfId}`);

    // This user_id is consumed on the marketing website for tracking events
    // We should not clean it when the user is logged out
    setUserId(myselfId);

    // When adding a new listener, a remove listener function is returned
    const onDisconnect = userChannel.onConnect(() => {
      restoreData();
    });

    return onDisconnect;
  }, [myselfId, userChannel, restoreData, setUserId]);

  return <Outlet />;
}

// This layout sets up state for when you're "in the app," which applies to most
// pages.
//
// The only ones that are excluded are ones which don't "feel like" they're in
// the app, like the desktop notification/notepad windows.  Everywhere else
// (places where we'd want to show Intercom messages or the new feature
// announcement modal), this layout should be used.
function AppLayout() {
  const { extra, loading: extraLoading } = useExtra();
  const visibleAcceptTermsModal = useHasAcceptTerms();
  const navigate = useNavigate();
  const location = useLocation();
  const prevLocation = usePrevLocation(location);
  const { appEvent } = useDesktop();
  const { trackEvent } = useAnalytics();
  useGlobalShortcuts();

  const {
    announcements,
    showAnnouncementModal,
    setAnnouncementShown,
    setAnnouncementClosed
  } = useCheckAnnouncements();
  const announcementModalShownRef = useRef(false);

  useMyselfQuery({
    onCompleted(data) {
      const { myself } = data || {};
      // myself is null if an unauthorized error pops up
      if (myself) {
        Intercom.boot({ user_hash: myself.intercomToken });
        Intercom.updateMyself(myself);
      }
    }
  });

  // Listens to changes of onboarding trigger to trigger onboarding toast for non paid members
  // https://linear.app/grain/issue/ENG-9586
  useOnboardingToast();

  // Triggers toast for unassigned paid seats
  useUnassignedSeatsToast();

  // Handles user preference to open in the desktop app
  const {
    isDesktopRedirectScreenOpen,
    setIsDesktopRedirectScreenOpen,
    setOpenInBrowser
  } = useDesktopAppRedirect();

  useDesktopAppToaster({
    hasRedirectActive: isDesktopRedirectScreenOpen,
    setIsDesktopRedirectScreenOpen
  });

  const { isOpen: ftuxSlideshowIsOpen } = useRouteOverlay(
    FTUX_SLIDESHOW_ROUTE_ID
  );
  const { isOpen: postOnboardingIsOpen } = useRouteOverlay(
    POST_ONBOARDING_ROUTE_ID
  );
  const { isOpen: postSlideshowIsOpen } = useRouteOverlay(
    POST_SLIDESHOW_ROUTE_ID
  );

  const openModal = useOpenModal();

  const { isOpen: subscribeIsOpen } = useRouteModal(SUBSCRIBE_ROUTE_ID);
  const { isOpen: inviteIsOpen } = useRouteModal(INVITE_ROUTE_ID);
  const { isOpen: cancelPlanIsOpen } = useRouteModal(CANCEL_PLANS_ROUTE_ID);
  const { isOpen: startTrialIsOpen } = useRouteModal(START_TRIAL_ROUTE_ID);
  const { isOpen: trialEndedIsOpen } = useRouteModal(TRIAL_ENDED_ROUTE_ID);
  const { isOpen: trialStartedIsOpen } = useRouteModal(TRIAL_STARTED_ROUTE_ID);
  const { isOpen: isUpgradePlanOpen } = useRouteModal(UPGRADE_PLAN_ROUTE_ID);
  const { isOpen: isRequestUpgradeAdminOpen } = useRouteModal(
    REQUEST_UPGRADE_ADMIN_ROUTE_ID
  );
  const { isOpen: isRecordMeetingsGateOpen } = useRouteModal(
    RECORD_MEETINGS_GATE_ROUTE_ID
  );

  const { isOpen: integrationsModalIsOpen } = useRouteModal(
    INTEGRATIONS_ROUTE_ID
  );
  const { isOpen: onboardingIsOpen } = useRouteModal(ONBOARDING_ROUTE_ID);
  const { isOpen: calendlyIsOpen } = useRouteModal(CALENDLY_ROUTE_ID);
  const { isOpen: zoomConnectIsOpen } = useRouteModal(ZOOM_CONNECT_ROUTE_ID);
  const { isOpen: hubspotIsOpen } = useRouteModal(HUBSPOT_CONNECT_ROUTE_ID);
  const { isOpen: importZoomConfirmIsOpen } =
    useRouteModal(IMPORT_ZOOM_ROUTE_ID);
  const { isOpen: announcementIsOpen, open: openAnnouncementModal } =
    useRouteModal(ANNOUNCEMENT_ROUTE_ID);

  useGlobalEffects();

  React.useEffect(() => {
    if (!extra || extraLoading || process.env.CYPRESS) return;

    // v38 changed the jobFunctionSet default from true to false
    const onboardingPending = !extra?.jobFunctionSet && extra.v >= 38;
    const isDesktopRedirect = location.pathname.startsWith(
      '/app/_/redirect/desktop'
    );

    // Prevents the redirect page from being redirected to onboarding or meetings
    if (isDesktopRedirect) {
      return;
    }

    if (onboardingPending) {
      if (!location.pathname.startsWith('/app/onboarding')) {
        navigate('/app/onboarding');
      }
    } else if (
      extra?.onboardingDone &&
      location.pathname.startsWith('/app/onboarding')
    ) {
      navigate('/app/meetings');
    }
  }, [navigate, extra, extraLoading, location.pathname]);

  React.useEffect(() => {
    if (!appEvent) return;
    const settingHandler = () => {
      navigate('/app/settings/account?tab=app');
    };
    const recordingHandler = recordingId => {
      navigate(`/share/recording/${recordingId}`);
    };
    const modalHandler = routeKey => {
      openModal(routeKey);
    };
    const urlHandler = url => {
      navigate(url);
    };

    appEvent.on('open-app-settings', settingHandler);
    appEvent.on('open-recording', recordingHandler);
    appEvent.on('open-modal', modalHandler);
    appEvent.on('open-url', urlHandler);
    return () => {
      appEvent.off('open-app-settings', settingHandler);
      appEvent.off('open-recording', recordingHandler);
      appEvent.off('open-modal', modalHandler);
      appEvent.off('open-url', urlHandler);
    };
  }, [navigate, appEvent, openModal]);

  React.useEffect(() => {
    if (!showAnnouncementModal || announcementModalShownRef.current) return;
    announcementModalShownRef.current = true;
    openAnnouncementModal();
    trackEvent(
      'New Feature Announcement Viewed',
      {
        announcements: announcements.map(({ title }) => title)
      },
      ['user', 'workspace']
    );
  }, [
    announcements,
    openAnnouncementModal,
    setAnnouncementShown,
    showAnnouncementModal,
    trackEvent,
    location.key
  ]);

  const handleOpenInBrowser = useCallback(() => {
    setOpenInBrowser(true);
    setIsDesktopRedirectScreenOpen(false);
    trackEvent('Desktop Redirect Opened In Browser');
  }, [setOpenInBrowser, trackEvent, setIsDesktopRedirectScreenOpen]);

  const showDevTools =
    import.meta.env.DEV && process.env.VITE_ENABLE_DEV_TOOLS === 'true';

  return (
    <KnockFeedProvider>
      <LastLocation.Provider value={prevLocation}>
        {showDevTools && (
          <React.Suspense fallback={null}>
            <GrainDevTools />
          </React.Suspense>
        )}
        {isDesktopRedirectScreenOpen ? (
          <DesktopRedirectPage onOpenInBrowser={handleOpenInBrowser} />
        ) : (
          <Outlet />
        )}
        {/* Route-based overlays */}
        {ftuxSlideshowIsOpen && <FtuxSlideshowOverlay />}
        {postOnboardingIsOpen && <PostOnboardingOverlay />}
        {postSlideshowIsOpen && <PostSlideshowOverlay />}
        {/* Route-based modals */}
        {subscribeIsOpen && <SubscribeModal />}
        {inviteIsOpen && <InviteModal />}
        {onboardingIsOpen && <OnboardingModal />}
        {integrationsModalIsOpen && <IntegrationsModal />}
        {visibleAcceptTermsModal && <AcceptTermsModal />}
        {calendlyIsOpen && <CalendlyModal />}
        {zoomConnectIsOpen && <ZoomConnectModal />}
        {hubspotIsOpen && <HubspotReconnectModal />}
        {importZoomConfirmIsOpen && <ImportZoomConfirmModal />}
        {announcementIsOpen && (
          <AnnouncementModal
            announcements={announcements}
            showAnnouncementModal={showAnnouncementModal}
            setAnnouncementClosed={setAnnouncementClosed}
            setAnnouncementShown={setAnnouncementShown}
          />
        )}
        {cancelPlanIsOpen && <CancelPlansModal />}
        {startTrialIsOpen && <StartTrialModal />}
        {trialEndedIsOpen && <TrialEndedModal />}
        {trialStartedIsOpen && <TrialStartedModal />}
        {isUpgradePlanOpen && <UpgradePlanModal />}
        {isRequestUpgradeAdminOpen && <RequestAdminUpgradeModal />}
        {isRecordMeetingsGateOpen && <RecordMeetingsGateModal />}
      </LastLocation.Provider>
    </KnockFeedProvider>
  );
}

const DealsRoute = () => {
  return useRoutes([
    { path: '/', element: <Deals /> },
    {
      path: '/:id',
      // Don't redirect to "/:id/overview", go to the "bare" path "/:id",
      // this way our analytics module can read the path as "/:id" and classify
      // the view as a "Deal Viewed".  Clicking on the tabs will NOT count
      // as another "Deal Viewed", instead the page will count those as
      // "Deal Tabs Changed".
      element: <DealDetails contentType='overview' />
    },
    {
      path: '/:id/overview',
      element: <DealDetails contentType='overview' />
    },
    {
      path: '/:id/activity',
      element: <DealDetails contentType='activity' />
    },
    {
      path: '/:id/contacts',
      element: <DealDetails contentType='contacts' />
    }
  ]);
};

const CoachingRoute = () => {
  const coachingRoutes = [
    { path: '/', element: <CoachingPage /> },
    { path: '/:recordingId', element: <CoachingPage /> },
    {
      path: '/:recordingId/feedback',
      element: <Navigate replace to='../overview' relative='path' />
    },
    { path: '/:recordingId/metrics', element: <CoachingPage /> },
    { path: '/:recordingId/comments', element: <CoachingPage /> },
    { path: '/:recordingId/scoring', element: <CoachingPage /> },
    { path: '/:recordingId/overview', element: <CoachingPage /> }
  ];

  return useRoutes(coachingRoutes);
};

/**
 * @type {import('react-router-dom').RouteObject[]}
 */
const appRoutes = [
  {
    path: '/app/request-upgrade',
    element: <Navigate replace to='/app/calendar?requestUpgrade=true' />
  },
  {
    path: '/app/automations/',
    element: <Navigate replace to='/app/settings/integrations' />
  },

  { path: '/share/recording/:id/:token', element: <RecordingPage /> },
  // Fixes deep links to the desktop app (it incorrectly prepends the /app)
  {
    path: '/app/share/recording/:id/:token',
    element: (
      <>
        <Navigate
          replace
          to={`/share/recording/${location.href.split('app/share/recording/')[1]}`}
        />
      </>
    )
  },

  {
    path: '/app/share/collection/:id/:token',
    element: (
      <>
        <Navigate replace to={location.pathname.replace('/app', '')} />
      </>
    )
  },

  { path: '/app/stories/:storyId', element: <StoryPage /> },
  { path: '/app/ask', element: <AskPage /> },
  /* This supports legacy releases <2022.11.3 */
  { path: '/share/collection/:id/:token', element: <CollectionPage /> },
  { path: '/app/deleted', element: <DeletedPage /> },
  { path: '/app/search', element: <SearchPage /> },
  { path: '/app/settings/workspace', element: <WorkspaceSettings /> },
  { path: '/app/settings/account', element: <AccountSettings /> },
  { path: '/app/settings/integrations', element: <IntegrationSettings /> },
  { path: '/app/change-plan/:planSku', element: <SubscriptionPlanPage /> },
  { path: '/app/_/redirect/desktop', element: <PostLoginPage /> },
  { path: '/app/import-zoom', element: <ImportZoom /> },
  /* This supports legacy desktop releases <2021.15.2 */
  {
    path: '/app/zoom_import',
    element: <Navigate replace to='/app/import-zoom' />
  },

  { path: '/app/upload-recording', element: <UploadMeetingFilePage /> },
  {
    path: '/app/record-call',
    element: <Navigate replace to='/app/calendar' />
  },
  {
    path: '/app/calendar',
    element: <CalendarPage />
  },

  {
    path: '/app/stories',
    element: <StoriesPage />
  },

  {
    path: '/app/clips',
    element: <StoriesPage />
  },

  {
    path: '/app/playlists',
    element: <Playlists />
  },

  {
    path: '/app/meetings/all',
    element: <MeetingsPage key='all' />
  },
  {
    path: '/app/meetings/',
    element: <MeetingsPage key='my' />
  },
  {
    path: '/app/meetings/shared',
    element: <MeetingsPage key='shared' />
  },

  {
    path: '/app/get-started',
    element: <Navigate replace to='/app/calendar' />
  },
  {
    path: '/app/insights/smarttags',
    element: <Insights contentType='smarttags' />
  },
  { path: '/app/insights/interaction', element: <Insights /> },
  { path: '/app/insights/', element: <Insights /> },
  { path: '/app/deals/*', element: <DealsRoute /> },
  {
    path: '/app/coaching',
    element: <CoachingRoute />,
    children: [
      { path: ':recordingId', element: <CoachingRoute /> },
      { path: ':recordingId/:tabId', element: <CoachingRoute /> }
    ]
  },
  { path: '/app/onboarding', element: <Onboarding /> },
  { path: '/app/', element: <Navigate replace to='/app/calendar' /> },
  { path: '/', element: <Navigate replace to='/app/calendar' /> }
];

const DEV_ONLY_ROUTES = [{ path: '/app/playground', element: <Playground /> }];

const otherRoutes = [
  {
    path: '/app/desktop/*',
    element: <DesktopLayout />,
    children: desktopRoutes
  },

  { path: '/upgrade/', element: <Upgrade /> },
  // Fixes deep links to the desktop app (it incorrectly prepends the /app)
  { path: '/app/upgrade', element: <Navigate replace to='/upgrade' /> },
  { path: '/downloading', element: <Downloading /> },

  { path: '/live-demo', element: <CalendlyPage /> }
];

export const loggedInRoutes = [
  {
    element: <LoggedInLayout />,
    children: [
      {
        element: <AppLayout />,
        children: [
          ...appRoutes,
          // https://vitejs.dev/guide/env-and-mode#env-variables
          // These routes will not be available in prod builds (when Vite server is not running)
          ...(import.meta.env.DEV ? DEV_ONLY_ROUTES : [])
        ]
      },
      ...otherRoutes
    ]
  }
];
