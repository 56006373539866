// @ts-strict-ignore
import React, { useEffect, useRef, useCallback } from 'react';
import { PageHeader, Button, Tooltip, Spinner, color } from '@grain/grain-ui';
import { ButtonWrapper, CustomTextArea, StyledHeader } from './styles';
import styled from '@emotion/styled';

type EditableHeaderProps = {
  title: string;
  id?: string;
  tooltipContent?: string;
  tooltipMaxWidth?: number | string;
  placeholder?: string;
  onCancel: () => void;
  onSave: () => void;
  onTitleChange: (title: string) => void;
  canSave: boolean;
  additionalRightButtons?: React.ReactNode;
};
const StyledTooltip = styled(Tooltip)`
  .tippy-content {
    padding: 2px 10px !important;
  }
  .tippy-content,
  &.tippy-box {
    border-radius: 100px !important;
  }
`;
const StyledTooltipText = styled.div`
  display: flex;
  padding: 6px;
  align-items: center;
  span {
    color: ${color.swan};
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;

export const EditableHeader = ({
  id,
  title: _title,
  onCancel,
  onSave,
  canSave,
  placeholder,
  tooltipContent,
  tooltipMaxWidth,
  onTitleChange,
  additionalRightButtons
}: EditableHeaderProps) => {
  const titleRef = useRef<HTMLTextAreaElement>(undefined);
  const [title, setTitle] = React.useState(_title);
  const [isSaving, setIsSaving] = React.useState(false);

  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTitle(e.target.value);
    onTitleChange(e.target.value);
  };

  useEffect(() => {
    setTitle(_title);
    onTitleChange(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_title]);

  useEffect(() => {
    // Set cursor to end of title
    const textarea = titleRef?.current;
    const value = textarea?.value;
    textarea?.setSelectionRange(value.length, value.length);
    // Focus on title when creating a new template
    if (!id) {
      titleRef.current?.focus();
      titleRef.current?.select();
    }
  }, [titleRef, id]);

  const innerRef = useCallback(
    (node: HTMLTextAreaElement) => {
      if (node) {
        titleRef.current = node;
      }
    },
    [titleRef]
  );
  const createOrSaveText = id ? 'Save' : 'Create';
  const tooltipContentEdited = (!canSave && tooltipContent) || null;

  const handleSave = async () => {
    setIsSaving(true);
    await onSave();
    setIsSaving(false);
  };

  return (
    <>
      <PageHeader.Left>
        <StyledHeader>
          <CustomTextArea
            placeholder={placeholder}
            innerRef={innerRef}
            value={title}
            onChange={handleTitleChange}
          />
        </StyledHeader>
      </PageHeader.Left>
      <PageHeader.Right>
        <ButtonWrapper>
          <Button variant='secondary' onClick={onCancel}>
            Cancel
          </Button>
          <StyledTooltip
            arrow={!!tooltipContent}
            placement='bottom'
            trigger='click mouseenter'
            hideOnClick={false}
            disabled={!tooltipContent || canSave}
            maxWidth={tooltipMaxWidth}
            content={
              <StyledTooltipText>
                <span>{tooltipContentEdited}</span>
              </StyledTooltipText>
            }
          >
            <Button
              variant={canSave ? 'primary' : 'secondary'}
              disabled={!canSave || isSaving}
              onClick={handleSave}
            >
              {isSaving ? <Spinner /> : createOrSaveText}
            </Button>
          </StyledTooltip>
          {additionalRightButtons}
        </ButtonWrapper>
      </PageHeader.Right>
    </>
  );
};
