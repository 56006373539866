import { css } from '@emotion/react';
import { color, Button } from '@grain/grain-ui';

type NoResultsStateProps = {
  noResultsText?: string;
  noMatchingResultsText?: string;
  isFilterActive: boolean;
  resetAllFilters: () => void;
};

export function NoResultsState({
  noResultsText = 'No Results',
  noMatchingResultsText = 'No results match your filters',
  isFilterActive,
  resetAllFilters
}: NoResultsStateProps) {
  return (
    <div
      css={css`
        margin-top: 56px;
        text-align: center;
        color: ${isFilterActive ? color.blackbird : color.pigeon};
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
      `}
    >
      {isFilterActive ? noMatchingResultsText : noResultsText}
      {isFilterActive && (
        <div
          css={css`
            margin-top: 16px;
            display: flex;
            justify-content: center;
          `}
        >
          <Button onClick={resetAllFilters} variant='secondary'>
            Clear filters
          </Button>
        </div>
      )}
    </div>
  );
}
