import { useEffect, useState } from 'react';

interface UseLazyLoadOptions {
  percentage?: number;
}

export const useLazyLoad = (
  ref: React.RefObject<HTMLDivElement | null>,
  callback: () => void,
  options: UseLazyLoadOptions = {}
) => {
  const { percentage = 90 } = options;
  const [hasTriggered, setHasTriggered] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return;

      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      const scrollPosition = scrollTop + clientHeight;
      const scrollThreshold = (percentage / 100) * scrollHeight;

      if (!hasTriggered && scrollPosition >= scrollThreshold) {
        callback();
        setHasTriggered(true);
      } else if (hasTriggered && scrollPosition < scrollThreshold) {
        setHasTriggered(false);
      }
    };

    const element = ref.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [ref, callback, percentage, hasTriggered]);
};

export default useLazyLoad;
