// @ts-strict-ignore
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import {
  AddAISectionModal,
  TemplateAddOption,
  copyElementAsHtml,
  NoteSection,
  toTimestamp,
  useShowToast,
  useAnalytics
} from '@grain/grain-ui';
import {
  useIntelligenceSectionUpdatedSubscription,
  useRecordingIntelligenceSectionRunMutation,
  RecordingIntelligenceSectionRunMutation,
  useIntelligenceCustomPromptCreateMutation,
  useIntelligenceCustomPromptSectionCreateMutation,
  IntelligenceCustomPromptSectionCreateMutation
} from '@grain/api/graphql/queries/notesTemplate.generated';
import {
  IntelligenceCustomPromptType,
  IntelligenceJobStatus,
  IntelligenceMark,
  IntelligenceMarkSection,
  IntelligenceTemplate,
  IntelligenceTextSection,
  Recording
} from '@grain/api/schema.generated';
import { GraphQLError, GraphQLFormattedError } from 'graphql';
import { SectionOptions, useNotesTemplate } from '~/modules/templates/hooks';
import { IntelligenceTemplateQuerySection } from '@grain/api/schema.extra';

type TimestampProps = {
  timestamp: number;
  url: string;
};

const Timestamp = ({ timestamp, url }: TimestampProps) =>
  url ? (
    <>
      (<a href={url}>{toTimestamp(timestamp)}</a>)
    </>
  ) : (
    <>({toTimestamp(timestamp)})</>
  );

type PointProps = Pick<IntelligenceMark, 'timestamp' | 'text'> & {
  url: string;
};

const Point = ({ url, timestamp, text }: PointProps) => (
  <div style={{ display: 'inline-block' }}>
    <span>
      <Timestamp timestamp={timestamp} url={url} /> - {text || 'Summary'}
    </span>
  </div>
);

type AddSectionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onAddSection?: (section: SectionOptions) => void;
  recordings: Recording[];
  sectionsNotInCurrentTemplate?: IntelligenceTemplateQuerySection[];
  isTemplate?: boolean;
  addSectionText: string;
  isRecordingFilterLoading?: boolean;
  onRecordingSearch?: (searchTerm: string) => void;
  recordingFilterValue?: string;
  placeholder?: string;
  template?: IntelligenceTemplate;
  templateAddOptions: TemplateAddOption;
  editSection?: IntelligenceTemplateQuerySection;
  modalMode?: 'add' | 'edit';
  onEditSection?: (
    section: IntelligenceTemplateQuerySection,
    processedPromptId: string,
    promptType: IntelligenceCustomPromptType
  ) => void;
};

type IIntelligencePrompt = IntelligenceMarkSection | IntelligenceTextSection;

const NOOP = (): void => null;
const EMPTY_ARRAY = [] as never[];
export const AddSectionModal = ({
  isOpen,
  onClose,
  recordings,
  sectionsNotInCurrentTemplate = EMPTY_ARRAY,
  onAddSection = NOOP,
  isTemplate,
  addSectionText,
  isRecordingFilterLoading,
  onRecordingSearch,
  placeholder,
  recordingFilterValue = '',
  template,
  templateAddOptions,
  editSection,
  modalMode,
  onEditSection
}: AddSectionModalProps) => {
  const showToast = useShowToast();

  const [sectionId, setSectionId] = useState<string>(null);
  const { trackEvent } = useAnalytics();
  const { isSectionInCurrentTemplate } = useNotesTemplate(template?.id);

  // We don't have any mutation to preview results based on the existing section
  // so we need to keep track of the section id and the user prompt to preview
  const [previewSectionId, setPreviewSectionId] = useState<string>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sectionTitle, setSectionTitle] = useState('');
  const [sectionDescription, setSectionDescription] = useState('');
  const [userPrompt, setUserPrompt] = useState('');
  const [isUserPromptDirty, setIsUserPromptDirty] = useState(false);
  const [errorKey, setErrorKey] = useState(null);
  const [createCustomPrompt] = useIntelligenceCustomPromptCreateMutation();
  const [createCustomPromptPreview] =
    useIntelligenceCustomPromptSectionCreateMutation();
  const [runPrompt] = useRecordingIntelligenceSectionRunMutation();
  const [recording, setRecording] = useState<Recording>(null);
  const [isExistingPromptSelected, setIsExistingPromptSelected] =
    useState(false);
  const [promptId, setPromptId] = useState<string>(null);
  const [isPromptValid, setIsPromptValid] = useState(false);
  const [promptType, setPromptType] =
    useState<IntelligenceCustomPromptType>(null);
  const [hasRunEditPrompt, setHasRunEditPrompt] = useState(false);

  const [customPromptData, setCustomPromptData] =
    useState<IIntelligencePrompt>(null);

  useEffect(() => {
    if (recording) return;
    const defaultRecording = recordings.length > 0 ? recordings[0] : null;
    setRecording(defaultRecording);
  }, [recordings, recording]);

  const handleClose = () => {
    setErrorKey(null);
    setCustomPromptData(null);
    setSectionTitle('');
    setSectionId(null);
    setPreviewSectionId(null);
    setUserPrompt(null);
    setIsLoading(false);
    setRecording(null);
    setPromptId(null);
    setPromptType(null);
    setIsPromptValid(false);
    setIsExistingPromptSelected(false);
    setHasRunEditPrompt(false);
    onClose();
  };

  const handleTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setSectionTitle(e.target.value);
    },
    []
  );

  const handleError = (error?: GraphQLFormattedError) => {
    setIsLoading(false);
    setErrorKey(error?.message ?? 'error');
  };

  const errorMessage = useMemo(() => {
    switch (errorKey) {
      case 'invalid_prompt':
        return (
          <span>
            This is an invalid prompt. Please update and re-enter it. For tips
            on how to write greate prompts,{' '}
            <a
              href='https://support.grain.com/en/articles/8977370-how-to-write-better-ai-prompts'
              target='_blank'
              rel='noopener noreferrer'
            >
              learn more here
            </a>
            .
          </span>
        );
      case 'insufficient_transcript':
        return 'Error generating section. The meeting selected has insufficient transcript. Please try a different meeting.';
      case 'no_transcript':
        return 'Error generating section. The meeting selected has no transcript. Please try a different meeting.';
      case 'error':
        return 'Error generating section. Please try again.';
      default:
        return null;
    }
  }, [errorKey]);

  const onGenerateComplete = useCallback((result: IIntelligenceRun) => {
    let generateData;

    if ('intelligenceCustomPromptSectionCreate' in result) {
      generateData = result.intelligenceCustomPromptSectionCreate;
    } else {
      generateData = result.recordingIntelligenceSectionRun;
      setSectionTitle(generateData?.section?.title);
    }

    switch (generateData?.status) {
      case IntelligenceJobStatus.Processing:
        setPreviewSectionId(generateData?.id);
        break;
      case IntelligenceJobStatus.Success:
        setPreviewSectionId(generateData?.id);
        setIsLoading(false);
        setCustomPromptData(generateData as IIntelligencePrompt);
        break;
      case IntelligenceJobStatus.WontRun:
        setIsLoading(false);
        setPreviewSectionId(generateData?.id);
        handleError({ message: 'insufficient_transcript' } as GraphQLError);
        break;
      case IntelligenceJobStatus.Failed:
        handleError();
        break;
      default:
        break;
    }
  }, []);

  const handlePromptSelect = useCallback(
    (sectionId: string, description: string, selectedRecordingId?: string) => {
      setSectionId(sectionId);
      setIsLoading(true);
      setIsExistingPromptSelected(true);
      setSectionDescription(description);
      setErrorKey(null);
      runPrompt({
        variables: {
          templateSectionId: sectionId,
          recordingId: selectedRecordingId || recording?.id,
          visible: false,
          eventProperties: JSON.stringify({
            location: isTemplate ? 'template' : 'meeting'
          })
        },
        onCompleted: onGenerateComplete,
        onError: errors => {
          handleError(errors.graphQLErrors[0]);
        }
      });
    },
    [isTemplate, onGenerateComplete, recording, runPrompt]
  );

  const handlePromptCreate = useCallback(
    async (prompt: string) => {
      setIsPromptValid(false);
      setIsLoading(true);
      setPromptId(null);
      const response = await createCustomPrompt({
        variables: {
          userPrompt: prompt,
          eventProperties: JSON.stringify({
            location: isTemplate ? 'template' : 'meeting'
          })
        },
        onError: errors => {
          handleError(errors.graphQLErrors[0]);
        }
      });

      if (!response.data?.intelligenceCustomPromptCreate) {
        return;
      }
      const data = response.data.intelligenceCustomPromptCreate;
      setPromptId(data.id);
      setSectionTitle(data.title);
      setIsPromptValid(true);
      return data;
    },
    [createCustomPrompt, isTemplate]
  );

  useEffect(() => {
    if (
      !editSection ||
      modalMode !== 'edit' ||
      hasRunEditPrompt ||
      !recording?.id
    ) {
      return;
    }
    setHasRunEditPrompt(true);
    setUserPrompt(editSection.userPrompt);
    setIsLoading(true);
    // Running these two mutations together.
    // promptCreate: so we can get the promptId for when users want to edit prompt or prompt type
    // promptSelect: gives the actual initial preview
    handlePromptCreate(editSection.userPrompt);
    handlePromptSelect(editSection.id, editSection.description, recording?.id);
  }, [
    editSection,
    modalMode,
    handlePromptSelect,
    hasRunEditPrompt,
    handlePromptCreate,
    recording
  ]);

  const handlePromptPreviewGenerate = useCallback(
    async (
      recording: Recording,
      promptId: string,
      type: IntelligenceCustomPromptType
    ) => {
      setIsLoading(true);
      setErrorKey(null);
      setIsExistingPromptSelected(false);
      setPromptType(type);
      createCustomPromptPreview({
        variables: {
          recordingId: recording?.id,
          processedPromptId: promptId,
          userPromptType: type
        },
        onCompleted: onGenerateComplete,
        onError: errors => {
          handleError(errors.graphQLErrors[0]);
        }
      });
    },
    [createCustomPromptPreview, onGenerateComplete]
  );

  const handlePromptSubmit = useCallback(
    async (value: string) => {
      const promptData = await handlePromptCreate(value);
      if (!promptData) return;
      setIsPromptValid(true);
      setIsUserPromptDirty(false);
      const promptType = promptData?.type;
      setPromptType(promptType);
      handlePromptPreviewGenerate(recording, promptData.id, promptType);
    },
    [handlePromptCreate, handlePromptPreviewGenerate, recording]
  );

  const handlePromptTypeChange = useCallback(
    (type: IntelligenceCustomPromptType) => {
      handlePromptPreviewGenerate(recording, promptId, type);
    },
    [handlePromptPreviewGenerate, promptId, recording]
  );

  const handleRecordingChange = useCallback(
    (recording: Recording) => {
      setRecording(recording);
      if (isExistingPromptSelected && (!userPrompt || !promptId) && sectionId) {
        return handlePromptSelect(sectionId, sectionDescription, recording?.id);
      }
      if (promptId && promptType) {
        handlePromptPreviewGenerate(recording, promptId, promptType);
      }
    },
    [
      handlePromptSelect,
      handlePromptPreviewGenerate,
      isExistingPromptSelected,
      userPrompt,
      sectionId,
      sectionDescription,
      promptId,
      promptType
    ]
  );

  const noteItem = useMemo(() => {
    if (!customPromptData || !!errorKey) return null;
    return {
      title: sectionTitle,
      status: customPromptData.status,
      onTitleChange: handleTitleChange,
      isTitleEditable: true,
      isUserPrompt: customPromptData.section.isUserPrompt,
      type: customPromptData.section
        .type as unknown as IntelligenceCustomPromptType,
      onTypeChange: (type: IntelligenceCustomPromptType) =>
        handlePromptTypeChange(type),
      onCopy: isTemplate
        ? null
        : () => {
            copyElementAsHtml(
              <>
                <div>
                  <b>{sectionTitle}</b>
                </div>
                {customPromptData.__typename === 'IntelligenceTextSection'
                  ? customPromptData.data?.text ?? ''
                  : (customPromptData.data ?? []).reduce(
                      (acc, mark, index, arr) => [
                        ...acc,
                        <Point
                          key={mark.id}
                          text={mark.text}
                          timestamp={mark.timestamp}
                          url={`${recording.recordingUrl}?t=${mark.timestamp}`}
                        />,
                        ...(index - 1 !== arr.length ? ['\n'] : [])
                      ],
                      []
                    )}
              </>
            );

            const existingType = customPromptData?.section?.isUserPrompt
              ? 'custom_existing'
              : 'grain';

            trackEvent(
              'Custom Prompt Copied',
              {
                prompt_type: isExistingPromptSelected
                  ? existingType
                  : 'custom_new'
              },
              ['user', 'workspace']
            );

            showToast({
              content: 'Section copied to clipboard',
              type: 'success',
              showCloseButton: true
            });
          },
      children:
        customPromptData.__typename === 'IntelligenceTextSection' ? (
          <NoteSection.Text
            status={customPromptData.status}
            id={customPromptData.data?.id}
            text={customPromptData.data?.text ?? ''}
            emptyText={`No ${sectionTitle} results detected in this meeting.`}
          />
        ) : (
          <NoteSection.NotePointList
            status={customPromptData.status}
            points={customPromptData.data ?? []}
            emptyText={`No ${sectionTitle} results detected in this meeting.`}
            highlightList={(recording?.persons ?? []).map(p => p.name)}
            onEdited={null}
            onDelete={null}
          />
        )
    };
  }, [
    customPromptData,
    handleTitleChange,
    recording,
    sectionTitle,
    showToast,
    errorKey,
    trackEvent,
    isExistingPromptSelected,
    handlePromptTypeChange,
    isTemplate
  ]);

  useIntelligenceSectionUpdatedSubscription({
    variables: {
      sectionId: previewSectionId
    },
    skip: Boolean(!previewSectionId),
    shouldResubscribe: true,
    onData: ({ data }) => {
      setIsLoading(false);
      const customPrompt = data.data
        .intelligenceSectionUpdated as IIntelligencePrompt;

      if (customPrompt?.status === IntelligenceJobStatus.Success) {
        setCustomPromptData(customPrompt);
      } else {
        handleError();
      }
    }
  });

  type IIntelligenceRun =
    | IntelligenceCustomPromptSectionCreateMutation
    | RecordingIntelligenceSectionRunMutation;

  const handleAddSection = async () => {
    if (modalMode === 'edit') {
      onEditSection(
        { ...editSection, title: sectionTitle },
        promptId,
        promptType
      );
      return handleClose();
    }
    onAddSection({
      id: previewSectionId ?? sectionId,
      title: sectionTitle,
      sectionId,
      processedPromptId: promptId,
      promptType,
      description: sectionDescription,
      isExistingSection: isExistingPromptSelected
    });
    handleClose();
  };

  const isPreviewSectionInCurrentTemplate = useMemo(() => {
    if (!previewSectionId) return false;
    const sectionData = {
      id: previewSectionId,
      title: sectionTitle,
      description: userPrompt
        ? `Custom Prompt: "${userPrompt}"`
        : sectionDescription,
      type: customPromptData?.section?.type,
      isUserPrompt: !isExistingPromptSelected,
      userPrompt: ''
    } satisfies IntelligenceTemplateQuerySection;
    return isSectionInCurrentTemplate(sectionData);
  }, [
    previewSectionId,
    isSectionInCurrentTemplate,
    sectionTitle,
    sectionDescription,
    userPrompt,
    customPromptData,
    isExistingPromptSelected
  ]);

  const canSave = useMemo(() => {
    if (isTemplate) {
      return !isUserPromptDirty && (isPromptValid || isExistingPromptSelected);
    }
    return (
      isExistingPromptSelected ||
      (isPromptValid && !errorMessage && !isLoading && noteItem)
    );
  }, [
    isTemplate,
    isPromptValid,
    isExistingPromptSelected,
    errorMessage,
    isLoading,
    noteItem,
    isUserPromptDirty
  ]);

  if (!isOpen) return null;

  const getPlaceholderText = () => {
    if (placeholder) return placeholder;
    if (noteItem || errorKey) return 'Write your own prompt.';
    return 'Write your own prompt or choose from the list below.';
  };

  return (
    <AddAISectionModal
      isPreviewLoading={isLoading}
      userPrompt={userPrompt}
      noteItem={noteItem}
      onClose={handleClose}
      onSectionAdd={handleAddSection}
      onPreviewSubmit={(value: string) => handlePromptSubmit(value)}
      onSuggestionSelect={handlePromptSelect}
      errorMessage={errorMessage}
      selectedRecording={recording}
      onRecordingChange={handleRecordingChange}
      recordings={recordings}
      placeholder={getPlaceholderText()}
      sections={sectionsNotInCurrentTemplate}
      addSectionText={addSectionText}
      isRecordingFilterLoading={isRecordingFilterLoading}
      onRecordingSearch={onRecordingSearch}
      canSave={Boolean(canSave)}
      showRecordingSelector={isTemplate}
      recordingFilterValue={recordingFilterValue}
      modalMode={modalMode}
      setIsUserPromptDirty={setIsUserPromptDirty}
      addToTemplateOptions={
        {
          ...templateAddOptions,
          canAddToTemplate:
            templateAddOptions.canAddToTemplate &&
            !isPreviewSectionInCurrentTemplate
        } as TemplateAddOption
      }
    />
  );
};
