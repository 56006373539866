import React, { useMemo } from 'react';
import {
  ChipContainer,
  Title,
  Metadata,
  State,
  IconWrapper,
  CloseButton,
  Content
} from './styled';
import { ChipSize, ChipVariant } from './types';
import type { IconType } from 'components/atoms/Icon/Icon';

// This union exists because an Icon can not be provided to a Chip without a Title or State existing.
// The one state that may not seem obvious is `icon?: never` which means if title and string are both optional an icon can never be
// provided. But the reason it's optional is to support no icon with no title/state.
type TitleStateIconVariants =
  | { icon: IconType; title: string; state?: string }
  | { icon: IconType; title?: string; state: string }
  | { icon?: never; title?: string; state?: string };

export type ChipProps = TitleStateIconVariants & {
  as?: React.ComponentProps<typeof ChipContainer>['as'];
  className?: string;
  variant?: ChipVariant;
  size?: ChipSize;
  metadata?: string;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export const Chip = ({
  as,
  className,
  icon,
  title,
  metadata,
  state,
  onClick,
  size = 'md',
  variant = 'stroked',
  onClose
}: ChipProps) => {
  const showTitleIcon = Boolean(title && icon);
  const showStateIcon = Boolean(icon) && !showTitleIcon;

  const iconElement = useMemo(() => {
    if (!icon) return null;
    return <IconWrapper as={icon} />;
  }, [icon]);

  return (
    <ChipContainer
      as={as}
      variant={variant}
      size={size}
      onClick={onClick}
      className={className}
      data-cy={`chip-${title}-${metadata}-${state}`}
    >
      <Content>
        {title && (
          <Title variant={variant}>
            {showTitleIcon && iconElement}
            {title}
          </Title>
        )}
        {Boolean(title && metadata) && (
          <Metadata variant={variant}>{metadata}</Metadata>
        )}
        {state && (
          <State>
            {showStateIcon && iconElement}
            {state}
          </State>
        )}
      </Content>

      {onClose && (
        <CloseButton size={size} variant={variant} onClick={onClose} />
      )}
    </ChipContainer>
  );
};
