import React from 'react';
import { ModalConfirm, pxToRem, useRouteModal } from '@grain/grain-ui';
import {
  CALENDLY_ROUTE_ID,
  INTEGRATIONS_ROUTE_ID
} from '@grain/components/modals/constants';

export default function ImportZoomConfirmModal() {
  const { close } = useRouteModal(CALENDLY_ROUTE_ID);
  const { open: openIntegrations } = useRouteModal(INTEGRATIONS_ROUTE_ID);

  return (
    <ModalConfirm
      title={
        <div
          css={['display: flex; align-items: center; justify-content: center;']}
        >
          Your recent meetings are being imported
        </div>
      }
      description={
        <span
          css={[
            `white-space: pre-wrap; font-weight: 400; font-size: ${pxToRem(
              16
            )}; line-height: ${pxToRem(20)}; color: #545454;`
          ]}
        >
          New Zoom Cloud recordings will be auto imported to Grain.
        </span>
      }
      confirmButtonType='primary'
      width={418}
      cancelContent='Open Zoom Settings'
      confirmContent='Continue'
      onConfirm={close}
      onCancel={openIntegrations}
      showCancel={true}
      closable={false}
    />
  );
}
