import React from 'react';
import { Link } from 'react-router-dom';
import { StyledButtonContainer, StyledButtonPlaceholder } from './styles';
import { usePlan } from '@grain/components/Subscriptions/hooks';
import { SubscriptionPlan } from '@grain/api/schema.generated';
import { Button, Tooltip } from '@grain/grain-ui/v4';

type PlanButtonProps = {
  isCurrentPlan: boolean;
  onAction: () => void;
  currentPlanSku: string;
  canEdit: boolean;
  actionText: string;
  actionCompletedText?: React.ReactNode;
  planIndex: number;
  plan: SubscriptionPlan;
  hasNoPrice: boolean;
  disableButtonText?: string;
  hasProminentCta?: boolean;
  isWorkspaceOnTrial?: boolean;
};

const PlanButton = (props: React.ComponentProps<typeof Button>) => (
  <Button fullWidth size='lg' {...props} />
);

const CurrentPlanButton = ({
  onAction,
  canEdit,
  actionText,
  hasProminentCta
}: Pick<
  PlanButtonProps,
  'onAction' | 'canEdit' | 'actionText' | 'hasProminentCta'
>) => {
  return (
    <StyledButtonContainer>
      <PlanButton
        variant={hasProminentCta ? 'plan' : 'neutral'}
        onClick={onAction}
        disabled={!canEdit}
      >
        {actionText}
      </PlanButton>
    </StyledButtonContainer>
  );
};

const UpgradePlanButton = ({
  onAction,
  canEdit,
  hasNoPrice,
  actionText,
  actionCompletedText,
  disableButtonText,
  hasProminentCta
}: Pick<
  PlanButtonProps,
  | 'onAction'
  | 'canEdit'
  | 'actionText'
  | 'actionCompletedText'
  | 'disableButtonText'
  | 'hasProminentCta'
> & {
  hasNoPrice: boolean;
}) => {
  const isButtonDisabled = Boolean(actionCompletedText) || !canEdit;
  return (
    <Tooltip
      content={
        disableButtonText ??
        'To upgrade your plan, please contact sales@grain.com'
      }
      disabled={hasNoPrice || canEdit}
    >
      <StyledButtonContainer>
        {hasNoPrice ? (
          <PlanButton
            as={Link}
            to='https://grain.com/contact-sales'
            variant='neutral'
          >
            Request a demo
          </PlanButton>
        ) : (
          <PlanButton
            onClick={onAction}
            disabled={isButtonDisabled}
            variant={hasProminentCta && !isButtonDisabled ? 'plan' : 'neutral'}
          >
            {actionCompletedText || actionText}
          </PlanButton>
        )}
      </StyledButtonContainer>
    </Tooltip>
  );
};

const DowngradePlanButton = ({
  onAction,
  canEdit,
  disableButtonText,
  actionText
}: Pick<
  PlanButtonProps,
  'onAction' | 'canEdit' | 'actionText' | 'disableButtonText'
>) => (
  <Tooltip
    disabled={canEdit}
    content={
      disableButtonText ?? 'To change your plan, please contact sales@grain.com'
    }
  >
    <StyledButtonContainer>
      <PlanButton onClick={onAction} disabled={!canEdit} variant='neutral'>
        {actionText}
      </PlanButton>
    </StyledButtonContainer>
  </Tooltip>
);

export const PlanActionButton = ({
  isCurrentPlan,
  onAction,
  currentPlanSku,
  plan,
  canEdit,
  actionText,
  actionCompletedText,
  disableButtonText,
  planIndex,
  hasNoPrice,
  isWorkspaceOnTrial
}: PlanButtonProps) => {
  const { subscriptionPlans: plans, subscriptionPlanIndex: currentPlanIndex } =
    usePlan(currentPlanSku);
  const popularPlanIndex = plans.findIndex(plan => plan.isPopular);
  const hasProminentCta =
    currentPlanIndex > popularPlanIndex ? isCurrentPlan : plan.isPopular;

  const getButton = () => {
    if (isCurrentPlan) {
      return (
        <CurrentPlanButton
          onAction={onAction}
          canEdit={canEdit}
          actionText={actionText}
          hasProminentCta={hasProminentCta}
        />
      );
    }

    if (planIndex > currentPlanIndex || (!plan.isFree && isWorkspaceOnTrial)) {
      return (
        <UpgradePlanButton
          onAction={onAction}
          canEdit={canEdit}
          hasNoPrice={hasNoPrice}
          actionText={actionText}
          actionCompletedText={actionCompletedText}
          disableButtonText={disableButtonText}
          hasProminentCta={hasProminentCta}
        />
      );
    }

    if (planIndex < currentPlanIndex) {
      return (
        <DowngradePlanButton
          onAction={onAction}
          canEdit={canEdit}
          disableButtonText={disableButtonText}
          actionText={actionText}
        />
      );
    }

    return <StyledButtonPlaceholder />;
  };

  return <div className='plan-cta'>{getButton()}</div>;
};
