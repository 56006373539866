import React from 'react';
import { useSidebar } from '../Layout';
import { ButtonIcon, Icon } from '@grain/grain-ui';
import { css } from '@emotion/react';
import { isDesktopApp, IS_MAC } from '@grain/desktop-lib';

const SidebarMenuButton: React.FC = () => {
  const { toggleCollapsed } = useSidebar();

  return (
    <ButtonIcon
      variant='stealth'
      onClick={toggleCollapsed}
      css={css`
        margin-right: 24px !important;
        ${isDesktopApp && IS_MAC && 'margin-left: 60px !important;'}
        ${isDesktopApp && '-webkit-app-region: no-drag;'}
      `}
    >
      <Icon.Menu />
    </ButtonIcon>
  );
};

export default SidebarMenuButton;
