import styled from '@emotion/styled';
import { color, flex, spacing, media, pxToRem } from '@grain/grain-ui';
import { IVariant } from '.';

export const StyledContainer = styled.div<{
  isCurrentPlan: boolean;
  shouldHighlight: boolean;
  variant: IVariant;
  isTrial: boolean;
}>`
  ${spacing.m3}
  margin-top: 0;
  border: 1px solid ${color.gull};
  border-radius: 12px;
  flex-shrink: 0;
  overflow: hidden;
  width: 256px;
  position: relative;
  ${({ isCurrentPlan, shouldHighlight, variant, isTrial }) => {
    const vaiantStyles = {
      large: 'margin-top: 44px;',
      medium: '',
      small: 'margin-top: 44px;'
    };
    return `
    ${isCurrentPlan && variant === 'large' ? '' : vaiantStyles[variant]}
    background-color: ${isCurrentPlan && !isTrial ? color.goose : ''};
    border: 1px solid ${shouldHighlight ? color.planbird : color.gull};
  `;
  }}

  ${media.small} {
    width: 80vw;
  }

  .plan-description {
    color: ${color.raven};
    font-weight: 400;
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(18)};
    ${spacing.mt1}
  }

  .plan-allocation {
    ${spacing.mb4};
    font-size: calc(14rem / 16);
    text-align: center;
  }

  .bullet-header {
    font-weight: 700;
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(20)};
    color: ${color.blackbird};
    ${spacing.mb3};
  }
`;

export const StyledYourSeatHeader = styled.div<{ variant?: IVariant }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  color: ${color.blackbird};
  padding: 12px 25px;
  text-align: left;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(18)};
  font-weight: 600;
  height: 44px;
  ${props => {
    if (props.variant !== 'large') {
      return `
        border-radius: 0px 0px 16px 16px;
        border-top: 1px solid ${color.gull};
        position: absolute;
        bottom: 0;
        width: 100%;
      `;
    }
    return `
      border-radius: 16px 16px 0px 0px;
      border-bottom: 1px solid ${color.gull};
    `;
  }}
`;

export const StyledPlanName = styled.div`
  display: flex;
  height: 20px;
  ${flex.direction.row};
  ${flex.justifyContent.spaceBetween};
  font-size: ${pxToRem(18)};
  color: #444;
  font-weight: 600;
  align-items: center;
`;

export const StyledMinimumSeat = styled.div`
  ${spacing.ml2};
  line-height: ${pxToRem(16)};
  font-size: ${pxToRem(12)};
  font-weight: 400;
  color: ${color.crow};
`;

export const StyledPlanHeader = styled.div<{
  background?: string;
  foreground?: string;
}>`
  ${spacing.p6};
  ${spacing.pb0};
  background: ${props => props.background};
  color: ${props => props.foreground};
  user-select: none;
`;

export const StyledDescription = styled.div`
  ${flex.direction.column};
  ${spacing.g6};
  ${spacing.p6};
  position: relative;
  padding-top: 0;
`;

export const StyledBullet = styled.div`
  ${spacing.mb2};
  align-items: flex-start;
  display: flex;
  font-size: 0.875rem;
  line-height: ${pxToRem(16)};
`;

export const StyledPriceContainer = styled.div`
  ${spacing.mt4};
  display: flex;
  flex-direction: column;
  ${spacing.mb6};
`;

export const StyledAnnualRate = styled.div`
  font-weight: 600;
  font-size: ${pxToRem(32)};
  line-height: ${pxToRem(30)};
  color: ${color.blackbird};
`;

export const StyledMonthlyRate = styled.div`
  font-size: ${pxToRem(12)};
  font-weight: 400;
  color: ${color.crow};
  ${spacing.mt2};
  span {
    font-weight: 700;
  }
`;

export const StyledPlanLabel = styled.div`
  width: 100%;
  white-space: nowrap;
  color: ${color.blackbird};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color.swan};
  border-radius: 100px;
  border: 1px solid ${color.gull};
  font-weight: 500;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(20)};
  height: 48px;

  &[disabled] {
    cursor: not-allowed;
  }
`;

export const StyledLimitationsContainer = styled.div`
  min-height: 80px;
`;

export const StyledButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledPlanItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  ${spacing.ml2};

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    .tippy-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  svg {
    width: 13px;
    height: 13px;
    color: ${color.pigeon};
  }
`;

export const StyledCornerPill = styled.div`
  border-radius: 0px 0px 0px 8px;
  border-bottom: 1px solid ${color.planbird};
  border-left: 1px solid ${color.planbird};
  color: ${color.planbird};
  position: absolute;
  top: 0;
  right: 0;
  width: fit-content;
  padding: 4px 10px;
  font-size: ${pxToRem(12)};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButtonPlaceholder = styled.div`
  height: 48px;
`;
