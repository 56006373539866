// @ts-strict-ignore
import React, { useState } from 'react';
import { NoteTemplate } from './NoteTemplate';
import { NoteTemplatesList } from './NoteTemplatesList';
import { useSearchParams } from 'react-router-dom';
import { useNotesTemplates } from '~/modules/templates/hooks';

export const NoteTemplates = () => {
  const templates = useNotesTemplates();

  const [isNewTemplate, setIsNewTemplate] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleAdd = () => {
    setIsNewTemplate(true);
  };

  const handleEdit = (id: string) => {
    setSearchParams({ tab: searchParams.get('tab'), templateId: id });
  };

  const handleExit = () => {
    setSearchParams({ tab: searchParams.get('tab') });
    setIsNewTemplate(false);
  };

  const showTemplateEditor = searchParams.get('templateId') || isNewTemplate;

  if (!templates?.length) return null;

  return (
    <>
      {showTemplateEditor ? (
        <NoteTemplate onExit={handleExit} />
      ) : (
        <NoteTemplatesList
          templates={templates}
          onEdit={handleEdit}
          onAdd={handleAdd}
        />
      )}
    </>
  );
};
