import { useMemo } from 'react';
import {
  useWorkspace,
  useMyself,
  useFeature,
  useExperiment
} from '@grain/api/auth';
import { useMutation } from '@grain/api/graphql';
import { Icon, Button, useAnalytics } from '@grain/grain-ui';
import { Icon16, Tooltip } from '@grain/grain-ui/v4';
import { getBillingDisplayDate } from '../../modules/subscriptions/helpers';
import { setSubscriptionAutoRenewMutation } from '../../modules/subscriptions/graphql';
import { CancelStepsProps } from './CancelTypes';
import {
  StyledContent,
  ProgressBarContainer,
  StyledProgressBar,
  StyledTitleContainer,
  InfoBoxConfirmCancellationWrapper,
  ConfirmCancellationList,
  StyledInfoBox,
  StyledTooltip,
  StyledTooltipText,
  StyledButtons,
  PageWrapper
} from './styles';
import { workspaceMembersQuery } from '@grain/api/graphql/queries';
import { PlanFeaturesDocument } from '@grain/api/graphql/queries/planFeatures.generated';

const commaSeparated = (list: string[]) =>
  list.slice(0, list.length - 1).join(', ') +
  (list.length > 2 ? ', ' : '') +
  (list.length > 1 ? ` and ${list[list.length - 1]}` : '');

const RECORDING_LIMIT = 20;
const INTEGRATIONS_INACCESSIBLE_RESTRICTION =
  'Some integrations will be inaccessible.';

export const ScheduleCancellation = ({
  step = 3,
  totalSteps = 3,
  nextStep,
  prevStep,
  closeModal
}: CancelStepsProps) => {
  const { workspace } = useWorkspace();
  const { myself } = useMyself();
  const { trackEvent } = useAnalytics();
  const { enabled: isFreemiumEnabled } = useExperiment('freemium');

  const [setAutoRenew, { loading }] = useMutation(
    setSubscriptionAutoRenewMutation,
    {
      onCompleted() {
        trackEvent('Workspace Subscription Cancelled', {});
        nextStep?.();
      }
    }
  );

  // Infer all features that will be lost due to cancelling according to what's
  // currently enabled, which this logic understands is all paid-plan-only.
  // (Don't SKU-sniff.)

  const { enabled: coachingEnabled } = useFeature('sales_coaching');
  const { enabled: insightsEnabled } = useFeature('insights');
  const { enabled: uploadsEnabled } = useFeature('upload_recording');
  const { enabled: customAiNotesEnabled } = useFeature('custom_ai_notes');
  const { enabled: hubspotEnabled } = useFeature('hubspot_integration');
  const { enabled: salesforceEnabled } = useFeature('salesforce_integration');
  const { enabled: aircallEnabled } = useFeature('aircall_integration');
  const { enabled: productboardEnabled } = useFeature(
    'productboard_integration'
  );
  const { enabled: zapierEnabled } = useFeature('zapier_integration');

  const businessFeatures = useMemo(
    () => [
      ...(coachingEnabled ? ['Coaching'] : []),
      ...(insightsEnabled ? ['Metrics', 'Insights'] : [])
    ],
    [coachingEnabled, insightsEnabled]
  );

  const meetingFeatures = useMemo(
    () => [
      // FIXME: there is no separate feature for imports yet, so for now imply
      // imports if there are uploads.
      ...(uploadsEnabled ? ['uploads', 'imports'] : [])
    ],
    [uploadsEnabled]
  );

  const integrations = useMemo(
    () => [
      ...(hubspotEnabled ? ['HubSpot'] : []),
      ...(salesforceEnabled ? ['Salesforce'] : []),
      ...(aircallEnabled ? ['Aircall'] : []),
      ...(productboardEnabled ? ['Productboard'] : []),
      ...(zapierEnabled ? ['Zapier'] : [])
    ],
    [
      hubspotEnabled,
      salesforceEnabled,
      aircallEnabled,
      productboardEnabled,
      zapierEnabled
    ]
  );

  const restrictions = useMemo(
    () => [
      'Only 1 Free Notetaker seat.',
      ...(businessFeatures.length
        ? [`${commaSeparated(businessFeatures)} will be inaccessible.`]
        : []),
      ...(meetingFeatures.length
        ? [`Meeting ${commaSeparated(meetingFeatures)} will be inaccessible.`]
        : []),
      ...(customAiNotesEnabled
        ? ['Custom note templates will be inaccessible.']
        : []),
      ...(integrations.length ? [INTEGRATIONS_INACCESSIBLE_RESTRICTION] : [])
    ],
    [businessFeatures, meetingFeatures, customAiNotesEnabled, integrations]
  );

  return (
    <PageWrapper>
      <StyledContent>
        <ProgressBarContainer>
          <StyledProgressBar
            type='progress'
            progressTheme='highlightGreenDark'
            size='medium'
            percent={(step / totalSteps) * 100}
          />
          <span>
            {' '}
            {step} of {totalSteps}
          </span>
          <span onClick={closeModal}>
            {' '}
            <Icon.Close />{' '}
          </span>
        </ProgressBarContainer>
        <StyledTitleContainer>
          <h1>Confirm cancellation</h1>
          <p>
            Thanks for the feedback! Enjoy your current paid features until the
            end of your billing period on{' '}
            {getBillingDisplayDate(
              workspace?.workspaceSubscription?.renewalDate ?? ''
            )}
            . You can resubscribe or switch to another plan anytime.
          </p>
        </StyledTitleContainer>
        <InfoBoxConfirmCancellationWrapper>
          <h6>The following restrictions will apply when your plan cancels:</h6>
          {isFreemiumEnabled ? (
            <ConfirmCancellationList>
              {restrictions.map(restriction => (
                <li key={restriction}>
                  <span>
                    <span>{restriction}</span>
                    {restriction === INTEGRATIONS_INACCESSIBLE_RESTRICTION ? (
                      <Tooltip
                        content={commaSeparated(integrations)}
                        maxWidth={200}
                        tippyProps={{
                          placement: 'bottom'
                        }}
                      >
                        <Icon16.InfoCircle />
                      </Tooltip>
                    ) : null}
                  </span>
                </li>
              ))}
            </ConfirmCancellationList>
          ) : (
            <StyledInfoBox>
              <Icon.Record style={{ width: '20px', height: '20px' }} />
              <span>Meetings:</span>
              <span className='strike-through'>Unlimited</span>
              <Icon.ArrowRight style={{ width: '12px', height: '12px' }} />
              <span className='marked-red'>{RECORDING_LIMIT} </span>
              <span>per user </span>
              <StyledTooltip
                content={
                  <StyledTooltipText>
                    <span>
                      {myself?.user?.recordingCount &&
                      typeof myself?.user?.recordingCount === 'number' &&
                      myself?.user?.recordingCount > RECORDING_LIMIT
                        ? `${
                            myself?.user?.recordingCount - RECORDING_LIMIT
                          } meetings will be deleted after 60 days`
                        : `Meetings past ${RECORDING_LIMIT} will be deleted`}
                    </span>
                  </StyledTooltipText>
                }
                placement='bottom'
              >
                <Icon.Information style={{ width: '16px', height: '16px' }} />
              </StyledTooltip>
            </StyledInfoBox>
          )}
        </InfoBoxConfirmCancellationWrapper>
      </StyledContent>
      <StyledButtons>
        <Button variant='secondary' onClick={prevStep}>
          Back
        </Button>
        <Button
          variant='danger'
          onClick={() => {
            setAutoRenew({
              variables: {
                shouldAutoRenew: false
              },
              refetchQueries: [
                {
                  query: workspaceMembersQuery,
                  fetchPolicy: 'network-only'
                },
                {
                  query: PlanFeaturesDocument,
                  fetchPolicy: 'network-only'
                }
              ]
            });
          }}
          spinning={loading}
        >
          Schedule cancellation
        </Button>
      </StyledButtons>
    </PageWrapper>
  );
};
