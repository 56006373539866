import styled from '@emotion/styled';
import { Button } from '@grain/grain-ui';
import { color, font } from 'base/css';
import { theme } from 'ui/v4/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  border-bottom: 1px solid ${color.gull};

  &:last-child {
    border-bottom: none;
  }
`;

export const SectionTitle = styled.h5`
  ${font.v4.h5}
`;

export const StyledSectionContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
  align-items: center;
  border-bottom: 1px solid ${color.gull};

  &:last-child {
    border-bottom: none;
  }

  ${Button} {
    flex: 0;
  }
`;

export const SectionContentRowTitle = styled.span`
  ${font.v4.b2['500']}
  width: 188px;
`;

export const SectionContentRowDetails = styled.span`
  ${font.v4.b2['400']};
  flex: 1;
  padding-right: 24px;
`;

export const MoreBillingInfo = styled.div`
  ${font.v4.b2['400']};
`;

export const CancelPlan = styled.a`
  color: ${color.errorbird};
  margin-left: 10px;
  cursor: pointer;
`;

export const StyledTable = styled.table`
  text-align: left;
  border-collapse: collapse;
  table-layout: fixed;
`;

export const StyledTableHeader = styled.th`
  ${font.v4.b2['600']};
  padding: 17px 12px 17px 0;

  &:not(:first-of-type) {
    padding: 17px 12px;
  }
  &:last-child {
    text-align: right;
  }
`;

export const StyledTableHeaderRow = styled.tr`
  border-bottom: 1px solid ${color.gull};
`;

export const StyledTableCell = styled.td`
  ${font.v4.b3['400']};
  color: ${color.crow};
  padding: 17px 12px 17px 0;

  &:not(:first-of-type) {
    padding: 17px 12px;
    ${font.v4.c1['500']};
  }

  &:last-child {
    text-align: right;
  }
`;

export const StyledTableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${color.gull};

  &:last-child {
    border-bottom: none;
  }
`;

export const InvoiceLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  ${font.v4.c1['500']};
  color: ${color.crow};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  svg {
    width: 14px;
  }
`;

export const StyledInvoiceNotification = styled.div`
  ${font.v4.b2['500']};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SupportContainer = styled.div`
  ${theme.tokens.typography.b2[400]};
  color: ${theme.tokens.color.textSecondary};

  a {
    color: ${theme.tokens.color.textSecondary};
    text-decoration: underline;
    cursor: pointer;
  }
`;
