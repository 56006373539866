import { InsightQueries, SmartTopic } from '@grain/api/schema.generated';
import { FilterNavigation } from './SmartTags';
import styled from '@emotion/styled';
import {
  Avatar,
  Icon,
  Spinner,
  color,
  flex,
  getSpeakerColor,
  spacing
} from '@grain/grain-ui';

type SmartTagsDetailProps = {
  activeSmartTag: SmartTopic;
  onNavigate: (nav: FilterNavigation) => void;
  trackerInsightDetail: InsightQueries;
  trackerDetailLoading: boolean;
};

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -187px;
  height: 50vh;
  pointer-events: none;
  ${spacing.g2};
  ${color.fg.graieen};
`;

export const SmartTagsDetail = ({
  activeSmartTag,
  onNavigate,
  trackerInsightDetail,
  trackerDetailLoading
}: SmartTagsDetailProps) => {
  return trackerDetailLoading ? (
    <LoadingContainer>
      <Spinner size={20} />
    </LoadingContainer>
  ) : (
    <div
      style={{
        maxHeight: 'calc(100vh - 355px)',
        overflow: 'auto'
      }}
    >
      {trackerInsightDetail.trackerInsightDetails?.usersStatistics.map(
        stats => {
          const isNAUser = stats.totalRecordingCount === 0;
          const percent = Math.round(
            (stats.hitCount / stats.totalRecordingCount) * 100
          );

          return (
            <div
              onClick={() => {
                if (!isNAUser) {
                  onNavigate({
                    tag: {
                      id: activeSmartTag.tag.id,
                      name: activeSmartTag.tag.text
                    },
                    owner: {
                      id: stats.user.id,
                      name: stats.user.name
                    }
                  });
                }
              }}
              className={`grid-row ${isNAUser ? 'na-user' : ''}`}
              key={stats.user.id}
            >
              <Avatar
                defaultAvatarColor={getSpeakerColor(stats.user.name)}
                avatarSize='small'
                name={stats.user.name}
                avatarUrl={stats.user?.avatarUrl ?? false}
              />
              <div>{stats.user.name}</div>
              <div>
                {!isNAUser
                  ? `${stats.hitCount} of ${stats.totalRecordingCount}`
                  : 'NA'}
              </div>
              <div css={[flex.alignItems.center, 'gap: 8px;']}>
                <div
                  className='bar'
                  style={{
                    width: !isNAUser
                      ? `max(${percent < 20 ? percent : 20}px, calc(${percent}% - 56px))`
                      : '0px'
                  }}
                />
                {!isNAUser && percent.toString() + '%'}
              </div>
              <div className='drill-down'>
                {!isNAUser && <Icon.ChevronRight />}
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};
