import { useStoryQuery } from '~/modules/stories/index.generated';
import ShareStoryPermissions from '~/modals/SharePermissions/ShareStory';

type Props = {
  storyId: string;
  isShareOpen: boolean;
};

export const StoriesShareModal = ({ storyId, isShareOpen }: Props) => {
  const { data: storyData } = useStoryQuery({
    variables: { storyId },
    fetchPolicy: 'cache-first',
    skip: Boolean(!storyId || !isShareOpen)
  });
  const story = storyData?.story;

  if (!story || !isShareOpen) return null;

  return <ShareStoryPermissions story={story} />;
};
