import styled from '@emotion/styled';
import { flex, pxToRem, color, font } from '@grain/grain-ui';
import { spacing } from '@grain/styles/constants';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledModalTitle = styled.h5`
  ${spacing.m0};
  ${spacing.pb2};
  ${flex.justifyContent.center};
  color: ${color.blackbird};
  ${font.v4.h5};
`;

export const StyledDescription = styled.div`
  ${font.v4.b1['400']};
  color: ${color.crow};
`;

export const StyledPlanDetails = styled.div`
  display: flex;
  flex-direction: column;
  ${spacing.my6};
  ${spacing.p6};
  gap: ${pxToRem(16)};
  background-color: ${color.goose};
  border: 1px solid ${color.gull};
  border-radius: ${pxToRem(12)};
`;

export const StyledPlanList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${pxToRem(8)};
`;

export const StyledBullet = styled.div`
  display: flex;
  flex: 1 1 45%;
  align-items: center;
  gap: ${pxToRem(8)};
  ${spacing.px0}
  ${spacing.pt2};
  ${font.v4.b2['400']};

  svg {
    width: ${pxToRem(14)};
    height: ${pxToRem(14)};
  }
`;
