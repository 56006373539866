import * as Types from '@grain/api/schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@grain/api/graphql';
const defaultOptions = {} as const;
export type WorkspaceSharedAutomationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type WorkspaceSharedAutomationsQuery = { __typename?: 'RootQueryType', hubspot: { __typename?: 'AircallIntegration' } | { __typename?: 'HubspotIntegration', automationEnabled: boolean } | { __typename?: 'ProductboardIntegration' } | { __typename?: 'SalesforceIntegration' } | { __typename?: 'SlackIntegration' } | { __typename?: 'SlackUser' } | { __typename?: 'TeamsIntegration' } | { __typename?: 'ZapierIntegration' } | { __typename?: 'ZoomIntegration' } | null, slack: { __typename?: 'AircallIntegration' } | { __typename?: 'HubspotIntegration' } | { __typename?: 'ProductboardIntegration' } | { __typename?: 'SalesforceIntegration' } | { __typename?: 'SlackIntegration', automations: Array<{ __typename?: 'SlackAutomation', id: string }> } | { __typename?: 'SlackUser' } | { __typename?: 'TeamsIntegration' } | { __typename?: 'ZapierIntegration' } | { __typename?: 'ZoomIntegration' } | null };


export const WorkspaceSharedAutomationsDocument = gql`
    query workspaceSharedAutomations {
  hubspot: integration(type: HUBSPOT_INTEGRATION) {
    ... on HubspotIntegration {
      automationEnabled
    }
  }
  slack: integration(type: SLACK_INTEGRATION) {
    ... on SlackIntegration {
      automations {
        id
      }
    }
  }
}
    `;

/**
 * __useWorkspaceSharedAutomationsQuery__
 *
 * To run a query within a React component, call `useWorkspaceSharedAutomationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceSharedAutomationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceSharedAutomationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceSharedAutomationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WorkspaceSharedAutomationsQuery, WorkspaceSharedAutomationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<WorkspaceSharedAutomationsQuery, WorkspaceSharedAutomationsQueryVariables>(WorkspaceSharedAutomationsDocument, options);
      }
export function useWorkspaceSharedAutomationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WorkspaceSharedAutomationsQuery, WorkspaceSharedAutomationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<WorkspaceSharedAutomationsQuery, WorkspaceSharedAutomationsQueryVariables>(WorkspaceSharedAutomationsDocument, options);
        }
export function useWorkspaceSharedAutomationsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<WorkspaceSharedAutomationsQuery, WorkspaceSharedAutomationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<WorkspaceSharedAutomationsQuery, WorkspaceSharedAutomationsQueryVariables>(WorkspaceSharedAutomationsDocument, options);
        }
export type WorkspaceSharedAutomationsQueryHookResult = ReturnType<typeof useWorkspaceSharedAutomationsQuery>;
export type WorkspaceSharedAutomationsLazyQueryHookResult = ReturnType<typeof useWorkspaceSharedAutomationsLazyQuery>;
export type WorkspaceSharedAutomationsSuspenseQueryHookResult = ReturnType<typeof useWorkspaceSharedAutomationsSuspenseQuery>;
export type WorkspaceSharedAutomationsQueryResult = Apollo.QueryResult<WorkspaceSharedAutomationsQuery, WorkspaceSharedAutomationsQueryVariables>;