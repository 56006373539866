import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useCompanyContextUpdateMutation } from '@grain/api/graphql/mutations/workspace.generated';
import { useWorkspaceInfoQuery } from '@grain/api/graphql/queries';
import { GrowingInput, Modal, ModalTitle, useShowToast } from '@grain/grain-ui';
import {
  Input,
  theme,
  TextArea,
  Icon16,
  Button,
  Chip
} from '@grain/grain-ui/v4';
import { StyledGrowingInputContainer } from 'components/atoms/GrowingInput/GrowingInput';
import { SetStateAction, useCallback, useId, useRef, useState } from 'react';
import { z } from 'zod';

type CompanyContextModalProps = {
  onCancel: () => void;
};

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing.sm};
`;

const FormLabel = styled.label`
  ${theme.tokens.typography.b3[600]};
  color: ${theme.tokens.color.textPrimary};
`;

const formSchema = z
  .object({
    companyName: z.string(),
    companyContext: z.string(),
    productDescription: z.string(),
    competitors: z.array(z.string()),
    competitorInputValue: z.string()
  })
  .refine(data => {
    // This requires companyName, companyContext, or productDescription to be
    // filled out, or at least one competitor to be added
    return (
      data.competitors.length > 0 ||
      [data.companyName, data.companyContext, data.productDescription].some(
        Boolean
      )
    );
  });

type FormSchema = z.infer<typeof formSchema>;

export default function CompanyContextModal({
  onCancel
}: CompanyContextModalProps) {
  const showToast = useShowToast();
  const [updateCompanyContext] = useCompanyContextUpdateMutation();
  const growingInputRef = useRef<HTMLInputElement>(null);
  const companyNameId = useId();
  const companyContextId = useId();
  const productDescriptionId = useId();
  const [formData, setForm] = useState<FormSchema>({
    companyName: '',
    companyContext: '',
    productDescription: '',
    competitors: [],
    competitorInputValue: ''
  });

  const { data } = useWorkspaceInfoQuery({
    onCompleted(data) {
      setForm(prev => ({
        ...prev,
        companyName: data.workspace.workspaceGroup.name || '',
        companyContext: data.workspace.organizationContext || '',
        productDescription: data.workspace.productContext || '',
        competitors: data.workspace.competitorContext || []
      }));
    }
  });

  const {
    companyName,
    companyContext,
    productDescription,
    competitors,
    competitorInputValue
  } = formData;
  const updateFormField = useCallback(
    <T extends keyof FormSchema>(
      field: T,
      value: SetStateAction<FormSchema[T]>
    ) => {
      setForm(prev => ({
        ...prev,
        [field]: typeof value === 'function' ? value(prev[field]) : value
      }));
    },
    [setForm]
  );

  const handleAddCompetitor = () => {
    setForm(prev => {
      const growingInput = growingInputRef.current;
      const newCompetitors = Array.from(
        new Set([...prev.competitors, prev.competitorInputValue])
      );

      if (growingInput) {
        growingInput.value = '';
      }

      return {
        ...prev,
        competitors: newCompetitors,
        competitorInputValue: ''
      };
    });
  };

  const { success: isValidForm } = formSchema.safeParse(formData);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isValidForm) {
      updateCompanyContext({
        variables: {
          id: data?.workspace.workspaceGroup.id || '',
          name: companyName,
          competitorContext: competitors,
          organizationContext: companyContext || null,
          productContext: productDescription || null
        },
        onCompleted() {
          showToast({ type: 'success', content: 'Company context updated' });
        }
      });
    }
  };

  return (
    <Modal
      onCancel={onCancel}
      closable
      css={css`
        max-width: 516px;
      `}
    >
      <ModalTitle centered title='Company context' />
      <form
        onSubmit={handleSubmit}
        css={css`
          overflow-y: auto;
        `}
      >
        <div
          css={css`
            ${theme.utils.padding('3xl')};
            display: flex;
            flex-direction: column;
            gap: ${theme.tokens.spacing.lg};
          `}
        >
          <FormGroup>
            <FormLabel htmlFor={companyNameId}>Company name</FormLabel>
            <Input
              id={companyNameId}
              placeholder='Company name'
              size='lg'
              value={companyName}
              onChange={e => {
                updateFormField('companyName', e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor={companyContextId}>Company context</FormLabel>
            <TextArea
              id={companyContextId}
              value={companyContext}
              onChange={e => {
                updateFormField('companyContext', e.target.value);
              }}
              placeholder='A summary of your company’s size, industry, geographic location, and any other important details'
            />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor={productDescriptionId}>
              Product description
            </FormLabel>
            <TextArea
              id={productDescriptionId}
              placeholder='A description of the products or services your company sells, including their names, features and their target customer'
              value={productDescription}
              onChange={e => {
                updateFormField('productDescription', e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Competitor names</FormLabel>
            <div
              css={css`
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                ${theme.utils.gap('xs')}
              `}
            >
              {competitors.map(competitor => (
                <Chip
                  key={competitor}
                  variant='stroked'
                  size='sm'
                  title={competitor}
                  onClose={() => {
                    updateFormField('competitors', prevCompetitors =>
                      prevCompetitors.filter(c => c !== competitor)
                    );
                  }}
                />
              ))}
              <GrowingInput
                placeholder='Add competitor'
                placeholderIcon={
                  <Icon16.Plus
                    css={css`
                      /* Override SVG size/color for Icon */
                      svg& {
                        color: ${theme.tokens.color.iconPrimary};
                        width: 16px;
                        height: 16px;
                      }
                    `}
                  />
                }
                css={css`
                  color: ${theme.tokens.color.textPrimary};

                  ::placeholder {
                    color: ${theme.tokens.color.textPrimary};
                  }
                `}
                containerProps={{
                  css: css`
                    margin: 0;
                    align-self: flex-start;

                    & ${StyledGrowingInputContainer} {
                      ${theme.utils.gap('xs')}
                    }
                  `
                }}
                ref={growingInputRef}
                onChange={e => {
                  updateFormField('competitorInputValue', e.target.value);
                }}
                onKeyDown={e => {
                  if (e.code === 'Enter' && competitorInputValue !== '') {
                    e.preventDefault();
                    handleAddCompetitor();
                  }
                }}
                onBlur={() => {
                  if (competitorInputValue !== '') {
                    handleAddCompetitor();
                  }
                }}
              />
            </div>
          </FormGroup>
        </div>
        <div
          css={css`
            ${theme.utils.padding('2xl')};
            display: flex;
            justify-content: center;
          `}
        >
          <Button
            type='submit'
            size='lg'
            disabled={!isValidForm}
            variant='primary'
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
}
