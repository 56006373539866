import { css } from '@emotion/react';
import {
  COLLECTIONS_DEFAULT_TITLE,
  Icon,
  useAnalytics,
  useShowToast
} from '@grain/grain-ui';
import { ActionBar, Input, Menu, MenuButton } from '@grain/grain-ui/v4';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useCollectionCreateMutation,
  useCollectionRecordingsAddMutation
} from '~/modules/collections/collections.generated';
import {
  CollectionFragmentFragment,
  useViewAdHocQuery
} from '~/modules/contentFilter/contentfilter.generated';
import { LabelWithValue } from '~/modules/filtersV2/types';

type PlaylistButtonProps = {
  selectedMeetingIds: string[];
  reset: () => void;
};

export const PlaylistButton = ({
  selectedMeetingIds,
  reset
}: PlaylistButtonProps) => {
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const showToast = useShowToast();
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<LabelWithValue[]>([]);

  const [addRecordingsToPlaylist] = useCollectionRecordingsAddMutation({
    onCompleted: () => {
      trackEvent(
        'Add to Collection Clicked',
        {
          button_name: 'meeting_library_multi_select',
          trigger: 'meeting_library_action_bar'
        },
        ['user', 'workspace']
      );
      showToast({
        type: 'success',
        content: 'Meetings added to Playlist'
      });
      reset();
    }
  });
  const [createCollectionForRecordings] = useCollectionCreateMutation({
    variables: {
      title: COLLECTIONS_DEFAULT_TITLE
    },
    onCompleted: async data => {
      await addRecordingsToPlaylist({
        variables: {
          recordingIds: selectedMeetingIds,
          collectionId: data.collectionCreate.id
        }
      });
      navigate(data.collectionCreate.collectionPath);
    }
  });

  const filteredOptions = useMemo(() => {
    const cleanSearch = searchValue.trim().toLowerCase();
    return options.filter(opt => opt.label.toLowerCase().includes(cleanSearch));
  }, [searchValue, options]);

  useViewAdHocQuery({
    variables: {
      filter: JSON.stringify({
        types: ['collections'],
        filters: []
      })
    },
    onCompleted: data => {
      const collections = data.viewAdHoc.list;

      const options = collections
        .filter(
          (collection): collection is CollectionFragmentFragment =>
            collection.__typename === 'Collection'
        )
        .map(collection => {
          return {
            label: collection.title,
            value: collection.id
          };
        });

      setOptions(options);
    }
  });

  return (
    <Menu
      width='200px'
      tippyProps={{
        placement: 'top',
        onHide: () => setSearchValue('')
      }}
      hideOnClickInside
      content={
        <>
          <Input
            css={css`
              margin-bottom: 4px;
              flex-shrink: 0;
            `}
            onClick={e => e.stopPropagation()}
            placeholder='Search Playlists'
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
          <MenuButton
            textLabelProps={{ startIcon: Icon.Plus }}
            label='New playlist'
            onClick={() => createCollectionForRecordings()}
          />
          {filteredOptions.map(option => (
            <MenuButton
              key={option.value}
              label={option.label}
              onClick={() => {
                addRecordingsToPlaylist({
                  variables: {
                    recordingIds: selectedMeetingIds,
                    collectionId: option.value
                  }
                });
              }}
            />
          ))}
        </>
      }
    >
      <ActionBar.Button
        disabled={selectedMeetingIds.length === 0}
        icon={Icon.Playlists}
        onClick={() => {}}
      />
    </Menu>
  );
};
