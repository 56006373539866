import styled from '@emotion/styled';
import { Icon16, theme, Tooltip } from '@grain/grain-ui/v4';

import { ExampleTimeline } from './ExampleTimeline';

const StyledExplanation = styled.div`
  display: flex;
  gap: ${theme.tokens.spacing.sm};
`;

const StyledTopBottomRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing['3xs']};
`;

const StyledTopBottomRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.tokens.spacing.xs};
  ${theme.tokens.typography.b4[400]};
`;

type TimelineExplanationTooltipProps = React.ComponentProps<typeof Tooltip>;

export function TimelineExplanationTooltip({
  children,
  ...tooltipProps
}: TimelineExplanationTooltipProps) {
  return (
    <Tooltip
      content={
        // No rhyme or reason to all the different shades of gray being used
        // here.  I even asked for confirmation on them from Design.
        <StyledExplanation>
          <ExampleTimeline />
          <StyledTopBottomRows>
            <StyledTopBottomRow>
              <Icon16.ArrowUp
                css={[`color: ${theme.tokens.color.textPrimary};`]}
              />
              <span css={[`color: ${theme.tokens.color.textPrimary};`]}>
                Team activity
              </span>
            </StyledTopBottomRow>
            <StyledTopBottomRow>
              <Icon16.ArrowDown
                css={[`color: ${theme.tokens.color.textSecondary};`]}
              />
              <span css={[`color: ${theme.tokens.color.textSecondary};`]}>
                Customer activity
              </span>
            </StyledTopBottomRow>
          </StyledTopBottomRows>
        </StyledExplanation>
      }
      tippyProps={{
        ...tooltipProps,
        placement: 'bottom',
        offset: [0, 4]
      }}
    >
      {children}
    </Tooltip>
  );
}
