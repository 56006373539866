import * as Types from '@grain/api/schema.generated';

import { gql } from '@apollo/client';
import { RecordingListItemFragmentFragmentDoc } from '../../../../grain-api/graphql/fragments/recording.generated';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@grain/api/graphql';
const defaultOptions = {} as const;
export type RecordingUpdatedSubscriptionVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type RecordingUpdatedSubscription = { __typename?: 'RootSubscriptionType', recordingUpdated: { __typename: 'Recording', id: string, title: string, startDatetime: string, endDatetime: string | null, duration: number | null, recordingUrl: string, recordingPath: string, fullJpegThumbnailUrl: string | null, fullWebpThumbnailUrl: string | null, animatedPreviewUrl: string | null, canDelete: boolean, state: Types.RecordingState, locked: boolean, clipCount: number, storyCount: number, source: Types.RecordingSource, sharedUsersCount: number, workspaceShared: boolean, shareState: Types.RecordingShareState, updatedAt: string, processingStatus: { __typename?: 'RecordingProcessingJob', progress: number, status: Types.RecordingProcessingStatus }, owner: { __typename?: 'User', id: string, name: string, isSelf: boolean } } | null };


export const RecordingUpdatedDocument = gql`
    subscription recordingUpdated($id: ID!) {
  recordingUpdated(id: $id) {
    ...recordingListItemFragment
  }
}
    ${RecordingListItemFragmentFragmentDoc}`;

/**
 * __useRecordingUpdatedSubscription__
 *
 * To run a query within a React component, call `useRecordingUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRecordingUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordingUpdatedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecordingUpdatedSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<RecordingUpdatedSubscription, RecordingUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<RecordingUpdatedSubscription, RecordingUpdatedSubscriptionVariables>(RecordingUpdatedDocument, options);
      }
export type RecordingUpdatedSubscriptionHookResult = ReturnType<typeof useRecordingUpdatedSubscription>;
export type RecordingUpdatedSubscriptionResult = Apollo.SubscriptionResult<RecordingUpdatedSubscription>;