import { LinkButton, ImageEmptyView, getBasePublicPath } from '@grain/grain-ui';
import { Button, Icon16 } from '@grain/grain-ui/v4';

import { ReactNode } from 'react';
import { StoriesTabId } from '../types';

type EmptyViewProps = {
  tabId: StoriesTabId;
  onClickNewStory: React.MouseEventHandler;
};

const titles: Record<StoriesTabId, string> = {
  clips: "You don't have any clips yet.",
  stories: 'Create your first story'
};

const descriptions: Record<StoriesTabId, ReactNode> = {
  clips:
    'Clips are segments of meetings that you can share anywhere. To get started, select a recording of a meeting',
  stories:
    'Stories are collections of Clips you can stitch together to summarize a meeting, compile feedback, tell a narrative, and more.'
};

const images: Record<StoriesTabId, string> = {
  clips: '/images/zero-states/highlights.png',
  stories: '/images/zero-states/stories.png'
};

type CTAProps = {
  onClickButton: React.MouseEventHandler;
};
const ctas = {
  clips: (_: CTAProps) => (
    <LinkButton variant='secondary' to='/app/meetings' size='large'>
      View meetings
    </LinkButton>
  ),
  stories: ({ onClickButton: onClickNewStory }: CTAProps) => (
    <Button
      variant='primary'
      onClick={onClickNewStory}
      size='xl'
      textLabelProps={{
        startIcon: <Icon16.Plus />
      }}
    >
      New story
    </Button>
  )
};
export const EmptyStoriesState = ({
  tabId,
  onClickNewStory
}: EmptyViewProps) => {
  const title = titles[tabId];
  const description = descriptions[tabId];
  const Cta = ctas[tabId];

  return (
    <ImageEmptyView.Wrapper>
      <ImageEmptyView.Image src={`${getBasePublicPath()}${images[tabId]}`} />
      <ImageEmptyView.InnerWrapper>
        <ImageEmptyView.Title>{title}</ImageEmptyView.Title>
        <ImageEmptyView.Description>{description}</ImageEmptyView.Description>
        <ImageEmptyView.Action>
          <Cta onClickButton={onClickNewStory} />
        </ImageEmptyView.Action>
      </ImageEmptyView.InnerWrapper>
    </ImageEmptyView.Wrapper>
  );
};
