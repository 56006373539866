import * as Types from '@grain/api/schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@grain/api/graphql';
const defaultOptions = {} as const;
export type WorkspaceInvoicesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type WorkspaceInvoicesQuery = { __typename?: 'RootQueryType', workspace: { __typename?: 'Workspace', id: string, workspaceSubscription: { __typename?: 'WorkspaceSubscription', invoices: Array<{ __typename?: 'Invoice', amount: number, date: string, status: string, invoicePageUrl: string }> } } };


export const WorkspaceInvoicesDocument = gql`
    query workspaceInvoices {
  workspace {
    id
    workspaceSubscription {
      invoices {
        amount
        date
        status
        invoicePageUrl
      }
    }
  }
}
    `;

/**
 * __useWorkspaceInvoicesQuery__
 *
 * To run a query within a React component, call `useWorkspaceInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceInvoicesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WorkspaceInvoicesQuery, WorkspaceInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<WorkspaceInvoicesQuery, WorkspaceInvoicesQueryVariables>(WorkspaceInvoicesDocument, options);
      }
export function useWorkspaceInvoicesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WorkspaceInvoicesQuery, WorkspaceInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<WorkspaceInvoicesQuery, WorkspaceInvoicesQueryVariables>(WorkspaceInvoicesDocument, options);
        }
export function useWorkspaceInvoicesSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<WorkspaceInvoicesQuery, WorkspaceInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<WorkspaceInvoicesQuery, WorkspaceInvoicesQueryVariables>(WorkspaceInvoicesDocument, options);
        }
export type WorkspaceInvoicesQueryHookResult = ReturnType<typeof useWorkspaceInvoicesQuery>;
export type WorkspaceInvoicesLazyQueryHookResult = ReturnType<typeof useWorkspaceInvoicesLazyQuery>;
export type WorkspaceInvoicesSuspenseQueryHookResult = ReturnType<typeof useWorkspaceInvoicesSuspenseQuery>;
export type WorkspaceInvoicesQueryResult = Apollo.QueryResult<WorkspaceInvoicesQuery, WorkspaceInvoicesQueryVariables>;