// @ts-strict-ignore
import { useActiveIntegrationsQuery } from '@grain/api/graphql/queries/integrations.generated';
import {
  HubspotIntegrationFragment,
  SalesforceIntegrationFragment
} from '@grain/api/graphql/fragments/fragments.generated';
import { useAdHocFilters } from '~/modules/contentFilter';

export const useCRMIntegrations = () => {
  const { data: integrationsData } = useActiveIntegrationsQuery();
  const activeIntegrations = integrationsData?.activeIntegrations || [];

  const hubspotIntegration = activeIntegrations.find(
    integration => integration.__typename === 'HubspotIntegration'
  ) as HubspotIntegrationFragment | undefined;

  const salesforceIntegration = activeIntegrations.find(
    integration => integration.__typename === 'SalesforceIntegration'
  ) as SalesforceIntegrationFragment | undefined;

  const hasHubspot = !!hubspotIntegration;
  const hasSalesforce = !!salesforceIntegration;
  return {
    hasCRM: hasHubspot || hasSalesforce,
    hasHubspot,
    hasSalesforce,
    hubspotIntegration,
    salesforceIntegration
  } as const;
};

type Filters = ReturnType<typeof useAdHocFilters>['filtersMap'];

export const useParseStageFilter = (filters: Filters) => {
  const stageFilter = filters.stage;

  let selectedPipelineId: string | undefined;
  let selectedStageId: string | undefined;

  try {
    const parsed = JSON.parse(stageFilter.value[0].toString()) as {
      pipelineId?: string;
      stageId?: string;
    };

    selectedPipelineId = parsed.pipelineId;
    selectedStageId = parsed.stageId;
  } catch {}

  return {
    selectedPipelineId,
    selectedStageId
  } as const;
};

export const useParseAtRiskFilter = (filters: Filters) => {
  const stageFilter = filters.at_risk;
  return stageFilter ? stageFilter.value : null;
};
