import { SMART_TAGS_FTUX } from '@grain/components/modals/constants';
import { HotspotTooltip } from '@grain/grain-ui';
import { useLayoutEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useHotspotContext, useHotspots } from '~/components/Hotspot';
import { useHotspotByKey } from '~/components/Hotspot/context';
import { useFeature } from '@grain/api/auth';

export const useSmartTopicsFtux = () => {
  const { registerStory } = useHotspotContext();
  const { hasSeenHotspot } = useHotspotByKey(SMART_TAGS_FTUX);
  const [createTagRef] = useHotspots(SMART_TAGS_FTUX, 'create-tag');
  const [manageTagRef] = useHotspots(SMART_TAGS_FTUX, 'manage-tags');
  const [notifiedRef] = useHotspots(SMART_TAGS_FTUX, 'notified');
  const [params] = useSearchParams();
  const { enabled: isSmartTopicsEnabled } = useFeature('smart_topics');

  const isOpen = params.get('tour') === 'true';
  const hasCreatedOrEdited = hasSeenHotspot('create-or-edit');

  useLayoutEffect(() => {
    if (!isOpen) {
      return;
    }

    registerStory(SMART_TAGS_FTUX, {
      shouldSkip: () => !isSmartTopicsEnabled,
      steps: [
        {
          id: 'create-tag',
          nextId: 'manage-tags',
          content: (
            <HotspotTooltip
              showClose
              title='Create your own Trackers'
              description='Automatically track any topic in your team’s meetings based on keywords and phrases.'
            />
          )
        },
        {
          id: 'manage-tags',
          nextId: hasCreatedOrEdited ? 'notified' : null,
          content: (
            <HotspotTooltip
              showClose
              title='Manage your trackers'
              description='Click the more icon to edit or delete existing trackers (e.g., add your competitors as keywords).'
            />
          ),
          tippyProps: {
            placement: 'bottom'
          }
        },

        ...(hasCreatedOrEdited
          ? [
              {
                id: 'notified',
                nextId: null,
                content: (
                  <HotspotTooltip
                    showClose
                    title='Get notified of matches in Slack'
                    description='Click the add notification icon to setup Slack notifications for tracker matches. You’ll be notified every time a match occurs in your meetings.'
                  />
                ),
                tippyProps: {
                  placement: 'bottom'
                }
              }
            ]
          : [])
      ]
    });
  }, [isOpen, registerStory, hasCreatedOrEdited, isSmartTopicsEnabled]);

  return { createTagRef, manageTagRef, notifiedRef } as const;
};
