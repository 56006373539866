import React from 'react';
import styled from '@emotion/styled';
import { format, isThisYear } from 'date-fns';

import { Icon, flex } from '@grain/grain-ui';
import { theme } from '@grain/grain-ui/v4';

import {
  DealActivityFragment,
  DealDetailsFragment
} from '../../deal.generated';
import { getActivityTimestamp } from '../../utils';

import { DealEmail } from './DealEmail';
import { Recording } from './Recording';
import { RecordingView } from './RecordingView';
import { StageChange } from './StageChange';

export const ItemBorder = styled.div`
  padding: ${theme.tokens.spacing.lg};
  ${flex.direction.column};
  gap: ${theme.tokens.spacing.lg};
  border: 1px solid ${theme.tokens.color.borderTertiary};
  border-radius: ${theme.tokens.radii.xl};
  width: 100%;
`;

const ActivityContainer = styled.div`
  overflow: auto;
`;

const ActivityMargins = styled.div`
  margin-top: ${theme.tokens.spacing.lg};
  margin-left: ${theme.tokens.spacing['3xl']};
  margin-right: ${theme.tokens.spacing['3xl']};
`;

const ActivityGroup = styled.div`
  display: flex;
  gap: ${theme.tokens.spacing.sm};
`;

const ActivityGroupLeft = styled.div`
  width: 108px;
  display: flex;
  justify-content: flex-end;
`;

const ActivityGroupRight = styled.div`
  flex: 1 0 0;
`;

const ActivityItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const ActivityTimeline = styled.div`
  display: flex;
  width: 24px;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
`;

const ActivityItemContent = styled.div`
  display: flex;
  padding: ${theme.tokens.spacing.sm} 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
`;

const Chip = styled.div`
  background: ${theme.tokens.color.surfaceTertiary};
  border-radius: ${theme.tokens.radii.full};
  height: 24px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  padding: 2px 10px;
  margin-top: 21px;

  position: sticky;
  top: ${theme.tokens.spacing.lg};

  & > * {
    ${theme.tokens.typography.button};
    height: 32px;
    display: flex;
    align-items: center;
  }
`;

const ChipIcon = styled.div`
  background: ${theme.tokens.color.surfaceTertiary};
  border-radius: ${theme.tokens.radii.full};
`;

const IconContainer = styled.div`
  ${flex.center};
  padding: ${theme.tokens.spacing['2xs']};
`;

const Line = styled.div`
  align-self: stretch;
  background: ${theme.tokens.color.surfaceTertiary};
  width: 2px;
`;

const LineTop = styled.div`
  display: flex;
  height: 21px;
  padding: 0px 11px;
  align-items: flex-end;
  gap: 10px;
`;

const LineBot = styled.div`
  display: flex;
  padding: 0px 11px;
  align-items: flex-end;
  gap: 10px;
  flex: 1 0 0;
`;

const formatShortDate = (timestamp: string) => {
  const date = new Date(timestamp);
  const fmt = isThisYear(date) ? 'E, MMM do' : 'MMM dd, yyyy';

  return format(date, fmt);
};

export const formatTime = (timestamp: string) => {
  return format(new Date(timestamp), 'h:mm aaa');
};

const itemToIcon = (item: DealActivityFragment) => {
  const props = {
    height: 16,
    width: 16
  };

  switch (item.__typename) {
    case 'DealEmail':
      return <Icon.Mail16x16 {...props} />;
    case 'DealRecordingView':
      return <Icon.View {...props} />;
    case 'DealStageChange':
      return <Icon.MarkerPin {...props} />;
    case 'Recording':
      return <Icon.Record16x16 {...props} />;
    default:
      return <></>;
  }
};

const itemToContent = (
  deal: DealDetailsFragment,
  item: DealActivityFragment
) => {
  switch (item.__typename) {
    case 'DealEmail':
      return <DealEmail deal={deal} email={item} />;
    case 'DealRecordingView':
      return <RecordingView view={item} />;
    case 'DealStageChange':
      return <StageChange stage={item} />;
    case 'Recording':
      return <Recording deal={deal} recording={item} />;
  }
};

type TimelineProps = {
  deal: DealDetailsFragment;
  activity: DealActivityFragment[];
};
export const Timeline = ({ deal, activity }: TimelineProps) => {
  const datesAndItems = React.useMemo(() => {
    const acc: { [key: string]: DealActivityFragment[] } = {};

    const groupedActivity = activity.reduce((acc, item) => {
      const date = formatShortDate(getActivityTimestamp(item));
      if (acc[date]) {
        acc[date] = acc[date].concat(item);
      } else {
        acc[date] = [item];
      }
      return acc;
    }, acc);

    return Object.entries(groupedActivity);
  }, [activity]);

  return (
    <ActivityContainer className='timeline'>
      <ActivityMargins>
        {datesAndItems.map(([date, items], dateIdx) => (
          <ActivityGroup key={dateIdx}>
            <ActivityGroupLeft>
              <Chip>
                <div>{date}</div>
              </Chip>
            </ActivityGroupLeft>
            <ActivityGroupRight>
              {items.map((item, itemIdx) => (
                <ActivityItem key={item.id}>
                  <ActivityTimeline>
                    <LineTop>
                      {!(dateIdx === 0 && itemIdx === 0) && <Line />}
                    </LineTop>
                    <ChipIcon>
                      <IconContainer>{itemToIcon(item)}</IconContainer>
                    </ChipIcon>
                    <LineBot>
                      <Line />
                    </LineBot>
                  </ActivityTimeline>
                  <ActivityItemContent>
                    {itemToContent(deal, item)}
                  </ActivityItemContent>
                </ActivityItem>
              ))}
            </ActivityGroupRight>
          </ActivityGroup>
        ))}
      </ActivityMargins>
    </ActivityContainer>
  );
};
