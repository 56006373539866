import styled from '@emotion/styled';
import { hexToRgba } from '@grain/grain-ui';
import { color, colors, font, media, spacing } from '@grain/styles/constants';

export const StyledContainer = styled.div`
  .switch-billing-period {
    ${color.graieen};
    align-self: flex-start;
    cursor: pointer;
    font-size: calc(14rem / 16);
    margin-top: 12px;
    width: auto;
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  flex-shrink: 0;
  background-color: ${hexToRgba(colors.blackbird, 0.16)};
  ${spacing.my7};
`;

export const StyledPlansContainer = styled.div`
  display: flex;
  align-items: center;
  ${media.small} {
    flex-direction: column;
    flex-shrink: 0;
  }
  ${spacing.mt7};
  .arrow svg {
    width: 24px;
    height: 24px;
  }
  .arrow {
    ${spacing.mx6};
  }
`;

export const StyledNext = styled.div`
  .heading {
    ${font.bodyL};
  }
  .text {
    ${font.bodyM};
    font-weight: normal;
    ${spacing.mt6};
  }
`;

export const StyledFooter = styled.div`
  ${spacing.mb6};
`;
