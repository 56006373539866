import React from 'react';
import { StyledTos } from './styles';
import { spacing } from '@grain/styles/constants';

export default function Footer({ ...rest }) {
  return (
    <StyledTos {...rest}>
      <div css={[spacing.mb2]}>
        By signing up, I agree to the&nbsp;
        <a href='https://www.grain.com/terms'>Terms of Service</a> and{' '}
        <a href='https://www.grain.com/privacy-policy'>Privacy Policy</a>
      </div>
    </StyledTos>
  );
}
