// @ts-strict-ignore
import React from 'react';
import { Modal, ModalTitle, UploadImageModal } from '@grain/grain-ui';
import { useWebSocket } from '@grain/components/WebSocketContext';
import { useWorkspaceUploadInfoQuery } from './UploadImage.generated';

type UploadLogoModalProps = {
  onCancel: () => void;
};

export default function UploadLogoModal({
  onCancel,
  ...rest
}: UploadLogoModalProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const { data } = useWorkspaceUploadInfoQuery();
  const { userChannel } = useWebSocket();

  const onUploading = React.useCallback(
    () => setIsLoading(true),
    [setIsLoading]
  );
  const onUploadSuccess = React.useCallback(
    () => setIsLoading(false),
    [setIsLoading]
  );
  const onUploadFailure = React.useCallback(
    () => setIsLoading(false),
    [setIsLoading]
  );

  return (
    <>
      {data?.workspaceUploadInfo && (
        <Modal
          onCancel={onCancel}
          css={['max-width: 516px; !important']}
          maskClosable={!isLoading}
          closable={!isLoading}
          {...rest}
        >
          <ModalTitle centered title='Upload Workspace Icon' />
          <UploadImageModal
            uploadUrl={data.workspaceUploadInfo.url.url}
            uploadUuid={data.workspaceUploadInfo.url.uuid}
            maxUploadBytes={data.workspaceUploadInfo.maxUploadBytes}
            minHeight={32}
            minWidth={32}
            onCancel={onCancel}
            onUploading={onUploading}
            onUploadSuccess={onUploadSuccess}
            onUploadFailure={onUploadFailure}
            showCropControls={true}
            cropAspectRatio={1}
            containerHeight='200px'
            containerWidth='100%'
            userChannel={userChannel}
            {...rest}
          />
        </Modal>
      )}
    </>
  );
}
