import styled from '@emotion/styled';
import {
  Icon,
  ItemsForSelectType,
  SelectInline,
  color,
  flex,
  font,
  spaces,
  spacing
} from '@grain/grain-ui';
import { SalesOpportunity, scoreToLabelVariant } from '~/support/scorecard';
import { SpeakerStatisticsMetricsKeys } from '~/support/speakerMetrics';

type OpportunityTypes = 'scoring' | 'metrics';

type BaseOpportunityItemProps = {
  label: string;
  onClick: (
    type: OpportunityTypes,
    key: SalesOpportunity | SpeakerStatisticsMetricsKeys
  ) => void;
};

type ScoringOpportunityItemProps = BaseOpportunityItemProps & {
  type: 'scoring';
  opportunityKey: SalesOpportunity;
  value: number;
};

type MetricsOpportunityItemProps = BaseOpportunityItemProps & {
  type: 'metrics';
  opportunityKey: SpeakerStatisticsMetricsKeys;
  value: string | number;
};

type OpportunityItemProps =
  | ScoringOpportunityItemProps
  | MetricsOpportunityItemProps;

const ButtonStyled = styled.button`
  all: initial;
  font: inherit;
  color: inherit;
  cursor: pointer;
  ${flex.alignItems.center}
  gap: ${spaces[6]};
  ${spacing.py4}
  ${spacing.px6}
  border-top: 1px solid ${color.gull};

  &:first-of-type {
    border-top: 0;
  }

  &:hover {
    ${color.bg.goose};
  }
`;

export const OpportunityItem = ({
  opportunityKey,
  label,
  value,
  type,
  onClick
}: OpportunityItemProps) => {
  return (
    <ButtonStyled onClick={() => onClick(type, opportunityKey)}>
      <span css={[font.v4.b4[500], { flex: 1 }]}>{label}</span>
      {type === 'metrics' ? (
        <span css={[font.v4.b4[500]]}>{value}</span>
      ) : (
        <SelectInline
          items={ItemsForSelectType.numeric}
          selected={value}
          labelProps={{ selectedVariant: scoreToLabelVariant(value) }}
        />
      )}
      <Icon.ChevronRight />
    </ButtonStyled>
  );
};
