import styled from '@emotion/styled';
import { color } from '@grain/grain-ui';
import { useMemo } from 'react';
import { SuggestionRow } from './SuggestionRow';
import { NormalizedActiveFilter, Suggestion } from '~/pages/Search/state';

const Wrapper = styled.div<{ isFirstSelected: boolean }>`
  padding-top: 30px;
  border-radius: 8px;
  ${props => props.isFirstSelected && color.bg.goose}
`;

const Container = styled.div`
  ${color.bg.swan}
`;

type FilterSuggestionsProps = {
  search: string;
  normalizedActiveFilters: NormalizedActiveFilter[];
  newFilters: Suggestion[];
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  onSelect: (selectedIndex: number, searchString?: string) => void;
  className?: string;
  /**
   * Searches executed with modifiers (e.g. "@" or "#") should not show the search string as a suggestion
   */
  showSearchString?: boolean;
};

export const FilterSuggestions = ({
  search,
  className,
  normalizedActiveFilters,
  newFilters,
  selectedIndex,
  setSelectedIndex,
  onSelect,
  showSearchString
}: FilterSuggestionsProps) => {
  const filters = useMemo(() => {
    return normalizedActiveFilters.map(filter => ({
      filterKey: filter?.filterKey,
      filterLabel: filter?.filterLabel,
      valueLabel: filter?.values?.map(({ label }) => label).join(', '),
      valueId: filter?.values?.map(({ value }) => value).join(', ')
    }));
  }, [normalizedActiveFilters]);

  return (
    <Wrapper className={className} isFirstSelected={selectedIndex === 0}>
      <Container>
        {showSearchString && (
          <SuggestionRow
            text={search}
            filters={filters}
            isSelected={selectedIndex === 0}
            onMouseMove={() => setSelectedIndex(0)}
            onSelect={() => onSelect(0, search)}
          />
        )}
        {newFilters.map((filter, index) => {
          const correctedIndex = showSearchString ? index + 1 : index;
          return (
            <SuggestionRow
              key={`${filter.filterKey}-${filter.valueId}`}
              filters={[...filters, filter]}
              isSelected={selectedIndex === correctedIndex}
              onMouseMove={() => setSelectedIndex(correctedIndex)}
              onSelect={() => onSelect(correctedIndex)}
            />
          );
        })}
      </Container>
    </Wrapper>
  );
};
