import React from 'react';

import {
  useUserRegistration,
  AccessibleWorkspace
} from '../../Onboarding/hooks';

import {
  StyledNewWorkspaceDescription,
  StyledWorkspaceContainer,
  StyledWorkspaceLabel,
  StyledWorkspaceName,
  StyledWorkspaceDescription,
  StyledLogo,
  StyledJoinButton,
  StyledCreateWorkspaceCta,
  StyledWorkspaceListContainer,
  StyledFooterContainer
} from './styles';

import {
  StyledCardHeaderText,
  StyledCardSubHeaderText,
  StyledCard,
  StyledCardWrapper
} from '../../Onboarding/styles';

import { StepComponentProps } from '..';

function getWorkspaceDescription(workspace: AccessibleWorkspace) {
  if (workspace.inviter?.name) {
    return `${workspace.inviter.name} invited you`;
  }
  const otherMembersCount = workspace.member_count - 1;
  switch (otherMembersCount) {
    case 0:
      return `${workspace.owner.name}`;
    case 1:
      return `${workspace.owner.name} and 1 other`;
    default:
      return `${workspace.owner.name} and ${otherMembersCount} others`;
  }
}

export default function JoinWorkspace({
  registrationData,
  forceToCreateWorkspace,
  forceToPendingWorkspaceRequest,
  onNext
}: StepComponentProps) {
  const [loadingJoinId, setLoadingJoinId] = React.useState<string | null>(null);

  const { joinWorkspaceById, requestToJoinWorkspace } = useUserRegistration();

  const ssoAuth = (e: Event, workspace: AccessibleWorkspace) => {
    e.preventDefault();
    const path = `/app/_/redirect/workos?action=web_register&sso_workspace_id=${workspace.id}`;
    window.location.href = path;
    return false;
  };

  const invitedWorkspaces = React.useMemo(() => {
    const workspaces = registrationData?.accessible_workspaces ?? [];
    return workspaces.filter(a => a.invite_type !== 'open');
  }, [registrationData]);

  const openWorkspaces = React.useMemo(() => {
    const workspaces = registrationData?.accessible_workspaces ?? [];
    return workspaces.filter(a => a.invite_type === 'open');
  }, [registrationData]);

  const sortedWorkspaces = React.useMemo(() => {
    return [...invitedWorkspaces, ...openWorkspaces];
  }, [invitedWorkspaces, openWorkspaces]);

  const subtitle = React.useMemo(() => {
    if (invitedWorkspaces.length === 0) {
      return `Someone from @${registrationData.domain} has already created a workspace.`;
    } else {
      return 'You have access to these workspaces.';
    }
  }, [registrationData, invitedWorkspaces]);

  const availableWorkspacesCount = sortedWorkspaces.length;
  const requestedWorkspace = registrationData?.requested_workspace;

  if (!requestedWorkspace && !availableWorkspacesCount) {
    return (
      <div css={['margin-left: auto; margin-right: auto;']}>
        Oops! Something went wrong. Please try again.
      </div>
    );
  }

  return (
    <StyledCardWrapper>
      <StyledCard>
        <StyledCardHeaderText data-cy='register-title'>
          Join your team’s workspace.
        </StyledCardHeaderText>
        <StyledCardSubHeaderText>{subtitle}</StyledCardSubHeaderText>

        <div className='content-wrapper'>
          <StyledWorkspaceListContainer>
            {sortedWorkspaces.map(workspace => (
              <StyledWorkspaceContainer key={workspace.id}>
                <StyledLogo
                  url={workspace.logo_url}
                  name={workspace.name}
                  size={48}
                />
                <StyledWorkspaceLabel>
                  <StyledWorkspaceName>{workspace.name}</StyledWorkspaceName>
                  <StyledWorkspaceDescription>
                    {getWorkspaceDescription(workspace)}
                  </StyledWorkspaceDescription>
                </StyledWorkspaceLabel>
                <StyledJoinButton
                  key={workspace.id}
                  loading={loadingJoinId === workspace.id}
                  disabled={!!loadingJoinId}
                  type='primary'
                  onClick={e =>
                    workspace.join_by_request
                      ? requestToJoinWorkspace(
                          workspace.id,
                          forceToPendingWorkspaceRequest
                        )
                      : workspace.requires_sso_reauth
                        ? ssoAuth(e, workspace)
                        : joinWorkspaceById(
                            workspace.id,
                            loadingJoinId,
                            setLoadingJoinId,
                            onNext
                          )
                  }
                >
                  {workspace.join_by_request
                    ? 'Request to join'
                    : workspace.requires_sso_reauth
                      ? 'Continue with SSO'
                      : 'Join'}
                </StyledJoinButton>
              </StyledWorkspaceContainer>
            ))}
          </StyledWorkspaceListContainer>
        </div>
        <StyledFooterContainer>
          {registrationData.can_create_workspace && (
            <>
              <StyledNewWorkspaceDescription>
                Looking to create a new workspace for a different team?
              </StyledNewWorkspaceDescription>
              <StyledCreateWorkspaceCta
                data-cy='create-new'
                onClick={() => forceToCreateWorkspace()}
              >
                Create a new Workspace
              </StyledCreateWorkspaceCta>
            </>
          )}
        </StyledFooterContainer>
      </StyledCard>
    </StyledCardWrapper>
  );
}
