import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useExtra } from '@grain/components/support/extra';
import { getBasePublicPath } from '@grain/components/support/environment';
import {
  StyledModalCard,
  StyledCardHeaderText,
  StyledModalCardSubHeaderText,
  StyledCardWrapper,
  StyledNextButton
} from '../styles';
import { StyledClose, StyledConnect, StyledActionWrapper } from './styles';
import { useRouteModal, Icon, color } from '@grain/grain-ui';
import { isDesktopApp } from '@grain/desktop-lib';
import { HUBSPOT_CONNECT_ROUTE_ID } from '@grain/components/modals/constants';
import { useActiveIntegrationsQuery } from '@grain/api/graphql/queries/integrations.generated';
import { HubspotIntegration } from '@grain/api/schema.generated';
import { getDesktopUrl } from 'desktop/lib/utils';

const ConnectHubspot = () => {
  const { close } = useRouteModal(HUBSPOT_CONNECT_ROUTE_ID);
  const location = useLocation();
  const navigate = useNavigate();

  const { extra, saveExtra } = useExtra();

  const redirect = React.useMemo(() => {
    const path = `${location.pathname}${location.search}`;
    return isDesktopApp
      ? window.encodeURIComponent(getDesktopUrl(path))
      : window.encodeURIComponent(path);
  }, [location]);

  const { data: integrationsData } = useActiveIntegrationsQuery();

  const hubspotConnected = React.useMemo(() => {
    const hubspotIntegration = integrationsData?.activeIntegrations?.find(
      integration => integration.__typename === 'HubspotIntegration'
    ) as HubspotIntegration;
    return hubspotIntegration?.canSyncProperties;
  }, [integrationsData]);

  const connectHref = `/app/_/redirect/hubspot?enable=sync_properties&redirect_to=${redirect}`;

  const closeModal = React.useCallback(() => {
    saveExtra({ ...extra, hubspotPropertiesModalClosed: true });
    close();
  }, [extra, saveExtra, close]);
  return (
    <StyledCardWrapper>
      <StyledModalCard>
        <div className='left'>
          {!hubspotConnected ? (
            <>
              <StyledCardHeaderText>
                HubSpot deal qualification properties
              </StyledCardHeaderText>
              <StyledModalCardSubHeaderText>
                Grain can now create deal qualification properties in HubSpot
                from your meeting notes.
              </StyledModalCardSubHeaderText>
              <StyledModalCardSubHeaderText>
                To try this feature, reconnect HubSpot.
              </StyledModalCardSubHeaderText>
              <div className='content-wrapper'>
                <StyledConnect
                  as='a'
                  href={connectHref}
                  css={['background-color: #FF5C35', color.fg.swan]}
                  data-track='Button Clicked'
                  data-track-args={JSON.stringify({
                    button_name: 'reconnect_hubspot',
                    category: 'integrations',
                    trigger: 'reconnect_hubspot',
                    button_text: 'Reconnect HubSpot'
                  })}
                >
                  <span className='icon'>
                    <Icon.Hubspot css={['width: 20px;']} />
                  </span>{' '}
                  Reconnect HubSpot
                </StyledConnect>
              </div>
            </>
          ) : (
            <>
              <StyledCardHeaderText>You're all set</StyledCardHeaderText>
              <StyledModalCardSubHeaderText>
                Your HubSpot account has been reconnected. Grain will now
                automatically create qualification properties on your existing
                deals in HubSpot.
              </StyledModalCardSubHeaderText>
            </>
          )}
          {!hubspotConnected ? (
            <StyledClose onClick={() => closeModal()}>
              I'll try it later
            </StyledClose>
          ) : (
            <StyledActionWrapper
              css={['margin-right: auto; margin-top: auto;']}
            >
              <StyledNextButton variant='primary' onClick={() => closeModal()}>
                Done
              </StyledNextButton>
              <StyledClose
                css={['margin-top: unset;']}
                onClick={() => {
                  navigate('app/settings/integrations?tab=hubspot');
                }}
              >
                Configure in HubSpot settings
              </StyledClose>
            </StyledActionWrapper>
          )}
        </div>
        <div className='right'>
          <img
            src={`${`${getBasePublicPath()}/images/onboarding/card-hubspot.svg`}`}
          />
        </div>
      </StyledModalCard>
    </StyledCardWrapper>
  );
};

export default ConnectHubspot;
