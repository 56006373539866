// @ts-strict-ignore
import React from 'react';
import styled from '@emotion/styled';
import {
  useShowToast,
  flex,
  Icon,
  InfoBox,
  color,
  pxToRem,
  Checkbox,
  getOauthRedirectUrl
} from '@grain/grain-ui';
import type { provider } from '@grain/grain-ui';
import { useCalendarSelectedSetMutation } from './Calendar.generated';
import { accounts } from './';
import { CalendarAccount, SelectableCalendar } from '@g/schema';
import { OauthAccount } from './types';

const Arrow = styled.div`
  margin-right: 6px;
  margin-left: auto;
  display: flex;
  ${flex.alignItems.center};
  ${flex.justifyContent.center};
`;

const GridContainer = styled.div`
  width: 100%;
  border: 1px solid ${color.gull};
  border-radius: 0 0 ${pxToRem(12)} ${pxToRem(12)};
  margin-bottom: 16px;
`;

export const GridItem = styled.div`
  width: 100%;
  padding: 16px 24px;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(18)};
  color: ${color.blackbird};
  border-bottom: 1px solid ${color.gull};
  gap: 8px;
  display: flex;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }
`;

const InfoBoxContainer = styled.div`
  .icon {
    margin-top: 0px;
  }
`;

type CalendarsBoxProps = {
  calendarAccount: CalendarAccount;
  oauthAccount: OauthAccount;
  showInfoBox: boolean;
};

export default function CalendarsBox({
  calendarAccount,
  oauthAccount,
  showInfoBox
}: CalendarsBoxProps) {
  const showToast = useShowToast();
  const [isOpen, setIsOpen] = React.useState(!showInfoBox);
  const [calendar, setCalendar] =
    React.useState<CalendarAccount>(calendarAccount);
  const [calendarSelectedSetMutation] = useCalendarSelectedSetMutation();
  const currentPath = window.location.pathname + window.location.search;

  const ArrowButton = (
    <Arrow className='option-select-caret' onClick={() => setIsOpen(!isOpen)}>
      {isOpen ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
    </Arrow>
  );

  const calendarConnected = oauthAccount?.calendarConnected;

  React.useEffect(() => {
    setCalendar(calendarAccount);
  }, [calendarAccount]);

  const handleConnect = () => {
    window.location.href = getOauthRedirectUrl(
      oauthAccount.provider.toLowerCase() as provider,
      true,
      'connect_account',
      {},
      currentPath,
      oauthAccount.email
    );
  };

  return (
    <div css={['margin-bottom: 16px;']}>
      {showInfoBox && (
        <InfoBoxContainer>
          <InfoBox
            width='100%'
            css={[
              `padding: 16px 24px !important; ${
                isOpen &&
                calendarConnected &&
                'border-radius: 12px 12px 0 0 !important;'
              }`
            ]}
            altButton={calendarConnected && ArrowButton}
            icon={accounts[oauthAccount?.provider].icon}
            buttonText={!calendarConnected && 'Connect'}
            variant={'primary'}
            onButtonClicked={handleConnect}
          >
            {oauthAccount?.email}
          </InfoBox>
        </InfoBoxContainer>
      )}
      {isOpen && calendarConnected && (
        <GridContainer>
          {calendar?.calendars?.map((calendar: SelectableCalendar) => (
            <GridItem key={calendar.id}>
              <Checkbox
                checked={calendar.isSelected}
                onChange={async (ev: React.ChangeEvent<HTMLInputElement>) => {
                  const { data } = await calendarSelectedSetMutation({
                    variables: {
                      calendarId: calendar.id,
                      selected: ev.target.checked
                    }
                  });
                  setCalendar(
                    data?.calendarSelectedSet.accounts.find(
                      (account: CalendarAccount) =>
                        account.email === oauthAccount?.email &&
                        account.provider === oauthAccount?.provider
                    ) as CalendarAccount
                  );
                  showToast({
                    content: 'Account Updated',
                    type: 'success'
                  });
                }}
              />
              {calendar.title}
            </GridItem>
          ))}
        </GridContainer>
      )}
    </div>
  );
}
