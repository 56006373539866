import styled from '@emotion/styled';
import { color } from '@grain/grain-ui';
import MenuBar from '../../components/Notepad/MenuBar';

const StyledRecordingDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-right: 6px;
  background-color: ${color.errorbird};
`;

interface HeaderProps {
  title?: string;
  actionBar?: React.ReactNode;
}
const Header = ({ title = '', actionBar = null }: HeaderProps) => {
  return (
    <MenuBar
      compact
      dark
      title={title}
      actions={actionBar}
      leftContent={<StyledRecordingDot />}
    />
  );
};

export default Header;
