import {
  Select,
  Option,
  type OptionProps,
  type SelectProps,
  Button
} from '@grain/grain-ui/v4';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { useFeature, type GatedFeatureName } from '@grain/api/auth';

type GatedSelectProps<T> = SelectProps<T> & {
  gate: GatedFeatureName;
  children: React.ReactNode;
};

const UpgradeButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 6px 6px 6px;
`;

export const GatedSelect = <T,>({
  gate,
  children,
  ...selectProps
}: GatedSelectProps<T>) => {
  const { enabled } = useFeature(gate);

  const optionsArray = useMemo(
    () => (Array.isArray(children) ? children : [children]),
    [children]
  );

  const gatedChildren = useMemo(
    () =>
      optionsArray.map(option => {
        return (
          <Option
            key={option.props.value}
            {...option.props}
            disabled={option.props.gated && !enabled}
            disabledReason={
              option.props.gated && !enabled
                ? 'Upgrade your plan to enable this'
                : undefined
            }
          >
            {option.props.children}
          </Option>
        );
      }),
    [optionsArray, enabled]
  );

  return (
    <Select
      {...selectProps}
      footer={
        !enabled && (
          <UpgradeButtonContainer key='upgrade-button'>
            <Button
              variant='plan'
              size='sm'
              as={Link}
              to='/app/settings/workspace?tab=plans'
            >
              Upgrade
            </Button>
          </UpgradeButtonContainer>
        )
      }
    >
      {gatedChildren}
    </Select>
  );
};

type GatedOptionProps = OptionProps & {
  gated?: boolean;
};

export function GatedOption(_props: GatedOptionProps) {
  // It's a virtual element that will be rendered by <GatedSelect>
  return <></>;
}
