import { useCallback, useMemo, useState } from 'react';
import { useMyself } from '@grain/api/auth';
import { Modal, useRouteModal } from '@grain/grain-ui';
import { ONBOARDING_ROUTE_ID } from '@grain/components/modals/constants';
import { AutoRecording } from './SlideshowModals/AutoRecording';
import { FreeViewerWelcome } from './SlideshowModals/FreeViewerWelcome';

export type StepComponentProps = {
  totalSteps: number;
  step: number;
  handleBack: () => void;
  handleNext: () => void;
  handleDone: () => void;
  showStepsIndicator: boolean;
};

export default function FreemiumOnboardingModal() {
  const { close } = useRouteModal(ONBOARDING_ROUTE_ID);
  const { myself } = useMyself();

  const isOnPlan = myself?.user?.onPlan ?? false;

  // NOTE: there will be more steps in W3 (related to the trial)
  const [step, setStep] = useState(0);
  const steps: React.ComponentType<StepComponentProps>[] = useMemo(() => {
    // Free Notetaker and Paid seats should see the auto recording message,
    // since they can record.  A Free Viewer should see a different message
    // aligned with his value proposition as a Free Viewer.
    const firstStep = isOnPlan ? AutoRecording : FreeViewerWelcome;
    return [firstStep];
  }, [isOnPlan]);
  const StepComponent = steps[step];
  const totalSteps = steps.length;

  const showStepsIndicator = totalSteps > 1;

  const handleDone = useCallback(() => {
    close();
  }, [close]);

  const handleNext = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const handleBack = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  // Wait to render until the user is loaded so we can show the correct slide
  // initially (see "firstStep" above)
  if (!myself) return;

  return (
    <Modal onCancel={close} width={700} roundness='md' centered closable>
      <StepComponent
        totalSteps={totalSteps}
        step={step}
        handleBack={handleBack}
        handleNext={handleNext}
        handleDone={handleDone}
        showStepsIndicator={showStepsIndicator}
      />
    </Modal>
  );
}
