// @ts-strict-ignore
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided,
  DroppableStateSnapshot,
  DraggableStateSnapshot
} from 'react-beautiful-dnd';
import { TemplateItem, Item } from './TemplateItem';

export type { Item } from './TemplateItem';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

type NotesTemplateProps = {
  items: Item[];
  onChangeOrder: (items: Item[]) => void;
};

// We're forced to use portals due to an issue with react-beautiful-dnd
// https://github.com/atlassian/react-beautiful-dnd/issues/499
function PortalAwareItem({
  provided,
  item,
  droppableSnapshot,
  draggableSnapshot
}: {
  provided: DraggableProvided;
  item: Item;
  droppableSnapshot: DroppableStateSnapshot;
  draggableSnapshot: DraggableStateSnapshot;
}) {
  const usePortal = draggableSnapshot.isDragging;

  const child = (
    <div ref={provided.innerRef} {...provided.draggableProps}>
      <TemplateItem
        item={item}
        handleProps={provided.dragHandleProps}
        style={{
          pointerEvents: droppableSnapshot.isDraggingOver ? 'none' : 'auto'
        }}
      />
    </div>
  );

  if (usePortal) return ReactDOM.createPortal(child, document.body);
  return child;
}

export function NotesTemplate({ items, onChangeOrder }: NotesTemplateProps) {
  const handleDragEnd = (result: DropResult) => {
    const newItems = [...items];
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);
    onChangeOrder(newItems);
  };

  return (
    <Wrapper>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='notes-template'>
          {(provided, droppableSnapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                position: 'relative'
              }}
            >
              {items.map((item, index) => (
                <Draggable
                  draggableId={item.id}
                  index={index}
                  key={item.id}
                  isDragDisabled={!item.draggable}
                >
                  {(provided, draggableSnapshot) => (
                    <PortalAwareItem
                      provided={provided}
                      item={item}
                      droppableSnapshot={droppableSnapshot}
                      draggableSnapshot={draggableSnapshot}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Wrapper>
  );
}
