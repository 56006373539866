import { useMemo } from 'react';
import {
  Navigate,
  Outlet,
  RouterProvider,
  useLocation
} from 'react-router-dom';
import { sentryCreateBrowserRouter } from '@grain/components/support/sentry';
import GrainErrorBoundary from '~/components/ErrorBoundary';
import NotFound from '~/components/NotFound';

import BaseLogin from '~/pages/Login';
import { DesktopLogin, isDesktopApp } from '@grain/desktop-lib';

import { loggedInRoutes } from './LoggedInRouter';
import { loggedOutRoutes } from './LoggedOutRouter';
import { useAuth } from '~/support/auth';
import RegisterContextForDesktop from '~/apps/MainApp/RegisterContextForDesktop';
import { useMediaQuery } from '@grain/components/support/browser';
import { media } from '@grain/grain-ui';
import { useSidebar } from '~/components/Layout';

function loginRedirect() {
  const redirect = `${window.location.pathname}${window.location.search}`;
  return window.encodeURIComponent(redirect);
}

const getRouter = () => {
  return sentryCreateBrowserRouter([
    {
      element: <RootLayout />,
      children: [
        { path: '/login', element: <LoginRoute /> },
        {
          element: <ProtectedRoute />,
          children: loggedInRoutes
        },
        ...loggedOutRoutes,
        /* Unknown path at this point, show 404 */
        { path: '*', element: <NotFound /> }
      ]
    }
  ]);
};

export default function AppRouter() {
  const router = useMemo(() => getRouter(), []);
  return <RouterProvider router={router} />;
}

function RootLayout() {
  return (
    <>
      <RegisterContextForDesktop />
      <GrainErrorBoundary>
        <Outlet />
      </GrainErrorBoundary>
    </>
  );
}

function LoginRoute() {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Navigate to='/app' /> : <Login />;
}

function Login() {
  return isDesktopApp ? <DesktopLogin /> : <BaseLogin />;
}

function ProtectedRoute() {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const { isCollapsed, toggleCollapsed } = useSidebar();
  const isSmallScreen = useMediaQuery(media.small);

  // Collapse the sidebar on small screens when the user navigates to a new page
  React.useEffect(() => {
    if (isAuthenticated && isSmallScreen && !isCollapsed) {
      toggleCollapsed();
    }
    // We cannot include other dependencies here because it would cause the sidebar to
    // flicker when the user navigates to a new page, we only want to run this effect
    // when the user navigates to a new page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={`/login?redirect_to=${loginRedirect()}`} />
  );
}
