// @ts-strict-ignore
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  LinkButton,
  NoteTemplateListDropdown,
  SelectorDropdownButton,
  Switch,
  useAnalytics,
  useConfirm,
  WorkspaceAccessDropdown
} from '@grain/grain-ui';
import { useMutation } from '@grain/api/graphql';
import { useIntelligenceTemplatesQuery } from '@grain/api/graphql/modules/templates.generated';
import {
  calendarEventRecordSetMutation,
  calendarEventRecordResetMutation,
  calendarEventWorkspaceSharedOverrideSetMutation,
  calendarEventIntelligenceTemplateSetMutation
} from '@grain/api/graphql/mutations';
import {
  type ActiveMeeting,
  useActiveRecordings
} from '@grain/components/ActiveRecordingsContext';
import { useRecordingIntelligenceTemplateSetMutation } from '@grain/api/graphql/queries/notesTemplate.generated';
import { useMyself, useWorkspace } from '@grain/api/auth';
import TagEditor from '@grain/components/TagEditor';

import { useApolloClient } from '@apollo/client';
import {
  useCalendarTagAddMutation,
  useCalendarTagRemoveMutation,
  CalendarEventQueryDocument
} from './calendarTags.generated';

import type {
  CalendarEvent,
  IntelligenceTemplate,
  Tag
} from '@grain/api/schema.generated';

import { useBot } from '~/components/StartBot';
import { MeetingCardMetadata } from '~/components/HeaderMetadata';

import {
  type Warning,
  BotStateIndicator,
  WarningIndicator
} from './indicators';
import { PasscodeModal } from './PasscodeModal';

import {
  StyledCardContainer,
  StyledCard,
  StyledTopContent,
  StyledAction,
  StyledButtonContainer,
  StyledOptions,
  StyledOverrideMessage,
  StyledOverrideMessageResetButton,
  StyledDivider
} from './styles';

const EMPTY_ARRAY_OF_TAGS: Tag[] = [];
const NOOP = () => {};

type MeetingCardProps = {
  accessibleTags?: Tag[];
  event: Partial<CalendarEvent>; // TODO: be more specific about required props
  meeting?: ActiveMeeting;
  onRecordingStart?: () => void;
  onRecurringEventTemplateChange?: () => void;
  openCall?: (url: string) => void;
};

export default function MeetingCard({
  accessibleTags = EMPTY_ARRAY_OF_TAGS,
  event,
  meeting,
  onRecordingStart = NOOP,
  onRecurringEventTemplateChange = NOOP,
  openCall = NOOP
}: MeetingCardProps) {
  const navigate = useNavigate();
  const [calendarEventRecordSet] = useMutation(calendarEventRecordSetMutation, {
    variables: {
      eventId: event.id
    }
  });
  const { getActiveRecording, setMeetings } = useActiveRecordings();
  const activeRecording = getActiveRecording({ meetingId: meeting?.id });
  const { states: botStates, stopRecording } = useBot();
  const providerId =
    meeting?.provider && meeting?.provider_key
      ? `${meeting?.provider}|${meeting?.provider_key}`
      : undefined;
  const botState = botStates[providerId];
  const { trackEvent } = useAnalytics();
  const { myself } = useMyself();
  const { workspace } = useWorkspace();

  type EventTrackingProps = {
    calendar_event_internal: boolean | undefined;
    calendar_event_recurring: boolean | undefined;
  };

  const eventTrackingProps = useMemo<EventTrackingProps>(
    () => ({
      calendar_event_internal: event.internal,
      calendar_event_recurring: event.recurring
    }),
    [event.internal, event.recurring]
  );

  const shareSetting = useMemo(() => {
    if (
      (myself?.settings?.autoShareExternalRecordings === null &&
        myself?.settings?.autoShareInternalRecordings === null) ||
      !workspace?.autoShareRecordingsOverridable
    ) {
      return [
        workspace?.autoShareExternalRecordings,
        workspace?.autoShareInternalRecordings
      ];
    }

    return [
      myself?.settings?.autoShareExternalRecordings,
      myself?.settings?.autoShareInternalRecordings
    ];
  }, [
    myself?.settings?.autoShareExternalRecordings,
    myself?.settings?.autoShareInternalRecordings,
    workspace?.autoShareExternalRecordings,
    workspace?.autoShareInternalRecordings,
    workspace?.autoShareRecordingsOverridable
  ]);

  const defaultTemplate = event.internal
    ? workspace?.internalRecordingIntelligenceTemplateV2
    : workspace?.externalRecordingIntelligenceTemplateV2;

  const [calendarEventRecordReset] = useMutation(
    calendarEventRecordResetMutation,
    {
      variables: {
        eventId: event.id
      }
    }
  );

  const showConfirm = useConfirm();

  const handleToggleRecord = useCallback(() => {
    const newRecordStatus = !event.record;
    calendarEventRecordSet({
      variables: {
        record: newRecordStatus
      },
      optimisticResponse: {
        __typename: 'Mutation',
        calendarEventRecordSet: {
          __typename: 'CalendarEvent',
          ...event,
          record: newRecordStatus,
          recordOverridden: 'RECURRING'
        }
      }
    });
    trackEvent(
      'Calendar Page Interaction',
      {
        interaction_name: 'Recording Override Toggled',
        toggle_status: newRecordStatus ? 'on' : 'off',
        ...eventTrackingProps
      },
      ['user', 'workspace']
    );
  }, [calendarEventRecordSet, event, eventTrackingProps, trackEvent]);

  const showWorkspaceAccess = Boolean(
    (meeting?.id && meeting?.calendar_event_id) || event?.id
  );
  const workspaceAccess = Boolean(
    event?.workspaceSharedOverride ??
      ((event.internal && shareSetting[1]) ||
        (!event.internal && shareSetting[0]))
  );
  const [calendarEventWorkspaceSharedOverrideSet] = useMutation(
    calendarEventWorkspaceSharedOverrideSetMutation,
    {
      variables: {
        eventId: event.id
      }
    }
  );
  // Update the local value since we don't have a subscription for this.
  // FIXME: add a WebSocket event handler for changes to meetings.
  const meetingWorkspaceSharedOverrideSet = useCallback(
    (workspaceSharedOverride: boolean) => {
      if (!meeting?.id) return;
      setMeetings(meetings =>
        meetings.map(other =>
          other.id === meeting.id
            ? {
                ...other,
                workspace_shared_override: workspaceSharedOverride
              }
            : other
        )
      );
    },
    [setMeetings, meeting]
  );
  const handleChangeWorkspaceAccess = useCallback(
    (workspaceSharedOverride: boolean) => {
      calendarEventWorkspaceSharedOverrideSet({
        variables: {
          workspaceSharedOverride
        }
      });
      meetingWorkspaceSharedOverrideSet(workspaceSharedOverride);
      trackEvent(
        'Calendar Page Interaction',
        {
          interaction_name: 'Workspace Access Updated',
          ...eventTrackingProps
        },
        ['user', 'workspace']
      );
    },
    [
      calendarEventWorkspaceSharedOverrideSet,
      meetingWorkspaceSharedOverrideSet,
      trackEvent,
      eventTrackingProps
    ]
  );

  const { data: intelligenceTemplatesData } = useIntelligenceTemplatesQuery();
  const meetingTemplateList = useMemo(
    () =>
      (intelligenceTemplatesData?.intelligenceTemplates ??
        []) as IntelligenceTemplate[],
    [intelligenceTemplatesData]
  );
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>();

  useEffect(() => {
    const newTemplateId =
      activeRecording?.intelligence_template_id ||
      event?.intelligenceTemplateV2?.id ||
      defaultTemplate?.id;

    setSelectedTemplateId(templateId =>
      templateId !== newTemplateId ? newTemplateId : templateId
    );
  }, [
    activeRecording?.intelligence_template_id,
    event?.intelligenceTemplateV2,
    defaultTemplate?.id,
    setSelectedTemplateId
  ]);
  const selectedTemplate = meetingTemplateList.find(
    template => template.id === selectedTemplateId
  );
  const showTemplateSelector = Boolean(
    (meeting?.id && meeting?.calendar_event_id) ||
      activeRecording.id ||
      event?.id
  );

  const [calendarEventIntelligenceTemplateSet] = useMutation(
    calendarEventIntelligenceTemplateSetMutation,
    {
      variables: {
        eventId: event.id
      }
    }
  );
  const [setRecordingTemplate] = useRecordingIntelligenceTemplateSetMutation();
  const handleChangeTemplate = useCallback(
    (templateId: string) => {
      if (activeRecording?.id) {
        setRecordingTemplate({
          variables: {
            recordingId: activeRecording.id,
            intelligenceTemplateId: templateId,
            eventProperties: JSON.stringify({
              trigger: 'today_page'
            })
          }
        });
        setSelectedTemplateId(templateId);
        trackEvent(
          'Calendar Page Interaction',
          {
            interaction_name: 'Template Updated',
            ...eventTrackingProps
          },
          ['user', 'workspace']
        );
      } else {
        if (event?.recurring) {
          showConfirm({
            confirmButtonType: 'primary',
            confirmContent: 'Confirm',
            description:
              'Are you sure you want to change the template for all future meetings?',
            title: 'You’re changing the template of a recurring event.',
            cancelContent: 'Cancel',
            width: 420,
            onConfirm: async () => {
              await calendarEventIntelligenceTemplateSet({
                variables: {
                  intelligenceTemplateId: templateId,
                  eventProperties: JSON.stringify({
                    trigger: 'calendar_page'
                  })
                }
              });
              setSelectedTemplateId(templateId);
              trackEvent(
                'Calendar Page Interaction',
                {
                  interaction_name: 'Template Updated',
                  ...eventTrackingProps
                },
                ['user', 'workspace']
              );
              // Provide a place to refetch calendar data so future instances of
              // the recurring event can be updated too.  TODO: remove this
              // (probably just revert the commit where this was added) once we
              // have completed work on scheduled meetings and can simply
              // subscribe to changes (rather than refetch).
              onRecurringEventTemplateChange();
            }
          });
        } else {
          calendarEventIntelligenceTemplateSet({
            variables: {
              intelligenceTemplateId: templateId,
              eventProperties: JSON.stringify({
                trigger: 'today_page'
              })
            }
          });
          setSelectedTemplateId(templateId);
          trackEvent(
            'Calendar Page Interaction',
            {
              interaction_name: 'Template Updated',
              ...eventTrackingProps
            },
            ['user', 'workspace']
          );
        }
      }
    },
    [
      activeRecording.id,
      calendarEventIntelligenceTemplateSet,
      event?.recurring,
      eventTrackingProps,
      onRecurringEventTemplateChange,
      setRecordingTemplate,
      showConfirm,
      trackEvent
    ]
  );

  const updateMeetingResetRecord = useCallback(() => {
    calendarEventRecordReset({
      optimisticResponse: {
        __typename: 'Mutation',
        calendarEventRecordReset: {
          __typename: 'CalendarEvent',
          ...event,
          recordOverridden: 'NOT_OVERRIDDEN'
        }
      }
    });
    trackEvent(
      'Calendar Page Interaction',
      {
        interaction_name: 'Recording Override Reset Confirmed',
        ...eventTrackingProps
      },
      ['user', 'workspace']
    );
  }, [calendarEventRecordReset, event, eventTrackingProps, trackEvent]);

  const handleResetRecord = useCallback(async () => {
    if (event?.recurring && event?.recordOverridden === 'RECURRING') {
      trackEvent(
        'Calendar Page Interaction',
        {
          interaction_name: 'Recording Override Reset Shown',
          ...eventTrackingProps
        },
        ['user', 'workspace']
      );
      showConfirm({
        confirmButtonType: 'primary',
        confirmContent: 'Reset All Instances',
        showCancel: true,
        description:
          'This will reset all instances of this event, and defer to your auto-record rules.',
        title: 'You’re changing the recording settings of a recurring event.',
        width: 420,
        onConfirm: () => {
          updateMeetingResetRecord();
        },
        onCancel: () => {
          trackEvent(
            'Calendar Page Interaction',
            {
              interaction_name: 'Recording Override Reset Cancelled',
              ...eventTrackingProps
            },
            ['user', 'workspace']
          );
        }
      });
    } else {
      updateMeetingResetRecord();
    }
  }, [
    event?.recurring,
    event?.recordOverridden,
    showConfirm,
    updateMeetingResetRecord,
    trackEvent,
    eventTrackingProps
  ]);

  const title = event?.title || meeting?.title || activeRecording?.title;

  const [stopping, setStopping] = useState(false);

  const handleStopRecording = useCallback(
    (meetingId?: string) => {
      showConfirm({
        confirmButtonType: 'danger',
        confirmContent: 'Stop',
        title: `Are you sure you want to stop recording: ${title}`,
        cancelContent: 'Cancel',
        width: 418,
        closable: false,
        onConfirm: () => {
          trackEvent(
            'Calendar Page Interaction',
            {
              interaction_name: 'Stop Recording Button Clicked',
              ...eventTrackingProps
            },
            ['user', 'workspace']
          );
          const stopPromise = stopRecording({
            meetingId,
            recordingId: activeRecording.id
          });
          setStopping(true);
          return stopPromise;
        }
      });
    },
    [
      activeRecording.id,
      eventTrackingProps,
      showConfirm,
      stopRecording,
      title,
      trackEvent
    ]
  );

  useEffect(() => {
    if (!activeRecording?.id) {
      setStopping(false);
    }
  }, [activeRecording, setStopping]);

  // -- Tags
  const client = useApolloClient();
  const variables = useMemo(
    () => ({ eventId: event.id, text: '' }), // text gets set later
    [event]
  );
  const [calendarTagAdd] = useCalendarTagAddMutation({
    variables,
    onCompleted: () => {
      trackEvent('Calendar Page Interaction', {
        interaction_name: 'Tag Updated',
        ...eventTrackingProps
      });
    }
  });
  const [calendarTagRemove] = useCalendarTagRemoveMutation({
    variables,
    onCompleted: () => {
      trackEvent('Calendar Page Interaction', {
        interaction_name: 'Tag Updated',
        ...eventTrackingProps
      });
    }
  });

  const optimisticAddTag = useCallback(
    (formattedText: string, currentTags: Tag[]) => {
      const clientTag: Tag = {
        __typename: 'Tag',
        id: `client-${formattedText}`,
        text: formattedText
      };

      return client.writeQuery({
        query: CalendarEventQueryDocument,
        variables,
        data: {
          calendarEvent: {
            ...event,
            tags: currentTags.concat(clientTag)
          }
        }
      });
    },
    [client, event, variables]
  );

  const optimisticRemoveTag = useCallback(
    (tag: string, currentTags: Tag[]) => {
      return client.writeQuery({
        query: CalendarEventQueryDocument,
        variables,
        data: {
          calendarEvent: {
            ...event,
            tags: currentTags.filter(item => item.text !== tag)
          }
        }
      });
    },
    [client, event, variables]
  );

  const isNonIdleState = Boolean(botState);
  const meetingActive = Boolean(meeting?.id);
  const isRecording = Boolean(activeRecording?.id);

  const warning: Warning =
    !isRecording &&
    event?.id &&
    event?.meetingProvider === 'ZOOM' &&
    event?.internalOrganiser === false
      ? 'external_host'
      : null;

  const [isWorkspaceAccessOpen, setIsWorkspaceAccessOpen] = useState(false);
  const [isTemplateSelectorOpen, setIsTemplateSelectorOpen] = useState(false);
  const [showPasscodeModal, setShowPasscodeModal] = useState(false);

  const openPasscodeModal = useCallback(() => {
    setShowPasscodeModal(true);
  }, []);

  const closePasscodeModal = useCallback(() => {
    setShowPasscodeModal(false);
  }, []);

  const action = useMemo(() => {
    if (activeRecording?.id) {
      return (
        <StyledButtonContainer>
          {event?.meetingUrl && (
            <Button
              variant='secondary'
              onClick={() => {
                trackEvent(
                  'Calendar Page Interaction',
                  {
                    interaction_name: 'Join Meeting Button Clicked',
                    ...eventTrackingProps
                  },
                  ['user', 'workspace']
                );
                openCall(event.meetingUrl);
              }}
            >
              Join meeting
            </Button>
          )}
          <LinkButton
            to={activeRecording.recording_path}
            onClick={() => {
              trackEvent(
                'Calendar Page Interaction',
                {
                  interaction_name: 'View Live Transcript Button Clicked',
                  ...eventTrackingProps
                },
                ['user', 'workspace']
              );
            }}
          >
            View live transcript
          </LinkButton>
        </StyledButtonContainer>
      );
    }

    if (meeting?.id) {
      return (
        <StyledButtonContainer>
          <Button
            variant='secondary'
            onClick={() => {
              trackEvent(
                'Calendar Page Interaction',
                {
                  interaction_name: 'Join Meeting Button Clicked',
                  ...eventTrackingProps
                },
                ['user', 'workspace']
              );
              openCall(meeting.url);
            }}
          >
            Join meeting
          </Button>
          <Button
            onClick={() => {
              trackEvent(
                'Calendar Page Interaction',
                {
                  interaction_name: 'Start Recording Button Clicked',
                  ...eventTrackingProps
                },
                ['user', 'workspace']
              );
              onRecordingStart();
            }}
            disabled={isNonIdleState}
          >
            Start recording
          </Button>
        </StyledButtonContainer>
      );
    }
  }, [
    activeRecording?.id,
    activeRecording.recording_path,
    meeting?.id,
    meeting?.url,
    event.meetingUrl,
    trackEvent,
    eventTrackingProps,
    openCall,
    isNonIdleState,
    onRecordingStart
  ]);

  const renderRecord = useMemo(() => {
    if (isRecording) {
      return (
        <StyledAction>
          <Switch
            switchSize='small'
            checked={!stopping}
            onClick={() => handleStopRecording(meeting?.id)}
          >
            Recording
          </Switch>
        </StyledAction>
      );
    }

    if (meetingActive || isRecording) return null;

    return (
      <StyledAction>
        <Switch
          switchSize='small'
          disabled={!event.recordable}
          checked={event.record && event.recordable}
          onChange={handleToggleRecord}
          key={event.id}
        >
          Record
        </Switch>
      </StyledAction>
    );
  }, [
    event,
    handleToggleRecord,
    handleStopRecording,
    isRecording,
    meeting,
    meetingActive,
    stopping
  ]);

  const metadata = useMemo(
    () => (
      <MeetingCardMetadata
        isRecording={isRecording}
        title={title}
        event={event}
        meeting={meeting}
        openCall={url => {
          trackEvent(
            'Calendar Page Interaction',
            {
              interaction_name: 'Join Meeting Title Clicked',
              ...eventTrackingProps
            },
            ['user', 'workspace']
          );
          openCall(url);
        }}
        extraTitleColumns={renderRecord}
      />
    ),
    [
      isRecording,
      title,
      event,
      meeting,
      renderRecord,
      trackEvent,
      eventTrackingProps,
      openCall
    ]
  );

  const renderWorkspaceAccess = useMemo(
    () => (
      <WorkspaceAccessDropdown
        workspaceAccess={workspaceAccess}
        onSelect={handleChangeWorkspaceAccess}
        onShow={() => setIsWorkspaceAccessOpen(true)}
        onHide={() => setIsWorkspaceAccessOpen(false)}
        placement='bottom'
        css={['width: 232px;']}
      >
        <SelectorDropdownButton
          variant='stealth'
          label='Workspace access'
          value={workspaceAccess ? 'On' : 'Off'}
          isOpen={isWorkspaceAccessOpen}
        />
      </WorkspaceAccessDropdown>
    ),
    [workspaceAccess, handleChangeWorkspaceAccess, isWorkspaceAccessOpen]
  );

  const renderTemplateList = useMemo(
    () => (
      <NoteTemplateListDropdown
        templateList={meetingTemplateList}
        selectedTemplateId={selectedTemplateId}
        onSelect={handleChangeTemplate}
        onShow={() => setIsTemplateSelectorOpen(true)}
        onHide={() => setIsTemplateSelectorOpen(false)}
        placement='bottom'
        css={['width: 232px;']}
      >
        <SelectorDropdownButton
          variant='stealth'
          label='Template'
          value={selectedTemplate?.title || 'Select template'}
          isOpen={isTemplateSelectorOpen}
        />
      </NoteTemplateListDropdown>
    ),
    [
      meetingTemplateList,
      selectedTemplateId,
      handleChangeTemplate,
      selectedTemplate?.title,
      isTemplateSelectorOpen
    ]
  );

  const tags = useMemo(
    () => (
      <TagEditor
        tags={event.tags}
        accessibleTags={accessibleTags}
        onAddTag={calendarTagAdd}
        onRemoveTag={calendarTagRemove}
        onOptimisticAddTag={optimisticAddTag}
        onOptimisticRemoveTag={optimisticRemoveTag}
        onOpenTag={tag => {
          const initialFilters = {
            tags: [{ label: tag.text, value: tag.id }]
          };

          navigate('/app/meetings/all', {
            state: {
              initialFilters
            }
          });
        }}
      />
    ),
    [
      event.tags,
      accessibleTags,
      calendarTagAdd,
      calendarTagRemove,
      optimisticAddTag,
      optimisticRemoveTag,
      navigate
    ]
  );

  const overrideMessage = useMemo(() => {
    const hasMeeting = Boolean(meeting) && Object.keys(meeting).length > 0;
    if (
      event?.id &&
      !hasMeeting &&
      event?.recordOverridden !== 'NOT_OVERRIDDEN' &&
      event.record !== event.autoRecord
    ) {
      return (
        <StyledOverrideMessage>
          You’ve manually overridden the auto-record settings for this recurring
          event.{' '}
          <StyledOverrideMessageResetButton onClick={handleResetRecord}>
            Reset
          </StyledOverrideMessageResetButton>
        </StyledOverrideMessage>
      );
    }

    return null;
  }, [
    meeting,
    event?.id,
    event?.recordOverridden,
    event.record,
    event.autoRecord,
    handleResetRecord
  ]);

  const passcodeModal = useMemo(
    () =>
      showPasscodeModal && (
        <PasscodeModal
          meeting={meeting}
          onCancel={closePasscodeModal}
          closeModal={closePasscodeModal}
        />
      ),
    [showPasscodeModal, meeting, closePasscodeModal]
  );

  const showBotState = isNonIdleState && !isRecording;

  return (
    <>
      <StyledCardContainer>
        <StyledCard>
          <StyledTopContent>
            {metadata}
            {(showWorkspaceAccess || showTemplateSelector) && (
              <StyledOptions>
                {showWorkspaceAccess && renderWorkspaceAccess}
                {showTemplateSelector && renderTemplateList}
              </StyledOptions>
            )}
            {event.tags && <div>{tags}</div>}
          </StyledTopContent>
          {action}
          {(warning || showBotState) && (
            <>
              <StyledDivider />
              {showBotState ? (
                <BotStateIndicator
                  meeting={meeting}
                  openPasscodeModal={openPasscodeModal}
                  botState={botState}
                />
              ) : warning ? (
                <WarningIndicator warning={warning} />
              ) : null}
            </>
          )}
        </StyledCard>
        {overrideMessage}
      </StyledCardContainer>
      {passcodeModal}
    </>
  );
}
