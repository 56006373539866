import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  SHARE_STORY_ID,
  SHARE_RECORDING_ID
} from '@grain/components/modals/constants';
import {
  useStoryDeleteMutation,
  useStoryCreateMutation
} from '~/modules/stories/index.generated';
import { getRandomBannerImageUrl } from '~/pages/StoryPage/constants';
import { useShowToast, useConfirm, useRouteModal } from '@grain/grain-ui';

import { useUpdateAdHocCache } from '~/modules/contentFilter/cacheHelpers';
import { pluralize } from '~/support/language';
import {
  useRecordingsDeleteMutation,
  useRecordingsTagAddMutation,
  useClipsDeleteMutation
} from './library.generated';

export const useLinkActions = () => {
  const navigate = useNavigate();

  const viewZoomImport = useCallback(() => {
    navigate('/app/import-zoom');
  }, [navigate]);

  const viewUploadRecording = useCallback(() => {
    navigate('/app/upload-recording');
  }, [navigate]);

  const viewAllRecording = useCallback(
    () => navigate('/app/meetings'),
    [navigate]
  );

  const viewAllStories = useCallback(
    () => navigate('/app/stories'),
    [navigate]
  );

  const viewAllHighlights = useCallback(
    () => navigate('/app/clips'),
    [navigate]
  );

  const viewHighlights = useCallback(
    (url: string) => window.open(url, '__blank'),
    []
  );

  return {
    viewZoomImport,
    viewUploadRecording,
    viewAllRecording,
    viewAllStories,
    viewHighlights,
    viewAllHighlights
  };
};

export const useDeleteStory = () => {
  const [deleteStory] = useStoryDeleteMutation();
  const showConfirm = useConfirm();

  const handleDelete = useCallback(
    (storyId: string, storyTitle = 'Story') => {
      showConfirm({
        width: 400,
        confirmContent: 'Delete',
        description: 'This will delete this story for all members.',
        title: `Delete ${storyTitle}?`,
        onConfirm: () => {
          deleteStory({
            variables: {
              storyId
            },
            update(cache) {
              cache.evict({
                id: cache.identify({ __typename: 'Story', id: storyId })
              });
              cache.gc();
            }
          });
        }
      });
    },
    [deleteStory, showConfirm]
  );

  return handleDelete;
};

export const useAddStory = (trigger: string) => {
  const updateLocalData = useUpdateAdHocCache();
  const navigate = useNavigate();

  const [createStory] = useStoryCreateMutation({
    onCompleted(data) {
      updateLocalData(data?.storyCreate);
      navigate(`/app/stories/${data?.storyCreate?.id}?init=true`);
    }
  });

  const handleAddStory = () => {
    createStory({
      variables: {
        title: 'Untitled Story',
        bannerImageUrl: getRandomBannerImageUrl(),
        eventProperties: JSON.stringify({ trigger: trigger })
      }
    });
  };

  return handleAddStory;
};

export const useLibraryShare = () => {
  const [storyId, setStory] = useState<string | undefined>();
  const [recordingId, setRecording] = useState<string | undefined>();
  const { isOpen: shareStoryIsOpen, open: openStoryShare } =
    useRouteModal(SHARE_STORY_ID);

  const { isOpen: shareRecordingIsOpen, open: openRecordingShare } =
    useRouteModal(SHARE_RECORDING_ID);

  const handleOpenStoryShare = useCallback(
    (storyId: string) => {
      setStory(storyId);
      openStoryShare();
    },
    [openStoryShare]
  );

  const handleOpenRecordingShare = useCallback(
    (recordingId: string) => {
      setRecording(recordingId);
      openRecordingShare();
    },
    [openRecordingShare]
  );

  return {
    storyId,
    shareStoryIsOpen,
    openStoryShare: handleOpenStoryShare,
    recordingId,
    shareRecordingIsOpen,
    openRecordingShare: handleOpenRecordingShare
  };
};

export const useTagRecordings = () => {
  const [recordingsTagAdd] = useRecordingsTagAddMutation();
  const showToast = useShowToast();

  const handleTag = useCallback(
    (recordingIds: string[], text: string) => {
      recordingsTagAdd({
        variables: {
          recordingIds,
          text
        },
        onCompleted() {
          showToast({
            content: 'Meetings tagged.',
            type: 'success'
          });
        }
      });
    },
    [recordingsTagAdd, showToast]
  );

  return handleTag;
};

function getDeleteRecordingsShowConfirmOptions(recordingIds: string[]) {
  return {
    width: 400,
    confirmContent: 'Delete',
    description:
      'This will delete the selected meetings and all associated clips.',
    title: `Delete ${recordingIds.length} ${pluralize(
      'meeting',
      'meetings',
      recordingIds.length
    )}?`
  };
}

export const useRecordingsDelete = () => {
  const [deleteRecordings] = useRecordingsDeleteMutation();
  const showToast = useShowToast();
  const showConfirm = useConfirm();

  return async function handleDelete(recordingIds: string[], done: () => void) {
    const result = await showConfirm(
      getDeleteRecordingsShowConfirmOptions(recordingIds)
    );
    if (result.isConfirm) {
      deleteRecordings({
        variables: {
          recordingIds
        },
        onCompleted() {
          showToast({
            content: 'Meetings deleted.',
            type: 'success'
          });
          done();
        }
      });
    }
  };
};

function getDeleteHighlightsShowConfirmOptions(clipIds: string[]) {
  return {
    width: 400,
    confirmContent: 'Delete',
    description: 'This will delete the selected clips.',
    title: `Delete ${clipIds.length} ${pluralize(
      'clip',
      'clips',
      clipIds.length
    )}?`
  };
}

export const useHighlightsDelete = () => {
  const [deleteClips] = useClipsDeleteMutation();
  const showToast = useShowToast();
  const showConfirm = useConfirm();

  return async function handleDelete(clipIds: string[], done?: () => void) {
    const result = await showConfirm(
      getDeleteHighlightsShowConfirmOptions(clipIds)
    );
    if (result.isConfirm) {
      deleteClips({
        variables: {
          clipIds
        },
        onCompleted() {
          showToast({
            content: 'Clips deleted.',
            type: 'success'
          });
          if (done) {
            done();
          }
        }
      });
    }
  };
};
