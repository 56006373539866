export const METADATA_ITEMS = [
  {
    id: 'owner',
    label: 'Owner',
    order: 0,
    gridColumnWidth: 'minmax(50px, 160px)'
  },
  {
    id: 'company',
    label: 'Company',
    order: 1,
    gridColumnWidth: 'minmax(50px, 160px)'
  },
  {
    id: 'participants',
    label: 'Participants',
    order: 2,
    gridColumnWidth: 'minmax(50px, 72px)'
  },
  {
    id: 'meetingTags',
    label: 'Meeting tags',
    order: 3,
    gridColumnWidth: 'minmax(50px, 160px)'
  },
  {
    id: 'trackers',
    label: 'Trackers',
    order: 4,
    gridColumnWidth: 'minmax(50px, 160px)'
  },
  {
    id: 'comments',
    label: 'Comments',
    order: 5,
    gridColumnWidth: 'minmax(50px, 72px)'
  },
  {
    id: 'clips',
    label: 'Clips',
    order: 6,
    gridColumnWidth: 'minmax(50px, 72px)'
  },
  {
    id: 'views',
    label: 'Views',
    order: 7,
    gridColumnWidth: 'minmax(50px, 72px)'
  },
  {
    id: 'meetingType',
    label: 'Meeting type',
    order: 8,
    gridColumnWidth: 'minmax(50px, 130px)'
  }
] as const;

export type MetadataItem = (typeof METADATA_ITEMS)[number];

export type MetadataItemId = MetadataItem['id'];

export const MetadataMap = new Map<MetadataItemId, MetadataItem>(
  METADATA_ITEMS.map(item => [item.id, item])
);
