import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Footer from './Footer';
import SSOAuth from './SSOAuth';
import { getUTMParams } from '~/support/campaigns';
import { spacing } from '@grain/styles/constants';
import { LinkButton, Icon, getTrackEventFunction } from '@grain/grain-ui';
import { LoginButton } from '~/components/LoginButton';

import {
  StyledCard,
  StyledCardHeaderText,
  StyledCardSubHeaderText,
  StyledCardWrapper,
  StyledContainer,
  StyledHeaderBar,
  StyledHeaderLogo,
  StyledHeaderSignup,
  StyledLayoutContainer,
  loginButtonStyles
} from './styles';

import { useUrlErrorBanner } from '~/modules/errors/hooks';

import api from '@grain/components/support/api';

type Provider = 'google' | 'microsoft' | 'zoom' | 'workos';

export default function Login() {
  const [searchParams] = useSearchParams();
  const path = searchParams.get('redirect_to');
  const action = searchParams.get('action') || 'web_login';
  const state = searchParams.get('state');
  const workspaceId = searchParams.get('sso_workspace_id') || false;
  const sso_token = searchParams.get('sso_token');

  const trackEvent = getTrackEventFunction('', api);

  const ErrorBanner = useUrlErrorBanner();

  const [isSSO, setSSO] = useState(false);

  const trackLoginClick = (provider: Provider) => {
    trackEvent('Sign In Clicked', {
      signup_type: provider
    });
  };

  const redirect = (provider: Provider) =>
    `/app/_/redirect/${provider}?action=${action}${
      !path ? '' : `&redirect_to=${encodeURIComponent(path)}`
    }${!state ? '' : `&state=${state}`}`;

  const onSSOSubmit = (e: React.MouseEvent) => {
    e.preventDefault();

    const path = sso_token
      ? `${redirect(
          'workos'
        )}&sso_workspace_id=${workspaceId}&sso_token=${sso_token}`
      : `${redirect('workos')}&sso_workspace_id=${workspaceId}`;

    window.location.href = path;
    return false;
  };

  const handleSSOClicked = (e: React.MouseEvent) => {
    if (workspaceId) {
      onSSOSubmit(e);
    } else {
      setSSO(current => !current);
    }
  };

  return (
    <StyledContainer>
      <ErrorBanner />
      <StyledLayoutContainer>
        <StyledHeaderBar>
          {!workspaceId && (
            <StyledHeaderSignup>
              <LinkButton
                css={['width: 108px;']}
                size='large'
                variant='secondary'
                to={`/app/signup?${
                  !action ? '' : `action=${action.split('_')[0]}_register`
                }${!state ? '' : `&state=${state}`}`}
              >
                Sign up
              </LinkButton>
            </StyledHeaderSignup>
          )}
        </StyledHeaderBar>
        <StyledHeaderLogo>
          <a
            href={`/?${getUTMParams({
              source: 'app',
              medium: 'site_link',
              content: 'product',
              campaign: 'signin_logo'
            })}`}
          >
            <Icon.GrainLogoAndWord />
          </a>
        </StyledHeaderLogo>
        <StyledCardWrapper>
          <StyledCard>
            <StyledCardHeaderText>Welcome back.</StyledCardHeaderText>
            <StyledCardSubHeaderText>
              Capture, transcribe, & share the best parts of your
              <br />
              customer meetings.
            </StyledCardSubHeaderText>
            <div className='content-wrapper'>
              {!workspaceId && !isSSO ? (
                <>
                  <div css={['width: 100%']}>
                    <LoginButton
                      as='a'
                      loginType='Google'
                      href={redirect('google')}
                      css={[loginButtonStyles]}
                      onClick={() => trackLoginClick('google')}
                    >
                      Sign in with Google
                    </LoginButton>
                  </div>
                  <div css={[spacing.mt5, 'width: 100%;']}>
                    <LoginButton
                      as='a'
                      loginType='Microsoft'
                      href={redirect('microsoft')}
                      css={[loginButtonStyles]}
                      onClick={() => trackLoginClick('microsoft')}
                    >
                      Sign in with Outlook
                    </LoginButton>
                  </div>
                  <div css={[spacing.mt5, 'width: 100%;']}>
                    <LoginButton
                      as='a'
                      loginType='Zoom'
                      href={redirect('zoom')}
                      css={[loginButtonStyles]}
                      onClick={() => trackLoginClick('zoom')}
                    >
                      Sign in with Zoom
                    </LoginButton>
                  </div>
                  <div css={[spacing.mt5, 'width: 100%;']}>
                    <LoginButton
                      as='a'
                      loginType='SSO'
                      variant='ghost'
                      css={[loginButtonStyles]}
                      onClick={handleSSOClicked}
                    >
                      Sign in with SSO
                    </LoginButton>
                  </div>
                </>
              ) : (
                isSSO && <SSOAuth redirect={`${redirect('workos')}&email=`} />
              )}

              {workspaceId && (
                <div css={[spacing.mt4, 'width: 100%;']}>
                  <LoginButton
                    as='a'
                    loginType='SSO'
                    variant='ghost'
                    css={[loginButtonStyles]}
                    onClick={handleSSOClicked}
                  >
                    Sign in with SSO
                  </LoginButton>
                </div>
              )}

              {isSSO && (
                <div className='sso-toggle' css={[spacing.mt4]}>
                  <a tabIndex={0} onClick={handleSSOClicked}>
                    or Sign In with other options
                  </a>
                </div>
              )}
              <Footer css={['margin: 30px 0; text-align: center;']} />
            </div>
          </StyledCard>
        </StyledCardWrapper>
      </StyledLayoutContainer>
    </StyledContainer>
  );
}
