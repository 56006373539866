import styled from '@emotion/styled';
import { getBasePublicPath } from '@grain/grain-ui';
import { theme } from '@grain/grain-ui/v4';

export const ModalLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 658px;
  padding: 73px 119px 0;
`;

export function ModalLogo() {
  return (
    <img
      src={`${getBasePublicPath()}/images/brand/grain-primary-standard.svg`}
      css={['height: 24px; margin-bottom: 48px;']}
    />
  );
}

const StyledModalInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing.md};
  text-align: center;
  text-wrap: balance;
`;

const StyledTitle = styled.div`
  ${theme.tokens.typography.h2};
  color: ${theme.tokens.color.textPrimary};
`;

const StyledBody = styled.div`
  ${theme.tokens.typography.b2[400]};
  line-height: 22px;
  color: ${theme.tokens.color.textSecondary};
`;

type ModalInfoProps = {
  title: string;
  body: string;
};

export function ModalInfo({ title, body }: ModalInfoProps) {
  return (
    <StyledModalInfo>
      <StyledTitle>{title}</StyledTitle>
      {body && <StyledBody>{body}</StyledBody>}
    </StyledModalInfo>
  );
}
