import styled from '@emotion/styled';
import { color, colors, spacing } from '@grain/styles/constants';
import { css } from '@emotion/react';
import { flex, Logo, hexToRgba, pxToRem } from '@grain/grain-ui';

import Button from '@grain/components/Button';

export const StyledWorkspacesContainer = styled.div`
  width: 520px;
  ${spacing.p7};
  border-radius: 12px;
  border: 1px solid ${hexToRgba(colors.graieen, 0.5)};
  background-color: ${hexToRgba(colors.graieen, 0.03)};
  ${flex.direction.column};
`;

export const StyledNewWorkspaceDescription = styled.span`
  flex: none;
  ${spacing.mb4};
  margin-top: auto;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(20)};
  font-weight: 500;
  ${color.crow};
`;

export const StyledCreateWorkspaceCta = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: ${pxToRem(14)};
  cursor: pointer;
  line-height: ${pxToRem(20)};
  text-decoration-line: underline;
  ${color.pigeon};
  ${spacing.mb8};
`;

export const StyledWorkspaceContainer = styled.div`
  ${flex.alignItems.center};
  width: 100%;
  padding-right: 8px;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

export const StyledWorkspaceListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    border-radius: 8px;
    background: #c9c9c9;
    backdrop-filter: blur(15px);
  }
`;

export const StyledWorkspaceLabel = styled.div`
  flex: 1 1 auto;
  ${flex.direction.column};
`;

export const StyledWorkspaceName = styled.span`
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(18)};
  font-weight: 600;
  ${color.blackbird};
`;

export const StyledWorkspaceDescription = styled.span`
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(24)};
  font-weight: 400;
  ${color.crow};
  margin-top: 2px;
`;

export const StyledJoinButton = styled(Button)`
  ${spacing.ml4};
  flex: none;
`;

export const StyledRequestButton = styled(StyledJoinButton)<{
  requested: boolean;
}>`
  width: 130px;

  ${props =>
    props.requested &&
    css`
      ${color.pigeon};
      background: ${colors.gull};

      :hover {
        ${color.pigeon};
        background-color: ${colors.gull};
      }
    `}
`;

export const StyledLogo = styled(Logo)`
  flex: none;
  border-radius: 8px;
  ${spacing.mr4};
`;

export const StyledFooterContainer = styled.div`
  margin: 0 auto;
  ${spacing.mt7};
  text-align: center;
`;
