import styled from '@emotion/styled';
import { Divider, color, font, spaces, useConfirm } from '@grain/grain-ui';
import { useNavigate } from 'react-router-dom';
import { Icon16, MenuButton, Menu, Button } from '@grain/grain-ui/v4';
import { GatedMenuButton } from '~/modules/gates/GatedMenuButton';

type ScorecardListItemProps = {
  id: string;
  title: string;
  description?: string | null;
  onDelete: (scorecardId: string) => Promise<void>;
};

const Container = styled.div`
  border: 1px solid ${color.gull};
  border-radius: 8px;
  padding: ${spaces[4]} ${spaces[6]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spaces[4]};

  &:last-child {
    margin-bottom: 0;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  ${font.v4.h6};
  margin: 0 0 ${spaces[2]} 0;
`;

const Description = styled.p`
  ${font.v4.b2[400]};
  ${color.fg.crow};
  margin: 0;
`;

export const ScorecardListItem = ({
  id,
  title,
  description,
  onDelete
}: ScorecardListItemProps) => {
  const showConfirm = useConfirm();
  const navigate = useNavigate();

  const handleDelete = (scorecardId: string) => {
    showConfirm({
      width: 420,
      cancelContent: 'Cancel',
      confirmContent: 'Delete',
      confirmButtonType: 'danger',
      async onConfirm() {
        await onDelete(scorecardId);
      },
      title: 'Delete this scorecard?'
    });
  };

  return (
    <Container>
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContainer>
      <Menu
        unmountOnClose
        hideOnClickInside
        stopClickPropagation
        tippyProps={{ placement: 'bottom-end' }}
        width='232px'
        content={
          <>
            <GatedMenuButton
              gate='scorecard'
              onClick={() =>
                navigate(
                  `/app/settings/workspace?tab=scorecards-manage&scorecardId=${id}`
                )
              }
              label='Edit Scorecard'
              textLabelProps={{ startIcon: Icon16.Edit }}
            />
            <Divider />
            <MenuButton
              onClick={() => handleDelete(id)}
              label='Delete Scorecard'
              textLabelProps={{ startIcon: Icon16.Trash }}
            />
          </>
        }
      >
        <Button
          data-cy='meeting-overflow'
          variant='ghost'
          size='lg'
          icon={Icon16.Overflow}
        />
      </Menu>
    </Container>
  );
};
