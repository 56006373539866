import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Modal, ModalTitle } from '@grain/grain-ui';
import { Button, Input, theme } from '@grain/grain-ui/v4';
import { useTitleFilter } from '../filters/useTitleFilter';
import { useState } from 'react';

type TitleFilterModalProps = {
  filter: ReturnType<typeof useTitleFilter>;
  handleCancel: () => void;
};

const Content = styled.div`
  ${theme.utils.px('lg')};
  ${theme.utils.py('2xl')};

  @media (${theme.tokens.breakpoints.lgMin}) {
    ${theme.utils.px('3xl')};
  }
`;

const StyledModal = styled(Modal)`
  max-width: 516px;
  width: 95vw;
`;

export const TitleFilterModal = ({
  handleCancel,
  filter
}: TitleFilterModalProps) => {
  const [value, setValue] = useState(filter.value);

  return (
    <StyledModal onCancel={handleCancel} centered closable>
      <ModalTitle
        title='Filter by Title'
        css={css`
          text-align: center;
        `}
      />
      <Content>
        <Input
          value={value}
          autoFocus
          onChange={e => setValue(e.target.value)}
          placeholder='Meeting Title'
          css={css`
            width: 100%;
            margin-bottom: ${theme.tokens.spacing['2xl']};

            & input {
              width: inherit;
            }
          `}
        />
        <div
          css={css`
            display: flex;
            justify-content: center;
            gap: ${theme.tokens.spacing.lg};
          `}
        >
          <Button variant='neutral' size='lg' onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant='primary'
            size='lg'
            disabled={value.length === 0}
            onClick={() => {
              filter.setValue(value);
              handleCancel();
            }}
          >
            Filter
          </Button>
        </div>
      </Content>
    </StyledModal>
  );
};
