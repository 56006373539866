import styled from '@emotion/styled';
import { Icon, color, font, spacing } from '@grain/grain-ui';

export const IconClose = styled(Icon.Close)`
  cursor: pointer;
  ${color.fg.pigeon};
  ${spacing.ml2};
`;

export const ShowAdvancedButton = styled.button`
  all: unset;
  cursor: pointer;
  ${font.v4.b2[400]}
  ${color.fg.graieen};
`;
