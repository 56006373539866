import styled from '@emotion/styled';
import { theme } from '@grain/grain-ui/v4';

export const StyledDetailsTable = styled.table`
  th,
  td {
    padding: 0;
    white-space: nowrap;
  }

  th {
    padding: ${theme.tokens.spacing.sm} ${theme.tokens.spacing.lg};
    text-align: left;
    vertical-align: top;
    border-bottom: 1px solid ${theme.tokens.color.borderTertiary};
    color: ${theme.tokens.color.textSecondary};
    ${theme.tokens.typography.b4[500]};
  }

  td {
    padding: ${theme.tokens.spacing.lg};
    ${theme.tokens.typography.b3[500]};
  }

  td.value {
    text-align: right;
    color: ${theme.tokens.color.textSecondary};
  }

  tr:not(:last-child) td {
    border-bottom: 1px solid ${theme.tokens.color.borderTertiary};
  }
`;
