import * as Layout from '~/components/Layout';
import { MeetingsPageTitle } from './components/PageTitle';
import { Helmet } from 'react-helmet';
import { Divider, EmptyState, Tab, Tabs, TabsBar } from '@grain/grain-ui/v4';
import {
  useFiltersManager,
  UseFiltersManagerProps
} from '~/modules/filtersV2/useFiltersManager';
import { FilterBar } from '~/modules/filtersV2/FilterBar/FilterBar';
import { useNavigate } from 'react-router-dom';
import {
  AdHocRecordingFragment,
  useSampleQuery,
  useViewAdHocQuery
} from '~/modules/contentFilter/contentfilter.generated';
import { SortDirection, ViewAdHocSortBy } from '@grain/api/schema.generated';
import { Fragment, useMemo, useState } from 'react';
import { format, isSameYear, isToday, isYesterday } from 'date-fns';
import { MeetingListItem } from './components/MeetingListItem';
import { MeetingTabId } from './types';
import { EmptyMeetingsState } from './components/EmptyMeetingsState';
import { MeetingDateHeader } from '~/modules/meetings/MeetingDateHeader';
import { MeetingsShareModal } from './components/MeetingShareModal';
import {
  useAnalytics,
  useConfirm,
  useRouteModal,
  useShowToast
} from '@grain/grain-ui';
import { SHARE_RECORDING_ID } from '@grain/components/modals/constants';
import { useAdHocSubscription } from '~/modules/filters/adhoc';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  MeetingActionBar,
  useActionBar
} from '~/modules/meetings/MeetingActionBar';
import pluralize from 'pluralize';
import { useRecordingsDeleteMutation } from '../Library/library.generated';
import { LabelWithValue } from '~/modules/filtersV2/types';

type MeetingTab = {
  id: MeetingTabId;
  label: string;
  pathname: string;
  initialFilterValues: UseFiltersManagerProps['initialValues'];
};

const tabs: MeetingTab[] = [
  {
    id: 'all-meetings',
    label: 'All meetings',
    pathname: '/app/meetings/all',
    initialFilterValues: {}
  },
  {
    id: 'my-meetings',
    label: 'My meetings',
    pathname: '/app/meetings',
    initialFilterValues: {
      viewer_role: 'attendee'
    }
  },
  {
    id: 'shared',
    label: 'Shared with me',
    pathname: '/app/meetings/shared',
    initialFilterValues: {
      viewer_role: 'absent',
      explicit_shared: true
    }
  }
];

const formatDate = (date: Date) => {
  if (isToday(date)) {
    return 'Today';
  } else if (isYesterday(date)) {
    return 'Yesterday';
  } else if (isSameYear(date, new Date())) {
    return format(date, 'EEEE, MMM do'); // Example: Monday, Aug 26th
  } else {
    return format(date, 'EEEE, MMM do, yyyy'); // Example: Monday, Aug 26th 2023
  }
};

const MeetingsPage = () => {
  const showToast = useShowToast();
  const showConfirm = useConfirm();
  const { isOpen: shareRecordingIsOpen, open: openRecordingShare } =
    useRouteModal(SHARE_RECORDING_ID);
  const [selectedRecordingId, setSelectedRecordingId] = useState<string | null>(
    null
  );
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();

  const currentPathnameWithoutTrailingSlash = location.pathname.replace(
    /\/+$/, // trailing slashes
    ''
  );
  const activeIndex = tabs.findIndex(
    tab => tab.pathname === currentPathnameWithoutTrailingSlash
  );
  const setActiveIndex = (val: number) => {
    const newTab = tabs[val];
    navigate(newTab.pathname);
    trackEvent('Meeting Library Tabs Changed', { tab: newTab.label }, [
      'user',
      'workspace'
    ]);
  };
  const activeTab = tabs[activeIndex];

  const { filters, activeFilters, resetFilters, viewAdHocFilterString } =
    useFiltersManager({
      filterEntityType: 'recordings',
      availableFilters: [
        'owners',
        'persons',
        'groups',
        'smart_tags',
        'tags',
        'date',
        'title',
        'explicit_shared',
        'viewer_role',
        'participant_scope',
        'workspace_access'
      ],
      storageType: { type: 'locationState' },
      hiddenFilters: ['explicit_shared', 'viewer_role'],
      initialValues: {
        ...activeTab.initialFilterValues
      }
    });

  useAdHocSubscription(viewAdHocFilterString);

  const { data, fetchMore, loading, error } = useViewAdHocQuery({
    variables: {
      filter: viewAdHocFilterString,
      sortBy: ViewAdHocSortBy.Chronological,
      sortDirection: SortDirection.Desc
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  });
  const recordings = useMemo(
    () =>
      (data?.viewAdHoc?.list || []).filter(
        item => item.__typename === 'Recording'
      ),
    [data]
  );

  // Fetch it in case there are no other recordings, so it can be shown quicker.
  const { data: sampleRecordingData } = useSampleQuery();
  const sampleRecording = sampleRecordingData?.recordingSample;

  const {
    selectedMeetingIds,
    resetSelected,
    toggleSelectedMeetingId,
    showActionBar,
    canDelete
  } = useActionBar(recordings);

  const [deleteRecordings] = useRecordingsDeleteMutation({
    update: cache => {
      selectedMeetingIds.forEach(id => {
        const normalizedId = cache.identify({ id, __typename: 'Recording' });
        cache.evict({ id: normalizedId });
        cache.gc();
        resetSelected();
      });
    },
    onCompleted: () => {
      showToast({
        content: 'Meetings deleted.',
        type: 'success'
      });
    }
  });

  const [isResettingTitleFilter, setIsResettingTitleFilter] = useState(false);

  const resetFiltersWithLoading = () => {
    setIsResettingTitleFilter(true);
    resetFilters();
    filters.title.setValue('');
    setTimeout(() => setIsResettingTitleFilter(false), 100);
  };

  const recordingsByDate = useMemo(() => {
    return recordings.reduce(
      (acc, recording) => {
        const dateFmt = formatDate(new Date(recording.startDatetime));
        if (!(dateFmt in acc)) {
          acc[dateFmt] = [];
        }
        acc[dateFmt].push(recording);
        return acc;
      },
      {} as Record<string, AdHocRecordingFragment[]>
    );
  }, [recordings]);

  const hasTitleFilter = filters.title.value !== '';
  const cursor = data?.viewAdHoc?.cursor;
  const hasFilters = Object.keys(activeFilters).length >= 1 || hasTitleFilter;
  const isLoading = loading || isResettingTitleFilter;
  const showEmptyState =
    !error && !isLoading && !hasFilters && recordings.length === 0;
  const showNoResults =
    !error && !isLoading && hasFilters && recordings.length === 0;
  const showFullErrorState = error && !isLoading && recordings.length === 0;

  const setOwnerFilter = (owner: LabelWithValue) => {
    filters.owners.setValue([owner]);
  };

  const setCompanyFilter = (company: LabelWithValue) => {
    filters.groups.setValue([company]);
  };

  const setPersonFilter = (person: LabelWithValue) => {
    filters.persons.setValue([person]);
  };

  return (
    <>
      <Helmet title='Meetings' />
      <Layout.Wrapper withSidebar>
        <MeetingsPageTitle />
        <Divider />
        <TabsBar>
          <Tabs currentValue={activeIndex} onChange={setActiveIndex}>
            {tabs.map((tab, index) => (
              <Tab value={index} key={tab.label}>
                {tab.label}
              </Tab>
            ))}
          </Tabs>
        </TabsBar>
        <Divider />
        <FilterBar filters={filters} resetAllFilters={resetFilters} />
        <Divider />
        <div
          id='infinite-scrollable-div'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <InfiniteScroll
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'unset'
            }}
            dataLength={recordings.length}
            hasMore={Boolean(data && cursor)}
            next={() =>
              fetchMore({
                variables: {
                  cursor
                }
              })
            }
            loader={<MeetingListItem.Skeleton />}
            scrollableTarget='infinite-scrollable-div'
          >
            {loading && recordings.length === 0 && (
              <>
                <MeetingDateHeader.Skeleton />
                {Array.from({ length: 20 }, (_, i) => (
                  <Fragment key={i}>
                    <MeetingListItem.Skeleton />
                    <Divider />
                  </Fragment>
                ))}
              </>
            )}
            {showFullErrorState && (
              <EmptyState.Error
                title='Error loading Meetings'
                onReload={() => {
                  fetchMore({
                    variables: {
                      cursor
                    }
                  });
                }}
              />
            )}
            {showNoResults && (
              <EmptyState.NoResults
                title='No content matches your filters'
                onClearFilters={resetFiltersWithLoading}
              />
            )}
            {showEmptyState && (
              <EmptyMeetingsState.Content section={activeTab.id} />
            )}
            {Object.entries(recordingsByDate).map(([date, recordings]) => (
              <section key={date}>
                <MeetingDateHeader>{date}</MeetingDateHeader>
                {recordings.map((recording, index) => (
                  <Fragment key={recording.id}>
                    {index > 0 && <Divider />}
                    <MeetingListItem
                      breadcrumbs={[
                        { title: 'Meetings', path: '/app/meetings' }
                      ]}
                      canConfigureMetadataItems
                      recording={recording}
                      toggleSelected={toggleSelectedMeetingId}
                      selected={selectedMeetingIds.includes(recording.id)}
                      forceShowCheckbox={showActionBar}
                      overflowOptions={[
                        'add_to_playlist',
                        'copy',
                        'delete',
                        'download',
                        'share'
                      ]}
                      onClickShare={() => {
                        setSelectedRecordingId(recording.id);
                        openRecordingShare();
                      }}
                      setOwnerFilter={setOwnerFilter}
                      setCompanyFilter={setCompanyFilter}
                      setPersonFilter={setPersonFilter}
                    />
                  </Fragment>
                ))}
              </section>
            ))}
          </InfiniteScroll>
        </div>
        {showActionBar && (
          <MeetingActionBar
            reset={resetSelected}
            selectedMeetingIds={selectedMeetingIds}
            canDelete={canDelete}
            onDelete={() =>
              showConfirm({
                width: 400,
                confirmContent: 'Delete',
                description:
                  'This will delete the selected meetings and all associated clips.',
                title: `Delete ${selectedMeetingIds.length} ${pluralize(
                  'meetings',
                  selectedMeetingIds.length
                )}?`,
                onConfirm: () => {
                  deleteRecordings({
                    variables: {
                      recordingIds: selectedMeetingIds
                    }
                  });
                }
              })
            }
          />
        )}
        {showEmptyState && (
          <EmptyMeetingsState.Banner sampleRecording={sampleRecording} />
        )}

        <MeetingsShareModal
          onCancel={() => setSelectedRecordingId(null)}
          recordingId={selectedRecordingId || ''}
          isShareOpen={shareRecordingIsOpen}
        />
      </Layout.Wrapper>
    </>
  );
};

export default MeetingsPage;
