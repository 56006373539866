import { color, flex, font, spaces, spacing } from '@grain/grain-ui';
import styled from '@emotion/styled';
import { SalesScoreCard } from '@grain/api/schema.generated';
import { SpeakerStatisticsQuery } from '~/modules/statistics/statistics.generated';
import {
  SpeakerStatisticsMetricsKeys,
  getMetricStatus,
  isValidMetricKey,
  metricsMap
} from '~/support/speakerMetrics';
import { useMemo } from 'react';
import {
  CATEGORY_ORDER,
  OPPORTUNITY_LABELS,
  SalesOpportunity
} from '~/support/scorecard';
import { OpportunityItem } from './OpportunityItem';

type SpeakerStatistics = NonNullable<
  NonNullable<SpeakerStatisticsQuery['recording']>['transcript']
>['speakerStatistics'][number];

type SalesOverviewProps = {
  summary: string;
  scoreCard: SalesScoreCard;
  speakerStatistics: SpeakerStatistics;
  onOpportunityClick: (tabId: string, item?: string) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid ${color.gull};
  overflow: hidden;
`;

const EmptyMessage = styled.span`
  ${font.v4.b2[400]}
  ${spacing.p6}
  color: ${color.fg.crow};
`;

export const SalesOverview = ({
  summary,
  scoreCard,
  speakerStatistics,
  onOpportunityClick
}: SalesOverviewProps) => {
  const outOfRangeMetrics = useMemo(
    () =>
      !speakerStatistics
        ? []
        : Object.entries(speakerStatistics)
            .filter(([key, value]) => {
              return (
                isValidMetricKey(key) &&
                getMetricStatus(key, value as number) === 'critical'
              );
            })
            .map(([key, value]) => {
              return { key, value } as {
                key: SpeakerStatisticsMetricsKeys;
                value: number;
              };
            }),
    [speakerStatistics]
  );

  const lowScoreCategories = useMemo(() => {
    if (!scoreCard) return [];

    const { __typename, ..._scoreCard } = scoreCard;

    return (
      Object.entries(_scoreCard).filter(
        ([key, value]) => key !== 'overall' && [1, 2].includes(Number(value))
      ) as Array<[SalesOpportunity, number]>
    ).sort(
      (a, b) => CATEGORY_ORDER.indexOf(a[0]) - CATEGORY_ORDER.indexOf(b[0])
    );
  }, [scoreCard]);

  const isSummaryEmpty =
    !summary || summary.trim() === 'Meeting outcome not available';

  return (
    <div
      css={[
        flex.direction.column,
        { gap: spaces[6], paddingBottom: spaces[4] }
      ]}
    >
      <Container css={[spacing.p6, { gap: spaces[6] }]}>
        <h6 css={[font.v4.h6, spacing.m0]}>Meeting Outcome</h6>
        {isSummaryEmpty ? (
          <EmptyMessage css={{ padding: 0 }}>{summary}</EmptyMessage>
        ) : (
          <p css={[font.v4.b2[400], spacing.m0]}>{summary}</p>
        )}
      </Container>
      <Container>
        <h6 css={[font.v4.h6, spacing.m6, 'margin-bottom:8px;']}>
          Coaching Opportunities
        </h6>
        {lowScoreCategories.length > 0 &&
          lowScoreCategories.map(([key, value]) => (
            <OpportunityItem
              key={key}
              opportunityKey={key}
              label={OPPORTUNITY_LABELS[key]}
              type='scoring'
              value={value}
              onClick={onOpportunityClick}
            />
          ))}
        {outOfRangeMetrics.length > 0 &&
          outOfRangeMetrics.map(metric => {
            const { title, valueFormatter } = metricsMap[metric.key];
            const displayValue =
              valueFormatter?.(metric.value, {
                isSingleSpeaker: true
              }) ?? metric.value;

            return (
              <OpportunityItem
                key={metric.key}
                opportunityKey={metric.key}
                label={title}
                type='metrics'
                value={displayValue}
                onClick={onOpportunityClick}
              />
            );
          })}
        {lowScoreCategories.length === 0 && outOfRangeMetrics.length === 0 && (
          <EmptyMessage>No coaching opportunities</EmptyMessage>
        )}
      </Container>
    </div>
  );
};
