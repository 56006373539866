import React from 'react';
import { ErrorBox, useShowToast, useConfirm } from '@grain/grain-ui';
import PropTypes from 'prop-types';
import { useMutation } from '@grain/api/graphql';
import format from 'date-fns/format';
import { font, spacing } from '@grain/styles/constants';
import { displayCents } from '~/support/price';
import { useCardBrand, images as cardImages } from 'react-card-brand';
import { useWorkspaceWithMembers } from '@grain/components/Workspace/hooks';
import {
  useWorkspacePlan,
  useUpcomingWorkspacePlan
} from '@grain/components/Subscriptions/hooks';
import {
  setSubscriptionAutoRenewMutation,
  addWorkspaceUsersToSubscriptionMutation
} from '../graphql';
import {
  SubscriptionExpirationExplanation,
  displayPeriodAdjective
} from '../helpers';
import Button from '@grain/components/Button';
import UpdatePaymentModal from './UpdatePaymentModal';

BillingStatus.propTypes = {
  showContinuePlanMessage: PropTypes.bool,
  showNextBill: PropTypes.bool
};

export default function BillingStatus({
  showNextBill,
  showContinuePlanMessage,
  ...rest
}) {
  const { workspace } = useWorkspaceWithMembers();
  const workspaceSubscription = workspace?.workspaceSubscription;
  const { getSvgProps } = useCardBrand();
  const svgProps = getSvgProps({
    type: workspaceSubscription?.cardBrand,
    images: cardImages,
    height: 48,
    width: 32
  });
  const showToast = useShowToast();
  const [showUpdateModal, setShowUpdateModal] = React.useState(false);
  const showConfirm = useConfirm();

  const { subscriptionPlan: workspacePlan } = useWorkspacePlan();
  const { subscriptionPlan: upcomingPlan } = useUpcomingWorkspacePlan();

  const isTrial =
    workspaceSubscription?.trial && !workspaceSubscription?.autoRenew;

  const [mutateAutoRenew, mutateAutoRenewRes] = useMutation(
    setSubscriptionAutoRenewMutation,
    {
      onCompleted(res) {
        const workspace = res.subscriptionAutoRenewSet;
        const autoRenew = workspace.workspaceSubscription.autoRenew;
        if (autoRenew) {
          showToast({
            type: 'success',
            content: 'Subscription reactivated.'
          });
        }
      }
    }
  );

  const paidUsers = React.useMemo(() => {
    return (workspace?.users ?? []).filter(user => user.onPlan);
  }, [workspace?.users]);

  const hasNoActivatedUsers = React.useMemo(() => {
    return (
      (workspace?.users ?? []).filter(user => user.staysOnPlan).length === 0
    );
  }, [workspace?.users]);

  const [addUsersToSubscription] = useMutation(
    addWorkspaceUsersToSubscriptionMutation,
    {}
  );

  const handleReactivate = async ev => {
    ev.preventDefault(); // don't toggle its internal value

    await showConfirm({
      width: 420,
      cancelContent: 'Go Back',
      confirmContent: 'Continue',
      confirmButtonType: 'primary',
      confirmButtonProps: {
        css: ['width: 100px;']
      },
      onConfirm: async () => {
        if (hasNoActivatedUsers) {
          const userIds = paidUsers.map(({ id }) => id);
          await addUsersToSubscription({
            variables: { userIds }
          });
        }
        await mutateAutoRenew({
          variables: { shouldAutoRenew: true }
        });
      },
      title: 'Confirm billing change',
      description: (
        <>
          You are about to <b>reactivate </b> your <b>{workspacePlan?.name}</b>{' '}
          plan
        </>
      )
    });
  };

  const formatAutoRenewErrorMessage = error => {
    return error?.message;
  };

  const openBillingModal = () => setShowUpdateModal(true);

  // react-card-brand takes up a LOT of room, more than it visibly looks like.
  const CARD_EXTRA_ROOM = 12;

  const paymentFormProps = {
    title: 'Update Payment Method',
    buttonText: 'Submit',
    defaultBillingEmail: workspaceSubscription?.billingEmail,
    summaryContent: (
      <>
        This will be used for your next bill of{' '}
        <b>${displayCents(workspaceSubscription?.renewalPrice)}</b> on{' '}
        <b>
          {new Date(workspaceSubscription?.renewalDate).toLocaleDateString({
            dateStyle: 'long'
          })}
        </b>
      </>
    ),
    onSuccess: () => {
      showToast({
        content: 'Payment information updated successfully.',
        type: 'success'
      });
      setShowUpdateModal(false);
    }
  };

  return (
    <div {...rest}>
      <div css={[font.bodyL]}>Billing</div>
      <div css={[font.bodyM, 'font-weight: normal;', spacing.mt6]}>
        {showUpdateModal && (
          <UpdatePaymentModal
            onClose={() => setShowUpdateModal(false)}
            {...paymentFormProps}
          />
        )}
        {workspaceSubscription?.cardLastFour && (
          <>
            <div style={{ marginTop: -CARD_EXTRA_ROOM }} />
            <div
              className='fs-block'
              css={['display: flex; align-items: center;']}
            >
              <svg {...svgProps} />
              <div css={[spacing.ml1]}>
                Card ending in <b>{workspaceSubscription?.cardLastFour}</b> ·
                Exp{' '}
                {workspaceSubscription &&
                  format(
                    new Date(workspaceSubscription?.cardExpiration),
                    'MM/yy'
                  )}
              </div>
            </div>
            <div style={{ marginTop: -CARD_EXTRA_ROOM }} />
          </>
        )}
        {showNextBill && (
          <div className='fs-block' css={[spacing.mt2]}>
            {upcomingPlan?.sku === workspacePlan?.sku ? (
              <>
                Your next{' '}
                {
                  displayPeriodAdjective[
                    workspaceSubscription?.renewalBillingPeriod
                  ]
                }{' '}
                bill is for{' '}
                <b>${displayCents(workspaceSubscription?.renewalPrice)}</b> on{' '}
                <b>
                  {new Date(
                    workspaceSubscription?.renewalDate
                  ).toLocaleDateString({
                    dateStyle: 'long'
                  })}
                </b>
              </>
            ) : (
              <SubscriptionExpirationExplanation />
            )}
          </div>
        )}
        {!!workspaceSubscription?.billingEmail && (
          <div css={[spacing.mt2]}>
            Receipts sent to{' '}
            <b className='fs-block'>{workspaceSubscription?.billingEmail}</b>
          </div>
        )}
        {isTrial ? null : workspaceSubscription?.cardLastFour ? (
          <>
            <div css={[spacing.mt4]} />
            {mutateAutoRenewRes.error && (
              <ErrorBox
                variant='minimal'
                css={spacing.mb2}
                error={formatAutoRenewErrorMessage(mutateAutoRenewRes.error)}
              />
            )}
            <div css={['display: flex; align-items: center;']}>
              <Button type='secondary' onClick={openBillingModal}>
                Update
              </Button>
              {!workspaceSubscription?.planDetails?.isFree &&
                !workspaceSubscription?.autoRenew && (
                  <div css={spacing.ml2}>
                    <Button
                      css={['width: 120px;']}
                      type='primary'
                      onClick={handleReactivate}
                    >
                      Reactivate
                    </Button>
                  </div>
                )}
            </div>
          </>
        ) : (
          <>
            {showContinuePlanMessage && (
              <div css={[spacing.my2]}>
                To continue your plan, enter payment information below.
              </div>
            )}
            <Button
              css={[spacing.my5]}
              onClick={openBillingModal}
              type='secondary'
            >
              Enter payment information
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
