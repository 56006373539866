import {
  Button,
  font,
  Icon,
  Modal,
  spacing,
  useRouteModal
} from '@grain/grain-ui';
import {
  StyledModalTitle,
  StyledDescription,
  StyledContainer,
  StyledPlanDetails,
  StyledPlanList,
  StyledBullet
} from './styles';
import { useWorkspacePlan } from '@grain/components/Subscriptions/hooks';
import { REQUEST_UPGRADE_ADMIN_ROUTE_ID } from '@grain/components/modals/constants';
import { useRequestAdminUpgrade } from '~/modules/gates/hooks';

export default function RequestAdminUpgrade() {
  const { close: closeModal } = useRouteModal(REQUEST_UPGRADE_ADMIN_ROUTE_ID);
  const requestUpgrade = useRequestAdminUpgrade({
    callback: closeModal,
    updateToWorkspacePlan: true
  });

  const { subscriptionPlan: workspacePlan } = useWorkspacePlan();
  return (
    <Modal
      width={600}
      closable
      onCancel={closeModal}
      maxContentHeight='100%'
      css={[spacing.p7]}
    >
      <StyledContainer>
        <StyledModalTitle>Upgrade to {workspacePlan.name}</StyledModalTitle>
        <StyledDescription>{workspacePlan.description}</StyledDescription>
        <StyledPlanDetails>
          <span css={[font.v4.h6]}>Everything you get with this upgrade</span>
          <StyledPlanList>
            {workspacePlan.featureBullets.map((feature, index) => (
              <StyledBullet key={index}>
                <Icon.CheckBullet css={['height: 14px; width: 14px;']} />
                {feature.name}
              </StyledBullet>
            ))}
          </StyledPlanList>
        </StyledPlanDetails>
        <Button css={['width: 100%']} onClick={() => requestUpgrade()}>
          Request upgrade from admin
        </Button>
      </StyledContainer>
    </Modal>
  );
}
