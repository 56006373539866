import { useMemo } from 'react';
import styled from '@emotion/styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import { format, isSameDay, isSameYear } from 'date-fns';
import { Recording } from '@grain/api/schema.generated';
import { Icon, Skeleton, color } from '@grain/grain-ui';
import { NoResultsState } from '~/components/NoResultsState';

import { RecordingListItem } from './RecordingListItem';
import { SkeletonRecordingListItem } from './SkeletonRecordingListItem';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ScrollContainer = styled.div`
  flex: 1;
  height: 100%;
  border-right: 1px solid ${color.gull};

  display: flex;
  flex-direction: column;
  > .infinite-scroll-component__outerdiv {
    flex: 1;
  }
`;

const DayHeading = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 32px;
  font-size: 12px;
  font-weight: 500;
  color: ${color.crow};
  background: ${color.goose};

  svg {
    width: 16px;
    height: 16px;
  }
`;

function SkeletonDayHeading() {
  return (
    <DayHeading>
      <Skeleton width='160px' height='20px' />
    </DayHeading>
  );
}

function SkeletonRecordingListItems({ count }: { count: number }) {
  return (
    <>
      {Array(count)
        .fill('')
        .map((_, index) => (
          <SkeletonRecordingListItem key={index} />
        ))}
    </>
  );
}

type RecordingsListProps = {
  isFilterActive: boolean;
  loading: boolean;
  resetAllFilters: () => void;
  currentRecordingId?: string;
  recordings: Recording[];
} & Pick<
  React.ComponentProps<typeof InfiniteScroll>,
  'dataLength' | 'hasMore' | 'next'
>;

export function RecordingsList({
  isFilterActive,
  loading,
  resetAllFilters,
  // adHocResults,
  currentRecordingId,
  recordings,
  dataLength,
  hasMore,
  next
}: RecordingsListProps) {
  const recordingsByDay = useMemo(() => {
    return recordings.reduce(
      (acc, recording) => {
        const today = new Date();
        const yesterday = (() => {
          const date = new Date();
          date.setDate(date.getDate() - 1);
          return date;
        })();
        const recordingDate = new Date(recording.startDatetime);
        const day = isSameDay(recordingDate, today)
          ? 'Today'
          : isSameDay(recordingDate, yesterday)
            ? 'Yesterday'
            : isSameYear(recordingDate, today)
              ? format(recordingDate, 'EEEE, MMM d')
              : format(recordingDate, 'EEEE, MMM d, y');
        acc[day] = acc[day] || [];
        acc[day].push(recording);
        return acc;
      },
      {} as Record<string, Recording[]>
    );
  }, [recordings]);

  const showNoResultsState = !loading && !recordings.length;

  return (
    <Container>
      <ScrollContainer
        id='infinite-scrollable-div'
        style={{
          overflow: 'auto',
          // Fix scrollbar rendering issue in Safari:
          // https://stackoverflow.com/a/72094910
          WebkitTransform: 'translateZ(0)'
        }}
      >
        {showNoResultsState && (
          <NoResultsState
            noResultsText='No external meetings in your workspace.'
            noMatchingResultsText='No results match your filters'
            isFilterActive={isFilterActive}
            resetAllFilters={resetAllFilters}
          />
        )}

        {loading ? (
          <>
            <SkeletonDayHeading />
            <SkeletonRecordingListItems count={10} />
          </>
        ) : (
          recordings.length > 0 && (
            <InfiniteScroll
              style={{
                overflow: 'visible'
              }}
              dataLength={dataLength}
              hasMore={hasMore}
              next={next}
              loader={<SkeletonRecordingListItems count={3} />}
              scrollableTarget='infinite-scrollable-div'
            >
              {Object.entries(recordingsByDay).map(([day, recordings]) => (
                <div key={day}>
                  <DayHeading>
                    <Icon.Calendar />
                    <span>{day}</span>
                  </DayHeading>
                  {recordings.map(recording => (
                    <RecordingListItem
                      key={recording.id}
                      active={recording.id === currentRecordingId}
                      viewed={recording.salesCoaching?.feedback?.viewed ?? true}
                      recording={recording}
                    />
                  ))}
                </div>
              ))}
            </InfiniteScroll>
          )
        )}
      </ScrollContainer>
    </Container>
  );
}
