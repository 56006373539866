import '../init';
import '@reach/dialog/styles.css';
import '@grain/grain-ui/public/global.css';
import '@webscopeio/react-textarea-autocomplete/style.css';
import 'focus-visible'; // :focus-visible polyfill
import 'react-datepicker/dist/react-datepicker.css';
import 'allotment/dist/style.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import smoothscroll from 'smoothscroll-polyfill';
import { ApolloProvider } from '@apollo/client';
import { RecoilRoot } from 'recoil';
import { BotProvider } from '~/components/StartBot';
import api from '@grain/components/support/api';
import initializeSentry, {
  SentryReplay
} from '@grain/components/support/sentry';
import initializeIntercom from '~/support/intercom';
import { setGitHash } from '@grain/components/support/environment';
import WebSocketProvider from '@grain/components/WebSocketContext';
import { SidebarProvider } from '~/components/Layout/Sidebar/context';
import GrainErrorBoundary from '~/components/ErrorBoundary';
import { ActiveRecordingsProvider } from '@grain/components/ActiveRecordingsContext';
import { HotspotProvider } from '~/components/Hotspot';
import {
  trackCurrentVersion,
  autoReloadOutdatedClients
} from '~/components/LayoutBanners/hooks';
import client from '~/support/apollo-client';
import AppRouter from '~/routers';
import GlobalFocusStyles from '@grain/components/GlobalFocusStyles';
import { AuthProvider } from '~/support/auth';
import { DesktopProvider } from '@grain/desktop-lib';
import {
  AnalyticsProvider,
  ToastProvider,
  ConfirmProvider,
  RecordingFileUploadProvider
} from '@grain/grain-ui';
import { SilentModeProvider } from '@grain/grain-ui/v4';
import socket from '~/support/socket';
/* eslint-enable import/first */

// https://github.com/emotion-js/emotion/issues/1549#issuecomment-554846600
window.React = React;

// Ignore connected deeplinks
if (window.todesktop) {
  window.todesktop.on('open-protocol-url', (_, event) => {
    if (event.url.includes('connected')) {
      event.preventDefault();
    }
  });
}

smoothscroll.polyfill();
initializeSentry();
initializeIntercom();
setGitHash();
trackCurrentVersion();
autoReloadOutdatedClients();

const App = () => {
  return (
    <GrainErrorBoundary isGeneric>
      <ApolloProvider client={client}>
        <RecoilRoot>
          <AnalyticsProvider api={api}>
            <DesktopProvider>
              <AuthProvider>
                <SilentModeProvider>
                  <ToastProvider>
                    <ConfirmProvider>
                      <HotspotProvider>
                        <WebSocketProvider phoenixSocket={socket}>
                          <ActiveRecordingsProvider>
                            <BotProvider>
                              <RecordingFileUploadProvider>
                                <SidebarProvider>
                                  <SentryReplay />
                                  <GlobalFocusStyles />
                                  <AppRouter />
                                </SidebarProvider>
                              </RecordingFileUploadProvider>
                            </BotProvider>
                          </ActiveRecordingsProvider>
                        </WebSocketProvider>
                      </HotspotProvider>
                    </ConfirmProvider>
                  </ToastProvider>
                </SilentModeProvider>
              </AuthProvider>
            </DesktopProvider>
          </AnalyticsProvider>
        </RecoilRoot>
      </ApolloProvider>
    </GrainErrorBoundary>
  );
};

const root = createRoot(document.getElementById('app'));
root.render(<App />);
