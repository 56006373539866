import styled from '@emotion/styled';
import { MenuButton, type MenuButtonProps, theme } from '@grain/grain-ui/v4';
import { GatedItemProps } from './hooks';
import { UpgradeContent, Badge } from './styles';
import { GatedUpgradeModal } from './GatedUpgradeModal';
import { useState } from 'react';
import { useFeature } from '@grain/api/auth';

const StyledBadge = styled(Badge)`
  background-color: ${theme.tokens.color.surfaceInvert};
`;

type GatedMenuButtonProps = MenuButtonProps & GatedItemProps;

export const GatedMenuButton = ({
  bypass,
  gate,
  ...rest
}: GatedMenuButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { enabled } = useFeature(gate);

  const showGate = !enabled && !bypass;

  return (
    <>
      <GatedUpgradeModal
        gate={gate}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      />
      <MenuButton
        {...rest}
        onClick={showGate ? () => setIsModalOpen(true) : rest.onClick}
        label={
          <UpgradeContent>
            <>{rest.label}</>
            {showGate && <StyledBadge>Upgrade</StyledBadge>}
          </UpgradeContent>
        }
      />
    </>
  );
};
