import customProtocolCheck from 'custom-protocol-check';
import { media } from '@grain/styles/constants';
import { Icon } from '@grain/grain-ui';
import { StyledInfoText, StyledAnchor } from '../styles';
type Props = {
  nativeUrl: string;
};

export default function Web({ nativeUrl }: Props) {
  const handleLaunchClick = () => {
    customProtocolCheck(nativeUrl);
  };

  return (
    <StyledInfoText>
      <div>Change your desktop settings in the</div>
      <span css={[`${media.gtSmall} { display: none;}`]}>
        {' '}
        Grain desktop app.
      </span>
      <StyledAnchor
        css={[`${media.small} { display: none; }`]}
        onClick={handleLaunchClick}
      >
        Grain desktop app <Icon.ExternalAppLink />
      </StyledAnchor>
    </StyledInfoText>
  );
}
