import { css } from '@emotion/react';
import { Icon, useAnalytics, useShowToast } from '@grain/grain-ui';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage, useMedia } from 'react-use';
import { theme } from '@grain/grain-ui/v4';
import { useRequestAdminUpgrade } from '~/modules/gates/hooks';
import { UserRole } from '@grain/api/schema.generated';
import { useMyself, useWorkspace } from '@grain/api/auth';
import pluralize from 'pluralize';

const NOOP = () => {};
const TOAST_DISMISS_KEY = 'unassigned_seats_toast_dismiss_date';
const DISMISS_DAYS = 7;

export const useUnassignedSeatsToast = () => {
  const isSmallScreen = useMedia(theme.tokens.breakpoints.mdMax);
  const { myself } = useMyself();
  const { workspace } = useWorkspace();
  const { trackEvent } = useAnalytics();
  const isAdmin = myself?.user?.role === UserRole.Admin;
  const unassignedSeats = Math.max(
    (workspace?.workspaceSubscription?.seatQuantity || 0) -
      (workspace?.workspaceSubscription?.count || 0),
    0
  );
  const clearToastRef = useRef<() => void>(NOOP);
  const showToast = useShowToast();
  const navigate = useNavigate();
  const [toastDismissDate, setToastDismissDate] = useLocalStorage<string>(
    TOAST_DISMISS_KEY,
    ''
  );

  const shouldShowToast = useMemo(() => {
    if (
      unassignedSeats === 0 || // No unassigned seats
      !workspace || // No workspace
      !myself?.extra.onboardingDone || // Onboarding not done
      (!isAdmin && myself?.user?.onPlan) || // User is not admin and is already on plan
      isSmallScreen // mobile does not support managing seats
    ) {
      return false;
    }

    if (!toastDismissDate) {
      return true;
    }

    const dismissDate = new Date(toastDismissDate);
    const today = new Date();
    const diffTime = Math.abs(today.getTime() - dismissDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays >= DISMISS_DAYS;
  }, [
    unassignedSeats,
    workspace,
    myself?.extra.onboardingDone,
    myself?.user?.onPlan,
    isAdmin,
    toastDismissDate,
    isSmallScreen
  ]);

  const handleDismiss = useCallback(() => {
    setToastDismissDate(new Date().toISOString());
    trackEvent('Unassigned Seats Toast Dismissed');
  }, [setToastDismissDate, trackEvent]);

  const requestUpgrade = useRequestAdminUpgrade({
    callback: error => {
      if (error) {
        return;
      }

      showToast({
        uniqueId: 'unassigned-seats-upgrade-request',
        title: 'Upgrade request sent!',
        body: 'Your workspace admin will be notified of your request in Grain and via email.',
        type: 'content',
        icon: (
          <Icon.Check
            css={css`
              width: 26px !important; /* overrides size definition inside the Toast component */
              height: 26px !important; /* overrides size definition inside the Toast component */
              color: ${theme.tokens.color
                .iconBrand} !important; /* overrides color definition inside the Icon component */
            `}
          />
        ),
        durationMs: 5000,
        showCloseButton: true,
        onClose: handleDismiss
      });
    },
    updateToWorkspacePlan: true
  });

  const handleRequestSeat = useCallback(() => {
    trackEvent('Unassigned Seats Toast Upgrade Clicked');
    if (isAdmin) {
      navigate('/app/settings/workspace?tab=members');
      handleDismiss();
    } else {
      requestUpgrade();
    }
  }, [isAdmin, navigate, handleDismiss, requestUpgrade, trackEvent]);

  const clearToast = useCallback(() => {
    clearToastRef.current();
  }, []);

  const showUnassignedSeatsToast = useCallback(() => {
    if (!shouldShowToast) {
      return;
    }

    trackEvent('Unassigned Seats Toast Viewed');

    clearToastRef.current = showToast({
      uniqueId: 'unassigned-seats-toast',
      title: `Your Workspace has ${unassignedSeats} unassigned Paid ${pluralize('seat', unassignedSeats)}`,
      body: isAdmin
        ? 'Assign them to enable unlimited meeting recordings, AI notes, and more.'
        : 'Request an upgrade from your Admins to unlock unlimited meeting recordings, AI notes, and more.',
      action: isAdmin ? 'Assign Member' : 'Request Seat',
      onAction: handleRequestSeat,
      showCloseButton: true,
      onClose: handleDismiss,
      closeOnAction: isAdmin,
      durationMs: 0,
      actionButtonVariant: 'primary',
      icon: (
        <Icon.Info
          css={css`
            width: 26px !important; /* overrides size definition inside the Toast component */
            height: 26px !important; /* overrides size definition inside the Toast component */
          `}
        />
      ),
      type: 'content'
    });
  }, [
    unassignedSeats,
    isAdmin,
    handleRequestSeat,
    handleDismiss,
    shouldShowToast,
    showToast,
    trackEvent
  ]);

  useEffect(showUnassignedSeatsToast, [showUnassignedSeatsToast]);

  // Ensure that if the conditions for showing the toast change from "should
  // show" to "should not show," then we clear any existing toasts that may have
  // previously appeared.
  useEffect(() => {
    if (!shouldShowToast) clearToast();
  }, [clearToast, shouldShowToast]);
};
