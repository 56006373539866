import { Chip, Icon16, Menu, theme, TippyInstance } from '@grain/grain-ui/v4';
import { MutableRefObject } from 'react';
import { Filters } from '../useFiltersManager';
import { FilterSelect } from './FilterSelect';
import { AutocompleteMenuList } from './AutocompleteMenuList';
import { isAutocompleteFilter, isDateFilter } from '../utils';
import { FilterName } from '../types';
import { DateMenuList } from './DateMenuList';
import { BaseButton } from 'ui/v4/components/Button/Button.shared';
import styled from '@emotion/styled';

type FilterMenuButton = {
  menuRef: MutableRefObject<TippyInstance | null>;
  filters: Filters;
  setSelectedFilterId: (id: FilterName | null) => void;
  selectFilter: (id: FilterName) => void;
  activeFilter: Filters[keyof Filters] | null;
};

const DashedChip = styled(Chip)`
  outline: 1px dashed ${theme.tokens.color.borderPrimary};
  outline-offset: -1px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.tokens.color.buttonGhostHover};
  }
`;

export const FilterMenuButton = ({
  menuRef,
  filters,
  selectFilter,
  setSelectedFilterId,
  activeFilter
}: FilterMenuButton) => {
  return (
    <Menu
      unmountOnClose
      ref={menuRef}
      hideOnClickInside={Boolean(activeFilter && !activeFilter.isMultiSelect)}
      tippyProps={{
        delay: [0, 0],
        onMount: () => setSelectedFilterId(null)
      }}
      width={activeFilter?.id === 'persons' ? '240px' : undefined}
      content={
        <>
          {!activeFilter && (
            <FilterSelect filters={filters} onFilterSelect={selectFilter} />
          )}
          {activeFilter && isAutocompleteFilter(activeFilter) && (
            <AutocompleteMenuList filter={activeFilter} />
          )}
          {activeFilter && isDateFilter(activeFilter) && (
            <DateMenuList filter={activeFilter} />
          )}
        </>
      }
    >
      <DashedChip
        as={BaseButton}
        size='sm'
        variant='stroked'
        icon={Icon16.Plus}
        title='Filter'
      />
    </Menu>
  );
};
