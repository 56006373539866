import React, { useMemo } from 'react';
import {
  NotesTemplate,
  Icon,
  useConfirm,
  color,
  pxToRem,
  TemplateAddOption
} from '@grain/grain-ui';
import styled from '@emotion/styled';
import { StyledContentWrapper, StyledPageHeader } from '../../SettingsWrapper';
import { EditableHeader } from '../EditableHeader';
import { useNotesTemplateEdit } from './hooks';
import { AddSectionModal } from '~/components/AddSectionModal/AddSectionModal';
import { SubHeading } from '../styles';
import {
  useSearchParams,
  unstable_useBlocker as useBlocker
} from 'react-router-dom';
import { AdHocRecordingFragment } from '~/modules/contentFilter/contentfilter.generated';
import {
  IntelligenceCustomPromptType,
  IntelligenceTemplate,
  Recording
} from '@grain/api/schema.generated';
import { GatedButton } from '~/modules/gates/GatedButton';
import { useAdHocFilters } from '~/modules/contentFilter';
import { useSectionAdd, SectionOptions } from '~/modules/templates/hooks';
import { Icon16, Menu, MenuButton, Button } from '@grain/grain-ui/v4';
import { IntelligenceTemplateQuerySection } from '@grain/api/schema.extra';

const Description = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

const AddSectionWrapper = styled.div`
  display: flex;
  margin-top: 24px;
`;

type NoteTemplateProps = {
  onExit: () => void;
};

const ConfirmDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(20)};
  text-align: center;
  font-feature-settings:
    'case' on,
    'cpsp' on;
  ${color.fg.crow};
`;

const useConfirmExit = (when = false, title: string) => {
  const showConfirm = useConfirm();
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      when &&
      (currentLocation.pathname !== nextLocation.pathname ||
        currentLocation.search !== nextLocation.search)
  );
  React.useEffect(() => {
    if (blocker.state === 'blocked') {
      showConfirm({
        width: 418,
        title: 'Clear edits?',
        description: (
          <ConfirmDescription>
            Navigating away from this page. {title} changes you made will be
            lost.
          </ConfirmDescription>
        ),
        confirmContent: 'Discard',
        cancelContent: 'Keep editing',
        onConfirm: () => {
          blocker.proceed();
        },
        onCancel: () => {
          blocker.reset();
        },
        closable: false
      });
    }
  }, [blocker, showConfirm, title]);
};

export const NoteTemplate = ({ onExit }: NoteTemplateProps) => {
  const [persisted, setPersisted] = React.useState(false);
  const showConfirm = useConfirm();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('templateId');
  const {
    template,
    title,
    templateSections,
    sectionsNotInCurrentTemplate,
    changesMade,
    handleSectionAddToTemplate,
    handleChangeOrder,
    handleSaveTemplate,
    handleReset,
    handleTitleChange,
    handleTemplateDelete,
    setEditModalOpen,
    isEditModalOpen,
    editSection,
    modalMode,
    handleUpdateCustomPrompt,
    handleReplaceTemplateSection
  } = useNotesTemplateEdit(id ?? undefined);
  const handleAddSection = useSectionAdd('template');

  const cantNavigate = useMemo(() => {
    return !persisted && changesMade;
  }, [persisted, changesMade]);

  const canSave = useMemo(() => {
    return title && templateSections.length > 0 && changesMade;
  }, [title, templateSections, changesMade]);

  const [cannotSaveReason, tooltipMaxWidth] = useMemo(() => {
    if (!title || !templateSections.length) {
      const things = [];
      let maxWidth = 350;
      if (!title) things.push('a title');
      if (!templateSections.length) {
        things.push('at least one section');
        maxWidth = 400;
      }
      if (things.length >= 2) maxWidth = 440;
      const reason =
        `Cannot create template without ${things.join(' and ')}. ` +
        `Please add ${things.join(' and ')} to this template before creating.`;
      return [reason, maxWidth];
    }
    return [];
  }, [title, templateSections]);

  const {
    viewData,
    loading: isRecordingLoading,
    onFilterChange,
    filtersMap,
    resetAllFilters
  } = useAdHocFilters({
    filterType: 'recordings',
    filters: [],
    // TODO: Uncomment role filter when prompt saving is decoupled from preview generation
    // Context: https://usegrain.slack.com/archives/C06ABESL97W/p1715641448790969?thread_ts=1715377742.304129&cid=C06ABESL97W
    // viewerRole: 'attendee',
    workspaceShared: true,
    skip: !isEditModalOpen,
    storagePrefix: 'notes-template-recordings'
  });

  const titleFilter = filtersMap.title;

  const recordingList =
    viewData?.viewAdHoc?.list.filter(item => item.__typename === 'Recording') ??
    ([] satisfies AdHocRecordingFragment[] as Recording[]);

  const handleRecordingSearch = (value: string) => {
    onFilterChange({
      name: 'title',
      value: [{ title: value, value: value }],
      isMultiSelect: false,
      options: []
    });
  };

  const handleSectionModalClose = () => {
    setEditModalOpen(false);
    resetAllFilters();
  };

  const onAddSection = async (section: SectionOptions) => {
    const savedSection = await handleAddSection({
      ...section,
      templateId: template?.id
    });
    if (!savedSection) return;
    handleSectionAddToTemplate(
      savedSection as IntelligenceTemplateQuerySection
    );
  };

  const onEditSection = async (
    section: IntelligenceTemplateQuerySection,
    processedPromptId: string,
    promptType: IntelligenceCustomPromptType
  ) => {
    if (promptType && section.type.toString() !== promptType.toString()) {
      // TODO: Also make the old section unavailable
      const savedSection = await handleAddSection({
        id: section.id,
        title: section.title,
        templateId: template?.id,
        promptType,
        processedPromptId,
        isExistingSection: false
      });
      if (!savedSection) return;
      handleReplaceTemplateSection(
        section.id,
        savedSection as IntelligenceTemplateQuerySection
      );
      return;
    }
    await handleUpdateCustomPrompt(
      section.id,
      section.title,
      processedPromptId
    );
  };

  useConfirmExit(cantNavigate, title);

  const handleDelete = async () => {
    setPersisted(true);
    handleTemplateDelete(onExit);
  };

  const handleCancel = async () => {
    setPersisted(true);
    if (changesMade) {
      const result = await showConfirm({
        width: 400,
        confirmContent: 'Discard',
        cancelContent: 'Keep editing',
        description: 'Changes you made will be lost.',
        title: 'Discard changes to template?'
      });
      if (!result.isConfirm) return;
    }
    onExit();
  };

  return (
    <>
      <StyledPageHeader isTopic sticky>
        <EditableHeader
          title={title}
          placeholder='Untitled Template'
          onTitleChange={handleTitleChange}
          id={template?.id}
          onCancel={handleCancel}
          onSave={() => {
            setPersisted(true);
            handleSaveTemplate(onExit);
          }}
          canSave={Boolean(canSave)}
          tooltipContent={cannotSaveReason}
          tooltipMaxWidth={tooltipMaxWidth}
          additionalRightButtons={
            template?.id && (
              <Menu
                unmountOnClose
                hideOnClickInside
                stopClickPropagation
                tippyProps={{ placement: 'bottom-end' }}
                width='232px'
                content={
                  <>
                    {template?.canDelete ? (
                      <MenuButton
                        onClick={handleDelete}
                        textLabelProps={{ startIcon: Icon16.Trash }}
                        label='Delete Notes Template'
                      />
                    ) : (
                      <MenuButton
                        onClick={handleReset}
                        textLabelProps={{ startIcon: Icon16.Refresh }}
                        label='Reset Template'
                      />
                    )}
                  </>
                }
              >
                <Button
                  data-cy='meeting-overflow'
                  variant='ghost'
                  size='lg'
                  icon={Icon16.Overflow}
                />
              </Menu>
            )
          }
        />
      </StyledPageHeader>
      <StyledContentWrapper>
        {templateSections.length === 0 ? (
          <Description>
            <span>
              Add sections to your template to get started. Learn more about
              building custom notes templates{' '}
              <a
                href='https://support.grain.com/en/articles/8229813-how-to-create-custom-note-templates'
                target='_blank'
                rel='noopener noreferrer'
              >
                here
              </a>
              .
            </span>
          </Description>
        ) : (
          <SubHeading>Note templates</SubHeading>
        )}
        <NotesTemplate
          items={templateSections}
          onChangeOrder={handleChangeOrder}
        />
        <AddSectionWrapper>
          <GatedButton
            variant='secondary'
            icon={<Icon.DeprecatedPlus />}
            onClick={() => setEditModalOpen(true)}
            gate='custom_ai_notes'
          >
            Add Section
          </GatedButton>
        </AddSectionWrapper>
      </StyledContentWrapper>
      <AddSectionModal
        isOpen={isEditModalOpen}
        modalMode={modalMode}
        editSection={editSection as IntelligenceTemplateQuerySection}
        onEditSection={onEditSection}
        onClose={() => handleSectionModalClose()}
        recordings={recordingList as Recording[]}
        onAddSection={onAddSection}
        sectionsNotInCurrentTemplate={sectionsNotInCurrentTemplate}
        addSectionText={modalMode === 'edit' ? 'Save' : 'Add To Template'}
        isRecordingFilterLoading={isRecordingLoading}
        onRecordingSearch={handleRecordingSearch}
        recordingFilterValue={titleFilter?.value?.[0] ?? ''}
        template={template as IntelligenceTemplate}
        isTemplate
        templateAddOptions={
          { canAddToTemplate: false } satisfies TemplateAddOption
        }
      />
    </>
  );
};
