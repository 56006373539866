import React from 'react';
import { Helmet } from 'react-helmet';
import format from 'date-fns/format';
import NotFound from '~/components/NotFound';
import * as Layout from '~/components/Layout';
import { RecordingPageProvider, useRecordingPage } from './context';

import { TranscriptContextProvider } from './transcriptContext';
import { useExtra } from '@grain/components/support/extra';

import { LiveRecordingPage } from './LiveRecordingPage';
import { RecordingPage as DefaultRecordingPage } from './RecordingPage';

export default function RecordingPageWrapper(props) {
  return (
    <TranscriptContextProvider>
      <RecordingPageProvider>
        <RecordingPage {...props} />
      </RecordingPageProvider>
    </TranscriptContextProvider>
  );
}

function RecordingPage() {
  const { recordingRes, recordingId } = useRecordingPage();
  const { extra, saveExtra, loading: extraLoading } = useExtra();
  const { error, data } = recordingRes;
  const recording = data?.recording;
  const isLive = recording?.state === 'ACTIVE';

  React.useEffect(() => {
    if (
      !extraLoading &&
      !extra?.hasWatchedIntroVideo &&
      recording?.source === 'SAMPLE'
    ) {
      saveExtra({ ...extra, hasWatchedIntroVideo: true });
    }
  }, [extra, recording?.source, saveExtra, extraLoading]);

  const pageTitle = recording
    ? `${recording?.title} | ${format(
        new Date(recording?.startDatetime),
        'E MMM d'
      )}`
    : '';

  // User is unauthorized. Show blank screen until redirect logic
  if (error?.message === 'GraphQL error: forbidden') return;
  if (error) {
    return (
      <NotFound
        title='A problem has occurred.'
        message='An unexpected problem occurred. Please try again.'
      />
    );
  }

  return (
    <>
      {!!recording && <Helmet title={pageTitle} />}
      <Layout.Wrapper withSidebar>
        {isLive ? (
          <LiveRecordingPage key={recordingId} />
        ) : (
          <DefaultRecordingPage key={recordingId} />
        )}
      </Layout.Wrapper>
    </>
  );
}
