import { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  FTUX_SLIDESHOW_ROUTE_ID,
  POST_ONBOARDING_ROUTE_ID
} from '@grain/components/overlays/constants';
import { getBasePublicPath, useRouteOverlay } from '@grain/grain-ui';
import { Button, Icon16 } from '@grain/grain-ui/v4';

import { type ExitFunction, DarkOverlay } from '~/components/DarkOverlay';

const Confetti = styled.img`
  width: 300px;
`;

const BottomLeftConfetti = styled(Confetti)`
  transform: rotate(270deg);
`;

const BottomRightConfetti = styled(Confetti)`
  transform: rotate(180deg);
`;

export default function PostOnboardingOverlay() {
  const { close } = useRouteOverlay(POST_ONBOARDING_ROUTE_ID);
  const { open: openFtuxSlideshow } = useRouteOverlay(FTUX_SLIDESHOW_ROUTE_ID);
  const [exiting, setExiting] = useState(false);

  const exitFunctionRef = useRef<ExitFunction | undefined>();

  const handleOpenFtuxSlideshow = useCallback(async () => {
    if (!exitFunctionRef.current) return; // In practice, should never happen
    setExiting(true);
    await exitFunctionRef.current();
    openFtuxSlideshow();
  }, [openFtuxSlideshow]);

  const [showDecoration, setShowDecoration] = useState(false);

  useEffect(() => {
    // Delay the confetti until .5s after the slide fades in
    setTimeout(() => setShowDecoration(true), 800);
  }, []);

  const confettiProps = {
    alt: 'Animation of confetti popping out, congratulating you',
    src: `${getBasePublicPath()}/images/confetti.gif`
  };

  // Darken the background to match the darkness of the FTUX slideshow
  const overlayStyles = css`
    @property --overlayOpacity {
      syntax: '<number>';
      initial-value: 0.94;
      inherits: false;
    }

    background: linear-gradient(
      69deg,
      rgba(33, 33, 33, var(--overlayOpacity)) 0%,
      rgba(38, 38, 38, var(--overlayOpacity)) 100%
    );

    transition: --overlayOpacity 1s;
    ${exiting && '--overlayOpacity: 0.98;'}
  `;

  return (
    <DarkOverlay
      onClose={close}
      title='You’re all set!'
      body='See how a meeting looks with Grain, so you know what to expect before, during, and after meetings.'
      bottomLeftDecoration={
        showDecoration && <BottomLeftConfetti {...confettiProps} />
      }
      bottomRightDecoration={
        showDecoration && <BottomRightConfetti {...confettiProps} />
      }
      overlayStyles={overlayStyles}
      exitFunctionRef={exitFunctionRef}
    >
      <Button
        size='xl'
        textLabelProps={{ startIcon: Icon16.Play }}
        onClick={handleOpenFtuxSlideshow}
      >
        See how Grain works
      </Button>
    </DarkOverlay>
  );
}
