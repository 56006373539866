import React from 'react';
import { css } from '@emotion/react';
import { RecapShareType } from '@grain/api/schema.generated';
import { useSetSettingsMutation } from '@grain/api/graphql/mutations/user.generated';
import { useWorkspacePlan } from '@grain/components/Subscriptions/hooks';
import {
  Button,
  Option,
  Select,
  SelectProps,
  TextLabel,
  theme
} from '@grain/grain-ui/v4';
import {
  StyledCard,
  StyledCardHeaderText,
  StyledCardSubHeaderText,
  StyledCardWrapper
} from '../styles';
import {
  StyledOptionContainer,
  StyledOptionLabel,
  StyledOptionItem
} from './styles';
import { StepComponentProps } from '..';
import { useExperiment } from '@grain/api/auth';

type SettingsOption = {
  label: string;
  recommended?: boolean;
  value: string;
  autoRecordSettings?: {
    autoShareExternalRecordings: boolean;
    autoShareInternalRecordings: boolean;
  };
  recapShareMode?: RecapShareType;
  displayCondition?: (options: DisplayConditionOptions) => boolean;
};

type DisplayConditionOptions = {
  isFreemiumEnabled: boolean;
  isFreePlan: boolean;
};

const autoRecordOptions: SettingsOption[] = [
  {
    label: 'All meetings',
    recommended: false,
    value: 'ALL',
    autoRecordSettings: {
      autoShareExternalRecordings: true,
      autoShareInternalRecordings: true
    }
  },
  {
    label: 'All meetings with only team participants',
    recommended: false,
    value: 'INTERNAL',
    autoRecordSettings: {
      autoShareExternalRecordings: false,
      autoShareInternalRecordings: true
    }
  },
  {
    label: 'All meetings with customer participants',
    recommended: true,
    value: 'EXTERNAL',
    autoRecordSettings: {
      autoShareExternalRecordings: true,
      autoShareInternalRecordings: false
    }
  },
  {
    label: 'Don’t auto-record any meetings',
    recommended: false,
    value: 'NONE',
    autoRecordSettings: {
      autoShareExternalRecordings: false,
      autoShareInternalRecordings: false
    },
    displayCondition({ isFreemiumEnabled, isFreePlan }) {
      return !(isFreemiumEnabled && isFreePlan);
    }
  }
];

const defaultAutoRecordOption = autoRecordOptions.find(
  ({ value }) => value === 'EXTERNAL'
)!;

const emailSummaryOptions: SettingsOption[] = [
  {
    label: 'All participants',
    recommended: false,
    value: RecapShareType.All,
    recapShareMode: RecapShareType.All
  },
  {
    label: 'All participants from your team domain(s)',
    recommended: true,
    value: RecapShareType.Internal,
    recapShareMode: RecapShareType.Internal
  }
];

const defaultEmailSummaryOption = emailSummaryOptions.find(
  ({ value }) => value === RecapShareType.Internal
)!;

export default function MeetingPreferences({ onNext }: StepComponentProps) {
  const { subscriptionPlan: workspacePlan } = useWorkspacePlan();
  const { enabled: isFreemiumEnabled } = useExperiment('freemium');
  const isFreePlan = workspacePlan?.isFree ?? false;

  const displayConditionOptions = {
    isFreemiumEnabled,
    isFreePlan
  };

  const [changeSettings] = useSetSettingsMutation({
    onCompleted: () => {
      onNext();
    }
  });

  const [selectedAutoRecordOption, setSelectedAutoRecordOption] =
    React.useState(defaultAutoRecordOption.value);
  const [selectedEmailSummaryOption, setSelectedEmailSummaryOption] =
    React.useState(defaultEmailSummaryOption.value);

  const prepareToChangeSettings = React.useCallback(() => {
    const autoRecordSettings = autoRecordOptions.find(
      option => option.value === selectedAutoRecordOption
    );
    const emailSummarySettings = emailSummaryOptions.find(
      option => option.value === selectedEmailSummaryOption
    );
    // Should never happen:
    if (!autoRecordSettings || !emailSummarySettings) return;

    const variables = {
      settings: {
        ...autoRecordSettings.autoRecordSettings,
        ...(emailSummarySettings.recapShareMode
          ? { recapShareMode: emailSummarySettings.recapShareMode }
          : {})
      }
    };

    changeSettings({ variables });
  }, [changeSettings, selectedAutoRecordOption, selectedEmailSummaryOption]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    prepareToChangeSettings();
  };

  return (
    <StyledCardWrapper>
      <StyledCard css={[`margin-bottom: ${theme.tokens.spacing['3xl']};`]}>
        <StyledCardHeaderText css={['text-align: center;']}>
          Confirm meeting preferences
        </StyledCardHeaderText>
        <StyledCardSubHeaderText
          css={css`
            margin-top: ${theme.tokens.spacing.md};
            margin-bottom: ${theme.tokens.spacing['3xl']};
          `}
        >
          You can always change this later in account settings.
        </StyledCardSubHeaderText>

        <form onSubmit={handleSubmit} className='content-wrapper'>
          <StyledOptionContainer>
            <StyledOptionLabel>
              Which meetings do you want Grain to auto-record?
            </StyledOptionLabel>
            <StyledOptionItem>
              <PreferenceSelect
                options={autoRecordOptions}
                displayConditionOptions={displayConditionOptions}
                value={selectedAutoRecordOption}
                onChange={setSelectedAutoRecordOption}
              />
            </StyledOptionItem>
          </StyledOptionContainer>
          <StyledOptionContainer>
            <StyledOptionLabel>
              Who should Grain send email summaries to?
            </StyledOptionLabel>
            <StyledOptionItem>
              <PreferenceSelect
                options={emailSummaryOptions}
                displayConditionOptions={displayConditionOptions}
                value={selectedEmailSummaryOption}
                onChange={setSelectedEmailSummaryOption}
              />
            </StyledOptionItem>
          </StyledOptionContainer>
          <Button
            data-cy='continue'
            variant='primary'
            type='submit'
            size='xl'
            fullWidth
            css={[`margin-top: ${theme.tokens.spacing['3xl']};`]}
          >
            Done
          </Button>
        </form>
      </StyledCard>
    </StyledCardWrapper>
  );
}

type PreferenceSelectProps<T> = Omit<SelectProps<T>, 'children'> & {
  value?: T;
  options: SettingsOption[];
  displayConditionOptions: DisplayConditionOptions;
};

function PreferenceSelect<T>({
  options,
  displayConditionOptions,
  ...restProps
}: PreferenceSelectProps<T>) {
  return (
    <Select
      fullWidth
      matchWidth
      entryComponent={props => <Select.Input {...props} size='xl' />}
      {...restProps}
    >
      {options
        .filter(
          option =>
            !option.displayCondition ||
            option.displayCondition(displayConditionOptions)
        )
        .map(({ label, value, recommended }) => (
          <Option key={value} value={value}>
            <TextLabel metadata={recommended ? 'Recommended' : undefined}>
              {label}
            </TextLabel>
          </Option>
        ))}
    </Select>
  );
}
