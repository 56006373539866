import * as Types from '@grain/api/schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@grain/api/graphql';
const defaultOptions = {} as const;
export type UserUploadInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserUploadInfoQuery = { __typename?: 'RootQueryType', userUploadInfo: { __typename?: 'ImageUploadInfo', maxUploadBytes: number, url: { __typename?: 'UploadUrl', uuid: string, url: string } | null } };


export const UserUploadInfoDocument = gql`
    query userUploadInfo {
  userUploadInfo {
    url {
      uuid
      url
    }
    maxUploadBytes
  }
}
    `;

/**
 * __useUserUploadInfoQuery__
 *
 * To run a query within a React component, call `useUserUploadInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserUploadInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserUploadInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserUploadInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserUploadInfoQuery, UserUploadInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserUploadInfoQuery, UserUploadInfoQueryVariables>(UserUploadInfoDocument, options);
      }
export function useUserUploadInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserUploadInfoQuery, UserUploadInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserUploadInfoQuery, UserUploadInfoQueryVariables>(UserUploadInfoDocument, options);
        }
export function useUserUploadInfoSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<UserUploadInfoQuery, UserUploadInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<UserUploadInfoQuery, UserUploadInfoQueryVariables>(UserUploadInfoDocument, options);
        }
export type UserUploadInfoQueryHookResult = ReturnType<typeof useUserUploadInfoQuery>;
export type UserUploadInfoLazyQueryHookResult = ReturnType<typeof useUserUploadInfoLazyQuery>;
export type UserUploadInfoSuspenseQueryHookResult = ReturnType<typeof useUserUploadInfoSuspenseQuery>;
export type UserUploadInfoQueryResult = Apollo.QueryResult<UserUploadInfoQuery, UserUploadInfoQueryVariables>;