import React from 'react';
import { useParams } from 'react-router-dom';
import ChangePlan from '~/modules/subscriptions/ChangePlan';
import { StyledContentWrapper } from './styles';
import * as Layout from '~/components/Layout';

export default function SubscriptionPlan() {
  const { planSku } = useParams();

  return (
    <Layout.Wrapper withSidebar>
      <StyledContentWrapper>
        <Layout.Header>Change Plan</Layout.Header>
        <ChangePlan toSku={planSku} />
      </StyledContentWrapper>
    </Layout.Wrapper>
  );
}
