import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useMutation } from '@grain/api/graphql';
import PropTypes from 'prop-types';
import PaymentForm from '~/modules/subscriptions/PaymentForm';
import { workspaceMembersQuery } from '@grain/api/graphql/queries';
import { useSubscribeInfo } from '../context';
import Input from '@grain/components/Input';
import { useMyself } from '@grain/api/auth';
import { renderSubscriptionPreviewBillingExplanation } from '~/modules/subscriptions/helpers';

import {
  addWorkspaceUsersToSubscriptionMutation,
  createSubscriptionMutation
} from '~/modules/subscriptions/graphql';
import { color, Spinner } from '@grain/grain-ui';
import { parseISO, isFuture } from 'date-fns';
import { handleCardConfirmation } from '../../PaymentForm/confirmCard';
import { PlanFeaturesDocument } from '@grain/api/graphql/queries/planFeatures.generated';

const hasValidPayment = (cardLastFour, cardExpiration) => {
  if (!cardLastFour || !cardExpiration) return false;
  try {
    return isFuture(parseISO(cardExpiration ?? ''));
  } catch (error) {
    return false;
  }
};

PaymentStep.propTypes = {
  onSuccess: PropTypes.func.isRequired
};

export default function PaymentStep({ onSuccess }) {
  const { myself } = useMyself();
  const [hasValidPaymentMethod, setHasValidPaymentMethod] =
    React.useState(false);
  const [hasSetPaymentMethodValidity, setHasSetPaymentMethodValidity] =
    useState(false);
  const [hasTriedToAutoPay, setHasTriedToAutoPay] = useState(false);
  const [isConfirmingCard, setIsConfirmingCard] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    subscriptionPreviewCurrent,
    subscriptionArgs,
    promoCode,
    planSku,
    setPromoCode,
    workspacePlan,
    membersSet,
    workspaceRes
  } = useSubscribeInfo();

  const wsSubscription = workspaceRes.workspace?.workspaceSubscription;

  const [addUsersToSubscription, { loading: addToSubscriptionLoading }] =
    useMutation(addWorkspaceUsersToSubscriptionMutation);

  const [createSubscription, { loading: createSubscriptionLoading }] =
    useMutation(createSubscriptionMutation, {
      refetchQueries: [
        {
          query: workspaceMembersQuery,
          fetchPolicy: 'network-only'
        },
        {
          query: PlanFeaturesDocument,
          fetchPolicy: 'network-only'
        }
      ]
    });
  const isLoading = workspaceRes.loading || !wsSubscription;

  useEffect(() => {
    if (!wsSubscription || hasSetPaymentMethodValidity) return;
    const { cardLastFour, cardExpiration } = wsSubscription || {};
    const isPaymentMethodValid = hasValidPayment(cardLastFour, cardExpiration);
    setHasValidPaymentMethod(isPaymentMethodValid);
    setHasSetPaymentMethodValidity(true);
  }, [wsSubscription, hasSetPaymentMethodValidity]);

  const performPlanAction = useCallback(async () => {
    if (wsSubscription.trial || planSku !== workspacePlan?.sku) {
      return createSubscription({ variables: { subscriptionArgs } });
    } else {
      const userIds = Array.from(membersSet).map(({ id }) => id);
      return addUsersToSubscription({ variables: { userIds } });
    }
  }, [
    wsSubscription,
    planSku,
    workspacePlan,
    createSubscription,
    subscriptionArgs,
    addUsersToSubscription,
    membersSet
  ]);

  const handleSubmit = useCallback(
    async (isAutoTriggered = false) => {
      if (isAutoTriggered) setHasTriedToAutoPay(true);
      return performPlanAction();
    },
    [performPlanAction]
  );

  useEffect(() => {
    const attemptAutoSubmit = async () => {
      if (
        isConfirmingCard ||
        !workspacePlan ||
        !hasValidPaymentMethod ||
        hasTriedToAutoPay ||
        isLoading
      ) {
        return;
      }

      try {
        await handleSubmit(true);
        onSuccess();
      } catch (error) {
        try {
          const confirmed = await handleCardConfirmation(
            error,
            setIsConfirmingCard
          );
          if (confirmed) {
            onSuccess();
          } else {
            setErrorMessage(
              error.graphQLErrors?.[0]?.message || 'An error occurred'
            );
          }
        } catch (e) {
          setErrorMessage(e.message);
        }
      }
    };

    attemptAutoSubmit();
  }, [
    hasValidPaymentMethod,
    hasTriedToAutoPay,
    isLoading,
    handleSubmit,
    workspacePlan,
    isConfirmingCard,
    onSuccess
  ]);

  const isProcessing = useMemo(
    () =>
      isLoading ||
      isConfirmingCard ||
      (hasValidPaymentMethod &&
        (!hasTriedToAutoPay ||
          createSubscriptionLoading ||
          addToSubscriptionLoading)),
    [
      isLoading,
      hasValidPaymentMethod,
      hasTriedToAutoPay,
      createSubscriptionLoading,
      addToSubscriptionLoading,
      isConfirmingCard
    ]
  );

  if (isProcessing) {
    return (
      <div css={['display: flex; padding-top: 32px; justify-content: center;']}>
        <Spinner color={color.graieen} size={40} />
      </div>
    );
  }

  return (
    <div>
      {errorMessage && (
        <div css={['color: red; padding: 16px 32px 0;']}>{errorMessage}</div>
      )}
      <PaymentForm
        onSuccess={onSuccess}
        handleCreateSubscription={handleSubmit}
        withAcceptCheckbox
        title='Add Payment Method'
        defaultBillingEmail={myself?.user?.email}
        buttonText='Pay'
        summary={renderSubscriptionPreviewBillingExplanation(
          subscriptionPreviewCurrent,
          promoCode
        )}
        additionalInputs={
          <label>
            <div className='text'>Promo code</div>
            <Input
              className='fs-block'
              style={{ height: '40px' }}
              type='promoCode'
              placeholder='If you have a promo code, enter it here'
              name='promoCode'
              value={promoCode}
              onChange={ev => setPromoCode(ev.target.value)}
            />
          </label>
        }
      />
    </div>
  );
}
