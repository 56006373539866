import cx from 'classnames';
import styled from '@emotion/styled';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { POST_SLIDESHOW_ROUTE_ID } from '@grain/components/overlays/constants';
import { useMediaQuery } from '@grain/components/support/browser';
import { color, fontFamily, media, pxToRem } from '@grain/grain-ui';
import { Icon16, theme } from '@grain/grain-ui/v4';

import type { ExitFunction } from './types';
import { SLIDES } from './slides';

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;

  @property --overlayOpacity {
    syntax: '<number>';
    initial-value: 0.98;
    inherits: false;
  }

  background: linear-gradient(
    69deg,
    rgba(33, 33, 33, var(--overlayOpacity)) 0%,
    rgba(38, 38, 38, var(--overlayOpacity)) 100%
  );

  transition: --overlayOpacity 1s;

  &.exiting {
    --overlayOpacity: 0.94;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 1140px;
  width: calc(100% - 112px);
  margin-left: auto;
  margin-right: auto;

  opacity: 1;
  transform: translateY(0);
  transition:
    opacity ease-out 0.3s,
    transform ease-out 0.5s;

  &.hidden {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.small} {
    width: calc(100% - 82px);
  }
`;

const Header = styled.div`
  display: flex;
  gap: 56px;
  align-items: center;

  ${media.small} {
    gap: unset;
  }
`;

const SlideTextCarousel = styled.div`
  flex: 1;
  height: 202px;
  position: relative; // For shadows
  overflow: hidden; // For carousel

  // Accomodate shorter text boxes:
  @media (max-width: 600px) {
    height: 174px;
  }
  ${media.xs} {
    height: 164px;
  }
`;

const SlideTextCarouselPlaceholder = styled.div`
  flex: 1;
  height: 202px;
`;

const SlideTextCarouselItems = styled.div<{ slideIndex: number }>`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-top: 50px;

  // This shifts the header text for the current slide into view:
  transform: translateY(
    ${({ slideIndex }) => slideIndex * -(100 / SLIDES.length)}%
  );
  transition: transform ease-in-out 0.5s;
`;

// The following 2 shadow components make it look like the carousel item is
// "fading away" or "fading in" as it slides out or in.

const SlideTextCarouselTopShadow = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(
    to bottom,
    rgba(36, 36, 36, 1),
    rgba(36, 36, 36, 0)
  );
`;

const SlideTextCarouselBottomShadow = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to top, rgba(36, 36, 36, 1), rgba(36, 36, 36, 0));
`;

const SlideText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing.sm};
  text-align: center;
  text-wrap: balance;
  white-space: pre-line;
`;

const Question = styled.div`
  ${theme.tokens.typography.b1[500]};
  color: ${color.highlightGreen};

  // Preserve the carousel layout in case the screen is narrow (not ideal)
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Answer = styled.div`
  ${fontFamily.body};
  font-weight: 500;
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(34)};
  color: ${theme.primitives.color.white};

  // Force all text to fit even on the smallest screens:
  @media (max-width: 600px) {
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(20)};
  }
  ${media.xs} {
    font-size: ${pxToRem(10)};
    line-height: ${pxToRem(16)};
  }

  // Preserve the carousel layout in case the screen is narrow (not ideal)
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const TextLabel = styled.div`
  display: flex;
  gap: ${theme.tokens.spacing.xs};
  padding: ${theme.tokens.spacing.sm} ${theme.tokens.spacing['2xs']};

  svg {
    width: 24px;
    height: 24px;
  }
`;

const Button = styled.button`
  ${fontFamily.body};
  font-weight: 500;
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(24)};
  min-width: 130px;
  padding: ${theme.tokens.spacing.lg} ${theme.tokens.spacing.xl};
  border: 2px solid transparent;
  border-radius: ${theme.tokens.radii.lg};

  ${media.xs} {
    min-width: 116px;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(20)};
    padding: ${theme.tokens.spacing.md} ${theme.tokens.spacing.lg};
  }

  ${TextLabel} {
    justify-content: center;
    align-items: center;
  }
`;

const BackButton = styled(Button)`
  color: ${theme.tokens.color.textTertiary};
  border-color: rgba(255, 255, 255, 0.08);
  background: transparent;

  &:hover,
  &:focus-visible {
    border-color: rgba(255, 255, 255, 0.16);
    cursor: pointer;
  }

  &:disabled {
    border-color: rgba(255, 255, 255, 0.08);
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const NextButton = styled(Button)`
  color: ${theme.primitives.color.white};
  border-color: rgba(255, 255, 255, 0.16);
  background: linear-gradient(
    136deg,
    rgba(0, 153, 89, 0.75) 0%,
    rgba(0, 128, 74, 0.75) 100%
  );

  &:hover,
  &:focus-visible {
    border-color: rgba(255, 255, 255, 0.24);
    background: linear-gradient(
      136deg,
      rgba(2, 171, 100, 0.75) 0%,
      rgba(0, 148, 86, 0.75) 100%
    );
    cursor: pointer;
  }
`;

const GraphicContainer = styled.div`
  opacity: 1;
  transform: translateY(0);
  transition:
    opacity ease-out 0.5s,
    transform ease-out 0.5s;

  &.hidden {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
`;

const DEFAULT_SLIDE_INDEX = 0;
const FINAL_SLIDE_INDEX = SLIDES.length - 1;

export default function FtuxSlideshowOverlay() {
  const isSmallScreen = useMediaQuery(media.small);
  const isExtraSmallScreen = useMediaQuery(media.xs);
  const [searchParams, setSearchParams] = useSearchParams();
  const slideSearchParam = searchParams.get('slide');
  const urlSlideIndex = slideSearchParam
    ? Number(slideSearchParam) - 1 // 1-indexed, since users see it
    : DEFAULT_SLIDE_INDEX;

  // Track slideIndex separate from the URL so changes in the URL can be
  // detected in an effect.
  const [slideIndex, setSlideIndex] = useState(urlSlideIndex);

  // The slideIndex, as far as the graphic is concerned (it lags behind the header
  // and URL changes due to fading out).
  const [graphicSlideIndex, setGraphicSlideIndex] = useState(slideIndex);

  const { Graphic } = SLIDES[graphicSlideIndex];

  const [initialSlide, setInitialSlide] = useState(true);
  const [graphicHidden, setGraphicHidden] = useState(false);
  const [slideshowHidden, setSlideshowHidden] = useState(true);
  const [exitingSlideshow, setExitingSlideshow] = useState(false);

  useEffect(() => {
    setTimeout(() => setSlideshowHidden(false), 100);
  }, []);

  // Apply animations in response to routing changes.  In this way, the
  // browser's history navigation integrates with the animations.
  useEffect(() => {
    if (slideIndex !== urlSlideIndex) {
      setInitialSlide(false);
      setGraphicHidden(true);
      setSlideIndex(urlSlideIndex);
      setTimeout(() => {
        setGraphicHidden(false);
        setGraphicSlideIndex(urlSlideIndex);
      }, 1000);
    }
  }, [slideIndex, urlSlideIndex]);

  // The Graphic knows how to clean itself up, so it passes up a function
  // to this component that will clean itself up, so that this component
  // can additionally define how to advance to the next slide.
  //
  // We do it this way because you can either click "Next" in this component
  // to advance, or you can click on something inside the Graphic of a slide
  // to advance.
  const exitFunctionRef = useRef<ExitFunction | undefined>();

  const goToPreviousSlide = useCallback(() => {
    const previousSlideIndex = slideIndex - 1;
    const previousSlideDisplayValue = previousSlideIndex + 1;
    setSearchParams({
      ...Object.fromEntries(searchParams),
      slide: String(previousSlideDisplayValue)
    });
  }, [searchParams, setSearchParams, slideIndex]);

  const advanceToNextSlide = useCallback(() => {
    const nextSlideIndex = slideIndex + 1;
    const nextSlideDisplayValue = nextSlideIndex + 1;
    setSearchParams({
      ...Object.fromEntries(searchParams),
      slide: String(nextSlideDisplayValue)
    });
  }, [searchParams, setSearchParams, slideIndex]);

  const exitSlideshow = useCallback(() => {
    setSlideshowHidden(true);
    setExitingSlideshow(true);
    setTimeout(() => {
      // Remove overlay and slide params from the URL simultaneously.  That way,
      // the overlay closes AND if it's reopened, it starts from the beginning.
      const {
        overlay: _overlay,
        slide: _slide,
        ...restSearchParams
      } = Object.fromEntries(searchParams);
      setSearchParams({
        ...restSearchParams,
        overlay: POST_SLIDESHOW_ROUTE_ID
      });
    }, 1000);
  }, [searchParams, setSearchParams]);

  const transitionToNextSlide = useCallback(async () => {
    if (!exitFunctionRef.current) return; // In practice, should never happen
    await exitFunctionRef.current();
    if (slideIndex === FINAL_SLIDE_INDEX) exitSlideshow();
    else advanceToNextSlide();
  }, [advanceToNextSlide, exitSlideshow, slideIndex]);

  const slideTextCarousel = (
    <SlideTextCarousel>
      <SlideTextCarouselTopShadow />
      <SlideTextCarouselItems slideIndex={slideIndex}>
        {SLIDES.map(({ question, shortQuestion, answer }, index) => (
          <SlideText key={index} aria-hidden={slideIndex !== index}>
            <Question>
              {(isExtraSmallScreen && shortQuestion) || question}
            </Question>
            <Answer>{answer}</Answer>
          </SlideText>
        ))}
      </SlideTextCarouselItems>
      <SlideTextCarouselBottomShadow />
    </SlideTextCarousel>
  );

  return (
    <Overlay className={cx({ exiting: exitingSlideshow })}>
      <Content className={cx({ hidden: slideshowHidden })}>
        <Header>
          <BackButton
            disabled={slideIndex === DEFAULT_SLIDE_INDEX}
            onClick={goToPreviousSlide}
          >
            <TextLabel>
              <Icon16.ArrowLeft />
              <span>Back</span>
            </TextLabel>
          </BackButton>
          {!isSmallScreen ? (
            slideTextCarousel
          ) : (
            <SlideTextCarouselPlaceholder />
          )}
          <NextButton onClick={transitionToNextSlide}>
            <TextLabel>
              <span>{slideIndex === FINAL_SLIDE_INDEX ? 'Done' : 'Next'}</span>
              {slideIndex !== FINAL_SLIDE_INDEX && <Icon16.ArrowRight />}
            </TextLabel>
          </NextButton>
        </Header>
        <GraphicContainer
          className={cx({
            hidden: graphicHidden
          })}
        >
          <Graphic
            transitionDelay={initialSlide ? 600 : 0}
            exitFunctionRef={exitFunctionRef}
            nextSlide={transitionToNextSlide}
          />
        </GraphicContainer>
        {isSmallScreen && <Footer>{slideTextCarousel}</Footer>}
      </Content>
    </Overlay>
  );
}
