import styled from '@emotion/styled';
import { Icon } from '@grain/grain-ui';
import { Icon16, TextArea, theme, Button } from '@grain/grain-ui/v4';
import { motion } from 'motion/react';
import { css } from '@emotion/react';

export const TopGradient = styled.div`
  height: 8px;
  position: sticky;
  top: -${theme.tokens.spacing.md};
  width: 100%;
  left: 0;
  z-index: 1;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
`;

type StyledArrowUpProps = {
  isValid: boolean;
};
export const StyledArrowUp = styled(Icon16.ArrowUp)<StyledArrowUpProps>`
  color: ${({ isValid = true }) =>
    isValid ? theme.tokens.color.iconPrimary : theme.tokens.color.iconDisabled};
  transition: color 0.3s linear;
`;

type StyledTextAreaProps = {
  isOpen: boolean;
  chatSize: 'lg' | 'sm';
};

export const StyledTextArea = styled(TextArea)<StyledTextAreaProps>`
  flex-direction: row;
  width: 100%;
  gap: ${theme.tokens.spacing['3xs']};

  ${({ isOpen = false }: StyledTextAreaProps) =>
    !isOpen &&
    css`
      border: none;
    `}

  ${({ chatSize = 'lg', isOpen = false }: StyledTextAreaProps) =>
    chatSize === 'sm' &&
    !isOpen &&
    css`
      padding-right: 0;

      & textarea {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    `}

  & textarea,
  & textarea:disabled {
    background: ${theme.tokens.color.surfacePrimary};
    cursor: text;
  }
`;

export const ChatWindow = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 auto;
  overflow-y: auto;
  scrollbar-gutter: stable;

  ${theme.utils.padding('md')}
`;

export const Header = styled.div`
  ${theme.utils.padding('14px', 'lg')};
  display: flex;
  align-items: center;
  gap: ${theme.tokens.spacing.xs};
`;

export const StyledGrainLogo = styled(Icon.GrainLogo)<{ active?: boolean }>`
  border-radius: ${theme.tokens.radii.full};
  border: 1px solid ${theme.tokens.color.borderTertiary};
  background: ${theme.tokens.color.surfacePrimary};
  padding: ${theme.tokens.spacing.xs};
  color: ${({ active = false }) =>
    active ? theme.tokens.color.iconBrand : theme.tokens.color.iconTertiary};

  ${theme.utils.size('32px')}
`;

export const RelativeContainer = styled.div`
  ${theme.utils.padding('none', '2xl', '2xl')}
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  height: 100%;
  isolation: isolate;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;

  & > * {
    pointer-events: auto;
  }
`;

export const MenuContainer = styled(motion.div)`
  border-radius: ${theme.tokens.radii.xl};
  border: 1px solid ${theme.tokens.color.borderSecondary};
  background: ${theme.tokens.color.surfacePrimary};
  align-self: end;

  display: flex;
  flex-direction: column;
  justify-content: end;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
  position: relative;
`;

export const CurrentPositionButton = styled(Button)`
  position: absolute;
  top: -48px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
`;
