import React, { useMemo } from 'react';
import Button from '@grain/components/Button';
import usePreviousDistinct from 'react-use/lib/usePreviousDistinct';
import { useApolloClient } from '@apollo/client';
import { Checkbox, color, font, pxToRem } from '@grain/grain-ui';
import { useMutation } from '@grain/api/graphql';

import { workspaceMembersQuery } from '@grain/api/graphql/queries';
import {
  setPaymentMethodMutation,
  subscriptionSetupIntentGenerateMutation
} from '~/modules/subscriptions/graphql';
import { isValidEmail } from '~/support/validators';
import Input from '@grain/components/Input';
import { spacing } from '@grain/styles/constants';
import {
  useStripe,
  useElements,
  PaymentElement,
  Elements
} from '@stripe/react-stripe-js';

import { StyledForm, StyledSummary, StyledLabel, StyledError } from './styles';
import { Appearance, StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { getStripe } from '~/modules/subscriptions/helpers';
import {
  CardAuthError,
  GraphQLStripeError,
  handleCardConfirmation
} from './confirmCard';

type BillingProps = {
  handleCreateSubscription?: () => Promise<void>;
  buttonText: string;
  title?: string;
  summary?: string;
  defaultBillingEmail: string;
  withAcceptCheckbox?: boolean;
  additionalInputs?: React.ReactNode;
  updateBillingEmail?: boolean;
  onSuccess: () => void;
};

const ignoredErrors = [
  'This customer has no attached payment source',
  'You have an in-flight confirmCardPayment'
];

export default function Billing({
  updateBillingEmail = true,
  ...props
}: BillingProps) {
  const stripePromise = useMemo(() => getStripe(), []);
  const appearance = {
    rules: {
      '.Label': {
        fontWeight: '600',
        fontSize: pxToRem(12)
      },
      '.Input': {
        fontSize: pxToRem(14),
        fontWeight: '400',
        borderRadius: '8px',
        borderColor: color.pigeon
      }
    }
  } as Appearance;

  return (
    <Elements
      stripe={stripePromise}
      options={{
        currency: 'usd',
        appearance,
        paymentMethodCreation: 'manual',
        mode: 'setup'
      }}
    >
      <Content
        {...{
          updateBillingEmail
        }}
        {...props}
      />
    </Elements>
  );
}

function Content({
  handleCreateSubscription,
  defaultBillingEmail,
  summary,
  withAcceptCheckbox,
  additionalInputs,
  buttonText,
  updateBillingEmail = true,
  onSuccess
}: BillingProps) {
  const client = useApolloClient();
  const stripe = useStripe();
  const elements = useElements();
  const [isStripeElementComplete, setIsStripeElementComplete] =
    React.useState(false);

  const [agree, setAgree] = React.useState(false);
  const [billingEmail, setBillingEmail] =
    React.useState<string>(defaultBillingEmail);

  const [loading, setLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  const [, setStripeElementValue] =
    React.useState<StripePaymentElementChangeEvent>();
  const [submissionError, setSubmissionError] = React.useState<string>();

  const [emailFocused, setEmailFocused] = React.useState(false);
  const previousEmail = usePreviousDistinct(billingEmail);
  const isEmailDirty = (!!previousEmail || submitted) && !emailFocused;
  const validEmail = isValidEmail(billingEmail || '');
  const [generateIntent] = useMutation(subscriptionSetupIntentGenerateMutation);

  const setupPaymentMethod = async (hasSubscription: boolean) => {
    if (!stripe || !elements) return;

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setSubmissionError(submitError?.message || 'An error occurred.');
      setLoading(false);
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          email: billingEmail
        }
      }
    });

    if (error) throw error;

    // If there is no subscription, we need to confirm the card setup cos it's probably an update
    // If there is a subscription, we don't need to confirm the card setup as the subscription handler will do that
    if (!hasSubscription) {
      const data = await generateIntent();
      const clientSecret = data?.data?.subscriptionSetupIntentGenerate;
      const { error: confirmError } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: paymentMethod.id
        }
      );

      if (confirmError) {
        throw confirmError;
      }
    }

    await client.mutate({
      mutation: setPaymentMethodMutation,
      variables: { paymentMethodId: paymentMethod.id },
      refetchQueries: [
        {
          query: workspaceMembersQuery,
          fetchPolicy: 'network-only'
        }
      ]
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setSubmitted(true);
    if (
      !validEmail ||
      (withAcceptCheckbox && !agree) ||
      !isStripeElementComplete
    ) {
      setSubmissionError('Please fill in all required fields correctly.');
      return;
    }

    setSubmissionError('');
    setLoading(true);

    const hasSubscription = !!handleCreateSubscription;

    try {
      await setupPaymentMethod(hasSubscription);
      if (hasSubscription) {
        await handleCreateSubscription();
      }
      onSuccess();
    } catch (apolloError) {
      try {
        const confirmed = await handleCardConfirmation(
          apolloError as GraphQLStripeError,
          setLoading
        );
        if (confirmed) {
          onSuccess();
        } else {
          throw apolloError;
        }
      } catch (error: unknown) {
        const confirmError = error as CardAuthError;
        const message = confirmError.message || 'An error occurred.';
        if (message && !ignoredErrors.some(msg => message.includes(msg))) {
          setSubmissionError(message);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleStripeChange = (
    stripeElementValue: StripePaymentElementChangeEvent
  ) => {
    setStripeElementValue(stripeElementValue);
    setIsStripeElementComplete(stripeElementValue.complete);
  };

  const [, setCardFocused] = React.useState(false);

  return (
    <StyledForm onSubmit={handleSubmit} noValidate>
      {submissionError && <StyledError>{submissionError}</StyledError>}
      <PaymentElement
        className='fs-block'
        onChange={handleStripeChange}
        onFocus={() => setCardFocused(true)}
        onBlur={() => setCardFocused(false)}
      />

      {updateBillingEmail && (
        <StyledLabel>
          <span css={[font.v4.c1['600']]}>Billing email</span>
          {isEmailDirty && !validEmail && (
            <div className='error'>Please enter a valid billing email.</div>
          )}
          <Input
            className='fs-block'
            style={{ height: '40px' }}
            error={isEmailDirty && !validEmail}
            onFocus={() => setEmailFocused(true)}
            onBlur={() => setEmailFocused(false)}
            type='email'
            placeholder='Enter an email address'
            name='email'
            value={billingEmail}
            onChange={(ev: React.FormEvent<HTMLInputElement>) =>
              setBillingEmail((ev.target as HTMLInputElement)?.value)
            }
          />
        </StyledLabel>
      )}

      {additionalInputs}

      {withAcceptCheckbox && (
        <div css={spacing.mt6}>
          {withAcceptCheckbox && submitted && !agree && (
            <div className='error'>
              Please agree to the Terms and Conditions.
            </div>
          )}
          <Checkbox
            error={withAcceptCheckbox && submitted && !agree}
            checked={agree}
            onChange={e => setAgree(e.target.checked)}
          >
            I agree to the Grain{' '}
            <a
              css={[
                'color: inherit; text-decoration: underline; font-weight: bold;'
              ]}
              onClick={e => {
                e.stopPropagation();
              }}
              href='/terms'
              target='_blank'
              rel='noopener noreferrer'
            >
              Terms and Conditions
            </a>
            .
          </Checkbox>
        </div>
      )}

      {!!summary && <StyledSummary>{summary}</StyledSummary>}

      <div css={['display: flex;', spacing.mt4]}>
        <Button
          htmlType='submit'
          loading={loading}
          type='primary'
          css={['flex: 1;', 'height: 48px;']}
        >
          {buttonText}
        </Button>
      </div>
    </StyledForm>
  );
}
