// @ts-strict-ignore
import React from 'react';
import { TopicEdit, useShowToast, useConfirm } from '@grain/grain-ui';
import { StyledContentWrapper, StyledPageHeader } from '../../SettingsWrapper';
import { EditableHeader } from '../EditableHeader';
import { PreviewMatchesModal } from './PreviewMatches';
import { useTopic } from './hooks';
import { validateTopicScope } from './utils';

import {
  SmartTopicMatchScope,
  SmartTopicSpeakerMatchScope
} from '@grain/api/schema.generated';
import { z } from 'zod';

import { useSearchParams } from 'react-router-dom';
import { SmartTopicV2 } from '@grain/api/schema.extra';
import pluralize from 'pluralize';
import { useHotspotByKey } from '~/components/Hotspot/context';

export type EditTopicProps = {
  topics: SmartTopicV2[];
  isNewTopic: boolean;
  onCancel: () => void;
};

const meetingTypeOptions = [
  {
    value: SmartTopicMatchScope.All,
    title: 'All meetings'
  },
  {
    value: SmartTopicMatchScope.External,
    title: 'Customer meetings'
  },
  {
    value: SmartTopicMatchScope.Internal,
    title: 'Team meetings'
  }
];

const smartTopicSchema = z
  .object({
    text: z.string().min(1),
    description: z.string(),
    matchScope: z.nativeEnum(SmartTopicMatchScope),
    speakerScope: z.nativeEnum(SmartTopicSpeakerMatchScope),
    phrases: z.array(z.string()),
    keywords: z.array(z.string())
  })
  .refine(
    ops => {
      return (
        ops.phrases.filter(Boolean).length > 0 ||
        ops.keywords.filter(Boolean).length > 0
      );
    },
    {
      message: 'Must have more than one trigger',
      path: ['triggers']
    }
  );

const speakerOptions = [
  {
    value: SmartTopicSpeakerMatchScope.All,
    title: 'All Speakers'
  },
  {
    value: SmartTopicSpeakerMatchScope.External,
    title: 'Customers'
  },
  {
    value: SmartTopicSpeakerMatchScope.Internal,
    title: 'Team members'
  }
];

type ErrorType = {
  title: string;
  description: string;
};
const error_messages: Record<string, ErrorType> = {
  'too few examples': {
    title: 'Cannot delete this example',
    description: 'Trackers require at least one example.'
  },
  'invalid example': {
    title: 'Inconsistent example',
    description:
      'Please add an example phrase more consistent with previous examples to see matches for your trackers.'
  }
};

export const EditTopic = ({ topics, onCancel, isNewTopic }: EditTopicProps) => {
  const { hasSeenHotspot, setStepViewed } = useHotspotByKey('smart-tags-ftux');
  const [searchParams] = useSearchParams();
  const showToast = useShowToast();
  const showConfirm = useConfirm();
  const tagId = searchParams.get('tagId');
  const {
    currentTopic: updatedTopic,
    handleSmartTopicV2Configure,
    currentVariables,
    setCurrentTopic,
    setCurrentVariables,
    renameTagText,
    currentSmartTopicMatchPreview
  } = useTopic(topics, tagId);

  const { success: validateNoneEmpty } =
    smartTopicSchema.safeParse(currentVariables);

  const scopeValidationError = validateTopicScope(currentVariables);

  const tagTextChanged = currentVariables.text !== updatedTopic?.tag?.text;
  const canUpdate =
    !scopeValidationError &&
    validateNoneEmpty &&
    (currentVariables.speakerScope !== updatedTopic?.speakerScope ||
      currentVariables.matchScope !== updatedTopic?.matchScope ||
      currentVariables.phrases?.toString() !==
        updatedTopic?.phrases?.toString() ||
      currentVariables.keywords?.toString() !==
        updatedTopic?.keywords?.toString());

  const canSave =
    (!isNewTopic && (tagTextChanged || canUpdate)) ||
    (isNewTopic && tagTextChanged && canUpdate);

  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);

  const handleSave = async () => {
    if (!isNewTopic) {
      const matchText = pluralize(
        'match',
        currentSmartTopicMatchPreview?.matchCount || 0
      );
      // Only show confirm if it will affect existing matches
      if (
        currentSmartTopicMatchPreview?.matchCount ||
        updatedTopic?.matchCount
      ) {
        const result = await showConfirm({
          width: 400,
          confirmContent: 'Save Changes',
          cancelContent: 'Cancel',
          confirmButtonType: 'primary',
          description: `This will add ${
            currentSmartTopicMatchPreview?.matchCount || 0
          } ${matchText} and remove ${
            updatedTopic?.matchCount || 0
          } ${matchText} of #${
            currentVariables.text
          } across your workspace’s meetings.`,
          title: 'Change this Tracker across your entire workspace?'
        });
        if (!result.isConfirm) return;
      }
    }
    if (!isNewTopic && tagTextChanged) {
      await renameTagText({
        variables: {
          from: updatedTopic?.tag?.text,
          to: currentVariables.text
        }
      });
    }
    if (!canUpdate) {
      setCurrentTopic({
        ...updatedTopic,
        tag: { ...updatedTopic?.tag, text: currentVariables.text }
      });
      const topicLabel = 'updated';
      showToast({
        content: `${currentVariables.text} Tracker ${topicLabel}`,
        type: 'success',
        uniqueId: 'smart_topic_created_updated'
      });
      onCancel();
      return;
    }
    return handleSmartTopicV2Configure({
      variables: currentVariables,
      onCompleted: ({ smartTopicConfigureV2: data }) => {
        setCurrentTopic(data);
        const topicLabel = isNewTopic ? 'created' : 'updated';
        showToast({
          content: `${currentVariables.text} Tracker ${topicLabel}`,
          type: 'success',
          uniqueId: 'smart_topic_created_updated'
        });
        if (!hasSeenHotspot('create-or-edit')) {
          setStepViewed('create-or-edit');
        }
        onCancel();
      },
      onError: e => {
        showConfirm({
          width: 400,
          confirmContent: 'Got it',
          showCancel: false,
          confirmButtonType: 'primary',
          description:
            error_messages[e.message.toLowerCase()]?.description ||
            'Please try again with all the appropriate fields filled out.',
          title:
            error_messages[e.message.toLowerCase()]?.title ||
            'There was an error processing the input'
        });
      }
    });
  };

  return (
    <>
      <StyledPageHeader isTopic sticky>
        <EditableHeader
          title={currentVariables?.text}
          placeholder='Untitled Tracker'
          onTitleChange={title =>
            setCurrentVariables({
              ...currentVariables,
              text: title?.replace(/\s+/g, '-')
            })
          }
          tooltipContent={scopeValidationError}
          id={updatedTopic?.id}
          onCancel={onCancel}
          onSave={handleSave}
          canSave={canSave}
        />
      </StyledPageHeader>
      <StyledContentWrapper isTopic>
        <TopicEdit
          meetingTypeOptions={meetingTypeOptions}
          speakerOptions={speakerOptions}
          currentVariables={currentVariables}
          setCurrentVariables={setCurrentVariables}
          matchCount={currentSmartTopicMatchPreview?.matchCount || 0}
          recordingCount={currentSmartTopicMatchPreview?.recordingCount || 0}
          openPreviewModal={() => setIsEditModalOpen(true)}
          scopeValidationError={scopeValidationError}
        />
      </StyledContentWrapper>
      <PreviewMatchesModal
        isOpen={isEditModalOpen}
        currentSmartTopicMatchPreview={currentSmartTopicMatchPreview}
        onClose={() => setIsEditModalOpen(false)}
      />
    </>
  );
};
