import React from 'react';
import copy from 'clipboard-copy';

import Dropdown, { MenuItem } from '@grain/components/Dropdown';
import { useMutation } from '@grain/api/graphql';
import AccessBar, { filterAndSortUsers } from '../common/AccessBar';
import Button from '@grain/components/Button';
import {
  regenerateStoryPublicUrlMutation,
  storyShareMutation,
  storyUnshareMutation,
  storyWorkspaceSharedSetMutation
} from '~/modules/stories/graphql';
import { useWorkspaceWithMembers } from '@grain/components/Workspace/hooks';
import { colors, media, spacing } from '@grain/styles/constants';
import { useMyself } from '~/support/auth';
import { useMediaQuery } from '@grain/components/support/browser';

import InviteForm from '../common/InviteForm';
import MembersList from '../common/MembersList';
import { EmailInput } from '~/components/MultiInput';
import { SHARE_STORY_ID } from '@grain/components/modals/constants';
import {
  StyledModalContentWrapper,
  StyledMoreButton,
  StyledModalFooter,
  StyledRowTitle,
  StyledShareRow
} from '../common/styles';
import {
  Modal,
  ModalTitle,
  useRouteModal,
  useAnalytics,
  Icon,
  useShowToast
} from '@grain/grain-ui';
import { Sharee } from '@grain/api/schema.generated';
import { StoryFullFragmentFragment } from '~/modules/stories/index.generated';

const EMPTY_OBJECT: Partial<StoryFullFragmentFragment> = {};

type ShareStoryPermissionsProps = {
  story: StoryFullFragmentFragment | typeof EMPTY_OBJECT;
};

export default function ShareStoryPermissions({
  story = EMPTY_OBJECT,
  ...rest
}: ShareStoryPermissionsProps) {
  const {
    creator,
    sharedUsers = [],
    id: storyId,
    workspaceShared
  } = story ?? {};
  const showToast = useShowToast();
  const [inputKey, setInputKey] = React.useState<number | null>(null);
  const [isEmailInputActive, setIsEmailInputActive] = React.useState(false);
  const [selectedEmails, setSelectedEmails] = React.useState<string[]>([]);
  const { close } = useRouteModal(SHARE_STORY_ID);
  const isMobile = useMediaQuery(media.small);
  const { getMediaSharedTrigger } = useAnalytics();

  const [storyUnshare] = useMutation(storyUnshareMutation, {
    variables: { storyId }
  });
  const [storyShare] = useMutation(storyShareMutation, {
    variables: { storyId },
    onError(error) {
      if (/users_exist_in_other_workspaces/.test(error.message)) {
        // Handle error here
        showToast({
          content: 'User already exists in another workspace',
          type: 'failure',
          durationMs: 7000,
          showCloseButton: true,
          uniqueId: 'error'
        });
        return true;
      }
    }
  });

  const [
    regenerateStoryPublicUrl,
    { loading: regenerateStoryPublicUrlLoading }
  ] = useMutation(regenerateStoryPublicUrlMutation, {
    variables: { storyId },
    onCompleted(data) {
      showToast({
        content: 'Watch-only link generated and copied to clipboard',
        type: 'success'
      });
      copy(data.storyPublicUrlRegenerate.publicUrl);
    }
  });

  const { workspace } = useWorkspaceWithMembers();
  const { myself } = useMyself();

  const allWorkspaceMembers = React.useMemo(
    () => workspace?.users ?? [],
    [workspace?.users]
  );

  const isExplicitCollaborator = sharedUsers.find(u => u.id === myself?.id);

  const handleUnshareUserOrGuest = async (invitee: Sharee) => {
    const isGuest = invitee.id === undefined;

    const res = await storyUnshare({
      variables: {
        userIds: !isGuest ? [invitee.id] : [],
        emails: isGuest ? [invitee] : []
      }
    });
    showToast({
      content: `${
        isGuest ? invitee : invitee.email
      } is no longer a collaborator`,
      uniqueId: 'story-share-toast',
      type: 'success'
    });
    return res;
  };

  const handleBackNavigation = () => {
    setIsEmailInputActive(false);
    setInputKey(Math.random());
  };

  const handleCopyUrl = (url: string, message: string) => {
    copy(url);
    showToast({ content: message, type: 'success' });
  };

  const emailsToIgnore = React.useMemo(
    () => sharedUsers.map(user => user.email),
    [sharedUsers]
  );

  const availableMembers = React.useMemo(
    () =>
      (allWorkspaceMembers ?? []).filter(
        user => !sharedUsers.find(u => u.id === user.id)
      ),
    [allWorkspaceMembers, sharedUsers]
  );

  const [storyWorkspaceSharedSet] = useMutation(
    storyWorkspaceSharedSetMutation,
    {
      variables: { storyId }
    }
  );

  const filteredSharedUsers = filterAndSortUsers({
    users: sharedUsers,
    guestEmails: [],
    owner: creator,
    sharedToAll: !!story?.workspaceShared
  });

  function handleWorkspaceToggle(checked: boolean) {
    if (checked) {
      storyWorkspaceSharedSet({
        variables: { shared: true }
      });
      showToast({
        content: 'All members of your workspace are now collaborators',
        uniqueId: 'story-share-toast'
      });
    } else {
      storyWorkspaceSharedSet({
        variables: { shared: false }
      });
    }
  }

  const handleManageAccess = () => {
    setIsEmailInputActive(true);
  };

  const handleShareUsers = async (note: string) => {
    const res = await storyShare({
      variables: {
        userIds: [],
        emails: selectedEmails,
        note
      }
    });

    // on success
    if (res?.data) {
      showToast({
        content: `Story shared with ${selectedEmails.length} ${
          selectedEmails.length > 1 ? 'people ' : 'person'
        }`,
        uniqueId: 'story-share-toast',
        type: 'success'
      });
    }

    close();
    return res;
  };

  return (
    <Modal
      closable
      css={[!isMobile && 'width: 600px; !important', 'background: white;']}
      onCancel={close}
      {...rest}
    >
      <StyledModalContentWrapper>
        <ModalTitle title={'Share story'} centered />
        {!isEmailInputActive && (
          <div>
            <StyledShareRow css={[`background: ${colors.goose};`]}>
              <Icon.ChibiStoryPlay height='32' css={['flex-shrink: 0;']} />
              <StyledRowTitle>Get watch-only link to story</StyledRowTitle>
              {story?.publicUrl ? (
                <>
                  <Dropdown
                    placement='bottom'
                    targetElement={
                      <StyledMoreButton>
                        <Icon.MenuMore height='16px' />
                      </StyledMoreButton>
                    }
                  >
                    <MenuItem onClick={() => regenerateStoryPublicUrl()}>
                      <Icon.Regenerate />
                      Regenerate link
                    </MenuItem>
                  </Dropdown>
                  <Button
                    css={['width: 140px;']}
                    loading={regenerateStoryPublicUrlLoading}
                    type='primary'
                    onClick={() =>
                      handleCopyUrl(
                        story?.publicUrl ?? '',
                        'View-only link copied to clipboard'
                      )
                    }
                    data-track='Media Shared'
                    data-track-args={JSON.stringify({
                      share_type: 'copy_view_only_link',
                      trigger: getMediaSharedTrigger()
                    })}
                    data-track-entities={JSON.stringify([
                      'user',
                      'workspace',
                      `story:${story.id}`
                    ])}
                  >
                    Copy link
                  </Button>
                </>
              ) : (
                <Button
                  css={['width: 140px;']}
                  loading={regenerateStoryPublicUrlLoading}
                  onClick={() => regenerateStoryPublicUrl()}
                >
                  Generate link
                </Button>
              )}
            </StyledShareRow>
          </div>
        )}
        <>
          <EmailInput
            css={[
              spacing.px7,
              spacing.mt7,
              !isEmailInputActive && spacing.mb7,
              isEmailInputActive && spacing.mt6,
              isEmailInputActive && spacing.mb6,
              isMobile && spacing.px4
            ]}
            autosuggestions={availableMembers}
            autosuggestionsOnly={true}
            emailsToIgnore={emailsToIgnore}
            onChangeEmails={setSelectedEmails}
            key={inputKey}
            placeholder='Invite members of your workspace via name or email'
            onFocus={() => setIsEmailInputActive(true)}
          />
          {!isEmailInputActive && (
            <StyledModalFooter css={spacing.py4}>
              <AccessBar
                users={[creator, ...filteredSharedUsers]}
                workspaceShared={!!workspaceShared}
                isWorkspaceDisabled={!isExplicitCollaborator}
                onWorkspaceToggle={handleWorkspaceToggle}
                onManageAccessClick={handleManageAccess}
                mediaEntity={`story:${story.id}`}
              />
            </StyledModalFooter>
          )}
          {isEmailInputActive && selectedEmails.length === 0 && (
            <MembersList
              users={filteredSharedUsers}
              onWorkspaceToggle={handleWorkspaceToggle}
              sharedToAll={workspaceShared}
              isWorkspaceDisabled={!isExplicitCollaborator}
              onUserUnshare={handleUnshareUserOrGuest}
              owner={creator}
            />
          )}
          {selectedEmails.length > 0 && (
            <InviteForm
              onSend={handleShareUsers}
              onBack={handleBackNavigation}
              selectedEmails={selectedEmails}
            />
          )}
        </>
      </StyledModalContentWrapper>
    </Modal>
  );
}
