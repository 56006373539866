import styled from '@emotion/styled';
import { color, colors, font, spacing } from '@grain/styles/constants';
import { pxToRem } from '@grain/grain-ui';

export const StyledInviteSection = styled.div`
  max-width: 600px;
`;

export const StyledNoteTextArea = styled.textarea`
  ${font.bodyS};
  ${font.family.body};
  ${spacing.mb2};
  ${spacing.mt2};
  ${spacing.p3};
  border: 1px solid ${colors.gull};
  min-height: 72px;
  outline: none;
  resize: vertical;
  width: 100%;
`;

export const StyledInviteButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

export const StyledWrapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledUsersList = styled.div`
  flex-shrink: 1;
`;

export const StyledUserSection = styled.div`
  ${spacing.mb6};
  margin-right: 80px;
  width: 300px;
`;

export const StyledDescription = styled.div`
  ${color.crow};
  ${spacing.mb3};
  ${spacing.mt1};
  font-size: 0.75rem;
  line-height: 1 rem;
`;

export const StyledGrowYourWorkspaceSubtitle = styled.div`
  ${spacing.mt2};
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(16)};
  font-weight: 400;
`;

export const StyledConfirmTitle = styled.div`
  width: 100%;
  padding: 0 40px;
`;
