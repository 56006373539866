// @ts-strict-ignore
import React from 'react';
import pluralize from 'pluralize';
import { SubscriptionPlan } from '@grain/api/schema.generated';
import { usePlan } from '@grain/components/Subscriptions/hooks';
import { useWorkspaceWithMembers } from '@grain/components/Workspace/hooks';
import { useSearchParams } from 'react-router-dom';
import { FeatureList } from './Features';
import { PlanActionButton } from './PlanActionButton';
import { PlanAction } from './types';

import {
  StyledContainer,
  StyledDescription,
  StyledPlanHeader,
  StyledAnnualRate,
  StyledMonthlyRate,
  StyledPriceContainer,
  StyledYourSeatHeader,
  StyledPlanName,
  StyledMinimumSeat,
  StyledCornerPill
} from './styles';
import { displayCents } from '~/support/price';
import { getFreeTrialData } from '@grain/components/support/utils';
import { css } from '@emotion/react';
import { theme } from 'ui/v4/theme';

export type IVariant = 'large' | 'medium' | 'small';

type GrainPlanCardProps = {
  plan: SubscriptionPlan;
  previousPlan?: SubscriptionPlan;
  currentPlanSku?: string;
  upcomingPlanSku?: string;
  variant?: IVariant;
  planAction: PlanAction;
  overrideActionText?: string;
  actionCompletedText?: React.ReactNode;
  currentTrial?: boolean;
  onAction?: () => void;
  currentPlanIndex?: number;
  forcePrice?: 'YEARLY' | 'MONTHLY';
  showBillingPeriod?: boolean;
  canEdit?: boolean;
  loading?: boolean;
  shouldHighlight?: boolean;
  planIndex?: number;
  disableButtonText?: string;
  isWorkspaceLoading?: boolean;
  isAdmin?: boolean;
};

type ActionTextFn = (args: { isAdmin?: boolean; name?: string }) => string;

const PLAN_ACTION_TEXT: Record<PlanAction, ActionTextFn | string> = {
  CANCEL_PLAN: ({ name }) => `Cancel ${name}`,
  CHANGE_PLAN: ({ name }) => `Downgrade to ${name}`,
  CURRENT_PLAN: 'Current plan',
  FALLBACK_AFTER_TRIAL: 'Fallback after trial',
  INVITE_TEAM: 'Invite team',
  REQUEST_TRIAL: 'Request a trial',
  REQUEST_UPGRADE: 'Request upgrade from admin',
  START_TRIAL: 'Start trial',
  UPCOMING_PLAN: 'Upcoming plan',
  UPGRADE_PLAN: 'Upgrade',
  VIEW_MEMBERS: ({ isAdmin }) => (isAdmin ? 'Manage members' : 'View members')
};

const NOOP = () => {};
const EMPTY_OBJECT = {};

export default function GrainPlanCard({
  onAction = NOOP,
  planAction,
  actionCompletedText = null,
  currentTrial = false,
  variant = 'large',
  plan,
  previousPlan = EMPTY_OBJECT as SubscriptionPlan,
  planIndex = 0,
  loading = false,
  currentPlanSku = null,
  currentPlanIndex,
  canEdit = true,
  shouldHighlight = false,
  disableButtonText = null,
  isWorkspaceLoading = false,
  isAdmin = false,
  ...rest
}: GrainPlanCardProps) {
  const [searchParams] = useSearchParams();

  const { subscriptionPlan: currentPlan } = usePlan(currentPlanSku);

  // The user's current subscription
  const { workspace } = useWorkspaceWithMembers();
  const workspaceSubscription = workspace?.workspaceSubscription;
  searchParams.set('dialog', 'invite');

  const { trialDaysLeft } = getFreeTrialData(workspaceSubscription);

  // No loading state
  if (loading || !plan || !currentPlan) return null;

  const {
    sku,
    monthlyPrice,
    yearlyPrice,
    description,
    featureBullets: features,
    shortName,
    isFree,
    isLegacy,
    isPopular
  } = plan;

  const isCurrentPlan = sku === currentPlanSku;

  const showPopularTag =
    isPopular &&
    !isWorkspaceLoading &&
    !isCurrentPlan &&
    planIndex > currentPlanIndex;

  const hasNoPrice = !yearlyPrice || !monthlyPrice;
  const isSelfServe = !isFree && hasNoPrice;
  const annualPrice = isSelfServe
    ? 'Let’s Talk'
    : `$${displayCents(yearlyPrice / 12)}`;

  const monthlyPriceToShow = monthlyPrice
    ? `$${displayCents(monthlyPrice)}`
    : null;
  let monthlyInfo = 'billed monthly';

  if (isFree) {
    monthlyInfo = 'Free for everyone';
  } else if (isSelfServe) {
    monthlyInfo = 'Contact sales for demo & pricing';
  }

  const actionTextFnArgs = (() => {
    if (planAction === PlanAction.CANCEL_PLAN) {
      return { name: currentPlan.name };
    }
    if (planAction === PlanAction.CHANGE_PLAN) {
      return { name: plan.shortName };
    }
    if (planAction === PlanAction.VIEW_MEMBERS) {
      return { isAdmin };
    }
  })();

  const actionTextOrFn = PLAN_ACTION_TEXT[planAction];
  const actionText =
    typeof actionTextOrFn === 'function'
      ? actionTextOrFn(actionTextFnArgs)
      : actionTextOrFn;

  return (
    <StyledContainer
      {...rest}
      isCurrentPlan={isCurrentPlan && !isWorkspaceLoading}
      isTrial={currentTrial}
      variant={variant}
      shouldHighlight={shouldHighlight || showPopularTag || currentTrial}
    >
      {showPopularTag && <StyledCornerPill>Popular</StyledCornerPill>}
      {isCurrentPlan && currentTrial && variant === 'medium' && (
        <StyledCornerPill>
          {trialDaysLeft}-{pluralize('day', trialDaysLeft)} left in trial
        </StyledCornerPill>
      )}
      {isCurrentPlan && variant === 'large' && (
        <StyledYourSeatHeader variant={variant}>
          Your Plan
          {currentTrial && (
            <span
              css={css`
                ${theme.tokens.typography.c1[500]};
                color: ${theme.primitives.color.plan500};
              `}
            >
              {trialDaysLeft} {pluralize('day', trialDaysLeft)} left in trial
            </span>
          )}
        </StyledYourSeatHeader>
      )}
      <StyledPlanHeader>
        <StyledPlanName>
          {shortName}
          {isLegacy && ' (Legacy)'}{' '}
        </StyledPlanName>
        <div className='plan-description'>{description}</div>
        <StyledPriceContainer>
          <div css={['display: flex; align-items: center; height: 40px']}>
            <StyledAnnualRate>{annualPrice}</StyledAnnualRate>
            {!isSelfServe && !isFree && (
              <StyledMinimumSeat>
                <div>per seat/month</div>
                <div>billed annually</div>
              </StyledMinimumSeat>
            )}
          </div>
          <StyledMonthlyRate>
            <span>{monthlyPriceToShow}</span> {monthlyInfo}
          </StyledMonthlyRate>
        </StyledPriceContainer>
      </StyledPlanHeader>
      {variant !== 'small' && (
        <StyledDescription>
          {['medium', 'large'].includes(variant) && !isWorkspaceLoading && (
            <PlanActionButton
              isCurrentPlan={isCurrentPlan}
              onAction={onAction}
              currentPlanSku={currentPlanSku}
              plan={plan}
              canEdit={canEdit}
              actionText={actionText}
              actionCompletedText={actionCompletedText}
              planIndex={planIndex}
              hasNoPrice={hasNoPrice}
              disableButtonText={disableButtonText}
              isWorkspaceOnTrial={!!workspaceSubscription?.trial}
            />
          )}

          {['medium', 'large'].includes(variant) && features?.length > 0 && (
            <FeatureList
              features={features}
              previousPlanName={!isFree ? previousPlan?.shortName : null}
            />
          )}
        </StyledDescription>
      )}
    </StyledContainer>
  );
}

export { PlanAction } from './types';
