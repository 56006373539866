import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import useSessionStorage from 'react-use/lib/useSessionStorage';
import { useWorkspaceSharedAutomationsQuery } from '../../settings.generated';
import {
  Checkbox,
  useChangeSettings,
  useConfirm,
  color,
  spacing,
  Icon
} from '@grain/grain-ui';
import {
  Select,
  Option as OptionV4,
  Tooltip,
  Button,
  theme
} from '@grain/grain-ui/v4';
import {
  useExperiment,
  useMyself,
  useWorkspace,
  useFeature
} from '@grain/api/auth';

import {
  StyledSectionLabel,
  StyledSubSectionLabel,
  StyledOption,
  StyledOptionTitle,
  StyledSubSectionLabelInfo
} from '../styles';
import {
  StyledContainer,
  StyledDisabledInfo,
  StyledSelectWrapper,
  StyledSeparator
} from './styles';

import CalendarStatus from '../Profile/CalendarStatus';
import {
  CollectionShareState,
  RecapShareType,
  RecordingShareState,
  IntelligenceTemplate
} from '@grain/api/schema.generated';
import { templateSettingsOptions } from '../../Workspace/NotesTemplateTab/hooks';
import {
  Description,
  StyledSectionMainDescription
} from '../../Workspace/NotesTemplateTab/styles';
import { useNotesTemplates } from '~/modules/templates/hooks';
import { css } from '@emotion/react';
import { GatedRecordingAccessSelect } from '~/modules/gates/GatedRecordingAccessSelect';
import { GatedOption, GatedSelect } from '~/modules/gates/GatedSelect';
import { MeetingLanguageSelector } from '~/components/MeetingLanguageSelector/MeetingLanguageSelector';
import { Link } from 'react-router-dom';

const StyledCalendarIntegrationContainer = styled.div`
  font-size: calc(14rem / 16);
  .connection-status {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    gap: 16px;
    ${color.bg.goose};
    border: 1px solid #f0f0f0;
    border-radius: 12px;
  }
  .connection-email {
    align-items: center;
    display: flex;
    svg {
      ${spacing.mr2};
      height: 16px;
      width: 16px;
    }
  }
`;

export const settingSections = {
  Layout: 'layout'
};

export const recordingShareOptions = {
  ALL: 1,
  INTERNAL: 2,
  EXTERNAL: 3,
  NONE: 4,
  WORKSPACE: 5
};

const recorderSelectorOptions = [
  {
    title: 'Auto record all meetings',
    value: recordingShareOptions.ALL
  },
  {
    title: 'Auto record all meetings with only team participants',
    value: recordingShareOptions.INTERNAL
  },
  {
    title: 'Auto record all meetings with customer participants',
    value: recordingShareOptions.EXTERNAL
  },
  {
    title: 'Don’t auto record any meetings',
    value: recordingShareOptions.NONE
  }
];

const workspaceShareSelectorOptions = [
  {
    title: 'All meetings',
    value: recordingShareOptions.ALL
  },
  {
    title: 'All meetings with only team participants',
    value: recordingShareOptions.INTERNAL
  },
  {
    title: 'All meetings with customer participants',
    value: recordingShareOptions.EXTERNAL
  },
  {
    title: 'No meetings',
    value: recordingShareOptions.NONE
  }
];

const recapShareSelectorOptions = [
  {
    title: 'Only send recap to participants in your Grain workspace',
    value: RecapShareType.Workspace
  },
  {
    title: 'Send recap to all participants from your team domain(s)',
    value: RecapShareType.Internal
  },
  {
    title: 'Send recap to all participants',
    value: RecapShareType.All
  },
  {
    title: 'Don’t send recap emails',
    value: RecapShareType.None
  }
];

const noneTemplate = {
  title: 'None',
  id: 'none'
};

const noneTemplateOption = {
  title: 'None',
  value: 'none'
};

export default function Recording() {
  const showConfirm = useConfirm();
  const { enabled: isFreemiumEnabled } = useExperiment('freemium');
  const { myself } = useMyself();
  const { workspace } = useWorkspace();
  const [changeSettings] = useChangeSettings();
  const { data: automations } = useWorkspaceSharedAutomationsQuery();
  const hubspotAutomation =
    automations?.hubspot?.__typename === 'HubspotIntegration'
      ? automations?.hubspot
      : null;
  const slackAutomation =
    automations?.slack?.__typename === 'SlackIntegration'
      ? automations?.slack
      : null;

  const templates = useNotesTemplates();
  const templateOptions = useMemo(() => {
    const options = [
      noneTemplateOption,
      ...templates?.map(template => ({
        title: template.title,
        value: template.id
      }))
    ];

    return options;
  }, [templates]);

  const [, setAutoRecordHidden] = useSessionStorage('auto_record_dismissed');
  const { enabled: isPaidSettingsEnabled } = useFeature('paid_settings');

  const getTemplateForType = (
    isOverridden: boolean,
    userTemplate: string | null,
    workspaceTemplate: string | null
  ): IntelligenceTemplate | typeof noneTemplate | undefined => {
    const templateId = isOverridden ? userTemplate : workspaceTemplate;
    return templateId
      ? templates?.find(template => template.id === templateId)
      : noneTemplate;
  };

  const getSelectedTemplate = (type: 'external' | 'internal') => {
    const isOverridden =
      type === 'internal'
        ? myself?.settings?.internalTemplateWorkspaceOverridden
        : myself?.settings?.externalTemplateWorkspaceOverridden;

    const userTemplate =
      type === 'internal'
        ? myself?.settings?.internalRecordingIntelligenceTemplate?.id
        : myself?.settings?.externalRecordingIntelligenceTemplate?.id;

    const workspaceTemplate =
      type === 'internal'
        ? workspace?.internalRecordingIntelligenceTemplateV2?.id
        : workspace?.externalRecordingIntelligenceTemplateV2?.id;

    const template = getTemplateForType(
      isOverridden ?? false,
      userTemplate ?? null,
      workspaceTemplate ?? null
    );
    return template ? [{ title: template.title, id: template.id }] : [];
  };

  const handleChangeTemplate = React.useCallback(
    (type: 'external' | 'internal', template?: { value: string }) => {
      const types = templateSettingsOptions.map(option => option.value);
      if (!types.includes(type) || !template) return;
      const templateId = template.value === 'none' ? null : template.value;
      const workspaceTemplate =
        workspace?.[`${type}RecordingIntelligenceTemplateV2`]?.id;
      changeSettings({
        [`${type}RecordingIntelligenceTemplateId`]: templateId,
        [`${type}TemplateWorkspaceOverridden`]: workspaceTemplate !== templateId
      });
    },
    [changeSettings, workspace]
  );

  // null if no connected accounts have calendar access
  const calendarConnectedAccount = React.useMemo(() => {
    if (!myself) return null;
    return (
      myself.oauthAccounts.find(account => account.calendarConnected) ?? null
    );
  }, [myself]);

  const {
    autoRecordExternal = null,
    autoRecordInternal = null,
    autoRecordOrganiserOnly = null,
    autoShareExternalRecordings = null,
    autoShareInternalRecordings = null,
    recapShareMode = null,
    defaultRecordingsShareState = null
  } = myself?.settings || {};

  const confirmShareRecordingsWithWorkspace = React.useCallback(
    (
      disablingType: 'external' | 'internal' | 'customer' | 'team' | '',
      checked: boolean,
      onConfirm: (checked: boolean) => void
    ) => {
      const shareNewRecordingsWithWorkspace = checked;

      const hubspotEnabled = hubspotAutomation?.automationEnabled;
      const slackEnabled = (slackAutomation?.automations?.length ?? 0) > 0;

      const shouldConfirm =
        !shareNewRecordingsWithWorkspace && (hubspotEnabled || slackEnabled);

      if (shouldConfirm) {
        const title = 'Are you sure you want to change your sharing settings?';

        const enabled = [];
        if (hubspotEnabled) {
          enabled.push('HubSpot');
        }
        if (slackEnabled) {
          enabled.push('Slack');
        }
        const enabledStr = enabled.join(', ');

        const description = `Changing this sharing setting will limit active Automations. No ${disablingType} meetings will be automatically sent to: ${enabledStr}`;
        showConfirm({
          closable: false,
          width: 418,
          title,
          description,
          confirmContent: 'Continue',
          confirmButtonProps: { variant: 'primary' },
          onConfirm: () => {
            onConfirm(checked);
          }
        });
      } else {
        onConfirm(checked);
      }
    },
    [
      hubspotAutomation?.automationEnabled,
      slackAutomation?.automations?.length,
      showConfirm
    ]
  );

  const changeShareSettings = React.useCallback(
    (value: number) => {
      if (value === recordingShareOptions.ALL) {
        changeSettings({
          autoShareExternalRecordings: true,
          autoShareInternalRecordings: true
        });
      } else if (value === recordingShareOptions.INTERNAL) {
        confirmShareRecordingsWithWorkspace('customer', false, () => {
          changeSettings({
            autoShareInternalRecordings: true,
            autoShareExternalRecordings: false
          });
        });
      } else if (value === recordingShareOptions.EXTERNAL) {
        confirmShareRecordingsWithWorkspace('team', false, () => {
          changeSettings({
            autoShareExternalRecordings: true,
            autoShareInternalRecordings: false
          });
        });
      } else if (value === recordingShareOptions.NONE) {
        confirmShareRecordingsWithWorkspace('', false, () => {
          changeSettings({
            autoShareExternalRecordings: false,
            autoShareInternalRecordings: false
          });
        });
      }
    },
    [changeSettings, confirmShareRecordingsWithWorkspace]
  );

  const changeRecordSettings = React.useCallback(
    (value: number) => {
      if (
        ![
          recordingShareOptions.ALL,
          recordingShareOptions.INTERNAL,
          recordingShareOptions.EXTERNAL,
          recordingShareOptions.NONE
        ].includes(value)
      ) {
        return;
      }

      const settings = {
        autoRecordExternal: false,
        autoRecordInternal: false,
        autoRecordOrganiserOnly:
          autoRecordOrganiserOnly ?? workspace?.defaultAutoRecordOrganiserOnly
      };

      if (value === recordingShareOptions.ALL) {
        settings.autoRecordExternal = true;
        settings.autoRecordInternal = true;
      } else if (value === recordingShareOptions.INTERNAL) {
        settings.autoRecordInternal = true;
      } else if (value === recordingShareOptions.EXTERNAL) {
        settings.autoRecordExternal = true;
      }
      if (value !== recordingShareOptions.NONE) setAutoRecordHidden(true);
      changeSettings(settings);
    },
    [
      autoRecordOrganiserOnly,
      changeSettings,
      workspace?.defaultAutoRecordOrganiserOnly,
      setAutoRecordHidden
    ]
  );

  const handleCheckedOrganiserOnly = React.useCallback(
    (checked: boolean) => {
      changeSettings({
        autoRecordOrganiserOnly: checked,
        autoRecordExternal:
          autoRecordExternal ?? workspace?.defaultAutoRecordExternal,
        autoRecordInternal:
          autoRecordInternal ?? workspace?.defaultAutoRecordInternal
      });
    },
    [
      autoRecordExternal,
      autoRecordInternal,
      changeSettings,
      workspace?.defaultAutoRecordExternal,
      workspace?.defaultAutoRecordInternal
    ]
  );

  const getRecordSetting = React.useCallback(
    (autoRecordExternal: boolean, autoRecordInternal: boolean) => {
      if (autoRecordExternal && autoRecordInternal) {
        return recorderSelectorOptions.find(
          option => option.value === recordingShareOptions.ALL
        );
      }
      if (autoRecordInternal && !autoRecordExternal) {
        return recorderSelectorOptions.find(
          option => option.value === recordingShareOptions.INTERNAL
        );
      }
      if (autoRecordExternal && !autoRecordInternal) {
        return recorderSelectorOptions.find(
          option => option.value === recordingShareOptions.EXTERNAL
        );
      }
      if (!autoRecordExternal && !autoRecordInternal) {
        return recorderSelectorOptions.find(
          option => option.value === recordingShareOptions.NONE
        );
      }
    },
    []
  );

  const recordSetting = React.useMemo(() => {
    if (autoRecordExternal === null || autoRecordInternal === null) {
      if (
        workspace?.defaultAutoRecordExternal === null ||
        workspace?.defaultAutoRecordInternal === null
      ) {
        return recorderSelectorOptions[3];
      }

      return getRecordSetting(
        workspace?.defaultAutoRecordExternal ?? false,
        workspace?.defaultAutoRecordInternal ?? false
      );
    }

    return getRecordSetting(autoRecordExternal, autoRecordInternal);
  }, [
    autoRecordExternal,
    autoRecordInternal,
    getRecordSetting,
    workspace?.defaultAutoRecordExternal,
    workspace?.defaultAutoRecordInternal
  ]);

  const getShareSetting = React.useCallback(
    (
      autoShareExternalRecordings: boolean,
      autoShareInternalRecordings: boolean
    ) => {
      if (autoShareExternalRecordings && autoShareInternalRecordings) {
        return workspaceShareSelectorOptions.find(
          option => option.value === recordingShareOptions.ALL
        );
      }
      if (autoShareInternalRecordings && !autoShareExternalRecordings) {
        return workspaceShareSelectorOptions.find(
          option => option.value === recordingShareOptions.INTERNAL
        );
      }
      if (autoShareExternalRecordings && !autoShareInternalRecordings) {
        return workspaceShareSelectorOptions.find(
          option => option.value === recordingShareOptions.EXTERNAL
        );
      }
      if (!autoShareExternalRecordings && !autoShareInternalRecordings) {
        return workspaceShareSelectorOptions.find(
          option => option.value === recordingShareOptions.NONE
        );
      }
    },
    []
  );

  const shareSetting = React.useMemo(() => {
    if (
      autoShareExternalRecordings === null &&
      autoShareInternalRecordings === null
    ) {
      return getShareSetting(
        workspace?.autoShareExternalRecordings ?? false,
        workspace?.autoShareInternalRecordings ?? false
      );
    }

    return getShareSetting(
      autoShareExternalRecordings ?? false,
      autoShareInternalRecordings ?? false
    );
  }, [
    autoShareExternalRecordings,
    autoShareInternalRecordings,
    getShareSetting,
    workspace?.autoShareExternalRecordings,
    workspace?.autoShareInternalRecordings
  ]);

  const recapShare = React.useMemo(() => {
    const setting =
      recapShareMode === null ? workspace?.recapShareMode : recapShareMode;

    return setting
      ? recapShareSelectorOptions.find(option => option.value === setting)
      : recapShareSelectorOptions[0];
  }, [recapShareMode, workspace?.recapShareMode]);

  const handleRecapShare = React.useCallback(
    (recapShareOption: RecapShareType) => {
      changeSettings({
        recapShareMode: recapShareOption
      });
    },
    [changeSettings]
  );

  const handleShareState = React.useCallback(
    (shareState: RecordingShareState | CollectionShareState) => {
      changeSettings({
        defaultRecordingsShareState: shareState as RecordingShareState
      });
    },
    [changeSettings]
  );

  return (
    <div>
      <StyledCalendarIntegrationContainer>
        <div>
          <StyledSectionLabel css={[spacing.mt0]}>
            Auto record your meetings
          </StyledSectionLabel>
          <StyledSubSectionLabel>
            Choose which meeting types your notetaker will join and record
            automatically.
          </StyledSubSectionLabel>
          {!calendarConnectedAccount ? (
            <div>
              <CalendarStatus myself={myself} showTitle={false} />
            </div>
          ) : (
            <>
              <StyledContainer>
                <div css={['width: 420px']}>
                  <Select
                    fullWidth
                    matchWidth
                    placeholder='Choose one'
                    value={recordSetting?.value}
                    disabled={!workspace?.defaultAutoRecordOverridable}
                    onChange={(value: number) => {
                      changeRecordSettings(value);
                    }}
                  >
                    {recorderSelectorOptions.map(option => (
                      <OptionV4 key={option.value} value={option.value}>
                        {option.title}
                      </OptionV4>
                    ))}
                  </Select>
                </div>
              </StyledContainer>
              <Tooltip
                content='Upgrade your plan to change this'
                disabled={isPaidSettingsEnabled}
              >
                <StyledOption css={[spacing.mb0, spacing.mt4]}>
                  <Checkbox
                    checked={
                      recordSetting?.value !== recordingShareOptions.NONE &&
                      (autoRecordOrganiserOnly ??
                        workspace?.defaultAutoRecordOrganiserOnly)
                    }
                    disabled={
                      recordSetting?.value === recordingShareOptions.NONE ||
                      !workspace?.defaultAutoRecordOverridable ||
                      !isPaidSettingsEnabled
                    }
                    onChange={ev => {
                      handleCheckedOrganiserOnly(ev.target.checked);
                    }}
                  />
                  <StyledOptionTitle
                    css={[
                      recordSetting?.value === recordingShareOptions.NONE ||
                      !workspace?.defaultAutoRecordOverridable
                        ? color.fg.pigeon
                        : null
                    ]}
                  >
                    Only auto record if I am the event organizer
                  </StyledOptionTitle>
                  {!isPaidSettingsEnabled && (
                    <Button
                      as={Link}
                      to='/app/settings/workspace?tab=plans'
                      variant='ghost'
                      size='sm'
                      css={css`
                        color: ${theme.tokens.color.buttonPlanDefault};
                        margin-left: 4px;
                      `}
                    >
                      Upgrade
                    </Button>
                  )}
                </StyledOption>
              </Tooltip>
              {!workspace?.defaultAutoRecordOverridable && (
                <StyledDisabledInfo css={[spacing.my2]}>
                  <Icon.Info />
                  <span>
                    Your Grain admin has locked this setting so it cannot be
                    changed.
                  </span>
                </StyledDisabledInfo>
              )}

              {(autoRecordExternal !== null ||
                autoRecordInternal !== null ||
                autoRecordOrganiserOnly !== null) &&
                workspace?.defaultAutoRecordOverridable &&
                isPaidSettingsEnabled && (
                  <StyledSubSectionLabelInfo>
                    You’ve overridden your workspace’s default setting.{' '}
                    <span
                      css={['pointer-events: auto;']}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        changeSettings({
                          autoRecordInternal: null,
                          autoRecordExternal: null,
                          autoRecordOrganiserOnly: null
                        });
                      }}
                      className='option-reset'
                    >
                      Reset
                    </span>
                  </StyledSubSectionLabelInfo>
                )}
            </>
          )}
        </div>
      </StyledCalendarIntegrationContainer>

      <StyledSeparator />

      <StyledSectionLabel>Workspace access</StyledSectionLabel>
      <StyledSubSectionLabel>
        Select the types of meetings you own that you want default accessible to
        all members of your workspace and automations.
      </StyledSubSectionLabel>
      <StyledContainer>
        <StyledSelectWrapper>
          <div css={['width: 420px']}>
            <Select
              fullWidth
              matchWidth
              placeholder='Choose one'
              value={shareSetting?.value}
              disabled={!workspace?.autoShareRecordingsOverridable}
              onChange={value => {
                changeShareSettings(value);
              }}
            >
              {workspaceShareSelectorOptions.map(option => (
                <OptionV4 key={option.value} value={option.value}>
                  {option.title}
                </OptionV4>
              ))}
            </Select>
          </div>
          {!workspace?.autoShareRecordingsOverridable && (
            <StyledDisabledInfo>
              <Icon.Info />
              <span>
                Your Grain admin has locked this setting so it cannot be
                changed.
              </span>
            </StyledDisabledInfo>
          )}
        </StyledSelectWrapper>
      </StyledContainer>

      {autoShareExternalRecordings !== null &&
        autoShareInternalRecordings !== null &&
        workspace?.autoShareRecordingsOverridable && (
          <StyledSubSectionLabelInfo>
            You’ve overridden your workspace’s default setting.{' '}
            <span
              css={['pointer-events: auto;']}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                changeSettings({
                  autoShareExternalRecordings: null,
                  autoShareInternalRecordings: null
                });
              }}
              className='option-reset'
            >
              Reset
            </span>
          </StyledSubSectionLabelInfo>
        )}
      <StyledOption css={[spacing.mb0, spacing.mt4]}>
        <>
          <Checkbox
            checked={myself?.settings?.shareNewStoriesWithWorkspace}
            onChange={ev =>
              changeSettings({
                shareNewStoriesWithWorkspace: ev.target.checked
              })
            }
          />
        </>
        <StyledOptionTitle>
          Always share new stories with my workspace
        </StyledOptionTitle>
      </StyledOption>

      <StyledSeparator />

      <StyledSectionLabel>Link access default</StyledSectionLabel>
      <StyledSubSectionLabel>
        Choose who can access your meeting recordings with a shared link.
      </StyledSubSectionLabel>
      <StyledContainer>
        <StyledSelectWrapper>
          <GatedRecordingAccessSelect
            workspaceName={workspace?.name ?? ''}
            selectedRecordingAccess={
              defaultRecordingsShareState ??
              workspace?.defaultRecordingsShareState
            }
            onChangeAccess={handleShareState}
            disabled={!workspace?.defaultRecordingsShareStateOverridable}
            gate='paid_settings'
          />
          {!workspace?.defaultRecordingsShareStateOverridable && (
            <StyledDisabledInfo>
              <Icon.Info />
              <span>
                Your Grain admin has locked this setting so it cannot be
                changed.
              </span>
            </StyledDisabledInfo>
          )}
        </StyledSelectWrapper>
      </StyledContainer>
      {defaultRecordingsShareState !== null &&
        workspace?.defaultRecordingsShareStateOverridable && (
          <StyledSubSectionLabelInfo>
            You’ve overridden your workspace’s default setting.{' '}
            <span
              css={['pointer-events: auto;']}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                changeSettings({
                  defaultRecordingsShareState: null
                });
              }}
              className='option-reset'
            >
              Reset
            </span>
          </StyledSubSectionLabelInfo>
        )}

      <StyledSeparator />
      <StyledSectionLabel>Meeting recap emails</StyledSectionLabel>
      <StyledSubSectionLabel>
        Select which meetings generate automatic meeting recap emails and who
        receives them.
      </StyledSubSectionLabel>
      <StyledContainer>
        <div css={['width: 420px']}>
          <GatedSelect
            fullWidth
            matchWidth
            placeholder='Choose one'
            value={recapShare?.value}
            onChange={value => {
              handleRecapShare(value);
            }}
            gate='paid_settings'
          >
            {recapShareSelectorOptions.map(option => (
              <GatedOption
                key={option.value}
                value={option.value}
                gated={[RecapShareType.Workspace, RecapShareType.None].includes(
                  option.value
                )}
              >
                {option.title}
              </GatedOption>
            ))}
          </GatedSelect>
        </div>
      </StyledContainer>
      {recapShareMode !== null && isPaidSettingsEnabled && (
        <StyledSubSectionLabelInfo>
          You’ve overridden your workspace’s default setting.{' '}
          <span
            css={['pointer-events: auto;']}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              changeSettings({
                recapShareMode: null
              });
            }}
            className='option-reset'
          >
            Reset
          </span>
        </StyledSubSectionLabelInfo>
      )}
      <StyledSeparator />
      {isFreemiumEnabled &&
        workspace?.workspaceSubscription?.planDetails?.isFree && (
          <>
            <MeetingLanguageSelector />
            <StyledSeparator />
          </>
        )}
      <StyledSectionMainDescription>
        <span>Templates</span>
        <Description>
          Select the default templates to automatically use for your meetings.
        </Description>
      </StyledSectionMainDescription>
      {templateSettingsOptions.map(option => {
        const showReset =
          myself?.settings?.[`${option.value}TemplateWorkspaceOverridden`] ??
          false;
        return (
          <>
            <StyledSectionLabel>{option.title}</StyledSectionLabel>
            <StyledSubSectionLabel />
            <StyledContainer>
              <div css={['width: 420px']}>
                <Select
                  fullWidth
                  matchWidth
                  placeholder='Choose one'
                  value={getSelectedTemplate(option.value)[0]?.id}
                  onChange={value => {
                    const selectedOption = templateOptions.find(
                      opt => opt.value === value
                    );
                    handleChangeTemplate(option.value, selectedOption);
                  }}
                >
                  {templateOptions.map(option => (
                    <OptionV4 key={option.value} value={option.value}>
                      {option.title}
                    </OptionV4>
                  ))}
                </Select>
              </div>
            </StyledContainer>
            {showReset && (
              <StyledSubSectionLabelInfo>
                You’ve overridden your workspace’s defaults for this setting.{' '}
                <span
                  css={['pointer-events: auto;']}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    changeSettings({
                      [`${option.value}RecordingIntelligenceTemplateId`]: null,
                      [`${option.value}TemplateWorkspaceOverridden`]: false
                    });
                  }}
                  className='option-reset'
                >
                  Reset
                </span>
              </StyledSubSectionLabelInfo>
            )}
          </>
        );
      })}
    </div>
  );
}
