import { useQuery } from './adhoc';
import type { useQueryArgs } from './adhoc';

import { useRestoreFilter, useStoreFilter } from './storage';
import {
  useFiltersManager,
  type UseFiltersManagerProps
} from '../filtersV2/useFiltersManager';

export * from './types';

export type useFilterArgs = UseFiltersManagerProps &
  Omit<useQueryArgs, 'filter'>;
export const useFilteredViewAdHoc = (args: useFilterArgs) => {
  const {
    // -- useFilterManager --
    filterEntityType,
    availableFilters,
    initialValues = {},
    hiddenFilters = [],
    filterOptions,
    // -- useQuery --
    query,
    fetchPolicy,
    skip,
    variables,
    onCompleted
  } = args;

  const storageFilters = useRestoreFilter();

  const {
    filters,
    activeFilters,
    resetFilters,
    viewAdHocFiltersJSON,
    viewAdHocFilterString
  } = useFiltersManager({
    filterEntityType,
    availableFilters,
    initialValues: { ...initialValues, ...storageFilters },
    hiddenFilters,
    filterOptions
  });

  useStoreFilter({ viewAdHocFiltersJSON, skip });

  const queryResponse = useQuery({
    filter: viewAdHocFilterString,
    query,
    fetchPolicy,
    skip,
    variables,
    onCompleted
  });

  return {
    activeFilters,
    resetFilters,
    filters,
    viewAdHocFilterString,
    queryResponse
  };
};
