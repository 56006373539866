import { useMemo } from 'react';
import styled from '@emotion/styled';
import { theme } from '@grain/grain-ui/v4';

import {
  type DealContactFragment,
  type DealDetailsFragment
} from '../../deal.generated';

import { ContactListItem, EmptyListItem } from './ContactListItem';

const StyledCompanyTitle = styled.div`
  padding: ${theme.tokens.spacing.sm} ${theme.tokens.spacing['3xl']};
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textSecondary};
  background-color: ${theme.tokens.color.surfaceSecondary};
`;

type ContactsProps = {
  deal: DealDetailsFragment;
  hubspotIntegration?: {
    hubId: string;
  };
};

export function Contacts({ deal, hubspotIntegration }: ContactsProps) {
  const [contactsMap, companyMap] = useMemo(() => {
    const employeeMap = {} as Record<string, DealContactFragment[]>;
    const companyNameMap = {} as Record<string, string>;

    const dealCompany = deal?.group;

    if (dealCompany) {
      // Initialize employee and company name map with deal info so they appear at top of list.
      employeeMap[dealCompany.id] = [];
      companyNameMap[dealCompany.id] = dealCompany.name;
    }

    for (const contact of deal?.contacts || []) {
      const group = contact.person.groups[0];
      if (!group) continue;
      const companyId = group.id;
      if (!(companyId in employeeMap)) {
        companyNameMap[companyId] = group.name;
        employeeMap[companyId] = [];
      }

      employeeMap[companyId] = [...employeeMap[companyId], contact];
    }

    return [employeeMap, companyNameMap] as const;
  }, [deal]);

  const contactsMapEntries = Object.entries(contactsMap).filter(
    ([_, contacts]) => {
      // Don't show a company if we don't have any contacts to display for it.
      return contacts.length > 0;
    }
  );

  if (!contactsMapEntries.length) {
    return <EmptyListItem>No Contacts</EmptyListItem>;
  }

  return (
    <>
      {contactsMapEntries.map(([companyId, contacts]) => (
        <section key={companyId}>
          <StyledCompanyTitle>{companyMap[companyId]}</StyledCompanyTitle>
          {contacts.map(contact => (
            <ContactListItem
              key={contact.person.id}
              contact={contact}
              hubspotIntegration={hubspotIntegration}
            />
          ))}
        </section>
      ))}
    </>
  );
}
