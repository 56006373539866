import { useExtraPath } from '@grain/components/support/extra';
import { useState, useEffect } from 'react';
import { MetadataItemId, MetadataMap } from './MetadataItems';
import { useAnalytics } from '@grain/grain-ui';

const DEFAULT_METADATA_ITEMS: MetadataItemId[] = [
  'owner',
  'company',
  'participants'
];

type UseMeetingMetadataItemsReturn = {
  selectedMetadataItems: MetadataItemId[];
  onMetadataItemToggle: (itemId: MetadataItemId) => void;
  defaultMetadataItems: MetadataItemId[];
};

export const useMeetingMetadataItems = (): UseMeetingMetadataItemsReturn => {
  const { trackEvent } = useAnalytics();

  const [
    { value: serverMetadataItems = DEFAULT_METADATA_ITEMS },
    saveMetadataItems
  ] = useExtraPath<MetadataItemId[]>('meetingsListMetadataItems');

  // Local state for optimistic updates
  const [selectedMetadataItems, setSelectedMetadataItems] =
    useState<MetadataItemId[]>(serverMetadataItems);

  // Sync local state with server state
  useEffect(() => {
    setSelectedMetadataItems(serverMetadataItems);
  }, [serverMetadataItems]);

  const sortMetadataItems = (items: MetadataItemId[]) => {
    return [...items].sort((a, b) => {
      const aOrder = MetadataMap.get(a)?.order || 0;
      const bOrder = MetadataMap.get(b)?.order || 0;
      return aOrder - bOrder;
    });
  };

  const onMetadataItemToggle = (itemId: MetadataItemId) => {
    const newSelectedItems = selectedMetadataItems.includes(itemId)
      ? selectedMetadataItems.filter(id => id !== itemId)
      : [...selectedMetadataItems, itemId];

    const sortedItems = sortMetadataItems(newSelectedItems);

    // Update local state immediately
    setSelectedMetadataItems(sortedItems);

    // Update server state in the background
    saveMetadataItems(sortedItems);

    trackEvent('Meetings List Metadata Items Changed', {
      metadata_items: sortedItems
    });
  };

  return {
    selectedMetadataItems,
    onMetadataItemToggle,
    defaultMetadataItems: DEFAULT_METADATA_ITEMS
  };
};
