import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { RecordingShareState } from '@grain/api/schema.generated';
import { theme } from '@grain/grain-ui/v4';
import { GatedFeatureName } from '@grain/api/auth';
import { GatedSelect, GatedOption } from './GatedSelect';

const DescriptionWrapper = styled.div`
  ${theme.tokens.typography.b4[400]}
  color: ${theme.tokens.color.textInactive};
  margin-top: ${theme.tokens.spacing.md};
`;

type RecordingAccessSelectorProps = {
  workspaceName: string;
  onChangeAccess?: (recordingAccess: RecordingShareState) => void;
  disabled?: boolean;
  selectedRecordingAccess?: RecordingShareState;
  gate: GatedFeatureName;
};

const NOOP = () => {};

export const GatedRecordingAccessSelect = ({
  workspaceName,
  onChangeAccess = NOOP,
  selectedRecordingAccess = RecordingShareState.Restricted,
  disabled = false,
  gate
}: RecordingAccessSelectorProps) => {
  const accessMap: { [key in RecordingShareState]: ReactNode } = {
    [RecordingShareState.Public]: 'Anyone with link',
    [RecordingShareState.Workspace]: `Members of ${workspaceName}`,
    [RecordingShareState.Restricted]: 'Only members with access'
  };
  const accessDescription: { [key in RecordingShareState]: ReactNode } = {
    [RecordingShareState.Public]: (
      <>
        Members of your workspace can view and edit meetings and clips.
        <br />
        Non-members can view the meetings.
      </>
    ),
    [RecordingShareState.Workspace]: 'Members can view and edit.',
    [RecordingShareState.Restricted]:
      'Only members with access can view and edit.'
  };

  return (
    <>
      <div css={['width: 410px;']}>
        <GatedSelect
          fullWidth
          matchWidth
          placeholder='Choose one'
          disabled={disabled}
          value={selectedRecordingAccess}
          onChange={onChangeAccess}
          gate={gate}
        >
          {Object.keys(accessMap).map(access => (
            <GatedOption
              key={access}
              value={access}
              gated={access !== RecordingShareState.Public}
            >
              {accessMap[access as RecordingShareState]}
            </GatedOption>
          ))}
        </GatedSelect>
      </div>
      <DescriptionWrapper>
        {accessDescription[selectedRecordingAccess]}
      </DescriptionWrapper>
    </>
  );
};
