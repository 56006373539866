import React from 'react';
import format from 'date-fns/format';
import { getBasePublicPath, StepsIndicator } from '@grain/grain-ui';
import {
  LeftSide,
  RightSide,
  Title,
  Subtitle,
  Description,
  Buttons,
  SlideButton,
  StepComponentProps
} from '../../../modals/LegacyOnboarding';
import { useWorkspace } from '@grain/api/auth';
import { useCalendarQuery } from '@grain/api/graphql/modules/calendar.generated';

const AutoRecording = ({
  totalSteps,
  step,
  showSetupComplete,
  handleBack,
  handleNext,
  handleDone,
  showStepsIndicator
}: StepComponentProps) => {
  const { workspace, loading } = useWorkspace();
  const { data: calendarData } = useCalendarQuery();

  const calendarSyncing = React.useMemo(() => {
    if (!calendarData) return true;
    return calendarData?.calendar?.accounts?.some(
      (account: { status: string }) =>
        ['NOT_SYNCED', 'SYNCING'].includes(account.status)
    );
  }, [calendarData]);

  const autoRecord = React.useMemo(() => {
    if (!workspace) return null;
    const {
      defaultAutoRecordExternal: autoRecordExternal,
      defaultAutoRecordInternal: autoRecordInternal
    } = workspace;

    if (autoRecordExternal && !autoRecordInternal) {
      return {
        title: 'customer',
        value: 'EXTERNAL'
      };
    }
    if (!autoRecordExternal && autoRecordInternal) {
      return {
        title: 'team',
        value: 'INTERNAL'
      };
    }
    if (autoRecordExternal && autoRecordInternal) {
      return {
        title: '',
        value: 'ALL'
      };
    }
    if (!autoRecordExternal && !autoRecordInternal) {
      return {
        title: 'none',
        value: 'NONE'
      };
    }
  }, [workspace]);

  const nextMeeting = React.useMemo(() => {
    if (!calendarData || calendarSyncing || !workspace) return null;
    const { events } = calendarData.calendar;
    if (autoRecord?.value === 'ALL') {
      return events[0];
    }
    return events.find(event => {
      if (autoRecord?.value === 'INTERNAL') {
        return event.internal;
      }
      if (autoRecord?.value === 'EXTERNAL') {
        return !event.internal;
      }
    });
  }, [autoRecord, calendarData, calendarSyncing, workspace]);

  if (loading || !workspace) return null;

  const autoRecordTitle =
    autoRecord?.value === 'NONE'
      ? 'Manually add Grain to your next meeting'
      : `Grain will auto-record all your ${autoRecord?.title} meetings`;

  const autoRecordDescription = (
    <>
      {autoRecord?.value !== 'NONE' && (
        <>
          {nextMeeting ? (
            <>
              Grain will join and record your next {autoRecord?.title} meeting,{' '}
              <span css={['font-weight: bold;']}>{nextMeeting?.title}</span> on{' '}
              {nextMeeting
                ? format(new Date(nextMeeting?.start), 'MMMM d')
                : ''}{' '}
              at{' '}
              {nextMeeting ? format(new Date(nextMeeting?.start), 'h a') : ''}.
            </>
          ) : (
            `Grain will join and record your next ${autoRecord?.title} meeting.`
          )}
          <br />
          <br />
          For unscheduled meetings,{' '}
        </>
      )}
      {`${
        autoRecord?.value !== 'NONE' ? 'm' : 'M'
      }anually add Grain by pasting the meeting link
      on the Calendar page.`}
      &nbsp;
      <a
        href='https://support.grain.com/en/articles/5395670-record-your-first-call-with-grain'
        target='_blank'
        rel='noreferrer'
      >
        <span css={['text-decoration: underline;']}>Learn more.</span>
      </a>
    </>
  );

  return (
    <>
      <LeftSide width={378}>
        <div>
          {showSetupComplete && <Subtitle>Setup complete</Subtitle>}
          <Title>{autoRecordTitle}</Title>
          <Description>{autoRecordDescription}</Description>
        </div>
        <Buttons>
          {step !== 0 && totalSteps > 1 && (
            <SlideButton variant='secondary' size='large' onClick={handleBack}>
              Back
            </SlideButton>
          )}
          <SlideButton
            variant='primary'
            size='large'
            onClick={step === totalSteps - 1 ? handleDone : handleNext}
          >
            {step === totalSteps - 1 ? 'Done' : 'Continue'}
          </SlideButton>
        </Buttons>
      </LeftSide>
      <RightSide
        background={`${getBasePublicPath()}/images/noise-and-texture.png`}
        width={542}
      >
        <img
          src={
            autoRecord?.value !== 'NONE'
              ? `${getBasePublicPath()}/images/onboarding/meeting-demo.png`
              : `${getBasePublicPath()}/images/onboarding/today-page.png`
          }
          alt={
            autoRecord?.value !== 'NONE'
              ? 'Grain in a meeting'
              : "Grain's calendar page"
          }
        />
        {showStepsIndicator && (
          <div className='steps-indicator'>
            <StepsIndicator steps={totalSteps} step={step} />
          </div>
        )}
      </RightSide>
    </>
  );
};

export default AutoRecording;
