import styled from '@emotion/styled';
import { format } from 'date-fns';
import type { Deal, Group, Person } from '@grain/api/schema.generated';
import { Icon, Skeleton } from '@grain/grain-ui';
import { theme, Divider, TextLabel, Icon16 } from '@grain/grain-ui/v4';

const USD_FORMATTER = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

function formatAmount(amount: number) {
  return USD_FORMATTER.format(amount);
}

function formatDate(dateString: string) {
  return format(new Date(dateString), 'MMM d, y');
}

const StyledTitle = styled.div`
  ${theme.tokens.typography.b4[500]};
  display: flex;
  align-items: center;
  ${theme.utils.px('3xl')};
  height: 56px;
`;

const StyledTable = styled.table`
  border-spacing: 0;
  width: 100%;
  ${theme.utils.px('lg')};
  ${theme.utils.py('sm')};
`;

const StyledTableLabelColumn = styled.th`
  ${theme.tokens.typography.b4[500]};
  width: 1px;
  padding: ${theme.tokens.spacing.lg};
  white-space: nowrap;
  color: ${theme.tokens.color.textSecondary};
  vertical-align: middle;
`;

const StyledTableDataColumn = styled.td`
  ${theme.tokens.typography.b4[500]};
  padding: ${theme.tokens.spacing.lg};
  vertical-align: middle;
`;

type DealProperties = Pick<
  Deal,
  'stage' | 'amount' | 'closedAt' | 'openedAt'
> & {
  group: Pick<Group, 'name'>;
  owner: Pick<Person, 'name'> | null;
};

type DealPropertiesProps = {
  deal: DealProperties;
};

export function DealProperties({ deal }: DealPropertiesProps) {
  const companyName = deal.group?.name;
  const ownerName = deal.owner?.name;
  const { stage, amount, closedAt, openedAt } = deal;
  return (
    <>
      <StyledTitle>Properties</StyledTitle>
      <Divider />
      <StyledTable>
        <tbody>
          {companyName && (
            <tr>
              <StyledTableLabelColumn>
                <TextLabel size='sm' startIcon={Icon16.Building}>
                  Company
                </TextLabel>
              </StyledTableLabelColumn>
              <StyledTableDataColumn>{companyName}</StyledTableDataColumn>
            </tr>
          )}
          {stage && (
            <tr>
              <StyledTableLabelColumn>
                <TextLabel size='sm' startIcon={Icon.MarkerPin}>
                  Stage
                </TextLabel>
              </StyledTableLabelColumn>
              <StyledTableDataColumn>{stage}</StyledTableDataColumn>
            </tr>
          )}
          {typeof amount === 'number' && (
            <tr>
              <StyledTableLabelColumn>
                <TextLabel size='sm' startIcon={Icon.CurrencyDollarCircle}>
                  Amount
                </TextLabel>
              </StyledTableLabelColumn>
              <StyledTableDataColumn>
                {formatAmount(amount)}
              </StyledTableDataColumn>
            </tr>
          )}
          {ownerName && (
            <tr>
              <StyledTableLabelColumn>
                <TextLabel size='sm' startIcon={Icon.UserCircle}>
                  Owner
                </TextLabel>
              </StyledTableLabelColumn>
              <StyledTableDataColumn>{ownerName}</StyledTableDataColumn>
            </tr>
          )}
          {closedAt && (
            <tr>
              <StyledTableLabelColumn>
                <TextLabel size='sm' startIcon={Icon.CalendarCheck}>
                  Close Date
                </TextLabel>
              </StyledTableLabelColumn>
              <StyledTableDataColumn>
                {formatDate(closedAt)}
              </StyledTableDataColumn>
            </tr>
          )}
          {openedAt && (
            <tr>
              <StyledTableLabelColumn>
                <TextLabel size='sm' startIcon={Icon.CalendarPlus}>
                  Open Date
                </TextLabel>
              </StyledTableLabelColumn>
              <StyledTableDataColumn>
                {formatDate(openedAt)}
              </StyledTableDataColumn>
            </tr>
          )}
        </tbody>
      </StyledTable>
    </>
  );
}

export function SkeletonProperties() {
  return (
    <>
      <StyledTitle>Properties</StyledTitle>
      <Divider />
      <StyledTable>
        <tbody>
          {Array(6)
            .fill(0)
            .map((_, i) => (
              <tr key={i}>
                <StyledTableLabelColumn>
                  <Skeleton
                    width={70}
                    height={16}
                    css={[`border-radius: ${theme.tokens.radii.md};`]}
                  />
                </StyledTableLabelColumn>
                <StyledTableDataColumn>
                  <Skeleton
                    width={90}
                    height={16}
                    css={[`border-radius: ${theme.tokens.radii.md};`]}
                  />
                </StyledTableDataColumn>
              </tr>
            ))}
        </tbody>
      </StyledTable>
    </>
  );
}
