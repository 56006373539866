import PlanCard from '~/modules/subscriptions/PlanCard';
import PropTypes from 'prop-types';
import { billingPreviewIsYearlyState } from '~/modules/subscriptions/state';
import { spacing, media } from '@grain/styles/constants';
import { useMediaQuery } from '@grain/components/support/browser';
import { usePlans } from '@grain/components/Subscriptions/hooks';
import { useRecoilValue } from 'recoil';
import { useSubscribeInfo } from '../context';
import { useWorkspaceWithMembers } from '@grain/components/Workspace/hooks';

ChoosePlan.propTypes = {
  onSuccess: PropTypes.func.isRequired
};

export default function ChoosePlan({ onSuccess }) {
  const isMobile = useMediaQuery(media.small);
  const { workspace } = useWorkspaceWithMembers();
  const { workspaceSubscription } = workspace || {};
  const { subscriptionPlans, loading: plansLoading } = usePlans();
  const { planSku, setPlanSku } = useSubscribeInfo();

  const yearlyPricing = useRecoilValue(billingPreviewIsYearlyState);

  return (
    <div css={[isMobile ? 'display: block;' : 'display: flex;', spacing.pt7]}>
      {subscriptionPlans.map((plan, index) =>
        plan.isFree ? null : (
          <PlanCard
            size='large'
            plan={plan}
            planIndex={index}
            previousPlan={index > 0 ? subscriptionPlans[index - 1] : null}
            loading={plansLoading}
            currentPlanSku={workspaceSubscription?.planSku}
            key={plan.sku}
            css={[isMobile && index === 0 && spacing.mb3, spacing.mr7]}
            actionType={plan.sku === planSku ? 'current' : 'upgrade'}
            overrideActionText={plan.sku === planSku ? 'Selected Plan' : null}
            forcePrice={yearlyPricing ? 'YEARLY' : 'MONTHLY'}
            onAction={() => {
              setPlanSku(plan.sku);
              onSuccess();
            }}
          />
        )
      )}
    </div>
  );
}
