import styled from '@emotion/styled';
import { color, colors, spacing } from '@grain/styles/constants';
import { overflowEllipsis } from '@grain/grain-ui';

export const StyledContent = styled.div`
  overflow-y: auto;
  padding: 16px 24px 32px 24px;
`;

export const StyledThumbnail = styled.img`
  background-color: black;
  border-radius: 4px;
  cursor: pointer;
  height: 38px;
  width: 74px;
`;

export const StyledHighlightRow = styled.div`
  ${spacing.p2};
  align-items: center;
  border: 1px solid transparent;
  display: flex;
  margin: -${spacing[2]};
  :not(:last-of-type) {
    ${spacing.mb5};
  }
  :hover {
    border: 1px solid ${colors.gull};
    background-color: ${colors.goose};
    border-radius: 8px;
  }

  .text-container {
    ${overflowEllipsis};
    ${spacing.mx4};
  }

  .title {
    ${overflowEllipsis};
    font-size: calc(14rem / 16);
    font-weight: 400;
  }
  .title:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .recording-title {
    ${color.crow};
    ${overflowEllipsis};
    font-size: calc(12rem / 16);
  }

  svg.play-icon {
    color: white;
    cursor: pointer;
    position: absolute;

    // center the icon
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);

    // scale the icon on hover
    transform-origin: 50% 50%;
    transition: transform 0.2s;
    will-change: transform;
    :hover {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
`;

export const StyledVideoPlaceholder = styled.div`
  align-items: center;
  background: ${colors.blackbird};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 290px;
  justify-content: center;
  width: 516px;

  .title {
    ${color.swan};
    font-weight: 700;
  }
  .subtitle {
    ${spacing.mt2};
    color: #a8a8a8;
    font-weight: 400;
  }
`;

export const StyledEmptyMessage = styled.div`
  ${color.crow};
  font-size: calc(14rem / 16);
  text-align: center;
`;
