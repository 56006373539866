import * as Types from '@grain/api/schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@grain/api/graphql';
const defaultOptions = {} as const;
export type IntelligenceQueryQueryVariables = Types.Exact<{
  query: Types.Scalars['NonEmptyString']['input'];
}>;


export type IntelligenceQueryQuery = { __typename?: 'RootQueryType', intelligenceQuery: { __typename?: 'IntelligenceQueryResponse', responses: Array<{ __typename?: 'IntelligenceQueryTypedResponse', result: string, type: string }> } };


export const IntelligenceQueryDocument = gql`
    query intelligenceQuery($query: NonEmptyString!) {
  intelligenceQuery(query: $query) {
    responses {
      result
      type
    }
  }
}
    `;

/**
 * __useIntelligenceQueryQuery__
 *
 * To run a query within a React component, call `useIntelligenceQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntelligenceQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntelligenceQueryQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useIntelligenceQueryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<IntelligenceQueryQuery, IntelligenceQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<IntelligenceQueryQuery, IntelligenceQueryQueryVariables>(IntelligenceQueryDocument, options);
      }
export function useIntelligenceQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IntelligenceQueryQuery, IntelligenceQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<IntelligenceQueryQuery, IntelligenceQueryQueryVariables>(IntelligenceQueryDocument, options);
        }
export function useIntelligenceQuerySuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<IntelligenceQueryQuery, IntelligenceQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<IntelligenceQueryQuery, IntelligenceQueryQueryVariables>(IntelligenceQueryDocument, options);
        }
export type IntelligenceQueryQueryHookResult = ReturnType<typeof useIntelligenceQueryQuery>;
export type IntelligenceQueryLazyQueryHookResult = ReturnType<typeof useIntelligenceQueryLazyQuery>;
export type IntelligenceQuerySuspenseQueryHookResult = ReturnType<typeof useIntelligenceQuerySuspenseQuery>;
export type IntelligenceQueryQueryResult = Apollo.QueryResult<IntelligenceQueryQuery, IntelligenceQueryQueryVariables>;