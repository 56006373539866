import { useState, useMemo, ComponentProps } from 'react';
import styled from '@emotion/styled';
import { color, pxToRem, spacing } from '@grain/grain-ui';
import { getGraphemeLength } from '@grain/components/support/utils';
import TagEditor from '@grain/components/TagEditor';
import { useMyselfQuery } from '@grain/api/graphql/queries/user.generated';
import { useSetReactionPaletteV2Mutation } from '@grain/api/graphql/mutations/user.generated';

const emojiRegex = /(\p{Emoji_Presentation}|\p{Extended_Pictographic})/u;
const StyledContainer = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(20)};
  ${color.fg.blackbird};

  .heading {
    font-weight: 400;
    ${spacing.mb6};
  }
`;

type QuickTagsProps = ComponentProps<typeof StyledContainer>;
export default function QuickTags(rest: QuickTagsProps) {
  const { data } = useMyselfQuery();
  const tags = useMemo(
    () => data?.myself?.reactionPalette || [],
    [data?.myself?.reactionPalette]
  );
  const [setReactionPalette] = useSetReactionPaletteV2Mutation();
  const [quickTags, setQuickTags] = useState(tags);
  function optimisticAddTag(text: string, _: unknown) {
    const textLength = getGraphemeLength(text.trim());
    if (emojiRegex.test(text.trim()) && textLength === 1) {
      setQuickTags([...tags, `${text}`]);
      setReactionPalette({
        variables: { reactions: [...tags, `${text}`] }
      });
    } else if (text.trim() !== '') {
      setQuickTags([...tags, `#${text}`]);
      setReactionPalette({
        variables: { reactions: [...tags, `#${text}`] }
      });
    }
  }

  function optimisticRemoveTag(text: string, _: unknown) {
    const updatedTags = tags.filter((tags, _) => tags !== text);
    setQuickTags(updatedTags);

    setReactionPalette({
      variables: { reactions: updatedTags }
    });
  }

  return (
    <StyledContainer {...rest}>
      <div className='heading'>
        Create a group of your most frequently used tags that are easy to access
        during and after your calls.{' '}
      </div>
      <TagEditor
        tags={quickTags.map(tag => ({ text: tag }))}
        isQuickTags={true}
        accessibleTags={[]}
        onAddTag={() => Promise.resolve()}
        onRemoveTag={() => Promise.resolve()}
        onOptimisticAddTag={optimisticAddTag}
        onOptimisticRemoveTag={optimisticRemoveTag}
        onOpenTag={() => {}}
      />
    </StyledContainer>
  );
}
