import styled from '@emotion/styled';
import { color, font, spacing } from '@grain/styles/constants';
import { flex } from '@grain/grain-ui';
import { useSearchParams } from 'react-router-dom';

const StyledContainer = styled.div`
  ${flex.center};
  ${spacing.mx3};
  flex-direction: column;
  height: 100%;
  margin: auto;
  max-width: 41rem;
  width: 100%;
`;

const StyledTitle = styled.div`
  ${color.blackbird};
  ${font.titleL};
  text-align: center;
`;

const StyledSubtitle = styled.div`
  ${color.pigeon};
  ${spacing.mt4};
  text-align: center;
`;

export default function DisabledUser() {
  const [params] = useSearchParams();
  const disabledReason = params.get('reason');
  const isMarkedForDeletion = disabledReason === 'marked_for_deletion';

  return (
    <StyledContainer>
      <StyledTitle>
        {isMarkedForDeletion
          ? 'This workspace has been marked for deletion by your workspace admin.'
          : 'This account has been suspended from this workspace.'}
      </StyledTitle>
      <StyledSubtitle>
        {isMarkedForDeletion ? (
          <>
            If you believe this is an error, please{' '}
            <a href='mailto:help@grain.com'>contact Grain support</a>.
          </>
        ) : (
          'If you believe this is an error, please contact your workspace admin.'
        )}
      </StyledSubtitle>
    </StyledContainer>
  );
}
