import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { flex, Icon, pxToRem } from '@grain/grain-ui';
import { spacing, colors } from '@grain/styles/constants';

export const StyledTitle = styled.div`
  ${spacing.mt7};
  ${spacing.mb6};
  font-size: ${pxToRem(38)};
  font-weight: 600;
  text-align: center;
`;

export const StyledButtonContainer = styled.div`
  ${spacing.mt8};
  ${flex.direction.row};
  ${flex.justifyContent.center};
  padding: 8px ${spacing[3]};
`;

export const loginConfirmButtonStyles = css`
  ${spacing.mr4};
  ${spacing.px8}
  ${spacing.py4};
  width: 48px;
`;

export const StyledConnectionSection = styled.div`
  ${flex.direction.row};
  ${flex.center};
`;

export const StyledSection = styled.div`
  ${flex.alignItems.flexStart};
  ${flex.direction.column};
  ${spacing.g8};
  ${spacing.px6};
  ${spacing.py4}
  background-color: ${colors.goose};
  border-radius: 8px;
  border: 1px solid ${colors.gull};
  width: ${pxToRem(600)};
`;

export const StyledSectionLabel = styled.div`
  ${spacing.mb4};
  font-size: ${pxToRem(14)};
  font-weight: 600;
`;

export const StyledRow = styled.div`
  font-size: ${pxToRem(14)};
  display: flex;
  ${spacing.py1};
  justify-content: space-between;
`;

export const StyledRowLabel = styled.div`
  ${spacing.mr2};
  font-weight: 500;
  width: 50px;
`;

export const StyledRowText = styled.div`
  font-weight: normal;
`;

export const StyledConnectionVertical = styled(Icon.ConnectionVertical)`
  color: ${colors.pigeon};
  ${spacing.m6}
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
`;

export const StyledConnectionHorizontal = styled(Icon.ConnectionHorizontal)`
  color: ${colors.pigeon};
  ${spacing.mx6}
  width: ${pxToRem(17)};
  height: ${pxToRem(12)};
`;

export const StyledGrainLogo = styled(Icon.GrainLogo)`
  color: ${colors.graieen};
  width: ${pxToRem(30)};
  height: ${pxToRem(40)};
`;

export const StyledSSO = styled(Icon.SSO)`
  width: ${pxToRem(54)};
  height: ${pxToRem(34)};
`;
