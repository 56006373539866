import React from 'react';
import { useSessionStorage } from 'react-use';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useMyself } from '@grain/api/auth';
import { useAnalytics, getBasePublicPath } from '@grain/grain-ui';
import { useUserRegistration, RegistrationData } from '../Onboarding/hooks';
import {
  StyledContainer,
  StyledLayoutContainer,
  StyledHeaderLogo,
  StyledHeaderBar
} from '../Login/styles';
import CreateWorkspace from './CreateWorkspace';
import JoinWorkspace from './JoinWorkspace';
import RequestedWorkspace from './RequestedWorkspace';
import { regDataState } from './state';
import { RequestWorkEmail } from './RequestWorkEmail';
import { CalendarAccessWarning } from './CalendarAccessWarning';

export type StepComponentProps = {
  registrationData: RegistrationData;
  forceToCreateWorkspace: () => void;
  forceToPendingWorkspaceRequest: () => void;
  cancelJoinWorkspaceRequest: () => void;
  forceToJoinWorkspace: () => void;
  bypassFreeDomainGating: () => void;
  bypassCalendarAccessWarning: () => void;
  calendarConnected?: boolean;
  onNext: () => void;
};

type RegistrationStep = {
  key: string;
  condition: ({
    isCreatingWorkspace,
    hasRequestedWorkspace,
    showFreeDomainGating,
    showCalendarAccessWarning
  }: {
    isCreatingWorkspace: boolean;
    hasRequestedWorkspace: boolean;
    showFreeDomainGating: boolean;
    showCalendarAccessWarning: boolean;
  }) => boolean;
  component?: React.FC<StepComponentProps>;
};

const PROVIDERS_WITH_CALENDARS = ['google', 'microsoft'];

const registrationSteps: RegistrationStep[] = [
  {
    key: 'request-work-email',
    condition: ({ showFreeDomainGating }) => showFreeDomainGating,
    component: RequestWorkEmail
  },
  {
    key: 'calendar-access-warning',
    condition: ({ showCalendarAccessWarning }) => showCalendarAccessWarning,
    component: CalendarAccessWarning
  },
  {
    key: 'requested-workspace',
    condition: ({ hasRequestedWorkspace }) => hasRequestedWorkspace,
    component: RequestedWorkspace
  },
  {
    key: 'create-workspace',
    condition: ({ isCreatingWorkspace }) => isCreatingWorkspace,
    component: CreateWorkspace
  },
  {
    key: 'join-workspace',
    condition: ({ isCreatingWorkspace }) => !isCreatingWorkspace,
    component: JoinWorkspace
  }
];

export default function Registration() {
  const { myself } = useMyself();
  const regData = useRecoilValue(regDataState);
  const setRegData = useSetRecoilState(regDataState);
  const { trackEvent } = useAnalytics();

  const [isCreatingWorkspace, setIsCreatingWorkspace] = React.useState(true);
  const [forcedToCreate, setForcedToCreate] = React.useState(false);
  const [hasRequestedWorkspace, setHasRequestedWorkspace] =
    React.useState(false);
  const [showFreeDomainGating, setShowFreeDomainGating] = React.useState(false);
  const [bypassFreeDomainGating, setBypassFreeDomainGating] =
    React.useState(false);
  const [bypassCalendarAccessWarning, setBypassCalendarAccessWarning] =
    React.useState(false);

  const [, setCalendarWarningSkipped] = useSessionStorage(
    'calendar_warning_skipped',
    false
  );

  const bypassFreeDomainGatingCallback = React.useCallback(() => {
    setBypassFreeDomainGating(true);
  }, []);
  const bypassCalendarAccessWarningCallback = React.useCallback(() => {
    // Inform the onboarding flow that redundant warnings, such as "missing
    // calendar events," should not be shown.  (Without first having calendar
    // access, we can't even check for whether their events have meetings.)
    setCalendarWarningSkipped(true);
    setBypassCalendarAccessWarning(true);
  }, [setCalendarWarningSkipped]);

  const forceToJoinWorkspace = React.useCallback(() => {
    return setIsCreatingWorkspace(false);
  }, []);

  const forceToCreateWorkspace = React.useCallback(() => {
    setIsCreatingWorkspace(true);
    return setForcedToCreate(true);
  }, []);

  const forceToPendingWorkspaceRequest = React.useCallback(() => {
    return setHasRequestedWorkspace(true);
  }, []);

  const cancelJoinWorkspaceRequest = React.useCallback(() => {
    return setHasRequestedWorkspace(false);
  }, []);

  const navigate = useNavigate();
  const handleNext = () => {
    navigate('/app/onboarding');
  };

  const { getRegistrationData } = useUserRegistration();
  React.useEffect(() => {
    (async () => {
      const data = await getRegistrationData(handleNext);
      setRegData(data);
      if (data && data.requested_workspace) {
        return forceToPendingWorkspaceRequest();
      }
      if (data && data.accessible_workspaces.length && !forcedToCreate) {
        return forceToJoinWorkspace();
      }
      if (!data?.domain && !bypassFreeDomainGating) {
        return setShowFreeDomainGating(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRequestedWorkspace]);

  React.useEffect(() => {
    if (!regData) return;
    trackEvent(
      'Registration',
      {
        step: 0,
        screen_name: isCreatingWorkspace ? 'CreateWorkspace' : 'JoinWorkspace',
        ...(regData && {
          num_of_accessible_workspaces: regData.accessible_workspaces?.length,
          num_of_invited_workspaces: regData.accessible_workspaces?.filter(
            ws => ws.is_invite_ws || ws.invite_type !== 'open'
          ).length,
          can_create_workspace: regData.can_create_workspace
        })
      },
      []
    );
  }, [regData, trackEvent, isCreatingWorkspace]);

  const calendarConnected =
    (myself?.oauthAccounts?.some(account => account.calendarConnected) ??
      false) ||
    (regData?.has_calendar ?? false);
  const providerOffersCalendar = Boolean(
    regData && PROVIDERS_WITH_CALENDARS.includes(regData.provider)
  );

  if (!regData) return null;

  const StepComponent = registrationSteps.find(step =>
    step.condition({
      isCreatingWorkspace,
      hasRequestedWorkspace,
      showFreeDomainGating: showFreeDomainGating && !bypassFreeDomainGating,
      showCalendarAccessWarning:
        !calendarConnected &&
        providerOffersCalendar &&
        !bypassCalendarAccessWarning
    })
  )?.component;

  return (
    <StyledContainer>
      <StyledLayoutContainer>
        <StyledHeaderBar justifyContent='flex-start' />
        <StyledHeaderLogo>
          <img
            alt='Grain logo'
            src={`${getBasePublicPath()}/images/brand/grain-primary-standard.svg`}
          />
        </StyledHeaderLogo>
        {regData && StepComponent ? (
          <StepComponent
            registrationData={regData}
            forceToCreateWorkspace={forceToCreateWorkspace}
            forceToPendingWorkspaceRequest={forceToPendingWorkspaceRequest}
            forceToJoinWorkspace={forceToJoinWorkspace}
            cancelJoinWorkspaceRequest={cancelJoinWorkspaceRequest}
            calendarConnected={calendarConnected}
            bypassFreeDomainGating={bypassFreeDomainGatingCallback}
            bypassCalendarAccessWarning={bypassCalendarAccessWarningCallback}
            onNext={handleNext}
          />
        ) : null}
      </StyledLayoutContainer>
    </StyledContainer>
  );
}
