import {
  ModalLayout,
  ModalLogo,
  ModalInfo
} from '~/modals/LogoInfoInteractionModals';
import { SendInvites } from '~/pages/Onboarding/InviteTeam/SendInvites';

import type { StepComponentProps } from '../types';

export function InviteTeam({ handleNext }: StepComponentProps) {
  return (
    <ModalLayout>
      <ModalLogo />
      <ModalInfo
        title='Notetakers for everyone on your team'
        body='For 14 days, all Workspace members get their own Grain Notetaker. Bring your whole team onboard to uncover more insights.'
      />
      <div css={['margin-top: 32px;']}>
        <SendInvites onNext={() => handleNext(1)} skipText='Skip Invites' />
      </div>
    </ModalLayout>
  );
}
