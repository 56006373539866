import { Maybe, RecordingsParticipantScope } from '@grain/api/schema.generated';
import {
  Divider,
  Icon16,
  Menu,
  MenuButton,
  MenuHeader,
  TextLabel,
  theme
} from '@grain/grain-ui/v4';
import { useCallback, useMemo } from 'react';
import { AdHocRecordingFragment } from '~/modules/contentFilter/contentfilter.generated';
import { ButtonMetadata } from './ButtonMetadata';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import { useGetHubspotIntegration } from '~/pages/RecordingPage/hooks';
import { LabelWithValue } from '../filtersV2/types';

type CompanyMetaItemProps = {
  participantScope: RecordingsParticipantScope;
  externalGroupsData: AdHocRecordingFragment['externalGroupsData'];
  workspaceName: Maybe<string>;
  workspaceLogoUrl: Maybe<string>;
  stopClickPropagation?: boolean;
  setCompanyFilter?: (company: LabelWithValue) => void;
};

const DealTextLabel = styled(TextLabel)`
  display: flex;
  & ${TextLabel.Text} {
    flex: 0 0 90px;
    color: ${theme.tokens.color.textTertiary};
  }

  & ${TextLabel.Metadata} {
    color: ${theme.tokens.color.textPrimary};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }
`;

const DealRow = styled.div`
  ${theme.utils.py('sm')};
  ${theme.utils.px('sm')};
`;

const USD_FORMATTER = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

export const CompanyMetaItem = ({
  participantScope,
  externalGroupsData: groups,
  workspaceName,
  workspaceLogoUrl,
  stopClickPropagation = false,
  setCompanyFilter
}: CompanyMetaItemProps) => {
  const navigate = useNavigate();
  const hubspotIntegration = useGetHubspotIntegration();

  const viewHubspotDeal = useCallback(
    (dealId: string) => {
      window.open(
        `https://app.hubspot.com/contacts/${hubspotIntegration.hubId}/deal/${dealId}`,
        '_blank'
      );
    },
    [hubspotIntegration]
  );

  const companyTitle = useMemo(() => {
    if (participantScope === RecordingsParticipantScope.Internal) {
      return 'Team';
    }

    if (groups?.length === 0) {
      return 'Customer';
    }

    if (groups?.length === 1) {
      return groups[0]?.group?.name;
    }

    return `${groups?.length} Companies`;
  }, [participantScope, groups]);

  if (!groups?.length) {
    return (
      <ButtonMetadata width='md' disableInteractive>
        {companyTitle === 'Team' ? (
          <TextLabel
            avatarProps={{
              name: workspaceName || '',
              url: workspaceLogoUrl || '',
              variant: 'square'
            }}
            size='sm'
          >
            {workspaceName || ''}
          </TextLabel>
        ) : (
          <Icon16.Minus />
        )}
      </ButtonMetadata>
    );
  }

  // Assume first grouping is only shown
  const firstGroup = groups[0];

  const viewMeetingsButton = (
    <MenuButton
      onClick={() => {
        if (setCompanyFilter) {
          setCompanyFilter({
            label: firstGroup.group.name,
            value: firstGroup.group.id.toString()
          });
        } else {
          navigate('/app/meetings/all', {
            replace: true,
            state: {
              initialFilters: {
                groups: [
                  {
                    label: firstGroup.group.name,
                    value: firstGroup.group.id
                  }
                ]
              }
            }
          });
        }
      }}
      label='View Meetings'
      textLabelProps={{ startIcon: Icon16.Record }}
    />
  );

  return (
    <Menu
      hideOnClickInside
      width='264px'
      stopClickPropagation={stopClickPropagation}
      content={
        <>
          <MenuHeader
            avatarProps={{
              name: firstGroup.group.name,
              variant: 'square'
            }}
            metadata='Company'
          >
            {firstGroup.group.name}
          </MenuHeader>
          {firstGroup.deal ? (
            <>
              <Divider />
              <DealRow>
                <DealTextLabel metadata={firstGroup.deal.name}>
                  Deal
                </DealTextLabel>
              </DealRow>
              <DealRow>
                <DealTextLabel metadata={firstGroup.deal.stage}>
                  Stage
                </DealTextLabel>
              </DealRow>
              <DealRow>
                <DealTextLabel metadata={firstGroup?.deal?.owner?.name || '-'}>
                  Deal Owner
                </DealTextLabel>
              </DealRow>
              <DealRow>
                <DealTextLabel
                  metadata={
                    firstGroup.deal.amount
                      ? USD_FORMATTER.format(firstGroup.deal.amount)
                      : '-'
                  }
                >
                  Value
                </DealTextLabel>
              </DealRow>
              <Divider />
              <MenuButton
                as={Link}
                to={`/app/deals/${firstGroup.deal.id}`}
                label='View in Deals'
                textLabelProps={{
                  startIcon: Icon16.BankNote
                }}
              />
              {viewMeetingsButton}
              <MenuButton
                label='Open in Hubspot'
                onClick={() =>
                  viewHubspotDeal(firstGroup?.deal?.providerKey || '')
                }
                textLabelProps={{
                  startIcon: Icon16.Hubspot,
                  endIcon: Icon16.ExternalLink
                }}
              />
            </>
          ) : (
            <>
              <Divider />
              {viewMeetingsButton}
            </>
          )}
        </>
      }
    >
      <ButtonMetadata width='md'>
        <TextLabel
          avatarProps={{
            name: companyTitle,
            variant: 'square'
          }}
          size='sm'
        >
          {companyTitle}
        </TextLabel>
      </ButtonMetadata>
    </Menu>
  );
};
