import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { format, formatDistanceToNow } from 'date-fns';

import {
  color,
  font,
  spacing,
  useShowToast,
  Icon,
  type Option as OptionType,
  Skeleton,
  useAnalytics,
  StopPropagation
} from '@grain/grain-ui';
import {
  CompletenessStatus,
  SortDirection,
  ViewAdHocSortBy
} from '@grain/api/schema.generated';

import { Chip, Icon16, theme, Tooltip } from '@grain/grain-ui/v4';

import { useFilteredViewAdHoc } from '~/modules/filters';

import { AdHocDealFragment } from '~/modules/contentFilter/contentfilter.generated';

import { useDealStagesQuery, useDealUpdateMutation } from '../deal.generated';

import type { SortBy } from '..';

import { MomentumTimeline, toMomentum } from './MomentumTimeline';
import { tokens } from 'ui/v4/theme/tokens';
import { useApolloClient } from '@apollo/client';
import { scrollbarStyles } from 'ui/v4/theme/utils';
import { useLazyLoad } from 'ui/v4/hooks';
import { getAtRiskReasons, getMeetingPerformanceBasedOnScore } from '../utils';
import { useExperiment } from '~/support/auth';
import { DealDiagnosisCompletenessIcon } from '~/components/DealDiagnosis/DealDiagnosisCompletenessIcon';
import { TimelineExplanationTooltip } from '../TimelineExplanationTooltip';
import { useHubspotIntegration } from '~/modules/integrations/hubspot/useHubspotIntegration';
import { DealStageCell } from './DealStageCell';

const cellStyles = {
  title: css`
    width: 220px;
    flex: 1;
  `,
  stage: css`
    width: 180px;
  `,
  momentum: css`
    width: 231px;
  `,
  diagnosisCompletenessStatus: css`
    width: 140px;
  `,
  scorecardAvg: css`
    width: 140px;
  `,
  amount: css`
    width: 140px;
  `,
  owner: css`
    width: 180px;
  `,
  closedate: css`
    width: 180px;
  `,
  contacts: css`
    width: 220px;
  `
};

const Container = styled.div`
  flex: 1;
  overflow-x: auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${scrollbarStyles}
`;

type RowProps = {
  isSticky?: boolean;
  as?: React.ElementType;
  to?: string;
  header?: boolean;
};
const Row = styled.div<RowProps>`
  width: 100%;
  min-width: fit-content;
  border-width: 0 0 1px 0;
  border-color: ${color.gull};
  border-style: solid;

  display: flex;

  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 2;
    `}

  & > * {
    background: ${tokens.color.surfacePrimary};
  }

  &:hover {
    text-decoration: none;
    & > * {
      ${({ header }) =>
        !header && `background: ${tokens.color.surfaceSecondary};`}
    }
  }

  > :first-of-type {
    ${spacing.pl0}
    border-right: 1px solid ${color.gull};
    position: sticky;
    left: 0;
    z-index: 1;

    & > div,
    & > a {
      ${spacing.pl6}
    }
  }

  > * {
    flex: 0 0 auto;
  }
`;

const Cell = styled.div`
  display: flex;

  > * {
    flex: 1 0 auto;
    max-width: 100%;
  }

  .tippy-wrapper {
    display: inline-block; // Fix Safari positioning
  }
`;

const cellContentCss = css`
  ${spacing.px4}
  ${spacing.py2}
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;

  :hover {
    text-decoration: none; // Reset link styles
  }

  > * {
    width: 100%;
    max-width: 100%;
  }
`;

const CellHover = styled.div`
  ${cellContentCss}
  ${spacing.p2}

  :hover {
    background: ${tokens.color.buttonNeutralHover};
  }
`;

const CellLink = styled.div`
  ${cellContentCss}
`;

const Text = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type HeaderProps = { sortable?: boolean };
const Header = styled.div<HeaderProps>`
  ${spacing.px4}
  ${spacing.py2}
  background: ${tokens.color.surfacePrimary};
  display: flex;

  color: ${color.crow};
  ${font.v4.c1[500]}

  ${({ sortable }) =>
    sortable &&
    css`
      :hover {
        cursor: pointer;
        background: ${tokens.color.surfaceSecondary};
      }
    `}

  .sort-icon {
    display: none;
  }

  :hover .sort-icon {
    display: block;
  }

  .tippy-wrapper {
    display: inline-flex; // Fix height
  }
`;

const HeaderTitle = styled.div`
  flex: 1 0 0;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  ${spacing.g4}

  max-width: 100%;
`;

const TitleText = styled.div`
  display: flex;
  flex-direction: column;
  ${spacing.g1}
  overflow: hidden;

  flex: 1 0 0;
`;

const Stage = styled.div`
  display: flex;
  flex-direction: column;
  ${spacing.g1}
`;

const StageLabel = styled.div`
  color: ${color.rook};
  ${font.v4.b3[400]};
  margin-top: -2px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StageBar = styled.div`
  padding: 6px 0 3px 0;
  display: flex;
`;

const StageBarL = styled.div`
  border-radius: 2px 0 0 2px;
  height: 4px;
`;

const StageBarR = styled.div`
  border-radius: 0 2px 2px 0;
  height: 4px;
`;

const Momentum = styled.div`
  ${spacing.px4}
  display: flex;
  ${spacing.g5}
  align-items: center;
  justify-content: center;
`;

const CenteredIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClosedAt = styled.div`
  display: flex;
  flex-direction: column;
  ${spacing.g2}
`;

const Contacts = styled.div`
  display: flex;
  align-items: center;
  ${spacing.g2}
`;

const ContactsText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;

  flex: 1 0 0;
`;

const LoadingCell = styled(Cell)`
  min-height: 62px;
  align-items: center;
`;

const TableLoading = ({
  dealDiagnosisEnabled
}: {
  dealDiagnosisEnabled: boolean;
}) => (
  <>
    {Array(20)
      .fill(null)
      .map((_, i) => (
        <Row key={i} css={['height: 62px']}>
          <LoadingCell css={cellStyles.title}>
            <Skeleton height='16px' />
          </LoadingCell>
          <LoadingCell css={cellStyles.stage}>
            <Skeleton height='16px' />
          </LoadingCell>
          <LoadingCell css={cellStyles.momentum}>
            <Skeleton height='16px' />
          </LoadingCell>
          {dealDiagnosisEnabled && (
            <LoadingCell css={cellStyles.diagnosisCompletenessStatus}>
              <Skeleton height='16px' />
            </LoadingCell>
          )}
          <LoadingCell css={cellStyles.scorecardAvg}>
            <Skeleton height='16px' />
          </LoadingCell>
          <LoadingCell css={cellStyles.amount}>
            <Skeleton height='16px' />
          </LoadingCell>
          <LoadingCell css={cellStyles.owner}>
            <Skeleton height='16px' />
          </LoadingCell>
          <LoadingCell css={cellStyles.closedate}>
            <Skeleton height='16px' />
          </LoadingCell>
          <LoadingCell css={cellStyles.contacts}>
            <Skeleton height='16px' />
          </LoadingCell>
        </Row>
      ))}
  </>
);

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

type DealType = AdHocDealFragment;

const toStagePercentages = (deal: DealType) => {
  let leftPer, leftColor, rightPer, rightColor;

  if (deal.isOpen) {
    leftPer = deal.stageDetails?.percentage ?? 0;
    // Ensure we don't eat up the border radius
    leftPer = leftPer < 5 ? 5 : leftPer;
    leftPer = leftPer > 95 ? 95 : leftPer;

    leftColor = color.brandGreenDark;
    rightPer = 100 - leftPer;
    rightColor = color.gull;
  } else {
    leftPer = 95;
    leftColor = color.brandGreenDark;
    rightPer = 5;
    rightColor = color.brandGreenDark;
  }

  return {
    lColor: `background: ${leftColor};`,
    lWidth: `width: ${leftPer}%;`,
    rColor: `background: ${rightColor};`,
    rWidth: `width: ${rightPer}%;`
  };
};

type TableProps = {
  dealsQueryResponse: ReturnType<typeof useFilteredViewAdHoc>['queryResponse'];
  stageResults: ReturnType<typeof useDealStagesQuery>;
  sortByState: [SortBy, React.Dispatch<React.SetStateAction<SortBy>>];
  loading: boolean;
};
export const Table = ({
  dealsQueryResponse,
  stageResults,
  sortByState,
  loading
}: TableProps) => {
  const lazyLoadRef = useRef<HTMLDivElement | null>(null);
  const { trackEvent } = useAnalytics();
  const client = useApolloClient();
  const showToast = useShowToast();
  const { data: dealsData } = dealsQueryResponse?.results;
  const deals = (dealsData?.viewAdHoc?.list ?? []) as DealType[];
  const hubspotIntegration = useHubspotIntegration();
  const { enabled: dealDiagnosisFlagEnabled } = useExperiment('deal_diagnosis');
  const dealDiagnosisEnabled =
    dealDiagnosisFlagEnabled &&
    Boolean(hubspotIntegration?.dealDiagnosticMethodology);

  const { data: stagesData, refetch: refetchStages } = stageResults;

  const stageOptions = React.useMemo(() => {
    if (!stagesData) return [];
    const { dealStages } = stagesData;

    return dealStages.map(stage => ({
      title: stage.label,
      value: stage.stageId
    }));
  }, [stagesData]) as OptionType[];

  const [updateDeal] = useDealUpdateMutation();

  const updateStage = React.useCallback(
    (dealId: string, stageId: string) => {
      updateDeal({
        variables: { dealId, updates: { stageId } },
        onCompleted: data => {
          const updatedDeal = data?.dealUpdate;
          if (updatedDeal) {
            // TODO: This is a temporary fix to update the deal stage until we have a subscription
            client.cache.modify({
              id: client.cache.identify(updatedDeal),
              fields: {
                stageDetails: (existingStageDetails = {}) => ({
                  ...existingStageDetails,
                  stageId,
                  label:
                    stageOptions.find(option => option.value === stageId)
                      ?.title || existingStageDetails.label
                })
              }
            });
          }
          refetchStages();
          // END TEMP FIX

          showToast({
            content: 'Stage updated',
            type: 'success'
          });
        }
      });
    },
    [updateDeal, refetchStages, showToast, client.cache, stageOptions]
  );

  const [sort, setSort] = sortByState;

  const maybeChevron = React.useCallback(
    (
      sortBy: ViewAdHocSortBy,
      defaultDirection: SortDirection = SortDirection.Desc
    ) => {
      // When sorting by a different column, we render this chevron
      // so we can display it when the user hovers over the column header
      if (sort.sortBy !== sortBy) {
        return defaultDirection === SortDirection.Asc ? (
          <Icon.ChevronUp16x16 className='sort-icon' />
        ) : (
          <Icon.ChevronDown16x16 className='sort-icon' />
        );
      }

      return sort.sortDirection === SortDirection.Asc ? (
        <Icon.ChevronUp16x16 />
      ) : (
        <Icon.ChevronDown16x16 />
      );
    },
    [sort]
  );

  const updateSortBy = React.useCallback(
    (
      newSort: SortBy['sortBy'],
      defaultDirection: SortBy['sortDirection'] = SortDirection.Desc
    ) => {
      if (dealsQueryResponse.results.loading) return;

      if (sort.sortBy !== newSort) {
        setSort({ sortBy: newSort, sortDirection: defaultDirection });
        return;
      }

      if (sort.sortDirection === SortDirection.Asc) {
        setSort({ ...sort, sortDirection: SortDirection.Desc });
      } else {
        setSort({ ...sort, sortDirection: SortDirection.Asc });
      }
    },
    [dealsQueryResponse.results.loading, sort, setSort]
  );

  const handleDealRowClick = React.useCallback(() => {
    trackEvent('Deal Opened');
  }, [trackEvent]);

  useLazyLoad(lazyLoadRef, () => {
    if (dealsQueryResponse.hasNext) {
      dealsQueryResponse.fetchNext();
    }
  });

  const dealDiagnosticMethodology =
    deals?.[0]?.diagnosis?.methodology ?? 'Diagnosis';

  return (
    <Container ref={lazyLoadRef}>
      <Row as='div' css={['border-top-width: 1px;']} isSticky header>
        <Header
          css={[cellStyles.title]}
          sortable={true}
          onClick={() =>
            updateSortBy(ViewAdHocSortBy.Alphabetical, SortDirection.Asc)
          }
        >
          <HeaderTitle>Deals</HeaderTitle>
          {maybeChevron(ViewAdHocSortBy.Alphabetical, SortDirection.Asc)}
        </Header>
        <Header
          css={[cellStyles.stage]}
          sortable={true}
          onClick={() => updateSortBy(ViewAdHocSortBy.Stage, SortDirection.Asc)}
        >
          <HeaderTitle>Stage</HeaderTitle>
          {maybeChevron(ViewAdHocSortBy.Stage, SortDirection.Asc)}
        </Header>
        <Header css={[cellStyles.momentum]}>
          <HeaderTitle>Momentum</HeaderTitle>
          <TimelineExplanationTooltip>
            <Icon16.HelpCircle
              css={[color.fg.pigeon, 'cursor: pointer;']}
              aria-label='Show momentum timeline explanation'
            />
          </TimelineExplanationTooltip>
        </Header>
        {dealDiagnosisEnabled && (
          <Header css={[cellStyles.diagnosisCompletenessStatus]}>
            <Text css={{ margin: 'auto' }}>{dealDiagnosticMethodology}</Text>
          </Header>
        )}
        <Header css={[cellStyles.scorecardAvg]}>
          <Text css={{ width: 108 }}>Scorecard Avg</Text>
        </Header>
        <Header
          css={[cellStyles.amount]}
          sortable={true}
          onClick={() => updateSortBy(ViewAdHocSortBy.Relevance)}
        >
          <HeaderTitle>Amount</HeaderTitle>
          {maybeChevron(ViewAdHocSortBy.Relevance)}
        </Header>
        <Header css={[cellStyles.owner]}>Deal Owner</Header>
        <Header
          css={[cellStyles.closedate]}
          sortable={true}
          onClick={() => updateSortBy(ViewAdHocSortBy.ClosedAt)}
        >
          <HeaderTitle>Close Date</HeaderTitle>
          {maybeChevron(ViewAdHocSortBy.ClosedAt)}
        </Header>
        <Header css={[cellStyles.contacts]}>Contacts</Header>
      </Row>
      {loading ? (
        <TableLoading dealDiagnosisEnabled={dealDiagnosisEnabled} />
      ) : (
        deals.map(deal => {
          const stageBar = toStagePercentages(deal);
          const closedAt = new Date(deal.closedAt ?? '');
          const mInfo = toMomentum(deal);
          const contact = deal?.firstContact?.person;
          const contactEmail = contact?.contacts?.find(
            c => c.contactType === 'EMAIL'
          )?.contact;

          const dealPath = `/app/deals/${deal.id}`;
          const selectedStage = {
            title: deal.stageDetails?.label,
            value: deal.stageDetails?.stageId
          } as OptionType;

          const atRiskReasons = getAtRiskReasons(
            deal.atRisk,
            deal.latestMomentum,
            deal.latestCallScore
          );

          const performance = getMeetingPerformanceBasedOnScore(
            deal.latestCallScore
          );

          const atRiskTooltipContent = atRiskReasons.length
            ? `Deal at Risk - ${atRiskReasons.join(', ')}`
            : 'Deal at Risk';

          return (
            <Row
              as={Link}
              to={dealPath}
              onClick={handleDealRowClick}
              key={deal.id}
              css={['height: 62px']}
            >
              <Cell css={cellStyles.title}>
                <CellLink>
                  <Title>
                    <TitleText>
                      <Text css={[color.fg.blackbird, font.v4.b3[500]]}>
                        {deal.name}
                      </Text>
                      <Text css={[color.fg.pigeon, font.v4.b4[500]]}>
                        {deal.group.name}
                      </Text>
                    </TitleText>
                    {deal.atRisk && (
                      <Tooltip maxWidth={400} content={atRiskTooltipContent}>
                        <div css={[color.fg.errorbird]}>
                          <Icon16.AlertCircle />
                        </div>
                      </Tooltip>
                    )}
                  </Title>
                </CellLink>
              </Cell>
              <Cell css={[spacing.p2, cellStyles.stage]}>
                <StopPropagation eventNames={['click']} preventDefault>
                  <DealStageCell
                    entryComponent={() => (
                      <CellHover>
                        <Stage>
                          <StageLabel>{deal.stageDetails?.label}</StageLabel>
                          <StageBar>
                            <StageBarL
                              css={[stageBar.lColor, stageBar.lWidth]}
                            />
                            <StageBarR
                              css={[stageBar.rColor, stageBar.rWidth]}
                            />
                          </StageBar>
                        </Stage>
                      </CellHover>
                    )}
                    value={selectedStage.value}
                    onChange={val => {
                      const selectedOption = stageOptions.find(
                        option => option.value === val
                      );
                      if (selectedOption) {
                        updateStage(deal.id, selectedOption.value);
                        trackEvent('Deal Stage Updated');
                      }
                    }}
                    stageOptions={stageOptions}
                  />
                </StopPropagation>
              </Cell>
              <Cell css={['padding: 0', cellStyles.momentum]}>
                <CellLink>
                  {deal?.isOpen ? (
                    <Momentum>
                      <MomentumTimeline
                        activityBar={deal?.activityBar}
                        momentumInfo={mInfo}
                        momentumUpdates={deal?.momentumUpdates}
                        latestMomentum={deal?.latestMomentum}
                      />
                      <CenteredIcon css={[`color: ${mInfo.colorOne};`]}>
                        {mInfo.icon}
                      </CenteredIcon>
                    </Momentum>
                  ) : (
                    <Text css={[color.fg.rook, font.v4.b3[400]]}>
                      <Icon.Minus16x16
                        color={theme.tokens.color.iconDisabled}
                        aria-label='No Scorecard Average'
                      />
                    </Text>
                  )}
                </CellLink>
              </Cell>
              {dealDiagnosisEnabled && (
                <Cell css={cellStyles.diagnosisCompletenessStatus}>
                  <CellLink>
                    <DealDiagnosisCompletenessIcon
                      status={deal.diagnosis?.status ?? CompletenessStatus.None}
                      disabled={!deal.isOpen}
                    />
                  </CellLink>
                </Cell>
              )}
              <Cell css={cellStyles.scorecardAvg}>
                <CellLink>
                  {performance ? (
                    <Text css={[color.fg.rook, font.v4.b3[400]]}>
                      {performance.text}
                    </Text>
                  ) : (
                    <Text css={[color.fg.rook, font.v4.b3[400]]}>
                      <Icon.Minus16x16
                        color={theme.tokens.color.iconDisabled}
                        aria-label='No Scorecard Average'
                      />
                    </Text>
                  )}
                </CellLink>
              </Cell>
              <Cell css={cellStyles.amount}>
                <CellLink>
                  <Text css={[color.fg.rook, font.v4.b3[400]]}>
                    {!!deal?.amount && formatter.format(deal.amount)}
                  </Text>
                </CellLink>
              </Cell>
              <Cell css={cellStyles.owner}>
                <CellLink>
                  <Text css={[color.fg.rook, font.v4.b3[400]]}>
                    {deal?.owner?.name}
                  </Text>
                </CellLink>
              </Cell>
              <Cell css={cellStyles.closedate}>
                <CellLink>
                  {deal.closedAt && (
                    <ClosedAt>
                      <Text css={[color.fg.rook, font.v4.b3[400]]}>
                        {format(closedAt, 'MMM d, yyyy')}
                      </Text>
                      <Text css={[color.fg.pigeon, font.v4.b4[500]]}>
                        {formatDistanceToNow(closedAt, { addSuffix: true })}
                      </Text>
                    </ClosedAt>
                  )}
                </CellLink>
              </Cell>
              <Cell css={cellStyles.contacts}>
                <CellLink>
                  {deal.firstContact && (
                    <Contacts>
                      <ContactsText>
                        <Text css={[color.fg.rook, font.v4.b3[400]]}>
                          {contact?.name}
                        </Text>
                        {contactEmail && (
                          <Text css={[color.fg.pigeon, font.v4.b4[500]]}>
                            {contactEmail}
                          </Text>
                        )}
                      </ContactsText>
                      {deal.firstContact.contactsCount > 1 && (
                        <Chip
                          variant='stroked'
                          size='sm'
                          title={`+${deal.firstContact.contactsCount - 1}`}
                        />
                      )}
                    </Contacts>
                  )}
                </CellLink>
              </Cell>
            </Row>
          );
        })
      )}
    </Container>
  );
};
