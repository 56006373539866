import { Helmet } from 'react-helmet';

import * as Layout from '~/components/Layout';
import { ErrorBox, useQueryParam } from '@grain/grain-ui';

import QuickTags from './QuickTags';
import Account from './Profile';
import App from './App';
import Recording from './Recording';
import Recorder from './Recorder';
import {
  StyledContentWrapper,
  StyledWrapper,
  StyledContainer,
  TabHeader
} from '~/pages/Settings/SettingsWrapper';
import { getMetaContent } from '@grain/components/support/browser';
import { layoutContentStyles } from '~/pages/Settings/Workspace/styles';
import { SettingsGate } from '../SettingsGate';
import { useMyselfQuery } from '@grain/api/graphql/queries/user.generated';
import { useFeature } from '@grain/api/auth';

const TAB_IDS = {
  account: 'account',
  recordings: 'recordings',
  recorder: 'recorder',
  quickTags: 'quickTags',
  app: 'app'
};

const TAB_LABELS = {
  [TAB_IDS.account]: 'Account',
  [TAB_IDS.recordings]: 'My Meetings',
  [TAB_IDS.recorder]: 'Notetaker',
  [TAB_IDS.quickTags]: 'Quick Tags',
  [TAB_IDS.app]: 'App'
};

const CREATE_RECORDING_GATED_TABS = [TAB_IDS.recordings, TAB_IDS.recorder];

export default function AccountSettings() {
  const [_tabId] = useQueryParam('tab');
  const tabId = _tabId as string;

  const { enabled: isCreateRecordingEnabled } = useFeature('create_recording');
  const isSpeedBumpActive =
    CREATE_RECORDING_GATED_TABS.includes(tabId) && !isCreateRecordingEnabled;
  const currentGateName = CREATE_RECORDING_GATED_TABS.includes(tabId)
    ? 'create_recording'
    : undefined;

  const { loading, error } = useMyselfQuery();

  const activeTabId = tabId in TAB_IDS ? tabId : TAB_IDS.account;

  const nativeUrl =
    getMetaContent('grain:native:protocol') + '://settings/account?tab=app';

  if (loading) return null;

  return (
    <Layout.Wrapper withSidebar>
      <Helmet title='Account settings' />
      <Layout.Content className='draggable' css={layoutContentStyles}>
        <StyledWrapper>
          <SettingsGate
            shouldGate={isSpeedBumpActive}
            gateName={currentGateName}
          >
            {error && <ErrorBox error={error} />}
            <StyledContainer isSpeedBumpActive={isSpeedBumpActive}>
              <TabHeader
                pageName='My Account'
                title={TAB_LABELS[activeTabId]}
                css={[
                  'padding-left: 0 !important; margin: 0 -32px !important; border-radius: 15px 15px 0 0;'
                ]}
              />
              <StyledContentWrapper css={['padding: 0;']}>
                {activeTabId === TAB_IDS.account && <Account />}
                {activeTabId === TAB_IDS.recordings && <Recording />}
                {activeTabId === TAB_IDS.recorder && <Recorder />}
                {activeTabId === TAB_IDS.quickTags && <QuickTags />}
                {activeTabId === TAB_IDS.app && <App nativeUrl={nativeUrl} />}
              </StyledContentWrapper>
            </StyledContainer>
          </SettingsGate>
        </StyledWrapper>
      </Layout.Content>
    </Layout.Wrapper>
  );
}
