import { isDesktopApp } from '@grain/desktop-lib';
import Web from './Web';
import DesktopOnlySettings from './DesktopOnlySettings';

type Props = {
  nativeUrl: string;
};

const App = ({ nativeUrl }: Props) => {
  return isDesktopApp ? <DesktopOnlySettings /> : <Web nativeUrl={nativeUrl} />;
};

export default App;
