import React from 'react';
import { storyState, storyDurationSelector } from '../state';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { scrollItemIntoView } from '../Document';
import {
  useStoryReorderDrag,
  useAddSection,
  useStoryItemsState
} from '../hooks';
import { media, spacing } from '@grain/styles/constants';
import { useMediaQuery } from '@grain/components/support/browser';
import { useHotspots } from '~/components/Hotspot';
import { roundToNearestMinute, Icon } from '@grain/grain-ui';

import {
  StyledPlaceholder,
  StyledOutlineWrapper,
  StyledAddSectionButton,
  StyledOutlineLabel,
  StyledScrollContainer
} from './styles';

import StoryItem from './StoryItem';

const dragParent = document.createElement('div');
document.body.appendChild(dragParent);

export default function Outline() {
  const [story] = useRecoilState(storyState);
  const containerRef = React.useRef();
  const isMobile = useMediaQuery(media.small);
  const [addSection, addSectionRes] = useAddSection({
    onCompleted() {
      // Wait for new item to render...
      setTimeout(() => {
        scrollItemIntoView(story.items.length - 1);
      }, 30);
    }
  });

  const storyDurationMs = useRecoilValue(storyDurationSelector);
  const durationMinutes = roundToNearestMinute(storyDurationMs);
  const hasAtLeastOneClip = story?.items?.some(item => !!item.clip);

  const [hotspotRef] = useHotspots('story-ftux', 'add-section', {
    top: 0,
    left: 120
  });

  const [storyItems, setStoryItems] = useStoryItemsState();
  const {
    placeholderStyle,
    draggingItemId,
    moveItemRes,
    handleDragStart,
    handleDragUpdate,
    handleDragEnd
  } = useStoryReorderDrag({
    containerRef,
    onDragEnd: ({ destination }) => {
      const { index: toIndex } = destination;
      scrollItemIntoView(toIndex);
    },
    setState: setStoryItems
  });

  // Create a set of the indices of duplicated clips, skipping the first unique
  const dupeIndices = React.useMemo(() => {
    const ids = storyItems.map(item => item?.clip?.id ?? null);
    const uniques = new Set(ids);
    const dupeIndices = new Set();

    // Remove each id from a set of unique ids. If id doesn't exist, then it is a duplicate
    ids.forEach((id, index) => {
      if (id === null) return;
      if (uniques.has(id)) {
        uniques.delete(id);
      } else {
        dupeIndices.add(index);
      }
    });
    return dupeIndices;
  }, [storyItems]);

  if (isMobile) {
    return null;
  }

  return (
    <StyledOutlineWrapper ref={containerRef}>
      <StyledOutlineLabel>
        <span>STORY OUTLINE</span>
        {hasAtLeastOneClip && (
          <span className='duration-pill'>~{durationMinutes} min</span>
        )}
      </StyledOutlineLabel>

      <StyledScrollContainer>
        <DragDropContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragUpdate={handleDragUpdate}
          isDragDisabled={moveItemRes.loading}
        >
          <Droppable droppableId='outline'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  cursor: snapshot.isDragging ? 'grabbing' : '',
                  position: 'relative'
                }}
              >
                {storyItems.map((item, index) => (
                  <Draggable
                    draggableId={item.id}
                    index={index}
                    key={item.id + index}
                  >
                    {(provided, snapshot) => {
                      const highlighted =
                        snapshot.isDragging || snapshot.isDropAnimating;

                      return (
                        <div
                          key={item.id + index}
                          ref={provided.innerRef}
                          css={[spacing.mt1]}
                          {...provided.draggableProps}
                        >
                          <StoryItem
                            item={item}
                            index={index}
                            handleProps={provided.dragHandleProps}
                            highlighted={highlighted}
                            disableHover={
                              snapshot.isDragging && draggingItemId !== item.id
                            }
                            isDupe={dupeIndices.has(index)}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
                {!!placeholderStyle?.height && snapshot.isDraggingOver && (
                  <StyledPlaceholder style={placeholderStyle} />
                )}
                <StyledAddSectionButton
                  data-cy='story-outline-add-section'
                  ref={hotspotRef}
                  onClick={() => addSection()}
                  disabled={addSectionRes.loading}
                >
                  <Icon.PlusThin /> Add New Section
                </StyledAddSectionButton>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </StyledScrollContainer>
    </StyledOutlineWrapper>
  );
}
