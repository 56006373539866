import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { Button, Modal } from '@grain/grain-ui';
import { colors, spacing } from '@g/styles/constants';

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  ${spacing.p7};
  text-align: center;

  .title {
    font-weight: 600;
  }
  .subtitle {
    color: ${colors.crow};
    ${spacing.mb6};
    ${spacing.mt3};
  }
  .buttons {
    display: flex;
    gap: ${spacing[3]};
  }
  button:hover,
  a:hover {
    text-decoration: none !important;
  }
`;

type RecordingLimitModalProps = {
  onClose: () => void;
  limit: number;
};
export function RecordingLimitModal({
  onClose,
  limit
}: RecordingLimitModalProps) {
  return (
    <Modal closable={false} width={420}>
      <StyledContainer>
        <div className='title'>
          You've reached the {limit} recording limit on your Starter plan.
        </div>
        <div className='subtitle'>
          To restore this recording, upgrade to the Business plan for unlimited
          recordings and transcripts.
        </div>
        <div className='buttons'>
          <Button variant='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Link to='/app/settings/workspace?tab=plans'>
            <Button>Upgrade plan</Button>
          </Link>
        </div>
      </StyledContainer>
    </Modal>
  );
}
