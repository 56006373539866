import React, { useMemo } from 'react';
import format from 'date-fns/format';
import { useMyself, useWorkspace } from '@grain/api/auth';
import { useCalendarQuery } from '@grain/api/graphql/modules/calendar.generated';

export const useOnboardingNextMeetingInformation = () => {
  const { workspace, loading: workspaceLoading } = useWorkspace();
  const { myself, loading: myselfLoading } = useMyself();
  const { data: calendarData } = useCalendarQuery();

  const calendarSyncing = useMemo(() => {
    if (!calendarData) {
      return true;
    }

    return calendarData?.calendar?.accounts?.some(
      (account: { status: string }) =>
        ['NOT_SYNCED', 'SYNCING'].includes(account.status)
    );
  }, [calendarData]);

  const autoRecord = useMemo(() => {
    if (!workspace) return null;
    const { defaultAutoRecordExternal, defaultAutoRecordInternal } = workspace;

    const userAutoRecordExternal = myself?.settings?.autoRecordExternal;
    const userAutoRecordInternal = myself?.settings?.autoRecordInternal;

    const autoRecordExternal =
      userAutoRecordExternal ?? defaultAutoRecordExternal;
    const autoRecordInternal =
      userAutoRecordInternal ?? defaultAutoRecordInternal;

    if (autoRecordExternal && !autoRecordInternal) {
      return {
        title: 'customer',
        value: 'EXTERNAL'
      };
    }
    if (!autoRecordExternal && autoRecordInternal) {
      return {
        title: 'team',
        value: 'INTERNAL'
      };
    }
    if (autoRecordExternal && autoRecordInternal) {
      return {
        title: '',
        value: 'ALL'
      };
    }
    if (!autoRecordExternal && !autoRecordInternal) {
      return {
        title: 'none',
        value: 'NONE'
      };
    }
  }, [workspace, myself]);

  const nextMeeting = useMemo(() => {
    if (!calendarData || calendarSyncing || !workspace) {
      return null;
    }

    const { events } = calendarData.calendar;

    return events?.find(calendarEvent => calendarEvent.record);
  }, [calendarData, calendarSyncing, workspace]);

  const autoRecordTitle =
    !autoRecord || autoRecord?.value === 'NONE'
      ? 'Manually add Grain to your next meeting'
      : `All of your ${autoRecord?.title} meetings will be auto-recorded`;

  const autoRecordDescription: React.ReactNode =
    autoRecord?.value !== 'NONE' ? (
      nextMeeting ? (
        <>
          Your Grain Notetaker will join and record your next meeting,
          <br />
          {nextMeeting.title} on{' '}
          {format(new Date(nextMeeting.start), 'MMMM do')} at{' '}
          {format(new Date(nextMeeting.start), 'h a')}.
        </>
      ) : (
        'Your Grain Notetaker will join and record your next meeting.'
      )
    ) : (
      'Manually add Grain by pasting the meeting link on the Calendar page.'
    );

  return {
    loading: workspaceLoading || myselfLoading || !workspace || !myself,
    autoRecordTitle,
    autoRecordDescription
  };
};
