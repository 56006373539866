import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { toHumanDuration } from '@grain/grain-ui';
import { theme, Icon16, Thumbnail } from '@grain/grain-ui/v4';
import { format } from 'date-fns';

const {
  utils: { padding },
  tokens: { spacing, color, radii, typography }
} = theme;

const Container = styled.div<{ maxWidth?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${spacing.lg};
  ${padding('md', 'md', 'lg', 'md')};
  border-radius: ${radii.xl};
  background: ${color.surfacePrimary};
  border: 1px solid ${color.borderTertiary};
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
  max-width: ${props => props.maxWidth || 340}px;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.sm};
  flex: 1;
  min-width: 0;
  gap: ${spacing['2xs']};
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacing.xs};
  width: 100%;
`;

const Title = styled.h3`
  ${typography.b2[500]};
  color: ${color.textPrimary};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MetaInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.sm};
  color: ${color.textTertiary};
  ${typography.b4[500]};
`;

type CrmProvider = 'hubspot' | 'salesforce';

type CrmMeetingThumbnailProps = {
  loading?: boolean;
  title: string;
  date: Date;
  duration?: number;
  thumbnailUrl?: string;
  crmProvider?: CrmProvider;
  maxWidth?: number;
};

const getCrmIcon = (provider?: CrmProvider) => {
  switch (provider) {
    case 'hubspot':
      return (
        <Icon16.Hubspot
          css={css`
            flex-shrink: 0;
          `}
          color='#FF7959'
        />
      );
    case 'salesforce':
      return (
        <Icon16.Salesforce
          css={css`
            flex-shrink: 0;
          `}
          color='#00A1E0'
        />
      );
  }
};

export const CrmMeetingThumbnail = ({
  loading,
  title,
  date,
  duration = 0,
  thumbnailUrl,
  crmProvider,
  maxWidth
}: CrmMeetingThumbnailProps) => {
  return (
    <Container maxWidth={maxWidth}>
      <Thumbnail imgSrc={thumbnailUrl} variant='preview' isLoading={loading} />
      <Content>
        <TitleRow>
          <Title>{title}</Title>
          {getCrmIcon(crmProvider)}
        </TitleRow>
        <MetaInfo>
          <span>{format(date, 'MMM d, yyyy')}</span>
          {duration > 0 && (
            <>
              <span>·</span>
              <span>{toHumanDuration(duration)}</span>
            </>
          )}
        </MetaInfo>
      </Content>
    </Container>
  );
};
