import styled from '@emotion/styled';
import { getBasePublicPath, ImageEmptyView } from '@grain/grain-ui';
import { Button, Icon16, theme } from '@grain/grain-ui/v4';
import { Link } from 'react-router-dom';

const Description = styled.div`
  ${theme.tokens.typography.b1[400]};
  color: ${theme.tokens.color.textSecondary};
  max-width: 450px;
  text-align: center;
  margin-bottom: ${theme.tokens.spacing['3xl']};
`;

const Title = styled.div`
  ${theme.tokens.typography.h1};
  color: ${theme.tokens.color.textPrimary};
  margin-bottom: ${theme.tokens.spacing.lg};
  text-align: center;
`;
export const EmptyCollectionState = () => (
  <ImageEmptyView.Wrapper>
    <ImageEmptyView.Image
      src={`${getBasePublicPath()}/images/zero-states/playlists.png`}
    />
    <ImageEmptyView.InnerWrapper>
      <Title>
        It looks like you don’t have any meetings in this playlist yet.
      </Title>
      <Description>
        <span>You can add a meeting to a playlist by clicking the </span>
        <Icon16.Playlists
          role='img'
          aria-label='Playlists Icon'
          css={[theme.utils.mx('sm'), theme.utils.size('16px')]}
        />{' '}
        <span>icon from a meeting.</span>
      </Description>
      <Button as={Link} to='/app/meetings/all'>
        View meetings
      </Button>
    </ImageEmptyView.InnerWrapper>
  </ImageEmptyView.Wrapper>
);
