import { color, Skeleton } from '@grain/grain-ui';
import { SectionContainer, SectionTitle } from './styles';

export default function Section({
  title,
  children,
  isLoading = true
}: {
  title: string;
  children: React.ReactNode;
  isLoading?: boolean;
}) {
  return (
    <SectionContainer>
      <SectionTitle>{title}</SectionTitle>
      {isLoading ? (
        <div>
          {[1, 2].map((_, index) => (
            <Skeleton
              key={index}
              baseColor={color.gull}
              shimmerColor={color.goose}
              css={['width: 100%; height: 32px; margin-bottom: 16px;']}
            />
          ))}
        </div>
      ) : (
        children
      )}
    </SectionContainer>
  );
}
