import { Navigate, Outlet, useLocation } from 'react-router-dom';

import SignUp from '~/pages/SignUp';
import Registration from '~/pages/Registration';
import RecordingPage from '~/pages/RecordingPage';
import DisabledUser from '~/pages/DisabledUser';
import TeamsAuthCompletePage from '~/pages/TeamsAuthCompletePage';
import LoginConfirm from '~/pages/LoginConfirm';
import Download from '~/pages/Download';
import { LastLocation, usePrevLocation } from './usePrevLocation';
import Acknowledgements from '~/pages/Acknowledgements';

function LoggedOutLayout() {
  return <Outlet />;
}

function LoggedOutAppLayout() {
  const location = useLocation();
  const prevLocation = usePrevLocation(location);

  return (
    <LastLocation.Provider value={prevLocation}>
      <Outlet />
    </LastLocation.Provider>
  );
}

export const appLoggedOutRoutes = [
  { path: '/download', element: <Download /> },
  { path: '/app/_/teams_auth_complete', element: <TeamsAuthCompletePage /> },
  { path: '/app/login/confirm', element: <LoginConfirm /> },
  { path: '/app/signup', element: <SignUp /> },

  {
    path: '/app/signup/create',
    element: <Navigate replace to={'/register'} />
  },

  { path: '/app/guest/shared-recording/:id', element: <RecordingPage /> },

  { path: '/app/acknowledgements', element: <Acknowledgements /> },

  { path: '/register', element: <Registration /> },

  { path: '/shared-recording/:id', element: <RecordingPage /> },
  { path: '/app/disabled-user', element: <DisabledUser /> }
];

export const loggedOutRoutes = [
  {
    element: <LoggedOutLayout />,
    children: [
      { element: <LoggedOutAppLayout />, children: appLoggedOutRoutes }
    ]
  }
];
