import uniqBy from 'lodash/uniqBy';
import {
  OptionsList,
  type Option
} from 'components/molecules/OptionsList/OptionsList';
import { useMemo } from 'react';
import { Icon, useAnalytics } from '@grain/grain-ui';
import {
  useCollections,
  useCollectionRemoveRecording,
  useCreateCollectionWithRecording,
  useCollectionAddRecording
} from '~/modules/collections/hooks';
import { cacheUpdateRecordingCollections } from '@grain/api/graphql/cache-helpers';
import { Collection } from '@grain/api/schema.generated';
import { useRecordingCollectionsUpdatedSubscription } from '@grain/api/graphql/subscriptions/subscriptions.generated';

const EMPTY_ARRAY: unknown[] = [];
const COLLECTIONS_DEFAULT_TITLE = 'Untitled Playlist';

type AddToCollectionDropdownProps = {
  recordingCollections: { id: string; title: string }[];
  recordingId: string;
};

const collectionToItem = ({ id, title }: { id: string; title: string }) => ({
  title,
  value: id
});

export const AddToCollectionDropdown = ({
  recordingCollections = EMPTY_ARRAY as { id: string; title: string }[],
  recordingId
}: AddToCollectionDropdownProps) => {
  const { trackEvent } = useAnalytics();
  const {
    collections,
    onSearch: onCollectionSearch,
    fetchMore
  } = useCollections();
  const removeRecordingFromCollection = useCollectionRemoveRecording();
  const createCollection = useCreateCollectionWithRecording();

  useRecordingCollectionsUpdatedSubscription({
    variables: { id: recordingId },
    onData: ({ data, client }) => {
      cacheUpdateRecordingCollections(client, {
        recordingId,
        collections: (data.data?.recordingUpdated?.collections ||
          []) as Collection[]
      });
    }
  });

  const collectionOptions = useMemo(() => {
    const defaultOptions = [
      {
        title: 'New playlist',
        value: 'new',
        icon: <Icon.DeprecatedPlus />
      }
    ];

    const allCollections = [...recordingCollections, ...collections].map(
      collectionToItem
    );

    const filteredOptions = uniqBy(allCollections, 'value');

    return [...defaultOptions, ...filteredOptions];
  }, [recordingCollections, collections]);

  const selectedCollections = useMemo(
    () => recordingCollections.map(collectionToItem),
    [recordingCollections]
  );

  const addRecordingToCollection = useCollectionAddRecording();

  const collectionAddRecording = async (collectionId: string) => {
    addRecordingToCollection(collectionId, recordingId);
  };

  const collectionRemoveRecording = (collectionId: string) => {
    const collection = recordingCollections.find(c => c.id === collectionId);
    removeRecordingFromCollection(collection?.id, recordingId);
  };

  const handleNewCollection = () => {
    trackEvent(
      'New Collection Clicked',
      {
        button_name: 'recording_page_new_collection',
        trigger: 'recording_page_new_collection_clicked'
      },
      ['user', 'workspace']
    );
    createCollection(COLLECTIONS_DEFAULT_TITLE, recordingId);
  };

  const handleCollectionChange = (values: Option[]) => {
    if (values.length === selectedCollections.length) return;

    if (values.length > selectedCollections.length) {
      const newCollection = values[values.length - 1];
      if (newCollection.value === 'new') {
        handleNewCollection();
      } else {
        collectionAddRecording(newCollection.value);
      }
    } else {
      const removedCollection = selectedCollections.find(
        item => !values.some(v => v.value === item.value)
      );
      if (removedCollection) {
        collectionRemoveRecording(removedCollection.value);
      }
    }
  };

  return (
    <OptionsList
      autoFocusInput
      numberOfSelectedItems={selectedCollections.length}
      onSelect={handleCollectionChange}
      options={collectionOptions}
      defaultValues={selectedCollections}
      placeholder='Find a playlist'
      isMultiSelect={true}
      showChecked={false}
      maxHeight={'200px'}
      fetchMore={fetchMore}
      onSearch={onCollectionSearch}
      usesServerSearch
      excludeSearchTerms={['new']}
    />
  );
};
