import { flex, Icon, spacing, color, spaces, Button } from '@grain/grain-ui';
import styled from '@emotion/styled';

const InstallButton = styled(Button)`
  height: ${spaces[8]};
`;

export default function TeamsAuthCompletePage() {
  return (
    <>
      <div css={['height:105px;', flex.alignItems.center, spacing.pl8]}>
        <a href='/'>
          <Icon.GrainLogoAndWord />
        </a>
      </div>
      <div css={[flex.center, spacing.g4]}>
        <div>
          <Icon.Logo />
        </div>
        <div>
          <Icon.ConnectionHorizontal
            css={`
              color: ${color.pigeon};
              width: ${spaces[4]};
            `}
          />
        </div>
        <div>
          <Icon.Teams css={['width: 45px; height: 45px;']} />
        </div>
      </div>
      <div css={['align-items:center;', flex.direction.column, spacing.mt2]}>
        <h1>Finish integration setup.</h1>
        <p css={[color.fg.crow]}>
          Please install the Grain app for Teams to complete the integration
          setup.
        </p>
        <div css={[`margin:${spaces[8]} 0;`]}>
          <InstallButton>Install Grain App for Teams</InstallButton>
        </div>
        <a css={[color.fg.crow, 'text-decoration: underline;']} href='/'>
          I’m done, take me to to Grain.com
        </a>
      </div>
    </>
  );
}
