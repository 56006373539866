import type { SubscriptionPlan } from '@grain/api/schema.generated';
import { PlanAction } from '~/modules/subscriptions/PlanCard';

type GetPlanActionArgs = {
  plan: Pick<SubscriptionPlan, 'isFree' | 'isPopular'>;
  isCurrentPlan: boolean;
  isOnFreePlan: boolean;
  isTrial: boolean;
  isPostTrial: boolean;
  canTrial: boolean;
};

export function getPlanAction({
  plan,
  isCurrentPlan,
  isOnFreePlan,
  isTrial,
  isPostTrial,
  canTrial
}: GetPlanActionArgs): PlanAction {
  // Unlike the "Upgrade plan" modal, which is only concerned with upgrading,
  // the Plans page has broader responsibilities.  Therefore we have the current
  // plan do an action unrelated to conversion.
  //
  // In the case of the Free plan, offer to invite more members.
  if (plan.isFree && isCurrentPlan) {
    return PlanAction.INVITE_TEAM;
  }

  // In the case of other plans, offer to view your current members.  The
  // exception is when on trial, because we haven't actually converted the user
  // onto the current plan yet.  For those trial users, the current plan will be
  // presented as an option to "upgrade" (complete the conversion).
  if (!plan.isFree && isCurrentPlan && !isTrial) {
    return PlanAction.VIEW_MEMBERS;
  }

  // We want to guide users into the trial.  Naturally we would only do that
  // pre-trial, and we wouldn't do it if they were already on the plan that is
  // able to be trialed (i.e. the popular plan).
  if (plan.isPopular && !isTrial && !isPostTrial && !isCurrentPlan) {
    // We want to allow users on qualified workspaces to self-initiate trials.
    if (canTrial) {
      return PlanAction.START_TRIAL;
    }

    // All other users need to request a trial, in which case they go through
    // Sales, which can determine whether the trial will suit their needs.
    return PlanAction.REQUEST_TRIAL;
  }

  // In other cases: on the Free plan, all other plans will be upgrade options.
  // The same goes for when you're on the trial (even the current plan is an
  // upgrade option).  The exception is the Free plan while on trial; the button
  // for the Free plan while on trial becomes a cancel button.
  if (isOnFreePlan || (!plan.isFree && isTrial)) {
    return PlanAction.UPGRADE_PLAN;
  }

  // The button for the Free plan while on a paid plan (or on the trial) becomes
  // a cancel button.
  if (plan.isFree) {
    return PlanAction.CANCEL_PLAN;
  }

  // The final case is that you're on a paid plan, in which case the other plan
  // options are going to be presented as alternative plan options to which you
  // can change.
  return PlanAction.CHANGE_PLAN;
}
