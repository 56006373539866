import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { colors } from '@grain/styles/constants';
import {
  Icon,
  NoteTemplateListDropdown,
  SelectorDropdownButton,
  WorkspaceAccessDropdown,
  color,
  useAnalytics,
  useChangeSettings,
  useConfirm
} from '@grain/grain-ui';

import { calendarEventWorkspaceSharedOverrideSetMutation } from '@grain/api/graphql/mutations';
import Editor, { UsersBar } from '../../components/Notepad';
import { useDesktop } from 'providers/DesktopProvider';

import Header from './Header';
import { useMyself, useWorkspace } from '@grain/api/auth';
import Dropdown, { MenuItem } from '@grain/components/Dropdown';
import { IS_WINDOWS } from 'desktop/lib/constants';
import {
  StyledLoadingIcon,
  StyledLoadingIconContainer,
  StyledMoreMenuButton,
  StyledRecordingActionContainer,
  StyledRecordingInfoContainer,
  StyledStopButton,
  StyledTopContainer,
  StyledWindow
} from './styles';
import {
  ActiveMeeting,
  ActiveRecording
} from '@grain/components/ActiveRecordingsContext';
import { Channel, Presence } from 'phoenix';
import { RecordingPresence } from 'desktop/components/Notepad/utils';
import { DesktopManagerChannelMessage } from 'desktop/lib/windowEvents';
import { useIntelligenceTemplatesQuery } from '@grain/api/graphql/modules/templates.generated';
import { IntelligenceTemplate } from '@grain/api/schema.generated';
import { useRecordingIntelligenceTemplateSetMutation } from '@grain/api/graphql/queries/notesTemplate.generated';
import { useMutation } from '@grain/api/graphql';

export default function Notepad() {
  const { desktopWindow, recordingContext, botContext, webSocketContext } =
    useDesktop();
  const { workspace } = useWorkspace();

  const { recordings, meetings, setMeetings } = recordingContext;

  const showConfirm = useConfirm();

  const { stopRecording } = botContext;
  // Used to prevent recording being auto opened multiple times
  const [stopping, setStopping] = React.useState(false);
  const { recordingChannel } = webSocketContext;
  const { myself } = useMyself({ fetchPolicy: 'cache-and-network' });

  const { trackEvent } = useAnalytics();

  const [changeSettings] = useChangeSettings();
  const [activeRecording, setActiveRecording] =
    React.useState<ActiveRecording>();
  const [activeMeeting, setActiveMeeting] = React.useState<ActiveMeeting>();

  React.useEffect(() => {
    if (!recordings.length || recordings[0]?.id === activeRecording?.id) return;
    const recording = recordings[0];
    setActiveRecording(recording);
  }, [activeRecording?.id, recordings]);

  React.useEffect(() => {
    if (!meetings.length || meetings[0] === activeMeeting) return;
    const meeting = meetings[0];
    setActiveMeeting(meeting);
  }, [activeMeeting, meetings]);

  React.useEffect(() => {
    if (!desktopWindow || !recordingChannel) return;

    const handler = (event: DesktopManagerChannelMessage) => {
      if (
        event.type === 'window-visibility-change' &&
        event.window === 'notepad'
      ) {
        recordingChannel.pushMessage(
          'in_recording',
          { status: event.isVisible },
          { ignoreResponse: true }
        );
      }
    };

    desktopWindow.channel.addEventListener('message', handler);

    return () => {
      desktopWindow.channel.removeEventListener('message', handler);
    };
  }, [desktopWindow, recordingChannel]);

  const handleTrackClickEvent = useCallback(
    (button: string, args = {}) => {
      trackEvent(
        'Notepad Button Clicked',
        {
          button,
          recording_id: activeRecording?.id,
          ...args
        },
        ['user', 'workspace']
      );
    },
    [activeRecording?.id, trackEvent]
  );

  const [recordingPresence, setRecordingPresence] =
    React.useState<RecordingPresence>([]);
  const recordingId = activeRecording?.id;
  useEffect(() => {
    recordingId &&
      recordingChannel.pushMessage(
        'in_recording',
        { status: true },
        { ignoreResponse: true }
      );
  }, [recordingId, recordingChannel]);

  React.useEffect(() => {
    if (!recordingId) return;

    const onRecordingChannelConnect = ({
      channel,
      _log
    }: {
      channel: Channel;
      _log: (message: string, ...args: unknown[]) => void;
    }) => {
      if (!channel) {
        return;
      }
      const presence = new Presence(channel);

      presence.onSync(() => {
        const list = presence.list((id, { metas }) => {
          const first = metas[0];
          return { ...first, id };
        });
        _log('presence.sync()', list);
        setRecordingPresence(list);
      });
    };

    recordingChannel.connect(`recording:${recordingId}`);

    const unlisteners = [recordingChannel.onConnect(onRecordingChannelConnect)];

    return () => unlisteners.forEach(fn => fn());
  }, [recordingChannel, recordingId, setRecordingPresence]);

  function openQuickTagsEditTab() {
    desktopWindow.sendEvent({
      type: 'open-url',
      window: 'main',
      url: 'app/settings/account?tab=quickTags'
    });
  }

  function handleStopRecording() {
    showConfirm({
      confirmButtonType: 'danger',
      confirmContent: 'Stop Recording',
      description: '',
      title: 'Are you sure you want to stop recording?',
      cancelButtonType: 'dark',
      cancelContent: 'Cancel',
      width: 269,
      height: 160,
      closable: false,
      onConfirm: async () => {
        setStopping(true);
        stopRecording({ recordingId: activeRecording?.id });
      },
      onCancel: () => null as null,
      overlayProps: {
        style: {
          background: colors.raven,
          boxShadow:
            '0px 0px 20px 0px rgba(0, 0, 0, 0.15), 0px 20px 30px 8px rgba(0, 0, 0, 0.25)'
        }
      },
      css: css`
        background: none;
        color: ${colors.swan};
        border: none;
        border-radius: 8px;
        box-shadow: none;
      `
    });
  }

  const [isWorkspaceAccessOpen, setIsWorkspaceAccessOpen] = useState(false);
  const [isTemplateSelectorOpen, setIsTemplateSelectorOpen] = useState(false);
  const { data: intelligenceTemplatesData } = useIntelligenceTemplatesQuery();
  const meetingTemplateList = useMemo(
    () =>
      (intelligenceTemplatesData?.intelligenceTemplates ??
        []) as IntelligenceTemplate[],
    [intelligenceTemplatesData]
  );

  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null
  );

  const defaultTemplate = activeMeeting?.internal_event
    ? workspace?.internalRecordingIntelligenceTemplateV2
    : workspace?.externalRecordingIntelligenceTemplateV2;
  useEffect(() => {
    const newTemplateId =
      activeRecording?.intelligence_template_id || defaultTemplate?.id || null;

    setSelectedTemplateId(templateId =>
      templateId !== newTemplateId ? newTemplateId : templateId
    );
  }, [
    activeRecording?.intelligence_template_id,
    defaultTemplate,
    setSelectedTemplateId
  ]);

  const selectedTemplate = meetingTemplateList.find(
    template => template.id === selectedTemplateId
  );

  const [setRecordingTemplate] = useRecordingIntelligenceTemplateSetMutation();
  const handleChangeTemplate = useCallback(
    (templateId: string | null) => {
      if (activeRecording?.id) {
        setRecordingTemplate({
          variables: {
            recordingId: activeRecording.id,
            intelligenceTemplateId: templateId,
            eventProperties: JSON.stringify({
              trigger: 'notepad_window'
            })
          }
        });
        setSelectedTemplateId(templateId);
      }
      handleTrackClickEvent('template_change', {
        template_id: templateId
      });
    },
    [activeRecording?.id, handleTrackClickEvent, setRecordingTemplate]
  );

  const shareSetting = useMemo(() => {
    if (
      (myself?.settings?.autoShareExternalRecordings === null &&
        myself?.settings?.autoShareInternalRecordings === null) ||
      !workspace?.autoShareRecordingsOverridable
    ) {
      return [
        workspace?.autoShareExternalRecordings,
        workspace?.autoShareInternalRecordings
      ];
    }

    return [
      myself?.settings?.autoShareExternalRecordings,
      myself?.settings?.autoShareInternalRecordings
    ];
  }, [
    myself?.settings?.autoShareExternalRecordings,
    myself?.settings?.autoShareInternalRecordings,
    workspace?.autoShareExternalRecordings,
    workspace?.autoShareInternalRecordings,
    workspace?.autoShareRecordingsOverridable
  ]);

  const workspaceAccess = Boolean(
    activeMeeting?.workspace_shared_override ??
      ((activeMeeting?.internal_event && shareSetting[1]) ||
        (!activeMeeting?.internal_event && shareSetting[0]))
  );

  const showWorkspaceAccess = Boolean(
    activeMeeting?.id && activeMeeting?.calendar_event_id
  );

  const [calendarEventWorkspaceSharedOverrideSet] = useMutation(
    calendarEventWorkspaceSharedOverrideSetMutation
  );

  const meetingWorkspaceSharedOverrideSet = useCallback(
    (workspaceSharedOverride: boolean) => {
      if (!activeMeeting?.id) return;
      setMeetings(meetings =>
        meetings.map(other =>
          other.id === activeMeeting.id
            ? {
                ...other,
                workspace_shared_override: workspaceSharedOverride
              }
            : other
        )
      );
    },
    [activeMeeting?.id, setMeetings]
  );

  const handleChangeWorkspaceAccess = useCallback(
    (workspaceSharedOverride: boolean) => {
      calendarEventWorkspaceSharedOverrideSet({
        variables: {
          workspaceSharedOverride,
          eventId: activeMeeting?.calendar_event_id
        }
      });
      meetingWorkspaceSharedOverrideSet(workspaceSharedOverride);
      handleTrackClickEvent('workspace_access', {
        workspace_shared_override: workspaceSharedOverride
      });
    },
    [
      activeMeeting?.calendar_event_id,
      calendarEventWorkspaceSharedOverrideSet,
      handleTrackClickEvent,
      meetingWorkspaceSharedOverrideSet
    ]
  );

  if (!activeRecording) return null;

  return (
    <StyledWindow>
      {activeRecording && (
        <Header
          title={activeRecording.title}
          actionBar={
            <>
              {['BOT', 'ZOOM_BOT', 'TEAMS', 'MEET'].includes(
                activeRecording.source
              ) &&
                (stopping ? (
                  <StyledLoadingIconContainer isWindows={IS_WINDOWS}>
                    <StyledLoadingIcon color={colors.pigeon} strokeWidth={3} />
                  </StyledLoadingIconContainer>
                ) : (
                  <StyledStopButton
                    isWindows={IS_WINDOWS}
                    onClick={() => {
                      handleStopRecording();
                      handleTrackClickEvent('stop_recording');
                    }}
                  >
                    Stop
                  </StyledStopButton>
                ))}
              <Dropdown
                placement={IS_WINDOWS ? 'bottom-end' : 'bottom-start'}
                arrow={false}
                offset={[0, 4]}
                targetElement={
                  <StyledMoreMenuButton isWindows={IS_WINDOWS}>
                    <Icon.MenuMore />
                  </StyledMoreMenuButton>
                }
              >
                <MenuItem
                  onClick={() => {
                    openQuickTagsEditTab();
                    handleTrackClickEvent('quick_tags_edit');
                  }}
                >
                  Customize quick tags
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (myself?.settings) {
                      changeSettings({
                        showReactionBar: !myself.settings.showReactionBar
                      });
                    }
                    handleTrackClickEvent('quick_tags_toggle_hide_show');
                  }}
                >
                  {myself?.settings &&
                    (myself.settings.showReactionBar
                      ? 'Hide quick tags'
                      : 'Show quick tags')}
                </MenuItem>
              </Dropdown>
            </>
          }
        />
      )}
      <StyledTopContainer>
        <StyledRecordingActionContainer>
          <NoteTemplateListDropdown
            templateList={meetingTemplateList}
            selectedTemplateId={selectedTemplateId}
            onSelect={handleChangeTemplate}
            onShow={() => setIsTemplateSelectorOpen(true)}
            onHide={() => setIsTemplateSelectorOpen(false)}
            placement='bottom'
          >
            <SelectorDropdownButton
              variant='stealth'
              label='Template'
              value={
                selectedTemplate?.title && selectedTemplate?.title?.length > 20
                  ? `${selectedTemplate.title.slice(0, 20)}...`
                  : selectedTemplate?.title || 'Select template'
              }
              isOpen={isTemplateSelectorOpen}
              css={css`
                padding: 6px 8px 6px 8px;
                margin-left: -8px;
                background: transparent;
                ${color.fg.swan};
                :hover {
                  ${color.bgImportant.dipper};
                  ${color.fg.swan};
                }
              `}
            />
          </NoteTemplateListDropdown>
          {showWorkspaceAccess && (
            <WorkspaceAccessDropdown
              workspaceAccess={workspaceAccess}
              onSelect={handleChangeWorkspaceAccess}
              onShow={() => setIsWorkspaceAccessOpen(true)}
              onHide={() => setIsWorkspaceAccessOpen(false)}
              placement='bottom'
              offset={[44, 0]}
            >
              <SelectorDropdownButton
                variant='stealth'
                label='Workspace access'
                value={workspaceAccess ? 'On' : 'Off'}
                isOpen={isWorkspaceAccessOpen}
                css={css`
                  padding: 6px 8px 6px 8px;
                  margin-left: -8px;
                  background: transparent;
                  ${color.fg.swan};
                  :hover {
                    ${color.bgImportant.dipper};
                    ${color.fg.swan};
                  }
                `}
              />
            </WorkspaceAccessDropdown>
          )}
        </StyledRecordingActionContainer>
        <StyledRecordingInfoContainer>
          <UsersBar
            css={['height: 32px;']}
            recordingPresence={recordingPresence}
          />
        </StyledRecordingInfoContainer>
      </StyledTopContainer>
      <Editor
        recordingPresence={recordingPresence}
        recordingId={activeRecording?.id}
        handleTrackClickEvent={handleTrackClickEvent}
      />
    </StyledWindow>
  );
}
