import styled from '@emotion/styled';
import { color, colors, spacing } from '@grain/styles/constants';
import {
  flex,
  resetButtonStyles,
  Icon,
  pxToRem,
  hexToRgba
} from '@grain/grain-ui';

export const StyledTagManagementButton = styled.button`
  ${resetButtonStyles};
  padding: 6px 20px;
  border-radius: 100px;
  ${spacing.mr4};
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(20)};
  font-weight: 500;
  ${color.swan};
  ${flex.alignItems.center};
  cursor: pointer;
  user-select: none;
  background-color: ${hexToRgba(colors.raven, 0.7)};
  transition: background-color 0.2s;

  :hover {
    background-color: ${hexToRgba(colors.raven, 1)};
  }
`;

export const StyledChevronIcon = styled(Icon.ChevronDown)`
  width: 12px;
  height: 12px;
  ${color.pigeon};
`;

export const StyledTagCount = styled.span`
  ${spacing.mx2};
  ${color.pigeon};
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(20)};
  font-weight: 500;
`;
