import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import {
  TopicCard,
  NoTopics,
  PageHeader,
  PageTitle,
  Icon,
  Button,
  useShowToast,
  ButtonIcon,
  color,
  font,
  spacing,
  InputText,
  Skeleton
} from '@grain/grain-ui';
import { Menu, TextLabel } from '@grain/grain-ui/v4';
import { StyledContentWrapper, StyledPageHeader } from '../../SettingsWrapper';
import { Description } from '../styles';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import { SlackModal } from '~/pages/Settings/Integrations/providers/Slack/SlackModal';
import { useActiveIntegrationsQuery } from '@grain/api/graphql/queries/integrations.generated';
import { css } from '@emotion/react';
import { SlackAutomationFragment } from '~/modules/integrations/slack/slack.generated';
import {
  useSlackAutomationsSubscriptionQuery,
  useSlackRedirect
} from '~/modules/integrations/slack/hooks';
import type { SmartTopicV2 } from '@grain/api/schema.extra';

import { useSmartTopicsFtux } from './useSmartTopicsFtux';
import { useLocalStorage } from 'react-use';
import {
  SmartTagMatchResults,
  useSmartTagFiltersState
} from '~/modals/SmartTagMatchResults';

export type ViewTopicsProps = {
  topics: SmartTopicV2[];
  onEdit: (id?: string) => void;
  onDelete: (id: string) => void;
  onAdd: () => void;
  isLoading: boolean;
};

const TopicContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ViewTopics = ({
  topics,
  isLoading,
  onEdit,
  onDelete,
  onAdd
}: ViewTopicsProps) => {
  const [filterName, setFilterName] = useState('');
  const [smartTagFilters, setSmartTagFilters] = useSmartTagFiltersState();
  const showToast = useShowToast();
  const { data } = useActiveIntegrationsQuery();
  const { data: slackAutomations } = useSlackAutomationsSubscriptionQuery();
  const navigate = useNavigate();
  const connectSlackLink = useSlackRedirect();
  // Used for knowing current smart topic for creating slack automation
  const [activeSmartTopic, setActiveSmartTopic] = useState<SmartTopicV2 | null>(
    null
  );
  const [
    pendingSlackAuthorizationSmartTopic,
    setPendingSlackAuthorizationSmartTopic
  ] = useLocalStorage<SmartTopicV2 | null>(
    'pendingSlackAuthorizationSmartTopic',
    null
  );

  const { createTagRef, manageTagRef, notifiedRef } = useSmartTopicsFtux();

  // This map is used for determining automations created for a specific smart topic
  // The key in the map is the tag id
  // The value is an array of automations that belong to that tag, used for display purposes.
  const slackAutomationTagMap = useMemo(() => {
    if (slackAutomations?.slackAutomation?.__typename === 'SlackIntegration') {
      return (slackAutomations?.slackAutomation?.automations || []).reduce(
        (acc, automation) => {
          automation.view.referencedSmartTopics.forEach(smartTopic => {
            const tagId = smartTopic.tag.id;
            if (!acc[tagId]) acc[tagId] = [];
            acc[tagId].push(automation);
          });

          return acc;
        },
        {} as Record<string, SlackAutomationFragment[]>
      );
    }

    return {};
  }, [slackAutomations]);

  const smartTopicsWithAutomations = useMemo(() => {
    // Remove any dashes for easier search
    const cleanFilterName = filterName.toLowerCase().replace(/-/g, ' ');

    return topics
      .filter(topic => {
        return (
          !filterName ||
          topic.tag.text
            .toLocaleLowerCase()
            .replace(/-/g, ' ') // Remove any dashes for easier search
            .includes(cleanFilterName)
        );
      })
      .map(topic => ({
        ...topic,
        slackAutomationCount: slackAutomationTagMap[topic.id]?.length || 0
      }));
  }, [topics, slackAutomationTagMap, filterName]);

  const smartTopicsSkeleton = () => {
    return Array.from({ length: 5 }).map((_, i) => (
      <div
        css={css`
          > div {
            border-radius: 8px;
            width: 100%;
          }
        `}
      >
        <Skeleton
          baseColor={color.gull}
          shimmerColor={color.swan}
          key={i}
          height={102}
        />
      </div>
    ));
  };

  const hasSlackIntegration = useMemo(() => {
    return (data?.activeIntegrations || []).some(
      integration => integration.__typename === 'SlackIntegration'
    );
  }, [data]);

  useEffect(() => {
    if (pendingSlackAuthorizationSmartTopic && hasSlackIntegration) {
      setActiveSmartTopic(pendingSlackAuthorizationSmartTopic);
      setPendingSlackAuthorizationSmartTopic(null);
    }
  }, [
    pendingSlackAuthorizationSmartTopic,
    hasSlackIntegration,
    setPendingSlackAuthorizationSmartTopic
  ]);

  const filterTopic = (topic: SmartTopicV2) => {
    setSmartTagFilters({
      smart_tags: [{ value: topic.tag.id, label: topic.tag.text }]
    });
  };

  const onCreateNotificationCompleted = useCallback(() => {
    showToast({
      actionProps: {
        onClick: () => {
          navigate('/app/settings/integrations?tab=slack');
        }
      },
      action: 'Manage',
      content: 'Slack notification created.',
      type: 'success',
      showCloseButton: true
    });
  }, [showToast, navigate]);

  const hasActiveSmartTopic = !!activeSmartTopic;

  return (
    <>
      <StyledPageHeader isTopic sticky>
        <PageHeader.Left>
          <PageTitle hasSubTitle>
            <div className='page-name'>Workspace</div>
            <div>Trackers</div>
          </PageTitle>
        </PageHeader.Left>
        <PageHeader.Right>
          <Button
            ref={createTagRef}
            variant='secondary'
            onClick={onAdd}
            icon={<Icon.DeprecatedPlus />}
          >
            New tracker
          </Button>
        </PageHeader.Right>
      </StyledPageHeader>
      <StyledContentWrapper isTopic>
        <Description>
          Trackers mark critical words, phrases, or concepts in your meeting
          transcripts. Use them to track and be alerted of important topics.{' '}
          <Link
            target='_blank'
            rel='noreferrer noopener'
            to='https://support.grain.com/en/articles/8892020-how-to-create-trackers'
          >
            Learn more.
          </Link>
        </Description>
        <div css={[spacing.mb6, 'position: relative;']}>
          <InputText
            value={filterName}
            onChange={e => setFilterName(e.currentTarget.value)}
            placeholder='Filter by name'
            css={['max-width: 200px']}
            onClear={() => setFilterName('')}
          />
        </div>
        <TopicContainer>
          {smartTopicsWithAutomations.length === 0 && filterName && (
            <div css={['text-align: center', spacing.py5, font.v4.b2[600]]}>
              No trackers match your filters
            </div>
          )}
          {smartTopicsWithAutomations.map((topic, i) => (
            <TopicCard
              menuRef={i === 0 ? manageTagRef : undefined}
              key={topic.id}
              topic={topic}
              onEdit={onEdit}
              onDelete={onDelete}
              filterTopic={filterTopic}
              notificationMenu={
                <Menu
                  tippyProps={{
                    disabled: hasSlackIntegration
                  }}
                  content={
                    <>
                      <TextLabel
                        css={css`
                          padding: 6px 8px;
                        `}
                        startIcon={Icon.Slack}
                        label='Connect Slack'
                        onClick={() => {
                          setPendingSlackAuthorizationSmartTopic(topic);
                          window.location.href = connectSlackLink;
                        }}
                      >
                        Connect Slack
                      </TextLabel>
                      <div
                        css={css`
                          padding: 6px 8px;
                          ${font.v4.b4[400]};
                          text-align: left;
                          max-width: 246px;
                        `}
                      >
                        Connect Slack to create a notification for this tracker.
                      </div>
                    </>
                  }
                >
                  <ButtonIcon
                    ref={i === 0 ? notifiedRef : undefined}
                    variant='stealth'
                    onClick={e => {
                      e.stopPropagation();
                      if (hasSlackIntegration) {
                        setActiveSmartTopic(topic);
                      }
                    }}
                  >
                    <Icon.BellPlus />
                  </ButtonIcon>
                </Menu>
              }
            />
          ))}
          {isLoading && smartTopicsSkeleton()}
          {topics.length === 0 && !isLoading && <NoTopics />}
        </TopicContainer>
      </StyledContentWrapper>
      {hasActiveSmartTopic && (
        <SlackModal
          filtersList={[
            'tags',
            'smart_tags',
            'owner',
            'people',
            'groups',
            'meeting_type'
          ]}
          readOnlyFilters={['smart_tags']}
          initialFilters={{
            smart_tags: [
              { id: activeSmartTopic.tag.id, text: activeSmartTopic.tag.text }
            ]
          }}
          onCompleted={onCreateNotificationCompleted}
          close={() => {
            setActiveSmartTopic(null);
            setSmartTagFilters(null);
          }}
        />
      )}
      <Suspense fallback={null}>
        {smartTagFilters && !hasActiveSmartTopic && (
          <SmartTagMatchResults
            filters={smartTagFilters}
            onClose={() => setSmartTagFilters(null)}
            eventSource='settings_page'
          />
        )}
      </Suspense>
    </>
  );
};
