import { MenuButton, Input } from '@grain/grain-ui/v4';
import { css } from '@emotion/react';
import { DateFilter } from '../filters/useDateFilter';

type DateMenuListProps = {
  filter: DateFilter;
};

export const DateMenuList = ({ filter }: DateMenuListProps) => {
  return (
    <>
      <Input
        css={css`
          margin-bottom: 4px;
        `}
        placeholder='Date'
        value={''}
        onChange={() => {}} // TODO
      />
      {filter.options.map(option => (
        <MenuButton
          key={option.value}
          label={option.label}
          onClick={() => {
            filter.setSelectedOption(option);
            if (option?.getValue) {
              const [start, end] = option.getValue();
              filter.toggle([start, end]);
            } else {
              filter.setShowPicker(true);
            }
          }}
        />
      ))}
    </>
  );
};
