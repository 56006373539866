// @ts-strict-ignore
import { useEffect, useState } from 'react';
import {
  PageHeader,
  PageTitle,
  Icon,
  color,
  PageHeaderSection,
  Ask
} from '@grain/grain-ui';
import { Helmet } from 'react-helmet';
import { Wrapper, Content } from '~/components/Layout';
import styled from '@emotion/styled';
import { useIntelligenceQueryLazyQuery } from './ask.generated';

export const StyledPageHeaderSection = styled(PageHeaderSection)`
  margin-bottom: 0;
`;

const PageDescription = styled.div`
  margin: 40px auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  ${color.fg.pigeon};
`;

export default function AskPage() {
  const [query, setQuery] = useState('');

  const [loadIntelligenceQuery, { loading, data }] =
    useIntelligenceQueryLazyQuery({
      variables: { query }
    });

  useEffect(() => {
    if (!query) return;

    loadIntelligenceQuery();
  }, [query, loadIntelligenceQuery]);

  return (
    <>
      <Helmet title='Ask' />
      <Wrapper withSidebar>
        <Content>
          <PageHeader>
            <StyledPageHeaderSection>
              <PageHeader.Left>
                <Icon.AskActive
                  css={['margin-right: 12px;', `color: ${color.graieen}`]}
                />
                <PageTitle>Ask</PageTitle>
              </PageHeader.Left>
            </StyledPageHeaderSection>
          </PageHeader>
          <div css={['margin: 24px auto; max-width: 700px; width: 100%;']}>
            <Ask
              preview={{
                responses: data?.intelligenceQuery?.responses
              }}
              onSubmit={setQuery}
              isLoading={loading}
            />
          </div>
        </Content>
        {!loading && !data?.intelligenceQuery && (
          <PageDescription>
            Ask may occasionally yield inaccurate information. If precise
            answers are needed, we recommend that you refer to source meetings.
          </PageDescription>
        )}
      </Wrapper>
    </>
  );
}
