// @ts-strict-ignore
import { useCallback } from 'react';
import {
  type BotAction,
  type IndicatorIconography,
  type BotStateDetails,
  Button,
  color,
  getBotStateIndicator,
  Icon,
  LinkButton,
  Spinner
} from '@grain/grain-ui';
import type { ActiveMeeting } from '@grain/components/ActiveRecordingsContext';
import { useBot } from '~/components/StartBot';
import { StyledIndicator, StyledIndicatorColumn } from './styles';

type BotStateIndicatorProps = {
  meeting?: ActiveMeeting;
  openPasscodeModal?: () => void;
  botState: BotStateDetails;
};

export function BotStateIndicator({
  meeting,
  openPasscodeModal,
  botState
}: BotStateIndicatorProps) {
  const indicator = getBotStateIndicator(botState);
  return <Indicator {...{ meeting, openPasscodeModal, ...indicator }} />;
}

// There is only one possible warning so far, but this type is meant to be
// extensible; it could be made a union if there were more warnings:
// type Warning = 'external_host' | 'foo' | 'bar' | ...;
export type Warning = 'external_host';

const LOCAL_RECORDING_DISABLED_HELP_URL =
  'https://support.grain.com/en/articles/9105489-what-zoom-meeting-hosts-need-for-grain-to-record';

const WARNINGS: Record<Warning, IndicatorProps> = {
  external_host: {
    iconography: 'warning',
    message:
      'External host - they must have Zoom Local Recording turned on in order for you to record.',
    action: { action: 'learn_more', url: LOCAL_RECORDING_DISABLED_HELP_URL }
  }
};

type WarningIndicatorProps = {
  warning: Warning;
};

export function WarningIndicator({ warning }: WarningIndicatorProps) {
  return <Indicator {...WARNINGS[warning]} />;
}

const SUPPORT_URL = 'mailto:help@grain.com';

type IndicatorProps = {
  meeting?: ActiveMeeting;
  openPasscodeModal?: () => void;
  iconography: IndicatorIconography;
  message: string;
  action: BotAction;
};

const EMPTY_OBJECT = {};

function Indicator({
  meeting,
  openPasscodeModal,
  iconography,
  message,
  action = EMPTY_OBJECT as BotAction
}: IndicatorProps) {
  const { retryRecording, cancelJoin } = useBot();

  const handleRetry = useCallback(() => {
    retryRecording({ link: meeting?.url });
  }, [retryRecording, meeting]);

  const handleCancel = useCallback(() => {
    cancelJoin(meeting?.id);
  }, [cancelJoin, meeting]);

  return (
    <StyledIndicator>
      <StyledIndicatorColumn>
        {iconography === 'warning' ? (
          <Icon.Important
            css={[`width: 20px; height: 20px; color: ${color.oriole}`]}
          />
        ) : iconography === 'error' ? (
          <Icon.Alert
            css={[`width: 20px; height: 20px; color: ${color.oriole}`]}
          />
        ) : iconography === 'spinner' ? (
          <Spinner size={20} color={color.graieen} />
        ) : null}
        <span>{message}</span>
      </StyledIndicatorColumn>
      <StyledIndicatorColumn>
        {action.action === 'retry' ? (
          <Button variant='stealth' onClick={handleRetry}>
            Retry
          </Button>
        ) : action.action === 'contact_support' ? (
          <LinkButton
            variant='stealth'
            to={SUPPORT_URL}
            target='_blank'
            rel='noopener noreferrer'
          >
            Contact support
          </LinkButton>
        ) : action.action === 'learn_more' ? (
          <LinkButton
            variant='stealth'
            to={action.url}
            target='_blank'
            rel='noopener noreferrer'
          >
            Learn more
          </LinkButton>
        ) : action.action === 'cancel' ? (
          <Button variant='stealth' onClick={handleCancel}>
            Cancel
          </Button>
        ) : action.action === 'enter_meeting_passcode' ? (
          <Button variant='stealth' onClick={openPasscodeModal}>
            Enter password
          </Button>
        ) : null}
      </StyledIndicatorColumn>
    </StyledIndicator>
  );
}
