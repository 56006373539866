import styled from '@emotion/styled';
import { Button, theme } from '@grain/grain-ui/v4';
import { DealDiagnosisCompletenessIcon } from './DealDiagnosisCompletenessIcon';
import { CompletenessStatus } from '@grain/api/schema.generated';
import { Link } from 'react-router-dom';
import { useAnalytics } from '@grain/grain-ui';

type DealDiagnosisSetupMiniBannerProps = {
  onSelect: () => void;
};

const BannerContainer = styled.div`
  display: flex;
  ${theme.utils.px('md')}
  ${theme.utils.py('lg')}
  gap: ${theme.tokens.spacing.sm};
  border: 1px solid ${theme.tokens.color.borderTertiary};
  border-radius: ${theme.tokens.radii.xl};
  align-items: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${theme.tokens.spacing.md};
`;

const OverlappingIcon = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-of-type) {
    margin-left: -4px;
  }
  svg {
    width: 16px;
    height: 16px;
  }
`;

const TextContent = styled.div`
  ${theme.tokens.typography.b4[500]};
  margin-right: ${theme.tokens.spacing.xs};
`;

const SecondaryTextContent = styled.div`
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textTertiary};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${theme.tokens.spacing.sm};
`;

export const DealDiagnosisSetupMiniBanner = ({
  onSelect
}: DealDiagnosisSetupMiniBannerProps) => {
  const { trackEvent } = useAnalytics();

  const handleLearnMore = () => {
    trackEvent('Deal Diagnosis Learn More Clicked');
  };
  return (
    <BannerContainer>
      <ContentWrapper>
        <IconWrapper>
          <OverlappingIcon>
            <DealDiagnosisCompletenessIcon status={CompletenessStatus.None} />
          </OverlappingIcon>
          <OverlappingIcon>
            <DealDiagnosisCompletenessIcon
              status={CompletenessStatus.Partial}
            />
          </OverlappingIcon>
          <OverlappingIcon>
            <DealDiagnosisCompletenessIcon
              status={CompletenessStatus.Complete}
            />
          </OverlappingIcon>
        </IconWrapper>
        <TextContent>Automate your sales methodology</TextContent>
        <SecondaryTextContent>SPICED, MEDDICC&hellip;</SecondaryTextContent>
      </ContentWrapper>
      <ButtonsWrapper>
        <Button
          onClick={handleLearnMore}
          css={{
            '&:hover': {
              textDecoration: 'none'
            }
          }}
          as={Link}
          to='https://support.grain.com/en/articles/9931236-how-automated-spiced-analysis-can-improve-your-team-s-deal-performance'
          variant='ghost'
          rel='noopener noreferrer'
        >
          Learn more
        </Button>
        <Button variant='stroked' onClick={onSelect}>
          Get started
        </Button>
      </ButtonsWrapper>
    </BannerContainer>
  );
};
