import { isDesktopApp } from '@grain/desktop-lib';
import { getDesktopUrl } from 'desktop/lib/utils';
import { getOauthRedirectUrl } from '@grain/grain-ui';

export const getConnectSalesforceHref = (getNextHref: () => string) => {
  const nextHref = new URL(getNextHref(), window.location.origin);
  const sfPath = `${nextHref.pathname}${nextHref.search}`;
  const salesforceRedirectTo = isDesktopApp ? getDesktopUrl(sfPath) : sfPath;
  return getOauthRedirectUrl(
    'salesforce',
    true,
    'connect_account',
    {},
    salesforceRedirectTo
  );
};

export const getConnectHubspotHref = (getNextHref: () => string) => {
  const nextHref = new URL(getNextHref(), window.location.origin);
  const hsHref = new URL(
    '/app/_/redirect/hubspot?enable=automation',
    window.location.origin
  );
  hsHref.searchParams.set(
    'redirect_to',
    `${nextHref.pathname}${nextHref.search}`
  );
  const hsPath = `${hsHref.pathname}${hsHref.search}`;
  const hubspotHref = isDesktopApp ? getDesktopUrl(hsPath) : hsPath;
  return hubspotHref;
};
