import React from 'react';
import styled from '@emotion/styled';

import { flex, pxToRem } from '@grain/grain-ui';
import { theme } from '@grain/grain-ui/v4';

import { DealDetailsFragment, DealEmailFragment } from '../../deal.generated';
import { getActivityTimestamp } from '../../utils';

import { DealEmailModal } from '../DealEmailModal';
import { ItemBorder, formatTime } from './Timeline';

const StyledHeader = styled.div`
  ${flex.direction.column};
  gap: ${theme.tokens.spacing['2xs']};
`;

const StyledTitle = styled.div`
  ${flex.alignItems.flexEnd};
  gap: ${theme.tokens.spacing.sm};
`;

const StyledBody = styled.div`
  ${theme.tokens.typography.b2[400]};
  line-height: ${pxToRem(20)};
  margin: 0;
  white-space: preserve-breaks;
`;

const StyledSubtitle = styled.div`
  ${theme.tokens.typography.c1[500]};
  color: ${theme.tokens.color.textSecondary};
`;

const StyledSender = styled.span`
  ${theme.tokens.typography.b3[600]};
`;

const StyledTime = styled.span`
  ${theme.tokens.typography.c1[500]};
  color: ${theme.tokens.color.textSecondary};
`;

const StyledMore = styled.a`
  color: ${theme.tokens.color.textTertiary};
  cursor: pointer;
`;

type DealEmailProps = { deal: DealDetailsFragment; email: DealEmailFragment };
export const DealEmail = ({ deal, email }: DealEmailProps) => {
  const [openModal, setOpenModal] = React.useState(false);

  const readMore = React.useCallback(() => {
    setOpenModal(true);
  }, []);

  return (
    <ItemBorder>
      <StyledHeader>
        <StyledTitle>
          <StyledSender>{email.from?.name}</StyledSender>
          <StyledTime>
            {formatTime(getActivityTimestamp(email) ?? '')}
          </StyledTime>
        </StyledTitle>
        <StyledSubtitle>{email.subject}</StyledSubtitle>
      </StyledHeader>
      <StyledBody>
        {email.preview}
        {email.previewTruncated && (
          <>
            {' '}
            <StyledMore onClick={() => readMore()}>More</StyledMore>
          </>
        )}
      </StyledBody>
      {openModal && (
        <DealEmailModal
          deal={deal}
          email={email}
          onCancel={() => setOpenModal(false)}
        />
      )}
    </ItemBorder>
  );
};
