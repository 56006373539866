import { RECORD_MEETINGS_GATE_ROUTE_ID } from '@grain/components/modals/constants';
import { useRouteModal } from '@grain/grain-ui';
import { GatedUpgradeModal } from '~/modules/gates/GatedUpgradeModal';

export default function RecordMeetingsGateModal() {
  const { close } = useRouteModal(RECORD_MEETINGS_GATE_ROUTE_ID);
  return (
    <GatedUpgradeModal gate='create_recording' centered onCancel={close} />
  );
}
