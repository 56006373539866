import {
  ErrorBox,
  Spinner,
  color,
  flex,
  font,
  spaces,
  spacing
} from '@grain/grain-ui';
import { ScorecardListItem } from './ScorecardListItem';
import styled from '@emotion/styled';
import {
  useCustomScorecardChangedSubscription,
  useWorkspaceCustomScorecardDeleteMutation,
  useWorkspaceCustomScorecardsListQuery
} from '@grain/api/graphql/queries/scorecards.generated';
import { cacheUpdateCustomScorecards } from '@grain/api/graphql/cache-helpers';
import { CustomScorecard } from '@grain/api/schema.generated';

type ContentProps = {
  loading: boolean;
  error?: Error;
  scorecards?: Pick<CustomScorecard, 'id' | 'description' | 'title'>[];
  onDelete: (scorecardId: string) => Promise<void>;
};

const EmptyState = styled.p`
  ${font.v4.b2[400]};
  ${color.fg.crow};
  border: 1px solid ${color.gull};
  border-radius: ${spaces[2]};
  margin: 0;
  ${spacing.px5};
  ${spacing.py4};
`;

const Content = ({ loading, error, scorecards, onDelete }: ContentProps) => {
  if (loading) {
    return (
      <Spinner css={[flex.center, { width: '100%' }]} color={color.graieen} />
    );
  }

  if (error) {
    return <ErrorBox variant='minimal'>{error.message}</ErrorBox>;
  }

  if (!scorecards?.length) {
    return <EmptyState>No Scorecards</EmptyState>;
  }

  return (
    <>
      {scorecards.map(scorecard => (
        <ScorecardListItem
          key={scorecard.id}
          onDelete={onDelete}
          {...scorecard}
        />
      ))}
    </>
  );
};

const Description = styled.p`
  padding: ${spaces[7]};
  margin: 0;
  border-bottom: 1px solid ${color.gull};
  ${font.v4.b2[400]}
`;

export const WorkspaceScorecards = () => {
  const { data, loading, error } = useWorkspaceCustomScorecardsListQuery();
  const [deleteScorecard] = useWorkspaceCustomScorecardDeleteMutation();
  const scorecards = data?.customScorecards;

  useCustomScorecardChangedSubscription({
    onData({ data, client }) {
      if (data.data) {
        cacheUpdateCustomScorecards(client, data.data.customScorecardsChanged);
      }
    }
  });

  const handleDelete = async (scorecardId: string) => {
    deleteScorecard({ variables: { id: scorecardId } });
  };

  return (
    <>
      <Description>
        View, edit and create scorecards to evaluate your team’s meetings.{' '}
        <a
          css={{
            textDecoration: 'underline',
            textUnderlineOffset: '0.2em'
          }}
          href='https://support.grain.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          Learn more
        </a>
      </Description>
      <div css={[spacing.p7, 'padding-bottom:0;']}>
        <Content
          loading={loading}
          scorecards={scorecards}
          error={error}
          onDelete={handleDelete}
        />
      </div>
    </>
  );
};
