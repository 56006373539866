import { useExperiment } from '@grain/api/auth';
import FreemiumOnboardingModal from '~/modals/FreemiumOnboarding';
import LegacyOnboardingModal from '~/modals/LegacyOnboarding';

export default function OnboardingModal() {
  const { enabled: isFreemiumEnabled } = useExperiment('freemium');

  if (isFreemiumEnabled) {
    return <FreemiumOnboardingModal />;
  } else {
    return <LegacyOnboardingModal />;
  }
}
