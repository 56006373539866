import styled from '@emotion/styled';
import { color, colors, font, spacing } from '@grain/styles/constants';
import {
  resetButtonStyles,
  overflowEllipsis,
  hexToRgba
} from '@grain/grain-ui';

export const StyledPlaceholder = styled.div`
  background: lightgrey;
  opacity: 25%;
  border: dashed 1px black;
  position: absolute;
  border-radius: 4px;
`;

export const StyledOutlineWrapper = styled.div`
  ${spacing.mr1};
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

export const StyledAddSectionButton = styled.button`
  ${resetButtonStyles};
  ${spacing.mt3};
  background-color: ${colors.gull};
  border-radius: 4px;
  cursor: pointer;
  font-size: calc(12rem / 16);
  font-weight: 500;
  margin-left: 28px;
  padding: 4px 12px 4px 8px;

  :hover {
    background-color: ${colors.hoverbird};
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  svg {
    transform: translateY(1px);
    ${spacing.mr1};
    width: 12px;
    height: 12px;
  }
`;

export const StorySectionItem = styled.div`
  height: 24px;
`;

export const StyledOutlineLabel = styled.div`
  ${color.crow};
  ${overflowEllipsis};
  ${spacing.mb2};
  ${spacing.ml7};
  ${spacing.pb2};
  align-items: center;
  border-bottom: 1px solid ${colors.gull};
  display: flex;
  font-size: calc(10rem / 16);

  .duration-pill {
    ${color.swan};
    ${font.code};
    ${spacing.mr1};
    ${spacing.px3};
    background: ${hexToRgba(colors.rook, 0.5)};
    border-radius: 20px;
    flex-shrink: 0;
    font-size: 12px;
    font-weight: 500;
    margin-left: auto;
  }
`;

export const StyledScrollContainer = styled.div`
  ${spacing.pb6};
  height: 100%;
  overflow-y: auto;
`;
