import { RecordingMetadata } from '~/components/HeaderMetadata';
import {
  useRouteModal,
  Icon,
  ButtonIcon,
  ControlGroup,
  spaces,
  spacing
} from '@grain/grain-ui';

import { SHARE_RECORDING_ID } from '@grain/components/modals/constants';
import { useRecordingPage } from '../context';
import {
  StyledHeader,
  StyledTitle,
  StyledContainer,
  StyledLeft
} from './styles';
import { useRecordingTimestamp } from '../hooks';
import TitleInput from '~/components/HeaderMetadata/TitleInput';
import { SmallScreenRecordingDropdownMenu } from './SmallScreenRecordingDropdownMenu';
import ShareRecordingPermissions from '~/modals/SharePermissions/ShareRecording';
import { isDesktopApp } from '@grain/desktop-lib';
import SidebarMenuButton from '~/components/SidebarMenuButton';

export default function SmallScreenRecordingHeader() {
  const { recordingRes, videoContextRef } = useRecordingPage();
  const { getRecordingTimestamp } = useRecordingTimestamp();

  const { isOpen: shareRecordingIsOpen } = useRouteModal(SHARE_RECORDING_ID);

  const recording = recordingRes.data?.recording;

  const lastStamp = getRecordingTimestamp(recording?.id);
  // Use the current time of the video player if it is available else use the last stamp
  const recordingCurrentTime =
    videoContextRef?.current?.api?.getCurrentTime() * 1000 || lastStamp;

  if (!recording) {
    return null;
  }

  return (
    <StyledHeader>
      <StyledContainer>
        <StyledLeft css={[isDesktopApp && spacing.ml6]}>
          <SidebarMenuButton />
          <StyledTitle>
            <TitleInput
              id={recording.id}
              title={recording.title}
              inputProps={{ disabled: true }}
            />
            <RecordingMetadata
              recording={recording}
              showParticipants={false}
              showCompany={false}
            />
          </StyledTitle>
        </StyledLeft>
        <ControlGroup gap={spaces[4]} css={['-webkit-app-region: no-drag;']}>
          <SmallScreenRecordingDropdownMenu
            recording={recording}
            canDelete={recording?.canDelete}
          >
            <ButtonIcon variant='stealth' data-cy='menu-more-button'>
              <Icon.MenuMore />
            </ButtonIcon>
          </SmallScreenRecordingDropdownMenu>
        </ControlGroup>
      </StyledContainer>
      {shareRecordingIsOpen && (
        <ShareRecordingPermissions
          recording={recording}
          recordingCurrentTime={recordingCurrentTime}
        />
      )}
    </StyledHeader>
  );
}
