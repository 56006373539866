import React from 'react';
import { useMyself } from '@grain/api/auth';

export function useHasAcceptTerms() {
  const { myself } = useMyself();

  return React.useMemo(
    () =>
      myself?.hasAcceptedLatestPrivacyPolicy === false ||
      myself?.hasAcceptedLatestTermsOfService === false,
    [myself]
  );
}
