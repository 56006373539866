import { useMemo, useState } from 'react';
import { ViewTopics } from './View';
import { EditTopic } from './Edit';
import { useTopics, useTags } from './hooks';
import { color, useConfirm, useShowToast } from '@grain/grain-ui';
import { SmartTopic } from '@grain/api/schema.generated';
import { useSearchParams } from 'react-router-dom';

import styled from '@emotion/styled';
import { TopicWizard } from './TopicWizard/TopicWizard';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 698px;
  ${color.bg.swan};
  border-radius: 15px;
  padding-bottom: 32px;
`;

enum PageState {
  VIEW = 'view',
  EDIT = 'edit'
}

export const TopicsTab = () => {
  const showConfirm = useConfirm();
  const [showNew, setShowNew] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPageState = searchParams.get('mode') || PageState.VIEW;
  const isPageState = (state: PageState) => state === currentPageState;

  const { topics = [], handleSmartTopicDelete, loading } = useTopics();
  const showToast = useShowToast();
  const topicTagIds = useMemo(
    () => topics?.map(topic => topic.tag.id),
    [topics]
  );
  const workspaceTags = useTags();

  const handleAdd = () => {
    setShowNew(true);
  };

  const handleEdit = (id?: string) => {
    if (!id) {
      return;
    }
    setSearchParams({
      tab: searchParams.get('tab') || '',
      tagId: id,
      mode: PageState.EDIT
    });
  };

  const handleDelete = (id: string) => {
    showConfirm({
      width: 418,
      title: `Delete ${id}?`,
      description: `Deleting ${id} will also delete any associated Slack notifications.`,
      confirmContent: 'Delete Tracker',
      cancelContent: 'Cancel',
      confirmButtonType: 'danger',
      onConfirm: () => {
        handleSmartTopicDelete({
          variables: { text: id },
          onCompleted: () => {
            showToast({
              content: `${id} deleted`,
              type: 'success',
              uniqueId: 'tag_smart_topic_delete_success'
            });
          }
        });
      },
      onCancel: () => {},
      closable: false
    });
  };

  const handleCancel = () => {
    setSearchParams({
      tab: searchParams.get('tab') || '',
      mode: PageState.VIEW
    });
  };
  const computedTags = useMemo(
    () =>
      workspaceTags?.filter(
        workspace => !topicTagIds.includes(workspace.tag.id)
      ),
    [workspaceTags, topicTagIds]
  );

  const smartTopicWorkspaceTags = useMemo(() => {
    return computedTags.map(
      workspaceTags => ({ tag: workspaceTags.tag }) as SmartTopic
    );
  }, [computedTags]);

  return (
    <StyledWrapper>
      <StyledContainer>
        {isPageState(PageState.VIEW) && (
          <ViewTopics
            topics={topics}
            isLoading={loading}
            onDelete={handleDelete}
            onEdit={handleEdit}
            onAdd={handleAdd}
          />
        )}
        {isPageState(PageState.EDIT) && (
          <EditTopic
            topics={smartTopicWorkspaceTags}
            key={searchParams.get('tab')}
            isNewTopic={false}
            onCancel={handleCancel}
          />
        )}
      </StyledContainer>
      {showNew && <TopicWizard onClose={() => setShowNew(false)} />}
    </StyledWrapper>
  );
};
