import * as Types from '@grain/api/schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@grain/api/graphql';
const defaultOptions = {} as const;
export type RecordingIntelligenceChatMessageFragment = { __typename?: 'IntelligenceChatMessage', id: string, content: string | null, role: Types.IntelligenceChatMessageRole, status: Types.IntelligenceChatMessageStatus, citations: Array<{ __typename?: 'IntelligenceChatMessageCitation', citationId: string, startMs: number, endMs: number }> | null, feedback: { __typename?: 'IntelligenceChatMessageFeedback', id: string, feedback: string | null, type: Types.IntelligenceChatMessageFeedbackType } | null };

export type RecordingIntelligenceChatMessageFeedbackFragment = { __typename?: 'IntelligenceChatMessageFeedback', id: string, feedback: string | null, type: Types.IntelligenceChatMessageFeedbackType };

export type RecordingChatHistoryFragment = { __typename?: 'IntelligenceChatHistory', historyTruncatedAtMessageId: string | null, id: string, messages: Array<{ __typename?: 'IntelligenceChatMessage', id: string, content: string | null, role: Types.IntelligenceChatMessageRole, status: Types.IntelligenceChatMessageStatus, citations: Array<{ __typename?: 'IntelligenceChatMessageCitation', citationId: string, startMs: number, endMs: number }> | null, feedback: { __typename?: 'IntelligenceChatMessageFeedback', id: string, feedback: string | null, type: Types.IntelligenceChatMessageFeedbackType } | null }> };

export type RecordingChatHistoryQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type RecordingChatHistoryQuery = { __typename?: 'RootQueryType', recording: { __typename?: 'Recording', id: string, intelligence: { __typename?: 'RecordingIntelligence', id: string, chatHistory: { __typename?: 'IntelligenceChatHistory', historyTruncatedAtMessageId: string | null, id: string, messages: Array<{ __typename?: 'IntelligenceChatMessage', id: string, content: string | null, role: Types.IntelligenceChatMessageRole, status: Types.IntelligenceChatMessageStatus, citations: Array<{ __typename?: 'IntelligenceChatMessageCitation', citationId: string, startMs: number, endMs: number }> | null, feedback: { __typename?: 'IntelligenceChatMessageFeedback', id: string, feedback: string | null, type: Types.IntelligenceChatMessageFeedbackType } | null }> } | null } } | null };

export type SendChatMessageMutationVariables = Types.Exact<{
  message: Types.Scalars['NonEmptyString']['input'];
  recordingId: Types.Scalars['ID']['input'];
}>;


export type SendChatMessageMutation = { __typename?: 'RootMutationType', intelligenceChatMessageSend: { __typename?: 'IntelligenceChatHistory', historyTruncatedAtMessageId: string | null, id: string, messages: Array<{ __typename?: 'IntelligenceChatMessage', id: string, content: string | null, role: Types.IntelligenceChatMessageRole, status: Types.IntelligenceChatMessageStatus, citations: Array<{ __typename?: 'IntelligenceChatMessageCitation', citationId: string, startMs: number, endMs: number }> | null, feedback: { __typename?: 'IntelligenceChatMessageFeedback', id: string, feedback: string | null, type: Types.IntelligenceChatMessageFeedbackType } | null }> } };

export type ResetChatMutationVariables = Types.Exact<{
  recordingId: Types.Scalars['ID']['input'];
}>;


export type ResetChatMutation = { __typename?: 'RootMutationType', intelligenceChatReset: { __typename?: 'Recording', id: string, intelligence: { __typename?: 'RecordingIntelligence', id: string, chatHistory: { __typename?: 'IntelligenceChatHistory', historyTruncatedAtMessageId: string | null, id: string, messages: Array<{ __typename?: 'IntelligenceChatMessage', id: string, content: string | null, role: Types.IntelligenceChatMessageRole, status: Types.IntelligenceChatMessageStatus, citations: Array<{ __typename?: 'IntelligenceChatMessageCitation', citationId: string, startMs: number, endMs: number }> | null, feedback: { __typename?: 'IntelligenceChatMessageFeedback', id: string, feedback: string | null, type: Types.IntelligenceChatMessageFeedbackType } | null }> } | null } } };

export type NewChatMessageSubscriptionVariables = Types.Exact<{
  recordingId: Types.Scalars['ID']['input'];
}>;


export type NewChatMessageSubscription = { __typename?: 'RootSubscriptionType', intelligenceChatHistoryUpdated: { __typename?: 'IntelligenceChatHistory', historyTruncatedAtMessageId: string | null, id: string, messages: Array<{ __typename?: 'IntelligenceChatMessage', id: string, content: string | null, role: Types.IntelligenceChatMessageRole, status: Types.IntelligenceChatMessageStatus, citations: Array<{ __typename?: 'IntelligenceChatMessageCitation', citationId: string, startMs: number, endMs: number }> | null, feedback: { __typename?: 'IntelligenceChatMessageFeedback', id: string, feedback: string | null, type: Types.IntelligenceChatMessageFeedbackType } | null }> } | null };

export type NewChatRecordingFragment = { __typename?: 'Recording', id: string, intelligence: { __typename?: 'RecordingIntelligence', id: string, chatHistory: { __typename?: 'IntelligenceChatHistory', id: string } | null } };

export type StreamRecordingChatMessageSubscriptionVariables = Types.Exact<{
  chatMessageId: Types.Scalars['ID']['input'];
}>;


export type StreamRecordingChatMessageSubscription = { __typename?: 'RootSubscriptionType', intelligenceChatStreamUpdated: { __typename?: 'IntelligenceChatStreamUpdated', delta: string, messageContent: string } };

export type SetRecordingChatMessageFeedbackMutationVariables = Types.Exact<{
  chatMessageId: Types.Scalars['ID']['input'];
  feedback?: Types.InputMaybe<Types.Scalars['NonEmptyString']['input']>;
  type: Types.IntelligenceChatMessageFeedbackType;
}>;


export type SetRecordingChatMessageFeedbackMutation = { __typename?: 'RootMutationType', intelligenceChatMessageFeedbackSet: { __typename?: 'IntelligenceChatMessage', id: string, content: string | null, role: Types.IntelligenceChatMessageRole, status: Types.IntelligenceChatMessageStatus, citations: Array<{ __typename?: 'IntelligenceChatMessageCitation', citationId: string, startMs: number, endMs: number }> | null, feedback: { __typename?: 'IntelligenceChatMessageFeedback', id: string, feedback: string | null, type: Types.IntelligenceChatMessageFeedbackType } | null } };

export type RemoveRecordingChatMessageFeedbackMutationVariables = Types.Exact<{
  chatMessageId: Types.Scalars['ID']['input'];
}>;


export type RemoveRecordingChatMessageFeedbackMutation = { __typename?: 'RootMutationType', intelligenceChatMessageFeedbackUnset: { __typename?: 'IntelligenceChatMessage', id: string, content: string | null, role: Types.IntelligenceChatMessageRole, status: Types.IntelligenceChatMessageStatus, citations: Array<{ __typename?: 'IntelligenceChatMessageCitation', citationId: string, startMs: number, endMs: number }> | null, feedback: { __typename?: 'IntelligenceChatMessageFeedback', id: string, feedback: string | null, type: Types.IntelligenceChatMessageFeedbackType } | null } };

export const RecordingIntelligenceChatMessageFeedbackFragmentDoc = gql`
    fragment RecordingIntelligenceChatMessageFeedback on IntelligenceChatMessageFeedback {
  id
  feedback
  type
}
    `;
export const RecordingIntelligenceChatMessageFragmentDoc = gql`
    fragment RecordingIntelligenceChatMessage on IntelligenceChatMessage {
  id
  content
  citations {
    citationId
    startMs
    endMs
  }
  role
  status
  feedback {
    ...RecordingIntelligenceChatMessageFeedback
  }
}
    ${RecordingIntelligenceChatMessageFeedbackFragmentDoc}`;
export const RecordingChatHistoryFragmentDoc = gql`
    fragment RecordingChatHistory on IntelligenceChatHistory {
  historyTruncatedAtMessageId
  id
  messages {
    ...RecordingIntelligenceChatMessage
  }
}
    ${RecordingIntelligenceChatMessageFragmentDoc}`;
export const NewChatRecordingFragmentDoc = gql`
    fragment NewChatRecording on Recording {
  id
  intelligence {
    id
    chatHistory {
      id
    }
  }
}
    `;
export const RecordingChatHistoryDocument = gql`
    query RecordingChatHistory($id: ID!) {
  recording(id: $id) {
    id
    intelligence {
      id
      chatHistory {
        ...RecordingChatHistory
      }
    }
  }
}
    ${RecordingChatHistoryFragmentDoc}`;

/**
 * __useRecordingChatHistoryQuery__
 *
 * To run a query within a React component, call `useRecordingChatHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordingChatHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordingChatHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecordingChatHistoryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<RecordingChatHistoryQuery, RecordingChatHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<RecordingChatHistoryQuery, RecordingChatHistoryQueryVariables>(RecordingChatHistoryDocument, options);
      }
export function useRecordingChatHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecordingChatHistoryQuery, RecordingChatHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<RecordingChatHistoryQuery, RecordingChatHistoryQueryVariables>(RecordingChatHistoryDocument, options);
        }
export function useRecordingChatHistorySuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<RecordingChatHistoryQuery, RecordingChatHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<RecordingChatHistoryQuery, RecordingChatHistoryQueryVariables>(RecordingChatHistoryDocument, options);
        }
export type RecordingChatHistoryQueryHookResult = ReturnType<typeof useRecordingChatHistoryQuery>;
export type RecordingChatHistoryLazyQueryHookResult = ReturnType<typeof useRecordingChatHistoryLazyQuery>;
export type RecordingChatHistorySuspenseQueryHookResult = ReturnType<typeof useRecordingChatHistorySuspenseQuery>;
export type RecordingChatHistoryQueryResult = Apollo.QueryResult<RecordingChatHistoryQuery, RecordingChatHistoryQueryVariables>;
export const SendChatMessageDocument = gql`
    mutation SendChatMessage($message: NonEmptyString!, $recordingId: ID!) {
  intelligenceChatMessageSend(message: $message, recordingId: $recordingId) {
    ...RecordingChatHistory
  }
}
    ${RecordingChatHistoryFragmentDoc}`;
export type SendChatMessageMutationFn = Apollo.MutationFunction<SendChatMessageMutation, SendChatMessageMutationVariables>;

/**
 * __useSendChatMessageMutation__
 *
 * To run a mutation, you first call `useSendChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChatMessageMutation, { data, loading, error }] = useSendChatMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *      recordingId: // value for 'recordingId'
 *   },
 * });
 */
export function useSendChatMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendChatMessageMutation, SendChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SendChatMessageMutation, SendChatMessageMutationVariables>(SendChatMessageDocument, options);
      }
export type SendChatMessageMutationHookResult = ReturnType<typeof useSendChatMessageMutation>;
export type SendChatMessageMutationResult = Apollo.MutationResult<SendChatMessageMutation>;
export type SendChatMessageMutationOptions = Apollo.BaseMutationOptions<SendChatMessageMutation, SendChatMessageMutationVariables>;
export const ResetChatDocument = gql`
    mutation ResetChat($recordingId: ID!) {
  intelligenceChatReset(recordingId: $recordingId) {
    id
    intelligence {
      id
      chatHistory {
        ...RecordingChatHistory
      }
    }
  }
}
    ${RecordingChatHistoryFragmentDoc}`;
export type ResetChatMutationFn = Apollo.MutationFunction<ResetChatMutation, ResetChatMutationVariables>;

/**
 * __useResetChatMutation__
 *
 * To run a mutation, you first call `useResetChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetChatMutation, { data, loading, error }] = useResetChatMutation({
 *   variables: {
 *      recordingId: // value for 'recordingId'
 *   },
 * });
 */
export function useResetChatMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetChatMutation, ResetChatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ResetChatMutation, ResetChatMutationVariables>(ResetChatDocument, options);
      }
export type ResetChatMutationHookResult = ReturnType<typeof useResetChatMutation>;
export type ResetChatMutationResult = Apollo.MutationResult<ResetChatMutation>;
export type ResetChatMutationOptions = Apollo.BaseMutationOptions<ResetChatMutation, ResetChatMutationVariables>;
export const NewChatMessageDocument = gql`
    subscription NewChatMessage($recordingId: ID!) {
  intelligenceChatHistoryUpdated(recordingId: $recordingId) {
    ...RecordingChatHistory
  }
}
    ${RecordingChatHistoryFragmentDoc}`;

/**
 * __useNewChatMessageSubscription__
 *
 * To run a query within a React component, call `useNewChatMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewChatMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewChatMessageSubscription({
 *   variables: {
 *      recordingId: // value for 'recordingId'
 *   },
 * });
 */
export function useNewChatMessageSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<NewChatMessageSubscription, NewChatMessageSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<NewChatMessageSubscription, NewChatMessageSubscriptionVariables>(NewChatMessageDocument, options);
      }
export type NewChatMessageSubscriptionHookResult = ReturnType<typeof useNewChatMessageSubscription>;
export type NewChatMessageSubscriptionResult = Apollo.SubscriptionResult<NewChatMessageSubscription>;
export const StreamRecordingChatMessageDocument = gql`
    subscription StreamRecordingChatMessage($chatMessageId: ID!) {
  intelligenceChatStreamUpdated(intelligenceChatMessageId: $chatMessageId) {
    delta
    messageContent
  }
}
    `;

/**
 * __useStreamRecordingChatMessageSubscription__
 *
 * To run a query within a React component, call `useStreamRecordingChatMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStreamRecordingChatMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStreamRecordingChatMessageSubscription({
 *   variables: {
 *      chatMessageId: // value for 'chatMessageId'
 *   },
 * });
 */
export function useStreamRecordingChatMessageSubscription(baseOptions: ApolloReactHooks.SubscriptionHookOptions<StreamRecordingChatMessageSubscription, StreamRecordingChatMessageSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<StreamRecordingChatMessageSubscription, StreamRecordingChatMessageSubscriptionVariables>(StreamRecordingChatMessageDocument, options);
      }
export type StreamRecordingChatMessageSubscriptionHookResult = ReturnType<typeof useStreamRecordingChatMessageSubscription>;
export type StreamRecordingChatMessageSubscriptionResult = Apollo.SubscriptionResult<StreamRecordingChatMessageSubscription>;
export const SetRecordingChatMessageFeedbackDocument = gql`
    mutation SetRecordingChatMessageFeedback($chatMessageId: ID!, $feedback: NonEmptyString, $type: IntelligenceChatMessageFeedbackType!) {
  intelligenceChatMessageFeedbackSet(
    intelligenceChatMessageId: $chatMessageId
    feedback: $feedback
    type: $type
  ) {
    ...RecordingIntelligenceChatMessage
  }
}
    ${RecordingIntelligenceChatMessageFragmentDoc}`;
export type SetRecordingChatMessageFeedbackMutationFn = Apollo.MutationFunction<SetRecordingChatMessageFeedbackMutation, SetRecordingChatMessageFeedbackMutationVariables>;

/**
 * __useSetRecordingChatMessageFeedbackMutation__
 *
 * To run a mutation, you first call `useSetRecordingChatMessageFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRecordingChatMessageFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRecordingChatMessageFeedbackMutation, { data, loading, error }] = useSetRecordingChatMessageFeedbackMutation({
 *   variables: {
 *      chatMessageId: // value for 'chatMessageId'
 *      feedback: // value for 'feedback'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSetRecordingChatMessageFeedbackMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetRecordingChatMessageFeedbackMutation, SetRecordingChatMessageFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SetRecordingChatMessageFeedbackMutation, SetRecordingChatMessageFeedbackMutationVariables>(SetRecordingChatMessageFeedbackDocument, options);
      }
export type SetRecordingChatMessageFeedbackMutationHookResult = ReturnType<typeof useSetRecordingChatMessageFeedbackMutation>;
export type SetRecordingChatMessageFeedbackMutationResult = Apollo.MutationResult<SetRecordingChatMessageFeedbackMutation>;
export type SetRecordingChatMessageFeedbackMutationOptions = Apollo.BaseMutationOptions<SetRecordingChatMessageFeedbackMutation, SetRecordingChatMessageFeedbackMutationVariables>;
export const RemoveRecordingChatMessageFeedbackDocument = gql`
    mutation RemoveRecordingChatMessageFeedback($chatMessageId: ID!) {
  intelligenceChatMessageFeedbackUnset(intelligenceChatMessageId: $chatMessageId) {
    ...RecordingIntelligenceChatMessage
  }
}
    ${RecordingIntelligenceChatMessageFragmentDoc}`;
export type RemoveRecordingChatMessageFeedbackMutationFn = Apollo.MutationFunction<RemoveRecordingChatMessageFeedbackMutation, RemoveRecordingChatMessageFeedbackMutationVariables>;

/**
 * __useRemoveRecordingChatMessageFeedbackMutation__
 *
 * To run a mutation, you first call `useRemoveRecordingChatMessageFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRecordingChatMessageFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRecordingChatMessageFeedbackMutation, { data, loading, error }] = useRemoveRecordingChatMessageFeedbackMutation({
 *   variables: {
 *      chatMessageId: // value for 'chatMessageId'
 *   },
 * });
 */
export function useRemoveRecordingChatMessageFeedbackMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveRecordingChatMessageFeedbackMutation, RemoveRecordingChatMessageFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveRecordingChatMessageFeedbackMutation, RemoveRecordingChatMessageFeedbackMutationVariables>(RemoveRecordingChatMessageFeedbackDocument, options);
      }
export type RemoveRecordingChatMessageFeedbackMutationHookResult = ReturnType<typeof useRemoveRecordingChatMessageFeedbackMutation>;
export type RemoveRecordingChatMessageFeedbackMutationResult = Apollo.MutationResult<RemoveRecordingChatMessageFeedbackMutation>;
export type RemoveRecordingChatMessageFeedbackMutationOptions = Apollo.BaseMutationOptions<RemoveRecordingChatMessageFeedbackMutation, RemoveRecordingChatMessageFeedbackMutationVariables>;