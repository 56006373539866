import { css } from '@emotion/react';
import { Chip, theme } from '@grain/grain-ui/v4';
import { ChatMessage } from './types';
import { motion } from 'motion/react';

type UserChatMessageProps = {
  chatMessage: ChatMessage;
  includedInContext: boolean;
  hasAnimated: boolean;
  onAnimationComplete: () => void;
};

export const UserChatMessage = ({
  chatMessage,
  includedInContext,
  hasAnimated,
  onAnimationComplete
}: UserChatMessageProps) => {
  return (
    <motion.div
      css={css`
        justify-self: end;
        margin-left: ${theme.tokens.spacing['3xl']};
      `}
      variants={{
        show: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 25 }
      }}
      initial={hasAnimated ? 'hidden' : 'show'}
      animate='show'
      onAnimationComplete={onAnimationComplete}
    >
      <Chip
        variant='fill'
        css={css`
          word-break: break-word;

          ${!includedInContext &&
          css`
            opacity: 0.5;
          `}
        `}
        title={chatMessage.content ?? ''}
      />
    </motion.div>
  );
};
