import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, Thumbnail, theme } from '@grain/grain-ui/v4';
import { Duration } from 'components/atoms/Duration/Duration';
import { format } from 'date-fns';
import { RecordingFileUpload } from 'lib/upload/RecordingFileUploadContext';
import { Link } from 'react-router-dom';
import {
  RecordingQuery,
  UploadRecordingChangeDocument,
  useRecordingQuery
} from '../../Library/library.generated';
import { useEffect } from 'react';

const Container = styled.article`
  ${theme.utils.py('14px')}
  ${theme.utils.px('2xl')}
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto auto;
  flex-shrink: 0;
  gap: ${theme.tokens.spacing.md};

  &:hover {
    text-decoration: none;
    background-color: ${theme.tokens.color.surfaceSecondary};
  }
`;

const Title = styled.h3`
  display: inline-flex;
  align-items: center;
  ${theme.tokens.typography.b3[500]};
  color: ${theme.tokens.color.textPrimary};
  padding: 0;
  margin: 0 0 ${theme.tokens.spacing['2xs']} 0;
`;

const Meta = styled.div`
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textTertiary};
`;

type MaybeRecording = RecordingQuery['recording'] | undefined;

type MeetingListItemProps = {
  upload: RecordingFileUpload;
  onCancel: () => void;
};

const UploadMeta = ({
  upload,
  maybeRecording
}: {
  upload: RecordingFileUpload;
  maybeRecording: MaybeRecording;
}) => {
  if (maybeRecording) {
    return (
      <>
        {format(new Date(maybeRecording.startDatetime), 'MMM, do h:mm a')} ·{' '}
        <Duration
          durationMs={
            new Date(maybeRecording.startDatetime).getTime() -
            new Date(maybeRecording.endDatetime ?? '').getTime()
          }
        />
      </>
    );
  }

  if (upload.error) return <>Upload Error</>;

  return <>Processing...</>;
};

const getThumbnailProps = (maybeRecording: MaybeRecording) => {
  if (
    maybeRecording &&
    (maybeRecording.fullWebpThumbnailUrl || maybeRecording.fullJpegThumbnailUrl)
  ) {
    return {
      imgSrc:
        maybeRecording.fullWebpThumbnailUrl ||
        maybeRecording.fullJpegThumbnailUrl ||
        undefined
    };
  }

  if (maybeRecording?.locked) return { isLocked: true };

  return { isLoading: true };
};

export const UploadedMeetingItem = ({
  upload,
  onCancel
}: MeetingListItemProps) => {
  const { data, subscribeToMore } = useRecordingQuery({
    variables: {
      recordingId: upload.recordingId!
    },
    skip: Boolean(!upload.recordingId)
  });

  useEffect(() => {
    if (upload.recordingId) {
      const cancel = subscribeToMore({
        document: UploadRecordingChangeDocument,
        variables: { recordingId: upload.recordingId! },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data?.recording) return prev;

          return {
            ...prev,
            recording: {
              ...prev.recording,
              ...subscriptionData.data.recording
            }
          };
        }
      });

      return () => cancel();
    }
  }, [subscribeToMore, upload.recordingId]);

  const maybeRecording: MaybeRecording = data?.recording;

  return (
    <Container
      {...(upload.complete
        ? {
            as: Link,
            to: maybeRecording?.recordingPath
          }
        : {})}
    >
      <Thumbnail
        {...getThumbnailProps(maybeRecording)}
        css={['height: 36px;']}
      />

      <div>
        <Title title={upload.title}>
          {upload.title.slice(0, 50)}
          {upload.title.length > 50 && '...'}{' '}
        </Title>
        <Meta>
          <UploadMeta upload={upload} maybeRecording={maybeRecording} />
        </Meta>
      </div>
      <div />

      <div
        css={css`
          display: flex;
          align-items: center;
          gap: ${theme.tokens.spacing.sm};
        `}
      >
        {maybeRecording && (
          <Button
            variant='neutral'
            size='md'
            as={Link}
            to={maybeRecording.recordingPath}
          >
            View
          </Button>
        )}
        {!upload.complete && (
          <Button variant='neutral' size='md' onClick={onCancel}>
            Cancel
          </Button>
        )}
      </div>
    </Container>
  );
};
