// @ts-strict-ignore
import React from 'react';
import styled from '@emotion/styled';
import {
  Checkbox,
  color,
  pxToRem,
  Icon,
  spacing,
  Button,
  Dropdown,
  MenuItem,
  useShowToast,
  getOauthRedirectUrl
} from '@grain/grain-ui';
import { SelectableCalendar } from '@g/schema';
import type { provider } from '@grain/grain-ui';

import { useCalendarQuery } from '@grain/api/graphql/modules/calendar.generated';
import { useCalendarSelectedSetMutation } from './Calendar.generated';
import { MyselfQuery } from '@grain/api/graphql/queries/user.generated';

export const StyledContainer = styled.div`
  ${color.fg.blackbird};

  font-size: ${pxToRem(14)};
  font-weight: 400;
  line-height: ${pxToRem(20)};

  .tippy-svg-arrow {
    display: none;
  }

  .tippy-content {
    width: 230px;
  }

  .title {
    ${color.fg.blackbird};
    font-size: ${pxToRem(14)};
    font-style: normal;
    font-weight: 600;
    line-height: ${pxToRem(18)};
  }

  .subtitle {
    margin-top: 8px;
    margin-bottom: 16px;
    ${color.fg.crow};
    font-size: ${pxToRem(14)};
    font-style: normal;
    font-weight: 400;
    line-height: ${pxToRem(18)};
  }

  .email {
    display: flex;
    align-items: center;

    svg {
      ${spacing.mr2};
      height: 16px;
      width: 16px;
    }
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  border: 1px solid ${color.gull};
  border-radius: ${pxToRem(12)};
`;

export const GridItem = styled.div`
  width: 100%;
  padding: 16px 24px;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(18)};
  color: ${color.blackbird};
  border-bottom: 1px solid ${color.gull};
  gap: 8px;
  display: flex;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledMissingScopeInfo = styled.div`
  display: flex;
  padding: 16px 24px;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid ${color.gull};
  ${color.bg.goose};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  ${spacing.mt6};
  ${color.fg.blackbird};

  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 20px;
    }
  }

  .text {
    font-feature-settings:
      'cpsp' on,
      'case' on,
      'clig' off,
      'liga' off;
    font-size: ${pxToRem(14)};
    font-style: normal;
    font-weight: 400;
    line-height: ${pxToRem(18)};
  }

  .action {
    margin-left: auto;
  }
`;

export const StyledUpgradeText = styled.span`
  ${color.pigeon};
`;

type CalendarStatusProps = {
  myself?: MyselfQuery['myself'];
  showTitle: boolean;
};

export default function CalendarStatus({
  myself,
  showTitle = true
}: CalendarStatusProps) {
  const showToast = useShowToast();
  const [calendar, setCalendar] = React.useState(null);

  const calendarConnectedAccount = React.useMemo(() => {
    if (!myself) return null;
    return (
      myself.oauthAccounts.find(account => account.calendarConnected) ?? null
    );
  }, [myself]);

  useCalendarQuery({
    skip: Boolean(!calendarConnectedAccount),
    onCompleted: data => {
      setCalendar(data?.calendar);
    }
  });

  const [calendarSelectedSetMutation] = useCalendarSelectedSetMutation();

  const [calendarAccount] = calendar?.accounts ?? [];
  const currentPath = window.location.pathname + window.location.search;

  return (
    <StyledContainer>
      {showTitle && (
        <>
          <div className='title'>Calendar</div>
          <div className='subtitle'>
            {calendarConnectedAccount
              ? 'Choose the calendars with meetings you want to sync with Grain.'
              : 'Connect your calendar below to record meetings with Grain.'}
          </div>
        </>
      )}
      {calendarConnectedAccount ? (
        <>
          {!showTitle ? (
            <div className='email'>
              {calendarConnectedAccount?.provider === 'GOOGLE' ? (
                <Icon.Google />
              ) : (
                <Icon.Outlook />
              )}
              {calendarConnectedAccount?.email}
            </div>
          ) : (
            <div css={[spacing.mt2]}>
              <GridContainer>
                {calendarAccount?.missingScopes ? (
                  <GridItem>
                    <Checkbox checked={true} disabled={true} />
                    {calendarAccount?.name}
                  </GridItem>
                ) : (
                  calendarAccount?.calendars.map(
                    (calendar: SelectableCalendar) => (
                      <GridItem key={calendar.id}>
                        <Checkbox
                          checked={calendar.isSelected}
                          onChange={async ev => {
                            const { data } = await calendarSelectedSetMutation({
                              variables: {
                                calendarId: calendar.id,
                                selected: ev.target.checked
                              }
                            });
                            setCalendar(data?.calendarSelectedSet);
                            showToast({
                              content: 'Account Updated',
                              type: 'success'
                            });
                          }}
                        />
                        {calendar.title}
                      </GridItem>
                    )
                  )
                )}
              </GridContainer>
              {calendarAccount?.missingScopes && (
                <StyledMissingScopeInfo>
                  <div className='info'>
                    <Icon.Information />
                  </div>
                  <div className='text'>
                    Reconnect your email to connect multiple calendars.
                  </div>
                  <div className='action'>
                    <Button
                      variant='secondary'
                      onClick={() => {
                        window.location.href = getOauthRedirectUrl(
                          calendarConnectedAccount?.provider.toLowerCase() as provider,
                          true,
                          'connect_account',
                          {},
                          currentPath,
                          myself?.user?.email
                        );
                      }}
                    >
                      Reconnect
                    </Button>
                  </div>
                </StyledMissingScopeInfo>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <Dropdown
            placement='bottom'
            targetElement={
              <div css={['width: 144px; display: table-cell;']}>
                <Button variant='primary'>Connect calendar</Button>
              </div>
            }
          >
            <MenuItem
              css={['width: 230px;']}
              icon={Icon.Google}
              label='Connect Google Calendar'
              onClick={() => {
                window.location.href = getOauthRedirectUrl(
                  'google',
                  true,
                  'connect_account',
                  {},
                  currentPath,
                  myself?.user?.email
                );
              }}
            />
            <MenuItem
              css={['width: 230px;']}
              icon={Icon.Outlook}
              label='Connect Outlook Calendar'
              onClick={() => {
                window.location.href = getOauthRedirectUrl(
                  'microsoft',
                  true,
                  'connect_account',
                  {},
                  currentPath,
                  myself?.user?.email
                );
              }}
            />
          </Dropdown>
        </>
      )}
    </StyledContainer>
  );
}
