import { css } from '@emotion/react';

type LoadingCircleProps = {
  background?: string;
  color: string;
  size: number;
  weight?: number;
};

export default function LoadingCircle({
  size,
  color,
  background = 'transparent',
  weight = 2,
  ...rest
}: LoadingCircleProps) {
  const styles = css`
    // do not rearrange
    border: ${weight}px solid ${background};
    border-top: ${weight}px solid ${color};

    border-radius: 50%;
    height: ${size}px;
    width: ${size}px;

    animation: spin 2s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;

  return <div css={[styles]} {...rest} />;
}
