import PropTypes from 'prop-types';
import React from 'react';
import { useRecoilState } from 'recoil';

import Gallery from './Gallery';
import { Modal, ModalTitle } from '@grain/grain-ui';
import Pixabay from './Pixabay';
import Upload from './Upload';
import { storyState } from '~/pages/StoryPage/state';
import { storyUpdateMutation } from '~/modules/stories/graphql';
import { useMutation } from '@grain/api/graphql';
import { useStoryToast } from '~/pages/StoryPage/hooks';

import {
  StyledContent,
  StyledSourceContainer,
  StyledSource,
  StyledImageContainer
} from './styles';

CoverPicker.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default function CoverPicker({ onClose, ...rest }) {
  const [story, setStory] = useRecoilState(storyState);
  const showToast = useStoryToast();
  const prevStoryRef = React.useRef();
  const [activeTab, setActiveTab] = React.useState('GALLERY');

  const [isLoading, setIsLoading] = React.useState(false);
  const onUploading = React.useCallback(
    () => setIsLoading(true),
    [setIsLoading]
  );
  const onUploadSuccess = React.useCallback(
    () => setIsLoading(false),
    [setIsLoading]
  );
  const onUploadFailure = React.useCallback(
    () => setIsLoading(false),
    [setIsLoading]
  );

  const [updateStory] = useMutation(storyUpdateMutation, {
    onError() {
      setStory(prevStoryRef.current);
    }
  });

  const pickStoryImage = React.useCallback(
    bannerImageUrl => {
      prevStoryRef.current = story;
      setStory(story => ({
        ...story,
        bannerImageUrl
      }));
      updateStory({
        variables: { storyId: story.id, title: story.title, bannerImageUrl }
      });
      showToast({ content: 'Cover image updated', type: 'success' });
    },
    [story, prevStoryRef, setStory, updateStory, showToast]
  );

  const Cmp = React.useMemo(() => {
    if (activeTab === 'GALLERY') {
      return <Gallery onSelect={pickStoryImage} />;
    } else if (activeTab === 'PIXABAY') {
      return <Pixabay onSelect={pickStoryImage} />;
    } else if (activeTab === 'UPLOAD') {
      return (
        <Upload
          storyId={story.id}
          onCancel={onClose}
          onUploading={onUploading}
          onUploadSuccess={onUploadSuccess}
          onUploadFailure={onUploadFailure}
        />
      );
    }

    return Gallery;
  }, [
    activeTab,
    onClose,
    story.id,
    pickStoryImage,
    onUploading,
    onUploadSuccess,
    onUploadFailure
  ]);

  return (
    <Modal
      closable={!isLoading}
      maskClosable={!isLoading}
      onCancel={onClose}
      width='600px'
      {...rest}
      overlayProps={{
        style: {
          backgroundColor: 'transparent'
        },
        ...rest.overlayProps
      }}
      css={['margin-top: 10vh !important; max-height: 80vh; !important']}
    >
      <ModalTitle title='Choose Cover Image' centered />
      <StyledContent>
        <StyledSourceContainer>
          <StyledSource
            active={activeTab === 'GALLERY'}
            onClick={React.useCallback(
              () => setActiveTab('GALLERY'),
              [setActiveTab]
            )}
            disabled={isLoading}
          >
            Gallery
          </StyledSource>
          <StyledSource
            active={activeTab === 'PIXABAY'}
            onClick={React.useCallback(
              () => setActiveTab('PIXABAY'),
              [setActiveTab]
            )}
            disabled={isLoading}
          >
            Pixabay
          </StyledSource>
          <StyledSource
            active={activeTab === 'UPLOAD'}
            onClick={React.useCallback(
              () => setActiveTab('UPLOAD'),
              [setActiveTab]
            )}
            disabled={isLoading}
          >
            Upload
          </StyledSource>
        </StyledSourceContainer>
        <StyledImageContainer>{Cmp}</StyledImageContainer>
      </StyledContent>
    </Modal>
  );
}
