import styled from '@emotion/styled';
import { color, colors, media, spacing } from '@grain/styles/constants';
import { isDesktopApp } from '@grain/desktop-lib';
import { css } from '@emotion/react';
import { flex, WorkspaceAvatar, hexToRgba, pxToRem } from '@grain/grain-ui';
import { theme } from '@grain/grain-ui/v4';

export const StyledContainer = styled.div`
  height: 100%;
  background-color: ${colors.swan};
  .cta-options {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    a {
      cursor: pointer;
    }
  }

  .sso-toggle {
    ${color.pigeon};
    font-size: ${pxToRem(14)};
    margin-top: 24px;
    text-align: center;
    a {
      ${color.pigeon};
      cursor: pointer;
      font-size: ${pxToRem(14)};
      text-decoration: underline;
    }
  }
`;

export const StyledLogoContainer = styled.div`
  ${flex.center};
  margin-bottom: 15vh;
  margin-top: 20vh;
`;

export const StyledWrapper = styled.div`
  ${flex.center};
  flex-direction: column;
  margin: auto;
`;

export const StyledHeaderBar = styled.div<{ justifyContent?: string }>`
  height: 90px;
  background-color: ${colors.swan};
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-end'};
  ${spacing.px5};
  ${spacing.py6};
  ${spacing.mb3};
  ${isDesktopApp && spacing.pt8};
`;

export const StyledHeaderLogo = styled.div`
  display: inline-flex;
  padding: 28px 40px;
  justify-content: center;

  img {
    height: 34px;
  }
`;

export const StyledHeaderSignup = styled.div`
  flex: none;
`;

export const StyledTitle = styled.div`
  ${color.blackbird};
  ${spacing.my4};
  text-align: center;
  font-weight: 600;
  font-size: ${pxToRem(38)};
  line-height: ${pxToRem(48)};
`;

export const StyledLogo = styled(WorkspaceAvatar)`
  ${spacing.mt4};
  width: 56px !important;
  height: 56px !important;
  border-radius: 11.2px;
`;

export const StyledSubtitle = styled.div`
  ${color.blackbird};
  ${spacing.my6};
  text-align: center;
  font-weight: 600;
  font-size: ${pxToRem(30)};
  line-height: ${pxToRem(36.6)};
`;

export const StyledDescription = styled.div`
  ${color.crow};
  line-height: ${pxToRem(28)};
  font-size: ${pxToRem(18)};
  font-weight: 400;
  text-align: center;
`;

export const StyledHighlight = styled.span`
  background-color: ${hexToRgba(colors.highlightGreen, 0.4)};
  margin-left: -2px;
  margin-right: -2px;
  padding: 2px;
`;

export const StyledSignUpDescription = styled.div`
  ${spacing.mt7};
  ${spacing.pb7};
  font-weight: 400;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(20)};
  text-align: center;
  ${color.blackbird};

  & > a {
    ${color.crow};
    text-decoration: underline;
  }
`;

export const StyledTos = styled.div`
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(16)};
  font-weight: normal;
  text-align: initial;
  margin: 0 auto;
  ${color.crow};
  ${spacing.mt7};

  & > a {
    ${color.crow};
    text-decoration: underline;
  }

  & > a:hover,
  & > a:visited {
    ${color.crow};
  }
`;

export const loginButtonStyles = css`
  width: 100%;
`;

export const StyledLayoutContainer = styled.div`
  min-height: 100vh;
  overflow-y: auto;
  width: 100%;
  background-color: ${colors.swan};
  display: flex;
  flex-direction: column;
`;

export const StyledLayoutTopAndBottom = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledLayoutSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLayoutFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${theme.tokens.spacing['3xl']};
`;

export const StyledFreeTrialContainer = styled.div`
  ${flex.direction.column};
  ${flex.alignItems.center};
  ${spacing.mx4};
`;

export const StyledFreeTrialBox = styled.div`
  ${spacing.px6};
  ${spacing.py4};
  background: ${colors.goose};
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  max-width: 405px;
`;

export const StyledFreeTrialTitle = styled.div`
  ${spacing.mb2};
  font-size: ${pxToRem(14)};
  font-weight: 600;
`;

export const StyledFreeTrialBulletsContainer = styled.div`
  ${flex.direction.column};
`;

export const StyledFreeTrialBullet = styled.span`\
  ${spacing.mt2};
  font-size: ${pxToRem(12.9)};
  line-height: ${pxToRem(18.43)};
  font-weight: 400;
  ${flex.direction.row};
  ${flex.alignItems.center};
`;

export const StyledGrainInfo = styled.div`
  ${color.blackbird};
  font-style: normal;
  font-weight: 400;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(18)};
  font-feature-settings:
    'case' on,
    'cpsp' on;
`;

// NOTE: StyledCard* styles originally copied from pages/Onboarding/styles.ts,
// but letting them diverge so as to refine the styles for Login/Signup without
// potential negative side-effects to Onboarding.

export const StyledCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${color.goose};
`;

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 0px;
  background: ${color.swan};
  border-radius: 32px;
  ${spacing.mt6}
  width: 500px;

  ${media.small} {
    max-width: 100%;
    ${spacing.px4};
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

export const StyledCardHeaderText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: ${pxToRem(28)};
  line-height: ${pxToRem(34)};
  ${color.blackbird};
  font-feature-settings:
    'case' on,
    'cpsp' on;

  ${media.small} {
    text-align: left;
    align-self: flex-start;
  }
`;

export const StyledCardSubHeaderText = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(20)};
  ${color.crow};
  font-feature-settings:
    'case' on,
    'cpsp' on;
  text-align: center;

  ${media.small} {
    text-align: left;
    align-self: flex-start;
  }
`;
