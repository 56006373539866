import { useEffect, useState } from 'react';
import { Global, css } from '@emotion/react';
import { Allotment } from 'allotment';
import useLocalStorage from 'react-use/lib/useLocalStorage';

import { RecordingMediaType } from '@grain/api/schema.generated';
import { media, Icon, useAnalytics } from '@grain/grain-ui';
import { tagSuggestionCss } from '@grain/components/Tags/common';
import { useMediaQuery } from '@grain/components/support/browser';

import { useRecordingHotspotsEffect } from './hotspots';
import { useRecordingPage } from './context';
import { useTabLayout } from './hooks';
import { useTabs } from './RecordingTabs/useTabs';

import { RecordingMediaPane } from './RecordingMediaPane';
import { RecordingTabs } from './RecordingTabs';

import {
  StyledHeader,
  StyledRecording,
  StyledRightPane,
  StyledMediaPane,
  StyledTabsPane,
  StyledCollapseDivider,
  StyledCollapseButton
} from './styles';
import SmallScreenRecordingHeader from './SmallScreenRecordingHeader';
import { AskChat } from './components/AskChat/AskChat';
import { useRefreshStaleIntelligenceMutation } from './recordingpage.generated';
import {
  VideoCollapseState,
  useVideoCollapsedState
} from './hooks/useVideoCollapsed';
import { FreemiumCutoffBanner } from './components/FreemiumCuttoffBanner/FreemiumCutoffBanner';

export function RecordingPage() {
  const { trackEvent } = useAnalytics();

  const { recordingRes, recordingId, typedRecording, videoContextRef } =
    useRecordingPage();
  const [refreshStaleIntelligence] = useRefreshStaleIntelligenceMutation();
  const videoRef = videoContextRef.current?.videoRef;
  const videoEl = videoRef?.current?.contentEl();
  const { data } = recordingRes;
  const recording = data?.recording;
  const isSmallScreen = useMediaQuery(media.small);

  const tabs = useTabLayout();
  const { setTab, currentTab } = useTabs(tabs);

  const dealProviderKey: string | undefined =
    recording?.externalGroupsData?.[0]?.deal?.providerKey;

  // Currently triggered when a user changes a participant on a meeting from the transcript tab.
  // This will rerun the stale intelligence sections when they leave the transcript to keep it up to date.
  // https://linear.app/grain/issue/ENG-13792/refresh-ai-notes-when-user-navigates-to-summary-chapters-or-template
  useEffect(() => {
    if (recording?.intelligence?.anyStale && currentTab.tab !== 'transcript') {
      refreshStaleIntelligence({
        variables: {
          recordingId: recordingId
        }
      });
    }
  }, [
    recording?.intelligence?.anyStale,
    currentTab.tab,
    recordingId,
    refreshStaleIntelligence
  ]);

  useRecordingHotspotsEffect({
    recording: typedRecording,
    setTab,
    dealProviderKey
  });

  const [defaultVerticalSplit, setDefaultVerticalSplit] = useLocalStorage<
    number[]
  >('recording_page_vertical_split', []);

  const [collapsible, setCollapsible] = useState(true);
  const {
    state: videoCollapsedState,
    collapsed,
    toggle: toggleVideoCollapsed,
    setState: setVideoCollapsedState
  } = useVideoCollapsedState();

  useEffect(() => {
    const handlePipEnter = () => setCollapsible(false);
    const handlePipLeave = () => setCollapsible(true);
    videoEl?.addEventListener('enterpictureinpicture', handlePipEnter);
    videoEl?.addEventListener('leavepictureinpicture', handlePipLeave);
    return () => {
      videoEl?.removeEventListener('enterpictureinpicture', handlePipEnter);
      videoEl?.removeEventListener('leavepictureinpicture', handlePipLeave);
    };
  }, [videoEl]);

  const isAskChatEnabled = !isSmallScreen;

  const recordingWrapperCSS = css`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    min-height: 0;
    flex: 1;
    width: 100%;

    ${isAskChatEnabled &&
    css`
      .tab-layout-content > div {
        padding-bottom: 60px;
      }
    `}
  `;

  return (
    <>
      <Global styles={tagSuggestionCss} />

      {isSmallScreen ? (
        <SmallScreenRecordingHeader />
      ) : (
        <StyledHeader css={['margin: 0;']} setTab={setTab} />
      )}

      <StyledRecording>
        {!!recording && (
          <Allotment
            defaultSizes={defaultVerticalSplit}
            onChange={setDefaultVerticalSplit}
          >
            {!isSmallScreen && (
              <Allotment.Pane minSize={450} className='recording-left-pane'>
                <RecordingTabs
                  tabstoShow={tabs.tab}
                  setTab={setTab}
                  currentTab={currentTab.tab}
                />
              </Allotment.Pane>
            )}
            <Allotment.Pane
              minSize={450}
              preferredSize={isSmallScreen ? '100%' : undefined}
              className='recording-right-pane'
            >
              <StyledRightPane>
                <StyledMediaPane>
                  <FreemiumCutoffBanner
                    cutoffApplied={typedRecording?.cutoffApplied || null}
                  />
                  <RecordingMediaPane forceAudioPlayer={collapsed} />
                </StyledMediaPane>
                <StyledTabsPane className='meeting-page-tabs-pane'>
                  <div css={recordingWrapperCSS}>
                    <StyledCollapseDivider>
                      {recording.mediaType === RecordingMediaType.Video &&
                        collapsible && (
                          <StyledCollapseButton
                            disabled={
                              videoCollapsedState ===
                              VideoCollapseState.COLLAPSED_FROM_CHAT
                            }
                            onClick={() => toggleVideoCollapsed()}
                          >
                            {collapsed ? (
                              <Icon.ChevronDown18x18 />
                            ) : (
                              <Icon.ChevronUp18x18 />
                            )}
                          </StyledCollapseButton>
                        )}
                    </StyledCollapseDivider>
                    <RecordingTabs
                      tabstoShow={isSmallScreen ? tabs.tab : tabs.mediaTab}
                      setTab={setTab}
                      currentTab={
                        isSmallScreen ? currentTab.tab : currentTab.mediaTab
                      }
                    />
                    {/* Ask Chat uses position absolute, and thus relies on RecordingWrapperCSS to position it */}
                    {isAskChatEnabled && (
                      <AskChat
                        onOpen={() => {
                          if (
                            videoCollapsedState === VideoCollapseState.EXPANDED
                          ) {
                            setVideoCollapsedState(
                              VideoCollapseState.COLLAPSED_FROM_CHAT
                            );
                          }
                          trackEvent('Button Clicked', {
                            button_name: 'ask_opened',
                            trigger: 'ask_recording'
                          });
                        }}
                        onClose={() => {
                          if (
                            videoCollapsedState ===
                            VideoCollapseState.COLLAPSED_FROM_CHAT
                          ) {
                            setVideoCollapsedState(VideoCollapseState.EXPANDED);
                          }
                          trackEvent('Button Clicked', {
                            button_name: 'ask_closed',
                            trigger: 'ask_recording'
                          });
                        }}
                        size={currentTab.mediaTab === 'timeline' ? 'lg' : 'sm'}
                      />
                    )}
                  </div>
                </StyledTabsPane>
              </StyledRightPane>
            </Allotment.Pane>
          </Allotment>
        )}
      </StyledRecording>
    </>
  );
}
