import styled from '@emotion/styled';
import SalesMethodologyBanner from './sales_methodology_banner.svg?react';
import { theme, Button } from '@grain/grain-ui/v4';
import { Link } from 'react-router-dom';
import { useAnalytics } from '@grain/grain-ui';

type DealDiagnosisSetupBannerProps = {
  onSelect: () => void;
  onClose: () => void;
};

const Wrapper = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  border-radius: ${theme.tokens.radii.xl};
  border: 1px solid ${theme.tokens.color.borderTertiary};
  overflow: hidden;
`;

const Illustration = styled.div`
  background-color: ${theme.tokens.color.surfaceTertiary};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  ${theme.utils.px('xl')}
  padding-top: ${theme.tokens.spacing.md};
`;

const Content = styled.div`
  padding: ${theme.tokens.spacing['2xl']};
  width: 100%;
  background-color: ${theme.tokens.color.surfaceSecondary};
`;

const Title = styled.h3`
  ${theme.tokens.typography.h3};
  margin: 0;
  margin-bottom: ${theme.tokens.spacing.md};
`;

const Description = styled.p`
  ${theme.tokens.typography.b2[400]};
  color: ${theme.tokens.color.textSecondary};
  margin-bottom: ${theme.tokens.spacing.lg};
  margin: 0;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: ${theme.tokens.spacing.lg};
  width: 100%;
  justify-content: space-between;
  margin-top: ${theme.tokens.spacing['3xl']};
`;

const ButtonGroupInner = styled.div`
  display: flex;
  gap: ${theme.tokens.spacing.lg};
`;

export const DealDiagnosisSetupBanner = ({
  onSelect,
  onClose
}: DealDiagnosisSetupBannerProps) => {
  const { trackEvent } = useAnalytics();

  const handleLearnMore = () => {
    trackEvent('Deal Diagnosis Learn More Clicked');
  };

  return (
    <Wrapper>
      <Illustration>
        <SalesMethodologyBanner css={{ height: 'unset', maxHeight: '180px' }} />
      </Illustration>
      <Content>
        <Title>Automate your sales methodology</Title>
        <Description>
          Grain's automated SPICED analysis reviews every part of your sales
          conversations to help your team identify gaps in their deals and
          improve close rate.
        </Description>
        <ButtonGroup>
          <Button variant='ghost' size='lg' onClick={onClose}>
            Not now
          </Button>
          <ButtonGroupInner>
            <Button
              onClick={handleLearnMore}
              css={{
                justifySelf: 'flex-end',
                '&:hover': {
                  textDecoration: 'none'
                }
              }}
              variant='neutral'
              size='lg'
              as={Link}
              to='https://support.grain.com/en/articles/9931236-how-automated-spiced-analysis-can-improve-your-team-s-deal-performance'
              rel='noopener noreferrer'
            >
              Learn more
            </Button>
            <Button
              css={{ justifySelf: 'flex-end' }}
              variant='primary'
              size='lg'
              onClick={onSelect}
            >
              Get started
            </Button>
          </ButtonGroupInner>
        </ButtonGroup>
      </Content>
    </Wrapper>
  );
};
