import styled from '@emotion/styled';
import { media, spacing, colors } from '@grain/styles/constants';

export const StyledStoriesWrapper = styled.div`
  ${spacing.py7};
  ${spacing.px4};
  height: 100vh;
  ${media.small} {
    ${spacing.pt4};
    height: calc(100vh - 32px);
  }
  display: flex;
  justify-content: center;

  .story-resize-handle {
    background: none;
    margin-right: -4px;
    transition: background-color 0.2s linear;
    width: 4px;
    z-index: 100;

    :hover {
      background: ${colors.pigeon};
    }
  }

  .story-resize-handle[data-resize-handle-active] {
    background: ${colors.graieen};
  }
`;

// Defined here because Outline is async loaded...
export const StyledOutlineWrapper = styled.div`
  min-width: 212px;
  height: 100%;

  .inner-wrapper {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
`;

export const StyledActionWrapper = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  ${media.large} {
    flex-direction: row;
    width: 212px;
  }
  button {
    width: 80px;
    ${spacing.mb3};
  }
  a:hover {
    text-decoration: none;
  }
`;

export const StyledMobileButtonsContainer = styled.div`
  ${spacing.mt4};
  ${spacing.mx4};
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    width: 100px;
  }
`;
