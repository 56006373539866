import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@grain/api/graphql';
import { useWebSocket } from '@grain/components/WebSocketContext';
import { UploadImageModal } from '@grain/grain-ui';
import { StoryBannerUploadInfoDocument as storyBannerUploadInfoQuery } from '../../../storyPage.generated';

const CROP_ASPECT_RATIO = 16 / 9;

Upload.propTypes = {
  storyId: PropTypes.string.isRequired,
  onCancel: PropTypes.func
};

const NOOP = () => {};

export default function Upload({ storyId, onCancel = NOOP, ...rest }) {
  const { data: uploadInfoData } = useQuery(storyBannerUploadInfoQuery, {
    variables: {
      storyId
    }
  });

  const { userChannel } = useWebSocket();

  return (
    <>
      {uploadInfoData ? (
        <UploadImageModal
          uploadUrl={uploadInfoData.storyBannerUploadInfo.url.url}
          uploadUuid={uploadInfoData.storyBannerUploadInfo.url.uuid}
          maxUploadBytes={uploadInfoData.storyBannerUploadInfo.maxUploadBytes}
          onCancel={onCancel}
          cropAspectRatio={CROP_ASPECT_RATIO}
          containerHeight='200px'
          containerWidth='300px'
          userChannel={userChannel}
          {...rest}
        />
      ) : null}
    </>
  );
}
