// @ts-strict-ignore
import React from 'react';
import styled from '@emotion/styled';
import { color, pxToRem, spacing } from '@grain/grain-ui';
import { CalendarAccount } from '@g/schema';
import CalendarsBox from './CalendarsBox';
import { OauthAccount } from './types';
import { useCalendarQuery } from '@grain/api/graphql/modules/calendar.generated';

export const StyledContainer = styled.div`
  ${color.fg.blackbird};

  font-size: ${pxToRem(14)};
  font-weight: 400;
  line-height: ${pxToRem(20)};

  .title {
    ${color.fg.blackbird};
    font-size: ${pxToRem(14)};
    font-style: normal;
    font-weight: 600;
    line-height: ${pxToRem(18)};
  }

  .subtitle {
    margin-top: 8px;
    margin-bottom: 16px;
    ${color.fg.crow};
    font-size: ${pxToRem(14)};
    font-style: normal;
    font-weight: 400;
    line-height: ${pxToRem(18)};
  }

  .email {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

    svg {
      ${spacing.mr2};
      height: 16px;
      width: 16px;
    }
  }
`;

export const StyledMissingScopeInfo = styled.div`
  display: flex;
  padding: 16px 24px;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid ${color.gull};
  ${color.bg.goose};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  ${spacing.mt6};
  ${color.fg.blackbird};

  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 20px;
    }
  }

  .text {
    font-feature-settings:
      'cpsp' on,
      'case' on,
      'clig' off,
      'liga' off;
    font-size: ${pxToRem(14)};
    font-style: normal;
    font-weight: 400;
    line-height: ${pxToRem(18)};
  }

  .action {
    margin-left: auto;
  }
`;

export const StyledUpgradeText = styled.span`
  ${color.pigeon};
`;

type CalendarStatusProps = {
  accounts: OauthAccount[];
};

export default function CalendarStatus({ accounts }: CalendarStatusProps) {
  const [calendarAccounts, setCalendarAccounts] = React.useState(null);

  useCalendarQuery({
    skip: Boolean(!accounts),
    onCompleted: data => {
      setCalendarAccounts(data?.calendar);
    }
  });

  return (
    <StyledContainer>
      <div className='title'>Calendars</div>
      <div className='subtitle' css={['margin-bottom: 24px !important;']}>
        Choose the calendars with meetings you want to sync with Grain
      </div>
      {accounts.map((oauthAccount: OauthAccount) => (
        <CalendarsBox
          key={oauthAccount.email}
          calendarAccount={calendarAccounts?.accounts?.find(
            (account: CalendarAccount) =>
              account.email === oauthAccount.email &&
              account.provider === oauthAccount.provider
          )}
          oauthAccount={oauthAccount}
          showInfoBox={accounts.length > 1 || !oauthAccount.calendarConnected}
        />
      ))}
    </StyledContainer>
  );
}
