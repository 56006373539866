import cx from 'classnames';
import styled from '@emotion/styled';
import { type SerializedStyles } from '@emotion/react';
import { type MutableRefObject, useCallback, useEffect, useState } from 'react';
import { getBasePublicPath, pxToRem } from '@grain/grain-ui';
import { Button, Icon16, theme } from '@grain/grain-ui/v4';

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;

  background: linear-gradient(
    69deg,
    rgba(33, 33, 33, 0.94) 0%,
    rgba(38, 38, 38, 0.94) 100%
  );
  backdrop-filter: blur(5px);
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 40px;
  right: 40px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  max-width: 424px;
  width: calc(100% - 80px);
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;

  opacity: 1;
  transform: translateY(0);
  transition:
    opacity ease-out 0.3s,
    transform ease-out 0.5s;

  &.hidden {
    opacity: 0;
    transform: translateY(20px);
  }

  &.hiding {
    transition:
      opacity ease-in 0.5s,
      transform ease-in 0.5s;
    transform: translateY(40px);
  }
`;

const LogoAndText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 52px;
`;

const Logo = styled.img`
  width: 125px;
  margin: 0 auto;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing.md};
  text-align: center;
`;

const Title = styled.div`
  ${theme.tokens.typography.h1};
  color: ${theme.primitives.color.white};
`;

const Body = styled.div`
  ${theme.tokens.typography.b1[400]};
  line-height: ${pxToRem(24)};
  color: rgba(255, 255, 255, 0.7);
`;

const BottomLeftDecoration = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const BottomRightDecoration = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export type ExitFunction = () => Promise<void>;

type DarkOverlayProps = {
  onClose: () => void;
  title: string;
  body: string;
  children?: React.ReactNode;
  bottomLeftDecoration?: React.ReactNode;
  bottomRightDecoration?: React.ReactNode;
  overlayStyles?: SerializedStyles;
  exitFunctionRef?: MutableRefObject<ExitFunction | undefined>;
};

export function DarkOverlay({
  onClose,
  title,
  body,
  children,
  bottomLeftDecoration,
  bottomRightDecoration,
  overlayStyles,
  exitFunctionRef
}: DarkOverlayProps) {
  const [contentHidden, setContentHidden] = useState(true);
  const [hidingContent, setHidingContent] = useState(false);

  useEffect(() => {
    setTimeout(() => setContentHidden(false), 100);
  }, []);

  const fadeOutContent: ExitFunction = useCallback(() => {
    setHidingContent(true);
    setContentHidden(true);
    return new Promise(resolve => {
      setTimeout(() => resolve(), 1000);
    });
  }, []);

  // This component tells its parent how to fade out its content with this
  // function.  The parent might want to fade it sometimes, other times not.
  if (exitFunctionRef) {
    exitFunctionRef.current = fadeOutContent;
  }

  return (
    <Overlay css={overlayStyles}>
      <CloseButton
        size='xl'
        variant='overlay'
        onClick={onClose}
        textLabelProps={{ startIcon: Icon16.XSm }}
      />
      <Content className={cx({ hidden: contentHidden, hiding: hidingContent })}>
        <LogoAndText>
          <Logo
            src={`${getBasePublicPath()}/images/brand/grain-primary-white-text.svg`}
          />
          <TextContent>
            <Title>{title}</Title>
            <Body>{body}</Body>
          </TextContent>
        </LogoAndText>
        {children}
      </Content>
      {bottomLeftDecoration && (
        <BottomLeftDecoration>{bottomLeftDecoration}</BottomLeftDecoration>
      )}
      {bottomRightDecoration && (
        <BottomRightDecoration>{bottomRightDecoration}</BottomRightDecoration>
      )}
    </Overlay>
  );
}
