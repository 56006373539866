import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { color, colors, font, spacing, media } from '@grain/styles/constants';
import { flex, pxToRem } from '@grain/grain-ui';

export const StyledContentWrapper = styled.div`
  ${flex.direction.column};
  ${spacing.px7};
`;

export const StyledContainer = styled.div`
  ${flex.direction.row};
  ${flex.justifyContent.spaceBetween};
  ${flex.alignItems.center};

  .option-select-button {
    background-color: ${colors.gull} !important;
    border: none !important;
  }

  .option-select-arrow {
    margin-right: 16px !important;
  }

  .trimmed-select {
    .option-select-options-list-wrapper {
      ul {
        overflow-y: auto !important;
        max-height: 142px !important;
      }
    }
  }
`;

export const StyledSeparator = styled.div`
  height: 1px;
  background: ${colors.gull};
  margin: 0 -32px;
`;

export const StyledSection = styled.div`
  ${flex.direction.column};
  gap: 24px;
  margin: 24px 0;
`;

export const Description = styled.div`
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(18)};
  ${color.blackbird};
  font-weight: 400;
`;

export const StyledSectionMainDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const StyledSectionSubHeader = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

export const StyledRecorderSection = styled.div`
  ${flex.direction.row};
  ${flex.alignItems.center};
  ${font.bodyM};

  > div {
    width: 33%;

    ${media.small} {
      width: auto;
    }
  }

  > span {
    ${spacing.pl2};
    font-weight: 400;
  }
`;

export const StyledSectionLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(18)};
  font-feature-settings:
    'case' on,
    'cpsp' on;
  ${color.blackbird};
  margin-bottom: 8px;
`;

export const StyledSubSectionLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: ${pxToRem(18)};
`;

export const StyledSubSectionLabelInfo = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(16)};
  ${color.crow};
  .option-reset {
    cursor: pointer;
    ${color.graieen};
  }
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const StyledSectionWithUpgradeLabel = styled(StyledSectionLabel)`
  ${flex.direction.row};
  ${flex.alignItems.center};
  ${flex.justifyContent.spaceBetween};
`;

export const StyledRow = styled.div`
  display: flex;
  padding: 0 16px;
  ${spacing.py1};
  justify-content: space-between;

  &:nth-of-type(even) {
    background: ${colors.goose};
  }
`;

export const StyledRowLabel = styled.div`
  ${font.bodyM};
  font-weight: 600;
  ${color.crow};
  min-width: 40px;
`;

export const StyledRowText = styled.div`
  ${font.bodyM};
  font-weight: normal;
  ${color.crow};
`;

export const StyledInfoText = styled.div`
  ${font.bodyM};
  font-weight: normal;
  ${color.pigeon};
  text-align: center;
  width: 100%;
  ${spacing.mt5};

  & > a {
    cursor: pointer;
  }
`;

export const StyledAnchor = styled.a`
  ${color.graieen};
  font-weight: 500;
  align-items: center;

  &:hover {
    ${color.graieen};
    cursor: pointer;
    text-decoration: underline;
  }

  svg {
    vertical-align: text-top;
  }
`;

export const contentStyles = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${spacing.px8};
  ${spacing.py7};
`;

export const StyledScrollContainer = styled.div`
  overflow-y: auto;
  margin-top: 24px;
  margin-right: -${spacing[8]};
  padding-right: ${spacing[8]};
`;

export const StyledOption = styled.label`
  align-items: center;
  display: flex;
  margin: 14px 0;

  input {
    &:disabled ~ .box {
      background-color: ${colors.gull} !important;
      border-color: ${colors.gull} !important;
    }
  }
`;

export const StyledOptionDivider = styled.div`
  border: 1px solid #f0f0f0;
  ${spacing.my4};
  margin-right: -16px;
`;

export const StyledOptionTitle = styled.div`
  ${color.blackbird};
  ${spacing.ml2};
  ${flex.direction.column};
  font-size: ${pxToRem(14)};

  span {
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(16)};
    ${color.crow};
    .option-reset {
      cursor: pointer;
      ${color.graieen};
    }
  }

  .option-subtitle {
    ${color.graieen};
    font-size: calc(12rem / 16);
  }

  .option-label {
    align-items: center;
    display: flex;
    svg {
      ${spacing.ml1};
    }
  }
`;

export const StyledOptionBold = styled.strong`
  font-weight: 600;
`;

export const StyledOptionDescription = styled.div`
  font-size: ${pxToRem(14)};
  line-height: normal;

  & > .learn-more {
    font-size: ${pxToRem(12)};
    ${color.graieen};

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

export const StyledDivider = styled.div`
  border-bottom: 1px solid ${colors.gull};
  margin: 32px -32px;
`;

export const StyledDescription = styled.div`
  ${color.blackbird};
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(18)};
  margin-top: 8px;
`;

export const StyledTemplateCardWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid ${colors.gull};
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`;

export const StyleTemplateInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: ${pxToRem(14)};
  span {
    font-weight: 600;
  }

  p {
    font-weight: 400;
    margin: 0;
  }
`;

export const StyledTemplateOtherInfo = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const StyledTemplateSectionInfo = styled.div`
  display: flex;
  font-weight: 500;
  font-size: ${pxToRem(12)};
  align-items: center;
  cursor: help;

  span {
    margin-right: 8px;
  }
`;
