import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalTitle } from '@grain/grain-ui';
import PaymentForm from '~/modules/subscriptions/PaymentForm';
import { spacing } from '@grain/styles/constants';

UpdatePaymentModal.propTypes = {
  onClose: PropTypes.func.isRequired
};
export default function UpdatePaymentModal({ onClose, ...paymentFormProps }) {
  return (
    <Modal
      closable
      maskClosable={false}
      css={['width: 516px; overflow: auto;']}
      onCancel={onClose}
    >
      <ModalTitle title='Update payment method' centered />
      <div css={[spacing.px8, spacing.pb8]}>
        <PaymentForm {...paymentFormProps} />
      </div>
    </Modal>
  );
}
