import { useNavigate } from 'react-router-dom';
import { StepComponentProps } from '..';

import {
  StyledCard,
  StyledCardWrapper,
  StyledCardHeaderText,
  StyledCardSubHeaderText
} from '../../Onboarding/styles';

import { useUserRegistration } from '~/pages/Onboarding/hooks';

import { Button } from '@grain/grain-ui';
import cookies from 'browser-cookies';

export default function RequestedWorkspace({
  registrationData,
  cancelJoinWorkspaceRequest,
  forceToJoinWorkspace,
  onNext
}: StepComponentProps) {
  const navigate = useNavigate();
  const requestedWorkspace = registrationData.requested_workspace;
  const inviteWs = registrationData.accessible_workspaces.find(
    ({ is_invite_ws }: { is_invite_ws: boolean }) => is_invite_ws
  );
  const { cancelRequestToJoinWorkspace, joinWorkspaceById } =
    useUserRegistration();

  const handleCancelRequest = () => {
    if (!requestedWorkspace) return;

    if (!inviteWs) {
      cancelRequestToJoinWorkspace(
        requestedWorkspace?.id,
        cancelJoinWorkspaceRequest,
        forceToJoinWorkspace
      );
    } else {
      // clear cookies and redirect to marketing website
      cookies.erase('grain_logged_in');
      cookies.erase('_grain_key');
      window.sessionStorage?.clear();

      // We should clean our localStorage storage
      // But keep user_id to be consumed on the marketing website
      Object.keys(window.localStorage).forEach(localStorageKey => {
        if (localStorageKey !== 'user_id') {
          window.localStorage.removeItem(localStorageKey);
        }
      });

      navigate('/');
    }
  };

  const handleJoinWorkspace = async () => {
    if (!inviteWs) return;
    joinWorkspaceById(inviteWs.id, null, undefined, onNext);
  };

  const hasInvite = !!inviteWs;

  return (
    <StyledCardWrapper>
      {requestedWorkspace && (
        <StyledCard>
          <StyledCardHeaderText css={['text-align: center;']}>
            {hasInvite
              ? 'You have a pending request'
              : `We sent your request to join ${requestedWorkspace?.name} to the workspace admin`}
          </StyledCardHeaderText>
          <StyledCardSubHeaderText css={['text-align: center;']}>
            {hasInvite ? (
              <>
                You have a pending join request for{' '}
                <b>{requestedWorkspace?.name}</b>. Are you sure you want to join{' '}
                <b>{inviteWs?.name}</b>?
              </>
            ) : (
              `We'll send you an email and give you acccess to the ${requestedWorkspace?.name} workspace when your request has been approved.`
            )}
          </StyledCardSubHeaderText>
          <div css={['display: flex; gap: 16px;']}>
            <Button
              onClick={handleCancelRequest}
              variant='secondary'
              size='normal'
            >
              {hasInvite ? 'Cancel' : 'Cancel request'}
            </Button>
            {hasInvite && (
              <Button
                onClick={handleJoinWorkspace}
                variant='primary'
                size='normal'
              >
                Join workspace
              </Button>
            )}
          </div>
        </StyledCard>
      )}
    </StyledCardWrapper>
  );
}
