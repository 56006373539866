import styled from '@emotion/styled';
import { colors, media, font } from '@grain/styles/constants';

export const StyledHandle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.crow};
  cursor: grab;

  width: 28px;

  &:before {
    position: absolute;
    left: -4px;
    top: -4px;
    bottom: -4px;
    right: -4px;
    content: '';
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 12px;
  display: flex;
  align-items: center;
  ${font.bodyS};
  font-weight: 500;
  user-select: none;

  &.clip {
    margin-bottom: 4px;
  }
  &.section {
    margin-bottom: 16px;
  }

  margin-right: ${props => (props.isClip ? 36 : 28)}px;

  // Set clips and sections the same width
  ${media.small} {
    margin-top: ${props => (props.isClip ? 12 : 28)}px;
    margin-right: ${props => (props.isClip ? 28 : 28)}px;
    margin-left: ${props => (props.isClip ? 14 : 0)}px;
  }

  // For clips, the total size on the left is 56px. Half
  // That space is used up by the drag handle. Other half margin.
  // For non-clips, the entire space is used up by drag handle, no margin.
  margin-left: ${props => (props.isClip ? 28 : 0)}px;

  ${StyledHandle} {
    visibility: hidden;
  }
  ${media.small} {
    ${StyledHandle} {
      visibility: visible;
    }
  }

  &:hover:not(.disable-hover) {
    ${StyledHandle} {
      visibility: visible;
    }
  }

  ${props =>
    props.highlighted &&
    `
      // background-color: ${colors.gull};
      background-color: ${colors.gull};
      filter: brightness(90%);
      ${StyledHandle} {
        visibility: visible;
      }
    `}
`;
