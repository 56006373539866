import styled from '@emotion/styled';
import { pxToRem } from '@grain/grain-ui';
import { theme } from '@grain/grain-ui/v4';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 560px;
  margin: 96px 50px 0 50px;
  align-self: center;
`;

const Image = styled.img`
  width: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  align-items: center;
`;

const Title = styled.div`
  ${theme.tokens.typography.h1};
  color: ${theme.primitives.color.black};
  text-align: center;
`;

const Description = styled.div`
  margin-top: ${theme.tokens.spacing.md};
  ${theme.tokens.typography.b1[400]};
  line-height: ${pxToRem(24)};
  color: ${theme.tokens.color.textSecondary};
  text-align: center;
`;

const Actions = styled.div`
  margin-top: ${theme.tokens.spacing['2xl']};
  display: flex;
  gap: ${theme.tokens.spacing.sm};
`;

export const ImageEmptyView = {
  Wrapper,
  Image,
  InnerWrapper,
  Title,
  Description,
  Actions
};
