import styled from '@emotion/styled';
import { theme } from '@grain/grain-ui/v4';

import CRMGraphic from './graphics/crm-graphic.svg?react';
import CoachingFlyover from './graphics/coaching-flyover.svg?react';
import TrackersFlyover from './graphics/trackers-flyover.svg?react';
import DealsFlyover from './graphics/deals-flyover.svg?react';

const GenericColumnText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing['2xs']};
`;

const TopColumnText = styled(GenericColumnText)`
  width: 212px;
  padding: ${theme.tokens.spacing.lg} ${theme.tokens.spacing.xl};
`;

const BottomColumnText = styled(GenericColumnText)`
  position: absolute;
  bottom: 0;

  // Extend the width of the text box over the borders of the column
  // (otherwise the text doesn't wrap correctly):
  right: 1px;
  left: -1px;
  width: calc(100% + 2px);

  padding: ${theme.tokens.spacing['2xs']} ${theme.tokens.spacing.xl}
    ${theme.tokens.spacing.lg};
`;

const ColumnTitleText = styled.div`
  ${theme.tokens.typography.b2[500]};
  line-height: 20px;
`;

const TopColumnBodyText = styled.div`
  ${theme.tokens.typography.b3[400]};
  line-height: 18px;
`;

const BottomColumnBodyText = styled.div`
  ${theme.tokens.typography.b4[400]};
  line-height: 18px;
`;

// This creates a "fade" effect between the graphic and the text below it:
const Blur = styled.div`
  position: absolute;
  bottom: 80px;
  width: 100%;
  height: 20px;

  background: linear-gradient(
    180deg,
    transparent 0%,
    ${theme.primitives.color.grey50} 50%,
    ${theme.primitives.color.grey50} 100%
  );
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 632px;
`;

const BottomRow = styled.div`
  display: flex;
`;

const GenericColumn = styled.div`
  position: relative;
  overflow: hidden;
  margin: ${theme.tokens.spacing.xs};
  border: 1px solid;
  border-color: ${theme.tokens.color.borderSecondary};
  border-radius: ${theme.tokens.radii.lg};

  @property --col-bg-color-1 {
    syntax: '<color>';
    initial-value: ${theme.tokens.color.surfaceSecondary};
    inherits: false;
  }

  @property --col-bg-color-2 {
    syntax: '<color>';
    initial-value: ${theme.tokens.color.surfaceSecondary};
    inherits: false;
  }

  color: ${theme.tokens.color.textSecondary};

  transition:
    border-color 0.2s ease,
    color 0.2s ease,
    --col-bg-color-1 0.2s ease,
    --col-bg-color-2 0.2s ease;

  &:focus,
  &:hover {
    border-color: ${theme.primitives.color.grey500};
    color: ${theme.tokens.color.textPrimary};
  }
`;

const TopColumn = styled(GenericColumn)`
  display: flex;
  gap: 52px;
  height: 181px;

  background: linear-gradient(
    95deg,
    var(--col-bg-color-1) 0%,
    var(--col-bg-color-2) 100%
  );

  > svg {
    .send-button {
      filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0));
      transition: filter 0.2s ease;
    }

    .send-icon {
      stroke: #949494;
      transition: stroke 0.2s ease;
    }

    .send-menu {
      transform: translateY(4px);
      filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.04));
      transition:
        transform 0.2s ease,
        filter 0.2s ease;
    }

    .hubspot-icon,
    .hubspot-text,
    .salesforce-icon,
    .salesforce-text {
      opacity: 0.5;
      transition: opacity 0.2s ease;
    }
  }

  &:focus,
  &:hover {
    --col-bg-color-2: ${theme.primitives.color.grey300};

    > svg {
      .send-button {
        filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.08));
      }

      .send-icon {
        stroke: #141414;
      }

      .send-menu {
        transform: translateY(0px);
        filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.08));
      }

      .hubspot-icon,
      .hubspot-text,
      .salesforce-icon,
      .salesforce-text {
        opacity: 1;
      }
    }
  }
`;

const BottomColumn = styled(GenericColumn)`
  height: 176px;
  flex: 1;

  background: linear-gradient(
    0deg,
    var(--col-bg-color-1) 50%,
    var(--col-bg-color-2) 100%
  );

  > svg {
    position: absolute;
    top: 0;
    left: 0;

    opacity: 0.8;
    transform: translateY(4px);

    .card-shadow {
      filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0));
      transition: filter 0.2s ease;
    }

    transition:
      opacity 0.2s ease,
      transform 0.2s ease;
  }

  ${Blur} {
    bottom: 44px;
    height: 32px;
    transition:
      bottom 0.2s ease,
      height 0.2s ease;
  }

  ${BottomColumnText} {
    // Background should eclipse graphic
    background-color: ${theme.tokens.color.surfaceSecondary};

    transform: translateY(36px);
    transition: transform 0.2s ease;
  }

  ${BottomColumnBodyText} {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:focus,
  &:hover {
    --col-bg-color-2: ${theme.primitives.color.grey200};

    ${Blur} {
      bottom: 80px;
      height: 20px;
    }

    > svg {
      opacity: 1;
      transform: translateY(0px);

      .card-shadow {
        filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.08));
      }
    }

    ${BottomColumnText} {
      transform: translateY(0px);
    }

    ${BottomColumnBodyText} {
      opacity: 1;
    }
  }
`;

const BottomMiddleColumn = styled(BottomColumn)`
  ${Blur} {
    height: 48px;
  }
`;

export function BusinessBentoBox() {
  return (
    <Container>
      <TopColumn tabIndex={0}>
        <TopColumnText>
          <ColumnTitleText>CRM integration</ColumnTitleText>
          <TopColumnBodyText>
            Auto-detect customer meetings and automatically sync AI notes to
            Contacts in your CRM.
          </TopColumnBodyText>
        </TopColumnText>
        <CRMGraphic />
      </TopColumn>
      <BottomRow>
        <BottomColumn tabIndex={0}>
          <CoachingFlyover />
          <Blur />
          <BottomColumnText>
            <ColumnTitleText>Coaching</ColumnTitleText>
            <BottomColumnBodyText>
              Coach & onboard reps fast with automated scorecards.
            </BottomColumnBodyText>
          </BottomColumnText>
        </BottomColumn>
        <BottomMiddleColumn tabIndex={0}>
          <TrackersFlyover />
          <Blur />
          <BottomColumnText>
            <ColumnTitleText>Trackers</ColumnTitleText>
            <BottomColumnBodyText>
              Never miss insights, track keywords in meetings.
            </BottomColumnBodyText>
          </BottomColumnText>
        </BottomMiddleColumn>
        <BottomColumn tabIndex={0}>
          <DealsFlyover />
          <Blur />
          <BottomColumnText>
            <ColumnTitleText>Deals</ColumnTitleText>
            <BottomColumnBodyText>
              See deal status, risks, and momentum in one place.
            </BottomColumnBodyText>
          </BottomColumnText>
        </BottomColumn>
      </BottomRow>
    </Container>
  );
}
