import styled from '@emotion/styled';
import { colors, media } from '@grain/styles/constants';

export const StyledFooter = styled.div`
  height: 74px;
  ${media.small} {
    height: 48px;
  }
  background-color: ${colors.raven};
  display: flex;
  align-items: center;
  background-color: ${colors.raven};
  color: ${colors.swan};
  display: flex;
  flex-shrink: 0;
  font-size: calc(12rem / 16);
  font-weight: 500;
  margin-top: 32px;
  padding: 0 56px;
`;
