import styled from '@emotion/styled';
import {
  getBasePublicPath,
  StepsIndicator,
  Icon,
  useAnalytics
} from '@grain/grain-ui';
import {
  LeftSide,
  RightSide,
  Title,
  Subtitle,
  Description,
  Buttons,
  SlideButton,
  StepComponentProps,
  StyledBullet,
  StyledOverlay
} from '../../../modals/LegacyOnboarding';
import { getCalendlyUrl } from 'pages/Onboarding/Calendly/hooks';
import { useExtra } from '@grain/components/support/extra';
import { OnboardingRoleKey } from 'pages/Onboarding/ChooseRole';
import { useMyself } from '@grain/api/auth';

const StyledHeading = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const bullets = [
  {
    icon: <Icon.Tag02 />,
    description: (
      <>
        Get personalized pricing information including <span>discounts</span>
      </>
    )
  },
  {
    icon: <Icon.MessageQuestionCircle />,
    description: (
      <>
        Setup your team and get your <span>questions answered</span>
      </>
    )
  },
  {
    icon: <Icon.Lightbulb02 />,
    description: (
      <>
        Explore use cases and get <span>expert tips</span>
      </>
    )
  }
];

const ScheduleOnboarding = ({
  totalSteps,
  step,
  showSetupComplete,
  handleDone,
  onlyCalendarPerms,
  isOnPaidPlan
}: StepComponentProps) => {
  const { extra, loading: extraLoading, saveExtra } = useExtra();
  const { myself } = useMyself();
  const { trackEvent } = useAnalytics();

  const email = myself?.user?.email || '';
  const calendlyUrl = getCalendlyUrl(isOnPaidPlan, email);

  const handleBookTime = () => {
    trackEvent(
      'Onboarding Booked',
      {
        source: 'Onboarding',
        category: 'onboarding'
      },
      ['user', 'workspace']
    );
    saveExtra({ ...extra, hasScheduledSalesMeeting: true });
    window.open(calendlyUrl, '_blank');
  };

  const hasScheduled = !extraLoading && extra?.hasScheduledSalesMeeting;
  const existingRole = window.localStorage.getItem(OnboardingRoleKey);
  const role = existingRole ? JSON.parse(existingRole) : null;
  const isManager = role?.ob_survey_selected_work_type === 'Sales leader';

  const handleComplete = () => {
    // reset in case they need to book again
    saveExtra({ ...extra, hasScheduledSalesMeeting: false });
    handleDone();
  };

  return (
    <>
      <LeftSide width={378}>
        <div css={['width: 100%']}>
          {showSetupComplete && <Subtitle>Setup Complete</Subtitle>}
          <Title>Schedule time with a Grain sales expert</Title>
          <Description>
            {`Schedule a brief demo to ${
              isManager
                ? 'get your sales team setup for success.'
                : 'learn how to use Grain like a pro.'
            }`}
          </Description>
        </div>
        <Buttons>
          <SlideButton variant='secondary' size='large' onClick={handleDone}>
            No thanks
          </SlideButton>
          <SlideButton
            variant='primary'
            size='large'
            onClick={hasScheduled ? handleComplete : handleBookTime}
          >
            {hasScheduled ? 'Done' : 'Book a time'}
          </SlideButton>
        </Buttons>
      </LeftSide>
      <RightSide
        width={542}
        background={`${getBasePublicPath()}/images/noise-and-texture.png`}
      >
        <StyledOverlay>
          <StyledHeading>Why book a demo?</StyledHeading>
          {bullets.map(({ icon, description }, index) => (
            <StyledBullet key={index}>
              {icon}
              <div>{description}</div>
            </StyledBullet>
          ))}
        </StyledOverlay>
        {onlyCalendarPerms && (
          <div className='steps-indicator'>
            <StepsIndicator steps={totalSteps} step={step} />
          </div>
        )}
      </RightSide>
    </>
  );
};

export default ScheduleOnboarding;
