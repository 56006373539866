import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@grain/api/graphql';
import { importRecordingMutation } from '@grain/api/graphql/mutations';
import { useNavigate } from 'react-router-dom';
import { getProcessingThumbSrc } from '~/support/image';
import { RecordingState } from '@grain/api/schema.generated';
import { useDeleteRecordingLazy } from '~/modules/recording/hooks';
import {
  ImportRecordingTile,
  RecordingTile,
  useShowToast
} from '@grain/grain-ui';
import { useRecordingUpdatedSubscription } from './import.generated';

ImportCard.propTypes = {
  importableRecording: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    source: PropTypes.string,
    startDatetime: PropTypes.string,
    duration: PropTypes.number,
    playUrl: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    recording: PropTypes.object
  }).isRequired,
  openRecordingShare: PropTypes.func.isRequired
};

export default function ImportCard({
  importableRecording,
  openRecordingShare
}) {
  const navigate = useNavigate();
  const [importRecording] = useMutation(importRecordingMutation);
  const [importableRec, setImportableRec] = React.useState(importableRecording);

  const handleDeleteRecording = useDeleteRecordingLazy();
  const showToast = useShowToast();

  useRecordingUpdatedSubscription({
    variables: { id: importableRec?.recording?.id },
    onData({ data }) {
      const recording = data.data.recordingUpdated;
      setImportableRec({
        ...importableRecording,
        recording: recording
      });
    }
  });

  async function handleImport() {
    // Kick off the import mutation

    const importedRecording = await importRecording({
      variables: {
        importableRecordingId: importableRec?.id
      }
    });

    showToast({
      content: 'Importing recording',
      uniqueId: 'import-recording-toast',
      type: 'success'
    });

    setImportableRec({
      ...importableRecording,
      recording: importedRecording?.data?.importRecording
    });
  }

  const handlePreview = () => {
    window?.open(importableRec?.playUrl, '_blank');
  };

  const handleOpen = () => {
    if (!importableRec?.recording?.id) return;
    navigate(importableRec?.recording?.recordingPath);
  };

  const handleShare = () => {
    if (!importableRec?.recording?.id) return;

    openRecordingShare(importableRec?.recording?.id);
  };

  const handleDelete = async () => {
    if (!importableRec?.recording?.id) return;

    await handleDeleteRecording(importableRec?.recording);
    setImportableRec({
      ...importableRecording,
      recording: null
    });
  };

  const isProcessed =
    importableRec?.recording?.state === RecordingState.Processed ||
    importableRec?.recording?.state === RecordingState.Active;
  return isProcessed ? (
    <RecordingTile
      recordingUrl={importableRec?.recording?.recordingPath}
      staticUrl={importableRec?.recording?.fullJpegThumbnailUrl}
      animatedUrl={importableRec?.recording?.animatedPreviewUrl}
      title={importableRec?.recording?.title}
      locked={importableRec?.recording?.locked}
      durationMs={importableRec?.recording?.duration}
      when={new Date(importableRec?.recording?.startDatetime)}
      tags={[]}
      sharedWithWorkspace={importableRec?.recording?.workspaceShared}
      sharedUserCount={importableRec?.recording?.sharedUsersCount - 1}
      onOpen={handleOpen}
      onDelete={handleDelete}
      onShare={handleShare}
      isOwner={importableRec?.recording?.owner?.isSelf}
    />
  ) : (
    <ImportRecordingTile
      staticThumbnailUrl={
        importableRec?.thumbnailUrl || getProcessingThumbSrc(importableRec?.id)
      }
      title={importableRec?.title}
      durationMs={importableRec?.duration}
      when={new Date(importableRec?.startDatetime)}
      onImport={handleImport}
      onPreview={handlePreview}
      state={importableRec?.recording?.state || importableRec?.state}
      sharedWithWorkspace={importableRec?.recording?.workspaceShared}
      sharedUserCount={importableRec?.recording?.sharedUsersCount - 1}
    />
  );
}
