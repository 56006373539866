import styled from '@emotion/styled';
import { StepComponentProps } from './index';
import { Button, theme } from '@grain/grain-ui/v4';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 490px;
  width: 100%;
  gap: 12px;
  margin: 28px auto 0 auto;
  padding: 0 ${theme.tokens.spacing.lg};
`;

const Title = styled.h1`
  ${theme.tokens.typography.h1}
  text-align: center;
  margin: 0;
`;

const Description = styled.p`
  ${theme.tokens.typography.b1[400]}
  text-align: center;
  color: ${theme.tokens.color.textSecondary};
  margin: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  gap: 16px;
  margin-top: 12px;
`;

export const RequestWorkEmail = ({
  bypassFreeDomainGating
}: StepComponentProps) => {
  return (
    <Container>
      <Title>
        Sign up with a work email to enable unlimited meeting recordings
      </Title>
      <Description>
        Otherwise, you’ll have a 20-meeting recording limit.
      </Description>
      <ButtonContainer>
        <Button
          fullWidth
          variant='stroked'
          size='xl'
          as={Link}
          to='/app/signup?action=web_register&message=work_email'
        >
          Sign up with work email
        </Button>
        <Button
          fullWidth
          variant='ghost'
          size='xl'
          onClick={bypassFreeDomainGating}
        >
          Continue with personal email
        </Button>
      </ButtonContainer>
    </Container>
  );
};
