import { WorkspaceSubscription } from '@grain/api/schema.generated';
import {
  useUpcomingWorkspacePlan,
  useWorkspacePlan
} from '@grain/components/Subscriptions/hooks';
import { useWorkspaceWithMembers } from '@grain/components/Workspace/hooks';
import {
  useWorkspaceInvoicesQuery,
  WorkspaceInvoicesQuery
} from './workspaceInvoices.generated';
import { useRef, useState, useEffect } from 'react';

export type WorkspaceInvoices =
  WorkspaceInvoicesQuery['workspace']['workspaceSubscription']['invoices'];

export const useBillingData = () => {
  const fetchedInvoices = useRef(false);
  const [invoices, setInvoices] = useState<WorkspaceInvoices>([]);

  const {
    workspace,
    loading: workspaceLoading,
    error: workspaceError
  } = useWorkspaceWithMembers();

  const {
    subscriptionPlan: workspacePlan,
    loading: workspacePlanLoading,
    error: workspacePlanError
  } = useWorkspacePlan();

  const {
    data: invoiceData,
    loading: isLoadingInvoices,
    error: invoiceError
  } = useWorkspaceInvoicesQuery({
    skip: Boolean(fetchedInvoices.current)
  });

  useEffect(() => {
    if (!invoiceData) return;
    const invoicesList =
      invoiceData.workspace.workspaceSubscription.invoices ?? [];
    setInvoices(invoicesList);
    fetchedInvoices.current = true;
  }, [invoiceData, invoiceError]);

  const {
    name: planName,
    shortName,
    monthlyPrice,
    yearlyPrice
  } = workspacePlan ?? {};
  const { subscriptionPlan: upcomingPlan } = useUpcomingWorkspacePlan();

  const { workspaceSubscription = {} as WorkspaceSubscription } =
    workspace ?? {};

  const {
    trial,
    autoRenew,
    renewalCount = 0,
    planDetails,
    seatQuantity,
    billingPeriod,
    cardBrand,
    cardLastFour,
    billingEmail,
    renewalPrice,
    renewalDate,
    renewalBillingPeriod
  } = workspaceSubscription ?? {};

  return {
    workspace,
    workspaceLoading,
    workspaceError,
    workspacePlan,
    workspacePlanLoading,
    workspacePlanError,
    planName,
    shortName,
    monthlyPrice,
    yearlyPrice,
    upcomingPlan,
    workspaceSubscription,
    trial,
    autoRenew,
    renewalCount,
    planDetails,
    seatQuantity,
    billingPeriod,
    cardBrand,
    cardLastFour,
    billingEmail,
    renewalPrice,
    renewalDate,
    renewalBillingPeriod,
    isLoadingInvoices,
    invoices,
    invoiceError
  };
};
