import styled from '@emotion/styled';

export const StyledShadow = styled.div`
  position: absolute;
  pointer-events: none;
  opacity: ${props => (props.isVisible ? 1 : 0)};

  left: 0;
  right: 12px; // scrollbar margin
  height: 64px; // should be same as single item

  ${props => (props.direction === 'up' ? 'top: 0px' : 'bottom: 0px;')};
  background: linear-gradient(
    ${props => (props.direction === 'down' ? 'to bottom' : 'to top')},
    rgba(255, 255, 255, 0),
    white
  );
`;
