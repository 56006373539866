import styled from '@emotion/styled';
import { color, font, spacing } from 'base/css';

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${spacing.px7};
  ${spacing.pt6};
  ${spacing.pb7};
  gap: 32px;
`;

export const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledInputLabel = styled.label`
  ${font.v4.c1['600']};
  ${spacing.mb2};
  text-align: left;
`;

export const StyledErrorMessage = styled.div`
  ${font.v4.c1['500']};
  color: ${color.errorbird};
  margin-top: 4px;
`;
