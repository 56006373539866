import * as Types from '@grain/api/schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@grain/api/graphql';
const defaultOptions = {} as const;
export type IntelligenceAskQueryEvaluateMutationVariables = Types.Exact<{
  dealId: Types.Scalars['ID']['input'];
  limitedEvaluation?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  query: Types.Scalars['NonEmptyString']['input'];
}>;


export type IntelligenceAskQueryEvaluateMutation = { __typename?: 'RootMutationType', intelligenceAskQueryEvaluate: { __typename?: 'IntelligenceAskQuery', id: string, query: string, result: string | null, status: Types.AskQueryStatus } };

export type IntelligenceAskQueryUpdatedSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type IntelligenceAskQueryUpdatedSubscription = { __typename?: 'RootSubscriptionType', intelligenceAskQueryUpdated: { __typename?: 'IntelligenceAskQuery', id: string, query: string, result: string | null, status: Types.AskQueryStatus } };


export const IntelligenceAskQueryEvaluateDocument = gql`
    mutation intelligenceAskQueryEvaluate($dealId: ID!, $limitedEvaluation: Boolean, $query: NonEmptyString!) {
  intelligenceAskQueryEvaluate(
    dealId: $dealId
    limitedEvaluation: $limitedEvaluation
    query: $query
  ) {
    id
    query
    result
    status
  }
}
    `;
export type IntelligenceAskQueryEvaluateMutationFn = Apollo.MutationFunction<IntelligenceAskQueryEvaluateMutation, IntelligenceAskQueryEvaluateMutationVariables>;

/**
 * __useIntelligenceAskQueryEvaluateMutation__
 *
 * To run a mutation, you first call `useIntelligenceAskQueryEvaluateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIntelligenceAskQueryEvaluateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [intelligenceAskQueryEvaluateMutation, { data, loading, error }] = useIntelligenceAskQueryEvaluateMutation({
 *   variables: {
 *      dealId: // value for 'dealId'
 *      limitedEvaluation: // value for 'limitedEvaluation'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useIntelligenceAskQueryEvaluateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IntelligenceAskQueryEvaluateMutation, IntelligenceAskQueryEvaluateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<IntelligenceAskQueryEvaluateMutation, IntelligenceAskQueryEvaluateMutationVariables>(IntelligenceAskQueryEvaluateDocument, options);
      }
export type IntelligenceAskQueryEvaluateMutationHookResult = ReturnType<typeof useIntelligenceAskQueryEvaluateMutation>;
export type IntelligenceAskQueryEvaluateMutationResult = Apollo.MutationResult<IntelligenceAskQueryEvaluateMutation>;
export type IntelligenceAskQueryEvaluateMutationOptions = Apollo.BaseMutationOptions<IntelligenceAskQueryEvaluateMutation, IntelligenceAskQueryEvaluateMutationVariables>;
export const IntelligenceAskQueryUpdatedDocument = gql`
    subscription intelligenceAskQueryUpdated {
  intelligenceAskQueryUpdated {
    id
    query
    result
    status
  }
}
    `;

/**
 * __useIntelligenceAskQueryUpdatedSubscription__
 *
 * To run a query within a React component, call `useIntelligenceAskQueryUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIntelligenceAskQueryUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntelligenceAskQueryUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useIntelligenceAskQueryUpdatedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<IntelligenceAskQueryUpdatedSubscription, IntelligenceAskQueryUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useSubscription<IntelligenceAskQueryUpdatedSubscription, IntelligenceAskQueryUpdatedSubscriptionVariables>(IntelligenceAskQueryUpdatedDocument, options);
      }
export type IntelligenceAskQueryUpdatedSubscriptionHookResult = ReturnType<typeof useIntelligenceAskQueryUpdatedSubscription>;
export type IntelligenceAskQueryUpdatedSubscriptionResult = Apollo.SubscriptionResult<IntelligenceAskQueryUpdatedSubscription>;