// @ts-strict-ignore
import React, { useEffect } from 'react';
import {
  InteractionInsights,
  InteractionStatistics
} from '@grain/api/schema.generated';
import { EmptyState } from '../EmptyState';
import styled from '@emotion/styled';
import cloneDeep from 'lodash/cloneDeep';
import {
  Avatar,
  Spinner,
  color,
  flex,
  getSpeakerColor,
  metricStatusColorMap,
  overflowEllipsis,
  resetButtonStyles,
  spacing,
  toHumanTimestamp
} from '@grain/grain-ui';
import {
  SpeakerStatisticsMetricsKeys,
  getMetricStatus
} from '~/support/speakerMetrics';
import type { MetricStatus } from '@grain/grain-ui';
import { css } from '@emotion/react';
import { theme } from '@grain/grain-ui/v4';

const TabRow = styled.div`
  display: flex;
  color: ${color.crow};
  border-bottom: 1px solid ${color.gull};
  font-size: 12px;
  font-weight: 500;
  ${theme.utils.gap('sm')};
  margin-top: 20px;
  ${theme.utils.px('3xl')}
`;

const Tab = styled.button<{ active: boolean }>`
  ${resetButtonStyles};
  cursor: pointer;
  top: 1px;
  border: 1px solid ${color.gull};
  background-color: ${color.goose};
  width: 225px;
  ${spacing.p4}
  border-radius: 8px 8px 0 0;
  text-align: start;

  ${({ active }) =>
    active &&
    css`
      color: ${color.blackbird};
      background: unset;
      background-color: ${color.swan};
      border-bottom: unset;
    `}
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -187px;
  height: 100vh;
  ${spacing.g2};
  ${color.fg.graieen};
`;

const InfoHeader = styled.div`
  ${spacing.pt7}
  ${spacing.pl8}
  ${spacing.pb3}
  font-size: 12px;
  font-weight: 500;
  color: ${color.crow};
`;

const linePositionTop = 110;

const GridContainer = styled.div`
  height: 100%;
  width: 100%;
  .overlay-line {
    right: calc(50% - 140px);
    border-right: dashed 2px ${color.hawk};
    height: calc(100% - ${linePositionTop}px);
    position: absolute;
    z-index: 1;
    top: ${linePositionTop}px;
    width: 1px;
    @media (max-width: 1060px) {
      top: ${linePositionTop + 10}px;
    }
    &.patienceMs {
      right: calc(67% - 203px);
    }
  }

  .grid-row {
    display: grid;
    align-items: center;
    grid-template-columns: 30px 116px 120px auto;
    grid-gap: 8px;
    font-size: 12px;
    ${spacing.py3};
    ${spacing.px8};
    color: ${color.crow};
    border-bottom: 1px solid ${color.gull};
    .bar {
      border-radius: 8px;
      height: 6px;
      background-color: ${color.hawk};
      max-width: 100%;
      &.right {
        position: relative;
        left: 33%;
        width: 67% !important;
      }
    }
    .circle-cell {
      display: flex;
      ${flex.alignItems.center}
    }
  }

  .best-practice {
    .top {
      display: flex;
      ${flex.justifyContent.spaceBetween};
      ${spacing.pb2};
    }
    .bottom {
      .circle {
        position: relative;
        top: -9px;
        right: calc(4px - 50%);
        &.patienceMs {
          right: calc(4px - 33%);
        }
      }
    }
  }
`;

export type InteractionProps = {
  filter: string;
  onFiltersClear: () => void;
  interactionLoading: boolean;
  interactionData: InteractionInsights;
};

type CurrentSection =
  | 'speakerPercentage'
  | 'longestMonologueMs'
  | 'fillerPhrasesPerMinute'
  | 'wordsPerMinute'
  | 'patienceMs';

type GridHeader = {
  header: string;
  max: number;
  recommendedLabel: string;
  minLabel: string;
  maxLabel: string;
  displayBackwards?: boolean;
  avgLabel: string;
  orderDesc: boolean;
};

const recommendations = new Map<CurrentSection, GridHeader>();
recommendations.set('speakerPercentage', {
  header: 'Average percentage of time members speak in their meetings.',
  max: 100,
  recommendedLabel: 'Best Practice is 50% or less',
  minLabel: '0%',
  maxLabel: '100%',
  avgLabel: '%',
  orderDesc: true
});
recommendations.set('longestMonologueMs', {
  header: 'Average amount of time members spend monologuing in their meetings.',
  max: 300000,
  recommendedLabel: 'Best Practice 2min 30s or less',
  minLabel: '0m',
  maxLabel: '5m',
  avgLabel: '',
  orderDesc: true
});
recommendations.set('fillerPhrasesPerMinute', {
  header: 'Average number of filler words used by members in their meetings.',
  max: 10,
  recommendedLabel: 'Best Practice 5 wpm or less',
  minLabel: '0 wpm',
  maxLabel: '10 wpm',
  avgLabel: 'wpm',
  orderDesc: true
});
recommendations.set('wordsPerMinute', {
  header:
    'Average number of words per minute used by members in their meetings.',
  max: 350,
  recommendedLabel: 'Best Practice 175 wpm or less',
  minLabel: '0 wpm',
  maxLabel: '350 wpm',
  avgLabel: 'wpm',
  orderDesc: true
});
recommendations.set('patienceMs', {
  header: 'Average amount of time members pause before responding in meetings.',
  max: 3000,
  recommendedLabel: 'Best Practice 1s or more',
  minLabel: '0s',
  maxLabel: '3s',
  displayBackwards: true,
  avgLabel: '',
  orderDesc: false
});

const getMetricColor = (
  averageStats: InteractionStatistics,
  metricKey: keyof InteractionStatistics
) => {
  const value = averageStats[metricKey];

  const status: MetricStatus = getMetricStatus(
    metricKey as SpeakerStatisticsMetricsKeys,
    value as number
  );

  return metricStatusColorMap[status];
};

export const Interaction = ({
  filter,
  onFiltersClear,
  interactionData,
  interactionLoading
}: InteractionProps) => {
  const [activeSection, setActiveSection] =
    React.useState<CurrentSection>('speakerPercentage');
  const [interactionInsights, setInteractionInsights] =
    React.useState<InteractionInsights>();

  const [height, setHeight] = React.useState(0);
  const [isScrolling, setIsScrolling] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const content = containerRef?.current;
    setHeight(content?.scrollHeight);
    let scroll;
    if (!containerRef) {
      if (window.innerHeight) {
        scroll = document.body.offsetHeight > innerHeight;
      } else {
        scroll =
          document.documentElement.scrollHeight >
            document.documentElement.offsetHeight ||
          document.body.scrollHeight > document.body.offsetHeight;
      }
    } else {
      scroll = content?.scrollHeight > content?.offsetHeight;
    }
    setIsScrolling(scroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef?.current, filter]);

  // added disable as it throws an ESLint warning: unnecessary dependency:
  // 'containerRef.current'. Either exclude it or remove the dependency array.
  // will not render correctly without it

  const convertDataToPercentage = (value: number) => {
    return activeSection === 'speakerPercentage'
      ? value
      : (value / recommendations.get(activeSection).max) * 100;
  };

  const convertDataToAvgLabel = (value: number) => {
    switch (activeSection) {
      case 'patienceMs':
        return toHumanTimestamp(value, { morePrecise: true }) || '0 sec';
      case 'longestMonologueMs':
        return toHumanTimestamp(value) || '0 sec';
      default:
        return Math.round(value).toString();
    }
  };

  useEffect(() => {
    if (!interactionData?.usersStatistics) return;

    const insights = cloneDeep(interactionData);

    const sortedStatistics = insights.usersStatistics.sort((a, b) => {
      return recommendations.get(activeSection).orderDesc
        ? b.averageStatistics[activeSection] -
            a.averageStatistics[activeSection]
        : a.averageStatistics[activeSection] -
            b.averageStatistics[activeSection];
    });

    insights.usersStatistics = sortedStatistics;

    setInteractionInsights(insights);
  }, [interactionData, activeSection]);

  return interactionLoading ? (
    <LoadingContainer>
      <Spinner size={20} />
    </LoadingContainer>
  ) : (
    <>
      <TabRow>
        <Tab
          active={activeSection === 'speakerPercentage'}
          onClick={() => {
            setActiveSection('speakerPercentage');
          }}
        >
          Talk time
        </Tab>
        <Tab
          active={activeSection === 'longestMonologueMs'}
          onClick={() => {
            setActiveSection('longestMonologueMs');
          }}
        >
          Longest monologue
        </Tab>
        <Tab
          active={activeSection === 'fillerPhrasesPerMinute'}
          onClick={() => {
            setActiveSection('fillerPhrasesPerMinute');
          }}
        >
          Filler words
        </Tab>
        <Tab
          active={activeSection === 'wordsPerMinute'}
          onClick={() => {
            setActiveSection('wordsPerMinute');
          }}
        >
          Talk speed
        </Tab>
        <Tab
          active={activeSection === 'patienceMs'}
          onClick={() => {
            setActiveSection('patienceMs');
          }}
        >
          Patience
        </Tab>
      </TabRow>
      <div
        ref={containerRef}
        style={{
          maxHeight: 'calc(100vh - 242px)',
          overflowY: 'auto',
          overflowX: 'hidden',
          position: 'absolute',
          width: 'calc(100vw - 218px)'
        }}
      >
        <InfoHeader>
          <div>{recommendations.get(activeSection).header}</div>
        </InfoHeader>
        {interactionInsights?.usersStatistics.length ? (
          <GridContainer>
            <div
              style={{
                height: isScrolling ? height - linePositionTop + 'px' : null
              }}
              className={`overlay-line ${activeSection}`}
            ></div>

            <div className='grid-row header'>
              <div>Member</div>
              <div style={{ gridColumn: 3 }}>Avg</div>
              <div className='best-practice'>
                <div className='top'>
                  <div>{recommendations.get(activeSection).minLabel}</div>
                  <div>
                    {recommendations.get(activeSection).recommendedLabel}
                  </div>
                  <div>{recommendations.get(activeSection).maxLabel}</div>
                </div>
                <div className='bottom'>
                  <div
                    className={`bar ${
                      recommendations.get(activeSection).displayBackwards
                        ? 'right'
                        : ''
                    }`}
                    style={{ width: '50%' }}
                  ></div>
                  <div className={`circle ${activeSection}`}>
                    <div
                      style={{
                        width: '12px',
                        height: '12px',
                        backgroundColor: color.hawk,
                        borderRadius: '25px'
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            {interactionInsights?.usersStatistics.map(stat => (
              <div className='grid-row' key={stat.user?.id}>
                <Avatar
                  avatarSize='small'
                  defaultAvatarColor={getSpeakerColor(stat.user.name)}
                  name={stat.user.name}
                  avatarUrl={stat.user?.avatarUrl ?? false}
                />
                <div css={[overflowEllipsis]}>{stat.user.name}</div>
                <div className='circle-cell'>
                  <div
                    style={{
                      width: '8px',
                      height: '8px',
                      backgroundColor: getMetricColor(
                        stat.averageStatistics,
                        activeSection
                      ),
                      borderRadius: '16px',
                      marginRight: '4px'
                    }}
                  ></div>
                  {convertDataToAvgLabel(stat.averageStatistics[activeSection])}
                  &nbsp;{recommendations.get(activeSection).avgLabel}
                </div>
                <div>
                  <div
                    className='bar'
                    style={{
                      backgroundColor: getMetricColor(
                        stat.averageStatistics,
                        activeSection
                      ),
                      width:
                        convertDataToPercentage(
                          stat.averageStatistics[activeSection]
                        ) + '%'
                    }}
                  ></div>
                </div>
              </div>
            ))}
          </GridContainer>
        ) : (
          <EmptyState filter={filter} onFiltersClear={onFiltersClear} />
        )}
      </div>
    </>
  );
};
