import pluralize from 'pluralize';
import { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Icon, useAnalytics } from '@grain/grain-ui';
import { Select, Option, theme } from '@grain/grain-ui/v4';

import {
  type DealActivity_DealEmail_Fragment,
  type DealActivity_Recording_Fragment,
  type DealActivity_DealRecordingView_Fragment,
  type DealActivity_DealStageChange_Fragment,
  type DealActivityQuery,
  type DealDetailsFragment
} from '../../deal.generated';

import { Timeline } from './Timeline';

type ActivityFilter = 'all' | 'meetings' | 'emails' | 'events';

const StyledTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px ${theme.tokens.spacing['3xl']};
  border-bottom: 1px solid ${theme.tokens.color.borderTertiary};
`;

const StyledTopBarTotals = styled.div`
  display: flex;
  gap: ${theme.tokens.spacing.lg};
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textSecondary};
`;

const StyledTopBarTotal = styled.span`
  display: flex;
  gap: ${theme.tokens.spacing.xs};
`;

const StyledEmptyState = styled.div`
  padding: ${theme.tokens.spacing.lg} ${theme.tokens.spacing['3xl']};
  ${theme.tokens.typography.b3[500]};
  color: ${theme.tokens.color.textTertiary};
`;

type ActivityProps = {
  deal: DealDetailsFragment;
  activityData: DealActivityQuery;
};

export function Activity({ deal, activityData }: ActivityProps) {
  const { trackEvent } = useAnalytics();

  const emails = useMemo(
    () =>
      activityData.deal.activity.filter(
        (activity): activity is DealActivity_DealEmail_Fragment =>
          activity.__typename === 'DealEmail'
      ) ?? [],
    [activityData]
  );

  const meetings = useMemo(
    () =>
      activityData.deal.activity.filter(
        (activity): activity is DealActivity_Recording_Fragment =>
          activity.__typename === 'Recording'
      ) ?? [],
    [activityData]
  );

  const events = useMemo(
    () =>
      activityData.deal.activity.filter(
        (
          activity
        ): activity is
          | DealActivity_DealRecordingView_Fragment
          | DealActivity_DealStageChange_Fragment =>
          activity.__typename === 'DealRecordingView' ||
          activity.__typename === 'DealStageChange'
      ) ?? [],
    [activityData]
  );

  const [activityFilter, setActivityFilter] = useState<ActivityFilter>('all');

  const handleFilterChange = useCallback(
    (value: string) => {
      const activityFilter = value as ActivityFilter;
      setActivityFilter(activityFilter);
      trackEvent(
        'Filter Used',
        {
          content_type: 'deals_activity_detail',
          filter_by: [activityFilter]
        },
        ['user', 'workspace']
      );
    },
    [trackEvent]
  );

  const filteredActivity = useMemo(
    () =>
      activityData.deal.activity?.filter(activity => {
        if (activityFilter === 'all') return true;
        if (activityFilter === 'meetings') {
          return activity.__typename === 'Recording';
        }
        if (activityFilter === 'emails') {
          return activity.__typename === 'DealEmail';
        }
        if (activityFilter === 'events') {
          return (
            activity.__typename === 'DealRecordingView' ||
            activity.__typename === 'DealStageChange'
          );
        }
      }) ?? [],
    [activityData, activityFilter]
  );

  return (
    <>
      <StyledTopBar>
        <StyledTopBarTotals>
          <StyledTopBarTotal>
            <Icon.Mail16x16 />
            <span>
              {emails.length} {pluralize('Email', emails.length)}
            </span>
          </StyledTopBarTotal>
          <StyledTopBarTotal>
            <Icon.Record16x16 />
            <span>
              {meetings.length} {pluralize('Meeting', meetings.length)}
            </span>
          </StyledTopBarTotal>
          <StyledTopBarTotal>
            <Icon.Overflow16x16 />
            <span>
              {events.length} {pluralize('Event', events.length)}
            </span>
          </StyledTopBarTotal>
        </StyledTopBarTotals>
        <Select value={activityFilter} onChange={handleFilterChange}>
          <Option value='all'>All Activity</Option>
          <Option value='meetings'>Meetings</Option>
          <Option value='emails'>Emails</Option>
          <Option value='events'>Events</Option>
        </Select>
      </StyledTopBar>
      {filteredActivity.length ? (
        <Timeline deal={deal} activity={filteredActivity} />
      ) : (
        <StyledEmptyState>No Results</StyledEmptyState>
      )}
    </>
  );
}
