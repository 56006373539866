import React from 'react';
import { Helmet } from 'react-helmet';

import * as Layout from '~/components/Layout';
import { useQueryParam, useRouteModal, Button, Icon } from '@grain/grain-ui';
import { INVITE_ROUTE_ID } from '@grain/components/modals/constants';
import { useMyself, useWorkspace, useFeature } from '@grain/api/auth';
import { UserRole } from '@grain/api/schema.generated';

import Billing from './BillingTab';
import Members from './MembersTab';
import Plans from './PlansTab';
import Workspace from './WorkspaceTab';
import Meetings from './MeetingsTab';

import { NoteTemplates } from './NotesTemplateTab';
import { TopicsTab } from './Topic';
import {
  StyledScrollableContainer,
  StyledContentContainer,
  layoutContentStyles,
  StyledOnlyAdmin,
  SETTINGS_PAGE_PADDING_TOP
} from './styles';
import {
  StyledContentWrapper,
  TabHeader
} from '~/pages/Settings/SettingsWrapper';
import { useNavigate } from 'react-router-dom';
import { GatedContentWrapper } from '~/modules/gates';
import { WorkspaceScorecards } from './Scorecards';
import { ManageScorecard } from './Scorecards/ManageScorecard';
import { GatedButton } from '~/modules/gates/GatedButton';
import { css } from '@emotion/react';

const TAB_IDS = {
  workspace: 'general',
  members: 'members',
  meetings: 'meetings',
  billing: 'billing',
  plans: 'plans',
  'notes-template': 'notes-template',
  topics: 'topics',
  scorecards: 'scorecards',
  'scorecards-manage': 'scorecards-manage'
};

const TAB_LABELS = {
  [TAB_IDS.workspace]: 'General',
  [TAB_IDS.members]: 'Members',
  [TAB_IDS.meetings]: 'Workspace meetings',
  [TAB_IDS.billing]: 'Billing',
  [TAB_IDS.plans]: 'Plans',
  [TAB_IDS['notes-template']]: 'Notes',
  [TAB_IDS.scorecards]: 'Scorecards',
  [TAB_IDS['scorecards-manage']]: 'Manage scorecard'
};

const PAGES_WITH_STICKY_HEADER = [
  TAB_IDS.topics,
  TAB_IDS['notes-template'],
  TAB_IDS['scorecards-manage'],
  TAB_IDS.scorecards,
  TAB_IDS.members
];

const TABS_WITH_OWN_HEADER = [
  TAB_IDS.topics,
  TAB_IDS['notes-template'],
  TAB_IDS['scorecards-manage']
];

export default function WorkspaceSettings() {
  const [tabId] = useQueryParam('tab') as [string, (value: string) => void];

  const { enabled: isSmartTopicsEnabled } = useFeature('smart_topics');
  const { open: openInviteModal } = useRouteModal(INVITE_ROUTE_ID);

  const { myself } = useMyself();
  const { workspace } = useWorkspace();

  const navigate = useNavigate();

  const activeTabId = tabId in TAB_IDS ? tabId : TAB_IDS.workspace;

  const tabsWithOwnHeader = [...TABS_WITH_OWN_HEADER, TAB_IDS.billing];

  const getActionButton = () => {
    switch (activeTabId) {
      case TAB_IDS.members:
        return (
          <Button
            css={['margin-left: auto;']}
            variant='primary'
            onClick={openInviteModal}
            icon={<Icon.DeprecatedPlus />}
          >
            Invite members
          </Button>
        );
      case TAB_IDS.scorecards:
        return (
          <GatedButton
            gate='scorecard'
            css={['margin-left: auto;']}
            variant='secondary'
            onClick={() => {
              navigate('/app/settings/workspace?tab=scorecards-manage');
            }}
            icon={<Icon.DeprecatedPlus />}
          >
            New scorecard
          </GatedButton>
        );
      default:
        return <></>;
    }
  };

  const ShouldDenyAccess = React.useMemo(() => {
    return (
      ([TAB_IDS.workspace, TAB_IDS.meetings, TAB_IDS.billing].includes(
        activeTabId
      ) &&
        myself?.user?.role !== UserRole.Admin) ||
      (activeTabId === TAB_IDS['notes-template'] &&
        myself?.user?.role !== UserRole.Admin &&
        workspace?.templateEditingRequireAdmin)
    );
  }, [activeTabId, myself?.user?.role, workspace?.templateEditingRequireAdmin]);

  return (
    <Layout.Wrapper withSidebar>
      <Helmet title='Workspace Settings' />
      <Layout.Content centered className='draggable' css={layoutContentStyles}>
        {ShouldDenyAccess ? (
          <StyledOnlyAdmin>
            <div className='title'>Only admins can access this page</div>
            <div className='description'>
              To make any changes to this setting please contact your admin
            </div>
            <div className='action'>
              <Button
                variant='primary'
                onClick={() => {
                  navigate('/app');
                }}
              >
                Go back
              </Button>
            </div>
          </StyledOnlyAdmin>
        ) : (
          <div
            css={css`
              width: 100%;
              height: 100%;
              overflow-y: auto;
            `}
          >
            <StyledScrollableContainer
              activeTabId={activeTabId}
              isSpeedBumpActive={!isSmartTopicsEnabled}
            >
              <StyledContentContainer isTopics={activeTabId === TAB_IDS.topics}>
                {!tabsWithOwnHeader.includes(activeTabId) && (
                  <>
                    <TabHeader
                      sticky={PAGES_WITH_STICKY_HEADER.includes(activeTabId)}
                      pageName='Workspace'
                      title={TAB_LABELS[activeTabId]}
                      pageAction={getActionButton()}
                      css={[
                        'padding: 16px 32px !important; border-radius: 15px 15px 0 0; display: flex; flex-direction: row; justify-content: space-between; align-items: center;',
                        `top: -${SETTINGS_PAGE_PADDING_TOP};`
                      ]}
                    />

                    <StyledContentWrapper
                      css={[
                        activeTabId === TAB_IDS.workspace &&
                          css`
                            /* Overrides padding/margins from content wrapper on workspace tab to be handled inside tab due to new design */
                            && {
                              padding: 0;
                              margin: 0;
                            }
                          `
                      ]}
                    >
                      {activeTabId === TAB_IDS.workspace && <Workspace />}
                      {activeTabId === TAB_IDS.members && <Members />}
                      {activeTabId === TAB_IDS.meetings && <Meetings />}
                      {activeTabId === TAB_IDS.plans && <Plans />}
                    </StyledContentWrapper>
                  </>
                )}
                {activeTabId === TAB_IDS.billing && <Billing />}
                {activeTabId === TAB_IDS['notes-template'] && <NoteTemplates />}
                {activeTabId === TAB_IDS.scorecards && <WorkspaceScorecards />}
                {activeTabId === TAB_IDS['scorecards-manage'] && (
                  <ManageScorecard />
                )}

                {activeTabId === TAB_IDS.topics && (
                  <GatedContentWrapper gate='smart_topics'>
                    <TopicsTab />
                  </GatedContentWrapper>
                )}
              </StyledContentContainer>
            </StyledScrollableContainer>
          </div>
        )}
      </Layout.Content>
    </Layout.Wrapper>
  );
}
