import styled from '@emotion/styled';
import type { Recording } from '@grain/api/schema.generated';
import { resetButtonStyles } from '@grain/grain-ui';
import { Icon16, theme, Thumbnail } from '@grain/grain-ui/v4';

const Button = styled.button`
  ${resetButtonStyles};

  display: inline-flex;
  padding: 10px;
  border-radius: 13px;
  color: rgba(255, 255, 255, 0.7);
  background: rgba(20, 20, 20, 0.4);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
  cursor: pointer;

  svg {
    width: 32px;
    height: 32px;
  }
`;

const Card = styled.div`
  ${theme.utils.padding('lg', 'lg', '2xl', 'lg')};
  border-radius: ${theme.tokens.radii.xl};
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: linear-gradient(
    69deg,
    rgba(33, 33, 33, 0.85) 0%,
    rgba(38, 38, 38, 0.57) 100%
  );
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.24);
  cursor: pointer;

  :hover,
  :focus-visible {
    border-color: rgba(255, 255, 255, 0.16);
    background: linear-gradient(
      136deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(153, 153, 153, 0.15) 100%
    );

    ${Button} {
      color: rgba(255, 255, 255, 1);
      background: rgba(20, 20, 20, 0.7);
    }
  }
`;

const ThumbnailContainer = styled.div`
  position: relative;
  display: flex; // Fix height

  ${Button} {
    position: absolute;
    right: ${theme.tokens.spacing.md};
    bottom: ${theme.tokens.spacing.md};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing['2xl']};
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing['2xs']};
  text-align: center;
`;

const Title = styled.div`
  ${theme.tokens.typography.b2[500]};
  color: ${theme.tokens.color.textInvert};
`;

const Body = styled.div`
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textTertiary};
`;

type MeetingCardProps = {
  title: string;
  body: string;
  recording: Pick<Recording, 'fullJpegThumbnailUrl' | 'recordingPath'>;
  onClick: () => void;
};

export function MeetingCard({
  title,
  body,
  recording,
  onClick
}: MeetingCardProps) {
  if (!recording?.fullJpegThumbnailUrl) return;
  return (
    <Card tabIndex={0} onClick={onClick}>
      <Content>
        <ThumbnailContainer>
          <Thumbnail
            imgSrc={recording.fullJpegThumbnailUrl}
            variant='preview'
            css={['width: 100%;']}
          />
          <Button tabIndex={-1}>
            <Icon16.Play />
          </Button>
        </ThumbnailContainer>
        <Text>
          <Title>{title}</Title>
          <Body>{body}</Body>
        </Text>
      </Content>
    </Card>
  );
}
