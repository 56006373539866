import { getBasePublicPath } from '@grain/grain-ui';
import { useCallback, useEffect, useRef } from 'react';
import { isMac } from 'lib/browser';
import {
  StyledContainer,
  StyledHeaderLogo,
  StyledLayoutContainer,
  StyledHeaderBar
} from '~/pages/Login/styles';
import { MacDownload } from './MacDownload';
import { WindowsDownload } from './WindowsDownload';

export default function Downloading() {
  const buttonProperties = isMac
    ? {
        link: '/app/_/redirect/download/mac',
        name: 'download_mac_app'
      }
    : {
        link: '/app/_/redirect/download/windows',
        name: 'download_windows_app'
      };

  const firstLoad = useRef<boolean>(true);

  const openDownloadLink = useCallback(() => {
    window.open(buttonProperties.link);
  }, [buttonProperties.link]);

  useEffect(() => {
    if (!firstLoad.current) return;
    firstLoad.current = false;
    openDownloadLink();
  }, [buttonProperties.link, openDownloadLink]);

  return (
    <StyledContainer>
      <StyledLayoutContainer>
        <StyledHeaderBar />
        <StyledHeaderLogo>
          <img
            src={`${getBasePublicPath()}/images/brand/grain-primary-standard.svg`}
          />
        </StyledHeaderLogo>
        {isMac ? (
          <MacDownload onDownloadAgain={openDownloadLink} />
        ) : (
          <WindowsDownload onDownloadAgain={openDownloadLink} />
        )}
      </StyledLayoutContainer>
    </StyledContainer>
  );
}
