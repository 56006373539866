import {
  ContentTileMeta,
  Title,
  Date,
  Tags
} from 'components/atoms/ContentTile/ContentTileMeta';
import { DateTime, DateTimeProps } from 'components/atoms/DateTime/DateTime';

export type ImportRecordingTileMetaProps = DateTimeProps & {
  title: string;
};

export function ImportRecordingTileMeta({
  title,
  when
}: ImportRecordingTileMetaProps) {
  return (
    <ContentTileMeta>
      <Title>{title}</Title>
      <Date>
        <DateTime when={when} />
      </Date>
      <Tags />
    </ContentTileMeta>
  );
}
