import * as Types from '@grain/api/schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@grain/api/graphql';
const defaultOptions = {} as const;
export type ViewAdHocQueryVariables = Types.Exact<{
  filter: Types.Scalars['ViewAdHocFiltersJSON']['input'];
  cursor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortBy?: Types.InputMaybe<Types.ViewAdHocSortBy>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
}>;


export type ViewAdHocQuery = { __typename?: 'RootQueryType', viewAdHoc: { __typename?: 'ViewItems', cursor: string | null, list: Array<{ __typename?: 'ClipV2', id: string, state: Types.ClipProcessingState, data: { __typename?: 'ClipDataFailed', text: string, timestamp: number, recording: { __typename?: 'Recording', id: string, title: string, recordingUrl: string, recordingPath: string } | null } | { __typename?: 'ClipDataProcessing', text: string, timestamp: number, recording: { __typename?: 'Recording', id: string, title: string, recordingUrl: string, recordingPath: string } | null } | { __typename?: 'ClipDataQueued', url: string | null, thumbnailJpegUrl: string | null, outTimestamp: number, inTimestamp: number, text: string, timestamp: number, recording: { __typename?: 'Recording', id: string, title: string, recordingUrl: string, recordingPath: string } | null } | { __typename?: 'ClipDataStaged', url: string | null, thumbnailJpegUrl: string | null, text: string, timestamp: number, recording: { __typename?: 'Recording', id: string, title: string, recordingUrl: string, recordingPath: string } | null } | { __typename?: 'ClipDataSuccess', url: string | null, videoUrl: string, thumbnailJpegUrl: string | null, outTimestamp: number, inTimestamp: number, text: string, timestamp: number, recording: { __typename?: 'Recording', id: string, title: string, recordingUrl: string, recordingPath: string } | null } } | { __typename?: 'Collection' } | { __typename?: 'Deal' } | { __typename?: 'Recording' } | { __typename?: 'Story' }> } };


export const ViewAdHocDocument = gql`
    query viewAdHoc($filter: ViewAdHocFiltersJSON!, $cursor: String, $sortBy: ViewAdHocSortBy, $sortDirection: SortDirection) {
  viewAdHoc(
    filter: $filter
    cursor: $cursor
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    cursor
    list {
      ... on ClipV2 {
        id
        state
        data {
          text
          timestamp
          recording {
            id
            title
            recordingUrl
            recordingPath
          }
          ... on ClipDataSuccess {
            url
            videoUrl
            thumbnailJpegUrl
            outTimestamp
            inTimestamp
          }
          ... on ClipDataQueued {
            url
            thumbnailJpegUrl
            outTimestamp
            inTimestamp
          }
          ... on ClipDataStaged {
            url
            thumbnailJpegUrl
          }
        }
      }
    }
  }
}
    `;

/**
 * __useViewAdHocQuery__
 *
 * To run a query within a React component, call `useViewAdHocQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewAdHocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewAdHocQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      cursor: // value for 'cursor'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useViewAdHocQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ViewAdHocQuery, ViewAdHocQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ViewAdHocQuery, ViewAdHocQueryVariables>(ViewAdHocDocument, options);
      }
export function useViewAdHocLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewAdHocQuery, ViewAdHocQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ViewAdHocQuery, ViewAdHocQueryVariables>(ViewAdHocDocument, options);
        }
export function useViewAdHocSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<ViewAdHocQuery, ViewAdHocQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<ViewAdHocQuery, ViewAdHocQueryVariables>(ViewAdHocDocument, options);
        }
export type ViewAdHocQueryHookResult = ReturnType<typeof useViewAdHocQuery>;
export type ViewAdHocLazyQueryHookResult = ReturnType<typeof useViewAdHocLazyQuery>;
export type ViewAdHocSuspenseQueryHookResult = ReturnType<typeof useViewAdHocSuspenseQuery>;
export type ViewAdHocQueryResult = Apollo.QueryResult<ViewAdHocQuery, ViewAdHocQueryVariables>;