import React from 'react';
import PropTypes from 'prop-types';
import useUnmount from 'react-use/lib/useUnmount';
import { Modal, ModalTitle, resetButtonStyles, Icon } from '@grain/grain-ui';

import ChoosePlan from './ChoosePlan';
import ChooseMembers from './ChooseMembers';
import Payment from './Payment';
import Confirmation from './Confirmation';
import { SubscribeInfoProvider, useSubscribeInfo } from './context';
import { StyledWrapper } from './styles';

export default function SubscribeWrapper(props) {
  return (
    <SubscribeInfoProvider>
      <SubscribeModal {...props} />
    </SubscribeInfoProvider>
  );
}

SubscribeModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

function SubscribeModal({ onClose }) {
  const { plan, planSku, setPlanSku, workspaceRes, omitPlanMembers, source } =
    useSubscribeInfo();

  const currentSubscriptionRef = React.useRef(
    workspaceRes.workspace?.workspaceSubscription
  );

  useUnmount(() => {
    // Erase plan sku from url when modal closes
    setPlanSku(null);
  });

  const {
    step,
    StepCmp,
    StepComponents,
    handleSuccess,
    showModalTitle,
    canGoBack,
    canCloseModal,
    goBack
  } = useStepManager(planSku, onClose);

  // While loading plan object, stop modal from rendering.
  if (!plan.name && planSku) {
    return null;
  }

  return (
    <Modal
      closable={canCloseModal}
      roundness={step === StepComponents.length - 1 && 'lg'}
      width={
        StepCmp === ChoosePlan
          ? 790
          : step === StepComponents.length - 1
            ? 533
            : 600
      }
      maxContentHeight='85vh'
      onCancel={onClose}
      maskClosable={false}
    >
      {showModalTitle && (
        <ModalTitle
          title={`Upgrade ${plan.name ? `to ${plan.name}` : 'Plan'}`}
          centered
          leftAction={
            canGoBack && (
              <button
                css={[
                  resetButtonStyles,
                  'width: 100%; height: 100%; cursor: pointer;'
                ]}
                aria-label='Back'
                onClick={() => goBack()}
              >
                <Icon.ArrowLeft css={['width: 16px; height: 16px;']} />
              </button>
            )
          }
        />
      )}
      <StyledWrapper>
        <StepCmp
          currentSubscription={currentSubscriptionRef.current}
          omitPlanMembers={omitPlanMembers}
          onSuccess={handleSuccess}
          source={source}
        />
      </StyledWrapper>
    </Modal>
  );
}

const STEPS = [ChoosePlan, ChooseMembers, Payment, Confirmation];

function useStepManager(planSku, onClose) {
  const [step, setStep] = React.useState(0);

  const stepsProps = React.useMemo(() => {
    const componentList = planSku ? STEPS.slice(1) : STEPS;
    return {
      components: componentList,
      showModalTitle: step < componentList.length - 1,
      canGoBack: step > 0 && step < componentList.length - 1,
      canCloseModal: step < componentList.length - 1,
      handleSuccess: () => {
        if (step < componentList.length - 1) {
          setStep(step + 1);
        } else {
          setStep(0);
          onClose();
        }
      }
    };
  }, [planSku, step, onClose]);

  const {
    components: StepComponents,
    showModalTitle,
    canGoBack,
    canCloseModal,
    handleSuccess
  } = stepsProps;
  const StepCmp = StepComponents[step];

  const goBack = () => setStep(step - 1);

  return {
    step,
    StepCmp,
    handleSuccess,
    StepComponents,
    showModalTitle,
    goBack,
    canGoBack,
    canCloseModal
  };
}
