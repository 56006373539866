import { useCallback, useState } from 'react';
import * as Recoil from 'recoil';
import { Modal, ModalTitle, useRouteModal } from '@grain/grain-ui';
import { Icon16, TextLabel } from '@grain/grain-ui/v4';
import { useExperiment } from '@grain/api/auth';
import { type SubscriptionPlan, UserRole } from '@grain/api/schema.generated';
import {
  StyledModalTitle,
  StyledButtonWrapper,
  StyledPlanList
} from './styles';
import PlanCard, { PlanAction } from '~/modules/subscriptions/PlanCard';
import {
  usePlan,
  usePlans,
  useUpcomingWorkspacePlan
} from '@grain/components/Subscriptions/hooks';
import { billingPreviewIsYearlyState } from '~/modules/subscriptions/state';
import {
  START_TRIAL_ROUTE_ID,
  UPGRADE_PLAN_ROUTE_ID
} from '@grain/components/modals/constants';
import { useWorkspaceWithMembers } from '@grain/components/Workspace/hooks';
import { useMyself } from '~/support/auth';
import { useHandleShowUpgradeModal } from '~/modules/gates';
import { useRequestAdminUpgrade } from '~/modules/gates/hooks';
import { requestTrial } from '~/modules/freemium/requestTrial';
import { getPlanAction } from './getPlanAction';

export default function UpgradePlan() {
  const { enabled: isFreemiumEnabled } = useExperiment('freemium');
  const { subscriptionPlan: upcomingPlan } = useUpcomingWorkspacePlan();
  const { close: closeModal } = useRouteModal(UPGRADE_PLAN_ROUTE_ID);
  const { open: openStartTrialModal } = useRouteModal(START_TRIAL_ROUTE_ID, {
    source: UPGRADE_PLAN_ROUTE_ID
  });
  const { myself } = useMyself();
  const isAdmin = myself?.user?.role === UserRole.Admin;
  const { workspace } = useWorkspaceWithMembers();
  const workspaceSubscription = workspace?.workspaceSubscription;
  const { subscriptionPlans: plans, loading: plansLoading } = usePlans();
  const {
    subscriptionPlan: currentPlan,
    subscriptionPlanIndex: currentPlanIndex
  } = usePlan(workspaceSubscription?.planSku);
  const visiblePlans = plans.filter(plan =>
    currentPlan.isFree || upcomingPlan.isFree
      ? plan.isFree || plan.yearlyPrice
      : plan.yearlyPrice
  );

  const isPostTrial = workspaceSubscription?.trialExpired;

  const basePlansIndx = currentPlan.isFree
    ? 0
    : plans.filter(plan => plan.isFree)?.length;
  const canTrial = workspaceSubscription?.canTrial;
  const isTrial =
    workspaceSubscription?.trial && !workspaceSubscription?.autoRenew;
  const [yearlyPricing] = Recoil.useRecoilState(billingPreviewIsYearlyState);
  const forcePrice = yearlyPricing ? 'YEARLY' : 'MONTHLY';

  const [requestedUpgradeFromAdmin, setRequestedUpgradeFromAdmin] =
    useState(false);
  const requestUpgrade = useRequestAdminUpgrade({
    callback: () => {
      setRequestedUpgradeFromAdmin(true);
    },
    updateToWorkspacePlan: false
  });
  const requestUpgradeToPlan = useCallback(
    (plan: SubscriptionPlan) => {
      requestUpgrade({
        variables: {
          planSku: plan.sku
        }
      });
    },
    [requestUpgrade]
  );

  const openSubscribeModal = useHandleShowUpgradeModal();

  const outerPadding = 32;
  const cardWidth = 256;
  const cardSpacing = 12;
  const modalWidth =
    (cardWidth + cardSpacing * 2) * visiblePlans.length +
    (outerPadding - cardSpacing) * 2;

  return (
    <Modal
      width={modalWidth}
      closable
      onCancel={closeModal}
      maxContentHeight='100%'
    >
      <ModalTitle
        centered
        title={
          <StyledModalTitle>
            <Icon16.Upgrade />
            <span>Upgrade your plan</span>
          </StyledModalTitle>
        }
      />
      <StyledPlanList>
        {visiblePlans.map((plan, index) => {
          const isCurrentPlan = plan.sku === workspaceSubscription?.planSku;
          const isUpcomingPlan = plan.sku === upcomingPlan?.sku;
          const planAction = getPlanAction({
            plan,
            isCurrentPlan,
            isUpcomingPlan,
            isFreemiumEnabled,
            isAdmin,
            isTrial,
            isPostTrial,
            canTrial
          });
          return (
            <PlanCard
              key={plan.sku}
              variant='medium'
              plan={plan}
              currentPlanSku={workspaceSubscription?.planSku}
              loading={plansLoading}
              previousPlan={visiblePlans[index - 1]}
              planIndex={index + basePlansIndx}
              currentPlanIndex={currentPlanIndex}
              currentTrial={
                isTrial && workspaceSubscription?.planSku === plan.sku
              }
              forcePrice={forcePrice}
              showBillingPeriod={false}
              shouldHighlight={plan.isPopular}
              planAction={planAction}
              actionCompletedText={
                requestedUpgradeFromAdmin ? (
                  <TextLabel startIcon={Icon16.Check}>
                    Request sent to admin
                  </TextLabel>
                ) : undefined
              }
              canEdit={[
                PlanAction.START_TRIAL,
                PlanAction.REQUEST_TRIAL,
                PlanAction.REQUEST_UPGRADE,
                PlanAction.UPGRADE_PLAN
              ].includes(planAction)}
              onAction={() => {
                if (planAction === PlanAction.START_TRIAL) {
                  openStartTrialModal();
                } else if (planAction === PlanAction.REQUEST_TRIAL) {
                  requestTrial({ workspace, plan });
                } else if (planAction === PlanAction.REQUEST_UPGRADE) {
                  requestUpgradeToPlan(plan);
                } else if (planAction === PlanAction.UPGRADE_PLAN) {
                  openSubscribeModal(plan.sku, { source: 'Upgrade Widget' });
                }
                // No action for CURRENT_PLAN or FALLBACK_AFTER_TRIAL.
                // Instead, the button is disabled.
                // The action UPCOMING_PLAN is theoretical; never happens here.
              }}
            />
          );
        })}
      </StyledPlanList>
      <StyledButtonWrapper>
        <div>
          <span>Enterprise customer?</span>
          <a target='_blank' href='/live-demo'>
            Book a demo
          </a>
        </div>
      </StyledButtonWrapper>
    </Modal>
  );
}
