import React from 'react';

import { getBasePublicPath } from '@grain/components/support/environment';
import { useExtra } from '@grain/components/support/extra';
import {
  useNumberQueryParam,
  useAnalytics,
  Icon,
  media
} from '@grain/grain-ui';
import ChooseRole, { OnboardingRoleKey } from './ChooseRole';

import ConnectCalendar from './ConnectCalendar';
import ConnectCRM from './ConnectCRM';

import {
  StyledContainer,
  StyledLayoutContainer,
  StyledHeaderLogo,
  StyledHeaderBar
} from '../Login/styles';
import { StyledBackButton } from './styles';
import SelectMeetingPlace from './SelectMeetingPlace';
import InviteTeam from './InviteTeam';
import MeetingPreferences from './MeetingPreferences';
import { ActiveIntegrationsDocument } from '@grain/api/graphql/queries/integrations.generated';
import { useQuery } from '@grain/api/graphql';
import { useAuth, useExperiment } from '~/support/auth';
import {
  Integration,
  HubspotIntegration,
  SalesforceIntegration
} from '@grain/api/schema.generated';
import DownloadDesktop from './DownloadDesktop';
import { useMediaQuery } from '@grain/components/support/browser';
import { isDesktopApp } from 'desktop/lib/utils';
import { useNavigate } from 'react-router-dom';

export type StepComponentProps = {
  onNext: () => void;
  isJoiningWorkspace: boolean;
  getNextHref: () => string;
  desktopAppRequired?: boolean;
};

export interface IntegrationType extends Integration {
  __typename: string;
}

type OnboardingStepCondition = {
  isUserJoining?: boolean;
  supportedCRM?: boolean;
  crmNeedsConnection?: boolean;
  isSmallScreen?: boolean;
  isFreemiumEnabled?: boolean;
};

type OnboardingStep = {
  key: string;
  name: string;
  condition: (condition: OnboardingStepCondition) => boolean;
  component: React.FC<StepComponentProps>;
};

const onboardingSteps: OnboardingStep[] = [
  {
    name: 'ConnectCalendar',
    key: 'connect-calendar',
    condition: ({ isUserJoining }) => {
      return !isUserJoining;
    },
    component: ConnectCalendar
  },
  {
    name: 'DownloadApp',
    key: 'download-app',
    condition: ({ isSmallScreen, isUserJoining }) => {
      return !isDesktopApp && !isSmallScreen && !isUserJoining;
    },
    component: DownloadDesktop
  },
  {
    name: 'ChooseRole',
    key: 'choose-role',
    condition: () => {
      return true;
    },
    component: ChooseRole
  },
  {
    name: 'SelectMeetingPlace',
    key: 'select-meeting-place',
    condition: () => {
      return true;
    },
    component: SelectMeetingPlace
  },
  {
    // TODO: delete this step once Freemium is GA
    name: 'ConnectCRM',
    key: 'connect-crm',
    condition: ({
      isFreemiumEnabled,
      isUserJoining,
      supportedCRM,
      crmNeedsConnection
    }) => {
      if (isFreemiumEnabled) {
        return false;
      }
      if (!isUserJoining && supportedCRM) {
        return true;
      }
      if (isUserJoining && supportedCRM && crmNeedsConnection) {
        return true;
      }
      return false;
    },
    component: ConnectCRM
  },
  {
    name: 'InviteTeam',
    key: 'invite-team',
    condition: ({ isUserJoining }) => {
      return !isUserJoining;
    },
    component: InviteTeam
  },
  {
    name: 'MeetingPreferences',
    key: 'meeting-preferences',
    condition: ({ isUserJoining }) => {
      return !isUserJoining;
    },
    component: MeetingPreferences
  },
  {
    name: 'DownloadApp',
    key: 'download-app',
    condition: ({ isSmallScreen, isUserJoining }) => {
      return !isDesktopApp && !isSmallScreen && Boolean(isUserJoining);
    },
    component: DownloadDesktop
  }
];

const useOnboardingSteps = () => {
  const { extra } = useExtra();
  const isUserJoining = extra?.joiningWorkspace || extra?.requestedToJoin;
  const isSmallScreen = useMediaQuery(media.small);
  const { enabled: isFreemiumEnabled } = useExperiment('freemium');

  const existingRole = window.localStorage.getItem(OnboardingRoleKey);
  const role = existingRole ? JSON.parse(existingRole) : null;
  const supportedCRM = ['HubSpot', 'Salesforce'].includes(
    role?.ob_survey_selected_crm_type
  );

  const { isAuthenticated } = useAuth();
  const { data: integrationsData } = useQuery(ActiveIntegrationsDocument, {
    skip: Boolean(!isAuthenticated)
  });
  const integrations = React.useMemo(() => {
    const hubspotIntegration: HubspotIntegration =
      integrationsData?.activeIntegrations?.find(
        (integration: IntegrationType) =>
          integration.__typename === 'HubspotIntegration'
      );
    const salesforceIntegration: SalesforceIntegration =
      integrationsData?.activeIntegrations?.find(
        (integration: IntegrationType) =>
          integration.__typename === 'SalesforceIntegration'
      );
    return { hubspotIntegration, salesforceIntegration };
  }, [integrationsData]);

  const crmNeedsConnection = React.useMemo(() => {
    const usesHubspot = role?.ob_survey_selected_crm_type === 'HubSpot';
    const usesSalesforce = role?.ob_survey_selected_crm_type === 'Salesforce';
    return (
      (usesHubspot && !integrations.hubspotIntegration) ||
      (usesSalesforce && !integrations.salesforceIntegration)
    );
  }, [integrations, role]);

  return onboardingSteps.filter(step =>
    step.condition({
      isUserJoining,
      supportedCRM,
      crmNeedsConnection,
      isSmallScreen,
      isFreemiumEnabled
    })
  );
};

export default function Onboarding() {
  const steps = useOnboardingSteps();
  const { extra, saveExtra, loading: extraLoading } = useExtra();
  const navigate = useNavigate();
  const { enabled: ftuxSlideshowEnabled } = useExperiment('ftux_slideshow');

  const [stepIndex, setStepIndex] = useNumberQueryParam('step', {
    defaultValue: 0
  });

  const { trackEvent } = useAnalytics();

  React.useEffect(() => {
    trackEvent(
      'Registration',
      {
        step: stepIndex,
        screen_name: steps[stepIndex]?.name
      },
      []
    );
  }, [stepIndex, steps, trackEvent]);

  const getNextHref = React.useCallback(() => {
    if (stepIndex === steps.length - 1) {
      return ftuxSlideshowEnabled
        ? '/app/meetings?overlay=post-onboarding'
        : '/app/meetings?dialog=onboarding';
    }
    return `/app/onboarding?step=${stepIndex + 1}`;
  }, [ftuxSlideshowEnabled, stepIndex, steps]);

  const nextStep = React.useCallback(() => {
    if (stepIndex === steps.length - 1) {
      saveExtra({
        ...extra,
        onboardingDone: true
      });
      const next = getNextHref();
      navigate(next);
      return;
    }
    setStepIndex(stepIndex + 1);
  }, [
    stepIndex,
    steps.length,
    setStepIndex,
    saveExtra,
    extra,
    getNextHref,
    navigate
  ]);

  const StepComponent = steps[stepIndex].component;

  const previousStep = () => {
    setStepIndex(stepIndex - 1);
  };

  if (extraLoading) {
    return null;
  }

  const isUserJoining = Boolean(extra?.joiningWorkspace);

  return (
    <StyledContainer>
      <StyledLayoutContainer>
        <StyledHeaderBar justifyContent='flex-start'>
          {stepIndex > 1 && (
            <StyledBackButton
              onClick={previousStep}
              type='button'
              variant='secondary'
              size='normal'
              icon={<Icon.BackCaret />}
            >
              Back
            </StyledBackButton>
          )}
        </StyledHeaderBar>
        <StyledHeaderLogo>
          <img
            src={`${getBasePublicPath()}/images/brand/grain-primary-standard.svg`}
          />
        </StyledHeaderLogo>
        <StepComponent
          onNext={nextStep}
          isJoiningWorkspace={isUserJoining}
          getNextHref={getNextHref}
          desktopAppRequired={!isUserJoining}
        />
      </StyledLayoutContainer>
    </StyledContainer>
  );
}
