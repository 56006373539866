import { useCallback, useEffect, useMemo, useRef } from 'react';
import { css } from '@emotion/react';
import { useExtraPath, type Extra } from '@grain/components/support/extra';
import { useDesktopAppInstallation } from '../useDesktopAppInstallation';
import { useAnalytics, useShowToast } from '@grain/grain-ui';
import { TextLabel, Checkbox, theme } from '@grain/grain-ui/v4';
import { openUrlInDesktopApp } from 'desktop/lib/utils/openUrlInDesktopApp';
import appIcon from './app-icon.png';
import { useLocalStorage, useMedia } from 'react-use';

type useDesktopAppToasterProps = {
  hasRedirectActive: boolean;
  setIsDesktopRedirectScreenOpen: (open: boolean) => void;
};

const TOAST_DISMISS_DURATION_MS = 7 * 24 * 60 * 60 * 1000; // 7 days
const NOOP = () => {};

const AppIcon = () => (
  <img
    src={appIcon}
    alt='Grain app icon'
    css={css`
      transform: scale(1.1);
    `}
  />
);

export const useDesktopAppToaster = ({
  hasRedirectActive,
  setIsDesktopRedirectScreenOpen
}: useDesktopAppToasterProps) => {
  const showToast = useShowToast();
  const clearToastRef = useRef<() => void>(NOOP);
  const isToastOpen = useRef(false);
  const [dismissedDate, setDismissedDate] = useLocalStorage(
    'desktop-toast-dismissed-date',
    ''
  );
  const { trackEvent } = useAnalytics();
  const [{ value: preferOpenInDesktopApp }, setPreferOpenInDesktopApp] =
    useExtraPath<Extra['preferOpenInDesktopApp']>('preferOpenInDesktopApp');
  const { hasDesktopApp, isDesktopApp } = useDesktopAppInstallation();
  const isSmallScreen = useMedia(theme.tokens.breakpoints.mdMax);
  const handleDismiss = useCallback(() => {
    setDismissedDate(new Date().toISOString());
  }, [setDismissedDate]);

  const hasDismissed = useMemo(() => {
    return (
      dismissedDate &&
      new Date(dismissedDate) > new Date(Date.now() - TOAST_DISMISS_DURATION_MS)
    );
  }, [dismissedDate]);

  const clearToast = useCallback(() => {
    clearToastRef.current();
    isToastOpen.current = false;
  }, [clearToastRef]);

  const sharedToastProps = useMemo(
    () => ({
      uniqueId: 'desktop-app-toast',
      type: 'content' as const,
      durationMs: 0,
      minWidth: '356px',
      actionButtonVariant: 'primary',
      showCloseButton: true,
      closeOnAction: false,
      icon: <AppIcon />,
      animateEnter: 'bottom' as const,
      animateLeave: 'bottom' as const,
      onClose: () => {
        trackEvent('Desktop Toast Clicked', {
          action: 'Close',
          hasDesktopApp
        });
        handleDismiss();
        clearToast();
      }
    }),
    [trackEvent, hasDesktopApp, handleDismiss, clearToast]
  );

  const createAppInstalledToast = useCallback(() => {
    isToastOpen.current = true;
    clearToastRef.current = showToast({
      ...sharedToastProps,
      title: 'Open in desktop app?',
      body: 'It looks like you have the desktop app installed',
      action: 'Open in app',
      onAction: async () => {
        openUrlInDesktopApp(location.pathname);
        setIsDesktopRedirectScreenOpen(true);
        trackEvent('Desktop Toast Clicked', {
          action: 'Open in app',
          hasDesktopApp
        });
        clearToast();
      },
      extraActionAreaElement: (
        <label
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <Checkbox
            size='sm'
            checked={Boolean(preferOpenInDesktopApp)}
            onChange={e => {
              setPreferOpenInDesktopApp(e.target.checked);
              trackEvent('Desktop Toast Clicked', {
                action: `Checkbox toggled to ${e.target.checked}`,
                hasDesktopApp
              });
            }}
          />
          <TextLabel
            size='sm'
            css={css`
              color: ${theme.tokens.color.textSecondary};
            `}
          >
            Always open in app
          </TextLabel>
        </label>
      )
    });
  }, [
    clearToast,
    hasDesktopApp,
    preferOpenInDesktopApp,
    setIsDesktopRedirectScreenOpen,
    setPreferOpenInDesktopApp,
    sharedToastProps,
    showToast,
    trackEvent
  ]);

  const createDownloadAppToast = useCallback(() => {
    isToastOpen.current = true;
    clearToastRef.current = showToast({
      ...sharedToastProps,
      title: 'Download the desktop app',
      body: 'Boost the efficiency of your workflow in Grain',
      action: 'Download app',
      onAction: () => {
        window.open('/download', '_blank');
        trackEvent('Desktop Toast Clicked', {
          action: 'Download app',
          hasDesktopApp
        });
        clearToast();
      },
      secondaryAction: 'Not right now',
      onSecondaryAction: () => {
        trackEvent('Desktop Toast Clicked', {
          action: 'Not right now',
          hasDesktopApp
        });
        handleDismiss();
        clearToast();
      }
    });
  }, [
    clearToast,
    handleDismiss,
    hasDesktopApp,
    sharedToastProps,
    showToast,
    trackEvent
  ]);

  useEffect(() => {
    if (
      !isDesktopApp &&
      hasRedirectActive === false &&
      !hasDismissed &&
      !isSmallScreen
    ) {
      trackEvent('Desktop Toast Viewed', {
        hasDesktopApp
      });

      hasDesktopApp ? createAppInstalledToast() : createDownloadAppToast();
    }
    if (hasRedirectActive && isToastOpen.current) {
      clearToast();
    }
  }, [
    hasDesktopApp,
    isDesktopApp,
    trackEvent,
    hasRedirectActive,
    showToast,
    setIsDesktopRedirectScreenOpen,
    hasDismissed,
    handleDismiss,
    clearToast,
    preferOpenInDesktopApp,
    setPreferOpenInDesktopApp,
    createAppInstalledToast,
    createDownloadAppToast,
    isSmallScreen
  ]);
};
