import { ErrorBox, PageHeader, PageTitle } from '@grain/grain-ui';
import { Container, SupportContainer } from './styles';
import { useBillingData } from './useBillingData';
import BillingDetailsSection from './BillingDetailsSection';
import CurrentPlanDetailsSection from './CurrentPlanDetailsSection';
import InvoicesSection from './InvoicesSection';
import { StyledPageHeader } from '../../SettingsWrapper';
import * as Intercom from '~/support/intercom';

export default function Billing() {
  const {
    workspace,
    workspaceLoading,
    workspaceError,
    workspacePlanLoading,
    workspacePlanError,
    planName,
    upcomingPlan,
    workspacePlan,
    workspaceSubscription: subscription,
    invoices,
    isLoadingInvoices,
    invoiceError
  } = useBillingData();

  return (
    <>
      <StyledPageHeader css={['padding: 24px;']} sticky>
        <PageHeader.Left>
          <PageTitle hasSubTitle>
            <div>Billing</div>
          </PageTitle>
        </PageHeader.Left>
        <PageHeader.Right>
          <SupportContainer>
            For questions about billing,{' '}
            <a
              onClick={e => {
                e.preventDefault();
                Intercom.showTab('messages');
              }}
              href='mailto:help@grain.com'
              tabIndex={0}
              data-track='Button Clicked'
              data-track-args={JSON.stringify({
                button_name: 'chat_with_support',
                location: 'billing_page',
                button_text: 'Chat with support'
              })}
            >
              chat with support
            </a>
          </SupportContainer>
        </PageHeader.Right>
      </StyledPageHeader>
      <Container>
        {workspaceError && <ErrorBox error={workspaceError} />}
        {workspacePlanError && <ErrorBox error={workspacePlanError} />}
        <CurrentPlanDetailsSection
          isLoading={workspacePlanLoading}
          workspaceSubscription={subscription}
          workspacePlan={workspacePlan}
        />
        {subscription.cardLastFour && (
          <BillingDetailsSection
            workspaceSubscription={subscription}
            isLoading={workspaceLoading}
            planName={planName}
            workspacePlanSku={workspacePlan?.sku}
            upcomingPlan={upcomingPlan}
            workspacePlan={workspacePlan}
            wsUsers={workspace?.users ?? []}
          />
        )}
        <InvoicesSection
          invoices={invoices}
          isLoading={isLoadingInvoices}
          invoiceError={invoiceError}
        />
      </Container>
    </>
  );
}
