import {
  SmartTopicMatchScope,
  SmartTopicSpeakerMatchScope
} from '@grain/api/schema.generated';

import type { Topic } from './types';

export function validateTopicScope(
  topic: Pick<Topic, 'matchScope' | 'speakerScope'>
) {
  if (
    topic?.matchScope === SmartTopicMatchScope.Internal &&
    topic?.speakerScope === SmartTopicSpeakerMatchScope.External
  ) {
    return 'There can’t be customer speakers in team meetings.';
  }
}
