import { Button, InputText, Modal, ModalTitle } from '@grain/grain-ui';
import { color } from 'base/css';

import {
  StyledErrorMessage,
  StyledFormWrapper,
  StyledInputLabel,
  StyledModalContent
} from './styles';
import { useState } from 'react';
import { isValidEmail } from '~/support/validators';

type ChangeEmailModalProps = {
  onClose: () => void;
  email: string;
  onUpdate: (email: string) => void;
};

export default function ChangeEmailModal({
  onClose,
  email,
  onUpdate
}: ChangeEmailModalProps) {
  const [updatedEmail, setUpdatedEmail] = useState(email);
  const [isEmailFocused, setEmailFocused] = useState(false);
  const isEmailValid = isValidEmail(updatedEmail || '');
  const isEmailDirty = updatedEmail !== email && !isEmailFocused;
  const isError = isEmailDirty && !isEmailValid;
  return (
    <Modal closable centered width={516} onCancel={onClose}>
      <ModalTitle title='Change billing email' centered />
      <StyledModalContent>
        <StyledFormWrapper>
          <StyledInputLabel htmlFor='billing-email'>
            Billing Email
          </StyledInputLabel>

          <InputText
            type='email'
            placeholder='Enter an email address'
            id='billing-email'
            css={[
              `width: 100%; ${isError ? `border-color: ${color.errorbird}` : ''}`
            ]}
            value={updatedEmail}
            onFocus={() => setEmailFocused(true)}
            onBlur={() => setEmailFocused(false)}
            onChange={e => setUpdatedEmail(e.target.value)}
          />
          {isError && (
            <StyledErrorMessage className='error'>
              Please enter a valid billing email.
            </StyledErrorMessage>
          )}
        </StyledFormWrapper>
        <Button
          onClick={() => onUpdate(updatedEmail)}
          disabled={!updatedEmail || !isEmailValid}
        >
          {email ? 'Update' : 'Add'}
        </Button>
      </StyledModalContent>
    </Modal>
  );
}
