import * as Types from '@grain/api/schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@grain/api/graphql';
const defaultOptions = {} as const;
export type InteractionInsightsQueryVariables = Types.Exact<{
  filter: Types.Scalars['ViewAdHocFiltersJSON']['input'];
}>;


export type InteractionInsightsQuery = { __typename?: 'RootQueryType', insightQueries: { __typename?: 'InsightQueries', interactionInsights: { __typename?: 'InteractionInsights', usersStatistics: Array<{ __typename?: 'InteractionUserStatistics', averageStatistics: { __typename?: 'InteractionStatistics', fillerPhrasesPerMinute: number, longestMonologueMs: number | null, patienceMs: number | null, recordingPercentage: number, speakerPercentage: number, wordsPerMinute: number }, user: { __typename?: 'User', hexColor: string, name: string, id: string, avatarUrl: string | null } }> } } };


export const InteractionInsightsDocument = gql`
    query interactionInsights($filter: ViewAdHocFiltersJSON!) {
  insightQueries {
    interactionInsights(filter: $filter) {
      usersStatistics {
        averageStatistics {
          fillerPhrasesPerMinute
          longestMonologueMs
          patienceMs
          recordingPercentage
          speakerPercentage
          wordsPerMinute
        }
        user {
          hexColor
          name
          id
          avatarUrl
        }
      }
    }
  }
}
    `;

/**
 * __useInteractionInsightsQuery__
 *
 * To run a query within a React component, call `useInteractionInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteractionInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteractionInsightsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useInteractionInsightsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<InteractionInsightsQuery, InteractionInsightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<InteractionInsightsQuery, InteractionInsightsQueryVariables>(InteractionInsightsDocument, options);
      }
export function useInteractionInsightsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InteractionInsightsQuery, InteractionInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<InteractionInsightsQuery, InteractionInsightsQueryVariables>(InteractionInsightsDocument, options);
        }
export function useInteractionInsightsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<InteractionInsightsQuery, InteractionInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<InteractionInsightsQuery, InteractionInsightsQueryVariables>(InteractionInsightsDocument, options);
        }
export type InteractionInsightsQueryHookResult = ReturnType<typeof useInteractionInsightsQuery>;
export type InteractionInsightsLazyQueryHookResult = ReturnType<typeof useInteractionInsightsLazyQuery>;
export type InteractionInsightsSuspenseQueryHookResult = ReturnType<typeof useInteractionInsightsSuspenseQuery>;
export type InteractionInsightsQueryResult = Apollo.QueryResult<InteractionInsightsQuery, InteractionInsightsQueryVariables>;