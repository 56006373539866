// @ts-strict-ignore
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
  color,
  Skeleton,
  SkeletonVideo,
  TabLayout,
  useRouteModal,
  VideoContextProps
} from '@grain/grain-ui';
import { CoachingHeaderMetadata } from '~/components/HeaderMetadata';
import { RecordingMetrics } from '~/components/RecordingMetrics/RecordingMetrics';
import { RecordingMedia } from '~/components/RecordingMedia';
import { useSpeakerStatisticsQuery } from '~/modules/statistics/statistics.generated';
import { useTimeStamp } from '~/modules/recording/hooks';

import { HEADER_HEIGHT } from '../constants';
import { Comments } from './Comments';
import { SHARE_RECORDING_ID } from '@grain/components/modals/constants';
import { useRecording, useTranscript } from './hooks';
import { Handler } from 'mitt';
import { SalesScoring } from '~/components/SalesScoring';
import {
  IntelligenceTextSection,
  PersonContactType
} from '@grain/api/schema.generated';
import { SalesOverview } from '~/components/SalesOverview';
import { SendButton } from './SendButton';
import ShareRecordingPermissions from '~/modals/SharePermissions/ShareRecording';
import { useRecordingSalesFeedbackViewed } from '~/modules/coaching/hooks';
import { RecordingQuery } from '@grain/api/graphql/queries/recording.generated';
import { UpsertScorecardModal } from '~/modals/UpsertScorecardModal';
import { useUpsertScorecardModal } from './useUpsertScorecardModal';

const StyledRecordingDetail = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
  height: ${HEADER_HEIGHT}px; // Match RecordingsList header
  padding: 11px 32px;
  border-bottom: 1px solid ${color.gull};
`;

const StyledMetadataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const StyledMainContentContainer = styled.div`
  flex: 1;
  padding: 24px 32px 0 32px;
  min-height: 0; // Fix tab overflow
  display: flex;
  gap: 24px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .tab-layout {
    flex: 1;
    min-height: 0; // Fix tab overflow
  }

  .tab-layout-content {
    flex: 1;
    padding-bottom: 24px;
  }
`;

const StyledMainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  max-width: 100%;
`;

function SkeletonHeaderMetadata() {
  return (
    <StyledMetadataContainer>
      <Skeleton width='250px' height='20px' />
      <Skeleton width='350px' height='16px' />
    </StyledMetadataContainer>
  );
}

function SkeletonTabContentItem() {
  return (
    <div css={['display: flex; flex-direction: column; gap: 8px;']}>
      <div css={['padding: 10px 24px;']}>
        <Skeleton width='146px' height='20px' />
      </div>
      <div css={['> div { padding: 8px 24px; }']}>
        <div>
          <Skeleton width='100%' height='20px' />
        </div>
        <div>
          <Skeleton width='100%' height='20px' />
        </div>
      </div>
    </div>
  );
}

function SkeletonTabContents() {
  return (
    <div css={['display: flex; flex-direction: column; gap: 12px;']}>
      {Array(3)
        .fill('')
        .map((_, index) => (
          <SkeletonTabContentItem key={index} />
        ))}
    </div>
  );
}

type TabContentProps = {
  isLoading: boolean;
  children: React.ReactElement;
};

const TabContent = ({ isLoading, children }: TabContentProps) => {
  return isLoading ? <SkeletonTabContents /> : children;
};

type RecordingDetailProps = {
  recordingId: string;
  onChangeTabId: (tabId: string, item?: string) => void;
  tabId: string;
};

export function RecordingDetail({
  recordingId,
  onChangeTabId,
  tabId
}: RecordingDetailProps) {
  const videoContextRef = useRef<VideoContextProps>(undefined);
  const videoContextApi = videoContextRef?.current?.api;
  const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const wrapperRef = useRef(null);

  const { recording, isLoading, refetchRecording } = useRecording(recordingId);
  const { transcript, transcriptDataLoaded } = useTranscript(
    recordingId,
    isLoading
  );

  useRecordingSalesFeedbackViewed({ recording, isRecordingLoading: isLoading });

  useEffect(() => {
    if (!videoContextApi) return;
    const handleTimeUpdate: Handler = (e: { seconds: number }) => {
      setCurrentVideoTime(e.seconds);
    };
    videoContextApi.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoContextApi.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [videoContextApi]);

  const { initialTime, settingInitialTime } = useTimeStamp({
    transcript,
    transcriptDataLoaded,
    recordingId
  });

  const handleTimestampClick = useCallback(
    (timestamp: number) => {
      videoContextApi?.setCurrentTime(timestamp / 1000);
    },
    [videoContextApi]
  );

  const { data: speakerStatisticsData, loading: isSpeakerStatsLoading } =
    useSpeakerStatisticsQuery({
      fetchPolicy: 'no-cache',
      variables: { recordingId: recordingId }
    });

  const handleVideoPause = useCallback(() => {
    videoContextApi?.pause();
  }, [videoContextApi]);

  const { isOpen: isSendModalOpen, close: closeSendModal } =
    useRouteModal(SHARE_RECORDING_ID);

  const primarySpeakerStats = useMemo(
    () =>
      speakerStatisticsData?.recording?.transcript?.speakerStatistics?.find(
        stat =>
          stat.speaker?.person?.id ===
          recording?.salesCoaching.feedback?.assessedPerson?.id
      ),
    [
      recording?.salesCoaching.feedback?.assessedPerson?.id,
      speakerStatisticsData?.recording?.transcript?.speakerStatistics
    ]
  );

  const primarySpeakerPerson = useMemo(
    () =>
      recording?.persons?.find(
        person => person.id === primarySpeakerStats?.speaker?.person?.id
      ),
    [recording, primarySpeakerStats]
  );

  const primarySpeakerEmail = useMemo(
    () =>
      primarySpeakerPerson?.contacts?.find(
        contact => contact.contactType === PersonContactType.Email
      )?.contact,
    [primarySpeakerPerson]
  );

  const {
    isAddScorecardModalOpen,
    toggleAddScorecardModal,
    scorecardSubmissionToEdit,
    selectedScorecardSubmissionId,
    setSelectedScorecardSubmissionId,
    setIsAddScorecardModalOpen,
    setScorecardSubmissionToEdit,
    scorecardSubmissions
  } = useUpsertScorecardModal();

  const summaryOverviewSection = (
    recording as unknown as RecordingQuery['recording']
  )?.intelligence?.summaryOverview as IntelligenceTextSection;

  const salesMeetingOutcomeSection = (
    recording as unknown as RecordingQuery['recording']
  )?.intelligence?.salesMeetingOutcome as IntelligenceTextSection;

  const summary =
    salesMeetingOutcomeSection?.data?.text ??
    summaryOverviewSection?.data?.text ??
    'Meeting outcome not available';

  return (
    <StyledRecordingDetail>
      <StyledHeader>
        {isLoading ? (
          <SkeletonHeaderMetadata />
        ) : (
          recording && (
            <StyledMetadataContainer>
              <CoachingHeaderMetadata recording={recording} />
            </StyledMetadataContainer>
          )
        )}

        <SendButton
          recording={recording}
          recipientName={primarySpeakerPerson?.name}
          recipientEmail={primarySpeakerPerson?.user?.email}
        />
      </StyledHeader>
      <StyledMainContentContainer>
        <StyledMainContent>
          {isLoading ? (
            <SkeletonVideo />
          ) : (
            recording && (
              <RecordingMedia
                key={recording.id}
                initialTime={initialTime}
                settingInitialTime={settingInitialTime}
                videoContextRef={videoContextRef}
                wrapperRef={wrapperRef}
                recording={recording}
                refetchRecording={refetchRecording}
                isLoading={isLoading}
                autoFocus={false}
              />
            )
          )}
        </StyledMainContent>

        <TabLayout
          animated
          size='small'
          tabId={tabId}
          onChangeTabId={onChangeTabId}
        >
          <TabLayout.Tab id='overview' label='Overview'>
            <TabContent isLoading={isLoading}>
              <SalesOverview
                summary={summary}
                scoreCard={recording?.salesCoaching.scoring?.scoreCard}
                speakerStatistics={primarySpeakerStats}
                onOpportunityClick={onChangeTabId}
              />
            </TabContent>
          </TabLayout.Tab>
          <TabLayout.Tab id='scoring' label='Scorecard'>
            <TabContent isLoading={isLoading}>
              <SalesScoring
                grainScorecard={recording?.salesCoaching.scoring?.scoreCard}
                feedbackAssessments={
                  recording?.salesCoaching.feedback?.assessments
                }
                primarySpeaker={primarySpeakerPerson}
                speakerStats={speakerStatisticsData}
                onTimestampClick={handleTimestampClick}
                setIsAddScorecardModalOpen={setIsAddScorecardModalOpen}
                selectedScorecardSubmissionId={selectedScorecardSubmissionId}
                setSelectedScorecardSubmissionId={
                  setSelectedScorecardSubmissionId
                }
                setScorecardSubmissionToEdit={setScorecardSubmissionToEdit}
                coachingJobStatus={recording?.salesCoaching?.status}
                canRunManually={recording?.salesCoaching?.canRunManually}
              />
            </TabContent>
          </TabLayout.Tab>
          <TabLayout.Tab id='metrics' label='Metrics'>
            <TabContent isLoading={isLoading}>
              <RecordingMetrics
                recording={recording}
                speakerStatisticsData={speakerStatisticsData}
                isSpeakerStatsLoading={isSpeakerStatsLoading}
                avatars={{
                  backgroundColor: color.gull,
                  textColor: color.blackbird
                }}
              />
            </TabContent>
          </TabLayout.Tab>
          <TabLayout.Tab id='comments' label='Comments'>
            <TabContent isLoading={isLoading}>
              <Comments
                recording={recording}
                transcript={transcript}
                currentVideoTime={currentVideoTime}
                handleVideoPause={handleVideoPause}
              />
            </TabContent>
          </TabLayout.Tab>
        </TabLayout>
      </StyledMainContentContainer>
      {isSendModalOpen && recording && primarySpeakerEmail && (
        <ShareRecordingPermissions
          recording={recording}
          onCancel={closeSendModal}
          predefinedEmails={[primarySpeakerEmail]}
          allowResendNotificationIfAlreadyShared
        />
      )}
      <UpsertScorecardModal
        open={isAddScorecardModalOpen}
        onClose={toggleAddScorecardModal}
        primarySpeakerId={primarySpeakerPerson?.id}
        scorecardSubmissionToEdit={scorecardSubmissionToEdit ?? undefined}
        scorecardSubmissions={scorecardSubmissions}
        onScorecardSubmissionDeleted={() => {
          setSelectedScorecardSubmissionId(null);
        }}
      />
    </StyledRecordingDetail>
  );
}
