import { useEffect } from 'react';
import { DealDetailsV4Fragment } from '~/pages/Deals/deal.generated';
import { DealDiagnosisResults } from './DealDiagnosisResults';
import { DealDiagnosisSetupBanner } from './DealDiagnosisSetupBanner';
import { DealDiagnosisSetupMiniBanner } from './DealDiagnosisSetupMiniBanner';
import { DealDiagnosisMethodologySelector } from './DealDiagnosisMethodologySelector';
import { DealDiagnosisResultsSkeleton } from './DealDiagnosisResultsSkeleton';
import { useDealDiagnosis } from './useDealDiagnosis';
import { DEFAULT_SPICED_DIAGNOSIS } from './defaultData';

type DealDiagnosisProps = {
  diagnosis?: DealDetailsV4Fragment['diagnosis'];
  companyName?: string;
  loading?: boolean;
  disabled?: boolean;
};

export const DealDiagnosis = ({
  diagnosis: diagnosisProp,
  disabled,
  companyName,
  loading = false
}: DealDiagnosisProps) => {
  const diagnosis = diagnosisProp ?? DEFAULT_SPICED_DIAGNOSIS;
  const {
    state: dealDiagnosisState,
    handleCloseSetup,
    handleSelectMethodology,
    handleCloseMethodology,
    handleSetReady,
    handleCompleteSetup,
    methodology
  } = useDealDiagnosis();

  useEffect(() => {
    if (methodology) {
      handleSetReady();
    }
  }, [methodology, handleSetReady]);

  if (loading) {
    return <DealDiagnosisResultsSkeleton />;
  }

  return (
    <>
      {dealDiagnosisState.setup && (
        <DealDiagnosisSetupBanner
          onClose={handleCloseSetup}
          onSelect={handleSelectMethodology}
        />
      )}
      {dealDiagnosisState.methodologySelector && (
        <DealDiagnosisMethodologySelector
          initialSelectedMethodology={null}
          onSelect={handleCompleteSetup}
          onClose={handleCloseMethodology}
        />
      )}
      {dealDiagnosisState.miniSetup && (
        <DealDiagnosisSetupMiniBanner onSelect={handleSelectMethodology} />
      )}
      {dealDiagnosisState.processing && (
        <DealDiagnosisResults processing diagnosis={diagnosis} />
      )}
      {dealDiagnosisState.ready && (
        <DealDiagnosisResults
          diagnosis={diagnosis}
          companyName={companyName}
          disabled={disabled}
        />
      )}
    </>
  );
};
