import { useEffect, useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import { useExperiment } from '@grain/api/auth';

const TWENTY_MINUTES = 20 * 60 * 1000;

export const useCacheRefresh = (refreshTimeout = TWENTY_MINUTES) => {
  const { enabled, loading } = useExperiment('cache_refresh');
  const client = useApolloClient();
  const lastHiddenTimeRef = useRef<number | null>(null);

  useEffect(() => {
    if (!enabled || loading) {
      return;
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        lastHiddenTimeRef.current = Date.now();
      } else if (document.visibilityState === 'visible') {
        const lastHiddenTime = lastHiddenTimeRef.current;
        if (lastHiddenTime && Date.now() - lastHiddenTime >= refreshTimeout) {
          client.reFetchObservableQueries();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [client, refreshTimeout, enabled, loading]);
};
