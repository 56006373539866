// @ts-strict-ignore
import {
  useRouteModal,
  ErrorBox,
  useShowToast,
  useConfirm,
  MembersList,
  Member,
  color
} from '@grain/grain-ui';
import React from 'react';
import { TRANSFER_RECORDING_ROUTE_ID } from '@grain/components/modals/constants';
import {
  useWorkspaceMembersInviteMutation,
  useRevokeInvitationMutation
} from '@grain/api/graphql/mutations/workspace.generated';
import { useMutation, useLazyQuery } from '@grain/api/graphql';
import { UserFragmentFragment } from '@grain/api/graphql/fragments/fragments.generated';
import {
  UserRole,
  TransferRecordingsType,
  User
} from '@grain/api/schema.generated';
import { useExperiment, useFeature, useMyself } from '@grain/api/auth';

import * as Intercom from '~/support/intercom';
import { pluralize } from '~/support/language';
import {
  useWorkspacePlan,
  useUpcomingWorkspacePlan,
  useFreePlan
} from '@grain/components/Subscriptions/hooks';
import { useWorkspaceWithMembers } from '@grain/components/Workspace/hooks';
import {
  deactivateUserMutation,
  reactivateUserMutation,
  workspaceMemberRoleSetMutation,
  workspaceMembersJoinRequestApproveMutation,
  workspaceMembersJoinRequestRejectMutation,
  workspaceMembersJoinRequestUpdateMutation
} from '../graphql';

import {
  addWorkspaceUsersToSubscriptionMutation,
  removeWorkspaceUsersFromSubscriptionMutation,
  subscriptionPreviewQuery
} from '~/modules/subscriptions/graphql';
import TransferRecordingModal from '~/modals/TransferRecordings';
import { useHandleShowUpgradeModal } from '~/modules/gates';
import { StyledConfirmTitle } from './styles';
import { useNavigate } from 'react-router-dom';
import { handleCardConfirmation } from '~/modules/subscriptions/PaymentForm/confirmCard';
import { getSeatBillingConfirmationText } from '~/modules/subscriptions/seats';

const sortBySelfAndName = (user1: User, user2: User) => {
  if (user1.isSelf) return -1;
  return user1.name.localeCompare(user2.name);
};

export default function Members() {
  const [suspendingUser, setUserToSuspend] = React.useState(null);
  const { myself } = useMyself();
  const navigate = useNavigate();
  const { isOpen: transferRecordingModal, open: openTransferRecordingModal } =
    useRouteModal(TRANSFER_RECORDING_ROUTE_ID);
  const { loading, error, workspace } = useWorkspaceWithMembers({
    fetchPolicy: 'cache-and-network'
  });
  const {
    loading: workspacePlanLoading,
    error: workspacePlanError,
    subscriptionPlan: workspacePlan
  } = useWorkspacePlan();
  const { subscriptionPlan: upcomingPlan } = useUpcomingWorkspacePlan();

  const { enabled: adminManagementEnabled } = useFeature('admin_paid_features');
  const { enabled: isFreemiumEnabled } = useExperiment('freemium');

  const showToast = useShowToast();
  const [revokeInvitation] = useRevokeInvitationMutation();
  const [resendInvite] = useWorkspaceMembersInviteMutation({
    errorPolicy: 'ignore'
  });
  const [addUsersToSubscription] = useMutation(
    addWorkspaceUsersToSubscriptionMutation
  );
  const addUserToSubscription = React.useCallback(
    (user: User) => {
      const userIds = [user.id];
      return addUsersToSubscription({
        variables: { userIds },
        onCompleted() {
          showToast({
            content: `${user.name} upgraded to a paid seat`,
            type: 'success'
          });
        }
      });
    },
    [addUsersToSubscription, showToast]
  );
  const [removeUsersFromSubscription] = useMutation(
    removeWorkspaceUsersFromSubscriptionMutation
  );
  const removeUserFromSubscription = React.useCallback(
    (user: User) => {
      const userIds = [user.id];
      return removeUsersFromSubscription({
        variables: { userIds },
        onCompleted() {
          showToast({
            content: `${user.name} downgraded to a free viewer`,
            type: 'success'
          });
        }
      });
    },
    [removeUsersFromSubscription, showToast]
  );

  const showConfirm = useConfirm();
  const freePlan = useFreePlan();

  const divideUsersByBilling = !workspacePlan?.isFree;

  const onInvitationRevoke = (email: string) => {
    showToast({ content: `${email} invite has been cancelled` });
    revokeInvitation({ variables: { email } });
  };

  const onInvitationResend = (email: string) => {
    showToast({ content: `${email} has been sent an invite` });
    resendInvite({ variables: { emails: [email] } });
  };

  const [deactivateUser] = useMutation(deactivateUserMutation, {
    onCompleted() {
      showToast({
        content: 'User has been deactivated.',
        type: 'success'
      });
    }
  });
  const [reactivateUser] = useMutation(reactivateUserMutation, {
    onCompleted() {
      showToast({
        content: 'User has been reactivated.',
        type: 'success'
      });
    }
  });

  const [changeRole] = useMutation(workspaceMemberRoleSetMutation, {
    onCompleted() {
      showToast({
        content: 'User role changed.',
        type: 'success'
      });
    }
  });

  // Update workspace size in Intercom. Currently this is the only place in the app where we fetch the number of users in a workspace
  React.useEffect(() => {
    if (!workspace?.users) return;
    Intercom.updateWorkspace(workspace);
  }, [workspace]);

  const workspaceUsers = React.useMemo(
    () => workspace?.users ?? [],
    [workspace?.users]
  );

  const currentUser = React.useMemo(() => {
    return workspaceUsers.find(user => user.isSelf);
  }, [workspaceUsers]);

  const sortedUsers = React.useMemo(() => {
    return workspaceUsers?.slice().sort(sortBySelfAndName);
  }, [workspaceUsers]);

  const pendingInvitees = workspace?.pendingInvitees;
  const sortedPendingInvitees = React.useMemo(() => {
    return (
      pendingInvitees?.slice().sort((a, b) => a.email.localeCompare(b.email)) ??
      []
    );
  }, [pendingInvitees]);

  const deactivatedUsers = workspace?.deactivatedUsers;
  const sortedDeactivatedUsers = React.useMemo(() => {
    return (
      deactivatedUsers?.slice().sort((a, b) => a.name.localeCompare(b.name)) ??
      []
    );
  }, [deactivatedUsers]);

  const sortedPaidUsers = React.useMemo(() => {
    return workspaceUsers.filter(user => user.onPlan).sort(sortBySelfAndName);
  }, [workspaceUsers]);

  const sortedFreeUsers = React.useMemo(() => {
    return workspaceUsers.filter(user => !user.onPlan).sort(sortBySelfAndName);
  }, [workspaceUsers]);

  const pendingJoinRequests = workspace?.pendingJoinRequests;
  const sortedPendingJoinRequests = React.useMemo(() => {
    return (
      pendingJoinRequests
        ?.slice()
        .sort((a, b) => a.name.localeCompare(b.name)) ?? []
    );
  }, [pendingJoinRequests]);

  const [getSubscriptionPreview] = useLazyQuery(subscriptionPreviewQuery, {
    fetchPolicy: 'cache-and-network'
  });

  const getRenewalDate = React.useCallback(() => {
    if (!freePlan || !workspace?.workspaceSubscription) return null;

    const renewalDateInstance = new Date(
      workspace?.workspaceSubscription?.renewalDate
    );
    const renewalDateString = renewalDateInstance.toLocaleDateString(
      undefined,
      {
        dateStyle: 'long'
      }
    );

    return renewalDateString;
  }, [freePlan, workspace?.workspaceSubscription]);

  const totalSeats = workspace?.workspaceSubscription?.seatQuantity ?? 0;

  // seatQuantity should never be less than count but be defensive.
  const availableSeats = Math.max(
    workspace?.workspaceSubscription?.seatQuantity -
      workspace?.workspaceSubscription?.count,
    0
  );

  const openSubscribeModal = useHandleShowUpgradeModal();

  const handleUpgrade = React.useCallback(() => {
    if (workspacePlan?.isFree) {
      navigate('/app/settings/workspace?tab=plans');
      return;
    }
    openSubscribeModal(workspacePlan?.sku, {
      omitPlanMembers: true,
      source: 'Members Page Buy Seats'
    });
  }, [openSubscribeModal, workspacePlan, navigate]);

  const currentUserIsAdmin = React.useMemo(() => {
    return currentUser?.role === UserRole.Admin || workspacePlan?.isFree;
  }, [currentUser?.role, workspacePlan?.isFree]);

  const confirmDowngradeUser = React.useCallback(
    async (user: User) => {
      await showConfirm({
        width: 440,
        cancelContent: 'Cancel',
        confirmContent: 'Downgrade',
        confirmButtonType: 'danger',
        confirmButtonProps: {
          css: ['width: 160px;']
        },
        onConfirm: async () => {
          await removeUserFromSubscription(user);
        },
        title: (
          <StyledConfirmTitle>
            <span css={['white-space: nowrap;']}>{user.name}</span> will be
            downgraded to a free viewer.
          </StyledConfirmTitle>
        ),
        description:
          'A paid seat will be added to your account which can be used to upgrade another user. The paid seat will be removed if unused at the end of this billing cycle.'
      });
    },
    [showConfirm, removeUserFromSubscription]
  );

  const changeUserRole = React.useCallback(
    async (user: User, role: UserRole) => {
      await changeRole({ variables: { userId: user.id, userRole: role } });
    },
    [changeRole]
  );

  const confirmSuspendUserBasic = React.useCallback(
    (user: User) => {
      const firstname = user.name.split(' ')[0] ?? user.name;
      showConfirm({
        width: 420,
        onConfirm: async () => {
          await deactivateUser({
            variables: { userId: user.id }
          });
        },
        confirmContent: 'Deactivate User',
        confirmButtonType: 'danger',
        cancelContent: 'Go Back',
        description: (
          <div>
            {firstname} will no longer be able to access this workspace, and{' '}
            <span css={['font-weight: 600;']}>
              {firstname}’s {user.recordingCount}{' '}
              {pluralize('meeting', 'meetings', user.recordingCount)} will be
              permanently deleted.
            </span>
            <br />
            <br /> Upgrade to a paid workspace to transfer {firstname}’s
            recordings.
          </div>
        ),
        title: (
          <StyledConfirmTitle>
            Are you sure you want to deactivate {user.name}?
          </StyledConfirmTitle>
        )
      });
    },
    [showConfirm, deactivateUser]
  );

  const confirmSuspendUser = React.useCallback(
    async (
      user: UserFragmentFragment,
      transferOptions: {
        transferRecordingsTo: string;
        transferRecordingsType: TransferRecordingsType;
      } = null
    ) => {
      if (
        transferOptions.transferRecordingsType === TransferRecordingsType.All
      ) {
        await deactivateUser({
          variables: { userId: user.id, ...(transferOptions ?? {}) }
        });
        return;
      }

      if (
        transferOptions.transferRecordingsType ===
          TransferRecordingsType.WorkspaceShared &&
        user.unsharedRecordingCount === 0
      ) {
        await deactivateUser({
          variables: { userId: user.id, ...(transferOptions ?? {}) }
        });
        return;
      }

      showConfirm({
        width: 420,
        onConfirm: async () => {
          await deactivateUser({
            variables: { userId: user.id, ...(transferOptions ?? {}) }
          });
        },
        confirmContent: 'Deactivate and Delete Meetings',
        confirmButtonType: 'danger',
        cancelContent: 'Go Back',
        description: transferOptions?.transferRecordingsTo ? (
          <div>
            {user.unsharedRecordingCount}{' '}
            {pluralize(
              'meeting has',
              'meetings have',
              user.unsharedRecordingCount
            )}{' '}
            not been transferred to a new recipient and will be deleted
            permanently.
          </div>
        ) : (
          'If you deactivate this user all their meetings would be deleted'
        ),
        title: (
          <StyledConfirmTitle>
            {transferOptions?.transferRecordingsTo
              ? 'Are you sure?'
              : 'Deactivate User'}
          </StyledConfirmTitle>
        )
      });
    },
    [showConfirm, deactivateUser]
  );

  const confirmSuspendUserAdmin = React.useCallback(
    (user: User) => {
      setUserToSuspend(user);
      openTransferRecordingModal();
    },
    [openTransferRecordingModal]
  );

  // This should be disabled when a workspace cannot have more than one user
  const maybeConfirmReactivateUser = React.useCallback(
    (user: User) => {
      // if workspace is free or user was not on paid plan, just reactivate user (no changes to billing)
      if (workspacePlan?.isFree || !user.onPlan) {
        return reactivateUser({ variables: { userId: user.id } });
      }

      // show confirmation for paid users in paid workspaces
      showConfirm({
        width: 340,
        onConfirm: async () => {
          await reactivateUser({ variables: { userId: user.id } });
        },
        confirmButtonProps: {
          css: ['width: 140px;']
        },
        confirmContent: 'Reactivate User',
        confirmButtonType: 'primary',
        cancelContent: 'Go Back',
        description:
          'The card on file for this workspace will be billed for one additional user on your existing paid plan.',
        title: 'Reactivate User'
      });
    },
    [workspacePlan, reactivateUser, showConfirm]
  );

  const confirmAddBackUser = React.useCallback(
    async (user: User) => {
      await showConfirm({
        width: 420,
        confirmContent: `Add Back to ${workspacePlan?.name}`,
        cancelContent: 'Go Back',
        confirmButtonType: 'primary',
        confirmButtonProps: {
          css: ['width: 200px;']
        },
        onConfirm: async () => {
          await addUserToSubscription(user);
        },
        title: (
          <StyledConfirmTitle>
            You are about to add{' '}
            <span css={['white-space: nowrap;']}>{user.name}</span> back to{' '}
            {workspacePlan?.name}.
          </StyledConfirmTitle>
        )
      });
    },
    [showConfirm, workspacePlan, addUserToSubscription]
  );

  const upgradeUser = React.useCallback(
    async (user: User) => {
      try {
        const { errors } = await addUserToSubscription(user);
        const error = Array.isArray(errors) ? errors[0] : errors;
        if (error) {
          await handleCardConfirmation(error);
        }
      } catch {
        showToast({
          content: 'An Error Occured.',
          type: 'failure'
        });
      }
    },
    [addUserToSubscription, showToast]
  );

  const confirmUpgradeUser = React.useCallback(
    async (user: User) => {
      const { workspaceSubscription } = workspace;
      const existingUsers = [...workspaceUsers, ...deactivatedUsers]
        .filter(u => u.onPlan)
        .map(u => u.id);
      const subscriptionPreviewRes = await getSubscriptionPreview({
        variables: {
          subscriptionArgs: {
            userIds: [...existingUsers, user.id]
          }
        }
      });
      const subscriptionPreview =
        subscriptionPreviewRes?.data?.subscriptionPreview;
      const description = getSeatBillingConfirmationText({
        subscriptionPreview,
        workspacePlan: upcomingPlan,
        workspaceSubscription
      });

      showConfirm({
        width: 420,
        confirmContent: 'Upgrade',
        cancelContent: 'Cancel',
        confirmButtonType: 'primary',
        onConfirm: async () => {
          await upgradeUser(user);
        },
        confirmButtonProps: {
          css: ['width: 124px;']
        },
        description: description,
        title: (
          <StyledConfirmTitle>
            Upgrade <span css={['white-space: nowrap;']}>{user.name}</span> to a
            Paid seat
          </StyledConfirmTitle>
        )
      });
    },
    [
      showConfirm,
      upcomingPlan,
      upgradeUser,
      workspaceUsers,
      deactivatedUsers,
      getSubscriptionPreview,
      workspace
    ]
  );

  const requestAssignFreeNotetaker = React.useCallback((user: User) => {
    Intercom.showNewMessage(
      `Please assign the Free Notetaker seat to ${user.name} (${user.email})`
    );
  }, []);

  const confirmAssignFreeNotetaker = React.useCallback(
    (user: User) => {
      showConfirm({
        width: 420,
        closable: false,
        confirmContent: 'Assign Seat',
        cancelContent: 'Cancel',
        confirmButtonType: 'primary',
        onConfirm: () => {
          requestAssignFreeNotetaker(user);
        },
        description:
          'You will lose unlimited recordings and AI notes, but will still have full workspace access.',
        title: `Assign ${user.name} the Free Notetaker seat?`
      });
    },
    [requestAssignFreeNotetaker, showConfirm]
  );

  const getRenewalTooltipText = (user: User) => {
    if (!freePlan || !workspace?.workspaceSubscription) return null;
    const renewalDateString = getRenewalDate();

    return `${user.name} will be changed to a Free User on ${renewalDateString}`;
  };

  const [workspaceMembersJoinRequestApprove] = useMutation(
    workspaceMembersJoinRequestApproveMutation,
    {
      onCompleted() {
        showToast({
          content: 'Request has been approved.',
          type: 'success'
        });
      }
    }
  );

  const [workspaceMembersJoinRequestReject] = useMutation(
    workspaceMembersJoinRequestRejectMutation,
    {
      onCompleted() {
        showToast({
          content: 'Request has been denied.',
          type: 'success'
        });
      }
    }
  );

  const [workspaceMembersJoinRequestUpdate] = useMutation(
    workspaceMembersJoinRequestUpdateMutation,
    {
      onCompleted() {
        showToast({
          content: 'Request has been updated.',
          type: 'success'
        });
      }
    }
  );

  if ((loading && !workspace) || workspacePlanLoading) return null;
  if (error || workspacePlanError) {
    return <ErrorBox error={error || workspacePlanError} />;
  }

  const showPending = workspace?.pendingInvitees.length > 0;
  const showSuspended = sortedDeactivatedUsers.length > 0;
  const showPendingJoinRequests = sortedPendingJoinRequests.length > 0;

  const freeDescription =
    'Can not record, upload or import meetings but can access all other features on your plan.';
  const nonFreeDescription =
    'Can record, upload and import meetings and access all features on your plan.';

  // Discounting trials, admins should always be able to buy seats when they run
  // out of them, unless the workspace is "on invoice",
  // i.e. subscription_self_serve = false.  Then they can only assign remaining
  // floating seats (or unassign via downgrade).
  const canBuySeatsIfNoneRemain =
    workspace?.workspaceSubscription?.canEdit !== false;
  const canSetSeatsToPaid = canBuySeatsIfNoneRemain || availableSeats > 0;

  const onTrial = workspace?.workspaceSubscription?.trial;
  const onNonRenewingTrial =
    onTrial && !workspace?.workspaceSubscription?.autoRenew;

  // Since trial users aren't paying for their seats, don't call them "Paid" seats.
  // Instead use the language from the Free plan and call them "Free Notetaker"
  // seats.
  const paidSeatName = !onTrial ? 'Paid' : 'Free Notetaker';

  const members: Member[] = [
    ...(!divideUsersByBilling
      ? [
          ...sortedUsers.map(user => {
            return {
              name: user.name,
              email: user.email,
              type: 'Active',
              role: user.role === UserRole.Admin ? 'Admin' : 'Member',
              seatType: user.onPlan ? 'Free notetaker' : 'Free viewer',
              canSetSeatType: !user.onPlan,
              prominentSeatType: user.onPlan,
              activeStatus: 'Active',
              isAdmin: user?.role === UserRole.Admin,
              isSelf: user?.isSelf,
              seatTypeMenuItems: [
                {
                  text: 'Free notetaker',
                  click: () => confirmAssignFreeNotetaker(user),
                  subText:
                    'Unlimited meeting recordings and AI notes, with full workspace access.',
                  selected: user.onPlan
                },
                {
                  text: 'Free viewer',
                  click: (): null => null,
                  subText:
                    'View and comment on shared meetings with full workspace access.',
                  selected: !user.onPlan
                }
              ],
              roleMenuItems: [
                {
                  text: 'Admin',
                  click: () => {
                    if (user?.role === UserRole.Admin) return;
                    changeUserRole(user, UserRole.Admin);
                  },
                  selected: user?.role === UserRole.Admin,
                  subText:
                    'Can change workspace settings, deactivate members, and add other admins.'
                },
                {
                  text: 'Member',
                  click: () => {
                    if (user?.role === UserRole.Member) return;
                    changeUserRole(user, UserRole.Member);
                  },
                  selected: user?.role === UserRole.Member,
                  subText: 'Can invite workspace members and upgrade.'
                }
              ],
              menuItems: [
                {
                  click: (): null => null,
                  text: 'Active',
                  selected: true,
                  subText:
                    'Can sign in to your workspace and see all recordings shared with workspace.'
                },
                ...(!user.isSelf && currentUser?.role === UserRole.Admin
                  ? [
                      {
                        text: 'Deactivate member',
                        click: () => {
                          workspacePlan?.isFree
                            ? confirmSuspendUserBasic(user)
                            : confirmSuspendUserAdmin(user);
                        },
                        subText: 'Can no longer sign into Grain.',
                        selected: false,
                        tone: 'danger'
                      }
                    ]
                  : [])
              ]
            };
          })
        ]
      : []),

    ...(divideUsersByBilling
      ? [
          ...(sortedPaidUsers.length > 0
            ? sortedPaidUsers.map(user => {
                const userRenewTooltip = !user.staysOnPlan
                  ? getRenewalTooltipText(user)
                  : null;
                return {
                  name: user.name,
                  email: user.email,
                  type: !user.staysOnPlan ? 'Pending downgrade' : 'Active',
                  role: user.role === UserRole.Admin ? 'Admin' : 'Member',
                  seatType: user.onPlan ? paidSeatName : 'Free viewer',
                  activeStatus: 'Active',
                  isAdmin: user?.role === UserRole.Admin,
                  isSelf: user?.isSelf,
                  userRenewTooltip,
                  seatTypeMenuItems: [
                    ...(user.staysOnPlan
                      ? [
                          {
                            text: paidSeatName,
                            click: (): null => null,
                            subText: nonFreeDescription,
                            selected: user.planSku === workspacePlan?.sku,
                            disabled: !canSetSeatsToPaid
                          },
                          {
                            text: 'Free viewer',
                            click: () => confirmDowngradeUser(user),
                            subText: freeDescription,
                            selected: user.planSku === freePlan?.sku
                          }
                        ]
                      : []),
                    ...(!user.staysOnPlan
                      ? [
                          {
                            text: paidSeatName,
                            click: () => confirmAddBackUser(user),
                            subText: nonFreeDescription,
                            selected: user.planSku === workspacePlan?.sku,
                            disabled: !canSetSeatsToPaid
                          },
                          {
                            text: 'Free viewer',
                            click: (): null => null,
                            subText: freeDescription,
                            selected: user.planSku === freePlan?.sku
                          }
                        ]
                      : [])
                  ],
                  roleMenuItems: [
                    {
                      text: 'Admin',
                      click: () => {
                        if (user?.role === UserRole.Admin) return;
                        changeUserRole(user, UserRole.Admin);
                      },
                      selected: user?.role === UserRole.Admin,
                      subText:
                        'Can change workspace settings, deactivate members, and add other admins.'
                    },
                    {
                      text: 'Member',
                      click: () => {
                        if (user?.role === UserRole.Member) return;
                        changeUserRole(user, UserRole.Member);
                      },
                      selected: user?.role === UserRole.Member,
                      subText: 'Can invite workspace members and upgrade.'
                    }
                  ],
                  menuItems: [
                    {
                      click: (): null => null,
                      text: 'Active',
                      selected: true,
                      subText:
                        'Can sign in to your workspace and see all recordings shared with workspace.'
                    },
                    ...(!user.isSelf && currentUser?.role === UserRole.Admin
                      ? [
                          {
                            text: 'Deactivate',
                            selected: false,
                            click: () => {
                              workspacePlan?.isFree
                                ? confirmSuspendUserBasic(user)
                                : confirmSuspendUserAdmin(user);
                            },
                            subText: 'Can no longer sign into Grain.',
                            tone: 'danger'
                          }
                        ]
                      : [])
                  ]
                };
              })
            : []),

          ...(sortedFreeUsers.length > 0
            ? sortedFreeUsers.map(user => {
                return {
                  name: user.name,
                  email: user.email,
                  type: 'Active',
                  role: user.role === UserRole.Admin ? 'Admin' : 'Member',
                  seatType: user.onPlan ? paidSeatName : 'Free viewer',
                  activeStatus: 'Active',
                  isAdmin: user?.role === UserRole.Admin,
                  isSelf: user?.isSelf,
                  seatTypeMenuItems: [
                    {
                      text: paidSeatName,
                      click: workspacePlan?.isFree
                        ? (): null => null
                        : () => confirmUpgradeUser(user),
                      subText: nonFreeDescription,
                      selected: user.planSku === workspacePlan?.sku,
                      disabled: !canSetSeatsToPaid
                    },
                    {
                      text: 'Free viewer',
                      click: (): null => null,
                      subText: freeDescription,
                      selected: user.planSku === freePlan?.sku
                    }
                  ],
                  roleMenuItems: [
                    {
                      text: 'Admin',
                      click: () => {
                        if (user?.role === UserRole.Admin) return;
                        changeUserRole(user, UserRole.Admin);
                      },
                      selected: user?.role === UserRole.Admin,
                      subText:
                        'Can change workspace settings, deactivate members, and add other admins.'
                    },
                    {
                      text: 'Member',
                      click: () => {
                        if (user?.role === UserRole.Member) return;
                        changeUserRole(user, UserRole.Member);
                      },
                      selected: user?.role === UserRole.Member,
                      subText: 'Can invite workspace members and upgrade.'
                    }
                  ],
                  menuItems: [
                    {
                      click: (): null => null,
                      text: 'Active',
                      selected: true,
                      subText:
                        'Can sign in to your workspace and see all recordings shared with workspace.'
                    },
                    ...(!user.isSelf && currentUser?.role === UserRole.Admin
                      ? [
                          {
                            text: 'Deactivate',
                            click: () => {
                              workspacePlan?.isFree
                                ? confirmSuspendUserBasic(user)
                                : confirmSuspendUserAdmin(user);
                            },
                            selected: false,
                            subText: 'Can no longer sign into Grain.',
                            tone: 'danger'
                          }
                        ]
                      : [])
                  ]
                };
              })
            : [])
        ]
      : []),

    ...(showPending
      ? [
          ...sortedPendingInvitees.map(user => {
            return {
              name: 'Pending member',
              email: user.email,
              type: 'Pending',
              role: 'Member',
              seatType: user.addToPlan ? paidSeatName : 'Free viewer',
              activeStatus: 'Pending',
              isAdmin: false,
              seatTypeMenuItems: [],
              menuItems: [
                {
                  text: 'Resend invite',
                  click: () => onInvitationResend(user.email)
                },
                {
                  text: 'Cancel invite',
                  click: () => onInvitationRevoke(user.email),
                  subText: '\t' // whitespace to invite menu items
                }
              ],
              roleMenuItems: []
            };
          })
        ]
      : []),

    ...(showSuspended
      ? [
          ...sortedDeactivatedUsers.map(user => {
            return {
              name: user.name,
              email: user.email,
              type: 'Deactivated',
              role: 'Deactivated member',
              seatType: user.onPlan ? paidSeatName : 'Free viewer',
              activeStatus: 'Inactive',
              seatTypeMenuItems: [
                {
                  text: paidSeatName,
                  click: (): null => null,
                  subText: nonFreeDescription,
                  self: user.planSku === workspacePlan?.sku,
                  disabled: !canSetSeatsToPaid
                },
                {
                  text: 'Free viewer',
                  click: (): null => null,
                  subText: freeDescription,
                  selected: user.planSku === freePlan?.sku
                }
              ],
              menuItems: [
                ...[
                  {
                    text: 'Reactivate',
                    click: () => maybeConfirmReactivateUser(user),
                    selected: false,
                    subText:
                      'Can sign in to your workspace and see all recordings shared with workspace.'
                  },
                  {
                    click: (): null => null,
                    text: 'Deactivated',
                    value: 'Inactive',
                    selected: true,
                    subText: 'Can no longer sign into Grain.'
                  }
                ]
              ]
            };
          })
        ]
      : []),

    ...(showPendingJoinRequests
      ? [
          ...sortedPendingJoinRequests.map(user => {
            return {
              name: user.name,
              email: user.email,
              type: 'Pending',
              role: user.role === UserRole.Admin ? 'Admin' : 'Member',
              seatType: user.addToPlan ? paidSeatName : 'Free viewer',
              activeStatus: 'Pending approval',
              roleMenuItems: [
                {
                  text: 'Admin',
                  click: () => {
                    if (user?.role === UserRole.Admin) return;
                    workspaceMembersJoinRequestUpdate({
                      variables: {
                        addToPlan: user.addToPlan,
                        joinRequestId: user.id,
                        role: UserRole.Admin
                      }
                    });
                  },
                  selected: user?.role === UserRole.Admin,
                  subText:
                    'Can change workspace settings, deactivate members, and add other admins.'
                },
                {
                  text: 'Member',
                  click: () => {
                    if (user?.role === UserRole.Member) return;
                    workspaceMembersJoinRequestUpdate({
                      variables: {
                        addToPlan: user.addToPlan,
                        joinRequestId: user.id,
                        role: UserRole.Member
                      }
                    });
                  },
                  selected: user?.role === UserRole.Member,
                  subText: 'Can invite workspace members and upgrade.'
                }
              ],
              seatTypeMenuItems: !workspacePlan?.isFree
                ? [
                    {
                      text: paidSeatName,
                      click: () => {
                        workspaceMembersJoinRequestUpdate({
                          variables: {
                            addToPlan: true,
                            joinRequestId: user.id,
                            role: user.role
                          }
                        });
                      },
                      subText: nonFreeDescription,
                      selected: user.addToPlan,
                      disabled: !canSetSeatsToPaid
                    },
                    {
                      text: 'Free viewer',
                      click: () => {
                        workspaceMembersJoinRequestUpdate({
                          variables: {
                            addToPlan: false,
                            joinRequestId: user.id,
                            role: user.role
                          }
                        });
                      },
                      subText: freeDescription,
                      selected: !user.addToPlan
                    }
                  ]
                : [],
              menuItems: [],
              actions:
                currentUser?.role === UserRole.Admin
                  ? [
                      {
                        text: 'Deny',
                        click: () =>
                          workspaceMembersJoinRequestReject({
                            variables: { joinRequestId: user.id }
                          }),
                        variant: 'secondary',
                        css: `background: ${color.gull} !important;`
                      },
                      {
                        text: 'Approve',
                        click: () =>
                          workspaceMembersJoinRequestApprove({
                            variables: { joinRequestId: user.id }
                          }),
                        variant: 'primary',
                        css: `background: ${color.graieen} !important; margin-left: 8px;`
                      }
                    ]
                  : []
            };
          })
        ]
      : [])
  ];

  let buySeatsDisabledTooltip;
  let isBuySeatDisabled = false;

  const hasNoFreeActiveUsers = sortedFreeUsers.length === 0;

  if (!workspacePlan?.isFree) {
    isBuySeatDisabled =
      hasNoFreeActiveUsers ||
      myself.user.role !== UserRole.Admin ||
      availableSeats > 0;
    if (hasNoFreeActiveUsers) {
      buySeatsDisabledTooltip = 'Invite more members to buy seats';
    } else if (myself.user.role !== UserRole.Admin) {
      buySeatsDisabledTooltip = 'Contact workspace admin to buy seats';
    } else if (availableSeats > 0) {
      buySeatsDisabledTooltip = 'Use unassigned paid seats first';
    }
  }

  return (
    <>
      <MembersList
        members={members}
        totalSeats={totalSeats}
        availableSeats={availableSeats}
        currentPlan={workspacePlan}
        onTrial={onTrial}
        isAdmin={currentUserIsAdmin}
        canSetSeatOfMember={!onNonRenewingTrial}
        adminManagementEnabled={adminManagementEnabled}
        isFreemiumEnabled={isFreemiumEnabled}
        renewalDate={getRenewalDate()}
        buySeatsProps={{
          enabled:
            workspace?.workspaceSubscription?.canEdit !== false &&
            !(workspacePlan?.isFree || onTrial),
          onClick: handleUpgrade,
          isDisabled: isBuySeatDisabled,
          disabledTooltip: buySeatsDisabledTooltip
        }}
      />
      <div css={['margin-bottom: 24px;']} />
      {transferRecordingModal && (
        <TransferRecordingModal
          user={suspendingUser}
          users={sortedUsers}
          confirmSuspendUser={confirmSuspendUser}
        />
      )}
    </>
  );
}
