import { useMemo } from 'react';
import type { Filters } from '../useFiltersManager';
import { AutocompleteMenuList } from './AutocompleteMenuList';
import { DateMenuList } from './DateMenuList';
import { Menu, Chip } from '@grain/grain-ui/v4';
import styled from '@emotion/styled';
import { isAutocompleteFilter, isDateFilter } from '../utils';

type ActiveFiltersProps = {
  filters: Filters;
};

const StyledChip = styled(Chip)`
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const ActiveFilters = ({ filters }: ActiveFiltersProps) => {
  const activeFilters = useMemo(() => {
    return Object.values(filters).filter(
      filter => filter.active && 'asChip' in filter
    );
  }, [filters]);

  return (
    <>
      {activeFilters
        .filter(filter => !filter.hidden)
        .map(filter => {
          return filter.asChip.map(chipProps => (
            <Menu
              key={filter.id + chipProps.title}
              tippyProps={{
                // We should only show a dropdown menu for Autocomplete or Date style filters
                disabled: !(
                  isAutocompleteFilter(filter) || isDateFilter(filter)
                )
              }}
              content={
                <>
                  {isAutocompleteFilter(filter) && (
                    <AutocompleteMenuList
                      filter={filter}
                      key={filter.id + chipProps.title}
                    />
                  )}
                  {isDateFilter(filter) && <DateMenuList filter={filter} />}
                </>
              }
            >
              <StyledChip
                key={filter.id + chipProps.title}
                size='sm'
                {...chipProps}
                // noop onClick is required for the proper styling/hover effects on the Chip Content.
                onClick={() => {}}
                onClose={() => filter.reset()}
              />
            </Menu>
          ));
        })}
    </>
  );
};
