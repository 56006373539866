import PropTypes from 'prop-types';
import FeatureUpgradeInformation from '~/modules/subscriptions/FeatureUpgradeInformation';

IntegrationFeatureGate.propTypes = {
  requiredPlan: PropTypes.object.isRequired,
  featureName: PropTypes.string.isRequired
};

export default function IntegrationFeatureGate({
  requiredPlan,
  featureName,
  ...rest
}) {
  return (
    <FeatureUpgradeInformation {...rest}>
      Advanced integrations are available to workspaces starting on a{' '}
      {requiredPlan?.name} plan.
    </FeatureUpgradeInformation>
  );
}
