import styled from '@emotion/styled';
import { color, pxToRem } from '@grain/grain-ui';
import { spacing } from '~/../grain-styles/constants';

export const StyledSkip = styled.a`
  ${color.fg.crow};
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(20)};
  font-style: normal;
  font-weight: 500;
  text-decoration-line: underline;
  ${spacing.mt8};
  cursor: pointer;
`;

export const StyledConnect = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: solid 1px ${color.pigeon};
  border-radius: 12px;
  outline: none;
  ${color.bg.swan};
  ${color.fg.blackbird};
  width: 100%;
  height: 48px;
  cursor: pointer;

  font-size: ${pxToRem(14)};
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  gap: 12px;

  &:hover {
    text-decoration: none;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
