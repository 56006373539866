import { useHandleRecordingFileUpload } from '@grain/grain-ui';
import { recordingUploadInfoQuery } from '@grain/api/graphql/queries';
import { useQuery } from '@grain/api/graphql';
import { useWebSocket } from '@grain/components/WebSocketContext';
import { useWorkspacePlan } from '@grain/components/Subscriptions/hooks';
import { useMyself } from '@grain/api/auth';
import { useNavigate } from 'react-router-dom';
import { captureException } from '@grain/components/support/sentry';

export const useFileUploadSetup = () => {
  const { subscriptionPlan: workspacePlan } = useWorkspacePlan();
  const { myself } = useMyself();
  const { userChannel } = useWebSocket();
  const navigate = useNavigate();

  const { refetch: refreshUploadInfoData } = useQuery(
    recordingUploadInfoQuery,
    {
      fetchPolicy: 'network-only',
      skip: true,
      onError: () => {
        return true;
      }
    }
  );

  const { uploadedRecordings, handleNewFileUpload, removeUpload, setRef } =
    useHandleRecordingFileUpload();

  setRef(navigate, userChannel, myself?.user, workspacePlan, captureException);

  return {
    uploadedRecordings,
    handleNewFileUpload: (file: File) => {
      handleNewFileUpload(refreshUploadInfoData, file);
    },
    removeUpload,
    refreshUploadInfoData
  };
};
