import { PlanResourceLimitation } from '@grain/api/schema.generated';

export function getRecordingAllocationMessage(plan, options = {}) {
  if (plan?.resourceLimitation === PlanResourceLimitation.UserRecordingCount) {
    if (plan.recordingCountLimit) {
      return (
        <>
          <b>5</b> users with{' '}
          <b>{plan.recordingCountLimit} Meetings per user</b> and Transcripts
        </>
      );
    } else {
      return (
        <>
          <b>unlimited meetings </b>and access to{' '}
          <b>all {plan.shortName} features</b>
        </>
      );
    }
  }
  return <b>Unlimited Recordings</b>;
}

export function getInvitationMessage(plan, workspace, displayCents, isAdmin) {
  const isYearly =
    workspace?.workspaceSubscription?.billingPeriod !== 'MONTHLY';
  const monthlyPrice = isYearly ? plan.yearlyPrice / 12 : plan.monthlyPrice;
  if (!isAdmin) {
    return 'Contact your admin to add a user to a paid plan';
  }

  return `Unlimited recordings and premium features for $${displayCents(
    monthlyPrice
  )}/month.`;
}
