import {
  Button,
  capitalize,
  color,
  ErrorBox,
  font,
  spacing,
  useAnalytics,
  useRouteModal
} from '@grain/grain-ui';
import { useCardBrand, images as cardImages } from 'react-card-brand';
import { CancelPlan, MoreBillingInfo } from './styles';
import SectionContentRow from './SectionContentRow';
import Section from './Section';
import { format } from 'date-fns';
import { displayCents } from '~/support/price';
import {
  BillingPeriod,
  SubscriptionPlan,
  User,
  WorkspaceSubscription
} from '@grain/api/schema.generated';
import { useBillingActions } from './useBillingActions';
import { SubscriptionExpirationExplanation } from '~/modules/subscriptions/helpers';
import { CANCEL_PLANS_ROUTE_ID } from '@grain/components/modals/constants';
import { theme } from 'ui/v4/theme';
import { useMemo } from 'react';
import { showNewMessage } from '~/support/intercom';

export default function BillingDetailsSection({
  workspaceSubscription,
  isLoading,
  upcomingPlan,
  workspacePlan,
  wsUsers
}: {
  workspaceSubscription: WorkspaceSubscription;
  isLoading: boolean;
  planName: string;
  workspacePlanSku: string;
  upcomingPlan: SubscriptionPlan;
  workspacePlan: SubscriptionPlan;
  wsUsers: User[];
}) {
  const { trackEvent } = useAnalytics();
  const { open } = useRouteModal(CANCEL_PLANS_ROUTE_ID);
  const {
    planDetails,
    billingPeriod,
    cardBrand,
    cardLastFour,
    billingEmail,
    renewalDate,
    renewalPrice,
    autoRenew,
    trial,
    renewalBillingPeriod
  } = workspaceSubscription ?? {};

  const canEditPlan = workspaceSubscription?.canEdit;

  const isTrial = trial && !autoRenew;

  const {
    handleReactivate,
    PaymentModal,
    openBillingModal,
    openBillingEmailModal,
    BillingChangeEmailModal,
    openBillingCycleModal,
    BillingChangeIntervalModal,
    error: activateError
  } = useBillingActions({
    workspacePlan,
    wsUsers,
    workspaceSubscription
  });

  const isFree = planDetails?.isFree;
  const billingCycle = useMemo(() => {
    const renewalPeriod = renewalBillingPeriod ?? billingPeriod;
    if (renewalPeriod === BillingPeriod.Monthly) {
      return 'Monthly';
    }
    return 'Yearly';
  }, [renewalBillingPeriod, billingPeriod]);
  const showContinuePlanMessage = !cardLastFour;

  const { getSvgProps } = useCardBrand();
  const svgProps = getSvgProps({
    type: cardBrand ?? undefined,
    images: cardImages
  });

  const formattedRenewalDate = renewalDate
    ? format(new Date(renewalDate), 'dd/MM/yy')
    : null;

  return (
    <Section title='Billing Details' isLoading={isLoading}>
      {PaymentModal}
      {BillingChangeEmailModal}
      {BillingChangeIntervalModal}
      {cardLastFour ? (
        <>
          <SectionContentRow
            title='Payment method'
            details={
              <div css={['display: flex; align-items: center; gap: 2px;']}>
                <svg {...svgProps} />
                <span css={['margin-left: 6px']}>
                  {cardBrand && capitalize(cardBrand)}
                </span>
                <span>- {cardLastFour}</span>
              </div>
            }
            cta='Update'
            onClick={openBillingModal}
          />
          {!isTrial && !workspacePlan.isLegacy && (
            <SectionContentRow
              title='Billing cycle'
              details={isFree ? '-' : `${billingCycle}`}
              subDetails={
                renewalBillingPeriod && renewalBillingPeriod !== billingPeriod
                  ? `(beginning on ${formattedRenewalDate})`
                  : ''
              }
              cta='Update'
              onClick={!isFree ? openBillingCycleModal : undefined}
            />
          )}
          <SectionContentRow
            title='Billing email'
            details={billingEmail || '-'}
            cta={billingEmail ? 'Update' : 'Add'}
            onClick={openBillingEmailModal}
          />
        </>
      ) : null}

      <MoreBillingInfo
        css={[
          `margin-top: ${cardLastFour ? theme.primitives.spacing[9] : '0'}`
        ]}
      >
        <div className='fs-block'>
          {upcomingPlan?.sku === workspacePlan?.sku ? (
            renewalDate ? (
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '16px'
                }}
              >
                <div>
                  Your renewal price is <b>${displayCents(renewalPrice)}</b> and
                  your next renewal date is <b>{formattedRenewalDate}</b>
                  {canEditPlan && (
                    <CancelPlan
                      onClick={() => {
                        trackEvent('Button Clicked', {
                          button_name: 'cancel_plan',
                          location: 'billing_page',
                          button_text: 'Cancel Plan'
                        });
                        open();
                      }}
                    >
                      Cancel plan
                    </CancelPlan>
                  )}
                  <div
                    css={[
                      spacing.mt2,
                      font.body.s.regular,
                      `color: ${color.crow}`
                    ]}
                  >
                    Note: Do not cancel your plan if you want to change your
                    plan type or your payment method.
                  </div>
                </div>
                {!canEditPlan && (
                  <Button variant='secondary' onClick={showNewMessage}>
                    Manage plan
                  </Button>
                )}
              </div>
            ) : null
          ) : (
            <SubscriptionExpirationExplanation />
          )}
        </div>

        {isTrial ? null : workspaceSubscription?.cardLastFour ? (
          <>
            {activateError && (
              <ErrorBox
                variant='minimal'
                css={spacing.mb2}
                error={activateError}
              />
            )}
            <div css={['display: flex; align-items: center;']}>
              {!workspaceSubscription?.planDetails?.isFree &&
                !workspaceSubscription?.autoRenew && (
                  <div>
                    <Button onClick={handleReactivate}>Reactivate plan</Button>
                  </div>
                )}
            </div>
          </>
        ) : (
          <>
            {showContinuePlanMessage ? (
              <div css={[spacing.my2]}>
                To continue your plan, enter payment information below.
              </div>
            ) : null}
            <Button
              css={[spacing.my5]}
              onClick={openBillingModal}
              variant='secondary'
            >
              Enter payment information
            </Button>
          </>
        )}
      </MoreBillingInfo>
    </Section>
  );
}
