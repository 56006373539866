import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { useExtra } from '@grain/components/support/extra';
import { useAnnouncements } from './announcements';
import { announcementShowingState } from './state';
import { useLocation } from 'react-router-dom';
import { useSilentMode } from '@grain/grain-ui/v4';

export function useCheckAnnouncements() {
  const { extra, saveExtra } = useExtra();
  const location = useLocation();
  const announcementsToShowRef = useRef<string[] | null>(null);
  const allAnnouncements = useAnnouncements();
  const shouldSkipAnnouncement = location.search.includes('skipAnnouncement');

  const [currentAnnouncementKey, setCurrentAnnouncementKey] = useRecoilState(
    announcementShowingState
  );

  useEffect(() => {
    if (
      !extra?.featureAnnouncementsShown ||
      allAnnouncements.length === 0 ||
      shouldSkipAnnouncement
    ) {
      return;
    }
    const shownAnnouncements = new Set(extra.featureAnnouncementsShown);
    const newAnnouncements = allAnnouncements
      .map(a => a.key)
      .reverse()
      .filter(val => !shownAnnouncements.has(val));

    announcementsToShowRef.current = newAnnouncements;
    setCurrentAnnouncementKey(newAnnouncements[0] || '');
  }, [
    extra,
    setCurrentAnnouncementKey,
    allAnnouncements,
    location.key,
    shouldSkipAnnouncement
  ]);

  const announcementEntries = useMemo(
    () => {
      if (!currentAnnouncementKey) return [];
      const announcement = allAnnouncements.find(
        a => a.key === currentAnnouncementKey
      );
      if (!announcement) return [];
      return announcement.announcementEntries;
    },
    // Disabling this rule as I need location.key added so we recalc the `hidden` field on page changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAnnouncementKey, allAnnouncements, location.key]
  );

  const isSilent = useSilentMode();

  const showAnnouncementModal = !isSilent && !!announcementEntries?.length;

  const setAnnouncementShown = useCallback(() => {
    if (
      currentAnnouncementKey &&
      showAnnouncementModal &&
      !extra?.featureAnnouncementsShown?.includes(currentAnnouncementKey)
    ) {
      saveExtra({
        ...extra,
        featureAnnouncementsShown: [
          ...new Set([
            ...(extra?.featureAnnouncementsShown || []),
            currentAnnouncementKey
          ])
        ]
      });
    }
  }, [currentAnnouncementKey, extra, saveExtra, showAnnouncementModal]);

  const setAnnouncementClosed = useCallback(() => {
    const announcementsToShow = announcementsToShowRef.current;
    if (!announcementsToShow) return;

    announcementsToShow.shift();
    setCurrentAnnouncementKey(announcementsToShow[0] || '');
  }, [setCurrentAnnouncementKey, announcementsToShowRef]);

  return {
    announcements: announcementEntries,
    showAnnouncementModal,
    setAnnouncementShown,
    setAnnouncementClosed
  };
}
