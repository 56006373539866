import { useCallback, useEffect } from 'react';
import { useMyself, useWorkspace } from '@grain/api/auth';
import { useAnalytics } from '@grain/grain-ui';
import { useExtra } from '@grain/components/support/extra';
import { getIsOnPaidPlan } from '~/support/getIsOnPaidPlan';

const getCalendly = (onload: () => void) => {
  const calendlyScript = document.getElementById('calendly-script');
  if (!calendlyScript) {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.id = 'calendly-script';
    document.body.appendChild(script);
    script.onload = onload;
  }
};

const isCalendlyScheduledEvent = (e: MessageEvent) => {
  return e.data.event && e.data.event === 'calendly.event_scheduled';
};

declare global {
  interface Window {
    Calendly: {
      initInlineWidget: (options: unknown) => void;
    };
  }
}

export function getCalendlyUrl(isOnPaidPlan: boolean, email: string) {
  return `https://calendly.com/${isOnPaidPlan ? 'zachary-liem' : 'grainhq'}/demo?utm_source=product%20(onboarding)&prefill[email]=${email}`;
}

export function useCalendly({ isFullFrame = false } = {}) {
  const { myself } = useMyself();
  const { workspace, loading: isWorkspaceLoading } = useWorkspace();
  const isOnPaidPlan = getIsOnPaidPlan(workspace);
  const email = myself?.user?.email || '';
  const { trackEvent } = useAnalytics();
  const { extra, saveExtra } = useExtra();

  const base_url = getCalendlyUrl(isOnPaidPlan, email);

  const showCalendlyBlock = useCallback(() => {
    window.Calendly.initInlineWidget({
      url: isFullFrame
        ? base_url
        : base_url +
          '?hide_event_type_details=1&hide_gdpr_banner=1&text_color=000000&primary_color=00b96c',
      prefill: {
        email: email
      },
      parentElement: document.getElementById('calendly-block')
    });
  }, [isFullFrame, email, base_url]);

  const setupCalendly = useCallback(() => {
    if (isWorkspaceLoading || !workspace) {
      return;
    }
    getCalendly(showCalendlyBlock);
  }, [showCalendlyBlock, isWorkspaceLoading, workspace]);

  useEffect(() => {
    const handleEvent = (e: MessageEvent) => {
      if (isCalendlyScheduledEvent(e)) {
        trackEvent(
          'Onboarding Booked',
          {
            source: isFullFrame ? 'Help' : 'Onboarding',
            category: 'onboarding'
          },
          ['user', 'workspace']
        );
        saveExtra({ ...extra, hasScheduledSalesMeeting: true });
      }
    };
    window.addEventListener('message', handleEvent);
    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, [isFullFrame, trackEvent, extra, saveExtra]);

  return { setupCalendly };
}
