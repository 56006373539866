import styled from '@emotion/styled';
import { color, shadows } from '@grain/styles/constants';

export const StyledDocumentWrapper = styled.div`
  box-shadow: ${shadows.base};
  display: flex;
  flex-direction: column;
  flex: 0 1 664px;
  overflow-x: hidden;

  // This fixes an issue with cutting off the title background with the
  // border radius in a scroll container in Safari
  // https://stackoverflow.com/a/64885552/9275524
  position: relative;
  border-radius: 12px;
`;

export const StyledButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 36px;
`;

export const StyledEmptySpaceFill = styled.div`
  ${color.crow};
  align-items: center;
  display: flex;
  flex: 1;
  font-size: calc(14rem / 16);
  justify-content: center;
  margin: auto;
`;
