import {
  IntelligenceChatMessageRole,
  IntelligenceChatMessageStatus
} from '@g/schema';
import {
  RecordingChatHistoryDocument,
  NewChatRecordingFragment,
  NewChatRecordingFragmentDoc,
  RecordingIntelligenceChatMessageFragment,
  useNewChatMessageSubscription,
  useRecordingChatHistoryQuery,
  useSendChatMessageMutation,
  useStreamRecordingChatMessageSubscription,
  RecordingIntelligenceChatMessageFragmentDoc,
  useSetRecordingChatMessageFeedbackMutation as useGeneratedSetRecordingChatMessageFeedbackMutation,
  useRemoveRecordingChatMessageFeedbackMutation as useGeneratedRemoveRecordingChatMessageFeedbackMutation,
  SetRecordingChatMessageFeedbackMutationVariables
} from './ask-chat.generated';
import { v4 as uuidv4 } from 'uuid';
import { useRecordingPage } from '../../context';
import { useApolloClient } from '@apollo/client';
import { useCallback, useMemo, useRef } from 'react';
import { ChatMessage, ChatMessageWithAugmentedCitations } from './types';
import { useTranscriptState } from '../../transcriptContext';

// Reexports the generated mutation with an optimistic response
export const useSetRecordingChatMessageFeedbackMutation = (
  options?: Parameters<
    typeof useGeneratedSetRecordingChatMessageFeedbackMutation
  >[0]
) => {
  const client = useApolloClient();

  return useGeneratedSetRecordingChatMessageFeedbackMutation({
    ...options,
    optimisticResponse: (variables, { IGNORE }) => {
      try {
        const chatMessage =
          client.cache.readFragment<RecordingIntelligenceChatMessageFragment>({
            id: client.cache.identify({
              __typename: 'IntelligenceChatMessage',
              id: variables.chatMessageId
            }),
            fragment: RecordingIntelligenceChatMessageFragmentDoc,
            fragmentName: 'RecordingIntelligenceChatMessage'
          });

        if (!chatMessage) {
          return IGNORE;
        }

        return {
          intelligenceChatMessageFeedbackSet: {
            ...chatMessage,
            feedback: {
              __typename: 'IntelligenceChatMessageFeedback',
              id: uuidv4(),
              feedback: variables.feedback || null,
              type: variables.type
            }
          }
        };
      } catch (err) {
        return IGNORE;
      }
    }
  });
};

export const useRemoveRecordingChatMessageFeedbackMutation = (
  options?: Parameters<
    typeof useGeneratedRemoveRecordingChatMessageFeedbackMutation
  >[0]
) => {
  const client = useApolloClient();

  return useGeneratedRemoveRecordingChatMessageFeedbackMutation({
    ...options,
    optimisticResponse: (variables, { IGNORE }) => {
      try {
        const chatMessage =
          client.cache.readFragment<RecordingIntelligenceChatMessageFragment>({
            id: client.cache.identify({
              __typename: 'IntelligenceChatMessage',
              id: variables.chatMessageId
            }),
            fragment: RecordingIntelligenceChatMessageFragmentDoc,
            fragmentName: 'RecordingIntelligenceChatMessage'
          });

        if (!chatMessage) {
          return IGNORE;
        }

        return {
          intelligenceChatMessageFeedbackUnset: {
            ...chatMessage,
            feedback: null
          }
        };
      } catch (err) {
        return IGNORE;
      }
    }
  });
};

export const useToggleRecordingChatMessageFeedbackMutation = (
  chatMessage: ChatMessage
) => {
  const [setFeedback] = useSetRecordingChatMessageFeedbackMutation();
  const [removeFeedback] = useRemoveRecordingChatMessageFeedbackMutation();
  const feedback = chatMessage.feedback;

  const toggleFeedback = useCallback(
    // We only need to pass in SetVariables since Remove only requires chatMessageId
    (variables: SetRecordingChatMessageFeedbackMutationVariables) => {
      if (feedback?.type === variables.type) {
        return removeFeedback({
          variables: {
            chatMessageId: variables.chatMessageId
          }
        });
      } else {
        return setFeedback({
          variables: {
            chatMessageId: variables.chatMessageId,
            feedback: variables.feedback,
            type: variables.type
          }
        });
      }
    },
    [setFeedback, removeFeedback, feedback]
  );

  return [toggleFeedback] as const;
};

export const useAskChatQueries = () => {
  const { transcript, dedupedSpeakerRanges, transcriptSpeakerMap } =
    useTranscriptState();

  const generatedCacheIdRefs = useRef<string[]>([]);
  const client = useApolloClient();
  const cache = client.cache;
  const { recordingId } = useRecordingPage();
  const recordingChatHistoryQuery = useRecordingChatHistoryQuery({
    variables: {
      id: recordingId
    }
  });

  const augmentedChatMessageCitations = useCallback(
    (messages: ChatMessage[]): ChatMessageWithAugmentedCitations[] => {
      return messages.map(message => {
        if (
          !message.citations ||
          message.citations.length === 0 ||
          !transcript ||
          transcript.length === 0
        ) {
          return {
            ...message,
            citations: []
          } as ChatMessageWithAugmentedCitations;
        }

        const augmentedCitations = message.citations.map(citation => {
          const { startMs, endMs } = citation;

          const citedTranscriptParts = transcript.filter(
            part => part.start >= startMs && part.end <= endMs
          );

          const citedText = citedTranscriptParts
            .map(part => part.value)
            .join(' ');

          // Early return with default values if no speaker ranges
          if (!dedupedSpeakerRanges || dedupedSpeakerRanges.length === 0) {
            return {
              ...citation,
              citedText: citedText || '',
              speakers: ['Unknown speaker']
            };
          }

          const speakersInRange = dedupedSpeakerRanges
            .filter(range => {
              if (!range) {
                return false;
              }

              return (
                (range.startMs <= startMs && range.endMs >= startMs) || // Speaker range contains startMs
                (range.startMs <= endMs && range.endMs >= endMs) || // Speaker range contains endMs
                (range.startMs >= startMs && range.endMs <= endMs) // Speaker range is fully within citation range
              );
            })
            .map(range => {
              if (!range.speakerId || !transcriptSpeakerMap) {
                return 'Unknown speaker';
              }
              const speaker = transcriptSpeakerMap.get(range.speakerId);
              return speaker ? speaker.name : 'Unknown speaker';
            });

          const speakerNames = Array.from(new Set(speakersInRange));

          return {
            ...citation,
            citedText: citedText || '',
            speakers: speakerNames.length ? speakerNames : ['Unknown speaker']
          };
        });

        return {
          ...message,
          citations: augmentedCitations
        };
      });
    },
    [transcript, dedupedSpeakerRanges, transcriptSpeakerMap]
  );

  const chatMessages = useMemo(() => {
    const messages =
      recordingChatHistoryQuery?.data?.recording?.intelligence?.chatHistory
        ?.messages || [];

    return augmentedChatMessageCitations(messages);
  }, [
    recordingChatHistoryQuery?.data?.recording?.intelligence?.chatHistory
      ?.messages,
    augmentedChatMessageCitations
  ]);

  const historyTruncatedId =
    recordingChatHistoryQuery?.data?.recording?.intelligence?.chatHistory
      ?.historyTruncatedAtMessageId;

  // Used for GraphQL subscription to most recent assistant message
  const subscribeMessageId = useMemo(() => {
    const lastMessage = chatMessages?.[chatMessages.length - 1];
    const lastMessageId = lastMessage?.id || null;
    const isAssistantMessage =
      lastMessage?.role === IntelligenceChatMessageRole.Assistant;
    const isProcessing =
      lastMessage?.status === IntelligenceChatMessageStatus.Processing;

    return isAssistantMessage && isProcessing ? lastMessageId : null;
  }, [chatMessages]);

  const [sendChatMessage] = useSendChatMessageMutation({
    optimisticResponse: variables => {
      let chatHistoryId =
        recordingChatHistoryQuery?.data?.recording?.intelligence?.chatHistory
          ?.id;

      if (!chatHistoryId) {
        chatHistoryId = uuidv4();
        generatedCacheIdRefs.current.push(chatHistoryId);
      }

      const chatHistory =
        recordingChatHistoryQuery?.data?.recording?.intelligence?.chatHistory;

      const res = {
        intelligenceChatMessageSend: {
          __typename: 'IntelligenceChatHistory' as const,
          historyTruncatedAtMessageId:
            chatHistory?.historyTruncatedAtMessageId || null,
          id: chatHistoryId,
          messages: [
            ...chatMessages,
            {
              __typename: 'IntelligenceChatMessage',
              role: IntelligenceChatMessageRole.User,
              status: IntelligenceChatMessageStatus.Processing,
              content: variables.message,
              id: uuidv4()
            }
          ] as RecordingIntelligenceChatMessageFragment[]
        }
      };

      return res;
    },
    update: (_cache, { data }) => {
      // Check if chat history id has changed
      const cacheRecording = cache.readFragment<NewChatRecordingFragment>({
        id: cache.identify({ __typename: 'Recording', id: recordingId }),
        fragment: NewChatRecordingFragmentDoc,
        fragmentName: 'NewChatRecording'
      });
      const prevChatHistoryId = cacheRecording?.intelligence?.chatHistory?.id;

      cache.writeQuery({
        query: RecordingChatHistoryDocument,
        id: cache.identify({ __typename: 'Recording', id: recordingId }),
        data: {
          recording: {
            __typename: 'Recording',
            id: recordingId,
            intelligence: {
              __typename: 'RecordingIntelligence',
              id: recordingId,
              chatHistory: data?.intelligenceChatMessageSend || null
            }
          }
        },
        broadcast: true
      });

      if (
        prevChatHistoryId &&
        generatedCacheIdRefs.current.includes(prevChatHistoryId)
      ) {
        cache.evict({
          id: cache.identify({
            __typename: 'IntelligenceChatHistory',
            id: prevChatHistoryId
          })
        });
      }
      cache.gc();
    }
  });

  useStreamRecordingChatMessageSubscription({
    variables: {
      chatMessageId: subscribeMessageId!
    },
    skip: Boolean(!subscribeMessageId),
    onData: ({ data }) => {
      if (subscribeMessageId) {
        const chatMessageIdentity = cache.identify({
          __typename: 'IntelligenceChatMessage',
          id: subscribeMessageId
        });
        const currentChatMessage =
          cache.readFragment<RecordingIntelligenceChatMessageFragment>({
            id: chatMessageIdentity,
            fragment: RecordingIntelligenceChatMessageFragmentDoc,
            fragmentName: 'RecordingIntelligenceChatMessage'
          });

        const newContent =
          data?.data?.intelligenceChatStreamUpdated?.messageContent ||
          currentChatMessage?.content;

        cache.writeFragment({
          id: chatMessageIdentity,
          fragment: RecordingIntelligenceChatMessageFragmentDoc,
          fragmentName: 'RecordingIntelligenceChatMessage',
          data: {
            ...currentChatMessage,
            content: newContent
          }
        });
      }
    }
  });

  useNewChatMessageSubscription({
    variables: {
      recordingId
    }
  });

  return {
    ...recordingChatHistoryQuery,
    chatMessages,
    historyTruncatedId,
    sendChatMessage
  } as const;
};
