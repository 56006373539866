import { ComponentType, useCallback } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  Modal,
  useConfirm,
  useOpenModal,
  useRouteModal
} from '@grain/grain-ui';
import {
  START_TRIAL_ROUTE_ID,
  UPGRADE_PLAN_ROUTE_ID
} from '@grain/components/modals/constants';

import { SalesPitch } from './SlideshowModals/SalesPitch';
import { ConnectCRM } from './SlideshowModals/ConnectCRM';
import { ConnectedCRM } from './SlideshowModals/ConnectedCRM';
import { InviteTeam } from './SlideshowModals/InviteTeam';
import { CRMTour } from './SlideshowModals/CRMTour';

import type { StepComponentProps } from './types';

const STEPS: React.ComponentType<StepComponentProps>[] = [
  SalesPitch,
  ConnectCRM,
  ConnectedCRM,
  InviteTeam,
  CRMTour
];

const CONFIRM_TO_CANCEL_STEPS: ComponentType<StepComponentProps>[] = [
  ConnectCRM
];

export default function StartTrialModal() {
  const location = useLocation();
  const showConfirm = useConfirm();

  const openModal = useOpenModal({
    // Unset param to prevent it from accumulating
    source: null,
    // Unset param because it becomes irrelevant to the new modal
    step: null
  });
  const { close } = useRouteModal(START_TRIAL_ROUTE_ID, {
    // Unset param on close
    step: null
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const source = searchParams.get('source');
  const sourceName = (() => {
    if (!source) return;
    return {
      [UPGRADE_PLAN_ROUTE_ID]: 'Plans'
    }[source];
  })();

  // NOTE: steps start counting at 1, not 0
  const step = Number(searchParams.get('step')) || 1;

  const StepComponent = STEPS[step - 1];

  const navigateToStep = useCallback(
    (step: number) =>
      setSearchParams({
        ...Object.fromEntries(searchParams),
        step: String(step)
      }),
    [searchParams, setSearchParams]
  );

  // Get current URL, with this modal open, but with step+1
  const getNextHref = useCallback(() => {
    const nextUrl = new URL(
      `${location.pathname}${location.search}`,
      window.location.origin
    );
    nextUrl.searchParams.set('step', String(step + 1));
    return `${nextUrl.pathname}${nextUrl.search}`;
  }, [location.pathname, location.search, step]);

  const handleDone = useCallback(() => {
    close();
  }, [close]);

  const handleCancel = useCallback(
    (force?: boolean) => {
      if (!force && CONFIRM_TO_CANCEL_STEPS.includes(StepComponent)) {
        showConfirm({
          width: 350,
          title: 'Quit trial setup?',
          description:
            'You can always start the trial later, but your progress won’t be saved.',
          confirmContent: 'Quit Setup',
          confirmButtonType: 'danger',
          onConfirm() {
            close();
          }
        });
      } else {
        close();
      }
    },
    [close, showConfirm, StepComponent]
  );

  const handleNext = useCallback(
    (steps: number) => {
      navigateToStep(step + steps);
    },
    [navigateToStep, step]
  );

  const backToSource = useCallback(() => {
    if (!source) return;
    openModal(source);
  }, [openModal, source]);

  const handleBack = step === 1 && source ? backToSource : undefined;

  return (
    <Modal
      onCancel={() => handleCancel()}
      width={700}
      roundness='md'
      centered
      closable
    >
      <StepComponent
        totalSteps={STEPS.length}
        step={step}
        source={sourceName}
        handleBack={handleBack}
        handleNext={handleNext}
        handleDone={handleDone}
        handleCancel={handleCancel}
        getNextHref={getNextHref}
      />
    </Modal>
  );
}
