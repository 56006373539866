import { z } from 'zod';
import { CustomScorecardWithQuestionType } from './types';

const QuestionSchema = z.object({
  id: z.string(),
  text: z.string().min(1, 'Question text is required'),
  question_type: z.enum(['numeric', 'yes_no'] as const)
});

const ScorecardSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  description: z.string().min(1, 'Description is required'),
  questions: z.object({
    schemaVersion: z.number().optional(),
    questions: z
      .array(QuestionSchema)
      .nonempty('At least one question is required')
  })
});

export const getValidationErrors = (
  scorecard: CustomScorecardWithQuestionType
) => {
  const { success, error } = ScorecardSchema.safeParse(scorecard);

  if (success) {
    return null;
  }
  return error.errors.map(({ message }) => message);
};
