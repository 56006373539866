import { Link } from 'react-router-dom';
import { ButtonMetadata } from './ButtonMetadata';
import {
  TextLabel,
  Menu,
  MenuHeader,
  Icon16,
  theme,
  Divider,
  MenuButton
} from '@grain/grain-ui/v4';

const EMPTY_ARRAY: never[] = [];

type Tracker = {
  tracker: string;
  count: number;
};

export type TrackerMetaItemProps = {
  trackers?: Tracker[];
  viewInstancesUrl?: string;
};

export const TrackerMetaItem = ({
  trackers = EMPTY_ARRAY,
  viewInstancesUrl = ''
}: TrackerMetaItemProps) => {
  const [firstTracker, ...restTrackers] = trackers;

  const metadata = !firstTracker
    ? 'none'
    : restTrackers.length
      ? `+${restTrackers.length}`
      : '';

  const TrackerButton = () => (
    <ButtonMetadata width='lg' disableInteractive={!firstTracker}>
      <TextLabel
        startIcon={Icon16.Trackers}
        metadata={metadata}
        size='sm'
        css={{ color: theme.tokens.color.textSecondary }}
      >
        {firstTracker?.tracker ?? ''}
      </TextLabel>
    </ButtonMetadata>
  );

  if (!firstTracker) {
    return <TrackerButton />;
  }

  return (
    <Menu
      hideOnClickInside
      stopClickPropagation
      width='232px'
      childWidth='auto'
      content={
        <>
          <MenuHeader>Trackers</MenuHeader>
          <Divider />
          {trackers.map(tracker => (
            <MenuHeader
              variant='subheader'
              key={tracker.tracker}
              metadata={tracker.count}
              startIcon={Icon16.Trackers}
            >
              {tracker.tracker}
            </MenuHeader>
          ))}
          <Divider />
          <MenuButton label='View Instances' as={Link} to={viewInstancesUrl} />
        </>
      }
    >
      <TrackerButton />
    </Menu>
  );
};
