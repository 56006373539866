import * as Layout from '~/components/Layout';
import { Helmet } from 'react-helmet';
import {
  Button,
  Divider,
  EmptyState,
  Icon20,
  PageHeader,
  Tab,
  Tabs,
  TabsBar
} from '@grain/grain-ui/v4';
import { Icon, useRouteModal } from '@grain/grain-ui';
import { FilterBar } from '~/modules/filtersV2/FilterBar/FilterBar';
import { useFilteredViewAdHoc } from '~/modules/filters';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AdHocClipV2Fragment,
  AdHocStoryFragment,
  useViewAdHocQuery
} from '~/modules/contentFilter/contentfilter.generated';
import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAddStory } from '../Library/hooks';
import { StoryListItem } from './components/StoryListItem';
import { ClipListItem } from './components/ClipListItem';
import { EmptyStoriesState } from './components/EmptyStoriesState';
import { StoriesShareModal } from './StoriesShareModal';
import { SHARE_STORY_ID } from '@grain/components/modals/constants';
import { FilterName } from '~/modules/filtersV2/types';
import { isDesktopApp } from '@grain/desktop-lib';

type StoriesTab = {
  id: 'stories' | 'clips';
  label: string;
  pathname: string;
  filterEntityType: 'highlights' | 'stories';
  availableFilters: FilterName[];
};

const tabs: StoriesTab[] = [
  {
    id: 'stories',
    label: 'Stories',
    pathname: '/app/stories',
    filterEntityType: 'stories',
    availableFilters: ['title', 'date', 'owners', 'tags']
  },
  {
    id: 'clips',
    label: 'Clips',
    pathname: '/app/clips',
    filterEntityType: 'highlights',
    availableFilters: ['title', 'date', 'tags', 'persons']
  }
];

const StoriesPage = () => {
  const location = useLocation();
  const prevPathname = useRef(location.pathname);
  const { toggleCollapsed } = Layout.useSidebar();
  const [sharestoryId, setShareStoryId] = useState<null | string>(null);
  const navigate = useNavigate();
  const activeIndex = tabs.findIndex(tab => tab.pathname === location.pathname);
  const setActiveIndex = (val: number) => navigate(tabs[val].pathname);
  const addStory = useAddStory('stories_page');
  const { isOpen: isShareStoryOpen, open: openStoryShare } =
    useRouteModal(SHARE_STORY_ID);
  const activeTab = tabs[activeIndex];

  const { filters, activeFilters, resetFilters, viewAdHocFilterString } =
    useFilteredViewAdHoc({
      filterEntityType: activeTab.filterEntityType,
      availableFilters: activeTab.availableFilters
    });

  useLayoutEffect(() => {
    if (prevPathname.current !== location.pathname) {
      resetFilters();
      prevPathname.current = location.pathname;
    }
  }, [resetFilters, prevPathname, location.pathname]);

  const { data, loading, error, fetchMore } = useViewAdHocQuery({
    variables: {
      filter: viewAdHocFilterString
    }
  });
  const cursor = data?.viewAdHoc?.cursor || null;

  const fetchMoreWithCursor = useCallback(() => {
    if (cursor) {
      return fetchMore({
        variables: {
          cursor
        }
      });
    }
  }, [cursor, fetchMore]);

  const [stories, clips] = useMemo(() => {
    const _stories: AdHocStoryFragment[] = [];
    const _clips: AdHocClipV2Fragment[] = [];

    const items = data?.viewAdHoc.list || [];
    for (const item of items) {
      if (item.__typename === 'ClipV2') {
        _clips.push(item as AdHocClipV2Fragment);
      }

      if (item.__typename === 'Story') {
        _stories.push(item as AdHocStoryFragment);
      }
    }

    return [_stories, _clips] as const;
  }, [data]);

  const [isResettingTitleFilter, setIsResettingTitleFilter] = useState(false);
  const resetFiltersWithLoading = () => {
    setIsResettingTitleFilter(true);
    resetFilters();
    filters.title.setValue('');
    setTimeout(() => setIsResettingTitleFilter(false), 100);
  };

  const hasTitleFilter = filters.title.value !== '';
  const hasFilters = Object.keys(activeFilters).length >= 1 || hasTitleFilter;
  const isLoading = loading || isResettingTitleFilter;
  const showEmptyState =
    !error &&
    !isLoading &&
    !hasFilters &&
    (data?.viewAdHoc.list || []).length === 0;
  const showNoResults =
    !error &&
    !isLoading &&
    hasFilters &&
    (data?.viewAdHoc.list || []).length === 0;
  const showFullPageError =
    error && !isLoading && (data?.viewAdHoc.list || []).length === 0;

  return (
    <>
      <Helmet title='Stories' />
      <Layout.Wrapper withSidebar>
        <PageHeader
          title='Stories'
          icon={Icon20.Story}
          onMenuClick={toggleCollapsed}
          rightSection={
            <Button variant='neutral' icon={Icon.Plus} onClick={addStory}>
              New story
            </Button>
          }
          isDesktopApp={isDesktopApp}
        />

        <Divider />
        <TabsBar>
          <Tabs currentValue={activeIndex} onChange={setActiveIndex}>
            {tabs.map((tab, index) => (
              <Tab value={index} key={tab.label}>
                {tab.label}
              </Tab>
            ))}
          </Tabs>
        </TabsBar>
        <Divider />
        <FilterBar filters={filters} resetAllFilters={resetFilters} />
        <Divider />

        <div
          id='infinite-scrollable-div'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <InfiniteScroll
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
            dataLength={stories.length}
            hasMore={Boolean(data && cursor)}
            next={fetchMoreWithCursor}
            loader={
              activeTab.id === 'stories' ? (
                <StoryListItem.Skeleton />
              ) : (
                <ClipListItem.Skeleton />
              )
            }
            scrollableTarget='infinite-scrollable-div'
          >
            {showFullPageError && (
              <EmptyState.Error
                title={`Error loading ${tabs[activeIndex].label}`}
                onReload={fetchMoreWithCursor}
              />
            )}
            {showNoResults && (
              <EmptyState.NoResults
                title='No content matches your filters'
                onClearFilters={resetFiltersWithLoading}
              />
            )}
            {showEmptyState && (
              <EmptyStoriesState
                tabId={activeTab.id}
                onClickNewStory={addStory}
              />
            )}
            {!data &&
              !hasFilters &&
              loading &&
              Array.from({ length: 20 }, (_, i) => {
                const Loader =
                  activeTab.id === 'clips'
                    ? ClipListItem.Skeleton
                    : StoryListItem.Skeleton;

                return (
                  <Fragment key={activeTab.id + i}>
                    {i > 0 && <Divider />}
                    <Loader />
                  </Fragment>
                );
              })}
            {stories.map(story => (
              <Fragment key={story.id}>
                <StoryListItem
                  story={story}
                  onClickShare={() => {
                    setShareStoryId(story.id);
                    openStoryShare();
                  }}
                />
                <Divider />
              </Fragment>
            ))}
            {clips.map(clip => (
              <Fragment key={clip.id}>
                <ClipListItem clip={clip} />
                <Divider />
              </Fragment>
            ))}
          </InfiniteScroll>
        </div>
        {sharestoryId && (
          <StoriesShareModal
            isShareOpen={isShareStoryOpen}
            storyId={sharestoryId}
          />
        )}
      </Layout.Wrapper>
    </>
  );
};

export default StoriesPage;
