import pluralize from 'pluralize';
import Markdown from 'react-markdown';
import { DealDetailsV4Fragment } from '../../pages/Deals/deal.generated';
import { DealDiagnosisDimension } from '~/components/DealDiagnosis/DealDiagnosisDimension';
import { DealDiagnosisSubDimension } from '~/components/DealDiagnosis/DealDiagnosisSubDimension';
import {
  dimensionSorter,
  getDimensionAndSubDimensionLabel,
  makeCompanyNameBoldInMarkdown,
  subDimensionSorter
} from '~/components/DealDiagnosis/utils';
import {
  Accordion,
  Icon12,
  Icon16,
  Menu,
  MenuButton,
  theme
} from '@grain/grain-ui/v4';
import styled from '@emotion/styled';
import { DealDiagnosisCompletenessPill } from '~/components/DealDiagnosis/DealDiagnosisCompletenessPill';
import { Fragment, useCallback, useMemo } from 'react';
import { copyElementAsHtml, useAnalytics, useShowToast } from '@grain/grain-ui';
import { formatDate, formatDuration } from '../../pages/Deals/utils';
import { sortReadOnlyArray } from '~/support/sortReadOnlyArray';
import { Link } from 'react-router-dom';
import { DealDiagnosticMethodologyDimension } from '@grain/api/schema.generated';

type DealDiagnosisResultsProps = {
  diagnosis: DealDetailsV4Fragment['diagnosis'];
  companyName?: string;
  processing?: boolean;
  disabled?: boolean;
};

const StyledAccordion = styled(Accordion)`
  flex: 0 0 auto;
`;

const HeaderGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.tokens.spacing.sm};
`;

const Title = styled.span`
  ${theme.tokens.typography.b2[500]};
`;

const UpdatedMessage = styled.div`
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textSecondary};
`;

const CopyIcon = styled(Icon16.Copy)`
  color: ${theme.tokens.color.iconSecondary};
  display: flex;
  align-items: center;
`;

const DropdownIcon = () => {
  return (
    <div css={{ display: 'flex', alignItems: 'center' }}>
      <Icon12.ChevronDown color={theme.tokens.color.iconSecondary} />
    </div>
  );
};

const ProcessingMessage = styled.span`
  min-height: 32px;
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textTertiary};
  display: flex;
  align-items: center;
`;

export const DealDiagnosisResults = ({
  diagnosis,
  companyName,
  disabled = false,
  processing = false
}: DealDiagnosisResultsProps) => {
  const showToast = useShowToast();
  const { trackEvent } = useAnalytics();

  const totalCoveredRecordings = diagnosis?.coveredRecordings.length ?? 0;

  const sortedCoveredRecordings = useMemo(() => {
    return sortReadOnlyArray(diagnosis?.coveredRecordings ?? [], (a, b) => {
      return (
        new Date(a?.startDatetime ?? '').getTime() -
        new Date(b?.startDatetime ?? '').getTime()
      );
    }).filter(Boolean);
  }, [diagnosis]);

  const onCopySummarizedPoints = useCallback(() => {
    if (!diagnosis) return;

    copyElementAsHtml(
      <>
        <h3>{diagnosis.methodology}</h3>
        {sortReadOnlyArray(diagnosis.dimensionResults, dimensionSorter).map(
          dimension => (
            <Fragment key={dimension.dimension}>
              <hr />
              <div key={dimension.dimension}>
                <h4>{getDimensionAndSubDimensionLabel(dimension.dimension)}</h4>
                <Markdown>
                  {makeCompanyNameBoldInMarkdown(
                    dimension.summary,
                    companyName
                  )}
                </Markdown>
              </div>
            </Fragment>
          )
        )}
      </>
    );
    trackEvent('Deal Diagnosis Copied', {
      type: 'summarized_points'
    });
    showToast({
      content: 'Summarized Points copied',
      type: 'success'
    });
  }, [showToast, diagnosis, trackEvent, companyName]);

  const onCopyDetailedBreakdown = useCallback(() => {
    if (!diagnosis) return;

    copyElementAsHtml(
      <>
        <h3>{diagnosis.methodology}</h3>
        {sortReadOnlyArray(diagnosis.dimensionResults, dimensionSorter).map(
          dimension => (
            <Fragment key={dimension.dimension}>
              <hr />
              <div key={dimension.dimension}>
                <h4>{getDimensionAndSubDimensionLabel(dimension.dimension)}</h4>
                <Markdown>
                  {makeCompanyNameBoldInMarkdown(
                    dimension.summary,
                    companyName
                  )}
                </Markdown>
                {sortReadOnlyArray(
                  dimension.subdimensionResults,
                  subDimensionSorter
                ).map(subdimension => (
                  <div key={subdimension.subdimension}>
                    <i>
                      {getDimensionAndSubDimensionLabel(
                        subdimension.subdimension
                      )}
                    </i>
                    <p>{subdimension.summary}</p>
                  </div>
                ))}
              </div>
            </Fragment>
          )
        )}
      </>
    );
    trackEvent('Deal Diagnosis Copied', {
      type: 'detailed_breakdown'
    });
    showToast({
      content: 'Detailed Breakdown copied',
      type: 'success'
    });
  }, [showToast, diagnosis, trackEvent, companyName]);

  const getDimensionEmptyMessage = (
    dimension: DealDiagnosticMethodologyDimension
  ) => `No ${getDimensionAndSubDimensionLabel(dimension)} identified`;

  if (!diagnosis) {
    return null;
  }

  return (
    <StyledAccordion
      allowMultipleExpanded={false}
      title={
        <HeaderGroup>
          <Title>{diagnosis.methodology}</Title>
          {!processing && diagnosis.lastSync && (
            <UpdatedMessage>
              Updated{' '}
              {formatDuration(
                new Date(diagnosis.lastSync),
                new Date()
              ).toLowerCase()}{' '}
              ago
            </UpdatedMessage>
          )}
        </HeaderGroup>
      }
      secondaryComponents={
        processing ? (
          <ProcessingMessage>
            Processing may take a few minutes
          </ProcessingMessage>
        ) : (
          <>
            {!processing && totalCoveredRecordings > 0 && (
              <Menu
                width='300px'
                content={sortedCoveredRecordings.map(recording => (
                  <MenuButton
                    key={recording?.id}
                    as={Link}
                    to={recording?.recordingPath ?? ''}
                    textLabelProps={{
                      startIcon: Icon16.Record,
                      metadata: formatDate(
                        new Date(recording?.startDatetime ?? '')
                      )
                    }}
                    label={recording?.title}
                  />
                ))}
              >
                <MenuButton
                  textLabelProps={{
                    size: 'sm',
                    endIcon: DropdownIcon
                  }}
                  label={`Based on ${totalCoveredRecordings} ${pluralize(
                    'Meeting',
                    totalCoveredRecordings
                  )}`}
                />
              </Menu>
            )}
            <Menu
              width='210px'
              hideOnClickInside
              content={
                <>
                  <MenuButton
                    textLabelProps={{
                      startIcon: Icon16.List
                    }}
                    label='Copy Summarized Points'
                    onClick={onCopySummarizedPoints}
                  />
                  <MenuButton
                    textLabelProps={{
                      startIcon: Icon16.File
                    }}
                    label='Copy Detailed Breakdown'
                    onClick={onCopyDetailedBreakdown}
                  />
                </>
              }
            >
              <MenuButton
                textLabelProps={{
                  endIcon: DropdownIcon,
                  startIcon: () => <CopyIcon />
                }}
                label={null}
              />
            </Menu>
            <DealDiagnosisCompletenessPill
              status={diagnosis.status}
              disabled={disabled}
            />
          </>
        )
      }
    >
      {sortReadOnlyArray(diagnosis.dimensionResults, dimensionSorter).map(
        dimension => (
          <DealDiagnosisDimension
            key={dimension.dimension}
            disabled={disabled}
            label={getDimensionAndSubDimensionLabel(dimension.dimension)}
            status={dimension.status}
            summary={
              makeCompanyNameBoldInMarkdown(dimension.summary, companyName) ||
              getDimensionEmptyMessage(dimension.dimension)
            }
            processing={processing}
            onChange={expanded => {
              if (expanded) {
                trackEvent('Deal Diagnosis Dimension Expanded', {
                  dimension: dimension.dimension
                });
              }
            }}
          >
            {sortReadOnlyArray(
              dimension.subdimensionResults,
              subDimensionSorter
            ).map(subdimension => (
              <DealDiagnosisSubDimension
                key={subdimension.subdimension}
                disabled={disabled}
                label={getDimensionAndSubDimensionLabel(
                  subdimension.subdimension
                )}
                status={subdimension.status}
                content={makeCompanyNameBoldInMarkdown(
                  subdimension.summary,
                  companyName
                )}
                userFeedback={subdimension.userFeedback}
                lastSync={diagnosis.lastSync}
                sources={subdimension.sources}
              />
            ))}
          </DealDiagnosisDimension>
        )
      )}
    </StyledAccordion>
  );
};
