import styled from '@emotion/styled';
import { color, colors, font, spacing } from '@grain/styles/constants';

export const StyledNoteContainer = styled.div`
  ${spacing.m7};
  ${spacing.mt2};

  ${spacing.mb4};
`;

export const StyledNoteArea = styled.textarea`
  resize: none;
  height: 86px;
  width: 100%;
  resize: none;
  ${spacing.p3};
  ${spacing.mb3};
  border: 1px solid ${colors.pigeon};
  border-radius: 8px;
  ${font.family.body};
  ${font.bodyS};
  outline: none;

  &:focus {
    border: 1px solid ${colors.graieen};
  }

  ::placeholder {
    ${color.pigeon};
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${spacing.p6};
  align-items: center;
  border-top: 1px solid ${colors.gull};
`;
