import { ApolloError } from '@apollo/client';
import Section from './Section';
import {
  InvoiceLink,
  StyledInvoiceNotification,
  StyledTable,
  StyledTableCell,
  StyledTableHeader,
  StyledTableHeaderRow
} from './styles';
import { WorkspaceInvoices } from './useBillingData';
import { capitalize, Icon } from '@grain/grain-ui';
import { displayCents } from '~/support/price';

function formatCurrency(number: number) {
  if (isNaN(number)) {
    throw new Error('Input is not a valid number');
  }

  return '$' + displayCents(number);
}

export default function InvoicesSection({
  invoices,
  isLoading,
  invoiceError
}: {
  invoices: WorkspaceInvoices;
  isLoading: boolean;
  invoiceError?: ApolloError;
}) {
  return (
    <Section title='Invoices' isLoading={isLoading}>
      {invoices.length > 0 && (
        <StyledTable>
          <thead>
            <StyledTableHeaderRow>
              <StyledTableHeader>Date</StyledTableHeader>
              <StyledTableHeader>Amount</StyledTableHeader>
              <StyledTableHeader>Status</StyledTableHeader>
              <StyledTableHeader>Invoice</StyledTableHeader>
            </StyledTableHeaderRow>
          </thead>
          <tbody>
            {invoices.map((invoice, indx) => (
              <StyledTableHeaderRow key={indx}>
                <StyledTableCell>
                  {new Date(invoice.date).toLocaleDateString()}
                </StyledTableCell>
                <StyledTableCell>
                  {formatCurrency(invoice.amount)}
                </StyledTableCell>
                <StyledTableCell>{capitalize(invoice.status)}</StyledTableCell>
                <StyledTableCell>
                  <InvoiceLink
                    href={invoice.invoicePageUrl}
                    target='_blank'
                    data-track='Button Clicked'
                    data-track-args={JSON.stringify({
                      button_name: 'view_invoice',
                      location: 'billing_page',
                      button_text: 'View Invoice'
                    })}
                  >
                    View invoice <Icon.ArrowRight />{' '}
                  </InvoiceLink>
                </StyledTableCell>
              </StyledTableHeaderRow>
            ))}
          </tbody>
        </StyledTable>
      )}
      {(invoices.length === 0 || invoiceError) && (
        <StyledInvoiceNotification>
          {invoiceError
            ? 'An error occured retrieving invoices'
            : 'No invoices found'}
        </StyledInvoiceNotification>
      )}
    </Section>
  );
}
