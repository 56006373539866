import * as Types from '@grain/api/schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@grain/api/graphql';
const defaultOptions = {} as const;
export type CalendarEventQueryQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type CalendarEventQueryQuery = { __typename?: 'RootQueryType', calendarEvent: { __typename?: 'CalendarEvent', end: string, id: string, internal: boolean, meetingProvider: Types.CalendarMeetingProvider, meetingUrl: string, organiser: boolean, record: boolean, recordOverridden: Types.RecordOverridden, recurring: boolean, start: string, title: string | null, tags: Array<{ __typename?: 'Tag', id: string, text: string }> } };

export type CalendarTagAddMutationVariables = Types.Exact<{
  eventId: Types.Scalars['ID']['input'];
  text: Types.Scalars['String']['input'];
}>;


export type CalendarTagAddMutation = { __typename?: 'RootMutationType', calendarTagAdd: { __typename?: 'Tag', id: string, text: string } };

export type CalendarTagRemoveMutationVariables = Types.Exact<{
  eventId: Types.Scalars['ID']['input'];
  text: Types.Scalars['String']['input'];
}>;


export type CalendarTagRemoveMutation = { __typename?: 'RootMutationType', calendarTagRemove: boolean };


export const CalendarEventQueryDocument = gql`
    query calendarEventQuery($id: ID!) {
  calendarEvent(id: $id) {
    end
    id
    internal
    meetingProvider
    meetingUrl
    organiser
    record
    recordOverridden
    recurring
    start
    title
    tags {
      id
      text
    }
  }
}
    `;

/**
 * __useCalendarEventQueryQuery__
 *
 * To run a query within a React component, call `useCalendarEventQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarEventQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarEventQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCalendarEventQueryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CalendarEventQueryQuery, CalendarEventQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CalendarEventQueryQuery, CalendarEventQueryQueryVariables>(CalendarEventQueryDocument, options);
      }
export function useCalendarEventQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarEventQueryQuery, CalendarEventQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CalendarEventQueryQuery, CalendarEventQueryQueryVariables>(CalendarEventQueryDocument, options);
        }
export function useCalendarEventQuerySuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<CalendarEventQueryQuery, CalendarEventQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<CalendarEventQueryQuery, CalendarEventQueryQueryVariables>(CalendarEventQueryDocument, options);
        }
export type CalendarEventQueryQueryHookResult = ReturnType<typeof useCalendarEventQueryQuery>;
export type CalendarEventQueryLazyQueryHookResult = ReturnType<typeof useCalendarEventQueryLazyQuery>;
export type CalendarEventQuerySuspenseQueryHookResult = ReturnType<typeof useCalendarEventQuerySuspenseQuery>;
export type CalendarEventQueryQueryResult = Apollo.QueryResult<CalendarEventQueryQuery, CalendarEventQueryQueryVariables>;
export const CalendarTagAddDocument = gql`
    mutation calendarTagAdd($eventId: ID!, $text: String!) {
  calendarTagAdd(eventId: $eventId, text: $text) {
    id
    text
  }
}
    `;
export type CalendarTagAddMutationFn = Apollo.MutationFunction<CalendarTagAddMutation, CalendarTagAddMutationVariables>;

/**
 * __useCalendarTagAddMutation__
 *
 * To run a mutation, you first call `useCalendarTagAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarTagAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarTagAddMutation, { data, loading, error }] = useCalendarTagAddMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCalendarTagAddMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CalendarTagAddMutation, CalendarTagAddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CalendarTagAddMutation, CalendarTagAddMutationVariables>(CalendarTagAddDocument, options);
      }
export type CalendarTagAddMutationHookResult = ReturnType<typeof useCalendarTagAddMutation>;
export type CalendarTagAddMutationResult = Apollo.MutationResult<CalendarTagAddMutation>;
export type CalendarTagAddMutationOptions = Apollo.BaseMutationOptions<CalendarTagAddMutation, CalendarTagAddMutationVariables>;
export const CalendarTagRemoveDocument = gql`
    mutation calendarTagRemove($eventId: ID!, $text: String!) {
  calendarTagRemove(eventId: $eventId, text: $text)
}
    `;
export type CalendarTagRemoveMutationFn = Apollo.MutationFunction<CalendarTagRemoveMutation, CalendarTagRemoveMutationVariables>;

/**
 * __useCalendarTagRemoveMutation__
 *
 * To run a mutation, you first call `useCalendarTagRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarTagRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarTagRemoveMutation, { data, loading, error }] = useCalendarTagRemoveMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCalendarTagRemoveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CalendarTagRemoveMutation, CalendarTagRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CalendarTagRemoveMutation, CalendarTagRemoveMutationVariables>(CalendarTagRemoveDocument, options);
      }
export type CalendarTagRemoveMutationHookResult = ReturnType<typeof useCalendarTagRemoveMutation>;
export type CalendarTagRemoveMutationResult = Apollo.MutationResult<CalendarTagRemoveMutation>;
export type CalendarTagRemoveMutationOptions = Apollo.BaseMutationOptions<CalendarTagRemoveMutation, CalendarTagRemoveMutationVariables>;