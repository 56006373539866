import styled from '@emotion/styled';
import { color, pxToRem, flex, spacing } from '@grain/grain-ui';

export const StyledDirSelector = styled.div<{ disabled?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  padding: 4px 12px;
  border: 1px solid ${color.goose};
  border-radius: 4px;
  overflow: auto;
  padding-right: 24px;
  position: relative;
  height: 36px;
  ${color.bg.goose};
  font-size: 14px;
  line-height: 36px;
  ${color.fg.blackbird};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background 0.3s;

  ${props =>
    props.disabled &&
    `
    opacity: 0.6;
    cursor: not-allowed;
  `};

  &:hover {
    ${props => !props.disabled && `background: ${color.gull}`}
  }

  & > svg {
    position: absolute;
    right: 12px;
  }
`;

export const StyledSwitchSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const StyledSwitchSection = styled.div`
  ${flex.direction.row};
  ${flex.alignItems.center};
  ${flex.justifyContent.spaceBetween};
  gap: 24px;
  .title {
    ${color.fg.blackbird};
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
    font-feature-settings:
      'case' on,
      'cpsp' on;

    .sub-title {
      ${color.fg.crow};
      ${spacing.mt2};
      font-style: normal;
      font-weight: 400;
      font-size: ${pxToRem(14)};
      line-height: ${pxToRem(18)};
      font-feature-settings:
        'case' on,
        'cpsp' on;
    }
  }
`;

export const StyledSeparator = styled.div`
  height: 1px;
  background: ${color.gull};
`;
