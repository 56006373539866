import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Icon, flex, pxToRem, toHumanDuration } from '@grain/grain-ui';
import { theme } from '@grain/grain-ui/v4';

import {
  DealDetailsFragment,
  DealDetailsRecordingFragment
} from '../../deal.generated';
import { getActivityTimestamp } from '../../utils';

import { ItemBorder, formatTime } from './Timeline';

const StyledHeader = styled.div`
  gap: ${theme.tokens.spacing.md};
  display: flex;
  align-items: center;
`;

const StyledBody = styled.div`
  ${theme.tokens.typography.b2[400]};
  line-height: ${pxToRem(20)};
`;

const StyledMeta = styled.div`
  ${flex.direction.column};
  gap: ${theme.tokens.spacing['2xs']};
  flex: 1 0 0;
`;

const StyledTopRow = styled.div`
  ${flex.alignItems.flexEnd}
  gap: ${theme.tokens.spacing.sm};
`;

const StyledTitle = styled(Link)`
  ${theme.tokens.typography.b3[600]};
  color: ${theme.tokens.color.textPrimary}; // Override link color
`;

const StyledBotRow = styled.div`
  ${flex.alignItems.center};
  gap: ${theme.tokens.spacing.md};
  ${theme.tokens.typography.c1[500]};
  color: ${theme.tokens.color.textSecondary};
`;

const StyledTime = styled.div`
  ${theme.tokens.typography.c1[500]};
  color: ${theme.tokens.color.textSecondary};
`;

const detailsImageStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.tokens.color.surfaceSecondary};
  border-radius: ${theme.tokens.radii.md};
  aspect-ratio: 65 / 36;
  width: 65px;
`;

const StyledMeetingDetailsImage = styled.img`
  ${detailsImageStyles}
`;

const StyledMeetingDetailsImageFallback = styled.div`
  ${detailsImageStyles}
`;

type RecordingProps = {
  deal: DealDetailsFragment;
  recording: DealDetailsRecordingFragment;
};

export const Recording = ({ recording, deal }: RecordingProps) => {
  const location = useLocation();
  const [errorLoading, setHasErrorLoading] = React.useState(false);

  const maybeSummary = React.useMemo(() => {
    type summaryType = { data?: { text?: string } };

    const summarySection = recording?.intelligence?.summary as summaryType;
    return summarySection?.data?.text;
  }, [recording]);

  const detailsImage =
    !errorLoading && recording.fullJpegThumbnailUrl ? (
      <StyledMeetingDetailsImage
        src={recording.fullJpegThumbnailUrl}
        alt='thumbnail'
        onError={() => setHasErrorLoading(true)}
      />
    ) : (
      <StyledMeetingDetailsImageFallback>
        <Icon.Calendar
          style={{
            height: '20px',
            width: '72px',
            color: theme.tokens.color.textTertiary
          }}
        />
      </StyledMeetingDetailsImageFallback>
    );

  return (
    <ItemBorder>
      <StyledHeader>
        <Link to={recording.recordingPath}>{detailsImage}</Link>
        <StyledMeta>
          <StyledTopRow>
            <StyledTitle
              to={recording.recordingPath}
              state={{
                breadcrumbs: [
                  { title: 'Deals', path: '/app/deals' },
                  { title: deal.name, path: location.pathname }
                ]
              }}
            >
              {recording.title}
            </StyledTitle>
            <StyledTime>
              {recording ? formatTime(getActivityTimestamp(recording)) : ''}
            </StyledTime>
          </StyledTopRow>
          <StyledBotRow>
            {typeof recording.duration === 'number' && (
              <span>{toHumanDuration(recording.duration)}</span>
            )}
          </StyledBotRow>
        </StyledMeta>
      </StyledHeader>
      {maybeSummary && <StyledBody>{maybeSummary}</StyledBody>}
    </ItemBorder>
  );
};
