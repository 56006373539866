import type { SubscriptionPlan, Workspace } from '@grain/api/schema.generated';
import * as Intercom from '~/support/intercom';

type RequestTrialArgs = {
  workspace: Workspace;
  plan: SubscriptionPlan;
};

export function requestTrial({ workspace, plan }: RequestTrialArgs) {
  const subject = 'Free Trial Request';
  const body = `Our workspace would like to trial ${plan.name} for 14 days, please.`;
  if (workspace.hasWorkEmailDomains) {
    Intercom.showNewMessage(body);
  } else {
    window.open(
      'mailto:help@grain.com' +
        `?subject=${encodeURIComponent(subject)}` +
        `&body=${encodeURIComponent(body)}`
    );
  }
}
