import { OptionsSelect, color, flex, font, spacing } from '@grain/grain-ui';
import { Topic } from './types';
import {
  SmartTopicMatchScope,
  SmartTopicSpeakerMatchScope
} from '@grain/api/schema.generated';

type Step3Props = {
  topic: Topic;
  setTopicValue: <K extends keyof Topic>(k: K, value: Topic[K]) => void;
};

const meetingTypeOptions = [
  {
    value: SmartTopicMatchScope.All,
    title: 'All meetings'
  },
  {
    value: SmartTopicMatchScope.External,
    title: 'Customer meetings'
  },
  {
    value: SmartTopicMatchScope.Internal,
    title: 'Team meetings'
  }
];

const speakerOptions = [
  {
    value: SmartTopicSpeakerMatchScope.All,
    title: 'All speakers'
  },
  {
    value: SmartTopicSpeakerMatchScope.External,
    title: 'Customers'
  },
  {
    value: SmartTopicSpeakerMatchScope.Internal,
    title: 'Team members'
  }
];

export const Step3 = ({ topic, setTopicValue }: Step3Props) => {
  return (
    <section css={[spacing.py4, flex.direction.column, spacing.px7]}>
      <div css={[spacing.mb6]}>
        <div css={[spacing.mb4, font.v4.h6]}>Meeting type</div>
        <div css={[font.v4.b2[400], color.fg.crow, spacing.mb4]}>
          Select the meeting type this tracker should apply to.
        </div>
        <OptionsSelect
          css={['max-width: 232px;']}
          isMultiSelect={false}
          options={meetingTypeOptions}
          placeholder='Choose one'
          selectHeight='32px'
          width='232px'
          defaultValues={meetingTypeOptions.filter(
            option => option.value === topic.matchScope
          )}
          selectedText={
            meetingTypeOptions.find(opt => opt.value === topic?.matchScope)
              ?.title || ''
          }
          onSelect={options => {
            const currentOptions = options.slice(-1);
            if (currentOptions[0]?.value) {
              setTopicValue(
                'matchScope',
                currentOptions[0]?.value as SmartTopicMatchScope
              );
            }
          }}
        />
      </div>

      <div>
        <div css={[spacing.mb4, font.v4.h6]}>Speaker type</div>
        <div css={[font.v4.b2[400], color.fg.crow, spacing.mb4]}>
          Select the speaker type this tracker should apply to.
        </div>
        <OptionsSelect
          css={['max-width: 232px;']}
          isMultiSelect={false}
          options={speakerOptions}
          placeholder='Choose one'
          selectHeight='32px'
          width='232px'
          defaultValues={speakerOptions.filter(
            option => option.value === topic.speakerScope
          )}
          selectedText={
            speakerOptions.find(opt => opt.value === topic?.speakerScope)
              ?.title || ''
          }
          onSelect={options => {
            const currentOptions = options.slice(-1);
            if (currentOptions[0]?.value) {
              setTopicValue(
                'speakerScope',
                currentOptions[0]?.value as SmartTopicSpeakerMatchScope
              );
            }
          }}
        />
      </div>
    </section>
  );
};
