// @ts-strict-ignore
import { ScorecardQuestion, spaces } from '@grain/grain-ui';
import {
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableStateSnapshot
} from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';
import { Question } from './types';

type PortalAwareQuestionProps = {
  provided: DraggableProvided;
  question: Question;
  onChange: (question: Question) => void;
  onRemove: (id: string) => void;
  onSelect: () => void;
  isSelected: boolean;
  droppableSnapshot: DroppableStateSnapshot;
  draggableSnapshot: DraggableStateSnapshot;
};

// We're forced to use portals due to an issue with react-beautiful-dnd
// https://github.com/atlassian/react-beautiful-dnd/issues/499
export const PortalAwareQuestion = ({
  provided,
  question,
  onChange,
  onRemove,
  onSelect,
  isSelected,
  droppableSnapshot,
  draggableSnapshot
}: PortalAwareQuestionProps) => {
  const usePortal = draggableSnapshot.isDragging;

  const child = (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      css={{
        marginBottom: spaces[6],
        ':last-child': { marginBottom: spaces[7] }
      }}
    >
      <ScorecardQuestion
        onChange={onChange}
        question={question}
        onSelect={onSelect}
        onRemove={onRemove}
        isSelected={isSelected}
        dragHandleProps={provided.dragHandleProps}
        css={{
          pointerEvents: droppableSnapshot.isDraggingOver ? 'none' : 'auto'
        }}
      />
    </div>
  );

  if (usePortal) {
    return ReactDOM.createPortal(child, document.body);
  }

  return child;
};
