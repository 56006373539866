import { css } from '@emotion/react';
import { Icon16, TextLabel, theme } from '@grain/grain-ui/v4';

// Same as desktop-lib/src/pages/Login/PrivacySecurityInfo
export function PrivacySecurityInfo() {
  return (
    <TextLabel
      size='sm'
      startIcon={Icon16.Lock}
      css={[
        css`
          display: inline-flex;
          color: ${theme.tokens.color.textTertiary};

          a {
            color: inherit;
            text-decoration: underline;
          }
        `
      ]}
    >
      Grain is SOC II audited and GDPR compliant.{' '}
      <a
        href='https://www.grain.com/privacy-policy'
        target='_blank'
        rel='noopener noreferrer'
      >
        View Privacy Policy
      </a>
    </TextLabel>
  );
}
