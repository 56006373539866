// @ts-strict-ignore
import {
  KnockProvider as _KnockProvider,
  KnockFeedProvider as _KnockFeedProvider
} from '@knocklabs/react';

import { useMyself } from '@grain/api/auth';
import { useAuth } from '~/support/auth';
import { useNotificationAuthQuery } from './notificationAuth.generated';
import useTimeout from './useTimeout';
import { parseISO } from 'date-fns';
import { GrainKnockFeedProvider } from './GrainKnockFeedProvider';

const KNOCK_FEED_CHANNEL_ID = '0b0c2581-1476-4b17-b19b-52ebbbf18c2c';

// Knock Feed Provider should only be rendered within the logged in router
export const KnockFeedProvider = ({
  children
}: {
  children: React.ReactElement;
}) => {
  // Knock doesn't need to load in the notification window nor the notepad and
  // it breaks notifications.
  const { isAuthenticated } = useAuth();
  const { myself } = useMyself({ skip: Boolean(!isAuthenticated) });
  const { data, refetch } = useNotificationAuthQuery({
    skip: Boolean(!isAuthenticated)
  });

  const token = data?.notificationAuth?.clientAuthToken || '';

  const getExpiry = () => {
    const expiration =
      data?.notificationAuth?.expiration &&
      parseISO(data?.notificationAuth?.expiration).getTime();

    if (!expiration) return null;

    const now = Date.now();

    return Math.max(expiration - now, 0);
  };

  useTimeout(() => {
    refetch();
  }, getExpiry());

  const userId = myself?.user?.id || undefined;

  if (!token || !userId) {
    return null;
  }

  return (
    <_KnockProvider
      apiKey={process.env.KNOCK_PUBLIC_API_KEY}
      userToken={token}
      userId={userId}
    >
      <_KnockFeedProvider feedId={KNOCK_FEED_CHANNEL_ID}>
        <GrainKnockFeedProvider>{children}</GrainKnockFeedProvider>
      </_KnockFeedProvider>
    </_KnockProvider>
  );
};
