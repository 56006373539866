import { ButtonMetadata } from './ButtonMetadata';
import {
  TextLabel,
  Menu,
  MenuHeader,
  Icon16,
  theme,
  Divider
} from '@grain/grain-ui/v4';

const EMPTY_ARRAY: string[] = [];

type TagMetaItemProps = {
  tags?: string[];
};

export const TagMetaItem = ({ tags = EMPTY_ARRAY }: TagMetaItemProps) => {
  const [firstTag, ...restTags] = tags;

  const metadata = !firstTag
    ? 'none'
    : restTags.length
      ? `+${restTags.length}`
      : '';

  const TagButton = () => (
    <ButtonMetadata width='lg' disableInteractive={!firstTag}>
      <TextLabel
        startIcon={Icon16.Hash}
        metadata={metadata}
        size='sm'
        css={{ color: theme.tokens.color.textSecondary }}
      >
        {firstTag ?? ''}
      </TextLabel>
    </ButtonMetadata>
  );

  if (!firstTag) {
    return <TagButton />;
  }

  return (
    <Menu
      hideOnClickInside
      stopClickPropagation
      width='232px'
      childWidth='auto'
      content={
        <>
          <MenuHeader>Meeting Tags</MenuHeader>
          <Divider />
          {tags.map(tag => (
            <MenuHeader variant='subheader' key={tag}>
              #{tag}
            </MenuHeader>
          ))}
        </>
      }
    >
      <TagButton />
    </Menu>
  );
};
