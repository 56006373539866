import { css } from '@emotion/react';
import { color, colors, font, spacing } from '@grain/styles/constants';

export const focusedStyles = css`
  ${color.rook};
  background: white;
  border: 1px solid ${colors.graieen};
`;

export const textAreaStyles = css`
  ${font.bodyS};
  ${spacing.px3};
  ${spacing.py3};

  border-radius: 8px;
  border: 1px solid ${colors.pigeon};
  font-family: Inter, sans-serif;
  font-weight: normal;
  outline: none;
  margin: 0;

  &:disabled {
    ${color.pigeon};
    background: ${colors.gull};
    border: 1px solid ${colors.pigeon};
    cursor: not-allowed;
  }

  &:focus {
    ${focusedStyles};
  }

  &.error {
    border-color: ${colors.errorbird};
  }

  ::placeholder {
    ${color.pigeon};
  }
`;

export const labelStyles = css`
  ${color.crow};
  ${font.bodyS};
  ${spacing.ml1};
  ${spacing.mb3};
`;
