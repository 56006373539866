import Button from '@grain/components/Button';
import React from 'react';
import { spacing } from '@grain/styles/constants';
import { INTEGRATIONS_ROUTE_ID } from '@grain/components/modals/constants';
import { useFeature } from '@grain/api/auth';
import {
  Modal,
  ModalTitle,
  useRouteModal,
  useQueryParam,
  Icon
} from '@grain/grain-ui';
import {
  StyledSectionLabel,
  StyledOptionDescription,
  StyledSeparator
} from '~/pages/Settings/Account/styles';

import { useGetIntegrations } from '~/pages/Settings/Integrations/hook';

import IntegrationFeatureGate from '~/pages/Settings/Integrations/IntegrationFeatureGate';
import {
  StyledBackButton,
  StyledContent,
  StyledIntegrationLine,
  StyledIntegrationLogo,
  StyledCategory,
  modalStyles
} from '~/pages/Settings/Integrations/styles';

export default function Integrations() {
  const [activeIntegration, setActiveIntegration] =
    useQueryParam('integration');

  const { close: closeModal } = useRouteModal(INTEGRATIONS_ROUTE_ID);

  const close = React.useCallback(() => {
    setActiveIntegration(undefined);
    closeModal();
  }, [setActiveIntegration, closeModal]);

  const { allIntegrations: integrations } = useGetIntegrations();

  const leftAction = React.useMemo(() => {
    if (!activeIntegration) return null;

    return (
      <StyledBackButton onClick={() => setActiveIntegration(undefined)}>
        <Icon.ArrowLeft />
      </StyledBackButton>
    );
  }, [activeIntegration, setActiveIntegration]);

  // Hidden highlight watermark premium feature
  const {
    enabled: productboardEnabled,
    visible: productboardVisible,
    requiredPlan: productboardRequiredPlan
  } = useFeature('productboard_integration');

  const {
    enabled: salesforceEnabled,
    visible: salesforceVisible,
    requiredPlan: salesforceRequiredPlan
  } = useFeature('salesforce_integration');

  const {
    enabled: zapierEnabled,
    visible: zapierVisible,
    requiredPlan: zapierRequiredPlan
  } = useFeature('zapier_integration');

  const hasConnectedIntegrations = React.useMemo(
    () =>
      integrations.find(
        integration => integration.isConnected && integration.isVisible
      ),
    [integrations]
  );
  const hasNotConnectedIntegrations = React.useMemo(
    () =>
      integrations.find(
        integration => !integration.isConnected && integration.isVisible
      ),
    [integrations]
  );

  const title = React.useMemo(() => {
    let titleString = 'Integrations';
    let integration;

    if (activeIntegration) {
      integration = integrations.find(
        integration => integration.name.toLowerCase() === activeIntegration
      );
    }

    if (activeIntegration) {
      titleString = integration?.name || titleString;
    }

    return (
      <div
        css={['display: flex; align-items: center; justify-content: center;']}
      >
        <span>{titleString}</span>
      </div>
    );
  }, [activeIntegration, integrations]);

  let content = null;
  let footer = null;

  const renderIntegrations = integrationsList => {
    return integrationsList.map(integration => {
      const isProductBoardFeatureGated =
        productboardVisible &&
        !productboardEnabled &&
        integration.name === 'Productboard';

      const isSalesforceFeatureGated =
        salesforceVisible &&
        !salesforceEnabled &&
        integration.name === 'Salesforce';

      const isZapierFeatureGated =
        zapierVisible && !zapierEnabled && integration.name === 'Zapier';

      return (
        <div key={integration.name}>
          <StyledIntegrationLine>
            <StyledSectionLabel>
              <div css={['display: flex; align-items: center;']}>
                <StyledIntegrationLogo>
                  {integration.logo}
                </StyledIntegrationLogo>
                {integration.name}
              </div>
            </StyledSectionLabel>
            <StyledOptionDescription css={[spacing.mb6]}>
              {integration.description}
            </StyledOptionDescription>
            {!isProductBoardFeatureGated &&
              !isZapierFeatureGated &&
              !isSalesforceFeatureGated && (
                <Button
                  type='primary'
                  onClick={() => {
                    setActiveIntegration(integration.name.toLowerCase());
                  }}
                >
                  {integration.isConnected ? 'Settings' : 'Get started'}
                </Button>
              )}
            {isProductBoardFeatureGated && (
              <IntegrationFeatureGate
                featureName='productboard_integration'
                requiredPlan={productboardRequiredPlan}
              />
            )}
            {isSalesforceFeatureGated && (
              <IntegrationFeatureGate
                featureName='salesforce_integration'
                requiredPlan={salesforceRequiredPlan}
              />
            )}
            {isZapierFeatureGated && (
              <IntegrationFeatureGate
                featureName='zapier_integration'
                requiredPlan={zapierRequiredPlan}
              />
            )}
          </StyledIntegrationLine>
        </div>
      );
    });
  };

  if (activeIntegration) {
    const integration = integrations.find(
      integration => integration.name.toLowerCase() === activeIntegration
    );

    if (activeIntegration) {
      content = integration.page;
      footer = integration.footer;
    }
  } else {
    content = (
      <div>
        {hasConnectedIntegrations && (
          <>
            <StyledCategory>Connected</StyledCategory>
            {renderIntegrations(
              integrations.filter(
                integration => integration.isConnected && integration.isVisible
              )
            )}
            {hasNotConnectedIntegrations && <StyledSeparator />}
          </>
        )}
        {hasNotConnectedIntegrations && (
          <>
            <StyledCategory>Discover</StyledCategory>
            {renderIntegrations(
              integrations.filter(
                integration => !integration.isConnected && integration.isVisible
              )
            )}
          </>
        )}
      </div>
    );
  }

  return (
    <Modal closable width={516} css={[modalStyles]} onCancel={close}>
      <ModalTitle title={title} centered leftAction={leftAction} />
      <StyledContent>{content}</StyledContent>
      {footer}
    </Modal>
  );
}
