import React from 'react';
import { useRouteModal } from '@grain/grain-ui';
import { TRIAL_STARTED_ROUTE_ID } from '@grain/components/modals/constants';
import LegacyOnboardingModal from './LegacyOnboarding';

export default function TrialStartedModal() {
  const { close } = useRouteModal(TRIAL_STARTED_ROUTE_ID);

  return <LegacyOnboardingModal close={close} onlyTrial />;
}
