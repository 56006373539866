import styled from '@emotion/styled';
import type { OauthAccount } from '@grain/api/schema.generated';
import { TextLabel, theme } from '@grain/grain-ui/v4';

const Pill = styled.div`
  display: inline-block;
  padding: ${theme.tokens.spacing.lg} ${theme.tokens.spacing.xl};
  border-radius: ${theme.tokens.radii.full};
  background: ${theme.tokens.color.surfaceTertiary};
`;

type AccountPillProps = Pick<OauthAccount, 'avatarUrl' | 'name' | 'email'>;

export function AccountPill({ avatarUrl, name, email }: AccountPillProps) {
  const url = avatarUrl ?? undefined;
  return (
    <Pill>
      <TextLabel size='lg' {...(name ? { avatarProps: { url, name } } : {})}>
        {email}
      </TextLabel>
    </Pill>
  );
}
