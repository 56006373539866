import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Recording } from '@grain/api/schema.generated';
import api from '@grain/components/support/api';
import * as Layout from '~/components/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import { useUrlErrorBanner } from '~/modules/errors/hooks';
import styled from '@emotion/styled';
import { RecordingsList } from './RecordingsList/RecordingsList';
import { RecordingDetail } from './RecordingDetail/RecordingDetail';
import { GatedContentWrapper } from '~/modules/gates';
import { color, useAnalytics, useShowToast } from '@grain/grain-ui';
import { Divider, Icon20, PageHeader } from '@grain/grain-ui/v4';
import { css } from '@emotion/react';
import { useRecordingsSalesFeedbackViewedMutation } from '~/modules/coaching/coaching.generated';
import { CoachingHeader } from './components/CoachingHeader';
import { useFiltersManager } from '~/modules/filtersV2/useFiltersManager';
import { useViewAdHocQuery } from '~/modules/contentFilter/contentfilter.generated';
import { useSidebar } from '~/components/Layout';
import { isDesktopApp } from '@grain/desktop-lib';
import { useFeature } from '@grain/api/auth';

// For debugging in dev/staging:
declare global {
  interface Window {
    resetCoachingData: () => void;
  }
}

const StyledPanels = styled.div`
  display: flex;
  height: 100%;
`;

const StyledPanelLeft = styled.div`
  flex-shrink: 0;
  width: 400px;
`;

const StyledPanelRight = styled.div<{ greyedOut: boolean }>`
  flex-grow: 1;

  ${({ greyedOut }) =>
    greyedOut &&
    css`
      background-color: ${color.goose};
    `}
`;

const CoachingPage = () => {
  const ErrorBanner = useUrlErrorBanner();
  const navigate = useNavigate();
  const showToast = useShowToast();
  const { recordingId, tabId = 'overview' } = useParams();
  const { enabled } = useFeature('sales_coaching');
  const { trackEvent } = useAnalytics();
  const { toggleCollapsed } = useSidebar();

  useEffect(() => {
    if (process.env.ENVIRONMENT !== 'production') {
      /**
       * Resets the following data in this workspace:
       *
       *   - Whether feedback for a recording was viewed
       *   - Delete any review requests that were sent
       *   - Un-delete any assessment feedback points that were deleted
       *
       * It will be as if you've never opened the Coaching page before.
       */
      window.resetCoachingData = async () => {
        try {
          await api.get('/_/clear_coaching_page_data');
          showToast({
            content: 'Coaching page data cleared',
            type: 'success',
            showCloseButton: true,
            durationMs: 0,
            action: 'Reload',
            onAction() {
              location.reload();
            }
          });
        } catch {
          showToast({
            content: 'Error clearing data',
            type: 'failure',
            showCloseButton: true
          });
        }
      };
    }
  }, [showToast]);

  const setRecordingId = useCallback(
    (newRecordingId: string | null) => {
      if (!newRecordingId) {
        navigate('/app/coaching');
      } else {
        navigate(`/app/coaching/${newRecordingId}`, {
          replace: !recordingId // replace only if coming from a url without a recordingId ENG-12034
        });
      }
    },
    [navigate, recordingId]
  );

  // Check initial page load
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (!tabId) return;

    trackEvent('Coaching Tabs Changed', { tab: tabId }, ['user', 'workspace']);
  }, [tabId, trackEvent]);

  const { filters, viewAdHocFilterString, resetFilters, activeFilters } =
    useFiltersManager({
      filterEntityType: 'recordings',
      availableFilters: [
        'title',
        'date',
        'owners',
        'tags',
        'persons',
        'groups',
        'sales_call_overall_scores',
        'coaching',
        'coaching_unseen',
        'participant_scope',
        'locked'
      ],
      hiddenFilters: ['title', 'participant_scope', 'locked', 'coaching'],
      initialValues: {
        participant_scope: { value: 'external', label: '' },
        locked: false
      }
    });

  const { data, refetch, fetchMore, loading } = useViewAdHocQuery({
    variables: {
      filter: viewAdHocFilterString,
      includeCoaching: true,
      includeScoring: true
    },
    onCompleted: data => {
      if (recordingId || !enabled) return;
      const firstRecordingId = data?.viewAdHoc?.list?.[0]?.id;
      setRecordingId(firstRecordingId || null);
    }
  });

  const dataToDisplay = useMemo(() => data?.viewAdHoc?.list || [], [data]);
  const cursor = data?.viewAdHoc?.cursor;

  const fetchMoreData = useCallback(() => {
    fetchMore({ variables: { cursor } });
  }, [cursor, fetchMore]);

  const recordings = useMemo(
    () => dataToDisplay.filter(item => item.__typename === 'Recording'),
    [dataToDisplay]
  ) as unknown as Recording[];

  const onChangeTabId = (tabId: string, itemOnTab?: string) => {
    navigate(
      {
        pathname: `/app/coaching/${recordingId}/${tabId}`,
        search: location.search
      },
      {
        state: { itemOnTab }
      }
    );
  };

  const [markAllAsViewed] = useRecordingsSalesFeedbackViewedMutation({
    variables: {
      filter: JSON.stringify({
        types: ['recordings'],
        filters: [
          { participant_scope: 'external' },
          { has_feedback_assessments: true, include_deleted: false }
        ]
      })
    },
    onCompleted: data => {
      if (data.recordingsSalesFeedbackViewed) {
        // TODO: remove this when we have subscriptions on the backend
        refetch();
        showToast({
          content: 'Feedback marked as seen',
          type: 'success'
        });
      }
    },
    onError: () => {
      showToast({
        content: 'Failed to mark feedback as seen',
        type: 'failure'
      });
    }
  });

  const handleCoachingOpportunityChange = () => {
    filters.coaching.setValue(p => !p);
  };

  return (
    <>
      <Helmet title='Coaching' />
      <Layout.Wrapper withSidebar>
        {!enabled && (
          // Reimplement a simplified page header for when the page is gated.
          // That way this gated page looks more like other gated pages.
          // Normally the header is in the left column of the Coaching page
          // layout, but that would look weird if all the other content was
          // hidden behind the gate.
          <>
            <PageHeader
              isDesktopApp={isDesktopApp}
              icon={Icon20.Announcement}
              title='Coaching'
              onMenuClick={toggleCollapsed}
            />
            <Divider />
          </>
        )}
        <GatedContentWrapper gate='sales_coaching'>
          <ErrorBanner />
          <StyledPanels>
            <StyledPanelLeft>
              <CoachingHeader
                filters={filters}
                resetFilters={resetFilters}
                markAllAsViewed={markAllAsViewed}
                handleCoachingOpportunityChange={
                  handleCoachingOpportunityChange
                }
                onlyCoachingOpportunity={filters.coaching.value}
              />
              {enabled && (
                <RecordingsList
                  currentRecordingId={recordingId}
                  recordings={recordings}
                  dataLength={dataToDisplay.length}
                  hasMore={Boolean(cursor)}
                  next={fetchMoreData}
                  isFilterActive={Object.keys(activeFilters).length > 0}
                  loading={loading}
                  resetAllFilters={resetFilters}
                />
              )}
            </StyledPanelLeft>
            <StyledPanelRight greyedOut={!recordingId}>
              {recordingId && enabled && (
                <RecordingDetail
                  recordingId={recordingId}
                  tabId={tabId}
                  onChangeTabId={onChangeTabId}
                />
              )}
            </StyledPanelRight>
          </StyledPanels>
        </GatedContentWrapper>
      </Layout.Wrapper>
    </>
  );
};

export default CoachingPage;
