import React from 'react';
import { useRouteModal } from '@grain/grain-ui';
import { CALENDLY_ROUTE_ID } from '@grain/components/modals/constants';
import LegacyOnboardingModal from './LegacyOnboarding';

export default function CalendlyModal() {
  const { close } = useRouteModal(CALENDLY_ROUTE_ID);

  return <LegacyOnboardingModal close={close} onlyBookingScreen />;
}
