import styled from '@emotion/styled';
import {
  Button,
  color,
  font,
  Icon,
  Modal,
  ModalTitle,
  spacing
} from '@grain/grain-ui';
import {
  SubscriptionPlan,
  WorkspaceSubscription
} from '@grain/api/schema.generated';
import { getBillingDisplayDate } from '../helpers';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  justify-content: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  ${color.fg.blackbird};
  text-align: center;
  ${font.v4.b1[600]};
  ${spacing.mt6};
`;
const Description = styled.div`
  ${color.fg.crow};
  text-align: center;
  ${font.v4.b1[400]};
  margin-top: 12px;
  margin-bottom: 24px;

  span {
    font-weight: 600;
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type DowngradeConfirmModalProps = {
  fromPlan: SubscriptionPlan;
  toPlan: SubscriptionPlan;
  workspaceSubscription: WorkspaceSubscription;
  close: () => void;
};

export default function DowngradeConfirmModal({
  toPlan,
  fromPlan,
  workspaceSubscription,
  close
}: DowngradeConfirmModalProps) {
  return (
    <Modal onCancel={() => close()} closable width={516} centered>
      <ModalTitle title='Account downgraded' centered />
      <Content>
        <IconWrapper>
          <Icon.CheckComplete />
        </IconWrapper>
        <Title>
          You have been downgraded
          {toPlan?.shortName ? ` to the ${toPlan?.shortName} plan` : ''}{' '}
        </Title>
        <Description>
          You can use your {fromPlan.shortName}
          {fromPlan.isLegacy && ' (Legacy)'} plan features until the end of your
          billing period on{' '}
          <span>
            {getBillingDisplayDate(workspaceSubscription?.renewalDate ?? '')}.{' '}
          </span>
        </Description>
        <Action>
          <Button variant='primary' onClick={() => close()}>
            Got it
          </Button>
        </Action>
      </Content>
    </Modal>
  );
}
