import { getBasePublicPath } from '@grain/components/support/environment';
import { DownloadButton } from '@grain/grain-ui';
import { Wrapper } from './Wrapper';

import {
  StyledContainer,
  StyledHeader,
  StyledSubHeader,
  StyledButtonContainer,
  StyledButtonWrapper,
  StyledButtonDescription,
  StyledFooterNote
} from './styles';

export default function Download() {
  return (
    <Wrapper>
      <StyledContainer>
        <StyledHeader>Get the Grain desktop app</StyledHeader>
        <StyledSubHeader>
          The best way to take notes and record with Grain.
        </StyledSubHeader>
        <StyledButtonContainer>
          <StyledButtonWrapper>
            <DownloadButton
              downloadType='Mac'
              href='/app/_/redirect/download/mac'
              data-track='Button Clicked'
              data-track-args={JSON.stringify({
                button_name: 'download_mac_app',
                button_text: 'Download for Mac'
              })}
            >
              Download for Mac
            </DownloadButton>

            <StyledButtonDescription>
              For macOS 10.14 or later.
            </StyledButtonDescription>
          </StyledButtonWrapper>
          <StyledButtonWrapper>
            <DownloadButton
              downloadType='Windows'
              href='/app/_/redirect/download/windows'
              data-track='Button Clicked'
              data-track-args={JSON.stringify({
                button_name: 'download_windows_app',
                button_text: 'Download for Windows'
              })}
            >
              Download for Windows
            </DownloadButton>
            <StyledButtonDescription>(Beta version)</StyledButtonDescription>
          </StyledButtonWrapper>
        </StyledButtonContainer>
        <img
          css={['max-width: 520px; width: 100%;']}
          alt='Screenshot of the Grain desktop app open on the Grain Library page with Grain accessible via the system tray'
          src={`${getBasePublicPath()}/images/download/desktop-thumb.png`}
        />
        <StyledFooterNote>
          By downloading Grain, you agree to the Grain{' '}
          <a href='https://grain.com/terms' target='_blank'>
            Terms of Service
          </a>
        </StyledFooterNote>
      </StyledContainer>
    </Wrapper>
  );
}
