import { ViewChangedType } from '@grain/api/schema.generated';
import { useViewAdHocChangedSubscription } from '~/modules/contentFilter/contentfilter.generated';
import {
  cacheDeleteItem,
  useRemoveFromAdHocCache,
  useUpdateAdHocCache
} from '~/modules/contentFilter/cacheHelpers';

type UseFetchPlaylistsProps = {
  filter: string;
};

export const usePlaylistsSubscription = ({
  filter
}: UseFetchPlaylistsProps) => {
  const updateAdHocCache = useUpdateAdHocCache();
  const removeFromAdHocCache = useRemoveFromAdHocCache();

  useViewAdHocChangedSubscription({
    variables: {
      filter
    },
    onData: ({ client, data }) => {
      if (!data?.data?.viewAdHocChanged) {
        return;
      }
      const { viewAdHocChanged } = data.data;
      if (
        viewAdHocChanged.type === ViewChangedType.Added ||
        viewAdHocChanged.type === ViewChangedType.Updated
      ) {
        updateAdHocCache(viewAdHocChanged.data);
      }

      if (viewAdHocChanged.type === ViewChangedType.Removed) {
        let typename = viewAdHocChanged.data.__typename!;
        if (typename === 'RemovedMediaClip') typename = 'ClipV2';
        if (typename === 'RemovedMediaRecording') typename = 'Recording';
        if (typename === 'RemovedMediaStory') typename = 'Story';

        cacheDeleteItem(client, {
          typename,
          id: viewAdHocChanged.data.id
        });
        removeFromAdHocCache(viewAdHocChanged.data.id);
      }
    }
  });
};
