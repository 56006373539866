// @ts-strict-ignore
import React from 'react';
import styled from '@emotion/styled';
import {
  Button,
  color,
  font,
  Modal,
  ModalTitle,
  pxToRem,
  spacing,
  useConfirm,
  useRouteModal
} from '@grain/grain-ui';
import { CHANGE_LEGACY_PLAN_ROUTE_ID } from '@grain/components/modals/constants';
import { useNavigate } from 'react-router-dom';
import { SubscriptionPlan } from '@grain/api/schema.generated';
import { displayCents } from '~/support/price';
import { SubscriptionExpirationExplanation } from '~/modules/subscriptions/helpers';
import * as Intercom from '~/support/intercom';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 48px;
`;

const Description = styled.div`
  ${color.fg.crow};
  ${font.v4.b1[400]};
  ${spacing.mb4};

  span {
    ${color.fg.highlightGreenDark};
  }
`;

const PlanPane = styled.div<{ isPopular?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${spacing.pr7}
  gap: 24px;
  border-radius: 16px;
  ${color.bg.goose};
  ${spacing.my2};
  border: 1px solid
    ${({ isPopular = false }) => (isPopular ? color.planbird : color.gull)};
`;

const PlanPaneContent = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

const PlanPaneTitle = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  ${color.fg.blackbird};
  font-size: ${pxToRem(18)};
  font-weight: 600;
  line-height: ${pxToRem(34)};
`;

const PlanPaneTitlePricing = styled.div`
  ${font.v4.b4[400]};
`;

const PlanPaneInfo = styled.div`
  ${color.fg.crow};
  ${font.v4.b2[400]};
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const PlanPaneAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  ${color.bg.blackbird};
  margin: 0 10px;
`;

const Contact = styled.div`
  ${color.fg.crow};
  ${font.v4.b2[400]};
  width: 100%;
  text-align: center;
  ${spacing.mt6};
`;

const PopularPill = styled.div`
  margin-left: 10px;
  border-radius: 100px;
  border: 1px solid ${color.planbird};
  background: rgba(61, 115, 255, 0.02);
  display: flex;
  padding: 2px 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${color.fg.planbird};
  text-align: center;
  ${font.v4.button};
`;

type ChangeLegacyPlanModalProps = {
  subscriptionPlans: SubscriptionPlan[];
  currentPlan: SubscriptionPlan;
  upcomingPlan: SubscriptionPlan;
  isAdmin: boolean;
  openContactAdmin: () => void;
};

export default function ChangeLegacyPlanModal({
  subscriptionPlans,
  currentPlan,
  upcomingPlan,
  isAdmin,
  openContactAdmin
}: ChangeLegacyPlanModalProps) {
  const { close: closeChangeLegacyPlanModal } = useRouteModal(
    CHANGE_LEGACY_PLAN_ROUTE_ID
  );
  const showConfirm = useConfirm();

  const downgradePath = subscriptionPlans.find(
    plan => currentPlan.legacyUpdatePath.downgradeSku === plan.sku
  );

  const upgradePath = subscriptionPlans.find(
    plan => currentPlan.legacyUpdatePath.upgradeSku === plan.sku
  );
  const navigate = useNavigate();

  const changePlan = (sku: string) => {
    if (!isAdmin) {
      openContactAdmin();
      return;
    }
    if (upcomingPlan?.sku === sku) {
      showConfirm({
        showCancel: false,
        width: 418,
        confirmButtonType: 'primary',
        title: 'Plan Change Pending',
        onConfirm: () => {},
        description: <SubscriptionExpirationExplanation />
      });
      return;
    }
    navigate({
      pathname: `/app/change-plan/${sku}`,
      search: location.search
    });
  };
  return (
    <Modal
      onCancel={() => closeChangeLegacyPlanModal()}
      closable
      width={862}
      centered
    >
      <ModalTitle title='Select a Plan' centered />
      <Content>
        <Description>
          You are currently on one of Grain's legacy business plans that is no
          longer offered to new users. Your plan effectively consists of today's
          Starter plan with HubSpot and Salesforce integrations and unlimited
          uploads added on. You currently pay{' '}
          <span>{`$${displayCents(currentPlan.yearlyPrice / 12)}`}</span> per
          month per seat. We will continue to support your current plan, but you
          can also change to one of our new plans
        </Description>

        <PlanPane isPopular={downgradePath.isPopular}>
          <PlanPaneContent>
            <PlanPaneTitle>
              {downgradePath.shortName}
              <Dot />
              <PlanPaneTitlePricing>
                {`$${displayCents(downgradePath.yearlyPrice / 12)}`} per
                seat/month
              </PlanPaneTitlePricing>
            </PlanPaneTitle>
            <PlanPaneInfo>
              {currentPlan.legacyUpdatePath.downgradeSkuDescription}
            </PlanPaneInfo>
          </PlanPaneContent>
          <PlanPaneAction>
            <Button
              variant='secondary'
              onClick={() => {
                changePlan(downgradePath.sku);
              }}
            >
              Downgrade to {downgradePath.shortName}
            </Button>
          </PlanPaneAction>
        </PlanPane>
        <PlanPane isPopular={upgradePath.isPopular}>
          <PlanPaneContent>
            <PlanPaneTitle>
              {upgradePath.shortName}
              <Dot />
              <PlanPaneTitlePricing>
                {`$${displayCents(upgradePath.yearlyPrice / 12)}`} per
                seat/month
              </PlanPaneTitlePricing>
              {upgradePath.isPopular && <PopularPill>Popular</PopularPill>}
            </PlanPaneTitle>
            <PlanPaneInfo>
              {currentPlan.legacyUpdatePath.upgradeSkuDescription}
            </PlanPaneInfo>
          </PlanPaneContent>
          <PlanPaneAction>
            <Button
              variant='plan'
              onClick={() => {
                changePlan(upgradePath.sku);
              }}
            >
              Upgrade to {upgradePath.shortName}
            </Button>
          </PlanPaneAction>
        </PlanPane>

        <Contact>
          <a
            href='mailto:help@grain.com'
            target='_blank'
            rel='noreferrer'
            onClick={e => {
              e.preventDefault();
              Intercom.showTab('messages');
            }}
            css={[
              color.fg.highlightGreenDark,
              'text-decoration: underline; font-size: 12px;'
            ]}
          >
            Contact support
          </a>{' '}
          with any questions!
        </Contact>
      </Content>
    </Modal>
  );
}
