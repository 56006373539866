import { useRecentSearches, useSearchString } from '../state';
import { Icon, color } from '@grain/grain-ui';
import {
  RecentRecordingFragment,
  RecentRecordingsQuery,
  useRecentRecordingsQuery
} from '../searchQuery.generated';
import { RecentRecording } from './RecentRecording';
import { ResultHeading } from './ResultHeading';
import { TextRow } from './TextRow';
import { useNavigate } from 'react-router-dom';

const isRecording = (
  item: RecentRecordingsQuery['viewAdHoc']['list'][number]
): item is RecentRecordingFragment => {
  return item.__typename === 'Recording';
};

export const RecentSearchItems = () => {
  const { data: recentSearchesData, loading: recentSearchesLoading } =
    useRecentSearches(5);
  const { data: recentRecordingsData } = useRecentRecordingsQuery();
  const setSearchString = useSearchString()[1];
  const navigate = useNavigate();

  const recentSearches = recentSearchesData.recentSearches ?? [];
  const showEmpty = !recentSearchesLoading && recentSearches.length === 0;
  const recentRecordings = (recentRecordingsData?.viewAdHoc?.list ?? []).filter(
    isRecording
  );

  const handleRecentSearchItemClick = (search: string) => {
    setSearchString(search);
  };

  return (
    <div>
      <ResultHeading>Recent searches</ResultHeading>
      {showEmpty && (
        <TextRow css={[color.fg.pigeon]}>No recent searches</TextRow>
      )}
      {recentSearches.map((search, index) => (
        <TextRow
          as='button'
          key={search}
          onClick={() => handleRecentSearchItemClick(search)}
          style={
            index === recentSearches.length - 1 ? { borderBottom: 'none' } : {}
          }
        >
          <Icon.Clock color={color.pigeon} />
          {search}
        </TextRow>
      ))}
      <ResultHeading>Recent meetings</ResultHeading>
      {recentRecordings.length === 0 && (
        <TextRow css={[color.fg.pigeon]}>No recent meetings</TextRow>
      )}
      {recentRecordings.map(recentRecording => (
        <TextRow
          key={recentRecording.id}
          as='button'
          onClick={() => navigate(recentRecording.recordingPath)}
        >
          <RecentRecording recording={recentRecording} />
        </TextRow>
      ))}
    </div>
  );
};
