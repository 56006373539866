import React from 'react';
import { Allotment } from 'allotment';
import Button from '@grain/components/Button';
import { useParams, useNavigate } from 'react-router-dom';
import * as Layout from '~/components/Layout';
import useUnmount from 'react-use/lib/useUnmount';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import { useQuery } from '@grain/api/graphql';
import NotFound from '~/modals/NotFound';
import Tooltip from '@grain/components/Tooltip';
import { useRouteModal, Icon, useShowToast, color } from '@grain/grain-ui';
import Start from '~/pages/StoryPage/Ftux/Start';
import { storyQuery } from '~/modules/stories/graphql';
import { useStoryToast } from './hooks';
import { media, spacing } from '@grain/styles/constants';
import { useRecoilState } from 'recoil';
import { storyState, expandedStoryItemIdState } from './state';
import {
  StyledStoriesWrapper,
  StyledOutlineWrapper,
  StyledMobileButtonsContainer,
  StyledActionWrapper
} from './styles';
import { Helmet } from 'react-helmet';
import { cacheDeleteStory } from '@grain/api/graphql/cache-helpers';
import { useApolloClient } from '@apollo/client';
import { useMediaQuery } from '@grain/components/support/browser';
import { SHARE_STORY_ID } from '@grain/components/modals/constants';
import { useHotspots } from '~/components/Hotspot';
import StoryOutline from './Outline';
import StoryDocument from './Document';
import ShareStoryPermissions from '~/modals/SharePermissions/ShareStory';
import { ClipProcessingState, StoryClipV2 } from '@grain/api/schema.generated';
import {
  StyledToastContent,
  StyledLoadingIconWrapper
} from 'lib/upload/useUploadToast';
import {
  useStoryRemovedSubscription,
  useStoryUpdatedSubscription
} from '~/modules/stories/graphql.generated';

export default function StoryPage() {
  const [story, setStory] = useRecoilState(storyState);
  const [, setExpandedStoryItemId] = useRecoilState(expandedStoryItemIdState);
  const { storyId } = useParams();
  const showToast = useStoryToast();
  const showProcessingToast = useShowToast();
  const navigate = useNavigate();
  const client = useApolloClient();
  const isMobile = useMediaQuery(media.small);
  const { isOpen: shareStoryIsOpen, open: openShare } =
    useRouteModal(SHARE_STORY_ID);
  const [hotspotRef] = useHotspots('story-ftux', 'watch-story', {
    top: 16,
    left: 24,
    skip: Boolean(!story)
  });

  const [defaultVerticalSplit, setDefaultVerticalSplit] = useLocalStorage(
    'story_page_vertical_split',
    [300, 600]
  );

  const { data: storyData, error } = useQuery(storyQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      storyId
    }
  });

  const clipsProcessing = storyData?.story?.items?.filter(
    (item: StoryClipV2) => item.clip?.state === ClipProcessingState.Processing
  ).length;

  React.useEffect(() => {
    let clearToast = null;

    if (clipsProcessing > 0) {
      clearToast = showProcessingToast({
        isStatic: true,
        uniqueId: `processing-clips-${storyId}`,
        content: (
          <StyledToastContent>
            <StyledLoadingIconWrapper>
              <Icon.Loading style={{ color: color.graieen }} />
            </StyledLoadingIconWrapper>{' '}
            Processing {clipsProcessing}{' '}
            {clipsProcessing > 1 ? 'clips' : 'clip'}
          </StyledToastContent>
        ),
        durationMs: 0
      });
    }

    return () => {
      if (clearToast) clearToast();
    };
  }, [clipsProcessing, showProcessingToast, storyId]);

  useStoryUpdatedSubscription({
    skip: Boolean(!story || !storyId),
    variables: { storyId: storyId! },
    onData({ data }) {
      setStory(data?.data?.storyUpdated);
    }
  });

  useStoryRemovedSubscription({
    skip: Boolean(!storyId),
    variables: { storyId: storyId! },
    onData() {
      navigate('/app/stories', { replace: true });
      cacheDeleteStory(client, { storyId: storyId! });
      showToast({
        content: 'The story has been deleted'
      });
    }
  });

  React.useEffect(() => {
    if (storyData?.story) setStory(storyData.story);
  }, [setStory, storyData]);

  useUnmount(() => {
    setStory(null);
    setExpandedStoryItemId(null);
  });

  const hasAtLeastOneClip = story?.items?.some(
    (item: StoryClipV2) => !!item.clip
  );
  const tooltipContent = hasAtLeastOneClip
    ? null
    : 'Add at least one Clip to share or play';

  if (error?.message === 'GraphQL error: not_found') {
    return <NotFound title='Story Not Found' />;
  }

  return (
    <>
      <Helmet title={story?.title} />
      <Layout.Wrapper withSidebar>
        {!!story && (
          <>
            {isMobile && (
              <StyledMobileButtonsContainer>
                <Button
                  disabled={!hasAtLeastOneClip}
                  css={[spacing.mr3]}
                  onClick={openShare}
                >
                  Share
                </Button>
                {/* NOTE cannot use button as anchor tag else `disabled` prop doesn't work, so wrap with anchor tags */}
                <a href={story.publicUrl}>
                  <Button
                    data-cy='story-play-toggle'
                    type='primary'
                    disabled={!hasAtLeastOneClip}
                    css={[!hasAtLeastOneClip && spacing.mr2]}
                  >
                    Play
                  </Button>
                </a>
                {!hasAtLeastOneClip && (
                  <Tooltip
                    content={tooltipContent}
                    trigger='click'
                    placement='bottom'
                  >
                    <div css={['display: flex; align-items: center;']}>
                      <Icon.Information css={[color.errorbird]} />
                    </div>
                  </Tooltip>
                )}
              </StyledMobileButtonsContainer>
            )}
            <StyledStoriesWrapper>
              <Allotment
                minSize={200}
                defaultSizes={defaultVerticalSplit}
                onChange={setDefaultVerticalSplit}
              >
                {!isMobile && (
                  <div css={['height: 100%; width: 100%;']}>
                    <Allotment.Pane css={{ height: '100%' }}>
                      <StyledOutlineWrapper>
                        <div className='inner-wrapper'>
                          <StoryOutline />
                        </div>
                      </StyledOutlineWrapper>
                    </Allotment.Pane>
                  </div>
                )}
                <Allotment.Pane minSize={400}>
                  <div
                    id='story-scroller'
                    css={['width: 100%; height: 100%; overflow-y: auto;']}
                  >
                    <StoryDocument css={['flex: 0 1 664px;']} />
                  </div>
                </Allotment.Pane>
              </Allotment>
              {!isMobile && (
                <StyledActionWrapper css={[spacing.ml6, 'flex: 1;']}>
                  <Tooltip content={tooltipContent} placement='bottom'>
                    {/* NOTE cannot use button as anchor tag else `disabled` prop doesn't work, so wrap with anchor tags */}
                    <a
                      href={story.publicUrl}
                      css={[spacing.mr4, 'height: 32px;']}
                    >
                      <Button
                        data-cy='story-play'
                        disabled={!hasAtLeastOneClip}
                        ref={hotspotRef}
                        type='primary'
                      >
                        Play
                      </Button>
                    </a>
                  </Tooltip>
                  <Tooltip content={tooltipContent} placement='bottom'>
                    <div css={['height: 32px;']}>
                      <Button disabled={!hasAtLeastOneClip} onClick={openShare}>
                        Share
                      </Button>
                    </div>
                  </Tooltip>
                </StyledActionWrapper>
              )}
            </StyledStoriesWrapper>
          </>
        )}
        {shareStoryIsOpen && <ShareStoryPermissions story={story} />}
        <Start />
      </Layout.Wrapper>
    </>
  );
}
