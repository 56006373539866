import { useMemo } from 'react';
import styled from '@emotion/styled';

import { DealMomentumState } from '@grain/api/schema.generated';
import { color, Icon } from '@grain/grain-ui';

import type { Deal, MomentumInfo } from '../types';
import { getLastDates, formatDateString } from '../utils';

type BarMap = {
  [key: string]: { incoming: number; outgoing: number };
};

const MAX_ACTIVITY_UNITS = 5;
const ONE_UNIT_OF_ACTIVITY_HEIGHT = 2;
const MIN_ACTIVITY_BAR_HEIGHT = 2;

const activityToHeight = (activity: number) => {
  return (
    Math.min(activity, MAX_ACTIVITY_UNITS) * ONE_UNIT_OF_ACTIVITY_HEIGHT +
    MIN_ACTIVITY_BAR_HEIGHT
  );
};

export const toMomentum = (deal: Deal): MomentumInfo => {
  let icon, colorOne, colorTwo;

  const iconProps = { height: '16px', width: '16px' };

  switch (deal?.latestMomentum) {
    case DealMomentumState.Progressing:
      icon = <Icon.ArrowNarrowUpRight {...iconProps} />;
      colorOne = color.brandGreenDark;
      colorTwo = color.highlightGreenDark;

      break;
    case DealMomentumState.Slowing:
      icon = <Icon.ArrowNarrowRight {...iconProps} />;
      colorOne = color.oriole;
      colorTwo = color.oriole;
      break;
    case DealMomentumState.Stalled:
      icon = <Icon.ArrowNarrowDownRight {...iconProps} />;
      colorOne = color.errorbird;
      colorTwo = color.errorbird;
      break;
  }

  if (!deal.isOpen) {
    colorOne = color.pigeon;
    colorTwo = color.hawk;
  }

  return { icon, colorOne, colorTwo };
};

const StyledMomentumTimeline = styled.div`
  display: flex;
  gap: 2px;
`;

const StyledMomentumBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledMomentumBarItem = styled.div`
  height: 50%;
  display: flex;
`;

type BarProps = { color: string; height: number; hasOpacity?: boolean };

const StyledMomentumBarT = styled.div<BarProps>`
  background: ${({ color }) => color};
  height: ${({ height }) => height}px;
  width: 3.5px;
  border-radius: 2px 2px 0 0;
`;

const StyledMomentumBarB = styled.div<BarProps>`
  background: ${({ color }) => color};
  height: ${({ height }) => height}px;
  ${({ hasOpacity }) => hasOpacity && 'opacity: 0.4;'}
  width: 3.5px;
  border-radius: 0 0 2px 2px;
`;

type MomentumTimelineProps = {
  activityBar?: Deal['activityBar'];
  momentumInfo: MomentumInfo;
  momentumUpdates: Deal['momentumUpdates'];
  latestMomentum: Deal['latestMomentum'];
};

const isSameOrAfterLastMomentumUpdate = (
  currentDate: string,
  lastMomentumUpdateDate: string
) => {
  return new Date(currentDate) >= new Date(lastMomentumUpdateDate);
};

export function MomentumTimeline({
  activityBar,
  momentumUpdates,
  momentumInfo: mInfo,
  latestMomentum
}: MomentumTimelineProps) {
  const dates = useMemo(() => getLastDates(30), []);

  const lastMomentumUpdateDate = useMemo(() => {
    return (
      [...(momentumUpdates ?? [])]
        .reverse()
        .find(update => update.state !== latestMomentum)?.date ??
      momentumUpdates?.[0]?.date ??
      formatDateString(dates[0])
    );
  }, [momentumUpdates, latestMomentum, dates]);

  const barMap: BarMap = useMemo(
    () =>
      activityBar?.reduce((obj: BarMap, item) => {
        obj[item.date] = item;
        return obj;
      }, {}) ?? {},
    [activityBar]
  );

  return (
    <StyledMomentumTimeline aria-label='Momentum timeline showing incoming and outgoing activity over time'>
      {dates.map(date => {
        const dateString = formatDateString(date);
        const info = barMap[dateString];

        return (
          <StyledMomentumBar key={dateString}>
            <StyledMomentumBarItem css={['align-items: flex-end']}>
              <StyledMomentumBarT
                color={
                  isSameOrAfterLastMomentumUpdate(
                    dateString,
                    lastMomentumUpdateDate
                  )
                    ? mInfo.colorOne
                    : color.hawk
                }
                height={activityToHeight(info?.outgoing ?? 0)}
              />
            </StyledMomentumBarItem>
            <StyledMomentumBarItem css={['align-items: flex-start']}>
              <StyledMomentumBarB
                color={
                  isSameOrAfterLastMomentumUpdate(
                    dateString,
                    lastMomentumUpdateDate
                  )
                    ? mInfo.colorTwo
                    : color.hawk
                }
                height={activityToHeight(info?.incoming ?? 0)}
                hasOpacity={!!info}
              />
            </StyledMomentumBarItem>
          </StyledMomentumBar>
        );
      })}
    </StyledMomentumTimeline>
  );
}
