import cx from 'classnames';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { getBasePublicPath } from '@grain/grain-ui';

import type { Slide } from '../types';
import { NOOP_EXIT_FUNCTION } from '../constants';

const BOT_SLIDE_WIDTH = 1140;
const BOT_SLIDE_HEIGHT = 628;

const BotSlideGraphicContainer = styled.div`
  display: flex; // Fix height
  position: relative;
  overflow: hidden;
  width: 100%;

  .background-image {
    width: 100%;
    height: auto;
    aspect-ratio: ${BOT_SLIDE_WIDTH} / ${BOT_SLIDE_HEIGHT};
  }

  .user-1 {
    position: absolute;
    top: ${(20 / BOT_SLIDE_HEIGHT) * 100}%;
    left: ${(130 / BOT_SLIDE_WIDTH) * 100}%;
    width: ${(434 / BOT_SLIDE_WIDTH) * 100}%;

    opacity: 1;
    transition: opacity linear 0.5s;
    &.dimmed {
      opacity: 0.4;
    }
  }

  .user-2 {
    position: absolute;
    top: ${(20 / BOT_SLIDE_HEIGHT) * 100}%;
    right: ${(130 / BOT_SLIDE_WIDTH) * 100}%;
    width: ${(434 / BOT_SLIDE_WIDTH) * 100}%;

    opacity: 1;
    transition: opacity linear 0.5s;
    &.dimmed {
      opacity: 0.4;
    }
  }

  .user-3 {
    position: absolute;
    top: ${(278 / BOT_SLIDE_HEIGHT) * 100}%;
    right: ${(130 / BOT_SLIDE_WIDTH) * 100}%;
    width: ${(434 / BOT_SLIDE_WIDTH) * 100}%;

    opacity: 1;
    transform: translateX(0);
    transition:
      opacity linear 0.5s,
      transform linear 0.5s;
    &.dimmed {
      opacity: 0.4;
    }
    &.centered {
      transform: translateX(-50%);
    }
  }

  .bot {
    position: absolute;
    top: ${(264 / BOT_SLIDE_HEIGHT) * 100}%;
    left: ${(111 / BOT_SLIDE_WIDTH) * 100}%;
    width: ${(471 / BOT_SLIDE_WIDTH) * 100}%;

    opacity: 1;
    transition: opacity linear 0.5s;
    &.hidden {
      opacity: 0;
    }
  }
`;

export const BotSlide: Slide = {
  question: 'Why does the Grain bot join as a participant?',
  shortQuestion: 'Why does the Grain bot join?',
  answer:
    'The bot records video, detects speakers, and signals that the meeting is being recorded.',
  Graphic({ transitionDelay = 0, exitFunctionRef }) {
    const [initialState, setInitialState] = useState(true);

    useEffect(() => {
      setTimeout(() => setInitialState(false), transitionDelay + 1000);
      // This effect should only run once, on the first render.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    exitFunctionRef.current = NOOP_EXIT_FUNCTION;

    return (
      <BotSlideGraphicContainer>
        <img
          className='background-image'
          alt='The user interface of a video conference app, it resembles Zoom'
          src={`${getBasePublicPath()}/images/ftux-slideshow/video-conference-client-background.jpg`}
        />
        <img
          className={cx('user-1', { dimmed: !initialState })}
          alt='Screen feed of the first user participating in the video conference; he is a man, and his feed is in the upper-left corner of the screen'
          src={`${getBasePublicPath()}/images/ftux-slideshow/video-conference-user-1.jpg`}
        />
        <img
          className={cx('user-2', { dimmed: !initialState })}
          alt='Screen feed of the second user participating in the video conference; she is a woman, and her feed is in the upper-right corner of the screen'
          src={`${getBasePublicPath()}/images/ftux-slideshow/video-conference-user-2.jpg`}
        />
        <img
          className={cx('user-3', {
            dimmed: !initialState,
            centered: initialState
          })}
          alt='Avatar of the third user participating in the video conference; he is a dog, and his avatar is in the bottom of the screen'
          src={`${getBasePublicPath()}/images/ftux-slideshow/video-conference-user-3.jpg`}
        />
        <img
          className={cx('bot', { hidden: initialState })}
          alt='Graphic displayed for a bot participating in the video conference; the graphic indicates that the bot is recording the call, using Grain to capture notes and next steps'
          src={`${getBasePublicPath()}/images/ftux-slideshow/video-conference-bot.svg`}
        />
      </BotSlideGraphicContainer>
    );
  }
};
