// @ts-strict-ignore
import React from 'react';
import { css } from '@emotion/react';
import { colors } from '@grain/styles/constants';

import { Wrapper, Header } from '~/components/Layout';
import LoadingCircle from '~/components/LoadingCircle';
import {
  getMetaContent,
  isFirefox,
  isSafari
} from '@grain/components/support/browser';
import {
  StyledPageContainer,
  StyledTitle,
  bodyStyles,
  dividerStyles,
  noticeStyles
} from './styles';

const redirectAction = getMetaContent('grain:native:action');
const nativeUrl = getMetaContent('grain:native:url') || '';
const downloadUrl = getMetaContent('grain:download:url');

export default function PostLoginPage() {
  const [isAnimated, setIsAnimated] = React.useState(true);

  React.useEffect(() => {
    if (nativeUrl) {
      // Why delay opening the native app?
      // - Safari: The native "Open app?" dialog covers up the content.
      //   Delay to ensure user has had time to read the content.
      const delay = isSafari ? 1500 : 0;

      setTimeout(() => {
        if (!isFirefox) {
          window.location.href = nativeUrl;
        } else {
          // Firefox will abort open sockets/requests if you don't open the link from an iframe.
          const frame = document.createElement('iframe');
          frame.src = nativeUrl;
          frame.style.display = 'none';
          document.body.appendChild(frame);
        }
      }, delay);
    }
  }, []);

  setTimeout(() => {
    setIsAnimated(false);
  }, 5000);

  return (
    <Wrapper>
      <Header withLogo centered />
      <StyledPageContainer>
        <LoadingCircle
          background='white'
          color={colors.graieen}
          size={24}
          css={!isAnimated && css('visibility: hidden;')}
          weight={2}
        />
        <StyledTitle redirectAction={redirectAction}>
          {redirectAction === 'login'
            ? 'Signing into Grain...'
            : 'Opening the Grain app...'}
        </StyledTitle>
        <div css={bodyStyles}>You&apos;ll be redirected in a moment.</div>
        <div css={dividerStyles} />
        <div css={noticeStyles}>
          If the Grain app doesn&apos;t open,{' '}
          <a href='javascript:window.location.reload(true)'>try again</a> or{' '}
          <a href={downloadUrl}>download and run Grain.</a>
        </div>
      </StyledPageContainer>
    </Wrapper>
  );
}
