import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { pxToRem, color } from '@grain/grain-ui';

export const StyledCompleteContainer = styled.div`
  margin-top: 40px;
  opacity: 0;
  animation: ${keyframes`
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    75% {
      opacity: 1;
      transform: scale(1.1);
    }
100% {
  opacity: 1;
  transform: scale(1.0);
}
  `} 0.8s 0.25s ease both;
`;

export const StyledConfirmationHeader = styled.div`
  ${color.fg.blackbird};
  text-align: center;
  font-feature-settings:
    'cpsp' on,
    'case' on;
  font-size: ${pxToRem(20)};
  font-style: normal;
  font-weight: 600;
  line-height: ${pxToRem(24)};

  span {
    position: absolute;
    cursor: pointer;
    right: 32px;
    top: 32px;
    width: 20px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${color.fg.pigeon};

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
