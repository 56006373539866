import { useMutation } from '@grain/api/graphql';
import { setSettingsMutation } from '@grain/api/graphql/mutations';
import { useWorkspace } from '@grain/components/Workspace/hooks';
import { useMyself } from '@grain/api/auth';
import { useActiveIntegrationsQuery } from '@grain/api/graphql/queries/integrations.generated';
import { useRouteModal } from '@grain/grain-ui';
import {
  useSetupExtra,
  useExtraPath,
  useExtra,
  ONBOARDING_STEP_IDS
} from '@grain/components/support/extra';
import {
  HUBSPOT_CONNECT_ROUTE_ID,
  TRIAL_ENDED_ROUTE_ID,
  TRIAL_STARTED_ROUTE_ID
} from '@grain/components/modals/constants';

export function useGlobalEffects() {
  useSetupExtra();
  useOnboardingCompleteCheck();
  useRecordFtuxBannerCheck();
  useHubspotReconnectCheck();
  useTrialEndedCheck();
  useTrialStartedCheck();
}

// Set myself.extra.onboardingComplete to true on all steps completed
function useOnboardingCompleteCheck() {
  const { myself } = useMyself();
  const [mutateSettings] = useMutation(setSettingsMutation);
  const [{ value: completedOnboardingSteps, loading: extraLoading }] =
    useExtraPath('hotspots.onboarding');

  const completedOnboardingStepsKey = (completedOnboardingSteps || [])
    .slice()
    .sort()
    .toString();

  const onboardingComplete = myself?.settings?.onboardingComplete;

  React.useEffect(() => {
    if (onboardingComplete || extraLoading) return;

    const allOnboardingStepsKey = ONBOARDING_STEP_IDS.slice().sort().toString();

    if (completedOnboardingStepsKey === allOnboardingStepsKey) {
      mutateSettings({
        variables: {
          settings: {
            onboardingComplete: true
          }
        }
      });
    }
  }, [
    extraLoading,
    onboardingComplete,
    completedOnboardingStepsKey,
    mutateSettings
  ]);
}

// Set extra.recordFtuxBannerClosed to true if user has recorded at least one video
function useRecordFtuxBannerCheck() {
  const { extra, saveExtra, loading: extraLoading } = useExtra();
  const { myself, loading: myselfLoading } = useMyself();

  const recordFtuxBannerClosed = extra?.recordFtuxBannerClosed;
  const recordingCount = myself?.recordingCount;
  React.useEffect(() => {
    if (
      recordFtuxBannerClosed ||
      extraLoading ||
      myselfLoading ||
      !recordingCount
    ) {
      return;
    }

    saveExtra({
      ...extra,
      recordFtuxBannerClosed: true
    });
  }, [
    recordFtuxBannerClosed,
    extraLoading,
    saveExtra,
    recordingCount,
    myselfLoading,
    extra
  ]);
}

export const useHubspotReconnectCheck = () => {
  const { myself } = useMyself();
  const { data: integrationsData, loading: integrationsLoading } =
    useActiveIntegrationsQuery();
  const { open: openHubspotModal } = useRouteModal(HUBSPOT_CONNECT_ROUTE_ID);
  // Prevents the modal being re-opened after closing it the first time
  const [hubspotOpened, setHubspotOpened] = React.useState(false);

  const shouldOpenHubspotModal = React.useMemo(() => {
    if (integrationsLoading) {
      return false;
    }
    if (myself?.extra?.hubspotPropertiesModalClosed) {
      return false;
    }

    const hubspotIntegration = integrationsData?.activeIntegrations?.find(
      integration => integration.__typename === 'HubspotIntegration'
    );

    return hubspotIntegration && !hubspotIntegration.canSyncProperties;
  }, [myself, integrationsLoading, integrationsData]);

  React.useEffect(() => {
    if (!shouldOpenHubspotModal || hubspotOpened) return;
    setHubspotOpened(true);
    openHubspotModal();
  }, [
    shouldOpenHubspotModal,
    hubspotOpened,
    setHubspotOpened,
    openHubspotModal
  ]);
};

export const useTrialEndedCheck = () => {
  const { workspace } = useWorkspace();
  const { open: openTrialEndedModal } = useRouteModal(TRIAL_ENDED_ROUTE_ID);

  const { extra, saveExtra, loading: extraLoading } = useExtra();

  React.useEffect(() => {
    if (
      workspace?.workspaceSubscription &&
      workspace?.workspaceSubscription?.trialExpired &&
      !extraLoading &&
      !extra?.trialEndedModalShown
    ) {
      saveExtra({ ...extra, trialEndedModalShown: true });
      openTrialEndedModal();
    }
  }, [
    workspace?.workspaceSubscription,
    openTrialEndedModal,
    extraLoading,
    extra,
    saveExtra
  ]);
};

export const useTrialStartedCheck = () => {
  const { workspace } = useWorkspace();
  const { open: openTrialStartedModal } = useRouteModal(TRIAL_STARTED_ROUTE_ID);

  const { extra, saveExtra, loading: extraLoading } = useExtra();

  React.useEffect(() => {
    if (
      workspace?.workspaceSubscription?.trial &&
      !extraLoading &&
      !extra?.trialStartedModalShown &&
      !extra?.requestedToJoin
    ) {
      saveExtra({ ...extra, trialStartedModalShown: true });
      openTrialStartedModal();
    }
  }, [
    workspace?.workspaceSubscription,
    openTrialStartedModal,
    extraLoading,
    extra,
    saveExtra
  ]);
};
