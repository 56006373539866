import styled from '@emotion/styled';
import { spacing, font, color } from '@grain/grain-ui';
import { theme } from '@grain/grain-ui/v4';

export const TextRow = styled.div`
  all: unset;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: stretch;
  align-items: center;
  height: 48px;
  ${theme.utils.px('lg')}
  ${font.v4.b3[500]}
  ${color.fg.blackbird}
  border-bottom: 1px solid ${color.gull};
  gap: 8px;

  @media (${theme.tokens.breakpoints.lgMin}) {
    ${spacing.px7}
  }

  &:is(button) {
    cursor: pointer;
    &:focus-visible {
      box-shadow: inset 0 0 0 3px ${color.highlightGreen};
    }
  }

  &:focus-within {
    background-color: ${color.goose};
  }

  &:is(button),
  &:has(a) {
    &:hover {
      background-color: ${color.goose};
    }
  }
`;
