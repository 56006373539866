import styled from '@emotion/styled';
import { Button, Checkbox, theme, useSilentMode } from '@grain/grain-ui/v4';
import { Wrapper, Header } from '~/components/Layout';
import desktopIcon from './desktop-icon.png';
import { useCallback } from 'react';
import { useExtra } from '@grain/components/support/extra';
import { useAnalytics, useShowToast } from '@grain/grain-ui';

type DesktopRedirectPageProps = {
  onOpenInBrowser: () => void;
};

const PageContainer = styled.div`
  margin: 30vh auto 10vh;
  max-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 30px;
`;

const Title = styled.div`
  ${theme.tokens.typography.d2}
  margin-bottom: 16px;
`;

const Body = styled.div`
  ${theme.tokens.typography.b1[400]};
  color: ${theme.tokens.color.textSecondary};
`;

const Actions = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 24px;
`;

const CheckboxLabel = styled.label`
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textSecondary};
  display: flex;
  align-items: center;
  gap: ${theme.tokens.spacing.xs};
`;

export default function DesktopRedirectPage({
  onOpenInBrowser
}: DesktopRedirectPageProps) {
  useSilentMode('desktop-redirect');
  const { extra, saveExtra } = useExtra();
  const showToast = useShowToast({ force: true });
  const { trackEvent } = useAnalytics();

  const handleAlwaysOpenInAppChange = useCallback(
    (alwaysOpenInApp: boolean) => {
      saveExtra({ ...extra, preferOpenInDesktopApp: alwaysOpenInApp });
      showToast({
        type: 'success',
        content: 'Preference updated'
      });
      trackEvent('Settings Updated', {
        setting_name: 'user_extra_preferOpenInDesktopApp',
        setting_new_value: alwaysOpenInApp,
        setting_old_value: !alwaysOpenInApp
      });
    },
    [saveExtra, extra, trackEvent, showToast]
  );

  return (
    <Wrapper>
      <Header withLogo centered />
      <PageContainer>
        <Icon src={desktopIcon} alt='Grain desktop icon' />
        <Title>Opening the Grain app&hellip;</Title>
        <Body>This is based on your chosen preference.</Body>
        <Actions>
          <Button variant='stroked' onClick={onOpenInBrowser}>
            Open in browser
          </Button>
          <CheckboxLabel>
            <Checkbox
              checked={Boolean(extra?.preferOpenInDesktopApp)}
              onChange={e => handleAlwaysOpenInAppChange(e.target.checked)}
            />
            Always open in app
          </CheckboxLabel>
        </Actions>
      </PageContainer>
    </Wrapper>
  );
}
