import styled from '@emotion/styled';
import { theme } from '@grain/grain-ui/v4';

const StyledModalInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing.md};
  padding: 40px 80px 16px;
  text-align: center;
  text-wrap: balance;
`;

const StyledPrelude = styled.div`
  ${theme.tokens.typography.b2[500]};
  color: ${theme.tokens.color.textBrand};
`;

const StyledTitle = styled.div`
  ${theme.tokens.typography.h2};
  color: ${theme.tokens.color.textPrimary};
`;

const StyledBody = styled.div`
  ${theme.tokens.typography.b3[400]};
  color: ${theme.tokens.color.textSecondary};
`;

type ModalInfoProps = {
  prelude: string;
  title: string;
  body?: React.ReactNode;
};

export function ModalInfo({ prelude, title, body }: ModalInfoProps) {
  return (
    <StyledModalInfo>
      <StyledPrelude>{prelude}</StyledPrelude>
      <StyledTitle>{title}</StyledTitle>
      {body && <StyledBody>{body}</StyledBody>}
    </StyledModalInfo>
  );
}

export const ModalGraphic = styled.div`
  padding: 20px 40px 40px;
`;

export const ModalComponent = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 40px 40px;
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${theme.tokens.color.borderSecondary};
  padding: ${theme.tokens.spacing['2xl']};
`;
