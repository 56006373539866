import { ButtonMetadata } from './ButtonMetadata';
import {
  TextLabel,
  Menu,
  MenuHeader,
  MenuButton,
  Icon16,
  Divider
} from '@grain/grain-ui/v4';
import { AdHocRecordingFragment } from '~/modules/contentFilter/contentfilter.generated';
import { LabelWithValue } from '../filtersV2/types';

type OwnerMetaItemProps = {
  owner: AdHocRecordingFragment['owner'];
  setOwnerFilter?: (owner: LabelWithValue) => void;
  stopClickPropagation?: boolean;
};

export const OwnerMetaItem = ({
  owner,
  setOwnerFilter,
  stopClickPropagation = false
}: OwnerMetaItemProps) => {
  return (
    <Menu
      hideOnClickInside
      width='264px'
      childWidth='auto'
      stopClickPropagation={stopClickPropagation}
      content={
        <>
          <MenuHeader
            avatarProps={{
              name: owner.name,
              url: owner.avatarUrl || ''
            }}
            metadata='Owner'
          >
            {owner.name}
          </MenuHeader>
          {setOwnerFilter && (
            <>
              <Divider />
              <MenuButton
                onClick={() => {
                  setOwnerFilter({
                    label: owner.name,
                    value: owner.id
                  });
                }}
                label='View Meetings'
                textLabelProps={{ startIcon: Icon16.Record }}
              />
            </>
          )}
        </>
      }
    >
      <ButtonMetadata width='lg'>
        <TextLabel
          avatarProps={{
            name: owner.name,
            url: owner.avatarUrl || '',
            color: owner.hexColor
          }}
          size='sm'
        >
          {owner.name}
        </TextLabel>
      </ButtonMetadata>
    </Menu>
  );
};
