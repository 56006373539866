import { Icon, Tooltip } from '@grain/grain-ui';
import { ActionBar, Button } from '@grain/grain-ui/v4';
import pluralize from 'pluralize';
import { PlaylistButton } from './PlaylistButton';
import { TagsButton } from './TagsButton';
import styled from '@emotion/styled';

type MeetingsActionBarProps = {
  selectedMeetingIds: string[];
  reset: (closeActionBar?: boolean) => void;
  canDelete: boolean;

  onDelete: () => void;
};

const FixedContainer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  bottom: 36px;
  z-index: 1;
`;

export const MeetingActionBar = ({
  onDelete,
  selectedMeetingIds,
  reset,
  canDelete
}: MeetingsActionBarProps) => {
  return (
    <FixedContainer>
      <ActionBar
        size='lg'
        textLabel={{
          startIcon: Icon.Record16x16,
          children: `${selectedMeetingIds.length} ${pluralize('Meeting', selectedMeetingIds.length)} Selected`
        }}
      >
        <PlaylistButton selectedMeetingIds={selectedMeetingIds} reset={reset} />

        <TagsButton selectedMeetingIds={selectedMeetingIds} />

        <Tooltip
          content='You’ve selected a recording you don’t own.'
          disabled={canDelete}
        >
          <ActionBar.Button
            disabled={!canDelete || selectedMeetingIds.length === 0}
            icon={Icon.Delete}
            onClick={onDelete}
          />
        </Tooltip>
        <Button variant='neutral' size='lg' onClick={() => reset()}>
          Done
        </Button>
      </ActionBar>
    </FixedContainer>
  );
};
