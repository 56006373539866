import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef
} from 'react';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import useSessionStorage from 'react-use/lib/useSessionStorage';
import { atom } from 'recoil';

import {
  SearchResultsV2Query,
  SearchViewAdHocQuery,
  useGetTagByLabelLazyQuery,
  useSearchResultsV2Query,
  useSearchSuggestionsQuery,
  useSearchViewAdHocQuery
} from './searchQuery.generated';
import { useAnalytics, useQueryParam } from '@grain/grain-ui';
import {
  HitType,
  QuoteHit,
  SearchSortBy,
  ViewAdHocSortBy
} from '@grain/api/schema.generated';
import { TAG_REGEX } from '@grain/components/Highlight';
import { uniqBy } from 'lodash';
import { MEETING_TYPE_OPTIONS } from '~/modules/contentFilter/useFilters';
import { useFiltersManager } from '~/modules/filtersV2/useFiltersManager';
import { CoreFilter, FilterName } from '~/modules/filtersV2/types';
import {
  useAllFilters,
  UseAllFiltersProps
} from '~/modules/filtersV2/filters/useAllFilters';

const MAX_SEARCH_RESULTS = 2000;
const RECENT_SEARCH_LIMIT = 20;

export type Suggestion = {
  filterKey: SuggestionFilterKey;
  filterLabel: string;
  filterRegex?: RegExp;
  valueLabel: string;
  valueId: string;
};

const getFirst = <T,>(val: T | T[]): T => (Array.isArray(val) ? val[0] : val);

export function useSearchString() {
  const [val, setVal] = useQueryParam('q');

  return [getFirst(val) ?? '', setVal] as const;
}

export function useSearchTranscriptsFlag() {
  const [storageFlag, setStorageFlag] = useSessionStorage(
    'search_setting_transcript',
    true
  );
  const [queryFlag, innerSetQueryFlag] = useQueryParam('t', {
    defaultValue: (!!storageFlag).toString()
  });

  const enabled = queryFlag === 'true';

  React.useEffect(() => {
    setStorageFlag(enabled);
  }, [enabled, setStorageFlag]);

  const setQueryAsBoolean = useCallback(
    (val: boolean | string) => {
      innerSetQueryFlag(val.toString());
    },
    [innerSetQueryFlag]
  );

  return [enabled || false, setQueryAsBoolean] as const;
}

const transformViewAdHocToSearchResultsAdHoc = (
  data: SearchViewAdHocQuery['viewAdHoc']['results'][number]
): SearchResultsV2Query['searchResultsV2']['results'][number] | null => {
  if (data.__typename !== 'Recording') {
    return null;
  }
  return {
    recordingId: data.id,
    recording: data,
    matchText: data.title,
    score: null,
    hits: []
  };
};

const applySmartTopicTextHighlight = (
  smartTopicText: string,
  searchQuery?: string
) => {
  const words = searchQuery?.toLowerCase().split(/\s+/).filter(Boolean) ?? [];

  const parts = smartTopicText.split(/(\s+)/);

  return parts
    .map(part => {
      if (part.trim() === '') {
        return part;
      }

      const lowerPart = part.toLowerCase();
      if (words.some(word => lowerPart.includes(word))) {
        return `<>${part}</>`;
      }

      const tagMatch = RegExp(TAG_REGEX).exec(part);
      if (tagMatch) {
        return `<>${part}</>`;
      }

      return part;
    })
    .join('');
};

export const getFilterLabel = (filterKey: string) => {
  if (!filterKey) {
    return '';
  }

  switch (filterKey) {
    case 'date':
      return 'Date';
    case 'groups':
      return 'Company';
    case 'owner':
      return 'Owner';
    case 'persons':
      return 'Participants';
    case 'smart_tags':
      return 'Trackers';
    case 'tags':
      return 'Tags';
    case 'collections':
      return 'Playlist';
    case 'participant_scope':
      return 'Meeting type';
    default:
      return filterKey;
  }
};

const getNormalizedActiveFilterValues = (filter: CoreFilter) => {
  if (filter.normalizedValue) {
    return filter.normalizedValue;
  }
  return null;
};

const isNotNull = <T,>(val: T): val is NonNullable<T> => val !== null;

export type NormalizedActiveFilter = {
  filterKey: SuggestionFilterKey;
  filterLabel: string;
  values: { label: string; value: unknown }[];
};

export const SEARCH_FILTERS_ORDER: FilterName[] = [
  'owners',
  'persons',
  'groups',
  'smart_tags',
  'tags',
  'date',
  'collections',
  'participant_scope'
];

const getNormalizedActiveFilters = (
  activeFilters: Partial<FiltersMap>
): NormalizedActiveFilter[] => {
  const activeFiltersArray = Object.values(activeFilters);
  if (!activeFiltersArray || !activeFiltersArray.length) {
    return [];
  }
  return activeFiltersArray
    .map(filter => {
      if (!filter || !SEARCH_FILTERS_ORDER.includes(filter.id)) {
        return null;
      }
      return {
        filterKey: filter.id,
        filterLabel: filter.label,
        values: getNormalizedActiveFilterValues(filter)
      };
    })
    .filter(isNotNull)
    .sort((a, b) => {
      return (
        SEARCH_FILTERS_ORDER.indexOf(a.filterKey) -
        SEARCH_FILTERS_ORDER.indexOf(b.filterKey)
      );
    }) as NormalizedActiveFilter[];
};

const convertSearchSortToViewAdHocSort = (
  sortBy: SearchSortBy
): ViewAdHocSortBy => {
  switch (sortBy) {
    case SearchSortBy.Relevancy:
      return ViewAdHocSortBy.Relevance;
    case SearchSortBy.StartedAt:
      return ViewAdHocSortBy.Chronological;
    default:
      return ViewAdHocSortBy.Relevance;
  }
};

const PAGE_SIZE = 20;

type FiltersMap = ReturnType<typeof useAllFilters>;

export function useSearchResultsWithFilters() {
  // URL states
  const [queryString, setQueryString] = useSearchString();
  const [includeTranscripts] = useSearchTranscriptsFlag();
  const { sortBy, setSortBy } = useSearchSortBy();
  const { trackEvent } = useAnalytics();

  // This is used to prevent the search from running before we parse the query string
  // into proper filters, like #tag converts into tag id (currently only tags are supported).
  const [isReadyToSearch, setIsReadyToSearch] = useState<boolean>(false);

  const [initialValues, setInitialValues] = useState<
    UseAllFiltersProps['initialValues']
  >({});

  const [fetchTag] = useGetTagByLabelLazyQuery();

  useEffect(() => {
    // If no query string, or query string doesn't start with #, we're ready to search
    if (!queryString?.startsWith('#')) {
      setIsReadyToSearch(true);
      return;
    }

    const tagLabel = queryString.slice(1);

    // Clear the query string since we will pass it as a proper tag filter
    setQueryString('');

    fetchTag({
      variables: { label: tagLabel },
      onCompleted: data => {
        const tag = data.tagSearch[0]?.tag;
        if (!tag) {
          return;
        }

        setInitialValues(prevValues => ({
          ...prevValues,
          tags: [{ label: tag.label, value: tag.id }]
        }));
        setIsReadyToSearch(true);
      }
    });
  }, [queryString, setInitialValues, fetchTag, setQueryString]);

  const { activeFilters, filters, resetFilters, viewAdHocFilterString } =
    useFiltersManager({
      availableFilters: SEARCH_FILTERS_ORDER,
      initialValues
    });

  // Tracking active filters
  const activeFiltersKeys = useMemo(
    () => Reflect.ownKeys(activeFilters ?? {}),
    [activeFilters]
  );
  useEffect(() => {
    if (activeFiltersKeys.length) {
      trackEvent('Filter Used', {
        content_type: 'search',
        filter_by: activeFiltersKeys
      });
    }
    // Passing a stringified array of active filters to dependency array
    // prevents the useEffect from running on if a filter value changes,
    // it will only run when a filter key changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFiltersKeys.join(','), trackEvent]);

  // Using filters without a query string should trigger the searchViewAdHoc query, instead of the searchResultsAdHoc query
  const shouldUseSearchViewAdHoc = !queryString && activeFiltersKeys.length > 0;

  // used when no query string is available and filters are applied
  const {
    data: searchResultsWithoutQueryString,
    loading: searchingWithoutQueryString,
    fetchMore: fetchMoreWithoutQueryString
  } = useSearchViewAdHocQuery({
    variables: {
      filter: viewAdHocFilterString,
      limit: PAGE_SIZE,
      sortBy: convertSearchSortToViewAdHocSort(sortBy)
    },
    skip: Boolean(!shouldUseSearchViewAdHoc || !isReadyToSearch),
    fetchPolicy: 'cache-and-network'
  });

  // used when a query string is available
  const {
    data: searchResultsWithQueryString,
    error,
    fetchMore: fetchMoreWithQueryString,
    loading: searchingWithQueryString
  } = useSearchResultsV2Query({
    skip: Boolean(!queryString || !isReadyToSearch),
    variables: {
      queryString,
      sortBy,
      searchTranscripts: includeTranscripts,
      limit: PAGE_SIZE,
      offset: 0,
      filter: viewAdHocFilterString
    },
    fetchPolicy: 'cache-and-network'
  });

  // Callback to use for lazy loading
  const fetchMore = React.useCallback(() => {
    if (shouldUseSearchViewAdHoc) {
      if (
        !searchResultsWithoutQueryString?.viewAdHoc.cursor ||
        !searchResultsWithoutQueryString?.viewAdHoc.results.length ||
        searchResultsWithoutQueryString?.viewAdHoc.results.length >
          MAX_SEARCH_RESULTS
      ) {
        return Promise.resolve(null);
      }
      return fetchMoreWithoutQueryString({
        variables: {
          offset: searchResultsWithoutQueryString?.viewAdHoc.results.length,
          cursor: searchResultsWithoutQueryString?.viewAdHoc.cursor
        }
      });
    }
    if (
      !searchResultsWithQueryString?.searchResultsV2.results.length ||
      searchResultsWithQueryString?.searchResultsV2.results.length >
        MAX_SEARCH_RESULTS
    ) {
      return Promise.resolve(null);
    }

    return fetchMoreWithQueryString({
      variables: {
        offset: searchResultsWithQueryString?.searchResultsV2.results.length
      }
    });
  }, [
    shouldUseSearchViewAdHoc,
    searchResultsWithQueryString?.searchResultsV2.results.length,
    fetchMoreWithQueryString,
    searchResultsWithoutQueryString?.viewAdHoc.results.length,
    searchResultsWithoutQueryString?.viewAdHoc.cursor,
    fetchMoreWithoutQueryString
  ]);

  // When smart topic filter is applied, we want to display the smart topic matches as search hits
  const smartTopicAsHitsIfApplicable = useCallback(
    (
      resultsRaw: SearchResultsV2Query['searchResultsV2']['results'] | null
    ): SearchResultsV2Query['searchResultsV2']['results'] | null => {
      if (!resultsRaw) {
        return null;
      }

      const smartTopicFilterValues = filters.smart_tags?.value?.map(
        smartTagAppliedFilter => smartTagAppliedFilter.value
      );

      if (smartTopicFilterValues && smartTopicFilterValues.length > 0) {
        return resultsRaw.map(result => {
          const { intelligence } = result.recording;
          const smartTopicMatches =
            intelligence?.smartTopicIntelligence?.smartTopicMatches;

          if (smartTopicMatches) {
            const smartTopicAsHits: QuoteHit[] = smartTopicMatches.reduce(
              (acc, match) => {
                const isRelevantTag = match.richText.tags.some(tag =>
                  smartTopicFilterValues.includes(tag.id)
                );
                if (isRelevantTag) {
                  acc.push({
                    matchText: applySmartTopicTextHighlight(
                      match.richText.formattedText,
                      queryString
                    ),
                    timestamp: match.startMs,
                    relativeTimestamp: 0, // not used.
                    type: HitType.Quote
                  });
                }
                return acc;
              },
              [] as QuoteHit[]
            );

            return {
              ...result,
              hits: uniqBy(
                [...smartTopicAsHits, ...result.hits],
                hit => hit.timestamp
              ).sort((a, b) => a.timestamp - b.timestamp)
            };
          }
          return result;
        });
      }
      return resultsRaw;
    },
    [filters.smart_tags?.value, queryString]
  );

  const results = useMemo(() => {
    if (shouldUseSearchViewAdHoc && searchResultsWithoutQueryString) {
      return {
        results: smartTopicAsHitsIfApplicable(
          searchResultsWithoutQueryString?.viewAdHoc.results
            .map(transformViewAdHocToSearchResultsAdHoc)
            .filter(isNotNull) ?? null
        ),
        totalCount: searchResultsWithoutQueryString?.totalCount
      };
    }
    return {
      results: smartTopicAsHitsIfApplicable(
        searchResultsWithQueryString?.searchResultsV2.results ?? null
      ),
      totalCount: searchResultsWithQueryString?.searchResultsV2.totalCount
    };
  }, [
    smartTopicAsHitsIfApplicable,
    searchResultsWithoutQueryString,
    shouldUseSearchViewAdHoc,
    searchResultsWithQueryString
  ]);

  const removeFilterByName = useCallback(
    (filterName: keyof typeof filters) => {
      filters[filterName].reset();
    },
    [filters]
  );

  return React.useMemo(() => {
    return {
      results: results.results,
      totalCount: results.totalCount,
      error,
      searching: shouldUseSearchViewAdHoc
        ? searchingWithoutQueryString
        : searchingWithQueryString,
      fetchMore,
      activeFilters,
      normalizedActiveFilters: getNormalizedActiveFilters(activeFilters),
      removeFilterByName,
      filtersList: SEARCH_FILTERS_ORDER,
      filters,
      resetFilters,
      filterJsonAsString: viewAdHocFilterString,
      sortBy,
      setSortBy
    };
  }, [
    results.results,
    results.totalCount,
    error,
    shouldUseSearchViewAdHoc,
    searchingWithoutQueryString,
    searchingWithQueryString,
    fetchMore,
    activeFilters,
    removeFilterByName,
    filters,
    resetFilters,
    viewAdHocFilterString,
    sortBy,
    setSortBy
  ]);
}

export function useRecentSearches(recentSearchLimit = RECENT_SEARCH_LIMIT) {
  const [recentSearches, setRecentSearches] = useLocalStorage<string[]>(
    'search-recents',
    []
  );

  const addRecentSearch = React.useCallback(
    (searchString: string) => {
      if (searchString === '') {
        return;
      }

      const filteredRecentSearches = (recentSearches ?? []).filter(
        el => el !== searchString
      );
      const newRecentSearches = [searchString, ...filteredRecentSearches];
      setRecentSearches(newRecentSearches.slice(0, recentSearchLimit));
    },
    [recentSearches, setRecentSearches, recentSearchLimit]
  );

  return React.useMemo(() => {
    return {
      data: {
        recentSearches: recentSearches?.slice(0, recentSearchLimit)
      },
      loading: !recentSearches,
      addRecentSearch
    };
  }, [recentSearches, addRecentSearch, recentSearchLimit]);
}

export function useSearchSortBy() {
  const [sortBy, setSortBy] = useLocalStorage<SearchSortBy>(
    'search-sort-by',
    SearchSortBy.Relevancy
  );

  return {
    sortBy: sortBy ?? SearchSortBy.Relevancy,
    setSortBy
  };
}

export const searchInputValueState = atom({
  key: 'searchInputValueState',
  default: ''
});

type Screen = 'recent_items' | 'searching' | 'results' | 'no_results';

export const searchScreenState = atom<Screen>({
  key: 'searchScreenState',
  default: 'recent_items'
});

const EMPTY_ARRAY: never[] = [];

type SuggestionFilterKey =
  | 'date'
  | 'groups'
  | 'owner'
  | 'persons'
  | 'smart_tags'
  | 'tags'
  | 'collections'
  | 'participant_scope';

type SuggestionItem = {
  label: string;
  id: string;
};

type SuggestionFilterMatch = {
  test: RegExp;
  filterKeys?: SuggestionFilterKey[];
  exclude?: RegExp;
  default?: boolean;
};

// Matches filter keywords (e.g., owner, participants, tags) with optional plural forms, colon, and space
export const SUGGESTIONS_FILTER_MATCHES: SuggestionFilterMatch[] = [
  { test: /owner:(\s*)(.*)/i, filterKeys: ['owner', 'persons'] },
  { test: /participants?:(\s*)(.*)/i, filterKeys: ['persons', 'owner'] },
  { test: /compan(?:y|ies):(\s*)(.*)/i, filterKeys: ['groups'] },
  { test: /smart[\s]tags?:(\s*)(.*)/i, filterKeys: ['smart_tags', 'tags'] },
  {
    test: /tags?:(\s*)(.*)/i,
    filterKeys: ['tags', 'smart_tags'],
    exclude: /smart\s+tags?:/i
  },
  { test: /playlists?:(\s*)(.*)/i, filterKeys: ['collections'] },
  { test: /(?:meeting\s+)?type:(\s*)(.*)/i, filterKeys: ['participant_scope'] },
  { test: /(?:^|\s)@(\s*)(.*)/i, filterKeys: ['persons', 'owner'] }, // Match words starting with @ ensuring it's not preceded by a character
  { test: /#(\s*)(.*)/i, filterKeys: ['tags', 'smart_tags'] }, // Match words starting with #
  {
    test: /(?:^|\s)([^\s]+)(?:\s*)$/i,
    default: true,
    filterKeys: [
      'groups',
      'persons',
      'tags',
      'smart_tags',
      'collections',
      'participant_scope',
      'owner'
    ]
  } // Match the last word (or previous word if ending with space)
];

export const getFilterMatch = (
  query: string
): {
  filterKeys?: SuggestionFilterKey[];
  searchString: string;
  filterRegex: RegExp;
  default?: boolean;
} | null => {
  const matchedFilter = SUGGESTIONS_FILTER_MATCHES.find(({ test, exclude }) => {
    if (exclude?.exec(query)) {
      return false;
    }
    return test.exec(query) !== null;
  });

  if (!matchedFilter) {
    return null;
  }

  const match = matchedFilter.test.exec(query);

  const searchString = match![match!.length - 1].trim();

  return {
    filterKeys: matchedFilter.filterKeys,
    searchString,
    filterRegex: matchedFilter.test,
    default: matchedFilter.default
  };
};

const SUGGESTIONS_PER_FILTER_LIMIT = 2;

export const useSearchSuggestions = (
  query: string,
  filters: string,
  normalizedActiveFilters: NormalizedActiveFilter[]
) => {
  const debouncedSearchStringTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const [debouncedSearchString, setDebouncedSearchString] = useState('');

  const filterMatch = useMemo(() => getFilterMatch(query), [query]);

  useEffect(() => {
    debouncedSearchStringTimeoutId.current = setTimeout(() => {
      setDebouncedSearchString(filterMatch?.searchString ?? '');
    }, 250);

    return () => {
      if (debouncedSearchStringTimeoutId.current !== null) {
        clearTimeout(debouncedSearchStringTimeoutId.current);
      }
    };
  }, [filterMatch]);

  const filtersJson = useMemo(() => {
    return JSON.parse(filters) as {
      filters: { [key in SuggestionFilterKey]: string }[];
    };
  }, [filters]);

  const shouldIncludeCollection = useMemo(
    () => !filtersJson.filters.some(filter => 'collection' in filter),
    [filtersJson.filters]
  );

  const filtersCollection = useMemo(() => {
    return JSON.stringify({
      types: ['collections'],
      filters: [
        ...filtersJson.filters.filter(filter => {
          return ![
            'tags',
            'smart_tags',
            'collection',
            'participant_scope'
          ].includes(Reflect.ownKeys(filter)[0] as string);
        }),
        { title: debouncedSearchString }
      ]
    });
  }, [debouncedSearchString, filtersJson.filters]);

  const { data, loading } = useSearchSuggestionsQuery({
    skip: Boolean(!debouncedSearchString),
    variables: {
      query: debouncedSearchString,
      limit: SUGGESTIONS_PER_FILTER_LIMIT,
      filters,
      filtersCollection,
      includeCollection: shouldIncludeCollection
    }
  });

  const meetingTypeMatch = useMemo(() => {
    return Object.values(MEETING_TYPE_OPTIONS)
      .filter(option =>
        option.title.toLowerCase().includes(debouncedSearchString.toLowerCase())
      )
      .map(({ title, value }) => ({
        label: title,
        id: value
      }));
  }, [debouncedSearchString]);

  const suggestions: Suggestion[] = useMemo(() => {
    // Early return if data is not available
    if (!data) return EMPTY_ARRAY;

    // Mapping data to filterMap for easier access and manipulation
    const filterMap: Record<SuggestionFilterKey, SuggestionItem[] | undefined> =
      {
        owner: data.owners.users,
        persons: data.persons.persons,
        groups: data.groups.groups,
        tags: data.tags?.map(({ tag }) => tag),
        smart_tags: data.smartTopics?.map(({ tag }) => tag),
        collections: (data?.collection?.list ?? []) as SuggestionItem[],
        participant_scope: meetingTypeMatch,
        date: EMPTY_ARRAY
      };

    // Helper function to check if a suggestion is already applied
    const isSuggestionApplied = (
      valueId: string,
      filterKey: SuggestionFilterKey
    ) =>
      normalizedActiveFilters.some(
        filter =>
          filter.filterKey === filterKey &&
          filter.values.some(v => v.value === valueId)
      );

    // Flatten the filterMap to an array of suggestions
    const allSuggestions = Object.entries(filterMap).flatMap(
      ([filterName, items]) => {
        const filterKey = filterName as SuggestionFilterKey;
        return (items ?? []).map(({ label, id }) => ({
          filterKey,
          filterLabel: getFilterLabel(filterName),
          valueLabel: label,
          valueId: id,
          filterRegex: filterMatch?.filterRegex // Attach regex if available
        }));
      }
    );

    // Filter, remove duplicates, and sort suggestions based on the current filterMatch and active filters
    const uniqueSuggestions = new Map();
    return allSuggestions
      .filter(suggestion => {
        const key = `${suggestion.filterKey}-${suggestion.valueId}`;
        // Check if suggestion is already applied, matches filter keys, and is not a duplicate
        if (
          !isSuggestionApplied(suggestion.valueId, suggestion.filterKey) &&
          (filterMatch?.filterKeys?.includes(suggestion.filterKey) ?? true) &&
          !uniqueSuggestions.has(key)
        ) {
          uniqueSuggestions.set(key, true); // Mark this key as seen
          return true;
        }
        return false;
      })
      .sort((a, b) => {
        // Sort by filter key order if specified in filterMatch
        if (filterMatch?.filterKeys) {
          const aIndex = filterMatch.filterKeys.indexOf(a.filterKey);
          const bIndex = filterMatch.filterKeys.indexOf(b.filterKey);
          if (aIndex !== bIndex) return aIndex - bIndex;
        }
        // Fallback to sorting by value label
        return a.valueLabel.localeCompare(b.valueLabel);
      });
  }, [data, normalizedActiveFilters, filterMatch, meetingTypeMatch]);

  return { suggestions, hasUsedModifiers: !filterMatch?.default, loading };
};
