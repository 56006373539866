import { useCallback, useEffect, useRef, useState } from 'react';

const BOTTOM_THRESHOLD = 50;
const EMPTY_ARRAY: never[] = [];

/**
 * Hook to auto-scroll a container to the bottom when content changes.
 * Auto-scrolling is disabled when user scrolls up and can be re-enabled
 * through a button click or when user scrolls close to the bottom.
 */
export const useAutoScroll = <T extends HTMLElement>(
  dependencies: unknown[] = EMPTY_ARRAY,
  scrollThreshold = BOTTOM_THRESHOLD
) => {
  const scrollElementRef = useRef<T | null>(null);
  const [isReady, setIsReady] = useState(false);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);
  const [userHasScrolled, setUserHasScrolled] = useState(false);

  const setScrollElement = (element: T) => {
    scrollElementRef.current = element;
    setIsReady(true);
  };

  const scrollToBottom = (smooth = false) => {
    if (!scrollElementRef.current) return;

    if (smooth) {
      scrollElementRef.current.scrollTo({
        top: scrollElementRef.current.scrollHeight,
        behavior: 'smooth'
      });
    } else {
      scrollElementRef.current.scrollTop =
        scrollElementRef.current.scrollHeight;
    }
  };

  const enableAutoScroll = () => {
    setIsAutoScrollEnabled(true);
    scrollToBottom(true);
  };

  const isElementAtBottom = useCallback(
    (element: T): boolean => {
      return (
        element.scrollHeight - element.scrollTop - element.clientHeight <=
        scrollThreshold
      );
    },
    [scrollThreshold]
  );

  useEffect(() => {
    if (isAutoScrollEnabled && scrollElementRef.current) {
      scrollToBottom();
    }
    // We specifically want this to run when dependencies change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);

  useEffect(() => {
    const element = scrollElementRef.current;
    if (!element || !isReady) {
      return;
    }

    const handleScroll = () => {
      const isAtBottom = isElementAtBottom(element);

      if (!isAtBottom && !userHasScrolled) {
        setUserHasScrolled(true);
        setIsAutoScrollEnabled(false);
      }

      if (isAtBottom && userHasScrolled) {
        setUserHasScrolled(false);
        setIsAutoScrollEnabled(true);
      }
    };

    // Handle resize events (for sizing changes)
    const resizeObserver = new ResizeObserver(() => {
      if (isAutoScrollEnabled) {
        scrollToBottom();
      } else if (isElementAtBottom(element) && userHasScrolled) {
        setUserHasScrolled(false);
        setIsAutoScrollEnabled(true);
      }
    });

    element.addEventListener('scroll', handleScroll);
    resizeObserver.observe(element);

    return () => {
      element.removeEventListener('scroll', handleScroll);
      resizeObserver.disconnect();
    };
  }, [
    scrollThreshold,
    userHasScrolled,
    isReady,
    isAutoScrollEnabled,
    isElementAtBottom
  ]);

  return {
    setScrollElement,
    scrollElementRef,
    isAutoScrollEnabled,
    scrollToBottom,
    enableAutoScroll
  };
};
