import * as Types from '@grain/api/schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@grain/api/graphql';
const defaultOptions = {} as const;
export type SubscriptionBillingEmailUpdateMutationVariables = Types.Exact<{
  billingEmail: Types.Scalars['String']['input'];
}>;


export type SubscriptionBillingEmailUpdateMutation = { __typename?: 'RootMutationType', subscriptionBillingEmailUpdate: { __typename?: 'Workspace', id: string, workspaceSubscription: { __typename?: 'WorkspaceSubscription', billingEmail: string | null } } };


export const SubscriptionBillingEmailUpdateDocument = gql`
    mutation subscriptionBillingEmailUpdate($billingEmail: String!) {
  subscriptionBillingEmailUpdate(billingEmail: $billingEmail) {
    id
    workspaceSubscription {
      billingEmail
    }
  }
}
    `;
export type SubscriptionBillingEmailUpdateMutationFn = Apollo.MutationFunction<SubscriptionBillingEmailUpdateMutation, SubscriptionBillingEmailUpdateMutationVariables>;

/**
 * __useSubscriptionBillingEmailUpdateMutation__
 *
 * To run a mutation, you first call `useSubscriptionBillingEmailUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionBillingEmailUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriptionBillingEmailUpdateMutation, { data, loading, error }] = useSubscriptionBillingEmailUpdateMutation({
 *   variables: {
 *      billingEmail: // value for 'billingEmail'
 *   },
 * });
 */
export function useSubscriptionBillingEmailUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubscriptionBillingEmailUpdateMutation, SubscriptionBillingEmailUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SubscriptionBillingEmailUpdateMutation, SubscriptionBillingEmailUpdateMutationVariables>(SubscriptionBillingEmailUpdateDocument, options);
      }
export type SubscriptionBillingEmailUpdateMutationHookResult = ReturnType<typeof useSubscriptionBillingEmailUpdateMutation>;
export type SubscriptionBillingEmailUpdateMutationResult = Apollo.MutationResult<SubscriptionBillingEmailUpdateMutation>;
export type SubscriptionBillingEmailUpdateMutationOptions = Apollo.BaseMutationOptions<SubscriptionBillingEmailUpdateMutation, SubscriptionBillingEmailUpdateMutationVariables>;