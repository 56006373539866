import styled from '@emotion/styled';
import { color, font, spacing } from '@grain/grain-ui';
import { ReactNode } from 'react';
import { theme } from '@grain/grain-ui/v4';

const Heading = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
  ${theme.utils.px('lg')}
  ${spacing.py3}
  ${font.v4.c1[500]}
  ${color.bg.goose}
  ${color.fg.crow}
  position: sticky;
  top: 0;
  z-index: 2;

  @media (${theme.tokens.breakpoints.lgMin}) {
    ${spacing.px7}
  }
`;

type ResultHeadingProps = {
  children: ReactNode;
};

export const ResultHeading = ({ children }: ResultHeadingProps) => {
  return <Heading>{children}</Heading>;
};
