import * as Types from '@grain/api/schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@grain/api/graphql';
const defaultOptions = {} as const;
export type TrackersInsightsQueryVariables = Types.Exact<{
  filter: Types.Scalars['ViewAdHocFiltersJSON']['input'];
}>;


export type TrackersInsightsQuery = { __typename?: 'RootQueryType', insightQueries: { __typename?: 'InsightQueries', trackersInsights: { __typename?: 'TrackersInsights', recordingCount: number, trackers: Array<{ __typename?: 'TrackerStatistics', hitCount: number, smartTopic: { __typename?: 'SmartTopic', id: string, matchCount: number, description: string | null, tag: { __typename?: 'Tag', id: string, text: string } } }> } } };

export type TrackersInsightDetailsQueryVariables = Types.Exact<{
  filter: Types.Scalars['ViewAdHocFiltersJSON']['input'];
  tagId: Types.Scalars['ID']['input'];
}>;


export type TrackersInsightDetailsQuery = { __typename?: 'RootQueryType', insightQueries: { __typename?: 'InsightQueries', trackerInsightDetails: { __typename?: 'TrackerInsightDetails', recordingCount: number, usersStatistics: Array<{ __typename?: 'TrackerUserStatistics', hitCount: number, totalRecordingCount: number, user: { __typename?: 'User', hexColor: string, name: string, id: string, avatarUrl: string | null } }> } } };


export const TrackersInsightsDocument = gql`
    query trackersInsights($filter: ViewAdHocFiltersJSON!) {
  insightQueries {
    trackersInsights(filter: $filter) {
      recordingCount
      trackers {
        hitCount
        smartTopic {
          id
          matchCount(filter: $filter)
          description
          tag {
            id
            text
          }
        }
      }
    }
  }
}
    `;

/**
 * __useTrackersInsightsQuery__
 *
 * To run a query within a React component, call `useTrackersInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackersInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackersInsightsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTrackersInsightsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<TrackersInsightsQuery, TrackersInsightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TrackersInsightsQuery, TrackersInsightsQueryVariables>(TrackersInsightsDocument, options);
      }
export function useTrackersInsightsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrackersInsightsQuery, TrackersInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TrackersInsightsQuery, TrackersInsightsQueryVariables>(TrackersInsightsDocument, options);
        }
export function useTrackersInsightsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<TrackersInsightsQuery, TrackersInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<TrackersInsightsQuery, TrackersInsightsQueryVariables>(TrackersInsightsDocument, options);
        }
export type TrackersInsightsQueryHookResult = ReturnType<typeof useTrackersInsightsQuery>;
export type TrackersInsightsLazyQueryHookResult = ReturnType<typeof useTrackersInsightsLazyQuery>;
export type TrackersInsightsSuspenseQueryHookResult = ReturnType<typeof useTrackersInsightsSuspenseQuery>;
export type TrackersInsightsQueryResult = Apollo.QueryResult<TrackersInsightsQuery, TrackersInsightsQueryVariables>;
export const TrackersInsightDetailsDocument = gql`
    query trackersInsightDetails($filter: ViewAdHocFiltersJSON!, $tagId: ID!) {
  insightQueries {
    trackerInsightDetails(filter: $filter, tagId: $tagId) {
      recordingCount
      usersStatistics {
        hitCount
        totalRecordingCount
        user {
          hexColor
          name
          id
          avatarUrl
        }
      }
    }
  }
}
    `;

/**
 * __useTrackersInsightDetailsQuery__
 *
 * To run a query within a React component, call `useTrackersInsightDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackersInsightDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackersInsightDetailsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useTrackersInsightDetailsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<TrackersInsightDetailsQuery, TrackersInsightDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<TrackersInsightDetailsQuery, TrackersInsightDetailsQueryVariables>(TrackersInsightDetailsDocument, options);
      }
export function useTrackersInsightDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrackersInsightDetailsQuery, TrackersInsightDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<TrackersInsightDetailsQuery, TrackersInsightDetailsQueryVariables>(TrackersInsightDetailsDocument, options);
        }
export function useTrackersInsightDetailsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<TrackersInsightDetailsQuery, TrackersInsightDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<TrackersInsightDetailsQuery, TrackersInsightDetailsQueryVariables>(TrackersInsightDetailsDocument, options);
        }
export type TrackersInsightDetailsQueryHookResult = ReturnType<typeof useTrackersInsightDetailsQuery>;
export type TrackersInsightDetailsLazyQueryHookResult = ReturnType<typeof useTrackersInsightDetailsLazyQuery>;
export type TrackersInsightDetailsSuspenseQueryHookResult = ReturnType<typeof useTrackersInsightDetailsSuspenseQuery>;
export type TrackersInsightDetailsQueryResult = Apollo.QueryResult<TrackersInsightDetailsQuery, TrackersInsightDetailsQueryVariables>;