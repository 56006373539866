import React from 'react';
import Button from '@grain/components/Button';
import { font } from '@grain/grain-ui';

import {
  StyledNoteContainer,
  StyledNoteArea,
  StyledButtonContainer
} from './styles';

type InviteFormProps = {
  selectedEmails: string[];
  onSend: (note: string) => void;
  onBack: () => void;
};

function InviteForm({ selectedEmails, onSend, onBack }: InviteFormProps) {
  const [note, setNote] = React.useState('');

  return (
    <>
      <StyledNoteContainer>
        <StyledNoteArea
          value={note}
          onChange={e => setNote(e.target.value)}
          className='fs-block'
          placeholder='Add a message...'
        />
      </StyledNoteContainer>
      <StyledButtonContainer>
        <Button type='secondary' onClick={onBack}>
          Back
        </Button>
        <Button
          disabled={selectedEmails.length === 0}
          onClick={() => onSend(note)}
          type='primary'
          width={125}
          css={[font.body.s, 'font-weight: bold;']}
        >
          Send
        </Button>
      </StyledButtonContainer>
    </>
  );
}

export default InviteForm;
