import { useMemo, useCallback, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { WorkspaceMeetingLanguageCode } from '@g/schema';
import { useWorkspaceEditMutation } from '@grain/api/graphql/mutations/workspace.generated';
import { useWorkspaceMembersQuery } from '@grain/api/graphql/queries/workspace.generated';
import { useShowToast } from '@grain/grain-ui';
import { MultiSelect, Option, Divider, theme } from '@grain/grain-ui/v4';

const Title = styled.h3`
  color: ${theme.tokens.color.textPrimary};
  ${theme.tokens.typography.b2[600]};
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const SubTitle = styled.span`
  color: ${theme.tokens.color.textSecondary};
  ${theme.tokens.typography.b2[400]};
  margin-top: ${theme.tokens.spacing.xs};
`;

const languageOptions = [
  {
    title: 'English',
    value: WorkspaceMeetingLanguageCode.En
  },
  {
    title: 'Chinese',
    value: WorkspaceMeetingLanguageCode.Zh
  },
  {
    title: 'Dutch',
    value: WorkspaceMeetingLanguageCode.Nl
  },
  {
    title: 'Finnish',
    value: WorkspaceMeetingLanguageCode.Fi
  },
  {
    title: 'French',
    value: WorkspaceMeetingLanguageCode.Fr
  },
  {
    title: 'German',
    value: WorkspaceMeetingLanguageCode.De
  },
  {
    title: 'Hindi',
    value: WorkspaceMeetingLanguageCode.Hi
  },
  {
    title: 'Italian',
    value: WorkspaceMeetingLanguageCode.It
  },
  {
    title: 'Japanese',
    value: WorkspaceMeetingLanguageCode.Ja
  },
  {
    title: 'Korean',
    value: WorkspaceMeetingLanguageCode.Ko
  },
  {
    title: 'Polish',
    value: WorkspaceMeetingLanguageCode.Pl
  },
  {
    title: 'Portuguese',
    value: WorkspaceMeetingLanguageCode.Pt
  },
  {
    title: 'Russian',
    value: WorkspaceMeetingLanguageCode.Ru
  },
  {
    title: 'Spanish',
    value: WorkspaceMeetingLanguageCode.Es
  },
  {
    title: 'Turkish',
    value: WorkspaceMeetingLanguageCode.Tr
  },
  {
    title: 'Ukrainian',
    value: WorkspaceMeetingLanguageCode.Uk
  },
  {
    title: 'Vietnamese',
    value: WorkspaceMeetingLanguageCode.Vi
  },
  {
    title: 'Others',
    value: WorkspaceMeetingLanguageCode.Other
  }
];

export const MeetingLanguageSelector = () => {
  const showToast = useShowToast();

  const [meetingLanguages, setMeetingLanguages] = useState<
    WorkspaceMeetingLanguageCode[]
  >([]);

  useWorkspaceMembersQuery({
    onCompleted: ({ workspace }) => {
      setMeetingLanguages(workspace.meetingLanguages);
    }
  });

  const [editWorkspace] = useWorkspaceEditMutation({
    onCompleted: () => {
      showToast({
        content: 'Workspace updated.',
        type: 'success',
        uniqueId: 'workspace-update'
      });
    }
  });

  const meetingLanguageSetting = useMemo(
    () =>
      languageOptions
        .filter(option => (meetingLanguages || []).includes(option.value))
        .map(option => option.value),
    [meetingLanguages]
  );

  const handleMeetingLanguageChange = useCallback(
    (newMeetingLanguages: WorkspaceMeetingLanguageCode[]) => {
      setMeetingLanguages(newMeetingLanguages);
      editWorkspace({
        variables: {
          meetingLanguages: newMeetingLanguages
        }
      });
    },
    [setMeetingLanguages, editWorkspace]
  );

  return (
    <>
      <Title>
        Meeting languages
        <SubTitle>
          Grain will automatically detect your language(s), but for the best
          detection, let us know which language most of your meetings will be
          in.{' '}
          <a
            href='https://support.grain.com/en/articles/4742859-does-grain-support-other-languages'
            target='_blank'
          >
            See all 100+ supported languages
          </a>
        </SubTitle>
      </Title>
      <div
        css={css`
          width: 410px;
        `}
      >
        <MultiSelect
          fullWidth
          matchWidth
          onChange={handleMeetingLanguageChange}
          value={
            meetingLanguageSetting.length
              ? meetingLanguageSetting
              : [WorkspaceMeetingLanguageCode.En]
          }
          placeholder='English'
        >
          <Option value={WorkspaceMeetingLanguageCode.En}>English</Option>
          <Divider />
          {...languageOptions
            .map(
              option =>
                option.value !== WorkspaceMeetingLanguageCode.En && (
                  <Option key={option.value} value={option.value}>
                    {option.title}
                  </Option>
                )
            )
            .filter(Boolean)}
        </MultiSelect>
      </div>
    </>
  );
};
