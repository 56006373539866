import { format } from 'date-fns';
import { toTimestamp } from '@grain/grain-ui';
import {
  MenuHeader,
  MenuButton,
  Divider,
  Icon16,
  theme
} from '@grain/grain-ui/v4';
import { Link } from 'react-router-dom';
import { SourceFragment } from '~/pages/Deals/deal.generated';
import styled from '@emotion/styled';

type SourceMenuContentProps = {
  sourcesGroupedByContainingObject: Record<string, SourceFragment[]>;
};

const Fact = styled.span`
  display: list-item;
  ${theme.tokens.typography.b3['400p']}
  margin-left: 18px;
`;

export const DealDiagnosisSourceMenuContent = ({
  sourcesGroupedByContainingObject
}: SourceMenuContentProps) => {
  const shouldShowDivider = (index: number) => {
    return index < Object.keys(sourcesGroupedByContainingObject).length - 1;
  };

  return (
    <>
      {Object.values(sourcesGroupedByContainingObject).map((sources, index) => (
        <div key={index}>
          <MenuHeader
            as={Link}
            to={`${sources[0].containingObject?.recordingPath}`}
            startIcon={Icon16.Record}
            metadata={format(
              new Date(sources[0].containingObject?.startDatetime ?? ''),
              'MMM d'
            )}
            variant='subheader'
          >
            {sources[0].containingObject?.title}
          </MenuHeader>
          {sources.map(source => (
            <MenuButton
              as={Link}
              to={`${source.containingObject?.recordingPath}?t=${source.timestamp}&tab=transcript`}
              key={source.timestamp}
              textLabelProps={{
                metadata: toTimestamp(source.timestamp)
              }}
              label={<Fact>{source.fact}</Fact>}
            />
          ))}
          {shouldShowDivider(index) && <Divider />}
        </div>
      ))}
    </>
  );
};
