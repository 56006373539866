// @ts-strict-ignore
import { useRecordingShareInfoQuery } from '../../Library/library.generated';
import ShareRecordingPermissions from '~/modals/SharePermissions/ShareRecording';

type Props = {
  isShareOpen: boolean;
  recordingId: string;
  onCancel?: () => void;
};

export const MeetingsShareModal = ({
  recordingId,
  isShareOpen,
  onCancel
}: Props) => {
  const { data: recordingData } = useRecordingShareInfoQuery({
    variables: { recordingId },
    fetchPolicy: 'cache-first',
    skip: Boolean(!recordingId || !isShareOpen)
  });

  const recording = recordingData?.recording;

  return (
    <>
      {recording && isShareOpen && (
        <ShareRecordingPermissions
          recording={recording}
          onCancel={onCancel}
          analyticProperties={{
            share_type: 'copy_link_meeting_library_hamburger_menu'
          }}
          recordingCurrentTime={null}
        />
      )}
    </>
  );
};
