import styled from '@emotion/styled';

export const StyledFullCalendlyBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  & > iframe {
    min-height: 394px;
  }
`;
