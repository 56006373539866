import { Dispatch, SetStateAction } from 'react';
import { useExtra } from '@grain/components/support/extra';
import { useAuth } from '~/support/auth';
import { useShowToast } from '@grain/grain-ui';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import api from '@grain/components/support/api';
import { useNavigate } from 'react-router-dom';

export type AccessibleWorkspace = {
  id: string;
  name: string;
  logo_url: string;
  requires_sso_reauth: boolean;
  member_count: number;
  invite_type:
    | 'email_invitation'
    | 'open'
    | 'workspace_code'
    | 'story_editor_link'
    | 'sso';
  is_invite_ws: boolean;
  join_by_request: boolean;
  owner: {
    id: string;
    name: string;
    email: string;
  };
  inviter: {
    id: string;
    name: string;
    email: string;
  } | null;
};

export type RequestedWorkspace = {
  id: string;
  name: string;
  logo_url: string;
  owner: {
    id: string;
    name: string;
    email: string;
  };
  member_count: number;
};

export type RegistrationData = {
  name: string;
  email: string;
  domain: string;
  has_calendar: boolean;
  can_create_workspace: boolean;
  provider: 'google' | 'microsoft' | 'workos';
  accessible_workspaces: AccessibleWorkspace[];
  requested_workspace: RequestedWorkspace | null;
};

type ApiError = {
  data: {
    error: string;
  };
};

export const useUserRegistration = () => {
  const showToast = useShowToast({ force: true });
  const [utmParams] = useLocalStorage('registration_utms', {});
  const { extra, saveExtra } = useExtra();
  const { setIsAuthenticated } = useAuth();
  const navigate = useNavigate();

  const registerResponseHandler = (
    invited: boolean = false,
    isUserJoining: boolean = false,
    nextStep?: () => void
  ) => {
    setIsAuthenticated(true);

    const hasJobFunctionSet = extra?.jobFunctionSet ?? false;
    const hasOnboardingDone = extra?.onboardingDone ?? false;

    saveExtra(
      {
        ...extra,
        jobFunctionSet: hasJobFunctionSet,
        onboardingDone: hasOnboardingDone,
        trialStartedModalShown: true,
        invitedWorkspace: invited,
        joiningWorkspace: isUserJoining,
        requestedToJoin: false
      },
      true,
      nextStep
    );
  };

  const joinWorkspaceById = async (
    id: string,
    loadingJoinId?: string | null,
    setLoadingJoinId?: Dispatch<SetStateAction<null | string>>,
    onNext?: () => void
  ) => {
    if (loadingJoinId && setLoadingJoinId) setLoadingJoinId(id);
    const response = await api
      .post('/_/reg_api/', {
        body: {
          method: 'register_and_join_workspace',
          workspace_id: id,
          utms: utmParams
        }
      })
      .catch(() => {
        if (setLoadingJoinId) setLoadingJoinId(null);
        showToast({
          content: 'Oops, something went wrong. Please try again.',
          type: 'failure'
        });
      });
    if (response && response?.data?.success) {
      registerResponseHandler(false, true, onNext);
    }
    return response;
  };

  const createWorkspace = async (onNext: () => void) => {
    const response = await api
      .post('/_/reg_api/', {
        body: {
          method: 'register_and_create_workspace',
          utms: utmParams
        }
      })
      .catch(() => {
        showToast({
          content: 'Oops, we couldn’t set up your workspace!',
          type: 'failure',
          action: 'Contact Support',
          showCloseButton: false,
          closeOnAction: false,
          onAction: () => window.open('mailto:help@grain.com'),
          durationMs: 0
        });
      });
    if (response && response?.data?.success) {
      registerResponseHandler(false, false, onNext);
    }
  };

  const getRegistrationData = async (nextStep?: () => void) => {
    const response = await api
      .post('/_/reg_api/', {
        body: {
          method: 'get_registration_data'
        }
      })
      .catch((e: ApiError) => {
        // auth data only present before registration
        if (e.data?.error === 'missing_auth_data') {
          navigate('/');
          showToast({
            content: 'Oops, something went wrong. Please try again.',
            type: 'failure'
          });
        }
        // redirect back to sign in for accepted join requests
        if (e.data?.error === 'invalid_auth_data') {
          navigate('/');
        }
      });

    let data = null;
    if (response) data = response?.data as RegistrationData;

    if (
      data &&
      !data.accessible_workspaces.length &&
      !data.can_create_workspace &&
      !data.requested_workspace
    ) {
      navigate('/app/signup?action=web_register&error=account_not_found');
    }

    if (data && data.accessible_workspaces?.length) {
      const autoJoinWorkspace = data.accessible_workspaces.find(
        ({ is_invite_ws }: { is_invite_ws: boolean }) => is_invite_ws
      );
      const requestedWorkspace = data.requested_workspace;
      if (autoJoinWorkspace && !requestedWorkspace) {
        const joinResponse = await joinWorkspaceById(autoJoinWorkspace.id);
        if (joinResponse && joinResponse?.data.success) {
          registerResponseHandler(true, true, nextStep);
        }
      }
    }

    return data;
  };

  const requestToJoinWorkspace = async (
    workspaceId: string,
    forceToPendingWorkspaceRequest: () => void
  ) => {
    try {
      const response = await api.post('/_/reg_api/', {
        body: {
          method: 'request_to_join_workspace',
          workspace_id: workspaceId
        }
      });
      if (response && response?.data?.success) {
        forceToPendingWorkspaceRequest();
      }
    } catch {
      showToast({
        content: 'Oops, something went wrong. Please try again.',
        type: 'failure'
      });
    }
  };

  const cancelRequestToJoinWorkspace = async (
    workspaceId: string,
    cancelRequestToJoinWorkspace: () => void,
    forceToJoinWorkspace: () => void
  ) => {
    try {
      await api.post('/_/reg_api/', {
        body: {
          method: 'cancel_request_to_join_workspace',
          workspace_id: workspaceId
        }
      });
      showToast({
        content: 'Request to join workspace cancelled.',
        type: 'success'
      });
      cancelRequestToJoinWorkspace();
      forceToJoinWorkspace();
    } catch {
      showToast({
        content: 'Oops, something went wrong. Please try again.',
        type: 'failure'
      });
    }
  };

  return {
    joinWorkspaceById,
    createWorkspace,
    getRegistrationData,
    registerResponseHandler,
    requestToJoinWorkspace,
    cancelRequestToJoinWorkspace
  };
};
