import { CrmMeetingThumbnail } from '~/components/CrmMeetingThumbnail/CrmMeetingThumbnail';
import { crmProviderForRecording } from '~/modules/freemium/crmProviderForRecording';
import { useFreemiumTrialOnboardingRecordingQuery } from '~/modules/freemium/freemium.generated';

export const TrialJoinerCrmTourStep = () => {
  const { data, loading } = useFreemiumTrialOnboardingRecordingQuery();

  const crmRecording = data?.freemiumTrialOnboardingRecording;
  const crmProvider = crmRecording
    ? crmProviderForRecording(crmRecording)
    : undefined;

  return (
    <CrmMeetingThumbnail
      loading={loading}
      maxWidth={435}
      title={crmRecording?.title || ''}
      date={new Date(crmRecording?.startDatetime || new Date())}
      duration={crmRecording?.duration || 0}
      thumbnailUrl={crmRecording?.fullJpegThumbnailUrl || ''}
      crmProvider={crmProvider}
    />
  );
};
