import styled from '@emotion/styled';
import { getSectionDarkColorByNumber } from '~/pages/StoryPage/constants';
import {
  resetButtonStyles,
  resetInputStyles,
  TextArea,
  hexToRgba
} from '@grain/grain-ui';
import { colors, font, media, spacing } from '@grain/styles/constants';

export const StyledSectionWrapper = styled.div`
  position: relative;
  flex: 1;
  ${spacing.py6};
  ${spacing.pl7};
  display: flex;
  align-items: center;

  ${spacing.pr3};

  background-color: ${colors.raven};
  border-radius: 12px;
  color: ${colors.swan};

  .content-container {
    flex: 1;
  }
  .actions-container {
    ${spacing.ml2};
    flex-shrink: 0;
  }

  .title-container {
    display: flex;
    position: relative;
    align-items: flex-end;
  }

  .description-container {
    display: flex;
    position: relative;
    align-items: flex-end;
  }

  .description-container.empty {
    display: none;
  }

  :hover .description-container.empty {
    display: flex;
  }
`;

export const StyledColorFlag = styled.div`
  position: absolute;
  left: 0;
  top: 28px;
  bottom: 28px;
  width: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${props => getSectionDarkColorByNumber(props.colorNumber)};
`;

export const StyledTitleInlineInput = styled.input`
  ${font.titleM};
  width: calc(100% - 32px);
  ${media.small} {
    font-size: 1.25rem;
    width: 100%;
  }

  font-weight: 600px;
  ${resetInputStyles};

  height: 30px;
  line-height: 30px;
  color: ${colors.swan};
`;

export const StyledDescriptionTextarea = styled(TextArea)`
  ${font.bodyM};
  padding: 4px 0;
  line-height: 1.25rem;
  font-weight: 400;
  padding-left: 4px;
  margin-left: -4px;
  border-radius: 12px;
  color: ${colors.swan};
  textarea {
    color: ${colors.swan};
  }
`;

const StyledCharCounter = styled.div`
  font-weight: bold;
  font-size: 10px;
  color: ${props => (props.isMax ? colors.errorbird : colors.swan)};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

export const StyledSectionTitleCharCounter = styled(StyledCharCounter)`
  ${media.small} {
    position: absolute;
    top: -12px;
  }
`;

export const StyledSectionDescriptionCharCounter = styled(StyledCharCounter)`
  ${media.small} {
    position: absolute;
    bottom: -12px;
  }
`;

export const StyledIconContainer = styled.button`
  ${resetButtonStyles}
  cursor: pointer;
  z-index: 1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  background-color: ${hexToRgba(colors.rook, 0.5)};
  ${font.bodyM};
  svg {
    width: 16px;
    height: 16px;
  }
  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

export const StyledColorPicker = styled.div`
  display: flex;
  width: 132px;
  flex-wrap: wrap;

  .color {
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6px;
    border-radius: 50%;

    // Do not rearrange
    border: 2px solid transparent;
    &.active {
      border-color: ${colors.graieen};
    }

    .circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }
`;
