import SectionContentRow from './SectionContentRow';
import Section from './Section';
import { useNavigate } from 'react-router-dom';
import { displayCents } from '~/support/price';
import { pluralize } from '~/support/language';
import { showNewMessage } from '~/support/intercom';
import {
  SubscriptionPlan,
  WorkspaceSubscription
} from '@grain/api/schema.generated';
import { useAnalytics } from 'lib/Analytics';

const pluralSeat = (count: number) => pluralize('seat', 'seats', count);

export default function CurrentPlanDetailsSection({
  isLoading,
  workspaceSubscription,
  workspacePlan
}: {
  isLoading: boolean;
  workspaceSubscription: WorkspaceSubscription;
  workspacePlan: SubscriptionPlan;
}) {
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();

  const { name: planName, monthlyPrice, yearlyPrice } = workspacePlan ?? {};
  const { trial, autoRenew, count, planDetails, seatQuantity, billingPeriod } =
    workspaceSubscription ?? {};

  const isTrial = trial && !autoRenew;
  const isFree = planDetails?.isFree;
  const billingCycle = billingPeriod === 'MONTHLY' ? 'Monthly' : 'Annual';
  const seatPrice =
    billingCycle === 'Monthly'
      ? `$${displayCents(monthlyPrice)}/mo per paid seat`
      : `$${displayCents((yearlyPrice ?? 0) / 12)}/mo per paid seat`;
  const availableSeats = Math.max((seatQuantity ?? 0) - count, 0);
  const canEditPlan = workspaceSubscription?.canEdit;

  const getPlanCTAText = () => {
    if (isFree || isTrial) {
      return 'Upgrade';
    }
    if (!canEditPlan) {
      return 'Manage plan';
    }
    return 'Change plan';
  };

  return (
    <Section title='Current plan details' isLoading={isLoading}>
      <SectionContentRow
        title='Your plan'
        details={`${planName} ${isTrial ? '(trial)' : `- ${billingCycle} (${seatPrice})`}`}
        cta={getPlanCTAText()}
        onClick={() => {
          trackEvent(
            'Button Clicked',
            {
              button_name: 'change_plan',
              location: 'billing_page'
            },
            ['user', 'workspace']
          );
          if (!canEditPlan) {
            showNewMessage();
          } else {
            navigate('/app/settings/workspace?tab=plans');
          }
        }}
      />
      {!isFree && (
        <SectionContentRow
          title='Paid seats'
          details={`${seatQuantity ?? 0} paid ${pluralSeat(seatQuantity ?? 0)}`}
          subDetails={`${availableSeats} unassigned`}
          cta='Manage seats'
          onClick={() => {
            trackEvent(
              'Button Clicked',
              {
                button_name: 'manage_seats',
                location: 'billing_page'
              },
              ['user', 'workspace']
            );
            navigate('/app/settings/workspace?tab=members');
          }}
        />
      )}
    </Section>
  );
}
