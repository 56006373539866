import { useConfirmRecordingHardDelete } from '~/modules/recording/hooks';
import { useRecordingUndeleteMutation } from '../deleted.generated';
import { useShowToast } from '@grain/grain-ui';
import { useMemo, useState } from 'react';
import { useMyself } from '@grain/api/auth';

const useRestoreLimit = () => {
  const { myself } = useMyself();

  const cannotRestore = useMemo(() => {
    // null if user is not limited
    if (myself?.recordingCountLimit === null) {
      return false;
    }
    return (myself?.recordingCount || 0) >= (myself?.recordingCountLimit || 0);
  }, [myself]);

  return {
    canRestore: !cannotRestore,
    limit: myself?.recordingCountLimit ?? 10
  };
};

export const useDeletedMeetingsMutations = (refetch: () => void) => {
  const [showLimitModal, setShowLimitModal] = useState(false);
  const { canRestore, limit } = useRestoreLimit();
  const showToast = useShowToast();
  const confirmHardDelete = useConfirmRecordingHardDelete({
    onCompleted() {
      showToast({
        content: 'Recording deleted.',
        type: 'success'
      });
      refetch();
    }
  });
  const [restoreRecordingMutation] = useRecordingUndeleteMutation({
    onCompleted: () => {
      showToast({
        content: 'Recording restored.',
        type: 'success'
      });
      refetch();
    }
  });

  const restoreRecording = (recordingId: string) => {
    if (canRestore) {
      restoreRecordingMutation({
        variables: { recordingId: recordingId }
      });
    } else {
      setShowLimitModal(true);
    }
  };

  return {
    restoreRecording,
    deleteRecording: confirmHardDelete,
    showLimitModal,
    limit,
    closeLimitModal: () => setShowLimitModal(false)
  };
};
