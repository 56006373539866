import { useCallback, useMemo, useState } from 'react';
import { FilterName } from '../types';
import { Filters } from '../useFiltersManager';
import styled from '@emotion/styled';
import { Button, MenuButton, Tooltip } from '@grain/grain-ui/v4';
import { useRouteModal } from 'lib/modals';
import { UPGRADE_PLAN_ROUTE_ID } from '@grain/components/modals/constants';

type FilterSelectProps = {
  filters: Filters;
  onFilterSelect: (val: FilterName) => void;
};

const FilterSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const FilterSelect = ({
  filters,
  onFilterSelect
}: FilterSelectProps) => {
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);
  const { open: openUpgradePlanModal } = useRouteModal(UPGRADE_PLAN_ROUTE_ID);

  const hasUpgradeableFilter = useMemo(() => {
    return Object.values(filters).some(filter => filter.requiresUpgrade);
  }, [filters]);

  const filteredFilters = useMemo(() => {
    return Object.values(filters)
      .sort((a, b) => a.order - b.order)
      .sort((a, b) => (a.requiresUpgrade ? 1 : b.requiresUpgrade ? -1 : 0))
      .filter(
        filter =>
          !filter.active &&
          !filter.skip &&
          !filter.hidden &&
          filter.id !== 'title' &&
          Reflect.has(filter, 'label')
      );
  }, [filters]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'ArrowDown') {
        setSelectedFilterIndex(prevIndex =>
          Math.min(prevIndex + 1, filteredFilters.length - 1)
        );
      } else if (e.key === 'ArrowUp') {
        setSelectedFilterIndex(prevIndex => Math.max(prevIndex - 1, 0));
      }
    },
    [filteredFilters]
  );

  return (
    <FilterSelectContainer onKeyDown={handleKeyDown}>
      <>
        {filteredFilters.map((filter, index) => {
          return (
            <Tooltip
              key={filter.id}
              disabled={!filter.requiresUpgrade}
              content={filter.requiresUpgrade ? 'Upgrade to enable this' : ''}
            >
              <MenuButton
                disabled={filter.requiresUpgrade}
                fullWidth
                buttonRef={
                  selectedFilterIndex === index ? el => el?.focus() : undefined
                }
                label={filter.label}
                onClick={() => {
                  if ('fetchData' in filter && filter.fetchData) {
                    filter.fetchData();
                  }
                  onFilterSelect(filter.id);
                }}
                textLabelProps={{
                  startIcon: filter.icon
                }}
              />
            </Tooltip>
          );
        })}
        {hasUpgradeableFilter && (
          <div
            css={{ display: 'flex', justifyContent: 'flex-end', padding: 4 }}
          >
            <Button variant='plan' size='sm' onClick={openUpgradePlanModal}>
              Upgrade
            </Button>
          </div>
        )}
      </>
    </FilterSelectContainer>
  );
};
