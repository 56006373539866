import React from 'react';
import { OauthProvider } from '@grain/api/schema.generated';
import type { OnboardingSurveyAnswers } from '@grain/api/schema.extra';
import { useMyself, useWorkspace } from '@grain/api/auth';
import { getBasePublicPath, getOauthRedirectUrl } from '@grain/grain-ui';
import { Button, Icon16 } from '@grain/grain-ui/v4';
import { LoginButton } from '~/components/LoginButton';
import { ImageEmptyView } from '~/components/ImageEmptyView';
import { getCalendlyUrl } from '~/pages/Onboarding/Calendly/hooks';
import { inMediumToLargeSizedCompany } from '~/pages/Onboarding/ChooseRole';

const titles: Record<ViewStates, string> = {
  'not-connected': 'Connect your calendar',
  'no-events': 'You have no upcoming meetings'
};

const descriptions: Record<ViewStates, string> = {
  'not-connected':
    'View your meetings and easily manage which ones Grain records. Otherwise, you’ll have to manually record every call.',
  'no-events':
    'Add events to your calendar, or manually record a call with Grain.'
};

type ViewStates = 'not-connected' | 'no-events';
type EmptyCalendarStateProps = {
  view: ViewStates;
  email?: string;
  providers?: OauthProvider[];
  isOnPaidPlan?: boolean;
};

const EMPTY_ARRAY: never[] = [];

export const EmptyCalendarState = ({
  view,
  email = '',
  providers = EMPTY_ARRAY,
  isOnPaidPlan = false
}: EmptyCalendarStateProps) => {
  const { myself } = useMyself();
  const { workspace } = useWorkspace();

  const currentPath = window.location.pathname + window.location.search;
  const calendlyUrl = getCalendlyUrl(isOnPaidPlan, email);
  const title = titles[view];
  const description = descriptions[view];

  const isOtherProvider = Boolean(
    providers.length &&
      !providers.includes(OauthProvider.Google) &&
      !providers.includes(OauthProvider.Microsoft)
  );
  const showGoogle =
    !providers.length ||
    providers.includes(OauthProvider.Google) ||
    isOtherProvider;
  const showMicrosoft =
    !providers.length ||
    providers.includes(OauthProvider.Microsoft) ||
    isOtherProvider;

  const hasWorkEmailDomains = workspace?.hasWorkEmailDomains ?? false;
  const onboardingSurveyAnswers = myself
    ? (myself.onboardingSurveyAnswers as OnboardingSurveyAnswers)
    : undefined;
  const canBookOnboarding = onboardingSurveyAnswers
    ? inMediumToLargeSizedCompany(onboardingSurveyAnswers)
    : false;

  const showScheduleOnboardingCall = hasWorkEmailDomains && canBookOnboarding;

  const ctas: Record<ViewStates, React.ReactElement | null> = {
    'not-connected': email ? (
      <>
        {showGoogle && (
          <LoginButton
            as='a'
            loginType='Google'
            href={getOauthRedirectUrl(
              'google',
              true,
              'connect_account',
              {},
              currentPath,
              email
            )}
            data-track='Button Clicked'
            data-track-args={JSON.stringify({
              button_name: 'calendar_connect_google',
              button_text: 'Connect Google Calendar'
            })}
          >
            Connect Google Calendar
          </LoginButton>
        )}
        {showMicrosoft && (
          <LoginButton
            as='a'
            loginType='Microsoft'
            href={getOauthRedirectUrl(
              'microsoft',
              true,
              'connect_account',
              {},
              currentPath,
              email
            )}
            data-track='Button Clicked'
            data-track-args={JSON.stringify({
              button_name: 'calendar_connect_microsoft',
              button_text: 'Connect Outlook'
            })}
          >
            Connect Outlook
          </LoginButton>
        )}
      </>
    ) : null,
    'no-events': showScheduleOnboardingCall ? (
      <Button
        as='a'
        href={calendlyUrl}
        target='_blank'
        rel='noopener noreferrer'
        size='xl'
        variant='stroked'
        textLabelProps={{ startIcon: Icon16.ExternalLink }}
      >
        Schedule onboarding call
      </Button>
    ) : null
  };
  const cta = ctas[view];

  return (
    <ImageEmptyView.Wrapper css={['max-width: 508px;']}>
      <ImageEmptyView.Image
        css={['max-width: 464px;']}
        src={`${getBasePublicPath()}/images/zero-states/calendar.svg`}
      />
      <ImageEmptyView.InnerWrapper>
        <ImageEmptyView.Title>{title}</ImageEmptyView.Title>
        <ImageEmptyView.Description>{description}</ImageEmptyView.Description>
        <ImageEmptyView.Actions>{cta}</ImageEmptyView.Actions>
      </ImageEmptyView.InnerWrapper>
    </ImageEmptyView.Wrapper>
  );
};
