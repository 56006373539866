import React, { ReactNode } from 'react';
import LegacyButton from '@grain/components/Button';
import {
  CustomVocabulary,
  ErrorBox,
  GrowingInput,
  Icon,
  Logo,
  Switch,
  useConfirm,
  useShowToast,
  Tooltip,
  color,
  useAnalytics
} from '@grain/grain-ui';
import {
  Button,
  ButtonRow,
  Divider,
  Icon16,
  Input,
  Menu,
  MenuButton,
  Option,
  Select,
  theme
} from '@grain/grain-ui/v4';
import SubscribeModal from '~/modules/subscriptions/SubscribeModal';
import UploadLogoModal from './UploadLogoModal';
import copy from 'clipboard-copy';
import { spacing } from '@grain/styles/constants';
import { useMutation } from '@grain/api/graphql';
import { useWorkspaceWithMembers } from '@grain/components/Workspace/hooks';
import { useMyself, useFeature } from '@grain/api/auth';
import {
  useGetCustomVocabularyQuery,
  useSetCustomVocabularyMutation,
  useAddWorkspaceDomainMutation,
  useRemoveWorkspaceDomainMutation,
  useUnapproveWorkspaceAuthorizedDomainMutation,
  useApproveWorkspaceAuthorizedDomainMutation
} from './workspaceGeneral.generated';

import { deleteWorkspaceMutation } from '../graphql';

import {
  StyledDescription,
  StyledDomainsContainer,
  StyledPill,
  StyledDomain,
  StyledSubheadingContainer,
  StyledAllowedDomainsPill,
  StyledDomainApproveBox,
  SectionTitle,
  SectionDescription,
  WorkspaceSection,
  WorkspaceTitleButton
} from './styles';
import {
  UserRole,
  AuthorizedDomainsJoinSetting
} from '@grain/api/schema.generated';
import { GatedComponent } from '~/modules/gates';
import {
  useWorkspaceEditMutation,
  useWorkspaceInviteCodeRegenerateMutation,
  useWorkspacePlanInviteCodeRegenerateMutation
} from '@grain/api/graphql/mutations/workspace.generated';
import { css } from '@emotion/react';
import CompanyContextModal from './CompanyContextModal';
import { useWorkspacePlan } from '@grain/components/Subscriptions/hooks';
import { GatedOption, GatedSelect } from '~/modules/gates/GatedSelect';

interface GatedWrapperProps {
  children: ReactNode;
}

const GatedWrapper: React.FC<GatedWrapperProps> = ({ children }) => {
  if (!React.isValidElement(children)) return null;
  return (
    <GatedComponent gate='custom_vocabulary' gap>
      {children}
    </GatedComponent>
  );
};

const requestJoinOptions = [
  {
    title: 'Join workspace on sign up',
    value: 'JOIN' as const,
    gated: false
  },
  {
    title: 'Request to join workspace',
    value: 'REQUEST' as const,
    gated: true
  }
];

type WorkspaceModal = 'company_context' | 'subscribe' | 'upload_logo';

export default function Workspace() {
  const { trackEvent } = useAnalytics();
  const showConfirm = useConfirm();
  const [activeModal, setActiveModal] = React.useState<WorkspaceModal | null>(
    null
  );

  const { subscriptionPlan: workspacePlan } = useWorkspacePlan();
  const isOnFreePlan = workspacePlan?.isFree;

  const showToast = useShowToast();

  // This is initialized as a string with just a space to solve for the mismatch
  //  of loading states between the apollo client and local state set onComplete
  //  that results in the button footer to "flash" in on the load of this modal
  const [workspaceName, setWorkspaceName] = React.useState(' ');
  const [hideWatermarks, setHideWatermarks] = React.useState(false);
  const [requestToJoinSetting, setRequestToJoinSetting] =
    React.useState<AuthorizedDomainsJoinSetting | null>(null);

  const {
    loading: workspaceLoading,
    error: workspaceError,
    workspace
  } = useWorkspaceWithMembers({
    onCompleted: ({ workspace }) => {
      setWorkspaceName(workspace.name);
      setHideWatermarks(workspace.hideWatermarks);
      setRequestToJoinSetting(workspace.authorizedDomainsJoinSetting);
    },
    fetchPolicy: 'cache-and-network'
  });

  const hasCompanyContext =
    workspace?.organizationContext !== null ||
    workspace?.competitorContext !== null ||
    workspace?.workspaceGroup?.name !== null ||
    (workspace?.competitorContext || []).length > 0;

  const { myself } = useMyself();

  const { enabled: showWatermarkHiddenFeature } = useFeature(
    'highlight_watermark_hidden'
  );

  const [editWorkspace] = useWorkspaceEditMutation({
    onCompleted: () => {
      showToast({
        content: 'Workspace updated.',
        type: 'success',
        uniqueId: 'workspace-update'
      });
    }
  });

  // Hidden highlight watermark premium feature

  const { enabled: loadVocab } = useFeature('custom_vocabulary');

  const {
    data: customVocabData,
    loading: customVocabQueryLoading,
    refetch: refetchCustomVocab
  } = useGetCustomVocabularyQuery({
    skip: Boolean(!loadVocab)
  });

  const vocabLoading = loadVocab && customVocabQueryLoading;
  const [submittableCustomVocab, setSubmittableCustomVocab] = React.useState<
    string[]
  >([]);

  React.useEffect(() => {
    if (vocabLoading) {
      return;
    }

    setSubmittableCustomVocab(
      customVocabData?.workspace?.customVocabulary?.items ?? []
    );
  }, [
    vocabLoading,
    setSubmittableCustomVocab,
    customVocabData?.workspace?.customVocabulary?.items
  ]);

  const onCustomVocabChange = React.useCallback(
    (items: string[]) => {
      setSubmittableCustomVocab(items);
    },
    [setSubmittableCustomVocab]
  );

  const [customVocabMutationRunning, setCustomVocabMutationRunning] =
    React.useState(false);
  const [setCustomVocabulary] = useSetCustomVocabularyMutation({
    onCompleted: () => {
      refetchCustomVocab();
      setCustomVocabMutationRunning(false);
    },
    onError: err => {
      showToast({
        content: `${err}`,
        type: 'failure',
        id: 'custom-vocab-error'
      });

      setCustomVocabMutationRunning(false);
    }
  });

  const onCustomVocabSave = React.useCallback(
    async (items: string[]) => {
      setCustomVocabMutationRunning(true);
      setCustomVocabulary({ variables: { items } });
    },
    [setCustomVocabMutationRunning, setCustomVocabulary]
  );

  const [deleteWorkspace] = useMutation(deleteWorkspaceMutation);

  function handleWorkspaceWatermarkToggle(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    setHideWatermarks(e.target.checked);
    editWorkspace({
      variables: {
        hideWatermarks: e.target.checked
      }
    });
  }

  const maybeChangeName = async () => {
    if (workspace.name === workspaceName) return;
    const result = await showConfirm({
      confirmButtonType: 'primary',
      confirmContent: 'Change workspace name',
      cancelContent: 'Go back',
      description: 'This will affect all members of this workspace.',
      title: 'You are about to change the name of the workspace.',
      width: 480
    });
    if (result.isConfirm) {
      editWorkspace({
        variables: {
          name: workspaceName
        }
      });
    } else {
      setWorkspaceName(workspace.name);
    }
  };

  const handleNameKeyPress = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      ev.currentTarget.blur();
    }
  };

  const [addToPlan, setAddToPlan] = React.useState(true);

  React.useEffect(() => {
    if (isOnFreePlan) {
      setAddToPlan(false);
    }
  }, [isOnFreePlan]);

  const inviteLinkDisplayed =
    (addToPlan ? workspace?.planInviteLink : workspace?.inviteLink) || '';

  const handleCopyInviteLink = () => {
    copy(inviteLinkDisplayed);
    showToast({ content: 'Copied to clipboard.', type: 'success' });
  };

  const [regeneratePlanInviteCode] =
    useWorkspacePlanInviteCodeRegenerateMutation({
      onCompleted() {
        showToast({
          content: 'Invite link reset.',
          type: 'success',
          uniqueId: 'edit-workspace'
        });
      }
    });

  const [regenerateInviteCode] = useWorkspaceInviteCodeRegenerateMutation({
    onCompleted() {
      showToast({
        content: 'Invite link reset.',
        type: 'success',
        uniqueId: 'edit-workspace'
      });
    }
  });

  const handleWorkspaceLinkResetChange = () => {
    if (addToPlan) regeneratePlanInviteCode();
    else regenerateInviteCode();
  };

  const confirmDeleteWorkspace = React.useCallback(() => {
    showConfirm({
      width: 420,
      title: 'Are you sure you want to delete this workspace?',
      description:
        'This will immediately disable the workspace and permanently delete all data within it in seven days, including recordings, stories, and clips from all members.',
      cancelContent: 'Cancel',
      confirmContent: 'Delete workspace',
      confirmButtonType: 'danger',
      onConfirm: async () => {
        await deleteWorkspace();
        window.location.href = '/';
      }
    });
  }, [showConfirm, deleteWorkspace]);

  const domainInputRef = React.useRef<HTMLInputElement>(null);
  const [domainInputValue, setDomainInputValue] = React.useState('');
  const [addWorkspaceDomain] = useAddWorkspaceDomainMutation();
  const [removeWorkspaceDomain] = useRemoveWorkspaceDomainMutation();

  const cleanDomainInput = React.useCallback(() => {
    setDomainInputValue('');
    if (domainInputRef?.current) {
      domainInputRef.current.value = '';
    }
  }, [domainInputRef, setDomainInputValue]);

  const onDomainInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newVal = e?.target?.value ?? '';
      setDomainInputValue(newVal);
    },
    [setDomainInputValue]
  );

  const handleAddDomain = React.useCallback(() => {
    addWorkspaceDomain({
      variables: { domain: domainInputValue, force: false },
      onCompleted: () => {
        showToast({
          content: 'Domain added.',
          type: 'success'
        });
        cleanDomainInput();
      },
      onError: error => {
        switch (error?.message) {
          case 'group_with_domain_exists':
            showConfirm({
              width: 480,
              title: 'Confirm team domain',
              description: `Please confirm that all meeting participants with the domain "${domainInputValue}" should be marked as team. This applies to all previous meetings.`,
              cancelContent: 'Cancel',
              confirmContent: 'Confirm',
              confirmButtonType: 'primary',
              onConfirm: async () => {
                addWorkspaceDomain({
                  variables: { domain: domainInputValue, force: true },
                  onCompleted: () => {
                    showToast({
                      content: 'Domain added.',
                      type: 'success'
                    });
                    cleanDomainInput();
                  }
                });
              }
            });
            break;
          case 'invalid_domain':
            showToast({
              content: "The domain is either invalid or can't be used.",
              type: 'failure'
            });
            break;
          default:
            showToast({
              content: 'An error ocurred.',
              type: 'failure'
            });
        }
        return false;
      }
    });
  }, [
    addWorkspaceDomain,
    cleanDomainInput,
    domainInputValue,
    showConfirm,
    showToast
  ]);

  const onDomainInputKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.code === 'Enter' && domainInputValue !== '') {
        event.preventDefault();
        handleAddDomain();
      }
    },
    [domainInputValue, handleAddDomain]
  );

  const onDomainInputBlured = React.useCallback(() => {
    if (domainInputValue !== '') {
      handleAddDomain();
    }
  }, [domainInputValue, handleAddDomain]);

  const onDomainRemove = React.useCallback(
    (domain: string) => {
      removeWorkspaceDomain({
        variables: { domain },
        onCompleted: () => {
          showToast({
            content: 'Domain removed.',
            type: 'success'
          });
        }
      });
    },
    [removeWorkspaceDomain, showToast]
  );

  const requestToJoinOption = React.useMemo(() => {
    if (requestToJoinSetting === 'JOIN') {
      return requestJoinOptions[0];
    }
    if (requestToJoinSetting === 'REQUEST') {
      return requestJoinOptions[1];
    }
    return { title: '', value: '' };
  }, [requestToJoinSetting]);

  const requestToJoinDisabled = workspace?.authorizedDomains.length === 0;

  const handleRequestJoinChange = React.useCallback(
    (option: string) => {
      if (!requestToJoinSetting || option === requestToJoinSetting) return;
      const joinSetting =
        option === 'JOIN'
          ? AuthorizedDomainsJoinSetting.Join
          : AuthorizedDomainsJoinSetting.Request;
      setRequestToJoinSetting(joinSetting);
      editWorkspace({
        variables: {
          authorizedDomainsJoinSetting: joinSetting
        }
      });
    },
    [editWorkspace, requestToJoinSetting]
  );

  const [unapproveWorkspaceAuthorizedDomainMutation] =
    useUnapproveWorkspaceAuthorizedDomainMutation();

  const handleAllowedDomainRemove = React.useCallback(
    (domainName: string) => {
      unapproveWorkspaceAuthorizedDomainMutation({
        variables: {
          domain: domainName
        },
        onCompleted: () => {
          showToast({
            content: 'Allowed domain removed.',
            type: 'success'
          });
        }
      });
    },
    [showToast, unapproveWorkspaceAuthorizedDomainMutation]
  );

  const [approveWorkspaceAuthorizedDomainMutation] =
    useApproveWorkspaceAuthorizedDomainMutation();

  const handleAllowDomain = React.useCallback(
    (domainName: string) => {
      approveWorkspaceAuthorizedDomainMutation({
        variables: {
          domain: domainName
        },
        onCompleted: () => {
          showToast({
            content: 'Domain approved',
            type: 'success'
          });
        }
      });
    },
    [showToast, approveWorkspaceAuthorizedDomainMutation]
  );

  const pendingDomains =
    workspace?.authorizedDomains.filter(d => !d.approved) ?? [];
  const approvedDomains =
    workspace?.authorizedDomains.filter(d => d.approved) ?? [];
  const hasApprovedDomains = approvedDomains.length > 0;

  if (workspaceLoading) return null;

  return (
    <>
      {activeModal === 'upload_logo' && (
        <UploadLogoModal onCancel={() => setActiveModal(null)} />
      )}
      {activeModal === 'subscribe' && (
        <SubscribeModal onClose={() => setActiveModal(null)} />
      )}
      {activeModal === 'company_context' && (
        <CompanyContextModal onCancel={() => setActiveModal(null)} />
      )}
      {workspaceError && <ErrorBox error={workspaceError} />}
      <WorkspaceSection css={theme.utils.gap('lg')}>
        <SectionTitle>Workspace name</SectionTitle>

        <div
          css={css`
            display: grid;
            grid-template-columns: 36px 1fr;
            ${theme.utils.gap('md')};
          `}
        >
          <Logo
            name={workspace.name}
            url={workspace.logoUrl ?? undefined}
            size={36}
          />

          <Input
            css={css`
              max-width: 348px;
              height: 36px;
            `}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setWorkspaceName(e.target.value)
            }
            onKeyPress={handleNameKeyPress}
            onBlur={maybeChangeName}
            value={workspaceName}
          />

          <button
            css={css`
              all: unset;
              cursor: pointer;
              text-align: center;
              ${theme.tokens.typography.b4[400]}
              text-decoration: underline;
              color: ${theme.tokens.color.textSecondary};
            `}
            onClick={() => setActiveModal('upload_logo')}
          >
            Edit
          </button>
          <div
            css={css`
              ${theme.tokens.typography.b4[400]};
              color: ${theme.tokens.color.textSecondary};
            `}
          >
            We suggest using your company or organization name.
          </div>
        </div>
      </WorkspaceSection>
      <Divider />
      <WorkspaceSection css={theme.utils.gap('48px')}>
        <article>
          <SectionTitle>Invite link</SectionTitle>
          <StyledDescription css={[spacing.mb4]}>
            Anyone with your company’s email can join into a{' '}
            {isOnFreePlan ? (
              'Free Viewer'
            ) : (
              <Select
                value={addToPlan ? 'paid' : 'free'}
                onChange={value => setAddToPlan(value !== 'free')}
                entryComponent={props => (
                  <Select.Button {...props} size='sm' variant='neutral' />
                )}
              >
                <Option
                  value='paid'
                  label='Paid seat'
                  caption='Can record meetings and access all features on your plan'
                >
                  Paid
                </Option>
                <Option
                  value='free'
                  label='Free viewer seat'
                  caption='Can’t record meetings but can access all other features on your plan'
                >
                  Free viewer
                </Option>
              </Select>
            )}{' '}
            seat with the link
          </StyledDescription>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <Input
              size='lg'
              css={css`
                flex: 1 0 auto;
                max-width: 420px;
                margin-right: ${theme.tokens.spacing.md};

                &:has(input:disabled) {
                  background-color: unset;
                  color: ${theme.tokens.color.textSecondary};
                }

                input:disabled {
                  cursor: text;
                }
              `}
              value={inviteLinkDisplayed}
              disabled={true}
            />
            <ButtonRow css={theme.utils.gap('xs')}>
              <Button
                size='lg'
                variant='ghost'
                icon={Icon16.Copy}
                onClick={() => {
                  handleCopyInviteLink();
                  trackEvent(
                    'Workspace User Invited',
                    {
                      invite_trigger: 'link',
                      button_text: 'Copy Link',
                      location: 'page-content'
                    },
                    ['user', 'workspace']
                  );
                }}
              />
              <Menu
                width='fit-content'
                content={
                  <MenuButton
                    onClick={handleWorkspaceLinkResetChange}
                    textLabelProps={{ startIcon: Icon16.Refresh }}
                    label='Reset link'
                    caption='Disable the existing link and create a new one'
                  />
                }
              >
                <Button size='lg' variant='ghost' icon={Icon16.Overflow} />
              </Menu>
            </ButtonRow>
          </div>
        </article>
        <article>
          <SectionTitle>Joining this workspace</SectionTitle>
          <StyledDescription css={[spacing.mb4]}>
            Select how allowed email domains can join your workspace on sign up.{' '}
            <a
              href='https://support.grain.com/en/articles/9468054-grain-domain-management/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Learn more.
            </a>
          </StyledDescription>
          <div
            css={css`
              max-width: 420px;
            `}
          >
            <GatedSelect
              gate='paid_settings'
              fullWidth
              matchWidth
              value={requestToJoinOption?.value}
              onChange={value => handleRequestJoinChange(value)}
              disabled={requestToJoinDisabled}
            >
              {requestJoinOptions.map(option => (
                <GatedOption
                  key={option.value}
                  value={option.value}
                  gated={option.gated}
                >
                  {requestToJoinDisabled
                    ? 'There are no allowed domains in this workspace'
                    : option.title}
                </GatedOption>
              ))}
            </GatedSelect>
          </div>
        </article>
        <article>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <SectionTitle>Allowed domains</SectionTitle>
            <SectionDescription>
              {`${approvedDomains.length} domain${
                approvedDomains.length > 1 || approvedDomains.length === 0
                  ? 's'
                  : ''
              }`}
            </SectionDescription>
          </div>
          {hasApprovedDomains ? (
            <StyledDomainsContainer>
              {approvedDomains.map(domain => (
                <StyledAllowedDomainsPill
                  key={`allowed-domain-${domain.domain}`}
                >
                  <Icon.At />
                  <StyledDomain>{domain.domain}</StyledDomain>
                  <Tooltip content='Disallow domain'>
                    <Icon.Close
                      css={[`cursor: pointer; color: ${color.crow};`]}
                      onClick={() => handleAllowedDomainRemove(domain.domain)}
                    />
                  </Tooltip>
                </StyledAllowedDomainsPill>
              ))}
            </StyledDomainsContainer>
          ) : (
            <StyledDomainApproveBox>
              There are no allowed domains in this workspace.
            </StyledDomainApproveBox>
          )}
          {pendingDomains.length > 0 && (
            <>
              <StyledSubheadingContainer>
                <div className='sub-heading'>Need approval</div>
                <div className='counter'>
                  {`${pendingDomains.length} domain${
                    pendingDomains.length > 1 ? 's' : ''
                  }`}
                </div>
              </StyledSubheadingContainer>
              {pendingDomains.map(domain => (
                <StyledDomainApproveBox key={`pending-domain-${domain.domain}`}>
                  <div css={['display: flex; align-items: center;']}>
                    <Icon.At />
                    <StyledDomain>{domain.domain}</StyledDomain>
                  </div>
                  <LegacyButton
                    type='secondary'
                    onClick={() => handleAllowDomain(domain.domain)}
                  >
                    Allow
                  </LegacyButton>
                </StyledDomainApproveBox>
              ))}
            </>
          )}
        </article>
      </WorkspaceSection>
      <Divider />
      <WorkspaceSection css={theme.utils.gap('sm')}>
        <header>
          <SectionTitle>Team email domains</SectionTitle>
          <SectionDescription>
            Add domains that Grain should use to identify team meetings.{' '}
            <a
              href='https://support.grain.com/en/articles/9468054-grain-domain-management'
              target='_blank'
              rel='noopener noreferrer'
            >
              Learn more.
            </a>
          </SectionDescription>
        </header>
        <StyledDomainsContainer>
          {(workspace?.domains ?? []).length > 0 &&
            workspace?.domains.map(({ domain, deletable }) => (
              <StyledPill key={`domain-${domain}`} deletable={deletable}>
                <Icon.At />
                <StyledDomain>{domain}</StyledDomain>
                {deletable && (
                  <Icon.Close
                    css={['cursor: pointer']}
                    onClick={() => onDomainRemove(domain)}
                  />
                )}
              </StyledPill>
            ))}
          <GrowingInput
            placeholder='Add domain'
            placeholderIcon={<Icon.At />}
            placeholderIconAlwaysVisible={true}
            ref={domainInputRef}
            onChange={onDomainInputChange}
            onKeyDown={onDomainInputKeyDown}
            onBlur={onDomainInputBlured}
          />
        </StyledDomainsContainer>
      </WorkspaceSection>
      <Divider />
      <WorkspaceSection css={theme.utils.gap('lg')}>
        <header>
          <SectionTitle>Company context</SectionTitle>
          <StyledDescription>
            Help Grain understand more about your company to improve AI outputs.
          </StyledDescription>
        </header>

        <Button
          variant='neutral'
          size='lg'
          onClick={() => {
            setActiveModal('company_context');
            trackEvent('Workspace Context Button Clicked', {
              button_text: hasCompanyContext ? 'Edit' : 'Get Started'
            });
          }}
          css={css`
            align-self: flex-start;
          `}
        >
          {hasCompanyContext ? 'Edit' : 'Get started'}
        </Button>
      </WorkspaceSection>
      {
        // Disable section until we decide to use it or scrap it completely
      }
      {showWatermarkHiddenFeature &&
        myself?.user?.role === UserRole.Admin &&
        false && (
          <>
            <Divider />
            <WorkspaceSection>
              <SectionTitle>Remove watermark</SectionTitle>
              <SectionDescription>
                Remove Grain watermark from clips
              </SectionDescription>

              <div className='switch'>
                <Switch
                  checked={hideWatermarks}
                  onChange={handleWorkspaceWatermarkToggle}
                />
              </div>
            </WorkspaceSection>
          </>
        )}
      <Divider />
      <WorkspaceSection>
        <CustomVocabulary
          loading={vocabLoading || customVocabMutationRunning}
          items={
            (vocabLoading || customVocabMutationRunning) &&
            submittableCustomVocab.length === 0
              ? ['  ']
              : submittableCustomVocab
          }
          onSave={onCustomVocabSave}
          onItemsChanged={onCustomVocabChange}
          gateWrapper={GatedWrapper}
        />
      </WorkspaceSection>
      <Divider />
      <WorkspaceSection>
        <WorkspaceTitleButton onClick={confirmDeleteWorkspace}>
          Delete workspace
        </WorkspaceTitleButton>
        <SectionDescription>
          This will permanently delete the workspace and all data in it.
        </SectionDescription>
      </WorkspaceSection>
    </>
  );
}
