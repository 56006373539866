import { useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { isToday, sub } from 'date-fns';

import {
  DealMomentumState,
  type Deal,
  type DealMomentumUpdate,
  type DealStageUpdate,
  type Group,
  type Workspace
} from '@grain/api/schema.generated';
import { theme } from '@grain/grain-ui/v4';

import {
  getActivityTimestamp,
  getLastDates,
  formatDate,
  formatDateString
} from '../../utils';

import type {
  DealActivityFragment,
  DealDetailsFragment,
  DealDetailsRecordingFragment,
  DealEmailFragment
} from '../../deal.generated';

import {
  type ActivitySection,
  type ActivitySectionName,
  ActivityForDateMenu
} from './ActivityForDateMenu';

type BarMap = {
  [key: string]: { incoming: number; outgoing: number };
};

type StageUpdateMap = {
  [key: string]: DealStageUpdate;
};

type MomentumUpdateMap = {
  [key: string]: DealMomentumUpdate;
};

type MomentumStateMap = {
  [key: string]: DealMomentumState;
};

type ActivityMap = {
  [key: string]: ActivitySection[];
};

const ACTIVITY_SECTION_SORT: { [K in ActivitySectionName]: number } = {
  team: 1,
  customer: 2,
  meetings: 3
};

const getActivitySection = (
  teamDomains: string[],
  activity: DealEmailFragment | DealDetailsRecordingFragment
): ActivitySectionName | undefined => {
  switch (activity.__typename) {
    case 'DealEmail': {
      const email = activity.from.email;
      const domain = email.split('@')[1];
      return teamDomains.includes(domain) ? 'team' : 'customer';
    }
    case 'Recording':
      return 'meetings';
  }
};

const getMomentumBarColor = (isOpen: boolean, state: DealMomentumState) => {
  if (!isOpen) return theme.tokens.color.iconDisabled;
  switch (state) {
    case DealMomentumState.Progressing:
      return theme.tokens.color.iconBrand;
    case DealMomentumState.Slowing:
      return theme.tokens.color.iconWarning;
    case DealMomentumState.Stalled:
      return theme.tokens.color.iconDanger;
    default:
      return theme.tokens.color.borderPrimary;
  }
};

const MAX_ACTIVITY_UNITS = 5;
const ONE_UNIT_OF_ACTIVITY_HEIGHT = 4.4;
const MIN_ACTIVITY_BAR_HEIGHT = 1;

const activityToHeight = (activity: number) => {
  return (
    Math.min(activity, MAX_ACTIVITY_UNITS) * ONE_UNIT_OF_ACTIVITY_HEIGHT +
    MIN_ACTIVITY_BAR_HEIGHT
  );
};

const formatDateOrToday = (date: Date) => {
  if (isToday(date)) return 'Today';
  return formatDate(date);
};

const StyledMomentumTimeline = styled.div`
  display: flex;
  align-items: flex-end;
  height: 86px; // Set height to contain "position: absolute" stage updates
`;

const StyledMomentumTimelineContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.tokens.spacing.lg};
  width: 100%;
`;

const StyledIntervalDate = styled.span`
  ${theme.tokens.typography.b4[500]};
  white-space: nowrap;
  color: ${theme.tokens.color.textTertiary};
`;

const StyledMomentumBars = styled.div`
  flex: 1;
  display: flex;
  height: 46px; // Would be max dynamic height; ensure consistent height
`;

const StyledMomentumBarContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;

  .tippy-wrapper {
    // Create padding that has the same visual effect as a thick border:
    position: relative;
    top: -4px;
    height: calc(100% + 8px);
    ${theme.utils.py('2xs')};
    border-radius: ${theme.tokens.radii.md};
    cursor: pointer;

    display: flex;
    justify-content: center;
  }

  &:hover .tippy-wrapper {
    background-color: ${theme.tokens.color.surfaceTertiary};
  }
`;

const StyledStageUpdateContainer = styled.div`
  position: absolute;
  top: -38px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60px; // Provide 24px extra for the line to extend down
  pointer-events: none; // Don't trigger hover effects
`;

const StyledStageUpdateChip = styled.div`
  padding: 2px 10px;
  border-radius: ${theme.tokens.radii.full};
  ${theme.tokens.typography.b4[500]};
  white-space: nowrap;
  border: 1px solid ${theme.tokens.color.borderTertiary};
  background-color: ${theme.tokens.color.surfacePrimary};
  color: ${theme.tokens.color.textSecondary};
`;

const StyledStageUpdateLine = styled.div`
  flex: 1; // Extend the line all the way down
  width: 1px;
  background-color: ${theme.tokens.color.borderTertiary};
`;

const StyledMomentumBar = styled.div`
  z-index: 1; // Cover stage update lines
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${theme.tokens.radii.xs};
`;

const StyledMomentumBarItem = styled.div`
  height: 50%;
  display: flex;
`;

type BarProps = { color: string; height: number; hasOpacity?: boolean };

const StyledMomentumBarHalf = styled.div<BarProps>`
  background: ${({ color }) => color};
  height: ${({ height }) => height}px;
  ${({ hasOpacity }) => hasOpacity && 'opacity: 0.4;'}
  width: 6px;
`;

const StyledMomentumBarT = styled(StyledMomentumBarHalf)`
  border-radius: 2px 2px 0 0;
`;

const StyledMomentumBarB = styled(StyledMomentumBarHalf)`
  border-radius: 0 0 2px 2px;
`;

type MomentumTimelineProps = {
  workspace: Pick<Workspace, 'domains' | 'name' | 'logoUrl'>;
  deal: DealDetailsFragment;
  group: Pick<Group, 'name'>;
  activity?: DealActivityFragment[];
  activityBar?: Deal['activityBar'];
  momentumUpdates?: Deal['momentumUpdates'];
  stageUpdates?: Deal['stageUpdates'];
};

export function MomentumTimeline({
  workspace,
  deal,
  group,
  activity,
  activityBar,
  momentumUpdates,
  stageUpdates
}: MomentumTimelineProps) {
  const last30Dates = useMemo(() => {
    const startDate =
      !deal.isOpen && deal.closedAt ? new Date(deal.closedAt) : new Date();
    return getLastDates(30, startDate);
  }, [deal]);
  const earliestDate = last30Dates[0];
  const latestDate = last30Dates[last30Dates.length - 1];

  const barMap: BarMap = useMemo(
    () =>
      activityBar?.reduce((obj: BarMap, item) => {
        obj[item.date] = item;
        return obj;
      }, {}) ?? {},
    [activityBar]
  );

  const stageUpdateMap: StageUpdateMap = useMemo(
    () =>
      stageUpdates?.reduce((obj: StageUpdateMap, item) => {
        obj[item.date] = item;
        return obj;
      }, {}) ?? {},
    [stageUpdates]
  );

  const momentumUpdateMap: MomentumUpdateMap = useMemo(
    () =>
      momentumUpdates?.reduce((obj: MomentumUpdateMap, item) => {
        obj[item.date] = item;
        return obj;
      }, {}) ?? {},
    [momentumUpdates]
  );

  const momentumStateMap: MomentumStateMap = useMemo(
    () =>
      last30Dates.reduce((obj: MomentumStateMap, date) => {
        const dateString = formatDateString(date);
        const momentumUpdate = momentumUpdateMap[dateString];
        if (momentumUpdate) {
          obj[dateString] = momentumUpdate.state;
        } else {
          // Fill in blanks by copying the previous day's state.
          const prevDate = sub(new Date(date), { days: 1 });
          const prevState = obj[formatDateString(prevDate)];
          if (prevState) obj[dateString] = prevState;
        }
        return obj;
      }, {}),
    [last30Dates, momentumUpdateMap]
  );

  const teamDomains = useMemo(
    () => workspace?.domains.map(domain => domain.domain),
    [workspace]
  );

  // Generate a mapping of dates to "activity sections" which will map closesly
  // to the design of the popup we want to show when hovering over days in the
  // timeline.  The mapping looks like this (all sections optional):
  //
  //   {
  //     2024-08-15: [
  //       { name: 'team', activity: [DealEmail, DealEmail, ...] },
  //       { name: 'customer', activity: [DealEmail, DealEmail, ...] },
  //       { name: 'meetings', activity: [Recording, Recording, ...] }
  //     ],
  //     2024-08-20: [
  //       { name: 'team', activity: [DealEmail, DealEmail, ...] },
  //       { name: 'customer', activity: [DealEmail, DealEmail, ...] },
  //       { name: 'meetings', activity: [Recording, Recording, ...] }
  //     ],
  //     ...
  //   }
  //
  const activityMap: ActivityMap = useMemo(() => {
    if (!activity || !teamDomains) return {};
    const unsortedMap = activity.reduce((obj: ActivityMap, item) => {
      if (
        !(item.__typename === 'DealEmail' || item.__typename === 'Recording')
      ) {
        return obj;
      }
      const sectionName = getActivitySection(teamDomains, item)!;
      const newSection: ActivitySection = {
        name: sectionName,
        activity: [item]
      };
      const dateString = formatDateString(new Date(getActivityTimestamp(item)));
      if (obj[dateString]) {
        const existingSection = obj[dateString].find(
          section => section.name === sectionName
        );
        if (existingSection) {
          existingSection.activity.push(item);
        } else {
          obj[dateString].push(newSection);
        }
      } else {
        obj[dateString] = [newSection];
      }
      return obj;
    }, {});
    return Object.fromEntries(
      Object.entries(unsortedMap).map(([key, sections]) => [
        key,
        sections.sort(
          (a, b) =>
            ACTIVITY_SECTION_SORT[a.name] - ACTIVITY_SECTION_SORT[b.name]
        )
      ])
    );
  }, [activity, teamDomains]);

  return (
    <StyledMomentumTimeline aria-label='Momentum timeline showing incoming and outgoing activity over time'>
      <StyledMomentumTimelineContent>
        <StyledIntervalDate>
          {formatDateOrToday(earliestDate)}
        </StyledIntervalDate>
        <StyledMomentumBars>
          {last30Dates.map(date => {
            const dateString = formatDateString(date);
            const openDateString = formatDateString(
              new Date(deal.openedAt ?? '')
            );
            const isOpenDate = dateString === openDateString;
            const info = barMap[dateString];
            const stageUpdate = stageUpdateMap[dateString];
            const momentumState = momentumStateMap[dateString];
            const activity = activityMap[dateString];

            return (
              <StyledMomentumBarContainer key={dateString}>
                {(isOpenDate || stageUpdate) && (
                  <StyledStageUpdateContainer>
                    <StyledStageUpdateChip>
                      {isOpenDate ? 'Deal Opened' : stageUpdate.label}
                    </StyledStageUpdateChip>
                    <StyledStageUpdateLine />
                  </StyledStageUpdateContainer>
                )}
                <ActivityForDateMenu
                  childWidth='100%'
                  workspace={workspace}
                  deal={deal}
                  group={group}
                  date={date}
                  isOpenDate={isOpenDate}
                  stageUpdate={stageUpdate?.label}
                  momentum={momentumState}
                  sections={activity}
                >
                  <StyledMomentumBar className='momentum-bar'>
                    <StyledMomentumBarItem css={['align-items: flex-end']}>
                      <StyledMomentumBarT
                        color={getMomentumBarColor(deal.isOpen, momentumState)}
                        height={activityToHeight(info?.outgoing ?? 0)}
                        hasOpacity={info && !info.outgoing}
                      />
                    </StyledMomentumBarItem>
                    <StyledMomentumBarItem css={['align-items: flex-start']}>
                      <StyledMomentumBarB
                        color={getMomentumBarColor(deal.isOpen, momentumState)}
                        height={activityToHeight(info?.incoming ?? 0)}
                        hasOpacity={!!info?.incoming}
                      />
                    </StyledMomentumBarItem>
                  </StyledMomentumBar>
                </ActivityForDateMenu>
              </StyledMomentumBarContainer>
            );
          })}
        </StyledMomentumBars>
        <StyledIntervalDate>{formatDateOrToday(latestDate)}</StyledIntervalDate>
      </StyledMomentumTimelineContent>
    </StyledMomentumTimeline>
  );
}

export function SkeletonTimeline() {
  const last30Dates = useMemo(() => getLastDates(30), []);
  return (
    <StyledMomentumTimeline aria-label='Momentum timeline showing incoming and outgoing activity over time (example, no real data)'>
      <StyledMomentumTimelineContent>
        <div
          css={css`
            display: inline-block;
            width: 32px;
            height: 16px;
            background: ${theme.tokens.color.surfaceTertiary};
            border-radius: ${theme.tokens.radii.md};
          `}
        />
        <StyledMomentumBars>
          {last30Dates.map(date => {
            const dateString = formatDateString(date);
            return (
              <StyledMomentumBarContainer key={dateString}>
                <StyledMomentumBar className='momentum-bar'>
                  <StyledMomentumBarItem css={['align-items: flex-end']}>
                    <StyledMomentumBarT
                      color={theme.tokens.color.surfaceTertiary}
                      height={activityToHeight(0)}
                    />
                  </StyledMomentumBarItem>
                  <StyledMomentumBarItem css={['align-items: flex-start']}>
                    <StyledMomentumBarB
                      color={theme.tokens.color.surfaceTertiary}
                      height={activityToHeight(0)}
                    />
                  </StyledMomentumBarItem>
                </StyledMomentumBar>
              </StyledMomentumBarContainer>
            );
          })}
        </StyledMomentumBars>
        <div
          css={css`
            display: inline-block;
            width: 32px;
            height: 16px;
            background: ${theme.tokens.color.surfaceTertiary};
            border-radius: ${theme.tokens.radii.md};
          `}
        />
      </StyledMomentumTimelineContent>
    </StyledMomentumTimeline>
  );
}
