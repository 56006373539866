import styled from '@emotion/styled';
import { color, pxToRem } from '@grain/grain-ui';

export const StyledContentContainer = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  .description {
    ${color.fg.blackbird};
    font-feature-settings:
      'cpsp' on,
      'case' on,
      'clig' off,
      'liga' off;
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
  }

  .sub-heading {
    ${color.fg.crow};

    font-feature-settings:
      'cpsp' on,
      'case' on,
      'clig' off,
      'liga' off;
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(16)};
    width: 100%;
  }

  .action-buttons {
    display: flex;
    align-items: flex-start;
    gap: 16px;
  }

  .option-select-options-list-wrapper {
    ul {
      overflow-y: auto !important;
      max-height: 250px !important;
    }
  }
`;
