import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon16, theme } from '@grain/grain-ui/v4';

import {
  ModalInfo,
  ModalGraphic,
  ModalActions
} from '~/modals/InfoGraphicActionModals';
import { BusinessBentoBox } from '~/components/BusinessBentoBox';
import { useSubscriptionTrialBeginMutation } from '~/modules/freemium/freemium.generated';

import type { StepComponentProps } from '../types';

export function SalesPitch({
  source,
  handleBack,
  handleNext
}: StepComponentProps) {
  const [subscriptionTrialBegin] = useSubscriptionTrialBeginMutation();
  const [loading, setLoading] = useState(false);

  const handleStartTrial = () => {
    setLoading(true);
    subscriptionTrialBegin({
      onCompleted() {
        handleNext(1);
        setLoading(false);
      }
    });
  };

  return (
    <>
      <ModalInfo
        prelude='Try Grain Business free for 14-days'
        title='Automate time consuming tasks so you can focus on growing revenue'
      />
      <ModalGraphic>
        <BusinessBentoBox />
      </ModalGraphic>
      <ModalActions>
        {handleBack ? (
          <Button
            variant='ghost'
            size='lg'
            textLabelProps={{ startIcon: Icon16.ChevronLeft }}
            onClick={handleBack}
          >
            {source ? `Back to ${source}` : 'Back'}
          </Button>
        ) : (
          <Button
            variant='ghost'
            size='lg'
            textLabelProps={{ endIcon: Icon16.ExternalLink }}
            as={Link}
            to='https://support.grain.com/en/articles/9253206-what-s-included-in-grain-s-free-14-day-business-plan-trial'
            target='_blank'
            rel='noopener noreferrer'
          >
            Learn more
          </Button>
        )}
        <Button
          variant='plan'
          size='lg'
          onClick={handleStartTrial}
          css={[
            `padding: ${theme.tokens.spacing.sm} ${theme.tokens.spacing['3xl']};`
          ]}
          spinning={loading}
        >
          Start trial
        </Button>
      </ModalActions>
    </>
  );
}
