import { useCallback, useEffect, useMemo, useState } from 'react';

export enum VideoCollapseState {
  COLLAPSED = 0,
  EXPANDED = 1,
  COLLAPSED_FROM_CHAT = 2
}

const DESERIALIZE_MAP = {
  true: VideoCollapseState.COLLAPSED,
  false: VideoCollapseState.COLLAPSED,
  [VideoCollapseState.COLLAPSED.toString()]: VideoCollapseState.COLLAPSED,
  [VideoCollapseState.EXPANDED.toString()]: VideoCollapseState.EXPANDED
} as const;

export const useVideoCollapsedState = () => {
  const [state = VideoCollapseState.EXPANDED, setState] =
    useState<VideoCollapseState>(() => {
      try {
        const value = localStorage.getItem('recording_page_video_collapsed');
        if (value && value in DESERIALIZE_MAP) {
          return DESERIALIZE_MAP[value];
        }

        return VideoCollapseState.EXPANDED;
      } catch (e) {
        return VideoCollapseState.EXPANDED;
      }
    });

  useEffect(() => {
    localStorage.setItem('recording_page_video_collapsed', state.toString());
  }, [state]);

  const toggle = useCallback(() => {
    setState(() => {
      switch (state) {
        case VideoCollapseState.COLLAPSED:
        case VideoCollapseState.COLLAPSED_FROM_CHAT:
          return VideoCollapseState.EXPANDED;
        case VideoCollapseState.EXPANDED:
          return VideoCollapseState.COLLAPSED;
      }
    });
  }, [state, setState]);

  return useMemo(
    () => ({
      state,
      toggle,
      setState,
      collapsed:
        state === VideoCollapseState.COLLAPSED ||
        state === VideoCollapseState.COLLAPSED_FROM_CHAT
    }),
    [state, toggle, setState]
  );
};
