import { useWorkspace } from '@grain/api/auth';
import { Icon, Button } from '@grain/grain-ui';
import React from 'react';
import { getBillingDisplayDate } from '../../modules/subscriptions/helpers';
import {
  StyledContent,
  StyledConfirmationHeader,
  StyledConfirmationContent,
  StyledButtons,
  PageWrapper
} from './styles';
import { useWorkspacePlan } from '@grain/components/Subscriptions/hooks';

import { CancelStepsProps } from './CancelTypes';
import { displayCents } from '../../support/price';

export const Confirmation = ({
  hasRenewed,
  billingPeriod,
  netPrice,
  renewalPrice,
  closeModal
}: CancelStepsProps) => {
  const { workspace } = useWorkspace();
  const isDiscountApplied = hasRenewed;
  const { subscriptionPlan } = useWorkspacePlan();
  const percentageDiscounted = billingPeriod === 'YEARLY' ? 25 : 40;
  const disocuntText =
    billingPeriod === 'YEARLY'
      ? `Your annual cost has gone from $${displayCents(
          renewalPrice
        )}/year to $${displayCents(netPrice)}/year.`
      : `Your monthly cost has gone from $${displayCents(
          renewalPrice
        )}/mo to $${displayCents(netPrice)}/mo.`;
  return (
    <PageWrapper>
      <StyledContent>
        <StyledConfirmationHeader>
          <span>
            {isDiscountApplied ? 'Discount applied' : 'Account cancelled'}
          </span>
          <span onClick={closeModal}>
            {' '}
            <Icon.Close />{' '}
          </span>
        </StyledConfirmationHeader>
        <StyledConfirmationContent>
          {isDiscountApplied ? <Icon.LoveStruckEye /> : <Icon.Smile />}
          <h5>
            {isDiscountApplied
              ? `A ${percentageDiscounted}% discount has been applied`
              : `Your ${subscriptionPlan?.name} plan has been cancelled`}
          </h5>
          <p>
            {isDiscountApplied ? (
              disocuntText
            ) : (
              <>
                You can use your current paid features until the end of your
                billing period on{' '}
                <b>
                  {getBillingDisplayDate(
                    workspace?.workspaceSubscription?.renewalDate ?? ''
                  )}
                </b>{' '}
                We’ll miss you!{' '}
              </>
            )}
          </p>
        </StyledConfirmationContent>
      </StyledContent>
      <StyledButtons style={{ marginTop: '32px', alignSelf: 'center' }}>
        <Button variant='primary' onClick={closeModal}>
          Got it
        </Button>
      </StyledButtons>
    </PageWrapper>
  );
};
