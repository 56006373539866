import { ToastProvider } from '@grain/grain-ui';
import Notification from './Notification';
import styled from '@emotion/styled';

const NotificationsWrapper = styled.div`
  background-color: #000000;
  > div {
    padding: 0;
    position: absolute;
    top: 8px;
    left: 8px;

    > div {
      padding: 0;
    }
  }
`;

export default () => {
  return (
    <NotificationsWrapper>
      <ToastProvider>
        <Notification />
      </ToastProvider>
    </NotificationsWrapper>
  );
};
