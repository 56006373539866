import { useDesktop } from '@grain/desktop-lib';
import { useActiveRecordings } from '@grain/components/ActiveRecordingsContext';
import { useWebSocket } from '@grain/components/WebSocketContext';
import { useFeature } from '@grain/api/auth';
import { useBot } from '~/components/StartBot';
import client from '~/support/apollo-client';

import React, { useMemo } from 'react';

const RegisterContextForDesktop = () => {
  const { setAppContext } = useDesktop();
  const recordingContext = useActiveRecordings();
  const webSocketContext = useWebSocket();
  const botContext = useBot();

  const { enabled: isCreateRecordingEnabled } = useFeature('create_recording');

  const contextValue = useMemo(
    () => ({
      recordingContext,
      webSocketContext,
      botContext,
      apolloClient: client,
      canRecord: isCreateRecordingEnabled
    }),
    [webSocketContext, recordingContext, botContext, isCreateRecordingEnabled]
  );

  React.useEffect(() => {
    setAppContext(contextValue);
  }, [contextValue, setAppContext]);
};

export default RegisterContextForDesktop;
