import styled from '@emotion/styled';
import { color, colors, font, spacing } from '@grain/styles/constants';
import { getSectionDarkColorByNumber } from '~/pages/StoryPage/constants';
import { overflowEllipsis, resetButtonStyles, pxToRem } from '@grain/grain-ui';

export const StyledRightAction = styled.button`
  flex-shink: 0;
  ${resetButtonStyles};
  width: 16px;
  height: 16px;
  ${spacing.ml2};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.crow};
  cursor: pointer;

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const StyledHandle = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.crow};
  ${spacing.mr2};
  cursor: grab;
  flex-shrink: 0;

  &:before {
    position: absolute;
    left: -4px;
    top: -4px;
    bottom: -4px;
    right: -4px;
    content: '';
  }
`;

// NOTE: Margins should not be added to this component. They should be added
// directly to the top child of <Droppable> to properly calculate placeholders
export const StyledItemWrapper = styled.div`
  display: flex;
  height: 24px;
  border-radius: 4px;
  ${spacing.px2};
  display: flex;
  align-items: center;
  ${font.bodyS};
  font-weight: 500;
  user-select: none;

  ${StyledHandle}, ${StyledRightAction} {
    visibility: hidden;
  }

  &:hover:not(.disable-hover) {
    background-color: ${colors.gull};
    ${StyledHandle}, ${StyledRightAction} {
      visibility: visible;
    }
  }

  ${props =>
    props.highlighted &&
    `
      // background-color: ${colors.gull};
      background-color: ${colors.gull};
      filter: brightness(90%);
      ${StyledHandle} {
        visibility: visible;
      }
    `}
`;

export const StyledText = styled.div`
  color: ${props =>
    props.colorNumber >= 0
      ? getSectionDarkColorByNumber(props.colorNumber)
      : colors.blackbird};
  ${overflowEllipsis};
  ${spacing.ml2};
  flex: 1;
`;

export const StyledThumbnail = styled.img`
  width: 28px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 2px;
`;

export const StyledSectionTitle = styled.div`
  ${color.swan};
  background: ${colors.raven};
  border-radius: 4px;
  display: flex;
  margin-right: auto;
  overflow: hidden;

  .title {
    ${overflowEllipsis};
    font-size: calc(12rem / 16);
    font-weight: 500;
    padding: 2px 14px;
  }
  .colored-flag {
    background-color: ${props =>
      getSectionDarkColorByNumber(props.colorNumber)};
    flex-shrink: 0;
    height: 20px;
    width: 6px;
  }
`;

export const StyledDupeLabel = styled.div`
  background: ${colors.gull};
  border-radius: 8px;
  font-size: ${pxToRem(10)};
  padding: 2px 8px;
`;
