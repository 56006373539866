import { useEffect, useState } from 'react';
import { addMinutes, addSeconds, formatDistanceToNowStrict } from 'date-fns';
import styled from '@emotion/styled';
import { font, spaces } from '@grain/grain-ui';

function formatUpdatedMessage(date: Date) {
  // Similar logic as used for comments:
  if (new Date() < addSeconds(date, 10)) {
    return 'updated just now';
  } else if (new Date() < addMinutes(date, 1)) {
    const seconds = formatDistanceToNowStrict(date, {
      unit: 'second',
      roundingMethod: 'floor'
    }).replace('second', 'sec');
    return `last updated ${seconds} ago`;
  } else {
    const minutes = formatDistanceToNowStrict(date, {
      unit: 'minute',
      roundingMethod: 'floor'
    }).replace('minute', 'min');
    return `last updated ${minutes} ago`;
  }
}

const StyledContainer = styled.div`
  margin-top: ${spaces[7]};
  margin-bottom: ${spaces[9]};
  display: flex;
  flex-direction: column;
  gap: ${spaces[1]};
  ${font.v4.b4[500]};
  text-align: center;
`;

const StyledLastUpdated = styled.div`
  ${font.v4.c2[500]};
`;

type ProcessingTranscriptProps = {
  lastUpdated: Date;
};

export function ProcessingTranscript({
  lastUpdated
}: ProcessingTranscriptProps) {
  // Re-render at least every few seconds
  const [tick, setTick] = useState(0);
  useEffect(() => {
    const timeout = setTimeout(() => setTick(tick + 1), 10000);
    return () => clearTimeout(timeout);
  }, [lastUpdated /* Reset on update */, tick]);

  const lastUpdatedMessage = lastUpdated && formatUpdatedMessage(lastUpdated);

  return (
    <StyledContainer>
      <div>Processing transcript…</div>
      {lastUpdatedMessage && (
        <StyledLastUpdated>{lastUpdatedMessage}</StyledLastUpdated>
      )}
    </StyledContainer>
  );
}
