import { useCallback, useState } from 'react';
import {
  type IntelligenceAskQuery,
  AskQueryStatus
} from '@grain/api/schema.generated';
import { useExperiment } from '@grain/api/auth';
import { Button, Icon16 } from '@grain/grain-ui/v4';

import {
  useIntelligenceAskQueryEvaluateMutation,
  useIntelligenceAskQueryUpdatedSubscription
} from './ask.generated';

// To use "limited evaluation" mode (make queries run about 3 times faster)
// you can add "[fast]" to either the beginning or ending of your input.
function isLimitedEvaluationQuery(query: string) {
  return /\[fast\]/i.test(query);
}

// Clean out syntax like "[fast]" from the query before submitting it.
function stripModifiersFromQuery(query: string) {
  return query
    .trim()
    .replace(/^\[\w+\]/, '')
    .replace(/\[\w+\]$/, '')
    .trim();
}

const EMPTY_ARRAY: never[] = [];

// Keep track of successful ask queries to make sure not to report a result from
// the same query twice.  The API seems like it could either return a result
// from the initial mutation or the subscription.  Since we have both, if the
// mutation actually provided a result, then the subscription would probably
// immediately fire afterwards too.  (During my testing, the mutation never
// provided a result, so keep track just to be safe.)

function getSuccessfulAskQueries(): string[] {
  try {
    return JSON.parse(sessionStorage['successful-ask-queries']);
  } catch {
    return EMPTY_ARRAY;
  }
}

function pushSuccessfulAskQuery(id: string) {
  const ids = getSuccessfulAskQueries();
  sessionStorage['successful-ask-queries'] = JSON.stringify(
    Array.from(new Set([...ids, id]))
  );
}

type QueryContextArgs = {
  dealId: string;
  // recordingId, et al may be added here later
};

type AskAIButtonProps = {
  queryContext: QueryContextArgs;
};

export function AskAIButton({ queryContext }: AskAIButtonProps) {
  const { enabled: askAiEnabled } = useExperiment('intelligence_ask_v2');
  const [loading, setLoading] = useState(false);

  const [intelligenceAskQueryEvaluate] =
    useIntelligenceAskQueryEvaluateMutation({
      variables: {
        ...queryContext,
        query: ''
      }
    });

  const handleAskQueryResult = useCallback(
    (askQueryResult?: IntelligenceAskQuery) => {
      if (!askQueryResult) return;
      const { id, query, result, status } = askQueryResult;
      if (getSuccessfulAskQueries().includes(id)) return;
      if (query && result && status === AskQueryStatus.Success) {
        const message = `You asked: ${query}\n\nAI responded: ${result}`;
        alert(message);
        // Log it too because `alert()` truncates long responses in Chrome:
        console.info(message); // eslint-disable-line no-console
        pushSuccessfulAskQuery(id);
        setLoading(false);
      }
    },
    []
  );

  useIntelligenceAskQueryUpdatedSubscription({
    skip: Boolean(!askAiEnabled),
    onData({ data }) {
      handleAskQueryResult(data.data?.intelligenceAskQueryUpdated);
    }
  });

  const handleClick = useCallback(async () => {
    const rawQuery = prompt('Ask AI a question:');
    if (!rawQuery) return;
    const limitedEvaluation = isLimitedEvaluationQuery(rawQuery);
    const query = stripModifiersFromQuery(rawQuery);
    setLoading(true);
    const { data, errors } = await intelligenceAskQueryEvaluate({
      variables: { ...queryContext, limitedEvaluation, query }
    });
    if (!data) {
      const error = Array.isArray(errors) ? errors[0] : errors;
      if (error?.message) {
        alert(`Query failed with following error: ${error.message}`);
      }
      setLoading(false);
      return;
    }
    handleAskQueryResult(data.intelligenceAskQueryEvaluate);
  }, [handleAskQueryResult, intelligenceAskQueryEvaluate, queryContext]);

  if (!askAiEnabled) return null;

  return (
    <Button
      icon={Icon16.Sparkle}
      variant='stroked'
      disabled={loading}
      onClick={handleClick}
    >
      {loading ? 'Thinking…' : 'Ask AI'}
    </Button>
  );
}
