import { Question, CustomScorecardWithQuestionType } from './types';

export const manageScorecardInitialState: CustomScorecardWithQuestionType = {
  id: '',
  title: '',
  description: '',
  visible: true,
  questions: {
    schema_version: 1,
    questions: []
  }
};

export enum ActionTypes {
  SET_TITLE = 'SET_TITLE',
  SET_DESCRIPTION = 'SET_DESCRIPTION',
  ADD_QUESTION = 'ADD_QUESTION',
  UPDATE_QUESTION = 'UPDATE_QUESTION',
  UPDATE_QUESTIONS_ORDER = 'UPDATE_QUESTIONS_ORDER',
  REMOVE_QUESTION = 'REMOVE_QUESTION',
  SET_SCORECARD = 'SET_SCORECARD'
}

type SetNameAction = {
  type: ActionTypes.SET_TITLE;
  payload: string;
};

type SetDescriptionAction = {
  type: ActionTypes.SET_DESCRIPTION;
  payload: string;
};

type AddQuestionAction = {
  type: ActionTypes.ADD_QUESTION;
  payload: string;
};

type UpdateQuestionAction = {
  type: ActionTypes.UPDATE_QUESTION;
  payload: { index: number; question: Question };
};

type UpdateQuestionsOrderAction = {
  type: ActionTypes.UPDATE_QUESTIONS_ORDER;
  payload: Question[];
};

type RemoveQuestionAction = {
  type: ActionTypes.REMOVE_QUESTION;
  payload: string;
};

type SetScorecardAction = {
  type: ActionTypes.SET_SCORECARD;
  payload: CustomScorecardWithQuestionType;
};

type Action =
  | SetNameAction
  | SetDescriptionAction
  | AddQuestionAction
  | UpdateQuestionAction
  | UpdateQuestionsOrderAction
  | RemoveQuestionAction
  | SetScorecardAction;

export const manageScorecardReducer = (
  state: CustomScorecardWithQuestionType,
  action: Action
) => {
  const newState = structuredClone(state);
  switch (action.type) {
    case ActionTypes.SET_TITLE:
      newState.title = action.payload;
      break;
    case ActionTypes.SET_DESCRIPTION:
      newState.description = action.payload;
      break;
    case ActionTypes.ADD_QUESTION:
      newState.questions.questions.push({
        text: '',
        question_type: 'numeric',
        id: action.payload
      });
      break;
    case ActionTypes.UPDATE_QUESTION: {
      const { index, question } = action.payload;
      newState.questions.questions[index] = question;
      break;
    }
    case ActionTypes.UPDATE_QUESTIONS_ORDER:
      newState.questions.questions = action.payload;
      break;
    case ActionTypes.REMOVE_QUESTION:
      newState.questions.questions = newState.questions.questions.filter(
        q => q.id !== action.payload
      );
      break;
    case ActionTypes.SET_SCORECARD: {
      const newScorecard = structuredClone(action.payload);
      // Add temporary IDs to questions, used for reordering
      newScorecard.questions.questions = newScorecard.questions.questions.map(
        (q, i) => ({
          ...q,
          id: `temp-${Math.random() + i}`
        })
      );
      return newScorecard;
    }
    default:
      return state;
  }
  return newState;
};
