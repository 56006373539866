import pluralize from 'pluralize';
import copy from 'clipboard-copy';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { type IconType, Icon, useShowToast } from '@grain/grain-ui';
import { Avatar, Button, Menu, MenuButton, theme } from '@grain/grain-ui/v4';
import { PersonContactType } from '@grain/api/schema.generated';

import { DealContactFragment } from '../../deal.generated';

const HubspotIconRounded: IconType = (props: React.ComponentProps<'svg'>) => {
  return <Icon.Hubspot {...props} radius={2} />;
};

const StyledContactListItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${theme.tokens.spacing.md};
  padding: 14px ${theme.tokens.spacing['3xl']};
  ${theme.tokens.typography.b4[500]}
  border-bottom: 1px solid ${theme.tokens.color.borderTertiary};

  > div {
    flex-shrink: 0;
    display: flex;
    gap: ${theme.tokens.spacing.md};
  }
`;

export const EmptyListItem = styled.div`
  padding: ${theme.tokens.spacing.lg} ${theme.tokens.spacing['3xl']};
  ${theme.tokens.typography.b3[500]};
  color: ${theme.tokens.color.textTertiary};
  border-bottom: 1px solid ${theme.tokens.color.borderTertiary};
`;

const StyledContactNameColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing['3xs']};
`;

const StyledContactStats = styled.div`
  color: ${theme.tokens.color.textTertiary};
`;

const StyledContactMetadata = styled.div`
  display: flex;
  gap: ${theme.tokens.spacing.sm};
  color: ${theme.tokens.color.textSecondary};
`;

const StyledContactMetadataColumn = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.tokens.spacing.xs};
  padding: ${theme.tokens.spacing.xs};

  > svg {
    flex-shrink: 0;
  }

  > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 0;
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

type ContactListItemProps = {
  contact: DealContactFragment;
  hubspotIntegration?: {
    hubId: string;
  };
};

export function ContactListItem({
  contact: { person, recordingsCount },
  hubspotIntegration
}: ContactListItemProps) {
  const navigate = useNavigate();
  const showToast = useShowToast();

  const email = person.contacts.find(
    contact => contact.contactType === PersonContactType.Email
  )?.contact;

  const hubspotContactId = useMemo(() => {
    return person.contacts.find(
      ({ contactType }) => contactType === PersonContactType.HubspotContactId
    )?.contact;
  }, [person]);

  const copyEmail = useCallback(() => {
    if (!email) return;
    copy(email);
    showToast({
      content: 'Email copied to clipboard',
      type: 'success',
      uniqueId: 'copied_deal_contact_email'
    });
  }, [email, showToast]);

  const viewMeetings = useCallback(() => {
    const initialFilters = {
      persons: [{ label: person.name, value: person.id }]
    };

    navigate('/app/meetings/all', {
      state: {
        initialFilters
      }
    });
  }, [navigate, person]);

  const viewInHubspot = useCallback(() => {
    if (!hubspotIntegration || !hubspotContactId) return;
    const url = `https://app.hubspot.com/contacts/${hubspotIntegration.hubId}/contact/${hubspotContactId}`;
    window.open(url, '_blank', 'noopener');
  }, [hubspotIntegration, hubspotContactId]);

  return (
    <StyledContactListItem>
      <div>
        <Avatar
          size='xl'
          name={person.name}
          url={person.user?.avatarUrl ?? undefined}
        />
        <StyledContactNameColumn>
          <div>{person.name}</div>
          <StyledContactStats>
            {recordingsCount} {pluralize('Meeting', recordingsCount)}
          </StyledContactStats>
        </StyledContactNameColumn>
      </div>
      <div>
        <StyledContactMetadata>
          {person.jobTitle && (
            <StyledContactMetadataColumn css={['width: 190px;']}>
              <Icon.GraduationHat />
              <span>{person.jobTitle}</span>
            </StyledContactMetadataColumn>
          )}
          {email && (
            <StyledContactMetadataColumn css={['width: 160px;']}>
              <Icon.Mail16x16 />
              <span>{email}</span>
            </StyledContactMetadataColumn>
          )}
        </StyledContactMetadata>
        <StyledButtonContainer>
          <Menu
            content={
              <>
                {email && (
                  <MenuButton
                    textLabelProps={{
                      startIcon: Icon.Copy
                    }}
                    label='Copy email'
                    onClick={copyEmail}
                  />
                )}
                <MenuButton
                  textLabelProps={{
                    startIcon: Icon.Record16x16
                  }}
                  label='View meetings'
                  onClick={viewMeetings}
                />
                {hubspotIntegration && hubspotContactId && (
                  <MenuButton
                    textLabelProps={{
                      startIcon: HubspotIconRounded,
                      endIcon: Icon.ExternalLink
                    }}
                    label='View in HubSpot'
                    onClick={viewInHubspot}
                  />
                )}
              </>
            }
          >
            <Button variant='ghost' icon={Icon.Overflow16x16} />
          </Menu>
        </StyledButtonContainer>
      </div>
    </StyledContactListItem>
  );
}
