import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { BANNER_IMAGE_URLS } from '~/pages/StoryPage/constants';
import { color, colors, font, media, spacing } from '@grain/styles/constants';
import { overflowEllipsis, InlineInput, TextArea } from '@grain/grain-ui';

export const STICKY_HEIGHT = 60;

const imageMaskStyles = css`
  .image {
    background-size: cover;
  }
  .mask {
    background: rgba(20, 20, 20, 0.25);
  }
  .image,
  .mask {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;

const titleStyles = css`
  ${font.titleS};
  font-weight: bold;
  color: ${colors.swan};
  height: 30px;
  line-height: 30px;
`;

const StyledCodeIconContainer = styled.span`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  user-select: none;
  ${font.family.code}
  ${font.bodyM}
`;

export const StyledCodeIcon = props => (
  <StyledCodeIconContainer {...props}>{'<>'}</StyledCodeIconContainer>
);

export const StyledSticky = styled.div`
  ${overflowEllipsis};
  position: sticky;
  height: ${STICKY_HEIGHT}px;
  left: 0;
  top: 0;
  flex-shrink: 0;
  z-index: 10;
  width: 100%;
  ${imageMaskStyles};
  overflow: hidden;

  display: flex;
  padding: 0 56px;
  ${media.small} {
    ${spacing.px4};
  }
  align-items: center;

  .title {
    ${titleStyles};
    z-index: 1;
  }

  .image {
    background-image: url(${props =>
      props.bannerImageUrl || BANNER_IMAGE_URLS[0]});
    background-position: center center;
  }
  .image,
  .mask {
    height: ${props => props.titleHeight}px;
  }
  .image {
    transform: translateY(${props => -props.titleHeight + STICKY_HEIGHT}px);
  }
`;

export const StyledTitleWrapper = styled.div`
  position: absolute;
  height: auto;
  left: 0;
  top: 0;
  z-index: 11;
  width: 100%;
  padding: 32px 32px 16px 0px;
  ${media.small} {
    ${spacing.p4};
  }

  ${imageMaskStyles};
  .image {
    background-image: url(${props =>
      props.bannerImageUrl || BANNER_IMAGE_URLS[0]});
    background-position: center center;
  }

  :hover textarea::placeholder {
    visibility: visible;
  }
`;

export const StyledButtonsContainer = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  z-index: 1;

  margin-left: 32px;
  ${media.small} {
    ${spacing.ml4};
  }
`;

export const StyledTitleContent = styled.div`
  flex: 1;
  color: ${colors.swan};
  overflow-x: hidden;

  display: flex;
  flex-direction: column;

  .title-wrapper {
    margin-left: 36px;
    position: relative;
    display: flex;
    align-items: center;
  }
  .metadata {
    ${spacing.mt1};
  }
`;

export const StyledTitleInlineInput = styled(InlineInput)`
  ${titleStyles};
  flex: 1;

  &,
  .mask,
  input {
    color: ${colors.swan};
  }

  input {
    border: 1px solid transparent;
  }

  &:hover,
  &:focus {
    input {
      border: 1px solid rgba(20, 20, 20, 0.25);
    }
  }
`;

export const StyledCharCounter = styled.div`
  ${spacing.ml2};
  color: ${props => (props.isMax ? colors.errorbird : colors.swan)};
  font-size: 10px;
  font-weight: bold;
  position: relative;
`;

export const StyledDescriptionTextarea = styled(TextArea)`
  ${color.swan};
  ${spacing.mt2};
  border-radius: 4px;
  border: 1px solid transparent;
  font-size: calc(14rem / 16) !important;
  padding: 2px;
  z-index: 0;

  :hover {
    cursor: pointer;
  }

  :hover,
  :focus,
  :active {
    border: 1px solid rgba(20, 20, 20, 0.25);
  }

  :focus,
  :active {
    cursor: text;
  }

  ::placeholder {
    visibility: hidden;
    ${color.swan};
    opacity: 0.75;
  }
`;

export const StyledDescriptionContainer = styled.div`
  align-items: center;
  display: flex;
  margin-left: 36px;
`;

export const StyledPillContainer = styled.div`
  ${spacing.mt2};
  ${spacing.ml7};
  align-items: center;
  display: flex;
  z-index: 1;
`;
