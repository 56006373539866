import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalTitle,
  useRouteModal,
  OptionsSelect,
  spacing
} from '@grain/grain-ui';
import { TRANSFER_RECORDING_ROUTE_ID } from '@grain/components/modals/constants';
import Button from '@grain/components/Button';
import { StyledContentContainer } from './styles';
import { TransferRecordingsType } from '@grain/api/schema.generated';

TransferModal.propTypes = {
  user: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  confirmSuspendUser: PropTypes.func.isRequired
};
export default function TransferModal({ confirmSuspendUser, user, users }) {
  const { close } = useRouteModal(TRANSFER_RECORDING_ROUTE_ID);
  const [input, setInput] = React.useState({
    transferOption: 'ALL',
    transferRecordingsTo: users?.[0]?.id
  });

  const transferOptions = [
    {
      title: `All meetings from ${user?.name} (${user?.recordingCount})`,
      value: 'ALL'
    },
    {
      title: `Only meetings with workspace access from ${user?.name} (${user?.sharedRecordingCount})`,
      value: 'ONLY'
    },
    {
      title: `No meetings (Deletes all meetings owned by ${user?.name})`,
      value: 'NONE'
    }
  ];

  const userOptions = users
    ?.filter(u => u.id !== user?.id)
    .map(user => ({
      title: `${user.name}`,
      value: user.id
    }));

  const deativateUser = React.useCallback(() => {
    if (input.transferOption?.value === 'ONLY') {
      confirmSuspendUser(user, {
        transferRecordingsTo:
          input.transferRecordingsTo?.value ?? userOptions?.[0]?.value,
        transferRecordingsType: TransferRecordingsType.WorkspaceShared
      });
    } else if (input.transferOption?.value === 'NONE') {
      confirmSuspendUser(user, {});
    } else {
      confirmSuspendUser(user, {
        transferRecordingsTo:
          input.transferRecordingsTo?.value ?? userOptions?.[0]?.value,
        transferRecordingsType: TransferRecordingsType.All
      });
    }
  }, [
    confirmSuspendUser,
    input.transferOption,
    input.transferRecordingsTo,
    userOptions,
    user
  ]);

  return (
    <Modal closable width={516} onCancel={close}>
      <ModalTitle title={'Deactivate Member'} centered />
      <StyledContentContainer>
        <div>
          Choose which meetings from this member you would like to transfer upon
          their deactivation.
        </div>
        <div className='sub-heading' css={[spacing.mb2, spacing.mt6]}>
          Select meetings to transfer
        </div>
        <div css={['width: 468px;']}>
          <OptionsSelect
            isMultiSelect={false}
            options={transferOptions}
            placeholder='Choose one'
            width='468px'
            selectHeight='32px'
            selectedText={
              input.transferOption?.title ?? transferOptions[0]?.title
            }
            selectedOptions={input.transferOption ? [] : [transferOptions[0]]}
            onSelect={options => {
              setInput(previous => ({
                ...previous,
                transferOption: options?.[0]
              }));
            }}
            position='relative'
          />
        </div>
        <div className='sub-heading' css={[spacing.mb2, spacing.mt6]}>
          Select recipient
        </div>
        <div css={['width: 468px;', spacing.mb7]}>
          <OptionsSelect
            isMultiSelect={false}
            disabled={input.transferOption?.value === 'NONE'}
            options={userOptions}
            selectedText={
              input.transferRecordingsTo?.title ?? userOptions[0]?.title
            }
            selectedOptions={
              input.transferRecordingsTo ? [] : [userOptions?.[0]]
            }
            placeholder='Choose one'
            width='468px'
            selectHeight='32px'
            onSelect={options => {
              setInput(previous => ({
                ...previous,
                transferRecordingsTo: options?.[0]
              }));
            }}
            position='fixed'
          />
        </div>
        <div className='action-buttons'>
          <Button onClick={close} type='secondary'>
            Cancel
          </Button>
          <Button
            onClick={() => {
              close();
              deativateUser();
            }}
            type='danger'
          >
            Deactivate
          </Button>
        </div>
      </StyledContentContainer>
    </Modal>
  );
}
