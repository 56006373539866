import { css } from '@emotion/react';
import {
  StyledCard,
  StyledCardHeaderText,
  StyledCardSubHeaderText,
  StyledCardWrapper
} from '../styles';
import { StepComponentProps } from '..';
import { useNavigate } from 'react-router-dom';
import { isMac as isMacSource } from 'lib/browser';
import { useAnalytics } from '@grain/grain-ui';
import MacImage from './mac.jpg';
import WindowsImage from './windows.jpg';
import { Button, Icon16, theme } from '@grain/grain-ui/v4';
import { useMount, useLocalStorage } from 'react-use';
import { useEffect } from 'react';

type DownloadDesktopProps = StepComponentProps & { isMac?: boolean };

const DownloadDesktop = ({
  onNext,
  desktopAppRequired,
  isMac = isMacSource
}: DownloadDesktopProps) => {
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const [canSkipDownload, setCanSkipDownload] = useLocalStorage(
    'can-skip-download',
    false
  );

  // After 10 seconds on this page the user can skip the download
  useEffect(() => {
    if (canSkipDownload) return;
    setCanSkipDownload(!desktopAppRequired);

    const timeout = setTimeout(() => {
      setCanSkipDownload(true);
    }, 10_000);

    return () => clearTimeout(timeout);
  }, [canSkipDownload, desktopAppRequired, setCanSkipDownload]);

  // Persist the canSkipDownload state when the user leaves the page
  // So when they come back, they can skip the download
  useEffect(() => {
    const handler = () => {
      setCanSkipDownload(true);
    };

    window.addEventListener('blur', handler);

    return () => {
      window.removeEventListener('blur', handler);
    };
  }, [setCanSkipDownload]);

  const buttonName = isMac ? 'download_mac_app' : 'download_windows_app';

  useMount(() => {
    trackEvent('Desktop Download Onboarding Viewed', {
      can_skip_download: !desktopAppRequired
    });
  });

  return (
    <StyledCardWrapper>
      <StyledCard
        css={css`
          max-width: 616px;
          width: 100%;
          margin-bottom: ${theme.tokens.spacing['3xl']};
        `}
      >
        <StyledCardHeaderText css={['text-align: center;']}>
          Grain works best on desktop
        </StyledCardHeaderText>
        <StyledCardSubHeaderText
          css={css`
            margin-top: ${theme.tokens.spacing.md};
            margin-bottom: ${theme.tokens.spacing['3xl']};
            text-wrap: balance;
          `}
        >
          Ensure the right meetings get recorded with push notifications,
          enhance AI notes with the notepad, and so much more!
        </StyledCardSubHeaderText>

        <div className='content-wrapper'>
          <img
            css={css`
              width: 100%;
              margin-bottom: ${theme.tokens.spacing['3xl']};
            `}
            alt='Screenshot of the Grain desktop app open on the Grain Meeting page'
            src={isMac ? MacImage : WindowsImage}
          />
          <Button
            onClick={() => {
              trackEvent('Desktop Download Started');
              setCanSkipDownload(true);
              navigate('/downloading');
            }}
            size='xl'
            css={css`
              width: 100%;
              justify-content: center;
            `}
            data-track='Button Clicked'
            icon={Icon16.Download}
            data-track-args={JSON.stringify({
              button_name: buttonName,
              button_text: 'Download desktop app'
            })}
          >
            Download desktop app
          </Button>
          {canSkipDownload && (
            <Button
              css={css`
                margin-top: ${theme.tokens.spacing.md};
              `}
              data-cy='continue'
              onClick={() => {
                onNext();
                trackEvent('Desktop Download Onboarding Skipped');
              }}
              variant='ghost'
              size='lg'
              data-track='Button Clicked'
              data-track-args={JSON.stringify({
                button_name: `skip_app_download${desktopAppRequired ? '_delayed' : ''}`,
                button_text: 'Skip for now'
              })}
            >
              Skip for now
            </Button>
          )}
        </div>
      </StyledCard>
    </StyledCardWrapper>
  );
};

export default DownloadDesktop;
