import { StatePill } from './StatePill';
import { getMeetingPerformanceBasedOnScore } from '../../utils';

type PerformanceStateProps = {
  isOpen: boolean;
  score: number | null;
};

export function PerformanceState({ isOpen, score }: PerformanceStateProps) {
  const performance = getMeetingPerformanceBasedOnScore(score);

  if (!performance) {
    return null;
  }

  return (
    <StatePill
      isOpen={isOpen}
      title={performance.text}
      color={performance.color}
    />
  );
}
