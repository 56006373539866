import { InputText, flex, font, spacing } from '@grain/grain-ui';
import { useId } from 'react';
import { Topic } from './types';

type Step1Props = {
  topic: Topic;
  setTopicValue: <K extends keyof Topic>(k: K, value: Topic[K]) => void;
};

export const Step1 = ({ topic, setTopicValue }: Step1Props) => {
  const nameId = useId();

  return (
    <section css={[spacing.py4, flex.direction.column, spacing.px7]}>
      <label css={[spacing.mb4, font.v4.h6]} htmlFor={nameId}>
        Tracker Name
      </label>
      <InputText
        value={topic.name}
        onChange={e => setTopicValue('name', e.currentTarget.value)}
        name='name'
        id={nameId}
        css={['width: 100%']}
        placeholder='e.g., “Competitors”'
        autoComplete='off'
      />
    </section>
  );
};
