import styled from '@emotion/styled';

import { color, colors, spacing } from '@grain/styles/constants';
import { pxToRem } from '@grain/grain-ui';

export const StyledContainer = styled.div`
  width: 234px;
  height: 132px;
  ${spacing.m3};
`;

export const StyledEmptyContainer = styled.div`
  width: 234px;
  height: 132px;
  background: ${colors.swan};
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  ${spacing.m3};
  ${spacing.p3};
`;

export const StyledEmptyTitle = styled.div`
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(18)};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  font-feature-settings:
    'case' on,
    'cpsp' on;

  ${color.blackbird};
`;
