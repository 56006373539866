import React from 'react';

import { StyledEmptyContainer, StyledEmptyTitle } from './styles';

export default function NoImportsCard() {
  return (
    <StyledEmptyContainer>
      <StyledEmptyTitle className='fs-block'>
        No Zoom cloud recordings available to import.
      </StyledEmptyTitle>
    </StyledEmptyContainer>
  );
}
