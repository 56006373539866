import styled from '@emotion/styled';
import {
  TagPill,
  pxToRem,
  flex,
  color,
  spacing,
  font,
  spaces
} from '@grain/grain-ui';
import { theme } from '@grain/grain-ui/v4';

export const WorkspaceSection = styled.section`
  display: flex;
  flex-direction: column;
  ${theme.utils.padding('3xl')}
`;

export const SectionDescription = styled.div`
  ${theme.tokens.typography.b2[400]};
  color: ${theme.tokens.color.textSecondary};
  margin-top: ${theme.tokens.spacing.sm};
`;

export const SectionTitle = styled.h4`
  ${theme.utils.margin('none')};
  ${theme.utils.padding('none')};
  ${theme.tokens.typography.b1[600]}
  color: ${theme.tokens.color.textPrimary};
`;

export const StyledEditWorkspace = styled.a`
  cursor: pointer;
  font-size: ${pxToRem(14)};
  text-decoration: underline;
  font-weight: 400;
  line-height: ${pxToRem(20)};
`;

export const StyledRecordingAccessContainer = styled.div`
  ${spacing.mt6};
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .sub-heading {
    ${color.blackbird};
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
    font-feature-settings:
      'case' on,
      'cpsp' on;
  }
`;

export const StyledOptionWrapper = styled.div`
  font-size: calc(14rem / 16);

  .option-label {
    ${spacing.ml2};
    cursor: default;
  }

  .switch-and-option {
    align-items: center;
    display: flex;
    max-width: 500px;
  }

  .switch-margin {
    ${spacing.mt4};
  }
`;

export const WorkspaceTitleButton = styled.button`
  all: unset;
  ${theme.utils.margin('none')};
  ${theme.utils.padding('none')};
  ${theme.tokens.typography.b1[600]}
  color: ${theme.tokens.color.textPrimary};
  text-decoration: underline;
  display: inline-flex;
  margin-bottom: ${theme.tokens.spacing.sm};
  cursor: pointer;
`;

export const StyledSectionDescription = styled.div`
  ${spacing.mt1};
  font-style: normal;
  font-weight: 400;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(16)};
  font-feature-settings:
    'case' on,
    'cpsp' on;
  ${color.crow};
`;

export const StyledContainer = styled.div`
  ${spacing.mt6};
  ${flex.direction.row};
  ${flex.justifyContent.spaceBetween};
  ${flex.alignItems.center};
`;

export const StyledSwitchSection = styled.div`
  ${flex.direction.row};
  ${flex.alignItems.center};
  ${flex.justifyContent.spaceBetween};
  gap: 24px;
  .title {
    ${color.blackbird};
    font-style: normal;
    font-weight: 600;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
    font-feature-settings:
      'case' on,
      'cpsp' on;

    .sub-title {
      ${color.crow};
      ${spacing.mt2};
      font-style: normal;
      font-weight: 400;
      font-size: ${pxToRem(14)};
      line-height: ${pxToRem(18)};
      font-feature-settings:
        'case' on,
        'cpsp' on;
    }
  }
`;

export const StyledDescription = styled.div`
  ${spacing.mt2};
  color: ${color.crow};
  font-style: normal;
  font-weight: 400;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(18)};
  font-feature-settings:
    'case' on,
    'cpsp' on;
`;

export const StyledDomainsContainer = styled.div`
  ${spacing.mt4};
  ${flex.alignItems.center};
  position: relative;
  width: 100%;
  flex-wrap: wrap;
`;

export const StyledPill = styled(TagPill)<{ deletable?: boolean }>`
  font-size: 14px;

  svg {
    display: block;
    width: 11px;
    height: 11px;
  }
`;

export const StyledDomain = styled.div`
  ${spacing.mx1};
  font-size: ${pxToRem(12)};
`;

export const StyledSubheadingContainer = styled.div`
  ${spacing.mt6};
  ${flex.justifyContent.spaceBetween};
  ${font.v4.b3[400]};
  .sub-heading {
    font-weight: 600;
  }
  .counter {
    font-weight: 400;
    color: ${color.crow};
  }
`;

export const StyledAllowedDomainsPill = styled(StyledPill)`
  background-color: ${color.gull};
`;

export const StyledDomainApproveBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${spaces['8']};
  ${spacing.px4};
  border-radius: 8px;
  border: 1px solid ${color.gull};
  font-size: ${pxToRem(12)};
  font-weight: 500;
  line-height: ${pxToRem(20)};
  width: 100%;
  ${spacing.mt4}
`;
