import {
  CompletenessStatus,
  DealDiagnosticMethodology,
  DealDiagnosticMethodologyDimension
} from '@grain/api/schema.generated';
import { DealDetailsV4Fragment } from '~/pages/Deals/deal.generated';

const generateEmptyDimensionResult = (
  dimension: DealDiagnosticMethodologyDimension,
  subdimensions: string[]
) => {
  const subdimensionResults = subdimensions.map(subdimension => ({
    status: CompletenessStatus.None,
    subdimension,
    summary: '',
    sources: [],
    userFeedback: null
  }));

  return {
    dimension,
    summary: '',
    status: CompletenessStatus.None,
    subdimensionResults
  };
};

export const DEFAULT_SPICED_DIAGNOSIS: DealDetailsV4Fragment['diagnosis'] = {
  __typename: 'DealDiagnosis',
  lastSync: '',
  methodology: DealDiagnosticMethodology.Spiced,
  status: CompletenessStatus.None,
  dimensionResults: [
    generateEmptyDimensionResult(
      DealDiagnosticMethodologyDimension.SpicedSituation,
      ['Context', 'Competition']
    ),
    generateEmptyDimensionResult(
      DealDiagnosticMethodologyDimension.SpicedPain,
      ['Quantifiable Pain', 'Qualitative Pain']
    ),
    generateEmptyDimensionResult(
      DealDiagnosticMethodologyDimension.SpicedImpact,
      ['Rational Impact', 'Emotional Impact']
    ),
    generateEmptyDimensionResult(
      DealDiagnosticMethodologyDimension.SpicedCriticalEvent,
      ['Critical Event', 'Compelling Event']
    ),
    generateEmptyDimensionResult(
      DealDiagnosticMethodologyDimension.SpicedDecision,
      ['Decision Criteria', 'Decision Process']
    )
  ],
  coveredRecordings: []
};
