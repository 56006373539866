import React, { type JSX } from 'react';
import { EmailInput } from '~/components/MultiInput';
import {
  Icon,
  useShowToast,
  useQueryParam,
  media,
  spacing
} from '@grain/grain-ui';
import {
  StyledInviteHeader,
  StyledEmailInput,
  StyledEmailWrapper,
  StyledLink,
  StyledShareLink,
  StyledBulk,
  StyledButtonContainer,
  StyledButton,
  StyledSendInvites
} from './styles';
import { isValidEmail } from '~/support/validators';
import { useWorkspaceMembersInviteMutation } from '@grain/api/graphql/mutations/workspace.generated';
import { workspaceMembersQuery } from '@grain/api/graphql/queries';
import { pluralize } from '~/support/language';
import { useWorkspaceWithMembers } from '@grain/components/Workspace/hooks';
import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import copy from 'clipboard-copy';

interface GraphQLEmailError extends GraphQLError {
  email?: string;
}

interface EmailError extends ApolloError {
  graphQLErrors: GraphQLEmailError[];
}

type SendInvitesProps = {
  skipText?: string;
  submitText?: string;
  onNext: () => void;
};

export function SendInvites({
  skipText,
  submitText,
  onNext
}: SendInvitesProps) {
  const { workspace } = useWorkspaceWithMembers({
    fetchPolicy: 'cache-and-network'
  });
  const showToast = useShowToast({ force: true });
  const [bulk, setBulk] = React.useState(false);
  const individualEmailLimit = 3;
  const [emailsToSend, setEmailsToSend] = React.useState(
    Array(individualEmailLimit).fill('')
  );

  const [crmConnected, setCrmConnected] = useQueryParam('crm_connected', {
    defaultValue: null,
    method: 'push'
  });

  React.useEffect(() => {
    if (crmConnected) {
      showToast({
        content: 'CRM connected',
        type: 'success'
      });
      setTimeout(() => setCrmConnected(''), 5000);
    }
    // showToast can't be used as a hook dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crmConnected, setCrmConnected]);

  const inviteText =
    'Grain enhances meetings by automatically recording, transcribing, and summarizing them using AI.';

  const emailsToIgnore = workspace
    ? [
        ...workspace?.pendingInvitees?.map(invite => invite.email),
        ...workspace?.users?.map(user => user.email)
      ]
    : [];

  const [invite] = useWorkspaceMembersInviteMutation({
    refetchQueries: [
      {
        query: workspaceMembersQuery,
        fetchPolicy: 'network-only'
      }
    ],
    onError: (error: ApolloError) => {
      if ((error as EmailError).graphQLErrors) {
        const emailErrorArray = (error as EmailError).graphQLErrors;
        // A user already exists in a workspace
        for (let i = 0; i < (emailErrorArray || []).length; i++) {
          const emailError = emailErrorArray[i];
          if (emailError.message === 'users_exist_in_workspace') {
            showToast({
              content: `${emailError?.email} is already a member of your workspace.`,
              type: 'failure'
            });
          } else if (emailError.message === 'users_exist_in_other_workspaces') {
            showToast({
              content: `${emailError?.email} is already a member of another workspace.`,
              type: 'failure'
            });
          }
        }
      }
    },
    onCompleted() {
      const pluralizedMessage = pluralize(
        'Invite',
        'Invites',
        emailsToSend.filter(email => email !== '').length
      );
      showToast({
        content: `${pluralizedMessage} sent`,
        type: 'success'
      });
      onNext();
      setEmailsToSend([]);
    }
  });

  const handleInviteUsers = async (emails: string[]) => {
    if (!emails.length) {
      return showToast({
        content: 'Please enter one or more emails to invite.',
        type: 'failure'
      });
    }

    const duplicatedEmails = emailsToIgnore.filter(emailToIgnore =>
      emails.includes(emailToIgnore)
    );

    if (duplicatedEmails.length === 1) {
      return showToast({
        content: `${duplicatedEmails[0]} has already been invited`,
        type: 'failure'
      });
    } else if (duplicatedEmails.length > 1) {
      return showToast({
        content: `${duplicatedEmails.length} email addresses have already been invited`,
        type: 'failure'
      });
    }

    invite({
      variables: {
        emails,
        note: inviteText,
        addToPlan: false
      }
    });
  };

  const onChangeEmails = (values: string[], i?: number) => {
    if (bulk) setEmailsToSend(values);
    else {
      setEmailsToSend(prev => {
        const newState = [...prev];
        if (i !== undefined) {
          newState[i] = isValidEmail(values[0]) ? values[0] : '';
        }
        return newState;
      });
    }
  };

  const renderIndividualEmailsToSend = (num: number): JSX.Element[] => {
    const emailInputs = [];
    for (let i = 0; i < num; i++) {
      emailInputs.push(
        <StyledEmailInput
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChangeEmails([event.target.value.trim()], i)
          }
          placeholder='Email address'
          key={i}
          defaultValue={emailsToSend[i]}
        />
      );
    }
    return emailInputs;
  };

  const handleBulkClick = () => {
    setBulk(!bulk);
  };

  const handleCopyInviteLink = () => {
    if (workspace?.inviteLink) {
      copy(workspace.inviteLink);
      showToast({
        content: 'Copied to clipboard.',
        type: 'success'
      });
    }
  };

  return (
    <StyledSendInvites className='send-invites'>
      <StyledEmailWrapper>
        <StyledInviteHeader>Send invites</StyledInviteHeader>
        {bulk ? (
          <EmailInput
            css={['width: 100%;']}
            inputCss={[
              'width: 451px; display: flex; padding: 8px 12px;',
              `${media.small} { max-width: 100%; ${spacing.px4} }`
            ]}
            boxCss={['padding: 8px 12px;']}
            placeholder='Emails, separated with comma, tab or enter'
            onChangeEmails={onChangeEmails}
            allowDebounce={true}
            altSelectedItem={true}
            defaultValue={emailsToSend.filter(email => email !== '')}
          />
        ) : (
          <>{renderIndividualEmailsToSend(individualEmailLimit)}</>
        )}
      </StyledEmailWrapper>
      <StyledBulk>
        <StyledLink onClick={handleBulkClick}>
          <Icon.PlusThin css={['margin-right: 8px']} />
          {bulk ? 'Add individually' : 'Add in bulk'}
        </StyledLink>
        <StyledShareLink onClick={handleCopyInviteLink}>
          <Icon.CopyLink
            css={['width: 14px; height: 14px; margin-right: 8px;']}
          />
          Copy invite link
        </StyledShareLink>
      </StyledBulk>
      <StyledButtonContainer>
        <StyledButton
          data-cy='continue'
          type='button'
          variant='secondary'
          onClick={onNext}
          size='large'
        >
          {skipText || 'I’ll do this later'}
        </StyledButton>
        <StyledButton
          data-cy='invite'
          type='button'
          variant='primary'
          size='large'
          onClick={() =>
            handleInviteUsers(emailsToSend.filter(email => email !== ''))
          }
          css={['width: 174px;']}
          disabled={emailsToSend.filter(email => email !== '').length < 1}
        >
          {submitText || 'Invite to workspace'}
        </StyledButton>
      </StyledButtonContainer>
    </StyledSendInvites>
  );
}
