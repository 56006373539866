import { TippyInstance } from '@grain/grain-ui/v4';
import { useCallback, useMemo, useRef, useState } from 'react';
import { FilterName } from '../types';
import { Filters } from '../useFiltersManager';

type UseFilterBarProps = {
  filters: Filters;
};

export const useFilterBar = ({ filters }: UseFilterBarProps) => {
  const menuRef = useRef<TippyInstance>(null);
  const [selectedFilterId, setSelectedFilterId] = useState<FilterName | null>(
    null
  );
  const hasActiveVisibleFilters = useMemo(() => {
    return Object.values(filters).some(
      filter => filter.active && !filter.hidden
    );
  }, [filters]);

  const activeFilter = selectedFilterId ? filters[selectedFilterId] : null;

  const selectFilter = useCallback(
    (filterKey: FilterName) => {
      const filter = filters[filterKey];
      if ('singleOption' in filter && filter.singleOption) {
        filter.apply();
        setSelectedFilterId(null);
        menuRef.current?.hide?.();
        return;
      }
      setSelectedFilterId(filterKey);
    },
    [filters]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Escape') {
        e.stopPropagation();
        e.preventDefault();
        setSelectedFilterId(null);
        menuRef.current?.hide?.();
      }
    },
    []
  );

  return {
    menuRef,
    selectedFilterId,
    setSelectedFilterId,
    hasActiveVisibleFilters,
    activeFilter,
    selectFilter,
    handleKeyDown
  } as const;
};
