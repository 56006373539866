import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Skeleton, color, spacing } from '@grain/grain-ui';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-top: 1px solid ${color.gull};
  padding: 16px 32px 16px 8px;
  width: 100%;
  overflow: hidden;

  &:first-of-type {
    border-top: none;
  }

  &:last-child {
    border-bottom: 1px solid ${color.gull};
  }
`;

const RecordingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Content = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(0, 1fr) 120px 120px 40px 32px;
  align-items: center;
  ${spacing.g6}
  width: 100%;
`;

export const SkeletonRecordingSearchItem = () => {
  return (
    <Wrapper>
      <RecordingContainer>
        <Skeleton width={16} height={16} />
        <Skeleton width={65} height={36} />
        <Content>
          <div>
            <Skeleton width={250} height={18} />
            <Skeleton
              width={150}
              height={16}
              css={css`
                margin-top: 4px;
              `}
            />
          </div>
          <Skeleton width={100} height={16} />
          <Skeleton width={100} height={16} />
          <Skeleton width={30} height={16} />
          <Skeleton width={24} height={24} />
        </Content>
      </RecordingContainer>
      <div
        css={{
          marginLeft: '113px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px'
        }}
      >
        <Skeleton width='100%' height={16} />
        <Skeleton width='100%' height={16} />
        <Skeleton width='100%' height={16} />
      </div>
    </Wrapper>
  );
};

export default SkeletonRecordingSearchItem;
