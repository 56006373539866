import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon20, Skeleton, TextLabel, theme } from '@grain/grain-ui/v4';
import React from 'react';

type MeetingDateHeaderProp = {
  children: React.ReactNode;
};

const Container = styled.div`
  ${theme.utils.py('xs')};
  ${theme.utils.px('lg')};
  position: sticky;
  z-index: 1;
  top: 0;
  background-color: ${theme.tokens.color.surfaceSecondary};
  color: ${theme.tokens.color.textSecondary};

  @media (${theme.tokens.breakpoints.lgMin}) {
    ${theme.utils.px('3xl')};
  }
`;

export const MeetingDateHeader = ({ children }: MeetingDateHeaderProp) => (
  <Container>
    <TextLabel size='sm' startIcon={Icon20.Calendar}>
      {children}
    </TextLabel>
  </Container>
);

MeetingDateHeader.Skeleton = () => (
  <Container>
    <Skeleton
      css={css`
        height: 16px;
        width: 80px;
      `}
    />
  </Container>
);
