import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { color, colors, spacing, media } from '@grain/styles/constants';
import {
  flex,
  resetLinkStyles,
  color as serializedColor,
  font,
  pxToRem,
  Button
} from '@grain/grain-ui';
import Input from '@grain/components/Input';
import * as Layout from '~/components/Layout';

export const StyledLayoutContent = styled(Layout.Content)`
  padding: 0;
  flex-direction: row;
  height: 100%;
`;

export const StyledPageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const StyledInnerPageWrapper = styled.div`
  max-width: 1800px;
  width: 100%;
  margin: 0 auto;
`;

export const StyledHeader = styled.div`
  min-height: 88px;
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.gull};
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 34px;
  flex: none;

  ${media.small} {
    min-height: 0;
    ${spacing.py4};
  }
`;

export const StyledPageTitle = styled.span`
  white-space: nowrap;
  ${spacing.mr6};
`;

export const StyledMeetingControl = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${spacing.mt6};
  & > div {
    flex: 1;
  }
`;

export const StyledMeetingInput = styled(Input)`
  min-width: 320px;
  ${spacing.mr6}

  ${media.small} {
    min-width: unset;
    ${spacing.mr3};
  }
`;

export const StyledLeftColumn = styled.div`
  width: 50%;
  max-width: 800px;
  height: 100%;
  ${spacing.mr7};
  ${spacing.pr3};
  ${spacing.pl4};
  ${spacing.pb7};
  flex: 4;
  display: flex;
  flex-direction: column;

  @media (max-width: 1060px) {
    width: 100%;
  }
`;

export const StyledRightColumn = styled.div`
  ${spacing.mt6};
  ${spacing.mb4};
  width: 50%;
  flex: 3;

  @media (max-width: 1060px) {
    width: 100%;
  }
`;

export const StyledFirstCategoryHeader = styled.div`
  ${flex.alignItems.center};
  ${flex.justifyContent.spaceBetween};
`;

export const StyledCompactSettingsLink = styled.a`
  ${resetLinkStyles};
  ${color.graieen};
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(16)};
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  display: block;

  &:hover,
  &:active {
    text-decoration: underline;
  }
`;

export const StyledColumnsContainer = styled.div`
  flex: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: calc(64px - 16px); // offset for left padding on left column
  padding-right: 64px;

  ${media.small} {
    ${spacing.px0}
    flex-direction: column-reverse;

    .divider {
      position: sticky;
      top: 60px;
      background: linear-gradient(0deg, transparent 0%, white 35%);
      z-index: 1;
    }
  }
`;

// Compact view, used for mobile devices
export const compactStyles = css`
  ${StyledLeftColumn} {
    ${spacing.px4};
    ${spacing.pb4};
    margin-right: 0;
    overflow: visible;
  }
`;

export const StyledHeaderHelper = styled.div`
  display: flex;
`;

export const IconLabelContainer = styled.div`
  display: flex;
  align-items: center;
  ${spacing.g1};
  gap: 4px;
  flex: none;
`;

export const IconContainer = styled.div`
  ${serializedColor.fg.pigeon};
  display: flex;

  svg {
    width: 14px;
    height: 14px;
  }
`;

export const Label = styled.div`
  ${font.h4.semibold};
  ${serializedColor.fg.pigeon};
  text-transform: uppercase;
`;

export const StyledConnectButton = styled(Button)`
  width: 164px;
  height: 44px;
`;
