import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Overflow from 'react-overflow-indicator';
import { ErrorBox, useAnalytics } from '@grain/grain-ui';
import { spacing, color, font } from '@grain/styles/constants';
import { useMyself } from '~/support/auth';
import { displayCents } from '~/support/price';
import { pluralize } from '~/support/language';
import UserCard from '~/components/UserCard';
import { useSubscribeInfo } from '../context';
import {
  TextLabel,
  Radio,
  Button,
  Checkbox,
  Tooltip,
  theme
} from '@grain/grain-ui/v4';
import {
  StyledContainer,
  StyledUserCardWrapper,
  StyledMembersContainer,
  StyledFooterContainer,
  StyledIntervalText,
  StyledSelectAllWrapper,
  StyledSelectWrapper,
  StyledRadioLabel,
  StyledPriceText,
  StyledBillingSelectWrapper,
  StyledMembersTitle,
  StyledMembersHeading,
  StyledIntervalNote
} from './styles';
import { User } from '@grain/api/schema.generated';
import { css } from '@emotion/react';
import * as Intercom from '~/support/intercom';

type ChooseMembersProps = {
  onSuccess: () => void;
  omitPlanMembers: boolean;
  source: string;
};

export default function ChooseMembers({
  onSuccess,
  omitPlanMembers,
  source
}: ChooseMembersProps) {
  const [searchParams] = useSearchParams();
  const { myself } = useMyself();
  const { trackEvent } = useAnalytics();
  const { setMembersSet, membersSet, plan, setIsYearly, workspaceRes } =
    useSubscribeInfo();

  const [validationError, setValidationError] = React.useState<Error | null>(
    null
  );

  const [selectedInterval, setSelectedInterval] = React.useState<string | null>(
    null
  );

  React.useEffect(() => {
    const isYearly = searchParams.get('yearly') === 'true';
    setSelectedInterval(isYearly ? 'Y' : 'M');
    setIsYearly(isYearly);
  }, [searchParams, setIsYearly, setSelectedInterval]);

  React.useEffect(() => {
    if (workspaceRes.workspace?.users && source) {
      trackEvent('Upgrade Users Modal Opened', {
        feature_name: source,
        page: location.pathname
      });
    }
  }, [workspaceRes.workspace, source, trackEvent]);

  const workspaceUsers = workspaceRes.workspace?.users;
  const usersToDisplay = React.useMemo(() => {
    if (!workspaceUsers) return [];

    return [
      // Put my user first.
      workspaceUsers.find((user: User) => user.id === myself?.id),
      // Then filter out my user
      ...workspaceUsers.filter((user: User) => user.id !== myself?.id)
    ]
      .filter(Boolean)
      .filter(user => {
        if (omitPlanMembers) {
          return !user.onPlan;
        }
        return true;
      });
  }, [workspaceUsers, myself, omitPlanMembers]);

  const setMemberChecked = (user: User, checked: boolean) => {
    const copy = new Set(membersSet);
    if (checked) {
      copy.add(user);
    } else {
      copy.delete(user);
    }
    setMembersSet(copy);
  };

  const handleContinue = async () => {
    if (membersSet.size === 0) {
      setValidationError(
        new Error(
          `Please select at least one member to upgrade to ${plan.name}.`
        )
      );
      return;
    }
    setMembersSet(membersSet);
    onSuccess();
  };

  React.useEffect(() => {
    if (validationError && membersSet.size > 0) {
      setValidationError(null);
    }
  }, [membersSet.size, validationError, setValidationError]);

  const pricing = {
    monthly: displayCents(plan.monthlyPrice),
    yearly: displayCents(plan.yearlyPrice / 12)
  };

  const useIntercom = workspaceRes.workspace?.domains.length > 0;
  const supportProps = useIntercom
    ? {
        onClick: (e: React.MouseEvent) => {
          e.preventDefault();
          Intercom.showTab('messages');
        },
        href: 'mailto:help@grain.com'
      }
    : { href: 'mailto:help@grain.com' };

  const isWorkspaceFree =
    workspaceRes.data?.workspace?.workspaceSubscription?.planDetails?.isFree;
  const workspaceBillingCycle =
    workspaceRes.data?.workspace?.workspaceSubscription?.billingPeriod;
  const isBuyingSeats = source === 'Members Page Buy Seats';
  const disableBillingCycle = !isWorkspaceFree && isBuyingSeats;
  const disableMonth =
    disableBillingCycle && workspaceBillingCycle !== 'MONTHLY';
  const disableYear = disableBillingCycle && workspaceBillingCycle !== 'YEARLY';

  const handleBillingSelect = (interval: string) => {
    if (disableMonth || disableYear) return;
    setSelectedInterval(interval);
    setIsYearly(interval === 'Y');
  };

  const isLoading =
    !plan || !workspaceUsers || membersSet.size === 0 || workspaceRes?.loading;

  if (isLoading) return null;

  return (
    <StyledContainer>
      {validationError && (
        <p
          css={[
            spacing.py2,
            spacing.pt2,
            font.bodyM,
            color.errorbird,
            'font-weight: normal;'
          ]}
        >
          {validationError.message}
        </p>
      )}
      <StyledMembersContainer>
        <StyledMembersTitle>
          <StyledMembersHeading>Select Members</StyledMembersHeading>
          <StyledSelectAllWrapper>
            <TextLabel size='lg'>Select All</TextLabel>
            <Checkbox
              checked={membersSet.size === usersToDisplay.length}
              disabled={
                membersSet.size === usersToDisplay.length &&
                membersSet.size === 1
              }
              onChange={ev =>
                usersToDisplay.map(() => {
                  if (ev.target.checked) {
                    setMembersSet(new Set(usersToDisplay));
                  } else {
                    setMembersSet(new Set([usersToDisplay[0]]));
                  }
                })
              }
            />
          </StyledSelectAllWrapper>
        </StyledMembersTitle>

        <Overflow tolerance={1} css={['max-height: 350px; overflow: auto;']}>
          <Overflow.Content css={['width: 100%;']}>
            {workspaceRes.loading ? null : workspaceRes.error ? (
              <ErrorBox error={workspaceRes.error} />
            ) : (
              usersToDisplay.map((user, index) => (
                <StyledUserCardWrapper key={index + user.id}>
                  <UserCard isYou={user.id === myself?.id} user={user} />
                  <div className='switch-container'>
                    <Tooltip content={'You must upgrade at least one member.'}>
                      <div>
                        <Checkbox
                          checked={membersSet.has(user)}
                          onChange={ev =>
                            setMemberChecked(user, ev.target.checked)
                          }
                          disabled={
                            membersSet.has(user) && membersSet.size === 1
                          }
                        />
                      </div>
                    </Tooltip>
                  </div>
                </StyledUserCardWrapper>
              ))
            )}
          </Overflow.Content>
        </Overflow>
      </StyledMembersContainer>

      {isWorkspaceFree ? (
        <StyledIntervalText
          css={css`
            margin-bottom: ${theme.tokens.spacing.lg};
          `}
        >
          Select Billing Cycle
        </StyledIntervalText>
      ) : (
        <>
          <StyledIntervalText>Select Billing Cycle</StyledIntervalText>
          <StyledIntervalNote>
            If you'd like to change your billing cycle,{' '}
            <a {...supportProps}>contact support</a>.
          </StyledIntervalNote>
        </>
      )}

      <StyledBillingSelectWrapper>
        <StyledSelectWrapper
          checked={selectedInterval === 'M'}
          onClick={() => handleBillingSelect('M')}
          disabled={disableMonth}
        >
          <Radio
            size='sm'
            value='M'
            checked={selectedInterval === 'M'}
            onChange={() => handleBillingSelect('M')}
            disabled={disableMonth}
          >
            <StyledRadioLabel size='lg'>Pay month-to-month</StyledRadioLabel>
          </Radio>
          <StyledPriceText>
            {`$${pricing.monthly} per seat/month`}
          </StyledPriceText>
        </StyledSelectWrapper>
        <StyledSelectWrapper
          checked={selectedInterval === 'Y'}
          onClick={() => handleBillingSelect('Y')}
          disabled={disableYear}
        >
          <Radio
            size='sm'
            value='Y'
            checked={selectedInterval === 'Y'}
            onChange={() => handleBillingSelect('Y')}
            disabled={disableYear}
          >
            <StyledRadioLabel
              metadata='Save 25%'
              size='lg'
              css={css`
                font-weight: 600;
              `}
            >
              Pay 1-year upfront
            </StyledRadioLabel>
          </Radio>
          <StyledPriceText>{`$${pricing.yearly} per seat/month`}</StyledPriceText>
        </StyledSelectWrapper>
      </StyledBillingSelectWrapper>
      <StyledFooterContainer>
        <Button onClick={handleContinue} variant='primary' size='xl' fullWidth>
          Continue ({membersSet.size}{' '}
          {pluralize('member', 'members', membersSet.size)} selected)
        </Button>
      </StyledFooterContainer>
    </StyledContainer>
  );
}
