import { css } from '@emotion/react';
import { useWorkspaceRecordingTagsLazyQuery } from '@grain/components/Tags/tags.generated';
import { Icon, useShowToast } from '@grain/grain-ui';
import { ActionBar, Input, Menu, MenuButton } from '@grain/grain-ui/v4';
import { useMemo, useState } from 'react';
import { LabelWithValue } from '~/modules/filtersV2/types';
import { useRecordingsTagAddMutation } from '~/pages/Library/library.generated';

type TagsButtonProps = {
  selectedMeetingIds: string[];
};

export const TagsButton = ({ selectedMeetingIds }: TagsButtonProps) => {
  const showToast = useShowToast();
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<LabelWithValue[]>([]);

  const [filteredOptions, exactMatch] = useMemo(() => {
    let hasExactMatch = false;
    const cleanSearch = searchValue.trim().toLowerCase();
    const filtered = options.filter(opt => {
      if (opt.label.toLowerCase() === `#${cleanSearch}`) {
        hasExactMatch = true;
      }

      return opt.label.toLowerCase().includes(cleanSearch);
    });

    return [filtered, hasExactMatch];
  }, [searchValue, options]);

  const [addTagsToRecordings] = useRecordingsTagAddMutation({
    onCompleted: () => {
      showToast({ content: 'Meetings tagged', type: 'success' });
    }
  });

  const [fetchData] = useWorkspaceRecordingTagsLazyQuery({
    onCompleted: data => {
      const tags = data.workspaceTags.map(tag => ({
        label: `#${tag.tag.text}`,
        value: tag.tag.id
      }));

      setOptions(tags);
    }
  });

  return (
    <Menu
      width='200px'
      tippyProps={{
        placement: 'top',
        onHide: () => setSearchValue('')
      }}
      content={
        <>
          <Input
            css={css`
              margin-bottom: 4px;
            `}
            onClick={e => e.stopPropagation()}
            placeholder='Search Tags'
            value={searchValue}
            onChange={e => setSearchValue(e.target.value.replace(' ', '-'))}
          />
          {searchValue && !exactMatch && (
            <MenuButton
              textLabelProps={{ startIcon: Icon.Plus }}
              label={`Create #${searchValue}`}
              onClick={() => {
                addTagsToRecordings({
                  variables: {
                    recordingIds: selectedMeetingIds,
                    text: searchValue.replace(/^#/, '')
                  }
                });
                setSearchValue('');
              }}
            />
          )}
          {filteredOptions.map(option => (
            <MenuButton
              key={option.value}
              label={option.label}
              onClick={() => {
                addTagsToRecordings({
                  variables: {
                    recordingIds: selectedMeetingIds,
                    text: option.label.replace(/^#/, '')
                  }
                });
              }}
            />
          ))}
        </>
      }
    >
      <ActionBar.Button
        disabled={selectedMeetingIds.length === 0}
        icon={Icon.Tag}
        onClick={() => fetchData()}
      />
    </Menu>
  );
};
