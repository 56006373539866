import styled from '@emotion/styled';
import { InputText, pxToRem, color } from '@grain/grain-ui';
import { StyledNextButton } from '../styles';
import { media } from '@grain/styles/constants';

export const StyledSendInvites = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledInviteHeader = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  line-height: 16px;
  font-family: Inter;
  align-self: flex-start;
  ${color.fg.crow};
`;

export const StyledEmailInput = styled(InputText)`
  width: 451px;
  max-width: 100%;
  height: 36px !important;
  padding: 8px 2px 8px 12px;
`;

export const StyledEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 451px;

  ${media.small} {
    max-width: 100%;
  }
`;

export const StyledLink = styled.a`
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Inter;
  font-size: ${pxToRem(12)};
  font-style: normal;
  font-weight: 500;
  line-height: ${pxToRem(20)};
  ${color.fg.crow};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledShareLink = styled(StyledLink)`
  ${color.fg.graieen};
`;

export const StyledBulk = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  align-content: space-between;
  width: 100%;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const StyledButton = styled(StyledNextButton)`
  font-size: 12px !important;
  height: 48px;
  width: 143px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;
