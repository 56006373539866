import { useExperiment, useMyself, useWorkspace } from '@grain/api/auth';
import { useSubscriptionMembersAddMutation } from '@grain/api/graphql/mutations/subscriptions.generated';
import { getBasePublicPath } from '@grain/grain-ui';
import { TrialJoinerCrmTourStep } from './components/TrialJoinerCrmTourStep';
import { BusinessBentoBox } from '~/components/BusinessBentoBox';
import { useOnboardingNextMeetingInformation } from '~/modules/freemium/useOnboardingNextMeetingInformation';
import { useFreemiumTrialOnboardingRecordingQuery } from '~/modules/freemium/freemium.generated';
import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

// Announcement data goes below.  Add new announcements to the END of the
// exported object.
//
// Every time we add a new announcement, we also need to add the announcement
// name to DEFAULT_EXTRA.featureAnnouncementsShown in
// grain-components/support/extra/index.js so that new users will not see the
// announcement after signing up.
//
// The following announcement names were used in the past (since deleted), and
// should never be used again:
//
//   - "custom-prompts-and-templates"
//   - "sales-sidebar-announcement"
//   - "insights-announcement"
//   - "coaching-and-deals-announcement"
//   - "recording-page-redesign"
//   - "deal-intelligence-for-hubspot-announcement"
//

export const useAnnouncements = () => {
  const { myself } = useMyself();
  const { workspace } = useWorkspace();
  const { enabled: isFreemiumEnabled } = useExperiment('freemium');
  const { data } = useFreemiumTrialOnboardingRecordingQuery();
  const navigate = useNavigate();
  const [subscriptionMembersAddMutation] = useSubscriptionMembersAddMutation();

  const isOnTrial = workspace?.workspaceSubscription?.trial;
  const trialStarter = workspace?.workspaceSubscription?.trialStarter;
  const showTrialJoinerAnnouncement =
    isOnTrial && !trialStarter?.isSelf && isFreemiumEnabled;

  const hasCrmRecording = Boolean(data?.freemiumTrialOnboardingRecording);

  const userOnPlan = myself?.user?.onPlan ?? false;
  const workspaceOnFreePlan =
    workspace?.workspaceSubscription?.planDetails?.isFree ?? false;
  const userIsFreeNotetaker = userOnPlan && workspaceOnFreePlan;
  const hasWorkEmailDomains = workspace?.hasWorkEmailDomains;

  const { autoRecordTitle, autoRecordDescription } =
    useOnboardingNextMeetingInformation();

  const handleAddUserToSubscription = useCallback(async () => {
    if (
      !myself?.id ||
      myself.user?.onPlan ||
      !workspace?.workspaceSubscription?.trial
    ) {
      return;
    }

    await subscriptionMembersAddMutation({
      variables: {
        userIds: [myself?.id]
      }
    });
  }, [
    myself?.id,
    myself?.user?.onPlan,
    subscriptionMembersAddMutation,
    workspace?.workspaceSubscription?.trial
  ]);

  const announcements = useMemo(
    () => [
      {
        key: 'free-notetaker-unlimited-meetings',
        announcementEntries: [
          {
            imageAlt:
              'Illustration of an AI-generated meeting summary next to a recording. The meeting summary includes "Chapters," "Outcome," and "Action Items" sections. The illustration also includes a depiction of a conceptual Settings option, which ostensibly would control whether the user could record an unlimited number of meetings. It is a toggle labeled "Unlimited meetings," and it is toggled "on."',
            imageSrc: `${getBasePublicPath()}/images/announcements/free-notetaker-unlimited-meetings.svg`,
            prelude: 'We’ve made some updates to Grain Free',
            title:
              'No more 20-meeting limit, enjoy unlimited recordings and AI notes!',
            secondaryButtonLink:
              'https://support.grain.com/en/articles/10375981-unlimited-recordings-for-free-users'
          }
        ],
        isHidden: !(
          isFreemiumEnabled &&
          userIsFreeNotetaker &&
          hasWorkEmailDomains
        )
      },
      {
        key: 'trial-joiner',
        isHidden: !showTrialJoinerAnnouncement,
        announcementEntries: [
          {
            imageAlt:
              'Illustration of an AI-generated meeting summary next to a recording. The meeting summary includes "Chapters," "Outcome," and "Action Items" sections. The illustration also includes a depiction of a conceptual Settings option, which ostensibly would control whether the user could record an unlimited number of meetings. It is a toggle labeled "Unlimited meetings," and it is toggled "on."',
            imageSrc: `${getBasePublicPath()}/images/announcements/free-notetaker-unlimited-meetings.svg`,
            prelude: 'Your workspace started the 14-day Business trial',
            title: autoRecordTitle,
            body: autoRecordDescription,
            primaryButtonText: 'Continue',
            secondaryButtonText: 'Change Setting',
            secondaryButtonLink:
              '/app/settings/account?tab=recordings&skipAnnouncement=true',
            onShow: handleAddUserToSubscription
          },
          {
            component: <BusinessBentoBox />,
            prelude: 'Enjoy 14 days of AI-powered Business features',
            title:
              'Automate sales workflows so you can focus on growing revenue',
            primaryButtonText: hasCrmRecording ? 'Continue' : 'Done',
            showBackButton: true
          },
          {
            component: <TrialJoinerCrmTourStep />,
            prelude: 'Take a tour of Grain Business',
            title:
              'Check out a recent meeting with HubSpot Contacts to highlight the benefits.',
            primaryButtonText: 'Start tour',
            primaryButtonAction: () => {
              if (data?.freemiumTrialOnboardingRecording?.recordingPath) {
                navigate(
                  data?.freemiumTrialOnboardingRecording?.recordingPath,
                  {
                    state: {
                      showFreemiumTrialTour: true
                    }
                  }
                );
              }
            },
            showBackButton: true,
            isHidden: !hasCrmRecording
          }
        ].filter(({ isHidden }) => !isHidden)
      }
    ],
    [
      isFreemiumEnabled,
      userIsFreeNotetaker,
      hasWorkEmailDomains,
      showTrialJoinerAnnouncement,
      autoRecordTitle,
      autoRecordDescription,
      handleAddUserToSubscription,
      hasCrmRecording,
      data?.freemiumTrialOnboardingRecording?.recordingPath,
      navigate
    ]
  );

  return announcements.filter(({ isHidden }) => !isHidden);
};
