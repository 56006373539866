import { useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button, theme } from '@grain/grain-ui/v4';
import { LoginButton } from '~/components/LoginButton';

import { PrivacySecurityInfo } from '../../SignUp/PrivacySecurityInfo';
import { StepComponentProps } from '../';
import { FeatureCard } from './FeatureCard';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 524px;
  width: 100%;
  margin: 28px auto 0 auto;
  padding: 0 ${theme.tokens.spacing.lg};
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing['2xl']};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing.md};
  text-wrap: balance;
`;

const Title = styled.h1`
  ${theme.tokens.typography.h1};
  text-align: center;
  color: ${theme.tokens.color.textPrimary};
  margin: 0;
`;

const Description = styled.p`
  ${theme.tokens.typography.b1[400]};
  line-height: 24px;
  text-align: center;
  color: ${theme.tokens.color.textSecondary};
  margin: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${theme.tokens.spacing.md};
`;

const FeatureCards = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${theme.tokens.spacing.lg};
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.tokens.spacing['3xl']};
  margin-bottom: ${theme.tokens.spacing['3xl']};
`;

export const CalendarAccessWarning = ({
  registrationData,
  bypassCalendarAccessWarning
}: StepComponentProps) => {
  const [searchParams] = useSearchParams();

  const newUrlParams = new URLSearchParams();
  const addUrlParamIfExists = (key: string) => {
    const val = searchParams.get(key);
    val && newUrlParams.set(key, val);
  };

  newUrlParams.set('action', searchParams.get('action') || 'web_register');
  addUrlParamIfExists('redirect_to');
  addUrlParamIfExists('state');
  addUrlParamIfExists('post_registration');

  const redirect = (provider: string) =>
    `/app/_/redirect/${provider}?${newUrlParams.toString()}`;

  const provider = registrationData?.provider;

  const loginType = (() => {
    if (provider === 'google') return 'Google';
    if (provider === 'microsoft') return 'Microsoft';
    // Other cases should never happen.
  })();

  const calendarProduct = (() => {
    if (provider === 'google') return 'Google Calendar';
    if (provider === 'microsoft') return 'Outlook';
    // Other cases should never happen.
  })();

  return (
    <Container>
      <MainContent>
        <TextContainer>
          <Title>Grain doesn’t have calendar access</Title>
          <Description>
            You can still use Grain without connecting your calendar. However,
            you will miss out on these key features:
          </Description>
        </TextContainer>
        <FeatureCards>
          <FeatureCard
            name='Auto-record'
            description='Set rules to auto-record based on the participants of the meeting.'
          />
          <FeatureCard
            name='Meeting types'
            description='Calendar events help Grain sort customers from team participants.'
          />
          <FeatureCard
            name='Automated email summaries'
            description='Set rules to auto-send AI summaries to meeting participants.'
          />
          <FeatureCard
            name='Calendar page'
            description='View your meetings and easily manage which ones Grain records.'
          />
        </FeatureCards>
        <ButtonContainer>
          <LoginButton
            as='a'
            loginType={loginType}
            css={['width: 100%;']}
            href={redirect(provider)}
          >
            Connect {calendarProduct}
          </LoginButton>
          <Button
            data-cy='skip-for-now'
            fullWidth
            variant='ghost'
            size='xl'
            onClick={bypassCalendarAccessWarning}
          >
            Skip for now
          </Button>
        </ButtonContainer>
      </MainContent>
      <Footer>
        <PrivacySecurityInfo />
      </Footer>
    </Container>
  );
};
