import { useCallback, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Icon16, theme } from '@grain/grain-ui/v4';
import { useUserRegistration } from '../../Onboarding/hooks';

import { StepComponentProps } from '..';

export default function CreateWorkspace({ onNext }: StepComponentProps) {
  const { createWorkspace } = useUserRegistration();

  const [minimumWaitTimePromise] = useState(
    new Promise(resolve => setTimeout(resolve, 1000))
  );

  // Even though the workspace might be created quickly, try to prevent the
  // loading state from disappearing so quickly that the user would be left
  // unsure of what just flashed before his eyes.
  const proceedToNextStepAfterMinimumWait = useCallback(async () => {
    await minimumWaitTimePromise;
    onNext();
  }, [onNext, minimumWaitTimePromise]);

  // Ensure that createWorkspace doesn't get called multiple times due to
  // React's rendering schedule.
  const attemptedWorkspaceCreation = useRef(false);

  useEffect(() => {
    if (attemptedWorkspaceCreation.current) return;
    attemptedWorkspaceCreation.current = true;
    createWorkspace(proceedToNextStepAfterMinimumWait);
  }, [createWorkspace, proceedToNextStepAfterMinimumWait]);

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
      `}
    >
      <Icon16.AnimatedSpinner
        css={css`
          width: 96px;
          height: 96px;
          margin-top: 76px;
          color: ${theme.primitives.color.brand500};
        `}
      />
    </div>
  );
}
