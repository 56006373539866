import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Avatar,
  Checkbox,
  StopPropagation,
  color,
  font,
  formatRecordingTimeLabel,
  media,
  spacing
} from '@grain/grain-ui';
import { isTouchDevice } from 'lib/browser';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { SearchResultsV2Query } from '~/pages/Search/searchQuery.generated';
import { MATCH_TEXT_TYPES, formatMatchText } from '~/pages/Search/utils';
import { ParticipantsMenu } from '~/modules/meetings/ParticipantsMenu';
import { RecordingQuickActionsButton } from '../RecordingQuickActionsButton/RecordingQuickActionsButton';
import MatchGroup from './MatchGroup';
import { useSearchString } from '~/pages/Search/state';
import { useMediaQuery } from '@grain/components/support/browser';
import { theme } from '@grain/grain-ui/v4';
import { CompanyMetaItem } from '~/modules/meetings/CompanyMetaItem';
import { MetadataContainer } from '~/modules/meetings/ButtonMetadata';
import { useWorkspace } from '@grain/api/auth';

const Wrapper = styled(Link)<{ isChecked: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-top: 1px solid ${color.gull};
  padding: 16px 32px 16px 8px;
  background-color: ${props => (props.isChecked ? color.washbird : color.swan)};

  &:first-of-type {
    border-top: none;
  }

  &:last-child {
    border-bottom: 1px solid ${color.gull};
  }

  &:hover {
    text-decoration: none;
    background-color: ${props =>
      props.isChecked ? color.washbird : color.goose};
  }

  ${theme.utils.px('lg')};

  @media (${theme.tokens.breakpoints.lgMin}) {
    padding: 16px 32px 16px 8px;
  }
`;

const RecordingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Thumbnail = styled.img`
  width: 65px;
  height: 36px;
  object-fit: cover;
  margin-right: 4px;
  flex-shrink: 0;
  border-radius: 6px;
`;

const Content = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(0, 1fr) 120px 120px 55px 32px;
  align-items: center;
  ${spacing.g6}
  width: 100%;

  ${media.small} {
    grid-template-columns: minmax(0, 1fr) 32px;
  }
`;

const Title = styled.div<{ hasSearchString: boolean }>`
  ${font.v4.b3[600]}
  ${props => (props.hasSearchString ? color.fg.crow : color.fg.blackbird)}
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: none;
  }

  .search-match {
    ${color.fg.blackbird}
    ${font.v4.b3[700]};
  }
`;

const StyledDate = styled.span`
  ${font.v4.c1[500]}
  ${color.fg.pigeon}
`;

const Owner = styled.div`
  width: 120px;
  display: flex;
  align-items: center;
  gap: 6px;
  ${color.fg.crow}
  ${font.v4.c1[500]}
`;

const OwnerName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 16px);
`;

type RecordingSearchItemProps = {
  searchResult: SearchResultsV2Query['searchResultsV2']['results'][number];
  setSelectedRecordingIds: (ids: string[]) => void;
  selectedRecordingIds: string[];
  smartTagView?: boolean;
};

export const RecordingSearchItem = ({
  searchResult,
  setSelectedRecordingIds,
  selectedRecordingIds,
  smartTagView = false
}: RecordingSearchItemProps) => {
  const { workspace } = useWorkspace();
  const isSmallScreen = useMediaQuery(media.small);
  const [searchString] = useSearchString();
  const {
    recording: {
      id,
      title,
      startDatetime,
      duration,
      fullJpegThumbnailUrl,
      recordingPath,
      owner,
      externalGroupsData,
      participantScope,
      collections,
      storyCount,
      downloadUrl,
      recordingUrl,
      participants
    }
  } = searchResult;

  // touch devices don't have hover, so we treat them as hovered by default
  const [isHovered, setIsHovered] = useState(isTouchDevice);

  const isChecked = useMemo(
    () => selectedRecordingIds.includes(id),
    [id, selectedRecordingIds]
  );

  const hasSelectedRecordingIds = selectedRecordingIds.length > 0;

  const showCheckbox = useMemo(() => {
    return hasSelectedRecordingIds || isHovered;
  }, [isHovered, hasSelectedRecordingIds]);

  const handleCheckboxChange = () => {
    if (isChecked) {
      setSelectedRecordingIds(
        selectedRecordingIds.filter(recordingId => recordingId !== id)
      );
    } else {
      setSelectedRecordingIds([...selectedRecordingIds, id]);
    }
  };

  const matches = searchResult.hits.map(hit => ({
    matchText: hit.matchText,
    timestamp: hit.timestamp,
    type: hit.type,
    recordingPath: recordingPath,
    smartTagView: smartTagView
  }));

  return (
    <Wrapper
      to={recordingPath}
      isChecked={isChecked}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      <RecordingContainer>
        {!isSmallScreen && (
          <StopPropagation eventNames={['click']} preventDefault>
            <Checkbox
              containerProps={{
                css: css`
                  transition: opacity 0.2s ease-in-out;
                  opacity: ${showCheckbox ? 1 : 0};
                `
              }}
              checkboxSize='extrasmall'
              onChange={handleCheckboxChange}
              checked={isChecked}
            />
          </StopPropagation>
        )}

        <Thumbnail src={fullJpegThumbnailUrl || ''} />

        <Content>
          <Title hasSearchString={Boolean(searchString)}>
            <span>
              {formatMatchText(searchResult.matchText, MATCH_TEXT_TYPES.TITLE)}
            </span>
            <StyledDate>
              {formatRecordingTimeLabel(startDatetime, duration)}
            </StyledDate>
          </Title>
          {!isSmallScreen && (
            <StopPropagation eventNames={['click']} preventDefault>
              <Owner>
                <Avatar
                  avatarSize='extrasmall'
                  name={owner.name}
                  avatarUrl={owner.avatarUrl ?? false}
                  defaultAvatarColor={owner.hexColor}
                />
                <OwnerName>{owner.name}</OwnerName>
              </Owner>
            </StopPropagation>
          )}
          {!isSmallScreen && (
            <StopPropagation eventNames={['click']} preventDefault>
              <CompanyMetaItem
                participantScope={participantScope}
                externalGroupsData={externalGroupsData}
                workspaceName={workspace?.name || null}
                workspaceLogoUrl={workspace?.logoUrl || null}
              />
            </StopPropagation>
          )}
          {!isSmallScreen && (
            <StopPropagation eventNames={['click']} preventDefault>
              <MetadataContainer width='sm'>
                <ParticipantsMenu
                  externalGroupsData={externalGroupsData}
                  participants={participants}
                  owner={owner}
                  recordingId={id}
                  tippyProps={{
                    placement: 'bottom-end'
                  }}
                />
              </MetadataContainer>
            </StopPropagation>
          )}
          <StopPropagation eventNames={['click']} preventDefault>
            <RecordingQuickActionsButton
              disabled={hasSelectedRecordingIds}
              recordingId={id}
              recordingTitle={title}
              recordingCollections={collections}
              storyCount={storyCount}
              copyUrl={recordingUrl}
              downloadUrl={downloadUrl}
            />
          </StopPropagation>
        </Content>
      </RecordingContainer>
      {matches.length > 0 && (
        <StopPropagation eventNames={['click']} preventDefault>
          <MatchGroup matches={matches} />
        </StopPropagation>
      )}
    </Wrapper>
  );
};
