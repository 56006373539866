import styled from '@emotion/styled';
import { color, Icon } from '@grain/grain-ui';

import {
  Button,
  Divider,
  Icon16,
  Icon20,
  Input,
  Menu,
  MenuButton,
  PageHeader,
  theme,
  Tooltip
} from '@grain/grain-ui/v4';
import { css } from '@emotion/react';
import { Filters } from '~/modules/filtersV2/useFiltersManager';
import { FilterMenuButton } from '~/modules/filtersV2/FilterBar/FilterMenuButton';
import { useFilterBar } from '~/modules/filtersV2/FilterBar/useFilterBar';
import { TitleFilterModal } from '~/modules/filtersV2/FilterBar/TitleFilterModal';
import { DatePickerWithModal } from 'components/organisms/ContentFilter/filters/Date';
import { ActiveFilters } from '~/modules/filtersV2/FilterBar/ActiveFilters';
import { useSidebar } from '~/components/Layout';
import { isDesktopApp } from '@grain/desktop-lib';
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const OtherFiltersWrapper = styled.div`
  display: flex;
  border-right: 1px solid ${color.gull};
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  ${theme.utils.py('md')};
  ${theme.utils.px('3xl')};
`;

type RecordingsHeaderProps = {
  markAllAsViewed: () => void;
  handleCoachingOpportunityChange: () => void;
  onlyCoachingOpportunity: boolean;

  filters: Filters;
  resetFilters: () => void;
};

export const CoachingHeader = ({
  filters,
  markAllAsViewed,
  handleCoachingOpportunityChange,
  onlyCoachingOpportunity
}: RecordingsHeaderProps) => {
  const { toggleCollapsed } = useSidebar();
  const titleFilter = filters.title;
  const {
    menuRef,
    selectedFilterId,
    setSelectedFilterId,
    hasActiveVisibleFilters,
    activeFilter,
    selectFilter
  } = useFilterBar({ filters });

  return (
    <>
      <Container>
        <PageHeader
          icon={Icon20.Announcement}
          title='Coaching'
          onMenuClick={toggleCollapsed}
          isDesktopApp={isDesktopApp}
          rightSection={
            <div
              css={css`
                display: flex;
                align-items: center;
                ${theme.utils.gap('sm')}
              `}
            >
              <FilterMenuButton
                filters={filters}
                menuRef={menuRef}
                setSelectedFilterId={setSelectedFilterId}
                selectFilter={selectFilter}
                activeFilter={activeFilter}
              />

              <Menu
                content={
                  <MenuButton
                    label='Mark all meetings as seen'
                    onClick={() => markAllAsViewed()}
                  />
                }
              >
                <Button icon={Icon16.Overflow} variant='ghost' />
              </Menu>
            </div>
          }
        />
        <Divider />
        {hasActiveVisibleFilters && (
          <>
            <div
              css={css`
                display: flex;
                flex-wrap: wrap;
                gap: ${theme.tokens.spacing.sm};
                ${theme.utils.py('md')};
                ${theme.utils.px('3xl')}
              `}
            >
              <ActiveFilters filters={filters} />
            </div>
          </>
        )}

        <OtherFiltersWrapper>
          <Input
            value={titleFilter.value}
            onChange={e => titleFilter.setValue(e.target.value)}
            placeholder='Search'
            autoFocus
            css={{ input: { width: '280px !important' } }}
          />

          <Tooltip
            content={
              onlyCoachingOpportunity
                ? 'Show all meetings'
                : 'Only show coaching opportunities'
            }
          >
            <Button
              variant='ghost'
              onClick={handleCoachingOpportunityChange}
              icon={() => (
                <Icon.Megaphone
                  css={
                    onlyCoachingOpportunity
                      ? { color: color.graieen, stroke: color.graieen }
                      : { color: 'transparent', stroke: color.blackbird }
                  }
                />
              )}
            />
          </Tooltip>
        </OtherFiltersWrapper>
      </Container>
      {selectedFilterId === 'title' && (
        <TitleFilterModal
          filter={filters.title}
          handleCancel={() => setSelectedFilterId(null)}
        />
      )}
      {filters.date?.selectedOption && filters.date.showPicker && (
        <DatePickerWithModal
          hasRange={Boolean(filters.date.selectedOption.hasRange)}
          title={filters.date.selectedOption.label}
          onCancel={() => {
            filters.date.setShowPicker(false);
          }}
          onConfirm={({ startDate, endDate }) => {
            filters.date.toggle([startDate, endDate]);
            filters.date.setShowPicker(false);
          }}
        />
      )}
    </>
  );
};
