import styled from '@emotion/styled';
import { fontObject } from '@grain/styles/constants';
import { color, font, spacing } from 'base/css';

export const stripeElementStyles = {
  base: {
    ...fontObject.family.body,
    ...fontObject.bodyM,
    fontSize: '14px',
    fontWeight: 'normal',
    '::placeholder': {
      color: color.pigeon
    }
  }
};

export const StyledForm = styled.form`
  ${spacing.p7};
  display: flex;
  flex-direction: column;
  gap: 16px;
  .error {
    ${color.errorbird};
    ${font.v4.b2['400']};
    font-weight: normal;
    ${spacing.px1};
    ${spacing.pb1};
  }
`;

export const StyledElementBox = styled.div<{ error: Error; focused: boolean }>`
  position: relative;
  height: 40px;
  padding: 10px 0 11px;
  border-radius: 8px;
  border: 1px solid
    ${props => {
      if (props.error) return color.errorbird;
      if (props.focused) return color.graieen;
      return color.pigeon;
    }};
  .StripeElement {
    ${spacing.mx2};
    position: relative;

    > * {
      height: 17px;
    }
  }
`;

export const StyledSummary = styled.div`
  ${font.v4.b2['400']};
  ${spacing.mt6};

  font-weight: normal;
  text-align: justify;
`;

export const StyledLabel = styled.label`
  span {
    display: block;
    ${font.v4.c1['600']};
    ${spacing.mb2};
  }
`;

export const StyledError = styled.div`
  color: ${color.errorbird};
  ${font.v4.b2['400']};
  font-weight: normal;
  ${spacing.px1};
  ${spacing.pb1};
`;
