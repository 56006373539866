// @ts-strict-ignore
import { useCallback, useEffect, useRef } from 'react';
import pluralize from 'pluralize';
import api from '@grain/components/support/api';

type GenerateLiveChunkArgs = {
  name?: string;
  sentences?: number;
};

declare global {
  interface Window {
    generateLiveChunk: ({ name, sentences }: GenerateLiveChunkArgs) => void;
  }
}

// Real average is 8, this is pessimistic as the range is 4-16:
const AVG_WORDS_PER_SENTENCE = 12;
const WORD_DURATION = 300; // in ms

export function useLiveRecordingPageDebugFunctions({
  recordingId
}: {
  recordingId: string;
}) {
  const generateLiveChunk_lastRun = useRef<number>(null);

  // Call this function via the console to generate chunks of the transcript
  // immediately, for testing live functionality:
  //
  //   > generateLiveChunk()
  //
  //   > generateLiveChunk({ name: 'Foo Bar', sentences: 50 })
  //
  // NOTE: The timestamps of transcript chunks are set leading up to the time
  // they are requested.  Thus, if you were to request multiple chunks of words
  // in quick succession, the second chunk could easily have overlapping
  // timestamps, which is going to result in collisions, i.e. undefined and
  // unrealistic behavior.  Therefore this function will exit if it seems like
  // requesting another chunk will cause collisions with the last request.
  //
  const generateLiveChunk = useCallback(
    ({ name, sentences }: GenerateLiveChunkArgs = {}) => {
      const effectiveSentences = sentences ?? 1;
      if (typeof generateLiveChunk_lastRun.current === 'number') {
        const earliestOkayTime =
          Date.now() -
          effectiveSentences * AVG_WORDS_PER_SENTENCE * WORD_DURATION;
        if (earliestOkayTime < generateLiveChunk_lastRun.current) {
          const remainingTime = (
            (generateLiveChunk_lastRun.current - earliestOkayTime) /
            1000
          ).toFixed(1);
          // eslint-disable-next-line no-console
          console.warn(
            `Wait ${remainingTime}s longer before generating ${effectiveSentences} more ${pluralize(
              'sentence',
              effectiveSentences
            )}`
          );
          return;
        }
      }
      generateLiveChunk_lastRun.current = Date.now();
      const qs = [
        name && `speaker_name=${encodeURIComponent(name)}`,
        sentences && `sentence_count=${encodeURIComponent(sentences)}`
      ]
        .filter(Boolean)
        .join('&');
      api.get(
        `/_/gen_data/rec/${recordingId}/live_chunk` + (qs ? `?${qs}` : '')
      );
    },
    [recordingId]
  );

  useEffect(() => {
    if (process.env.ENVIRONMENT !== 'production') {
      window.generateLiveChunk = generateLiveChunk;
    }
  }, [generateLiveChunk]);
}
