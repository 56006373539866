import React from 'react';

import Button from '@grain/components/Button';
import DuplicateAdd from '~/modules/stories/AddToStory/DuplicateAdd';
import {
  Modal,
  ModalTitle,
  Video,
  addHighlightPreviewOverlayConfig,
  Icon,
  InputText
} from '@grain/grain-ui';
import { color } from '@grain/styles/constants';
import { storyState } from '~/pages/StoryPage/state';
import { useRecoilValue } from 'recoil';
import { useStoryCreateOrAdd } from '~/modules/stories/hooks';
import { useStoryToast } from '~/pages/StoryPage/hooks';
import { useAuth } from '~/support/auth';

import { useViewAdHocQuery } from './recentclips.generated';
import {
  StyledContent,
  StyledHighlightRow,
  StyledVideoPlaceholder,
  StyledThumbnail,
  StyledEmptyMessage
} from './styles';

export default function AddHighlightModal({ ...rest }) {
  const story = useRecoilValue(storyState);
  const duplicateAddClipIdRef = React.useRef(null);
  const [duplicateAddActive, setDuplicateAddActive] = React.useState(false);
  const [activeHighlight, setActiveHighlight] = React.useState();
  const addClipIdRef = React.useRef(null);
  const showToast = useStoryToast();
  const { isAuthenticated } = useAuth();
  const storyItems = story?.items;
  const storyClipIdSet = React.useMemo(() => {
    return storyItems.reduce((set, item) => {
      if (item.clip) set.add(item.clip.id);
      return set;
    }, new Set());
  }, [storyItems]);

  const { storyClipAdd: _storyClipAdd, storyClipAddRes } = useStoryCreateOrAdd({
    trackingLocation: 'story_document',
    storyClipAddOptions: {
      onCompleted() {
        showToast({
          content: 'Added to Story.'
        });
      }
    }
  });

  // Support searching highlights
  const [inputValue, setInputValue] = React.useState('');
  const DEBOUNCE_INPUT_TIME_MS = 500;
  const generateFilterJSON = searchString => {
    return JSON.stringify({
      types: ['highlights'],
      filters: [searchString && { title: searchString }].filter(Boolean)
    });
  };
  const [filterSettings, setFilterSettings] =
    React.useState(generateFilterJSON());

  // Query highlights when a new search filter is set
  const { data: viewAdHocData, loading: viewAdHocLoading } = useViewAdHocQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      sortBy: 'CHRONOLOGICAL',
      filter: filterSettings
    }
  });
  const hasNoSearchClips =
    !viewAdHocLoading && viewAdHocData?.viewAdHoc.list.length === 0;

  // Immediately set input value state but debounce setting search filter
  const inputDebounceRef = React.useRef();
  const handleInputChange = (value = '') => {
    setInputValue(value);

    clearTimeout(inputDebounceRef.current);
    inputDebounceRef.current = window.setTimeout(() => {
      setFilterSettings(generateFilterJSON(value));
    }, DEBOUNCE_INPUT_TIME_MS);
  };

  const highlightsData = viewAdHocData?.viewAdHoc.list ?? [];
  const hasNoRecentClips =
    !inputValue && !highlightsData.length && !viewAdHocLoading;
  return (
    <>
      <Modal width={516} closable {...rest}>
        <ModalTitle title='Add Clip To Story' centered />
        <StyledVideoPlaceholder>
          {activeHighlight?.id ? (
            activeHighlight.data.videoUrl ? (
              <Video
                key={activeHighlight.id}
                videoUrl={activeHighlight.data.videoUrl}
                containerWidth='100%'
                containerHeight='100%'
                overlayConfig={addHighlightPreviewOverlayConfig}
                isAuthenticated={isAuthenticated}
                withCredentials={true}
                initialState={{
                  playing: true,
                  duration:
                    (activeHighlight.data.outTimestamp -
                      activeHighlight.data.timestamp) /
                      1000 || 0
                }}
              />
            ) : (
              <>
                <div className='title'>Clip is processing...</div>
                <div className='subtitle'>
                  This should take less than a minute.
                </div>
              </>
            )
          ) : (
            <>
              <div className='title'>Clips will play here</div>
              <div className='subtitle'>
                Click on a thumbnail to play a Clip.
              </div>
            </>
          )}
        </StyledVideoPlaceholder>
        <StyledContent>
          <InputText
            css={[
              'margin: 0 -8px 24px -8px !important; width: calc(100% + 16px) !important;'
            ]}
            placeholder='Search by title'
            value={inputValue}
            onChange={event => handleInputChange(event?.target.value)}
          />
          {hasNoRecentClips ? (
            <StyledEmptyMessage>
              You have not created any Clips. Create Clips from a Recording.
            </StyledEmptyMessage>
          ) : hasNoSearchClips ? (
            <StyledEmptyMessage>No matching Clips.</StyledEmptyMessage>
          ) : (
            <>
              {highlightsData.map(clip => {
                const isActive = clip.id === activeHighlight?.id;
                const hasClip = storyClipIdSet.has(clip.id);

                // Using template literals is throwing a linting error.
                // BUG: Live highlights do not have a recording id, so don't
                // show link
                const recordingHref = clip.data.recording?.id
                  ? clip.data.recording.recordingPath +
                    `?mediaTab=clips&t=${clip.data.timestamp}`
                  : null;

                function storyClipAdd() {
                  _storyClipAdd({
                    variables: {
                      storyId: story.id,
                      clipId: clip.id
                    }
                  });
                  addClipIdRef.current = clip.id;
                }

                return (
                  <StyledHighlightRow key={clip.id}>
                    <div
                      css={['position: relative;']}
                      onClick={() => setActiveHighlight(clip)}
                    >
                      <StyledThumbnail
                        src={clip.data.thumbnailJpegUrl}
                        crossOrigin='anonymous'
                      />
                      {!isActive && <Icon.Play className='play-icon' />}
                    </div>
                    <div className='text-container'>
                      <div
                        css={[isActive && color.graieen]}
                        title={clip.data.text}
                        className='title'
                        onClick={() => setActiveHighlight(clip)}
                      >
                        {clip.data.text}
                      </div>
                      {recordingHref && (
                        <div
                          title={clip.data.recording?.title}
                          className='recording-title'
                        >
                          From&nbsp;
                          <a
                            css={[color.crow]}
                            rel='noopener noreferrer'
                            target='_blank'
                            href={recordingHref}
                          >
                            {clip.data.recording.title}
                          </a>
                        </div>
                      )}
                    </div>
                    {!hasClip ? (
                      <Button
                        type='primary'
                        css={[
                          'margin-left: auto; width: 80px; flex-shrink: 0;'
                        ]}
                        onClick={storyClipAdd}
                        disabled={
                          storyClipAddRes.loading &&
                          addClipIdRef.current === clip.id
                        }
                      >
                        Add
                      </Button>
                    ) : (
                      <Button
                        type='secondary'
                        css={[
                          'margin-left: auto; width: 80px; flex-shrink: 0;'
                        ]}
                        onClick={() => {
                          duplicateAddClipIdRef.current = clip.id;
                          setDuplicateAddActive(true);
                        }}
                      >
                        Added
                      </Button>
                    )}
                  </StyledHighlightRow>
                );
              })}
            </>
          )}
        </StyledContent>
      </Modal>
      {duplicateAddActive && (
        <DuplicateAdd
          storyId={story.id}
          clipId={duplicateAddClipIdRef.current}
          onCancel={() => setDuplicateAddActive(false)}
          onAddShowOpen={false}
        />
      )}
    </>
  );
}
