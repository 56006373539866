import { UploadImageModal } from '@grain/grain-ui';
import { useUserUploadInfoQuery } from './UploadImage.generated';
import { useWebSocket } from '@grain/components/WebSocketContext';

const SIDE_MIN_DIMENSION = 32;

type UploadLogoModalProps = {
  onCancel?: () => void;
  onUploadSuccess?: () => void;
};

const NOOP = () => {};
export default function UploadLogoModal({
  onCancel = NOOP,
  onUploadSuccess = NOOP,
  ...rest
}: UploadLogoModalProps) {
  const { data } = useUserUploadInfoQuery();
  const { userChannel } = useWebSocket();

  return (
    <div>
      {data?.userUploadInfo?.url && (
        <UploadImageModal
          uploadUrl={data.userUploadInfo.url.url}
          uploadUuid={data.userUploadInfo.url.uuid}
          maxUploadBytes={data.userUploadInfo.maxUploadBytes}
          minHeight={SIDE_MIN_DIMENSION}
          minWidth={SIDE_MIN_DIMENSION}
          onCancel={onCancel}
          showCropControls={true}
          cropAspectRatio={1}
          containerHeight='200px'
          containerWidth='100%'
          userChannel={userChannel}
          onUploadSuccess={onUploadSuccess}
          {...rest}
        />
      )}
    </div>
  );
}
