// Typing the optional query on strict is a bit too challenging
// for this reason, we're just getting the type from the default param
import React from 'react';

import type {
  Reference,
  StoreObject,
  WatchQueryFetchPolicy
} from '@apollo/client';
import { useApolloClient } from '@apollo/client';

import { useAnalytics } from '@grain/grain-ui';
import { ViewChangedType } from '@grain/api/schema.generated';

import {
  useViewAdHocQuery,
  useViewAdHocChangedSubscription
} from '~/modules/contentFilter/contentfilter.generated';

export const useAdHocSubscription = (filter: string) => {
  type AdHocData = {
    id: string;
  } & (string | Reference | StoreObject);

  const client = useApolloClient();

  const updateAdHocCache = (data: AdHocData) => {
    client.cache.modify({
      fields: {
        viewAdHoc(cacheData, { toReference }) {
          const newDataReference = toReference(data);
          return {
            ...cacheData,
            list: {
              [data.id]: newDataReference,
              ...cacheData.list
            }
          };
        }
      }
    });
  };

  const removeFromAdHocCache = (id: string) => {
    client.cache.modify({
      fields: {
        viewAdHoc(cacheData) {
          const newList = Object.keys(cacheData.list || {})
            .filter(key => key !== id)
            .reduce((obj: Record<string, unknown>, key) => {
              obj[key] = cacheData.list[key];
              return obj;
            }, {});
          return {
            ...cacheData,
            list: newList
          };
        }
      }
    });
  };

  type cacheDeleteItemArgs = { typename: string; id: string };
  const cacheDeleteItem = ({ typename, id }: cacheDeleteItemArgs) => {
    client.cache.evict({
      id: client.cache.identify({ __typename: typename, id })
    });
    client.cache.gc();
  };

  useViewAdHocChangedSubscription({
    variables: {
      filter
    },
    onData: ({ data }) => {
      const viewAdHocChanged = data.data?.viewAdHocChanged;
      if (viewAdHocChanged?.type === ViewChangedType.Added) {
        updateAdHocCache(viewAdHocChanged.data);
      }

      if (viewAdHocChanged?.type === ViewChangedType.Removed) {
        let typename = viewAdHocChanged.data.__typename;
        if (typename === 'RemovedMediaClip') typename = 'ClipV2';
        if (typename === 'RemovedMediaRecording') typename = 'Recording';
        if (typename === 'RemovedMediaStory') typename = 'Story';

        if (typename) {
          cacheDeleteItem({
            typename,
            id: viewAdHocChanged.data.id
          });
        }
        removeFromAdHocCache(viewAdHocChanged.data.id);
      }
    }
  });
};

type ResData = {
  viewAdHoc: {
    list: object[];
    cursor: string | null;
  };
};

export type useQueryArgs = {
  filter: string;
  query?: typeof useViewAdHocQuery;
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
  onCompleted?: (data: ResData) => void;
  variables?: object;
};

export const useQuery = ({
  filter,
  query = useViewAdHocQuery,
  fetchPolicy = 'cache-and-network',
  skip,
  onCompleted,
  variables = {}
}: useQueryArgs) => {
  const { trackEvent } = useAnalytics();

  const filterVariables = {
    filter,
    ...variables
  };

  const results = query({
    fetchPolicy,
    skip: Boolean(!filter || skip),
    variables: filterVariables,
    onCompleted: data => {
      onCompleted && onCompleted(data);

      const parsedFilter = JSON.parse(filter);

      const activeFilterNames = Object.entries(parsedFilter.filters).map(
        ([filterName, _]) => filterName
      );

      if (activeFilterNames.length) {
        trackEvent(
          'Filter Used',
          {
            content_type: parsedFilter.type,
            filter_by: activeFilterNames
          },
          ['user', 'workspace']
        );
      }
    }
  });
  useAdHocSubscription(filter);

  const hasNext = React.useMemo(
    () => !!results.data?.viewAdHoc?.cursor,
    [results.data]
  );

  const fetchNext = React.useCallback(() => {
    const cursor = results.data?.viewAdHoc?.cursor;

    if (cursor) {
      results.fetchMore({ variables: { cursor } });
    }
  }, [results]);

  return {
    results,
    hasNext,
    fetchNext
  };
};
