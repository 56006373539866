import React from 'react';
import styled from '@emotion/styled';

import Button from '@grain/components/Button';
import Input from '@grain/components/Input';
import { Icon } from '@grain/grain-ui';
import { color, spacing } from '@grain/styles/constants';

import { isValidEmail } from '~/support/validators';

const StyledContainer = styled.div`
  width: 100%;
  .placement-container {
    position: relative;
    svg {
      ${color.crow};
      left: 12px;
      position: absolute;
      top: 14px;
      width: 20px;
    }
  }

  input {
    height: 48px;
    padding-left: 40px;
    width: 100%;
  }

  button {
    ${spacing.mt4};
    height: 48px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .sso-label {
    font-weight: 600;
  }
`;

type SSOAuthProps = {
  redirect: string;
};

export default function SSOAuth(props: SSOAuthProps) {
  const [value, setValue] = React.useState('');
  const [isInputValid, setInputValid] = React.useState(false);

  React.useEffect(() => {
    setInputValid(isValidEmail(value));
  }, [value, isInputValid]);

  const onSSOSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const path = `${props.redirect}${value}`;
      window.location.href = path;
      return false;
    },
    [value, props]
  );

  return (
    <StyledContainer>
      <form onSubmit={onSSOSubmit}>
        <div className='placement-container'>
          <Icon.Mail />
          <Input
            placeholder='Work Email'
            type='text'
            onChange={e => setValue(e.currentTarget.value)}
          />
        </div>
        <Button htmlType='submit' type='primary' disabled={!isInputValid}>
          Continue with SSO
        </Button>
      </form>
    </StyledContainer>
  );
}
