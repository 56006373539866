import { useNavigate } from 'react-router-dom';
import {
  color,
  Icon,
  Dropdown,
  MenuItem,
  MenuDivider,
  DropdownProps,
  useRouteModal
} from '@grain/grain-ui';
import { useDeleteRecording } from '~/modules/recording/hooks';
import { Recording } from '@grain/api/schema.generated';
import { SHARE_RECORDING_ID } from '@grain/components/modals/constants';

type SmallScreenRecordingDropdownMenuProps = Partial<DropdownProps> & {
  recording: Recording;
  canDelete: boolean;
};

export const SmallScreenRecordingDropdownMenu: React.FC<
  SmallScreenRecordingDropdownMenuProps
> = ({ children, recording, canDelete, ...rest }) => {
  const navigate = useNavigate();
  const { open: openShare } = useRouteModal(SHARE_RECORDING_ID);

  const handleDeleteRecording = useDeleteRecording({
    recording,
    queryOptions: {
      onCompleted() {
        // redirect user to the library page
        navigate('/app/meetings', { replace: true });
      }
    }
  });

  return (
    <Dropdown placement='bottom' targetElement={children} {...rest}>
      <MenuItem onClick={openShare} label='Share' icon={Icon.Share} />
      <>
        {canDelete && (
          <>
            <MenuDivider />
            <MenuItem
              css={[color.fg.errorbird]}
              onClick={handleDeleteRecording}
              icon={Icon.Delete}
              label='Delete recording'
              dangerous
            />
          </>
        )}
      </>
    </Dropdown>
  );
};
