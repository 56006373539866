import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { pxToRem } from '@grain/grain-ui';
import { colors, font, spacing } from '@grain/styles/constants';

export const StyledPageContainer = styled.div`
  margin: 30vh auto 10vh;
  max-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledTitle = styled.div<{ redirectAction: string }>`
  ${font.titleM};
  ${spacing.mt4};

  ${props => {
    if (!props.redirectAction) return;
    return css`
      font-family: Scandia;
      font-weight: 500;
      font-size: ${pxToRem(30)};
    `;
  }};
`;

export const bodyStyles = css`
  ${font.bodyM};
  ${spacing.mt4};
  color: ${colors.pigeon};
`;

export const dividerStyles = css`
  ${spacing.mt6};
  background-color: ${colors.gull};
  height: 1px;
  width: 100%;
`;

export const noticeStyles = css`
  ${font.bodyM};
  ${spacing.mt3};
  color: ${colors.pigeon};
  a {
    cursor: pointer;
  }
`;
