import {
  StyledCard,
  StyledCardHeaderText,
  StyledCardSubHeaderText,
  StyledCardWrapper,
  StyledSkip
} from '../styles';
import { Icon, getOauthRedirectUrl } from '@grain/grain-ui';
import { StyledConnect } from './styles';
import { OnboardingRoleKey } from '../ChooseRole';
import { isDesktopApp } from '@grain/desktop-lib';
import { StepComponentProps } from '..';
import { getDesktopUrl } from 'desktop/lib/utils';
// TODO: delete this component once Freemium is GA

const ConnectCRM = ({ onNext, getNextHref }: StepComponentProps) => {
  const existingRole = window.localStorage.getItem(OnboardingRoleKey);
  const role = existingRole ? JSON.parse(existingRole) : null;
  const selectedCRM = role?.ob_survey_selected_crm_type;
  const isManager = role?.ob_survey_selected_work_type === 'Sales leader';

  const nextHref = new URL(getNextHref(), window.location.origin);
  nextHref.searchParams.set('crm_connected', '1');

  // NOTE: this URL generation logic is being migrated to modals/StartTrial
  // under the ConnectCRM component there:

  const hsHref = new URL(
    '/app/_/redirect/hubspot?enable=automation',
    window.location.origin
  );
  hsHref.searchParams.set(
    'redirect_to',
    `${nextHref.pathname}${nextHref.search}`
  );

  const hsPath = `${hsHref.pathname}${hsHref.search}`;
  const hubspotHref = isDesktopApp ? getDesktopUrl(hsPath) : hsPath;

  const salesforceRedirectTo = isDesktopApp
    ? getDesktopUrl(nextHref.pathname + nextHref.search)
    : nextHref.pathname + nextHref.search;
  const salesforceHref = getOauthRedirectUrl(
    'salesforce',
    true,
    'connect_account',
    {},
    salesforceRedirectTo
  );

  return (
    <StyledCardWrapper>
      <StyledCard>
        <StyledCardHeaderText>Connect your CRM</StyledCardHeaderText>
        <StyledCardSubHeaderText>
          {isManager
            ? 'Grain automatically updates deal notes so your reps can focus on selling.'
            : 'Grain automatically updates deal notes so you can focus on talking to your customers.'}
        </StyledCardSubHeaderText>

        <div className='content-wrapper' css={['width: 100%']}>
          {selectedCRM === 'HubSpot' && (
            <StyledConnect
              href={hubspotHref}
              data-track='Button Clicked'
              data-track-args={JSON.stringify({
                button_name: 'onboarding_connect_hubspot',
                trigger: 'onboarding_connect_crm',
                button_text: 'Connect HubSpot'
              })}
            >
              <span className='icon'>
                <Icon.Hubspot
                  css={['width: 24px; height: 24px;']}
                  rectFill='none'
                  pathFill='#FF5C35'
                />
              </span>{' '}
              Connect HubSpot
            </StyledConnect>
          )}

          {selectedCRM === 'Salesforce' && (
            <StyledConnect
              href={salesforceHref}
              data-track='Button Clicked'
              data-track-args={JSON.stringify({
                button_name: 'onboarding_connect_salesforce',
                trigger: 'onboarding_connect_crm',
                button_text: 'Connect Salesforce'
              })}
            >
              <span className='icon'>
                <Icon.Salesforce css={['width: 24px; height: 24px;']} />
              </span>{' '}
              Connect Salesforce
            </StyledConnect>
          )}
        </div>
        <StyledSkip onClick={onNext}>Skip Connecting CRM</StyledSkip>
      </StyledCard>
    </StyledCardWrapper>
  );
};

export default ConnectCRM;
