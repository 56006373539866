import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { Icon } from '@grain/grain-ui';
import { theme, Button, Input } from '@grain/grain-ui/v4';
import type { Filters } from '../useFiltersManager';
import { DatePickerWithModal } from 'components/organisms/ContentFilter/filters/Date';
import { ActiveFilters } from './ActiveFilters';
import { TitleFilterModal } from './TitleFilterModal';
import { FilterMenuButton } from './FilterMenuButton';
import { useFilterBar } from './useFilterBar';
import { css } from '@emotion/react';

const Container = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  min-height: 56px;
  flex-shrink: 0;

  ${theme.utils.py('14px')}
  ${theme.utils.px('lg')};

  @media (${theme.tokens.breakpoints.lgMin}) {
    ${theme.utils.px('3xl')};
    ${theme.utils.py('lg')};
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${theme.tokens.spacing.lg};
`;

const Controls = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: ${theme.tokens.spacing['2xl']};
`;

type FilterBarProps = PropsWithChildren<{
  className?: string;
  controls?: React.ReactNode;
  filters: Filters;
  resetAllFilters: () => void;
}>;

export const FilterBar = ({
  controls,
  filters,
  resetAllFilters,
  className
}: FilterBarProps) => {
  const {
    menuRef,
    selectedFilterId,
    setSelectedFilterId,
    hasActiveVisibleFilters,
    activeFilter,
    selectFilter,
    handleKeyDown
  } = useFilterBar({ filters });

  return (
    <>
      <Container className={className} onKeyDown={handleKeyDown}>
        <FiltersContainer>
          {!filters.title.skip && (
            <Input
              css={css`
                width: 200px;
              `}
              onClear={() => filters.title.setValue('')}
              placeholder={filters.title.placeholder}
              value={filters.title.value}
              onChange={e => filters.title.setValue(e.target.value)}
            />
          )}
          <FilterMenuButton
            menuRef={menuRef}
            filters={filters}
            setSelectedFilterId={setSelectedFilterId}
            selectFilter={selectFilter}
            activeFilter={activeFilter}
          />
          <ActiveFilters filters={filters} />
        </FiltersContainer>

        <Controls>
          {hasActiveVisibleFilters && (
            <Button
              size='md'
              variant='stroked'
              icon={Icon.X}
              onClick={resetAllFilters}
            >
              Clear filters
            </Button>
          )}
          {controls}
        </Controls>
      </Container>
      {selectedFilterId === 'title' && (
        <TitleFilterModal
          filter={filters.title}
          handleCancel={() => setSelectedFilterId(null)}
        />
      )}
      {filters.date?.selectedOption && filters.date.showPicker && (
        <DatePickerWithModal
          hasRange={Boolean(filters.date.selectedOption.hasRange)}
          title={filters.date.selectedOption.label}
          onCancel={() => {
            filters.date.setShowPicker(false);
          }}
          onConfirm={({ startDate, endDate }) => {
            filters.date.toggle([startDate, endDate]);
            filters.date.setShowPicker(false);
          }}
        />
      )}
    </>
  );
};
