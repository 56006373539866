import {
  StyledContent,
  ProgressBarContainer,
  StyledProgressBar,
  TextAreaWrapper,
  StyledButtons,
  StyledTitleContainer,
  OptionsSelectWrapper,
  StyledOption,
  PageWrapper
} from './styles';
import { Button, Icon16, Checkbox, TextArea } from '@grain/grain-ui/v4';
import React from 'react';
import { CancelStepsProps } from './CancelTypes';

const complaintsArray = [
  'Missing features',
  'Not using it enough',
  'My project is complete',
  'Cost (too expensive or budget cuts)',
  'Too difficult to use',
  'Switching to another product',
  'Other'
];

const EMPTY_ARRAY = [] as never[];
export const CancellationForm = ({
  step = 1,
  totalSteps = 3,
  nextStep,
  selectedOptions = EMPTY_ARRAY,
  setSelectedOptions,
  complaintText,
  setComplaintText,
  closeModal,
  setOtherProductText,
  otherProductText
}: CancelStepsProps) => {
  const showWhichProduct = selectedOptions.includes(
    'Switching to another product'
  );
  return (
    <PageWrapper>
      <StyledContent>
        <ProgressBarContainer>
          <StyledProgressBar
            type='progress'
            progressTheme='highlightGreenDark'
            size='medium'
            percent={(step / totalSteps) * 100}
          />
          <span>
            {' '}
            {step} of {totalSteps}{' '}
          </span>
          <span onClick={closeModal}>
            {' '}
            <Icon16.XSm />
          </span>
        </ProgressBarContainer>
        <StyledTitleContainer>
          <h1>Why do you want to cancel?</h1>
          <p>We’d love your input to make Grain better.</p>
        </StyledTitleContainer>
        <OptionsSelectWrapper>
          <span> Chose one or more </span>
          {complaintsArray.map(item => (
            <React.Fragment key={item}>
              <StyledOption>
                <Checkbox
                  checked={selectedOptions.includes(item)}
                  value={item}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    setSelectedOptions(ev.target.value, ev.target.checked);
                  }}
                  name='complaints'
                />
                <span>{item}</span>
              </StyledOption>
              {showWhichProduct && item === 'Switching to another product' && (
                <TextArea
                  placeholder='Which product? (Up to 100 characters)'
                  value={otherProductText}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setOtherProductText(e.target.value, 100);
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </OptionsSelectWrapper>
        <TextAreaWrapper>
          <span>Tell us more (optional) </span>
          <TextArea
            placeholder='Up to 300 characters'
            value={complaintText}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setComplaintText(e.target.value, 300);
            }}
          />
        </TextAreaWrapper>
      </StyledContent>
      <StyledButtons>
        <Button variant='neutral' onClick={closeModal}>
          Cancel
        </Button>
        <Button
          variant={selectedOptions.length === 0 ? 'neutral' : 'primary'}
          onClick={nextStep}
          disabled={
            selectedOptions.length === 0 ||
            (showWhichProduct && otherProductText.length === 0)
          }
        >
          Next
        </Button>
      </StyledButtons>
    </PageWrapper>
  );
};
