import { css } from '@emotion/react';
import {
  Button,
  Chip,
  Divider,
  Icon16,
  Menu,
  MenuButton,
  TextLabel,
  theme,
  useAutoScroll
} from '@grain/grain-ui/v4';
import {
  ComponentProps,
  Fragment,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useRecordingPage } from '../../context';
import {
  IntelligenceChatMessageRole,
  IntelligenceChatMessageStatus
} from '@g/schema';
import { UserChatMessage } from './UserChatMessage';
import { AssistantChatMessage } from './AssistantChatMessage/AssistantChatMessage';
import { useAskChatQueries } from './data';
import { useMyself } from '@grain/api/auth';
import {
  ChatWindow,
  CurrentPositionButton,
  Header,
  MenuContainer,
  RelativeContainer,
  StyledArrowUp,
  StyledGrainLogo,
  StyledTextArea,
  TopGradient
} from './styled';
import { useHotspots } from '~/components/Hotspot';
import styled from '@emotion/styled';
import { useResetChatMutation } from './ask-chat.generated';
import { motion, MotionConfig } from 'motion/react';
import { useAnalytics, useToasterPositionEffect } from '@grain/grain-ui';
import { OPEN_CHAT_ANIMATION_DURATION } from './constants';
import { ChatMessageWithAugmentedCitations } from './types';

type AskChatProps = {
  onOpen: () => void;
  onClose: () => void;
  size: 'sm' | 'lg';
};

const StyledSuggestionButton = styled(
  (props: ComponentProps<typeof Button>) => (
    <Button {...props} variant='neutral' />
  )
)`
  justify-content: start;
  align-self: start;
  text-wrap: wrap;
`;

const hardcodedSuggestions = [
  'Who were the meeting participants and their roles?',
  'What processes or workflows were discussed?',
  'What issues, feedback, or requests were brought up?'
];

export const AskChat = ({ size, onOpen, onClose }: AskChatProps) => {
  const { myself } = useMyself();
  const { recordingId, typedRecording } = useRecordingPage();
  const animatedMessagesRef = useRef<Set<string>>(new Set());
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const lastMessageRef = useRef<ChatMessageWithAugmentedCitations | null>(null);
  const [message, setMessage] = useState('');
  const [ref] = useHotspots('ask-ai-meetings', 'ask-ai');
  const [resetChat] = useResetChatMutation({
    variables: {
      recordingId
    }
  });
  const { trackEvent } = useAnalytics();
  const firstName = myself?.user?.name?.split(' ')?.[0] || '';

  const [isOpen, setIsOpen] = useState(false);

  const { chatMessages, historyTruncatedId, sendChatMessage } =
    useAskChatQueries();

  const {
    setScrollElement,
    isAutoScrollEnabled,
    enableAutoScroll,
    scrollElementRef
  } = useAutoScroll<HTMLDivElement>([chatMessages]);

  const shouldShowAutoScrollButton = useMemo(() => {
    if (isAutoScrollEnabled || !isOpen) {
      return false;
    }

    if (chatMessages[chatMessages.length - 1] !== lastMessageRef.current) {
      lastMessageRef.current = chatMessages[chatMessages.length - 1];
      return true;
    }

    return false;
  }, [chatMessages, isAutoScrollEnabled, isOpen]);

  const messageIds = useMemo(() => {
    return chatMessages.map(chatMessage => chatMessage.id);
  }, [chatMessages]);

  const indexOfHistoryTruncatedId = messageIds.indexOf(
    historyTruncatedId || ''
  );

  useToasterPositionEffect('bottom-center');

  // Sets initial scroll of the chat window on open
  useLayoutEffect(() => {
    if (isOpen && scrollElementRef.current) {
      scrollElementRef.current.scrollTop =
        scrollElementRef.current.scrollHeight;
    }
  }, [isOpen, scrollElementRef]);

  const submitForm = () => {
    sendChatMessage({
      variables: {
        recordingId,
        message
      }
    });
    setMessage('');
  };

  // Used for disabling the input when user is waiting on a response,
  // either last message being the user's or the assistant is still processing
  const waitingOnResponse = useMemo(() => {
    const lastMessage = chatMessages[chatMessages.length - 1];

    return (
      lastMessage?.status === IntelligenceChatMessageStatus.Processing ||
      lastMessage?.role === IntelligenceChatMessageRole.User
    );
  }, [chatMessages]);

  // Focuses the textarea when the chat is opened.
  useLayoutEffect(() => {
    if (isOpen && textAreaRef.current && !waitingOnResponse) {
      textAreaRef.current.focus();
    }
  }, [isOpen, waitingOnResponse]);

  return (
    <MotionConfig reducedMotion='never'>
      <RelativeContainer
        onClick={
          !isOpen
            ? () => {
                onOpen();
                setIsOpen(true);
              }
            : undefined
        }
      >
        <MenuContainer
          variants={{
            open: {
              height: '100%',
              width: '100%'
            },
            closed: {
              height: '48px'
            },
            lg: {
              width: '100%'
            },
            sm: {
              width: '144px'
            }
          }}
          initial={[size, 'closed']}
          animate={[size, isOpen ? 'open' : 'closed']}
          ref={ref}
        >
          {/* Header */}
          {isOpen && (
            <Header>
              <div
                css={css`
                  flex: 1;
                  color: ${theme.tokens.color.textPrimary};
                  ${theme.tokens.typography.b3[500]}
                `}
              >
                {typedRecording?.title}
              </div>
              <Chip variant='stroked' size='sm' title='Beta' />
              <Menu
                hideOnClickInside
                content={
                  <MenuButton
                    textLabelProps={{ startIcon: Icon16.Refresh }}
                    label='Reset Chat'
                    onClick={() => {
                      resetChat({
                        variables: {
                          recordingId
                        }
                      });
                      trackEvent('Button Clicked', {
                        button_name: 'ask_reset',
                        trigger: 'ask_recording'
                      });
                    }}
                  />
                }
              >
                <Button
                  variant='ghost'
                  textLabelProps={{ startIcon: Icon16.Overflow }}
                  onClick={() => {
                    trackEvent('Button Clicked', {
                      button_name: 'ask_overflow',
                      trigger: 'ask_recording'
                    });
                  }}
                />
              </Menu>
              <Button
                variant='neutral'
                textLabelProps={{ startIcon: Icon16.XSm }}
                onClick={() => {
                  setIsOpen(false);
                  onClose();
                }}
              />
            </Header>
          )}

          {/* Middle Chat Section */}
          {isOpen && (
            <ChatWindow
              ref={setScrollElement}
              initial={{ overflowY: 'hidden' }}
              animate={{
                overflowY: 'auto',
                transition: { delay: 0.3 }
              }}
            >
              <TopGradient />
              <div
                css={css`
                  flex: 1 1 auto;
                `}
              />
              <div
                css={css`
                  display: grid;
                  grid-template-rows: fit-content;
                  gap: ${theme.tokens.spacing.lg};
                `}
              >
                {chatMessages.length === 0 && (
                  <motion.div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      gap: ${theme.tokens.spacing.sm};
                    `}
                    variants={{
                      hidden: { opacity: 0 },
                      show: {
                        opacity: 1,
                        transition: {
                          // This will stagger the opacity of the children (used for suggestions)
                          staggerChildren: 0.5,
                          delay: OPEN_CHAT_ANIMATION_DURATION
                        }
                      }
                    }}
                    initial='hidden'
                    animate='show'
                  >
                    <TextLabel
                      size='lg'
                      css={css`
                        & ${TextLabel.Text} {
                          ${theme.tokens.typography.b2[600]}
                        }

                        margin-bottom: ${theme.tokens.spacing.sm};
                      `}
                    >
                      Hi {firstName ? ` ${firstName}` : ''}! What do you want to
                      know about this meeting?
                    </TextLabel>
                    <motion.div
                      variants={{
                        hidden: { opacity: 0, x: -20 },
                        show: { opacity: 1, x: 0 }
                      }}
                    >
                      <Divider>Suggestions</Divider>
                    </motion.div>
                    {hardcodedSuggestions.map(suggestion => (
                      <motion.div
                        key={suggestion}
                        transition={{
                          duration: 0.2,
                          ease: 'easeInOut'
                        }}
                        variants={{
                          hidden: { opacity: 0, x: -20 },
                          show: { opacity: 1, x: 0 }
                        }}
                      >
                        <StyledSuggestionButton
                          onClick={() => {
                            sendChatMessage({
                              variables: {
                                recordingId,
                                message: suggestion
                              }
                            });
                            trackEvent('Button Clicked', {
                              button_name: 'ask_suggestion',
                              trigger: 'ask_recording',
                              button_text: suggestion
                            });
                          }}
                        >
                          {suggestion}
                        </StyledSuggestionButton>
                      </motion.div>
                    ))}
                  </motion.div>
                )}
                {chatMessages.map((chatMessage, i) => {
                  const includedInContext = i > indexOfHistoryTruncatedId;
                  const isFirstInContext =
                    chatMessage.id === historyTruncatedId;

                  return (
                    <Fragment key={chatMessage.id}>
                      {isFirstInContext && (
                        <div
                          css={css`
                            ${theme.tokens.typography.b4[500]};
                            text-align: center;
                            ${theme.utils.padding('10px', 'none')}
                            color: ${theme.tokens.color.textSecondary};
                          `}
                        >
                          Messages above are no longer considered for responses
                          in this conversation.
                        </div>
                      )}
                      {chatMessage.role ===
                        IntelligenceChatMessageRole.Assistant && (
                        <AssistantChatMessage
                          chatMessage={chatMessage}
                          includedInContext={includedInContext}
                        />
                      )}
                      {chatMessage.role ===
                        IntelligenceChatMessageRole.User && (
                        <UserChatMessage
                          chatMessage={chatMessage}
                          includedInContext={includedInContext}
                          hasAnimated={animatedMessagesRef.current.has(
                            chatMessage.id
                          )}
                          onAnimationComplete={() => {
                            animatedMessagesRef.current.add(chatMessage.id);
                          }}
                        />
                      )}
                    </Fragment>
                  );
                })}
              </div>
            </ChatWindow>
          )}

          {/* Footer */}
          <form
            css={css`
              width: 100%;
              display: flex;
              align-self: end;
              align-items: center;
              position: relative;
              padding: 6px 8px;
            `}
            onSubmit={e => {
              e.preventDefault();
              submitForm();
            }}
          >
            {shouldShowAutoScrollButton && (
              <CurrentPositionButton
                onClick={enableAutoScroll}
                variant='stroked'
                textLabelProps={{
                  startIcon: Icon16.ArrowDown
                }}
              >
                Current position
              </CurrentPositionButton>
            )}
            <motion.div
              css={css`
                width: 32px;
                height: 32px;
              `}
              animate={{ width: isOpen ? '0' : '32px' }}
              transition={{ duration: 0.2, ease: 'easeInOut' }}
            >
              <StyledGrainLogo />
            </motion.div>

            <StyledTextArea
              ref={textAreaRef}
              isOpen={isOpen}
              chatSize={size}
              placeholder={
                size === 'lg' || isOpen
                  ? 'Ask Grain about this meeting'
                  : 'Ask Grain...'
              }
              value={message}
              onChange={e => setMessage(e.target.value)}
              textAreaProps={{
                autoFocus: Boolean(!waitingOnResponse && isOpen),
                name: 'message',
                autoComplete: 'off',
                rows: 1,
                required: true,
                style: {
                  maxHeight: '200px'
                },
                disabled: waitingOnResponse || !isOpen,
                onKeyDown: e => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    submitForm();
                  } else if (e.key === 'Escape') {
                    e.preventDefault();
                    setIsOpen(false);
                    onClose();
                  }
                }
              }}
              endIcon={
                size === 'lg'
                  ? () => (
                      <Button
                        variant='ghost'
                        disabled={waitingOnResponse}
                        textLabelProps={{
                          startIcon: (
                            <StyledArrowUp
                              isValid={isOpen && message.length > 0}
                            />
                          )
                        }}
                        css={css`
                          justify-content: end;
                          align-self: end;
                          margin: -4px -8px -4px 0;
                        `}
                        type='submit'
                      />
                    )
                  : undefined
              }
            />
          </form>
        </MenuContainer>
      </RelativeContainer>
    </MotionConfig>
  );
};
