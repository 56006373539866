import { Button, color } from '@grain/grain-ui';
import {
  SectionContentRowDetails,
  SectionContentRowTitle,
  StyledSectionContentRow
} from './styles';

type SectionContentRowProps = {
  title: string;
  details: React.ReactNode;
  subDetails?: string;
  cta: string;
  onClick?: () => void | null;
};

export default function SectionContentRow({
  title,
  details,
  subDetails,
  cta,
  onClick
}: SectionContentRowProps) {
  return (
    <StyledSectionContentRow>
      <SectionContentRowTitle>{title}</SectionContentRowTitle>
      <SectionContentRowDetails css={['text-align: left']}>
        {details}
        {subDetails && (
          <span css={[`color: ${color.crow}`, 'padding-left: 24px']}>
            {subDetails}
          </span>
        )}
      </SectionContentRowDetails>
      {onClick && (
        <Button variant='secondary' onClick={onClick}>
          {cta}
        </Button>
      )}
    </StyledSectionContentRow>
  );
}
