import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { flex, pxToRem } from '@grain/grain-ui';
import { spacing, font } from '@grain/styles/constants';

export const StyledContent = styled.div`
  ${font.bodyM};
  ${spacing.mt6};
  ${spacing.px6};
`;

export const StyledButtons = styled.div`
  ${flex.direction.row};
  ${flex.justifyContent.spaceAround};
  ${spacing.py4};
  ${spacing.px3};
`;

export const buttonStyles = css`
  width: 180px;
  height: 40px;
  font-size: ${pxToRem(14)};

  &:not(:last-child) {
    ${spacing.mb4};
  }
`;
