import React from 'react';
import styled from '@emotion/styled';

import { color, font, spacing } from '@grain/grain-ui';
import { Divider, theme } from '@grain/grain-ui/v4';

import { Table } from './Table';

import { useFilteredViewAdHoc } from '~/modules/filters';

import { useDealStagesQuery } from '../deal.generated';

import { SortBy } from '..';
import { css } from '@emotion/react';
import { useFiltersManager } from '~/modules/filtersV2/useFiltersManager';
import { FilterBar } from '~/modules/filtersV2/FilterBar/FilterBar';
import { scrollbarStyles } from 'ui/v4/theme/utils';

const Dashboard = styled.div`
  height: 100%;
  width: 100%;
  border-top: 1px solid ${color.gull};

  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const StageBar = styled.div`
  ${spacing.px4}
  ${spacing.pb2}
  ${spacing.mb2}

  display: flex;
  flex-direction: row;
  ${spacing.g3}

  overflow-x: auto;

  ${scrollbarStyles}
`;

const StageItem = styled.div<{ active: boolean }>`
  ${spacing.px4}
  ${spacing.py3}
  width: 160px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 8px;
  border: 1px solid ${color.gull};
  cursor: ${props => (props.active ? 'default' : 'pointer')};
  background: ${props => (props.active ? color.gull : color.swan)};

  ${props =>
    !props.active &&
    css`
      :hover {
        background: ${color.gull};
        border: 1px solid ${color.gull};
      }
    `}
`;

const StageName = styled.div<{ active: boolean }>`
  color: ${props =>
    props.active
      ? theme.tokens.color.textPrimary
      : theme.tokens.color.textSecondary};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${font.v4.b3[500]}
`;

const StageBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  ${font.v4.c1[500]}
`;

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

const formatAtRisk = (amount: number) =>
  amount > 1000 ? `${Math.trunc(amount / 1000)}k` : amount;

type DealsDashboardProps = {
  sortByState: [SortBy, React.Dispatch<React.SetStateAction<SortBy>>];
  stageResults: ReturnType<typeof useDealStagesQuery>;
  dealsQueryResponse: ReturnType<typeof useFilteredViewAdHoc>['queryResponse'];
  resetFilters: () => void;
  filters: ReturnType<typeof useFiltersManager>['filters'];
  currentStage: string | undefined;
  setCurrentStage: (stageId: string | undefined) => void;
};

export const DealsDashboard = ({
  sortByState,
  stageResults,
  dealsQueryResponse,
  resetFilters,
  filters,
  currentStage,
  setCurrentStage
}: DealsDashboardProps) => {
  const { loading } = dealsQueryResponse.results;
  const { data: stagesData } = stageResults;

  const stages = React.useMemo(() => {
    if (!stagesData) return [];
    const { dealOpenTotal, dealStages } = stagesData;
    // Normalize the open deals stage to match the rest of the stages
    const openStage = {
      ...dealOpenTotal,
      label: 'Open Deals',
      stageId: undefined as string | undefined,
      isOpen: true
    };

    return [openStage, ...dealStages];
  }, [stagesData]);

  return (
    <Dashboard>
      <div>
        <FilterBar filters={filters} resetAllFilters={resetFilters} />
        <Divider css={[spacing.mb4]} />
        <StageBar>
          {stages.map(stage => (
            <StageItem
              key={stage.stageId ?? stage.label}
              active={stage?.stageId === currentStage}
              onClick={() =>
                stage?.stageId !== currentStage &&
                setCurrentStage(stage?.stageId)
              }
            >
              <StageName active={stage?.stageId === currentStage}>
                {stage.label}
              </StageName>
              <StageBody>
                <div css={[color.fg.crow]}>
                  {formatter.format(stage?.amount ?? 0)} ({stage.count})
                </div>
                {stage?.isOpen && typeof stage?.amountAtRisk === 'number' && (
                  <div css={[color.fg.pigeon]}>
                    ${formatAtRisk(stage.amountAtRisk)} at risk
                  </div>
                )}
              </StageBody>
            </StageItem>
          ))}
        </StageBar>
      </div>

      <Table
        stageResults={stageResults}
        dealsQueryResponse={dealsQueryResponse}
        sortByState={sortByState}
        loading={loading}
      />
    </Dashboard>
  );
};
