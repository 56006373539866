import Button from '@grain/components/Button';
import { Modal } from '@grain/grain-ui';
import React from 'react';
import styled from '@emotion/styled';
import { color, spacing } from '@grain/styles/constants';
import { useExtra, STORY_FTUX_STEP_IDS } from '@grain/components/support/extra';

const StyledContent = styled.div`
  ${spacing.p6};
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.div`
  ${color.blackbird};
  ${spacing.mb3};
  ${spacing.mt4};
  font-size: calc(24rem / 16);
  font-weight: 600;
`;

const StyledMessage = styled.div`
  ${color.dipper};
  ${spacing.mb6};
  font-size: calc(14rem / 16);
  font-weight: 400;
  text-align: center;
`;

const StyledLink = styled.a`
  ${color.pigeon};
  cursor: pointer;
  font-size: calc(12rem / 16);
  font-weight: 500;
`;

export default function Start({ ...rest }) {
  const [showStartFtux, setShowStartFtux] = React.useState(false);

  const { extra, saveExtra, initialized: extraInitialized } = useExtra();
  React.useEffect(() => {
    if (!extra || !extraInitialized) return;
    const showStartFtux = !extra.storyFtuxStartShown;
    setShowStartFtux(showStartFtux);
  }, [extra, saveExtra, extraInitialized]);

  const continueFlow = React.useCallback(() => {
    setShowStartFtux(false);
    saveExtra({
      ...extra,
      storyFtuxStartShown: true
    });
  }, [extra, saveExtra]);

  const skipFlow = React.useCallback(() => {
    setShowStartFtux(false);
    saveExtra({
      ...extra,
      storyFtuxStartShown: true,
      hotspots: {
        ...extra.hotspots,
        'story-ftux': STORY_FTUX_STEP_IDS
      }
    });
  }, [extra, saveExtra]);

  if (!showStartFtux) return null;

  return (
    <Modal
      data-cy='story-ftux-start'
      onCancel={() => setShowStartFtux(false)}
      css={['width: 412px; !important']}
      closable
      {...rest}
    >
      <StyledContent>
        <StyledTitle>Your clips tell a story</StyledTitle>
        <StyledMessage>
          A story is a place to collect and arrange your clips into a dynamic,
          shareable experience.
        </StyledMessage>
        <Button onClick={continueFlow} type='accent' css={[spacing.mb3]}>
          Learn more
        </Button>
        <StyledLink onClick={skipFlow}>Skip tutorial</StyledLink>
      </StyledContent>
    </Modal>
  );
}
