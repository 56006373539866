import { UserRole } from '@grain/api/schema.generated';
import { useCallback, useMemo, useRef, useState } from 'react';
import { AdHocRecordingFragment } from '~/modules/contentFilter/contentfilter.generated';
import { useMyself } from '~/support/auth';

export const useActionBar = (recordings: AdHocRecordingFragment[]) => {
  const { myself } = useMyself();
  const [selectedMeetingIds, setSelectedMeetingIds] = useState<string[]>([]);
  const [showActionBar, setShowActionBar] = useState(false);
  const lastSelectedIdRef = useRef<string | null>(null);

  const recordingIds = useMemo(() => {
    return recordings.map(recording => recording.id);
  }, [recordings]);

  const toggleSelectedMeetingId = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
      setSelectedMeetingIds(prev => {
        let returnState = [...prev];

        if (
          // Handle shift-click selection
          event.nativeEvent instanceof MouseEvent &&
          event.nativeEvent.shiftKey &&
          lastSelectedIdRef.current
        ) {
          const currentIndex = recordingIds.indexOf(id);
          const lastIndex = recordingIds.indexOf(lastSelectedIdRef.current);
          const [startIndex, endIndex] = [
            Math.min(currentIndex, lastIndex),
            Math.max(currentIndex, lastIndex)
          ];

          const selectedRange = recordingIds.slice(startIndex, endIndex + 1);
          const isUnselecting = prev.includes(id);

          if (isUnselecting) {
            // Remove the range if we're unselecting
            returnState = prev.filter(id => !selectedRange.includes(id));
          } else {
            // Add the range if we're selecting
            returnState = Array.from(new Set([...prev, ...selectedRange]));
          }
        } else {
          // Handle normal toggle
          if (prev.includes(id)) {
            returnState = prev.filter(p => p !== id);
          } else {
            returnState = [...prev, id];
          }
        }

        setShowActionBar(returnState.length > 0);
        lastSelectedIdRef.current = id;
        return returnState;
      });
    },
    [setSelectedMeetingIds, recordingIds, setShowActionBar, lastSelectedIdRef]
  );

  const recordingsMap = useMemo(() => {
    return new Map(recordings.map(recording => [recording.id, recording]));
  }, [recordings]);

  const isAdmin = myself?.user?.role === UserRole.Admin;
  const canDelete = useMemo(() => {
    if (isAdmin) return true;

    return selectedMeetingIds.every(id => {
      const recording = recordingsMap.get(id);

      return recording?.owner?.isSelf;
    });
  }, [recordingsMap, selectedMeetingIds, isAdmin]);

  const resetSelected = (closeActionBar: boolean = true) => {
    setSelectedMeetingIds([]);
    if (closeActionBar) {
      setShowActionBar(false);
    }
  };

  return {
    selectedMeetingIds,
    showActionBar,
    resetSelected,
    toggleSelectedMeetingId,
    canDelete
  };
};
