import styled from '@emotion/styled';
import { overflowEllipsis, color } from '@grain/grain-ui';
import { IS_WINDOWS } from 'desktop/lib/constants';

export const Container = styled.div<{ variant: string; dark: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  // -webkit-app-region: drag;

  background-color: ${props => (props.dark ? color.raven : 'white')};

  // Reverse order on mac: actions on right.
  flex-direction: ${props => (props.variant === 'mac' ? 'row-reverse' : 'row')};
  height: ${props => (props.variant === 'mac' ? '40px' : '36px')};

  -webkit-app-region: drag;

  & > * {
    -webkit-app-region: no-drag;
  }
`;

export const Title = styled.div<{ hasErrorText: boolean; dark: boolean }>`
  position: absolute;
  pointer-events: none;
  user-select: none;
  left: 28%;
  right: 28%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props =>
    props.hasErrorText
      ? color.errorbird
      : props.dark
        ? color.pigeon
        : color.crow};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  span {
    ${overflowEllipsis};
  }
  -webkit-app-region: drag;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
  user-select: none;
  ${IS_WINDOWS && 'flex-direction: row-reverse;'}
`;

export const WindowsControls = styled.div<{ dark: boolean }>`
  display: flex;
  align-items: center;
  font-family: 'Segoe MDL2 Assets';
  font-size: 10px;

  .button {
    transition: all 0.1s;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: default;
    user-select: none;

    color: ${props => (props.dark ? color.pigeon : 'inherit')};
    background-color: rgba(0, 0, 0, 0);

    &:hover {
      background-color: ${props =>
        props.dark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'};
    }
  }

  .maximize {
    background-color: rgba(0, 0, 0, 0) !important;
    opacity: 0.4;
  }
  .close {
    &:hover {
      background-color: rgba(232, 17, 35, 0.9);
      color: white;
    }
  }
`;
