import { DealDiagnosticMethodologyDimension } from '@grain/api/schema.generated';
import { escapeRegExp } from 'lib/regexp';

export const getDimensionAndSubDimensionLabel = (dimension: string) => {
  switch (dimension) {
    case DealDiagnosticMethodologyDimension.SpicedSituation:
      return 'Situation';
    case DealDiagnosticMethodologyDimension.SpicedPain:
      return 'Pain';
    case DealDiagnosticMethodologyDimension.SpicedImpact:
      return 'Impact';
    case DealDiagnosticMethodologyDimension.SpicedCriticalEvent:
      return 'Critical Event';
    case DealDiagnosticMethodologyDimension.SpicedDecision:
      return 'Decision';
    case DealDiagnosticMethodologyDimension.MeddpiccMetrics:
      return 'Metrics';
    case DealDiagnosticMethodologyDimension.MeddpiccEconomicBuyer:
      return 'Economic Buyer';
    case DealDiagnosticMethodologyDimension.MeddpiccDecisionCriteria:
      return 'Decision Criteria';
    case DealDiagnosticMethodologyDimension.MeddpiccDecisionProcess:
      return 'Decision Process';
    case DealDiagnosticMethodologyDimension.MeddpiccPaperProcess:
      return 'Paper Process';
    case DealDiagnosticMethodologyDimension.MeddpiccImplicatedPain:
      return 'Implicated Pain';
    case DealDiagnosticMethodologyDimension.MeddpiccChampion:
      return 'Champion';
    case DealDiagnosticMethodologyDimension.MeddpiccCompetition:
      return 'Competition';
    // SPICED SubDimensions
    case 'spiced_situation_context':
      return 'Context';
    case 'spiced_situation_competition':
      return 'Competition';
    case 'spiced_pain_quantifiable_pain':
      return 'Quantifiable Pain';
    case 'spiced_pain_qualitative_pain':
      return 'Qualitative Pain';
    case 'spiced_impact_rational_impact':
      return 'Rational Impact';
    case 'spiced_impact_emotional_impact':
      return 'Emotional Impact';
    case 'spiced_critical_event_critical_event':
      return 'Critical Event';
    case 'spiced_critical_event_compelling_event':
      return 'Compelling Event';
    case 'spiced_decision_decision_criteria':
      return 'Decision Criteria';
    case 'spiced_decision_decision_process':
      return 'Decision Process';
    default:
      return dimension;
  }
};

const dimensionOrderMap: Record<string, number> = {
  [DealDiagnosticMethodologyDimension.SpicedSituation]: 1,
  [DealDiagnosticMethodologyDimension.SpicedPain]: 2,
  [DealDiagnosticMethodologyDimension.SpicedImpact]: 3,
  [DealDiagnosticMethodologyDimension.SpicedCriticalEvent]: 4,
  [DealDiagnosticMethodologyDimension.SpicedDecision]: 5
};

export const dimensionSorter = <T extends { dimension: string }>(
  dimensionA: T,
  dimensionB: T
) => {
  const orderA = dimensionOrderMap[dimensionA.dimension];
  const orderB = dimensionOrderMap[dimensionB.dimension];

  return orderA - orderB;
};

const getSubDimensionOrder = (subdimension: string) => {
  switch (subdimension) {
    case 'spiced_situation_context':
    case 'spiced_pain_quantifiable_pain':
    case 'spiced_impact_rational_impact':
    case 'spiced_critical_event_critical_event':
    case 'spiced_decision_decision_criteria':
      return 1;
    case 'spiced_situation_competition':
    case 'spiced_pain_qualitative_pain':
    case 'spiced_impact_emotional_impact':
    case 'spiced_critical_event_compelling_event':
    case 'spiced_decision_decision_process':
      return 2;
    default:
      return 3;
  }
};

export const subDimensionSorter = <T extends { subdimension: string }>(
  subdimensionA: T,
  subdimensionB: T
) => {
  const orderA = getSubDimensionOrder(subdimensionA.subdimension);
  const orderB = getSubDimensionOrder(subdimensionB.subdimension);

  return orderA - orderB;
};

export const makeCompanyNameBoldInMarkdown = (
  originalText: string,
  companyName?: string
) => {
  if (!companyName) {
    return originalText;
  }

  const escapedCompanyName = escapeRegExp(companyName);
  const regex = new RegExp(escapedCompanyName, 'g');
  return originalText.replace(regex, `**${companyName}**`);
};
