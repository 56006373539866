import { css } from '@emotion/react';
import { Icon, media } from '@grain/grain-ui';
import { Link } from 'react-router-dom';
import {
  Button,
  Icon16,
  Icon20,
  Menu,
  MenuButton,
  PageHeader
} from '@grain/grain-ui/v4';
import { theme } from 'ui/v4/theme';
import { useSidebar } from '~/components/Layout';
import { MetadataItemsConfigButton } from '~/modules/meetings/MetadataItemsConfigButton';
import { useMeetingMetadataItems } from '~/modules/meetings/useMeetingMetadataItems';
import { useMediaQuery } from '@grain/components/support/browser';
import { isDesktopApp } from '@grain/desktop-lib';

export const MeetingsPageTitle = () => {
  const isSmallScreen = useMediaQuery(media.small);
  const { toggleCollapsed } = useSidebar();
  const { selectedMetadataItems, onMetadataItemToggle } =
    useMeetingMetadataItems();
  return (
    <PageHeader
      icon={Icon20.Record}
      css={[isDesktopApp && isSmallScreen && 'margin-left: 72px;']}
      title='Meetings'
      onMenuClick={toggleCollapsed}
      rightSection={
        <div
          css={css`
            display: inline-flex;
            gap: ${theme.tokens.spacing.sm};
          `}
        >
          <Menu
            content={
              <>
                <MenuButton
                  as={Link}
                  to='/app/import-zoom'
                  label='Import from Zoom'
                />
                <MenuButton
                  as={Link}
                  to='/app/upload-recording'
                  label='Upload a File'
                />
              </>
            }
          >
            <Button
              data-cy='new-recording'
              variant='neutral'
              size='md'
              icon={Icon16.Plus}
            >
              Add
            </Button>
          </Menu>
          {!isSmallScreen && (
            <MetadataItemsConfigButton
              selectedItems={selectedMetadataItems}
              onItemToggle={onMetadataItemToggle}
            />
          )}
          <Menu
            width='fit-content'
            content={
              <>
                <MenuButton
                  as={Link}
                  to='/app/deleted'
                  textLabelProps={{ startIcon: Icon.Delete }}
                  label='Recently deleted'
                />
              </>
            }
          >
            <Button variant='ghost' size='md' icon={Icon16.Overflow} />
          </Menu>
        </div>
      }
    />
  );
};
