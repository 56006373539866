import PropTypes from 'prop-types';
import { TagSelector } from '~/components/TagSelector';
import { useApolloClient } from '@apollo/client';
import { useMutation } from '@grain/api/graphql';
import { useWorkspaceStoryTags } from '@grain/components/Tags/hooks';

import {
  storyTagAddMutation,
  storyTagRemoveMutation,
  storyQuery
} from '~/modules/stories/graphql';

import {
  StyledTagManagementButton,
  StyledTagCount,
  StyledChevronIcon
} from './styles';

StoryTagSelector.propTypes = {
  story: PropTypes.object.isRequired
};

export default function StoryTagSelector({ story, ...rest }) {
  const client = useApolloClient();

  const { workspaceStoryTags } = useWorkspaceStoryTags();
  const [addStoryTag] = useMutation(storyTagAddMutation, {
    variables: { storyId: story?.id }
  });
  const [removeStoryTag] = useMutation(storyTagRemoveMutation, {
    variables: { storyId: story?.id }
  });

  // Optimistically add the tag to the cache
  // TODO: evict any client- tags from the cache when possible
  function optimisticAddTag(formattedText, currentTags) {
    const clientTag = {
      __typename: 'Tag',
      id: `client-${formattedText}`,
      text: formattedText
    };

    return client.writeQuery({
      query: storyQuery,
      variables: {
        storyId: story?.id
      },
      data: {
        story: {
          ...story,
          tags: currentTags.concat(clientTag)
        }
      }
    });
  }

  function optimisticRemoveTag(tag, currentTags) {
    return client.writeQuery({
      query: storyQuery,
      variables: {
        storyId: story?.id
      },
      data: {
        story: {
          ...story,
          tags: currentTags.filter(item => item.text !== tag)
        }
      }
    });
  }

  const clipTags = React.useMemo(() => {
    return Array.from(
      story?.items
        .filter(item => Boolean(item.clip))
        .reduce((tagSet, item) => {
          item.clip.data.tags.forEach(tag => tagSet.add(tag));
          return tagSet;
        }, new Set())
    );
  }, [story]);

  return (
    <TagSelector
      accessibleTags={workspaceStoryTags}
      onAddTag={addStoryTag}
      onRemoveTag={removeStoryTag}
      onOptimisticAddTag={optimisticAddTag}
      onOptimisticRemoveTag={optimisticRemoveTag}
      tags={story?.tags}
      page='story'
      clipTags={clipTags}
      {...rest}
    >
      <StyledTagManagementButton>
        Tags
        <StyledTagCount>{story?.tags.length + clipTags.length}</StyledTagCount>
        <StyledChevronIcon />
      </StyledTagManagementButton>
    </TagSelector>
  );
}
