import React from 'react';
import { css } from '@emotion/react';
import {
  StyledCard,
  StyledCardHeaderText,
  StyledCardSubHeaderText,
  StyledCardWrapper
} from '../styles';
import { useShowToast, useQueryParam } from '@grain/grain-ui';
import { OnboardingRoleKey } from '../ChooseRole';
import { StepComponentProps } from '..';

import { SendInvites } from './SendInvites';

const contentStyles = css`
  // Clamp the width of the content of this slide on mobile:
  width: 100%;
  .send-invites {
    width: 100%;
  }
`;

const InviteTeam = ({ onNext }: StepComponentProps) => {
  const showToast = useShowToast({ force: true });
  const existingRole = window.localStorage.getItem(OnboardingRoleKey);
  const role = existingRole ? JSON.parse(existingRole) : null;
  const isManager = role?.ob_survey_selected_work_type === 'Sales leader';

  const [crmConnected, setCrmConnected] = useQueryParam('crm_connected', {
    defaultValue: null,
    method: 'push'
  });

  React.useEffect(() => {
    if (crmConnected) {
      showToast({
        content: 'CRM connected',
        type: 'success'
      });
      setTimeout(() => setCrmConnected(''), 5000);
    }
    // showToast can't be used as a hook dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crmConnected, setCrmConnected]);

  return (
    <StyledCardWrapper>
      <StyledCard>
        <StyledCardHeaderText>Add your team to Grain</StyledCardHeaderText>
        <StyledCardSubHeaderText>
          {isManager
            ? 'Coach your team to become top performers.'
            : 'Collaborate and easily share meetings, notes, and clips.'}
        </StyledCardSubHeaderText>

        <div className='content-wrapper' css={contentStyles}>
          <SendInvites onNext={onNext} />
        </div>
      </StyledCard>
    </StyledCardWrapper>
  );
};

export default InviteTeam;
