import PropTypes from 'prop-types';
import cx from 'classnames';
import Tooltip from '@grain/components/Tooltip';
import { flex, Icon } from '@grain/grain-ui';
import { font, color, spacing } from '@grain/styles/constants';

import { StyledWrapper } from './styles';
import {
  StyledLabelContainer,
  StyledUserTitle
} from '~/components/UserCard/styles';

GuestCard.propTypes = {
  email: PropTypes.string.isRequired,
  onDelete: PropTypes.func
};

export default function GuestCard({ email, onDelete, ...rest }) {
  return (
    <StyledWrapper {...rest} className={cx('fs-block', rest.className)}>
      <Icon.Guest css={[spacing.mr4, 'flex-shrink: 0;']} />
      <StyledLabelContainer>
        <StyledUserTitle>{email}</StyledUserTitle>
        <div css={[font.bodyS, color.pigeon, flex.alignItems.center]}>
          Pending invite
          <Tooltip
            content='Waiting for the user to join the workspace.'
            maxWidth={500}
          >
            <div css={[flex.center]}>
              <Icon.Help css={[spacing.ml1, 'height: 13px;']} />
            </div>
          </Tooltip>
        </div>
      </StyledLabelContainer>
      <div css={['margin-left: auto;']}>
        {!!onDelete && (
          <Tooltip content='Remove'>
            <div>
              <Icon.Close
                css={['cursor: pointer;', color.pigeon]}
                onClick={onDelete}
              />
            </div>
          </Tooltip>
        )}
      </div>
    </StyledWrapper>
  );
}
