import React from 'react';
import { useSessionStorage } from 'react-use';
import { useMyself } from '@grain/api/auth';
import { getOauthRedirectUrl, Icon, useAnalytics } from '@grain/grain-ui';
import { LoginButton } from '~/components/LoginButton';
import { useCalendarQuery } from '@grain/api/graphql/modules/calendar.generated';
import { CalendarUpdatedDocument } from '@grain/api/graphql/subscriptions/subscriptions.generated';
import type { Calendar } from '@grain/api/schema.generated';
import styled from '@emotion/styled';
import { Button, theme } from '@grain/grain-ui/v4';
import { AccountPill } from './AccountPill';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  max-width: 524px;
  width: 100%;
  margin: 28px auto 0 auto;
  padding: 0 ${theme.tokens.spacing.lg};
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing['2xl']};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing.md};
  text-wrap: balance;
`;

const Title = styled.h1`
  ${theme.tokens.typography.h1};
  text-align: center;
  color: ${theme.tokens.color.textPrimary};
  margin: 0;
`;

const Description = styled.p`
  ${theme.tokens.typography.b1[400]};
  line-height: 24px;
  text-align: center;
  color: ${theme.tokens.color.textSecondary};
  margin: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; // Center loading spinner
  width: 100%;
  gap: ${theme.tokens.spacing.md};
`;

type Myself = Exclude<ReturnType<typeof useMyself>['myself'], null | undefined>;
type OauthAccount = Myself['oauthAccounts'][number];

type ConnectCalendarProps = {
  onNext: () => void;
  getNextHref: () => string;
};

const EMPTY_CALENDAR_EVENTS = [] as never[];

const ConnectCalendar = ({ onNext, getNextHref }: ConnectCalendarProps) => {
  const { myself } = useMyself();
  const [account, setAccount] = React.useState<OauthAccount | null>(null);
  const { trackEvent } = useAnalytics();
  const next = getNextHref();
  const connectCalendarHref = '/app/onboarding';

  const {
    data: calendarData,
    subscribeToMore: calendarDataSubscribeToMore,
    refetch: refetchCalendarData
  } = useCalendarQuery();

  const calendarEvents =
    calendarData?.calendar?.events || EMPTY_CALENDAR_EVENTS;

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      refetchCalendarData();
    }, 10000);

    const unsubscribe = calendarDataSubscribeToMore({
      document: CalendarUpdatedDocument,
      updateQuery: (
        prev,
        {
          subscriptionData
        }: { subscriptionData: { data: { calendarUpdated: Calendar } } }
      ) => {
        clearTimeout(timeout);
        if (!subscriptionData.data) return prev;
        return { ...prev, calendar: subscriptionData.data.calendarUpdated };
      }
    });

    // @edwardbaeg - There exists a race condition where a newly connected
    // calendar account is still syncing when the initial query completes
    // but finishes before the client subscribes. This fixes that.
    refetchCalendarData();

    return () => {
      clearTimeout(timeout);
      unsubscribe();
    };
  }, [calendarDataSubscribeToMore, refetchCalendarData]);

  const calendarSyncing = React.useMemo(() => {
    if (!calendarData) return true;
    return calendarData?.calendar?.accounts?.some(
      (account: { status: string }) =>
        ['NOT_SYNCED', 'SYNCING'].includes(account.status)
    );
  }, [calendarData]);

  const isEmpty = calendarEvents.length === 0;
  const noMeetingLinks = calendarEvents.every(event => !event.meetingUrl);

  React.useEffect(() => {
    if (!calendarData || calendarSyncing) return;
    if (!isEmpty && !noMeetingLinks) {
      onNext();
    }
  }, [isEmpty, calendarSyncing, calendarData, noMeetingLinks, onNext]);

  React.useEffect(() => {
    const accounts = myself?.oauthAccounts
      ?.filter(account => ['GOOGLE', 'MICROSOFT'].includes(account.provider))
      ?.sort(
        // Most-recently-created accounts first
        (a, b) => Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
      );
    if (accounts && accounts.length > 0) {
      // Each time the user connects, ensure the user sees his most recent
      // connection, so he knows that we connected it, but that this connection
      // didn't have any events with meetings.  The same remains true for his
      // prior account connections (if any), but we only show the latest one.
      setAccount(accounts[0]);
    }

    if (
      myself?.oauthAccounts &&
      myself?.oauthAccounts?.length > 0 &&
      accounts === undefined
    ) {
      onNext();
    }
  }, [myself, onNext, setAccount]);

  const [calendarWarningSkipped] = useSessionStorage(
    'calendar_warning_skipped',
    false
  );

  React.useEffect(() => {
    // If warnings about connection were skipped previously, we shouldn't be
    // showing this page.  The user has indicated that they don't wish to give
    // us calendar access yet so we shouldn't ask for it again so soon.
    if (calendarWarningSkipped) onNext();
  }, [calendarWarningSkipped, onNext]);

  const handleSkip = () => {
    trackEvent(
      'Button Clicked',
      {
        button_name: 'onboarding_skip_connect_calendar',
        category: 'Onboarding',
        location: 'onboarding_connect_calendar'
      },
      ['user', 'workspace']
    );
    onNext();
  };

  const providerName = (() => {
    if (account?.provider === 'GOOGLE') return 'Google';
    if (account?.provider === 'MICROSOFT') return 'Microsoft';
    // Other providers don't matter as we skip to the next step.
  })();

  if (!account || calendarWarningSkipped) return null;

  return (
    <Container>
      <TopContent>
        <TextContainer>
          <Title>No meetings or video links found.</Title>
          <Description>
            You can still use Grain but wanted to double check, is this the
            right {providerName} account?
          </Description>
        </TextContainer>
        <div css={['text-align: center;']}>
          <AccountPill {...account} />
        </div>
      </TopContent>
      <ButtonContainer>
        <Button fullWidth variant='stroked' size='xl' onClick={handleSkip}>
          Yes, continue with this account
        </Button>
        {calendarSyncing && <Icon.Loading />}
        {account?.provider === 'GOOGLE' && !calendarSyncing && (
          <LoginButton
            as='a'
            loginType='Google'
            variant='ghost'
            css={['width: 100%;']}
            href={getOauthRedirectUrl(
              'google',
              true,
              'connect_account',
              {},
              noMeetingLinks || isEmpty ? connectCalendarHref : next,
              myself?.user?.email
            )}
            data-track='Button Clicked'
            data-track-args={JSON.stringify({
              button_name: 'onboarding_connect_google',
              trigger: 'onboarding_connect_calendar',
              button_text: 'No, connect another account'
            })}
          >
            No, connect another account
          </LoginButton>
        )}
        {account?.provider === 'MICROSOFT' && !calendarSyncing && (
          <LoginButton
            as='a'
            loginType='Microsoft'
            variant='ghost'
            css={['width: 100%;']}
            href={getOauthRedirectUrl(
              'microsoft',
              true,
              'connect_account',
              {},
              noMeetingLinks || isEmpty ? connectCalendarHref : next,
              myself?.user?.email
            )}
            data-track='Button Clicked'
            data-track-args={JSON.stringify({
              button_name: 'onboarding_connect_microsoft',
              trigger: 'onboarding_connect_calendar',
              button_text: 'No, connect another account'
            })}
          >
            No, connect another account
          </LoginButton>
        )}
      </ButtonContainer>
    </Container>
  );
};

export default ConnectCalendar;
