import styled from '@emotion/styled';

import { theme } from '@grain/grain-ui/v4';

import { DealRecordingViewFragment } from '../../deal.generated';
import { getActivityTimestamp } from '../../utils';

import { formatTime } from './Timeline';

const StyledContainer = styled.div`
  padding: ${theme.tokens.spacing.lg};
  display: flex;
  gap: ${theme.tokens.spacing.sm};
  ${theme.tokens.typography.c1[500]};
`;

const StyledTitle = styled.div`
  color: ${theme.tokens.color.textPrimary};
`;

const StyledTime = styled.div`
  color: ${theme.tokens.color.textSecondary};
`;

type RecordingViewProps = { view: DealRecordingViewFragment };
export const RecordingView = ({ view }: RecordingViewProps) => {
  return (
    <StyledContainer>
      <StyledTitle>{view.title} viewed</StyledTitle>
      <StyledTime>{formatTime(getActivityTimestamp(view))}</StyledTime>
    </StyledContainer>
  );
};
