import styled from '@emotion/styled';
import { useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { Icon, color, flex, font, media } from '@grain/grain-ui';
import { MatchItem, MatchItemProps } from './MatchItem';

const MATCHES_TO_SHOW = 3;

type MatchGroupProps = {
  matches: MatchItemProps[];
};

const MatchGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 101px; /* aligns with the title */

  ${media.small} {
    margin-left: 24px; /* aligns with the thumbnail */
  }
`;

const ShowMoreButton = styled.button`
  all: unset;
  margin-top: 0.5rem;
  text-align: center;
  ${font.v4.b4[500]}
  ${color.fg.crow}
  cursor: pointer;
  white-space: pre-wrap;
  ${flex.alignItems.center};
  padding: 6px 12px;
  border-radius: 8px;

  &:hover {
    background-color: ${color.gull};
  }
`;

const MatchGroup = ({ matches }: MatchGroupProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const limitedMatches = useMemo(() => {
    return matches.slice(0, isCollapsed ? MATCHES_TO_SHOW : undefined);
  }, [matches, isCollapsed]);

  const hiddenItems = matches.length - MATCHES_TO_SHOW;

  return (
    <MatchGroupWrapper>
      {limitedMatches.map((match, index) => (
        <MatchItem
          key={index}
          matchText={match.matchText}
          timestamp={match.timestamp}
          type={match.type}
          recordingPath={match.recordingPath}
          smartTagView={match.smartTagView}
        />
      ))}
      {hiddenItems > 0 && (
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <ShowMoreButton onClick={() => setIsCollapsed(prev => !prev)}>
            {isCollapsed ? (
              <>
                {hiddenItems} More <Icon.ChevronDown />
              </>
            ) : (
              <>
                Show Less <Icon.ChevronUp />
              </>
            )}
          </ShowMoreButton>
        </div>
      )}
    </MatchGroupWrapper>
  );
};

export default MatchGroup;
