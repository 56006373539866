import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Avatar,
  Icon,
  StopPropagation,
  color,
  font,
  media,
  spacing
} from '@grain/grain-ui';
import { ParticipantsMenu } from '~/modules/meetings/ParticipantsMenu';
import { RecordingQuickActionsButton } from '~/components/RecordingQuickActionsButton/RecordingQuickActionsButton';
import { RecentRecordingFragment } from '../searchQuery.generated';
import { useMediaQuery } from '@grain/components/support/browser';
import { CompanyMetaItem } from '~/modules/meetings/CompanyMetaItem';
import { MetadataContainer } from '~/modules/meetings/ButtonMetadata';
import { useWorkspace } from '@grain/api/auth';
type RecentRecordingProps = {
  recording: RecentRecordingFragment;
};

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) 120px 120px 55px 20px;
  align-items: center;
  ${spacing.g6}
  width: 100%;

  ${media.small} {
    grid-template-columns: minmax(0, 1fr) 20px;
  }
`;

const OverflowEllipsis = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Owner = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  ${color.fg.crow}
  ${font.v4.c1[500]}
`;

const OwnerName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 16px);
`;

export const RecentRecording = ({ recording }: RecentRecordingProps) => {
  const isSmallScreen = useMediaQuery(media.small);
  const { workspace } = useWorkspace();
  return (
    <Container>
      <Title>
        <Icon.Recorder
          color={color.pigeon}
          css={css`
            flex-shrink: 0;
          `}
        />
        <OverflowEllipsis>{recording.title}</OverflowEllipsis>
      </Title>
      {!isSmallScreen && (
        <StopPropagation eventNames={['click']}>
          <Owner>
            <Avatar
              avatarSize='extrasmall'
              name={recording.owner.name}
              avatarUrl={recording.owner.avatarUrl ?? false}
              defaultAvatarColor={recording.owner.hexColor}
            />
            <OwnerName>{recording.owner.name}</OwnerName>
          </Owner>
        </StopPropagation>
      )}
      {!isSmallScreen && (
        <StopPropagation eventNames={['click']}>
          <CompanyMetaItem
            participantScope={recording.participantScope}
            externalGroupsData={recording.externalGroupsData}
            workspaceName={workspace?.name || null}
            workspaceLogoUrl={workspace?.logoUrl || null}
          />
        </StopPropagation>
      )}
      {!isSmallScreen && (
        <StopPropagation eventNames={['click']}>
          <MetadataContainer width='sm'>
            <ParticipantsMenu
              externalGroupsData={recording.externalGroupsData}
              participants={recording.participants}
              owner={recording.owner}
              recordingId={recording.id}
              tippyProps={{
                placement: 'bottom-end'
              }}
            />
          </MetadataContainer>
        </StopPropagation>
      )}
      <StopPropagation eventNames={['click']}>
        <RecordingQuickActionsButton
          recordingId={recording.id}
          recordingTitle={recording.title}
          recordingCollections={recording.collections}
          storyCount={recording.storyCount}
          copyUrl={recording.recordingUrl}
          downloadUrl={recording.downloadUrl}
        />
      </StopPropagation>
    </Container>
  );
};
