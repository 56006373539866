import { getBasePublicPath, Icon } from '@grain/grain-ui';
import { ImageEmptyView } from 'components/molecules/ImageEmptyView/ImageEmptyView';
import { Button } from '@grain/grain-ui/v4';

type PlaylistEmptyStateProps = {
  onNewPlaylist: () => void;
};

export const PlaylistEmptyState = ({
  onNewPlaylist
}: PlaylistEmptyStateProps) => {
  return (
    <ImageEmptyView.Wrapper>
      <ImageEmptyView.Image
        src={`${getBasePublicPath()}/images/zero-states/playlists.png`}
      />
      <ImageEmptyView.InnerWrapper>
        <ImageEmptyView.Title>Create your first playlist</ImageEmptyView.Title>
        <ImageEmptyView.Description>
          A playlist is a group of meetings you've created or were invited to.
          Get started by creating your first playlist.
        </ImageEmptyView.Description>
        <ImageEmptyView.Action>
          <Button
            variant='primary'
            size='xl'
            icon={Icon.Plus}
            onClick={onNewPlaylist}
          >
            New playlist
          </Button>
        </ImageEmptyView.Action>
      </ImageEmptyView.InnerWrapper>
    </ImageEmptyView.Wrapper>
  );
};
