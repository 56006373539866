// @ts-strict-ignore
import React, { useCallback, useMemo } from 'react';
import {
  useIntelligenceCustomPromptUpdateMutation,
  useIntelligenceTemplateCreateMutation,
  useIntelligenceTemplateDeleteMutation
} from '@grain/api/graphql/queries/notesTemplate.generated';
import {
  IntelligenceTemplateTypedSection,
  IntelligenceTypedSectionType
} from '@grain/api/schema.generated';
import {
  cacheAddIntelligenceTemplate,
  cacheDeleteTemplate
} from '@grain/api/graphql/cache-helpers';
import {
  TemplateItemType,
  useShowToast,
  useConfirm,
  NoteSectionMenu
} from '@grain/grain-ui';
import { useApolloClient } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { useNotesTemplate } from '~/modules/templates/hooks';
import { useAddSectionModal } from '~/components/AddSectionModal/hooks';
import { IntelligenceTemplateQuerySection } from '@grain/api/schema.extra';
import { useFeature } from '@grain/api/auth';

export type TemplateDefaultScope = 'internal' | 'external';

export const templateSettingsOptions = [
  {
    title: 'Customer meetings',
    value: 'external' as TemplateDefaultScope
  },
  {
    title: 'Team meetings',
    value: 'internal' as TemplateDefaultScope
  }
];

export const useNotesTemplateEdit = (id?: string) => {
  const isNewTemplate = !id;
  const showToast = useShowToast();
  const showConfirm = useConfirm();
  const client = useApolloClient();
  const {
    template,
    sectionsNotInCurrentTemplate,
    saveTemplate,
    typedAvailableSections
  } = useNotesTemplate(id);
  const {
    toggleModal,
    isOpen: isModalOpen,
    mode: modalMode,
    editSection
  } = useAddSectionModal();
  const [deleteTemplate] = useIntelligenceTemplateDeleteMutation();
  const [updateCustomPrompt] = useIntelligenceCustomPromptUpdateMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [intelligenceTemplateCreateMutation] =
    useIntelligenceTemplateCreateMutation();
  const [title, setTitle] = React.useState<string>('');
  const [typedSections, setTypedSections] = React.useState<
    IntelligenceTemplateQuerySection[]
  >([]);
  const { enabled: isCustomAiNotesEnabled } = useFeature('custom_ai_notes');

  const originalTitleRef = React.useRef<string>(title);
  const originalSectionsRef =
    React.useRef<IntelligenceTemplateQuerySection[]>(typedSections);

  const changesMade = React.useMemo(() => {
    const titleUpdated = title !== originalTitleRef.current;
    const sectionsUpdated = typedSections !== originalSectionsRef.current;
    const isChanged = titleUpdated || sectionsUpdated;
    return isChanged;
  }, [title, typedSections]);

  React.useEffect(() => {
    if (!template) return;

    const sections = template?.sections;

    setTypedSections(sections);
    setTitle(template?.title ?? '');
    originalSectionsRef.current = sections;
    originalTitleRef.current = template?.title ?? '';
  }, [template]);

  const isSectionDisabled = (type: IntelligenceTypedSectionType) => {
    return typedSections.some(item => item.type === type);
  };

  const handleSectionDelete = useCallback(
    (item: TemplateItemType) => {
      setTypedSections(typedSections.filter(section => section.id !== item.id));
    },
    [typedSections]
  );

  const handleTemplateDelete = async (onExit = () => {}) => {
    const result = await showConfirm({
      width: 400,
      confirmContent: 'Delete',
      description:
        'This Notes Template will be deleted from your workspace for all members.',
      title: 'Delete this Notes Template?'
    });
    if (!result.isConfirm) return;

    const templateId = template?.id;

    deleteTemplate({
      variables: {
        templateId
      },
      onCompleted: () => {
        cacheDeleteTemplate(client, { id: templateId });
        showToast({
          content: 'Template deleted',
          type: 'success'
        });
        onExit();
      }
    });
  };

  const handleUpdateCustomPrompt = async (
    id: string,
    title: string,
    processedPromptId: string
  ) => {
    return await updateCustomPrompt({
      variables: {
        templateSectionId: id,
        title,
        processedPromptId
      },
      onCompleted: data => {
        const updatedSection = data?.intelligenceCustomPromptUpdate;
        if (!updatedSection) return;
        setTypedSections(typedSections =>
          typedSections.map(section =>
            section.id === updatedSection.id ? updatedSection : section
          )
        );
        showToast({
          content: 'Section updated',
          type: 'success'
        });
      }
    });
  };

  const handleReplaceTemplateSection = (
    id: string,
    section: IntelligenceTemplateQuerySection
  ) => {
    setTypedSections(typedSections =>
      typedSections.map(item => (item.id === id ? section : item))
    );
  };

  const handleSectionAddToTemplate = (
    item: IntelligenceTemplateQuerySection
  ) => {
    const isSectionAlreadyAdded = typedSections.some(
      section => section.id === item.id
    );
    if (isSectionAlreadyAdded) {
      showToast({
        content: `${item.title} is already added`,
        type: 'failure'
      });
      return;
    }
    setTypedSections([
      ...typedSections,
      {
        ...item,
        type:
          typedAvailableSections.find(section => section.id === item.id)
            ?.type ?? item.type
      }
    ]);
  };

  const handleTitleChange = (value: string) => {
    setTitle(value);
  };

  const handleChangeOrder = (items: TemplateItemType[]) => {
    setTypedSections(typedSections =>
      items.map(item => typedSections.find(section => section.id === item.id))
    );
  };

  const handleReset = () => {
    setTypedSections(originalSectionsRef.current);
    setTitle(originalTitleRef.current);
  };

  const handleSaveTemplate = async (cb: () => void) => {
    if (isNewTemplate) {
      intelligenceTemplateCreateMutation({
        variables: {
          title,
          sectionIds: typedSections.map(section => section.id)
        },
        onCompleted: ({ intelligenceTemplateCreate }) => {
          cacheAddIntelligenceTemplate(client, intelligenceTemplateCreate);
          setSearchParams({
            tab: searchParams.get('tab'),
            templateId: intelligenceTemplateCreate.id
          });
          showToast({
            content: 'Template created',
            type: 'success'
          });
          cb();
        }
      });
    } else {
      const result = await showConfirm({
        width: 400,
        confirmContent: 'Save',
        confirmButtonType: 'primary',
        cancelContent: 'Keep editing',
        description:
          'Changes will apply to any new recording with this template in your workspace.',
        title: 'Save changes to template?'
      });
      if (!result.isConfirm) return;
      await saveTemplate(title, typedSections);
      showToast({
        content: 'Template updated',
        type: 'success'
      });
      cb();
    }
  };

  const templateSections = useMemo(
    () =>
      typedSections.map(section => {
        let disableMessage = '';
        if (!section.isUserPrompt) {
          disableMessage = 'You can only edit custom sections';
        } else if (!isCustomAiNotesEnabled) {
          disableMessage = 'Upgrade to a paid plan to edit custom sections';
        }
        return {
          id: section?.id,
          editable: true,
          draggable: true,
          type: section?.type,
          text: section?.title,
          userPrompt: section?.userPrompt,
          description: section?.description,
          menu: [
            {
              label: 'Edit Section',
              onClick: (section: IntelligenceTemplateTypedSection) => {
                toggleModal(true, section);
              },
              isDisabled: !section?.isUserPrompt || !isCustomAiNotesEnabled,
              disableMessage
            },
            {
              label: 'Remove Section',
              onClick: handleSectionDelete,
              isDisabled: false
            }
          ] as NoteSectionMenu[]
        } as TemplateItemType;
      }),
    [typedSections, isCustomAiNotesEnabled, handleSectionDelete, toggleModal]
  );

  return {
    template,
    title,
    templateSections,
    sectionsNotInCurrentTemplate,
    changesMade,
    isSectionDisabled,
    handleTitleChange,
    handleSectionAddToTemplate,
    handleChangeOrder,
    handleReset,
    handleTemplateDelete,
    handleSaveTemplate,
    isEditModalOpen: isModalOpen,
    setEditModalOpen: toggleModal,
    modalMode,
    editSection,
    handleUpdateCustomPrompt,
    handleReplaceTemplateSection
  };
};
