import { Outlet } from 'react-router-dom';
import DesktopNotepad from './apps/Notepad';
import DesktopNotification from './apps/Notification';
import { RecoilRoot } from 'recoil';
import { useDesktop } from './providers/DesktopProvider';
import { ApolloProvider } from '@apollo/client';

export function DesktopLayout() {
  const { apolloClient } = useDesktop();

  return (
    <>
      {/* We're using an old version of recoil and its TS types aren't compatible with React 18 */}
      {/* https://github.com/facebookexperimental/Recoil/issues/1726 */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <RecoilRoot>
        <ApolloProvider client={apolloClient}>
          <Outlet />
        </ApolloProvider>
      </RecoilRoot>
    </>
  );
}

export const desktopRoutes = [
  { path: 'notepad', element: <DesktopNotepad /> },
  { path: 'notification', element: <DesktopNotification /> }
];
