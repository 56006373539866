// @ts-strict-ignore
import styled from '@emotion/styled';
import {
  Button,
  ErrorBox,
  Icon,
  InputText,
  PageHeader,
  Spinner,
  color,
  flex,
  font,
  spaces,
  useQueryParam
} from '@grain/grain-ui';
import { EditableHeader } from '../EditableHeader';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useReducer, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot
} from 'react-beautiful-dnd';
import {
  useCustomScorecardChangedSubscription,
  useCustomScorecardCreateMutation,
  useCustomScorecardReplaceMutation,
  useGetWorkspaceCustomScorecardByIdQuery
} from '@grain/api/graphql/queries/scorecards.generated';
import { Question } from './types';
import {
  manageScorecardReducer,
  manageScorecardInitialState,
  ActionTypes
} from './reducers';
import { PortalAwareQuestion } from './PortalAwareQuestion';
import { getValidationErrors } from './scorecardValidation';
import { cacheUpdateCustomScorecards } from '@grain/api/graphql/cache-helpers';
import { SETTINGS_PAGE_PADDING_TOP } from '../styles';

const StyledPageHeader = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 16px 32px !important;
  ${props => props.sticky && `top: -${SETTINGS_PAGE_PADDING_TOP};`}
`;

const Description = styled.div`
  padding: ${spaces[7]};
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${color.gull};
`;

const DescriptionLabel = styled.label`
  ${font.v4.h6};
  margin: 0 0 ${spaces[2]} 0;
`;

const DescriptionHelpText = styled.p`
  ${font.v4.b2[400]};
  margin: 0 0 ${spaces[3]} 0;
  ${color.fg.crow};
`;

const Required = styled.sup`
  ${color.fg.errorbird};
  position: relative;
  top: 2px;
  :before {
    content: '*';
  }
`;

export const ManageScorecard = () => {
  const [scorecardId] = useQueryParam('scorecardId');
  const {
    data: scorecardSource,
    loading,
    error: fetchError
  } = useGetWorkspaceCustomScorecardByIdQuery({
    variables: { id: scorecardId as string },
    skip: Boolean(!scorecardId)
  });
  const [scorecard, dispatch] = useReducer(
    manageScorecardReducer,
    manageScorecardInitialState
  );
  const [canSave, setCanSave] = useState(false);
  const [currentlySelectedQuestionId, setCurrentlySelectedQuestionId] =
    useState<null | string>(null);
  const [saveScorecard] = useCustomScorecardCreateMutation();
  const [updateScorecard] = useCustomScorecardReplaceMutation();
  const navigate = useNavigate();

  useCustomScorecardChangedSubscription({
    onData({ data, client }) {
      cacheUpdateCustomScorecards(client, data.data.customScorecardsChanged);
    }
  });

  useEffect(() => {
    if (!loading && scorecardSource) {
      dispatch({
        type: ActionTypes.SET_SCORECARD,
        payload: scorecardSource.customScorecard
      });
    }
  }, [loading, scorecardSource]);

  const onCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    setCanSave(!getValidationErrors(scorecard));
  }, [scorecard]);

  const saveButtonTooltip = useMemo(
    () => getValidationErrors(scorecard)?.join(', '),
    [scorecard]
  );

  const handleTitleChange = (title: string) => {
    dispatch({ type: ActionTypes.SET_TITLE, payload: title });
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ActionTypes.SET_DESCRIPTION,
      payload: e.target.value
    });
  };

  const handleAddQuestion = () => {
    const tempId = `temp-${Date.now()}`;
    dispatch({ type: ActionTypes.ADD_QUESTION, payload: tempId });
    setCurrentlySelectedQuestionId(tempId);
  };

  const handleUpdateQuestion = (index: number, question: Question) => {
    dispatch({
      type: ActionTypes.UPDATE_QUESTION,
      payload: { index, question }
    });
  };

  const handleRemoveQuestion = (id: string) => {
    dispatch({
      type: ActionTypes.REMOVE_QUESTION,
      payload: id
    });
  };

  const handleDragEnd = (result: {
    source: { index: number };
    destination: { index: number };
  }) => {
    if (!result.destination) return;

    const items = scorecard.questions.questions;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    dispatch({
      type: ActionTypes.UPDATE_QUESTIONS_ORDER,
      payload: items
    });
  };

  const handleSave = async () => {
    const variables = {
      title: scorecard.title,
      description: scorecard.description,
      questions: JSON.stringify({
        schema_version: 1,
        questions: scorecard.questions.questions.map(
          ({ text, question_type }) => ({
            text,
            question_type
          })
        )
      })
    };

    if (scorecardId) {
      await updateScorecard({
        variables: { id: scorecardId as string, ...variables },
        onCompleted() {
          navigate(-1);
        }
      });
      return;
    }

    await saveScorecard({
      variables,
      onCompleted() {
        navigate(-1);
      }
    });
  };

  const renderDraggableQuestion =
    (
      question: Question,
      index: number,
      droppableSnapshot: DroppableStateSnapshot
    ) =>
    (
      provided: DraggableProvided,
      draggableSnapshot: DraggableStateSnapshot
    ) => (
      <PortalAwareQuestion
        provided={provided}
        question={question}
        onChange={question => handleUpdateQuestion(index, question)}
        onRemove={() => handleRemoveQuestion(question.id)}
        onSelect={() => setCurrentlySelectedQuestionId(question.id)}
        isSelected={currentlySelectedQuestionId === question.id}
        droppableSnapshot={droppableSnapshot}
        draggableSnapshot={draggableSnapshot}
      />
    );

  const renderDroppableQuestionArea = (
    provided: DroppableProvided,
    droppableSnapshot: DroppableStateSnapshot
  ) => (
    <div
      ref={provided.innerRef}
      {...provided.droppableProps}
      css={{ position: 'relative' }}
    >
      {scorecard.questions.questions.map((question, index) => (
        <Draggable draggableId={question.id} index={index} key={question.id}>
          {renderDraggableQuestion(question, index, droppableSnapshot)}
        </Draggable>
      ))}
      {provided.placeholder}
    </div>
  );

  return (
    <div>
      {fetchError && (
        <ErrorBox variant='minimal'>{fetchError?.message}</ErrorBox>
      )}
      {loading ? (
        <Spinner
          css={[flex.center, { width: '100%', margin: spaces[5] }]}
          color={color.graieen}
        />
      ) : (
        <>
          <StyledPageHeader sticky>
            <EditableHeader
              title={scorecard?.title ?? ''}
              placeholder='Untitled Scorecard'
              onTitleChange={handleTitleChange}
              tooltipContent={saveButtonTooltip}
              id={scorecardId as string}
              onCancel={onCancel}
              onSave={handleSave}
              canSave={canSave}
            />
          </StyledPageHeader>
          <Description>
            <DescriptionLabel htmlFor='input-description'>
              Description <Required />
            </DescriptionLabel>
            <DescriptionHelpText>
              Add a brief description of your scorecard.
            </DescriptionHelpText>
            <InputText
              css={{ width: '100%' }}
              id='input-description'
              placeholder='Description'
              value={scorecard.description}
              onChange={handleDescriptionChange}
            />
          </Description>
          <div css={{ padding: `${spaces[7]} ${spaces[7]} 0 ${spaces[7]}` }}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId='custom-scorecards'>
                {renderDroppableQuestionArea}
              </Droppable>
            </DragDropContext>
            <Button
              variant='secondary'
              onClick={handleAddQuestion}
              icon={<Icon.DeprecatedPlus />}
            >
              Add question
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
