import styled from '@emotion/styled';
import { colors } from '@grain/styles/constants';

export const StyledBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: ${colors.goose};
`;
