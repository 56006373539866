import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon } from '@grain/grain-ui';
import {
  Button,
  Divider,
  Icon16,
  Icon20,
  Menu,
  MenuButton,
  Skeleton,
  TextLabel,
  theme,
  Thumbnail
} from '@grain/grain-ui/v4';
import { Duration } from 'components/atoms/Duration/Duration';
import { format } from 'date-fns';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import { AdHocStoryFragment } from '~/modules/contentFilter/contentfilter.generated';
import { ButtonMetadata } from '~/modules/meetings/ButtonMetadata';
import { useDeleteStory } from '~/pages/Library/hooks';

type StoryListItemProps = {
  story: AdHocStoryFragment;
  onClickShare: () => void;
};

const Container = styled.article`
  ${theme.utils.py('14px')}
  ${theme.utils.px('3xl')}
  cursor: pointer;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto auto;
  flex-shrink: 0;
  gap: ${theme.tokens.spacing.md};

  &:hover {
    background-color: ${theme.tokens.color.surfaceSecondary};
  }
`;

const Title = styled.h3`
  display: inline-flex;
  align-items: center;
  ${theme.tokens.typography.b3[500]};
  color: ${theme.tokens.color.textPrimary};
  padding: 0;
  margin: 0 0 ${theme.tokens.spacing['2xs']} 0;
`;

const Meta = styled.div`
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textTertiary};
`;

const StyledStoryIcon = styled(Icon.Story)`
  color: ${theme.tokens.color.iconInvert};
`;

export const StoryListItem = ({ story, onClickShare }: StoryListItemProps) => {
  const deleteStory = useDeleteStory();
  const storyPath = `/app/stories/${story.id}`;

  return (
    <Container>
      <Link
        to={storyPath}
        css={css`
          display: contents;
        `}
      >
        <Thumbnail
          imgSrc={story.bannerImageUrl || undefined}
          icon={StyledStoryIcon}
          css={['height: 36px;']}
        />

        <div>
          <Title title={story.title}>{story.title}</Title>
          <Meta>
            {format(new Date(story.insertedAt), 'MMM, do h:mm a')} ·{' '}
            <Duration durationMs={story.duration || 0} />
          </Meta>
        </div>
      </Link>

      <div
        css={css`
          display: flex;
          align-items: center;
          gap: ${theme.tokens.spacing.sm};
        `}
      >
        {story.creator && (
          <ButtonMetadata width='lg' disableInteractive>
            <TextLabel
              avatarProps={{
                name: story.creator.name,
                url: story.creator.avatarUrl || '',
                color: story.creator.hexColor
              }}
              size='sm'
            >
              {story.creator.name}
            </TextLabel>
          </ButtonMetadata>
        )}

        <ButtonMetadata width='md' disableInteractive>
          <TextLabel size='sm' startIcon={Icon.Clip}>
            {story.items.length} {pluralize('clips', story.items.length)}
          </TextLabel>
        </ButtonMetadata>
      </div>
      <Menu
        hideOnClickInside
        content={
          <>
            <MenuButton
              label='Share'
              textLabelProps={{ startIcon: Icon20.Share }}
              onClick={onClickShare}
            />
            <Divider />

            <MenuButton
              label='Delete'
              textLabelProps={{ startIcon: Icon20.Delete }}
              onClick={() => deleteStory(story.id, story.title)}
            />
          </>
        }
      >
        <Button
          onClick={e => e.stopPropagation()}
          variant='ghost'
          size='md'
          icon={Icon16.Overflow}
        />
      </Menu>
    </Container>
  );
};

StoryListItem.Skeleton = () => (
  <Container>
    <Skeleton height={36} width={64} />

    <Skeleton height={16} width={200} />
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 24px;
      `}
    >
      <Skeleton height={16} width={140} />
      <Skeleton height={16} width={140} />
    </div>
    <Skeleton height={16} width={28} />
  </Container>
);
