import styled from '@emotion/styled';
import { pxToRem, color, font } from 'base/css';
import { Menu, MenuButton, Icon16, Button, Divider } from '@grain/grain-ui/v4';
import { Button as LegacyButton } from 'components/molecules/Button/Button';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import pluralize from 'pluralize';
import { SmartTopicV2 } from '@grain/api/schema.extra';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${pxToRem(14)};
  padding: 16px 24px;
  border: 1px solid ${color.gull};
  border-radius: 8px;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 0;
`;

const Description = styled.div`
  font-weight: 400;
  color: ${color.crow};
`;

const TextLine = styled.div`
  font-weight: 400;
  color: ${color.crow};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
`;

const SubTitle = styled.div`
  span,
  a {
    font-weight: 500;
    font-size: 12px;
    color: ${color.pigeon};
  }
  a {
    cursor: pointer;
    color: ${color.graieen};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  flex-grow: 1;
  min-width: 0;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
  align-items: center;
  flex-shrink: 0;
`;

const formatKeywords = (keywords: string[]) => {
  if (!keywords || !keywords.length) {
    return '';
  }
  if (keywords.length === 1) {
    return keywords[0];
  }
  if (keywords.length === 2) {
    return `${keywords[0]} and ${keywords[1]}`;
  }
  return `${keywords.slice(0, -1).join(', ')} and ${keywords.slice(-1)}`;
};

export const NoTopics = () => {
  return (
    <Container>
      <TitleWrapper>
        <Description>No Topics</Description>
      </TitleWrapper>
    </Container>
  );
};

type SmartTopicV2WithAutomationCount = SmartTopicV2 & {
  slackAutomationCount?: number;
};

export type TopicCardProps = {
  topic: SmartTopicV2WithAutomationCount;
  onEdit?: (id: string) => void;
  onDelete: (id: string) => void;
  filterTopic?: (topic: SmartTopicV2WithAutomationCount) => void;
  notificationMenu?: ReactNode;
  isTag?: boolean;
  menuRef?: React.Ref<HTMLButtonElement>;
};

const NOOP = () => {};

export const TopicCard = ({
  topic,
  onEdit = NOOP,
  onDelete,
  notificationMenu = null,
  filterTopic = NOOP,
  isTag = false,
  menuRef
}: TopicCardProps) => {
  const handleDelete = () => {
    onDelete(topic?.tag?.text);
  };

  const handleEdit = () => {
    onEdit(topic?.tag.id);
  };
  const instanceMatch = isTag
    ? `instance${topic?.matchCount === 1 ? '' : 's'}`
    : `match${topic?.matchCount === 1 ? '' : 'es'}`;

  const automationCount = topic?.slackAutomationCount;

  return (
    <Container>
      <ContentWrapper>
        <TitleWrapper>
          <Title>{topic?.tag.text}</Title>
          {topic.processing && (
            <span css={[font.v4.b4[500], color.fg.pigeon]}>
              Finding matches...
            </span>
          )}
          <SubTitle>
            {!topic.processing && !topic?.matchCount && (
              <span>No {instanceMatch} </span>
            )}
            {!!topic?.matchCount && !topic.processing && (
              <a onClick={() => filterTopic(topic)}>
                {topic?.matchCount} {instanceMatch}
              </a>
            )}
          </SubTitle>
        </TitleWrapper>

        {!topic.processing && topic?.keywords?.length > 0 && (
          <TextLine>{`Keywords: ${formatKeywords(topic?.keywords)}`}</TextLine>
        )}
        {!topic.processing && topic?.phrases?.length > 0 && (
          <TextLine>{`Phrases: ${topic?.phrases?.join('; ')}`}</TextLine>
        )}
        {!!automationCount && automationCount > 0 && (
          <Link
            to='/app/settings/integrations?tab=slack'
            css={[font.v4.c1[500], `color: ${color.pigeon};`]}
          >
            {automationCount} Slack {pluralize('notification', automationCount)}
          </Link>
        )}
      </ContentWrapper>
      <Controls>
        {notificationMenu}
        <Menu
          content={
            <>
              {!isTag && (
                <>
                  <MenuButton
                    textLabelProps={{
                      startIcon: Icon16.Edit
                    }}
                    onClick={handleEdit}
                    label='Edit'
                  />
                  <Divider />
                </>
              )}
              <MenuButton
                ref={menuRef}
                textLabelProps={{
                  startIcon: Icon16.Trash
                }}
                onClick={handleDelete}
                label='Delete'
              />
            </>
          }
        >
          <Button variant='ghost'>
            <Icon16.Overflow />
          </Button>
        </Menu>
        {isTag && (
          <LegacyButton variant='secondary' onClick={handleEdit}>
            Update
          </LegacyButton>
        )}
      </Controls>
    </Container>
  );
};
