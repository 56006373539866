import React from 'react';
import { useMeasure } from '@grain/components/support/utils';

// For best results, the ref element should have a max-width
export function useCardCount({ minWidth }) {
  const [contentRef, { width: contentWidth }] = useMeasure();

  const cardsPerRow = React.useMemo(() => {
    if (!contentRef.current) return 0;

    // component hasn't mounted yet
    if (contentWidth === 0) return 0;

    if (contentWidth <= minWidth) return 1;
    return Math.floor(contentWidth / minWidth);
  }, [contentRef, contentWidth, minWidth]);

  return { cardsPerRow, contentRef };
}

// Some displays are too large for the initial fetch of content to overflow,
// and so the lazy load scroll threshold does not trigger. This fetches until
// content overflows.
// @param scrollContentRef must be the content that scrolls within the
// container. It can be the scroll container IF it resizes dynamically with its
// content.
// IMPORTANT: This is legacy code. The newest version is in grain-ui/LibraryViewAllPage.
const LOAD_THRESHOLD_PX = 100;
export function useForceLazyLoad({
  fetchMore,
  skip = false,
  scrollContentRef
}) {
  const fetchMoreCount = React.useRef(0);

  React.useEffect(() => {
    if (skip || !scrollContentRef?.current || fetchMoreCount.current > 4) {
      return;
    }

    const contentDomRect = scrollContentRef.current.getBoundingClientRect();
    const { bottom: bottomOfContent } = contentDomRect;

    if (bottomOfContent - LOAD_THRESHOLD_PX < window.innerHeight) {
      fetchMore();
      fetchMoreCount.current += 1;
    }
  }, [skip, fetchMore, scrollContentRef]);
}
