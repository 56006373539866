import { useRef, useEffect, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { spacing, useAnalytics } from '@grain/grain-ui';
import { isMac } from '@grain/components/support/browser';
import { useEventListener } from '@grain/components/support/utils';

import {
  NormalizedActiveFilter,
  searchScreenState,
  useRecentSearches,
  useSearchString
} from '../state';
import styled from '@emotion/styled';
import { SearchWithFilterSuggestions } from '~/components/SearchWithFiltersSuggestions/SearchWithFilterSuggestions';
import { TokenizedInputRef } from 'components/molecules/TokenizedInput/TokenizedInput';
import { useAllFilters } from '~/modules/filtersV2/filters/useAllFilters';
import { LabelWithValue } from '~/modules/filtersV2/types';
import { ParticipantScope } from '~/modules/filtersV2/filters/useParticipantScope';
import { theme } from '@grain/grain-ui/v4';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${spacing.py3}
  ${theme.utils.px('lg')}

  @media (${theme.tokens.breakpoints.lgMin}) {
    ${spacing.px6}
  }
`;

const RECENT_SEARCH_LIMIT = 5;

type SearchInputContainerProps = {
  normalizedActiveFilters: NormalizedActiveFilter[];
  filtersAsString: string;
  removeFilterByName: (filterName: string) => void;
  filters: ReturnType<typeof useAllFilters>;
};

export const SearchInputContainer = ({
  normalizedActiveFilters,
  filtersAsString,
  removeFilterByName,
  filters
}: SearchInputContainerProps) => {
  const { addRecentSearch } = useRecentSearches(RECENT_SEARCH_LIMIT);
  const [searchString, setSearchString] = useSearchString();
  const [screen] = useRecoilState(searchScreenState);
  const inputRef = useRef<TokenizedInputRef>(null);

  const { trackEvent } = useAnalytics();

  // Focus and select input on load
  useEffect(() => {
    if (!inputRef?.current) {
      return;
    }
    inputRef.current?.getInputRef()?.focus();
    inputRef.current?.getInputRef()?.select();
  }, [inputRef]);

  // Focus input with `cmd/ctrl k` shortcut on this page
  useEventListener(
    'keydown',
    e => {
      if (e.key === 'k' && ((isMac && e.metaKey) || (!isMac && e.ctrlKey))) {
        e.preventDefault();
        if (!inputRef?.current) return;
        inputRef.current.getInputRef()?.focus();
        inputRef.current.getInputRef()?.select();
      }
    },
    document.body
  );

  const isAnalyticsTracked = useRef(false);

  const handleSearch = (value: string) => {
    setSearchString(value);
    addRecentSearch(value);

    if (!isAnalyticsTracked.current && value) {
      trackEvent('Search Meetings', {}, ['user', 'workspace']);

      isAnalyticsTracked.current = true;
    }

    if (!value) {
      isAnalyticsTracked.current = false;
    }
  };

  const handleAddSuggestion = useCallback(
    (key: string, label: string, value: string) => {
      if (!key) {
        return;
      }
      const existingFilterValues = (
        normalizedActiveFilters.find(filter => filter.filterKey === key)
          ?.values ?? []
      ).map(v => {
        return {
          value: v.value as string,
          label: v.label
        };
      });
      const filter = filters[key as keyof typeof filters];
      if (!filter) {
        return;
      }

      const valueToAdd = {
        value,
        label
      };

      const multipleValues = [...existingFilterValues, valueToAdd];

      if (
        filter.id === 'tags' ||
        filter.id === 'smart_tags' ||
        filter.id === 'groups' ||
        filter.id === 'persons'
      ) {
        filter.setValue(multipleValues);
      } else if (filter.id === 'owner' || filter.id === 'collections') {
        filter.setValue(valueToAdd);
      } else if (filter.id === 'participant_scope') {
        filter.setValue(valueToAdd as LabelWithValue<ParticipantScope>);
      }
    },
    [normalizedActiveFilters, filters]
  );

  return (
    <Container>
      <SearchWithFilterSuggestions
        ref={inputRef}
        initialValue={searchString}
        onRemoveToken={({ key }) => removeFilterByName(key)}
        onEnter={handleSearch}
        onAddSuggestion={handleAddSuggestion}
        loading={screen === 'searching'}
        normalizedActiveFilters={normalizedActiveFilters}
        filters={filtersAsString}
      />
    </Container>
  );
};
