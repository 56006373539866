import styled from '@emotion/styled';
import { flex, color, pxToRem } from '@grain/grain-ui';
import { spacing, media } from '~/../grain-styles/constants';

export const StyledContainer = styled.div`
  ${flex.direction.column};
  ${flex.alignItems.center};
`;

export const StyledMeetingImg = styled.img`
  width: 162px;
  margin-top: 40px;
  margin-bottom: 32px;
`;

export const StyledFooterText = styled.div`
  font-weight: 400;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(16)};
  ${color.fg.crow};
`;

export const StyledConnectZoomText = styled.a`
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(20)};
  font-style: normal;
  font-weight: 500;
  text-align: center;
  text-decoration-line: underline;
  cursor: pointer;
  ${color.fg.pigeon};
  ${spacing.mt4};
  ${spacing.mb6};
`;

export const StyledConnect = styled.button<{ href?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: none;
  outline: none;
  ${color.bg.gull};
  ${color.fg.blackbird};
  width: 451px;
  height: 48px;
  border-radius: 12px;
  cursor: pointer;

  font-size: ${pxToRem(14)};
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  gap: 12px;

  ${media.small} {
    max-width: 100%;
    ${spacing.px4};
  }

  &:hover {
    text-decoration: none;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledClose = styled.a`
  ${color.fg.crow};
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(20)};
  font-style: normal;
  font-weight: 500;
  text-decoration-line: underline;
  margin-top: auto;
  cursor: pointer;
`;
