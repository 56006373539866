import InfiniteScroll from 'react-infinite-scroll-component';
import styled from '@emotion/styled';
import { RecordingSearchItem } from '~/components/RecordingSearchItem/RecordingSearchItem';
import { SearchResultsV2Query } from '../searchQuery.generated';
import { ResultHeading } from './ResultHeading';
import pluralize from 'pluralize';

type ResultsProps = {
  searchResults: SearchResultsV2Query['searchResultsV2']['results'];
  totalCount?: number;
  hasMore: boolean;
  onLoadMore: () => void;
  onConnectToHubspot: () => void;
  setSelectedRecordingIds: (ids: string[]) => void;
  selectedRecordingIds: string[];
  smartTagView?: boolean;
};

const StyledResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Results = ({
  searchResults,
  totalCount = 0,
  hasMore,
  onLoadMore,
  setSelectedRecordingIds,
  selectedRecordingIds,
  smartTagView = false
}: ResultsProps) => {
  return (
    <StyledResultsWrapper>
      <ResultHeading>
        {totalCount} {pluralize('Meeting', totalCount)}
      </ResultHeading>
      <InfiniteScroll
        dataLength={searchResults.length}
        next={onLoadMore}
        hasMore={hasMore}
        loader={null}
        scrollableTarget='infinite-scroll-container'
      >
        {searchResults.map(searchRecording => (
          <RecordingSearchItem
            key={searchRecording.recordingId}
            searchResult={searchRecording}
            setSelectedRecordingIds={setSelectedRecordingIds}
            selectedRecordingIds={selectedRecordingIds}
            smartTagView={smartTagView}
          />
        ))}
      </InfiniteScroll>
    </StyledResultsWrapper>
  );
};
