import React from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { useRouteModal, useConfirm, color, pxToRem } from '@grain/grain-ui';
import { useExperiment, useMyself } from '~/support/auth';
import {
  INVITE_ROUTE_ID,
  CANCEL_PLANS_ROUTE_ID,
  CHANGE_LEGACY_PLAN_ROUTE_ID,
  START_TRIAL_ROUTE_ID
} from '@grain/components/modals/constants';
import PlanCard, { PlanAction } from '~/modules/subscriptions/PlanCard';

import {
  usePlan,
  usePlans,
  useUpcomingWorkspacePlan
} from '@grain/components/Subscriptions/hooks';
import { useWorkspaceWithMembers } from '@grain/components/Workspace/hooks';
import {
  SubscriptionPlan,
  UserRole,
  WorkspaceSubscription
} from '@grain/api/schema.generated';
import {
  getBillingDisplayDate,
  SubscriptionExpirationExplanation
} from '~/modules/subscriptions/helpers';
import ChangeLegacyPlanModal from '~/modals/ChangeLegacyPlan';
import { useHandleShowUpgradeModal } from '~/modules/gates';
import { theme } from '@grain/grain-ui/v4';
import { requestTrial } from '~/modules/freemium/requestTrial';
import { getPlanAction } from './getPlanAction';

const StyledContainer = styled.div`
  margin-top: -32px;
  svg.save-20 {
    transform: translateX(-8%);
  }
`;

const StyledBanner = styled.div`
  display: flex;
  margin: 0 -48px;
  padding: 14px 0px 14px 0;
  justify-content: center;
  align-items: center;
  ${color.bg.goose};
  white-space: pre-wrap;

  ${color.fg.blackbird};
  font-size: ${pxToRem(14)};
  font-style: normal;
  font-weight: 500;
  line-height: ${pxToRem(18)};

  span {
    cursor: pointer;
    font-weight: 400;
    text-decoration: underline;
    ${color.fg.highlightGreenDark};
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${theme.tokens.spacing['3xl']};
  padding-bottom: ${theme.tokens.spacing['2xl']};
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textTertiary};

  a {
    color: ${theme.tokens.color.textPrimary};
    cursor: pointer;
  }
`;

export default function PlansTab() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const showConfirm = useConfirm();
  const { workspace, loading: isWorkspaceLoading } = useWorkspaceWithMembers();
  const { myself } = useMyself();
  const { subscriptionPlans, loading: plansLoading } = usePlans();
  const openSubscribeModal = useHandleShowUpgradeModal();
  const { enabled: isFreemiumEnabled } = useExperiment('freemium');

  const { open: openInviteModal } = useRouteModal(INVITE_ROUTE_ID);
  const { open: openCancelPlanModal } = useRouteModal(CANCEL_PLANS_ROUTE_ID);
  const {
    open: openChangeLegacyPlanModal,
    isOpen: ChangeLegacyPlanModalIsOpen
  } = useRouteModal(CHANGE_LEGACY_PLAN_ROUTE_ID);
  const { open: openStartTrialModal } = useRouteModal(START_TRIAL_ROUTE_ID);

  const isAdmin = myself?.user?.role === UserRole.Admin;

  const { subscriptionPlan: upcomingPlan } = useUpcomingWorkspacePlan();
  const { workspaceSubscription } = workspace || {};
  const canTrial = workspaceSubscription?.canTrial;
  const isTrial = workspaceSubscription?.trial;

  const {
    subscriptionPlanIndex: currentPlanIndex,
    subscriptionPlan: currentPlan
  } = usePlan(workspaceSubscription?.planSku);

  const handleRedirect = React.useCallback(() => {
    const redirectPath = searchParams.get('from');
    if (redirectPath) navigate(redirectPath, { replace: true });
  }, [navigate, searchParams]);

  const freeUsersCount = React.useMemo(
    () => workspace?.users?.filter(user => !user.onPlan).length,
    [workspace]
  );

  const openContactAdmin = React.useCallback(() => {
    showConfirm({
      confirmButtonType: 'primary',
      confirmContent: 'Go back',
      showCancel: false,
      closable: false,
      maskClosable: true,
      description: 'To make any upgrades, please contact your workspace admin.',
      title: 'Only admins can upgrade',
      width: 418
    });
  }, [showConfirm]);

  const handlePlanAction = React.useCallback(
    (plan: SubscriptionPlan, planAction: PlanAction) => {
      const handleOpenSubscribeModal = (omitPlanMembers = false) => {
        if (!plan.isFree && !isAdmin) {
          openContactAdmin();
          return;
        }
        openSubscribeModal(plan.sku, {
          omitPlanMembers,
          source: 'Plans Page Upgrade'
        });
      };

      const handleViewMembers = () => {
        navigate('/app/settings/workspace?tab=members');
      };

      const handleShowSubscriptionExpirationExplanation = () => {
        showConfirm({
          showCancel: false,
          width: 418,
          confirmButtonType: 'primary',
          title: 'Plan Change Pending',
          onConfirm: handleRedirect,
          description: <SubscriptionExpirationExplanation />
        });
      };

      if (planAction === PlanAction.INVITE_TEAM) {
        openInviteModal();
      } else if (planAction === PlanAction.VIEW_MEMBERS) {
        handleViewMembers();
      } else if (planAction === PlanAction.START_TRIAL) {
        openStartTrialModal();
      } else if (planAction === PlanAction.REQUEST_TRIAL) {
        requestTrial({ workspace, plan });
      } else if (upcomingPlan?.sku === plan.sku) {
        // All remaining actions are conditioned on the option not already being
        // the upcoming plan.  If it is the upcoming plan, then show an
        // explanation instead of executing the option.
        handleShowSubscriptionExpirationExplanation();
      } else if (planAction === PlanAction.UPGRADE_PLAN) {
        handleOpenSubscribeModal();
      } else if (planAction === PlanAction.CANCEL_PLAN) {
        openCancelPlanModal();
      } else if (planAction === PlanAction.CHANGE_PLAN) {
        navigate({
          pathname: `/app/change-plan/${plan.sku}`,
          search: location.search
        });
      }
    },
    [
      handleRedirect,
      isAdmin,
      location.search,
      navigate,
      openStartTrialModal,
      openCancelPlanModal,
      openContactAdmin,
      openInviteModal,
      showConfirm,
      openSubscribeModal,
      upcomingPlan,
      workspace
    ]
  );

  const renderPlanCards = (
    subscriptionPlans: SubscriptionPlan[],
    workspaceSubscription: WorkspaceSubscription,
    isTrial: boolean,
    currentPlanIndex: number,
    plansLoading: boolean,
    upcomingPlan: SubscriptionPlan,
    handlePlanAction: (plan: SubscriptionPlan, planAction: PlanAction) => void,
    isWorkspaceLoading: boolean
  ) => {
    return subscriptionPlans.map((plan, index) => {
      // cannot upgrade to legacy plans
      if (plan.isLegacy) {
        return null;
      }

      // freemium model emphasizes business plans, so we hide enterprise plans
      if (
        isFreemiumEnabled &&
        !workspaceSubscription?.planSku.includes('enterprise') &&
        plan.sku.includes('enterprise')
      ) {
        return null;
      }

      const currentTrial =
        isTrial && workspaceSubscription?.planSku === plan.sku;

      const isCurrentPlan = plan.sku === workspaceSubscription?.planSku;
      const isOnFreePlan = workspaceSubscription?.planDetails?.isFree;
      const isPostTrial = workspaceSubscription?.trialExpired;

      const planAction = getPlanAction({
        plan,
        isCurrentPlan,
        isOnFreePlan,
        isTrial,
        isPostTrial,
        canTrial
      });

      const getButtonDisabledText = () => {
        if (!isAdmin) return 'Contact workspace admin to change plan';
        if (!workspaceSubscription?.canIncreaseSeats) {
          return 'To add seats, please contact sales@grain.com';
        }
        if (freeUsersCount === 0) return 'Invite more members to buy seats';
        return null;
      };

      const buttonDisabledText = getButtonDisabledText();

      return (
        <PlanCard
          key={plan.sku}
          plan={plan}
          planIndex={index}
          previousPlan={index > 0 ? subscriptionPlans[index - 1] : undefined}
          loading={plansLoading}
          currentPlanIndex={currentPlanIndex}
          currentPlanSku={workspaceSubscription?.planSku}
          upcomingPlanSku={upcomingPlan?.sku}
          planAction={planAction}
          currentTrial={currentTrial}
          canEdit={workspaceSubscription?.canEdit}
          disableButtonText={buttonDisabledText || undefined}
          onAction={() => handlePlanAction(plan, planAction)}
          isWorkspaceLoading={isWorkspaceLoading}
          isAdmin={isAdmin}
        />
      );
    });
  };

  return (
    <>
      <StyledContainer>
        {currentPlan.isLegacy && (
          <StyledBanner>
            You are currently on one of Grain's legacy business plans,{' '}
            {upcomingPlan?.sku &&
            upcomingPlan?.sku !== workspaceSubscription?.planSku ? (
              `your plan will change on ${getBillingDisplayDate(
                workspaceSubscription?.renewalDate ?? ''
              )}.`
            ) : (
              <span
                onClick={() => {
                  openChangeLegacyPlanModal();
                }}
              >
                learn more here.
              </span>
            )}
          </StyledBanner>
        )}
        <div
          css={[
            'display: flex; flex-wrap: wrap; justify-content: center; padding-top: 32px;'
          ]}
        >
          {renderPlanCards(
            subscriptionPlans,
            workspaceSubscription,
            isTrial,
            currentPlanIndex,
            plansLoading,
            upcomingPlan,
            handlePlanAction,
            isWorkspaceLoading
          )}
        </div>
      </StyledContainer>
      {isFreemiumEnabled && (
        <StyledButtonWrapper>
          <span>
            Enterprise customer?
            <a css={['margin-left: 6px;']} target='_blank' href='/live-demo'>
              Book a demo
            </a>
          </span>
        </StyledButtonWrapper>
      )}
      {ChangeLegacyPlanModalIsOpen && currentPlan.isLegacy && (
        <ChangeLegacyPlanModal
          openContactAdmin={openContactAdmin}
          isAdmin={isAdmin}
          subscriptionPlans={subscriptionPlans}
          currentPlan={currentPlan}
          upcomingPlan={upcomingPlan}
        />
      )}
    </>
  );
}
