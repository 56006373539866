import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { colors, shadows, spacing } from '@grain/styles/constants';
import { Icon } from '@grain/grain-ui';

const StyledContainer = styled.div`
  display: flex;
  ${spacing.m1};
  ${spacing.p3};
  border-radius: 8px;
  border: 1px solid ${colors.planbird};
  box-shadow: ${shadows.base};
  font-size: calc(14rem / 16);

  a {
    color: ${colors.planbird};
    text-decoration: underline;
  }

  svg {
    width: 40px;
    margin-right: 8px;
  }
`;

FeatureUpgradeInformation.propTypes = {
  children: PropTypes.node,
  onUpgradeClick: PropTypes.func
};

const NOOP = () => {};
export default function FeatureUpgradeInformation({
  children,
  onUpgradeClick = NOOP,
  ...rest
}) {
  return (
    <StyledContainer {...rest}>
      <Icon.Important color={colors.planbird} />
      <div>
        {children}
        &nbsp;
        <Link to='/app/settings/workspace?tab=plans' onClick={onUpgradeClick}>
          Upgrade Now
        </Link>
      </div>
    </StyledContainer>
  );
}
