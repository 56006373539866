import React from 'react';
import styled from '@emotion/styled';

import { EmailContactType } from '@grain/api/schema.generated';
import { Modal, ModalTitle, Skeleton, flex, pxToRem } from '@grain/grain-ui';
import { theme } from '@grain/grain-ui/v4';

import {
  DealDetailsFragment,
  DealEmailFragment,
  useDealEmailQuery
} from '../deal.generated';

import { getActivityTimestamp } from '../utils';
import { formatTime } from './Activity/Timeline';

const StyledModalContainer = styled.div`
  ${flex.direction.column};
  gap: ${theme.tokens.spacing.lg};
  padding: ${theme.tokens.spacing['2xl']} ${theme.tokens.spacing['3xl']};
  overflow-y: auto;
`;

const StyledModalContent = styled.div`
  ${flex.direction.column};
  gap: ${theme.tokens.spacing.lg};
  flex: 1 0 0;
  color: ${theme.tokens.color.textPrimary};
`;

const StyledModalHeader = styled.div`
  ${flex.alignItems.center};
  gap: ${theme.tokens.spacing.xs};
`;

const StyledHeaderText = styled.span`
  ${theme.tokens.typography.h6};
  flex: 1 0 0;
  color: ${theme.tokens.color.textPrimary};
`;

const StyledTime = styled.span`
  ${theme.tokens.typography.c1[500]};
  color: ${theme.tokens.color.textTertiary};
`;

const StyledEmailMeta = styled.div`
  ${flex.direction.column};
  gap: ${theme.tokens.spacing.sm};
  ${theme.tokens.typography.c1[500]};
  color: ${theme.tokens.color.textSecondary};
`;

const StyledModalBody = styled.div`
  ${theme.tokens.typography.b2[400]};
  line-height: ${pxToRem(20)};
  white-space: preserve-breaks;
`;

type DealEmailModalProps = {
  deal: DealDetailsFragment;
  email: DealEmailFragment;
} & Omit<React.ComponentProps<typeof Modal>, 'children'>;

export function DealEmailModal({
  deal,
  email,
  ...modalProps
}: DealEmailModalProps) {
  const { loading, data } = useDealEmailQuery({
    variables: { id: email.id }
  });

  const fullEmail = data?.dealEmail;

  const toEmails = React.useMemo(() => {
    if (!fullEmail) return;

    const string = fullEmail.emailContacts
      .filter(contact => contact.type === EmailContactType.To)
      .map(contact => contact.email)
      .join(', ');

    return string === '' ? null : string;
  }, [fullEmail]);

  const ccEmails = React.useMemo(() => {
    if (!fullEmail) return;

    const string = fullEmail.emailContacts
      .filter(contact => contact.type === EmailContactType.Cc)
      .map(contact => contact.email)
      .join(', ');

    return string === '' ? null : string;
  }, [fullEmail]);

  return (
    // 600 seems to be wide enough to make email client-inserted line breaks
    // in paragraphs look more like line breaks in flowing text (fixing "bad
    // line wrapping" issues on desktop browsers).
    <Modal closable width={600} {...modalProps}>
      <ModalTitle title={deal.name} centered></ModalTitle>
      <StyledModalContainer>
        {loading ? (
          <SkeletonModalContent />
        ) : (
          <StyledModalContent>
            <StyledEmailMeta>
              <StyledModalHeader>
                <StyledHeaderText>{email.from?.name}</StyledHeaderText>
                <StyledTime>
                  {formatTime(getActivityTimestamp(email))}
                </StyledTime>
              </StyledModalHeader>
              <div>{email.subject}</div>
              {toEmails && <div>to: {toEmails}</div>}
              {ccEmails && <div>cc: {ccEmails}</div>}
            </StyledEmailMeta>
            <StyledModalBody>{fullEmail?.body}</StyledModalBody>
          </StyledModalContent>
        )}
      </StyledModalContainer>
    </Modal>
  );
}

function SkeletonModalContent() {
  return (
    <StyledModalContent>
      <StyledEmailMeta>
        <StyledModalHeader>
          <StyledHeaderText>
            <Skeleton
              width={162}
              height={18}
              css={[`border-radius: ${theme.tokens.radii.md};`]}
            />{' '}
          </StyledHeaderText>
          <StyledTime>
            <Skeleton
              width={40}
              height={16}
              css={[`border-radius: ${theme.tokens.radii.md};`]}
            />
          </StyledTime>
        </StyledModalHeader>
        {Array(2)
          .fill(0)
          .map((_, i) => (
            <div key={i}>
              <Skeleton
                width={112}
                height={16}
                css={[`border-radius: ${theme.tokens.radii.md};`]}
              />
            </div>
          ))}
      </StyledEmailMeta>
      {/* Expand to the maximum height but without triggering a scrollbar: */}
      <StyledModalBody css={['height: calc(70vh - 186px);']} />
    </StyledModalContent>
  );
}
