import { Icon, LibraryPageBulkActions, useAnalytics } from '@grain/grain-ui';
import React, { useCallback, useMemo } from 'react';
import { useWorkspaceTags } from '@grain/components/Tags/hooks';
import {
  useCollectionAddRecordingsToCollection,
  useCollections,
  useCreateCollectionWithRecordings
} from '~/modules/collections/hooks';
import { useRecordingsDelete, useTagRecordings } from '~/pages/Library/hooks';
import { cacheDeleteRecordings } from '@grain/api/graphql/cache-helpers';
import { useApolloClient } from '@apollo/client';

const COLLECTIONS_DEFAULT_TITLE = 'New playlist';

type PartialRecording = {
  id: string;
  canDelete: boolean;
};

export type RecordingBulkActionsProps = {
  checkedItemIds: string[];
  recordings: PartialRecording[];
  cancelSelect: () => void;
};

export const RecordingBulkActions: React.FC<RecordingBulkActionsProps> = ({
  checkedItemIds,
  recordings,
  cancelSelect
}) => {
  const { workspaceTags } = useWorkspaceTags();
  const { trackEvent } = useAnalytics();
  const deleteRecordings = useRecordingsDelete();
  const createCollectionWithRecordings = useCreateCollectionWithRecordings();
  const tagRecordings = useTagRecordings();

  const deleteDisabled = useMemo(
    () =>
      recordings.some(
        recording =>
          checkedItemIds.includes(recording.id) && !recording.canDelete
      ),
    [checkedItemIds, recordings]
  );

  const {
    collections: collectionsList,
    onSearch: onCollectionSearch,
    fetchMore: fetchMoreCollections
  } = useCollections();

  const parsedWorkspaceTags = useMemo(() => {
    return [...workspaceTags]
      .sort((a, b) => b.usageCount - a.usageCount)
      .map(tag => ({
        title: `#${tag.text}`,
        value: tag.text
      }));
  }, [workspaceTags]);

  const collectionOptions = useMemo(() => {
    return [
      {
        title: 'New playlist',
        value: 'new',
        icon: <Icon.DeprecatedPlus />
      },
      ...collectionsList.map(c => ({
        title: c.title,
        value: c.id
      }))
    ];
  }, [collectionsList]);

  const collectionAddRecordings = useCollectionAddRecordingsToCollection();

  const handleCollectionAddRecordings = (
    meetingIds: string[],
    collectionId: string
  ) => {
    trackEvent(
      'Button Clicked',
      {
        button_name: 'bulk_actions_collection_add',
        category: 'bulk_actions'
      },
      ['user', 'workspace']
    );
    collectionAddRecordings(
      collectionId,
      meetingIds,
      recordings,
      collectionsList
    );
    cancelSelect();
  };

  const apolloClient = useApolloClient();

  const onRecordingsDeleted = useCallback(() => {
    cacheDeleteRecordings(apolloClient, { recordingIds: checkedItemIds });
    cancelSelect();
  }, [apolloClient, checkedItemIds, cancelSelect]);

  const handleDeleteRecordings = useCallback(
    (recordingIds: string[]) => {
      deleteRecordings(recordingIds, onRecordingsDeleted);
    },
    [deleteRecordings, onRecordingsDeleted]
  );

  if (checkedItemIds.length === 0) {
    return null;
  }

  return (
    <LibraryPageBulkActions
      tags={parsedWorkspaceTags}
      collections={collectionOptions}
      itemName='Meeting'
      itemIds={checkedItemIds}
      onAddToCollection={handleCollectionAddRecordings}
      onNewCollection={(meetingIds: string[]) => {
        trackEvent(
          'Button Clicked',
          {
            button_name: 'bulk_actions_collection_add_new',
            category: 'bulk_actions'
          },
          ['user', 'workspace']
        );
        createCollectionWithRecordings(COLLECTIONS_DEFAULT_TITLE, meetingIds);
        cancelSelect();
      }}
      onCollectionSearch={onCollectionSearch}
      fetchMore={fetchMoreCollections}
      onDeleteItems={handleDeleteRecordings}
      deleteDisabled={deleteDisabled}
      onAddTag={(meetingIds: string[], tagId: string) => {
        trackEvent(
          'Button Clicked',
          {
            button_name: 'bulk_actions_tag',
            category: 'bulk_actions'
          },
          ['user', 'workspace']
        );
        tagRecordings(meetingIds, tagId);
        cancelSelect();
      }}
      cancelSelect={cancelSelect}
    />
  );
};
