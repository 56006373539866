import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Footer from '~/pages/Login/Footer';
import SSOAuth from '../Login/SSOAuth';
import { getUTMParams } from '~/support/campaigns';
import {
  spacing,
  LinkButton,
  ErrorBox,
  Icon,
  getTrackEventFunction
} from '@grain/grain-ui';
import { LoginButton } from '~/components/LoginButton';

import {
  StyledCard,
  StyledCardHeaderText,
  StyledCardSubHeaderText,
  StyledCardWrapper,
  StyledContainer,
  StyledHeaderBar,
  StyledHeaderLogo,
  StyledHeaderSignup,
  StyledLayoutContainer,
  StyledLayoutTopAndBottom,
  StyledLayoutSection,
  StyledLayoutFooter,
  loginButtonStyles
} from '../Login/styles';

import { getMetaContent } from '@grain/components/support/browser';
import { StyledUrlErrorTextWrapper } from '~/modules/errors/hooks';
import api from '@grain/components/support/api';
import { Helmet } from 'react-helmet';
import { Button, Icon16 } from '@grain/grain-ui/v4';
import { PrivacySecurityInfo } from './PrivacySecurityInfo';

const errorMessage = (error: string): React.ReactElement => {
  switch (error) {
    case 'auth_failure':
      return <>A sign up error occurred, please try again.</>;
    case 'create_account':
      return (
        <>
          The Grain account you tried to sign in with does not exist, please
          sign up below.
        </>
      );
    case 'account_not_found':
      return (
        <>
          We couldn't find your workspace invite. Please ask your grain
          workspace admin to invite you.
        </>
      );
    default:
      return (
        <>
          Something went wrong. Please try again. If the problem persists,
          please contact help@grain.com.
        </>
      );
  }
};

export default function SignUp() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error') || false;
  const newUrlParams = new URLSearchParams();
  const addUrlParamIfExists = (key: string) => {
    const val = searchParams.get(key);
    val && newUrlParams.set(key, val);
  };

  const [showSSOAuth, setShowSSOAuth] = React.useState(false);

  const message = searchParams.get('message');

  const showWorkEmailMessage = message === 'work_email';

  newUrlParams.set('action', searchParams.get('action') || 'web_register');
  addUrlParamIfExists('redirect_to');
  addUrlParamIfExists('state');
  addUrlParamIfExists('post_registration');
  const workspaceId = getMetaContent('grain:sso_workspace_id') || false;
  const isInvite = getMetaContent('grain:invite') || false;
  const workspaceName = getMetaContent('grain:workspace:name');
  const inviterName = getMetaContent('grain:workspace:inviter:name');
  const inviterEmail = getMetaContent('grain:workspace:inviter:email');
  const byInvite = isInvite && workspaceName;

  const trackEvent = getTrackEventFunction('', api);

  const redirect = (provider: string) =>
    `/app/_/redirect/${provider}?${newUrlParams.toString()}`;

  const trackRegisterClick = (provider: string, href: string, text: string) => {
    trackEvent('Sign Up Clicked', {
      signup_type: provider,
      url: window.location.href,
      destination: href,
      text: text
    });
  };

  const handleSSORedirect = () => {
    const path = `${redirect('workos')}&sso_workspace_id=${workspaceId}`;
    window.location.href = path;
    return false;
  };

  const toggleSSOAuth = () => {
    setShowSSOAuth(current => !current);
  };

  const handleSSOButtonClicked = (): void => {
    if (workspaceId) {
      handleSSORedirect();
    } else {
      toggleSSOAuth();
    }
  };

  const invitedMessage = inviterName
    ? `${inviterName} (${inviterEmail}) has invited you`
    : 'You have been invited';

  return (
    <>
      {/* This should be temporary as ENG-13368 */}
      <Helmet>
        <script
          async
          src='https://www.googletagmanager.com/gtag/js?id=AW-778080622'
        />
        <script>{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){ dataLayer.push(arguments); }
        gtag('js', new Date());

        gtag('config', 'AW-778080622');
      `}</script>
      </Helmet>
      <StyledContainer>
        {error && (
          <ErrorBox
            variant='light'
            css={[spacing.m0, 'border-radius: unset !important;']}
            showGeneric={false}
          >
            <StyledUrlErrorTextWrapper>
              <Icon.Alert />
              {errorMessage(error)}
            </StyledUrlErrorTextWrapper>
          </ErrorBox>
        )}
        <StyledLayoutContainer>
          <StyledLayoutTopAndBottom>
            <StyledLayoutSection>
              <StyledHeaderBar
                justifyContent={
                  showWorkEmailMessage ? 'space-between' : undefined
                }
              >
                {showWorkEmailMessage && (
                  <Button
                    variant='ghost'
                    textLabelProps={{ startIcon: Icon16.ArrowLeft }}
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>
                )}
                <StyledHeaderSignup>
                  <LinkButton
                    css={['width: 108px;']}
                    variant='secondary'
                    to='/login'
                    size='large'
                  >
                    Sign in
                  </LinkButton>
                </StyledHeaderSignup>
              </StyledHeaderBar>
              <StyledHeaderLogo>
                <a
                  href={`/?${getUTMParams({
                    source: 'app',
                    medium: 'site_link',
                    content: 'product',
                    campaign: 'signup_logo'
                  })}`}
                >
                  <Icon.GrainLogoAndWord />
                </a>
              </StyledHeaderLogo>
              <StyledCardWrapper>
                <StyledCard>
                  {byInvite ? (
                    <>
                      <StyledCardHeaderText>
                        Join {workspaceName} on Grain
                      </StyledCardHeaderText>
                      <StyledCardSubHeaderText>
                        {`${invitedMessage}`} to capture and share your customer
                        meetings with Grain.
                      </StyledCardSubHeaderText>
                    </>
                  ) : (
                    <>
                      <StyledCardHeaderText css={['text-align: center;']}>
                        {showWorkEmailMessage ? (
                          <>
                            Sign up by connecting
                            <br />
                            your work calendar
                          </>
                        ) : (
                          <>Sign up by connecting your calendar</>
                        )}
                      </StyledCardHeaderText>
                      <StyledCardSubHeaderText>
                        {showWorkEmailMessage
                          ? 'Unlimited meeting recordings await!'
                          : 'Use a work email, get unlimited recordings.'}
                      </StyledCardSubHeaderText>
                    </>
                  )}
                  <div className='content-wrapper'>
                    {!showSSOAuth ? (
                      <>
                        {!workspaceId && (
                          <>
                            <div
                              css={[
                                'width: 100%; text-align: center; margin-bottom: 20px;'
                              ]}
                            >
                              <LoginButton
                                as='a'
                                loginType='Google'
                                href={redirect('google')}
                                css={[loginButtonStyles]}
                                onClick={() =>
                                  trackRegisterClick(
                                    'google',
                                    redirect('google'),
                                    'Sign up with Google'
                                  )
                                }
                              >
                                Sign up with Google
                              </LoginButton>
                            </div>

                            <div
                              css={[
                                'width: 100%; text-align: center; margin-bottom: 16px;'
                              ]}
                            >
                              <LoginButton
                                as='a'
                                loginType='Microsoft'
                                href={redirect('microsoft')}
                                css={[loginButtonStyles]}
                                onClick={() =>
                                  trackRegisterClick(
                                    'microsoft',
                                    redirect('microsoft'),
                                    'Sign up with Outlook'
                                  )
                                }
                              >
                                Sign up with Outlook
                              </LoginButton>
                            </div>
                          </>
                        )}

                        <div css={['width: 100%; text-align: center;']}>
                          <LoginButton
                            as='a'
                            loginType='SSO'
                            variant='ghost'
                            css={[loginButtonStyles]}
                            onClick={handleSSOButtonClicked}
                          >
                            Sign up with SSO
                          </LoginButton>
                        </div>
                      </>
                    ) : (
                      <SSOAuth redirect={`${redirect('workos')}&email=`} />
                    )}

                    {showSSOAuth && (
                      <div className='sso-toggle'>
                        <a tabIndex={0} onClick={toggleSSOAuth}>
                          Go back
                        </a>
                      </div>
                    )}

                    <Footer
                      css={[
                        'margin: 30px 0; text-align: center; color: #545454;'
                      ]}
                    />
                  </div>
                </StyledCard>
              </StyledCardWrapper>
            </StyledLayoutSection>
          </StyledLayoutTopAndBottom>
          <StyledLayoutFooter>
            <PrivacySecurityInfo />
          </StyledLayoutFooter>
        </StyledLayoutContainer>
      </StyledContainer>
    </>
  );
}
