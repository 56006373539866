import { font, color, spacing } from '@grain/styles/constants';
import styled from '@emotion/styled';
import { flex } from '@grain/grain-ui';

export const StyledContainer = styled.div`
  ${spacing.px6};
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
`;

export const StyledTitle = styled.div`
  ${color.blackbird};
  ${font.titleL};
  ${spacing.mb6};
  height: 48px;
  margin-top: 64px;
`;

export const StyledPackageList = styled.div`
  ${flex.direction.column};
  ${spacing.mb6};
`;

export const StyledPackage = styled.a`
  ${font.bodyM};
  ${spacing.mb1};
`;
