import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { isDesktopApp } from '@grain/desktop-lib';
import {
  ImageEmptyView,
  color,
  getBasePublicPath,
  pxToRem
} from '@grain/grain-ui';
import { Button } from '@grain/grain-ui/v4';

type Props = {
  errorState: DealsErrorStates;
};

export type DealsErrorStates = keyof typeof states;

type ErrorStateData = {
  title: React.ReactNode;
  description: React.ReactNode;
  cta: React.ReactNode;
};

const StyledDescription = styled(ImageEmptyView.Description)`
  color: ${color.crow};
  font-size: ${pxToRem(16)};
`;

const NavigateButton = ({
  to,
  children
}: React.PropsWithChildren<{ to: string }>) => {
  const navigate = useNavigate();
  return (
    <Button onClick={() => navigate(to)} variant='primary'>
      {children}
    </Button>
  );
};

const hubspotIntegrationPage = '/app/settings/integrations?tab=hubspot';

const reconnectHref = isDesktopApp
  ? '/app/_/redirect/desktop_integration/hubspot'
  : '/app/_/redirect/hubspot?redirect_to=%2Fapp%2Fdeals';

const reconnectEmailsHref = isDesktopApp
  ? '/app/_/redirect/desktop_integration/hubspot'
  : '/app/_/redirect/hubspot?redirect_to=%2Fapp%2Fdeals';

const states = {
  'no-crm': {
    title: 'Connect HubSpot to use deals',
    description: 'Track deal status, risks, and momentum in one place.',
    cta: (
      <Button variant='primary' size='xl' as='a' href={hubspotIntegrationPage}>
        Connect HubSpot
      </Button>
    )
  } as ErrorStateData,
  salesforce: {
    title: 'Salesforce deal tracking coming soon',
    description:
      "Our Deals feature currently only supports HubSpot. It looks like you've connected Salesforce. Contact support if you need assistance setting up this feature with a different CRM.",
    cta: (
      <Button variant='primary' size='xl' as='a' href={hubspotIntegrationPage}>
        Connect HubSpot
      </Button>
    )
  } as ErrorStateData,
  'no-deals': {
    title: 'No deals in HubSpot.',
    description:
      'It looks like there are no deals to track. Start adding deals in HubSpot to use this feature.',
    cta: null
  } as ErrorStateData,
  'hubspot-disconnected': {
    title: 'HubSpot Disconnected',
    description:
      'It seems your HubSpot connection has been disconnected. Reconnect to resume deal tracking.',
    cta: (
      <NavigateButton to={hubspotIntegrationPage}>
        Reconnect HubSpot
      </NavigateButton>
    )
  } as ErrorStateData,
  'hubspot-no-owners-access': {
    title: 'Reconnect your HubSpot account to get the latest version of deals.',
    description:
      'Easily browse and filter your deals by deal owner when you reconnect.',
    cta: (
      <Button variant='primary' size='xl' as='a' href={reconnectHref}>
        Reconnect HubSpot
      </Button>
    )
  } as ErrorStateData,
  'hubspot-no-emails-access': {
    title: 'Reconnect your HubSpot account to get the latest version of deals.',
    description:
      'Pull in emails from HubSpot when you reconnect to get a wholistic view of your deals.',
    cta: (
      <Button variant='primary' size='xl' as='a' href={reconnectEmailsHref}>
        Reconnect HubSpot
      </Button>
    )
  } as ErrorStateData
} as const;

export const EmptyDealsState = ({ errorState }: Props) => {
  const { title, description, cta } = states[errorState];

  return (
    <ImageEmptyView.Wrapper>
      <ImageEmptyView.Image
        src={`${getBasePublicPath()}/images/zero-states/deals.png`}
      />
      <ImageEmptyView.InnerWrapper>
        <ImageEmptyView.Title>{title}</ImageEmptyView.Title>
        <StyledDescription>{description}</StyledDescription>
        <ImageEmptyView.Action>{cta}</ImageEmptyView.Action>
      </ImageEmptyView.InnerWrapper>
    </ImageEmptyView.Wrapper>
  );
};
