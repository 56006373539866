import styled from '@emotion/styled';
import { flex } from '@grain/grain-ui';
import { theme } from '@grain/grain-ui/v4';

export const StyledOptionContainer = styled.div`
  ${flex.alignItems.flexStart};
  ${flex.direction.column};
  width: 100%;
  &:not(:last-of-type) {
    margin-bottom: ${theme.tokens.spacing['2xl']};
  }
`;

export const StyledOptionLabel = styled.div`
  ${theme.tokens.typography.b3[500]};
  color: ${theme.tokens.color.textPrimary};
  margin-bottom: ${theme.tokens.spacing.sm};
  white-space: nowrap;
  user-select: none;
`;

export const StyledOptionItem = styled.span`
  width: 100%;
`;
