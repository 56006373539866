import PropTypes from 'prop-types';
import React from 'react';
import Input from '@grain/components/Input';
import { useRecoilState, atom } from 'recoil';
import { Icon } from '@grain/grain-ui';
import api from '@grain/components/support/api';
import {
  StyledAttribution,
  StyledImageWrapper,
  inputStyles,
  StyledThumbnail
} from './styles';

const pixabayItemsState = atom({
  key: 'pixabayItemsState',
  default: []
});

Pixabay.propTypes = {
  onSelect: PropTypes.func.isRequired
};

export default function Pixabay({ onSelect }) {
  const [pixabayItems, setPixabayItems] = useRecoilState(pixabayItemsState);
  const [inputValue, setInputValue] = React.useState('');

  const fetchPixabay = React.useCallback(async () => {
    const pixabayProxyUrl = '/_/pixabay/search';

    const searchParams = new URLSearchParams({
      q: inputValue,
      editors_choice: 'false',
      orientation: 'landscape',
      per_page: 12,
      safesearch: 'true'
    }).toString();

    const url = `${pixabayProxyUrl}?${searchParams}`;
    api
      .get(url)
      .then(({ data }) => {
        setPixabayItems(data.hits);
      })
      // eslint-disable-next-line no-console
      .catch(error => console.error(error));
  }, [setPixabayItems, inputValue]);

  const SEARCH_DEBOUNCE_MS = 500;
  const timerRef = React.useRef(null);
  React.useEffect(() => {
    if (timerRef.current) window.clearTimeout(timerRef.current);
    if (inputValue.length === 0) return;

    timerRef.current = window.setTimeout(() => {
      fetchPixabay();
    }, SEARCH_DEBOUNCE_MS);
    return () => window.clearTimeout(timerRef.current);
  }, [inputValue, fetchPixabay]);

  function handleClick(item) {
    onSelect(item.largeImageURL || item.imageURL);
  }

  return (
    <div>
      <Input
        css={[inputStyles]}
        placeholder='Search for images'
        onChange={e => setInputValue(e.target.value)}
      />
      <StyledImageWrapper>
        {pixabayItems.map(item => (
          <StyledThumbnail
            onClick={() => handleClick(item)}
            key={item.id}
            thumbUrl={item.previewURL}
          />
        ))}
      </StyledImageWrapper>
      <StyledAttribution>
        Powered by:&nbsp;
        <a href='https://pixabay.com' target='_blank' rel='noreferrer noopener'>
          <Icon.Pixabay css={['height: 16px; width: auto;']} />
        </a>
      </StyledAttribution>
    </div>
  );
}
