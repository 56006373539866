import React, { useState, useEffect } from 'react';

import { Modal, useAnalytics, useRouteModal } from '@grain/grain-ui';
import { useQuery } from '@grain/api/graphql';
import { subscriptionCancellationDiscountPreview } from '@grain/api/graphql/queries';
import { CancelStepsProps } from './CancelTypes';
import { Confirmation } from './ConfirmationPage';
import { CancellationForm } from './CancellationFormPage';
import { OfferPage } from './OfferPage';
import { ScheduleCancellation } from './ScheduleCancellationPage';
import { CANCEL_PLANS_ROUTE_ID } from '@grain/components/modals/constants';

export default function CancelPlans() {
  const [step, setStep] = useState(1);
  const [hasRenewed, setHasRenewed] = useState(false);
  const [totalSteps, setTotalSteps] = useState(3);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [complaintText, setComplaintText] = useState('');
  const [otherProductText, setOtherProductText] = useState('');
  const handleComplaintText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
      setComplaintText(text);
    }
  };
  const handleOtherProductText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
      setOtherProductText(text);
    }
  };
  const { trackEvent } = useAnalytics();
  const { close } = useRouteModal(CANCEL_PLANS_ROUTE_ID);
  const {
    data: { subscriptionCancellationDiscountPreview: cancelData = {} } = {},
    loading
  } = useQuery(subscriptionCancellationDiscountPreview, {
    fetchPolicy: 'network-only',
    onError: () => {
      setTotalSteps(2);
      return true;
    }
  });
  const closeModal = () => {
    trackEvent('Cancellation Modal Closed', {});
    close();
  };
  const setOptions = (item: string, checked: boolean) => {
    if (checked) {
      setSelectedOptions([...selectedOptions, item]);
    } else {
      setSelectedOptions(selectedOptions.filter(option => option !== item));
    }
  };
  const nextStep = () => {
    setStep(step => step + 1);
  };
  const prevStep = () => {
    setStep(step => step - 1);
  };
  useEffect(() => {
    trackEvent('Cancellation Button Clicked', {});
  }, [trackEvent]);

  useEffect(() => {
    if (hasRenewed) {
      trackEvent('Save Offer Accepted', {
        offerType:
          cancelData?.billingPeriod === 'MONTHLY'
            ? '40% offer type'
            : '25% offer type'
      });
      setStep(totalSteps + 1);
    }
  }, [
    hasRenewed,
    totalSteps,
    cancelData.offerType,
    trackEvent,
    cancelData?.billingPeriod
  ]);
  useEffect(() => {
    if (totalSteps === step) {
      trackEvent('Reason for Cancellation Submitted', {
        reason: selectedOptions.join(', ')
      });
      trackEvent('Reason for Cancellation Details Submitted', {
        reasonDetails: complaintText
      });
      trackEvent('Cancellation Other Product Submitted', {
        product: otherProductText
      });
    }
  }, [
    totalSteps,
    step,
    trackEvent,
    selectedOptions,
    complaintText,
    otherProductText
  ]);
  const component: Record<number, React.FC<CancelStepsProps>> = totalSteps === 3
    ? {
        1: CancellationForm,
        2: OfferPage,
        3: ScheduleCancellation,
        4: Confirmation
      }
    : {
        1: CancellationForm,
        2: ScheduleCancellation,
        3: Confirmation
      };
  const RenderedComponent = component[step];
  return (
    !loading && (
      <Modal
        width={540}
        maxContentHeight='100vh'
        isResponsive={step === 1}
        roundness='lg'
      >
        <RenderedComponent
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          netPrice={cancelData?.netPrice}
          renewalPrice={cancelData?.renewalPrice}
          totalSteps={totalSteps}
          hasRenewed={hasRenewed}
          selectedOptions={selectedOptions}
          setSelectedOptions={setOptions}
          complaintText={complaintText}
          setComplaintText={handleComplaintText}
          otherProductText={otherProductText}
          setOtherProductText={handleOtherProductText}
          setHasRenewed={setHasRenewed}
          billingPeriod={cancelData?.billingPeriod}
          closeModal={closeModal}
        />
      </Modal>
    )
  );
}
