import styled from '@emotion/styled';
import { Icon20, theme } from '@grain/grain-ui/v4';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.tokens.spacing.md};

  padding: ${theme.tokens.spacing['2xl']};
  border-radius: 8px;
  border: 1px solid rgba(255, 69, 69, 0.24);
  background: rgba(255, 69, 69, 0.02);

  svg {
    color: ${theme.tokens.color.textDanger};
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.spacing['2xs']};
  text-wrap: balance;
`;

const Name = styled.div`
  ${theme.tokens.typography.b3[500]};
  text-align: center;
  color: ${theme.tokens.color.textPrimary};
`;

const Description = styled.div`
  ${theme.tokens.typography.b4[400]};
  text-align: center;
  color: ${theme.tokens.color.textTertiary};
`;

type FeatureCardProps = {
  name: string;
  description: string;
};

export const FeatureCard = ({ name, description }: FeatureCardProps) => {
  return (
    <Card>
      <Icon20.XSm />
      <TextContainer>
        <Name>{name}</Name>
        <Description>{description}</Description>
      </TextContainer>
    </Card>
  );
};
