/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import * as Layout from '~/components/Layout';
import { useUrlErrorBanner } from '~/modules/errors/hooks';
import { useInteractionInsightsQuery } from './Interaction.generated';
import { useLocation, useNavigate } from 'react-router-dom';
import { GatedContentWrapper } from '~/modules/gates';
import {
  useTrackersInsightDetailsQuery,
  useTrackersInsightsQuery
} from './Trackers.generated';
import { Interaction, InteractionProps } from './Interaction/Interaction';
import { SmartTags, SmartTagsProps } from './SmartTags/SmartTags';
import { SmartTopic } from '@grain/api/schema.generated';
import { useFeature } from '@grain/api/auth';
import {
  Divider,
  Icon20,
  PageHeader,
  Tab,
  Tabs,
  TabsBar
} from '@grain/grain-ui/v4';
import { FilterBar } from '~/modules/filtersV2/FilterBar/FilterBar';
import { useFiltersManager } from '~/modules/filtersV2/useFiltersManager';
import { subDays } from 'date-fns';
import { isDesktopApp } from '@grain/desktop-lib';

export default ({ contentType = 'interaction' }) => {
  const { toggleCollapsed } = Layout.useSidebar();
  const location = useLocation();
  const { enabled, loading } = useFeature('insights');

  const currentTabIndex = contentType === 'interaction' ? 0 : 1;

  const { filters, resetFilters, viewAdHocFilterString, initialFilters } =
    useFiltersManager({
      availableFilters: [
        'date',
        'owners',
        'smart_tags',
        'persons',
        'groups',
        'workspace_access'
      ],
      initialValues: {
        workspace_access: { value: true, label: 'Workspace' },
        date: {
          range: [subDays(new Date(), 29), new Date()],
          selectedOption: 'last30'
        }
      },
      hiddenFilters: ['workspace_access']
    });

  const ErrorBanner = useUrlErrorBanner();
  const navigate = useNavigate();
  const onChangeSelectedIndex = (index: number) => {
    navigate({
      pathname:
        index === 0 ? '/app/insights/interaction' : '/app/insights/smarttags',
      search: location.search
    });
  };

  const handleFiltersClear = () => {
    resetFilters();
  };

  const [activeSmartTag, setActiveSmartTag] = useState<SmartTopic>();

  const { data: interactionData, loading: interactionLoading } =
    useInteractionInsightsQuery({
      variables: {
        filter: viewAdHocFilterString
      },
      skip: Boolean(!enabled || contentType !== 'interaction')
    });

  const { data: trackersInsightData, loading: trackersLoading } =
    useTrackersInsightsQuery({
      variables: {
        filter: viewAdHocFilterString
      },
      skip: Boolean(!enabled || contentType === 'interaction')
    });

  const { data: trackerInsightDetail, loading: trackerDetailLoading } =
    useTrackersInsightDetailsQuery({
      variables: {
        filter: viewAdHocFilterString,
        tagId: activeSmartTag?.tag?.id ?? ''
      },
      skip: Boolean(!enabled || !activeSmartTag)
    });

  return (
    <>
      <Helmet title='Insights' />
      <Layout.Wrapper withSidebar>
        <ErrorBanner />
        {loading ? (
          <></>
        ) : (
          <>
            <PageHeader
              title='Insights'
              icon={Icon20.BarChart}
              onMenuClick={toggleCollapsed}
              isDesktopApp={isDesktopApp}
            />
            <Divider />
            <GatedContentWrapper gate='insights'>
              <div>
                <TabsBar>
                  <Tabs
                    currentValue={currentTabIndex}
                    onChange={onChangeSelectedIndex}
                  >
                    <Tab value={0}>Interaction</Tab>
                    <Tab value={1}>Trackers</Tab>
                  </Tabs>
                </TabsBar>
                <Divider />
                <FilterBar filters={filters} resetAllFilters={resetFilters} />
                <Divider />
                {contentType === 'interaction' ? (
                  <Interaction
                    onFiltersClear={handleFiltersClear}
                    filter={viewAdHocFilterString}
                    interactionData={
                      interactionData?.insightQueries
                        .interactionInsights as InteractionProps['interactionData']
                    }
                    interactionLoading={interactionLoading}
                  />
                ) : (
                  <SmartTags
                    onFiltersClear={handleFiltersClear}
                    filter={viewAdHocFilterString}
                    initialFilters={initialFilters}
                    trackersInsightData={
                      trackersInsightData?.insightQueries as SmartTagsProps['trackersInsightData']
                    }
                    trackersLoading={trackersLoading}
                    activeSmartTag={activeSmartTag}
                    onSetActiveSmartTag={setActiveSmartTag}
                    trackerInsightDetail={
                      trackerInsightDetail?.insightQueries as SmartTagsProps['trackerInsightDetail']
                    }
                    trackerDetailLoading={trackerDetailLoading}
                  />
                )}
              </div>
            </GatedContentWrapper>
          </>
        )}
      </Layout.Wrapper>
    </>
  );
};
