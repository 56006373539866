import {
  Button,
  GrowingInput,
  Icon,
  InfoBox,
  TagPill,
  color,
  flex,
  font,
  spacing
} from '@grain/grain-ui';
import { IconClose, ShowAdvancedButton } from './TopicWizard.styled';
import { Topic } from './types';
import { useCallback, useRef, useState } from 'react';
import { containsStopWord } from 'lib/stopWord';
import { PhraseItem } from 'components/organisms/TopicEdit/PhraseItem';
import { MAX_KEYWORDS_AND_PHRASES } from './TopicWizard';

type Step2Props = {
  topic: Topic;
  setTopicValue: <K extends keyof Topic>(key: K, value: Topic[K]) => void;
};

const checkKeywordValid = (val: string) =>
  !containsStopWord(val.split(' ')) && val.length >= 3;

export const Step2 = ({ topic, setTopicValue }: Step2Props) => {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [editExample, setEditExample] = useState<Record<number, boolean>>({});
  const [currentInputValue, setCurrentInputValue] = useState('');
  const [keywordHasError, setKeywordHasError] = useState(false);
  const growingInputRef = useRef<HTMLInputElement>(null);

  const hasReachedMaxKeywordsAndPhrases =
    topic.keywords.length + topic.phrases.length >= MAX_KEYWORDS_AND_PHRASES;

  const onBlurred = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (currentInputValue) {
        event.preventDefault();
        if (!checkKeywordValid(currentInputValue)) {
          setKeywordHasError(true);
          return;
        }
        setTopicValue('keywords', [...topic.keywords, currentInputValue]);
        setCurrentInputValue('');
        (event.target as HTMLInputElement).value = '';
      }
    },
    [currentInputValue, setKeywordHasError, topic, setTopicValue]
  );

  const onInputKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      setKeywordHasError(false);
      if (event.code === 'Enter' && currentInputValue) {
        // Enter
        event.preventDefault();
        if (!checkKeywordValid(currentInputValue)) {
          setKeywordHasError(true);
          return;
        }
        setTopicValue('keywords', [...topic.keywords, currentInputValue]);
        setCurrentInputValue('');
        (event.target as HTMLInputElement).value = '';
      } else if (
        event.code === 'Backspace' &&
        topic.keywords.length > 0 &&
        currentInputValue === ''
      ) {
        // Backspace
        event.preventDefault();
        setTopicValue(
          'keywords',
          topic.keywords.slice(0, topic.keywords.length - 1)
        );
        setCurrentInputValue('');
        (event.target as HTMLInputElement).value = '';
      }
    },
    [
      setKeywordHasError,
      currentInputValue,
      setCurrentInputValue,
      setTopicValue,
      topic.keywords
    ]
  );

  const handleDeleteExample = (index: number) => {
    const newExamples = topic.phrases.filter((_, idx) => idx !== index);
    setTopicValue('phrases', newExamples);
  };

  const onInputValChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const newVal = evt?.target?.value ?? '';
      setCurrentInputValue(newVal);
    },
    [setCurrentInputValue]
  );

  return (
    <>
      {hasReachedMaxKeywordsAndPhrases && (
        <InfoBox
          css={[spacing.mx7, 'width:calc(100% - 64px)']}
          icon={<Icon.Info />}
        >
          Maximum number of keywords and phrases reached
        </InfoBox>
      )}
      <section css={[spacing.p7, 'overflow-y: auto;']}>
        <div css={[spacing.mb2, font.v4.h6]}>Keywords</div>
        <div css={[font.v4.b2[400], color.fg.crow, spacing.mb4]}>
          Apply this Tracker when the keywords you add are mentioned in your
          meetings.
        </div>
        <div css={[flex.alignItems.center, spacing.mb6, 'flex-wrap: wrap;']}>
          {topic.keywords.map((keyword, idx) => (
            <TagPill
              key={`item-${idx}`}
              css={[font.v4.button, flex.alignItems.center, spacing.g2]}
            >
              {keyword}
              <IconClose
                onClick={() =>
                  setTopicValue(
                    'keywords',
                    topic.keywords.filter((_, i) => i !== idx)
                  )
                }
              />
            </TagPill>
          ))}
          {!hasReachedMaxKeywordsAndPhrases && (
            <GrowingInput
              ref={growingInputRef}
              placeholder='Add Keyword'
              placeholderIcon={<Icon.DeprecatedPlus />}
              inputValue={currentInputValue}
              onChange={onInputValChange}
              onBlur={onBlurred}
              onKeyDown={onInputKeyPress}
              disabled={false}
              errorText={keywordHasError ? 'Not supported' : undefined}
              containerProps={{
                css: ['width: fit-content;']
              }}
            />
          )}
        </div>

        {!showAdvanced ? (
          <ShowAdvancedButton onClick={() => setShowAdvanced(true)}>
            Show Advanced
          </ShowAdvancedButton>
        ) : (
          <>
            <div css={[spacing.mb4, font.v4.h6, spacing.mb2]}>Phrases</div>
            <div css={[font.v4.b2[400], color.fg.crow, spacing.mb4]}>
              Apply this Tracker when similar phrases are mentioned. Three or
              more example phrases perform best.
            </div>
            <div css={[flex.direction.column, spacing.g4, spacing.mb4]}>
              {topic.phrases.map((phrase, index) => {
                return (
                  <PhraseItem
                    key={index}
                    phrase={phrase}
                    onClickEdit={() => setEditExample({ [index]: true })}
                    isEditing={editExample[index]}
                    onCancelEdit={() => {
                      setEditExample({ [index]: false });
                      // Remove empty phrase
                      if (!topic.phrases[index]) {
                        handleDeleteExample(index);
                      }
                    }}
                    onSave={newPhrase => {
                      const newPhrases = [...topic.phrases];
                      newPhrases[index] = newPhrase;

                      setTopicValue('phrases', newPhrases);
                      setEditExample({ [index]: false });
                    }}
                    onDelete={() => handleDeleteExample(index)}
                  />
                );
              })}
            </div>
            <div>
              <Button
                variant={topic?.phrases?.length > 0 ? 'secondary' : 'primary'}
                disabled={hasReachedMaxKeywordsAndPhrases}
                icon={<Icon.DeprecatedPlus />}
                onClick={() => {
                  const newIndex = topic.phrases.length;
                  setTopicValue('phrases', [...topic.phrases, '']);
                  setEditExample({ [newIndex]: true });
                }}
              >
                Add phrase
              </Button>
            </div>
          </>
        )}
      </section>
    </>
  );
};
