import styled from '@emotion/styled';
import { injectGlobal } from '@emotion/css';
import { css } from '@emotion/react';
import { pxToRem } from '@grain/grain-ui';
import { color, spacing } from '@grain/styles/constants';

export const StyledContainer = styled.div`
  display: flex;
  padding: 136px 50px 50px 50px;
  align-items: center;
  flex-direction: column;
`;

export const StyledHeader = styled.div`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 48px;
  line-height: 130%;
  text-align: center;
  color: #000000;
`;

export const StyledHeaderBar = styled.div`
  height: 90px;
  background-color: ${color.swan};
  display: flex;
  ${spacing.px5};
  ${spacing.pt6};
`;

export const StyledHeaderLogo = styled.div`
  ${spacing.ml5}
  ${spacing.my1}
  flex: 1 1 auto;
`;

export const StyledHeaderSignup = styled.div`
  flex: none;
`;

export const flexContainer = css`
  display: flex;
`;

export const StyledSubHeader = styled.div`
  font-family: 'Inter';
  font-weight: 400;
  font-size: ${pxToRem(18)};
  text-align: center;
  padding-top: 30px;
  color: #707070;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  width: 590px;
  padding: 54px 0 44px 0;
  justify-content: space-between;
  flex-wrap: wrap;
`;

injectGlobal`
  html.has-sidebar {
    ${StyledButtonContainer} {
      @media (max-width: 886px) {
        width: auto;
        justify-content: center;
      }
    }
  }

  html:not(.has-sidebar) {
    ${StyledButtonContainer} {
      @media (max-width: 671px) {
        width: auto;
        justify-content: center;
      }
    }
  }
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export const StyledButtonDescription = styled.div`
  color: #707070;
  font-weight: 400;
  font-size: 14px;
  margin-top: 10px;
`;

export const StyledFooterNote = styled.div`
  color: #707070;
  font-weight: 400;
  font-size: 14px;
  margin-top: 40px;

  a {
    color: #707070;
    text-decoration: underline;
  }
`;
