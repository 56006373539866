import { useCallback, useState } from 'react';
import copy from 'clipboard-copy';
import {
  useAnalytics,
  Icon,
  Dropdown,
  MenuItem,
  MenuDivider,
  ButtonIcon
} from '@grain/grain-ui';
import { downloadFromUrl } from '@grain/components/support/browser';
import { useStoryToast } from '~/pages/StoryPage/hooks';
import { useDeleteRecording } from '~/modules/recording/hooks';
import { css } from '@emotion/react';
import { useRecordingShareInfoLazyQuery } from '~/pages/Library/library.generated';
import ShareRecordingPermissions from '~/modals/SharePermissions/ShareRecording';
import { AddToCollectionDropdown } from './AddToCollectionDropdown';

type RecordingQuickActionsButtonProps = {
  recordingId: string;
  recordingTitle: string;
  recordingCollections: { id: string; title: string }[];
  storyCount: number;
  copyUrl: string;
  downloadUrl: string | null;
  disabled?: boolean;
};

export const RecordingQuickActionsButton = ({
  recordingId,
  recordingTitle,
  recordingCollections,
  storyCount,
  copyUrl,
  downloadUrl,
  disabled
}: RecordingQuickActionsButtonProps) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const [showAddToPlaylistOptions, setShowAddToPlaylistOptions] =
    useState(false);
  const { trackEvent, getMediaDownloadedTrigger } = useAnalytics();
  const showToast = useStoryToast();
  const [getRecordingShareInfo, { data }] = useRecordingShareInfoLazyQuery();
  const shareRecordingInfo = data?.recording;

  const handleShare = useCallback(() => {
    getRecordingShareInfo({
      variables: { recordingId }
    });
    setShowShareModal(true);
  }, [getRecordingShareInfo, recordingId]);

  const handleDeleteRecording = useDeleteRecording({
    recording: { id: recordingId, title: recordingTitle, storyCount }
  });

  const handleCopyLink = useCallback(() => {
    if (!copyUrl) return;

    copy(copyUrl)
      .then(() => {
        showToast({
          content: 'Recording link copied to clipboard',
          type: 'success',
          uniqueId: 'copied_recording_link'
        });
      })
      .catch(() => {
        showToast({
          content: 'Oops, try again.',
          type: 'failure'
        });
      });
  }, [copyUrl, showToast]);

  const handleDownloadRecording = useCallback(() => {
    if (!downloadUrl) return;

    downloadFromUrl(downloadUrl);

    trackEvent(
      'Media Downloaded',
      {
        trigger: getMediaDownloadedTrigger()
      },
      ['user', 'workspace', `recording:${recordingId}`]
    );
  }, [downloadUrl, recordingId, trackEvent, getMediaDownloadedTrigger]);

  // Ensure that we reset the dropdown after the animation has finished
  const delayedHideAddToPlaylistOptions = useCallback(() => {
    setTimeout(setShowAddToPlaylistOptions, 300, false);
  }, []);

  return (
    <>
      <Dropdown
        css={css`
          width: 230px;
        `}
        placement='bottom-end'
        interactive
        onHide={delayedHideAddToPlaylistOptions}
        onShow={instance => {
          // This dropdown has a mix of interactive and non-interactive items.
          // We need to close the dropdown if a non-interactive item is clicked.
          instance.popper.addEventListener('click', event => {
            const target = event.target as HTMLElement;
            const shouldClose = target.closest('.close-tooltip') !== null;
            if (shouldClose) {
              instance.hide();
            }
          });
        }}
        targetElement={
          <ButtonIcon
            variant='stealth'
            data-cy='menu-more-button'
            disabled={disabled}
          >
            <Icon.MenuMore />
          </ButtonIcon>
        }
      >
        {!showAddToPlaylistOptions ? (
          <>
            <MenuItem
              className='close-tooltip'
              label='Copy link'
              icon={Icon.CopyLink}
              onClick={handleCopyLink}
            />
            <MenuItem
              className='close-tooltip'
              label='Share'
              icon={Icon.Share}
              onClick={handleShare}
            />
            <MenuItem
              label='Add to playlist'
              icon={Icon.CollectionOutline}
              onClick={() => setShowAddToPlaylistOptions(true)}
            />
            {downloadUrl && (
              <MenuItem
                className='close-tooltip'
                label='Download'
                icon={Icon.Download}
                onClick={handleDownloadRecording}
                disabled={!downloadUrl}
              />
            )}
            <MenuDivider />
            <MenuItem
              className='close-tooltip'
              label='Delete'
              dangerous
              icon={Icon.Delete}
              onClick={handleDeleteRecording}
            />
          </>
        ) : (
          <AddToCollectionDropdown
            recordingCollections={recordingCollections}
            recordingId={recordingId}
          />
        )}
      </Dropdown>
      {showShareModal && shareRecordingInfo && (
        <ShareRecordingPermissions
          recording={shareRecordingInfo}
          onCancel={() => setShowShareModal(false)}
        />
      )}
    </>
  );
};
