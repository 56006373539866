import {
  Menu,
  Button,
  Icon16,
  MenuHeader,
  MenuButton
} from '@grain/grain-ui/v4';
import { METADATA_ITEMS, MetadataItemId } from './MetadataItems';
import { useAnalytics } from '@grain/grain-ui';

const NOOP = () => {};
const EMPTY_ARRAY: never[] = [];

type MetadataItemsConfigButtonProps = {
  selectedItems: MetadataItemId[];
  onItemToggle?: (itemId: MetadataItemId) => void;
};

export const MetadataItemsConfigButton = ({
  selectedItems = EMPTY_ARRAY,
  onItemToggle = NOOP
}: MetadataItemsConfigButtonProps) => {
  const { trackEvent } = useAnalytics();

  return (
    <Menu
      tippyProps={{
        placement: 'bottom-end'
      }}
      width='232px'
      maxHeight='400px'
      content={
        <>
          <MenuHeader variant='subheader'>List properties</MenuHeader>
          {METADATA_ITEMS.map(item => (
            <MenuButton
              key={item.id}
              label={item.label}
              checked={selectedItems.includes(item.id)}
              onClick={() => onItemToggle(item.id)}
              role='option'
              aria-selected={selectedItems.includes(item.id)}
            />
          ))}
        </>
      }
    >
      <Button
        icon={Icon16.Settings02}
        variant='ghost'
        aria-label='List of metadata properties'
        onClick={() => trackEvent('Meetings List Metadata Button Clicked')}
      />
    </Menu>
  );
};
