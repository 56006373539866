import React from 'react';
import styled from '@emotion/styled';
import UploadImage from './UploadImage';
import {
  getOauthRedirectUrl,
  Icon,
  LogoWithAction,
  color,
  TagPill,
  pxToRem,
  spacing,
  useQueryParam,
  useShowToast
} from '@grain/grain-ui';
import { Button, Icon16, Menu, MenuButton, Tooltip } from '@grain/grain-ui/v4';
import { StyledAccountInfoWrapper } from '../styles';
import CalendarAccount from './CalendarAccount';
import { useMyself, useWorkspace } from '@grain/api/auth';
import { useNavigate } from 'react-router-dom';
import { getErrorText } from '~/modules/errors/hooks';
import { OauthAccount } from './types';
import {
  useOauthAccountDeleteMutation,
  useOauthAccountPrimarySetMutation
} from '@grain/api/graphql/mutations/user.generated';

const UploadImageHeader = styled.div`
  width: 100%;
  text-align: center;
`;

const StyledPrimaryLabel = styled(TagPill)`
  margin-bottom: 0;
  color: ${color.graieen};
  padding: 2px 10px;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(20)};
  ${spacing.ml2};
`;

type Account = 'GOOGLE' | 'MICROSOFT' | 'WORKOS' | 'ZOOM';

export const accounts = {
  GOOGLE: {
    icon: <Icon.Google css={['margin-top: 5px;']} className='icon' />,
    label: 'Google account'
  },
  MICROSOFT: {
    icon: <Icon.Outlook css={['margin-top: 5px;']} className='icon' />,
    label: 'Outlook account'
  },
  WORKOS: {
    icon: <Icon.KeyholeCircle css={['margin-top: 5px;']} className='icon' />,
    label: 'SSO account'
  },
  ZOOM: {
    icon: <Icon.ZoomSquareLogo css={['margin-top: 5px;']} className='icon' />,
    label: 'Zoom account'
  }
};

const getAccountIcon = (provider: Account) => {
  return (
    <Tooltip content={accounts[provider].label}>
      {accounts[provider].icon}
    </Tooltip>
  );
};

export default function Account() {
  const { myself } = useMyself();
  const [showUploadImage, setShowUploadImage] = React.useState(false);

  const { workspace } = useWorkspace();
  const hasSso = workspace?.hasSso;
  const [deleteAccount] = useOauthAccountDeleteMutation();
  const [setPrimaryAccount] = useOauthAccountPrimarySetMutation();
  const currentPath = window.location.pathname + window.location.search;

  const showToast = useShowToast();
  const navigate = useNavigate();

  const [error] = useQueryParam('error');
  const [provider] = useQueryParam('provider');
  React.useEffect(() => {
    if (error) {
      showToast({
        content: getErrorText(error, provider),
        type: 'failure'
      });
      setTimeout(() => navigate(window.location.pathname), 500);
    }
  }, [error, provider, showToast, navigate]);

  const ssoAccountConnected = !!myself?.oauthAccounts.find(
    account => account.provider === 'WORKOS'
  );

  const sortAccounts = () => {
    if (!myself?.oauthAccounts) return [];
    const primaryAccount = myself?.oauthAccounts.find(
      account => account.primaryAccount
    );
    const nonPrimaryAccounts = myself?.oauthAccounts.filter(
      account => !account.primaryAccount
    );
    const accounts = primaryAccount
      ? [primaryAccount, ...nonPrimaryAccounts]
      : myself?.oauthAccounts;
    return accounts.filter(account => account.display);
  };

  const sortedAccounts = sortAccounts();
  const filteredAccounts = sortedAccounts.filter(
    account => account.calendarConnected !== null
  );

  const setPrimary = (id: string) => {
    setPrimaryAccount({
      variables: {
        id
      }
    });
  };

  const removePrimaryAccount = () => {
    if (!myself?.oauthAccounts) return;
    const nextAccount = sortedAccounts.find(account => !account.primaryAccount);
    if (nextAccount) setPrimary(nextAccount.id);
  };

  const accountMenuItems = [
    {
      label: 'Make Primary account',
      onClick: (id: string) => setPrimary(id),
      show: (account: OauthAccount) =>
        account.canSetPrimary && !account.primaryAccount,
      color: color.swan
    },
    {
      label: 'Remove Primary account',
      onClick: removePrimaryAccount,
      show: (account: OauthAccount) =>
        account.primaryAccount &&
        (myself?.oauthAccounts?.length ?? 0) > 1 &&
        account.provider !== 'WORKOS',
      color: color.errorbird
    },
    {
      label: 'Disconnect account',
      onClick: (id: string) => {
        deleteAccount({
          variables: { id }
        });
      },
      show: (account: OauthAccount) => account.canDelete,
      color: color.errorbird
    }
  ];

  const showAccountDropdown = (account: OauthAccount) => {
    return accountMenuItems.filter(item => item.show(account)).length > 0;
  };

  return showUploadImage ? (
    <>
      <UploadImageHeader>
        <Icon.ArrowLeft
          onClick={() => setShowUploadImage(false)}
          css={['float: left; cursor: pointer;']}
        />
        <h4 css={['margin: 0;']}>Upload User Icon</h4>
        <div />
      </UploadImageHeader>
      <UploadImage
        onUploadSuccess={() => setShowUploadImage(false)}
        onCancel={() => setShowUploadImage(false)}
      />
    </>
  ) : (
    <>
      <StyledAccountInfoWrapper>
        <div className='profile-picture'>
          <div className='title'>Profile picture</div>
          <LogoWithAction
            name={myself?.user?.name ?? ''}
            url={myself?.user?.avatarUrl ?? undefined}
            size={82}
            onClick={() => setShowUploadImage(true)}
          />
        </div>
        <div className='divider' />
        <div className='details'>
          <div className='title'>Account Info</div>
          <div className='detail-item'>
            <div className='detail-label'>Name</div>
            <div className='detail-value'>{myself?.user?.name ?? ''}</div>
          </div>
          {sortedAccounts.map((account, index) => (
            <div className='detail-item' key={`${account.email}-${index}`}>
              <div className='detail-label'>
                {(myself?.oauthAccounts?.length ?? 0) > 1
                  ? `Account ${index + 1}`
                  : 'Account'}
                {account.primaryAccount && (
                  <StyledPrimaryLabel>Primary</StyledPrimaryLabel>
                )}
              </div>
              <div className='detail-value'>
                {account.email}
                {getAccountIcon(account.provider)}
                {(myself?.oauthAccounts?.length ?? 0) > 1 &&
                  showAccountDropdown(account) && (
                    <Menu
                      content={
                        <>
                          {accountMenuItems
                            .filter(item => item.show(account))
                            .map(item => (
                              <MenuButton
                                key={item.label}
                                label={item.label}
                                onClick={() => item.onClick(account.id)}
                              />
                            ))}
                        </>
                      }
                    >
                      <Button
                        variant='ghost'
                        textLabelProps={{ startIcon: Icon16.Overflow }}
                      />
                    </Menu>
                  )}
              </div>
            </div>
          ))}
          <div css={['max-width: 130px']}>
            <Menu
              width='fit-content'
              content={
                <>
                  <MenuButton
                    label='Connect Google Account'
                    textLabelProps={{ startIcon: Icon.Google }}
                    onClick={() => {
                      window.location.href = getOauthRedirectUrl(
                        'google',
                        true,
                        'connect_account',
                        {},
                        currentPath,
                        undefined,
                        undefined,
                        currentPath
                      );
                    }}
                  />
                  <MenuButton
                    label='Connect Outlook Account'
                    textLabelProps={{ startIcon: Icon.Outlook }}
                    onClick={() => {
                      window.location.href = getOauthRedirectUrl(
                        'microsoft',
                        true,
                        'connect_account',
                        {},
                        currentPath,
                        undefined,
                        undefined,
                        currentPath
                      );
                    }}
                  />

                  {hasSso && !ssoAccountConnected && (
                    <MenuButton
                      textLabelProps={{ startIcon: Icon.KeyholeCircle }}
                      label='Connect SSO account'
                      onClick={() => {
                        window.location.href = getOauthRedirectUrl(
                          'workos',
                          true,
                          'connect_account',
                          {},
                          currentPath,
                          undefined,
                          workspace?.id,
                          currentPath
                        );
                      }}
                    />
                  )}
                </>
              }
            >
              <Button
                size='lg'
                type='button'
                variant='neutral'
                icon={Icon16.Plus}
              >
                Add account
              </Button>
            </Menu>
          </div>
        </div>
        {filteredAccounts.length > 0 && (
          <>
            <div className='divider' />
            <CalendarAccount accounts={filteredAccounts} />
          </>
        )}
      </StyledAccountInfoWrapper>
    </>
  );
}
