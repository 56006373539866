import {
  COLLECTIONS_DEFAULT_TITLE,
  Icon,
  useAnalytics,
  useShowToast
} from '@grain/grain-ui';
import {
  BaseMenuButton,
  Divider,
  Icon20,
  Input,
  MenuButton,
  TextLabel,
  theme
} from '@grain/grain-ui/v4';
import { useCallback, useMemo, useState } from 'react';
import copy from 'clipboard-copy';
import {
  AdHocRecordingFragment,
  CollectionFragmentFragment,
  useViewAdHocQuery
} from '~/modules/contentFilter/contentfilter.generated';
import { downloadFromUrl } from '@grain/components/support/browser';
import { css } from '@emotion/react';
import {
  useCollectionAddRecording,
  useCollectionRemoveRecording,
  useCreateCollectionWithRecording
} from '~/modules/collections/hooks';
import { useRecordingCollectionsUpdatedSubscription } from '@grain/api/graphql/subscriptions/subscriptions.generated';
import { cacheUpdateRecordingCollections } from '@grain/api/graphql/cache-helpers';
import { Collection } from '@grain/api/schema.generated';
import { useDeleteRecordingLazy } from '~/modules/recording/hooks';
import { useParams } from 'react-router-dom';

type OverflowOptions =
  | 'copy'
  | 'share'
  | 'add_to_playlist'
  | 'download'
  | 'delete'
  | 'remove_from_playlist';

type MeetingOverflowMenuProps = {
  recording: AdHocRecordingFragment;
  onClickShare: () => void;
  overflowOptions: OverflowOptions[];
};

type Mode = 'default' | 'add_playlist';

const RemoveFromPlaylist = ({ recordingId }: { recordingId: string }) => {
  const { id: collectionId } = useParams();

  const removeRecordingFromCollection = useCollectionRemoveRecording();

  return (
    <>
      <Divider />
      <MenuButton
        textLabelProps={{ startIcon: Icon.Close }}
        label='Remove from playlist'
        onClick={() => removeRecordingFromCollection(collectionId, recordingId)}
      />
    </>
  );
};

export const MeetingOverflowMenu = ({
  recording,
  onClickShare,
  overflowOptions
}: MeetingOverflowMenuProps) => {
  const [search, setSearch] = useState('');
  const [mode, setMode] = useState<Mode>('default');
  const showToast = useShowToast();
  const { trackEvent, getMediaDownloadedTrigger, getMediaSharedTrigger } =
    useAnalytics();
  const addRecordingToPlaylist = useCollectionAddRecording();
  const removeRecordingFromPlaylist = useCollectionRemoveRecording();
  const createCollection = useCreateCollectionWithRecording();
  const deleteRecordingConfirm = useDeleteRecordingLazy();
  const { data, previousData } = useViewAdHocQuery({
    variables: {
      filter: JSON.stringify({
        types: ['collections'],
        filters: [search && { title: search }].filter(Boolean)
      })
    }
  });

  const unselectedPlaylists = useMemo(() => {
    const correctData = data || previousData;
    const list = (correctData?.viewAdHoc?.list ||
      []) as CollectionFragmentFragment[];
    const selectedIds = recording.collections.map(collection => collection.id);

    return list.filter(item => !selectedIds.includes(item.id));
  }, [data, previousData, recording.collections]);

  const handleDownloadRecording = useCallback(() => {
    if (!recording?.downloadUrl) return;

    downloadFromUrl(recording.downloadUrl);

    trackEvent(
      'Media Downloaded',
      {
        trigger: getMediaDownloadedTrigger()
      },
      ['user', 'workspace', `recording:${recording.id}`]
    );
  }, [recording, trackEvent, getMediaDownloadedTrigger]);

  const handleCopyLink = (recordingUrl: string) => {
    copy(recordingUrl)
      .then(() => {
        trackEvent(
          'Media Shared',
          {
            share_type: 'copy_link_recording',
            trigger: getMediaSharedTrigger()
          },
          ['user', 'workspace', `recording:${recording.id}`]
        );
        showToast({
          content: 'Recording link copied',
          type: 'success',
          uniqueId: 'copied_recording_link'
        });
      })
      .catch(() => {
        showToast({
          content: 'Oops, try again.',
          type: 'failure'
        });
      });
  };

  useRecordingCollectionsUpdatedSubscription({
    variables: { id: recording.id },
    onData: ({ data, client }) => {
      cacheUpdateRecordingCollections(client, {
        recordingId: recording.id,
        collections: (data.data?.recordingUpdated?.collections ||
          []) as Partial<Collection>[]
      });
    }
  });

  if (mode === 'default') {
    return (
      <>
        {overflowOptions.includes('copy') && (
          <MenuButton
            textLabelProps={{ startIcon: Icon.CopyLink16x16 }}
            label='Copy link'
            onClick={() => {
              handleCopyLink(recording.recordingUrl);
            }}
          />
        )}

        {overflowOptions.includes('share') && (
          <MenuButton
            textLabelProps={{ startIcon: Icon20.Share }}
            label='Share'
            onClick={onClickShare}
          />
        )}

        {overflowOptions.includes('add_to_playlist') && (
          <BaseMenuButton
            onClick={e => {
              e.stopPropagation();

              setMode('add_playlist');
            }}
          >
            <TextLabel startIcon={Icon20.Playlists}>Add to playlist</TextLabel>
          </BaseMenuButton>
        )}

        {overflowOptions.includes('download') && (
          <MenuButton
            textLabelProps={{ startIcon: Icon20.Download }}
            label='Download'
            onClick={handleDownloadRecording}
          />
        )}

        {overflowOptions.includes('remove_from_playlist') && (
          <RemoveFromPlaylist recordingId={recording.id} />
        )}

        {recording.canDelete && overflowOptions.includes('delete') && (
          <>
            <Divider />
            <MenuButton
              textLabelProps={{ startIcon: Icon20.Delete }}
              label='Delete'
              onClick={() => deleteRecordingConfirm(recording)}
            />
          </>
        )}
      </>
    );
  }

  if (mode === 'add_playlist') {
    return (
      <>
        <Input
          autoFocus
          value={search}
          onClick={e => e.stopPropagation()}
          onChange={e => setSearch(e.target.value)}
          css={css`
            margin-bottom: 4px;
            flex-shrink: 0;
          `}
        />
        <BaseMenuButton
          onClick={() => {
            trackEvent(
              'New Collection Clicked',
              {
                button_name: 'recording_page_new_collection',
                trigger: 'recording_page_new_collection_clicked'
              },
              ['user', 'workspace']
            );
            createCollection(COLLECTIONS_DEFAULT_TITLE, recording.id);
          }}
        >
          <TextLabel
            css={css`
              & svg {
                ${theme.utils.size('20px')}
              }
            `}
            startIcon={Icon.Plus}
          >
            New playlist
          </TextLabel>
        </BaseMenuButton>
        {recording.collections.map(playlist => (
          <MenuButton
            label={playlist.title}
            key={playlist.id}
            checked={true}
            onClick={() => {
              trackEvent(
                'Add to Collection Clicked',
                {
                  button_name: 'meeting_library_multi_select',
                  trigger: 'meeting_library_hamburger_menu'
                },
                ['user', 'workspace']
              );
              removeRecordingFromPlaylist(playlist.id, recording.id);
            }}
          />
        ))}
        {unselectedPlaylists.map(playlist => (
          <MenuButton
            label={playlist.title}
            key={playlist.id}
            checked={false}
            onClick={() => {
              trackEvent(
                'Add to Collection Clicked',
                {
                  button_name: 'meeting_library_multi_select',
                  trigger: 'meeting_library_hamburger_menu'
                },
                ['user', 'workspace']
              );

              addRecordingToPlaylist(playlist.id, recording.id);
            }}
          />
        ))}
      </>
    );
  }

  return <></>;
};
