import { Select, Option } from '@grain/grain-ui/v4';
import { css } from '@emotion/react';
import { SpeakerVideoWhenScreenshare } from '@grain/api/schema.generated';

type SpeakerOverlaySelectorProps = {
  onChangeSetting?: (setting: SpeakerVideoWhenScreenshare) => void;
  disabled?: boolean;
  selectedSetting?: SpeakerVideoWhenScreenshare;
  width?: string;
};

const settingsOptions = [
  {
    title: 'Overlap (show active speaker and screen share)',
    value: 'OVERLAP'
  },
  {
    title: 'Side-by-side (show active speaker and screen share)',
    value: 'BESIDE'
  },
  {
    title: 'Hide (show only screen share)',
    value: 'HIDE'
  }
];

const NOOP = () => {};

export const SpeakerOverlaySelector = ({
  onChangeSetting = NOOP,
  selectedSetting = SpeakerVideoWhenScreenshare.Hide,
  width = '450px',
  disabled = false
}: SpeakerOverlaySelectorProps) => {
  return (
    <div css={css({ width })}>
      <Select
        fullWidth
        matchWidth
        placeholder='Choose one'
        value={selectedSetting}
        disabled={disabled}
        onChange={value => {
          onChangeSetting?.(value);
        }}
      >
        {settingsOptions.map(option => (
          <Option key={option.value} value={option.value}>
            {option.title}
          </Option>
        ))}
      </Select>
    </div>
  );
};
