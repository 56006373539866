import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { storySectionUpdateMutation } from '~/modules/stories/graphql';
import { useMutation } from '@grain/api/graphql';
import { useRecoilState } from 'recoil';
import { Tooltip } from '@grain/grain-ui/v4';
import Dropdown from '@grain/components/Dropdown';
import { storyState } from '~/pages/StoryPage/state';
import { useStoryToast, useDeleteStoryItem } from '~/pages/StoryPage/hooks';
import { Icon } from '@grain/grain-ui';
import {
  getSectionDarkColorByNumber,
  SECTION_COLORS_DARK
} from '~/pages/StoryPage/constants';
import {
  StyledSectionWrapper,
  StyledColorFlag,
  StyledColorPicker,
  StyledTitleInlineInput,
  StyledDescriptionTextarea,
  StyledSectionTitleCharCounter,
  StyledSectionDescriptionCharCounter,
  StyledIconContainer
} from './styles';

const DESCRIPTION_CHAR_LIMIT = 120;
const TITLE_CHAR_LIMIT = 40;

StorySection.propTypes = {
  item: PropTypes.object.isRequired
};

export default function StorySection({ item, ...rest }) {
  const [isDescriptionFocused, setDescriptionFocused] = React.useState(false);
  const [isTitleFocused, setTitleFocused] = React.useState(false);

  const [description, setDescription] = React.useState(item.description);
  const [title, setTitle] = React.useState(item.title);

  const [deleteStoryItem, { loading: deleteStoryItemLoading }] =
    useDeleteStoryItem({ item, trackingLocation: 'document' });

  const showToast = useStoryToast();
  const [story, setStory] = useRecoilState(storyState);
  const prevStoryRef = React.useRef();
  const [updateSection] = useMutation(storySectionUpdateMutation, {
    onError() {
      if (prevStoryRef.current) {
        setStory(prevStoryRef.current);
        prevStoryRef.current = null;
      }
    }
  });

  React.useEffect(() => {
    if (item) setDescription(item.description);
  }, [item]);

  const handleSave = changes => {
    if (
      changes.description?.length > DESCRIPTION_CHAR_LIMIT ||
      changes.title?.length > TITLE_CHAR_LIMIT
    ) {
      return;
    }
    const newItem = { ...item, ...changes };
    if (
      newItem.title === item.title &&
      newItem.description === item.description &&
      newItem.colorNumber === item.colorNumber
    ) {
      return;
    }

    updateSection({
      variables: {
        itemId: newItem.id,
        storyId: story.id,
        section: {
          title: newItem.title,
          colorNumber: newItem.colorNumber,
          description: newItem.description
        }
      }
    });
    prevStoryRef.current = story;
    setStory(story => ({
      ...story,
      items: story.items.map(i => {
        if (item.id !== i.id) return i;
        return newItem;
      })
    }));

    if (changes.colorNumber == null) {
      showToast({
        content: `Section ${changes.title ? 'title' : 'description'} saved`
      });
    }
  };

  return (
    <StyledSectionWrapper {...rest} data-cy='story-doc-section'>
      <StyledColorFlag colorNumber={item.colorNumber} />
      <div className='content-container'>
        <div className='title-container'>
          <StyledTitleInlineInput
            className='fs-block'
            data-cy='section-title'
            key={item.title}
            maxLength={TITLE_CHAR_LIMIT}
            defaultValue={item.title}
            onChange={e => {
              setTitle(e.target.value);
            }}
            onBlur={e => {
              setTitleFocused(false);
              handleSave({ title });
            }}
            onFocus={() => setTitleFocused(true)}
            onKeyDown={e => {
              if (e.key === 'Enter') e.target.blur();
            }}
          />
          <StyledSectionTitleCharCounter
            isMax={title?.length >= TITLE_CHAR_LIMIT}
            visible={isTitleFocused || title?.length > TITLE_CHAR_LIMIT}
          >
            {title?.length}/{TITLE_CHAR_LIMIT}
          </StyledSectionTitleCharCounter>
        </div>
        <div
          className={cx('description-container', description ? '' : 'empty')}
        >
          <StyledDescriptionTextarea
            className='fs-block'
            data-cy='section-description'
            rows='1'
            value={description}
            maxLength={DESCRIPTION_CHAR_LIMIT}
            placeholder='Type a description...'
            onFocus={() => setDescriptionFocused(true)}
            onBlur={() => {
              setDescriptionFocused(false);
              handleSave({ description });
            }}
            onChange={ev => setDescription(ev.target.value)}
            onKeyDown={ev => {
              if (ev.key === 'Enter') {
                ev.preventDefault(); // disable new line
                ev.target.blur();
              }
            }}
          />
          <StyledSectionDescriptionCharCounter
            visible={
              isDescriptionFocused ||
              description?.length > DESCRIPTION_CHAR_LIMIT
            }
            isMax={description?.length >= DESCRIPTION_CHAR_LIMIT}
          >
            {description.length}/{DESCRIPTION_CHAR_LIMIT}
          </StyledSectionDescriptionCharCounter>
        </div>
      </div>
      <div className='actions-container'>
        <Dropdown
          placement='top'
          trigger='click'
          targetElement={
            <StyledIconContainer role='button'>
              <Icon.PaintBucket
                paintColor={getSectionDarkColorByNumber(item.colorNumber)}
              />
            </StyledIconContainer>
          }
        >
          <StyledColorPicker>
            {SECTION_COLORS_DARK.map((color, index) => {
              return (
                <div
                  className={cx(
                    'color',
                    index === item.colorNumber ? 'active' : ''
                  )}
                  key={index}
                  onClick={e => {
                    e.stopPropagation();
                    handleSave({ colorNumber: index });
                  }}
                >
                  <div className='circle' style={{ backgroundColor: color }} />
                </div>
              );
            })}
          </StyledColorPicker>
        </Dropdown>
        <Tooltip
          content='Remove section from story'
          tippyProps={{ delay: [500], placement: 'bottom' }}
        >
          <StyledIconContainer
            disabled={deleteStoryItemLoading}
            role='button'
            onClick={() => deleteStoryItem()}
          >
            <Icon.Delete />
          </StyledIconContainer>
        </Tooltip>
      </div>
    </StyledSectionWrapper>
  );
}
