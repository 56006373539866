import { css } from '@emotion/react';
import styled from '@emotion/styled';
import copy from 'clipboard-copy';
import { Icon, useShowToast } from '@grain/grain-ui';
import {
  Button,
  Divider,
  Icon16,
  Icon20,
  Menu,
  MenuButton,
  Skeleton,
  TextLabel,
  theme,
  Thumbnail
} from '@grain/grain-ui/v4';
import { format } from 'date-fns';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import { CollectionFragmentFragment } from '~/modules/contentFilter/contentfilter.generated';
import { ButtonMetadata } from '~/modules/meetings/ButtonMetadata';
import { useDeleteCollection } from '~/modules/collections/hooks';

const Container = styled.article`
  ${theme.utils.py('14px')}
  ${theme.utils.px('3xl')}
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto auto;
  flex-shrink: 0;
  gap: ${theme.tokens.spacing.md};
  cursor: pointer;

  &:hover {
    background-color: ${theme.tokens.color.surfaceSecondary};
  }
`;

const Title = styled.h3`
  ${theme.tokens.typography.b3[500]};
  color: ${theme.tokens.color.textPrimary};
  padding: 0;
  margin: 0 0 ${theme.tokens.spacing['2xs']} 0;
`;

const Meta = styled.div`
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textTertiary};
`;

type PlaylistListItemProps = {
  playlist: CollectionFragmentFragment;
  onClickShare: () => void;
};

export const PlaylistListItem = ({
  playlist,
  onClickShare
}: PlaylistListItemProps) => {
  const deleteCollection = useDeleteCollection();
  const showToast = useShowToast();

  return (
    <Container>
      <Link
        to={playlist.collectionPath}
        css={css`
          display: contents;
        `}
      >
        <Thumbnail icon={Icon.Playlists} css={['height: 36px;']} />

        <div
          css={css`
            overflow: hidden;
            white-space: nowrap;
          `}
        >
          <Title
            css={css`
              text-overflow: ellipsis;
              overflow: hidden;
            `}
            title={playlist.title}
          >
            {playlist.title}
          </Title>
          <Meta>Created {format(new Date(playlist.insertedAt), 'MMM d')}</Meta>
        </div>
      </Link>

      <div
        css={css`
          display: flex;
          align-items: center;
          gap: ${theme.tokens.spacing.sm};
        `}
      >
        {playlist.creator && (
          <ButtonMetadata width='lg' disableInteractive>
            <TextLabel
              avatarProps={{
                name: playlist.creator.name,
                url: playlist.creator.avatarUrl || '',
                color: playlist.creator.hexColor
              }}
              size='sm'
            >
              {playlist.creator.name}
            </TextLabel>
          </ButtonMetadata>
        )}

        <ButtonMetadata width='md' disableInteractive>
          <TextLabel size='sm' startIcon={Icon16.Record}>
            {playlist.recordingCount}{' '}
            {pluralize('meetings', playlist.recordingCount)}
          </TextLabel>
        </ButtonMetadata>
      </div>
      <Menu
        width='200px'
        content={
          <>
            <MenuButton
              textLabelProps={{ startIcon: Icon.CopyLink }}
              label='Copy link'
              onClick={() => {
                copy(playlist.collectionUrl);
                showToast({
                  type: 'success',
                  content: 'Playlist link copied.'
                });
              }}
            />
            <MenuButton
              textLabelProps={{ startIcon: Icon20.Share }}
              label='Share'
              onClick={onClickShare}
            />
            <Divider css={[theme.utils.my('md')]} />
            <MenuButton
              textLabelProps={{ startIcon: Icon20.Delete }}
              label='Delete playlist'
              onClick={() => deleteCollection(playlist)}
            />
          </>
        }
      >
        <Button variant='ghost' size='md' icon={Icon16.Overflow} />
      </Menu>
    </Container>
  );
};

PlaylistListItem.Skeleton = () => (
  <Container>
    <Skeleton width={64} height={36} />
    <Skeleton width={200} height={16} />
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 24px;
      `}
    >
      <Skeleton width={140} height={16} />
      <Skeleton width={140} height={16} />
    </div>
    <Skeleton width={28} height={16} />
  </Container>
);
