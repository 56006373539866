import styled from '@emotion/styled';
import { Skeleton, theme } from '@grain/grain-ui/v4';

const SkeletonWrapper = styled.div`
  border: 1px solid ${theme.tokens.color.borderTertiary};
  border-radius: ${theme.tokens.radii.xl};
  display: flex;
  flex-direction: column;
  padding: ${theme.tokens.spacing['2xl']};
`;

const SkeletonHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${theme.tokens.spacing.lg};
`;

const SkeletonContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const SkeletonDimension = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.tokens.spacing.lg};
  border-top: 1px solid ${theme.tokens.color.borderTertiary};
  ${theme.utils.py('18px')}

  &:first-child {
    border-top: none;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const DealDiagnosisResultsSkeleton = () => (
  <SkeletonWrapper>
    <SkeletonHeader>
      <Skeleton width={60} height={18} />
    </SkeletonHeader>
    <SkeletonContent>
      {[1, 2, 3, 4, 5].map(key => (
        <SkeletonDimension key={key}>
          <Skeleton width={28} height={28} />
          <Skeleton width={112} height={16} css={{ marginRight: 8 }} />
          <Skeleton width='100%' height={16} />
        </SkeletonDimension>
      ))}
    </SkeletonContent>
  </SkeletonWrapper>
);
