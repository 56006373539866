import React from 'react';
import { useRecoilValue } from 'recoil';
import { scrollIntoViewIfNeeded } from '@grain/components/support/browser';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from '@grain/components/Button';
import {
  useStoryReorderDrag,
  useAddSection,
  useStoryItemsState
} from '../hooks';
import { Icon, useShowToast } from '@grain/grain-ui';
import { storyState } from '../state';
import { StyledPlaceholder } from '~/pages/StoryPage/Outline/styles';
import { spacing } from '@grain/styles/constants';
import { useHotspots } from '~/components/Hotspot';
import AddHighlightModal from './AddHighlight';
import Title, { getStoryTitleEl } from './Title';
import Footer from './Footer';
import StoryItemWrapper from './StoryItemWrapper/index';
import Highlight from '@grain/components/Highlight';
import StorySection from './Section';

import {
  StyledDocumentWrapper,
  StyledButtonContainer,
  StyledEmptySpaceFill
} from './styles';
import { useClipEditMutation } from '@grain/desktop-lib';

const getStoryItemId = index => `story-item-${index}`;

export const getStoryItemEl = index =>
  document.getElementById(getStoryItemId(index));

export const getStoryScrollerEl = () =>
  document.getElementById('story-scroller');

export const scrollItemIntoView = index => {
  const itemEl = index === -1 ? getStoryTitleEl() : getStoryItemEl(index);
  scrollIntoViewIfNeeded(itemEl, {
    boundary: getStoryScrollerEl()
  });
};

export default function StoryDocument(props) {
  const containerRef = React.useRef();
  const story = useRecoilValue(storyState);
  const [showAddHighlight, setShowAddHighlight] = React.useState(false);
  const [hotspotRef] = useHotspots('story-ftux', 'add-highlight', {
    top: -4,
    left: 65
  });

  const [clipEdit] = useClipEditMutation();
  const showToast = useShowToast();
  const editClip = React.useCallback(
    async ({ clipId, text }) => {
      await clipEdit({
        variables: {
          clipId,
          text,
          eventProperties: JSON.stringify({
            tag_trigger: 'story_edit'
          })
        }
      });

      showToast({
        content: 'Clip updated',
        type: 'success'
      });
    },
    [clipEdit, showToast]
  );

  const [addSection, addSectionRes] = useAddSection({
    onCompleted() {
      // Wait for new item to render...
      setTimeout(() => {
        scrollItemIntoView(story.items.length - 1);
      }, 30);
    }
  });

  const [storyItemsCopy, setStoryItemsCopy] = useStoryItemsState(story.items);
  const {
    draggingItemId,
    moveItemRes,
    handleDragStart,
    handleDragUpdate,
    handleDragEnd,
    placeholderStyle
  } = useStoryReorderDrag({ containerRef, setState: setStoryItemsCopy });

  const isEmpty = story?.items?.length === 0;

  return (
    <>
      <StyledDocumentWrapper {...props} ref={containerRef}>
        <Title />
        <DragDropContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragUpdate={handleDragUpdate}
          isDragDisabled={moveItemRes.loading}
        >
          <Droppable droppableId='outline'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  cursor: snapshot.isDragging ? 'grabbing' : '',
                  position: 'relative',
                  paddingTop: '32px'
                }}
              >
                {storyItemsCopy.map((item, index) => (
                  <Draggable draggableId={item.id} index={index} key={item.id}>
                    {(provided, snapshot) => {
                      const highlighted =
                        snapshot.isDragging || snapshot.isDropAnimating;
                      return (
                        <StoryItemWrapper
                          item={item}
                          key={item.id}
                          handleProps={provided.dragHandleProps}
                          highlighted={highlighted}
                          disableHover={
                            snapshot.isDragging && draggingItemId !== item.id
                          }
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          {item.__typename === 'StoryClipV2' ? (
                            <Highlight
                              clip={item.clip}
                              isEditable={true}
                              editClip={editClip}
                              isTimestampVisible={false}
                              id={getStoryItemId(index)}
                              context='story'
                              storyItem={item}
                              isInlinePlay
                            />
                          ) : (
                            <StorySection
                              item={item}
                              id={getStoryItemId(index)}
                            />
                          )}
                        </StoryItemWrapper>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
                {!!placeholderStyle?.height && snapshot.isDraggingOver && (
                  <StyledPlaceholder
                    css={['border-radius: 12px;']}
                    style={placeholderStyle}
                  />
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* This component serves two purposes: */}
        {/* - show empty state message AND/OR */}
        {/* - take up space to push buttons to the bottom of the document */}
        <StyledEmptySpaceFill>
          {isEmpty && 'This story is currently empty.'}
        </StyledEmptySpaceFill>
        <StyledButtonContainer>
          <Button
            css={['height: 32px; width: 160px;', spacing.mr4]}
            disabled={addSectionRes.loading}
            onClick={addSection}
          >
            <Icon.DeprecatedPlus css={['margin-right: 8px;']} /> Add new section
          </Button>
          <Button
            ref={hotspotRef}
            css={['height: 32px; width: 170px;;']}
            onClick={() => setShowAddHighlight(true)}
          >
            <Icon.DeprecatedPlus css={['margin-right: 8px;']} /> Add new clip
          </Button>
        </StyledButtonContainer>
        <Footer />
      </StyledDocumentWrapper>
      {showAddHighlight && (
        <AddHighlightModal onCancel={() => setShowAddHighlight(false)} />
      )}
    </>
  );
}
