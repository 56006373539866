import * as Types from '@grain/api/schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@grain/api/graphql';
const defaultOptions = {} as const;
export type WorkspaceUploadInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type WorkspaceUploadInfoQuery = { __typename?: 'RootQueryType', workspaceUploadInfo: { __typename?: 'ImageUploadInfo', maxUploadBytes: number, url: { __typename?: 'UploadUrl', uuid: string, url: string } | null } };


export const WorkspaceUploadInfoDocument = gql`
    query workspaceUploadInfo {
  workspaceUploadInfo {
    url {
      uuid
      url
    }
    maxUploadBytes
  }
}
    `;

/**
 * __useWorkspaceUploadInfoQuery__
 *
 * To run a query within a React component, call `useWorkspaceUploadInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceUploadInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceUploadInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceUploadInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WorkspaceUploadInfoQuery, WorkspaceUploadInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<WorkspaceUploadInfoQuery, WorkspaceUploadInfoQueryVariables>(WorkspaceUploadInfoDocument, options);
      }
export function useWorkspaceUploadInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WorkspaceUploadInfoQuery, WorkspaceUploadInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<WorkspaceUploadInfoQuery, WorkspaceUploadInfoQueryVariables>(WorkspaceUploadInfoDocument, options);
        }
export function useWorkspaceUploadInfoSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<WorkspaceUploadInfoQuery, WorkspaceUploadInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<WorkspaceUploadInfoQuery, WorkspaceUploadInfoQueryVariables>(WorkspaceUploadInfoDocument, options);
        }
export type WorkspaceUploadInfoQueryHookResult = ReturnType<typeof useWorkspaceUploadInfoQuery>;
export type WorkspaceUploadInfoLazyQueryHookResult = ReturnType<typeof useWorkspaceUploadInfoLazyQuery>;
export type WorkspaceUploadInfoSuspenseQueryHookResult = ReturnType<typeof useWorkspaceUploadInfoSuspenseQuery>;
export type WorkspaceUploadInfoQueryResult = Apollo.QueryResult<WorkspaceUploadInfoQuery, WorkspaceUploadInfoQueryVariables>;