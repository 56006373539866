import cx from 'classnames';
import styled from '@emotion/styled';
import { useCallback, useEffect, useState } from 'react';
import { getBasePublicPath } from '@grain/grain-ui';

import type { ExitFunction, Slide } from '../types';

const DESKTOP_APP_SLIDE_WIDTH = 1140;
const DESKTOP_APP_SLIDE_HEIGHT = 628;

const DesktopAppSlideGraphicContainer = styled.div`
  display: flex; // Fix height
  position: relative;
  overflow: hidden;
  width: 100%;

  > img {
    width: 100%;
    height: auto;
    aspect-ratio: ${DESKTOP_APP_SLIDE_WIDTH} / ${DESKTOP_APP_SLIDE_HEIGHT};
  }
`;

const NOTIFICATION_WIDTH = 520;
const NOTIFICATION_HEIGHT = 216;

const DesktopAppSlideNotification = styled.div`
  display: flex; // Fix height
  position: absolute;
  top: ${(47 / DESKTOP_APP_SLIDE_HEIGHT) * 100}%;
  right: ${(8 / DESKTOP_APP_SLIDE_WIDTH) * 100}%;
  width: ${(520 / DESKTOP_APP_SLIDE_WIDTH) * 100}%;

  > img {
    width: 100%;
    height: auto;
    aspect-ratio: ${NOTIFICATION_WIDTH} / ${NOTIFICATION_HEIGHT};
  }

  // Like "ease-out", but slide in faster
  transition: transform cubic-bezier(0.05, 0, 0.25, 1) 0.8s;
  transform: translateX(0);

  &.offscreen {
    transform: translateX(125%);
  }
`;

const DesktopAppSlideRecordToggle = styled.label`
  input[type='checkbox'] {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    overflow: hidden;
    white-space: nowrap;
  }

  // Use 2 different elements to represent off/on states so we can crossfade
  // between the states.

  input[type='checkbox'] ~ .fake-checkbox-off,
  input[type='checkbox'] ~ .fake-checkbox-on {
    position: absolute;
    bottom: ${(28 / NOTIFICATION_HEIGHT) * 100}%;
    left: ${(30 / NOTIFICATION_WIDTH) * 100}%;
    width: ${(124 / NOTIFICATION_WIDTH) * 100}%;
    height: ${(36 / NOTIFICATION_HEIGHT) * 100}%;
    background-size: cover;
    transition: opacity linear 0.3s;
  }

  input[type='checkbox'] ~ .fake-checkbox-off {
    background-image: url('${getBasePublicPath()}/images/ftux-slideshow/record-off.svg');
    opacity: 1;
  }

  input[type='checkbox'] ~ .fake-checkbox-on {
    background-image: url('${getBasePublicPath()}/images/ftux-slideshow/record-on.svg');
    opacity: 0;
  }

  input[type='checkbox']:hover ~ .fake-checkbox-off,
  input[type='checkbox']:focus-visible ~ .fake-checkbox-off {
    background-image: url('${getBasePublicPath()}/images/ftux-slideshow/record-off-hover.svg');
    cursor: pointer;
  }

  input[type='checkbox']:checked ~ .fake-checkbox-off {
    opacity: 0;
  }

  input[type='checkbox']:checked ~ .fake-checkbox-on {
    opacity: 1;
  }

  input[type='checkbox']:checked:hover ~ .fake-checkbox-on,
  input[type='checkbox']:checked:focus-visible ~ .fake-checkbox-on {
    background-image: url('${getBasePublicPath()}/images/ftux-slideshow/record-on-hover.svg');
    cursor: pointer;
  }

  // Preload alternate checkbox states to avoid flickering immediately upon
  // triggering those states.
  .fake-checkbox-preload-1,
  .fake-checkbox-preload-2 {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    overflow: hidden;
    white-space: nowrap;
  }
  .fake-checkbox-preload-1 {
    background-image: url('${getBasePublicPath()}/images/ftux-slideshow/record-off-hover.svg');
  }
  .fake-checkbox-preload-2 {
    background-image: url('${getBasePublicPath()}/images/ftux-slideshow/record-on-hover.svg');
  }
`;

export const DesktopAppSlide: Slide = {
  question: 'Why use the desktop app?',
  answer:
    'Grain notifies you when a meeting starts.\nToggle to record if auto-record is off.',
  Graphic({ transitionDelay = 0, exitFunctionRef, nextSlide }) {
    const [offscreen, setOffscreen] = useState(true);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
      setTimeout(() => setOffscreen(false), transitionDelay + 500);
      // This effect should only run once, on the first render.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkAndExit: ExitFunction = useCallback(() => {
      setChecked(true);
      return new Promise(resolve => {
        setTimeout(() => {
          setOffscreen(true);
          setTimeout(() => resolve(), 800);
        }, 800);
      });
    }, []);

    exitFunctionRef.current = checkAndExit;

    return (
      <DesktopAppSlideGraphicContainer>
        <img
          alt='A macOS desktop with the Grain desktop app installed'
          src={`${getBasePublicPath()}/images/ftux-slideshow/desktop-with-grain-app.jpg`}
        />
        <DesktopAppSlideNotification className={cx({ offscreen })}>
          <img
            alt='A Grain desktop app notification, appearing over a macOS desktop'
            src={`${getBasePublicPath()}/images/ftux-slideshow/desktop-notification.svg`}
          />
          <DesktopAppSlideRecordToggle>
            <input type='checkbox' checked={checked} onChange={nextSlide} />
            <div className='fake-checkbox-off' />
            <div className='fake-checkbox-on' />
            <div className='fake-checkbox-preload-1' />
            <div className='fake-checkbox-preload-2' />
          </DesktopAppSlideRecordToggle>
        </DesktopAppSlideNotification>
      </DesktopAppSlideGraphicContainer>
    );
  }
};
