// @ts-strict-ignore
import { IntelligenceTemplateTypedSection } from '@grain/api/schema.generated';
import { useState } from 'react';

type AddSectionModalMode = 'add' | 'edit';

export const useAddSectionModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState<AddSectionModalMode>('add');
  const [editSection, setEditSection] =
    useState<IntelligenceTemplateTypedSection>(null);

  const toggleModal = (
    state: boolean,
    section?: IntelligenceTemplateTypedSection
  ) => {
    setIsOpen(state);
    setEditSection(section);
    setMode(section ? 'edit' : 'add');
  };

  return {
    isOpen,
    mode,
    editSection,
    toggleModal
  };
};
