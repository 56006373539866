import {
  StyledCard,
  StyledCardHeaderText,
  StyledCardWrapper,
  StyledCardSubHeaderText,
  StyledSkip
} from '../styles';
import { StyledConnect } from './styles';
import {
  getOauthRedirectUrl,
  Icon,
  color,
  media,
  spacing
} from '@grain/grain-ui';
import { StepComponentProps } from '..';

const SelectMeetingPlace = ({ getNextHref, onNext }: StepComponentProps) => {
  const next = getNextHref();
  return (
    <StyledCardWrapper>
      <StyledCard
        css={[
          'width: 451px;',
          `${media.small} { max-width: 100%; ${spacing.px4} }`
        ]}
      >
        <StyledCardHeaderText>Connect your Zoom</StyledCardHeaderText>
        <StyledCardSubHeaderText>
          Grain needs permission to automatically join and record your Zoom
          meetings
        </StyledCardSubHeaderText>

        <div className='content-wrapper'>
          <StyledConnect
            as='a'
            href={getOauthRedirectUrl(
              'zoom',
              true,
              'connect_account',
              {},
              next
            )}
            css={[
              'margin-bottom: 16px; background-color: #0E71EB',
              color.fg.swan
            ]}
            data-track='Button Clicked'
            data-track-args={JSON.stringify({
              button_name: 'connect_zoom',
              category: 'integrations',
              trigger: 'onboarding_import_start',
              button_text: 'Connect Zoom Account'
            })}
          >
            <span className='icon'>
              <Icon.ZoomLogoSecondary width='20px' />
            </span>{' '}
            Connect Zoom account
          </StyledConnect>

          <StyledConnect
            as='a'
            href={next}
            css={['gap: 4px;']}
            data-track='Button Clicked'
            data-track-args={JSON.stringify({
              button_name: 'meet_or_teams',
              category: 'integrations',
              trigger: 'onboarding_import_start',
              button_text: 'I use Meet or Teams'
            })}
          >
            <span>I use</span>
            <span className='icon'>
              <Icon.Meet css={['width: 14.667px;']} />
            </span>
            <span>Meet or</span>
            <span className='icon'>
              <Icon.Teams css={['width: 13.467px;']} />
            </span>
            <span>Teams</span>
          </StyledConnect>
        </div>
        <StyledSkip onClick={onNext}>Skip connection</StyledSkip>
      </StyledCard>
    </StyledCardWrapper>
  );
};

export default SelectMeetingPlace;
