import styled from '@emotion/styled';
import { Skeleton, color } from '@grain/grain-ui';

const StyledListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 18px 32px 18px 48px;
  border-bottom: 1px solid ${color.gull};
`;

export function SkeletonRecordingListItem() {
  return (
    <StyledListItem>
      <Skeleton width='250px' height='20px' />
      <Skeleton width='150px' height='20px' />
      <Skeleton width='150px' height='20px' />
      <Skeleton width='150px' height='20px' />
    </StyledListItem>
  );
}
