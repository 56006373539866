import styled from '@emotion/styled';
import {
  type Deal,
  type Group,
  type Workspace
} from '@grain/api/schema.generated';
import { Skeleton } from '@grain/grain-ui';
import { Avatar, theme } from '@grain/grain-ui/v4';

import {
  countHours,
  formatTimeAgoWithWeekdays,
  formatWaitingTime
} from '../../utils';
import { StyledDetailsTable } from './DetailsTable';

const StyledAvatarLabel = styled.div`
  display: flex;
  gap: ${theme.tokens.spacing.xs};

  > * {
    flex-shrink: 0;
  }
`;

const StyledWaiting = styled.td`
  color: ${theme.tokens.color.textDanger};
`;

type LastActiveTableProps = Pick<
  Deal,
  'closedAt' | 'latestCustomerActivity' | 'latestTeamActivity'
> & {
  group: Pick<Group, 'name'>;
  workspace: Workspace;
};

export function LastActiveTable({
  workspace,
  group,
  closedAt,
  latestCustomerActivity,
  latestTeamActivity
}: LastActiveTableProps) {
  const lastActivities: {
    id: string;
    avatarUrl?: string;
    name: string;
    date?: Date;
  }[] = [
    {
      id: 'customer',
      avatarUrl: undefined,
      name: group.name,
      date: latestCustomerActivity
        ? new Date(latestCustomerActivity)
        : undefined
    },
    {
      id: 'team',
      avatarUrl: workspace.logoUrl ?? undefined,
      name: workspace.name,
      date: latestTeamActivity ? new Date(latestTeamActivity) : undefined
    }
  ];
  const waitingSince = (() => {
    if (closedAt || !latestCustomerActivity) return;
    const customerActivityDate = new Date(latestCustomerActivity);
    if (!latestTeamActivity) return customerActivityDate;
    const teamActivityDate = new Date(latestTeamActivity);
    return customerActivityDate > teamActivityDate
      ? customerActivityDate
      : undefined;
  })();
  const showWaiting = Boolean(waitingSince && countHours(waitingSince) >= 36);
  return (
    <StyledDetailsTable>
      <thead>
        <tr>
          <th colSpan={2}>Last Active</th>
          {showWaiting && <th>Waiting</th>}
        </tr>
      </thead>
      <tbody>
        {lastActivities.map(({ id, avatarUrl, name, date }) => (
          <tr key={id}>
            <td>
              <StyledAvatarLabel>
                <Avatar
                  size='sm'
                  name={name}
                  url={avatarUrl}
                  variant='square'
                />
                <span>{name}</span>
              </StyledAvatarLabel>
            </td>
            <td className='value'>
              {date ? formatTimeAgoWithWeekdays(date) : 'Never'}
            </td>
            {waitingSince &&
              showWaiting &&
              (id === 'customer' ? (
                <StyledWaiting>{formatWaitingTime(waitingSince)}</StyledWaiting>
              ) : (
                <td>-</td>
              ))}
          </tr>
        ))}
      </tbody>
    </StyledDetailsTable>
  );
}

export function SkeletonLastActiveTable() {
  return (
    <StyledDetailsTable>
      <thead>
        <tr>
          <th>
            <Skeleton
              width={80}
              height={16}
              css={[`border-radius: ${theme.tokens.radii.md};`]}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {Array(2)
          .fill(0)
          .map((_, i) => (
            <tr key={i}>
              <td>
                <Skeleton
                  width={80}
                  height={16}
                  css={[`border-radius: ${theme.tokens.radii.md};`]}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </StyledDetailsTable>
  );
}
