import styled from '@emotion/styled';
import { theme, Tooltip, Avatar, MenuButton } from '@grain/grain-ui/v4';
import { useMemo } from 'react';
import { formatTime } from './utils';
import { css } from '@emotion/react';
import { AugmentedCitation } from '../types';
import { useRecordingPage } from '~/pages/RecordingPage/context';
import { useSearchParams } from 'react-router-dom';
import { useAnalytics } from 'lib/Analytics';

const EMPTY_ARRAY: never[] = [];
const DEFAULT_SPEAKER = ['Unknown speaker'];

export type CitationButtonProps = {
  citationId: string;
  citations?: AugmentedCitation[] | null;
};

const StyledMenuButton = styled(MenuButton)`
  max-width: 320px;
  text-align: start;
`;

const AvatarContainer = styled.div`
  margin: 0 2px;
  display: inline-flex;
  vertical-align: text-bottom;
  cursor: pointer;
`;

export const CitationButton = ({
  citationId,
  citations = EMPTY_ARRAY
}: CitationButtonProps) => {
  const { trackEvent } = useAnalytics();
  const { videoContextRef } = useRecordingPage();
  const [_, setParams] = useSearchParams();

  const citation = useMemo(
    () => citations?.find(c => c.citationId === citationId),
    [citations, citationId]
  );

  if (!citation) {
    return <>[{citationId}]</>;
  }

  const startTime = formatTime(citation.startMs);
  const speakers = citation.speakers || DEFAULT_SPEAKER;

  const tooltipContent = (
    <StyledMenuButton
      onClick={() => {
        trackEvent('Button Clicked', {
          button_name: 'ask_citation_timestamp',
          trigger: 'ask_recording'
        });
        videoContextRef.current?.api?.setCurrentTime(citation.startMs / 1000);
        setParams(prev => {
          prev.set('tab', 'transcript');
          prev.set('t', citation.startMs.toString());
          return prev;
        });
      }}
      label={speakers.join(', ')}
      caption={citation.citedText}
      captionProps={{
        css: css`
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        `,
        hasStartIcon: true
      }}
      textLabelProps={{
        metadata: startTime,
        startIcon: () => (
          <Avatar
            css={{
              border: `1px solid ${theme.tokens.color.borderSecondary}`
            }}
            size='sm'
            variant='round'
            color={theme.tokens.color.surfacePrimary}
            textColor={theme.tokens.color.textPrimary}
            name={citationId}
            fallbackText={citationId}
          />
        ),
        size: 'sm'
      }}
    />
  );

  return (
    <Tooltip
      content={tooltipContent}
      containerCss={css`
        padding: 3px;
      `}
      tippyProps={{
        interactive: true,
        appendTo: () => document.body,
        onShow: () => {
          trackEvent('Button Hovered', {
            button_name: 'ask_citation',
            trigger: 'ask_recording'
          });
        }
      }}
    >
      <AvatarContainer>
        <Avatar
          size='sm'
          variant='round'
          color={theme.tokens.color.surfaceTertiary}
          textColor={theme.tokens.color.textPrimary}
          name={citationId}
          fallbackText={citationId}
        />
      </AvatarContainer>
    </Tooltip>
  );
};
