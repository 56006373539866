import React, { useEffect, useRef } from 'react';
import { useMyself, useWorkspace } from '@grain/api/auth';
import { useMutation } from '@grain/api/graphql';
import { myselfOnboardingTriggerDismissMutation } from '@grain/api/graphql/mutations';
import { useShowToast, useAnalytics } from '@grain/grain-ui';
import { css } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import { getIsOnPaidPlan } from '~/support/getIsOnPaidPlan';
import { Icon16, theme } from '@grain/grain-ui/v4';

type OnboardingToasterTypes =
  | 'nth_user_added'
  | 'sales_template_used'
  | 'hubspot_connected';

const sharedProps = {
  type: 'content',
  durationMs: 0,
  showCloseButton: true,
  uniqueId: 'content',
  animateEnter: 'right',
  animateLeave: 'right',
  action: 'Schedule a call',
  actionButtonVariant: 'primary',
  body: 'Book a session with a Grain expert' as string,
  css: css`
    & .body-text {
      max-width: 211px;
    }
  `
} as const;

type ToastProps = typeof sharedProps & {
  title: string;
  icon: React.ReactNode;
  onAction: () => void;
};

const toasterProps = (
  isOnPaidPlan: boolean
): Record<OnboardingToasterTypes, ToastProps> => {
  const base_url = `https://calendly.com/${isOnPaidPlan ? 'zachary-liem' : 'grainhq'}/demo`;
  return {
    nth_user_added: {
      ...sharedProps,
      title: 'Book a session with a Grain expert',
      body: 'Your workspace is growing; get the most out of Grain for your team.',
      icon: <Icon16.Users css={{ color: theme.tokens.color.iconBrand }} />,
      onAction: () =>
        window.open(
          `${base_url}?utm_source=product%20(teammates)`,
          '_blank',
          'noopener'
        )
    },
    sales_template_used: {
      ...sharedProps,
      title: 'Learn how to automate your sales process with Grain.',
      icon: <Icon16.BankNote css={{ color: theme.tokens.color.iconBrand }} />,
      onAction: () =>
        window.open(
          `${base_url}?utm_source=product%20(template)`,
          '_blank',
          'noopener'
        )
    },
    hubspot_connected: {
      ...sharedProps,
      title: 'Learn how to automate your sales process.',
      icon: <Icon16.BankNote css={{ color: theme.tokens.color.iconBrand }} />,
      onAction: () =>
        window.open(
          `${base_url}?utm_source=product%20(CRM)`,
          '_blank',
          'noopener'
        )
    }
  };
};

const offerTriggerMap = {
  nth_user_added: '5 people in workspace',
  sales_template_used: 'Sales Discovery',
  hubspot_connected: 'Hubspot'
} as const;

const isValidTriggerId = (
  triggerId: string
): triggerId is OnboardingToasterTypes => triggerId in toasterProps(false);

export const useOnboardingToast = () => {
  const location = useLocation();
  const { myself } = useMyself();
  const onboardingTrigger = myself?.onboardingTrigger;
  const { workspace } = useWorkspace();
  const isOnPaidPlan = getIsOnPaidPlan(workspace);
  const originalShowToast = useShowToast();
  const showToastRef = useRef(originalShowToast);
  const { trackEvent } = useAnalytics();
  const [sendDismissMutation] = useMutation(
    myselfOnboardingTriggerDismissMutation
  );
  const triggerType = onboardingTrigger?.type;
  const isOnboarding = location.pathname.startsWith('/app/onboarding');

  // Small hack to use a mutable ref since showing a toast creates a new instance of useShowToast
  // causes infinite rerenders.
  useEffect(() => {
    showToastRef.current = originalShowToast;
  }, [originalShowToast, showToastRef]);

  useEffect(() => {
    const showToast = showToastRef.current;
    if (triggerType && isValidTriggerId(triggerType) && !isOnboarding) {
      const toastProps = toasterProps(isOnPaidPlan)[triggerType];
      const actionProps = {
        'data-track': 'Button Clicked',
        'data-track-args': JSON.stringify({
          button_name: 'onboarding_offer_scheduled',
          trigger: triggerType,
          button_text: 'Schedule a call'
        })
      };

      showToast({
        ...toastProps,
        actionProps,
        onClose: () => sendDismissMutation()
      });
      trackEvent('Onboarding Offer Viewed', {
        trigger: offerTriggerMap[triggerType]
      });
    }
  }, [
    triggerType,
    showToastRef,
    trackEvent,
    sendDismissMutation,
    isOnPaidPlan,
    isOnboarding
  ]);
};
