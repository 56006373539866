import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TextLabel, theme } from '@grain/grain-ui/v4';

export const StyledContainer = styled.div`
  .box {
    width: 20px !important;
    height: 20px !important;
  }
`;

export const StyledMembersContainer = styled.div`
  ${theme.utils.padding('3xl', '3xl', 'lg', '3xl')};
  border-bottom: 1px solid ${theme.tokens.color.borderTertiary};

  div {
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const StyledMembersTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: ${theme.tokens.spacing.sm};
  margin-bottom: ${theme.tokens.spacing['2xl']};
`;

export const StyledMembersHeading = styled.div`
  ${theme.tokens.typography.h4};
`;

export const StyledUserCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.tokens.spacing['2xl']};
  > div {
    padding: 0;
  }
  .switch-container {
    padding: ${theme.tokens.spacing.sm};
  }
`;

export const StyledSelectAllWrapper = styled.div`
  ${theme.tokens.typography.b2['400']};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.tokens.spacing.sm};
`;

export const StyledIntervalText = styled.div`
  ${theme.tokens.typography.b2['700']};
  margin-top: ${theme.tokens.spacing['2xl']};
  margin-bottom: ${theme.tokens.spacing.xs};
  padding-left: ${theme.tokens.spacing['3xl']};
`;

export const StyledIntervalNote = styled.div`
  ${theme.tokens.typography.b4['500']};
  margin-bottom: ${theme.tokens.spacing.lg};
  padding-left: ${theme.tokens.spacing['3xl']};
  color: ${theme.tokens.color.textSecondary};
`;

export const StyledFooterContainer = styled.div`
  margin-top: ${theme.tokens.spacing['2xl']};
  padding-right: ${theme.tokens.spacing['3xl']};
  padding-left: ${theme.tokens.spacing['3xl']};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledSelectWrapper = styled.div<{
  checked: boolean;
  disabled: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.tokens.spacing['2xs']};
  padding: ${theme.tokens.spacing.lg};
  flex: 1 0 0;
  border-radius: ${theme.tokens.radii.lg};
  border: ${({ checked }) =>
    checked
      ? `1px solid ${theme.tokens.color.borderActive}`
      : `1px solid ${theme.tokens.color.borderTertiary}`};
  cursor: pointer;
  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? 'none' : theme.tokens.color.surfaceTertiary};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      border: 1px solid ${theme.tokens.color.borderSecondary};
      color: ${theme.tokens.color.textSecondary};
    `}
`;

export const StyledRadioLabel = styled(TextLabel)`
  margin-left: ${theme.tokens.spacing.sm};
  span:first-of-type {
    font-weight: 600;
  }
`;

export const StyledPriceText = styled.div`
  padding: 0px 10px 0px 28px;
  ${theme.tokens.typography.b2['400']};
`;

export const StyledBillingSelectWrapper = styled.div`
  display: flex;
  gap: ${theme.tokens.spacing.lg};
  margin-left: ${theme.tokens.spacing['3xl']};
  margin-right: ${theme.tokens.spacing['3xl']};
  margin-bottom: ${theme.tokens.spacing['3xl']};
`;
