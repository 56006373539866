import React from 'react';
import { Modal, useRouteModal } from '@grain/grain-ui';
import { HUBSPOT_CONNECT_ROUTE_ID } from '@grain/components/modals/constants';
import HubspotReconnect from '~/pages/Onboarding/HubspotReconnect/HubspotReconnect';

export default function HubspotReconnectModal() {
  const { close } = useRouteModal(HUBSPOT_CONNECT_ROUTE_ID);

  return (
    <Modal
      width={'auto'}
      closable={false}
      onCancel={close}
      css={['background-color: transparent !important;']}
    >
      <HubspotReconnect />
    </Modal>
  );
}
