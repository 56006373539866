import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { LOGIN_URL, BASE_URL } from 'desktop/lib/constants';
import { useAuth } from '@grain/api/auth';
import { app } from '@todesktop/client-core';
import { api } from 'desktop/lib/api';
import { log, openUrlInBrowser } from 'desktop/lib/utils';
import uniqid from 'uniqid';

interface AuthHookOptions {
  onLogin?: () => void;
}

interface AuthHookProps {
  handleLogin: () => void;
  handleSignup: () => void;
}

const ONE_YEAR = 365 * 24 * 60 * 60;

export const useDesktopAuth = ({
  onLogin
}: AuthHookOptions = {}): AuthHookProps => {
  const [loginStateId, setLoginStateId] = useState<string | undefined>();
  const { setIsAuthenticated } = useAuth();

  useEffect(() => {
    const cookies = new Cookies();

    const unsubscribePromise = app.on('openProtocolURL', handleOpenProtocolUrl);
    const unsubscribeOpenProtocolUrl = () =>
      unsubscribePromise.then(unsubscribe => unsubscribe());

    function handleOpenProtocolUrl(
      _: null,
      event: { url: string; preventDefault: () => void }
    ) {
      event.preventDefault();
      const url = event.url;
      const params = new URLSearchParams(new URL(url).search);
      log.info('open-protocol-url', event);

      if (params && params.get('state') === loginStateId) {
        api
          .post('/exchange_code', {
            code: params.get('code'),
            redirect_uri: 'grain://login'
          })
          .then(({ data }) => {
            const { token } = data;
            setIsAuthenticated(true);
            cookies.set('token', token, { path: '/', maxAge: ONE_YEAR });
            cookies.set('logged_in', true, { path: '/', maxAge: ONE_YEAR });
            unsubscribeOpenProtocolUrl();
            if (onLogin) onLogin();
          })
          .catch(error => {
            throw error;
          });
      }
    }

    return () => {
      unsubscribeOpenProtocolUrl();
    };
  }, [loginStateId, onLogin, setIsAuthenticated]);

  const handleLogin = () => {
    const loginState = uniqid();
    setLoginStateId(loginState);
    openUrlInBrowser(`${LOGIN_URL}?state=${loginState}`);
  };

  const handleSignup = () => {
    openUrlInBrowser(`${BASE_URL}/app/signup`);
  };

  return {
    handleLogin,
    handleSignup
  };
};
