import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDeleteStoryItem } from '~/pages/StoryPage/hooks';
import {
  StyledItemWrapper,
  StyledHandle,
  StyledText,
  StyledThumbnail,
  StyledRightAction,
  StyledSectionTitle,
  StyledDupeLabel
} from './styles';
import { scrollItemIntoView } from '../../Document';
import { Icon } from '@grain/grain-ui';

StoryItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  highlighted: PropTypes.bool.isRequired,
  handleProps: PropTypes.object,
  disableHover: PropTypes.bool,
  isDupe: PropTypes.bool
};

const EMPTY_OBJ = {};
export default function StoryItem({
  item,
  index,
  highlighted,
  handleProps = EMPTY_OBJ,
  disableHover,
  isDupe,
  ...rest
}) {
  const [deleteStoryItem, { loading: deleteStoryLoading }] = useDeleteStoryItem(
    {
      item
    }
  );

  const handleDelete = e => {
    e.stopPropagation();
    deleteStoryItem();
  };

  const thumbnailSrc = item.clip?.data?.thumbnailJpegUrl;
  const titleText =
    item.__typename === 'StoryClipV2' ? item.clip.data.text : item.title;

  return (
    <StyledItemWrapper
      highlighted={highlighted}
      className={cx('fs-block', disableHover ? 'disable-hover' : '')}
      {...rest}
      onClick={() => scrollItemIntoView(index)}
    >
      <StyledHandle {...handleProps} onClick={e => e.stopPropagation()}>
        <Icon.DragHandle />
      </StyledHandle>
      {item.__typename === 'StoryClipV2' ? (
        <>
          <StyledThumbnail crossOrigin='anonymous' src={thumbnailSrc} />
          <StyledText>{titleText}</StyledText>
        </>
      ) : (
        <StyledSectionTitle colorNumber={item.colorNumber}>
          <div className='colored-flag' />
          <div className='title'>{titleText}</div>
        </StyledSectionTitle>
      )}
      {isDupe && <StyledDupeLabel>dupe</StyledDupeLabel>}
      <StyledRightAction
        disabled={deleteStoryLoading}
        aria-label='Delete item'
        onClick={handleDelete}
      >
        <Icon.Delete />
      </StyledRightAction>
    </StyledItemWrapper>
  );
}
