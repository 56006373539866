import styled from '@emotion/styled';
import { colors, spaces, spacing } from '@grain/styles/constants';
import { resetLinkStyles, pxToRem } from '@grain/grain-ui';
import { theme } from '@grain/grain-ui/v4';

export const StyledDashedBox = styled.div<{
  highlighted: boolean;
  loading?: boolean;
}>`
  ${props =>
    props.loading &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}
  ${spacing.py7};
  font-weight: 500;
  font-size: ${pxToRem(12)};
  text-align: center;
  border: 1px dashed ${colors.pigeon};
  box-sizing: border-box;
  border-radius: ${spaces[3]};
  display: flex;
  flex-direction: column;
  align-content: center;
  background-color: ${props =>
    props.highlighted ? colors.gull : 'transparent'};

  align-items: center;
  margin: ${theme.tokens.spacing['3xl']};
  gap: ${theme.tokens.spacing.sm};
  width: auto;

  .upload-gate-container {
    ${spacing.px6};
  }

  .title {
    ${spacing.mb3};
    font-size: ${pxToRem(16)};
    font-weight: 600;
    text-align: left;
  }

  .subtitle {
    ${spacing.mb3};
    font-size: ${pxToRem(16)};
    font-weight: 400;
    text-align: left;
  }

  .bullet-container {
    width: 100%;
    margin-bottom: 24px;
    font-size: calc(14rem / 16);
    line-height: calc(20rem / 16);
  }

  .button-container {
    a {
      ${resetLinkStyles};
    }
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`;
