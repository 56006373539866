// @ts-strict-ignore
import { SmartTopicV2 } from '@grain/api/schema.extra';
import {
  useWorkspaceTagsQuery,
  useWorkspaceSmartTopicsQuery,
  useDeleteTagandSmarTopicMutation,
  useRenameTopicTagMutation,
  useGetSingleSmartTopicQuery,
  useSmartTopicConfigureV2Mutation,
  useSmartTopicMatchPreviewV2LazyQuery,
  SmartTopicMatchPreviewV2QueryVariables
} from '@grain/api/graphql/queries/smartTopic.generated';

import {
  SmartTopicInputV2,
  SmartTopicMatchPreview,
  SmartTopicMatchScope,
  SmartTopicSpeakerMatchScope
} from '@grain/api/schema.generated';
import React, { useMemo } from 'react';

export const useTopics = () => {
  const { data, loading } = useWorkspaceSmartTopicsQuery();
  const [deleteTagSmartTopic] = useDeleteTagandSmarTopicMutation({
    refetchQueries: ['workspaceSmartTopics', 'workspaceTags']
  });
  const workSpaceSmartTopics = data?.workspaceSmartTopics;

  return {
    topics: workSpaceSmartTopics,
    loading: loading,
    handleSmartTopicDelete: deleteTagSmartTopic
  };
};

export const useTags = () => {
  const data = useWorkspaceTagsQuery();

  const tags = useMemo(() => {
    return data?.data?.workspaceTags || [];
  }, [data?.data?.workspaceTags]);

  return tags;
};

type SmartTopicFormValues = SmartTopicInputV2 & {
  text: string;
};

const convertSmartTopicToFormValues = (
  topic: SmartTopicV2
): SmartTopicFormValues => ({
  text: topic?.tag?.text || '',
  description: topic?.description || '',
  matchScope: topic?.matchScope || SmartTopicMatchScope.All,
  speakerScope: topic?.speakerScope || SmartTopicSpeakerMatchScope.All,
  phrases: topic?.phrases || [],
  keywords: topic?.keywords || []
});

export const useTopic = (topics: SmartTopicV2[], tagId?: string) => {
  let selectedTopic = topics.find(topic => topic.tag?.id === tagId);
  const data = useGetSingleSmartTopicQuery({
    variables: { id: tagId },
    skip: Boolean(selectedTopic || !tagId)
  });

  if (!selectedTopic) {
    selectedTopic = data?.data?.smartTopic;
  }
  const [currentTopic, setCurrentTopic] = React.useState(selectedTopic);
  const [currentSmartTopicMatchPreview, setCurrentSmartTopicMatchPreview] =
    React.useState<SmartTopicMatchPreview>();

  const smartTopicVariable = convertSmartTopicToFormValues(selectedTopic);

  const [currentVariables, setCurrentVariables] =
    React.useState(smartTopicVariable);
  const [getSmartTopicPreviewQueries] = useSmartTopicMatchPreviewV2LazyQuery();

  React.useEffect(() => {
    // Due to some error with persistent data in graphql, I had to add undefined to optional properties, the API either accepts a smartTopicId or a smartTopicInput object
    // Giving it both results in argument error, however, on reassignment, it seems to take previous value from previous call and merges them, which leads to argument error.
    // This is a workaround to prevent that from happening. assigning undefined to the other property when the other is present.
    let smartTopicPreviewMatchVariable: SmartTopicMatchPreviewV2QueryVariables =
      {
        matchLimit: 100,
        smartTopicInput: undefined
      };

    const isNewPreview = [
      [currentVariables.matchScope, selectedTopic?.matchScope],
      [
        currentVariables.phrases?.toString(),
        selectedTopic?.phrases?.toString()
      ],
      [
        currentVariables.keywords?.toString(),
        selectedTopic?.keywords?.toString()
      ],
      [currentVariables.speakerScope, selectedTopic?.speakerScope]
    ].some(([a, b]) => a !== b);

    if (isNewPreview) {
      const hasEnoughData = Boolean(
        currentVariables.matchScope &&
          currentVariables.speakerScope &&
          // Filter out empty strings
          (currentVariables.phrases.filter(Boolean).length ||
            currentVariables.keywords.filter(Boolean).length)
      );
      if (hasEnoughData) {
        const { text: _text, ...smartTopicInput } = currentVariables;
        smartTopicPreviewMatchVariable = {
          matchLimit: 100,
          smartTopicInput: {
            ...smartTopicInput,
            phrases: smartTopicInput.phrases.filter(Boolean),
            keywords: smartTopicInput.keywords.filter(Boolean)
          } as SmartTopicInputV2
        };
      }
    }
    if (!smartTopicPreviewMatchVariable.smartTopicInput) {
      return;
    }
    getSmartTopicPreviewQueries({
      variables: smartTopicPreviewMatchVariable
    }).then(({ data, loading, error }) => {
      if (error) {
        setCurrentSmartTopicMatchPreview({} as SmartTopicMatchPreview);
      }
      if (!loading && !error) {
        const smartTopicMatchPreview = data?.smartTopicMatchPreviewV2 || {};
        setCurrentSmartTopicMatchPreview(
          smartTopicMatchPreview as SmartTopicMatchPreview
        );
      }
    });
  }, [currentVariables, getSmartTopicPreviewQueries, selectedTopic]);

  React.useEffect(() => {
    setCurrentTopic(selectedTopic);
    setCurrentVariables(convertSmartTopicToFormValues(selectedTopic));
  }, [selectedTopic]);

  const [smartTopicConfigureMutationV2] = useSmartTopicConfigureV2Mutation({
    refetchQueries: ['workspaceSmartTopics', 'workspaceTags']
  });
  const [renameTopicTagMutation] = useRenameTopicTagMutation();

  return {
    handleSmartTopicV2Configure: smartTopicConfigureMutationV2,
    renameTagText: renameTopicTagMutation,
    currentTopic,
    setCurrentTopic,
    currentSmartTopicMatchPreview,
    currentVariables,
    setCurrentVariables
  } as const;
};
