import { useState } from 'react';
import styled from '@emotion/styled';
import { User, Recording } from '@grain/api/schema.generated';
import { color } from 'base/css';
import { CreateReply } from './CreateReply';
import { SuggestionData } from 'components/atoms/RichEditor/RichEditor';
import { UserFragmentFragment } from '@grain/api/graphql/fragments/fragments.generated';

const StyledCommentThread = styled.div`
  border: 1px solid ${color.gull};
  border-radius: 8px;
`;

export type CommentThreadInitializerProps = {
  workspaceMembers: User[];
  recording: Recording;
  user: UserFragmentFragment;
  memberSuggestions: SuggestionData[];
  placeholder: string;
  submitText: string;
  onSubmit: (text: string) => Promise<unknown>;
  onUpdate?: (text: string) => void;
};

const NOOP = () => {};

// "Mini" version of CommentThread that is only meant for leaving an initial
// comment (not for looking at a thread or leaving replies).
export function CommentThreadInitializer({
  workspaceMembers,
  recording,
  user,
  memberSuggestions,
  placeholder,
  submitText,
  onSubmit,
  onUpdate = NOOP,
  ...rest
}: CommentThreadInitializerProps) {
  const [value, setValue] = useState('');

  function handleChange(text: string) {
    setValue(text);
    onUpdate(text);
  }

  async function handleSubmit(text: string) {
    await onSubmit(text);
    setValue('');
  }

  function handleCancel() {
    setValue('');
  }

  return (
    <StyledCommentThread {...rest}>
      <CreateReply
        workspaceMembers={workspaceMembers}
        recording={recording}
        user={user}
        value={value}
        placeholder={placeholder}
        memberSuggestions={memberSuggestions}
        submitText={submitText}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        hasHoverState={false}
        hasFocusState={false}
      />
    </StyledCommentThread>
  );
}
