import React, { useEffect, useState, useRef, useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { color, font } from 'base/css';
import { Icon } from 'components/atoms/Icon/Icon';
import { TextArea } from 'components/atoms/TextArea/TextArea';
import { Button } from 'components/molecules/Button/Button';
import { Modal } from 'components/molecules/Modal';
import { Divider } from 'components/molecules/Divider/Divider';
import { Loading } from 'components/atoms/Icon/svg/Loading';
import {
  NoteSection,
  NoteSectionProps
} from 'components/molecules/NoteSection/NoteSection';
import { Recording } from '@grain/api/schema.generated';
import { Dropdown } from 'components/molecules/Dropdown/Dropdown';
import Suggestions, { SuggestionsProps } from './Suggestions';
import {
  Option,
  OptionsList
} from 'components/molecules/OptionsList/OptionsList';
import { Checkbox } from 'components/atoms/Checkbox/Checkbox';
import { InfoBox } from 'components/molecules/InfoBox/InfoBox';

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
  padding: 18px 0;
`;

const TextAreaWrapper = styled.div`
  width: 100%;
  padding: 0 24px;
  display: flex;
  gap: 16px;
  align-items: center;

  button {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    padding: 0;
  }

  textarea {
    padding: 0px 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0;
  gap: 8px;

  span {
    font-size: 14px;
  }
`;

const ContentWrapper = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin: 0 !important;
`;

const FooterWrapper = styled.div<{ hasRecordingList: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ hasRecordingList }) =>
    hasRecordingList ? 'space-between' : 'flex-end'};
  width: 100%;
  padding: 0 24px;
  justify-content: space-between;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-left: auto;
`;

const RecordingSelectWrapper = styled.div`
  display: flex;
  color: ${color.crow};
  font-size: 12px;
  gap: 6px;
  align-items: center;
`;

const RecordingSelect = styled.div`
  display: flex;
  color: ${color.blackbird};
  cursor: pointer;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;

  span {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover,
  &:active,
  &:focus,
  [aria-expanded*='true'] {
    background: ${color.gull};
    border-radius: 8px;
  }
`;
const ErrorWrapper = styled.div`
  display: flex;
  align-self: center;
  padding: 0 24px;
  width: 100%;
`;

const StyledTemplateAddCheckbox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
`;

type TemplateAddEnabled = {
  canAddToTemplate: true;
  isAddToTemplateSet: boolean;
  onAddOptionChange: (checked: boolean) => void;
  addText: string;
};

type TemplateAddDisabled = {
  canAddToTemplate: false;
};

export type TemplateAddOption = TemplateAddEnabled | TemplateAddDisabled;

type AddAISectionModalProps = {
  isPreviewLoading: boolean;
  noteItem?: NoteSectionProps;
  onClose: () => void;
  onSectionAdd: () => void;
  errorMessage?: React.ReactNode;
  recordings: Recording[];
  selectedRecording?: Recording;
  onRecordingChange?: (recording: Recording) => void;
  placeholder?: string;
  addSectionText: string;
  onPreviewSubmit: (value: string) => void;
  isRecordingFilterLoading?: boolean;
  onRecordingSearch?: (searchTerm: string) => void;
  canSave: boolean;
  showRecordingSelector: boolean;
  recordingFilterValue?: string;
  addToTemplateOptions: TemplateAddOption;
  modalMode?: 'add' | 'edit';
  userPrompt?: string;
  setIsUserPromptDirty?: (dirty: boolean) => void;
} & SuggestionsProps;

const MAX_TITLE_LENGTH = 40;

const shortenTitle = (title: string): string => {
  if (title.length > MAX_TITLE_LENGTH) {
    return `${title.slice(0, MAX_TITLE_LENGTH)}...`;
  }
  return title;
};

const NOOP = () => {};
const EMPTY_ARRAY: never[] = [];

export function AddAISectionModal({
  isPreviewLoading,
  noteItem,
  onClose,
  onSectionAdd,
  onPreviewSubmit,
  onSuggestionSelect,
  errorMessage,
  recordings,
  selectedRecording,
  onRecordingChange = NOOP,
  placeholder,
  addSectionText,
  isRecordingFilterLoading,
  onRecordingSearch = NOOP,
  sections = EMPTY_ARRAY,
  canSave,
  showRecordingSelector,
  recordingFilterValue = '',
  addToTemplateOptions,
  modalMode = 'add',
  userPrompt = '',
  setIsUserPromptDirty = NOOP
}: AddAISectionModalProps) {
  const [value, setValue] = React.useState('');
  const [showError, setShowError] = useState(false);
  const [canUpdate, setCanUpdate] = useState<boolean>();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const typingTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleAddSection = () => {
    onSectionAdd();
    onClose();
  };

  const handlePreviewSubmit = () => {
    onPreviewSubmit(value);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const updatedValue = e.target.value;
    setValue(updatedValue);
    setIsUserPromptDirty?.(updatedValue !== userPrompt);
  };

  useEffect(() => {
    setShowError(!!errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (!value) setValue(userPrompt);
  }, [userPrompt, value]);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    // Delay state update by 1000ms (1 second) after the user stops typing
    // to prevent changing focus between prompt and title textareas
    typingTimeoutRef.current = setTimeout(() => {
      setCanUpdate(!!value);
    }, 1000);

    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, [value]);

  const handleRecordingChange = (option: Option[]) => {
    if (!option.length) return;
    setDropdownVisible(false);
    const recording = recordings.find(r => r.id === option[0].value);
    if (recording) {
      onRecordingChange(recording);
    }
  };

  const recordingOptions = useMemo(
    () =>
      recordings.map(
        recording =>
          ({
            title: shortenTitle(recording.title),
            value: recording.id
          }) satisfies Option
      ),
    [recordings]
  );

  const handleRecordingFilter = React.useCallback(
    (value = '') => {
      onRecordingSearch(value);
    },
    [onRecordingSearch]
  );

  return (
    <Modal
      onCancel={onClose}
      maxContentHeight='90vh'
      width='650px;'
      css={['overflow: visible;']}
    >
      <ModalWrapper>
        <TextAreaWrapper>
          <TextArea
            autoFocus
            placeholder={
              placeholder || 'What do you want to know about your meetings?'
            }
            value={value ?? ''}
            onChange={handleTextChange}
            onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
              if (e.key === 'Enter' && e.shiftKey === false) {
                e.preventDefault();
                handlePreviewSubmit();
              }
            }}
          />
          <Button
            disabled={!value}
            onClick={handlePreviewSubmit}
            variant='stealth'
          >
            <Icon.FlipForward />
          </Button>
        </TextAreaWrapper>
        {!isPreviewLoading &&
          !noteItem &&
          !showError &&
          modalMode !== 'edit' && (
            <Suggestions
              sections={sections}
              filter={value ?? ''}
              onSuggestionSelect={onSuggestionSelect}
            />
          )}
        {isPreviewLoading && (
          <>
            <Divider spacing={0} />
            <LoadingWrapper>
              <Loading css={[color.fg.graieen]} />
              <span>Loading Preview</span>
            </LoadingWrapper>
          </>
        )}
        {showError && !isPreviewLoading && (
          <>
            <Divider spacing={0} />
            <ErrorWrapper>
              <InfoBox
                icon={
                  <Icon.Info
                    css={css`
                      width: 20px;
                      height: 20px;
                      flex-shrink: 0;
                    `}
                  />
                }
              >
                <div css={[font.body.m.unpaired]}>{errorMessage}</div>
              </InfoBox>
            </ErrorWrapper>
          </>
        )}
        {noteItem && !isPreviewLoading && !showError && (
          <>
            <Divider spacing={0} />
            <ContentWrapper>
              <NoteSection
                allowScroll
                title={noteItem.title}
                status={noteItem.status}
                isTitleEditable={canUpdate}
                isUserPrompt={noteItem.isUserPrompt}
                onTitleChange={noteItem.onTitleChange}
                onTypeChange={type => noteItem.onTypeChange?.(type, value)}
                type={noteItem.type}
              >
                {noteItem.children}
              </NoteSection>
            </ContentWrapper>
          </>
        )}
        <Divider spacing={0} />
        <FooterWrapper hasRecordingList={showRecordingSelector}>
          {showRecordingSelector && !addToTemplateOptions.canAddToTemplate && (
            <RecordingSelectWrapper>
              <Icon.Record />
              <span>Preview based on</span>
              <Dropdown
                interactive
                placement='top-start'
                visible={dropdownVisible}
                onClickOutside={() => setDropdownVisible(false)}
                onShow={instance => {
                  setTimeout(() => {
                    // autoFocus doesn't work inside of tippy elements
                    // so we have to do it manually here
                    instance.popper?.querySelector('input')?.focus();
                  }, 200);
                }}
                targetElement={
                  <RecordingSelect
                    onClick={() => setDropdownVisible(!dropdownVisible)}
                  >
                    <span>{selectedRecording?.title || 'Loading…'}</span>
                    <Icon.ChevronDown />
                  </RecordingSelect>
                }
                arrow={false}
              >
                <OptionsList
                  numberOfSelectedItems={1}
                  isMultiSelect={false}
                  onSelect={(value: Option[]) => handleRecordingChange(value)}
                  options={recordingOptions}
                  shouldSortList={false}
                  isLoading={isRecordingFilterLoading}
                  showChecked
                  maxHeight='160px'
                  groupTitle='Recent Meetings'
                  defaultInputValue={recordingFilterValue}
                  defaultValues={
                    [
                      {
                        title: selectedRecording?.title,
                        value: selectedRecording?.id
                      }
                    ] as Option[]
                  }
                  placeholder='Find a meeting'
                  onSearch={handleRecordingFilter}
                  usesServerSearch
                />
              </Dropdown>
            </RecordingSelectWrapper>
          )}
          {canSave && (
            <>
              {addToTemplateOptions.canAddToTemplate && (
                <StyledTemplateAddCheckbox>
                  <Checkbox
                    checked={addToTemplateOptions.isAddToTemplateSet}
                    onChange={ev =>
                      addToTemplateOptions.onAddOptionChange(ev.target.checked)
                    }
                  >
                    {addToTemplateOptions.addText}
                  </Checkbox>
                </StyledTemplateAddCheckbox>
              )}
              <ButtonsWrapper>
                {noteItem?.onCopy && (
                  <Button
                    variant='secondary'
                    icon={<Icon.Copy />}
                    onClick={noteItem.onCopy}
                  >
                    Copy
                  </Button>
                )}
                {addSectionText && (
                  <StyledButton
                    variant='primary'
                    onClick={handleAddSection}
                    disabled={!canSave}
                    css={['align-self: center; margin: 12px 0 12px 0;']}
                    {...(modalMode === 'edit'
                      ? {}
                      : { icon: <Icon.DeprecatedPlus /> })}
                  >
                    {addSectionText}
                  </StyledButton>
                )}
              </ButtonsWrapper>
            </>
          )}
        </FooterWrapper>
      </ModalWrapper>
    </Modal>
  );
}
