import * as Types from '@grain/api/schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@grain/api/graphql';
const defaultOptions = {} as const;
export type CalendarSelectedSetMutationVariables = Types.Exact<{
  calendarId: Types.Scalars['ID']['input'];
  selected: Types.Scalars['Boolean']['input'];
}>;


export type CalendarSelectedSetMutation = { __typename?: 'RootMutationType', calendarSelectedSet: { __typename?: 'Calendar', accounts: Array<{ __typename?: 'CalendarAccount', missingScopes: boolean, email: string | null, name: string | null, provider: Types.OauthProvider, status: Types.CalendarStatus, calendars: Array<{ __typename?: 'SelectableCalendar', id: string, isSelected: boolean, title: string }> }> } };


export const CalendarSelectedSetDocument = gql`
    mutation CalendarSelectedSet($calendarId: ID!, $selected: Boolean!) {
  calendarSelectedSet(calendarId: $calendarId, selected: $selected) {
    accounts {
      calendars {
        id
        isSelected
        title
      }
      missingScopes
      email
      name
      provider
      status
    }
  }
}
    `;
export type CalendarSelectedSetMutationFn = Apollo.MutationFunction<CalendarSelectedSetMutation, CalendarSelectedSetMutationVariables>;

/**
 * __useCalendarSelectedSetMutation__
 *
 * To run a mutation, you first call `useCalendarSelectedSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarSelectedSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarSelectedSetMutation, { data, loading, error }] = useCalendarSelectedSetMutation({
 *   variables: {
 *      calendarId: // value for 'calendarId'
 *      selected: // value for 'selected'
 *   },
 * });
 */
export function useCalendarSelectedSetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CalendarSelectedSetMutation, CalendarSelectedSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CalendarSelectedSetMutation, CalendarSelectedSetMutationVariables>(CalendarSelectedSetDocument, options);
      }
export type CalendarSelectedSetMutationHookResult = ReturnType<typeof useCalendarSelectedSetMutation>;
export type CalendarSelectedSetMutationResult = Apollo.MutationResult<CalendarSelectedSetMutation>;
export type CalendarSelectedSetMutationOptions = Apollo.BaseMutationOptions<CalendarSelectedSetMutation, CalendarSelectedSetMutationVariables>;