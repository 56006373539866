import {
  RecordingAccessSelector,
  Switch,
  useConfirm,
  useShowToast,
  Checkbox,
  color,
  flex,
  Icon
} from '@grain/grain-ui';
import React from 'react';
import { spacing } from '@grain/styles/constants';
import { useWorkspaceWithMembers } from '@grain/components/Workspace/hooks';
import { MeetingLanguageSelector } from '~/components/MeetingLanguageSelector/MeetingLanguageSelector';
import {
  useWorkspaceEditMutation,
  WorkspaceEditMutationVariables
} from '@grain/api/graphql/mutations/workspace.generated';
import { useExperiment } from '~/support/auth';
import {
  StyledRecordingAccessContainer,
  StyledDivider,
  StyledOption,
  StyledLockAction
} from './styles';
import {
  Maybe,
  RecapShareType,
  RecordingShareState
} from '@grain/api/schema.generated';
import {
  useWorkspaceSharedAutomationsQuery,
  WorkspaceSharedAutomationsQuery
} from '../../settings.generated';
import { StyledSwitchSection } from '../WorkspaceTab/styles';
import { Select, Option, Tooltip } from '@grain/grain-ui/v4';

enum ShareOptions {
  ALL = 1,
  INTERNAL = 2,
  EXTERNAL = 3,
  NONE = 4
}

const recorderSelectorOptions = [
  {
    title: 'Auto record all meetings',
    value: ShareOptions.ALL
  },
  {
    title: 'Auto record all meetings with only team participants',
    value: ShareOptions.INTERNAL
  },
  {
    title: 'Auto record all meetings with customer participants',
    value: ShareOptions.EXTERNAL
  },
  {
    title: 'Don’t auto record any meetings',
    value: ShareOptions.NONE
  }
];

const shareSelectorOptions = [
  {
    title: 'All meetings',
    value: ShareOptions.ALL
  },
  {
    title: 'All meetings with only team participants',
    value: ShareOptions.INTERNAL
  },
  {
    title: 'All meetings with customer participants',
    value: ShareOptions.EXTERNAL
  },
  {
    title: 'No meetings',
    value: ShareOptions.NONE
  }
];

const recapShareSelectorOptions = [
  {
    title: 'Only send recap to participants in your Grain workspace',
    value: RecapShareType.Workspace
  },
  {
    title: 'Send recap to all participants from your team domain(s)',
    value: RecapShareType.Internal
  },
  {
    title: 'Send recap to all participants',
    value: RecapShareType.All
  },
  {
    title: 'Don’t send recap emails',
    value: RecapShareType.None
  }
];

export default function Meetings() {
  const showConfirm = useConfirm();
  const showToast = useShowToast();
  const { enabled: isFreemiumEnabled } = useExperiment('freemium');

  // This is initialized as a string with just a space to solve for the mismatch
  //  of loading states between the apollo client and local state set onComplete
  //  that results in the button footer to "flash" in on the load of this modal
  const [allowRecordingCopy, setAllowRecordingCopy] = React.useState(false);
  const [recapShareMode, setRecapShareMode] = React.useState(
    RecapShareType.Internal
  );

  const [autoShareInternalActive, setAutoShareInternalActive] =
    React.useState(false);

  const [autoShareExternalActive, setAutoShareExternalActive] =
    React.useState(false);

  const [
    defaultAutoRecordOrganiserOnlyActive,
    setDefaultAutoRecordOrganiserOnlyActive
  ] = React.useState(false);

  const [defaultAutoRecordInternalActive, setDefaultAutoRecordInternalActive] =
    React.useState(false);

  const [defaultAutoRecordExternalActive, setDefaultAutoRecordExternalActive] =
    React.useState(false);

  const [defaultShareState, setDefaultShareState] =
    React.useState<RecordingShareState>(RecordingShareState.Public);

  const [recordOverridable, setRecordOverridable] = React.useState(false);
  const [shareOverridable, setShareOverridable] = React.useState(false);
  const [defaultShareStateOverridable, setDefaultShareStateOverridable] =
    React.useState(false);
  const [commentEmailEnabled, setCommentEmailEnabled] = React.useState(false);

  const { data: automations } = useWorkspaceSharedAutomationsQuery();

  const { loading: workspaceLoading, workspace } = useWorkspaceWithMembers({
    onCompleted: ({ workspace }) => {
      setAllowRecordingCopy(workspace.allowRecordingCopy);
      setDefaultShareState(workspace.defaultRecordingsShareState);
      setRecapShareMode(workspace.recapShareMode);
      setAutoShareExternalActive(workspace.autoShareExternalRecordings);
      setAutoShareInternalActive(workspace.autoShareInternalRecordings);
      setDefaultAutoRecordOrganiserOnlyActive(
        workspace.defaultAutoRecordOrganiserOnly
      );
      setDefaultAutoRecordInternalActive(workspace.defaultAutoRecordInternal);
      setDefaultAutoRecordExternalActive(workspace.defaultAutoRecordExternal);
      setRecordOverridable(workspace.defaultAutoRecordOverridable);
      setShareOverridable(workspace.autoShareRecordingsOverridable);
      setDefaultShareStateOverridable(
        workspace.defaultRecordingsShareStateOverridable
      );
      setCommentEmailEnabled(workspace.sendCommentEmailNotifications);
    }
  });

  const [editWorkspace] = useWorkspaceEditMutation({
    onCompleted: () => {
      showToast({
        content: 'Workspace updated.',
        type: 'success',
        uniqueId: 'workspace-update'
      });
    }
  });

  function handleCanCopyToggle(e: React.ChangeEvent<HTMLInputElement>) {
    setAllowRecordingCopy(e.target.checked);
    editWorkspace({
      variables: {
        allowRecordingCopy: e.target.checked
      }
    });
  }

  function handleCommentEmailEnabledToggle(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    setCommentEmailEnabled(e.target.checked);
    editWorkspace({
      variables: {
        sendCommentEmailNotifications: e.target.checked
      }
    });
  }

  function handleRecapShare(recapShareOption: RecapShareType) {
    setRecapShareMode(recapShareOption);
    editWorkspace({
      variables: {
        recapShareMode: recapShareOption
      }
    });
  }

  const recapShare = React.useMemo(() => {
    return recapShareSelectorOptions.find(
      option => option.value === recapShareMode
    );
  }, [recapShareMode]);

  const shareSetting = React.useMemo(() => {
    if (autoShareExternalActive && autoShareInternalActive) {
      return shareSelectorOptions[0];
    }
    if (autoShareInternalActive && !autoShareExternalActive) {
      return shareSelectorOptions[1];
    }
    if (!autoShareInternalActive && autoShareExternalActive) {
      return shareSelectorOptions[2];
    }
    if (!autoShareInternalActive && !autoShareExternalActive) {
      return shareSelectorOptions[3];
    }
  }, [autoShareExternalActive, autoShareInternalActive]);

  function handleShareState(shareState: RecordingShareState) {
    setDefaultShareState(shareState);
    editWorkspace({
      variables: {
        defaultRecordingsShareState: shareState
      }
    });
    if (shareState !== 'PUBLIC') {
      handleRecapShare(RecapShareType.None);
    }
  }

  const changeShareSettings = React.useCallback(
    ({
      internal = null,
      external = null
    }: {
      internal?: boolean | null;
      external?: boolean | null;
    }) => {
      const internalChanged =
        internal !== null &&
        internal !== workspace?.autoShareInternalRecordings;
      const externalChanged =
        external !== null &&
        external !== workspace?.autoShareExternalRecordings;

      // Return if nothing changed
      if (!(internalChanged || externalChanged)) {
        return;
      }

      const currentInternal = internalChanged
        ? internal
        : workspace?.autoShareInternalRecordings;
      const currentExternal = externalChanged
        ? external
        : workspace?.autoShareExternalRecordings;

      // Build edit request
      const variables: WorkspaceEditMutationVariables = {};
      if (internalChanged) {
        variables.autoShareInternalRecordings = internal;
      }
      if (externalChanged) {
        variables.autoShareExternalRecordings = external;
      }

      // Confirm edit
      let shouldConfirm = false;

      type HubspotIntegration = Maybe<
        Extract<
          WorkspaceSharedAutomationsQuery['hubspot'],
          { __typename?: 'HubspotIntegration' }
        >
      >;
      const hubspotEnabled = (automations?.hubspot as HubspotIntegration)
        ?.automationEnabled;
      if (hubspotEnabled && externalChanged && !external) {
        shouldConfirm = true;
      }

      type SlackIntegration = Maybe<
        Extract<
          WorkspaceSharedAutomationsQuery['slack'],
          { __typename?: 'SlackIntegration' }
        >
      >;

      const slackAutomations =
        (automations?.slack as SlackIntegration)?.automations?.length || 0;
      const slackEnabled = slackAutomations > 0;
      if (
        slackEnabled &&
        ((internalChanged && !internal) || (externalChanged && !external))
      ) {
        shouldConfirm = true;
      }

      // Maybe send request
      if (shouldConfirm) {
        const title = 'Change workspace access default?';
        const description =
          'Changing your workspace access default may limit one or more active automations for members of your workspace.';

        showConfirm({
          closable: false,
          width: 466,
          title,
          description,
          confirmContent: 'Continue',
          confirmButtonProps: { variant: 'primary' },
          onConfirm: () => {
            setAutoShareExternalActive(currentExternal);
            setAutoShareInternalActive(currentInternal);
            editWorkspace({ variables });
          }
        });
      } else {
        setAutoShareExternalActive(currentExternal);
        setAutoShareInternalActive(currentInternal);
        editWorkspace({ variables });
      }
    },
    [automations, editWorkspace, showConfirm, workspace]
  );

  function handleShareSetting(shareSetting: ShareOptions) {
    switch (shareSetting) {
      case ShareOptions.ALL:
        return changeShareSettings({ external: true, internal: true });
      case ShareOptions.INTERNAL:
        return changeShareSettings({ internal: true, external: false });
      case ShareOptions.EXTERNAL:
        return changeShareSettings({ internal: false, external: true });
      case ShareOptions.NONE:
        return changeShareSettings({ internal: false, external: false });
    }
  }

  const recordSetting = React.useMemo(() => {
    const autoRecordInternal = defaultAutoRecordInternalActive;
    const autoRecordExternal = defaultAutoRecordExternalActive;

    if (autoRecordExternal && autoRecordInternal) {
      return recorderSelectorOptions[0];
    }
    if (autoRecordInternal && !autoRecordExternal) {
      return recorderSelectorOptions[1];
    }
    if (autoRecordExternal && !autoRecordInternal) {
      return recorderSelectorOptions[2];
    }
    if (!autoRecordExternal && !autoRecordInternal) {
      return recorderSelectorOptions[3];
    }
  }, [defaultAutoRecordExternalActive, defaultAutoRecordInternalActive]);

  const changeRecordSettings = React.useCallback(
    (value: ShareOptions) => {
      let variables: {
        defaultAutoRecordExternal: boolean;
        defaultAutoRecordInternal: boolean;
      } = {
        defaultAutoRecordExternal: true,
        defaultAutoRecordInternal: true
      };

      if (value === ShareOptions.INTERNAL) {
        variables = {
          defaultAutoRecordInternal: true,
          defaultAutoRecordExternal: false
        };
      } else if (value === ShareOptions.EXTERNAL) {
        variables = {
          defaultAutoRecordExternal: true,
          defaultAutoRecordInternal: false
        };
      } else if (value === ShareOptions.NONE) {
        variables = {
          defaultAutoRecordExternal: false,
          defaultAutoRecordInternal: false
        };
      }

      if (Object.keys(variables).length !== 0) {
        setDefaultAutoRecordExternalActive(variables.defaultAutoRecordExternal);
        setDefaultAutoRecordInternalActive(variables.defaultAutoRecordInternal);
        editWorkspace({ variables });
      }
    },
    [editWorkspace]
  );

  if (workspaceLoading) return null;

  return (
    <StyledRecordingAccessContainer>
      <>
        <div className='group-heading' css={[spacing.mb4]}>
          Defaults
          <span>Select default settings for new workspace members.</span>
        </div>
        <div className='sub-heading' css={[spacing.mb4, spacing.mt4]}>
          Workspace auto record default
          <span>
            The meeting types for Grain to automatically join and record.
          </span>
        </div>
        <div css={['width: 450px; gap: 8px;', spacing.mb4, flex.direction.row]}>
          <Select
            fullWidth
            matchWidth
            value={recordSetting?.value}
            onChange={value => {
              changeRecordSettings(value);
            }}
          >
            {recorderSelectorOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.title}
              </Option>
            ))}
          </Select>
          <Tooltip
            tippyProps={{
              placement: 'bottom'
            }}
            content={
              recordOverridable
                ? 'Don’t allow workspace users to set their own default.'
                : 'Allow workspace users to set their own default.'
            }
          >
            <StyledLockAction
              onClick={() => {
                setRecordOverridable(!recordOverridable);
                editWorkspace({
                  variables: {
                    defaultAutoRecordOverridable: !recordOverridable
                  }
                });
              }}
            >
              {recordOverridable ? (
                <Icon.SettingLockOpened />
              ) : (
                <Icon.SettingLockClosed />
              )}
            </StyledLockAction>
          </Tooltip>
        </div>
        <StyledOption>
          <Checkbox
            checked={
              recordSetting?.value !== ShareOptions.NONE &&
              defaultAutoRecordOrganiserOnlyActive
            }
            disabled={recordSetting?.value === ShareOptions.NONE}
            onChange={ev => {
              setDefaultAutoRecordOrganiserOnlyActive(ev.target.checked);
              editWorkspace({
                variables: {
                  defaultAutoRecordOrganiserOnly: ev.target.checked
                }
              });
            }}
          />
          <div
            className='option-label'
            css={[
              recordSetting?.value === ShareOptions.NONE
                ? color.fg.pigeon
                : color.fg.blackbird
            ]}
          >
            Only auto record if a workspace member is the event organizer
          </div>
        </StyledOption>
        <div className='sub-heading' css={[spacing.mb4, spacing.mt4]}>
          Workspace access default
          <span>
            The meeting types that are default accessible to all workspace
            members and automations.
          </span>
        </div>
        <div css={['width: 450px; gap: 8px;', spacing.mb4, flex.direction.row]}>
          <Select
            fullWidth
            matchWidth
            placeholder='Choose one'
            value={shareSetting?.value}
            onChange={value => {
              handleShareSetting(value);
            }}
          >
            {shareSelectorOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.title}
              </Option>
            ))}
          </Select>
          <Tooltip
            tippyProps={{
              placement: 'bottom'
            }}
            content={
              shareOverridable
                ? 'Don’t allow workspace users to set their own default.'
                : 'Allow workspace users to set their own default.'
            }
          >
            <StyledLockAction
              onClick={() => {
                setShareOverridable(!shareOverridable);
                editWorkspace({
                  variables: {
                    autoShareRecordingsOverridable: !shareOverridable
                  }
                });
              }}
            >
              {shareOverridable ? (
                <Icon.SettingLockOpened />
              ) : (
                <Icon.SettingLockClosed />
              )}
            </StyledLockAction>
          </Tooltip>
        </div>
      </>
      <div className='sub-heading' css={[spacing.mb4, spacing.mt4]}>
        Link access default
        <span>Who can access your meeting recordings with a shared link.</span>
      </div>
      <div css={['gap: 8px;', flex.direction.row, flex.alignItems.flexStart]}>
        <RecordingAccessSelector
          workspaceName={workspace?.name}
          selectedRecordingAccess={defaultShareState}
          onChangeAccess={handleShareState}
          isDropdown={false}
        />
        <Tooltip
          tippyProps={{
            placement: 'bottom'
          }}
          content={
            defaultShareStateOverridable
              ? 'Don’t allow workspace users to set their own default.'
              : 'Allow workspace users to set their own default.'
          }
        >
          <StyledLockAction
            onClick={() => {
              setDefaultShareStateOverridable(!defaultShareStateOverridable);
              editWorkspace({
                variables: {
                  defaultRecordingsShareStateOverridable:
                    !defaultShareStateOverridable
                }
              });
            }}
          >
            {defaultShareStateOverridable ? (
              <Icon.SettingLockOpened />
            ) : (
              <Icon.SettingLockClosed />
            )}
          </StyledLockAction>
        </Tooltip>
      </div>
      {!(
        isFreemiumEnabled &&
        workspace?.workspaceSubscription?.planDetails?.isFree
      ) && (
        <div css={[spacing.mt7]}>
          <MeetingLanguageSelector />
        </div>
      )}

      <StyledDivider />

      <div className='sub-heading' css={[spacing.mb4]}>
        Meeting recap emails
        <span>
          Select which meetings generate automatic meeting recap emails and who
          receives them.
        </span>
      </div>
      <div css={['width: 410px']}>
        <Select
          fullWidth
          matchWidth
          value={recapShare?.value}
          placeholder='Choose one'
          onChange={value => {
            handleRecapShare(value);
          }}
        >
          {recapShareSelectorOptions.map(option => (
            <Option key={option.value} value={option.value}>
              {option.title}
            </Option>
          ))}
        </Select>
      </div>

      <StyledDivider />

      <StyledSwitchSection>
        <div className='title'>
          Comment notification emails
          <div className='sub-title'>
            Send emails to notify workspace members who are participants of the
            meeting about new comments.
          </div>
        </div>

        <Switch
          checked={commentEmailEnabled}
          onChange={handleCommentEmailEnabledToggle}
        />
      </StyledSwitchSection>

      <>
        <StyledDivider />
        <StyledSwitchSection>
          <div className='title'>
            Recording duplication
            <div className='sub-title'>
              Non-members see the option to copy meetings they can access.
            </div>
          </div>

          <Switch checked={allowRecordingCopy} onChange={handleCanCopyToggle} />
        </StyledSwitchSection>
      </>
    </StyledRecordingAccessContainer>
  );
}
