import styled from '@emotion/styled';
import { Recording } from '@grain/api/schema.generated';
import { Icon, Button, useRouteModal } from '@grain/grain-ui';
import { SHARE_RECORDING_ID } from '@grain/components/modals/constants';

const StyledSendButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

type SendButtonProps = {
  recording: Recording;
  recipientName: string;
  recipientEmail: string;
};

export function SendButton({ recipientName }: SendButtonProps) {
  const { open: openShareModal } = useRouteModal(SHARE_RECORDING_ID);

  return (
    <StyledSendButtonContainer>
      <Button variant='primary' icon={<Icon.Share />} onClick={openShareModal}>
        Send to {recipientName}
      </Button>
    </StyledSendButtonContainer>
  );
}
