import styled from '@emotion/styled';
import { flex, spaces, font, color } from '@grain/grain-ui';
import { Header } from '~/components/Layout';

export const RECORDING_HEADER_HEIGHT = 64;

export const StyledHeader = styled(Header)`
  height: ${RECORDING_HEADER_HEIGHT}px;
  justify-content: space-between;
  flex-shrink: 0;
  ${flex.direction.row};
  gap: ${spaces[2]};
  padding: 0;
  margin: 0;
  -webkit-app-region: drag;
`;

export const StyledTitle = styled.div`
  ${flex.direction.column};
  -webkit-app-region: no-drag;
`;

export const StyledContainer = styled.div`
  ${flex.alignItems.center};
  ${flex.justifyContent.spaceBetween};
  height: ${RECORDING_HEADER_HEIGHT}px;
  width: 100%;
`;

export const StyledLeft = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  ${color.fg.blackbird};
  ${font.body.m};
  width: 50%;

  .back {
    ${color.fg.blackbird};
    margin-top: -1px;
    margin-right: 16px;
    font-size: 24px;
    text-decoration: none !important;
    -webkit-app-region: no-drag;
  }
`;
