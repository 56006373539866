import React, { useCallback, useEffect, useMemo } from 'react';
import { useSessionStorage } from 'react-use';
import { Helmet } from 'react-helmet';
import * as Layout from '~/components/Layout';

import { useAnalytics } from '@grain/grain-ui';
import { Divider } from '@grain/grain-ui/v4';
import { ViewAdHocSortBy, SortDirection } from '@grain/api/schema.generated';
import { useFeature } from '@grain/api/auth';

import { useUrlErrorBanner } from '~/modules/errors/hooks';
import { useFilteredViewAdHoc } from '~/modules/filters';
import { GatedContentWrapper } from '~/modules/gates';
import { useCRMIntegrations } from './hooks';

import { DealPageHeader } from './DealPageHeader';
import { EmptyDealsState, DealsErrorStates } from './EmptyDealsState';
import { DealsDashboard } from './DealsDashboard/DealsDashboard';

import { useDealPipelinesQuery, useDealStagesQuery } from './deal.generated';

export type SortBy = {
  sortBy: ViewAdHocSortBy;
  sortDirection: SortDirection;
};

export default () => {
  const ErrorBanner = useUrlErrorBanner();
  const { hubspotIntegration, hasCRM, hasHubspot, hasSalesforce } =
    useCRMIntegrations();
  const { enabled } = useFeature('deal_board');

  const getErrorState = (): [DealsErrorStates | null, boolean] => {
    if (!hasCRM) return ['no-crm', true];
    if (hasSalesforce && !hasHubspot) return ['salesforce', true];
    if (hasHubspot && !hubspotIntegration?.hasOwnersAccess) {
      return ['hubspot-no-owners-access', true];
    }
    if (hasHubspot && !hubspotIntegration?.hasEmailsAccess) {
      return ['hubspot-no-emails-access', true];
    }
    return [null, false];
  };
  const [errorState, isWholePageError] = getErrorState();

  const { trackEvent } = useAnalytics();
  const [filterReady, setFilterReady] = React.useState<boolean>(false);
  const sortByState = React.useState<SortBy>({
    sortBy: ViewAdHocSortBy.Relevance,
    sortDirection: SortDirection.Desc
  });

  const {
    queryResponse: dealsQueryResponse,
    filters,
    viewAdHocFilterString,
    resetFilters
  } = useFilteredViewAdHoc({
    filterEntityType: 'deals',
    availableFilters: [
      'title',
      'at_risk',
      'owner_person',
      'persons',
      'groups',
      'date',
      'deal_stage',
      'is_open'
    ],
    hiddenFilters: ['deal_stage', 'is_open'],
    skip: Boolean(!filterReady),
    filterOptions: {
      title: {
        placeholder: 'Filter by deal'
      }
    },
    variables: {
      includeDealV4: true,
      includeIds: true,
      ...sortByState[0]
    }
  });

  const [persistedStageId, setPersistedStageId] = useSessionStorage<
    string | null
  >('deals-stage-id', null);

  // Event tracking for pipeline/stage filter usage
  const lastUsedPipelineId = React.useRef<string | null>(null);
  const lastUsedStageId = React.useRef<string | null>(null);
  useEffect(() => {
    const pipelineId = filters.deal_stage.value?.pipelineId;
    const stageId = filters.deal_stage.value?.stageId;
    if (pipelineId && pipelineId !== lastUsedPipelineId.current) {
      lastUsedPipelineId.current = pipelineId;
      trackEvent('Deals Pipeline Changed', {
        pipeline_id: pipelineId
      });
    }
    if (stageId && stageId !== lastUsedStageId.current) {
      lastUsedStageId.current = stageId;
      trackEvent('Deals Stage Changed');
    }
  }, [filters.deal_stage, trackEvent]);

  // Event tracking for sorting
  const { sortBy, sortDirection } = sortByState[0];
  useEffect(() => {
    trackEvent('Deals Sort Changed', {
      sort_by: sortBy === ViewAdHocSortBy.Relevance ? 'AMOUNT' : sortBy,
      sort_direction: sortDirection
    });
  }, [sortBy, sortDirection, trackEvent]);

  const pipelineResults = useDealPipelinesQuery({
    onCompleted: data => {
      const [pipeline] = data?.dealPipelines;
      if (!pipeline) {
        return;
      }

      if (persistedStageId) {
        filters.deal_stage.toggle({
          stageId: persistedStageId,
          pipelineId: pipeline.pipelineId
        });
        return;
      }

      if (!filters.is_open.value) {
        filters.is_open.toggle();
      }

      filters.deal_stage.toggle({ pipelineId: pipeline.pipelineId });
    }
  });

  const viewAdHocFilterStringWithoutStageFilter = useMemo(() => {
    try {
      const filterObj = JSON.parse(viewAdHocFilterString);
      filterObj.filters = filterObj.filters
        .map((filter: Record<string, unknown>) => {
          if ('stage' in filter) {
            delete filter.stage;
          }
          if ('is_open' in filter) {
            return null;
          }
          return filter;
        })
        .filter(Boolean);
      return JSON.stringify(filterObj);
    } catch (e) {
      return viewAdHocFilterString;
    }
  }, [viewAdHocFilterString]);

  const stageResults = useDealStagesQuery({
    skip: Boolean(!filters.deal_stage.value?.pipelineId),
    variables: { filter: viewAdHocFilterStringWithoutStageFilter },
    onCompleted: () => setFilterReady(true),
    fetchPolicy: 'cache-and-network'
  });

  // Keep track of the last set of filtered deals so we can navigate
  // forward/backward through the results on the deal details page.
  const [, setFilteredDeals] = useSessionStorage<string[]>(
    'filtered_deals',
    []
  );

  useEffect(() => {
    const { data: dealsData } = dealsQueryResponse.results;
    const ids = dealsData?.ids;
    if (ids) setFilteredDeals(ids);
  }, [dealsQueryResponse.results, setFilteredDeals]);

  const setCurrentPipelineId = useCallback(
    (pipelineId: string) => {
      setPersistedStageId(null);
      filters.deal_stage.toggle({ pipelineId });
    },
    [filters, setPersistedStageId]
  );

  const setCurrentStage = useCallback(
    (stageId?: string) => {
      if (!filters.deal_stage.value?.pipelineId) {
        return;
      }

      if (!stageId) {
        setPersistedStageId(null);
        filters.deal_stage.toggle({
          pipelineId: filters.deal_stage.value?.pipelineId
        });
        if (!filters.is_open.value) {
          filters.is_open.toggle();
        }
      } else {
        setPersistedStageId(stageId);
        filters.deal_stage.toggle({
          stageId,
          pipelineId: filters.deal_stage.value?.pipelineId
        });
        if (filters.is_open.value) {
          filters.is_open.toggle();
        }
      }
    },
    [filters, setPersistedStageId]
  );

  return (
    <>
      <Helmet title='Deals' />
      <Layout.Wrapper withSidebar>
        <>
          <ErrorBanner />
          <DealPageHeader
            currentPipelineId={filters.deal_stage.value?.pipelineId}
            setCurrentPipelineId={setCurrentPipelineId}
            pipelineResults={pipelineResults}
            hideMenu={!enabled || isWholePageError}
          />
          {!enabled && <Divider />}
          <GatedContentWrapper gate='deal_board'>
            {isWholePageError ? (
              // isWholePageError implies an errorState
              <EmptyDealsState errorState={errorState!} />
            ) : (
              <DealsDashboard
                sortByState={sortByState}
                stageResults={stageResults}
                filters={filters}
                dealsQueryResponse={dealsQueryResponse}
                currentStage={filters.deal_stage.value?.stageId}
                setCurrentStage={setCurrentStage}
                resetFilters={resetFilters}
              />
            )}
          </GatedContentWrapper>
        </>
      </Layout.Wrapper>
    </>
  );
};
