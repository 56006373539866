// @ts-strict-ignore
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from '@emotion/styled';
import {
  findClosestPartToTimestamp,
  parseTranscriptPart,
  type Transcript as TranscriptType,
  TRANSCRIPT_LIVE_HORIZONTAL_PADDING_PX,
  useLiveTranscriptQueue
} from '@grain/grain-ui';
import { useWebSocket } from '@grain/components/WebSocketContext';

import type { SpeakerChangedEvent, TranscriptPartAddedEvent } from '../types';
import { mouseIsDownState } from '../state';
import { useRecordingPage } from '../context';
import { useTranscriptState } from '../transcriptContext';
import { useRecordingPageSubscriptions } from '../hooks';
import { useTranscriptFont } from '../Sections/Transcript/hooks';
import TranscriptInstruments from '../Sections/Transcript/Instruments';
import { useLiveSpeakerChangedHandler } from '../Sections/Transcript/Speakers/hooks';
import TranscriptComponent from '../Sections/Transcript/Transcript';

import { useLiveRecordingPageDebugFunctions } from './hooks';
import { LiveRecordingHeader } from './LiveRecordingHeader';
import { ProcessingTranscript } from './ProcessingTranscript';

const SCROLL_DURATION = 100;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px auto 0 auto;
  max-width: 656px;
  width: 100%;
  height: 100%;

  @media (min-width: 1440px) {
    // Maintain ~53.5% width ratio relative to container, from mockups
    max-width: calc(656 / 1224 * 100%);
  }
`;

const StyledTranscriptWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 0%;
  overflow: hidden;
`;

export function LiveRecordingPage() {
  const { recordingChannel } = useWebSocket();
  const { recordingRes } = useRecordingPage();
  const { transcript, setTranscript, transcriptLastUpdated } =
    useTranscriptState();
  const { data } = recordingRes;
  const recording = data?.recording;
  const recordingId = recording?.id;

  const mouseIsDown = useRecoilValue(mouseIsDownState);
  const rawTranscriptResultsRef = useRef([]);

  useTranscriptFont();
  useRecordingPageSubscriptions(recording);

  useLiveRecordingPageDebugFunctions({
    recordingId
  });

  const liveTranscriptQueue = useLiveTranscriptQueue({
    paused: mouseIsDown,
    onAddWord: useCallback(
      rawPart => {
        const lastRawPart =
          rawTranscriptResultsRef.current[
            rawTranscriptResultsRef.current.length - 1
          ];

        // raw transcript parts are [start, value, end]
        if (lastRawPart && rawPart[0] < lastRawPart[2]) {
          // Sometimes out of order results arrive... ignore those.
          // They will be fixed during processing after live is over.
          return;
        }

        rawTranscriptResultsRef.current = [
          ...rawTranscriptResultsRef.current,
          rawPart
        ];
        setTranscript(transcript => {
          // Parse only the given word, and take the previous word into account.
          return transcript.concat(
            parseTranscriptPart(rawPart, transcript[transcript.length - 1])
          );
        });
      },
      [rawTranscriptResultsRef, setTranscript]
    )
  });

  const [speakerChangedEventQueue, setSpeakerChangedEventQueue] = useState<
    SpeakerChangedEvent[]
  >([]);
  const speakerChanged = useLiveSpeakerChangedHandler();

  useEffect(() => {
    // Don't process speaker change events until the transcript word
    // matching the event is fully loaded in.
    const next = speakerChangedEventQueue[0];

    // See if one of the recent words matches this speaker_change.
    const lastPart = transcript[transcript.length - 1];

    // TODO stop using matching part
    let matchingPart;
    if (lastPart?.start <= next?.start_ms && lastPart?.end >= next?.start_ms) {
      matchingPart = lastPart;
    } else if (next?.start_ms < lastPart?.start) {
      // If the events were received in such an order that the desired start for this speaker
      // has already been rendered, then find the match
      matchingPart = transcript
        .slice()
        .reverse()
        .find(part => {
          return part.end > next?.start_ms;
        });
    }

    if (matchingPart) {
      speakerChanged(next);
      setSpeakerChangedEventQueue(queue => queue.slice(1));
    }
  }, [transcript, speakerChangedEventQueue, speakerChanged]);

  const transcriptPartAdded = useCallback(
    ({ results }: TranscriptPartAddedEvent) => {
      liveTranscriptQueue.addWords(results);
    },
    [liveTranscriptQueue]
  );

  const handleSpeakerChanged = useCallback(
    (message: SpeakerChangedEvent) => {
      // Do not process speaker change events immediately, the word matching
      // the speaker change may not have been rendered yet.
      setSpeakerChangedEventQueue(queue => [...queue, message]);
    },
    [setSpeakerChangedEventQueue]
  );

  useEffect(() => {
    if (!recordingId) return;

    recordingChannel.pushMessage(
      'in_recording',
      { status: true },
      { ignoreResponse: true }
    );

    recordingChannel.connect(`recording:${recordingId}`);
  }, [recordingChannel, recordingId]);

  useEffect(() => {
    const unlisteners = [
      recordingChannel.onMessage('transcript_part_added', transcriptPartAdded),
      recordingChannel.onMessage('speaker_changed', handleSpeakerChanged)
    ];

    return () => unlisteners.forEach(fn => fn());
  }, [transcriptPartAdded, handleSpeakerChanged, recordingChannel]);

  const [currentPart, setCurrentPart] = useState<TranscriptType>(null);

  const handleSearchResult = useCallback(
    (timestampToJump: number) => {
      const part = findClosestPartToTimestamp(transcript, timestampToJump);
      setCurrentPart(part);
    },
    [transcript]
  );

  const handleSearchEnd = useCallback(() => {
    setCurrentPart(null);
  }, []);

  return (
    <>
      <LiveRecordingHeader css={['margin: 0;']} />
      <StyledWrapper>
        {transcript.length > 0 && (
          <>
            <TranscriptInstruments
              transcript={transcript}
              onSearchResult={handleSearchResult}
              onSearchEnd={handleSearchEnd}
              recordingId={recording?.id}
              transcriptEditable={false}
            />
            <StyledTranscriptWrapper>
              <TranscriptComponent
                horizontalPadding={TRANSCRIPT_LIVE_HORIZONTAL_PADDING_PX}
                currentPart={currentPart}
                recordingState={recording?.state}
                transcriptState={recording?.transcript?.state}
                scrollDuration={SCROLL_DURATION}
                trimStartMs={recording?.trimStartMs ?? 0}
              />
            </StyledTranscriptWrapper>
          </>
        )}
        <ProcessingTranscript lastUpdated={transcriptLastUpdated} />
      </StyledWrapper>
    </>
  );
}
