import styled from '@emotion/styled';
import { color, pxToRem, spacing } from '@grain/grain-ui';

export const StyledContentContainer = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  .options {
    border-radius: 12px;
    ${spacing.my6};
    border: 1px solid ${color.gull};
    ${color.bg.goose};
    ${color.fg.blackbird};
    font-feature-settings:
      'cpsp' on,
      'case' on,
      'clig' off,
      'liga' off;
    font-size: ${pxToRem(14)};
    line-height: ${pxToRem(18)};
    font-style: normal;
    font-weight: 400;
    padding: 24px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .option-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;

    .icon {
      ${color.fg.blackbird};
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .bold {
    font-weight: 700;
    ${color.fg.blackbird};
  }

  .header {
    ${color.fg.blackbird};
    text-align: center;
    font-feature-settings:
      'cpsp' on,
      'case' on;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(20)};
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  .sub-heading {
    ${color.fg.crow};
    text-align: center;
    font-feature-settings:
      'cpsp' on,
      'case' on;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(20)};
    margin-top: 12px;
  }

  .action-buttons {
    display: flex;
    align-items: flex-start;
    gap: 16px;
  }
`;
