import * as Types from '@grain/api/schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@grain/api/graphql';
const defaultOptions = {} as const;
export type SetCustomVocabularyMutationVariables = Types.Exact<{
  items: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type SetCustomVocabularyMutation = { __typename?: 'RootMutationType', customVocabularySet: { __typename?: 'CustomVocabulary', items: Array<string> } };

export type GetCustomVocabularyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCustomVocabularyQuery = { __typename?: 'RootQueryType', workspace: { __typename?: 'Workspace', id: string, customVocabulary: { __typename?: 'CustomVocabulary', items: Array<string> } } };

export type AddWorkspaceDomainMutationVariables = Types.Exact<{
  domain: Types.Scalars['String']['input'];
  force: Types.Scalars['Boolean']['input'];
}>;


export type AddWorkspaceDomainMutation = { __typename?: 'RootMutationType', workspaceDomainAdd: { __typename?: 'Workspace', id: string } };

export type RemoveWorkspaceDomainMutationVariables = Types.Exact<{
  domain: Types.Scalars['String']['input'];
}>;


export type RemoveWorkspaceDomainMutation = { __typename?: 'RootMutationType', workspaceDomainRemove: { __typename?: 'Workspace', id: string } };

export type UnapproveWorkspaceAuthorizedDomainMutationVariables = Types.Exact<{
  domain: Types.Scalars['String']['input'];
}>;


export type UnapproveWorkspaceAuthorizedDomainMutation = { __typename?: 'RootMutationType', workspaceAuthorizedDomainUnapprove: { __typename?: 'Workspace', id: string, authorizedDomains: Array<{ __typename?: 'AuthorizedDomain', domain: string, approved: boolean }> } };

export type ApproveWorkspaceAuthorizedDomainMutationVariables = Types.Exact<{
  domain: Types.Scalars['String']['input'];
}>;


export type ApproveWorkspaceAuthorizedDomainMutation = { __typename?: 'RootMutationType', workspaceAuthorizedDomainApprove: { __typename?: 'Workspace', id: string, authorizedDomains: Array<{ __typename?: 'AuthorizedDomain', domain: string, approved: boolean }> } };


export const SetCustomVocabularyDocument = gql`
    mutation setCustomVocabulary($items: [String!]!) {
  customVocabularySet(items: $items) {
    items
  }
}
    `;
export type SetCustomVocabularyMutationFn = Apollo.MutationFunction<SetCustomVocabularyMutation, SetCustomVocabularyMutationVariables>;

/**
 * __useSetCustomVocabularyMutation__
 *
 * To run a mutation, you first call `useSetCustomVocabularyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCustomVocabularyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCustomVocabularyMutation, { data, loading, error }] = useSetCustomVocabularyMutation({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useSetCustomVocabularyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetCustomVocabularyMutation, SetCustomVocabularyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SetCustomVocabularyMutation, SetCustomVocabularyMutationVariables>(SetCustomVocabularyDocument, options);
      }
export type SetCustomVocabularyMutationHookResult = ReturnType<typeof useSetCustomVocabularyMutation>;
export type SetCustomVocabularyMutationResult = Apollo.MutationResult<SetCustomVocabularyMutation>;
export type SetCustomVocabularyMutationOptions = Apollo.BaseMutationOptions<SetCustomVocabularyMutation, SetCustomVocabularyMutationVariables>;
export const GetCustomVocabularyDocument = gql`
    query getCustomVocabulary {
  workspace {
    id
    customVocabulary {
      items
    }
  }
}
    `;

/**
 * __useGetCustomVocabularyQuery__
 *
 * To run a query within a React component, call `useGetCustomVocabularyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomVocabularyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomVocabularyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomVocabularyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomVocabularyQuery, GetCustomVocabularyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCustomVocabularyQuery, GetCustomVocabularyQueryVariables>(GetCustomVocabularyDocument, options);
      }
export function useGetCustomVocabularyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomVocabularyQuery, GetCustomVocabularyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCustomVocabularyQuery, GetCustomVocabularyQueryVariables>(GetCustomVocabularyDocument, options);
        }
export function useGetCustomVocabularySuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetCustomVocabularyQuery, GetCustomVocabularyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetCustomVocabularyQuery, GetCustomVocabularyQueryVariables>(GetCustomVocabularyDocument, options);
        }
export type GetCustomVocabularyQueryHookResult = ReturnType<typeof useGetCustomVocabularyQuery>;
export type GetCustomVocabularyLazyQueryHookResult = ReturnType<typeof useGetCustomVocabularyLazyQuery>;
export type GetCustomVocabularySuspenseQueryHookResult = ReturnType<typeof useGetCustomVocabularySuspenseQuery>;
export type GetCustomVocabularyQueryResult = Apollo.QueryResult<GetCustomVocabularyQuery, GetCustomVocabularyQueryVariables>;
export const AddWorkspaceDomainDocument = gql`
    mutation addWorkspaceDomain($domain: String!, $force: Boolean!) {
  workspaceDomainAdd(domain: $domain, merge: $force) {
    id
  }
}
    `;
export type AddWorkspaceDomainMutationFn = Apollo.MutationFunction<AddWorkspaceDomainMutation, AddWorkspaceDomainMutationVariables>;

/**
 * __useAddWorkspaceDomainMutation__
 *
 * To run a mutation, you first call `useAddWorkspaceDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkspaceDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkspaceDomainMutation, { data, loading, error }] = useAddWorkspaceDomainMutation({
 *   variables: {
 *      domain: // value for 'domain'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useAddWorkspaceDomainMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddWorkspaceDomainMutation, AddWorkspaceDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddWorkspaceDomainMutation, AddWorkspaceDomainMutationVariables>(AddWorkspaceDomainDocument, options);
      }
export type AddWorkspaceDomainMutationHookResult = ReturnType<typeof useAddWorkspaceDomainMutation>;
export type AddWorkspaceDomainMutationResult = Apollo.MutationResult<AddWorkspaceDomainMutation>;
export type AddWorkspaceDomainMutationOptions = Apollo.BaseMutationOptions<AddWorkspaceDomainMutation, AddWorkspaceDomainMutationVariables>;
export const RemoveWorkspaceDomainDocument = gql`
    mutation removeWorkspaceDomain($domain: String!) {
  workspaceDomainRemove(domain: $domain) {
    id
  }
}
    `;
export type RemoveWorkspaceDomainMutationFn = Apollo.MutationFunction<RemoveWorkspaceDomainMutation, RemoveWorkspaceDomainMutationVariables>;

/**
 * __useRemoveWorkspaceDomainMutation__
 *
 * To run a mutation, you first call `useRemoveWorkspaceDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkspaceDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkspaceDomainMutation, { data, loading, error }] = useRemoveWorkspaceDomainMutation({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useRemoveWorkspaceDomainMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveWorkspaceDomainMutation, RemoveWorkspaceDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveWorkspaceDomainMutation, RemoveWorkspaceDomainMutationVariables>(RemoveWorkspaceDomainDocument, options);
      }
export type RemoveWorkspaceDomainMutationHookResult = ReturnType<typeof useRemoveWorkspaceDomainMutation>;
export type RemoveWorkspaceDomainMutationResult = Apollo.MutationResult<RemoveWorkspaceDomainMutation>;
export type RemoveWorkspaceDomainMutationOptions = Apollo.BaseMutationOptions<RemoveWorkspaceDomainMutation, RemoveWorkspaceDomainMutationVariables>;
export const UnapproveWorkspaceAuthorizedDomainDocument = gql`
    mutation unapproveWorkspaceAuthorizedDomain($domain: String!) {
  workspaceAuthorizedDomainUnapprove(domain: $domain) {
    id
    authorizedDomains {
      domain
      approved
    }
  }
}
    `;
export type UnapproveWorkspaceAuthorizedDomainMutationFn = Apollo.MutationFunction<UnapproveWorkspaceAuthorizedDomainMutation, UnapproveWorkspaceAuthorizedDomainMutationVariables>;

/**
 * __useUnapproveWorkspaceAuthorizedDomainMutation__
 *
 * To run a mutation, you first call `useUnapproveWorkspaceAuthorizedDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnapproveWorkspaceAuthorizedDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unapproveWorkspaceAuthorizedDomainMutation, { data, loading, error }] = useUnapproveWorkspaceAuthorizedDomainMutation({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useUnapproveWorkspaceAuthorizedDomainMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnapproveWorkspaceAuthorizedDomainMutation, UnapproveWorkspaceAuthorizedDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UnapproveWorkspaceAuthorizedDomainMutation, UnapproveWorkspaceAuthorizedDomainMutationVariables>(UnapproveWorkspaceAuthorizedDomainDocument, options);
      }
export type UnapproveWorkspaceAuthorizedDomainMutationHookResult = ReturnType<typeof useUnapproveWorkspaceAuthorizedDomainMutation>;
export type UnapproveWorkspaceAuthorizedDomainMutationResult = Apollo.MutationResult<UnapproveWorkspaceAuthorizedDomainMutation>;
export type UnapproveWorkspaceAuthorizedDomainMutationOptions = Apollo.BaseMutationOptions<UnapproveWorkspaceAuthorizedDomainMutation, UnapproveWorkspaceAuthorizedDomainMutationVariables>;
export const ApproveWorkspaceAuthorizedDomainDocument = gql`
    mutation approveWorkspaceAuthorizedDomain($domain: String!) {
  workspaceAuthorizedDomainApprove(domain: $domain) {
    id
    authorizedDomains {
      domain
      approved
    }
  }
}
    `;
export type ApproveWorkspaceAuthorizedDomainMutationFn = Apollo.MutationFunction<ApproveWorkspaceAuthorizedDomainMutation, ApproveWorkspaceAuthorizedDomainMutationVariables>;

/**
 * __useApproveWorkspaceAuthorizedDomainMutation__
 *
 * To run a mutation, you first call `useApproveWorkspaceAuthorizedDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveWorkspaceAuthorizedDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveWorkspaceAuthorizedDomainMutation, { data, loading, error }] = useApproveWorkspaceAuthorizedDomainMutation({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useApproveWorkspaceAuthorizedDomainMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveWorkspaceAuthorizedDomainMutation, ApproveWorkspaceAuthorizedDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ApproveWorkspaceAuthorizedDomainMutation, ApproveWorkspaceAuthorizedDomainMutationVariables>(ApproveWorkspaceAuthorizedDomainDocument, options);
      }
export type ApproveWorkspaceAuthorizedDomainMutationHookResult = ReturnType<typeof useApproveWorkspaceAuthorizedDomainMutation>;
export type ApproveWorkspaceAuthorizedDomainMutationResult = Apollo.MutationResult<ApproveWorkspaceAuthorizedDomainMutation>;
export type ApproveWorkspaceAuthorizedDomainMutationOptions = Apollo.BaseMutationOptions<ApproveWorkspaceAuthorizedDomainMutation, ApproveWorkspaceAuthorizedDomainMutationVariables>;