import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useExperiment, useWorkspace } from '@grain/api/auth';
import { Icon, Skeleton } from '@grain/grain-ui';
import { Button, theme } from '@grain/grain-ui/v4';

import type {
  DealActivityQuery,
  DealDetailsFragment,
  DealDetailsV4Fragment
} from '../../deal.generated';
import type { RecordingWithFeedback } from '../../types';
import { getAtRiskReasons } from '../../utils';
import { TimelineExplanationTooltip } from '../../TimelineExplanationTooltip';

import { MomentumTimeline, SkeletonTimeline } from './MomentumTimeline';
import { MomentumState } from './MomentumState';
import { LastActiveTable, SkeletonLastActiveTable } from './LastActiveTable';
import { TimeInStageTable, SkeletonTimeInStageTable } from './TimeInStageTable';
import { PerformanceState } from './PerformanceState';
import { DealOwner, SkeletonDealOwner } from './DealOwner';
import {
  MeetingWithScore,
  MeetingWithScoreEmptyState,
  SkeletonMeetingWithScore
} from './MeetingWithScore';
import { DealDiagnosis } from '~/components/DealDiagnosis/DealDiagnosis';

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.tokens.color.borderTertiary};
  border-radius: ${theme.tokens.radii.xl};
`;

const StyledAlertCard = styled(StyledCard)`
  padding: ${theme.tokens.spacing.lg};
  background-color: ${theme.tokens.color.surfaceSecondary};
`;

const StyledAlertText = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.tokens.spacing.sm};
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textSecondary};

  svg {
    color: ${theme.tokens.color.iconDanger};
  }
`;

const StyledCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${theme.tokens.spacing['2xl']};
  height: 56px;
`;

const StyledCardHeaderGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.tokens.spacing.sm};
`;

const StyledCardTitle = styled.span`
  ${theme.tokens.typography.b2[500]};
`;

const StyledCardDescription = styled.span`
  ${theme.tokens.typography.b4[500]};
  color: ${theme.tokens.color.textSecondary};
`;

const StyledCardHeaderPill = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTimelineContainer = styled.div`
  padding: ${theme.tokens.spacing['2xl']} ${theme.tokens.spacing['2xl']}
    ${theme.tokens.spacing['3xl']};
`;

const StyledTables = styled.div`
  display: flex;
  gap: ${theme.tokens.spacing.lg};
  padding: 0 ${theme.tokens.spacing.lg};

  // Container div prevents tables from growing vertically:
  > div {
    flex: 1;
  }

  > div > table {
    width: 100%;
  }
`;

type OverviewProps = {
  deal: DealDetailsFragment & DealDetailsV4Fragment;
  activityData: DealActivityQuery;
};

export function Overview({ deal, activityData }: OverviewProps) {
  const { workspace } = useWorkspace();
  const { enabled: isDealDiagnosisEnabled } = useExperiment('deal_diagnosis');

  const recordingsWithScores = useMemo(
    () =>
      deal.recordings.filter(recording => {
        const overall = recording.salesCoaching?.scoring?.scoreCard?.overall;
        return typeof overall === 'number';
      }, [] as RecordingWithFeedback[]),
    [deal.recordings]
  );

  const atRiskReasons = useMemo(
    () => getAtRiskReasons(deal.atRisk, deal.momentum.state, deal.callScore),
    [deal.atRisk, deal.momentum.state, deal.callScore]
  );

  // Build an owner from the Person with a supplemented avatarUrl from the User
  // associated with the Person, if any.  Do it this way because a Person is not
  // guaranteed to have an associated User, but at least we'd still have a name
  // in that case.
  const owner = deal.owner
    ? {
        avatarUrl: deal.owner.user?.avatarUrl || undefined,
        name: deal.owner.name
      }
    : undefined;

  if (!workspace) return null;

  return (
    <>
      {Boolean(atRiskReasons.length) && (
        <StyledAlertCard>
          <StyledAlertText>
            <Icon.Important />
            <span>
              Deal flagged at risk due to {atRiskReasons.join(' and ')}.
            </span>
          </StyledAlertText>
        </StyledAlertCard>
      )}
      {isDealDiagnosisEnabled && (
        <DealDiagnosis
          diagnosis={deal?.diagnosis}
          companyName={deal?.group.name}
          disabled={!deal?.isOpen}
        />
      )}
      <StyledCard>
        <StyledCardHeader>
          <StyledCardHeaderGroup>
            <StyledCardTitle>Momentum</StyledCardTitle>
            <StyledCardDescription>Last 30 days</StyledCardDescription>
          </StyledCardHeaderGroup>
          <StyledCardHeaderGroup>
            <TimelineExplanationTooltip>
              <Button
                icon={Icon.Help16x16}
                variant='ghost'
                css={[`color: ${theme.tokens.color.iconSecondary};`]}
                aria-label='Show momentum timeline explanation'
              />
            </TimelineExplanationTooltip>
            <StyledCardHeaderPill>
              <MomentumState isOpen={deal.isOpen} state={deal.momentum.state} />
            </StyledCardHeaderPill>
          </StyledCardHeaderGroup>
        </StyledCardHeader>
        <StyledTimelineContainer>
          <MomentumTimeline
            workspace={workspace}
            deal={deal}
            group={deal.group}
            activity={activityData.deal.activity}
            activityBar={deal.activityBar}
            momentumUpdates={deal.momentumUpdates}
            stageUpdates={deal.stageUpdates}
          />
        </StyledTimelineContainer>
        <StyledTables>
          <div>
            <LastActiveTable
              workspace={workspace}
              group={deal.group}
              closedAt={deal.closedAt}
              latestCustomerActivity={deal.latestCustomerActivity}
              latestTeamActivity={deal.latestTeamActivity}
            />
          </div>
          {deal.stage && (
            <div>
              <TimeInStageTable
                stageUpdates={[
                  {
                    id: '1',
                    date: deal.latestStageChange ?? '',
                    stage: deal.stage
                  }
                ]}
              />
            </div>
          )}
        </StyledTables>
      </StyledCard>
      <StyledCard>
        <StyledCardHeader>
          <StyledCardHeaderGroup>
            <StyledCardTitle>Scorecard Average</StyledCardTitle>
          </StyledCardHeaderGroup>
          <StyledCardHeaderPill>
            <PerformanceState isOpen={deal.isOpen} score={deal.callScore} />
          </StyledCardHeaderPill>
        </StyledCardHeader>
        {owner && <DealOwner owner={owner} />}
        {recordingsWithScores.length > 0 ? (
          recordingsWithScores.map(recording => (
            <MeetingWithScore
              key={recording.id}
              deal={deal}
              recording={recording}
            />
          ))
        ) : (
          <MeetingWithScoreEmptyState>
            No meetings associated with this deal.
          </MeetingWithScoreEmptyState>
        )}
      </StyledCard>
    </>
  );
}

export function SkeletonOverview() {
  return (
    <>
      <DealDiagnosis loading />
      <StyledCard>
        <SkeletonCardHeader />
        <StyledTimelineContainer>
          <SkeletonTimeline />
        </StyledTimelineContainer>
        <StyledTables>
          <div>
            <SkeletonLastActiveTable />
          </div>
          <div>
            <SkeletonTimeInStageTable />
          </div>
        </StyledTables>
      </StyledCard>
      <StyledCard>
        <SkeletonCardHeader />
        <SkeletonDealOwner />
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <SkeletonMeetingWithScore key={index} />
          ))}
      </StyledCard>
    </>
  );
}

function SkeletonCardHeader() {
  return (
    <StyledCardHeader>
      <Skeleton
        width={162}
        height={18}
        css={[`border-radius: ${theme.tokens.radii.md};`]}
      />
      <Skeleton
        width={60}
        height={24}
        css={[`border-radius: ${theme.tokens.radii.full};`]}
      />
    </StyledCardHeader>
  );
}
