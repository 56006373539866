import Overflow from 'react-overflow-indicator';
import PropTypes from 'prop-types';
import { spacing } from '@grain/styles/constants';

import UserCard from '~/components/UserCard';
import WorkspaceCard from '../WorkspaceCard';
import GuestCard from '../GuestCard';

import { StyledShadow } from './styles';

MembersList.propTypes = {
  users: PropTypes.array,
  onWorkspaceToggle: PropTypes.func,
  sharedToAll: PropTypes.bool,
  isWorkspaceDisabled: PropTypes.bool,
  owner: PropTypes.object,
  onUserUnshare: PropTypes.func
};

const EMPTY_ARRAY = [];
const NOOP = checked => {};

export default function MembersList({
  isWorkspaceDisabled,
  onUserUnshare = NOOP,
  onWorkspaceToggle = NOOP,
  owner,
  sharedToAll,
  users = EMPTY_ARRAY
}) {
  return (
    <Overflow
      tolerance={1}
      css={[
        spacing.mx6,
        spacing.mt6,
        spacing.mb8,
        'flex: 1; overflow: auto; min-height: 200px;'
      ]}
    >
      <Overflow.Content css={['width: 100%;']}>
        <WorkspaceCard
          handleWorkspaceToggle={onWorkspaceToggle}
          sharedToAll={sharedToAll}
          disabled={isWorkspaceDisabled}
        />
        <UserCard user={owner} isOwner css={[spacing.py3, spacing.px6]} />
        {users.map(user => {
          return user.id !== undefined ? (
            <UserCard
              key={user.id}
              user={user}
              onDelete={() => onUserUnshare(user)}
              css={[spacing.py3, spacing.px6]}
            />
          ) : (
            <GuestCard
              key={user}
              email={user}
              onDelete={() => onUserUnshare(user)}
            />
          );
        })}
      </Overflow.Content>
      <Overflow.Indicator direction='up'>
        {canScroll => <StyledShadow direction='up' isVisible={canScroll} />}
      </Overflow.Indicator>
      <Overflow.Indicator direction='down'>
        {canScroll => <StyledShadow direction='down' isVisible={canScroll} />}
      </Overflow.Indicator>
    </Overflow>
  );
}
