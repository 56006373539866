import { Button, Divider, TextLabel, theme } from '@grain/grain-ui/v4';
import AppIcon from './app-icon.png';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

type WindowsDownloadProps = {
  onDownloadAgain: () => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${theme.tokens.spacing.lg};
  width: 100%;
  min-height: calc(100vh - 200px);
`;

const Image = styled.img`
  width: 78px;
  height: 78px;
`;

const Title = styled.h1`
  ${theme.tokens.typography.d2}
  margin-bottom: 0;
`;

const DownloadAgainContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.tokens.spacing['3xs']};
`;

const Text = styled(TextLabel)`
  ${theme.tokens.typography.b1[400]}
  font-size: 16px;
  color: ${theme.tokens.color.textSecondary};
`;

export const WindowsDownload = ({ onDownloadAgain }: WindowsDownloadProps) => {
  return (
    <Container>
      <Image src={AppIcon} alt='Grain app icon' />
      <Title>Grain is downloading&hellip;</Title>
      <Text>Open your Downloads folder, and select the .zip file</Text>
      <Divider
        css={css`
          width: 450px;
        `}
      />
      <DownloadAgainContainer>
        <Text>Problems?</Text>
        <Button
          variant='ghost'
          onClick={onDownloadAgain}
          css={css`
            & * {
              text-decoration: underline;
              color: ${theme.tokens.color.textSecondary};
            }
          `}
        >
          Download again
        </Button>
      </DownloadAgainContainer>
    </Container>
  );
};
