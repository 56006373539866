import styled from '@emotion/styled';
import { spacing } from '@grain/styles/constants';
import { flex, overflowEllipsis } from '@grain/grain-ui';

export const StyledWrapper = styled.div`
  ${flex.alignItems.center};
  ${overflowEllipsis};
  ${spacing.px6};
  ${spacing.py3};
  width: 100%;
  position: relative;
`;
