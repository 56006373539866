import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '@grain/components/Button';
import {
  ErrorBox,
  Icon,
  color,
  useRouteModal,
  useShowToast,
  spacing
} from '@grain/grain-ui';
import { useQuery, useMutation } from '@grain/api/graphql';
import * as Recoil from 'recoil';
import useMount from 'react-use/lib/useMount';

import { displayCents } from '~/support/price';
import BillingStatus from '~/modules/subscriptions/BillingStatus';
import PlanCard from '~/modules/subscriptions/PlanCard';

import { billingPreviewIsYearlyState } from '~/modules/subscriptions/state';
import { useWorkspace } from '@grain/components/Workspace/hooks';
import { workspaceMembersQuery } from '@grain/api/graphql/queries';
import { PlanFeaturesDocument } from '@grain/api/graphql/queries/planFeatures.generated';
import {
  subscriptionPreviewQuery,
  createSubscriptionMutation
} from '~/modules/subscriptions/graphql';
import {
  usePlan,
  usePlans,
  useWorkspacePlan
} from '@grain/components/Subscriptions/hooks';
import { getStripe } from '~/modules/subscriptions/helpers';
import { DOWNGRADE_CONFIRM_ROUTE_ID } from '@grain/components/modals/constants';

import {
  StyledContainer,
  StyledDivider,
  StyledPlansContainer,
  StyledNext,
  StyledFooter
} from './styles';
import { getFromToTemplate } from './templates';
import DowngradeConfirmModal from './DowngradeConfirm';

ChangePlan.propTypes = {
  toSku: PropTypes.string.isRequired
};

export default function ChangePlan({ toSku }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const showToast = useShowToast();

  const { subscriptionPlans, loading: plansLoading } = usePlans();

  const [validationError, setValidationError] = React.useState();
  const { open: openDowngradeConfirmModal, isOpen: downgradeConfirmIsOpen } =
    useRouteModal(DOWNGRADE_CONFIRM_ROUTE_ID);

  const [isYearly, setIsYearly] = Recoil.useRecoilState(
    billingPreviewIsYearlyState
  );
  const selectedBillingPeriod = isYearly ? 'YEARLY' : 'MONTHLY';

  const subscriptionPreviewRes = useQuery(subscriptionPreviewQuery, {
    variables: {
      fetchPolicy: 'network-only',
      subscriptionArgs: {
        planSku: toSku,
        billingPeriod: selectedBillingPeriod
      }
    }
  });
  const subscriptionPreview = subscriptionPreviewRes.data?.subscriptionPreview;

  useMount(() => {
    // Refetch each time page is loaded with cached data
    if (subscriptionPreview) {
      subscriptionPreviewRes.refetch();
    }
  });

  const toPlanRes = usePlan(toSku);
  const workspacePlanRes = useWorkspacePlan();
  const workspaceRes = useWorkspace();

  const responses = [
    toPlanRes,
    workspacePlanRes,
    workspaceRes,
    subscriptionPreviewRes
  ];
  const loading = responses.some(r => r.loading);
  const error = responses.map(r => r.error).find(Boolean) || validationError;

  const workspace = workspaceRes.workspace;
  const workspaceSubscription = workspace?.workspaceSubscription;
  const isTrial =
    workspaceSubscription?.trial && !workspaceSubscription?.autoRenew;
  const isCycleChangeable =
    isTrial || workspaceSubscription?.planDetails?.isFree;

  // Set billing period to current subscription length if workspace is unable to change their billing period (isCycleChangeable)
  React.useEffect(() => {
    if (!workspaceSubscription || isCycleChangeable) return;
    const workspaceIsYearly = workspaceSubscription.billingPeriod === 'YEARLY';
    setIsYearly(workspaceIsYearly);
  }, [isCycleChangeable, setIsYearly, workspaceSubscription]);

  const currentPlan =
    subscriptionPlans?.find(
      plan => plan.sku === workspacePlanRes.subscriptionPlan.sku
    ) || {};

  const toPlan = subscriptionPlans?.find(plan => plan.sku === toSku) || {};

  // Hold current plan on load in ref. The hook will automatically update when a new
  // subscription is created, but we need to use the previous currentPlan
  const onloadPlanRef = React.useRef();
  if (!onloadPlanRef.current && workspacePlanRes.subscriptionPlan) {
    onloadPlanRef.current = workspacePlanRes.subscriptionPlan;
  }

  const isPaymentMethodRequired = toPlan?.monthlyPrice !== 0;
  const hasPaymentMethod = !!workspaceSubscription?.cardLastFour;

  // Handle React cycle delay
  const templateFn = getFromToTemplate(onloadPlanRef.current, toPlan);
  const templateArgs = {
    workspaceSubscription,
    currentPlan: onloadPlanRef?.current,
    toPlan,
    subscriptionPreview
  };

  const [createSubscription, createSubscriptionRes] = useMutation(
    createSubscriptionMutation,
    {
      onCompleted() {
        if (onloadPlanRef.current?.monthlyPrice < toPlan?.monthlyPrice) {
          navigate(getRedirectTo(), { replace: true });
          showToast({
            content: `Your workspace has been switched to ${toPlan?.name}.`,
            type: 'success'
          });
        } else {
          openDowngradeConfirmModal();
        }
      }
    }
  );

  const handleSubmit = async () => {
    const changePlan = async () => {
      setValidationError(null);
      return createSubscription({
        variables: {
          subscriptionArgs: {
            planSku: toSku,
            billingPeriod: selectedBillingPeriod
          }
        },
        refetchQueries: [
          {
            query: workspaceMembersQuery,
            fetchPolicy: 'network-only'
          },
          {
            query: PlanFeaturesDocument,
            fetchPolicy: 'network-only'
          }
        ]
      });
    };

    if (isPaymentMethodRequired && !hasPaymentMethod) {
      return setValidationError(
        new Error('Please provide billing information.')
      );
    }
    const { errors } = await changePlan();
    const graphQLError = (errors?.graphQLErrors || [])[0];

    if (graphQLError?.reason === 'confirm_card') {
      const stripe = await getStripe();
      await stripe.confirmCardPayment(graphQLError.secret);
    }
  };

  function handleNavigateBack() {
    if (searchParams.get('from')) {
      navigate(searchParams.get('from'), { replace: true });
    } else {
      navigate(-1);
    }
  }

  const getRedirectTo = () => {
    return searchParams.get('from') || '/app/settings/workspace?tab=plans';
  };

  if (onloadPlanRef?.current?.sku === toSku) {
    return (
      <ErrorBox variant='minimal'>
        Cannot change plan to {toSku}, since workspace is already on plan{' '}
        {onloadPlanRef?.current?.sku}.
      </ErrorBox>
    );
  }

  // Empty loading state (should be fast enough)
  if (loading || !workspace) return null;

  if (error) {
    return <ErrorBox variant='minimal'>{error.message}</ErrorBox>;
  }

  if (!loading && !templateFn) {
    return (
      <ErrorBox variant='minimal'>
        Something went wrong. Please try again. If the problem persists, please
        contact{' '}
        <a
          css={['color: inherit; font-weight: 600;']}
          href='mailto:help@grain.com'
        >
          help@grain.co
        </a>
      </ErrorBox>
    );
  }

  const billingPeriodsAreDifferent =
    workspaceSubscription &&
    workspaceSubscription?.billingPeriod !== subscriptionPreview?.billingPeriod;

  return (
    <>
      <StyledContainer>
        <StyledPlansContainer>
          <PlanCard
            variant='small'
            plan={currentPlan}
            loading={plansLoading}
            currentPlanSku={onloadPlanRef.current?.sku}
            forcePrice={workspaceSubscription?.billingPeriod}
            showBillingPeriod={!toPlan?.isFree && billingPeriodsAreDifferent}
            css={[spacing.mt0]}
          />
          <div className='arrow'>
            <Icon.ArrowRight />
          </div>
          <PlanCard
            variant='small'
            plan={toPlan}
            loading={plansLoading}
            currentPlanSku={{}}
            overrideActionText='Your new Plan'
            forcePrice={selectedBillingPeriod}
            showBillingPeriod={!toPlan?.isFree && billingPeriodsAreDifferent}
            css={[spacing.mt0]}
          />
        </StyledPlansContainer>
        {isCycleChangeable && (
          <div
            className='switch-billing-period'
            onClick={() => setIsYearly(!isYearly)}
          >
            <a>
              {isYearly
                ? 'Switch to Monthly and Pay 20% more'
                : 'Switch to Annual and Save 20%'}
            </a>
          </div>
        )}
        <StyledDivider />
        <StyledNext>
          <div className='heading'>What happens next?</div>
          <div className='text'>{templateFn?.next(templateArgs)}</div>
        </StyledNext>

        <StyledDivider />

        {!toPlan?.isFree && (
          <>
            <BillingStatus />
            <StyledDivider />
          </>
        )}

        {!toPlan?.isFree && (
          <div
            css={[spacing.mb6, 'font-size: 0.875rem; line-height: 1.25rem;']}
          >
            <b>
              By purchasing you authorize Grain to automatically charge you $
              {displayCents(subscriptionPreview?.renewalPrice || 0) || ''} per{' '}
              {subscriptionPreview?.billingPeriod === 'YEARLY'
                ? 'year'
                : 'month'}{' '}
              until you cancel.
            </b>{' '}
            If the price changes, we'll notify you beforehand. You can check
            your renewal date or cancel anytime via your plans page. No partial
            refunds.{' '}
            <a
              css={[color.fg.highlightGreenDark, 'text-decoration: underline;']}
              href='https://grain.com/terms'
              target='_blank'
              rel='noopener noreferrer'
            >
              Terms and Conditions
            </a>{' '}
            apply.
          </div>
        )}
        <StyledFooter>
          {createSubscriptionRes.error && (
            <ErrorBox
              variant='minimal'
              error={createSubscriptionRes.error}
              css={[spacing.mb4]}
            />
          )}
          <div css={['display: flex;']}>
            <Button
              css={['width: 120px;']}
              type='primary'
              onClick={handleSubmit}
              loading={createSubscriptionRes.loading}
            >
              Change plan
            </Button>
            <Button
              type='secondary'
              css={[spacing.ml4]}
              onClick={handleNavigateBack}
            >
              Back
            </Button>
          </div>
        </StyledFooter>
      </StyledContainer>
      {downgradeConfirmIsOpen && (
        <DowngradeConfirmModal
          fromPlan={onloadPlanRef.current}
          toPlan={toPlan}
          workspaceSubscription={workspaceSubscription}
          close={() => {
            navigate(getRedirectTo(), { replace: true });
          }}
        />
      )}
    </>
  );
}
