import styled from '@emotion/styled';
import { Button, flex, spacing } from '@grain/grain-ui';

const EmptyStateContainer = styled.div`
  display: flex;
  ${flex.direction.column};
  ${flex.alignItems.center};
  ${flex.justifyContent.center};
  height: 100%;
  font-weight: 600;
`;

type EmptyStateProps = {
  filter: string;
  onFiltersClear: () => void;
};

export const EmptyState = ({ onFiltersClear, filter }: EmptyStateProps) => {
  return JSON.parse(filter).filters.length === 1 ? (
    <EmptyStateContainer>
      <div>No data based on your meetings</div>
    </EmptyStateContainer>
  ) : (
    <EmptyStateContainer>
      <div>No data based on your filters</div>
      <div>
        <Button
          css={[spacing.mt5]}
          variant='secondary'
          onClick={onFiltersClear}
        >
          Clear filters
        </Button>
      </div>
    </EmptyStateContainer>
  );
};
