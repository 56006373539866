import * as Layout from '~/components/Layout';
import { Helmet } from 'react-helmet';
import {
  AdHocRecordingFragment,
  useViewAdHocQuery
} from '~/modules/contentFilter/contentfilter.generated';
import { Divider, Icon20, PageHeader, theme } from '@grain/grain-ui/v4';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Fragment, useCallback, useMemo } from 'react';
import { format, isToday, isYesterday } from 'date-fns';
import { MeetingDateHeader } from '~/modules/meetings/MeetingDateHeader';
import { DeletedListItem } from './components/DeletedListItem';
import { useDeletedMeetingsMutations } from './hooks/useDeletedMeetingsMutations';
import { RecordingLimitModal } from './RecordingLimitModal';
import styled from '@emotion/styled';
import { isDesktopApp } from '@grain/desktop-lib';

const formatDate = (date: Date) => {
  if (isToday(date)) {
    return 'Deleted Today';
  } else if (isYesterday(date)) {
    return 'Deleted Yesterday';
  } else {
    return `Deleted on ${format(date, 'EEEE, MMM do')}`; // Example: Monday, Aug 26th
  }
};

const adHocFilter = JSON.stringify({
  types: ['recordings'],
  filters: [{ deleted: true }]
});

const StyledDescription = styled.div`
  ${theme.utils.py('lg')};
  ${theme.utils.px('3xl')};
  ${theme.tokens.typography.b3[400]};
  color: ${theme.tokens.color.textSecondary};
`;

const DeletedPage = () => {
  const { toggleCollapsed } = Layout.useSidebar();
  const { data, fetchMore, refetch } = useViewAdHocQuery({
    variables: {
      filter: adHocFilter
    },
    fetchPolicy: 'no-cache'
  });

  const {
    restoreRecording,
    deleteRecording,
    showLimitModal,
    limit,
    closeLimitModal
  } = useDeletedMeetingsMutations(refetch);

  const cursor = data?.viewAdHoc?.cursor;
  const fetchMoreDeletedItems = useCallback(() => {
    fetchMore({
      variables: {
        cursor: cursor
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          ...prev,
          viewAdHoc: {
            ...prev.viewAdHoc,
            cursor: fetchMoreResult.viewAdHoc.cursor,
            list: [...prev.viewAdHoc.list, ...fetchMoreResult.viewAdHoc.list]
          }
        };
      }
    });
  }, [fetchMore, cursor]);

  const recordings = useMemo(() => {
    return (data?.viewAdHoc?.list || []).filter(
      (item): item is AdHocRecordingFragment =>
        item.__typename === 'Recording' && item.canDelete
    );
  }, [data]);

  const recordingsByDate = useMemo(() => {
    return recordings.reduce(
      (acc, recording) => {
        const dateFmt = formatDate(new Date(recording.deletedAt ?? ''));
        if (!(dateFmt in acc)) {
          acc[dateFmt] = [];
        }
        acc[dateFmt].push(recording);
        return acc;
      },
      {} as Record<string, AdHocRecordingFragment[]>
    );
  }, [recordings]);

  return (
    <>
      <Helmet title='Deleted Meetings' />
      <Layout.Wrapper withSidebar>
        <PageHeader
          title='My recently deleted meetings'
          icon={Icon20.Delete}
          onMenuClick={toggleCollapsed}
          isDesktopApp={isDesktopApp}
        />
        <Divider />
        <StyledDescription>
          Meetings that appear on this page will be permanently deleted after 30
          days.
        </StyledDescription>
        <Divider />
        <div
          id='infinite-scrollable-div'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <InfiniteScroll
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'unset'
            }}
            dataLength={recordings.length}
            hasMore={Boolean(data && data.viewAdHoc.cursor)}
            next={fetchMoreDeletedItems}
            loader={<DeletedListItem.Skeleton />}
            scrollableTarget='infinite-scrollable-div'
          >
            {Object.entries(recordingsByDate).map(([date, recordings]) => (
              <section key={date}>
                <MeetingDateHeader>{date}</MeetingDateHeader>
                {recordings.map(recording => (
                  <Fragment key={recording.id}>
                    <DeletedListItem
                      recording={recording}
                      onClickRestore={() => restoreRecording(recording.id)}
                      onClickDelete={() => deleteRecording(recording.id)}
                    />
                    <Divider />
                  </Fragment>
                ))}
              </section>
            ))}
          </InfiniteScroll>
        </div>
        {showLimitModal && (
          <RecordingLimitModal limit={limit} onClose={closeLimitModal} />
        )}
      </Layout.Wrapper>
    </>
  );
};

export default DeletedPage;
