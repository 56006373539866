import * as Types from '@grain/api/schema.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@grain/api/graphql';
const defaultOptions = {} as const;
export type FreemiumTrialOnboardingRecordingFragmentFragment = { __typename?: 'Recording', id: string, title: string, startDatetime: string, duration: number | null, fullJpegThumbnailUrl: string | null, recordingPath: string, participants: Array<{ __typename?: 'RecordingParticipant', id: string, person: { __typename?: 'Person', id: string, contacts: Array<{ __typename?: 'PersonContact', contactType: Types.PersonContactType }> } | null }> };

export type FreemiumTrialOnboardingRecordingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FreemiumTrialOnboardingRecordingQuery = { __typename?: 'RootQueryType', freemiumTrialOnboardingRecording: { __typename?: 'Recording', id: string, title: string, startDatetime: string, duration: number | null, fullJpegThumbnailUrl: string | null, recordingPath: string, participants: Array<{ __typename?: 'RecordingParticipant', id: string, person: { __typename?: 'Person', id: string, contacts: Array<{ __typename?: 'PersonContact', contactType: Types.PersonContactType }> } | null }> } | null };

export type FreemiumTrialOnboardingRecordingGenerateMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type FreemiumTrialOnboardingRecordingGenerateMutation = { __typename?: 'RootMutationType', freemiumTrialOnboardingRecordingGenerate: { __typename?: 'Recording', id: string, title: string, startDatetime: string, duration: number | null, fullJpegThumbnailUrl: string | null, recordingPath: string, participants: Array<{ __typename?: 'RecordingParticipant', id: string, person: { __typename?: 'Person', id: string, contacts: Array<{ __typename?: 'PersonContact', contactType: Types.PersonContactType }> } | null }> } };

export type SubscriptionTrialBeginMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SubscriptionTrialBeginMutation = { __typename?: 'RootMutationType', subscriptionTrialBegin: { __typename?: 'Workspace', id: string } };

export const FreemiumTrialOnboardingRecordingFragmentFragmentDoc = gql`
    fragment freemiumTrialOnboardingRecordingFragment on Recording {
  id
  title
  startDatetime
  duration
  fullJpegThumbnailUrl
  recordingPath
  participants {
    id
    person {
      id
      contacts {
        contactType
      }
    }
  }
}
    `;
export const FreemiumTrialOnboardingRecordingDocument = gql`
    query freemiumTrialOnboardingRecording {
  freemiumTrialOnboardingRecording {
    ...freemiumTrialOnboardingRecordingFragment
  }
}
    ${FreemiumTrialOnboardingRecordingFragmentFragmentDoc}`;

/**
 * __useFreemiumTrialOnboardingRecordingQuery__
 *
 * To run a query within a React component, call `useFreemiumTrialOnboardingRecordingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFreemiumTrialOnboardingRecordingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFreemiumTrialOnboardingRecordingQuery({
 *   variables: {
 *   },
 * });
 */
export function useFreemiumTrialOnboardingRecordingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FreemiumTrialOnboardingRecordingQuery, FreemiumTrialOnboardingRecordingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<FreemiumTrialOnboardingRecordingQuery, FreemiumTrialOnboardingRecordingQueryVariables>(FreemiumTrialOnboardingRecordingDocument, options);
      }
export function useFreemiumTrialOnboardingRecordingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FreemiumTrialOnboardingRecordingQuery, FreemiumTrialOnboardingRecordingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<FreemiumTrialOnboardingRecordingQuery, FreemiumTrialOnboardingRecordingQueryVariables>(FreemiumTrialOnboardingRecordingDocument, options);
        }
export function useFreemiumTrialOnboardingRecordingSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<FreemiumTrialOnboardingRecordingQuery, FreemiumTrialOnboardingRecordingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<FreemiumTrialOnboardingRecordingQuery, FreemiumTrialOnboardingRecordingQueryVariables>(FreemiumTrialOnboardingRecordingDocument, options);
        }
export type FreemiumTrialOnboardingRecordingQueryHookResult = ReturnType<typeof useFreemiumTrialOnboardingRecordingQuery>;
export type FreemiumTrialOnboardingRecordingLazyQueryHookResult = ReturnType<typeof useFreemiumTrialOnboardingRecordingLazyQuery>;
export type FreemiumTrialOnboardingRecordingSuspenseQueryHookResult = ReturnType<typeof useFreemiumTrialOnboardingRecordingSuspenseQuery>;
export type FreemiumTrialOnboardingRecordingQueryResult = Apollo.QueryResult<FreemiumTrialOnboardingRecordingQuery, FreemiumTrialOnboardingRecordingQueryVariables>;
export const FreemiumTrialOnboardingRecordingGenerateDocument = gql`
    mutation freemiumTrialOnboardingRecordingGenerate {
  freemiumTrialOnboardingRecordingGenerate {
    ...freemiumTrialOnboardingRecordingFragment
  }
}
    ${FreemiumTrialOnboardingRecordingFragmentFragmentDoc}`;
export type FreemiumTrialOnboardingRecordingGenerateMutationFn = Apollo.MutationFunction<FreemiumTrialOnboardingRecordingGenerateMutation, FreemiumTrialOnboardingRecordingGenerateMutationVariables>;

/**
 * __useFreemiumTrialOnboardingRecordingGenerateMutation__
 *
 * To run a mutation, you first call `useFreemiumTrialOnboardingRecordingGenerateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFreemiumTrialOnboardingRecordingGenerateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [freemiumTrialOnboardingRecordingGenerateMutation, { data, loading, error }] = useFreemiumTrialOnboardingRecordingGenerateMutation({
 *   variables: {
 *   },
 * });
 */
export function useFreemiumTrialOnboardingRecordingGenerateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FreemiumTrialOnboardingRecordingGenerateMutation, FreemiumTrialOnboardingRecordingGenerateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<FreemiumTrialOnboardingRecordingGenerateMutation, FreemiumTrialOnboardingRecordingGenerateMutationVariables>(FreemiumTrialOnboardingRecordingGenerateDocument, options);
      }
export type FreemiumTrialOnboardingRecordingGenerateMutationHookResult = ReturnType<typeof useFreemiumTrialOnboardingRecordingGenerateMutation>;
export type FreemiumTrialOnboardingRecordingGenerateMutationResult = Apollo.MutationResult<FreemiumTrialOnboardingRecordingGenerateMutation>;
export type FreemiumTrialOnboardingRecordingGenerateMutationOptions = Apollo.BaseMutationOptions<FreemiumTrialOnboardingRecordingGenerateMutation, FreemiumTrialOnboardingRecordingGenerateMutationVariables>;
export const SubscriptionTrialBeginDocument = gql`
    mutation subscriptionTrialBegin {
  subscriptionTrialBegin {
    id
  }
}
    `;
export type SubscriptionTrialBeginMutationFn = Apollo.MutationFunction<SubscriptionTrialBeginMutation, SubscriptionTrialBeginMutationVariables>;

/**
 * __useSubscriptionTrialBeginMutation__
 *
 * To run a mutation, you first call `useSubscriptionTrialBeginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionTrialBeginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriptionTrialBeginMutation, { data, loading, error }] = useSubscriptionTrialBeginMutation({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionTrialBeginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubscriptionTrialBeginMutation, SubscriptionTrialBeginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SubscriptionTrialBeginMutation, SubscriptionTrialBeginMutationVariables>(SubscriptionTrialBeginDocument, options);
      }
export type SubscriptionTrialBeginMutationHookResult = ReturnType<typeof useSubscriptionTrialBeginMutation>;
export type SubscriptionTrialBeginMutationResult = Apollo.MutationResult<SubscriptionTrialBeginMutation>;
export type SubscriptionTrialBeginMutationOptions = Apollo.BaseMutationOptions<SubscriptionTrialBeginMutation, SubscriptionTrialBeginMutationVariables>;