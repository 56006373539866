import styled from '@emotion/styled';
import { spacing, media } from '@grain/styles/constants';

export const StyledWrapper = styled.div`
  ${spacing.pb7};

  overflow-y: auto;

  ${media.small} {
    ${spacing.px4};
    ${spacing.pb4};
  }
`;
