import { Icon, LinkButton, Speedbump } from '@grain/grain-ui';
import { StyledBackground } from './styles';

export default function Upgrade() {
  return (
    <StyledBackground>
      <Speedbump width={418}>
        <Speedbump.Title>New features await!</Speedbump.Title>
        <Speedbump.Body>
          <p>A new version of Grain is available.</p>
          <p>Please download and install it to continue.</p>
        </Speedbump.Body>
        <div>
          <LinkButton icon={<Icon.Link />} to='/download' htmlLink>
            Download new Grain app
          </LinkButton>
        </div>
      </Speedbump>
    </StyledBackground>
  );
}
