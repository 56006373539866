import { useMemo } from 'react';
import { Skeleton } from '@grain/grain-ui';
import { theme } from '@grain/grain-ui/v4';

import { formatDuration } from '../../utils';
import { StyledDetailsTable } from './DetailsTable';

// Use this to test rendering the "Time In Stage" table.  The spec is currently
// just to show the latest stage, but past stages could be rendered in theory.
//
// import { sub } from 'date-fns';
//
// const TESTING_STAGE_UPDATES: StageUpdate[] = [
//  {
//    date: sub(new Date(), { days: 2 }).toISOString(),
//    stage: 'Qualified To Buy'
//  },
//  {
//    date: sub(new Date(), { days: 9 }).toISOString(),
//    stage: 'Appointment Scheduled'
//  }
// ];

type StageUpdate = { id: string; date?: string; stage: string };
type StageDuration = { id: string; stage: string; duration: string };

type TimeInStageTableProps = {
  stageUpdates: StageUpdate[];
};

export function TimeInStageTable({ stageUpdates }: TimeInStageTableProps) {
  const stageDurations: StageDuration[] = useMemo(() => {
    return stageUpdates.reduce((acc, update, index, arr) => {
      const prevUpdate = arr[index - 1];
      const endDate = (() => {
        if (index === 0) return new Date();
        if (prevUpdate.date) return new Date(prevUpdate.date);
      })();
      return [
        ...acc,
        {
          id: update.id,
          stage: update.stage,
          duration:
            update.date && endDate
              ? formatDuration(new Date(update.date), endDate)
              : 'Unknown'
        }
      ];
    }, [] as StageDuration[]);
  }, [stageUpdates]);
  return (
    <StyledDetailsTable>
      <thead>
        <tr>
          <th colSpan={2}>Time In Stage</th>
        </tr>
      </thead>
      <tbody>
        {stageDurations.map(({ id, stage, duration }) => (
          <tr key={id}>
            <td>{stage}</td>
            <td>{duration}</td>
          </tr>
        ))}
      </tbody>
    </StyledDetailsTable>
  );
}

export function SkeletonTimeInStageTable() {
  return (
    <StyledDetailsTable>
      <thead>
        <tr>
          <th>
            <Skeleton
              width={80}
              height={16}
              css={[`border-radius: ${theme.tokens.radii.md};`]}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Skeleton
              width={80}
              height={16}
              css={[`border-radius: ${theme.tokens.radii.md};`]}
            />
          </td>
        </tr>
      </tbody>
    </StyledDetailsTable>
  );
}
