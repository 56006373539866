import { Icon, getBasePublicPath } from '@grain/grain-ui';
import { Button } from '@grain/grain-ui/v4';
import { useDesktopAuth } from 'hooks/useDesktopAuth';
import { PrivacySecurityInfo } from './PrivacySecurityInfo';
import {
  StyledHeaderBar,
  StyledHeaderLogo,
  StyledContainer,
  StyledSignUp,
  StyledCard,
  StyledCardHeaderText,
  StyledCardSubHeaderText,
  StyledCardWrapper
} from './styles';

export default function Login() {
  const onLogin = () => {
    window.location.href = '/app';
  };

  const { handleLogin, handleSignup } = useDesktopAuth({
    onLogin
  });

  return (
    <StyledContainer>
      <StyledHeaderBar>
        <StyledHeaderLogo>
          <a href={'/'}>
            <Icon.GrainLogoAndWord />
          </a>
        </StyledHeaderLogo>
      </StyledHeaderBar>
      <StyledCardWrapper>
        <StyledCard>
          <div className='left'>
            <div className='content-wrapper'>
              <StyledCardHeaderText>Welcome.</StyledCardHeaderText>
              <StyledCardSubHeaderText>
                Capture, transcribe, & share your customer meetings.
              </StyledCardSubHeaderText>

              <Button
                fullWidth
                size='xl'
                textLabelProps={{ size: 'lg' }}
                onClick={handleLogin}
              >
                Sign in
              </Button>
              <StyledSignUp>
                Don’t have an account? <a onClick={handleSignup}>Sign up</a>
              </StyledSignUp>
            </div>

            <PrivacySecurityInfo />
          </div>

          <div className='right'>
            <img
              src={`${`${getBasePublicPath()}/images/onboarding/card-login.svg`}`}
            />
          </div>
        </StyledCard>
      </StyledCardWrapper>
    </StyledContainer>
  );
}
