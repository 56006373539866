import { css } from '@emotion/react';
import { RecorderModeType } from '@g/schema';
import { Select, Option } from '@grain/grain-ui/v4';

type RecorderModeSelectorProps = {
  onChangeSetting?: (setting: RecorderModeType) => void;
  selectedSetting?: RecorderModeType;
  width?: string;
  disabled?: boolean;
};

const settingsMap: Record<RecorderModeType, string> = {
  SPEAKER: 'Active speaker (only record the active speaker)',
  GALLERY: 'Gallery (display speakers in a gallery view)',
  AUDIO: 'Audio only (do not record video)'
};

const settingsOptions = Object.entries(settingsMap).map(([value, title]) => ({
  title,
  value
}));

const NOOP = () => {};
export const RecorderModeSelector = ({
  onChangeSetting = NOOP,
  selectedSetting,
  width = '450px',
  disabled = false
}: RecorderModeSelectorProps) => {
  return (
    <div css={css({ width })}>
      <Select
        fullWidth
        matchWidth
        placeholder='Choose one'
        value={selectedSetting}
        onChange={onChangeSetting}
        disabled={disabled}
      >
        {settingsOptions.map(option => (
          <Option key={option.value} value={option.value}>
            {option.title}
          </Option>
        ))}
      </Select>
    </div>
  );
};
