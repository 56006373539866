import React from 'react';
import { Modal, useRouteModal } from '@grain/grain-ui';
import { ZOOM_CONNECT_ROUTE_ID } from '@grain/components/modals/constants';
import ConnectZoom from '~/pages/Onboarding/SelectMeetingPlace/ConnectZoomExisting';

export default function ZoomConnectModal() {
  const { close } = useRouteModal(ZOOM_CONNECT_ROUTE_ID);

  return (
    <Modal
      width={'auto'}
      closable={false}
      maskClosable={false}
      escapeClosable={false}
      onCancel={close}
      css={['background-color: transparent !important;']}
    >
      <ConnectZoom />
    </Modal>
  );
}
