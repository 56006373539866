import { useCallback } from 'react';
import { useSessionStorage } from 'react-use';
import { Link, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import copy from 'clipboard-copy';

import type { Deal } from '@grain/api/schema.generated';
import {
  isEditableElementFocused,
  useEventListener,
  useShowToast
} from '@grain/grain-ui';
import { theme, Button, Icon16, Tooltip } from '@grain/grain-ui/v4';
import { isDesktopApp, openUrlInBrowser } from '@grain/desktop-lib';
import { AskAIButton } from '~/modules/ask';
import { useGetHubspotIntegration } from '~/pages/RecordingPage/hooks';

const StyledHeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.tokens.spacing.lg};
`;

const StyledPagePosition = styled.div`
  ${theme.tokens.typography.b4[500]};
`;

const StyledHeaderActionGroup = styled.div`
  display: flex;
  gap: ${theme.tokens.spacing.sm};
`;

const DEFAULT_FILTERED_DEALS: string[] = [];

type HeaderActionsProps = {
  dealId: Deal['id'];
  providerKey?: Deal['providerKey'];
};

export function HeaderActions({ dealId, providerKey }: HeaderActionsProps) {
  const navigate = useNavigate();
  const showToast = useShowToast();
  const hubspotIntegration = useGetHubspotIntegration();

  const [filteredDeals] = useSessionStorage<string[]>(
    'filtered_deals',
    DEFAULT_FILTERED_DEALS
  );
  const dealIndex = filteredDeals?.indexOf(dealId) ?? -1;
  const page = dealIndex > -1 ? dealIndex + 1 : undefined;
  const totalPages = filteredDeals?.length;

  const previousDealPath =
    filteredDeals && dealIndex > 0
      ? `/app/deals/${filteredDeals[dealIndex - 1]}`
      : '';
  const nextDealPath =
    filteredDeals && dealIndex < filteredDeals.length - 1
      ? `/app/deals/${filteredDeals[dealIndex + 1]}`
      : '';

  const previousDisabled = dealIndex === 0;
  const nextDisabled = filteredDeals && dealIndex === filteredDeals.length - 1;

  const hubspotDealUrl = (() => {
    if (!hubspotIntegration || !providerKey) return;
    const hubId = hubspotIntegration.hubId;
    return `https://app.hubspot.com/contacts/${hubId}/deal/${providerKey}`;
  })();

  const copyLink = useCallback(() => {
    copy(`${window.location.origin}/app/deals/${dealId}`);
    showToast({
      type: 'success',
      content: 'Deal Link Copied'
    });
  }, [dealId, showToast]);

  const PREV_SHORTCUT = 'k';
  const NEXT_SHORTCUT = 'j';

  const hotkeys: Record<string, (ev: React.KeyboardEvent) => boolean | void> = {
    [PREV_SHORTCUT]: () => {
      if (previousDealPath) navigate(previousDealPath);
    },
    [NEXT_SHORTCUT]: () => {
      if (nextDealPath) navigate(nextDealPath);
    }
  };

  useEventListener(
    'keydown',
    (ev: Event) => {
      const fn = hotkeys[(ev as KeyboardEvent).key.toLowerCase()];
      if (!fn || isEditableElementFocused()) return;
      const result = fn(ev as unknown as React.KeyboardEvent<HTMLElement>);
      if (result !== false) {
        ev.stopPropagation();
        ev.preventDefault();
      }
    },
    document.body
  );

  const previousButton = (
    <Button
      as={previousDisabled ? undefined : Link}
      to={previousDealPath}
      icon={Icon16.ChevronUp}
      variant='stroked'
      size='md'
      disabled={previousDisabled}
    />
  );

  const nextButton = (
    <Button
      as={nextDisabled ? undefined : Link}
      to={nextDealPath}
      icon={Icon16.ChevronDown}
      variant='stroked'
      size='md'
      disabled={nextDisabled}
    />
  );

  return (
    <StyledHeaderActions>
      {Boolean(page) && totalPages > 1 && (
        <>
          <StyledPagePosition>
            {page} / {totalPages}
          </StyledPagePosition>
          <StyledHeaderActionGroup>
            {previousDisabled ? (
              previousButton
            ) : (
              <Tooltip
                content='Previous deal'
                commands={{ mac: [PREV_SHORTCUT], others: [PREV_SHORTCUT] }}
              >
                {previousButton}
              </Tooltip>
            )}
            {nextDisabled ? (
              nextButton
            ) : (
              <Tooltip
                content='Next deal'
                commands={{ mac: [NEXT_SHORTCUT], others: [NEXT_SHORTCUT] }}
              >
                {nextButton}
              </Tooltip>
            )}
          </StyledHeaderActionGroup>
        </>
      )}
      <AskAIButton queryContext={{ dealId }} />
      <StyledHeaderActionGroup>
        <Tooltip content='Open deal page in HubSpot'>
          <Button
            as={Link}
            to={hubspotDealUrl || ''}
            rel='noopener noreferrer'
            target='_blank'
            onClick={e => {
              if (!hubspotDealUrl) {
                e.preventDefault();
              } else if (isDesktopApp) {
                e.preventDefault();
                openUrlInBrowser(hubspotDealUrl);
              }
            }}
            icon={Icon16.Hubspot}
            variant='ghost'
          />
        </Tooltip>
        <Tooltip content='Copy deal link'>
          <Button icon={Icon16.Link} variant='ghost' onClick={copyLink} />
        </Tooltip>
      </StyledHeaderActionGroup>
    </StyledHeaderActions>
  );
}
