import { HubspotIntegrationFragment } from '@grain/api/graphql/fragments/fragments.generated';
import { useActiveIntegrationsQuery } from '@grain/api/graphql/queries/integrations.generated';

export const useHubspotIntegration = () => {
  const { data: activeIntegrationsData } = useActiveIntegrationsQuery();

  const hubspotIntegration = activeIntegrationsData?.activeIntegrations.find(
    e => e.__typename === 'HubspotIntegration'
  ) as HubspotIntegrationFragment | null;

  return hubspotIntegration;
};
