import {
  CollectionFragmentFragment as PlaylistFragment,
  useViewAdHocQuery
} from '~/modules/contentFilter/contentfilter.generated';
import { useCallback, useMemo } from 'react';
import { usePlaylistsSubscription } from './usePlaylistsSubscription';

type UseFetchPlaylistsProps = {
  filter: string;
};

export const useFetchPlaylists = ({ filter }: UseFetchPlaylistsProps) => {
  usePlaylistsSubscription({
    filter
  });

  const queryResults = useViewAdHocQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: filter
    }
  });
  const { fetchMore, data } = queryResults;

  const playlists = useMemo(() => {
    return (data?.viewAdHoc?.list || []).filter(
      (item): item is PlaylistFragment => item.__typename === 'Collection'
    );
  }, [data]);

  const cursor = data?.viewAdHoc?.cursor;

  const fetchMorePlaylists = useCallback(() => {
    if (cursor) {
      fetchMore({
        variables: {
          cursor
        }
      });
    }
  }, [cursor, fetchMore]);

  return {
    ...queryResults,
    fetchMore: fetchMorePlaylists,
    playlists,
    cursor
  };
};
