// @ts-strict-ignore
import React from 'react';
import { Modal, useRouteModal, Button, Icon } from '@grain/grain-ui';
import { usePlans } from '@grain/components/Subscriptions/hooks';
import { TRIAL_ENDED_ROUTE_ID } from '@grain/components/modals/constants';
import { StyledContentContainer } from './styles';
import { useHandleShowUpgradeModal } from '~/modules/gates';

export default function TrialEnded() {
  const { close } = useRouteModal(TRIAL_ENDED_ROUTE_ID);
  const { subscriptionPlans } = usePlans();
  const openSubscribeModal = useHandleShowUpgradeModal();

  const handleSubscribeModal = () => {
    // Get the highest self service paid plan
    const upgradePlan = subscriptionPlans
      .slice()
      .reverse()
      .find(p => !p.isFree && p.yearlyPrice);

    openSubscribeModal(upgradePlan.sku, { source: 'Trial Ended Modal' });
  };

  return (
    <Modal
      closable={false}
      width={515}
      onCancel={close}
      css={['border-radius: 8px !important; margin: 17.9vh 0 0 0 !important;']}
    >
      <StyledContentContainer>
        <div className='header'>Your Grain Business trial has ended</div>
        <div className='sub-heading'>
          Upgrade to keep coaching your team and <br />
          saving deals with these features.
        </div>
        <div className='options'>
          <div className='option-wrapper'>
            <div className='icon'>
              <Icon.VideoRecorder />
            </div>
            <div>
              <span className='bold'>Unlimited meetings</span> with AI Notes for
              your team.
            </div>
          </div>
          <div className='option-wrapper'>
            <div className='icon'>
              <Icon.Star06 />
            </div>
            <div>
              Coach like a champ with AI Feedback, Insights,
              <br /> and <span className='bold'>Coaching Alerts.</span>
            </div>
          </div>
          <div className='option-wrapper'>
            <div className='icon'>
              <Icon.BellFilled />
            </div>
            <div>
              Save deals with Trackers, Keyword Alerts,
              <br /> and Grain’s <span className='bold'>Deal Board.</span>
            </div>
          </div>
          <div className='option-wrapper'>
            <div className='icon'>
              <Icon.DataFlow />
            </div>
            <div>
              Automate meeting ops with{' '}
              <span className='bold'>CRM integrations</span>
              <br /> and AI-powered follow-up emails.
            </div>
          </div>
        </div>
        <div className='action-buttons'>
          <Button variant='secondary' onClick={close}>
            Remain on Free
          </Button>
          <Button
            variant='plan'
            tabIndex={1}
            onClick={() => {
              handleSubscribeModal();
            }}
          >
            Upgrade
          </Button>
        </div>
      </StyledContentContainer>
    </Modal>
  );
}
