import React from 'react';
import { StoryCreditsMinimal } from '~/modules/stories/StoryCredits';
import { StyledFooter } from './styles';
import { storyState } from '../../state';
import { useRecoilValue } from 'recoil';

export default function Footer() {
  const story = useRecoilValue(storyState);

  return (
    <StyledFooter>
      <StoryCreditsMinimal story={story} />
    </StyledFooter>
  );
}
