import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { MenuButton, Input, theme, Icon16 } from '@grain/grain-ui/v4';
import { useCallback, useMemo, useState } from 'react';
import { useUnmount } from 'react-use';
import { UseAllFiltersReturn } from '../filters/useAllFilters';

type HasAutocomplete<T> = T extends { type: 'autocomplete' } ? T : never;

type AutocompleteFilter = HasAutocomplete<
  UseAllFiltersReturn[keyof UseAllFiltersReturn]
>;

type AutocompleteMenuListProps<T extends AutocompleteFilter> = {
  filter: T;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const AutocompleteMenuList = <Filter extends AutocompleteFilter>({
  filter
}: AutocompleteMenuListProps<Filter>) => {
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(-1);
  const selectedOptionsSet = useMemo(() => {
    const selectedOptions = Array.isArray(filter.value)
      ? filter.value
      : [filter.value];

    return new Set(
      selectedOptions.map(option => {
        if (option && typeof option === 'object' && 'value' in option) {
          return option.value;
        }
        return option;
      })
    );
  }, [filter.value]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'ArrowDown') {
        setFocusedOptionIndex(prevIndex =>
          Math.min(prevIndex + 1, filter.options.length - 1)
        );
      } else if (e.key === 'ArrowUp') {
        setFocusedOptionIndex(prevIndex => Math.max(prevIndex - 1, -1));
      }
    },
    [filter.options.length]
  );

  useUnmount(() => {
    // Cleanup search on close
    if ('onSearchChange' in filter) {
      filter?.onSearchChange?.('');
    }
  });

  const isLoading = 'loading' in filter && filter.loading;
  const searchValue = 'searchValue' in filter ? filter.searchValue : '';

  return (
    <Container onKeyDown={handleKeyDown}>
      <Input
        css={css`
          margin-bottom: 4px;
        `}
        placeholder={filter.label}
        value={searchValue}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          if ('onSearchChange' in filter) {
            filter.onSearchChange?.(e.target.value);
          }
        }}
        ref={focusedOptionIndex === -1 ? el => el?.focus() : undefined}
        autoFocus
        endIcon={
          isLoading
            ? () => (
                <Icon16.AnimatedSpinner color={theme.tokens.color.iconBrand} />
              )
            : undefined
        }
      />
      {searchValue && isLoading && filter.options.length === 0 && (
        <MenuButton
          as='div'
          label='No Results'
          onClick={e => e.stopPropagation()}
          css={css`
            cursor: unset;
            color: ${theme.tokens.color.textTertiary};
            &:hover {
              background-color: transparent;
            }
          `}
        />
      )}
      {filter.options.map((option, index) => {
        const isSelected = selectedOptionsSet.has(option.value);
        return (
          <MenuButton
            buttonRef={
              index === focusedOptionIndex ? el => el?.focus() : undefined
            }
            key={option.value.toString()}
            label={option.label}
            caption={'caption' in option ? option.caption : undefined}
            captionProps={
              'captionProps' in option ? option.captionProps : undefined
            }
            checked={filter.isMultiSelect ? isSelected : undefined}
            selected={
              !filter.isMultiSelect && filter.active ? isSelected : undefined
            }
            onClick={() => {
              if ('toggle' in filter) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore Seemingly impossible to narrow the type for filter.toggle along with the mapped over options.
                filter.toggle(option);
              }
            }}
          />
        );
      })}
    </Container>
  );
};
