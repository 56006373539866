import { CreateSelectionsBanner, HotspotTooltip } from '@grain/grain-ui';
import { RecordingTab } from './RecordingTabs/useTabs';
import { useCallback, useLayoutEffect } from 'react';
import { type CalculationArgs, useHotspotContext } from '~/components/Hotspot';
import { css } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import { useGetHubspotIntegration, useGetSalesforceIntegration } from './hooks';
import {
  IntelligenceJobStatus,
  RecordingSource,
  TranscriptState
} from '@grain/api/schema.generated';
import { RecordingQuery } from '@grain/api/graphql/queries/recording.generated';
import * as AskAIFtux from './stories/ask-ai-meetings';
import { useMyself } from '~/support/auth';
import { SUPPORTED_CRMS } from '~/modals/StartTrial/SlideshowModals/ConnectCRM';
import {
  getConnectHubspotHref,
  getConnectSalesforceHref
} from '~/modules/integrations/getConnectHrefs';

type UseRecordingHotspotsProps = {
  recording: RecordingQuery['recording'];
  setTab: (tab: RecordingTab) => void;
  dealProviderKey?: string;
};

const getRedirectHref = () => {
  const currentUrl = new URL(
    `${location.pathname}${location.search}`,
    window.location.origin
  );

  return `${currentUrl.pathname}${currentUrl.search}`;
};

const connectToSalesforce = () => {
  const salesforceHref = getConnectSalesforceHref(getRedirectHref);
  window.open(salesforceHref, '_blank');
};

const connectToHubspot = () => {
  const hubspotHref = getConnectHubspotHref(getRedirectHref);
  window.open(hubspotHref, '_blank');
};

export const useRecordingHotspotsEffect = ({
  recording,
  setTab,
  dealProviderKey
}: UseRecordingHotspotsProps) => {
  const { myself } = useMyself();
  const { registerStory } = useHotspotContext();
  const { state } = useLocation();

  const isCrmDemo = state?.showFreemiumTrialTour;
  const hasFollowUpEmail = recording?.intelligence?.followUpEmail;
  const selectedCrm =
    myself?.onboardingSurveyAnswers?.ob_survey_selected_crm_type;
  const hubspotIntegration = useGetHubspotIntegration();
  const salesforceIntegration = useGetSalesforceIntegration();
  const showSummaryHotspot = SUPPORTED_CRMS.includes(selectedCrm);
  const isSampleRecording = recording?.source === RecordingSource.Sample;

  const viewHubspotDeal = useCallback(() => {
    window.open(
      `https://app.hubspot.com/contacts/${hubspotIntegration.hubId}/deal/${dealProviderKey}`,
      '_blank'
    );
  }, [hubspotIntegration, dealProviderKey]);

  const getSummaryHotspotAction = useCallback(() => {
    if (selectedCrm === 'HubSpot') {
      return hubspotIntegration
        ? dealProviderKey
          ? {
              actionText: 'View on HubSpot',
              onNextStep: viewHubspotDeal
            }
          : {
              actionText: undefined,
              onNextStep: undefined
            }
        : {
            actionText: 'Connect to HubSpot',
            onNextStep: connectToHubspot
          };
    }
    if (selectedCrm === 'Salesforce') {
      return salesforceIntegration
        ? {
            actionText: 'Done',
            onNextStep: undefined
          }
        : {
            actionText: 'Connect to Salesforce',
            onNextStep: connectToSalesforce
          };
    }
    return undefined;
  }, [
    selectedCrm,
    hubspotIntegration,
    salesforceIntegration,
    dealProviderKey,
    viewHubspotDeal
  ]);

  useLayoutEffect(() => {
    // Register Recording Page hotspots
    registerStory('recording-v3', {
      shouldSkip: () =>
        isSampleRecording ||
        recording?.transcript?.state !== TranscriptState.Processed ||
        Boolean(
          recording?.intelligence?.sections?.some(
            section => section.status !== IntelligenceJobStatus.Success
          )
        ),
      steps: [
        {
          id: 'key-points',
          nextId: 'important-moments',
          content: (
            <HotspotTooltip
              showClose
              title='Skip to the important parts'
              description='Click on AI Notes with timestamps to see the corresponding point in the recording and the transcript.'
              onNextStep={() => setTab('transcript')}
            />
          ),
          tippyProps: {
            placement: 'right'
          }
        },
        {
          id: 'important-moments',
          nextId: 'share-highlights',
          content: (
            <HotspotTooltip
              showClose
              title='Clip important moments'
              description={
                <>
                  <div
                    css={css`
                      margin-bottom: 12px;
                    `}
                  >
                    Select text in the transcript to create and share clips.
                  </div>
                  <CreateSelectionsBanner />
                </>
              }
              onNextStep={() => setTab('clips')}
            />
          ),
          tippyProps: {
            placement: 'right'
          }
        },

        {
          id: 'share-highlights',
          nextId: null,
          content: (
            <HotspotTooltip
              showClose
              title='Share clips anywhere'
              description='Copy clip links to embed them in platforms like Slack and Notion or share in any document or messaging app.'
              onNextStep={() => setTab('summary')}
            />
          )
        }
      ]
    });

    registerStory('ask-ai-meetings', {
      shouldSkip: () => isSampleRecording || isCrmDemo,
      steps: [
        {
          id: 'ask-ai',
          content: <AskAIFtux.AskAI />
        }
      ]
    });

    // Register CRM Demo hotspots
    registerStory('recording-crm-demo', {
      shouldSkip: () => !isCrmDemo,
      steps: [
        {
          id: 'media-tab',
          nextId: 'sidebar',
          content: (
            <HotspotTooltip
              variant='secondary'
              title='Grain Business Tour'
              description='Meeting trackers and coaching insights are organized here for quick review.'
            />
          ),
          tippyProps: {
            placement: 'top'
          },
          options: {
            shouldHighlightElementRef: true,
            highlighterFunction: (element: HTMLElement) => {
              element.classList.add('grain-hotspot-highlight');
              const previousSibling = element.previousElementSibling;
              if (previousSibling) {
                const siblingWidth =
                  previousSibling.getBoundingClientRect().width;
                element.style.setProperty(
                  '--grain-hotspot-left',
                  `-${siblingWidth + 8 /* gap between elements */}px`
                );
              }
              element.style.setProperty('--grain-hotspot-right', '-86px');
              return () => {
                element.classList.remove('grain-hotspot-highlight');
              };
            }
          }
        },
        {
          id: 'sidebar',
          nextId: hasFollowUpEmail ? 'follow-up-email' : 'summary',
          content: (
            <HotspotTooltip
              variant='secondary'
              title='Grain Business Tour'
              description='Evaluate meeting performance, monitor deal progress, and review interaction data in these pages.'
              onNextStep={
                hasFollowUpEmail
                  ? () => {
                      // open the summary menu so follow up email is visible
                      const menu = document.getElementById('summary-menu');
                      const tippy = menu?.firstChild as HTMLElement;
                      if (tippy) tippy.click();
                    }
                  : undefined
              }
            />
          ),
          tippyProps: {
            placement: 'right'
          },
          options: {
            shouldHighlightElementRef: true
          }
        },
        ...(hasFollowUpEmail
          ? [
              {
                id: 'follow-up-email',
                nextId: showSummaryHotspot ? 'summary' : null,
                content: (
                  <HotspotTooltip
                    variant='secondary'
                    title='Grain Business Tour'
                    description='Create and send comprehensive, context-rich follow-up emails based on this meeting in your email client.'
                  />
                ),
                tippyProps: {
                  placement: 'right'
                },
                options: {
                  shouldHighlightElementRef: true
                }
              }
            ]
          : []),
        ...(showSummaryHotspot
          ? [
              {
                id: 'summary',
                nextId: null,
                content: (
                  <HotspotTooltip
                    variant='secondary'
                    title='Grain Business Tour'
                    description='Meeting summaries automatically sync to associated contacts in your CRM.'
                    actionText={getSummaryHotspotAction()?.actionText}
                    onNextStep={getSummaryHotspotAction()?.onNextStep}
                  />
                ),
                tippyProps: {
                  placement: 'right'
                },
                options: {
                  shouldHighlightElementRef: true
                }
              }
            ]
          : [])
      ]
    });

    registerStory('recording-sample', {
      shouldSkip: () => !isSampleRecording,
      steps: [
        {
          id: 'play-button',
          nextId: null,
          content: (
            <HotspotTooltip
              variant='prominent'
              title='Getting started'
              description='Push play to walk through the meeting page and other key features of Grain.'
              showPrimaryButton={false}
              showStepNumber={false}
            />
          ),
          tippyProps({ isSmallScreen }: CalculationArgs) {
            if (isSmallScreen) return { placement: 'bottom' };
            return { placement: 'left' };
          },
          dimmedComponents: [
            'pageSidebar',
            'meetingPageHeader',
            'meetingPageLeftPane',
            'meetingPageTabsPane'
          ],
          options: {
            shouldHighlightElementRef: true,
            calculateOffset({ isSmallScreen }: CalculationArgs) {
              const player = document.querySelector('#react-player');
              if (!player) return; // Use default offset
              if (isSmallScreen) return [0, 82];
              return [0, player.clientWidth / 2];
            },
            calculateLaserLength({ isSmallScreen }: CalculationArgs) {
              const player = document.querySelector('#react-player');
              if (!player) return 0;
              if (isSmallScreen) return 82;
              return player.clientWidth / 2;
            }
          }
        }
      ]
    });
  }, [
    recording,
    registerStory,
    setTab,
    isCrmDemo,
    isSampleRecording,
    dealProviderKey,
    viewHubspotDeal,
    hasFollowUpEmail,
    showSummaryHotspot,
    getSummaryHotspotAction
  ]);
};
