import styled from '@emotion/styled';
import { spacing } from '@grain/styles/constants';

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  align-items: center;
  ${spacing.px6};

  > * {
    width: 100%;
    max-width: 1054px;
  }
`;
