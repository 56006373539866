import styled from '@emotion/styled';
import { type IconType } from '@grain/grain-ui';
import { theme } from '@grain/grain-ui/v4';

const StyledStatePill = styled.div<{ isOpen: boolean; color: string }>`
  display: flex;
  gap: ${theme.tokens.spacing.xs};
  padding: ${theme.tokens.spacing['2xs']} ${theme.tokens.spacing.sm};
  ${theme.tokens.typography.b4[500]};
  color: ${({ isOpen }) =>
    isOpen ? theme.tokens.color.textInvert : theme.tokens.color.textSecondary};
  background-color: ${({ color, isOpen }) =>
    isOpen ? color : theme.tokens.color.surfaceSecondary};
  border-radius: ${theme.tokens.radii.full};
`;

type StatePillProps = {
  isOpen: boolean;
  icon?: IconType;
  title: string;
  color: string;
};

export function StatePill({
  isOpen,
  icon: Icon,
  title,
  color
}: StatePillProps) {
  return (
    <StyledStatePill isOpen={isOpen} color={color}>
      {Icon && <Icon />}
      <span>{title}</span>
    </StyledStatePill>
  );
}
