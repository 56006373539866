import React from 'react';
import styled from '@emotion/styled';

import { color, spacing } from '@grain/styles/constants';
import { flex, Icon, pxToRem, getOauthRedirectUrl } from '@grain/grain-ui';

export default function ConnectZoomAccount() {
  return (
    <StyledConnectZoomContainer>
      <StyledTitleText>Import Zoom cloud recordings.</StyledTitleText>
      <StyledText>
        Connect a Zoom account to browse and import past Zoom cloud recordings
        and
        <br />
        invite your call recorder when you record in Zoom
      </StyledText>
      <StyledConnectZoomButton as='a' href={getOauthRedirectUrl('zoom')}>
        <Icon.ZoomLogo width='32px' />
        <div> Connect Zoom account</div>
      </StyledConnectZoomButton>
    </StyledConnectZoomContainer>
  );
}

const StyledConnectZoomButton = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 223px;
  height: 36px;
  background: #4a8cff;
  border-radius: 12px;
  font-size: ${pxToRem(14)};
  font-weight: 500;
  line-height: ${pxToRem(20)};
  text-decoration: none;
  ${color.swan};

  :hover {
    text-decoration: none;
  }
`;
const StyledConnectZoomContainer = styled.div`
  background-color: rgba(0, 185, 108, 0.03);
  border: 1px solid rgba(0, 185, 108, 0.5);
  border-radius: 12px;
  ${spacing.py7};
  display: flex;
  ${flex.alignItems.center};
  ${flex.justifyContent.center};
  ${flex.direction.column};
  ${spacing.mt4};
`;

const StyledTitleText = styled.div`
  text-align: center;
  ${color.blackbird};
  line-height: ${pxToRem(24)};
  font-size: ${pxToRem(20)};
  font-weight: 600;
`;

const StyledText = styled.div`
  ${spacing.mt4};
  ${spacing.mb6};
  ${color.blackbird};
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(20)};
  font-weight: normal;
  text-align: center;
`;
