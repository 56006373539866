import {
  Button,
  Modal,
  ModalTitle,
  Tooltip,
  useConfirm,
  useShowToast
} from '@grain/grain-ui';

import {
  StyledFormWrapper,
  StyledInputLabel,
  StyledModalContent
} from './styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BillingPeriod,
  WorkspaceSubscription
} from '@grain/api/schema.generated';
import { useWorkspacePlan } from '@grain/components/Subscriptions/hooks';
import { useWorkspaceWithMembers } from '@grain/components/Workspace/hooks';
import { useLazyQuery } from '@grain/api/graphql';
import { subscriptionPreviewQuery } from '@grain/components/Subscriptions/graphql';
import { newRenderSubscriptionPreviewBillingExplanation } from '~/modules/subscriptions/helpers';
import { Select, Option as OptionV4 } from '@grain/grain-ui/v4';

type ChangeBillingCycleModalProps = {
  onClose: () => void;
  onUpdate: (interval: BillingPeriod) => void;
  loading?: boolean;
  currentSubscription: WorkspaceSubscription;
};

const intervalOptions = [
  { value: BillingPeriod.Monthly, title: 'Monthly' },
  { value: BillingPeriod.Yearly, title: 'Yearly' }
];

export default function ChangeBillingCycleModal({
  currentSubscription,
  onClose,
  onUpdate,
  loading
}: ChangeBillingCycleModalProps) {
  const initialCycle =
    currentSubscription.renewalBillingPeriod ?? BillingPeriod.Monthly;
  const [updatedInterval, setUpdatedInterval] =
    useState<BillingPeriod>(initialCycle);
  const [userIds, setUserIds] = useState<string[]>([]);
  const showConfirm = useConfirm();
  const showToast = useShowToast();

  const { subscriptionPlan: workspacePlan, loading: workspacePlanLoading } =
    useWorkspacePlan();

  const workspaceRes = useWorkspaceWithMembers();

  const selectedOption = useMemo(() => {
    return (
      intervalOptions.find(option => option.value === updatedInterval) ??
      intervalOptions[0]
    );
  }, [updatedInterval]);

  useEffect(() => {
    if (workspaceRes?.workspace?.users) {
      setUserIds(
        workspaceRes.workspace.users
          .filter(user => user.onPlan)
          .map(user => user.id)
      );
    }
  }, [workspaceRes]);

  const [triggerPreview, { loading: previewLoading }] = useLazyQuery(
    subscriptionPreviewQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        subscriptionArgs: {
          planSku: workspacePlan?.sku,
          userIds: userIds,
          billingPeriod: updatedInterval
        }
      }
    }
  );

  const handlePreview = useCallback(async () => {
    if (!workspacePlan || userIds.length === 0 || workspacePlanLoading) {
      return;
    }
    try {
      const previewData = await triggerPreview();
      if (!previewData?.data?.subscriptionPreview) {
        showToast({
          type: 'failure',
          content: 'An error occurred. Please try again.'
        });
        return;
      }
      const summary = newRenderSubscriptionPreviewBillingExplanation(
        previewData?.data?.subscriptionPreview,
        currentSubscription
      );

      onClose();
      const confirmOptions = {
        width: 420,
        confirmContent: 'Continue',
        cancelContent: 'Cancel',
        confirmButtonType: 'primary',
        onConfirm: () => {
          showToast({
            type: 'loading',
            content: 'Updating billing interval...'
          });
          onUpdate(updatedInterval);
        },
        confirmButtonProps: {
          css: ['width: 124px;']
        },
        description: summary,
        title: <div css={['font-size: 20px;']}>Change billing cycle</div>
      };
      showConfirm(confirmOptions);
    } catch {
      showToast({
        type: 'failure',
        content: 'An error occurred. Please try again.'
      });
    }
  }, [
    updatedInterval,
    currentSubscription,
    workspacePlan,
    userIds,
    workspacePlanLoading,
    triggerPreview,
    showConfirm,
    onUpdate,
    showToast,
    onClose
  ]);

  return (
    <Modal closable centered width={516} onCancel={onClose}>
      <ModalTitle title='Change Billing Interval' centered />
      <StyledModalContent>
        <StyledFormWrapper>
          <StyledInputLabel htmlFor='billing-interval'>
            Billing interval
          </StyledInputLabel>
          <Select
            fullWidth
            matchWidth
            placeholder='Select billing interval'
            value={selectedOption?.value}
            onChange={value => {
              setUpdatedInterval(value);
            }}
          >
            <OptionV4 value={BillingPeriod.Monthly}>Monthly</OptionV4>
            <OptionV4 value={BillingPeriod.Yearly}>Yearly</OptionV4>
          </Select>
        </StyledFormWrapper>
        <Tooltip
          content={`Already on the ${initialCycle === BillingPeriod.Yearly ? 'annual' : 'monthly'} plan`}
          disabled={updatedInterval !== initialCycle}
        >
          <Button
            onClick={handlePreview}
            spinning={loading || previewLoading}
            disabled={updatedInterval === initialCycle}
          >
            Update
          </Button>
        </Tooltip>
      </StyledModalContent>
    </Modal>
  );
}
