import {
  color,
  Icon,
  Dropdown,
  MenuItem,
  DropdownProps
} from '@grain/grain-ui';

type LiveRecordingDropdownMenuProps = Partial<DropdownProps> & {
  stopRecording: () => void;
};

export const LiveRecordingDropdownMenu: React.FC<
  LiveRecordingDropdownMenuProps
> = ({ children, stopRecording, ...rest }) => {
  // Sharing disabled until we provide support for it for non-members.
  // const { open: openShare } = useRouteModal(SHARE_RECORDING_ID);

  return (
    <Dropdown placement='bottom' targetElement={children} {...rest}>
      {/* <MenuItem onClick={openShare} label='Share' icon={Icon.Share} />/
          <MenuDivider /> */}
      <MenuItem
        css={[color.fg.errorbird]}
        onClick={stopRecording}
        icon={Icon.Stop}
        label='Stop recording'
        dangerous
      />
    </Dropdown>
  );
};
