import React from 'react';
import styled from '@emotion/styled';
import { useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { importableRecordingsQuery } from '@grain/api/graphql/queries';
import { ImportableRecordingsDocument } from '@grain/api/graphql/queries/recording.generated';
import { useImportableRecordingsChangedSubscription } from '@grain/api/graphql/subscriptions/subscriptions.generated';
import { useQuery } from '@grain/api/graphql';
import { spacing } from '@grain/styles/constants';
import { useLibraryShare } from '~/pages/Library/hooks';
import { useRecordingShareInfoQuery } from '~/pages/Library/library.generated';
import { useForceLazyLoad } from '~/pages/ImportZoom/hooks';
import { RecordingSkeleton, ErrorBox, TileGrid } from '@grain/grain-ui';
import { useGetZoomIntegration } from '~/pages/Settings/Integrations/hook';

import ConnectZoomAccount from '../ConnectZoomAccount';
import ImportCard from '../ImportCard';
import EmptyCard from '../ImportCard/EmptyCard';
import ShareRecordingPermissions from '~/modals/SharePermissions/ShareRecording';

const ImportTileGrid = styled(TileGrid)`
  height: 100%;
`;

export default function ImportZoomGrid() {
  const [searchParams] = useSearchParams();
  const { data, fetchMore, loading } = useQuery(importableRecordingsQuery, {
    variables: {
      includeImported: false
    }
  });
  const scrollContentRef = React.useRef();

  const { recordingId, openRecordingShare, shareRecordingIsOpen } =
    useLibraryShare();

  const { data: recordingData } = useRecordingShareInfoQuery({
    variables: { recordingId },
    fetchPolicy: 'cache-first',
    skip: Boolean(!recordingId || !shareRecordingIsOpen)
  });
  const fetchMoreCb = React.useCallback(() => {
    const cursor = data?.importableRecordings.cursor;
    if (!cursor) return;

    fetchMore({
      variables: { cursor },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const prevRecordings = prev.importableRecordings;
        const moreRecordings = fetchMoreResult.importableRecordings;
        const allRecordings = [...prevRecordings.list, ...moreRecordings.list];

        return {
          importableRecordings: {
            ...prevRecordings,
            cursor: moreRecordings.cursor,
            list: allRecordings
          }
        };
      }
    });
  }, [data, fetchMore]);

  useImportableRecordingsChangedSubscription({
    onData({ client, data }) {
      if (!data?.data?.importableRecordingsChanged) return;

      client.writeQuery({
        query: ImportableRecordingsDocument,
        data: data?.data?.importableRecordingsChanged
      });
    }
  });

  useForceLazyLoad({
    fetchMore: fetchMoreCb,
    skip: Boolean(loading || !data || !data.importableRecordings.cursor),
    scrollContentRef
  });

  const hasData = data?.importableRecordings?.list.length > 0;

  const zoomIntegration = useGetZoomIntegration();
  const hasZoom = !!zoomIntegration;

  const authError = searchParams.get('error') || false;
  const authErrorProvider = searchParams.get('provider') || '';
  const authErrorMessage = `That ${authErrorProvider} account is already connected to another Grain account`;

  if (loading) {
    return (
      <InfiniteScroll
        style={{
          overflow: 'visible'
        }}
        scrollableTarget='infinite-scroll-container'
        dataLength={12}
        hasMore={false}
      >
        <ImportTileGrid>
          {new Array(12).fill(null).map((_, i) => (
            <RecordingSkeleton key={Math.random(i)} />
          ))}
        </ImportTileGrid>
      </InfiniteScroll>
    );
  }

  return (
    <>
      {authError && (
        <ErrorBox variant='default' css={[spacing.m2]} showGeneric={false}>
          {authErrorMessage}
        </ErrorBox>
      )}
      {hasZoom && hasData && (
        <InfiniteScroll
          style={{
            overflow: 'visible'
          }}
          scrollableTarget='infinite-scroll-container'
          dataLength={data?.importableRecordings.list.length}
          hasMore={!!data?.importableRecordings.cursor}
          next={fetchMoreCb}
        >
          <div ref={scrollContentRef}>
            <ImportTileGrid>
              {data?.importableRecordings.list.map(importableRecording => (
                <ImportCard
                  importableRecording={importableRecording}
                  openRecordingShare={openRecordingShare}
                  key={importableRecording.id}
                />
              ))}
            </ImportTileGrid>
          </div>
        </InfiniteScroll>
      )}
      {!loading && hasZoom && !hasData && <EmptyCard />}
      {!hasZoom && <ConnectZoomAccount />}
      {recordingData?.recording && shareRecordingIsOpen && (
        <ShareRecordingPermissions recording={recordingData?.recording} />
      )}
    </>
  );
}
