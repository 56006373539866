import styled from '@emotion/styled';
import { HitType } from '@grain/api/schema.generated';
import { color, font, spacing, toTimestamp } from '@grain/grain-ui';
import { Link } from 'react-router-dom';
import {
  MATCH_TEXT_TYPES,
  formatMatchText,
  parseMatchHtmlToJsx
} from '~/pages/Search/utils';

const Wrapper = styled(Link)`
  display: flex;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  ${color.fg.crow}
  width: 100%;
  ${spacing.g2}
  border-radius: 2px;
  overflow: visible;

  :first-of-type {
    margin-top: 0;
  }

  ::before {
    position: relative;
    z-index: 1;
    content: '';
    min-width: 3px;
    width: 3px;
    background-color: ${color.hawk};
    border-radius: 8px;
  }

  &:hover {
    background-color: ${color.gull};
    outline: 6px solid ${color.gull};
    text-decoration: none;
  }
`;

const Text = styled.p`
  ${font.v4.b3[400]}
  flex: 0 1 auto;
  ${spacing.my1}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .search-match {
    ${font.v4.b3[700]}
    ${color.fg.blackbird}
  }

  .search-tag {
    ${color.fg.highlightGreenDark}
    ${font.v4.b3[400]}
  }
`;

const Timestamp = styled.p`
  ${font.v4.b3[400]}
  flex: 0 0 auto;
  ${spacing.my1}
  ${color.fg.pigeon}
`;

export type MatchItemProps = {
  matchText: string;
  timestamp: number;
  type: HitType;
  recordingPath: string;
  smartTagView?: boolean;
};

export const MatchItem = ({
  matchText,
  timestamp,
  type,
  recordingPath,
  smartTagView = false
}: MatchItemProps) => {
  return (
    <Wrapper to={`${recordingPath}?t=${timestamp}`}>
      <Text>
        {smartTagView
          ? parseMatchHtmlToJsx(matchText)
          : formatMatchText(matchText, MATCH_TEXT_TYPES[type])}
      </Text>
      <Timestamp>{toTimestamp(timestamp)}</Timestamp>
    </Wrapper>
  );
};
